import { Options, SourceData } from "./source.interface";

export function _paginate<T extends SourceData = SourceData>(
  [docs, ...filters]: [null | T | T[], ...Options.Mapped[]]) {
  // must be an array
  if ( ! Array.isArray(docs)) return [docs, ...filters];

  // if pagination is defined, apply it
  const pagination = filters.find(f => f?.pagination)?.pagination;
  if ( ! pagination) return [docs, ...filters];

  // paginate
  let   k = pagination.pageIndex;
  const n = pagination.pageSize;
  const N = docs.length;
  if (N <= k * n) {
    // need to go to a previous page as there would otherwise not be any visible docs
    // the new page index (k) should satisfy the following
    // 1. 0 <= k = integer
    // 2. kn <= N - 1 <= (k + 1)n - 1
    // which is satisfied by: k = max(0, floor((N - 1) / n))
    k = Math.max(0, Math.floor((N - 1) / n));
  }
  const paginatedDocs = docs.slice(k * n, (k + 1) * n);

  // need to manually update the page index
  // (this will luckily not trigger another pagination event)
  pagination.matPaginator.pageIndex = k;

  return [{ docs: paginatedDocs, totalDocs: docs?.length }, ...filters];
}
