import { Component,
         Input,
         ElementRef,
         OnDestroy,
         Optional,
         Self,
         EventEmitter,
         HostBinding,
         Output,
         ViewEncapsulation,
         ChangeDetectionStrategy,
         ViewChild                       } from '@angular/core';
import { ControlValueAccessor,
         NgControl,
         Validators,
         FormGroup,
         FormControl                     } from '@angular/forms';
import { MatFormFieldControl             } from '@angular/material/form-field';
import { FocusMonitor                    } from '@angular/cdk/a11y';
import { coerceBooleanProperty           } from '@angular/cdk/coercion';
import { MatMenuTrigger                  } from '@angular/material/menu';
import { Subject,
         fromEvent,
         asyncScheduler                  } from 'rxjs';
import { takeUntil                       } from 'rxjs/operators';
import _                                   from 'lodash';

import { MinBreakLength                  } from '@app/shared/interfaces';

type BreakLength = MinBreakLength;
type Type = 'default' | 'none' | 'symmetrical' | 'asymmetrical' | undefined;

type Form = {
  type:   FormControl<Type>;
  total:  FormControl<string>;
  before: FormControl<string>;
  after:  FormControl<string>;
};

function toInt (val: string | undefined | null): number {
  if (val == null) return 0;

  // if nan, return 0
  const num = parseInt(val);
  if (isNaN(num)) return 0;
  return num;
}
@Component({
  selector: 'app-form-field-break-length',
  templateUrl: './break-length.component.html',
  styleUrls: ['./break-length.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: BreakLengthComponent
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BreakLengthComponent implements OnDestroy,
                                             ControlValueAccessor,
                                             MatFormFieldControl<BreakLength | null | undefined> {
  @ViewChild(MatMenuTrigger) trigger?: MatMenuTrigger;
  @Output('onChange') emitter = new EventEmitter<BreakLength | null | undefined>();
  private onClose:     Subject<boolean>    = new Subject<boolean>();
  public placeholderText:  string          = '';
  static nextId:       number              = 0;
  public stateChanges: Subject<void>       = new Subject<void>();
  public focused:      boolean             = false;
  public errorState:   boolean             = false;
  public isVoid:       boolean             = true;
  public controlType:  string              = 'break-length-input';
  public id:           string              = `break-length-input-${ BreakLengthComponent.nextId++ }`;
  public describedBy:  string              = '';
  public onChange = (_: any) => {};
  public onTouched = () => {};
  public form:         FormGroup<Form> | null;

  @HostBinding('attr.tabindex') __tabindex = 0;

  protected numeralFormat = { numeral: true };

  constructor (
    @Optional() @Self() public ngControl: NgControl,
    private _focusMonitor: FocusMonitor,
    private _elementRef:   ElementRef<HTMLElement>,
  ) {

    _focusMonitor.monitor(_elementRef, true)
    .subscribe((origin: any) => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  public resetValue(): void {
    this.value = this._pristineValue;
    this.pristine = true;
    this._setForm();
  }

  public opened(): void {

    this.form = new FormGroup<Form>({
      type:   new FormControl<Type>  (undefined, { nonNullable: true }),
      total:  new FormControl<string>('0',       { nonNullable: true, validators: [Validators.required, /* Validators.min(0), ExtendedValidators.isMultipleOf(5) */] }),
      before: new FormControl<string>('0',       { nonNullable: true, validators: [Validators.required, /* Validators.min(0), ExtendedValidators.isMultipleOf(5) */] }),
      after:  new FormControl<string>('0',       { nonNullable: true, validators: [Validators.required, /* Validators.min(0), ExtendedValidators.isMultipleOf(5) */] }),
    });

    this._setForm();

    this.form.valueChanges
    .pipe(
      takeUntil(this.onClose),
    )
    .subscribe(() => {
      this._value = null;
      switch (this.form?.value.type) {
        case 'default':
          this._value = null;
          break;
        case 'none':
          this._value = false;
          break;
        case 'symmetrical':
          this._value = toInt(this.form.value.total);
          break;
        case 'asymmetrical':
          this._value = [toInt(this.form.value.before), toInt(this.form.value.after)];
          break;
      }

      this.pristine = _.isEqual(this._value, this._pristineValue);
      this._handleInput();
    });

    this.isVoid = false;

    fromEvent(document, 'keydown')
    .pipe(takeUntil(this.onClose))
    .subscribe((event: any) => {
      if (event.key == 'Escape') {
        this.trigger?.closeMenu()
      }
    });
  }

  public closed(): void {
    if (this.saveOnClose && this.valid && ! this.pristine) {
      this.emitter.emit(this.value);
      this.pristine = true;
      this._pristineValue = this.value;
    }
    this.form = null;
    this.onClose.next(true);
    this.isVoid = true;
  }

  public submit(): void {
    this.emitter.emit(this.value);
  }

  private _setForm(): void {
    let type: Type = undefined;
    if (this._value === null) {
      type = 'default';
    } else if (typeof this._value == 'boolean') {
      type = 'none';
    } else if (typeof this._value == 'number') {
      type = 'symmetrical';
      this.form?.controls.total.setValue(this._value.toString(), { emitEvent: false });
    } else if (Array.isArray(this._value)) {
      type = 'asymmetrical';
      this.form?.controls.before.setValue(this._value[0].toString(), { emitEvent: false });
      this.form?.controls.after .setValue(this._value[1].toString(), { emitEvent: false });
    }

    this.form?.controls.type.setValue(type, { emitEvent: false });
  }

  get empty() {
    return this._value === undefined;
  }

  get valid(): boolean {
    const type = this.form?.value.type;
    if (type == 'symmetrical')  return !! this.form?.controls.total.valid;
    if (type == 'asymmetrical') return !! this.form?.controls.before.valid && !! this.form?.controls.after.valid;
    return true;
  }

  @Input()
  get defaultValue(): MinBreakLength { return this._defaultValue; }
  set defaultValue(value: MinBreakLength | undefined | null) {
    if (value == undefined) return;
    this._defaultValue = value;
    this.stateChanges.next();
  }
  private _defaultValue: MinBreakLength;

  get shouldLabelFloat() { return this.focused || ! this.empty; }

  get pristine(): boolean {
    return this._pristine;
  }
  set pristine(_val: boolean) {
    const val      = coerceBooleanProperty(_val);
    this._pristine = val;

    if (val) asyncScheduler.schedule(() => this.ngControl?.control?.markAsPristine());

    this.stateChanges.next();
  }
  private _pristine:  boolean = true;

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get voidText(): string { return this._voidText; }
  set voidText(value: string) {
    this._voidText = value;
    this.stateChanges.next();
  }
  private _voidText: string = '';

  @Input()
  get reset(): boolean { return this._reset; }
  set reset(value: boolean) {
    this._reset = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _reset = true;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get disableActions(): boolean { return this._disableActions; }
  set disableActions(value: boolean | string) {
    this._disableActions = coerceBooleanProperty(value);
  }
  private _disableActions = true;

  @Input()
  get saveOnClose(): boolean { return this._saveOnClose; }
  set saveOnClose(value: boolean | string) {
    this._saveOnClose = coerceBooleanProperty(value);
  }
  private _saveOnClose: boolean = false;

  @Input()
  get hideOptionAsymmetrical(): boolean { return this._hideOptionAsymmetrical; }
  set hideOptionAsymmetrical(value: boolean | string) {
    this._hideOptionAsymmetrical = coerceBooleanProperty(value);
  }
  private _hideOptionAsymmetrical: boolean = false;

  @Input()
  get hideOptionNone(): boolean { return this._hideOptionNone; }
  set hideOptionNone(value: boolean | string) {
    this._hideOptionNone = coerceBooleanProperty(value);
  }
  private _hideOptionNone: boolean = false;

  @Input()
  get coalesced(): boolean { return this._coalesced; }
  set coalesced(value: boolean | string) {
    this._coalesced = coerceBooleanProperty(value);
  }
  private _coalesced = false;

  @Input()
  get value(): BreakLength | null | undefined { return (this.valid ? this._value : this._pristineValue) ?? null; }
  set value(_val: BreakLength | null | undefined) {
    const val = _val === undefined ? (this.coalesced ? _val : null) : _val;
    this._value         = val;
    this._pristineValue = val;

    this.stateChanges.next();
  }
  private _value:         BreakLength | null | undefined;
  private _pristineValue: BreakLength | null | undefined;

  @Input()
  get inherit(): boolean { return this._inherit; }
  set inherit(value: boolean | string) {
    this._inherit = coerceBooleanProperty(value);
  }
  private _inherit: boolean;

  @Input()
  get setVoidText(): string { return this._setVoidText; }
  set setVoidText(value: string) {
    this._setVoidText = value;
  }
  private _setVoidText: string;

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
    this.onClose.next(true);
    this.onClose.complete();
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent) {
    this.trigger?.openMenu();
  }

  writeValue(val: BreakLength): void {
    this.value = val;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  _handleInput(): void {
    this.onChange(this.value);
  }

  static ngAcceptInputType_disabled: boolean | string | null | undefined;
  static ngAcceptInputType_required: boolean | string | null | undefined;
}
