<!-- force rerender on stateChanges.next() otherwise it is not guaranteed to show the latest values due to some lazy rendering-->
{{ stateChanges | async }}

<mat-select [formControl]="control"
            (selectionChange)="_handleInput()"
            panelClass="dark-scrollbar">
  <mat-option
    *ngFor="let day of days"
    [value]="day">
    {{ 'common.day_' + day | translate }}
  </mat-option>
</mat-select>
