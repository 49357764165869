import { coerceBooleanProperty            } from '@angular/cdk/coercion';
import { Component,
         Input,
         OnDestroy                        } from '@angular/core';

import { TranslateService                 } from 'app/core';
import { debounceTime, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-form-field-duration-display-value',
  templateUrl: './display-value.component.html',
  styleUrls: ['./display-value.component.scss']
})
export class DisplayValueComponent implements OnDestroy {

  private onDestroy:     Subject<void> = new Subject<void>();
  private onLabelChange: Subject<void> = new Subject<void>();

  public empty:    boolean;
  public label:    string;
  public tooltip:  string;

  constructor (private _translate: TranslateService) {

    this.onLabelChange
    .pipe(takeUntil(this.onDestroy))
    .subscribe(() => this._updateLabel());
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  private _updateLabel(): void {
    // first reset
    this.label = '';

    if (this.prefix) this.label += `${this.prefix} `
    if (this.value == null) {
      ////
      //// this case does not show if "value == undefined" because the mat-select instead uses its placeholder
      ////
      this.empty   = true;
      this.label  += this.voidText;
    } else {
      this.empty   = false;
      this.label  += this.value;

      // add secondary value
      if (this.secondary != null && this.secondary != this.value)
        this.label += ` (${this.secondary})`;
    }
    if (this.suffix) this.label += ` ${this.suffix}`;

    if ( ! this.isPreferredDuration) {
      if (this.empty) this.tooltip = '';
      else            this.tooltip = this.label;
    } else {
      this.tooltip = this._translate.instant('attributes.event.preferredDuration')
                   + ': ' + this.value + ' ' + this.suffix + '\n\n'
                   + this._translate.instant('attributes.event.current_duration')
                   + ': ' + (this.secondary != null ? this.secondary + ' ' + this.suffix : '-' );
    }
  }

  @Input()
  get value(): number | null | undefined { return this._value; }
  set value(value: number | null | undefined) {
    this._value = value;
    this.onLabelChange.next();
  }
  private _value: number | null | undefined;

  @Input()
  get voidText(): string { return this._voidText; }
  set voidText(value: number | string | undefined) {
    this._voidText = value?.toString() ?? '';
    this.onLabelChange.next();
  }
  private _voidText: string = '';

  @Input()
  get prefix(): string { return this._prefix; }
  set prefix(value: string) {
    this._prefix = value;
    this.onLabelChange.next();
  }
  private _prefix: string = '';

  @Input()
  get suffix(): string { return this._suffix; }
  set suffix(value: string) {
    this._suffix = value;
    this.onLabelChange.next();
  }
  private _suffix: string = '';

  @Input()
  get secondary(): number | null | undefined { return this._secondary; }
  set secondary(value: number | null | undefined) {
    this._secondary = value;
    this.onLabelChange.next();
  }
  private _secondary: number | null | undefined;

  @Input()
  get isPreferredDuration(): boolean { return this._isPreferredDuration; }
  set isPreferredDuration(value: boolean | string) {
    this._isPreferredDuration = coerceBooleanProperty(value);
    this.onLabelChange.next();
  }
  private _isPreferredDuration = false;
}
