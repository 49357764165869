import { race,
         firstValueFrom,
         timer                           } from 'rxjs';
import { first,
         map                             } from 'rxjs/operators';

import { ThreadService                   } from './thread.service';

export function killMaster(this: ThreadService, id: number): void {
  this._database.delete(this._collection, id);
}

export function checkIfMaster(this: ThreadService): void {

  if (this._master != null)
    return;

  if (! this._threadId.value)
    return this._logger.warn('(Thread::Check) This thread has no id connected to it');

  this._database
      .db[this._collection]
      ?.orderBy('id')
      .first((doc: any) => {

    if (doc?.id == null) {
      console.log(doc)
      this._logger.warn('(Thread::Check) Id of the first thread document was undefined');
      this._addThread();
      //this.checkIfMaster();
      return;
    }

    const isMaster = doc.id == this._threadId;

    this._logger.debug(`(Thread::Check) This thread is ${ isMaster ? 'master' : 'a slave' }`);

    if (this.isMaster != isMaster)
      this._onSubject.next(isMaster);

    if (isMaster)
      this.setThreadAsMaster();
    else
      this.isMaster = false;

    if (! isMaster)
      this.checkIfMasterExist();
  });
}

export function checkIfMasterExist(this: ThreadService): Promise<boolean> {
  return new Promise<boolean>(async (resolve, reject) => {

    /*
      If a shared worker exist and maintains threads resolve
    */
    if (typeof SharedWorker !== 'undefined') {
      try {
        if (this.isMaster == undefined) {
          await firstValueFrom(this._onMaster);
        }
      } catch(err) {
        return reject(err);
      }
      return resolve(this.isMaster);

    }
    /*
      If this thread is master return;
    */
    if (this.isMaster)
      return resolve(true);

    this._logger.debug(`(Thread::Check) Check if master exist`);

    try {
      if (this._threadId.value == undefined) {
        await firstValueFrom(this._threadId);
      }
    } catch(err) {
      return reject(err);
    }

    this._database
        .db[this._collection]
        ?.where('id')
        .below(this._threadId.value ?? 0)
        .toArray()
        .then((docs: any[]) => {

      if (docs?.length == 0) {
        this.setThreadAsMaster();
        this._logger.debug(`(Thread::Check) This thread is master`);
        return resolve(true);
      }
      /*
        expect response in 0.3 seconds
      */
      race(
        this._broadcast.subscribeToChannel(`thread_sync_${ this._threadId.value }`).pipe(map(val => true)),
        timer(500).pipe(map(val => false))
      )
      .pipe(first())
      .subscribe((exist: boolean) => {

        if (exist) {
          this._logger.debug(`(Thread::Check) Master existed`);
          resolve(false)
        } else {
          this._logger.debug(`(Thread::Check) This thread became master`);
          this._database.bulkDelete(this._collection, docs?.map(({ id }) => id));
          this.setThreadAsMaster();
          resolve(true);
        }
      });

      this._broadcast.emit('thread_sync', { sync: this._threadId.value });
    }).catch(reject);
  });
}