import { Component,
         Input,
         OnDestroy                        } from '@angular/core';
import { coerceBooleanProperty,
         coerceNumberProperty             } from '@angular/cdk/coercion';
import { Subject,
         takeUntil                        } from 'rxjs';
import moment                               from 'moment';

import { Interval,
         Day                              } from 'app/shared/interfaces';
import { DateService                      } from 'app/shared/services';
import { Util                             } from 'app/common';


@Component({
  selector: 'app-form-field-intervals-display-value',
  templateUrl: './display-value.component.html',
  styleUrls: ['./display-value.component.scss']
})
export class DisplayValueComponent implements OnDestroy {

  private onDestroy:     Subject<void> = new Subject<void>();
  private onLabelChange: Subject<void> = new Subject<void>();

  public inherits: boolean;
  public label:    string | { available: boolean, day: number, start: string, end: string }[];
  public tooltip:  string;

  constructor (public date: DateService) {

    this.onLabelChange
    .pipe(takeUntil(this.onDestroy))
    .subscribe(() => this._updateLabel());
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  private _updateLabel(): void {
    this.inherits = false;
    if (   this.inherit && (this._value == null || this._value.length == 0)) this.inherits = true;
    if ( ! this.inherit && (this._value == null || this._value.length == 0)) this.inherits = true;


    // available days inheritance
    let availableDays = (this._availableDays ?? this._inheritAvailableDays)?.map(x => x.day);
    if ( ! availableDays?.length) availableDays = this._days;

    let val = this.value;
    if (val?.length == 1) {
      const { start, end } = val[0];
      const _val   = `${ moment.utc(start).format('HH:mm') } - ${ moment.utc(end).format('HH:mm') }`;
      this.label   = _val;
      // this.tooltip = _val;

      this.tooltip = availableDays
        .map(d => ({
          day:       d,
          start:     moment.utc(start).format('HH:mm'),
          end:       moment.utc(end  ).format('HH:mm')
        }))
        .map(x =>
          `${ this.date.getTranslatedDayName(x.day) }: ${ x.start } - ${ x.end }`
        )
        .join('\n\n');

    } else if (val?.length == this.numDays) {

      this.label = val.map((int, d) => ({
        available: availableDays?.includes(d) ?? false,
        day:       d,
        start:     moment.utc(int.start).format('HH:mm'),
        end:       moment.utc(int.end  ).format('HH:mm')
      }));

      this.tooltip = this.label
        .filter(x => x.available)
        .map(x =>
          `${ this.date.getTranslatedDayName(x.day) }: ${ x.start } - ${ x.end }`
        )
        .join('\n\n');

    } else {
      this.tooltip  = '';
      this.label    = this._voidText;
    }
  }

  protected isArray  = Util.TypeGuards.isArray<typeof this.label>;
  protected isString = Util.TypeGuards.isString;


  @Input()
  get value(): Interval[] | null | undefined {
    if (this.inherit) {
      return (this._value == null || this._value.length == 0) ? this._inheritValue : this._value;
    } else {
      return this._value;
    }
  }
  set value(value:  Interval[] | null | undefined) {
    this._value = value ?? null;
    this.onLabelChange.next();
  }
  private _value: Interval[] | null;

  @Input()
  get voidText(): string { return this._voidText; }
  set voidText(value: string) {
    this._voidText = value;
    this.onLabelChange.next();
  }
  private _voidText: string = '';

  @Input()
  get numDays(): number { return this._numDays; }
  set numDays(value: number) {
    this._numDays = coerceNumberProperty(value, 5);
    this._days    = [...Array(this._numDays).keys()];
    this.onLabelChange.next();
  }
  private _numDays = 5;

  get days(): number[] {
    return this._days;
  }
  private _days = [...Array(5).keys()];

  @Input()
  set availableDays(value: Day[] | null | undefined) {
    this._availableDays = value;
    this.onLabelChange.next();
  }
  private _availableDays: Day[] | null | undefined;

  @Input()
  get inherit(): boolean { return this._inherit; }
  set inherit(value: boolean | string) {
    this._inherit = coerceBooleanProperty(value);
    this.onLabelChange.next();
  }
  private _inherit: boolean;

  @Input()
  // get inheritValue(): Interval[] | null { return this._inheritValue; }
  set inheritValue(value: Interval[] | null | undefined) {
    this._inheritValue = value;
    this.onLabelChange.next();
  }
  private _inheritValue: Interval[] | null | undefined;

  @Input()
  // get inheritAvailableDays(): Day[] | null { return this._inheritAvailableDays; }
  set inheritAvailableDays(value: Day[] | null | undefined) {
    this._inheritAvailableDays = value;
    this.onLabelChange.next();
  }
  private _inheritAvailableDays: Day[] | null | undefined;
}
