import { Component, OnInit, ViewChild } from '@angular/core';
import { ChangePasswordFormComponent } from 'app/shared/forms';
import { HttpService } from 'app/core';
import { SubmitPaneComponent } from 'app/shared/components';
import { MatDialogRef } from 'app/common';
import { AuthService } from 'app/core';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  @ViewChild(ChangePasswordFormComponent, { static: true }) changePasswordComponent?: ChangePasswordFormComponent;
  @ViewChild(SubmitPaneComponent, { static: true }) submitPane: SubmitPaneComponent;

  constructor(private http:HttpService,
              public auth: AuthService,
              public dialogRef: MatDialogRef<ChangePasswordComponent>) { }

  ngOnInit() {
    this.changePasswordComponent?.setForm({ username: this.auth.getUsername() }, { filterInvalid: true, hiddenFields: {  } });
  }

  public submit() :void {
    if (! this.changePasswordComponent) return;
    let form = this.changePasswordComponent.form;
    if (! form.valid)
      return;
    this.submitPane.setSavingState();
    let o = {
      new: form.value.password?.password,
      old: form.value.oldPassword
    }
    this.http.post('users/settings/password', o)
      .subscribe((res: any) => {
        this.submitPane.setSavingSuccessState()
        .then((res: any) => {
          this.dialogRef.close(res);
        });
      }, (err: Error) => {
        this.submitPane.setSavingFail();
      });
  }
}
