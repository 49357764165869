<div class="tooltip"
     [ngClass]="['tooltip--' + position, 'tooltip--' + theme]"
     [class.tooltip--visible]="visible"
     [style.left.px]="center"
     [style.top.px]="top">
  <ng-container *ngIf="_portal" [cdkPortalOutlet]="_portal"></ng-container>

  <span [innerHTML]="tooltip"></span>

  <ng-container *ngIf="hasAction">
    <mat-divider></mat-divider>

    <button mat-button type="button" (click)="_onAction.next()">
      {{ 'common.goto-tutorial' | translate }}
    </button>
  </ng-container>
</div>