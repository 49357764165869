import { Injectable,
         ElementRef,
         ViewContainerRef,
         EmbeddedViewRef,
         ComponentRef                    } from '@angular/core';
import { firstValueFrom,
         Subject                         } from 'rxjs';
import $                                   from 'jquery';

import { PopoverComponent                } from './popover.component';
import { Options                         } from './popover.types';

@Injectable({
  providedIn: 'root'
})
export class PopoverService {
  private componentRef: ComponentRef<PopoverComponent> | null = null;
  private onClose = new Subject<void>();
  protected _viewContainerRef: ViewContainerRef;

  public options?: Options;

  constructor() {
    this.onClose.subscribe(() => this.destroy());
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  setViewContainerRef(viewContainerRef: ViewContainerRef) {
    this._viewContainerRef = viewContainerRef
  }

  public open(options: Options): Promise<void> {
    if (this.componentRef) {
      this.destroy();
    }

    this.componentRef = this._viewContainerRef.createComponent(PopoverComponent);
    const domElem =  (this.componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);

    this.componentRef.instance.setComponentProperties(options, this.onClose);

    const popover = $(domElem);

    this.options = options;

    // get day index

    ////
    //// calculate position
    ////

    // the source boundaries
    const source_top    = this.options.source_top;
    const source_bottom = this.options.source_bottom;
    const source_left   = this.options.source_left;
    const source_right  = this.options.source_right;

    setTimeout(() => {



      // the container offset
      const container = popover.parent();
      const container_top    = container.offset()!.top;
      const container_bottom = container_top + container.height()!;
      const container_left   = container.offset()!.left;
      const container_right  = container_left + container.width()!;

      //
      const horizontalSourceMargin     = 50;
      const verticalSourceMargin       = 50;
      const horizontalContainerPadding = 50;
      const verticalContainerPadding   = 50;

      //
      const popoverWidth  = popover.outerWidth();
      const popoverHeight = popover.outerHeight();


      ////
      //// horizontal position
      ////
      let popoverCentered = false;
      if (container_right - horizontalContainerPadding - source_right - horizontalSourceMargin >= popoverWidth!) {
        // possible to place to the right of the source event
        popover.css("left", source_right - container_left + horizontalSourceMargin + "px");
        if (this.options) this.options.animate = "left";

      } else if (source_left - horizontalSourceMargin - container_left - horizontalContainerPadding >= popoverWidth!) {
        // possible to place to the left of the source event
        popover.css("left", source_left - container_left - horizontalSourceMargin - popoverWidth! + "px");
        if (this.options) this.options.animate = "right";
      } else {
        // does not fit on either side, hence center
        const left = 0.5 * (container_right - container_left - popoverWidth!);
        popover.css("left", left + "px");
        if (this.options) this.options.animate = "left";
        popoverCentered = true;
      }


      ////
      //// vertical position
      ////
      if ( ! popoverCentered) {
        // not centered, hence try to align with source
        const topWithin    = source_top - container_top - verticalContainerPadding >= 0;
        const bottomWithin = container_bottom - verticalContainerPadding - source_top - popoverHeight! >= 0;

        if (topWithin && bottomWithin) {
          // possible to align with source
          popover.css("top", source_top - container_top + "px")
        } else if (topWithin) {
          // move up
          popover.css("top", container_bottom - container_top - verticalContainerPadding - popoverHeight! + "px")
        } else {
          // move down
          popover.css("top", verticalContainerPadding + "px")
        }
      } else {
        // centered, hence try to position beneath or above source
        if (source_top - verticalSourceMargin - container_top - verticalContainerPadding >= popoverHeight!) {
          // possible to place above
          popover.css("top", source_top - container_top - popoverHeight! - verticalSourceMargin + "px");
        } else if (container_bottom - verticalContainerPadding - source_top - verticalSourceMargin >= popoverHeight!) {
          // possible to place beneath
          popover.css("top", source_bottom - container_top + verticalSourceMargin + "px");
        } else {
          // does not fit on either side, hence center
          const top = 0.5 * (container_bottom - container_top - popoverHeight!);
          popover.css("top", top + "px");
        }
      }

    }, 0, this);

    // return promise that resolves when popover is closed
    return firstValueFrom(this.onClose);
  }

  public close (): void {
    this.onClose.next();
  }

  destroy(): void {
    if (this.componentRef !== null) {
      this._viewContainerRef.clear();
      this.componentRef.destroy();
      this.componentRef = null;
    }
  }
}
