import { coerceBooleanProperty            } from '@angular/cdk/coercion';
import { Component,
        ChangeDetectionStrategy,
         Input,
         OnDestroy                        } from '@angular/core';
import { TranslateService                 } from '@app/core';
import { Period                           } from '@app/shared/interfaces';
import { DisplayNamePipe                  } from '@app/shared/pipes/common/common.pipe';

import { Subject,
         takeUntil                        } from 'rxjs';

type Value = Period.populated;

@Component({
  selector: 'app-form-field-period-display-value',
  templateUrl: './display-value.component.html',
  styleUrls: ['./display-value.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DisplayValueComponent implements OnDestroy {

  private onDestroy:     Subject<void> = new Subject<void>();
  private onLabelChange: Subject<void> = new Subject<void>();

  public inherits: boolean;
  public label:    string;
  public tooltip:  string;

  constructor (private _translate:   TranslateService,
               private _displayName: DisplayNamePipe) {

    this.onLabelChange
    .pipe(takeUntil(this.onDestroy))
    .subscribe(() => this._updateLabel());
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  private _updateLabel(): void {
    if (this.value) {
      this.inherits = false;
      this.label    = this._value ? this._displayName.transform(this._value) : '-';
      this.tooltip  = this._value ? this.label : this._translate.instant('common.none_singular');
    } else {
      this.inherits = true;
      this.label    = this.voidText;
      this.tooltip  = '';
    }
  }

  @Input()
  get value(): Value | null | undefined { return this._value; }
  set value(value: Value | null | undefined) {
    this._value = value;
    this.onLabelChange.next();
  }
  private _value: Value | null | undefined;

  @Input()
  get voidText(): string { return this._voidText; }
  set voidText(value: string) {
    this._voidText = value;
    this.onLabelChange.next();
  }
  private _voidText: string = '';

  @Input()
  get disableTooltip(): boolean { return this._disableTooltip; }
  set disableTooltip(value: boolean) {
    this._disableTooltip = coerceBooleanProperty(value);
  }
  private _disableTooltip: boolean = false;


}
