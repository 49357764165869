/**
 * @description
 * When no activity is detected, this is the delay before the tab is considered inactive. When the tab is inactive, it will not send any heartbeats to the shared worker.
 * NOTE:
 * 1. Use fairly large values as throttled tabs might not be able to keep up. (see https://developer.mozilla.org/en-US/docs/Web/API/setTimeout#reasons_for_delays_longer_than_specified)
 * 2. When a tab hangs this delay becomes irrelevant as the tab beats are (per definition of a hung tab) no longer are sent to the shared worker.
 */
export const tabInactiveDelay = 2 * 60 * 1000;

/**
 * @description
 * When the tab is active, this variable specifies how often it should share this information with the shared worker.
 * NOTE: Use fairly large values as throttled tabs might not be able to keep up. (see https://developer.mozilla.org/en-US/docs/Web/API/setTimeout#reasons_for_delays_longer_than_specified)
 */
export const tabHeartbeatInterval = 5 * 1000;


/**
 * @description
 * Proceeds with at most one event every throttleTime milliseconds to be debounced by the resilientDebounceTime.
 * The latter is somewhat expensive and should not be run too often.
 */
export const tabInactiveDelayThrottleTime = 2 * 1000;
if (tabInactiveDelayThrottleTime >= tabInactiveDelay) {
  throw new Error('The tabInactiveDelay should be larger than the throttleTime');
}


/**
 * @description
 * The time until the global activity state is deemed inactive after the last tab "heartbeat" was received.
 * NOTE:
 * 1. Make rather large as slack for slowed down setTimeouts in background tabs
 *    (code executions in background tabs are restricted to once every second in chrome e.g.)
 *    (see https://developer.mozilla.org/en-US/docs/Web/API/setTimeout#reasons_for_delays_longer_than_specified)
 * 2. Make very large to avoid falsely marking tabs as inactive when the main thread is preforming some heavy calculations, such as rendering stuff
 *    -> IN THE FUTURE WE MIGHT WANT TO SEND THE HEARTBEATS NOT FROM THE MAIN THREAD OF THE TABS BUT FROM A WEB WORKER
*/
export const globalInactiveDelay = 30 * 60 * 1000;


if (globalInactiveDelay < 4 * tabHeartbeatInterval) {
  throw new Error('The globalInactiveDelay should be at least 4 times the tabHeartbeatInterval');
}
if (globalInactiveDelay < 20 * 1000) {
  throw new Error('The globalInactiveDelay should be at least 20 seconds, see "note 2"');
}