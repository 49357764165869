import _                                   from 'lodash';

import { Util                            } from './util';

const immutables: string[] =  ['_id', 'id', 'belongsTo'];

function _getNestedEntity(ob: unknown, path: string[], allowSerialized?: boolean): any {
  const key = path.shift();

  if (key === undefined) return;

  /*
    safe get. if path is groups.id but groups is serialized
  */
  if (allowSerialized && _.isString(ob)) {
    return ob;
  }

  if (! _.isObject(ob)) return;

  const entity: unknown = key in ob ? _.get(ob, key) : undefined;

  if (entity === undefined) return undefined;

  if (Array.isArray(entity)) {
    const subKey = path.shift();

    if (subKey === undefined) return entity;

    if (! path.length) {
      if (entity.some((nestedOb: Object | string | null) => _.isObject(nestedOb) && nestedOb !== null))
        return entity.map((nestedOb: Record<string, unknown>) => nestedOb?.[subKey]).filter(x => !_.isUndefined(x)).flat();
      return entity;
    }

    return entity.map((nestedOb: Record<string, unknown>) => _getNestedEntity(nestedOb?.[subKey], _.clone(path), allowSerialized) ?? nestedOb).flat();
  } else {
    if (! path.length) return entity;

    return _getNestedEntity(entity, path, allowSerialized);
  }
}

export function fromStringRepresentation<T extends object>(ob: T | (T | null)[] | null, path: Util.Types.NestedKeyOf<T>, allowSerialized?: boolean): any {
  if (! path) return ob;

  const _path: string[] = path?.split('.')!;

  if (! _path?.length) return ob;

  return Array.isArray(ob) ? ob.map(x => _getNestedEntity(x, _.clone(_path), allowSerialized)) : _getNestedEntity(ob, _path, allowSerialized);
}

type Maps = { [key: string]: any };

function _mapNestedProperty(ob: object, map: Maps, path: string[], index?: number): any {
  const key = path.shift();

  if (key === undefined) return;

  const entity: any = _.isObject(ob) ? _.get(ob, key) : undefined;

  if (entity === undefined) return;

  if (! path.length) {
    if (Array.isArray(entity))
      return Object.assign(ob, { [key]: entity.map((ref: string, index: number) => Array.isArray(map) ? map[index]?.[ref] : map[ref]) });
    return Object.assign(ob, { [key]: (Array.isArray(map) && index) ? map[index]?.[entity] : map[entity] });
  }

  if (Array.isArray(entity))
    return entity.forEach((elem: any, index: number) => _mapNestedProperty(elem, map, _.clone(path), index));
  return _mapNestedProperty(entity, map, path);
}

export function mapNestedProperty<T extends object>(ob: T, map: Maps, path: string): void {
  const _path: string[] = path.split('.');
  return Array.isArray(ob) ? ob.map(x => _mapNestedProperty(x, map, _.clone(_path))) : _mapNestedProperty(ob, map, _path);
}

function _setNestedProperty<T extends object>(ob: Partial<T> | null, property: any, path: Extract<keyof T, string>[]): any {
  const key: keyof T | undefined = path.shift();

  if (key === undefined) return;

  const entity = ob?.[key] as Partial<T[keyof T]> ?? null;

  if (! path.length) {
    if (! ob) ob = {};

    if (Array.isArray(entity)) {
      if (Array.isArray(property))
        return Object.assign(ob, { [key]: property });
      return Object.assign(ob, { [key]: entity.map((subOb: object) => Object.assign(subOb, property)) });
    }
    return Object.assign(ob, { [key]: property });
  }

  if (Array.isArray(entity))
    return entity.forEach((elem: object) => _setNestedProperty(elem, property, _.clone(path)));

  return _setNestedProperty<ThisType<typeof entity>>(entity, property, path as any);
}

export function setNestedProperty<T extends object>(ob: T | (T | null)[] | null, property: any, path: Util.Types.NestedKeyOf<T>): void {
  const _path: Extract<keyof T, string>[] = path.split('.') as Extract<keyof T, string>[];
  Array.isArray(ob) ? ob.forEach(x => _setNestedProperty(x, _.clone(property), _.clone(_path))) : _setNestedProperty(ob, property, _path);
}

function _filterNestedProperty<T extends object>(ob: Partial<T> | null, filter: string | number, path: Extract<keyof T, string>[]): any {
  const key: keyof T | undefined = path.shift();

  if (ob == null) return;

  if (key === undefined) return;

  const entity: any = ob?.[key] as T[keyof T] ?? null;

  if (! path.length) {

    if (Array.isArray(entity))
      return Object.assign(ob, { [key]: entity.filter(subOb => subOb != filter) });
    return entity != filter ? Object.assign(ob, { [key]: entity }) : ob;
  }

  if (Array.isArray(entity))
    return Object.assign(ob, { [key]: entity.filter((elem: any) => _getNestedEntity(elem, _.clone(path)) != filter) });

  _filterNestedProperty<ThisType<typeof entity>>(entity, filter, path as any); //TODO FIX
}

export function filterNestedProperty<T extends object>(ob: T | (T | null)[] | null, filter: string | number, path: Util.Types.NestedKeyOf<T>): void {
  const _path: Extract<keyof T, string>[] = path.split('.') as Extract<keyof T, string>[];
  Array.isArray(ob) ? ob.map(x => _filterNestedProperty(x, filter, _.clone(_path))) : _filterNestedProperty(ob, filter, _path);
}

function _unsetNestedProperty<T extends object>(
  ob: T | (T | null)[] | null,
  path: Extract<keyof T, string | number>[]
): void {
  const key = path.shift();

  if (ob == null) return;

  if (key === undefined) return;

  if (! path.length) {
    if (Array.isArray(ob)) {
      // If the key is not a number, then assume that it applies for all entries
      if (! _.isNumber(key)) {
        ob.forEach((elem: any) => _unsetNestedProperty(elem, [key as string]));
        return;
      }
      ob.splice(key, 1);
      return;
    }
    delete ob[key];
    return;
  }

  if (Array.isArray(ob)) {
    // If the key is not a number, then assume that it applies for all entries
    if (! _.isNumber(key)) {
      ob.forEach((elem: any) => _unsetNestedProperty(elem, [...path, key]));
    return;
    }
    // If the key is a number, then apply it to the specific entry
    _unsetNestedProperty(ob[key], path);
    return;
  }

  const entity: any = ob?.[key] as T[keyof T] ?? null;

  if (Array.isArray(entity)) {
    Object.assign(ob, { [key]: entity });
    return;
  }

  _unsetNestedProperty<ThisType<typeof entity>>(entity, path as any); //TODO FIX
}

export function unsetNestedProperty<T extends object>(
  ob: T | (T | null)[] | null,
  path: Util.Types.NestedKeyOf<T> | Extract<keyof T, string | number>[]
): void {
  const _path: Extract<keyof T, string | number>[] = Array.isArray(path) ? path : path.split('.') as Extract<keyof T, string | number>[];
  _unsetNestedProperty(ob, _path);
}
