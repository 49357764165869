<form [formGroup]="form"
      novalidate
      class="container">

  <mat-form-field [hidden]="true">
    <input matInput
           id="username"
           autocomplete="username"
           name="username"
           type="text"
           formControlName="username">
  </mat-form-field>

  <div formGroupName="password">
    <mat-form-field class="form-field" appearance="outline">
      <mat-label>{{ 'shared.forms.change-password-form.new_password' | translate }}</mat-label>
      <input matInput
             autocomplete="password"
             id="password"
             type="password"
             formControlName="password">
      <mat-error><app-form-field-error [control]="form.controls.password.controls.password"></app-form-field-error></mat-error>
    </mat-form-field>

    <mat-form-field class="form-field" appearance="outline" autocomplete="password">
      <mat-label>{{ 'shared.forms.change-password-form.repeat_password' | translate }}</mat-label>
      <input matInput
             autocomplete="confirm-password"
             id="confirmPassword"
             type="password"
             formControlName="confirmPassword">
      <mat-error><app-form-field-error [control]="form.controls.password.controls.confirmPassword"></app-form-field-error></mat-error>
    </mat-form-field>
  </div>

  <mat-form-field class="form-field" appearance="outline" *ngIf="! hiddenFields?.oldPassword">
    <mat-label>{{ 'shared.forms.change-password-form.current_password' | translate }}</mat-label>
    <input matInput
           required
           type="password"
           formControlName="oldPassword"
           autocomplete="old-password">
    <mat-error><app-form-field-error [control]="form.controls.oldPassword"></app-form-field-error></mat-error>
  </mat-form-field>

  <div class="form-actions">
    <ng-content></ng-content>
  </div>
</form>