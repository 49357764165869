import { NgModule                        } from '@angular/core';
import { CommonModule                    } from '@angular/common';

import { AppCommonModule                 } from 'app/common/common.module';

import { TranslationModule               } from 'app/core/translate/translate.module';

import { TooltipDirective                } from './tooltip.directive';
import { TooltipComponent                } from './tooltip.component';

@NgModule({
  declarations: [
    TooltipDirective,
    TooltipComponent
  ],
  exports: [
    TooltipDirective
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    TranslationModule
  ]
})
export class TooltipModule { }
