import { Injectable                      } from '@angular/core';
import { HttpEvent,
         HttpInterceptor,
         HttpHandler,
         HttpRequest,
         HttpHeaders                     } from '@angular/common/http';
import { Observable                      } from 'rxjs';

import { storageConstants                } from 'app/constants';

import { LoggerService                   } from 'app/core/logger/logger.service';
import { AuthService                     } from '../auth/auth.service';

@Injectable()
export class HttpClientInterceptor implements HttpInterceptor {

  constructor (
    private _auth: AuthService,
  ) {
  }

  intercept (req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let headers: HttpHeaders = req.headers;

    if ( ! headers.get('Accept')) headers = headers.set('Accept', 'application/json');

    const _token = this._auth.getToken();
    if (_token && req.responseType != 'blob') headers = headers.set('Authorization', `Bearer ${ _token }`);

    const clone = req.clone({ headers: headers });
    return next.handle(clone);
  }
}