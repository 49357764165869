<h1 mat-dialog-title>{{ 'common.create_schedule' | translate }}</h1>

<app-submit-pane [hasActions]="true"></app-submit-pane>

<mat-dialog-content>
  <app-division-form displayName start end numDays></app-division-form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button
    mat-button
    mat-dialog-close
    type="button">
    {{ 'common.cancel' | translate }}
  </button>

  <button
    mat-button
    form="division-form"
    type="submit"
    [disabled]=" ! division?.form?.valid"
    (click)="submit()"
    color="primary">
    {{ 'common.save' | translate }}
  </button>
</mat-dialog-actions>