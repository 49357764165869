import { HttpErrorResponse               } from '@angular/common/http';
import { Observable,
         throwError,
         timer                           } from 'rxjs';
import { mergeMap,
         finalize                        } from 'rxjs/operators';

import { HttpService                     } from './http.service';

const retryAfter:       number = 1000;
const maxRetryAttempts: number = 3;

export function retryStrategy(this: HttpService, skipRetry: boolean = false) {
  return (attempts: Observable<any>) => {
    return attempts.pipe(
      mergeMap((error: HttpErrorResponse, i: number) => {

        // if cypress, throw error in order to fail test
        if ('Cypress' in window && (window as any).Cypress) {
          console.warn(`(Http::retry) Cypress detected, throwing error to fail test`);
          throw(error);
        }

        const retryAttempt = i + 1;
        if (retryAttempt > maxRetryAttempts || error.status != 401 || skipRetry)
          return throwError(error);

        if (error.status == 401 && retryAttempt == maxRetryAttempts)
          this.onStatus.next();

        console.debug(`(Http::retry) Attempt ${retryAttempt}: retrying in ${retryAfter}ms`);

        return timer(retryAfter);
      }),

      finalize(() => {
        console.debug(`(Http::retry) retry attempts completed`);
      })
    );
  }
}