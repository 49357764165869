import { EventInput           } from '@fullcalendar/core';
import moment                   from 'moment';
import { CalendarComponent    } from './calendar.component';
import { ExtendedProperties,
         InCalendarEvent,
         isEvent,
         isLockedTime,
         _EventSource         } from './types';

export function setForegroundEvents (
  this:   CalendarComponent,
  events: InCalendarEvent[] | null
) {
  this.foregroundEvents.next(events?.map(x => this.mapToForegroundEvent(x)) ?? []);
}

export function getForegroundEvents (
  this: CalendarComponent
) {
  return this.foregroundEvents;
}

export function addForegroundEvent (
  this: CalendarComponent,
  event: InCalendarEvent
) {
  this.foregroundEvents.next([...this.foregroundEvents.value, this.mapToForegroundEvent(event)]);
}

export function removeForegroundEvent (
  this:   CalendarComponent,
  { id }: { id: string }
) {
  this.foregroundEvents.next(this.foregroundEvents.value.filter(x => x.id != id));
}

export function removeForegroundEvents (
  this:  CalendarComponent,
  val?:  { id: string }[]
) {
  if ( ! val) return this.foregroundEvents.next([]);

  this.foregroundEvents.next(this.foregroundEvents.value.filter(x => ! val.some(y => y.id == x.id)));
}


export function mapToForegroundEvent (
  this:  CalendarComponent,
  event: InCalendarEvent
): EventInput & { extendedProps: ExtendedProperties & { source: _EventSource } } {
  const id    = event.id;
  const start = moment.utc(event.start).toDate();
  const end   = moment.utc(event.end).toDate();

  let title = event.title;
  // if not title, derive from type
  if ( ! title && (isEvent(event) || isLockedTime(event))) {
    title = this._displayNamePipe.transform(event);
  }

  let backgroundColor = event.backgroundColor;
  if ( ! backgroundColor) {
    if (isEvent(event) || isLockedTime(event)) backgroundColor = this._eventColorPipe.transform(event);
    else                                       backgroundColor = this.defaultColor;
  }

  // css classes
  const classNames = event.classNames || []
  if (isLockedTime(event))                          classNames.push('locked-time-event');
  // if (event.isInterval == true)                     classNames.push('interval-event');
  if ('visible' in event && event.visible == false) classNames.push('invisible-event');
  if (isLockedTime(event) && event.type == 'LUNCH') classNames.push('lunch-event');

  // etc
  const extendedProps: ExtendedProperties & { source: _EventSource } = {
    ...event.extendedProps,
    source: event,   // reference to original source event
  };

  return {
    title, id, backgroundColor, start, end, classNames, extendedProps
  };
}