import { Component,
         DestroyRef,
         inject,
         Inject,
         Injector,
         OnInit,
         Optional                        } from '@angular/core';
import { FormsModule,
         ReactiveFormsModule             } from '@angular/forms';
import { CommonModule                    } from '@angular/common';
import { JsonEditorOptions,
         NgJsonEditorModule              } from 'ang-jsoneditor';
import _                                   from 'lodash';

import { TranslationModule               } from '@core/translate/translate.module';

import { AppCommonModule                 } from '@common/common.module';

import { PipesModule                     } from 'app/shared/pipes/pipes.module';

import { MatDialogRef                    } from 'app/common';
import { SourceService,
         LoggerService                   } from 'app/core';

import { ThreadService                   } from 'app/core/thread/thread.service';
import { SourceCore                      } from 'app/core/source/source.core';
import { Collection,
         InvalidVertex                   } from 'app/core/source/core/system-core';

import { ValidationResolvePipe,
         ValidationErrorPipe             } from './debug.pipe';
import { resolve                         } from './debug.util';

type Debug = Awaited<ReturnType<SourceCore["getDebugInfo"]>>;
@Component({
  selector: 'app-debug',
  templateUrl: './debug.component.html',
  standalone: true,
  imports: [
    CommonModule,
    AppCommonModule,
    ReactiveFormsModule,
    FormsModule,
    TranslationModule,
    ValidationErrorPipe,
    ValidationResolvePipe,
    NgJsonEditorModule,
    // PipesModule,
  ],
  styleUrls: ['./debug.component.scss'],
})
export class DebugComponent {
  private readonly destroyRef = inject(DestroyRef);
  protected state:      Debug["state"];
  protected references: Debug["references"];
  protected source:     Debug["source"];
  protected errors:     Debug["errors"];

  constructor(
    protected dialogRef:        MatDialogRef<DebugComponent>,
    @Optional() protected core: SourceCore,
    protected thread:           ThreadService,
    private readonly _injector: Injector,
  ) {
    this.core?.getDebugInfo()
    .then(({ references, source, state, errors }) => {
      this.state      = state;
      this.references = references;
      this.source     = source;
      this.errors     = errors;
    });
    // this.createSource();
    // console.log((this.core as any)._systemCore.checkRefs());
  }

  protected createSource(): void {
    const injector = Injector.create(
      {
        parent: this._injector,
        providers: [
          {
            provide: SourceService,
            deps: [
              SourceCore,
              LoggerService
            ]
          }
        ]
      }
    );

    const _source = injector.get(SourceService);
  }

  protected terminate(): void {
    this.core.terminate();
  }

  protected stateChange(state: Debug["state"]): void {
    this.core.useSystemInstance(state);
  }

  protected toggleEditor(item: any): void {
    item.code = ! item.code;
    item.options = new JsonEditorOptions();
  }

  protected onChange(item: any, change: any, editor: any): void {
    item.json = change;
  }

  protected resolve(
    item:       InvalidVertex & { json?: any },
    did:        string,
    collection: Collection
  ): void {
    if (item.json) {
      return this.core.set({ did, collection }, {
        id: item._original.id,
        ..._.omit(item.json, 'id')
      });
    }

    const modified: Record<string, any> = { id: item._original.id };
    // loop over every detail and resolve with modifiedValue and set said property(path[0])
    item.details.forEach((detail: any) => {
      modified[detail.path[0]] = resolve(item._original, detail, detail.modifiedValue, detail.cutPath);
    });

    // use ValidationResolvePipe.transform to resolve
    this.core.set({ did, collection }, modified);
  }
}