import { Inject,
         Component,
         OnDestroy                        } from '@angular/core';
import { BehaviorSubject,
         takeUntil,
         Subject,
         combineLatest,
         filter,
         map                              } from 'rxjs';
import _                                    from 'lodash';

import { MatBottomSheetRef,
         MAT_BOTTOM_SHEET_DATA,
         Util                             } from 'app/common';
import { SourceService                    } from 'app/core';
import { Populated as P,
         Week                             } from '@app/shared/interfaces';
import { OutQueryStructure                } from '@app/shared/services';


const types = ['teachers', 'groups', 'locations', 'week'] as const;
type EntityType = (typeof types)[number];

export type InjectedData = {
  did:   string;
  query: Pick<OutQueryStructure, EntityType>;
  lists: {
    groups?:    P.group   [] | null;
    teachers?:  P.teacher [] | null;
    locations?: P.location[] | null;
    weeks?:     Week      [] | null;
  }
}

type Entity = P.teacher | P.group | P.location | Week;

export type SchemaFilterBottomSheetComponentReturnData = Pick<OutQueryStructure, EntityType>;


@Component({
  templateUrl: './schema-filter-bottom-sheet.component.html',
  styleUrls: ['./schema-filter-bottom-sheet.component.scss'],
  providers: [
    SourceService
  ]
})
export class SchemaFilterBottomSheetComponent implements OnDestroy {

  private onDestroy = new Subject<void>();

  protected onTeachers  = new BehaviorSubject<P.teacher [] | null>(null);
  protected onGroups    = new BehaviorSubject<P.group   [] | null>(null);
  protected onLocations = new BehaviorSubject<P.location[] | null>(null);
  protected onWeeks     = new BehaviorSubject<Week      [] | null>(null);

  private did:      string;

  public activeType:      EntityType;
  // public key:      Omit<Key, 'week'>;
  // public selectedType: Key;

  // protected selectedWeek?: Week = { number: 99 } as any;

  public selectedWeek?: Week;


  public onSelected = new BehaviorSubject<Partial<Record<EntityType, string> > >({ });


  public selectedFilterList: any;
  public selectOptions:      BehaviorSubject<Entity[] | null> | undefined;

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<SchemaFilterBottomSheetComponent>,
    private _source:         SourceService,
    @Inject(MAT_BOTTOM_SHEET_DATA)
    private data:            InjectedData
  ) {
    const { did, lists, query } = data;
    this.did = did;

    // fetch data
    lists.groups    ? this.onGroups   .next(lists.groups   ) : this._source.getPopulatedGroups   ({ did: this.did, onDestroy: this.onDestroy }).pipe(takeUntil(this.onDestroy)).subscribe(this.onGroups);
    lists.teachers  ? this.onTeachers .next(lists.teachers ) : this._source.getPopulatedTeachers ({ did: this.did, onDestroy: this.onDestroy }).pipe(takeUntil(this.onDestroy)).subscribe(this.onTeachers);
    lists.locations ? this.onLocations.next(lists.locations) : this._source.getPopulatedLocations({ did: this.did, onDestroy: this.onDestroy }).pipe(takeUntil(this.onDestroy)).subscribe(this.onLocations);
    this.onWeeks.next(lists.weeks ?? []);

    // fetch initial selection from query
    const selected: Util.Types.GetObservableType<typeof this.onSelected> = { };
    const restrictedQuery = _.omit(query, 'week');
    if (_.size(restrictedQuery)) {
      let { key, val } = Util.functions.objectKeyVals(restrictedQuery)[0];
      this.activeType  = key;
      selected[key]    = val[0];
      this.setActive(this.activeType);
    }
    if (query.week) {
      selected.week = query.week;
    }
    this.onSelected.next(selected);

    // fetch the selected week in order to display its number
    combineLatest({
      id:    this.onSelected.pipe(map(x => x.week), filter(Boolean)),
      weeks: this.onWeeks.pipe(filter(Boolean))
    })
    .pipe(takeUntil(this.onDestroy))
    .subscribe(({ id, weeks }) => this.selectedWeek = weeks.find(x => x.id == id));

    // override the backdrop closing behavior
    this._bottomSheetRef.disableClose = true;
    this._bottomSheetRef.backdropClick()
    .pipe(takeUntil(this.onDestroy))
    .subscribe(() => this.close());
  }

  ngOnDestroy () {
    this.onDestroy.next();
    this.onDestroy.next();
  }

  protected select (type: EntityType, entity: Entity) {
    let selected = this.onSelected.value;

    if (type == 'week') selected.week = entity.id;
    else                selected = { ..._.pick(selected, 'week'), [type]: entity.id };

    this.onSelected.next(selected);

    // try close
    if (! this.onWeeks.value?.length) this.close()
  }

  protected close(): void {
    // map to OutQueryStructure
    const sel = this.onSelected.value
    const out: SchemaFilterBottomSheetComponentReturnData = {
      ...sel.week      ? { week:      sel.week        } : { },
      ...sel.teachers  ? { teachers:  [sel.teachers]  } : { },
      ...sel.groups    ? { groups:    [sel.groups]    } : { },
      ...sel.locations ? { locations: [sel.locations] } : { }
    };
    this._bottomSheetRef.dismiss(out);
  }

  protected setActive (key: EntityType): void {
    this.activeType = key;
    if      (key == 'teachers')  this.selectOptions = this.onTeachers;
    else if (key == 'groups')    this.selectOptions = this.onGroups;
    else if (key == 'locations') this.selectOptions = this.onLocations;
    else if (key == 'week')      this.selectOptions = this.onWeeks;
    else                         this.selectOptions = undefined;
  }

  protected isWeek (e: Entity): e is Week {
    return 'number' in e;
  }
}
