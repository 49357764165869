import { Component,
         Inject                      } from '@angular/core';
import { takeUntilDestroyed          } from '@angular/core/rxjs-interop';
import { FormControl,
         Validators                  } from '@angular/forms';
import { BehaviorSubject             } from 'rxjs';

import { MatDialogRef,
         MAT_DIALOG_DATA             } from '@app/common';
import { HttpService,
         PushNotificationService,
         TranslateService            } from '@app/core';
import { inOutAnimation              } from '@app/shared/animations';

@Component({
  templateUrl: './duplicate-schedule.component.html',
  styleUrls: ['./duplicate-schedule.component.scss'],
  animations: [ inOutAnimation ]
})
export class DuplicateScheduleComponent {
  protected readonly loading = new BehaviorSubject<boolean>(false);
  protected readonly did: string;
  protected readonly ctrl = new FormControl<string | null>(null, { validators: Validators.required });

  constructor (
    private   _dialogRef:     MatDialogRef<DuplicateScheduleComponent>,
    private   _http:          HttpService,
    private   _notifications: PushNotificationService,
    private   _translate:     TranslateService,
    @Inject(MAT_DIALOG_DATA)
    { did, displayName }: { did: string, displayName: string }
  ) {
    this.did = did;
    const copyStr = ` (${ this._translate.instant('common.copy') })`.toLowerCase();
    this.ctrl.setValue(displayName + copyStr);

    // make form control disabled when loading
    this.loading
    .pipe(takeUntilDestroyed())
    .subscribe(x => {
      if (x) this.ctrl.disable();
      else   this.ctrl.enable();
    });
  }

  protected submit () {
    this.loading.next(true);
    this._http.post(`divisions/${ this.did }/clone`, { division: { displayName: this.ctrl.value } })
    .subscribe({
      next: () => {
        this.loading.next(false);
        this._dialogRef.close(true);
      },
      error: () => {
        this.loading.next(false);
        this._notifications.pushError();
      }
    });
  }
}
