import { Injectable                         } from '@angular/core';
import { BehaviorSubject,
         Observable,
         Subject                            } from 'rxjs';

import { HttpService,
         LoggerService,
         TranslateService,
         UserPreferencesService             } from '@app/core';
import { Category,
         Stack                              } from './types';
import { activate,
         deactivate,
         setupDOM                           } from './activate';
import { push,
         notificationFactory,
         createNotifElem,
         closeNotification                  } from './push';
import { pushError                          } from './push-error';
import { pushConfirmation                   } from './push-confirmation';
import { setStack                           } from './set-stack';

export * from './types';


@Injectable({
  providedIn: 'root'
})
export class PushNotificationService {
  protected onActive: Subject<boolean>;

  // incremental variables
  protected notificationCount: number = 0;

  // stack id to stack map
  public get stacks (): Observable<Map<Category, Stack>> { return this._stacks_BH; };
  protected _stacks: Map<Category, Stack> = new Map();
  protected _stacks_BH: BehaviorSubject<Map<Category, Stack> > = new BehaviorSubject(this._stacks);

  // if it is activated or not
  public get activated () { return this._activated; };
  protected _activated: boolean = false;

  // private/protected methods
  protected setupDOM            = setupDOM;
  protected setStack            = setStack;
  protected notificationFactory = notificationFactory;
  protected createNotifElem     = createNotifElem;
  protected closeNotification   = closeNotification;

  // public methods
  public activate         = activate;
  public deactivate       = deactivate;
  public push             = push;
  public pushError        = pushError;
  public pushConfirmation = pushConfirmation;

  constructor (
    protected _logger:      LoggerService,
    protected _http:        HttpService,
    protected _translate:   TranslateService,
    protected _preferences: UserPreferencesService
  ) { }

}
