import Dexie                               from 'dexie';
// import                                          'dexie-observable';
import _                                   from 'lodash';
import { Subject                         } from 'rxjs';
import { filter                          } from 'rxjs/operators';

import { Logger                          } from '@core/logger/logger.interface';

import { Catch                           } from '@shared/decorators/catch/catch.decorator';

import { Database,
         Collection,
         ChangeEvent                     } from './database.interface';

const DATABASE_NAME:    string = 'RS';
const DATABASE_VERSION: number = 7;

export class DatabaseCore implements Database {
  private _onChange = new Subject<ChangeEvent>();
  public db: any = new Dexie(DATABASE_NAME);

  constructor(protected _logger?: Logger) {

    this.db.version(DATABASE_VERSION).stores({
      threads: '++id, master',
    });

    this.db.open().catch((err: Error) => {
      console.error('Failed to open db: ' + (err.stack || err));
    });

    this.db.threads?.hook('creating', (key: number, obj: any) => {
      this._onChange.next({ type: 'creating', table: 'threads', key, obj });
    });

    this.db.threads?.hook('updating', (modifications: any, key: number, obj: any) => {
      this._onChange.next({ type: 'updating', table: 'threads', key, obj, mods: modifications });
    });

    this.db.threads?.hook('deleting', (key: number, obj: any) => {
      this._onChange.next({ type: 'deleting', table: 'threads', key, obj });
    });
  }

  public watch(_collection: Collection) {
    return this._onChange.asObservable()
                          .pipe(
                            filter((change: ChangeEvent) => _collection ? change.table == _collection : true)
                          );
  }

  @Catch()
  public bulkPut(_collection: Collection, docs: object[]): void {
    const collection: Dexie.Table = this.db[_collection]!;

    if (! collection)
      throw new Error(`(Database::bulkput) No database collection for ${ _collection }`);

    collection.bulkPut(docs)
  }

  @Catch({ onError: (err: unknown) => Promise.reject(err) })
  public put(_collection: Collection, doc: object): Promise<any> {
    const collection: Dexie.Table = this.db[_collection]!;

    if (! collection)
      throw new Error(`(Database::put) No database collection for ${ _collection }`);

    if (collection.name != 'threads' && ! _.has(doc, 'id'))
      throw new Error(`(Database::put) No id in doc for collection ${ _collection }`);

    return collection.put(doc).catch((err) => { throw err.message })
  }

  @Catch()
  public update<T extends { id: string }>(_collection: Collection, update: T): void {
    const collection: Dexie.Table = this.db[_collection]!;

    if (! collection)
      throw new Error(`(Database::update) No database collection for ${ _collection }`);

    if (! update.id) {
      this._logger?.warn(`(Database::update) No id in update`);
      return;
    }

    collection.update(update.id, update)
  }

  @Catch()
  public bulkDelete(_collection: Collection, id: string[]): void {
    const collection: Dexie.Table = this.db[_collection]!;

    if (! collection)
      throw new Error(`(Database::bulkDelete) No database collection for ${ _collection }`);

    collection.bulkDelete(id)
  }

  @Catch()
  public delete(_collection: Collection, id: string | number): void {
    const collection: Dexie.Table = this.db[_collection]!;

    if (! collection)
      throw new Error(`(Database::delete) No database collection for ${ _collection }`);

    collection.delete(id)
  }

  @Catch()
  public clear(_collection: Collection): void {
    const collection: Dexie.Table = this.db[_collection]!;

    if (! collection)
      throw new Error(`(Database::clear) No database collection for ${ _collection }`);

    collection.clear()
  }

  @Catch({  onError: (err: unknown) => Promise.reject(err) })
  public get(_collection: Collection, did: string) {
    if (did == null)
      return Promise.reject(new Error(`(Database::get) No did for ${ _collection }`));

    const collection: Dexie.Table = this.db[_collection]!;

    if (! collection)
      throw new Error(`(Database::get) No database collection for ${ _collection}`);

    return collection.where({ belongsTo: did }).toArray();
  }

  @Catch()
  public find(_collection: Collection, query: object): object[] {
    const collection: Dexie.Table = this.db[_collection]!;

    if (! collection)
      throw new Error(`(Database::find) No database collection for ${ _collection}`);

    return (collection.get(query) as any).toArray()
  }

  @Catch({  onError: (err: unknown) => Promise.reject(err) })
  public has(did: string, _collection: Collection): Promise<boolean> {
    if (did == null)
      throw new Error(`(Database::has) No did for ${ _collection }`);

    if (! _.isString(did))
      throw new Error(`(Database::has) Did must be a string, got ${ typeof did }`);

    const collection: Dexie.Table = this.db[_collection]!;

    if (! collection)
      throw new Error(`(Database::has) N No database collection for ${ _collection }`);

    return new Promise<boolean>((resolve, reject) => {
      collection
      .where({ belongsTo: did })
      .first(doc => {
        resolve(!! doc);
      }).catch((err: Error) => {
        this._logger?.error(err);
        reject(err);
      });
    })
  }

  public deleteDatabase(): void {
    this.db.delete()
           .catch(this._logger?.error)
           .then(() => this.db.open());
  }
}
