import { BehaviorSubject                 } from 'rxjs';
import { OutQueryStructure               } from 'app/shared/services'


//                                                                                   ⌄⌄⌄⌄⌄⌄⌄⌄ needed even though it cannot be selected
export const collections = ['teachers', 'groups', 'locations', 'persons', 'courses', 'events', 'tags', 'week'] as const;
export type Collection = typeof collections[number];

export type Query = Pick<OutQueryStructure, Collection>;

type EntityCollection = Exclude<Collection, 'week'>;
export type Entity = { is?: string; id: string; displayName?: string; }

export type EntitySelectData = {
  collection: EntityCollection;
  label:      string;
  path:       EntityCollection;
  options$:   BehaviorSubject<Entity[] | null>;
};

export type SelectedEntity = {
  key:     Exclude<keyof Query, 'week'>;
  id:      string;
  entity?: Entity;
}