import { SourceCore                      } from '../source.core';

import { Debug                          } from './system-core/debug';

export type State = {
  state: 'Legacy' | 'Coalesced',
}

export function terminate(this: SourceCore): void {
  (this.__systemCore as any).useCommand?.({ terminate: true });
}

export async function getDebugInfo(this: SourceCore): Promise<Debug & State> {
  try {
    let info: ReturnType<typeof this._systemCore.getDebugInfo> | Promise<ReturnType<typeof this._systemCore.getDebugInfo>>;

    if (this._systemCore.getDebugInfo() instanceof Promise) {
      info = await this._systemCore.getDebugInfo();
    } else {
      info = this._systemCore.getDebugInfo();
    }

    return {
      state: this.state,
      ...info
    };
  } catch(err) {
    return Promise.reject(err);
  }
}