import _                                from "lodash";

import { Populated as P               } from "@app/shared/interfaces";
import { InputAnalysisService         } from "./input-analysis.service";


export function findLocation (
  this: InputAnalysisService,
  val:  string | undefined
): P.location | undefined {
  const location = this.locations.value?.find(x => x.id == val);
  if ( ! location) {
    this._logger.error(new Error(`(InputAnalysisService::findLocation): unable to find a location with id "${val}"`));
  }

  return location;
}

export function findGroup (
  this: InputAnalysisService,
  val:  string | undefined
): P.group | P.teacher | P.person | undefined {
  // the ID is of the form "xxx.yyy" where xxx is either groups, teachers or persons and yyy is the id
  if ( ! val?.includes('.')) {
    this._logger.error(new Error(`(InputAnalysisService::findGroup): Unexpected id "${val}"`));
    return undefined;
  }

  const [collection, id] = val.split('.');
  let group: P.group | P.teacher | P.person | undefined;
  if      (collection == 'groups'  ) group = this.groups  .value?.find(x => x.id == id);
  else if (collection == 'teachers') group = this.teachers.value?.find(x => x.id == id);
  else if (collection == 'persons' ) group = this.persons .value?.find(x => x.id == id);
  else {
    this._logger.error(new Error(`(InputAnalysisService::findGroup): Unexpected collection "${collection}"`));
    return undefined;
  }

  if ( ! group) {
    this._logger.error(new Error(`(InputAnalysisService::findGroup): unable to find a group/teacher/person with id "${val}"`));
  }

  return group;
}

export function findEvent (
  this: InputAnalysisService,
  val:  string | undefined
): P.event | P.lockedTime | undefined {
  // the ID is of the form "xxx.yyy" where xxx is either events or lockedtimes and yyy is the id
  if ( ! val?.includes('.')) {
    this._logger.error(new Error(`(InputAnalysisService::findEvent): Unexpected id "${val}"`));
    return undefined;
  }

  const [collection, id] = val.split('.');
  let event: P.event | P.lockedTime | undefined;
  if      (collection == 'events'     ) event = this.events     .value?.find(x => x.id == id);
  else if (collection == 'lockedtimes') event = this.lockedTimes.value?.find(x => x.id == id);
  else {
    this._logger.error(new Error(`(InputAnalysisService::findEvent): Unexpected collection "${collection}"`));
    return undefined;
  }

  if ( ! event) {
    this._logger.error(new Error(`(InputAnalysisService::findEvent): unable to find a event/locked time with id "${val}"`));
  }

  return event;
}



export function prependCollection (
  e: Pick<P.event | P.lockedTime | P.group | P.teacher | P.person, 'is' | 'id'>
): string {
  if (e.is == 'event')      return 'events'      + '.' + e.id;
  if (e.is == 'lockedTime') return 'lockedtimes' + '.' + e.id;
  if (e.is == 'group')      return 'groups'      + '.' + e.id;
  if (e.is == 'teacher')    return 'teachers'    + '.' + e.id;
  if (e.is == 'person')     return 'persons'     + '.' + e.id;

  throw 'could not determine collection type';
}