import { Input,
         Directive                         } from '@angular/core';
import { coerceBooleanProperty,
         coerceNumberProperty,
         coerceArray                       } from '@angular/cdk/coercion';
import { EnvironmentService,
         UserPreferencesService            } from '@app/core';

type FormType = 'row' | 'column';

@Directive()
export abstract class FormCore {

  constructor (private __preferences: UserPreferencesService,
               private __environment:  EnvironmentService) {}

  @Input()
  get displayName(): boolean { return this._displayName; }
  set displayName(value: boolean) {
    this._displayName = coerceBooleanProperty(value);
  }
  private _displayName: boolean = true;

  @Input()
  get ids(): boolean { return this.__preferences.displayPublicId && this._ids; }
  set ids(value: boolean) {
    this._ids = coerceBooleanProperty(value);
  }
  private _ids: boolean = true;

  @Input()
  get days(): boolean { return this._days; }
  set days(value: boolean) {
    this._days = coerceBooleanProperty(value);
  }
  private _days: boolean = true;

  @Input()
  get minBreakLength(): boolean { return this._minBreakLength; }
  set minBreakLength(value: boolean) {
    this._minBreakLength = coerceBooleanProperty(value);
  }
  private _minBreakLength: boolean = true;

  @Input()
  get duration(): boolean { return this._duration; }
  set duration(value: boolean) {
    this._duration = coerceBooleanProperty(value);
  }
  private _duration: boolean = true;

  @Input()
  get preferredDuration(): boolean { return this._preferredDuration; }
  set preferredDuration(value: boolean) {
    this._preferredDuration = coerceBooleanProperty(value);
  }
  private _preferredDuration: boolean = true;

  @Input()
  get durationVariance(): boolean { return this._durationVariance; }
  set durationVariance(value: boolean) {
    this._durationVariance = coerceBooleanProperty(value);
  }
  private _durationVariance: boolean = true;

  @Input()
  get groups(): boolean { return this._groups; }
  set groups(value: boolean) {
    this._groups = coerceBooleanProperty(value);
  }
  private _groups: boolean = this.__environment.hasCollection('groups');

  @Input()
  get teachers(): boolean { return this._teachers; }
  set teachers(value: boolean) {
    this._teachers = coerceBooleanProperty(value);
  }
  private _teachers: boolean = this.__environment.hasCollection('teachers');

  @Input()
  get locations(): boolean { return this._locations; }
  set locations(value: boolean) {
    this._locations = coerceBooleanProperty(value);
  }
  private _locations: boolean = this.__environment.hasCollection('locations');

  @Input()
  get lockedTimes(): boolean { return this._lockedTimes; }
  set lockedTimes(value: boolean) {
    this._lockedTimes = coerceBooleanProperty(value);
  }
  private _lockedTimes: boolean = this.__environment.hasCollection('lockedTimes');

  @Input()
  get periods(): boolean { return this._periods; }
  set periods(value: boolean) {
    this._periods = coerceBooleanProperty(value);
  }
  private _periods: boolean = this.__environment.hasCollection('periods');

  @Input()
  get intervals(): boolean { return this._intervals; }
  set intervals(value: boolean) {
    this._intervals = coerceBooleanProperty(value);
  }
  private _intervals: boolean = true;

  @Input()
  get centerOfAttraction(): boolean { return this._centerOfAttraction; }
  set centerOfAttraction(value: boolean) {
    this._centerOfAttraction = coerceBooleanProperty(value);
  }
  private _centerOfAttraction: boolean = true;

  @Input()
  get showLoading(): boolean { return this._showLoading; }
  set showLoading(value: boolean) {
    this._showLoading = coerceBooleanProperty(value);
  }
  private _showLoading: boolean = true;

  @Input()
  get editable(): boolean { return this._editable; }
  set editable(value: boolean) {
    this._editable = coerceBooleanProperty(value);
  }
  private _editable: boolean = false;

  // @Input()
  // get numDays(): number { return this._numDays; }
  // set numDays(value: number) {
  //   this._numDays = coerceNumberProperty(value, 5);
  // }
  // private _numDays = 5;

  @Input()
  get discretization(): number { return this._discretization; }
  set discretization(value: number) {
    this._discretization = coerceNumberProperty(value, 5);
  }
  private _discretization: number = 5;

  @Input()
  get type(): FormType { return this._type; }
  set type(value: FormType) {
    this._type = value;
  }
  private _type: FormType = 'column';
}