import { NgModule,
         ModuleWithProviders,
         InjectionToken           } from '@angular/core';
import { registerLocaleData       } from '@angular/common';
import { MatPaginatorIntl         } from '@angular/material/paginator';
import { TranslateModule          } from '@ngx-translate/core';
import * as T                       from './translations/index';

// alphabetical order
import localeCa                     from '@angular/common/locales/ca';
import localeCaExtra                from '@angular/common/locales/extra/ca';
registerLocaleData(localeCa, T.LANG_CA_NAME, localeCaExtra);

import localeDe                     from '@angular/common/locales/de';
import localeDeExtra                from '@angular/common/locales/extra/de';
registerLocaleData(localeDe, T.LANG_DE_NAME, localeDeExtra);

import localeEl                     from '@angular/common/locales/el';
import localeElExtra                from '@angular/common/locales/extra/el';
registerLocaleData(localeEl, T.LANG_EL_NAME, localeElExtra);

import localeEn                     from '@angular/common/locales/en';
import localeEnExtra                from '@angular/common/locales/extra/en';
registerLocaleData(localeEn, T.LANG_EN_NAME, localeEnExtra);

import localeEs                     from '@angular/common/locales/es';
import localeEsExtra                from '@angular/common/locales/extra/es';
registerLocaleData(localeEs, T.LANG_ES_NAME, localeEsExtra);

import localeFr                     from '@angular/common/locales/fr';
import localeFrExtra                from '@angular/common/locales/extra/fr';
registerLocaleData(localeFr, T.LANG_FR_NAME, localeFrExtra);

import localeSv                     from '@angular/common/locales/sv';
import localeSvExtra                from '@angular/common/locales/extra/sv';
registerLocaleData(localeSv, T.LANG_SV_NAME, localeSvExtra);

import { TranslateService         } from 'app/core/translate';
import { languageConstants        } from 'app/constants';
import { MatPaginatorIntlTrans    } from './paginator';

@NgModule({
  imports: [
  ],
  declarations: [
  ],
  exports: [
    TranslateModule
  ]
})
export class TranslationModule {
  static forRoot(): ModuleWithProviders<TranslationModule> {
    return {
      ngModule: TranslationModule,
      providers: [
        {
          provide: MatPaginatorIntl,
          useClass: MatPaginatorIntlTrans,
          deps: [TranslateService]
        },
        {
          provide: new InjectionToken('translations'),
          useValue: languageConstants
        },
        TranslateService
      ]
    };
  }
}