<!-- force rerender on stateChanges.next() otherwise it is not guaranteed to show the latest values due to some lazy rendering-->
{{ stateChanges | async }}

<div class="fixed-start-select-label clickable"
     (menuClosed)="closed()"
     (menuOpened)="opened()"
     [matMenuTriggerFor]="menu">
  <app-form-field-fixed-start-display-value [value]="value.fixedStart && value.start"
                                            [valid]="form?.valid"
                                            [voidText]="voidText">
  </app-form-field-fixed-start-display-value>
</div>

<mat-menu yPosition="below"
          #menu="matMenu"
          class="fixed-start-panel no-vertical-padding"
          [overlapTrigger]="true">
  <ng-template matMenuContent>
    <div class="toolbar" (click)="$event.stopPropagation()">
      <div class="row">

        <button mat-stroked-button
                class="action reset"
                (click)="resetValue()"
                *ngIf="! pristine && reset && valid">
          <div>
            <span>{{ 'common.restore' | translate }}</span>
            <mat-icon class="royal-icon">undo</mat-icon>
          </div>
        </button>

        <button mat-stroked-button
                class="action invalid"
                *ngIf="! valid">
          <div>
            <span>{{ 'common.invalid' | translate }}</span>
            <mat-icon>warnings</mat-icon>
          </div>
        </button>
      </div>
    </div>


    <div class="content" *ngIf="form" [formGroup]="form" (click)="$event.stopPropagation()">
      <mat-radio-group class="radio-group" formControlName="enabled">
        <mat-radio-button [value]="false" class="radio-button">
          {{ 'shared.form-fields.fixed-start.disabled' | translate }}
        </mat-radio-button>
        <mat-radio-button [value]="true" class="radio-button">
          {{ 'shared.form-fields.fixed-start.enabled' | translate }}
        </mat-radio-button>
      </mat-radio-group>

      <div class="container">

        <mat-form-field appearance="outline">
          <mat-label>{{ 'attributes.shared.day' | translate }}</mat-label>
          <app-form-field-day formControlName="day" [numDays]="numDays"></app-form-field-day>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{ 'attributes.shared.startTime' | translate }}</mat-label>
          <app-form-field-time formControlName="start"></app-form-field-time>
        </mat-form-field>

      </div>
    </div>

    <mat-divider class="divider"
                *ngIf="! disableActions"
                (click)="$event.stopPropagation()"></mat-divider>

    <div (click)="$event.stopPropagation()" class="actions" *ngIf="! disableActions">
      <button mat-stroked-button
              color="primary"
              type="button"
              class="button"
              (click)="trigger?.closeMenu()">
        {{ 'common.cancel' | translate }}
      </button>
      <button mat-flat-button
              color="primary"
              type="button"
              [disabled]="! valid"
              (click)="submit(); trigger?.closeMenu()"
              class="button right">
        {{ 'common.save' | translate }}
      </button>
    </div>
  </ng-template>

</mat-menu>


