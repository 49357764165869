import { Pipe,
         PipeTransform                   } from '@angular/core';
import _                                   from 'lodash';

import { TranslateService                } from '@app/core';

import { Group                           } from 'app/shared/interfaces';

import { Type,
         Value                           } from './groups.component';
import { Util                            } from '@app/common';


@Pipe({
  name: 'selectedMembers'
})
export class SelectedMembersPipe implements PipeTransform {
  transform(
    group:           Group,
    value:           Value | Value[] | null,
    selected:        boolean,
    coalescedValue?: Value | Value[] | null
  ): number | undefined {
    if (! selected) {
      if (! Array.isArray(coalescedValue) || ! coalescedValue?.length)
        return 0;
      return coalescedValue.filter(x => _.get(x, ['to', 'group', 'id']) == group.id).length
    }

    if (value == null)
      return group.members?.length ?? 0;
    /*
      optimize
    */
    const val = (Array.isArray(value) ? value : [value])?.find(x => ('to' in x ? x.to.id : x.id) === group.id);
    let excluded: number = 0;
    /*
      If database dereference on person group change is implemented then remove this
    */
    if (val)
      excluded = ('exclude' in val ? val.exclude?.filter(x => group.members?.some(y => y.id == x.id)).length : 0) ?? 0;
    return Math.max((group.members?.length ?? 0) - excluded, 0);
  }
}

@Pipe({
  name: 'totalSelected'
})
export class TotalSelectedPipe implements PipeTransform {
  constructor(private _translate: TranslateService) {}

  private _wrap(value: number, type: Type /* <added> */ | undefined | null /* </added> */): string {
    // according to its type "type: Type" should not be null, but it sometimes is...
    // (https://app.royalschedule.com/app-logs/6409ba8961c5a97a00a9c870)
    if ( ! type) return this._translate.instant('common.unknown');

    return `${ value } ${ this._translate.instant('common.' + (value == 1 ? type.slice(0, -1) : type)) }`;
  }

  transform(
    value:           number,
    type:            Type,
    coalescedValue?: number
  ): string | undefined {
    const others = coalescedValue ?? 0;

    if (value == null)
      return this._wrap(others, type);

    switch (type) {
      default:
      case 'teachers':
      case 'persons':
        return this._wrap((value ?? 0) + others, type);
      case 'groups':
        return this._wrap(others, 'persons');
    }
  }
}