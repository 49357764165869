import { Component,
         OnInit,
         Input,
         ElementRef,
         OnDestroy,
         Optional,
         HostBinding,
         Self,
         EventEmitter,
         ChangeDetectionStrategy,
         Output,
         ViewChild                       } from '@angular/core';
import { ControlValueAccessor,
         NgControl                       } from '@angular/forms';
import { fromEvent,
         asyncScheduler,
         Subject                         } from 'rxjs';
import { takeUntil                       } from 'rxjs/operators';
import { MatFormFieldControl             } from '@angular/material/form-field';
import { FocusMonitor                    } from '@angular/cdk/a11y';
import { coerceBooleanProperty           } from '@angular/cdk/coercion';
import { MatMenuTrigger                  } from '@angular/material/menu';

import { EnvironmentService              } from '@app/core';

import { commonConstants                 } from 'app/constants/common';

import { defaultColors,
         sduiColors                      } from './color.constants';


const defaultColor = commonConstants.COLORS.EVENT_DEFAULT;


@Component({
  selector: 'app-form-field-color',
  templateUrl: './color.component.html',
  styleUrls: ['./color.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: ColorComponent
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColorComponent implements OnInit,
                                       OnDestroy,
                                       ControlValueAccessor,
                                       MatFormFieldControl<string | null | undefined> {
  @ViewChild(MatMenuTrigger)      trigger:           MatMenuTrigger;
  @Output('onChange') change= new EventEmitter<string | null | undefined>();
  private onClose:    Subject<boolean> = new Subject<boolean>();
  static nextId:      number           = 0;
  public stateChanges:Subject<void>    = new Subject<void>();
  public focused:     boolean          = false;
  public errorState:  boolean          = false;
  public controlType: string           = 'color-input';
  public id:          string           = `color-input-${ ColorComponent.nextId++ }`;
  public describedBy: string           = '';
  public onChange = (_: any) => {};
  public onTouched = () => {};

  public defaultEventColor = defaultColor;

  ////
  //// NOTE: THESE NEED TO BE HEX VALUES AND 6 DIGITS LONG SINCE SOMETIMES OPACITIES ARE ADDED AT THE END
  ////
  public colors: readonly string[] = defaultColors;

  @HostBinding('attr.tabindex') __tabindex = 0;

  constructor(private _focusMonitor: FocusMonitor,
              private _elementRef: ElementRef<HTMLElement>,
              private _environment: EnvironmentService,
              @Optional() @Self() public ngControl: NgControl) {

    _focusMonitor.monitor(_elementRef, true)
    .subscribe((origin: any) => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    // Set color scheme to whitelabel colors if available
    switch (_environment.whiteLabel) {
      case 'sdui':
        this.colors = sduiColors;
        break;
      default:
        break;
    }
  }

  public closed(): void {
    this.onClose.next(true);
  }

  public opened(): void {
    fromEvent(document, 'keydown')
    .pipe(takeUntil(this.onClose))
    .subscribe((event: any) => {
      if (event.key == 'Escape') {
        this.trigger?.closeMenu()
      }
    });
  }

  ngOnInit(): void {
  }

  get empty() {
    return ! this._value;
  }

  get shouldLabelFloat() { return this.focused || ! this.empty; }

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get coalesced(): boolean { return this._coalesced; }
  set coalesced(value: boolean | string) {
    this._coalesced = coerceBooleanProperty(value);
    asyncScheduler.schedule(() => this.nullValue = this._nullValue);
  }
  private _coalesced = false;

  @Input()
  get nullable(): boolean {
    return this._nullable;
  }
  set nullable(value: boolean | string) {
    this._nullable= coerceBooleanProperty(value);
  }
  private _nullable: boolean = false;

  @Input()
  get nullValue(): string | null {
    return this._nullValue;
  }
  set nullValue(color: string | null) {
    const val = color || (this._coalesced === true ? '#ffffff' : '#ffffff');
    this._nullValue = val;
  }
  private _nullValue: string = '#ffffff';

  @Input()
  get value(): string | null | undefined {
    return this._value;
  }
  set value(color: string | null | undefined) {
    const val = color;
    this._value = val;
    this.stateChanges.next();
  }
  private _value: string | null | undefined;

  @Input()
  get setVoidText(): string { return this._setVoidText; }
  set setVoidText(value: string) {
    this._setVoidText = value;
  }
  private _setVoidText: string;

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
    this.onClose.next(true);
    this.onClose.complete();
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() != 'input') {
      //this._elementRef.nativeElement.querySelector('input')!.focus();
    }
  }

  writeValue(val: string | null): void {
    this.value = val;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  _handleInput(): void {
    this.onChange(this.value);
    this.trigger.closeMenu();
    this.change.emit(this.value!);
  }

  static ngAcceptInputType_disabled: boolean | string | null | undefined;
  static ngAcceptInputType_required: boolean | string | null | undefined;
}
