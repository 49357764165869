import { ElementRef,
         Inject,
         Injectable,
         OnDestroy                } from '@angular/core';
import { PageEvent                } from '@angular/material/paginator';
import { MatSortable              } from '@angular/material/sort';
import { BehaviorSubject,
         Subject,
         combineLatest,
         debounceTime,
         distinctUntilChanged,
         filter,
         fromEvent,
         map,
         switchMap,
         take,
         takeUntil                } from 'rxjs';
import _                            from 'lodash';
import $                            from 'jquery';

import { SearchComponent          } from '@app/shared';
import { StorageService           } from '@app/core';
import { MatPaginator,
         MatSort                  } from '@app/common';
import { DataSourceService        } from '@app/shared/services';
import { Collection               } from '../../types';
import { COLLECTION               } from '../../constants';
import { storageKeys              } from './constants';


@Injectable({
  providedIn: 'root'
})
export class StateService<C extends Collection> implements OnDestroy {
  private readonly onDestroy = new Subject<void>();

  private readonly storageKey  = storageKeys[this.collection];
  private readonly pageSizeKey = `${this.storageKey}/page-size`;
  private pageIndexKey (did: string) { return `${this.storageKey}/page-index/${did}`; }
  private searchKey    (did: string) { return `${this.storageKey}/search/${did}`;     }
  private scrollKey    (did: string) { return `${this.storageKey}/scroll/${did}`;     }
  private sortKey      (did: string) { return `${this.storageKey}/sort/${did}`;       }


  constructor (
    @Inject(COLLECTION) private collection: C,
    private storage: StorageService
  ) {

    // load search string
    combineLatest({
      did:             this._did            .pipe(filter(Boolean)),
      searchComponent: this._searchComponent.pipe(filter(Boolean)),
    })
    .pipe(
      takeUntil(this.onDestroy),
      take(1)
    )
    .subscribe(({ did, searchComponent }) => {
      const previousValue = sessionStorage.getItem(this.searchKey(did));
      if (previousValue && searchComponent.value != previousValue) searchComponent.value = previousValue;
    });
    // store search string
    combineLatest({
      did:          this._did            .pipe(filter(Boolean)                           ),
      searchString: this._searchComponent.pipe(filter(Boolean), switchMap(x => x.onValue))
    })
    .pipe(takeUntil(this.onDestroy))
    .subscribe(({ did, searchString }) => {
      sessionStorage.setItem(this.searchKey(did), searchString)
    });



    // load and sync page size
    combineLatest({
      pageSize:  this.storage.onStorage(this.pageSizeKey).pipe(filter(Boolean), map(x => _.toNumber(x.value))),
      paginator: this._matPaginator                      .pipe(filter(Boolean))
    })
    .pipe(
      takeUntil(this.onDestroy)
    )
    .subscribe(({ pageSize, paginator }) => {
      const previousPageIndex = paginator.pageIndex;
      const pageIndex         = Math.floor(previousPageIndex * paginator.pageSize / pageSize);

      paginator.pageSize = pageSize;

      // need to emit manually to trigger paginator.page of all components
      // (not only the one that triggered the change)
      paginator.page.emit({
        previousPageIndex, pageIndex, pageSize,
        length: paginator.length
      } as PageEvent);

    });
    // store page size
    this._matPaginator
    .pipe(
      takeUntil(this.onDestroy),
      filter(Boolean),
      switchMap(paginator => paginator.page),
      map(x => x.pageSize),
      distinctUntilChanged()
    )
    .subscribe(pageSize => this.storage.set(this.pageSizeKey, pageSize));



    // load page index
    combineLatest({
      did:       this._did         .pipe(filter(Boolean)),
      paginator: this._matPaginator.pipe(filter(Boolean)),
    })
    .pipe(
      takeUntil(this.onDestroy),
      take(1)
    )
    .subscribe(({ did, paginator }) => {
      const pageIndex = _.toNumber(sessionStorage.getItem(this.pageIndexKey(did)));
      if (pageIndex && paginator.pageIndex != pageIndex) {
        const previousPageIndex = paginator.pageIndex;

        paginator.pageIndex = pageIndex;

        // need to emit manually to trigger paginator.page
        paginator.page.emit({
          previousPageIndex, pageIndex,
          pageSize: paginator.pageSize,
          length: paginator.length
        } as PageEvent);
      }
    });
    // store page index
    combineLatest({
      did:       this._did         .pipe(filter(Boolean)),
      pageIndex: this._matPaginator.pipe(
        takeUntil(this.onDestroy),
        filter(Boolean),
        switchMap(paginator => paginator.page),
        map(x => x.pageIndex),
        distinctUntilChanged()
      )
    })
    .pipe(takeUntil(this.onDestroy))
    .subscribe(({ did, pageIndex }) => {
      sessionStorage.setItem(this.pageIndexKey(did), pageIndex.toString());
    });



    // loading scroll position
    combineLatest({
      did:            this._did           .pipe(filter(Boolean)),
      tableContainer: this._tableContainer.pipe(filter(Boolean)),
      finishedLoaded: this._dataSource.pipe(
        filter(Boolean),
        switchMap(dataSource => dataSource.onIsLoading),
        filter(loaded => ! loaded),
      )
    })
    .pipe(
      takeUntil(this.onDestroy),
      take(1)
    )
    .subscribe(({ did, tableContainer }) => {
      const raw = sessionStorage.getItem(this.scrollKey(did));
      if ( ! raw) return;

      const { top, left } = JSON.parse(raw);

      // await table rendering
      setTimeout(() => {
        if (top)  $(tableContainer.nativeElement).scrollTop(top);
        if (left) $(tableContainer.nativeElement).scrollLeft(left);
      }, 0);
    });
    // storing scroll position
    combineLatest({
      did:          this._did           .pipe(filter(Boolean)),
      scrollTraget: this._tableContainer.pipe(
        filter(Boolean),
        switchMap(x => fromEvent<Event>(x.nativeElement, 'scroll')),
        map(x => x.target),
        filter(Boolean),
        debounceTime(100),
      ),
    })
    .pipe(takeUntil(this.onDestroy))
    .subscribe(({ did, scrollTraget }) => {
      const elem = $(scrollTraget);
      const top  = elem.scrollTop();
      const left = elem.scrollLeft();
      sessionStorage.setItem(this.scrollKey(did), JSON.stringify({ top, left }));
    });



    // load sort
    combineLatest({
      did:  this._did    .pipe(filter(Boolean)),
      sort: this._matSort.pipe(filter(Boolean))
    })
    .pipe(
      takeUntil(this.onDestroy),
      take(1)
    )
    .subscribe(({ did, sort }) => {
      const raw = sessionStorage.getItem(this.sortKey(did));
      if ( ! raw) return;

      const { active, direction } = JSON.parse(raw);

      // direction '' means no sort
      direction && sort.sort({ id: active, start: direction } as MatSortable);
    });
    // store sort
    combineLatest({
      did:        this._did.pipe(filter(Boolean)),
      sortString: this._matSort
      .pipe(
        filter(Boolean),
        switchMap(sort => sort.sortChange),
        map(x => JSON.stringify(_.pick(x, 'active', 'direction'))),
        distinctUntilChanged()
      )
    })
    .pipe(takeUntil(this.onDestroy))
    .subscribe(({ did, sortString }) => {
      sessionStorage.setItem(this.sortKey(did), sortString)
    });
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }


  ////
  //// IO
  ////
  set searchComponent (val: SearchComponent | undefined) { val && this._searchComponent.next(val); }
  private readonly _searchComponent = new BehaviorSubject<SearchComponent | null>(null);

  set matPaginator (val: MatPaginator | undefined) { val && this._matPaginator.next(val); }
  private readonly _matPaginator = new BehaviorSubject<MatPaginator | null>(null);

  set matSort (val: MatSort | undefined) { val && this._matSort.next(val); }
  private readonly _matSort = new BehaviorSubject<MatSort | null>(null);

  set tableContainer (val: ElementRef | undefined) { val && this._tableContainer.next(val); }
  private readonly _tableContainer = new BehaviorSubject<ElementRef | null>(null);

  set did (val: string | null) { val && this._did.next(val); }
  private readonly _did = new BehaviorSubject<string | null>(null);

  set dataSource (val: DataSourceService | undefined) { val && this._dataSource.next(val); }
  private readonly _dataSource = new BehaviorSubject<DataSourceService | null>(null);
}