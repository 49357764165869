import { NgModule                        } from '@angular/core';
import { CommonModule                    } from '@angular/common';
import { DisplayValueComponent           } from './display-value/display-value.component';


@NgModule({
  declarations: [
    DisplayValueComponent
  ],
  exports: [
    DisplayValueComponent
  ],
  imports: [
    CommonModule
  ]
})
export class ComponentsModule { }
