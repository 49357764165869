<!-- force rerender on stateChanges.next() otherwise it is not guaranteed to show the latest values due to some lazy rendering-->
{{ stateChanges | async }}

<div class="break-length-select-label clickable"
     (menuClosed)="closed()"
     (menuOpened)="opened()"
     [matMenuTriggerFor]="menu">
     <app-form-field-break-length-display-value [value]="value"
                                                [voidText]="voidText"
                                                [inherit]="inherit">
     </app-form-field-break-length-display-value>
</div>

<mat-menu yPosition="below"
          #menu="matMenu"
          class="break-length-panel no-vertical-padding"
          [overlapTrigger]="true">

  <div class="toolbar" (click)="$event.stopPropagation()">

    <div class="row">
      <button mat-stroked-button
              class="action reset"
              color="primary"
              (click)="resetValue()"
              *ngIf="! pristine && reset && valid">
        <div>
          <span>{{ 'common.restore' | translate }}</span>
          <mat-icon class="royal-icon">undo</mat-icon>
        </div>
      </button>
    </div>

  </div>

  <div class="content" *ngIf=" ! isVoid && form" [formGroup]="form" (click)="$event.stopPropagation()">


    <mat-radio-group class="radio-group" formControlName="type">
      <mat-radio-button value="default" class="radio-button">
        {{ setVoidText || ('common.no_value' | translate) }}
      </mat-radio-button>
      <mat-radio-button value="none" class="radio-button" *ngIf="! hideOptionNone">
        {{ 'attributes.shared.minBreakLength.none' | translate }}
      </mat-radio-button>
      <mat-radio-button value="symmetrical" class="radio-button">
        {{ 'attributes.shared.minBreakLength.symmetrical' | translate }}
      </mat-radio-button>
      <mat-radio-button value="asymmetrical" class="radio-button" *ngIf="! hideOptionAsymmetrical">
        {{ 'attributes.shared.minBreakLength.asymmetrical' | translate }}
      </mat-radio-button>
    </mat-radio-group>

    <div [ngSwitch]="form.value.type" class="container">

      <ng-container *ngSwitchCase="'symmetrical'">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'attributes.shared.duration' | translate }}</mat-label>
          <app-form-field-minutes formControlName="total"></app-form-field-minutes>
          <span matTextSuffix class="form-field-suffix">{{ 'common.minutes' | translate | lowercase }}</span>
          <mat-error><app-form-field-error [control]="form.controls.total"></app-form-field-error></mat-error>
        </mat-form-field>
      </ng-container>

      <ng-container *ngSwitchCase="'asymmetrical'">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'attributes.shared.minBreakLength.asymmetrical.before' | translate }}</mat-label>
          <app-form-field-minutes formControlName="before"></app-form-field-minutes>
          <span matTextSuffix class="form-field-suffix">{{ 'common.minutes' | translate }}</span>
          <mat-error><app-form-field-error [control]="form.controls.before"></app-form-field-error></mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{ 'attributes.shared.minBreakLength.asymmetrical.after' | translate }}</mat-label>
          <app-form-field-minutes formControlName="after"></app-form-field-minutes>
          <span matTextSuffix class="form-field-suffix">{{ 'common.minutes' | translate }}</span>
          <mat-error><app-form-field-error [control]="form.controls.after"></app-form-field-error></mat-error>
        </mat-form-field>
      </ng-container>

    </div>
  </div>

  <mat-divider class="divider" *ngIf="! disableActions" (click)="$event.stopPropagation()"></mat-divider>

  <div class="actions" *ngIf="! disableActions" (click)="$event.stopPropagation()"  >
    <button mat-stroked-button
            color="primary"
            type="button"
            class="button"
            (click)="trigger?.closeMenu()">
      {{ 'common.cancel' | translate }}
    </button>
    <button mat-flat-button
            color="primary"
            type="button"
            [disabled]="! valid"
            (click)="submit(); trigger?.closeMenu()"
            class="button right">
      {{ 'common.save' | translate }}
    </button>
  </div>

</mat-menu>


