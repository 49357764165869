import { NgZone                          } from '@angular/core';
import { BehaviorSubject,
         combineLatest,
         debounceTime,
         delay,
         distinctUntilChanged,
         map,
         NEVER,
         Observable,
         shareReplay,
         startWith,
         Subject,
         switchMap,
         takeUntil                       } from 'rxjs';
import _                                   from 'lodash';

import { LoggerService                   } from 'app/core';
import { Main2WorkerMsg,
         State,
         Worker2MainMsg                  } from './types';
import { tabHeartbeatInterval            } from '../constants';


/**
 * @description
 * A wrapper around the SharedWorker API to hide certain details such as an internal id which is used to identify the tab in the shared worker.
 */
export class SharedWorkerWrapper {
  // the id is used to identify the tab in the shared worker, making it possible to deregister it when the tab is closed
  private id$ = new BehaviorSubject<string>('');

  private worker?: SharedWorker;
  private readonly state$            = new Subject<State>();
  private readonly timer$            = new Subject<void>();
  private readonly renewActiveState$ = new Subject<void>();

  constructor (
                     isAuthenticated$: Observable<boolean>,
                     isActive$:        Observable<boolean>,
    private readonly onDestroy:        Subject<void>,
    private readonly logger:           LoggerService,
    private readonly ngZone:           NgZone
  ) {
    // run outside of angular to prevent change detection
    // (IS THIS REALLY NEEDED A SECOND TIME!?)
    this.ngZone.runOutsideAngular(() => {

      // ensure that SharedWorker is supported
      // (do not throw error as this will prevent the app from working)
      if (typeof SharedWorker == 'undefined') {
        this.logger.error('SharedWorker is not supported');
        return;
      }

      // register and start the shared worker
      this.worker = new SharedWorker(new URL('./shared.worker', import.meta.url), { type: 'module', name: 'Royal_Schedule_User_Inactivity' });
      this.worker.port.start();

      // listen for messages and errors from the shared worker
      this.worker.port.addEventListener('message', (event: MessageEvent<Worker2MainMsg>) => {
        const data = event.data;
        // console.log('> message', data);

        if (data.type === 'registered') {
          this.id$.next(data.id);
        }
        else if (data.type === 'deregistered') {
          this.id$.next('');
        }
        else if (data.type === 'renewActiveStateConfirmation') {
          this.renewActiveState$.next();
        }
        else if (data.type === 'stateChange') {
          this.state$.next(data.state);
        }
        else if (data.type === 'timerComplete') {
          this.timer$.next();
        }

      });
      this.worker.port.addEventListener('error', (err) => {
        this.logger.error(err);
      });


      // notify worker of authentication state
      isAuthenticated$
      .pipe(takeUntil(this.onDestroy))
      .subscribe(x => void this.worker?.port.postMessage({ type: 'authChange', authenticated: x } satisfies Main2WorkerMsg));

      // periodically notify the shared worker of the tab's active state, i.e., a heartbeat
      // (as background tabs struggles with rxjs's interval(...) we use a recursive approach)
      combineLatest({
        id:     this.id$,
        active: isActive$
      })
      .pipe(
        takeUntil(this.onDestroy),
        distinctUntilChanged((a, b) => _.isEqual(a, b)),
        switchMap(x => (x.id && x.active) ? this.renewActiveState$.pipe(delay(tabHeartbeatInterval), startWith(null), map(() => x.id)) : NEVER)
      )
      .subscribe(id => {
        this.worker?.port.postMessage({ type: 'renewActiveState', id } satisfies Main2WorkerMsg);
      });


      // cleanup
      this.onDestroy.subscribe(() => {
        this.deregister()
        this.worker?.removeAllListeners?.();
      });

    });
  }

  public register (): Observable<State> {
    // ensure that we do not register twice without first deregistering
    if (this.id$.value) throw new Error('Already registered');

    this.worker?.port.postMessage({ type: 'register' } satisfies Main2WorkerMsg);

    return this.state$.pipe(
      distinctUntilChanged((a, b) => _.isEqual(a, b)),
      takeUntil(this.onDestroy),
      shareReplay(1)
    );
  }

  public deregister () {
    if ( ! this.id$.value) return;
    this.worker?.port.postMessage({ type: 'deregister', id: this.id$.value } satisfies Main2WorkerMsg);
  }

  /**
   * @description
   * Similar to rxjs's "debounceTime" operator apart from it uses the shared worker to keep track of the time.
   * This makes it resilient to background tabs, computer hibernations etc. which might pause the timer. It seems shared workers are not throttled in the same way as background tabs.
   * (see https://developer.mozilla.org/en-US/docs/Web/API/setTimeout#reasons_for_delays_longer_than_specified)
   * NOTE: The internal timer is shared, hence it should only be used once per SharedWorkerWrapper instance.
   */
  public resilientDebounceTime (time: number) {
    const worker = this.worker;
    if (worker) {
      return switchMap(x => {
        worker.port.postMessage({ type: 'setTimer', delay: time } satisfies Main2WorkerMsg);
        return this.timer$.pipe(map(() => x))
      });
    } else {
      this.logger.error('(SharedWorkerWrapper::resilientDebounceTime) worker not available, falling back to normal debounceTime');
      return debounceTime(time);
    }
  }

  public isSupported () {
    return this.worker ? true : false;
  }


}