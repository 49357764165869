import { Pipe,
         PipeTransform                   } from '@angular/core';

import { Util                            } from '@app/common';
import { EnvironmentService              } from '@app/core';
import { IntegrationType                 } from '@app/core/environment/environment.constants';
import { Validated                       } from './components/validator/validator.types'

@Pipe({
  name: 'syncError'
})
export class SyncErrorPipe implements PipeTransform {

  transform(value: unknown): string {
    switch (value) {
      case 0:
      case 1:
      case 3:
      case 4:
        return `shared.dialogs.sync.error.${ value }`;
      case 2:
        return 'shared.dialogs.sync.validation.title';
      default:
        return 'common.generic_error';
    }
  }
}

@Pipe({
  name: 'filterCollections',
  standalone: true
})
export class FilterCollectionsPipe implements PipeTransform {
  constructor(private _environment: EnvironmentService) {}

  transform(collections: (keyof Validated)[], partner: IntegrationType | undefined): (keyof Validated)[] {
    if (! partner ||  ! this._environment.isIntegration(partner))
      return collections;

    const integration = this._environment.getIntegration(partner);
    if (integration?.collections)
      return collections.filter(collection => integration?.collections?.includes(collection));
    return collections;
  }
}