import { Optional,
         Injectable                      } from '@angular/core';

import { LoggerService                   } from 'app/core/logger/logger.service';

import { DatabaseCore                    } from './database.core';

@Injectable()
export class DatabaseService extends DatabaseCore {

  constructor(@Optional() protected _logger?: LoggerService) {
    super(_logger);
  }
}