import { NgModule                        } from '@angular/core';
import { CommonModule                    } from '@angular/common';

import { PipesModule                     } from 'app/shared/pipes/pipes.module';
import { AppCommonModule                 } from '@app/common/common.module';

import { DisplayValueComponent           } from './display-value/display-value.component';

@NgModule({
  declarations: [
    DisplayValueComponent
  ],
  exports: [
    DisplayValueComponent
  ],
  imports: [
    CommonModule,
    PipesModule,
    AppCommonModule
  ]
})
export class ComponentsModule { }
