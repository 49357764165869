<div class="container" *ngIf="state" [ngStyle]="hasActions ? {bottom: 0} : {}">

  <div class="creating-text white" *ngIf="state == 1">
    <h3>{{ 'common.creating' | translate }}
      <div class="saving dot"><div class="dot">.</div><div class="dot">.</div><div class="dot">.</div></div>
    </h3>
  </div>

  <div class="created-text white" *ngIf="state == 2">
    <h3>{{ 'common.creation_success' | translate }}</h3>
  </div>

  <div class="progress-content" *ngIf="state == 3">
    <mat-spinner class="spinner"
                          diameter="104"
                          strokeWidth="2"></mat-spinner>
      <img src="assets/rs-symbol-white.svg" class="save-icon">
  </div>

  <div class="created-text white" *ngIf="state == 4">
    <h3>{{ 'common.saved' | translate }}</h3>
  </div>

  <div class="created-text white" *ngIf="state == 5">
    <h3>{{ 'common.creation_fail' | translate }}</h3>
  </div>

  <div class="created-text white" *ngIf="state == 6">
    <h3>{{ 'common.generic_error' | translate }}</h3>
  </div>

  <div class="created-text white" *ngIf="state == 7">
    <h3>{{ 'common.updated' | translate }}</h3>
  </div>

  <div class="progress-content" *ngIf="state == -1">
    <mat-spinner class="spinner"
                          diameter="104"
                          strokeWidth="2"></mat-spinner>
      <img src="assets/rs-symbol-white.svg" class="save-icon">
  </div>

  <div [ngClass]="state > 0 ? 'reveal-circular' : ''"></div>

  <div [ngClass]="state < 0 ? 'reveal' : ''"></div>

</div>
