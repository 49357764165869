import { NgModule                             } from '@angular/core';
import { CommonModule                         } from '@angular/common';
import { FormsModule, ReactiveFormsModule     } from '@angular/forms';
import { RouterModule                         } from '@angular/router';
import { NgxCleaveDirectiveModule             } from 'ngx-cleave-directive';

import { AppCommonModule                      } from 'app/common/common.module';
import { TranslationModule                    } from 'app/core/translate/translate.module';
import { FormsModule as MyFormsModule         } from 'app/shared/forms/forms.module';
import { FormFieldsModule                     } from 'app/shared/form-fields/form-fields.module';
import { ComponentsModule                     } from 'app/shared/components/components.module';
import { PipesModule                          } from 'app/shared/pipes/pipes.module';
import { TablesModule                         } from 'app/shared/tables/tables.module';

import { DivisionsModule                      } from './divisions/divisions.module';
import { UploadFileModule                     } from './upload-file/upload-file.module';
import { ParallelCoursesModule                } from './parallel-courses/parallel-courses.module';
import { SyncModule                           } from './sync/sync.module';
import { CompanyComponent                     } from './company/company.component';
import { TermsDialogComponent                 } from './terms-dialog/terms-dialog.component';
import { ConfirmRemoveComponent               } from './confirm-remove/confirm-remove.component';
import { EditCourseAndEventComponent          } from './edit-course-and-event/edit-course-and-event.component';
import { ChangePasswordComponent              } from './change-password/change-password.component';
import { EditScheduleDataComponent            } from './edit-schedule-data/edit-schedule-data.component';
import { LoginComponent                       } from './login/login.component';
import { ConfirmActionComponent               } from './confirm-action/confirm-action.component';
import { UnsavedChangesComponent              } from './unsaved-changes/unsaved-changes.component';
import { AddDurationSetComponent              } from './add-duration-set/add-duration-set.component';
import { VersionModule                        } from './version/version.module';
import { UnrecoverableAppStateModule          } from './unrecoverable-app-state/unrecoverable-app-state.module';
import { InputIssuesModule                    } from './input-issues/input-issues.module';
import { GenerateScheduleModule               } from './generate-schedule/generate-schedule.module';
import { DuplicateScheduleModule              } from './duplicate-schedule/duplicate-schedule.module';

@NgModule({
  declarations: [
    CompanyComponent,
    TermsDialogComponent,
    ConfirmRemoveComponent,
    EditCourseAndEventComponent,
    ChangePasswordComponent,
    EditScheduleDataComponent,
    LoginComponent,
    ConfirmActionComponent,
    UnsavedChangesComponent,
    AddDurationSetComponent
  ],
  exports: [
    CompanyComponent,
    TermsDialogComponent,
    ConfirmRemoveComponent,
    EditCourseAndEventComponent,
    ChangePasswordComponent,
    EditScheduleDataComponent,
    DivisionsModule,
    ParallelCoursesModule,
    UploadFileModule,
    InputIssuesModule,
    AddDurationSetComponent,
    VersionModule,
    UnrecoverableAppStateModule,
    GenerateScheduleModule,
    DuplicateScheduleModule
  ],
  imports: [
    CommonModule,
    MyFormsModule,
    FormsModule,
    ReactiveFormsModule,
    SyncModule,
    AppCommonModule,
    TranslationModule,
    TablesModule,
    ComponentsModule,
    FormFieldsModule,
    PipesModule,
    RouterModule,
    NgxCleaveDirectiveModule
  ]
})
export class DialogsModule { }
