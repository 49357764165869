import { SystemCore                      } from '../system-core';

import { wrap                            } from 'comlink';
import { interval                        } from 'rxjs';
import moment                              from 'moment';

import { ReverseProxy                    } from './reverse-proxy';

import { SourceCore                      } from '@core/source/source.core';

/*
                    ______________________                          |                        ______________________
                    |                     |                         |                        |                     |
                    |  _________________  |                         |                        |  _________________  |
                    | |    Coalesced    | |                         |                        | |    Coalesced    | |
                    | |      Core       | |     ./core              |                        | |      Core       | |     ./core
                    | |_________________| |                         |                        | |_________________| |
                    |          |          |                         |                        |          |          |
                    | _________|_________ |                         |                        | _________|_________ |
                    | |                 | |                         |                        | |                 | |
                    | |      Proxy      | |                         |                        | |      Proxy      | |
                    | |_________________| |                         |                        | |_________________| |
                    |     Shared Worker   |                         |                        |     Shared Worker   |
                    |_____________________|                     disconnect                   |_____________________|
                      |                |                          port N                       |                |
               port 1 |                | port N                   ---->                 port 1 |                x port N
                      |                |                            |                          |                |
              ________|                |_________                   |                  ________|                |_________
             |  |  |  |                |  |  |  |                   |                 |  |  |  |                |  |  |  |
   Observers |  |  |  |                |  |  |  | Observers 0-n     |       Observers |  |  |  |                x  x  x  x Observers 0-n
     ________|__|__|__|                |__|__|__|_______            |         ________|__|__|__|                |__|__|__|_______
     |   [origin 1]   |                |  [origin N]    |           |         |   [origin 1]   |                |  [origin N]    |
     |  ___________   |                |  ___________   |           |         |  ___________   |                |  ___________   |
     |  |          |  |                |  |          |  |           |         |  |          |  |                |  |          |  |
     |  |  reverse |  |      ...       |  |  reverse |  |           |         |  |  reverse |  |      ...       |  |  reverse |  |
     |  |   proxy  |  |                |  |   proxy  |  |           |         |  |   proxy  |  |                |  |   proxy  |  |
     |  |__________|  |                |  |__________|  |           |         |  |__________|  |                |  |__________|  |
     |  ______|____   |                |  ______|____   |           |         |  ______|____   |                |  ______|____   |
     |  |          |  |                |  |          |  |           |         |  |          |  |                |  |          |  |
     |  |  source  |  |      ...       |  |  source  |  |           |         |  |  source  |  |      ...       |  |  source  |  |
     |  |__________|  |                |  |__________|  |           |         |  |__________|  |                |  |__________|  |
     |________________|                |________________|           |         |________________|                |________________|

    Coalesced core store all subscriptions each port have. On Port disconnect
      all subscription of that port will be completed.

    Each reverse proxy have an identifier that is sent in each subscribe.

*/

export type SystemState = 'Legacy' | 'Coalesced';

export function newCoalescedCore(this: SourceCore): ReverseProxy {
  const worker = new SharedWorker(new URL('./core.worker', import.meta.url), { type: 'module', name: 'Royal_Schedule_Source_Worker' });
  const _obj = wrap(worker.port)
  const obj = new ReverseProxy(_obj);

  worker.port.postMessage({ command: { authenticate: this._auth.getToken() } });
  this._auth.onIsAuthenticated
  //.pipe(takeUntil(this.ondestroy))
  .subscribe((authenticated: boolean) => {
    worker.port.postMessage({ command: { authenticate: this._auth.getToken() } });
  });
  /*
    if the computer goes to sleep the subscription will not emit the event
      and therefor a interval must check if expiration date is passed and subsequently
      emit the event. In the normal event two commands will be emited.
      One cannot time the expiration date since this might be passed during sleep.
  */
  interval(1000)
  .subscribe(() => {
    const expiresAt: moment.Moment = moment(this._auth.getTokenExpirationTime());
    if (expiresAt.isBefore(moment()))
      worker.port.postMessage({ command: { authenticate: this._auth.getToken() } });
  });

  addEventListener('beforeunload', () => {
    worker.port.postMessage({ command: { disconnect: { identifier: obj.identifier } } });
    //worker.removeAllListeners()
  });

  return obj;
}

export function _getSystemType(this: SourceCore, state?: SystemState): SystemCore {
  switch (state) {
    default:
    case 'Coalesced':
      this.state = 'Coalesced';
      return newCoalescedCore.bind(this)();
  }
}