import { Collection            } from '../../types';


const BASE = 'TABLE_STATE'
export const storageKeys: Record<Collection, string> = {
  groups:    `${BASE}/GROUPS`,
  teachers:  `${BASE}/TEACHERS`,
  locations: `${BASE}/LOCATIONS`,
  persons:   `${BASE}/PERSONS`,
  courses:   `${BASE}/COURSES`,
  events:    `${BASE}/EVENTS`,
};