import { NgModule                                } from '@angular/core';
import { CommonModule                            } from '@angular/common';
import { FormsModule,
         ReactiveFormsModule                     } from '@angular/forms';
import { ScrollingModule                         } from '@angular/cdk/scrolling';

import { AppCommonModule                         } from 'app/common/common.module';
import { PipesModule                             } from 'app/shared/pipes/pipes.module';
import { ComponentsModule as AppComponentsModule } from 'app/shared/components/components.module';
import { TranslationModule                       } from 'app/core/translate/translate.module';

import { AddEditComponent                        } from './add-edit/add-edit.component';

@NgModule({
  declarations: [
    AddEditComponent
  ],
  exports: [
    AddEditComponent
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    PipesModule,
    AppComponentsModule,
    TranslationModule,
    FormsModule,
    ReactiveFormsModule,
    ScrollingModule
  ]
})
export class ComponentsModule { }
