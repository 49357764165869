import { Component,
         ViewChild,
         AfterViewInit,
         ViewContainerRef                } from '@angular/core';
import { Observable                      } from 'rxjs';
import { debounceTime,
         map,
         filter,
         takeUntil                       } from 'rxjs/operators';
import _                                   from 'lodash';

import { DataSourceService,
         DialogsService                  } from 'app/shared/services';
import { DivisionSettings,
         PartialTags,
         Populated                       } from 'app/shared/interfaces';
import { EnvironmentService,
         sourceHasPipe,
         sourceSelectPipe,
         SourceService,
         UserPreferencesService          } from 'app/core';
import { PersonsComponent as Form        } from 'app/shared/forms/persons/persons.component';
import { SelectionService                } from 'app/shared/services/selection/selection.service';
import { MatDialog                       } from '@app/common';
import { Collection                      } from '../types';
import { COLLECTION                      } from '../constants';
import { TableCore                       } from '../table-core';
import { TableColumnsService             } from '../services/table-columns/table-columns.service';
import { CustomSearchService             } from '../services/custom-search/custom-search.service';
import { StateService                    } from '../services/state/state.service';


const collection = 'persons' satisfies Collection;

@Component({
  selector: 'app-persons-table',
  templateUrl: './persons.component.html',
  styleUrls: ['./persons.component.scss'],
  providers: [
    DataSourceService,
    SelectionService,
    SourceService,
    { provide: COLLECTION, useValue: collection },
    TableColumnsService,
    CustomSearchService,
    StateService,
  ]
})
export class PersonsComponent extends TableCore<typeof collection, Populated.person>
                              implements AfterViewInit {

  @ViewChild(Form)
  protected form?: Form;

  protected settings:  Observable<DivisionSettings>;
  protected groups:    Observable<Populated.group[]>;
  protected locations: Observable<Populated.location[]>;

  // bulk edit values
  protected groupsBulkValue: null | undefined | NonNullable<Populated.person['group']>;
  protected sexBulkValue:    null | undefined | NonNullable<Populated.person['sex']>;
  protected tagsBulkValue:   null | undefined | PartialTags;

  constructor (
    protected dataSource:       DataSourceService<Populated.person>,
    protected selection:        SelectionService<Populated.person>,
    protected preferences:      UserPreferencesService,
    protected environment:      EnvironmentService,
    protected source:           SourceService,
    protected dialog:           DialogsService,
    protected matDialog:        MatDialog,
    protected viewContainerRef: ViewContainerRef,
    protected state:            StateService       <typeof collection>,
    protected tableColumns:     TableColumnsService<typeof collection>,
    protected customSearch:     CustomSearchService<typeof collection>,
  ) {
    super(collection, viewContainerRef, dataSource, selection, preferences, source, dialog, matDialog, state, tableColumns, customSearch);
  }

  ngAfterViewInit () {
    super.ngAfterViewInit();

    this.afterSourceGroupBy(
      ['persons', 'groups', 'settings'],
      [],
      this.source.getPopulatedStudents
    )
    .then(() => {
      this.dataSource.init({ source: this.data });
      this.selection.dataSource = this.dataSource;

      this.settings  = this.source.getStrictSettings    ({ did: this.did, onDestroy: this.onDestroy });
      // this.groups    = this.source.getPopulatedGroups   ({ did: this.did }, sourceHasPipe({ species: true }));
      this.locations = this.source.getPopulatedLocations({ did: this.did, onDestroy: this.onDestroy });

      this.subscribeToSelection();
    })
  }

  // subscribes to selection events
  private subscribeToSelection () {
    this.source
    .getPopulatedStudents({ did: this.did, skipNoFilter: true, onDestroy: this.onDestroy },
      sourceSelectPipe(
        this.selection.onSelection().pipe(map(x => ({ 'id': x.map(y => y.id) })))
      ))
    .pipe(
      takeUntil(this.onDestroy),
      filter(Boolean),
      debounceTime(200)
    )
    .subscribe(xs => {
      this.groupsBulkValue = TableCore.isSame(xs.map(x => x.group ?? null));
      this.sexBulkValue    = TableCore.isSame(xs.map(x => x.sex   ?? null));
      this.tagsBulkValue   = TableCore.getSelectedTags(xs);
    });
  }
}
