<ng-template #abort>
  <div class="container">
    <p>Unable to give further information about the error at this time.</p>
  </div>
</ng-template>

<div class="container" *ngIf=" ! inputAnalysis.criticalErrorEncountered; else abort">

  <div class="row">

    <app-input-analyzer-event-density-calendar class="calendar"
                                               [frame]="frame"
                                               [numDays]="numDays"
                                               [defaultTimeFrame]="onGroupTimeFrame | async"
                                               [defaultIntervals]="onGroupIntervals | async"
                                               (focused)="setFocusedInterval($event)"
                                               [focusedEvent]="focusedEvent">
    </app-input-analyzer-event-density-calendar>

    <div class="content dark-vertical-scrollbar">

      <div *ngIf="description" [innerHTML]="description"></div>

      <div class="interval-info" *ngIf="focusedInterval">
        <b style="display: inline-block; padding-right: 6px;">{{ 'inputAnalysis.modules.overloaded group.contributing events' | translate }}</b>
        <p style="display: inline-block">{{ focusedInterval.day }} {{ focusedInterval.beg }} - {{ focusedInterval.end }}</p>

        <mat-chip-set>
          <mat-chip *ngFor="let x of focusedInterval.events"
                    (mouseenter)="setFocusedEvent(x.id)"
                    (mouseleave)="setFocusedEvent()"
                    disableRipple
                    [style.background]="x.event | eventColor:'20'">

            <span *ngIf="isEvent(x.event); else lockedTime">
              <mat-icon inline *ngIf="x.event.fixedStart">push_pin</mat-icon>
              {{ x.event | displayName }} - {{ x.event.preferredDuration }} min ({{ x.mass * dt / x.event.preferredDuration * 100 | round }}%)
            </span>
            <ng-template #lockedTime>
              <span *ngIf="isLockedTime(x.event)">
                {{ x.event | displayName }} - {{ x.event.duration }} min ({{ x.mass * dt / (x.event.duration ?? 0) * 100 | round }}%)
              </span>
            </ng-template>
          </mat-chip>
        </mat-chip-set>

        <div *ngIf=" ! focusedInterval.events.length"
             class="no-events">
          {{ 'common.none_plural' | translate }}
        </div>
      </div>

      <div class="legend">

        <div class="fixed-event">
          <div class="titles">
            <span>{{ 'inputAnalysis.modules.overloaded group.legend.fixed' | translate }}</span>
          </div>
          <div class="bar">
          </div>
        </div>

        <div class="cbar">
          <div class="titles">
            <span>{{ 'inputAnalysis.modules.overloaded group.legend.good' | translate }}</span>
            <span>{{ 'inputAnalysis.modules.overloaded group.legend.bad' | translate }}</span>
          </div>
          <div class="bar">
            <div *ngFor="let c of cmap_cold" [style.background]="c"></div>
            <div *ngFor="let c of cmap_warm" [style.background]="c"></div>
          </div>
          <div class="description">
            <span>{{ 'inputAnalysis.modules.overloaded group.legend.sparse' | translate }}</span>
            <span>{{ 'inputAnalysis.modules.overloaded group.legend.compact' | translate }}</span>
            <span>{{ 'inputAnalysis.modules.overloaded group.legend.conflict' | translate }}</span>
          </div>
        </div>

      </div>

      <div [innerHTML]="'inputAnalysis.modules.overloaded group.description' | translate | paragraphs:'description'"></div>

    </div>

  </div>

</div>



