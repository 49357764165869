import { race,
         interval                        } from 'rxjs';
import { tap,
         map,
         finalize,
         takeUntil                       } from 'rxjs/operators';

import { ThreadService                   } from './thread.service';

const _intervalTime: number  = 3000;
let singleton:       boolean = false;


/**
 * Method that start a worker that emit a ping every n:th millisecond.
 * Only the master thread will emit a ping
 * @protected
 * @param void
 */
export function _emitPing(this: ThreadService): void {
  interval(_intervalTime)
  .pipe(
    tap(() => {
      //this._logger.debug(`(Thread::Ping) This thread emitted ping`);
      this._broadcast.emit(`thread_ping`, { id: this._threadId.value });
    }),
    finalize(() => {
      this._logger.warn(`(Thread::Ping) worker that emit ping was finalized`);
    })
  )
  .subscribe();
}

/**
 * Method that start a worker that receive ping.
 * @protected
 * @param void
 */
export function _receivePing(this: ThreadService): void {
  if (singleton) return;

  singleton = true;

  race(
    this._broadcast.subscribeToChannel('thread_ping')
      .pipe(map(() => false)),
    interval(2 * _intervalTime)
      .pipe(map(() => true))
  )
  .pipe(takeUntil(this._onMaster))
  .subscribe((val: boolean) => {
    if (val) {
      this._logger.debug(`(Thread::Ping) Thread did not receive ping from master`);
      this.checkIfMasterExist();
    }
  });
}