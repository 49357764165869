import _                                   from 'lodash';
import moment                              from 'moment';
import { nanoid                          } from 'nanoid';
import { map,
         Observable,
         combineLatestWith               } from 'rxjs';

import { fromStringRepresentation,
         mapNestedProperty,
         setNestedProperty,
         filterNestedProperty,
         unsetNestedProperty             } from './nested-object';

import { Populated as P                  } from 'app/shared/interfaces';
import { OutQueryStructure               } from '@app/shared/services/query/query.service';
import { HttpErrorResponse               } from '@angular/common/http';

export namespace Util {
  export namespace Types {

    export type Nullable<T> = { [P in keyof T]: T[P] | null };

    export type PartialNullable<T> = Partial<Nullable<T> >;

    export type Mutable<Type>     = { -readonly [Key in keyof Type]: Type[Key]; };
    export type DeepMutable<Type> = { -readonly [Key in keyof Type]: DeepMutable<Type[Key]>; };

    export type Split <T extends Object> = { [K in keyof T]: { [K in keyof T]: T[K] } }[keyof T];

    export type Entry <T extends Object> = NonNullable<{ [K in keyof T]: [K, NonNullable<T[K]>] }[keyof T]>;
    export type KeyVal<T extends Object> = NonNullable<{ [K in keyof T]: { key: K, val: NonNullable<T[K]> } }[keyof T]>;
    export type Value <T extends Object> = T[keyof T]

    export type GetObservableType<C extends Observable<any>> = C extends Observable<infer T> ? T : unknown;
    export type Observed<C extends Observable<any>> = C extends Observable<infer T> ? T : unknown;

    export type UnionToIntersection<U> =  (U extends any ? (k: U) => void : never) extends ((k: infer I) => void) ? I : never

    export type KeysOfUnion<T> = T extends T ? keyof T: never;

    export type DeepPartial<T> = T extends object ? { [P in keyof T]?: DeepPartial<T[P]> } : T;

    export type DeepPartialWithId<T> = T extends object ? { [P in keyof T]?: P extends 'id' ? DeepPartial<T[P]>: T[P] } : T;

    /*export type NestedKeyOf<ObjectType extends object | object[]> = {
        //[Key in keyof ObjectType & (string)]: ObjectType[Key] extends object | object[] ? `${Key}` | `${Key}.${NestedKeyOf<ObjectType[Key]>}` : `${Key}`
        [Key in keyof ObjectType & (string | number)]: ObjectType[Key] extends object | object[] ? `${Key}` | `${Key}.${Extract<keyof ObjectType[Key], string>}` : `${Key}`
      }[keyof ObjectType & (string | number)];*/
    type getKeys<ObjectType extends object | object[]> = {
      [Key in keyof ObjectType & (string | number)]: `${Key}`
    }[keyof ObjectType & (string | number)];

    export type NestedKeyOf<ObjectType extends object | object[]> = {
      [Key in keyof ObjectType & (string | number)]:
        ObjectType[Key] extends object[] ?
          `${Key}` | `${Key}.${getKeys<ObjectType[Key][0]>}` :
          ObjectType[Key] extends object ?
            `${Key}` | `${Key}.${getKeys<ObjectType[Key]>}` :
            `${Key}`
      }[keyof ObjectType & (string | number)];

    export type Join<K, P> = K extends string ? P extends string ? `${ K }.${ P }` : never : never;

    export type firstChild<ObjectType extends object> = {
      [Key in keyof ObjectType & (string | number)]:
        ObjectType[Key] extends object ? ObjectType[Key] :
          (ObjectType[Key] extends any ? ObjectType[Key] : never)
    }[keyof ObjectType & (string | number)];

    export type Collection = 'exceptions' |
                             'groups'  |
                             'teachers' |
                             'locations' |
                             'courses' |
                             'events' |
                             'generations' |
                             'settings' |
                             'schedules' |
                             'lockedTimes' |
                             'overlapGroups' |
                             'periods' |
                             'rootIntervals' |
                             'persons' |
                             'divisions';


    export type PlannedDurationUnit = 'hrs' | 'min/week';
  }

  export namespace Constants {
    export const COLLECTIONS: Readonly<Types.Collection[]> = Object.freeze([
      'exceptions',
      'calendarEvents' as any,
      'groups',
      'teachers',
      'locations',
      'persons',
      'courses',
      'events',
      'generations',
      'settings',
      'schedules',
      'lockedTimes',
      'overlapGroups',
      'periods',
      'rootIntervals',
      'divisions'
    ]);
  }

  export namespace functions {
    export const get    = fromStringRepresentation;
    export const set    = setNestedProperty;
    export const map    = mapNestedProperty;
    export const filter = filterNestedProperty;
    export const unset  = unsetNestedProperty;

    export const getSourceDataKeyPath
      = function(key: keyof OutQueryStructure) {
        switch (key) {
          case 'events':    return [`id`];
          case 'courses':   return [`course.id`];
          case 'locations': return [`inLocations.id`];
          case 'persons':   return [`${ key }.id`];
          case 'teachers':  return [`${ key }.to.id`];
          case 'groups':    return [`${ key }.to.parentGroups.id`, `${ key }.to.id`];
          case 'tags':      return [`tags.value`, `course.tags.value`];
          default:          return null
        }
      }

    export const objectEntries
      = function <T extends Object = Object> (
        obj: T,
      ): Types.Entry<T>[] { return Object.entries(obj) as Types.Entry<T>[]; }

    export const objectKeyVals
      = function <T extends Object = Object> (
        obj: T,
      ): Types.KeyVal<T>[] { return Object.entries(obj).map(([key, val]) => ({ key, val }) as any as Types.KeyVal<T>); }

    export const objectKeys
      = function <T extends Object = Object> (
        obj: T,
      ): (keyof T)[] { return Object.keys(obj) as (keyof T)[]; }

    export function coerceArrayProperty<T>(val: T | T[]): T[] {
      if (val == null)
        return [];
      return Array.isArray(val) ? val : [val];
    }
    export const mutable = <T extends Object>(obj: T): Types.Mutable<T> => obj as Types.Mutable<T>;

    export function getDayIndex (mnt: string | moment.Moment | Date): number {
      // monday is 0, sunday is 6
      return (moment.utc(mnt).day() + 6) % 7
    }

    export function setId<T extends object>(ob: T, path: Types.NestedKeyOf<T>) {
      const val = Util.functions.coerceArrayProperty(get(ob, path));
      val.forEach((v) => {
        if (! (_.isObject(v) || _.isString(v)))
          throw new Error(`(Common::Util::assignId): Value must be an object, string or an array of objects or strings, got ${ typeof v }`);

        v = _.isObject(v) ? Object.assign(v, { id: _.get(v, 'id') ?? nanoid(8) }) : { id: v };
      });
    }

    export function toMultipleOf5 (
      x:   number,
      min: number = 0,
    ): number {
      return Math.max(min, Math.floor(x / 5) * 5);
    }

    export function extractAndParsePlannedDuration (course: Pick<P.course, 'plannedDuration'>): null | [number, Types.PlannedDurationUnit] {
      if (course.plannedDuration == null) return null;

      const [_value, unit] = course.plannedDuration.split(' ');

      // check if unit is valid
      if (unit != 'hrs' && unit != 'min/week') return null;

      // parse value to integer
      const value = parseInt(_value);
      if (isNaN(value)) return null;

      return [value, unit];
    }

  }

  export namespace operators {
    export const defaultIfNull = <I, O>(to: Observable<O>) => (o: Observable<I>): Observable<I | O> => o.pipe(
      combineLatestWith(to),
      map(([x, y]) => x == null ? y : x),
    );
  }

  export namespace TypeGuards {
    export function isCollection(collection: string): collection is Types.Collection {
      return _.includes(Constants.COLLECTIONS, collection);
    }

    export function isHttpErrorResponse(x: unknown): x is HttpErrorResponse {
      return _.isObject(x) && x instanceof HttpErrorResponse;
    }

    export function has<T>(ob: T, key: string | number | symbol): key is keyof T {
      return _.isObject(ob) && key in ob;
    }

    export function isEvent (x: P.event | P.lockedTime): x is P.event{
      return 'is' in x && x.is === 'event';
    }
    export function isLockedTime (x: P.event | P.lockedTime): x is P.lockedTime {
      return 'is' in x && x.is === 'lockedTime';
    }

    export function isString (x: any): x is string {
      return typeof x === 'string';
    }
    export function isArray<T> (x: T): x is Extract<typeof x, Array<any> > {
      return !! Array.isArray(x);
    }
    export function isObject<T> (x: T): x is Extract<typeof x, object> {
      return !! _.isObject(x);
    }


  }
}