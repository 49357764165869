import { NgModule                        } from '@angular/core';
import { CommonModule                    } from '@angular/common';
import { FormsModule,
         ReactiveFormsModule             } from '@angular/forms';

import { TranslationModule               } from 'app/core/translate/translate.module';

import { AppCommonModule                 } from 'app/common/common.module';

import { ComponentsModule                } from './components/components.module';

import { ColorComponent                  } from './color.component';


@NgModule({
  declarations: [
    ColorComponent
  ],
  exports: [
    ColorComponent,
    ComponentsModule
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    FormsModule,
    ComponentsModule,
    TranslationModule,
    ReactiveFormsModule
  ]
})
export class ColorModule { }
