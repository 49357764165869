import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService    } from '@ngx-translate/core';

import { Role                } from '@app/constants';
import { OrganizationType    } from '@app/core/environment/environment.types';

@Pipe({
  name: 't',
  pure: false // required to update when language changes etc.
})
export class TranslatePipe implements PipeTransform {

  constructor (private _translate: TranslateService) { }

  // transform (key: string, path?: string): string;
  transform (key: string, path?: string, params?: Object): string;
  transform (key: string, params?: Object, path?: undefined): string;
  transform (
    key: string,
    a?:  string | Object,
    b?:  string
  ): any {

    // key only
    if (a === undefined) return this._translate.instant(key);

    // key + path + (params)
    if (typeof a === 'string') return this._translate.instant(a + '.' + key, b);

    // key + (params)
    return this._translate.instant(key, a);
  }
}




@Pipe({
  name: 'role',
})
export class RolePipe implements PipeTransform {
  constructor (private _translate: TranslateService) { }
  transform (value: Role): string {
    return this._translate.instant('auth.role.' + value);
  }
}

@Pipe({
  name: 'organizationType',
})
export class OrganizationTypePipe implements PipeTransform {
  constructor (private _translate: TranslateService) { }
  transform (value: OrganizationType | undefined): string {
    return this._translate.instant('common.' + (value ?? 'school'));
  }
}