import { Pipe,
         PipeTransform                   } from '@angular/core';
import _                                   from 'lodash';

import { Util                            } from 'app/common/util';
import { EnvironmentService              } from './environment.service';

@Pipe({
  name: 'environmentCollection'
})
export class EnvironmentCollectionPipe implements PipeTransform {
  constructor (private _environment: EnvironmentService) { }

  transform(collection: Util.Types.Collection | 'tags', origin?: string) {
    if (collection === 'tags') return true;
    if ( ! Util.TypeGuards.isCollection(collection)) return false;

    const { collections } = this._environment.getState() ?? { };
    const { origins } = collections?.[collection] ?? { };
    return Boolean(collections?.[collection]) && (! origin || origins?.omit?.[origin] !== true);
  }
}

@Pipe({
  name: 'collectionIcon'
})
export class CollectionIconPipe implements PipeTransform {
  constructor (private _environment: EnvironmentService) { }

  transform(collection: Util.Types.Collection | 'tags') {
    if (collection === 'tags') return 'sell';
    if ( ! Util.TypeGuards.isCollection(collection)) return false;

    const { collections } = this._environment.getState() ?? { };
    return collections?.[collection]?.icon ?? 'question_mark';
  }
}
