import { Component,
         OnInit,
         Input,
         ElementRef,
         OnDestroy,
         Optional,
         Self,
         EventEmitter,
         Output,
         ViewChild                      } from '@angular/core';
import { ControlValueAccessor,
         NgControl,
         FormControl                     } from '@angular/forms';
import { MatSlideToggleChange            } from '@angular/material/slide-toggle';
import { MatFormFieldControl             } from '@angular/material/form-field';
import { FocusMonitor                    } from '@angular/cdk/a11y';
import { coerceBooleanProperty           } from '@angular/cdk/coercion';
import { MatMenuTrigger                  } from '@angular/material/menu';
import { Subject,
         fromEvent,
         asyncScheduler                  } from 'rxjs';
import { takeUntil                       } from 'rxjs/operators';
import _                                   from 'lodash';

import { TranslateService                } from 'app/core';
import { Populated                       } from '@app/shared/interfaces';

type Value = Populated.rootInterval | null | undefined;

@Component({
  selector: 'app-form-field-root-intervals',
  templateUrl: './root-intervals.component.html',
  styleUrls: ['./root-intervals.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: RootIntervalsComponent
    }
  ]
})
export class RootIntervalsComponent implements OnInit,
                                        OnDestroy,
                                        ControlValueAccessor,
                                        MatFormFieldControl<Populated.rootInterval | undefined> {

  @ViewChild(MatMenuTrigger) trigger?: MatMenuTrigger;

  @Output('onChange') emitter = new EventEmitter<Value>();

  private onClose:     Subject<boolean>    = new Subject<boolean>();
  static nextId:       number              = 0;
  public stateChanges: Subject<void>       = new Subject<void>();
  public focused:      boolean             = false;
  public errorState:   boolean             = false;
  public controlType:  string              = 'root-intervals-input';
  public id:           string              = `root-intervals-input-${ RootIntervalsComponent.nextId++ }`;
  public describedBy:  string              = '';
  public onChange                          = (_: any) => {};
  public onTouched                         = () => {};
  public control?:     FormControl<[Value]>;

  constructor(private _focusMonitor: FocusMonitor,
              private _translate:    TranslateService,
              private _elementRef:   ElementRef<HTMLElement>,
              @Optional() @Self() public ngControl: NgControl) {

    _focusMonitor.monitor(_elementRef, true)
    .subscribe((origin: any) => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
  }

  public resetValue(): void {
    this.value = this._pristineValue;
    this.pristine = true;
    this._setForm();
  }

  public opened(): void {
    this.control = new FormControl();

    this._setForm();

    fromEvent(document, 'keydown')
    .pipe(takeUntil(this.onClose))
    .subscribe((event: KeyboardEvent) => {
      if (event.key == 'Escape') {
        this.trigger?.closeMenu()
      }
    });
  }

  public closed(): void {
    if (this.saveOnClose && this.valid && ! this.pristine) {
      this.pristine = true;
      this._pristineValue = this.value;
      this.emitter.emit(this.value);
    }

    if (this.saveOnClose && ! this.valid) {
      this.value     = this._pristineValue;
      this._valid    = true;
      this.pristine = true;
    }

    asyncScheduler
    .schedule(() => delete this.control );

    this.onClose.next(true);
  }

  public submit(): void {
    this.emitter.emit(this.value);
  }

  public inheritToggle(event: MatSlideToggleChange) {
    this.inherit?.setValue(event.checked);
    this._handleInput();
  }

  private _setForm(): void {
    this.inherit?.setValue(this.value === null);

    if (this.value != null) this.control?.setValue([this.value]);
    else                    this.control?.setValue([null]);
  }

  private _setValue(): void {
    if (this._inherit?.value) {
      this._value = null;
      this.valid  = true;
      this.pristine = _.isEqual(this.value, this._pristineValue);
    } else {
      const value = this.control?.value[0];
      this._value = value ?? null;
      this.valid  = true;
      this.pristine = _.isEqual(value, this._pristineValue);
    }
  }

  get valid(): boolean { return this._valid; }
  set valid(value: boolean) {
    this._valid = coerceBooleanProperty(value);
    this.errorState = ! this._valid;
  }
  private _valid: boolean = true;

  get empty() {
    return false
  }

  get shouldLabelFloat() { return this.focused || ! this.empty; }

  get pristine(): boolean {
    return this._pristine;
  }
  set pristine(_val: boolean) {
    const val      = coerceBooleanProperty(_val);
    this._pristine = val;

    if (val) asyncScheduler.schedule(() => this.ngControl?.control?.markAsPristine());

    this.stateChanges.next();
  }
  private _pristine:  boolean = true;

  compareWith (o1: Value, o2: Value): boolean {
    return o1?.id == o2?.id;
  }

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get voidText(): string { return this._voidText; }
  set voidText(value: string) {
    this._voidText = value;
    this.stateChanges.next();
  }
  private _voidText: string = '';

  @Input()
  get setVoidText(): string { return this._setVoidText; }
  set setVoidText(value: string) {
    this._setVoidText = value;
  }
  private _setVoidText: string;

  @Input()
  get reset(): boolean { return this._reset; }
  set reset(value: boolean) {
    this._reset = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _reset = true;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get inherit(): FormControl<boolean | null> | undefined { return this._inherit; }
  set inherit(value: FormControl<boolean | null> | undefined | string) {
    if (_.isString(value)) {
      this._inherit = new FormControl<boolean | null>(null);
    } else {
      this._inherit = value ? value : new FormControl<boolean | null>(null);
    }
    this.stateChanges.next();
  }
  private _inherit: FormControl<boolean | null> | undefined;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get disableActions(): boolean { return this._disableActions; }
  set disableActions(value: boolean | string) {
    this._disableActions = coerceBooleanProperty(value);
  }
  private _disableActions = false;

  @Input()
  get saveOnClose(): boolean { return this._saveOnClose; }
  set saveOnClose(value: boolean | string) {
    this._saveOnClose = coerceBooleanProperty(value);
  }
  private _saveOnClose: boolean = false;

  @Input()
  get value(): Value {
    return this._value;
  }
  set value(_val: Value) {
    this._value = this._pristineValue = _val;
    this.stateChanges.next();
  }
  private _value:         Value;
  private _pristineValue: Value;

  @Input()
  get options(): Populated.rootInterval[] {
    return this._options;
  }
  set options(value: Populated.rootInterval[] | null) {
    if ( ! value) return;
    this._options = value;
    this.stateChanges.next();
  }
  private _options: Populated.rootInterval[] = [];

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
    this.onClose.next(true);
    this.onClose.complete();
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent) {
    this.trigger?.openMenu();
  }

  writeValue(val: Value): void {
    this.value = val;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  _handleInput(): void {
    this._setValue();
    this.onChange(this.value);
  }

  static ngAcceptInputType_disabled: boolean | string | null | undefined;
  static ngAcceptInputType_required: boolean | string | null | undefined;
}
