import { EventInput                 } from '@fullcalendar/core';
import { CalendarComponent          } from './calendar.component';
import { ExtendedProperties,
         InCalendarBackgroundEvent,
        _EventSource                } from './types';
import moment                         from 'moment';
import _                              from 'lodash';

export function setBackgroundEvents (
  this:      CalendarComponent,
  intervals: InCalendarBackgroundEvent[] | null
) {
  this.backgroundEvents.next(intervals?.map(e => this.mapToBackgroundEvent(e)) ?? []);
}


export function mapToBackgroundEvent (
  this:  CalendarComponent,
  event: InCalendarBackgroundEvent
): EventInput & { extendedProps: ExtendedProperties & { source: { } } } {
  const display = 'background';
  const start   = moment.utc(event.start).toDate();
  const end     = moment.utc(event.end).toDate();

  const title       = event.title ?? '';
  const description = event.description;

  const backgroundColor = event.backgroundColor ?? this.defaultColor;

  // css classes
  const classNames = event.classNames;

  // // etc
  const extendedProps: ExtendedProperties & { source: { } } = {
  //   ...event.extendedProps,
    // source: event,   // reference to original source event
    source: { },
  };

  return {
    display, start, end, title, description, backgroundColor, classNames, extendedProps
  };
}