import { NgModule                                } from '@angular/core';
import { CommonModule                            } from '@angular/common';
import { FormsModule, ReactiveFormsModule        } from '@angular/forms';

import { TranslationModule                       } from 'app/core/translate/translate.module';
import { AppCommonModule                         } from 'app/common/common.module';
import { PipesModule                             } from 'app/shared/pipes/pipes.module';
import { FormsModule as MyFormsModule            } from 'app/shared/forms/forms.module';
import { DirectivesModule                        } from 'app/shared/directives/directives.module';
import { ComponentsModule as AppComponentsModule } from 'app/shared/components/components.module';
import { SelectMapComponent                      } from './select-map/select-map.component';
import { SelectFileComponent                     } from './select-file/select-file.component';
import { FinalizeComponent                       } from './finalize/finalize.component';
import { ConfigureComponent                      } from './configure/configure.component';


@NgModule({
  declarations: [
    SelectMapComponent,
    SelectFileComponent,
    FinalizeComponent,
    ConfigureComponent
  ],
  exports: [
    SelectMapComponent,
    SelectFileComponent,
    FinalizeComponent,
    ConfigureComponent
  ],
  imports: [
    CommonModule,
    TranslationModule,
    FormsModule,
    ReactiveFormsModule,
    MyFormsModule,
    AppCommonModule,
    PipesModule,
    DirectivesModule,
    AppComponentsModule
  ]
})
export class ComponentsModule { }
