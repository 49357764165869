import { NgModule                        } from '@angular/core';
import { CommonModule                    } from '@angular/common';
import { FormsModule,
         ReactiveFormsModule             } from '@angular/forms';
import { NgPipesModule                   } from 'ngx-pipes';
import { ScrollingModule                 } from '@angular/cdk/scrolling';

import { TranslationModule               } from 'app/core/translate/translate.module';

import { AppCommonModule                 } from 'app/common/common.module';

import { ComponentsModule                } from 'app/shared/components/components.module';

import { ComponentsModule as SubModule   } from './components/components.module';

import { PipesModule                     } from '@shared/pipes/pipes.module';

import { PeriodComponent                 } from './period.component';


@NgModule({
  declarations: [
    PeriodComponent
  ],
  exports: [
    PeriodComponent,
    SubModule
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    NgPipesModule,
    ScrollingModule,
    TranslationModule,
    PipesModule,
    FormsModule,
    SubModule,
    ComponentsModule,
    ReactiveFormsModule
  ]
})
export class PeriodFormFieldModule { }
