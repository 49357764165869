import _                                   from 'lodash';
import moment                              from 'moment';

import { Util                            } from '@app/common';

import { Options                         } from './source.interface';
import { SourceData                      } from './source.interface';


// function recursiveGetNestedEntity (source: unknown, path: string[], keyIndex = 0): unknown {
//   // if array continue with each element
//   if (Array.isArray(source)) return source.map(x => recursiveGetNestedEntity(x, path, keyIndex));

//   // fetch key and if undefined return source
//   const key = path.at(keyIndex);
//   if (key === undefined) return source;

//   // if object continue with next key
//   if (source && typeof source === 'object') return recursiveGetNestedEntity((source as Record<string, unknown>)[key], path, keyIndex + 1);

//   // will only reach this point if the path and source is incompatible
//   return undefined;
// }


// function getNestedEntity (source: unknown, stringPath: string): unknown {
//   if ( ! _.isString(stringPath)) return source;
//   let val = recursiveGetNestedEntity(source, stringPath.split('.'));

//   // as it branches for every array we must flatten the result
//   if (Array.isArray(val)) val = val.flat();

//   return val;
// }



/**
 * @description
 * The following actions are possible
 *
 * "has":
 *   is the value not nullish
 *
 * "select":
 *   Select a specific value or values. (select documents with id from an array of id)
 *
 * "oneOf":
 *   From an array of keys match against an equal length array of values.
 *   ie. keys: ['groups.id', 'inLocations.id'], values: [['UyO9zV9pd6'], ['goJJ8eH675-']]
 *   Values that match one of these criteria will be emitted.
 *
 * "match":
 *   simple string search function
 */
export function _filter(skipNoFilter: Options.Default['skipNoFilter']) {

  return <T extends SourceData = any>([docs, ..._filters]: [null | T | T[], ...Options.Mapped[]]):[null | T | T[], ...Options.Mapped[]] => {
    // must be an array
    if ( ! Array.isArray(docs)) return [docs, ..._filters];

    // extract all filters
    const filters = _filters.map(x => x.filter).filter(Boolean).flat();
    if ( ! filters.length) return [docs, ..._filters];

    // whether or not we require a filter to be active
    // (at least a single opaque filters must have a value if skipNoFilter is true)
    if (skipNoFilter && filters.filter(x => ! x.transparent).every(x => ! x.value)) return [[], ..._filters];

    const filteredDocs = docs.filter(doc => {
      return ! filters.some(({ action, key: _keys, value: pattern }) => {
        if (action == 'inTimeInterval') {
          const start = Util.functions.get(doc, (_keys?.[0] ?? 'start') as any);
          const end   = Util.functions.get(doc, (_keys?.[1] ?? 'end')   as any);

          if ( ! start || ! end) return true;

          return ! (pattern.start == null || moment.utc(pattern.start).isBefore(moment.utc(end))) ||
                 ! (pattern.end == null   || moment.utc(pattern.end).isAfter(moment.utc(start)));
        }

        // remaining filters must have a key and a value
        if ( ! _keys || pattern === '' || pattern == null) return false;

        // standardize the keys
        const keySets = Array.isArray(_keys) ? _keys : [_keys];

        return ! keySets.some((keys, index) => {

          keys = Util.functions.coerceArrayProperty(keys);

          return keys.some(key => {

            const value = Util.functions.get(doc, key as any);

            switch (action) {
              case 'has': {
                return (value != null) == pattern;
              }
              case 'select': {
                // check intersection
                const a = Array.isArray(value) ? value : [value];
                const b = pattern;
                return Array.isArray(b) ? a.some(x => b.includes(x)) : a.includes(b);
              }
              case 'oneOf': {
                // check intersection
                const a = Array.isArray(value) ? value : [value];
                const b = pattern[index];
                return Array.isArray(b) ? a.some(x => b.includes(x)) : a.includes(b);
              }
              case 'match': {
                if ( ! value) return false;

                // split based on & and find if any of the values match the pattern
                return pattern.split('&')
                .every(exp => {
                  // console.log('exp', exp);
                  // "i" makes the search case insensitive search
                  const expression = new RegExp(_.escapeRegExp(exp), 'i');

                  const a = Array.isArray(value) ? value : [value];
                  return a.some((x: unknown) => {
                    // skip null and undefined
                    if (x == null) return false;

                    if (typeof x === 'string') return expression.test(x);
                    if (typeof x === 'number') return expression.test(x.toString());

                    return false;
                  });
                });
              }
              default: {
                // this._logger.error(new Error(`(Source::Filter) Unimplemented filter ${ action }`));
                return false;
              }
            }
          });
        });
      });
    });

    return [filteredDocs, ..._filters];
  }

}
