import { Injectable,
         OnDestroy                       } from '@angular/core';
import { Observable,
         Subject                         } from 'rxjs';
import { share,
         filter,
         startWith                       } from 'rxjs/operators';

import { storageConstants                } from 'app/constants';
import { LoggerService                   } from 'app/core/logger/logger.service';



type KeyValue = { key: string, value: any };

@Injectable({
  providedIn: 'root'
})
export class StorageService implements OnDestroy {
  private onSubject = new Subject<KeyValue>();
  public changes = this.onSubject.asObservable().pipe(share());
  public isPurging:boolean = false;

  constructor(protected _logger: LoggerService) {
    this.watchForChange();
  }

  ngOnDestroy() {
    this.stopWatchingForChange();
  }

  public get(key: string): any {
    return localStorage.getItem(key);
  }

  public set(key: string, data: any): void {
    localStorage.setItem(key, data);
    this.onSubject.next({ key: key, value: data})
  }

  public has(key: string): boolean {
    return localStorage.hasOwnProperty(key);
  }

  public append(key: string, data: any): void {
    let array:any[] = localStorage.getItem(key) ?
                        JSON.parse(<string>localStorage.getItem(key)) :
                        [];
    array.push(data);
    localStorage.setItem(key, JSON.stringify(array));
    this.onSubject.next({ key: key, value: array });
  }

  public pull(key: string, data: any): void {
    let array:any[] = localStorage.getItem(key) ?
                        JSON.parse(<string>localStorage.getItem(key)) :
                        [];
    array = array.reduce((acc, val) => {
      if (val == data)
        return acc;
      return acc.concat(val);
    }, []);
    localStorage.setItem(key, JSON.stringify(array));
    this.onSubject.next({ key: key, value: array});
  }

  public remove(key: any):void {
    localStorage.removeItem(key);
    this.onSubject.next({ key: key, value: null });
  }

  public clear (preserve: string[] = []):void {
    this.isPurging = true;
    for (let key in localStorage) {
      // remove all keys that are not in the preserve array.
      if ( ! preserve.includes(key)) this.remove(key);
    }
    this.isPurging = false;
  }

  public getThreadID(): number {
    const _id: string | null = sessionStorage.getItem(<string>storageConstants.THREAD_ID);

    if (_id == null) return NaN;

    try {
      return parseInt(_id, 10);
    } catch(err) {
      this._logger.error(err);
      return NaN;
    }
  }

  public setThreadID(id: number): void {
    return sessionStorage.setItem(storageConstants.THREAD_ID, id?.toString());
  }

  public watchStorage (key?: string): Observable<KeyValue> {
    return this.changes.pipe(filter(x => key ? (x.key == key) : true));
  }

  public onStorage (key: string): Observable<KeyValue | null> {
    const value = this.has(key) ? { key: key, value: this.get(key) } : null;
    return this.watchStorage(key).pipe(startWith(value));
  }

  private watchForChange(): void {
    window.addEventListener('storage', x => this.storageEventListener(x));
  }

  private storageEventListener (event: StorageEvent) {
    if (event.storageArea == localStorage && event.key) {
      this.onSubject.next({ key: event.key, value: event.newValue });
    }
  }

  private stopWatchingForChange(): void {
    window.removeEventListener('storage', x => this.storageEventListener(x));
    this.onSubject.complete();
  }
}