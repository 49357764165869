import { DateRange } from 'moment-range';

export type Week = {
  id:           string;
  range:        DateRange;
  number:       number;
  string:       string;
  excludedDays: { date: string, day: number, reason?: string }[];
};

export const allWeeks = 'all';
export type AllWeeks = typeof allWeeks;