import { Injectable                      } from '@angular/core';
import { Subject,
         asyncScheduler                  } from 'rxjs';
import { debounceTime,
         filter,
         skip                            } from 'rxjs/operators';

import { TranslateService                } from '@core/translate/translate.service';
import { AuthService                     } from '@core/auth/auth.service';
import { HttpService                     } from '@core/http/http.service';
import { SocketService                   } from '@core/socket/socket.service';
import { LoggerService                   } from '@core/logger/logger.service';
import { ThreadService                   } from '@core/thread/thread.service';
import { BroadcastService                } from '@core/broadcast/broadcast.service';
import { PushNotificationService         } from '@core/push-notification/push-notification.service';

import { MatSnackBar                     } from 'app/common';

import { SystemState                     } from './core/system-instances';
import { SystemCore                      } from './core/system-core';
import { Core                            } from './core';

@Injectable({
  providedIn: 'root'
})
export class SourceCore extends Core {
  protected readonly onDestroy = new Subject<void>();   // never called, please implement
  protected state:                  SystemState;
  //protected _systemCore:        SystemCore;

  protected onNotify = new Subject<string>();

  constructor(protected _socket:       SocketService,
              protected _http:         HttpService,
              protected _broadcast:    BroadcastService,
              protected _snackbar:     MatSnackBar,
              protected _translate:    TranslateService,
              protected _auth:         AuthService,
              protected _thread:       ThreadService,
              protected _logger:       LoggerService,
              protected _notification: PushNotificationService) {
    super();

    this.onNotify.pipe(debounceTime(1000))
    .subscribe((message) => this._notification.pushError(message));

    this._auth.onIsAuthenticated
    .pipe(
      skip(1),  // skip the initial hardcoded false value
      filter(x => ! x)
    )
    .subscribe(() => this._complete());

    asyncScheduler.schedule(() => {
      this.__systemCore.onCommand()
      .subscribe(this._useCommand.bind(this));
    })
  }

  /*
    TODO
    listen to worker close.
    on close remove reference to systemcore.
  */
  protected get _systemCore(): SystemCore {
    // console.log('sys core', this.__systemCore);
    //if (this.__systemCore === undefined)
    //  this.__systemCore = this._getSystemType();
    return this.__systemCore;
  }
  protected __systemCore: SystemCore = this._getSystemType();

  public useSystemInstance(state: SystemState): void {
    this.__systemCore = this._getSystemType(state);
  }

  ngOnDestroy() {
    this.onNotify.complete();
  }
}