<!-- force rerender on stateChanges.next() otherwise it is not guaranteed to show the latest values due to some lazy rendering-->
{{ stateChanges | async }}

<div class="days-select-label clickable"
     (menuClosed)="closed()"
     (menuOpened)="opened()"
     [matMenuTriggerFor]="menu">
    <app-form-field-root-intervals-display-value [value]="value"
                                         [inherit]="!!inherit"
                                         [voidText]="voidText">
    </app-form-field-root-intervals-display-value>
</div>

<mat-menu yPosition="below"
          #menu="matMenu"
          class="root-intervals-panel no-vertical-padding"
          [overlapTrigger]="true">
  <ng-template matMenuContent>

    <div class="toolbar" (click)="$event.stopPropagation()">
      <div class="row">
        @if (inherit) {
          <mat-slide-toggle class="inherit-toggle"
                            labelPosition="before"
                            color="accent"
                            (change)="inheritToggle($event)"
                            [checked]="inherit.value"
                            [disabled]=" ! options.length">
            {{ setVoidText }}
          </mat-slide-toggle>
        }

        <button mat-stroked-button
                class="action reset"
                color="primary"
                (click)="resetValue()"
                *ngIf="! pristine && reset && valid">
          <div>
            <span>{{ 'common.restore' | translate }}</span>
            <mat-icon class="royal-icon">undo</mat-icon>
          </div>
        </button>

        <button mat-stroked-button
                class="action invalid"
                *ngIf="! pristine && ! valid"
                color="primary">
          <div>
            <span>{{ 'common.invalid' | translate }}</span>
            <mat-icon class="royal-icon">warnings</mat-icon>
          </div>
        </button>
      </div>
    </div>

    <div (click)="$event.stopPropagation()"
        *ngIf="control"
        class="content">
      <mat-selection-list [formControl]="control"
                          [multiple]="false"
                          [compareWith]="compareWith"
                          (selectionChange)="_handleInput()"
                          *ngIf="options.length; else empty">
        <mat-list-option class="default" [value]="null">
          {{ 'common.none_singular' | translate }}
        </mat-list-option>
        <mat-list-option *ngFor="let rootInterval of options"
                        [disabled]="inherit?.value"
                        [value]="rootInterval">
          {{ rootInterval | displayName }}
        </mat-list-option>
      </mat-selection-list>

      <ng-template #empty>
        <div class="empty">
          <mat-icon class="royal-icon warning-orange">warning</mat-icon>
          <span>{{ 'shared.form-fields.root-intervals.no-root-intervals' | translate }}</span>
        </div>
      </ng-template>

    </div>

    <mat-divider class="divider"
                *ngIf="! disableActions"
                (click)="$event.stopPropagation()"></mat-divider>

    <div (click)="$event.stopPropagation()"
        class="actions"
        *ngIf="! disableActions">
      <button mat-stroked-button
              color="primary"
              type="button"
              class="button"
              (click)="trigger?.closeMenu()">
        {{ 'common.cancel' | translate }}
      </button>
      <button mat-flat-button
              color="primary"
              type="button"
              [disabled]="! valid"
              (click)="submit(); trigger?.closeMenu()"
              class="button right">
        {{ 'common.save' | translate }}
      </button>
    </div>
  </ng-template>

</mat-menu>


