import { Injectable,
         NgZone,
         OnDestroy                             } from '@angular/core';
import { Subject,
         BehaviorSubject,
         map,
         combineLatest                         } from 'rxjs';

import { SourceService,
         TranslateService,
         LoggerService,
         PushNotificationService,
         UserPreferencesService,
         EnvironmentService                    } from '@app/core';
import { DialogsService,
         CommonService                         } from '@app/shared/services';
import { Populated as P                        } from '@app/shared/interfaces';

import { Schedule,
         Remark,
         VerifiedOut,
         AnalyzedOut,
         IntervalAnalysis,
         EventMassDistributionAnalysis         } from './types'
import { getName,
         sortMapFun,
         parseCompositeId,
         t                                     } from './aux';
import { notify                                } from './notify';
import { activate,
         setupDataProcessing,
         setupSharedWorker,
         setupSource,
         deactivate                            } from './activate-deactivate';
import { createRemark                          } from './create-remark';
import { findEvent,
         findGroup,
         findLocation,
         prependCollection                     } from './find';
import { makeReadable,
         makeSingleReadable                    } from './make-readable';
import { processData                           } from './process-data';
import { processVerified                       } from './process-verified';
import { processAnalyzed                       } from './process-analyzed';
import { SharedWorkerWrapper                   } from './Shared-worker/Shared-worker-wrapper';

@Injectable({ providedIn: 'root' })
export class InputAnalysisService implements OnDestroy {
  protected onDestroy = new Subject<void>();

  protected source: SourceService | null;

  protected locations   = new BehaviorSubject<P.location     [] | null>(null);
  protected groups      = new BehaviorSubject<P.group        [] | null>(null);
  protected teachers    = new BehaviorSubject<P.teacher      [] | null>(null);
  protected persons     = new BehaviorSubject<P.person       [] | null>(null);
  protected events      = new BehaviorSubject<P.event        [] | null>(null);
  protected lockedTimes = new BehaviorSubject<P.lockedTime   [] | null>(null);
  protected onData = new Subject<{ version: number, data: Schedule }>();


  protected worker?: SharedWorkerWrapper;
  protected onVerified = new Subject<VerifiedOut>();
  protected onAnalyzed = new Subject<AnalyzedOut>();


  protected version: number = 0;


  protected set errors (value: Remark[]) { this._errors.next(value); }
  private _errors = new BehaviorSubject<Remark[]>([]);
  private _numErrors = this._errors.pipe(map(x => x.length));
  public get errors () { return this._errors.value; }
  public get numErrors () { return this._errors.value.length; };
  public get onNumErrors () { return this._numErrors; };

  protected set issues (value: Remark[]) { this._issues.next(value); }
  private _issues = new BehaviorSubject<Remark[]>([]);
  private _numIssues = this._issues.pipe(map(x => x.length));
  public get issues () { return this._issues.value; }
  public get numIssues () { return this._issues.value.length; };
  public get onNumIssues () { return this._numIssues; };

  protected set notices (value: Remark[]) { this._notices.next(value); }
  public _notices = new BehaviorSubject<Remark[]>([]);
  private _numNotices = this._notices.pipe(map(x => x.length));
  public get notices () { return this._notices.value; }
  public get numNotices () { return this._notices.value.length; };
  public get onNumNotices () { return this._numNotices; };

  public get totNumRemarks () { return this.numErrors + this.numIssues + this.numNotices; };
  public get onTotNumRemarks () {
    return combineLatest([this._errors, this._issues, this._notices])
    .pipe(map(([errors, issues, notices]) => errors.length + issues.length + notices.length));
  };

  public    get active () { return this._active.value; };
  protected set active (value) { this._active.next(value); };
  private _active= new BehaviorSubject<boolean>(false);

  /** @description true until all data has been fetched */
  public    get initializing () { return this._initializing.value };
  protected set initializing (value) { this._initializing.next(value); };
  private _initializing = new BehaviorSubject<boolean>(false);

  /** @description true when the schedule is being analyzed or in the queue to be analyzed */
  public    get analyzing () { return this._analyzing.value };
  protected set analyzing (value) { this._analyzing.next(value); };
  private _analyzing = new BehaviorSubject<boolean>(false);

  /** @description true if the the latest version of the schedule contains no errors (critical or noncritical) */
  public    get valid () { return this._valid.value };
  protected set valid (value) { this._valid.next(value); };
  private _valid = new BehaviorSubject<boolean>(false);

  /** @description true if the the latest version of the schedule has encountered any critical errors during the input verification */
  public    get criticalErrorEncountered () { return this._criticalErrorEncountered.value };
  protected set criticalErrorEncountered (value) { this._criticalErrorEncountered.next(value); };
  private _criticalErrorEncountered = new BehaviorSubject<boolean>(false);

  public    get did () { return this._did; };
  protected set did (val) { this._did = val; };
  private _did: string | undefined;


  ////
  //// public variables
  ////
  public readonly criticalVerifierErrors?:    NonNullable<VerifiedOut['data']>['criticalVerifierErrors'];
  public readonly noncriticalVerifierErrors?: NonNullable<VerifiedOut['data']>['noncriticalVerifierErrors'];

  public readonly intervalAnalysis?:              IntervalAnalysis;
  public readonly eventMassDistributionAnalysis?: EventMassDistributionAnalysis;


  ////
  //// methods
  ////
  public        findEvent         = findEvent;
  public        findGroup         = findGroup;
  public        findLocation      = findLocation;
  public        activate          = activate;
  public        deactivate        = deactivate;
  public static prependCollection = prependCollection;
  public static parseCompositeId  = parseCompositeId;

  protected getName             = getName;
  protected sortMapFun          = sortMapFun;
  protected t                   = t;
  protected setupDataProcessing = setupDataProcessing;
  protected setupSharedWorker   = setupSharedWorker;
  protected setupSource         = setupSource;
  protected makeReadable        = makeReadable;
  protected makeSingleReadable  = makeSingleReadable;
  protected processData         = processData;
  protected processVerified     = processVerified;
  protected processAnalyzed     = processAnalyzed;
  protected createRemark        = createRemark;
  protected notify              = notify;

  constructor (
    protected _translate:     TranslateService,
    protected _notifications: PushNotificationService,
    protected _dialog:        DialogsService,
    protected _logger:        LoggerService,
    protected _preferences:   UserPreferencesService,
    protected _common:        CommonService,
    protected _environment:   EnvironmentService,
    protected _ngZone:        NgZone
  ) {
    // cleanup in case of page reload
    window.addEventListener('beforeunload', () => this.onDestroy.next());
  }

  ngOnDestroy () {
    this.deactivate();
    this.onDestroy.next();
    this.onDestroy.complete();
  }

}
