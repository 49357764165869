<!-- force rerender on stateChanges.next() otherwise it is not guaranteed to show the latest values due to some lazy rendering-->
{{ stateChanges | async }}

<span *ngIf="prefix && (value || voidText || focused)" [class.gray]=" ! input.value">{{ prefix }}</span>
<input #input
  type="text"
  autocomplete="off"
  [id]="id"
  [style.max-width]="maxWidth"
  [placeholder]="voidText"
  [formControl]="control"
  [dynamicCleave]="cleave"
  (keydown)="keydown($event)"
>
<span *ngIf="suffix && (value || voidText || focused)" [class.gray]=" ! input.value">{{ suffix }}</span>
