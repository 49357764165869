import _                       from 'lodash';
import moment                  from 'moment';

import { Pipe, PipeTransform } from '@angular/core';
import { Day                 } from '@app/shared/interfaces';
import { DateService         } from '@app/shared/services';
import { TranslateService    } from '@app/core';

@Pipe({ name: 'addDay' })
export class AddDayPipe implements PipeTransform {
  transform (
    date: moment.Moment | string | undefined,
    amount: number
  ): moment.Moment | undefined {
    date && _.isString(date) && (date = moment(date));
    return (date as moment.Moment)?.clone().add(amount, 'day');
  }
}

@Pipe({ name: 'toMoment' })
export class MomentPipe implements PipeTransform {
  transform (date: string | number | null | undefined | moment.Moment | Date) {
    return moment.utc(date);
  }
}

@Pipe({ name: 'toDate' })
export class DatePipe implements PipeTransform {
  transform (date: unknown): Date {
    if (date instanceof Date)  return date;
    if (moment.isMoment(date)) return date.toDate();
    if (_.isString(date))      return new Date(date);
    return new Date();
  }
}

@Pipe({ name: 'startOfWeek' })
export class StartOfWeekPipe implements PipeTransform {
  transform (date: moment.Moment | Date | string | undefined | null, format: string) {
    if ( ! date) return date;

    const _date = moment.utc(date);
    if (! _date.isValid()) return date;

    const startOfWeek = _date.clone().startOf('week');
    return format ? startOfWeek.format(format) : startOfWeek;
  }
}

@Pipe({ name: 'endOfWeek' })
export class EndOfWeekPipe implements PipeTransform {
  transform (date: moment.Moment | Date | string | undefined | null, format: string) {
    if ( ! date) return date;

    const _date = moment.utc(date);
    if (! _date.isValid()) return date;

    const endOfWeek = _date.clone().endOf('week');
    return format ? endOfWeek.format(format) : endOfWeek;
  }
}

@Pipe({ name: 'dayNames' })
export class DayNamesPipe implements PipeTransform {

  constructor (private _date: DateService) {}

  transform (val: Day[] | null | undefined, format: 'long' | 'short' | 'narrow' = 'long') {
    return (val ?? []).map(({ day }) => this._date.getTranslatedDayName(day, format)).join(', ');
  }
}


@Pipe({ name: 'formatMoment' })
export class FormatMomentPipe implements PipeTransform {
  transform (mnt: string | moment.Moment | Date | undefined | null, format: string, lang: string = 'en') {
    if ( ! mnt) return '';
    return moment.utc(mnt).locale(lang).format(format);
  }
}


@Pipe({ name: 'localeDate' })
export class LocaleDatePipe implements PipeTransform {

  constructor (private _translate: TranslateService) { }

  transform (
    date: string | moment.Moment | Date,
    dateStyle: 'long' | 'short' | 'medium' | 'full' = 'medium',
    timeStyle: 'long' | 'short' | 'medium' | 'full' = 'medium',
  ): string {
    const lang = this._translate.currentLanguage?.id ?? 'en';
    return new Intl.DateTimeFormat(lang, { dateStyle, timeStyle }).format(moment(date).toDate());
  }

}