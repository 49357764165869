import { NgModule                                         } from '@angular/core';
import { CommonModule                                     } from '@angular/common';
import { FormsModule,
         ReactiveFormsModule                              } from '@angular/forms';
import { ScrollingModule                                  } from '@angular/cdk/scrolling';

import { AppCommonModule                                  } from 'app/common/common.module';
import { ComponentsModule as AppComponentsModule          } from 'app/shared/components/components.module';
import { PipesModule                                      } from 'app/shared/pipes/pipes.module';
import { TranslationModule                                } from 'app/core/translate/translate.module';

import { ComponentsModule as AdminTablesComponentsModules } from '../../components/components.module';
import { AddEditComponent                                 } from './add-edit/add-edit.component';
import { ConfigureIdentifiersComponent                    } from './configure-identifiers/configure-identifiers.component';
import { ConfigureComputeEnvironmentComponent             } from './configure-compute-environment/configure-compute-environment.component';
import { IntegrationsComponent                            } from './integrations/integrations.component';

@NgModule({
  declarations: [
    AddEditComponent,
    ConfigureIdentifiersComponent,
    ConfigureComputeEnvironmentComponent,
    IntegrationsComponent
  ],
  exports: [
    AddEditComponent,
    ConfigureIdentifiersComponent,
    ConfigureComputeEnvironmentComponent,
    IntegrationsComponent
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    PipesModule,
    AppComponentsModule,
    TranslationModule,
    FormsModule,
    ReactiveFormsModule,
    ScrollingModule,
    AdminTablesComponentsModules
  ]
})
export class ComponentsModule { }
