import { Component,
         Inject,
         AfterViewInit,
         ViewChildren,
         QueryList                            } from '@angular/core';
import { MatExpansionPanel                    } from '@angular/material/expansion';
import { BehaviorSubject,
         delay,
         take                                 } from 'rxjs';
import { errorCodes                           } from "@royalschedule/input-verifier";
import $                                        from 'jquery';

import { MAT_DIALOG_DATA                      } from 'app/common';
import { DialogsService, InputAnalysisService } from 'app/shared/services';
import { Remark                               } from 'app/shared/services/input-analysis/types';
import { inOutAnimation                       } from 'app/shared/animations';


type Data = {
  did:       string;
  targetId?: string;
};

type Categories = 'errors' | 'issues' | 'notices';

export const categoriesDescription = [
  { key: 'errors'  satisfies Categories,  title: 'inputAnalysis.errors.title',  description: 'inputAnalysis.errors.description',  numberKey: 'numErrors',  icon: 'troubleshoot', class: 'error-red'      },
  { key: 'issues'  satisfies Categories,  title: 'inputAnalysis.issues.title',  description: 'inputAnalysis.issues.description',  numberKey: 'numIssues',  icon: 'troubleshoot', class: 'warning-orange' },
  { key: 'notices' satisfies Categories, title: 'inputAnalysis.notices.title', description: 'inputAnalysis.notices.description', numberKey: 'numNotices', icon: 'troubleshoot', class: 'remark-blue'    }
] as const;



@Component({
  selector: 'app-input-issues-dialog',
  templateUrl: './input-issues.component.html',
  styleUrls: ['./input-issues.component.scss'],
  animations: [ inOutAnimation ],
})
export class InputIssuesComponent implements AfterViewInit {
  protected readonly typeToken: { category: Categories, remarks: Remark[] };

  @ViewChildren('panel')
  private panels: QueryList<MatExpansionPanel>;

  protected did: string;

  protected tabIndex = 0;

  protected readonly errorCodes = {
    eventDays:             [errorCodes.event, 10, 0].join('.'),                    // no allowed day for event
    eventIntervals:        [errorCodes.event, 10, 1].join('.'),                    // no allowed interval time for event
    linkedEventsDays:      [errorCodes.forcedOverlappingEventsSet, 0].join('.'),   // no allowed day for linked events
    linkedEventsIntervals: [errorCodes.forcedOverlappingEventsSet, 1].join('.')    // no allowed interval for linked events
  }

  protected readonly categoriesDescription = categoriesDescription;

  protected readonly dialogContentHeight = new BehaviorSubject<number | null>(null);


  constructor(
    public inputAnalysis: InputAnalysisService,
    private _dialog:      DialogsService,
    @Inject(MAT_DIALOG_DATA)
    private _data: Data
  ) {
    this.did = _data.did;
  }


  ngAfterViewInit(): void {
    // (need a delay to not trigger a change detection error)
    const id = this._data.targetId;
    id && setTimeout(() => this.goTo(id), 0);
  }

  public goTo (remarkId: string) {

    // the first part of the id contains the category
    const category = remarkId.includes('.') ? remarkId.split('.').at(0) as undefined | 'error' | 'issue' | 'notice'
                   : remarkId == 'errors'  ? 'error'
                   : remarkId == 'issues'  ? 'issue'
                   : remarkId == 'notices' ? 'notice'
                   : undefined;
    let tabIndex: number | undefined;
    if      (category == 'error')  tabIndex = 1;
    else if (category == 'issue')  tabIndex = 2;
    else if (category == 'notice') tabIndex = 3;
    if (tabIndex != null) this.tabIndex = tabIndex!;

    const panel = this.panels.toArray()
      .map(x => ({ val: x, $elem: $(x._body.nativeElement).parent('mat-expansion-panel') }))
      .find(x => x.$elem.attr('id') == remarkId);
    if ( ! panel) return;

    // after the panel is expanded, scroll to it
    // (need to be fully expanded in order to scroll all the way if there are few items)
    panel.val.open();
    panel.val.afterExpand
    .pipe(
      take(1),
      delay(100)   // we need to wait a while otherwise the scrolling will not be accurate
    )
    .subscribe(() => {
      const $container = panel.$elem.parents('mat-dialog-content');
      const delta = $container.scrollTop()! + panel.$elem.offset()!.top - $container.offset()!.top;
      $container.animate({ scrollTop: delta }, 250);
    });
  }

  protected trackByFn (i: number, remark: Remark) {
    return i + ' ' + remark.id;
  }

  protected openOverlappableEventSetsDialog (remark: Remark) {
    const id = remark.entities.map(x => x.is == 'course' ? x.overlapGroup?.id : undefined).filter(Boolean)[0];
    this._dialog.openOverlapGroupsDialog(this.did, id)
  }
}
