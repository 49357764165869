import { Component,
         Input,
         ElementRef,
         OnDestroy,
         Optional,
         Self,
         EventEmitter,
         HostListener,
         ChangeDetectionStrategy,
         Output,
         ViewChild                 } from '@angular/core';
import { ControlValueAccessor,
         NgControl,
         Validators,
         FormControl               } from '@angular/forms';
import { MatFormFieldControl       } from '@angular/material/form-field';
import { FocusMonitor              } from '@angular/cdk/a11y';
import { coerceBooleanProperty     } from '@angular/cdk/coercion';
import { Subject                   } from 'rxjs';
import { CleaveOptions             } from 'cleave.js/options';
import $                             from 'jquery';

import { Validator                 } from './validators'


const multipleOf5Validator = (control: FormControl): null | { 'multiple-of-five': true } => {
  const val = control.value;

  // empty string is okay
  if (val == '') return null;

  // attempt to parse to number
  const num = parseInt(val);
  if (isNaN(num)) return { 'multiple-of-five': true };

  // must be equal to itself as a string
  if (num.toString() != val) return { 'multiple-of-five': true };

  // cannot be negative
  if (num < 0) return { 'multiple-of-five': true };

  // must be a multiple of 5
  if (num % 5 != 0) return { 'multiple-of-five': true };

  // is a multiple of 5
  return null;
}
const min5Validator = (control: FormControl): null | { 'min-five': true } => {
  const val = control.value;

  // empty string is not okay
  if ( ! val) return { 'min-five': true };

  // attempt to parse to number
  const num = parseInt(val);
  if (isNaN(num)) return { 'min-five': true };

  // must be equal to itself as a string
  if (num.toString() != val) return { 'min-five': true };

  // cannot be smaller than 5
  if (num < 5) return { 'min-five': true };

  // is a at least 5
  return null;
}

@Component({
  selector: 'app-form-field-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss'],
  providers: [
    {
      provide:     MatFormFieldControl,
      useExisting: TextComponent
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextComponent implements OnDestroy,
                                      ControlValueAccessor,
                                      MatFormFieldControl<string | null | undefined> {
  @ViewChild('input', { static: true }) input: ElementRef;
  @Output('onChange') emitter = new EventEmitter<string | null | undefined>();
  static nextId:      number        = 0;
  public stateChanges:Subject<void> = new Subject<void>();
  public focused:     boolean       = false;
  public errorState:  boolean       = false;
  public controlType: string        = 'text-input';
  public id:          string        = `text-input-${ TextComponent.nextId++ }`;
  public describedBy: string        = '';
  public onChange = (_: any) => {};
  public onTouched = () => {};

  public control = new FormControl<string>('');

  protected cleave?: CleaveOptions = { }
  private readonly cleave_number = {
    numeral:             true,
    delimiter:           '',
    numeralPositiveOnly: true,
    stripLeadingZeroes:  true
  } satisfies CleaveOptions;

  @HostListener('click') _click(){
    if (! this.focused)
      this.input.nativeElement.focus();
  }

  constructor (
    @Optional() @Self() public ngControl: NgControl,
    private _focusMonitor: FocusMonitor,
    private _elementRef:   ElementRef<HTMLElement>
  ) {
    _focusMonitor.monitor(_elementRef, true)
    .subscribe((origin) => {
      if (this.focused && ! origin) {
        this.onTouched();
        if (this.saveOnFocusLost)
          this._stateChange();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }


  private _stateChange(): void {
    if (this.valid && this._pristineValue !== this.control.value) {
      this.emitter.emit(this.value);
      this.control.markAsPristine();
      this._pristineValue = this.value;
    }
    this.value = this._pristineValue;
  }

  protected keydown (event: KeyboardEvent): void {
    if (event.key == 'Enter') {
      this._stateChange();
      $(`#${ this.id }`)[0].blur();
    }
  }

  get valid(): boolean {
    return this.control.value == null ? true : this.control.valid;
  }

  get empty () {
    return this.control.value === '';
  }

  get shouldLabelFloat() { return this.focused || !this.empty; }

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.control.disable() : this.control.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get saveOnFocusLost(): boolean { return this._saveOnFocusLost; }
  set saveOnFocusLost(value: boolean | string) {
    this._saveOnFocusLost = coerceBooleanProperty(value);
  }
  private _saveOnFocusLost = false;

  @Input()
  get value(): string | null | undefined {
    let val = this.control.value?.toString().trim() ?? '';
    if (this._omitCharacter) val = val.replace(this._omitCharacter, '');

    if (this.nullifyEmpty && ! val) return null;
    return val;
  }
  set value(_val: string | number | null | undefined) {
    let val = _val?.toString().trim() ?? '';
    if (this._omitCharacter) val = val.replace(this._omitCharacter, '');

    this._pristineValue = val;
    this.control.patchValue(val);
    this.control.updateValueAndValidity();
  }
  private _pristineValue: string | null | undefined = '';

  @Input()
  get requireValue(): boolean { return this._requireValue; }
  set requireValue(value: boolean) {
    this._requireValue = coerceBooleanProperty(value);
    if (this._requireValue)
      this.control.setValidators(Validators.required)
  }
  private _requireValue = false;

  @Input({ transform: coerceBooleanProperty })
  nullifyEmpty = false;

  @Input()
  get outlineOnFocus(): boolean { return this._outlineOnFocus; }
  set outlineOnFocus(value: boolean) {
    this._outlineOnFocus = coerceBooleanProperty(value);
    if (this._outlineOnFocus)
      this.control.setValidators(Validators.required)
  }
  private _outlineOnFocus = false;

  @Input()
  prefix?: string;

  @Input()
  suffix?: string;

  @Input()
  get voidText(): string { return this._voidText; }
  set voidText(value: string | number | null | undefined) {
    this._voidText = value?.toString() ?? '';
    this.stateChanges.next();
  }
  private _voidText: string = '';


  @Input()
  get omitCharacter(): string { return this._omitCharacter; }
  set omitCharacter(value: string) {
    this._omitCharacter = value;
    this.value = this._pristineValue;
  }
  private _omitCharacter = '';

  @Input()
  maxWidth: string = 'auto';

  @Input()
  set validate(_val: 'SSN' | 'email' | 'number' | 'multiple-of-five' | 'multiple-of-5-min-0' | null) {
    switch (_val) {
      case 'SSN':
        this.control.setValidators(Validator.SSN);
        break;
      case 'email':
        this.control.setValidators(Validators.email);
        break;
      case 'number':
        this.cleave = this.cleave_number;
        this.control.setValidators(Validators.pattern('^[0-9]*$'));
        break;
      case 'multiple-of-five':
        // must be a number and the last digit must be 0 or 5
        this.cleave = this.cleave_number;
        this.control.setValidators(multipleOf5Validator);
        break;
      case 'multiple-of-5-min-0':
        this.cleave = this.cleave_number;
        this.control.setValidators([multipleOf5Validator, min5Validator]);
        break;
      default:
        break;
    }
    this.control.updateValueAndValidity();
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent) {
    this.input.nativeElement.focus();
  }

  writeValue(val: string): void {
    this.value = val ?? '';
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  _handleInput(): void {
    this.onChange(this.value);
  }

  static ngAcceptInputType_disabled: boolean | string | null | undefined;
  static ngAcceptInputType_required: boolean | string | null | undefined;
}
