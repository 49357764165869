import { Injectable,
         NgZone                          } from '@angular/core';
import { SwUpdate                        } from '@angular/service-worker';
import { interval                        } from 'rxjs';

import Package                             from '@app/../../package.json';
import { environment                     } from '@env/environment';
import { MatDialog,
         MatDialogRef                    } from '@app/common';
import { LoggerService,
         BroadcastService,
         UserInactivityService           } from '@app/core';
import { DialogData,
         VersionComponent                } from '@app/shared/dialogs/version/version.component';
import { UnrecoverableAppStateComponent  } from '@app/shared/dialogs/unrecoverable-app-state/unrecoverable-app-state.component';

const namespace  = 'VERSION_SERVICE';


type AppData = undefined | {
  version?: string;
  timestamp?: string;
};

@Injectable({
  providedIn: 'root'
})
export class VersionService {

  private versionDialogRef?:       MatDialogRef<VersionComponent,               { reload?: true, skip?: true }>;
  private unrecoverableDialogRef?: MatDialogRef<UnrecoverableAppStateComponent, { reload?: true }>;

  constructor (
    private readonly _dialog:         MatDialog,
    private readonly _broadcast:      BroadcastService,
    private readonly _update:         SwUpdate,
    private readonly _logger:         LoggerService,
    private readonly _userInactivity: UserInactivityService,
                     ngZone:          NgZone
  ) {
    // poll for updates every 5 minutes
    // (run outside of angular to not affect the stability)
    ngZone.runOutsideAngular(() => {
      interval(5 * 60 * 1000)
      .subscribe(() => {
        if ( ! this._update.isEnabled) return;

        ngZone.run(() => {
          this._update.checkForUpdate()
          .then()
          .catch(err => this._logger.error(err));
        });
      });
    });

    // listen for new version updates
    let currHash: string | undefined;
    this._update.isEnabled && this._update.versionUpdates.subscribe(x => {
      if (x.type == 'VERSION_READY') {
        this.openVersionDialog(x.latestVersion.appData);
      }
      else if (x.type == 'NO_NEW_VERSION_DETECTED') {
        // the "VERSION_DETECTED" and "VERSION_READY" events are sometimes skipped due to unknown reasons
        if (currHash && currHash != x.version.hash) {
          console.log('version change detected from "NO_NEW_VERSION_DETECTED" event')
          this.openVersionDialog(x.version.appData);
        }
        // update hash
        currHash = x.version.hash;
      }
      else if (x.type == 'VERSION_INSTALLATION_FAILED') {
        this._logger.error(new Error(`Failed to install new version: ${ x.error }`))
      }
    });

    // listen for broken app state that cannot be fixed without a reload
    this._update.isEnabled && this._update.unrecoverable.subscribe(x => {
      this._logger.error(new Error(`Unrecoverable state detected: ${ x.reason }`));
      this.openUnrecoverableDialog();
    });

    // listen for skip broadcast
    this._broadcast
    .subscribeToChannel('skip', false, namespace)
    .subscribe(() => this.versionDialogRef?.close());
  }


  private openVersionDialog (appData: AppData) {
    // do not open dialog if already open
    if (this.versionDialogRef) return;

    // fetch "version" and "timestamp" from app data
    const { version, timestamp } = appData || { };
    const latestVersion = (version ?? '-') + '/' + (timestamp ?? '-');

    // fetch "version" from package.json and "timestamp" from environment
    const currentVersion = (Package.version ?? '-') + '/' + (environment.timestamp ?? '-');

    this.versionDialogRef = this._dialog.open<VersionComponent, DialogData>(VersionComponent, {
      disableClose: true,
      maxWidth: 500,
      data: { currentVersion, latestVersion }
    });
    this.versionDialogRef.afterClosed().subscribe(x => {
      // reload
      if (x?.reload) this._reload();

      // skip notifying about this version
      if (x?.skip) this._broadcast.emit('skip', true, namespace);

      // reset dialog reference
      delete this.versionDialogRef;
    });
  }


  private openUnrecoverableDialog () {
    // do not open dialog if already open
    if (this.unrecoverableDialogRef) return;

    this.unrecoverableDialogRef = this._dialog.open<UnrecoverableAppStateComponent>(UnrecoverableAppStateComponent, {
      disableClose: true,
      maxWidth: 500
    });
    this.unrecoverableDialogRef.afterClosed().subscribe(x => {
      // reload
      if (x?.reload) this._reload();

      // reset dialog reference
      delete this.unrecoverableDialogRef;
    });
  }


  private _reload () {
    // use the user inactivity service to reload all tabs
    this._userInactivity.reloadAllTabs();
  }

}
