import { PushNotificationService } from "./push-notification.service";
import { Button, Category, Message } from "./types";


type Args = {
  message:     Message,
  category:    Category,
  duration?:   number | false;
  silent?:     boolean;

  title?:      string;
  titleClass?: string;
  icon?:       string;
  iconClass?:  string;
  buttons?:    Button[];
}


export function pushConfirmation (
  this:     PushNotificationService,
  message:  Message,
  options?: Partial<Omit<Args, 'title' | 'icon' | 'iconClass' | 'titleClass' | 'buttons'> >
) {
  // default message error
  if ( ! message)
    message = this._translate.instant('common.generalErrorMessage');

  let args: Partial<Args> = options ?? {};
  args.title      = this._translate.instant('common.confirmation');
  args.icon       = 'done';
  args.iconClass  = 'ok-green';
  args.titleClass = 'ok-green';
  args.buttons    = [{ text: this._translate.instant('common.hide'), action: () => {} }];

  args.duration = args?.duration ?? 5000;

  this.push(message, 'generalConfirmation', args);
}