import { Injectable                      } from '@angular/core';
import { Observable                      } from 'rxjs';
import { StorageService                  } from 'app/core/storage/storage.service';
import { BroadcastChannel as PollyFill   } from 'broadcast-channel';

import { Broadcast                       } from './broadcast.interface';

@Injectable({
  providedIn: 'root'
})
export class BroadcastService implements Broadcast {

  constructor(private storage: StorageService) {
    this.mimicServer();
  }

  private mimicServer() {
    let self: BroadcastService = this;

    if (window.hasOwnProperty('BroadcastChannel')) {
      /*
        for browsers that support BrowserChannel
      */
      let inChannel = new BroadcastChannel('set_event');
      let outChannel = new BroadcastChannel('get_events');
      inChannel.addEventListener('message', function (event:any) {
        if (event.data.sender === self.storage.getThreadID()) {
          outChannel.postMessage({
            data: event.data.data,
            sender: event.data.sender
          });
        }

      });
    } else {
      /*
        for browsers that don't support BrowserChannel
      */
      let inChannel = new PollyFill('set_event');
      let outChannel = new PollyFill('get_events');
      inChannel.addEventListener('message', function (event:any) {
        if (event.data.sender === self.storage.getThreadID()) {
          outChannel.postMessage({
            data: event.data,
            sender: event.sender
          });
        };
      });
    }
  }

  public subscribeToChannel(channel: string, onlyExternal: boolean = true, namespace: string = '') {
    return new Observable( (observer) => {
      let ch: BroadcastChannel | PollyFill;
      if (window.hasOwnProperty('BroadcastChannel')) {
        /*
          for browsers that support BrowserChannel
        */
        ch = new BroadcastChannel(`${ namespace }/${ channel }`);
        ch.addEventListener('message', (event:any) => {
          if (! onlyExternal || (event.data.sender !== this.storage.getThreadID()))
            observer.next(event.data.data);
        });
      } else {
        /*
          for browsers that don't support BrowserChannel
        */
        ch = new PollyFill(`${ namespace }/${ channel }`);
        ch.addEventListener('message', (event:any) => {
          if (! onlyExternal || (event.sender != this.storage.getThreadID()))
            observer.next(event.data);
        });
      }

      return () => {
        ch.close();
      }
    })
  }

  public emit(channel:string, data: any, namespace: string = '') {
    let ch;
    if (window.hasOwnProperty('BroadcastChannel')) {
      /*
        for browsers that support BrowserChannel
      */
      ch = new BroadcastChannel(`${ namespace }/${ channel }`);
      ch.postMessage({
        data: JSON.parse(JSON.stringify(data)),
        sender: this.storage.getThreadID()
      });
    } else {
      /*
        for browsers that don't support BrowserChannel
      */
      ch = new PollyFill(`${ namespace }/${ channel }`);
      ch.postMessage({
        data: JSON.parse(JSON.stringify(data)),
        sender: this.storage.getThreadID()
      });

    }

    ch.close();
  }
}