import { Component,
         EmbeddedViewRef,
         Injectable,
         InjectionToken,
         Injector,
         ViewContainerRef                } from '@angular/core';
import _                                   from 'lodash';
import $                                   from 'jquery';
import { Subject                         } from 'rxjs';

import { EnvironmentService,
         TranslateService                } from '@app/core';
import { VideoGuideComponent             } from '@app/view/private/tutorial/components/video-guide/video-guide.component';

import { Tutorial                        } from './tutorials.types';
import { TUTORIALS                       } from './tutorials.constants';

export const TUTORIAL_DATA = new InjectionToken<any>('TUTORIAL_DATA');

@Injectable({
  providedIn: 'root'
})
export class TutorialsService {
  protected _viewContainerRef: ViewContainerRef;
  private _map = new Map<Tutorial['id'], Tutorial>(TUTORIALS.map((tutorial) => [tutorial.id?.toString(), tutorial]));

  constructor(private _environment: EnvironmentService,
              private _translate:   TranslateService,
              private _injector:    Injector) { }

  get tutorials(): Tutorial[] {
    return TUTORIALS.filter((tutorial: Tutorial) => (
      tutorial.collections == null ? true : tutorial.collections.every(x => this._environment.hasCollection(x))
    ));
  }

  /**
   * @param id Tutorial ID
   * @description Returns true if the tutorial is available in the current language.
   */
  public has(id: Tutorial['id']): boolean {
    const tutorial = this._map.get(id);
    return tutorial?.source[this._translate.currentLanguage?.id ?? 'en'] != null;
  }

  /**
   *
   * @param id Tutorial ID
   * @returns Tutorial object if the tutorial is available in the current language, undefined otherwise.
   */
  public get(id: Tutorial['id']): Tutorial | undefined {
    return this._map.get(id);
  }

  /**
   * @param id Tutorial ID
   * @param _viewContainerRef ViewContainerRef of the view that will host the tutorial
   * @description Opens the tutorial with the given ID.
   */
  public open (
    tutorial:          Tutorial['id'] | Tutorial,
    _viewContainerRef: ViewContainerRef,
    event?:            Event,
    options:           { zIndex?: number } = { }
  ): void {
    this._viewContainerRef = _viewContainerRef;
    if (_.isString(tutorial))
      tutorial = this.get(tutorial);

    if (! tutorial)
      return;

    const componentRef = this._viewContainerRef.createComponent<{ onDestroy: Subject<void> }>(this._getComponent(tutorial), { injector: this._createInjector({ selected: tutorial, tutorials: this.tutorials }) });
    const domElem =  (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

    if (event) {
      const container          = $(event.target!).closest('.summary-card');

      const top                = container.offset()!.top;
      const left               = container.offset()!.left;
      const width              = container.innerWidth();

      domElem.style['top']     = `${top}px`;
      domElem.style['left']    = `${left}px`;
      domElem.style['width']   = `${width}px`;
      domElem.classList.add('animate');
      domElem.classList.add('mat-elevation-z2');
    } else {
      domElem.style['top']     = `0px`;
      domElem.style['left']    = `0px`;
      domElem.style['width']   = '100vw';
      domElem.style['height']  = '100vh';
    }

    if (options.zIndex != null) domElem.style['zIndex'] = options.zIndex.toString();

    domElem.classList.add('expanded-card');
    domElem.classList.add('white-bg');

    document.body.appendChild(domElem);

    componentRef.instance.onDestroy.subscribe(() => componentRef.destroy());
  }

  private _createInjector(data: { selected: Tutorial, tutorials: Tutorial[] }) {
    return Injector.create({
      providers: [{
        provide: TUTORIAL_DATA,
        useValue: data
      }]
    });
  }

  private _getComponent(tutorial: Tutorial) {
    switch (tutorial.type) {
      case 'video':
      default:
        return VideoGuideComponent;
    }
  }
}