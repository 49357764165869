<app-container>
  <div *ngIf="loading | async; else loaded" class="loading flex-align-center" [@inAnimation]>
    {{ 'common.loading' | translate }}...
  </div>
</app-container>

<ng-template #loaded>

  <ng-container *ngFor="let x of dataArray">

    <mat-form-field appearance="outline" subscriptSizing="dynamic" class="white no-outline"
                    *ngIf="(x.options | async)?.length" [@inAnimation]
                    [ngClass]="x.width"
                    [class.is-selected]="isSelected(x.ctrl.value)"
    >
      <mat-icon matPrefix>{{ x.icon }}</mat-icon>

      <!-- multiple -->
      <ng-container *ngIf="x.multiselect; else single">
        <mat-select #select multiple [panelWidth]="x.panelWidth" panelClass="dark-scrollbar"
          [formControl]="x.ctrl"
          [compareWith]="compareWithFn"
          [placeholder]="x.label.plural | translate"
          (selectionChange)="userModified.next()"
        >
          <mat-select-trigger>
            <ng-container *ngTemplateOutlet="triggerTmpl; context: { selected: select.selected }"></ng-container>
          </mat-select-trigger>

          <mat-option #optionRef [value]="all" class="all" (click)="tryToggleAll(x.collection, all, optionRef.selected)" (key.enter)="tryToggleAll(x.collection, all, optionRef.selected)">{{ 'common.toggle_all' | translate}}</mat-option>
          <mat-divider></mat-divider>

          <mat-option #optionRef *ngFor="let option of x.options | async" [value]="option" (click)="tryToggleAll(x.collection, option, optionRef.selected)" (key.enter)="tryToggleAll(x.collection, option, optionRef.selected)">
            <ng-container *ngTemplateOutlet="optionTmpl; context: { option: option }"></ng-container>
          </mat-option>
        </mat-select>
      </ng-container>

      <!-- single -->
      <ng-template #single>
        <mat-select #select [panelWidth]="x.panelWidth" panelClass="dark-scrollbar"
          [formControl]="x.ctrl"
          [compareWith]="compareWithFn"
          [placeholder]="x.label.singular| translate"
        >
          <mat-select-trigger>
            <ng-container *ngTemplateOutlet="triggerTmpl; context: { selected: select.selected }"></ng-container>
          </mat-select-trigger>

          <mat-option *ngIf="x.nullable" class="none">{{ 'common.none_singular' | translate }}</mat-option>
          <mat-option *ngFor="let opt of x.headerOptions" [value]="opt.value" class="header">{{ opt.name }}</mat-option>
          <mat-divider *ngIf="x.nullable || x.headerOptions?.length"></mat-divider>

          <mat-option *ngFor="let option of x.options | async" [value]="option">
            <ng-container *ngTemplateOutlet="optionTmpl; context: { option: option }"></ng-container>
          </mat-option>
        </mat-select>
      </ng-template>

    </mat-form-field>

  </ng-container>

</ng-template>


<ng-template #triggerTmpl [templateTypes]="selectedTypeToken" let-selected="selected">
  <div class="no-wrap" *ngIf="selected | standardizeSelected; let val">
    <!-- if single week -->
    <span *ngIf="isAll(val); else week">
      {{ 'common.all' | translate | capitalizeFirst }}
    </span>
    <!-- week -->
    <ng-template #week>
      <span *ngIf="isWeek(val[0]); else day">
        {{ 'common.week_short' | translate | capitalizeFirst }} {{ val[0].number }}
        <sup>{{ val[0].excludedDays.length ? -val[0].excludedDays.length : '' }}</sup>
      </span>
    </ng-template>
    <!-- day -->
    <ng-template #day>
      <span *ngIf="isDay(val[0]); else other">
        {{ 'common.day_' + val[0].id | translate }}
      </span>
    </ng-template>
    <!-- other -->
    <ng-template #other>
      <span >{{ displayName(val[0]) ?? ('common.unknown' | translate) }}</span>
    </ng-template>
    <span *ngIf="isArray(val) && val.length > 1">
      + {{ val.length - 1 }} {{ 'common.' + (val.length > 2 ? 'others' : 'other') | translate | lowercase }}
    </span>
  </div>
</ng-template>


<ng-template #optionTmpl [templateTypes]="optionTypeToken" let-option="option">
  <!-- if single week -->
  <div *ngIf="isWeek(option); else day">
    <span class="week-no">
      {{ 'common.week_short' | translate | capitalizeFirst }} {{ option.number }}
      <sup>{{ option.excludedDays.length ? -option.excludedDays.length : '' }}</sup>
    </span>
    <span class="spacer"></span>
    <span class="date-range">{{ option.string }}</span>
  </div>
  <!-- day -->
  <ng-template #day>
    <span *ngIf="isDay(option); else other">
      {{ 'common.day_' + option.id | translate }}
    </span>
  </ng-template>
  <!-- other -->
  <ng-template #other>
    <div>{{ option.displayName ?? ('common.unknown' | translate) }}</div>
  </ng-template>
</ng-template>