import moment                              from 'moment';

import { Partner,
         Role,
         roles,
         storageConstants                } from 'app/constants';
import { OrganizationType,
         Theme,
         organizationTypes,
         themes                          } from '../environment/environment.types';
import { Session                         } from './auth.interface';
import { AuthService                     } from './auth.service';


/** @description Returns the currently active access token */
export function getToken (this: AuthService): string | undefined {
  return this._loadAndValidateSessions().at(-1)?.accessToken;
}

/** @description Returns the expiration time of the currently active access token */
export function getTokenExpirationTime (this: AuthService): string | undefined {
  return this._loadAndValidateSessions().at(-1)?.expiresAt;
}

/** @description Returns the username. The username is global and thus the same for all currently authenticated organizations */
export function getUsername (this: AuthService): string | undefined {
  return this._storage.get(storageConstants.USERNAME);
}

/** @description Returns the user id. THe user id is global and thus the same for all currently authenticated organizations */
export function getUserId (this: AuthService): string | undefined {
  return this._storage.get(storageConstants.USER_ID);
}

/** @description Returns the date the user was created */
export function getCreatedAt (this: AuthService): string | undefined {
  return this._storage.get(storageConstants.USER_CREATED_AT);
}

/** @description Returns the partner, if any */
export function getAssociatedPartner (this: AuthService): Partner | undefined {
  return this._loadAndValidateSessions().at(-1)?.associatedPartner;
}

/** @description Returns a hierarchical list of the organization names the user is currently authenticated with */
export function getOrganizationNames (this: AuthService): string[] {
  return this._loadAndValidateSessions()
    .map(x => {
      if (x.role === 'admin')   return 'Admin';
      if (x.role === 'partner') return x.associatedPartner?.capitalizeFirst();
      return x.organizationName
    })
    .filter(Boolean);
}

/** @description Returns a hierarchical list of the organizations (token, roles, etc) the user is currently authenticated with. Details which are incomplete or deemed invalid are removed */
export function _loadAndValidateSessions (
  this: AuthService
): Session[] {
  const raw = this._storage.get(storageConstants.AUTH_DETAILS) as unknown;
  if (! (typeof raw == 'string')) return [];

  // parse the string
  let val: unknown;
  try {
    val = JSON.parse(raw) as unknown;
  } catch (error) {
    this._logger.error(error);
    return [];
  }

  // must be an array
  if ( ! (val instanceof Array)) return [];

  // remove all invalid entries
  return val.filter((_x: unknown) => {
    // ensure that the entry is an object
    if ( ! _x || typeof _x !== 'object') return false;
    const x = _x as Record<string, unknown>;

    // ensure that the required fields are present and of the correct type
    if ( ! ('accessToken' in x) || typeof x.accessToken !== 'string') return false;
    if ( ! ('expiresAt'   in x) || ! (typeof x.expiresAt === 'string' && moment(x.expiresAt).isValid() )) return false;
    if ( ! ('role'        in x) || ! (typeof x.role      === 'string' && roles.includes(x.role as Role))) return false;

    // ensure that the optional fields are of the correct type
    if ('refreshToken'     in x && typeof x.refreshToken        !== 'string') return false;
    if ('companyName'      in x && typeof x.companyName         !== 'string') return false;
    if ('theme'            in x && ! (typeof x.theme            === 'string' && themes           .includes(x.theme            as Theme           ))) return false;
    if ('organizationType' in x && ! (typeof x.organizationType === 'string' && organizationTypes.includes(x.organizationType as OrganizationType))) return false;

    return true;
  });
}