import { Component,
         Input,
         OnDestroy                       } from '@angular/core';
import moment                              from 'moment';

import { TranslateService                 } from 'app/core';
import { DateService                      } from 'app/shared/services';

import { LockedTime                       } from 'app/shared/interfaces';
import { debounceTime, Subject, takeUntil } from 'rxjs';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ReadableLockedTimesPipe } from '@app/shared/pipes/common/common.pipe';

@Component({
  selector: 'app-form-field-locked-times-display-value',
  templateUrl: './display-value.component.html',
  styleUrls: ['./display-value.component.scss'],
  providers: [ReadableLockedTimesPipe]
})
export class DisplayValueComponent  implements OnDestroy {

  private onDestroy:     Subject<void> = new Subject<void>();
  private onLabelChange: Subject<void> = new Subject<void>();

  public inherits: boolean;
  public label:    string;
  public tooltip:  string;

  public erroneous: Boolean;

  constructor (private _translate:           TranslateService,
               private _date:                DateService,
               private _readableLockedTimes: ReadableLockedTimesPipe) {

    this.onLabelChange
    .pipe(takeUntil(this.onDestroy))
    .subscribe(() => this._updateLabel());
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }


  private _updateLabel(): void {

    if (this.value == null) {
      this.inherits = true;
      this.label    = this.voidText;
      this.tooltip  = '';
      return;
    }

    this.inherits = false;

    if (this.value.length == 0) {
      if (this.inherit) {
        this.label    = '-';
        this.tooltip  = this._translate.instant('common.none_plural');
        return;
      } else {
        this.inherits = true;
        this.label    = this.voidText;
        this.tooltip  = '';
        return;
      }
    }

    this.label   = this._readableLockedTimes.transform(this.value);
    this.tooltip = this._readableLockedTimes.transform(this.value, true).split(', ').join('\r\n\n');
  }

  @Input()
  get value(): LockedTime[] { return this._value; }
  set value(value: LockedTime[] | null | undefined) {
    this._value = value ?? [];
    this.onLabelChange.next();
  }
  private _value: LockedTime[];

  @Input()
  get voidText(): string { return this._voidText; }
  set voidText(value: string) {
    this._voidText = value;
    this.onLabelChange.next();
  }
  private _voidText: string = '';


  @Input()
  get inherit(): boolean { return this._inherit; }
  set inherit(value: boolean | string) {
    this._inherit = coerceBooleanProperty(value);
    this.onLabelChange.next();
  }
  private _inherit = false;

}
