<mat-form-field appearance="outline" subscriptSizing="dynamic" class="white no-outline wide">
  <mat-icon matPrefix>{{ icon }}</mat-icon>

  <mat-select #select
    [placeholder]="placeholder"
    hideSingleSelectionIndicator
    [value]="value"
    [compareWith]="compareWith"
    panelClass="no-vertical-padding flex-option no-right-padding-option no-max-height {{ _panelClass | async }} {{ transparentPanel ? 'transparent-panel' : '' }}"
    [class.search-visible]="search.value"
    (valueChange)="onValueChange.emit($event)"
    (opened)="scrollToSelected(select, scroll)"
    (closed)="search.value = ''"
    (keyup)="showSearchField($event, search)"
  >
    <mat-select-trigger>{{ value?.entity | displayName }}</mat-select-trigger>

    <div class="search-wrapper" [class.visible]="search.value" [class.mat-elevation-z2]="search.value">
      <app-search #search (keydown.arrowDown)="goToList(select)" (onChange)="scroll.scrollToIndex(0)"></app-search>
    </div>
    <mat-option disabled *ngIf="(onOptions | async | filterOptions:(search.onValue | async)).length == 0">{{ 'common.no_match' | translate }}</mat-option>

    <!-- the min and max buffer sizes are important as otherwise the rendering is laggy and the last item sometimes missing -->
    <cdk-virtual-scroll-viewport #scroll
      itemSize="48" minBufferPx="1000" maxBufferPx="1000"
      class="dark-vertical-scrollbar" [style.min-height.px]="computeHeight(onOptions | async | filterOptions:(search.onValue | async), search.onValue | async, 48)"
    >
      <mat-option *ngIf=" ! (search.onValue | async)" class="default-option">{{ 'common.none_singular' | translate }}</mat-option>
      <mat-option *cdkVirtualFor="let entity of (onOptions | async | filterOptions:(search.onValue | async))" [value]="entity">
        <span class="display-value">{{ entity | displayName }}</span>
        <span class="spacer"></span>
        <button mat-icon-button
                matSuffix
                *ngIf="openInNew"
                (click)="onOpenInNew.emit(entity); $event.stopPropagation()">
          <mat-icon class="royal-icon">open_in_new</mat-icon>
        </button>
      </mat-option>
    </cdk-virtual-scroll-viewport>

    <!-- the selected option must be present if the trigger display value should be displayed -->
    <mat-option class="hidden-mat-option" style="display: none" [value]="value"></mat-option>

  </mat-select>
</mat-form-field>
