import { Component,
         ViewChild,
         ElementRef,
         AfterViewInit,
         HostListener,
         OnDestroy,                      } from '@angular/core';
import { takeUntilDestroyed              } from '@angular/core/rxjs-interop';
import { Subject                         } from 'rxjs';
import $                                   from 'jquery';


function getX (elem: JQuery<HTMLElement>): number {
  let matrix = elem.css('transform');
  const floatPattern = '[+-]?\\d+(\\.\\d+)?';
  const re = new RegExp(`matrix\\(${floatPattern}, ${floatPattern}, ${floatPattern}, ${floatPattern}, (${floatPattern}), ${floatPattern}\\)`);
  return parseFloat(matrix.match(re)?.[5] ?? '0');
}


@Component({
  selector: 'app-container',
  templateUrl: './toolbar-container.component.html',
  styleUrls: ['./toolbar-container.component.scss']
})
export class ToolbarContainerComponent implements AfterViewInit, OnDestroy {

  @ViewChild('wrapper') wrapper: ElementRef;
  @ViewChild('content') content: ElementRef;

  @HostListener('window:resize', ['$event'])
  _onResize () {
    this.onCheckWrapperOverflow.next();
  }

  // scrolling and overflowing handling
  private onCheckWrapperOverflow = new Subject<void>();
  protected isOverflowing = false;
  protected isScrollable: { left: boolean, right: boolean };

  private readonly scrollCallback = () => this.onCheckWrapperOverflow.next();

  constructor () {

    // checks whether the wrapper is overflowing
    this.onCheckWrapperOverflow
    .pipe(takeUntilDestroyed())
    .subscribe(() => this.checkWrapperOverflow());
  }


  ngAfterViewInit () {
    // if the wrapper scrolls, scroll back to zero
    $(this.wrapper.nativeElement).on('scroll', this.scrollCallback);
  }

  ngOnDestroy () {
    // unsubscribe from scroll event as to not leak memory
    $(this.wrapper.nativeElement).off('scroll', this.scrollCallback);
  }

  private computeScrollableStatus (x?: number) {
    const $wrapper = $(this.wrapper.nativeElement);
    const $content = $(this.content.nativeElement);

    // if not provided, compute the current scroll position
    if (x == null) {
      x = getX($content);
    }

    const totScrollableDistance = $content.width()! - $wrapper.width()!;
    this.isScrollable = {
      left:  x < 0,
      right: x > -totScrollableDistance
    }
  }

  private checkWrapperOverflow () {
    // the element must have been initialized
    if ( ! this.wrapper?.nativeElement) return;
    const $wrapper = $(this.wrapper.nativeElement);
    const $content = $(this.content.nativeElement);

    if ($wrapper.width()! < $content.width()!) {
      this.isOverflowing = true;
      $wrapper.addClass('overflowing');
    } else {
      this.isOverflowing = false;
      $wrapper.removeClass('overflowing');
      $content.css('transform', `translateX(0px)`);
    }

    // force scroll to zero
    $wrapper.scrollLeft(0);

    // clamp the offset position
    this.scroll();
  }

  protected onResize () {
    // console.log('element resized');
    this.onCheckWrapperOverflow.next();
  }

  protected scroll (direction?: 'left' | 'right') {
    const $wrapper = $(this.wrapper.nativeElement);
    const $content = $(this.content.nativeElement);

    // compute the new scroll position
    let x = getX($content);
    if (direction) {
      x += (direction === 'left' ? 1 : -1) * $wrapper.width()! / 3;
    }

    // if the new scroll position is out of bounds, clamp it
    const totScrollableDistance = $content.width()! - $wrapper.width()!;
    x = Math.min(0, Math.max(x, -totScrollableDistance));

    // scroll to new scroll position
    $content.css('transform', `translateX(${ x }px)`);

    // compute available scroll distances
    this.computeScrollableStatus(x);
  }

}