import { NgModule                        } from '@angular/core';
import { CommonModule                    } from '@angular/common';
import { FormsModule,
         ReactiveFormsModule             } from '@angular/forms';
import { NgxCleaveDirectiveModule        } from 'ngx-cleave-directive';

import { TranslationModule               } from 'app/core/translate/translate.module';
import { PipesModule                     } from 'app/shared/pipes/pipes.module';
import { AppCommonModule                 } from 'app/common/common.module';
import { ComponentsModule                } from 'app/shared/components/components.module';

import { DurationWithVariationComponent  } from './duration-with-variation.component';


@NgModule({
  declarations: [
    DurationWithVariationComponent
  ],
  exports: [
    DurationWithVariationComponent
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    TranslationModule,
    FormsModule,
    PipesModule,
    ComponentsModule,
    ReactiveFormsModule,
    NgxCleaveDirectiveModule
  ]
})
export class DurationWithVariationModule { }
