import { Component,
         Input,
         Output,
         EventEmitter                    } from '@angular/core';
import { UntypedFormGroup,
         UntypedFormBuilder,
         Validators                      } from '@angular/forms';
import $                                   from 'jquery';
import _                                   from 'lodash';

import { Person                          } from '@app/shared/interfaces';

type type = 'row' | 'default';

export interface Options {
  showDisplayName?: boolean,
  showDays?:        boolean,
}

@Component({
  selector: 'app-person-form',
  templateUrl: './persons.component.html',
  styleUrls: ['./persons.component.scss']
})
export class PersonsComponent {
  @Output() onSubmit:                 EventEmitter<void> = new EventEmitter<void>();
  @Input('options') options:          Options;
  @Input()          type:             type             = 'default';
  public form:                        UntypedFormGroup;

  constructor(private _fb: UntypedFormBuilder) {
    this.form = this._fb.group({
      firstName:      [undefined, []],
      lastName:       [undefined, []],
    });
  }

  ngOnInit(): void {
  }

  public keydown(event: KeyboardEvent): void {
    if (event.key == 'Enter' && this.form.valid) {
      this.onSubmit.next();
    }
  }

  @Input()
  set focus(_field: keyof Person) {
    this._focusElement = $(`#${ _field }`)[0];
    setTimeout(() => {
      this._focusElement?.focus();
    });
  }
  private _focusElement: HTMLElement;

  public resetForm():void {
    this.form.reset();
  }

  private mapForm (val: any): { displayName?: string, ids?: string } {
    return _.pickBy(_.pick(val, Object.keys(this.form.controls)), _.identity);
  }

  public getForm() {
    return this.mapForm(this.form.value);
  }
}