<h1 matDialogTitle>
  <span [class.clickable-title]="tabIndex == 1" (click)="gotoCoursesTab($event)">{{ 'common.parallel_courses' | translate }}</span>
  <mat-icon class="royal-text-secondary" *ngIf="tabIndex == 1" @inOutAnimation>chevron_right</mat-icon>
  <span *ngIf="tabIndex == 1" @inOutAnimation>{{ 'common.linked_events' | translate }}</span>
  <span class="spacer"></span>
  @if (dragDisabled | async) {
    <mat-icon color="primary"
      @inOutAnimation
      class="rotating"
      [matTooltip]="'common.loading' | translate"
      cypress="loading"
    >
      loop
    </mat-icon>
  }
  @if (tutorial.has('31')) {
    <button mat-icon-button tabindex="-1"
      [matTooltip]="'common.open_tutorial' | translate"
      (click)="openTutorial()"
    >
      <mat-icon>help_outline</mat-icon>
    </button>
  }
</h1>

<mat-dialog-content class="overflow-hidden">
  <app-loading [loading]="loading | async"></app-loading>

  <mat-tab-group [(selectedIndex)]="tabIndex">
    <mat-tab label="courses" bodyClass="dark-vertical-scrollbar">
      <ng-container [cdkPortalOutlet]="coursesComponentPortal" (attached)="courseComponentAttached($event)"></ng-container>
    </mat-tab>
    <mat-tab label="events" bodyClass="dark-vertical-scrollbar">
      <ng-container [cdkPortalOutlet]="eventsComponentPortal" (attached)="eventsComponentAttached($event)"></ng-container>
    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>

<mat-dialog-actions>

  @if (tabIndex == 1) {
    <button mat-button tabindex="-1"
      *ngIf="tabIndex == 1" @inOutAnimation
      (click)="gotoCoursesTab($event)" (keydown.enter)="gotoCoursesTab($event)"
    >
      {{ 'common.back' | translate }}
    </button>
  }

  <span class="spacer"></span>

  <button mat-button mat-dialog-close tabindex="-1">
    {{ 'common.close' | translate }}
  </button>

  @if (tabIndex == 0) {
    @if (numAutoLinkable$ | async; as numAutoLinkable) {
      <button mat-button color="primary" tabindex="-1"
        [matTooltip]="'shared.dialogs.overlappableEventSets.auto-link_N.description' | translate:{ N: numAutoLinkable }"
        (click)="autoLink()"
      >
        {{ 'shared.dialogs.overlappableEventSets.auto-link_N' | translate:{ N: numAutoLinkable } }}
      </button>
    } @else {
      <span [matTooltip]="'shared.dialogs.overlappableEventSets.unable_to_auto-link_N.description' | translate">
        <button mat-button disabled>
          {{ 'shared.dialogs.overlappableEventSets.auto-link_N' | translate:{ N: 0 } }}
        </button>
      </span>
    }
  }

</mat-dialog-actions>