<div class="wrapper">
  <input #fileInput hidden
    type="file"
    accept=".xlsm,.xlsx,.xls,.json,.mdb,.csv,.txt"
    name="file"
    (change)="fileChanged($event)"
  >

  <button mat-flat-button color="primary"
    class="auto-height"
    [disabled]="loading"
    (click)="fileInput.click()"
  >
    <mat-icon fontSet="material-icons-outlined" class="rotating" *ngIf="loading" @inOutAnimation>motion_photos_on</mat-icon>
    {{ loading
      ? ('common.loading' | translate)
      : fileName
        ? ('common.selected_file' | translate:{file: fileName})
        : ('common.select_file' | translate)
    }}
  </button>

  <button mat-button (click)="excel.downloadBare()">
    {{ 'shared.dialogs.upload-file.select-file.download_template' | translate }}
  </button>

  @if (auth.onIsAdmin | async) {
    <button mat-button (click)="excel.downloadRaw()">
      Download raw (admin only)
    </button>
  }

</div>

<div #platformsRef class="platforms" [class.visible]="imagesLoaded">
  <ng-container *ngFor="let x of platforms">
    <div *ngIf=" ! x.hidden">
      <img #image [src]="x.logo"/>
    </div>
  </ng-container>
</div>