
<h1 matDialogTitle>
  {{ 'shared.dialogs.generate-schedule.title' | translate }}
  <span class="spacer"></span>
  <mat-icon color="primary"
    *ngIf="inputAnalysis.analyzing"
    @inOutAnimation
    class="rotating"
    [matTooltip]="'common.computing' | translate"
  >
    loop
  </mat-icon>
</h1>

<mat-dialog-content class="position-relative">
  <p>{{ 'shared.dialogs.generate-schedule.description' | translate }}</p>

  <app-loading [loading]="loading"></app-loading>

  <mat-list *ngIf="error; else flaws">
    <mat-list-item class="dynamic-height">
      <mat-icon matListItemIcon class="warn-color">error</mat-icon>
      <span matListItemTitle>{{ 'common.error' | translate }}</span>
      <span>{{ 'shared.dialogs.generate-schedule.error' | translate }}</span>
    </mat-list-item>
  </mat-list>

  <ng-template #flaws>
    <ng-container *ngIf="totNumRemarks | async as num; else flawless">
      <p>
        {{ 'shared.dialogs.generate-schedule.analysis.flaws.description' | translate:{
          flaws: num > 1 ? num + ' ' + ('common.flaws' | translate) : ('common.flaw' | translate)
        } }}
      </p>

      <mat-action-list>
        <ng-container *ngFor="let x of categoriesDescription">
          <mat-list-item *ngIf="numRemarks[x.key] | async; let num" class="dynamic-height" (click)="dialog.openInputIssuesDialog(did, x.key)">
            <mat-icon matListItemIcon [ngClass]="x.class">{{ x.icon }}</mat-icon>
            <span matListItemTitle>{{ x.title| translate }} ({{ num }})</span>
            <span>{{ x.description | translate }}</span>
            <mat-icon matListItemMeta>chevron_right</mat-icon>
          </mat-list-item>
        </ng-container>
      </mat-action-list>
    </ng-container>
  </ng-template>

  <ng-template #flawless>
    <mat-list-item class="dynamic-height" *ngIf="inputAnalysis.valid">
      <mat-icon matListItemIcon class="ok-green">done</mat-icon>
      <span matListItemTitle>{{ 'shared.dialogs.generate-schedule.analysis.flawless.title' | translate }}</span>
      <span>{{ 'shared.dialogs.generate-schedule.analysis.flawless.description' | translate }}</span>
    </mat-list-item>
  </ng-template>

</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button tabindex="-1"
          [disabled]="duplicated || submitted"
          (click)="duplicate()"
  >
    <mat-icon *ngIf="duplicated">done</mat-icon>
    {{ (duplicated ? 'common.duplicated' : 'common.duplicate') | translate }}
  </button>
  <span class="spacer"></span>
  <button mat-button mat-dialog-close tabindex="-1">
    {{ 'common.cancel' | translate }}
  </button>

  <button mat-button
          [disabled]="(onIsRunning | async) != false || ! inputAnalysis.valid || submitted"
          color="primary"
          (click)="generate()">
    {{ 'shared.dialogs.generate-schedule.action.submit' | translate }}
  </button>
</mat-dialog-actions>