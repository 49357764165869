<div class="container" [formGroup]="formGroup">

  <section class="header">
    <span>Activation</span>
    <mat-divider></mat-divider>
  </section>

  <section class="content">
    <mat-radio-group class="activation"
                     (change)="_handleInput()"
                     formControlName="activation">
      <mat-radio-button [value]="null">Unset</mat-radio-button>
      <mat-radio-button [value]="true">Yes</mat-radio-button>
      <mat-radio-button [value]="false">No</mat-radio-button>
    </mat-radio-group>
  </section>

  <!-- <section class="header">
    <span>Created At</span>
    <mat-divider></mat-divider>
  </section>

  <section class="content">
    <mat-form-field appearance="fill" class="date">
      <mat-label>Enter a date range</mat-label>
      <mat-date-range-input [rangePicker]="picker">
        <input matStartDate
               placeholder="Start date"
               (change)="_handleInput()"
               formControlName="start">
        <input matEndDate
               placeholder="End date"
               (change)="_handleInput()"
               formControlName="end">
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
  </section> -->

</div>