import { EnvironmentService    } from '@app/core';
import { Util                  } from '@app/common';
import { themes                } from '@app/core/environment/environment.types';
import { Collection            } from '../../types';
import { Columns,
         TableColumnProperties } from './types';



const BASE = 'TABLE_COLUMNS'
export const storageKeys: Record<Collection, string> = {
  groups:    `${BASE}/GROUPS`,
  teachers:  `${BASE}/TEACHERS`,
  locations: `${BASE}/LOCATIONS`,
  persons:   `${BASE}/PERSONS`,
  courses:   `${BASE}/COURSES`,
  events:    `${BASE}/EVENTS`,
};


function isSwedishSchool (e: EnvironmentService): boolean {
  return ( ! e.theme || e.theme == 'royal_schedule' || e.theme == 'schoolsoft')
      && ( ! e.organizationType || e.organizationType == 'school');
}

function isSwedishSportsFacility (e: EnvironmentService): boolean {
  return ( ! e.theme || e.theme == 'royal_schedule')
      && e.organizationType == 'sports_facility';
}

function isInternationalSchool (e: EnvironmentService): boolean {
  const internationalThemes = themes.filter(t => t != 'royal_schedule' && t != 'schoolsoft');
  return e.theme && internationalThemes.includes(e.theme);
}


type ColumnBase<T extends keyof Columns> = Partial<Record<keyof Columns[T], Omit<TableColumnProperties, 'enabled'>>>

function reorderAndEnable<T extends keyof Columns> (
  columns: ColumnBase<T>,
  mimic:   Record<keyof Columns[T], boolean>
): Columns[T] {
  const order = Object.keys(mimic);

  const entries = Util.functions.objectEntries(columns)
  .map(([key, value]) => {
    // set enabled property
    return [key, {...value, enabled: mimic[key] }] as [keyof Columns[T], TableColumnProperties];
  })
  .sort(([key1, _], [key2, __]) => {
    // sort the columns
    const i1 = order.indexOf(key1 as string);
    const i2 = order.indexOf(key2 as string);
    return (i1 > -1 ? i1 : Infinity) - (i2 > -1 ? i2 : Infinity);
  })

  return Object.fromEntries(entries);
}


export function locationsColumns (
  e:   EnvironmentService,
  ids: boolean
): Columns['locations'] {
  const collection = 'locations';

  const _c: Required<ColumnBase<typeof collection>> = {
    displayName:    { title: 'attributes.shared.displayName',    description: 'attributeDescriptions.shared.displayName'      },
    days:           { title: 'attributes.shared.days',           description: 'attributeDescriptions.location.days'           },
    lockedTimes:    { title: 'attributes.shared.lockedTimes',    description: 'attributeDescriptions.location.lockedTimes'    },
    minBreakLength: { title: 'attributes.shared.minBreakLength', description: 'attributeDescriptions.location.minBreakLength' },
    tags:           { title: 'attributes.shared.tags',           description: 'attributeDescriptions.shared.tags'             },
    ids:            { title: 'attributes.shared.ids',            description: 'attributeDescriptions.shared.ids'              },
    createdAt:      { title: 'attributes.shared.createdAt',      description: 'attributeDescriptions.shared.createdAt'        },
  };

  const c: ColumnBase<typeof collection> = _c;
  if ( ! e.hasColumn(collection, 'createdAt')     ) delete c.createdAt;
  if ( ! e.hasColumn(collection, 'displayName')   ) delete c.displayName;
  if ( ! e.hasColumn(collection, 'days')          ) delete c.days;
  if ( ! e.hasColumn(collection, 'lockedTimes')   ) delete c.lockedTimes;
  if ( ! e.hasColumn(collection, 'minBreakLength')) delete c.minBreakLength;
  if ( ! ids                                      ) delete c.ids;

  if (isSwedishSchool(e)) {
    return reorderAndEnable<typeof collection>(c, {
      displayName:    true,
      days:           true,
      lockedTimes:    true,
      minBreakLength: true,
      tags:           true,
      ids:            true,
      createdAt:      true,
    });
  }
  if (isSwedishSportsFacility(e)) {
    return reorderAndEnable<typeof collection>(c, {
      displayName:    true,
      days:           true,
      lockedTimes:    true,
      minBreakLength: true,
      tags:           true,
      ids:            true,
      createdAt:      true,
    });
  }
  if (isInternationalSchool(e)) {
    return reorderAndEnable<typeof collection>(c, {
      displayName:    true,
      days:           true,
      lockedTimes:    true,
      minBreakLength: true,
      tags:           true,
      ids:            true,
      createdAt:      true,
    });
  }

  throw new Error(`Unknown theme '${e.theme}' and organization type '${e.organizationType}'`);
}


export function personsColumns (
  e:   EnvironmentService,
  ids: boolean
): Columns['persons'] {
  const collection = 'persons';

  const _c: Required<ColumnBase<typeof collection>> = {
    firstName:     { title: 'attributes.person.firstName',    description: 'attributeDescriptions.person.firstName'    },
    lastName:      { title: 'attributes.person.lastName',     description: 'attributeDescriptions.person.lastName'     },
    group:         { title: 'attributes.person.group',        description: 'attributeDescriptions.person.group'        },
    sex:           { title: 'attributes.person.sex',          description: 'attributeDescriptions.person.sex'          },
    SSN:           { title: 'attributes.person.SSN',          description: 'attributeDescriptions.person.SSN'          },
    emails:        { title: 'attributes.shared.emails',       description: 'attributeDescriptions.person.emails'       },
    phoneNumbers:  { title: 'attributes.shared.phoneNumbers', description: 'attributeDescriptions.person.phoneNumbers' },
    tags:          { title: 'attributes.shared.tags',         description: 'attributeDescriptions.shared.tags'         },
    ids:           { title: 'attributes.shared.ids',          description: 'attributeDescriptions.shared.ids'          },
    createdAt:      { title: 'attributes.shared.createdAt',      description: 'attributeDescriptions.shared.createdAt'        },
  };

  const c: ColumnBase<typeof collection> = _c;
  if ( ! e.hasColumn(collection, 'createdAt')       ) delete c.createdAt;
  if ( ! e.hasColumn(collection, 'firstName')       ) delete c.firstName;
  if ( ! e.hasColumn(collection, 'lastName')        ) delete c.lastName;
  if ( ! e.hasColumn(collection, 'groups', 'groups')) delete c.group;
  if ( ! e.hasColumn(collection, 'sex')             ) delete c.sex;
  if ( ! e.hasColumn(collection, 'SSN')             ) delete c.SSN;
  if ( ! e.hasColumn(collection, 'emails')          ) delete c.emails;
  if ( ! e.hasColumn(collection, 'phoneNumbers')    ) delete c.phoneNumbers;
  if ( ! ids                                        ) delete c.ids;

  if (isSwedishSchool(e)) {
    return reorderAndEnable<typeof collection>(c, {
      firstName:    true,
      lastName:     true,
      group:        true,
      sex:          true,
      SSN:          true,
      emails:       true,
      phoneNumbers: true,
      tags:         true,
      ids:          true,
      createdAt:    true,

    });
  }
  if (isSwedishSportsFacility(e)) {
    return reorderAndEnable<typeof collection>(c, {
      firstName:    true,
      lastName:     true,
      group:        true,
      sex:          true,
      SSN:          true,
      emails:       true,
      phoneNumbers: true,
      tags:         true,
      ids:          true,
      createdAt:    true,

    });
  }
  if (isInternationalSchool(e)) {
    return reorderAndEnable<typeof collection>(c, {
      firstName:    true,
      lastName:     true,
      group:        true,
      sex:          true,
      SSN:          true,
      emails:       true,
      phoneNumbers: true,
      tags:         true,
      ids:          true,
      createdAt:    true,

    });
  }

  throw new Error(`Unknown theme '${e.theme}' and organization type '${e.organizationType}'`);
}



export function teachersColumns (
  e:   EnvironmentService,
  ids: boolean
): Columns['teachers'] {
  const collection = 'teachers';

  const _c: Required<ColumnBase<typeof collection>> = {
    displayName:    { title: 'attributes.shared.displayName',    description: 'attributeDescriptions.shared.displayName'     },
    lunch:          { title: 'attributes.shared.lunch',          description: 'attributeDescriptions.teacher.lunch'          },
    days:           { title: 'attributes.shared.days',           description: 'attributeDescriptions.teacher.days'           },
    intervals:      { title: 'attributes.shared.intervals',      description: 'attributeDescriptions.teacher.intervals'      },
    lockedTimes:    { title: 'attributes.shared.lockedTimes',    description: 'attributeDescriptions.teacher.lockedTimes'    },
    minBreakLength: { title: 'attributes.shared.minBreakLength', description: 'attributeDescriptions.teacher.minBreakLength' },
    tags:           { title: 'attributes.shared.tags',           description: 'attributeDescriptions.shared.tags'            },
    ids:            { title: 'attributes.shared.ids',            description: 'attributeDescriptions.shared.ids'             },
    signature:      { title: 'attributes.teachers.signature',    description: 'attributeDescriptions.teachers.signature'     },
    createdAt:      { title: 'attributes.shared.createdAt',      description: 'attributeDescriptions.shared.createdAt'        },
  };

  const c: ColumnBase<typeof collection> = _c;
  if ( ! e.hasColumn(collection, 'createdAt')     ) delete c.createdAt;
  if ( ! e.hasColumn(collection, 'displayName')   ) delete c.displayName;
  if ( ! e.hasColumn(collection, 'lunch')         ) delete c.lunch;
  if ( ! e.hasColumn(collection, 'days')          ) delete c.days;
  if ( ! e.hasColumn(collection, 'intervals')     ) delete c.intervals;
  if ( ! e.hasColumn(collection, 'lockedTimes')   ) delete c.lockedTimes;
  if ( ! e.hasColumn(collection, 'minBreakLength')) delete c.minBreakLength;
  if ( ! ids                                      ) delete c.ids;

  if (isSwedishSchool(e)) {
    return reorderAndEnable<typeof collection>(c, {
      displayName:    true,
      lunch:          true,
      days:           true,
      intervals:      true,
      lockedTimes:    true,
      minBreakLength: true,
      tags:           true,
      ids:            true,
      signature:      true,
      createdAt:      true,
    });
  }
  if (isSwedishSportsFacility(e)) {
    return reorderAndEnable<typeof collection>(c, {
      displayName:    true,
      lunch:          true,
      days:           true,
      intervals:      true,
      lockedTimes:    true,
      minBreakLength: true,
      tags:           true,
      ids:            true,
      signature:      true,
      createdAt:      true,
    });
  }
  if (isInternationalSchool(e)) {
    return reorderAndEnable<typeof collection>(c, {
      displayName:    true,
      lunch:          true,
      days:           true,
      intervals:      true,
      lockedTimes:    true,
      minBreakLength: true,
      tags:           true,
      ids:            true,
      signature:      true,
      createdAt:      true,
    });
  }

  throw new Error(`Unknown theme '${e.theme}' and organization type '${e.organizationType}'`);
}



export function groupsColumns (
  e:   EnvironmentService,
  ids: boolean
): Columns['groups'] {
  const collection = 'groups';

  const _c: Required<ColumnBase<typeof collection>> = {
    displayName:    { title: 'attributes.shared.displayName',    description: 'attributeDescriptions.shared.displayName'   },
    species:        { title: 'attributes.groups.species',        description: 'attributeDescriptions.groups.species'       },
    parentGroups:   { title: 'attributes.groups.parentGroups',   description: 'attributeDescriptions.groups.parentGroups'  },
    members:        { title: 'attributes.groups.members',        description: 'attributeDescriptions.groups.members'       },
    lunch:          { title: 'attributes.shared.lunch',          description: 'attributeDescriptions.group.lunch'          },
    days:           { title: 'attributes.shared.days',           description: 'attributeDescriptions.group.days'           },
    rootIntervals:  { title: 'attributes.shared.rootIntervals',  description: 'attributeDescriptions.group.rootIntervals'  },
    intervals:      { title: 'attributes.shared.intervals',      description: 'attributeDescriptions.group.intervals'      },
    lockedTimes:    { title: 'attributes.shared.lockedTimes',    description: 'attributeDescriptions.group.lockedTimes'    },
    minBreakLength: { title: 'attributes.shared.minBreakLength', description: 'attributeDescriptions.group.minBreakLength' },
    tags:           { title: 'attributes.shared.tags',           description: 'attributeDescriptions.shared.tags'           },
    ids:            { title: 'attributes.shared.ids',            description: 'attributeDescriptions.shared.ids'           },
    createdAt:      { title: 'attributes.shared.createdAt',      description: 'attributeDescriptions.shared.createdAt'        },
  };

  const c: ColumnBase<typeof collection> = _c;
  if ( ! e.hasColumn(collection, 'createdAt')                     ) delete c.createdAt;
  if ( ! e.hasColumn(collection, 'displayName')                   ) delete c.displayName;
  if ( ! e.hasColumn(collection, 'members', 'persons')            ) delete c.members;
  if ( ! e.hasColumn(collection, 'lunch')                         ) delete c.lunch;
  if ( ! e.hasColumn(collection, 'days')                          ) delete c.days;
  if ( ! e.hasColumn(collection, 'rootIntervals', 'rootIntervals')) delete c.rootIntervals;
  if ( ! e.hasColumn(collection, 'intervals')                     ) delete c.intervals;
  if ( ! e.hasColumn(collection, 'lockedTimes')                   ) delete c.lockedTimes;
  if ( ! e.hasColumn(collection, 'minBreakLength')                ) delete c.minBreakLength;
  if ( ! ids                                                      ) delete c.ids;

  if (isSwedishSchool(e)) {
    return reorderAndEnable<typeof collection>(c, {
      displayName:    true,
      species:        true,
      members:        true,
      lunch:          true,
      intervals:      true,
      lockedTimes:    true,
      days:           false,
      rootIntervals:  false,
      minBreakLength: false,
      tags:           true,
      ids:            false,
      parentGroups:   false,
      createdAt:      true,
    });
  }
  if (isSwedishSportsFacility(e)) {
    return reorderAndEnable<typeof collection>(c, {
      displayName:    true,
      members:        true,
      lunch:          true,
      days:           true,
      rootIntervals:  true,
      intervals:      true,
      lockedTimes:    true,
      minBreakLength: true,
      tags:           true,
      ids:            true,
      species:        false,
      parentGroups:   false,
      createdAt:      true,
    });
  }
  if (isInternationalSchool(e)) {
    return reorderAndEnable<typeof collection>(c, {
      displayName:    true,
      members:        true,
      lunch:          true,
      rootIntervals:  true,
      intervals:      true,
      lockedTimes:    true,
      days:           false,
      minBreakLength: false,
      tags:           true,
      ids:            false,
      species:        false,
      parentGroups:   false,
      createdAt:      true,
    });
  }

  throw new Error(`Unknown theme '${e.theme}' and organization type '${e.organizationType}'`);
}



export function coursesColumns (
  e:   EnvironmentService,
  ids: boolean
): Columns['courses'] {
  const collection = 'courses';

  const _c: Required<ColumnBase<typeof collection>> = {
    displayName:           { title: 'attributes.shared.displayName',            description: 'attributeDescriptions.shared.displayName'            },
    teachers:              { title: 'attributes.shared.teachers',               description: 'attributeDescriptions.course.teachers'               },
    groups:                { title: 'attributes.shared.groups',                 description: 'attributeDescriptions.course.groups'                 },
    subject:               { title: 'attributes.shared.subject',                description: 'attributeDescriptions.course.subject'                },
    color:                 { title: 'attributes.shared.color',                  description: 'attributeDescriptions.course.color'                  },
    /* TO BE DEPRECATED */ expectedTotalHours:    { title: 'attributes.course.expectedTotalHours',     description: 'attributeDescriptions.course.expectedTotalHours'     },
    /* TO BE DEPRECATED */ minutesPerWeek:        { title: 'attributes.course.minutesPerWeek',         description: 'attributeDescriptions.course.minutesPerWeek'         },
    plannedDuration:       { title: 'attributes.course.plannedDuration',        description: 'attributeDescriptions.course.plannedDuration'        },
    events:                { title: 'attributes.course.events',                 description: 'attributeDescriptions.course.events'                 },
    locations:             { title: 'attributes.shared.locations',              description: 'attributeDescriptions.course.locations'              },
    overlapGroup:          { title: 'attributes.course.overlapGroup',           description: 'attributeDescriptions.course.overlapGroup'           },
    period:                { title: 'attributes.shared.period',                 description: 'attributeDescriptions.course.period'                 },
    lockedTimes:           { title: 'attributes.shared.lockedTimes',            description: 'attributeDescriptions.course.lockedTimes'            },
    days:                  { title: 'attributes.shared.days',                   description: 'attributeDescriptions.course.days'                   },
    intervals:             { title: 'attributes.shared.intervals',              description: 'attributeDescriptions.course.intervals'              },
    centerOfAttraction:    { title: 'attributes.shared.centerOfAttraction',     description: 'attributeDescriptions.course.centerOfAttraction'     },
    minBreakLength:        { title: 'attributes.shared.minBreakLength',         description: 'attributeDescriptions.course.minBreakLength'         },
    eventDurationVariance: { title: 'attributes.course.eventDurationVariation', description: 'attributeDescriptions.course.eventDurationVariation' },
    tags:                  { title: 'attributes.shared.tags',                   description: 'attributeDescriptions.shared.tags'                   },
    ids:                   { title: 'attributes.shared.ids',                    description: 'attributeDescriptions.shared.ids'                    },
    createdAt:      { title: 'attributes.shared.createdAt',      description: 'attributeDescriptions.shared.createdAt'        },
  };

  const c: ColumnBase<typeof collection> = _c;
  if ( ! e.hasColumn(collection, 'createdAt')                     ) delete c.createdAt;
  if ( ! e.hasColumn(collection, 'displayName')                   ) delete c.displayName;
  if ( ! e.hasColumn(collection, 'teachers', 'teachers')          ) delete c.teachers;
  if ( ! e.hasColumn(collection, 'groups', 'groups')              ) delete c.groups;
  if ( ! e.hasColumn(collection, 'subject')                       ) delete c.subject;
  if ( ! e.hasColumn(collection, 'color')                         ) delete c.color;
  if ( ! e.hasColumn(collection, 'plannedDuration')               ) delete c.plannedDuration;
  /* TO BE DEPRECATED */ if ( ! e.hasColumn(collection, 'expectedTotalHours')            ) delete c.expectedTotalHours;
  /* TO BE DEPRECATED */ if ( ! e.hasColumn(collection, 'minutesPerWeek')                ) delete c.minutesPerWeek;
  if ( ! e.hasColumn(collection, 'events', 'events')              ) delete c.events;
  if ( ! e.hasColumn(collection, 'locations', 'locations')        ) delete c.locations;
  if ( ! e.hasColumn(collection, 'overlapGroups', 'overlapGroups')) delete c.overlapGroup;
  if ( ! e.hasColumn(collection, 'periods', 'periods')            ) delete c.period;
  if ( ! e.hasColumn(collection, 'lockedTimes')                   ) delete c.lockedTimes;
  if ( ! e.hasColumn(collection, 'days')                          ) delete c.days;
  if ( ! e.hasColumn(collection, 'intervals')                     ) delete c.intervals;
  if ( ! e.hasColumn(collection, 'centerOfAttraction')            ) delete c.centerOfAttraction;
  if ( ! e.hasColumn(collection, 'minBreakLength')                ) delete c.minBreakLength;
  if ( ! e.hasColumn(collection, 'eventDurationVariance')         ) delete c.eventDurationVariance;
  if ( ! ids                                                      ) delete c.ids;

  if (isSwedishSchool(e)) {
    return reorderAndEnable<typeof collection>(c, {
      displayName:           true,
      groups:                true,
      teachers:              true,
      events:                true,
      eventDurationVariance: false,   // rename -> durationVariance
      plannedDuration:       false,
      /* TO BE DEPRECATED */ expectedTotalHours:    false,
      /* TO BE DEPRECATED */ minutesPerWeek:        false,
      locations:             true,
      overlapGroup:          true,
      subject:               true,
      color:                 true,
      minBreakLength:        false,
      period:                false,
      centerOfAttraction:    false,
      days:                  false,
      intervals:             false,
      lockedTimes:           false,
      tags:                  true,
      ids:                   false,
      createdAt:             true,
    });
  }
  if (isSwedishSportsFacility(e)) {
    return reorderAndEnable<typeof collection>(c, {
      displayName:           true,
      events:                true,
      eventDurationVariance: false,   // rename -> durationVariance
      plannedDuration:       false,
      /* TO BE DEPRECATED */ expectedTotalHours:    false,
      /* TO BE DEPRECATED */ minutesPerWeek:        false,
      locations:             true,
      overlapGroup:          false,
      centerOfAttraction:    true,
      days:                  true,
      intervals:             true,
      subject:               true,
      color:                 true,
      minBreakLength:        false,
      period:                false,
      lockedTimes:           false,
      groups:                true,   // not in use here
      teachers:              true,   // not in use here
      tags:                  true,
      ids:                   false,
      createdAt:             true,
    });
  }
  if (isInternationalSchool(e)) {
    return reorderAndEnable<typeof collection>(c, {
      displayName:           true,
      teachers:              true,
      groups:                true,
      subject:               true,
      color:                 true,
      plannedDuration:       true,
      /* TO BE DEPRECATED */ expectedTotalHours:    false,
      /* TO BE DEPRECATED */ minutesPerWeek:        false,
      events:                true,
      locations:             true,
      overlapGroup:          true,
      period:                false,
      lockedTimes:           false,
      days:                  false,
      intervals:             false,
      centerOfAttraction:    false,
      minBreakLength:        false,
      eventDurationVariance: false,   // rename -> durationVariance
      tags:                  true,
      ids:                   false,
      createdAt:             true,
    });
  }

  throw new Error(`Unknown theme '${e.theme}' and organization type '${e.organizationType}'`);
}



export function eventsColumns (
  e:   EnvironmentService,
  ids: boolean
): Columns['events'] {
  const collection = 'events';

  const _c: Required<ColumnBase<typeof collection>> = {
    displayName:           { title: 'attributes.shared.displayName',        description: 'attributeDescriptions.shared.displayName'       },
    course:                { title: 'attributes.event.course',              description: 'attributeDescriptions.event.course'             },
    subject:               { title: 'attributes.shared.subject',            description: 'attributeDescriptions.event.subject'            },
    durationVariance:      { title: 'attributes.event.durationVariation',   description: 'attributeDescriptions.event.durationVariation'  },
    preferredDuration:     { title: 'attributes.event.preferredDuration',   description: 'attributeDescriptions.event.preferredDuration'  },
    fixedStart:            { title: 'attributes.event.fixedStart',          description: 'attributeDescriptions.event.fixedStart'         },
    groups:                { title: 'attributes.shared.groups',             description: 'attributeDescriptions.event.groups'             },
    teachers:              { title: 'attributes.shared.teachers',           description: 'attributeDescriptions.event.teachers'           },
    locations:             { title: 'attributes.shared.locations',          description: 'attributeDescriptions.event.locations'          },
    days:                  { title: 'attributes.shared.days',               description: 'attributeDescriptions.event.days'               },
    intervals:             { title: 'attributes.shared.intervals',          description: 'attributeDescriptions.event.intervals'          },
    period:                { title: 'attributes.shared.period',             description: 'attributeDescriptions.event.period'             },
    centerOfAttraction:    { title: 'attributes.shared.centerOfAttraction', description: 'attributeDescriptions.event.centerOfAttraction' },
    lockedTimes:           { title: 'attributes.shared.lockedTimes',        description: 'attributeDescriptions.event.lockedTimes'        },
    minBreakLength:        { title: 'attributes.shared.minBreakLength',     description: 'attributeDescriptions.event.minBreakLength'     },
    color:                 { title: 'attributes.shared.color',              description: 'attributeDescriptions.event.color'              },
    ids:                   { title: 'attributes.shared.ids',                description: 'attributeDescriptions.shared.ids'               },
    tags:                  { title: 'attributes.shared.tags',               description: 'attributeDescriptions.shared.tags'              },
    overlapSpecies:        { title: 'attributes.event.overlapSpecies',      description: 'attributeDescriptions.event.overlapSpecies'     },
    createdAt:             { title: 'attributes.shared.createdAt',          description: 'attributeDescriptions.shared.createdAt'         },
  };

  const c: ColumnBase<typeof collection> = _c;
  if ( ! e.hasColumn(collection, 'createdAt')             ) delete c.createdAt;
  if ( ! e.hasColumn(collection, 'displayName')           ) delete c.displayName;
  if ( ! e.hasColumn(collection, 'course', 'courses')     ) delete c.course;
  if ( ! e.hasColumn(collection, 'subject', 'courses')    ) delete c.subject;
  if ( ! e.hasColumn(collection, 'durationVariance')      ) delete c.durationVariance;
  if ( ! e.hasColumn(collection, 'preferredDuration')     ) delete c.preferredDuration;
  if ( ! e.hasColumn(collection, 'fixedStart')            ) delete c.fixedStart;
  if ( ! e.hasColumn(collection, 'groups', 'groups')      ) delete c.groups;
  if ( ! e.hasColumn(collection, 'teachers', 'teachers')  ) delete c.teachers;
  if ( ! e.hasColumn(collection, 'locations', 'locations')) delete c.locations;
  if ( ! e.hasColumn(collection, 'days')                  ) delete c.days;
  if ( ! e.hasColumn(collection, 'intervals')             ) delete c.intervals;
  if ( ! e.hasColumn(collection, 'period', 'periods')     ) delete c.period;
  if ( ! e.hasColumn(collection, 'centerOfAttraction')    ) delete c.centerOfAttraction;
  if ( ! e.hasColumn(collection, 'lockedTimes')           ) delete c.lockedTimes;
  if ( ! e.hasColumn(collection, 'minBreakLength')        ) delete c.minBreakLength;
  if ( ! e.hasColumn(collection, 'color')                 ) delete c.color;
  if ( ! ids                                              ) delete c.ids;
  if ( ! e.hasColumn(collection, 'overlapSpecies')        ) delete c.overlapSpecies;

  if (isSwedishSchool(e)) {
    return reorderAndEnable<typeof collection>(c, {
      displayName:        false,
      course:             true,
      preferredDuration:  true,
      overlapSpecies:     true,
      fixedStart:         true,
      groups:             true,
      teachers:           true,
      durationVariance:   false,
      locations:          true,
      subject:            true,
      color:              true,
      minBreakLength:     false,
      period:             false,
      centerOfAttraction: false,
      days:               false,
      intervals:          false,
      lockedTimes:        false,
      tags:               true,
      ids:                false,
      createdAt:          true,
    });
  }
  if (isSwedishSportsFacility(e)) {
    return reorderAndEnable<typeof collection>(c, {
      displayName:           true,
      course:                true,
      preferredDuration:     true,
      overlapSpecies:        true,
      fixedStart:            true,
      durationVariance:      false,
      locations:             true,
      centerOfAttraction:    true,
      days:                  true,
      intervals:             true,
      subject:               true,
      color:                 true,
      minBreakLength:        false,
      period:                false,
      lockedTimes:           false,
      groups:                true,   // not in use here
      teachers:              true,   // not in use here
      tags:                  true,
      ids:                   false,
      createdAt:             true,
    });
  }
  if (isInternationalSchool(e)) {
    return reorderAndEnable<typeof collection>(c, {
      displayName:        false,
      course:             true,
      preferredDuration:  true,
      overlapSpecies:     true,
      fixedStart:         true,
      teachers:           true,
      groups:             true,
      subject:            true,
      color:              true,
      locations:          true,
      period:             false,
      lockedTimes:        false,
      days:               false,
      intervals:          false,
      centerOfAttraction: false,
      minBreakLength:     false,
      durationVariance:   false,
      tags:               true,
      ids:                false,
      createdAt:          true,
    });
  }

  throw new Error(`Unknown theme '${e.theme}' and organization type '${e.organizationType}'`);
}