<app-loading [loading]="dataSource.isLoading"></app-loading>

<div class="toolbar top" *ngIf="toolbar">
  <div class="search" [class.hidden]="! search || (onNoData | async)">
    <app-search></app-search>

    <button mat-icon-button
            (click)="openCustomSearchDialog()"
            [tooltip]="'common.custom_search' | translate"
            tooltipTutorial="29">
      <mat-icon class="royal-icon" [matBadge]="(isDefaultSearchParameters | async) ? null : '!'" aria-hidden="false">tune</mat-icon>
    </button>
  </div>

  <div class="spacer"></div>

  <button mat-button
          class="edit-columns royal-text-secondary"
          [class.hidden]="onNoData | async"
          (click)="openEditColumnsDialog()"
          [tooltip]="'common.edit_columns' | translate"
          tooltipTutorial="16"
          cypress="open-edit-columns-dialog">
    <mat-icon class="material-icons-outlined">view_week</mat-icon>
    {{ 'common.edit_columns' | translate }}
  </button>

  <button mat-mini-fab
          [disabled]=" ! editable"
          color="primary"
          class="add"
          *ngIf="add"
          tooltipTutorial="30"
          [tooltip]="'common.create_location' | translate"
          (click)="openCreateEntityDialog()">
    <mat-icon>add</mat-icon>
  </button>
</div>


<div class="table-wrapper">
  <app-empty collection="locations" [visible]="onNoData | async"></app-empty>
  <app-no-columns [visible]="noDataColumns | async"></app-no-columns>

  <div #tableContainer class="table-container dark-scrollbar">

    <table #table mat-table
      [dataSource]="dataSource"
      [trackBy]="trackBy"
      recycleRows
      matSort
      class="default-hover-effect"
    >

      <ng-container matColumnDef="select" sticky>
        <th mat-header-cell *matHeaderCellDef class="transparent-right-border">
          <mat-checkbox (change)="$event ? selection.toggleAll() : null"
                        [checked]="selection.checked"
                        [indeterminate]="selection.indeterminate"
                        [disabled]=" ! editable">
          </mat-checkbox>
          <div class="divider one-px"></div>
        </th>
        <td mat-cell *matCellDef="let row; table: table">
          <mat-checkbox (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)"
                        [disabled]=" ! editable">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="ids">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <app-column-header [title]="columnTitleTranslationKeys.ids" [description]="columnDescriptionTranslationKeys.ids"></app-column-header>
        </th>
        <td mat-cell *matCellDef="let element; table: table">
          <app-form-field-text [value]="element.ids"
                                saveOnFocusLost
                                *ngIf="editable"
                                nullifyEmpty
                                (onChange)="edit(element.id, { ids: $event })">
          </app-form-field-text>
          <div *ngIf="! editable" class="overflow-text">{{ element.ids }}</div>
        </td>
      </ng-container>

      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <app-column-header [title]="columnTitleTranslationKeys.createdAt" [description]="columnDescriptionTranslationKeys.createdAt"></app-column-header>
        </th>
        <td mat-cell *matCellDef="let element; table: table">
          <div class="overflow-text">{{ element.createdAt }}</div>
        </td>
      </ng-container>

      <ng-container matColumnDef="tags">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <app-column-header [title]="columnTitleTranslationKeys.tags"
                             [description]="columnDescriptionTranslationKeys.tags"
                             tutorial="39"></app-column-header>
        </th>
        <td mat-cell *matCellDef="let element; table: table">
          <app-form-field-tags
            [value]="element.tags"
            [disabled]=" ! editable"
            [tagOptions]="tags$ | async"
            nullable
            saveOnClose
            (onChange)="edit(element.id, { tags: $event })"
          >
          </app-form-field-tags>
      </ng-container>

      <ng-container matColumnDef="displayName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <app-column-header [title]="columnTitleTranslationKeys.displayName" [description]="columnDescriptionTranslationKeys.displayName"></app-column-header>
        </th>
        <td mat-cell *matCellDef="let element; table: table">
          <app-form-field-text [value]="element.displayName"
                                *ngIf="editable"
                                saveOnFocusLost
                                (onChange)="edit(element.id, { displayName: $event })">
          </app-form-field-text>
          <div *ngIf="! editable" class="overflow-text">{{ element.displayName }}</div>
        </td>
      </ng-container>

      <ng-container matColumnDef="minBreakLength">
        <th mat-header-cell *matHeaderCellDef>
          <app-column-header [title]="columnTitleTranslationKeys.minBreakLength"
                             [description]="columnDescriptionTranslationKeys.minBreakLength"
                             tutorial="21"></app-column-header>
        </th>
        <td mat-cell *matCellDef="let element; table: table">
          <app-form-field-break-length [value]="element.minBreakLength"
                                        hideOptionAsymmetrical
                                        hideOptionNone
                                        *ngIf="editable"
                                        [voidText]="(settings | async)?.defaultDependencyMinimumBreakLength + ' ' + ('common.minutes' | translate | lowercase)"
                                        [setVoidText]="'common.useDefaultValue' | translate:{ value: (settings | async)?.defaultDependencyMinimumBreakLength }"
                                        disableActions
                                        saveOnClose
                                        [defaultValue]="(settings | async)?.defaultDependencyMinimumBreakLength"
                                        (onChange)="edit(element.id, { minBreakLength: $any($event) })">
          </app-form-field-break-length>
          <app-form-field-break-length-display-value [value]="element.minBreakLength"
                                                      [voidText]="(settings | async)?.defaultDependencyMinimumBreakLength + ' ' + ('common.minutes' | translate | lowercase)"
                                                      *ngIf="! editable">
          </app-form-field-break-length-display-value>
        </td>
      </ng-container>

      <ng-container matColumnDef="lockedTimes">
        <th mat-header-cell *matHeaderCellDef>
          <app-column-header [title]="columnTitleTranslationKeys.lockedTimes"
                             [description]="columnDescriptionTranslationKeys.lockedTimes"
                             tutorial="9"></app-column-header>
        </th>
        <td mat-cell *matCellDef="let element; table: table">
          <app-form-field-locked-times [value]="element.lockedTimes"
                                        [numDays]="settings | async | pluck:'numDays'"
                                        disableActions
                                        voidText="-"
                                        *ngIf="editable"
                                        saveOnClose
                                        (onChange)="edit(element.id, { lockedTimes: $event })">
          </app-form-field-locked-times>
          <app-form-field-locked-times-display-value [value]="element.lockedTimes"
                                                      *ngIf="! editable"
                                                      [voidText]="'-'">
          </app-form-field-locked-times-display-value>
        </td>
      </ng-container>

      <ng-container matColumnDef="days">
        <th mat-header-cell *matHeaderCellDef>
          <app-column-header [title]="columnTitleTranslationKeys.days"
                             [description]="columnDescriptionTranslationKeys.days"
                             tutorial="8"></app-column-header>
        </th>
        <td mat-cell *matCellDef="let element; table: table">
          <app-form-field-available-days [value]="element.days"
                                          [numDays]="settings | async | pluck:'numDays'"
                                          (onChange)="edit(element.id, { days: $event })"
                                          *ngIf="editable"
                                          saveOnClose
                                          disableActions>
          </app-form-field-available-days>
          <app-form-field-available-days-display-value [value]="element.days"
                                                        *ngIf="! editable">
          </app-form-field-available-days-display-value>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions" stickyEnd>
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element; table: table">
          <button mat-icon-button
                  [disabled]=" ! editable"
                  type="button"
                  [style.float]="'right'"
                  (click)="deleteOne(element.id)">
            <mat-icon class="royal-icon"
                      matTooltip="{{ 'common.delete' | translate }}">delete_outline</mat-icon>
          </button>
        </td>
      </ng-container>



      <!----------------------------------------------------------------------->
      <!------------------------------ BULK EDIT ------------------------------>
      <!----------------------------------------------------------------------->
      <ng-container matColumnDef="bulk-select">
        <th mat-header-cell *matHeaderCellDef  class="sticky">
          <div class="collapsible">
            <div class="corner top"></div>
            <div class="corner bottom"></div>
            <div class="selected-count">
              {{ selection.length }}
            </div>
          </div>
        </th>
      </ng-container>

      <ng-container matColumnDef="bulk-displayName">
        <th mat-header-cell *matHeaderCellDef class="disabled-cell">
        </th>
      </ng-container>

      <ng-container matColumnDef="bulk-days">
        <th mat-header-cell *matHeaderCellDef>
          <div class="collapsible">
            <app-form-field-available-days [value]="daysBulkValue"
                                          [numDays]="settings | async | pluck:'numDays'"
                                          voidText="-"
                                          (onChange)="editMany(selection.selected, { days: $event })"
                                          saveOnClose
                                          disableActions>
            </app-form-field-available-days>
          </div>
        </th>
      </ng-container>

      <ng-container matColumnDef="bulk-lockedTimes">
        <th mat-header-cell *matHeaderCellDef>
          <div class="collapsible">
            <app-form-field-locked-times [value]="lockedTimesBulkValue"
                                        [numDays]="settings | async | pluck:'numDays'"
                                        voidText="-"
                                        omitId
                                        disableActions
                                        saveOnClose
                                        (onChange)="editMany(selection.selected, { lockedTimes: $event })">
            </app-form-field-locked-times>
          </div>
        </th>
      </ng-container>

      <ng-container matColumnDef="bulk-minBreakLength">
        <th mat-header-cell *matHeaderCellDef>
          <div class="collapsible">
            <app-form-field-break-length [value]="minBreakLengthBulkValue"
                                        coalesced
                                        disableActions
                                        saveOnClose
                                        hideOptionAsymmetrical
                                        hideOptionNone
                                        voidText="-"
                                        [setVoidText]="'common.useDefaultValue' | translate:{ value: (settings | async)?.defaultDependencyMinimumBreakLength }"
                                        [defaultValue]="(settings | async)?.defaultDependencyMinimumBreakLength"
                                        (onChange)="editMany(selection.selected, { minBreakLength: $any($event) })">
            </app-form-field-break-length>
          </div>
        </th>
      </ng-container>

      <ng-container matColumnDef="bulk-ids">
        <th mat-header-cell *matHeaderCellDef class="disabled-cell"></th>
      </ng-container>

      <ng-container matColumnDef="bulk-createdAt">
        <th mat-header-cell *matHeaderCellDef class="disabled-cell"></th>
      </ng-container>

      <ng-container matColumnDef="bulk-tags">
        <th mat-header-cell *matHeaderCellDef>
          <div class="collapsible">
            <app-form-field-tags
              [value]="tagsBulkValue"
              [disabled]=" ! editable"
              [tagOptions]="tags$ | async"
              nullable
              saveOnClose
              (onChange)="bulkUpdateTags(selection.selection, $event)"
            >
            </app-form-field-tags>
          </div>
        </th>
      </ng-container>

      <ng-container matColumnDef="bulk-actions">
        <th mat-header-cell *matHeaderCellDef class="sticky">
          <div class="collapsible">
            <div class="border"></div>
            <div class="corner top"></div>
            <div class="corner bottom"></div>
            <button mat-icon-button
                    [disabled]=" ! editable"
                    type="button"
                    class="bulk-delete"
                    (click)="deleteMany(selection.selected)">
              <mat-icon class="royal-icon"
                        matTooltip="{{ 'common.delete' | translate }}"
                        [matBadge]="selection.length"
                        matBadgeOverlap="true"
                        matBadgeColor="warn"
                        matBadgeSize="small"
                        aria-hidden="false">delete_outline</mat-icon>
            </button>
          </div>
        </th>
      </ng-container>

      <!-- a column that absorbs width if for example no column is visible -->
      <!-- the colspan=2 also fixes the stickyEnd bug where columns are positioned above the table header -->
      <ng-container matColumnDef="absorber">
        <th mat-header-cell *matHeaderCellDef colspan="2"></th>
        <td mat-cell *matCellDef="let element; table: table"></td>
      </ng-container>
      <ng-container matColumnDef="bulk-absorber">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element; table: table"></td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>

      <!-- bulk edit table header -->
      <tr mat-header-row *matHeaderRowDef="bulkColumns;"
          class="bulk" [class.hidden]="selection.empty" [class.animate]="! dataSource.isLoading"></tr>

      <tr mat-row
          class="table-row"
          *matRowDef="let row; columns: columns;">
      </tr>
    </table>
  </div>


  <div class="toolbar bottom" *ngIf="paginator">
    <mat-paginator class="paginator"
                showFirstLastButtons
                [length]="dataSource ? dataSource.resultsLength : 0"
                [pageIndex]="0"
                [pageSize]="pageSize"
                [pageSizeOptions]="pageSizes">
    </mat-paginator>
  </div>
</div>
