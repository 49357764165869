<h1 mat-dialog-title class="header">Användarvillkor</h1>

<mat-dialog-content class="dark-scrollbar">

<h2>1. Bakgrund och definitioner</h2>
<ol>
<li> Royal Schedule AB (org.nr. 559181-0402), Götgatan 55, 116 21 Stockholm, (”Leverantören”) är ett svenskt företag som har utvecklat ett schemaläggningsverktyg för automatisk schemaläggning. Parterna har ingått ett huvudavtal (”Avtalet”) i syfte att Leverantören skall tillhandahålla Kunden denna automatiska schemaläggning. Dessa allmänna avtalsvillkor (”Allmänna Villkor”) reglerar avtalsförhållandet mellan Parterna, tillsammans med Avtalet samt övriga bilagor, för schemaläggningsverktyget. </li>
<li> Dessa Allmänna Villkor gäller för avtalade tjänster mellan Leverantören och Kunden enligt Avtalet. Avtalet har alltid företräde framför dessa Allmänna Villkor. Dessa Allmänna Villkor gäller därmed för avtalade produkter och tjänster om inte annat skriftligt har överenskommits i Avtalet. </li>
<li> ”Tjänsten” refererar i dessa Allmänna Villkor till schemaläggningsverktyget vars omfattning framgår av Avtalet och dessa Allmänna Villkor.</li>
<li> ”Parterna” refererar till gemensamt Kunden och Leverantören. </li>
<li> ”Part” refererar till Parterna var för sig.</li>
</ol>

<h2>2. Avtalet och Allmänna Villkor</h2>
<ol>
<li> Avtalstidpunkten är den dag Parterna undertecknade Avtalet. Avtalet är därmed bindande för Parterna när firmatecknare eller annan behörig person för Parterna undertecknat Avtalet. </li>
<li> Leveranstidpunkt är den dag Leverantören gör Tjänsten tillgänglig för Kunden. </li>
<li> Avtalsperioden löper tolv (12) månader från den dag att Avtalet undertecknats, om inget annat avtalats, och förlängs automatiskt med ytterligare tolv (12) månader om ingen av Parterna säger upp Avtalet i enlighet med punkt 16. Uppsägning av Avtal. </li>
<li> Kundens rätt att nyttja Tjänsten är begränsad till Avtalsperioden och gäller för ett (1) skolår (höst- och vårtermin). Kunden får ej använda Tjänsten till skoltermin, eller annat, som ej ingår i Avtalsperioden. </li>
<li> Dessa Allmänna Villkor skall anses bindande vid Avtalstidpunkten för Avtalet där dessa Allmänna Villkor utgör Bilaga 4.   </li>
<li> Villkor i dessa Allmänna Villkor gäller även för andra utbildning, tilläggs- eller konsulttjänster om inte annat stadgas i separata avtal. </li>
<li> Avtalet med bilagor, inklusive dessa Allmänna Villkor, utgör Parternas fullständiga reglering av alla frågor som Tjänsten berör. Alla skriftliga eller muntliga åtaganden och utfästelser som föregått Avtalet ersätts av innehållet i Avtalet och gällande bilagor.  </li>
<li> Kunden accepterar att Leverantören får använda Kundens namn, varumärke och logga i marknadsföringssyfte. </li>
<li> I det fall någon punkt i Avtalet eller dessa Allmänna Villkor strider mot rådande lagstiftning skall sådan punkt anses som ogiltig. Övriga punkter i Avtalet eller dessa Allmänna Villkor skall fortsatt gälla. </li>
</ol>

<h2>3. Avtalad specifikation</h2>
<ol>
<li>Med avtalad specifikation menas skriftlig överenskommen omfattning av leveransen samt funktionella och andra krav enligt nedan:
Ett schemaläggningsverktyg som Kunden har rätt att använda för att skapa schema till sin skola eller annan utbildningsverksamhet. <br>
Övriga krav som skriftligen överenskommits mellan parterna på leveransen kriterier Tjänsten skall möta. <br>
</li>
</ol>

<h2>4. Förberedelse, installation och leverans av Tjänsten </h2>
<ol>
<li> Kunden skall utföra överenskomna, och andra enligt Leverantören anvisningar erforderliga, förberedelser för att ta del av Tjänsten. </li>
<li> Kunden skall, i enlighet med Avtalet, Allmänna Villkor och andra bilagor, utföra de installationer och åtaganden som Leverantören hänvisar för att ta del av Tjänsten. </li>
<li> Kunden skall skapa ett administratörskonto för att ta del av Tjänsten. De personer som har tillgång till Kundens administratörskonto skall förhålla sig till Avtalet, dessa Allmänna Villkor samt övriga bilagor vid användning Tjänsten.</li>
<li> Leverans av Tjänsten innebär att Kunden får tillgång till Tjänsten och kan skapa ett administratörskonto på <a href="https://app.royalschedule.com/">https://app.royalschedule.com/</a>. </li>
</ol>

<h2>5. Dokumentation och användning av Tjänsten</h2>
<ol>
<li> För att få tillgång till och kunna använda Tjänsten skall ett administratörskonto skapas på Leverantörens hemsida, <a href="https://app.royalschedule.com/">https://app.royalschedule.com/</a>. Kunden är ansvarig för att den information som anges är korrekt, aktuell och komplett. Kunden är ansvarig för att uppdatera informationen om den förändras så att den alltid är uppdaterad och följer de krav som stadgas. </li>
<li> I Tjänsten ingår användardokumentation i form av manualer för Kundens användning av Tjänsten. </li>
<li> Kunden får, genom sitt administratörskonto, använda Tjänsten för att skapa och redigera scheman. När ett schema skapats kan Kunden exportera schemat. Ett schema lagras tillsvidare, eller tills det att Kunden raderar schemat. Om Kunden önskar behålla ett Schema skall Kunden exportera schemat.</li>
<li> Leverantören förbehåller sig rätten att lagra scheman, preferens och tjänstefördelning i syfte att utveckla Tjänsten, ta fram statistik och analys samt för andra liknande syften även efter det att Kunden raderar sitt administratörskonto och upphör att vara Kund hos Leverantören. De uppgifter som lagras skall anonymiseras och följa Personuppgiftsbiträdesavtalet samt tillämplig lagstiftning.</li>
<li> Kunden får importera filer i Leverantörens plattform som tillhandahållits Kunden av Leverantören för att skapa scheman. Dessa filer ska endast innehålla den information som är nödvändig för schemaläggningsverktyget, såsom information om klassrum, lärare, kurser samt annan preferens och tjänstefördelning. Det är inte tillåtet att ladda upp andra filer än de som godkänts av Leverantören. Kunden är ansvarig för att inga känsliga, privata eller andra icke-nödvändiga uppgifter laddas upp i Tjänsten såsom stadgas i dessa Allmänna Villkor och Personuppgiftsbiträdesavtalet eller som strider mot tillämplig lagstiftning.</li>
<li> Vid uppladdning av filer ska Kunden även försäkra att alla filer: <br>
Är fria från virus och annat skadligt innehåll. <br>
Inte innehåller olagligt material eller annat icke önskvärt innehåll. <br>
Är i överenskommet format. <br>
I övrigt inte kan skada eller interferera med Tjänsten på ett sätt som inte uttryckligen tillåts.</li>
<li> Om Kunden väsentligen bryter mot punkt 5.6 i dessa Allmänna Villkor får Leverantören säga upp Avtalet enligt punkt 16. Uppsägning av avtal.</li>
<li> Det är kundens ansvar att upprätta en godtagbar preferens- och tjänstefördelning samt infoga dessa i schemaläggningsverktyget. Det schema som Tjänsten tar fram är beroende på en korrekt inmatning av information som tillåter att ett schema skapas utan konflikter. </li>
</ol>

<h2>6. Pris och betalning</h2>
<ol>
<li>Av Avtalet och Offert och Specifikation framgår gällande priser och avgifter för Tjänsten. Dessa är angivna exklusive mervärdesskatt, andra skatter och eventuella pålagor. Priserna är fasta för var Avtalsperiod.</li>
<li>Betalning ska erläggas i enlighet med Avtalet. Betalning sker mot faktura inom trettio (30) dagar om inget annat stadgats. Kunden accepterar att fakturor skickas på e-post till den adress Kunden använts sig av vid ingången av Avtalet.</li>
<li>Kunden kan addera ytterligare Konsult- och Utbildningstjänster till Tjänsten. Pris för dessa extratjänster är angiva i Prislista för utbildning och konsulttjänster (Bilaga 3) och dess omfattning ska överenskommas mellan Parterna. Priserna är angivna exklusive mervärdesskatt, andra skatter och eventuella pålagor.</li>
<li>Prisjustering sker genom index i Avtalet vid ny Avtalsperiod. Andra prisjusteringar skall tillkännages Kunden sextio (60) dagar innan ny avtalsperiod påbörjas för att anses giltig. Kunden och Leverantören äger alltid rätt att säga upp Avtalet innan ny avtalsperiod påbörjats, oavsett om priset justerats eller ej i enlighet med punkt 16. Uppsägning av Avtal.  Kunden är skyldig att betala vid var tid gällande pris för Tjänsten om uppsägning ej skett.</li>
</ol>

<h2>7. Dröjsmål med betalning</h2>
<ol>
<li> Betalar kunden inte i rätt tid har Leverantören rätt att erhålla dröjsmålsränta eller i förekommande fall innehålla Tjänsten eller del därav i enlighet med Avtalet.</li>
</ol>

<h2>8. Rätten att använda Tjänsten</h2>
<ol>
<li> Leverantören upplåter Kunden rätten att använda Tjänsten som definierats i Avtalet med de förutsättningar som följer av Avtalet, dessa Allmänna Villkor och övriga bilagor. Leverantören åtnjuter all äganderätt till Tjänsten och har full rätt att fortsätta utveckla Tjänsten och de programvaror Tjänsten består utav.</li>
<li> Kunden får inte kopiera Tjänsten, eller någon del därav, programprodukt eller annat som Leverantören tillhandahåller Kunden utom i de fall det uttryckligen framgår av Avtalet eller dessa Allmänna Villkor. </li>
<li> Kunden får inte under några omständigheter vidare upplåta eller vidareförsälja sin nyttjanderätt av Tjänsten. Kunden får inte kopiera Tjänsten, eller någon del därav, om det inte uttryckligen tillåtits av Leverantören. Kunden får inte tekniskt behandla Tjänsten eller Schemaläggningsverktyget utom såsom är uttryckligen stadgas enligt Avtalet, Allmänna Villkor eller svensk lag. Kunden får inte ändra eller radera något i Tjänsten såsom, men inte begränsat till, patent, copyright, varumärke eller andra immateriella rättigheter. </li>
<li> Kunden skall förvara inloggningsuppgifter till Tjänsten på ett betryggande sätt så att inte obehöriga kan ta del av dem. Detta innebär att Kunden måste vidta alla rimliga åtgärder för att hålla kontouppgifter hemliga och inte dela dem med någon, bortsett från lämpligen godkända anställda och liknande. </li>
<li> Har Tjänsten använts av någon obehörig person utanför Kundens organisation, är Kunden ansvarig för sådan icke tillåten användning och skall ersätta Leverantören för merkostnader det har inneburit för Leverantören, om Kunden:
  <ol type="a">
    <li> givit annan tillgång till Tjänsten. </li>
    <li> genom oaktsamhet gjort det möjligt för annan att utnyttja Tjänsten.</li>
  </ol>
</li>
<li> Om Kunden inte förhåller sig till dessa Allmänna Villkor eller Avtalet äger Leverantören rätten att meddela Kunden om överträdelsen och om Kunden inte omedelbart vidtar åtgärder radera Kundens administratörskonto och säga upp Avtalet. Om administratörskontot raderas får Leverantören även radera information, inkluderande scheman, som relaterar till Kunden och Tjänsten.</li>
</ol>

<h2>9. Underhåll</h2>
<ol>
<li> Leverantören utvecklar Tjänsten kontinuerligt. Leverantören förbehåller sig rätten att göra förändringar, uppdateringar och underhåll i Tjänsten utan att underrätta Kunden. </li>
<li> Leverantören kommer utföra underhåll i Tjänsten som kan innebära driftstopp i Tjänsten. Leverantören skall vidta skäliga ansträngningar för att minimera tiden för driftstopp av Tjänsten och i Leverantörens system.  </li>
</ol>

<h2>10. Ansvar för fel</h2>
<ol>
<li> Leverantören skall inom rimlig tidsperiod avhjälpa fel i Tjänsten på egen bekostnad om Tjänsten inte uppfyller avtalad specifikation enligt punkt 3. Avtalad specifikation. </li>
<li> Leverantörens ansvar omfattar inte fel som är utan betydelse för Kundens avsedda användning och som inte innebär en olägenhet för Kunden.</li>
<li> Leverantören är inte ansvarig för: <br>
Fel förorsakade genom Kundens användning av Tjänsten med utrustning som inte har överenskommits och som påverkar Tjänstens funktion. <br>
Fel förorsakade genom av Kunden företagna ändringar eller ingrepp i Tjänsten som skett utan Leverantörens samtycke. <br>
Fel förorsakade genom Kundens försumlighet. <br>
Fel förorsakade av virus eller andra utifrån kommande angrepp orsakade av Kunden. <br>
Andra fel som kan härledas till Kundens agerande i strid med Avtalet, övriga bilagor eller andra ageranden som strider mot syftet med leveransen och användandet av Tjänsten. <br>
Alla andra fel som rimligen inte kan belastas Leverantören </li>
<li> Leverantören skall vidta rimliga åtgärder för att avhjälpa fel och avbrott i Tjänsten. Kunden ska snarast anmäla fel, störningar och andra upplevda problem till Leverantören. Den kontaktperson som Kunden använder sig av skall vara tillgänglig för kontakt med Leverantören så att problemet kan lösas. Felanmälan och kontakt med Leverantören skall ske enligt punkt 18. Kontakt.</li>
<li> Leverantören ansvarar inte i något fall för indirekt skada, t.ex. förlorad vinst eller utebliven nytta av Avtalet eller Tjänsten.  </li>
<li> Leverantörens skadeståndsansvar är begränsat till ett belopp om högst 10 000 kr om annat inte framgår av Avtalet. </li>
</ol>

<h2>11. Immateriella rättigheter</h2>
<ol>
<li> Alla immateriella rättigheter till mjukvara, programvara, manualer samt annan egendom och information som Leverantören tillhandahåller Kunden enligt Avtalet är Leverantörens egendom. Detta innefattar, men begränsas inte till, upphovsrätt, varumärke och andra immateriella rättigheter. Inget i Avtalet skall tolkas så som att någon sådan rättighet överförs till Kunden.</li>
</ol>

<h2>12. Force majeure och andra ansvarsbegränsningar</h2>
<ol>
<li> Parterna skall ej vara ansvariga för, eller anses ha brutit mot Avtalet genom, försening eller underlåtenhet att prestera enligt detta avtal om det har sin grund i en oförutsedd händelse såsom krig, strejk, lockout, brand, katastrof, pandemi eller andra omständigheter som ligger utanför vad som rimligen kan anses vara inom parternas kontroll.</li>
<li> Om Kunden väljer att exportera schemat till annan plattform är Leverantören ej ansvarig för förluster, skador eller andra krav orsakade av tredje parters agerande.</li>
</ol>

<h2>13. Sekretess, säkerhet, GDPR och behandling av personuppgifter</h2>
<ol>
<li> Parterna förbinder sig att inte, utan andra Parts medgivande, till tredje man under avtalsperioden eller för en tid tre (3) år därefter utlämna sådana uppgifter som kan betraktas som affärs- och yrkeshemlighet. Det gäller dock inte information som är allmänt känd.</li>
<li> Leverantören avser att förhålla sig till lagar och regler gällande säkerhet, integritet, privatliv och annan data. Personliga uppgifter kommer att behandlas i enlighet med dataskyddsförordningen (General Data Protection Regulation, EU 2016/679).</li>
<li> Leverantören kommer med anledning av Avtalet att behandla personuppgifter för Kundens räkning.  Leverantören blir därmed personuppgiftsbiträde och Kunden personuppgiftsansvarig för behandling av personuppgifter.</li>
<li> Parterna åtar sig att efterleva de regler för behandling av personuppgifter som, vid var tidpunkt, gäller för behandling av personuppgifter. Med anledning av sådan personuppgiftsbehandling har Parterna ingått ett Personuppgiftsbiträdesavtal för att reglera förutsättningarna därför.</li>
</ol>

<h2>14. Cookies (kakor)</h2>
<ol>
<li> Cookies är små textfiler som Leverantören kan begära att få spara på Kundens dator. Leverantören använder i vissa delar av Tjänsten cookies för att tillhandahålla, förbättra, hantera och ta fram statistik om Tjänsten samt dess användning.  </li>
<li> Om Kunden inte accepterar cookies ska webbläsarens inställningar ändras i enlighet därmed. Vänligen notera att i sådant fall finns det en risk att Tjänsten inte fungerar så som avsett och att Leverantören inte då ansvarar för eventuella störningar, driftsfel eller andra fel. </li>
</ol>

<h2>15. Ändringar och uppdateringar av dessa Allmänna Villkor</h2>
<ol>
<li> Leverantören förbehåller sig rätten att uppdatera dessa Allmänna Villkor vid var tidpunkt. En uppdatering av dessa Allmänna Villkor ska meddelas Kunden via e-post trettio (30) dagar innan de blir gällande för avtalsförhållandet mellan Parterna.  </li>
<li> Om Kunden inte accepterar uppdateringen av dessa Allmänna Villkor ska det meddelas Leverantören, och anledningen varför, innan de nya Allmänna Villkoren blir tillämpliga på avtalsförhållandet. Om Kunden inte meddelar Leverantören att uppdateringen inte accepteras inom trettio (30) dagar från meddelandet har Kunden accepterat uppdateringen av dessa Allmänna Villkor. </li>
<li> Om Kunden inte accepterar en uppdatering av dessa Allmänna Villkor och en överenskommelse inte nås mellan Parterna ska de gamla Allmänna Villkoren gälla fram tills Avtalsperiodens slut. Om Kunden inte accepterar de nya Allmänna Villkoren vid ny Avtalsperiod sägs Avtalet upp, om inte annat överenskommits eller stadgas i Avtalet. </li>
</ol>

<h2>16. Uppsägning av Avtal</h2>
<ol>
<li> Kunden kan endast säga upp Avtalet trettio (30) dagar innan ny Avtalsperiod påbörjas eller enligt bestämmelser i Avtalet om hävning av avtalet. Avtalet förlängs med ett (1) år om ingen av Parterna säger upp Avtalet. Leverantören har full rätt att justera prisnivån och skall meddela Kunden det nya priset för Tjänsten senast trettio (30) dagar innan Avtalet förnyas. </li>
<li> Om Kunden väsentligen åsidosätter sina skyldigheter vid nyttjande av Tjänsten kan Leverantören säga upp Avtalet genom skriftligt meddelande. Vid sådan uppsägning har Kunden inte rätt till återbetalning.  </li>
<li> Leverantörens uppsägning av Avtalet eller prisjustering ska ske till den kontaktperson Kunden utsett. Kunden ska meddela Leverantören enligt punkt 18. Kontakt i dessa Allmänna Villkor. </li>
</ol>

<h2>17. Tillämplig lag och tvister</h2>
<ol>
<li> Svensk lag skall tillämpas på tvister mellan Parterna. </li>
<li> Tvist i anledning av dessa Allmänna Villkor skall avgöras genom allmän domstol med Stockholms tingsrätt som första instans.</li>
</ol>

<h2>18. Kontakt</h2>
<ol>
<li> Kunden skall kontakta Leverantören på support&#64;royalschedule.com för frågor och funderingar om dessa Allmänna Villkor eller för annan support.</li>
<li> Kunden skall utse en kontaktperson. Kontaktperson skall ha befogenhet att i samarbete med Leverantören fatta de beslut som kan krävas i anledning av Avtalet. Kontaktperson skall kunna nås via e-post.</li>
</ol>

</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-flat-button color="primary" mat-dialog-close>{{ 'common.close' | translate }}</button>
</mat-dialog-actions>