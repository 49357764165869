import { Component,
         OnInit,
         Input,
         EventEmitter,
         Output,
         OnDestroy                       } from '@angular/core';
import { coerceBooleanProperty           } from '@angular/cdk/coercion';
import { UntypedFormGroup,
         UntypedFormBuilder,
         Validators                      } from '@angular/forms';
import { animationFrame,
         Subject                         } from 'rxjs';
import { takeUntil,
         map                             } from 'rxjs/operators';
import $                                   from 'jquery';

import { Populated as P,
         DivisionSettings                } from 'app/shared/interfaces';

import { FormCore                        } from './form-core';
import { EnvironmentService,
         UserPreferencesService          } from '@app/core';

@Component({
  selector: 'app-course-form',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.scss']
})
export class CourseComponent extends FormCore
                             implements OnInit,
                                        OnDestroy {
  @Output() onSubmit:  EventEmitter<void> = new EventEmitter<void>();
  private onDestroy:   Subject<boolean>   = new Subject<boolean>();
  public submitted:    boolean            = false;
  public cutoff:       number             = 2;
  public numberOfDays: number             = 5;
  public form?:         UntypedFormGroup;

  constructor(private _fb:          UntypedFormBuilder,
              private _environment: EnvironmentService,
              private _preferences: UserPreferencesService) {
    super(_preferences, _environment);
    this._setForm();
  }

  ngOnInit() {

  }

  public keydown(event: KeyboardEvent): void {
    if (event.key == 'Enter' && this.form?.valid) {
      this.onSubmit.next();
    }
  }

  @Input()
  set focus(_field: string) {
    animationFrame.schedule(() => {
      const elem = $(`#${ _field }`)[0];
      elem?.focus();
    })
  }

  @Input()
  get value(): P.course {
    const { controls } = this.form!;
    return Object.fromEntries(
             Object.keys(controls)
                   .map((key: string) => [key, controls[key]])
                   .filter(([key, control]: any) => control.valid && ! control.pristine)
                   .map(([key, control]: any) => [key, control.value])
           )
  }
  set value(_val: P.course) {
    if (! _val) return;
    const val = _val;
    this.form?.reset();
    this.form?.patchValue(val);
  }

  get pristine(): boolean {
    return !! this.form?.pristine;
  }

  get valid(): boolean {
    return !! this.form?.valid;
  }

  @Input()
  get settings(): DivisionSettings | null {
    return this._settings;
  }
  set settings(_val: DivisionSettings | null) {
    if (! _val) return;
    this._settings = _val
  }
  private _settings: DivisionSettings;

  @Input()
  get locationList(): P.location[] {
    return this._locationList;
  }
  set locationList(_val: P.location[] | null) {
    this._locationList = _val ?? [];
  }
  private _locationList: P.location[] = [];

  @Input()
  get periodList(): any[] {
    return this._periodList;
  }
  set periodList(_val: any[] | null) {
    this._periodList = _val ?? [];
  }
  private _periodList: any[] = [];

  public onChange() {
    return this.form?.valueChanges
    .pipe(
      takeUntil(this.onDestroy),
      map(() => this.value)
    );
  }

  @Input()
  get hideCalendars(   ): boolean  { return this._hideCalendars;                        }
  set hideCalendars(_val: boolean | string) { this._hideCalendars = coerceBooleanProperty(_val); }
  private _hideCalendars: boolean = false;

  private _setForm(): void {
    this.form = this._fb.group({
      displayName:           [undefined, []],
      ids:                   [undefined, []],
      minBreakLength:        [undefined, []],
      lockedTimes:           [undefined, []],
      days:                  [undefined, []],
      subject:               [undefined, []],
      groups:                [undefined, []],
      teachers:              [undefined, []],
      locations:             [undefined, []],
      participants:          [undefined, []],
      intervals:             [undefined, []],
      centerOfAttraction:    [undefined, []],
      eventDurationVariance: [undefined, [<any>Validators.min(0), <any>Validators.max(1000)]],
      period:                [undefined, []],
    });
    this.form.markAsPristine();
    this.form.markAsUntouched();
  }

  public reset(): void {
    this.form?.reset();
    this.form?.markAsPristine();
    this.form?.markAsUntouched();
  }

  ngOnDestroy() {
    this.onDestroy.next(true);
    this.onDestroy.complete();
  }
}
