<!-- scroll left -->
<button mat-button
        class="scroll-arrow left mat-elevation-z4 white"
        *ngIf="isOverflowing"
        [disabled]=" ! isScrollable.left"
        (click)="scroll('left')">
  <mat-icon>chevron_left</mat-icon>
</button>


<div class="wrapper" #wrapper>
  <div class="content" #content resizeObserver (resize)="onResize()">
    <ng-content></ng-content>
  </div>
</div>

<!-- scroll right -->
<button mat-button
        class="scroll-arrow right mat-elevation-z4 white"
        *ngIf="isOverflowing"
        [disabled]=" ! isScrollable.right"
        (click)="scroll('right')">
  <mat-icon>chevron_right</mat-icon>
</button>
