<h1 matDialogTitle>
  {{ 'shared.dialogs.edit-course-and-event.title' | translate }}
</h1>

<mat-dialog-content class="dark-vertical-scrollbar">
  <app-submit-pane></app-submit-pane>

  <mat-tab-group class="tabs" mat-stretch-tabs="false" dynamicHeight>
    <mat-tab bodyClass="custom-padding">
      <ng-template mat-tab-label>
        <mat-icon inline class="tab-icon">{{ 'events' | collectionIcon }}</mat-icon>
        {{ 'common.event' | translate }}
      </ng-template>
      <app-event-form [duration]="false"
                      [settings]="settings | async"
                      [locationList]="locations | async"
                      [periodList]="periods | async"
                      hideCalendars>
      </app-event-form>
    </mat-tab>

    <mat-tab bodyClass="custom-padding">
      <ng-template mat-tab-label>
        <mat-icon inline class="tab-icon">{{ 'courses' | collectionIcon }}</mat-icon>
        {{ 'common.course' | translate }}
      </ng-template>
      <app-course-form [settings]="settings | async"
                        [locationList]="locations | async"
                        [periodList]="periods | async"
                        hideCalendars>
        </app-course-form>
    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button
    *ngIf="! pristine" @inOutAnimation
    (click)="resetValue()"
  >
    {{ 'common.restore' | translate }}
  </button>

  <span class="spacer"></span>

  <button mat-button mat-dialog-close>
    {{ 'common.cancel' | translate }}
  </button>

  <button mat-button color="primary"
    [disabled]="pristine"
    (click)="submit($event)" (keydown.enter)="submit($event)"
  >
    {{ 'common.save' | translate }}
  </button>
</mat-dialog-actions>