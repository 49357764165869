<form [formGroup]="form"
      novalidate
      (ngSubmit)="submit()">

  <div class="form-field-wrapper">
    <mat-form-field class="form-field" appearance="outline">
      <mat-label class="form-label">{{ 'shared.forms.company.name' | translate | translate }}</mat-label>
      <input matInput
             formControlName="name"
             class="input"
             (ngModelChange)="onFilter.next({ Skolenhetsnamn: $event })"
             autocomplete="off">
      <!-- custom autocomplete since the angular one does not support a virtual scroll -->
      <div class="custom-autocomplete" *ngIf="(onFilter | async)?.Skolenhetsnamn">
        <ng-container *ngTemplateOutlet="autocomplete"></ng-container>
      </div>
      <mat-error><app-form-field-error [control]="form.controls.name"></app-form-field-error></mat-error>
    </mat-form-field>
  </div>

  <div class="form-field-wrapper" *ngIf="schoolCode">
    <mat-form-field class="form-field" appearance="outline">
      <mat-label>{{ 'shared.forms.company.schoolCode' | translate }}</mat-label>
      <input type="text"
             matInput
             formControlName="schoolCode"
             (ngModelChange)="onFilter.next({ Skolenhetskod: $event })"
             autocomplete="off">
      <!-- custom autocomplete since the angular one does not support a virtual scroll -->
      <div class="custom-autocomplete" *ngIf="(onFilter | async)?.Skolenhetskod">
        <ng-container *ngTemplateOutlet="autocomplete"></ng-container>
      </div>
      <mat-error><app-form-field-error [control]="form.controls.schoolCode"></app-form-field-error></mat-error>
    </mat-form-field>
  </div>

  <div class="form-field-wrapper" *ngIf="organization">
    <mat-form-field class="form-field" appearance="outline">
      <mat-label>{{ 'shared.forms.company.organization' | translate }}</mat-label>
      <mat-select formControlName="organization">
        <mat-option *ngFor="let organization of organizations" [value]="organization">
          {{ organization ?? '-'}}
        </mat-option>
      </mat-select>
      <mat-error><app-form-field-error [control]="form.controls.organization"></app-form-field-error></mat-error>
    </mat-form-field>
  </div>

  <div class="form-field-wrapper" *ngIf="organizationType">
    <mat-form-field class="form-field" appearance="outline">
      <mat-label>{{ 'shared.forms.company.organizationType' | translate }}</mat-label>
      <mat-select formControlName="organizationType">
        <mat-option *ngFor="let organizationType of ORGANIZATION_TYPES" [value]="organizationType">
          {{ organizationType }}
        </mat-option>
      </mat-select>
      <mat-error><app-form-field-error [control]="form.controls.organizationType"></app-form-field-error></mat-error>
    </mat-form-field>
  </div>

  <div class="form-field-wrapper" *ngIf="theme">
    <mat-form-field class="form-field" appearance="outline">
      <mat-label>{{ 'shared.forms.company.theme' | translate }}</mat-label>
      <mat-select formControlName="theme">
        <mat-option *ngFor="let theme of THEMES" [value]="theme">
          {{ theme ?? '-'}}
        </mat-option>
      </mat-select>
      <mat-error><app-form-field-error [control]="form.controls.theme"></app-form-field-error></mat-error>
    </mat-form-field>
  </div>

  <div class="form-actions">
    <ng-content></ng-content>
  </div>
</form>


<ng-template #autocomplete>
  <cdk-virtual-scroll-viewport *ngIf="((filter | async)?.length ?? 0) > 10; else few" itemSize="48" class="dark-scrollbar">
    <mat-option *cdkVirtualFor="let option of filter | async" (click)="selectSchool(option)" (keydown.enter)="selectSchool(option)">
      {{option.Skolenhetskod}} / {{option.Skolenhetsnamn}}
    </mat-option>
  </cdk-virtual-scroll-viewport>

  <ng-template #few>
    <div class="few-container dark-scrollbar">
      <mat-option *ngFor="let option of filter | async" (click)="selectSchool(option)" (keydown.enter)="selectSchool(option)">
        {{option.Skolenhetskod}} / {{option.Skolenhetsnamn}}
      </mat-option>
    </div>
  </ng-template>
</ng-template>