export const LANG_EN_NAME = 'en';

export const LANG_EN_TRANS = {
  "account.index.header": "Manage account",
  "account.notifications.header": "Notifications",
  "account.notifications.menu": "Notifications",
  "account.notifications.page.descr": "The table below allows for adjusting various aspects of the notification system used throughout the app.",
  "account.notifications.page.title": "Notifications",
  "account.security.header": "Security",
  "account.security.menu": "Security",
  "account.security.page.change-password.descr": "Fill out the form below to change your login password.",
  "account.security.page.change-password.error.descr": "The provided current password did not match your actual one. Please try again.",
  "account.security.page.change-password.error.title": "Unable to update the password",
  "account.security.page.change-password.success.descr": "Your password has now been updated!",
  "account.security.page.change-password.success.title": "Success",
  "account.security.page.change-password.title": "Change password",
  "account.security.page.title": "Security",
  "account.shortcuts.header": "Shortcuts",
  "account.shortcuts.menu": "Shortcuts",
  "account.shortcuts.page.category.editor.description": "Shortcuts for the schedule editor.",
  "account.shortcuts.page.category.editor.title": "Editor",
  "account.shortcuts.page.description": "Listed below are keyboard shortcuts for common actions in the app. You may change them according to your preference by clicking on the shortcut and pressing the new key combination.",
  "account.shortcuts.page.editor/hideEvent.description": "Toggles the hidden state of the selected lessons.",
  "account.shortcuts.page.editor/hideEvent.label": "Hide lesson",
  "account.shortcuts.page.editor/moveEventEarlier.description": "Moves the selected lessons 5 minutes earlier.",
  "account.shortcuts.page.editor/moveEventEarlier.label": "Move lesson earlier",
  "account.shortcuts.page.editor/moveEventIndividually.description": "Hold down this key combination while dragging a linked lesson to move it individually.",
  "account.shortcuts.page.editor/moveEventIndividually.label": "Move linked lesson individually",
  "account.shortcuts.page.editor/moveEventLater.description": "Moves the selected lessons 5 minutes later.",
  "account.shortcuts.page.editor/moveEventLater.label": "Move lesson later",
  "account.shortcuts.page.editor/moveEventMuchEarlier.description": "Moves the selected lessons 60 minutes earlier.",
  "account.shortcuts.page.editor/moveEventMuchEarlier.label": "Move lesson much earlier",
  "account.shortcuts.page.editor/moveEventMuchLater.description": "Moves the selected lessons 60 minutes later.",
  "account.shortcuts.page.editor/moveEventMuchLater.label": "Move lesson much later",
  "account.shortcuts.page.editor/moveEventNextDay.description": "Moves the selected lessons to the next day.",
  "account.shortcuts.page.editor/moveEventNextDay.label": "Move lesson to the next day",
  "account.shortcuts.page.editor/moveEventPrevDay.description": "Moves the selected lessons to the previous day.",
  "account.shortcuts.page.editor/moveEventPrevDay.label": "Move lesson to the previous day",
  "account.shortcuts.page.editor/multipleSelectEvents.description": "Holding this key will allow you to select multiple lessons.",
  "account.shortcuts.page.editor/multipleSelectEvents.label": "Select multiple lessons",
  "account.shortcuts.page.editor/parkEvent.description": "Toggles the parked state of the selected lessons.",
  "account.shortcuts.page.editor/parkEvent.label": "Park lesson",
  "account.shortcuts.page.editor/pinEvent.description": "Toggles the pinned state of the selected lessons.",
  "account.shortcuts.page.editor/pinEvent.label": "Pin lesson",
  "account.shortcuts.page.interference": "The command for this shortcut is already used by \"{{ label }}\" of the same category. Please choose another one.",
  "account.shortcuts.page.notifications.error": "Failed to update the shortcut. Please try again.",
  "account.shortcuts.page.restore.description": "Restore all shortcuts to their default values.",
  "account.shortcuts.page.title": "Keyboard shortcuts",
  "account.workspace.header": "Workspace",
  "account.workspace.menu": "Workspace",
  "account.workspace.page.backgroundDataAnalysis.descr": "This feature continuously analyzes your schedule data and provides you with suggestions for improvements. The continuous analysis sometimes affect the overall performance of the application and may therefore be disabled. Later it can be re-enabled at any time.",
  "account.workspace.page.backgroundDataAnalysis.label": "Background data analysis",
  "account.workspace.page.defaultEventDurations.descr": "Here, you can define sets of default lesson durations to make it easier to create lessons.",
  "account.workspace.page.defaultEventDurations.label": "Default lesson durations",
  "account.workspace.page.descr": "These types of preferences allow you to modify and customize the workspace of the app.",
  "account.workspace.page.displayCenterOfAttraction.descr": "The preferred time determines what point in time lessons are drawn to during the automatic scheduling procedure. By enabling this option, the default preferred time may be overridden for individual courses and lessons.",
  "account.workspace.page.displayCenterOfAttraction.label": "Show the <i>preferred time</i> course and lesson option",
  "account.workspace.page.displayChat.descr": "Whether the chat in the lower-left corner is visible.",
  "account.workspace.page.displayChat.label": "Display chat",
  "account.workspace.page.displayEventsTable.descr": "In this table, you will find the lessons from all courses.",
  "account.workspace.page.displayEventsTable.label": "Show lessons table",
  "account.workspace.page.displayPersonsTable.descr": "In this table, you will find students from all groups and those who are not members of any group.",
  "account.workspace.page.displayPersonsTable.label": "Show students table",
  "account.workspace.page.displayTooltips.descr": "Whether tooltips with information explaining the concept further should be visible.",
  "account.workspace.page.displayTooltips.label": "Show tooltips",
  "account.workspace.page.language.descr": "Sets the language of the app.",
  "account.workspace.page.notifications.error": "Failed to update the preference. Please try again.",
  "account.workspace.page.title": "Workspace",
  "attributeDescriptions.course.centerOfAttraction": "The lessons belonging to the course are drawn to their preferred time during the automatic scheduling process",
  "attributeDescriptions.course.color": "Sets the display color for the lessons belonging to the course",
  "attributeDescriptions.course.days": "Sets the allowed days for the lessons belonging to the course",
  "attributeDescriptions.course.eventDurationVariation": "Sets the maximum difference between the actual duration of a lesson and its preferred duration for lessons belonging to the course. The total course duration is however preserved.",
  "attributeDescriptions.course.events": "The lessons belonging to the course",
  "attributeDescriptions.course.groups": "Sets the groups that will take the lessons belonging to the course",
  "attributeDescriptions.course.intervals": "Sets the time frame for the lessons belonging to the course",
  "attributeDescriptions.course.locations": "Sets the rooms that can be booked for the lessons belonging to the course",
  "attributeDescriptions.course.lockedTimes": "Sets prohibited time intervals for the lessons belonging to the course",
  "attributeDescriptions.course.minBreakLength": "Sets the shortest allowed break before and after the lessons belonging to the course",
  "attributeDescriptions.course.overlapGroup": "Defines a set of courses whose lessons are allowed to overlap. The lessons belonging to the same course are however never allowed to overlap. During the automatic scheduling process, lessons within such a group are attempted to be paired up in the best possible way.",
  "attributeDescriptions.course.period": "Sets the time period for the lessons belonging to the course, e.g., fall, spring, or odd and even weeks. Please note that before a period may be selected it must be created.",
  "attributeDescriptions.course.subject": "The subject of the course",
  "attributeDescriptions.course.teachers": "Sets the teachers that will teach the lessons belonging to the course",
  "attributeDescriptions.event.centerOfAttraction": "The lesson is drawn to its preferred time during the automatic scheduling process",
  "attributeDescriptions.event.color": "Sets the display color of the lesson",
  "attributeDescriptions.event.course": "The course the lesson belongs to",
  "attributeDescriptions.event.courseId": "The public ID of the course the lesson belongs to",
  "attributeDescriptions.event.days": "Sets the lesson's allowed days",
  "attributeDescriptions.event.duration": "Sets the duration of the lesson",
  "attributeDescriptions.event.durationVariation": "Sets the maximum difference between the actual duration of the lesson and its preferred duration. The total course duration is however preserved.",
  "attributeDescriptions.event.fixedStart": "Fixates the start time of the lesson",
  "attributeDescriptions.event.groups": "Sets the groups that will take the lesson",
  "attributeDescriptions.event.intervals": "Sets the time frame for the lesson",
  "attributeDescriptions.event.locations": "Sets the rooms that can be booked for the lesson",
  "attributeDescriptions.event.lockedTimes": "Sets prohibited time intervals for the lesson",
  "attributeDescriptions.event.minBreakLength": "Sets the shortest allowed break before and after the lesson",
  "attributeDescriptions.event.overlapSpecies": "Linked lessons stick tick together and will be scheduled at the same time",
  "attributeDescriptions.event.period": "Sets the time period for the lesson, e.g., fall, spring, or odd and even weeks. Please note that before a period may be selected it must have been created.",
  "attributeDescriptions.event.preferredDuration": "Sets the preferred duration of the lesson. After the automatic scheduling process the actual duration will not exceed \"preferred duration ± duration variation\"",
  "attributeDescriptions.event.teachers": "Sets the teachers that will teach the lesson",
  "attributeDescriptions.group.days": "Sets the group's allowed days",
  "attributeDescriptions.group.intervals": "Sets the time frame for the group",
  "attributeDescriptions.group.lockedTimes": "Sets prohibited time intervals for the group",
  "attributeDescriptions.group.lunch": "Sets the group's lunch breaks",
  "attributeDescriptions.group.minBreakLength": "Sets the shortest allowed break between lessons for the group",
  "attributeDescriptions.group.rootIntervals": "Sets the group's blocks",
  "attributeDescriptions.groups.members": "Sets the members of the group",
  "attributeDescriptions.groups.species": "Sets the group's type",
  "attributeDescriptions.groups.parentGroups": "Sets the group's constituent classes based on the members of the group.",
  "attributeDescriptions.location.days": "Sets the room's allowed days",
  "attributeDescriptions.location.lockedTimes": "Sets prohibited time intervals for the room",
  "attributeDescriptions.location.minBreakLength": "Sets the shortest time margin between two bookings of the room",
  "attributeDescriptions.person.emails": "Sets the student's email",
  "attributeDescriptions.person.firstName": "Sets the student's first name",
  "attributeDescriptions.person.group": "Sets the student's group",
  "attributeDescriptions.person.lastName": "Sets the student's last name",
  "attributeDescriptions.person.lockedTimes": "Sets prohibited time intervals for the student",
  "attributeDescriptions.person.lunch": "Sets the student's lunch breaks",
  "attributeDescriptions.person.phoneNumbers": "Sets the student's phone number",
  "attributeDescriptions.person.sex": "Sets the student's gender",
  "attributeDescriptions.person.SSN": "Sets the student's social security number",
  "attributeDescriptions.shared.displayName": "A name used throughout the app",
  "attributeDescriptions.teacher.days": "Sets the teacher's allowed days",
  "attributeDescriptions.teacher.emails": "Sets the teacher's email",
  "attributeDescriptions.teacher.firstName": "Sets the teacher's first name",
  "attributeDescriptions.teacher.intervals": "Sets the time frame for the teacher",
  "attributeDescriptions.teacher.lastName": "Sets the teachers' last name",
  "attributeDescriptions.teacher.lockedTimes": "Sets prohibited time intervals for the teacher",
  "attributeDescriptions.teacher.lunch": "Sets the teacher's lunch breaks",
  "attributeDescriptions.teacher.minBreakLength": "Sets the shortest allowed break between lessons for the teacher",
  "attributes.course.eventDurationVariation": "Lesson duration variation",
  "attributes.course.events": "Lessons",
  "attributes.course.overlapGroup": "Parallel courses",
  "attributes.division.default.dayRank": "Default day ranking",
  "attributes.division.default.dependencyMinimumBreakLength": "For rooms",
  "attributes.division.default.dependencyRank": "Default room ranking",
  "attributes.division.default.eventDurationVariation": "Lesson duration variation",
  "attributes.division.default.eventMinimumBreakLength": "Between lessons",
  "attributes.division.default.groupMinimumBreakLength": "For groups and teachers",
  "attributes.division.default.lunch": "Default lunch break",
  "attributes.division.default.maxNumDailyWorkingHours": "Daily",
  "attributes.division.default.maxNumWorkingHours": "Total",
  "attributes.division.default.period": "Period",
  "attributes.division.end": "End date",
  "attributes.division.published": "publish",
  "attributes.division.settings.dayEnd": "Day end",
  "attributes.division.settings.dayStart": "Day start",
  "attributes.division.settings.discretization": "Discretization",
  "attributes.division.settings.numDays": "Number of days",
  "attributes.division.settings.potentialCenter": "Preferred time",
  "attributes.division.start": "Start date",
  "attributes.event.course": "Course",
  "attributes.event.courseId": "Course ID",
  "attributes.event.current_duration": "Current lesson duration",
  "attributes.event.durationVariation": "Duration variation",
  "attributes.event.fixedStart": "Fixed start time",
  "attributes.event.inherit": "Inherit from course",
  "attributes.event.overlapSpecies": "Linked lessons",
  "attributes.event.preferredDuration": "Lesson duration",
  "attributes.generation.status.COMPLETED": "Done",
  "attributes.generation.status.PENDING": "Waiting",
  "attributes.generation.status.STARTED": "Started",
  "attributes.groups.parentGroups": "Constituent classes",
  "attributes.groups.species.none": "Teaching group",
  "attributes.groups.species.class": "Class",
  "attributes.groups.species": "Group type",
  "attributes.groups.members": "Members",
  "attributes.teachers.signature": "Signature",
  "attributes.integrations.APPROVED": "Active",
  "attributes.integrations.DISABLED": "Disabled",
  "attributes.integrations.PENDING": "Pending",
  "attributes.integrations.REJECTED": "Rejected",
  "attributes.location.days": "Available days",
  "attributes.location.minBreakLength": "Time margin",
  "attributes.person.firstName": "First name",
  "attributes.person.group": "Group",
  "attributes.person.lastName": "Last name",
  "attributes.person.sex": "Gender",
  "attributes.person.SSN": "Social security number",
  "attributes.shared.centerOfAttraction": "Preferred time",
  "attributes.shared.createdAt": "Created at",
  "attributes.shared.color": "Color",
  "attributes.shared.course": "Course",
  "attributes.shared.day": "Day",
  "attributes.shared.days": "Allowed days",
  "attributes.shared.displayName": "Name",
  "attributes.shared.duration": "Duration",
  "attributes.shared.durationVariation": "Duration variation",
  "attributes.shared.dynamicLockedTime.custom": "Set for each day",
  "attributes.shared.dynamicLockedTime.default": "Default value",
  "attributes.shared.dynamicLockedTime.none": "None",
  "attributes.shared.dynamicLockedTime.repeated": "Same for each day",
  "attributes.shared.emails": "Email",
  "attributes.shared.end": "End",
  "attributes.shared.endTime": "End time",
  "attributes.shared.groups": "Groups",
  "attributes.shared.intervals": "Time frame",
  "attributes.shared.intervals.multiple": "Different for each day",
  "attributes.shared.intervals.none": "Use default time frame",
  "attributes.shared.intervals.single": "Same for each day",
  "attributes.shared.locations": "Allowed rooms",
  "attributes.shared.lockedTimes": "Prohibited times",
  "attributes.shared.lunch": "Lunch",
  "attributes.shared.minBreakLength": "Break lengths",
  "attributes.shared.minBreakLength.asymmetrical": "Different lengths",
  "attributes.shared.minBreakLength.asymmetrical.after": "After",
  "attributes.shared.minBreakLength.asymmetrical.before": "Before",
  "attributes.shared.minBreakLength.asymmetrical.tooltip": "{{ before }} before and {{ after }} after",
  "attributes.shared.minBreakLength.default": "Default value",
  "attributes.shared.minBreakLength.none": "None",
  "attributes.shared.minBreakLength.symmetrical": "Equal lengths before & after",
  "attributes.shared.period": "Period",
  "attributes.shared.period.entire": "All weeks",
  "attributes.shared.phoneNumbers": "Phone numbers",
  "attributes.shared.rootIntervals": "Blocks",
  "attributes.shared.start": "Start",
  "attributes.shared.startTime": "Start time",
  "attributes.shared.subject": "Subject",
  "attributes.shared.teachers": "Teachers",
  "auth.role.admin": "Admin",
  "auth.role.partner": "Partner",
  "auth.role.unrestricted": "Regular user",
  "common.goto_schedule": "Go to schedule",
  "common.+_n_more": "+ {{n}} more",
  "common.a_lunch_belonging_to_the_group": "a lunch belonging to the group",
  "common.a_lunch_belonging_to_the_person": "a lunch belonging to the student",
  "common.a_lunch_belonging_to_the_teacher": "a lunch belonging to the teacher",
  "common.about": "Status",
  "common.add": "Add",
  "common.add_course": "Add course",
  "common.add_event": "Add lesson(s): {{ event-durations }}",
  "common.add_event_advanced": "Add lesson(s)",
  "common.add_filter": "Add filter",
  "common.add_group": "Add group",
  "common.add_location": "Add room",
  "common.add_one_more": "Add one more",
  "common.add_period": "Add period",
  "common.add_person": "Add student",
  "common.add_teacher": "Add teacher",
  "common.advanced_settings": "Advanced settings",
  "common.all": "All",
  "common.alone": "Alone",
  "common.an_event_belonging_to_the_course": "a lesson belonging to the course",
  "common.analysis": "Analysis",
  "common.and": "and",
  "common.and1Other": "and one other",
  "common.andNOthers": "and {{n}} others",
  "common.associated_partner": "Associated partner",
  "common.back": "Back",
  "common.back_to_login": "Back to login",
  "common.calendar_view": "Calendar view",
  "common.cancel": "Cancel",
  "common.change_password": "Change password",
  "common.chooseOther": "Choose other",
  "common.clear": "Clear",
  "common.close": "Close",
  "common.complete": "Complete",
  "common.computing": "Computing",
  "common.confirmation": "Confirmation",
  "common.connect": "Connect",
  "common.connect_error": "Connection error",
  "common.copy": "Copy",
  "common.country": "Country",
  "common.counts": "Counts",
  "common.course": "Course",
  "common.courses": "Courses",
  "common.create": "Create",
  "common.create_course": "Create course",
  "common.create_group": "Create group",
  "common.create_location": "Create room",
  "common.create_person": "Create student",
  "common.create_schedule": "Create Schedule",
  "common.create_teacher": "Create teacher",
  "common.created_at": "Created",
  "common.creating": "Creating",
  "common.creation_fail": "Something went wrong",
  "common.creation_success": "Created",
  "common.custom_search": "Custom search",
  "common.day": "Day",
  "common.days": "Days",
  "common.default": "Default",
  "common.delete": "Delete",
  "common.delete_account": "Remove account",
  "common.deleting": "Deleting",
  "common.discard": "Discard",
  "common.download": "Download",
  "common.drop_here": "Drop here",
  "common.duplicate": "Duplicate",
  "common.duplicated": "Duplicated",
  "common.earliest": "Earliest",
  "common.edit": "Edit",
  "common.edit_columns": "Edit columns",
  "common.edit_failed": "Failed to save changes",
  "common.edited_at": "Last edited",
  "common.email": "email",
  "common.empty": "Empty",
  "common.erroneous_value": "Invalid value",
  "common.error": "Error",
  "common.errors": "errors",
  "common.event": "Lesson",
  "common.event_durations": "lesson durations",
  "common.events": "Lessons",
  "common.expand": "Expand",
  "common.export": "Export",
  "common.filter": "Filter",
  "common.flaw": "flaw",
  "common.flaws": "flaws",
  "common.forgot_password": "Forgot password?",
  "common.generalErrorMessage": "Whoopsie, something went wrong...",
  "common.generic_error": "Something went wrong",
  "common.generic_help": "We are working on solving the problem.",
  "common.goto-tutorial": "Go to tutorial",
  "common.group": "Group",
  "common.groups": "Groups",
  "common.help": "Help",
  "common.hide": "Hide",
  "common.hour": "Hour",
  "common.hour_short": "hr",
  "common.hours": "Hours",
  "common.hours_short": "hrs",
  "common.ignore": "Ignore",
  "common.import": "Import",
  "common.inheritFromCourse": "Inherit from course",
  "common.inheritFromCourseValue": "Inherit from course ({{value}})",
  "common.invalid": "Invalid",
  "common.invalid_division_id": "Schedule could not be found",
  "common.invalid_value": "Invalid value",
  "common.language": "Language",
  "common.latest": "Latest",
  "common.linked_events": "Linked lessons",
  "common.list_view": "List view",
  "common.loading": "Loading",
  "common.location": "Room",
  "common.locations": "Rooms",
  "common.locked": "Locked",
  "common.locked_time": "Prohibited time",
  "common.locked_times": "Prohibited times",
  "common.lockedTime": "Prohibited time",
  "common.lockedTimes": "Prohibited times",
  "common.login": "Log in",
  "common.logout": "Log out",
  "common.lunch": "Lunch",
  "common.make": "make",
  "common.makes": "makes",
  "common.manage_account": "Manage your account",
  "common.minimize": "Minimize",
  "common.minute": "Minute",
  "common.minute_short": "min",
  "common.minutes": "Minutes",
  "common.minutes_short": "mins",
  "common.modified_after": "Modified after",
  "common.nameless": "Untitled",
  "common.next": "Next",
  "common.no": "No",
  "common.no_available_data": "No available data",
  "common.no_events_to_show": "No lessons to show",
  "common.no_match": "No match",
  "common.no_value": "No value",
  "common.none_plural": "None",
  "common.none_singular": "None",
  "common.not_placed": "Not placed",
  "common.of": "of",
  "common.or": "Or",
  "common.other": "Other",
  "common.others": "others",
  "common.overlapGroup": "Set of parallel courses",
  "common.overlapGroups": "Sets of parallel courses",
  "common.period": "Period",
  "common.periods": "Periods",
  "common.person": "Student",
  "common.persons": "Students",
  "common.print": "Print",
  "common.related-content": "Related content",
  "common.reload": "Reload",
  "common.remaining": "Remaining",
  "common.remove": "Remove",
  "common.rename": "Rename",
  "common.resend": "Resend",
  "common.reset": "Clear",
  "common.reset_filter": "Reset filter",
  "common.reset_password_fail": "Could not send a reset link to this user",
  "common.reset_password_success": "A reset link was sent to",
  "common.restore": "Restore",
  "common.retry": "Retry",
  "common.rootInterval": "Block",
  "common.rootIntervals": "Block",
  "common.save": "Save",
  "common.save_failed": "Save failed",
  "common.saved": "Saved",
  "common.saving": "Saving",
  "common.schedule": "Schedule",
  "common.school": "School",
  "common.school_year": "School year",
  "common.screen_size": "Screen size too small",
  "common.search": "Search",
  "common.search.course": "Search for course",
  "common.search.event": "Search for lesson",
  "common.seconds": "Seconds",
  "common.seconds_short": "s",
  "common.select": "Select",
  "common.select_entity_to_show_events": "Select a group, teacher, or room to show its lessons.",
  "common.select_file": "Select file",
  "common.selected": "Selected",
  "common.selected_file": "Selected file: {{file}}",
  "common.settings": "Settings",
  "common.sex.man": "Man",
  "common.sex.woman": "Woman",
  "common.share": "Share",
  "common.show": "Show",
  "common.show_all_events": "Show all lessons",
  "common.sports_facility": "Sports facility",
  "common.subject": "Subject",
  "common.subjects": "Subjects",
  "common.sync": "synchronise\n",
  "common.synchronising": "Synchronising",
  "common.teacher": "Teacher",
  "common.teachers": "Teachers",
  "common.terms": "Terms and conditions",
  "common.the_course": "The course",
  "common.the_courses": "The courses",
  "common.the_event": "The lesson",
  "common.the_events": "The lessons",
  "common.the_group": "The group",
  "common.the_groups": "The groups",
  "common.the_location": "The room",
  "common.the_locations": "The rooms",
  "common.the_lunch": "The lunch",
  "common.the_lunches": "The lunches",
  "common.the_period": "The period",
  "common.the_periods": "The periods",
  "common.the_person": "The student",
  "common.the_persons": "The students",
  "common.the_subjects": "The subjects",
  "common.the_teacher": "The teacher",
  "common.the_teachers": "The teachers",
  "common.time_settings": "Time settings",
  "common.toggle_all": "Toggle all",
  "common.total": "Total",
  "common.type": "Type",
  "common.unknown": "unknown",
  "common.update": "Update",
  "common.updated": "Updated",
  "common.upload": "Upload",
  "common.useDefault": "Use default",
  "common.useDefaultValue": "Use default ({{value}})",
  "common.username": "Username",
  "common.users": "Users",
  "common.value": "Value",
  "common.versions": "Versions",
  "common.warning": "warning",
  "common.warnings": "warnings",
  "common.week": "Week",
  "common.week_short": "wk.",
  "common.weeks": "Weeks",
  "common.yes": "Yes",
  "common.YYYY-MM-DD": "YYYY-MM-DD",
  "common.zoom_center": "Restore zoom",
  "common.zoom_in": "Zoom in",
  "common.zoom_out": "Zoom out",
  "common.today": "Today",
  "common.tomorrow": "Tomorrow",
  "common.this_week": "This week",
  "common.next_week": "Next week",
  "common.this_month": "This month",
  "common.next_month": "Next month",
  "common.modify": "Modify",
  "common.calendar": "Calendar",
  "common.list": "List",
  "common.changes": "Changes",
  "common.no_selected": "No selected",
  "common.no_exceptions": "No exceptions",
  "common.no_absences": "No absences",
  "common.substitutions": "Substitutions",
  "common.absences": "Absences",
  "common.absentees": "Absentees",
  "common.reason": "Reason",
  "common.filter_by_groups": "Filter by groups",
  "common.not_available_for_current_selection": "Not available for current selection",
  'common.cancel_event': 'Cancel lesson',
  'common.comment': 'Comment',
  "common.error_code": "Error code",
  "current language": "English",
  "dialogs.admentumImport.step_1.descr": "Here you may choose which schedule and associated courses, teachers, etc. to import from the Admentum platform. If you have multiple schedules for a school year, they can be differentiated by their ID number.",
  "dialogs.admentumImport.step_1.required": "Please choose a schedule to continue.",
  "dialogs.admentumImport.step_1.title": "Choose a schedule",
  "dialogs.admentumImport.step_2.descr": "By choosing a week, all lessons within that week will be imported. Consequently, if no week is chosen, no lessons will be imported from Admentum.",
  "dialogs.admentumImport.step_2.title": "Choose a week",
  "dialogs.admentumImport.step_3.descr": "You may change the schedule name at any point after the import.",
  "dialogs.admentumImport.step_3.label": "Schedule name",
  "dialogs.admentumImport.step_3.required": "Please give the schedule a name.",
  "dialogs.admentumImport.step_3.title": "Give the schedule a name",
  "dialogs.create-courses.by-hand.label": "By hand",
  "dialogs.create-courses.in-bulk.for_each_of": "Create course(s) for each of",
  "dialogs.create-courses.in-bulk.label": "In bulk",
  "inputAnalysis.computing": "Computing...",
  "inputAnalysis.dialog.description": "The schedule data is analyzed continuously and the results are presented in the three tabs to the right. Each tab represents a category of flaws in the schedule data, which are described below.",
  "inputAnalysis.dialog.empty_category": "There are no remarks belonging to this category",
  "inputAnalysis.dialog.overview.title": "Overview",
  "inputAnalysis.dialog.title": "Schedule data analysis",
  "inputAnalysis.disabled": "The data analysis is disabled but may be enabled at any time under the workspace settings.",
  "inputAnalysis.entities.theLunch": "The lunch belonging to {{ theGroupOrTeacher }}",
  "inputAnalysis.errors.21.10.0.description": "There is no common allowed day for the lesson and the groups, teachers and rooms that are connected to it. See which days are blocked below:",
  "inputAnalysis.errors.21.10.0.title": "{{The-entity}} has no allowed days according to the schedule data.",
  "inputAnalysis.errors.21.10.1.description": "This could be due to several reasons, e.g., time intervals prohibited by connected groups and teachers, or days excluded due to chosen rooms. Use the calendar tool below to find out what is the cause of this particular case and how it might be solved.",
  "inputAnalysis.errors.21.10.1.title": "{{The-entity}} does not fit inside its available time frame.",
  "inputAnalysis.errors.24.0.description": "Each lesson by itself has at least one day available, but the problem arises when they are linked and thus scheduled on the same day.",
  "inputAnalysis.errors.24.0.title": "{{The-entities}} are linked but do not share an allowed day.",
  "inputAnalysis.errors.24.1.description": "Each lesson by itself can fit in some time interval, but the problem arises when they are linked and thus scheduled together.",
  "inputAnalysis.errors.24.1.title": "{{The-entities}} are linked but cannot be scheduled together due to time interval constraints",
  "inputAnalysis.errors.description": "Unable to auto schedule until these errors have been resolved.",
  "inputAnalysis.errors.missing_overlap_species.description": "Lessons belonging to parallel courses are not guaranteed to be scheduled at the same time after a schedule has been generated, that is, unless they are linked. This critical error can be disabled in the schedule settings.",
  "inputAnalysis.errors.missing_overlap_species.title": "{{The-entities}} {{is/are-parallel-courses}}, but one or more associated lessons are not linked.",
  "inputAnalysis.errors.notification_title": "The schedule data contains critical errors.",
  "inputAnalysis.errors.title": "Critical errors",
  "inputAnalysis.etc.modify_period": "Modify period",
  "inputAnalysis.etc.schedule_settings": "Schedule settings",
  "inputAnalysis.etc.schedule-settings": "Schedule settings",
  "inputAnalysis.etc.specify_forced_lesson_overlaps": "link lessons",
  "inputAnalysis.issues.description": "Able to auto schedule but the result will not be optimal. We therefore recommend that these problems are resolved before proceeding.",
  "inputAnalysis.issues.notification_title": "The schedule data contains problems",
  "inputAnalysis.issues.overloaded group.description": "It will be difficult or impossible to schedule this group without any schedule conflicts.",
  "inputAnalysis.issues.overloaded group.title": "{{The-entity}} is overloaded with lessons.",
  "inputAnalysis.issues.title": "Problems",
  "inputAnalysis.modules.event intervals.affecting_events": "Affecting lessons",
  "inputAnalysis.modules.event intervals.description": "Hover the mouse over teachers, groups, etc. below to see how their unavailable time intervals, displayed in the calendar in red, affect the lesson. You may also do the opposite, i.e., hover the mouse over the calendar to see if the corresponding placement of the lesson is permitted by those teachers, groups etc. If their background turns striped it means that placement is not permitted.",
  "inputAnalysis.modules.event intervals.this event": "This lesson",
  "inputAnalysis.modules.overloaded group.contributing events": "Contributing lessons",
  "inputAnalysis.modules.overloaded group.description": "The percentage shows how much of a time interval is occupied by lessons. <b>All percentages above 100% indicate a guaranteed schedule conflict.</b> <br> <b>Example:</b> If a 30-minute lesson is allowed to be placed on a one-hour time interval, the booking percentage is 50%. If, however, lessons equivalent to two hours are to be placed on the same interval, the booking percentage is 200%, which will lead to a conflict.<br> Common mistakes that may have contributed to the booking percentage being greater than 100%:  <ul> <li>Forgot to create overlapping lesson sets. Remember to place all lessons that are to be taught in parallel in an overlapping lesson set. <li>Too many lessons in the morning/evening.</li><li>Too many lessons on specific days.</li></ul>",
  "inputAnalysis.modules.overloaded group.legend.bad": "Bad",
  "inputAnalysis.modules.overloaded group.legend.compact": "Compact",
  "inputAnalysis.modules.overloaded group.legend.conflict": "Conflict",
  "inputAnalysis.modules.overloaded group.legend.fixed": "Fixed",
  "inputAnalysis.modules.overloaded group.legend.good": "Good",
  "inputAnalysis.modules.overloaded group.legend.sparse": "Sparse",
  "inputAnalysis.notices.completely unconnected event.description": "These types of completely unconnected lessons are ignored by the algorithm as they don't affect anything.",
  "inputAnalysis.notices.completely unconnected event.title": "{{The-entity}} is not connected to a teacher, a student, a group, or a room.",
  "inputAnalysis.notices.description": "May indicate that the schedule data is incomplete.",
  "inputAnalysis.notices.empty course.description": "Courses without lessons will be ignored by the algorithm.",
  "inputAnalysis.notices.empty course.title": "{{The-entity}} does not contain any lessons.",
  "inputAnalysis.notices.empty_period.description": "Did you forget to include weeks in the period?",
  "inputAnalysis.notices.empty_period.title": "{{The-entity}} is empty.",
  "inputAnalysis.notices.missing_overlap_species.description": "Lessons belonging to parallel courses are not guaranteed to be scheduled at the same time after a schedule has been generated, that is, unless they are linked.",
  "inputAnalysis.notices.missing_overlap_species.title": "{{The-entities}} {{is/are-parallel-courses}}, but one or more associated lessons are not linked.",
  "inputAnalysis.notices.notification_title": "The schedule data may be incomplete.",
  "inputAnalysis.notices.overflowing_period.description": "Did you forget to update the period after changing the schedule's period?",
  "inputAnalysis.notices.overflowing_period.title": "{{The-entity}} partially lays outside the schedule's period.",
  "inputAnalysis.notices.title": "Remarks",
  "inputAnalysis.notices.unconnected event.description": "Did you forget to add a teacher, a student or a group?",
  "inputAnalysis.notices.unconnected event.title": "{{The-entity}} is not connected to a teacher, a student, or a group.",
  "inputAnalysis.ok.notification_title": "No remarks regarding the schedule data",
  "schedule.guaranteedTuition.label": "Guaranteed tuition",
  "schedule.guaranteedTuition.tabs.calendarExceptions.descr": "Here you may add holidays and days off. These days will not be taken into account when the total scheduled time is calculated.",
  "view.private.schema.sub-pages.settings.page.tab.holidays.dialog.form.description.label": "Description",
  "view.private.schema.sub-pages.settings.page.tab.holidays.dialog.form.range.label": "Date range",
  "schedule.guaranteedTuition.tabs.calendarExceptions.tabLabel": "Add days off",
  "schedule.guaranteedTuition.tabs.calendarExceptions.title": "Add days off",
  "schedule.guaranteedTuition.tabs.dayCount.descr": "The following table shows the number of times each day appears in the schedule, given its start and end date as well as the days off specified above.",
  "schedule.guaranteedTuition.tabs.dayCount.title": "Summary",
  "schedule.guaranteedTuition.tabs.guaranteedTuition.empty": "There are no lessons to consider for the selected week. Note that the lessons must be scheduled, not just planned.",
  "schedule.guaranteedTuition.tabs.guaranteedTuition.no_selected_entity": "Select a group, teacher, or subject to get a summary of its total scheduled time. Please note that only courses that have a set subject are included in the calculations.",
  "schedule.guaranteedTuition.tabs.guaranteedTuition.tabLabel": "Guaranteed tuition",
  "schedule.guaranteedTuition.tabs.guaranteedTuition.table.per_day": "Per day",
  "schedule.guaranteedTuition.tabs.guaranteedTuition.table.total_count": "Number of lessons",
  "schedule.header": "Schedule",
  "schedule.navigation.automate": "Auto<br>schedule",
  "schedule.navigation.data": "Schedule<br>data",
  "schedule.navigation.dutyAssignment": "Duty<br>assignment",
  "schedule.navigation.edit": "Schedule<br>editor",
  "schedule.navigation.examine": "Examine<br>schedule",
  "schedule.navigation.guaranteedTuition": "Guaranteed<br>tuition",
  "schedule.navigation.lessonSettings": "Lesson<br>settings",
  "schedule.navigation.reports": "Reports/<wbr>Statistics",
  "schedule.navigation.scheduleDataAnalysis": "Data<br>analysis",
  "schedule.navigation.scheduleWarnings": "Schedule<br>warnings",
  "schedule.navigation.settings": "Schedule<br>settings",
  "schedule.navigation.substitution_planner": "Substitution<br>planner",
  "schedule.print.header": "Print",
  "schedule.print.page.toolbar.color.descr": "Print without color to save printer ink.",
  "schedule.print.page.toolbar.color.label": "Use colors",
  "schedule.print.page.toolbar.print.descr": "From the print dialog it is also possible to save the document as a PDF file.",
  "schedule.print.page.toolbar.print.label": "Print",
  "schedule.print.page.toolbar.QR.descr": "The link leads to the web viewer.",
  "schedule.print.page.toolbar.QR.label": "Include QR link",
  "schema-defects.category.critical.label": "Critical",
  "schema-defects.category.minor.label": "Minor",
  "schema-defects.category.significant.label": "Significant",
  "schema-defects.courseClustering": "Course clustering",
  "schema-defects.courseDurationMismatch": "Total event duration",
  "schema-defects.dailyOvertime": "Daily overtime",
  "schema-defects.doubleBooking": "Double booking",
  "schema-defects.eventDurationOverflow": "Event duration",
  "schema-defects.fixed.count.explanation": "There are {{ count }} schedule warning(s) of this category which arise entirely due to lessons being fixed.",
  "schema-defects.forcedOverlappingEventsDay": "Forced overlapping lessons",
  "schema-defects.insufficientBookingMargin": "Time margin",
  "schema-defects.insufficientBreakLength": "Break length",
  "schema-defects.missingDependency": "Missing room",
  "schema-defects.missingGroupsAndTeachers": "Missing teacher and group",
  "schema-defects.non-fixed.count.explanation": "There are {{ count }} schedule warnings of this category that arise for reasons other than the involved lessons being fixed.",
  "schema-defects.overflow": "Schedule frame",
  "schema-defects.overlap": "Collision",
  "schema-defects.overtime": "Overtime",
  "schema-defects.unavailableDay": "Forbidden day",
  "settings.index.copy": "API key copied",
  "settings.index.error": "Could not create API key",
  "settings.integrationsOut.form.title": "Create an API key",
  "settings.integrationsOut.menu": "API export",
  "settings.integrationsOut.page.descr": "Using the function below, you can create new API keys to make schedule data available to other service providers. Contact the other provider and check if they can import schedule data via the SS 12000 API standard.",
  "settings.integrationsOut.page.title": "Data export via API (SS 12000:2020)",
  "settings.integrationsOut.shared.description": "Description",
  "settings.integrationsOut.shared.displayName": "Name",
  "settings.integrationsOut.shared.integrationType": "Platform",
  "settings.integrationsOut.table.copyKey": "Copy key",
  "settings.integrationsOut.table.createdAt": "Created",
  "settings.integrationsOut.table.legend": "API-keys",
  "settings.integrationsOut.table.usedAt": "Last activity",
  "shared.components.schema-filter-dropdowns coalesced": "Add filter",
  "shared.components.schema-filter-dropdowns search course event": "Search for lesson",
  "shared.components.user-menu.to-parent-organization": "Back to {{ name }}",
  "shared.dialogs.add-duration-set.label": "Enter lesson duration(s)",
  "shared.dialogs.add-duration-set.title": "New duration set",
  "shared.dialogs.company.description": "Creates and adds a school to your organization.",
  "shared.dialogs.company.title": "Create a school",
  "shared.dialogs.confirm-action.title": "Are you sure?",
  "shared.dialogs.confirm-remove.confirm": "I understand",
  "shared.dialogs.confirm-remove.sub-title": "This action can't be reverted",
  "shared.dialogs.confirm-remove.title": "Confirm removal",
  "shared.dialogs.duplicate-schedule.title": "Duplicate schedule",
  "shared.dialogs.generate-schedule.action.submit": "Schedule",
  "shared.dialogs.generate-schedule.analysis.flawless.description": "Your schedule data has been analyzed and we found nothing to remark on. Well done!",
  "shared.dialogs.generate-schedule.analysis.flawless.title": "Ready",
  "shared.dialogs.generate-schedule.analysis.flaws.description": "Your schedule data has been analyzed and we found the following {{ flaws }} that may need to be resolved.",
  "shared.dialogs.generate-schedule.description": "This process generates a new schedule based on the schedule data and the current schedule will therefore be overwritten. If you want to save the schedule as it is now before proceeding you may duplicate it.",
  "shared.dialogs.generate-schedule.error": "Unable to process your request, please try again in a few minutes. If the problem persists, please contact the support team and we will gladly help you.",
  "shared.dialogs.generate-schedule.title": "Automatic scheduling",
  "shared.dialogs.login.description": "In order to renew your session you have to log in again. If you wish to cancel your session you simply close this dialog.",
  "shared.dialogs.login.title": "Session expired",
  "shared.dialogs.overlappableEventSets.description.forced": "The linked lessons will be scheduled at the same time and centered.",
  "shared.dialogs.overlappableEventSets.description.set": "The automatic scheduling algorithm strives to schedule lessons belonging to parallel courses at the same time. This is, however, not always possible or allowed. For example, it is not allowed for a course's lessons to be scheduled at the same time unless they are linked.",
  "shared.dialogs.overlappableEventSets.error.set": "There are associated lessons that are not linked.",
  "shared.dialogs.overlappableEventSets.forceOverlap": "Link lessons",
  "shared.dialogs.overlappableEventSets.gridView": "Grid view",
  "shared.dialogs.overlappableEventSets.listView": "List view",
  "shared.dialogs.overlappableEventSets.new.forced": "Drop a lesson here to form a new set of linked lessons",
  "shared.dialogs.overlappableEventSets.new.set": "Drop a course here to form a new set of parallel courses",
  "shared.dialogs.overlappableEventSets.noCourses": "No courses found",
  "shared.dialogs.overlappableEventSets.noEvents": "No lessons found",
  "shared.dialogs.overlappableEventSets.warning.forced": "Should include at least two lessons",
  "shared.dialogs.overlappableEventSets.warning.set": "Should include at least two courses",
  "shared.dialogs.sync.collections": "Select schedule data",
  "shared.dialogs.sync.error.0": "Could not authenticate with the selected platform with your credentials. If this is an error contact the support.",
  "shared.dialogs.sync.error.1": "Could not get the data from the selected platform. Something might be setup incorrectly",
  "shared.dialogs.sync.error.3": "Could not synchronize the schedule data. One or more students are connected to multiple classes. This is not allowed. Contact support with your error code for help.",
  "shared.dialogs.sync.error.4": "Could not synchronize the schedule data. One or more ids are not unique. Contact support with your error code for help.",
  "shared.dialogs.sync.last_sync": "Last sync",
  "shared.dialogs.sync.modified": "Only modified after",
  "shared.dialogs.sync.platform": "Select platform",
  "shared.dialogs.sync.synced_data": "Modified schedule data",
  "shared.dialogs.sync.time": "Select period",
  "shared.dialogs.sync.title": "Synchronize your schedule",
  "shared.dialogs.sync.validation": "Validate schedule data",
  "shared.dialogs.sync.validation.title": 'There are some problems with the schedule data. Please resolve them before continuing.',
  "shared.dialogs.sync.validation.indistinguishable.courses": "with name \"{{name}}\" share the same name, teachers and groups.",
  "shared.dialogs.sync.validation.indistinguishable.events": "belonging to the course \"{{name}}\" share the same teachers and groups and time.",
  "shared.dialogs.sync.validation.ids_missing": "are missing names",
  "shared.dialogs.sync.validation.ids_not_unique": "have identical names: \"{{ids}}\"",
  "shared.dialogs.sync.validation.valid": "The data is valid",
  "shared.dialogs.sync.push_success.title": "Synchronization successful",
  "shared.dialogs.sync.push_success.description": "The schedule data has been successfully synchronized with the selected platform. Go to the platform to see the changes.",
  "shared.dialogs.unrecoverable-app-state.description": "The application has entered an unrecoverable state. Please reload the page to continue.",
  "shared.dialogs.unrecoverable-app-state.title": "Unrecoverable state",
  "shared.dialogs.unsaved-changes.description": "There are unsaved changed present. Do you want to save or discard them?",
  "shared.dialogs.unsaved-changes.title": "Unsaved changes",
  "shared.dialogs.upload-file.configure.format_specific": "Format specific settings",
  "shared.dialogs.upload-file.configure.label": "Configure",
  "shared.dialogs.upload-file.configure.PlanDigital.displayName.label": "Use as course name",
  "shared.dialogs.upload-file.configure.PlanDigital.periods.label": "Select periods to import",
  "shared.dialogs.upload-file.configure.PlanDigital.periods.placeholder": "At least one period must be selected",
  "shared.dialogs.upload-file.configure.SchoolSoft.interval.descr": "Exclude lessons that are not scheduled during the selected period ({{start}} - {{end}}).",
  "shared.dialogs.upload-file.configure.SchoolSoft.interval.label": "Filter courses based on the selected period",
  "shared.dialogs.upload-file.configure.Skola24.deduceGroupsFromCourseName.descr": "Try to identify and add groups that are found in the course name if they are separated by a dash, such as 10FYS01a-<b>NA20E</b>-LABb or 10-AH-<b>EK21C</b>.",
  "shared.dialogs.upload-file.configure.Skola24.deduceGroupsFromCourseName.label": "Deduce groups from the course name",
  "shared.dialogs.upload-file.configure.Skola24.ignoreEmptyCourses.label": "Ignore courses without lessons",
  "shared.dialogs.upload-file.configure.Skola24.removeUnreferencedGroups.descr": "Groups that are not found in at least one course or lesson will be ignored.",
  "shared.dialogs.upload-file.configure.Skola24.removeUnreferencedGroups.label": "Ignore unused groups",
  "shared.dialogs.upload-file.configure.Skola24.removeUnreferencedTeachers.descr": "Teachers that are not found in at least one course or lesson will be ignored.",
  "shared.dialogs.upload-file.configure.Skola24.removeUnreferencedTeachers.label": "Ignore unused teachers",
  "shared.dialogs.upload-file.configure.Skola24.week.ignoreAllEvents": "Ignore all lessons",
  "shared.dialogs.upload-file.configure.Skola24.week.label": "Import lessons from selected week",
  "shared.dialogs.upload-file.finalize.content": "Schedule content",
  "shared.dialogs.upload-file.finalize.error": "Unable to process the selected file",
  "shared.dialogs.upload-file.finalize.errors_and_warnings": "Encountered errors and warnings",
  "shared.dialogs.upload-file.finalize.label": "Finalize",
  "shared.dialogs.upload-file.label": "Upload file",
  "shared.dialogs.upload-file.select-file.download_template": "Download Excel template (optional)",
  "shared.dialogs.upload-file.select-file.label": "Select file",
  "shared.dialogs.upload-file.select-map.determined": "Exact match",
  "shared.dialogs.upload-file.select-map.error": "Unable to identify the format of the file",
  "shared.dialogs.upload-file.select-map.label": "Select format",
  "shared.dialogs.upload-file.select-map.strong": "Probable format(s)",
  "shared.dialogs.upload-file.select-map.unknown": "Format unknown",
  "shared.dialogs.upload-file.select-map.weak": "Similar format(s)",
  "shared.dialogs.users.description": "Type an email address and select a school in order to create a login to the selected school.",
  "shared.dialogs.users.title": "Create user",
  "shared.dialogs.version.current_version": "Current version",
  "shared.dialogs.version.description": "A new version of the application is available. Please reload the page to get the latest version.",
  "shared.dialogs.version.description_hard_reload": "Sometimes a regular reload is not enough and a manual hard reload is required. Typically this is done by pressing <kbd>Ctrl</kbd> + <kbd>Shift</kbd> + <kbd>R</kbd> on Windows or <kbd>Cmd</kbd> + <kbd>Shift</kbd> + <kbd>R</kbd> on Mac but probably depends on your browser.",
  "shared.dialogs.version.latest_version": "Latest version",
  "shared.dialogs.version.title": "New version available",
  "shared.form-fields.available-locations.empty-repository": "No available rooms",
  "shared.form-fields.available-locations.groups": "Room group",
  "shared.form-fields.available-locations.locations": "Rooms",
  "shared.form-fields.available-locations.select-null": "No room",
  "shared.form-fields.available-locations.selected-null": "No room",
  "shared.form-fields.components.schema-filter-dropdowns search course event": "Search for lesson",
  "shared.form-fields.dynamic-locked-times.duration.tooltip": "The preferred duration and the maximal duration variation of the lunch break. The scheduling algorithm will select a suitable duration within the specified range but keep the combined duration of all lunch breaks equal to their summed preferred duration.",
  "shared.form-fields.fixed-start.disabled": "None",
  "shared.form-fields.fixed-start.enabled": "Fixed start time",
  "shared.form-fields.groups.selection-warning": "one or more students from this group are separately selected",
  "shared.form-fields.periods.no-periods": "There are no periods to select from. Please add periods to the schedule.",
  "shared.form-fields.root-intervals.no-root-intervals": "There are no blocks to select from. Please add blocks to the schedule.",
  "shared.forms.change-password-form.current_password": "Current password",
  "shared.forms.change-password-form.new_password": "New password",
  "shared.forms.change-password-form.repeat_password": "Repeat password",
  "shared.forms.company.name": "Name",
  "shared.forms.company.organization": "Parent organization",
  "shared.forms.company.organizationType": "Organization type",
  "shared.forms.company.schoolCode": "Code",
  "shared.forms.company.theme": "Theme",
  "shared.forms.users.company": "Select school",
  "shared.forms.users.username": "Email",
  "source.set_fail": "Could not update the value",
  "source.set_success": "Update succeeded",
  "source.set_timeout": "Update timed out",
  "source.validation_fail": "Invalid update",
  "substitutions.are_parallel_courses": "are parallel courses",
  "substitutions.is_a_parallel_course": "is a parallel course",
  "table.itemsPerPageLabel": "Rows per page",
  "table.nextPageLabel": "Next page",
  "table.previousPageLabel": "Previous page",
  "tables.admin-tables.divisions.actions.columns.displayName": "Name",
  "tables.admin-tables.divisions.actions.columns.id": "ID",
  "tables.admin-tables.divisions.actions.columns.organization": "Organization",
  "tables.admin-tables.divisions.actions.delete": "Delete schedule",
  "tables.admin-tables.divisions.actions.delete.failure": "Failed to delete schedule",
  "tables.admin-tables.divisions.actions.download": "Download schedule",
  "tables.admin-tables.divisions.actions.login": "Log in to schedule",
  "tables.admin-tables.divisions.actions.login.failure": "Login failed",
  "tables.admin-tables.organizations.actions.add": "Create organization",
  "tables.admin-tables.organizations.actions.configure_environment": "Configure compute environment",
  "tables.admin-tables.organizations.actions.configure_identifiers": "Configure identifiers",
  "tables.admin-tables.organizations.actions.delete": "Delete organization",
  "tables.admin-tables.organizations.actions.delete.failure": "Failed to delete organization",
  "tables.admin-tables.organizations.actions.edit": "Edit organization",
  "tables.admin-tables.organizations.actions.generate_access_key": "Generate access key",
  "tables.admin-tables.organizations.actions.login": "Log in to organization",
  "tables.admin-tables.organizations.actions.login.failure": "Failed to log in",
  "tables.admin-tables.organizations.add-edit.actions.add.failure": "Failed to create organization",
  "tables.admin-tables.organizations.add-edit.labels.name": "Name",
  "tables.admin-tables.organizations.add-edit.labels.organization": "Parent organization",
  "tables.admin-tables.organizations.add-edit.labels.organization_type": "Organization type",
  "tables.admin-tables.organizations.add-edit.labels.school_code": "School unit code",
  "tables.admin-tables.organizations.add-edit.labels.theme": "Theme",
  "tables.admin-tables.organizations.add-edit.title.add": "Create organization",
  "tables.admin-tables.organizations.add-edit.title.edit": "Edit organization",
  "tables.admin-tables.organizations.columns.divisions": "No. schedules",
  "tables.admin-tables.organizations.columns.environment": "Environment",
  "tables.admin-tables.organizations.columns.identifiers": "No. identifiers",
  "tables.admin-tables.organizations.columns.name": "Name",
  "tables.admin-tables.organizations.columns.organization": "Parent organization",
  "tables.admin-tables.organizations.columns.organizationType": "Organization type",
  "tables.admin-tables.organizations.columns.theme": "Theme",
  "tables.admin-tables.organizations.columns.version": "Algorithm version",
  "tables.admin-tables.organizations.configure-compute-environment.title": "Configure compute environment",
  "tables.admin-tables.organizations.configure-identifiers.actions.add.failure": "Failed to add identifier",
  "tables.admin-tables.organizations.configure-identifiers.actions.delete.failure": "Failed to delete identifier",
  "tables.admin-tables.organizations.configure-identifiers.identifier-types.school_code": "School unit code",
  "tables.admin-tables.organizations.configure-identifiers.identifier-types.vat": "VAT number",
  "tables.admin-tables.organizations.configure-identifiers.title": "Configure identifiers",
  "tables.admin-tables.organizations.app_features.substitution_planner": "Activate substitution planner",
  "tables.admin-tables.organizations.app_features.title": "Handle app features",
  "tables.admin-tables.users.actions.add": "Create user",
  "tables.admin-tables.users.actions.copy_verification_link": "Copy activation link",
  "tables.admin-tables.users.actions.delete": "Delete user",
  "tables.admin-tables.users.actions.delete.failure": "Failed to delete user",
  "tables.admin-tables.users.actions.edit": "Edit user",
  "tables.admin-tables.users.actions.login": "Log in to user's organization",
  "tables.admin-tables.users.actions.login.failure": "Login failed",
  "tables.admin-tables.users.actions.mail": "Send activation email to user",
  "tables.admin-tables.users.actions.mail.failure": "Failed to send activation email",
  "tables.admin-tables.users.actions.mail.success": "Activation email sent",
  "tables.admin-tables.users.add-edit.actions.add.failure": "Failed to create user",
  "tables.admin-tables.users.add-edit.actions.fetch_companies.failure": "Failed to fetch companies",
  "tables.admin-tables.users.add-edit.actions.fetch_from_skolverket.failure": "Failed to fetch information from Skolverket",
  "tables.admin-tables.users.add-edit.labels.organization": "Organization",
  "tables.admin-tables.users.add-edit.labels.role": "User role",
  "tables.admin-tables.users.add-edit.labels.username": "Username (email)",
  "tables.admin-tables.users.add-edit.title.add": "Create user",
  "tables.admin-tables.users.add-edit.title.edit": "Edit user",
  "tables.admin-tables.users.columns.activation": "Activation link",
  "tables.admin-tables.users.columns.last_login": "Last login",
  "tables.admin-tables.users.columns.online": "Online",
  "tables.admin-tables.users.columns.organization": "Organization",
  "tables.admin-tables.users.columns.role": "User role",
  "tables.admin-tables.users.columns.username": "Username",
  "tables.components.custom-search.empty": "Note that disabling search for all columns will result in empty search results.",
  "tables.components.empty.hint": "Create one by clicking the \"+\" button in the upper right corner",
  "tables.components.empty.title": "There exist no {{ entities }} yet",
  "tables.components.no-columns.hint": "Click the \"{{ button }}\" button in the upper right corner to select which columns to display",
  "tables.components.no-columns.title": "No visible table columns",
  "tables.courses.values.overridden": "The value is not inherited by all of the course's lessons",
  "tables.notifications.alerts.error.title": "Unable to update preferences",
  "tables.notifications.alerts.success.descr": "If other tabs are open, they need to be reloaded for the changes to take place.",
  "tables.notifications.alerts.success.title": "Preferences successfully updated",
  "tables.notifications.columns.category.descr": "Notifications within each category are shown together.",
  "tables.notifications.columns.category.name": "Category",
  "tables.notifications.columns.category.values.generalConfirmation": "General confirmations",
  "tables.notifications.columns.category.values.generalError": "General errors",
  "tables.notifications.columns.category.values.inputAnalysisError": "Input analyzer: critical errors",
  "tables.notifications.columns.category.values.inputAnalysisNotice": "Input analyzer: notices",
  "tables.notifications.columns.category.values.inputAnalysisWarning": "Input analyzer: warnings",
  "tables.notifications.columns.category.values.other": "Other",
  "tables.notifications.columns.location.descr": "Where on the screen the notifications of this category will appear.",
  "tables.notifications.columns.location.name": "Location",
  "tables.notifications.columns.location.values.bottom left": "Bottom left corner",
  "tables.notifications.columns.location.values.bottom right": "Bottom right corner",
  "tables.notifications.columns.location.values.top left": "Top left corner",
  "tables.notifications.columns.location.values.top right": "Top right corner",
  "tables.notifications.columns.mode.descr": "Whether these notifications are turned on, muted, or turned off.",
  "tables.notifications.columns.mode.name": "Mode",
  "tables.notifications.columns.mode.values.mute": "Mute",
  "tables.notifications.columns.mode.values.off": "Off",
  "tables.notifications.columns.mode.values.on": "On",
  "tables.notifications.columns.sound.descr": "What sound will play when a notification is received.",
  "tables.notifications.columns.sound.name": "Sound",
  "tables.notifications.columns.test.descr": "Try out by sending a dummy notification.",
  "tables.notifications.columns.test.name": "Test",
  "tables.notifications.columns.test.value": "send",
  "tables.notifications.columns.volume.descr": "The notification'a sound volume.",
  "tables.notifications.columns.volume.name": "Volume",
  "validators.email": "Not a valid email",
  "validators.equal": "Values don't match",
  "validators.invalidDate": "Invalid date",
  "validators.invalidDiscretization": "Must be a multiple of 5 minutes",
  "validators.invalidLaterDate": "Must be a later date",
  "validators.invalidMultiple": "Must be a multiple of 5",
  "validators.invalidTime": "Invalid time (5 min intervals)",
  "validators.matDatepickerMax": "Must select an earlier date",
  "validators.matDatepickerMin": "Must select a later date",
  "validators.matEndDateInvalid": "Invalid date range",
  "validators.matStartDateInvalid": "Invalid date range",
  "validators.max": "This value is too high",
  "validators.min": "This value is too low",
  "validators.minLength": "Too short",
  "validators.minlength": "Too short",
  "validators.noMatch": "Wrong email or password",
  "validators.outOfBounds": "Value is out of bounds",
  "validators.required": "This field is required",
  "validators.time_too_early": "Time too early",
  "validators.time_too_late": "Time too late",
  "validators.usernameOccupied": "Email is occupied",
  "view.administrator.organizations.table.add": "Create school",
  "view.administrator.organizations.table.createdAt": "Created at",
  "view.administrator.organizations.table.header": "Schools",
  "view.administrator.organizations.table.login": "Enter this school",
  "view.administrator.organizations.table.name": "Name",
  "view.administrator.page.menu.organizations": "My schools",
  "view.administrator.users.table.add": "Create user",
  "view.administrator.users.table.administrator": "Administrator",
  "view.administrator.users.table.company": "School/Organization",
  "view.administrator.users.table.createdAt": "Created at",
  "view.administrator.users.table.header": "User accounts",
  "view.administrator.users.table.login": "Login to this school",
  "view.administrator.users.table.mail.fail": "Activation link could not be sent to the user",
  "view.administrator.users.table.mail.success": "Activation link sent to user",
  "view.administrator.users.table.mail.tooltip": "Send activation link to user",
  "view.administrator.users.table.restricted": "Restricted",
  "view.administrator.users.table.role": "User role",
  "view.administrator.users.table.unrestricted": "Scheduler",
  "view.administrator.users.table.username": "Email",
  "view.demo.schedule.components.editor_contact_sub_title": "To learn more about our product, fill in your email and we will contact you with more information.",
  "view.demo.schedule.components.editor_contact_title": "The schedule is finished",
  "view.demo.schedule.components.introduction_contact_submit": "I'm interested",
  "view.demo.schedule.components.introduction_slide_1": "Welcome!",
  "view.demo.schedule.components.introduction_slide_2": "We have pre-populated the schedule data for a medium-sized Swedish school.",
  "view.demo.schedule.components.introduction_slide_3": "The only thing remaining is to click \"start automatic scheduling\", wait for a couple of minutes and you have created your schedule.",
  "view.demo.schedule.page.exit": "Exit demo",
  "view.demo.schedule.page.restart": "Restart demo",
  "view.partner.page.menu.divisions": "Schedules",
  "view.partner.page.menu.organizations": "Organiza&shy;tions",
  "view.partner.page.menu.users": "Users",
  "view.private.integrations.in.connectionTo": "Connection to",
  "view.private.integrations.in.descr": "Below you will find possible API integrations for exchanging information with external service providers.",
  "view.private.integrations.in.error.get": "Schedule data could not be fetched. Please contact the support team if the problem persists.",
  "view.private.integrations.in.error.postOrPatch": "The integration details could not be saved. Please contact the support team if the problem persists.",
  "view.private.integrations.in.import": "Import data",
  "view.private.integrations.in.menu": "Integrations",
  "view.private.integrations.in.no_integrations": "No integrations",
  "view.private.integrations.in.SchoolSoftDialog.tab1.title": "Name and period",
  "view.private.integrations.in.SchoolSoftDialog.tab2.descr": "It is currently not possible to import rooms directly via SchoolSoft's API. However, until this is resolved they can be imported by copy-pasting the corresponding data in a tab- or comma-separated format, which you may find <a href=\"https://sms.schoolsoft.se/{{webapp}}/jsp/admin/right_admin_import_room.jsp?menu=admin_import_room\" target=\"_blank\">here</a>.",
  "view.private.integrations.in.SchoolSoftDialog.tab2.error": "Unable to parse the text",
  "view.private.integrations.in.SchoolSoftDialog.tab2.label": "Paste the table text here",
  "view.private.integrations.in.SchoolSoftDialog.tab2.title": "Import rooms",
  "view.private.integrations.in.setupDialog.key": "API key",
  "view.private.integrations.in.setupDialog.meta.schoolapp": "schoolapp",
  "view.private.integrations.in.setupDialog.meta.schoolId": "School ID",
  "view.private.integrations.in.setupDialog.meta.webapp": "webapp",
  "view.private.integrations.in.setupDialog.startsWith": "Starts with {{value}}",
  "view.private.integrations.in.setupDialog.title": "Integration details",
  "view.private.integrations.in.success": "The schedule has successfully been imported",
  "view.private.integrations.in.title": "Integrations via API",
  "view.private.integrations.in.tooltip.delete": "Delete integration details",
  "view.private.integrations.in.tooltip.modify": "Modify integration details",
  "view.private.integrations.in.validation.error.401": "Invalid integration details",
  "view.private.integrations.in.validation.error.404": "Invalid integration details",
  "view.private.integrations.in.validation.error.500": "The integration details could not be validated",
  "view.private.integrations.in.validation.error.SchoolSoft.key": "Invalid API key",
  "view.private.integrations.in.validation.error.SchoolSoft.schoolapp": "Invalid schoolapp value",
  "view.private.integrations.in.validation.error.SchoolSoft.webapp": "Invalid webapp value",
  "view.private.integrations.in.validation.error.unknown": "The integration details could not be validated",
  "view.private.integrations.in.validation.valid": "Valid integration details",
  "view.private.integrations.in.validation.validating": "Validating integration details",
  "view.private.menu.schema": "Schedules",
  "view.private.menu.settings": "Settings",
  "view.private.menu.tutorial": "Tutorial",
  "view.private.menu.users": "Users",
  "view.private.schema.components.admentum.subtitle": "Export to Admentum",
  "view.private.schema.components.failed-generation-dialog.description": "An error occurred during the automatic scheduling process and we are working on a solution. Please try again later during the day or contact the support if you want to be notified when the problem is resolved.",
  "view.private.schema.components.failed-generation-dialog.title": "The automatic scheduling failed",
  "view.private.schema.components.subtitle": "Export to",
  "view.private.schema.components.idunsoft.subtitle": "Export to IdunSoft",
  "view.private.schema.components.infomentor.subtitle": "Export to InfoMentor",
  "view.private.schema.components.link.subtitle": "Copy the link to this schedule",
  "view.private.schema.components.link.success": "Link copied",
  "view.private.schema.components.link.title": "Web-viewer",
  "view.private.schema.components.royalschedule.subtitle": "Export to Royal Schedule",
  "view.private.schema.components.schoolsoft_displayName.subtitle": "Export to SchoolSoft using names as references",
  "view.private.schema.components.schoolsoft.subtitle": "Export to SchoolSoft",
  "view.private.schema.components.table.actions": "Actions",
  "view.private.schema.components.table.basis": "Schedule data",
  "view.private.schema.components.table.clone": "Duplicate schedule",
  "view.private.schema.components.table.createdAt": "Created",
  "view.private.schema.components.table.editedAt": "Last edited",
  "view.private.schema.components.table.header": "My schedules",
  "view.private.schema.components.table.interval": "Duration",
  "view.private.schema.components.table.link": "link to web viewer",
  "view.private.schema.components.table.name": "Schedule name",
  "view.private.schema.components.table.public": "Public",
  "view.private.schema.edit.toolbar.colorDescription": "Color description",
  "view.private.schema.edit.toolbar.selectedPeriods": "Included periods",
  "view.private.schema.editor.components.user-manual.title": "User manual",
  "view.private.schema.page.create": "Create schedule",
  "view.private.schema.page.download_fail": "Could not download schedule",
  "view.private.schema.page.header": "Schedules",
  "view.private.schema.page.sync": "Sync data",
  "view.private.schema.page.upload": "Upload file",
  "view.private.schema.sub-pages.schema-editor.components.event-form.fix-start": "Pin the day and start time",
  "view.private.schema.sub-pages.schema-editor.components.event-form.force-overlap": "Link lessons",
  "view.private.schema.sub-pages.schema-editor.components.event-form.make-invisible": "Hide in schedule viewer",
  "view.private.schema.sub-pages.schema-editor.components.event-form.make-visible": "Show in schedule viewer",
  "view.private.schema.sub-pages.schema-editor.components.event-form.park": "Park",
  "view.private.schema.sub-pages.schema-editor.components.event-form.remove-force-overlap": "Unlink lessons",
  "view.private.schema.sub-pages.schema-editor.components.event-form.unfix-start": "Unpin day and start time",
  "view.private.schema.sub-pages.schema-editor.components.list.filter": "Filter lessons",
  "view.private.schema.sub-pages.schema-editor.components.list.filter.not_placed": "Parked lessons",
  "view.private.schema.sub-pages.schema-editor.components.list.filter.period": "Included periods only",
  "view.private.schema.sub-pages.schema-editor.components.list.filter.placed": "Placed lessons",
  "view.private.schema.sub-pages.schema-editor.components.progress.info_PENDING": "The scheduling process has been placed in queue. It will start in a couple of minutes",
  "view.private.schema.sub-pages.schema-editor.components.progress.info_STARTED": "The scheduling process has started. It may take a couple of minutes before it is completed.",
  "view.private.schema.sub-pages.schema-editor.page.limited_state": "Collision analysis is disabled",
  "view.private.schema.sub-pages.schema-editor.page.start_job": "Start automatic scheduling",
  "view.private.schema.sub-pages.settings.components.default-values-form.fieldset.break-lengths": "Minimum break length",
  "view.private.schema.sub-pages.settings.components.default-values-form.fieldset.other": "Other",
  "view.private.schema.sub-pages.settings.components.default-values-form.fieldset.time-frame": "Time frame",
  "view.private.schema.sub-pages.settings.components.default-values-form.fieldset.working-hours": "Working hours",
  "view.private.schema.sub-pages.settings.components.options-form.fieldset.overlappable-lessons-set": "Linked lessons",
  "view.private.schema.sub-pages.settings.components.options-form.requireForcedOverlappingEventsSets.description": "If enabled, lessons belonging to parallel courses must be linked. Lessons that are linked are guaranteed to be scheduled at the same time after a schedule has been generated. Lessons belonging to parallel courses that <b>are not</b> linked are usually allowed to be scheduled at the same time, but this outcome is not guaranteed after a schedule has been generated.",
  "view.private.schema.sub-pages.settings.components.options-form.requireForcedOverlappingEventsSets.label": "Require linked lessons",
  "view.private.schema.sub-pages.settings.page.tab.default-values": "Default values",
  "view.private.schema.sub-pages.settings.page.tab.options": "Options",
  "view.private.schema.sub-pages.settings.page.tab.periods": "Periods",
  "view.private.schema.sub-pages.settings.page.tab.rootIntervals": "Blocks",
  "view.private.schema.sub-pages.statistics.duty-assignments.empty": "No data available",
  "view.private.schema.sub-pages.statistics.duty-assignments.header": "Duty assignments",
  "view.private.schema.sub-pages.statistics.duty-assignments.min_of_min_per_week": "{{current}} of {{total}}  min/week",
  "view.private.schema.sub-pages.statistics.duty-assignments.min_per_week": "Min/week",
  "view.private.schema.sub-pages.tooltips.locked": "While a schedule is being automatically generated, it is locked for modification.",
  "view.private.settings.language.label": "Language",
  "view.private.settings.language.title": "Language",
  "view.private.settings.menu.sync": "Sync",
  "view.private.settings.sync.button.select-schedule": "Select schedule",
  "view.private.settings.sync.components.table.sync_toggle": "Sync on",
  "view.private.settings.sync.descr": "Using the functions below, you can enable and disable external platforms to sync your data. If the sync is enabled, your data will be sent to the platform(s) you have allowed. This can be turned off at any time.",
  "view.private.settings.sync.in": "Schedules that fetch updates from external platforms",
  "view.private.settings.sync.no-selected-out": "Select a schedule for your external platforms to access.",
  "view.private.settings.sync.out": "Active schedules",
  "view.private.settings.sync.tab.add-platforms": "Add a platform",
  "view.private.settings.sync.tab.my-platforms": "My platforms",
  "view.private.settings.sync.title": "Platform integrations",
  "view.private.settings.sync.tooltip.REJECTED": "The request to create a sync between our platforms was rejected. Contact the support team if this is an error.",
  "view.private.tutorial.page.advanced": "Advanced",
  "view.private.tutorial.page.basic": "Basics",
  "view.private.tutorial.page.description.2.1": "Teacher constraints",
  "view.private.tutorial.page.description.2.2": "Create lessons and length variation",
  "view.private.tutorial.page.description.2.3": "Add allowed rooms",
  "view.private.tutorial.page.description.2.4": "Parallel courses and linked lessons",
  "view.private.tutorial.page.description.2.5": "Analysis of schedule data",
  "view.private.tutorial.page.description.2.6": "Iterative adjustments",
  "view.private.tutorial.page.description.api": "Synchronize data",
  "view.private.tutorial.page.description.bulk-update": "Learn how to edit in bulk",
  "view.private.tutorial.page.description.courses": "Learn how to add courses and events",
  "view.private.tutorial.page.description.create": "Create schedule",
  "view.private.tutorial.page.description.excel": "Upload an Excel file",
  "view.private.tutorial.page.description.groups": "Learn how to add groups",
  "view.private.tutorial.page.description.in-locations": "Learn how to modify allowed rooms",
  "view.private.tutorial.page.description.locations": "Learn how to add rooms",
  "view.private.tutorial.page.description.locked-times": "Learn how to add prohibited time intervals",
  "view.private.tutorial.page.description.teacher": "Learn how to add teachers",
  "view.private.tutorial.page.description.time-frame": "Manage the schedules' time frame",
  "view.private.tutorial.page.description.upload_json": "Upload RS file",
  "view.private.tutorial.page.filter.category": "Category",
  "view.private.tutorial.page.filter.category.adjustments": "Adjustments",
  "view.private.tutorial.page.filter.category.conditions": "Conditions",
  "view.private.tutorial.page.filter.category.data": "Schedule data",
  "view.private.tutorial.page.filter.category.edit": "Manual scheduling",
  "view.private.tutorial.page.filter.category.education": "Education",
  "view.private.tutorial.page.filter.category.export": "Export schedule data",
  "view.private.tutorial.page.filter.category.import": "Import schedule data",
  "view.private.tutorial.page.filter.category.inputAnalyzer": "Input analyzer",
  "view.private.tutorial.page.filter.category.other": "Other",
  "view.private.tutorial.page.filter.category.settings": "Settings",
  "view.private.tutorial.page.filter.category.warnings": "Warnings",
  "view.private.tutorial.page.filter.level": "Difficulty",
  "view.private.tutorial.page.filter.level.advanced": "Advanced",
  "view.private.tutorial.page.filter.level.beginner": "Beginner",
  "view.private.tutorial.page.filter.level.intermediate": "Intermediate",
  "view.private.tutorial.page.header": "Tutorial",
  "view.private.tutorial.page.type.video": "Video guide",
  "view.public.activation.approved": "Approved",
  "view.public.activation.expired": "Link expired",
  "view.public.activation.invalid": "Invalid link",
  "view.public.activation.verifying": "Verifying...",
  "view.public.demo.valid_config": "Configuring environment",
  "view.public.login.fail": "Failed to log in",
  "view.public.login.password": "Password",
  "view.public.login.status_email_required": "Valid email required",
  "view.public.login.status_invalid_credentials": "Invalid credentials",
  "view.public.login.status_invalid_email_format": "Invalid email format",
  "view.public.login.status_password_required": "Password required",
  "view.public.login.status_reset_password_fail": "Unable to send the reset link",
  "view.public.login.status_reset_password_success": "Reset link sent to {{email}} if such a user exists",
  "view.public.login.username": "Email",
  "view.public.web-viewer.not_public": "The schedule isn't public",
  "view.void.status": "Validating credentials",
  "account.advanced.menu": "Advanced",
  "account.advanced.page.title": "Advanced",
  "account.advanced.page.descr": "More advanced settings and actions which may sometimes be useful.",
  "account.advanced.page.clear-all-and-reload.label": "Clear all data and reload",
  "account.advanced.page.clear-all-and-reload.description": "This will clear all application data, terminate all workers and finally reload all opened tabs of the application simultaneously. This may be useful if you are experiencing problems with the application. Note that this will terminate your authentication session and you will have to log in again.",
  "account.advanced.page.clear-all-and-reload.action": "Clear and reload",
  "view.private.schema.sub-pages.examine.page.before_select": "Select the groups, teachers and rooms you want to compare. You may further chose what day and possibly what week to show.",
  "common.show_remaining": "Show remaining",
  "common.from_time": "From",
  "common.to_time": "To",
  "shared.dialogs.edit-course-and-event.title": "Edit",
  "common.parallel_courses": "Parallel courses",
  "common.open_tutorial": "Open tutorial",
  "common.second": "Second",
  "common.second_short": "s",
  "shared.components.svg-schedule.generating.title": "Generating printable schedules",
  "shared.components.svg-schedule.generating.completed_n_of_m": "{{n}} of {{m}} completed",
  "shared.components.svg-schedule.generating.estimated_time_remaining": "Estimated time remaining: {{time}}",
  "schedule.print.page.toolbar.print_date.label": "Print date",
  "schedule.print.page.toolbar.lockedTimes.label": "Prohibited times",
  "schedule.print.page.toolbar.print_date.descr": "The current date and time.",
  "schedule.print.page.toolbar.lockedTimes.descr": "Whether or not to include prohibited times.",
  "schedule.print.page.page.before_select": "Select the groups, teachers and rooms whose schedule you want to print.",
  "schedule.print.page.toolbar.time_frame.label": "Time frame",
  "schedule.print.page.toolbar.time_frame.descr": "Draws the time frame and allowed days.",
  "schedule.print.page.toolbar.fit_text.label": "Fit text",
  "schedule.print.page.toolbar.fit_text.descr": "Tries to fit the text by reducing the font size.",
  "inputAnalysis.issues.overloaded group aborted.title": "Unable to decide if {{the-entity}} is overloaded with lessons.",
  "inputAnalysis.issues.overloaded group aborted.description": "The computation was aborted as it took too long to complete.",
  "common.date_range": "Date range",
  "inputAnalysis.notices.overflowing_holiday.title": "{{The-entity}} partially lays outside the schedule's period.",
  "inputAnalysis.notices.overflowing_holiday.description": "Did you forget to update the holiday after changing the schedule's period?",
  "common.the_holiday": "the holiday",
  "common.the_holidays": "the holidays",
  "common.holiday": "holiday",
  "common.holidays": "holidays",
  "view.private.schema.sub-pages.settings.page.tab.holidays": "Holidays",
  "view.private.schema.sub-pages.settings.page.tab.holidays.dialog.add.title": "Add holiday",
  "view.private.schema.sub-pages.settings.page.tab.holidays.dialog.edit.title": "Edit holiday",
  "inputAnalysis.etc.modify_holiday": "Modify holiday",
  "common.printed": "Printed",
  "schedule.print.page.toolbar.display_dates.label": "Display dates",
  "schedule.print.page.toolbar.display_dates.descr": "A specific week must be selected.",
  "common.delete_all": "Delete all",
  "schedule.navigation.daysCount": "Days count",
  "common.whole": "Whole",
  "view.private.schema.sub-pages.statistics.days-count.caption": "The table shows how many times a day is present in a period and also the period's total number of days. The period \"{{ whole }}\" refers to the period defined by the schedule's start and end date.",
  "dialogs.create-courses.using-excel.label": "Excel",
  "dialogs.create-courses.components.excel.instructions": "First download the Excel template we have prepared with your existing schedule data (except courses). Then, in its course tab, enter the courses you want to create. When you are satisfied, save the file and upload it again here to get an overview of the courses that will be created as well as a list of errors encountered.",
  "shared.services.excel-template.errors.invalid_file_type": "Invalid file type. We only support files of types {{ types }}.",
  "shared.services.excel-template.errors.unable_to_parse": "Unable to parse the file. Please contact the support team.",
  "shared.services.excel-template.errors.incorrect_mapping": "Incorrect mapping type. Please contact the support team.",
  "shared.services.excel-template.errors.unable_to_map": "Mapping of schedule data failed. Please contact the support team.",
  "maps.excel.messages.missing_settings_sheet": "Could not find the settings sheet in the Excel file.",
  "maps.excel.messages.missing_locations_sheet": "Could not find the rooms sheet in the Excel file.",
  "maps.excel.messages.missing_groups_sheet": "Could not find the groups sheet in the Excel file.",
  "maps.excel.messages.missing_teachers_sheet": "Could not find the teachers sheet in the Excel file.",
  "maps.excel.messages.missing_persons_sheet": "Could not find the students sheet in the Excel file.",
  "maps.excel.messages.missing_courses_sheet": "Could not find the courses sheet in the Excel file.",
  "maps.excel.messages.ignoring_duplicate_location": "Two or more rooms with the name \"{{ context }}\" found. Ignoring all but the first.",
  "maps.excel.messages.ignoring_duplicate_group": "Two or more groups with the name \"{{ context }}\" found. Ignoring all but the first.",
  "maps.excel.messages.ignoring_duplicate_teacher": "Two or more teachers with the name \"{{ context }}\" found. Ignoring all but the first.",
  "maps.excel.messages.ignoring_duplicate_person": "Two or more students with the name \"{{ context }}\" found. Ignoring all but the first.",
  "maps.excel.messages.ignoring_duplicate_course": "Two or more courses with the name \"{{ context }}\" found. Ignoring all but the first.",
  "maps.excel.messages.invalid_group_reference": "Could not find the group with the name \"{{ context }}\".",
  "maps.excel.messages.invalid_location_reference": "Could not find the room with the name \"{{ context }}\".",
  "maps.excel.messages.invalid_teacher_reference": "Could not find the teacher with the name \"{{ context }}\".",
  "maps.excel.messages.location_referenced_multiple_times": "The room \"{{ context }}\" is referenced multiple times from the same course",
  "maps.excel.messages.teacher_referenced_multiple_times": "The teacher \"{{ context }}\" is referenced multiple times from the same course",
  "maps.excel.messages.group_referenced_multiple_times": "The group \"{{ context }}\" is referenced multiple times from the same course",
  "maps.excel.messages.invalid_number_of_days": "Invalid value \"{{ context }}\" specified for the schedule's number of days. The value must be 5 (Monday-Friday) or 7 (Monday-Sunday).",
  "maps.excel.messages.invalid_sex": "Invalid value \"{{ context }}\" specified as gender. The value must be \"Man\" or \"Woman\".",
  "maps.excel.messages.invalid_total_hours": "Invalid value \"{{ context }}\" specified as the total number of hours. The value must be a positive integer.",
  "maps.excel.messages.invalid_minutes_per_week": "Invalid value \"{{ context }}\" specified as the number of minutes per week. The value must be a positive multiple of 5.",
  "maps.excel.messages.invalid_lesson_durations": "Invalid value \"{{ context }}\" specified as lesson durations. The value must be one or more comma-separated numbers, where each number is a positive multiple of 5.",
  "shared.services.excel-template.template.readonly": "Not editable",
  "attributes.shared.ids": "Foreign ID",
  "attributeDescriptions.shared.ids": "A unique foreign ID, useful for export purposes",
  "attributeDescriptions.shared.createdAt": "The date and time when the entity was created",
  "attributeDescriptions.teachers.signature": "The teacher's signature",
  "account.workspace.page.displayPublicId.descr": "The foreign ID of an entity (course, lesson, teacher, etc.) is important when exporting data onwards to a different platform. If the schedule data has been imported into Royal Schedule, the value of the foreign ID should in general not be altered since this may give rise to data conflicts when the schedule is being exported. For inexperienced users, we therefore recommend hiding the foreign ID.",
  "account.workspace.page.displayPublicId.label": "Show foreign ID",
  "maps.excel.messages.forbidden_group_reference": "The group \"{{ context }}\" is a teaching group but is being used as a class.",
  "dialogs.create-courses.components.excel.warnings.no_courses": "No courses were found in the Excel file.",

  "common.undo": "Undo",
  "common.cancelled": "Cancelled",
  // "common.cancel_event": "Cancel event",
  "common.update_event": "Update event",

  "schedule.navigation.exceptions": "Exceptions",
  "schedule.navigation.exceptions.new": "New",
  "schedule.navigation.exceptions.list": "List",

  "view.private.schema.sub-pages.exceptions.list.title": "Current exceptions",
  "view.private.schema.sub-pages.exceptions.add": "Create new exception",

  "schedule.exceptions.new.title": "Create new absence",
  "schedule.exceptions.new.type-select": "Type",
  "schedule.exceptions.new.date-select": "Date interval",
  "schedule.exceptions.new.absentee-select": "Absentee",

  "view.private.schema.components.excel.subtitle": "Export schedule data in Excel format",
  "view.private.schema.components.additio.subtitle": "Export to Additio",

  "common.groups.species.none": "Teaching groups",
  "common.groups.species.class": "Classes",
  "common.the_groups.species.none": "The teaching groups",
  "common.the_groups.species.class": "The classes",
  "dialogs.create-courses.in-bulk.no_options": "There are no groups and teachers to choose from. First create groups and teachers before creating courses in bulk.",

  "view.private.schema.sub-pages.schema-editor.components.event-form.show_schedule": "Show schedule",

  "common.minutes-per-week_short": "min/week",
  "common.minutes-per-week": "Minutes/week",
  "attributes.course.plannedDuration": "Planned duration",
  "attributeDescriptions.course.plannedDuration": "The planned scheduled duration of the course in minutes per week or in total hours. The unspecified value is calculated automatically and rounded up.",
  "inputAnalysis.issues.plannedDuration.title": "{{The-entity}} does not satisfy its planned duration.",
  "inputAnalysis.issues.plannedDuration.description": "The planned duration is <b>{{planned-value}}</b> minutes/week, but the preferred duration of the events only adds up to <b>{{actual-value}}</b> minutes/week.",
  "schedule.guaranteedTuition.tabs.guaranteedTuition.table.planned_duration": "Planned duration",
  "schedule.guaranteedTuition.tabs.guaranteedTuition.table.planned_duration_not_met": "Planned duration not met for one or more courses represented by this row.",
  "schedule.guaranteedTuition.tabs.guaranteedTuition.table.actual_duration": "Actual duration",
  "tables.courses.values.plannedDuration.warning": "The planned duration is not satisfied.",
  "maps.excel.messages.ambiguous_planned_duration": "You cannot specify the planned duration in both minutes/week and hours at the same time. Please choose one of these: {{ context }}.",
  /* TO BE DEPRECATED */ "attributes.course.minutesPerWeek": "<s>Minutes/week</s>",
  /* TO BE DEPRECATED */ "attributes.course.expectedTotalHours": "<s>Expected total scheduled time</s>",
  /* TO BE DEPRECATED */ "attributeDescriptions.course.expectedTotalHours": "Replaced by 'Planned duration'.",
  /* TO BE DEPRECATED */ "attributeDescriptions.course.minutesPerWeek": "Replaced by 'Planned duration'.",
  "attributes.shared.tags": "Tags",
  "attributeDescriptions.shared.tags": "Tags are used to categorize and filter.",
  "shared.form-fields.tags.input.placeholder": "New tag...",
  "validators.invalid": "Invalid value",
  "maps.excel.messages.duplicate_group_member": "The student \"{{ context }}\" is listed more than once in the group.",
  "maps.excel.messages.invalid_person_reference": "Could not find the student with the social security number \"{{ context }}\".",
  "maps.excel.messages.person_in_multiple_classes": "The student \"{{ context }}\" is found in multiple classes.",
  "shared.dialogs.user-inactive.title": "Inactivity detected",
  "shared.dialogs.user-inactive.description": "Looks like you've been away for a bit. To keep everything running smoothly, please click the \"Reload\" button. This will refresh all your tabs and make sure everything is up-to-date.",
  "shared.views.schedule-viewer.before_select": "Select whose schedule you want to view and for which week.",
  "shared.dialogs.overlappableEventSets.auto-link.description": "Link automatically",
  "shared.dialogs.overlappableEventSets.unable_to_auto-link.description": "Unable to link the lessons automatically. For this to work, the parallel courses must contain the same number of lessons of the same length and the lessons must not already be fully linked.",
  "shared.dialogs.overlappableEventSets.auto-link_N": "Link {{ N }}",
  "shared.dialogs.overlappableEventSets.auto-link_N.description": "Automatically links the lessons for {{ N }} sets of parallel courses. For this to work, the parallel courses must contain the same number of lessons of the same length and the lessons must not already be fully linked.",
  "shared.dialogs.overlappableEventSets.unable_to_auto-link_N.description": "There are no sets of parallel courses that meet the requirements for their lessons to be linked automatically. For this to work, the parallel courses must contain the same number of lessons of the same length and the lessons must not already be fully linked.",
  "tables.courses.action.connect-courses": "Connect",
  "tables.courses.action.disconnect-courses": "Disconnect",
  "maps.excel.messages.invalid_course_reference": "Could not find the course with the ID \"{{ context }}\".",
};

// PLEASE LEAVE
// translate the value of the commented out key-value pairs to the language of this file and based on its context
