import { Pipe,
         PipeTransform                     } from '@angular/core';
import moment                                from 'moment';

import { TranslateService                  } from 'app/core';

type Style = 'long' | 'short';

@Pipe({
  name: 'durationSince',
  pure: false
})
export class DurationSincePipe implements PipeTransform {

  constructor(private _translate: TranslateService) { }

  private toString (
    now:     moment.Moment,
    then:    moment.Moment,
    unit:    'years' | 'days' | 'hours' | 'minutes' | 'seconds',
    literal: boolean,
    style:   Style
  ): string {
    const diff = -now.diff(then, unit);

    if (literal) {
      const rtf = new Intl.RelativeTimeFormat(this._translate.currentLanguage?.id, { style });
      return rtf.format(diff, unit);
    } else {
      return Math.round(-diff) + ' ' + this._translate.instant('common.' + unit + '_short');
    }
  }

  transform (
    _now:    moment.Moment | Date | string | undefined | null,
    _then:   moment.Moment | Date | string | undefined | null,   // required to avoid "ExpressionChangedAfterItHasBeenCheckedError"
    literal: boolean = true,
    style:   Style = 'long'
  ): string {
    if ( ! _then || ! _now) return '';

    const then       = moment.utc(_then);
    const now        = moment.utc(_now);
    const difference = now.diff(then, 's');


    // less than a minute
    if (difference < 60) {
      return this.toString(now, then, 'seconds', literal, style);
    }
    // less than an hour
    if (difference < 60*60) {
      return this.toString(now, then, 'minutes', literal, style);
    }
    // less than an day
    if (difference < 24*60*60) {
      return this.toString(now, then, 'hours', literal, style);
    }
    // less than an year
    if (difference < 365*24*60*60) {
      return this.toString(now, then, 'days', literal, style);
    }
    // more than an year
    return this.toString(now, then, 'years', literal, style);

  }

}