import { Pipe,
         PipeTransform                   } from '@angular/core';
import _                                   from 'lodash';

@Pipe({
  name: 'filterOptions'
})
export class FilterOptionsPipe implements PipeTransform {

  transform<T extends { displayName?: string }> (arr: T[] | null, filter: string | null) {
    if ( ! arr) return [];

    // the display names must contain the filter string
    if (filter) return arr.filter(x => x.displayName?.toLowerCase().includes(filter.toLowerCase()));
    return arr;
  }
}