import { NgModule                        } from '@angular/core';
import { CommonModule                    } from '@angular/common';
import { ReactiveFormsModule,
         FormsModule as AngFormsModule   } from '@angular/forms';

import { ComponentsModule                } from 'app/shared/components/components.module';

import { TranslationModule               } from 'app/core/translate/translate.module';

import { AppCommonModule                 } from 'app/common/common.module';

import { UsersComponent                  } from './users.component';

@NgModule({
  declarations: [
    UsersComponent
  ],
  exports: [
    UsersComponent
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    AppCommonModule,
    ReactiveFormsModule,
    AngFormsModule,
    TranslationModule
  ]
})
export class UsersModule { }
