import { Component,
         ViewChild,
         AfterViewInit,
         OnInit,
         Inject                            } from '@angular/core';
import { MatDialogRef,
         MAT_DIALOG_DATA                   } from 'app/common';
import { DivisionComponent                 } from 'app/shared/forms/division/division.component';
import { SubmitPaneComponent               } from 'app/shared/components';
import { apiConstants                      } from 'app/constants';
import { HttpService,
         LoggerService                     } from 'app/core';
import { DivisionComponent as Form         } from 'app/shared/forms/division/division.component';
import { Division                          } from 'app/shared/interfaces';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit, AfterViewInit {
  @ViewChild(Form, { static: true })                form:       Form;
  @ViewChild(DivisionComponent)                     division?:   DivisionComponent;
  @ViewChild(SubmitPaneComponent, { static: true }) submitPane: SubmitPaneComponent;
  public loading:     boolean;
  public submitted:   boolean;

  constructor(
    public  dialogRef: MatDialogRef<EditComponent>,
    private http:      HttpService,
    private logger:    LoggerService,
    @Inject(MAT_DIALOG_DATA)
    private data:      { division: Division }
  ) { }

  ngOnInit () { }

  ngAfterViewInit() {
    setTimeout(() => { this.form.setFormValue(this.data.division); });
  }

  public submit() {
    this.submitted = true;
    this.submitPane.setSavingState();
    const model = { division: this.division?.value };
    this.http
      .post(`${ apiConstants.DIVISIONS }/${this.data.division.id}`, model, undefined)
      .subscribe({
        next: () => {
          void this.submitPane
          .setUpdateSuccessState()
          .then(() => this.dialogRef.close(model.division))
        },
        error: (err: Error) => {
          this.submitPane.setCreationFail();
          this.submitted = false;
          this.logger.error(err);
        }
      });
  }
}
