import { Component,
         Input,
         ElementRef,
         OnDestroy,
         Optional,
         Self,
         EventEmitter,
         HostBinding,
         Output,
         ChangeDetectionStrategy,
         ViewChild                       } from '@angular/core';
import { ControlValueAccessor,
         NgControl,
         FormControl,
         FormGroup,
         FormArray,
         ValidationErrors                } from '@angular/forms';
import { MatFormFieldControl             } from '@angular/material/form-field';
import { FocusMonitor                    } from '@angular/cdk/a11y';
import { coerceNumberProperty,
         coerceBooleanProperty           } from '@angular/cdk/coercion';
import { MatMenuTrigger                  } from '@angular/material/menu';
import { Subject,
         fromEvent,
         asyncScheduler                  } from 'rxjs';
import { distinctUntilChanged,
         map,
         takeUntil                       } from 'rxjs/operators';
import moment                              from 'moment';
import _                                   from 'lodash';
import $                                   from 'jquery';

import { DateService                     } from 'app/shared/services';
import { Coalesced,
         Location,
         LockedTime                      } from 'app/shared/interfaces';
import { ExtendedValidators              } from 'app/shared/forms/validators';
import { MatSelect                       } from '@angular/material/select';

type Value = Partial<LockedTime.populated>[] | null | undefined;


const types = ['none', 'multiple', 'single', undefined] as const;
type Type = typeof types[number];

type Duration = {
  duration:  number;
  variation: number;
}


type InnerForm = {
  id:        FormControl<string | null>;
  earliest:  FormControl<moment.Moment>;
  latest:    FormControl<moment.Moment>;
  duration:  FormControl<Duration>;
  locations: FormControl<string[]>;
  // store the start and end time as we need to manipulate them
  // (start + duration = end)
  start:     FormControl<moment.Moment | null>;
  end:       FormControl<moment.Moment | null>;
}
type OuterForm = {
  type:   FormControl<Type>;
  values: FormArray<FormGroup<InnerForm>>;
}

const getDefaultStart = (d: number) => DateService.fromTimeString('11:00', d);
const getDefaultEnd   = (d: number) => DateService.fromTimeString('13:00', d);

const defaultDuration = 60;
const defaultVariance = 0;
const defaultLocations: string[] = [];

function createFormGroup (day: number): FormGroup<InnerForm> {
  return new FormGroup<InnerForm>({
    id:        new FormControl(null),
    earliest:  new FormControl(getDefaultStart(day), { nonNullable: true }),
    latest:    new FormControl(getDefaultEnd  (day), { nonNullable: true }),
    duration:  new FormControl({
      duration:  defaultDuration,
      variation: defaultVariance
    }, { nonNullable: true }),
    start:    new FormControl(null),
    end:      new FormControl(null),
    locations: new FormControl(defaultLocations, { nonNullable: true }),
  }, { validators: [
    ExtendedValidators.validateThat('earliest', 'isSameOrBefore', 'latest', { key: 'duration', valueAccessor: (x) => x.duration }),
  ]})
}

function removeMetaData (value: Value): Value {
  return value?.map(raw => {
    const x = _.pick(raw, 'id', 'coalesced', 'duration', 'durationVariance', 'intervals', 'type', 'start', 'end');

    // keep only the id
    x.coalesced = x.coalesced
      ?.filter((x): x is Coalesced<Location> => x.toModel == 'locations')
      ?.map(x => ({ to: { id: x.to.id } as Location, toModel: 'locations' }))

    return x;
  });
}


@Component({
  selector: 'app-form-field-dynamic-locked-times',
  templateUrl: './dynamic-locked-times.component.html',
  styleUrls: ['./dynamic-locked-times.component.scss'],
  // encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: DynamicLockedTimesComponent
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicLockedTimesComponent implements OnDestroy,
                                                    ControlValueAccessor,
                                                    MatFormFieldControl<Value> {
  @ViewChild(MatMenuTrigger)      trigger?:           MatMenuTrigger;
  @Output('onChange') emitter = new EventEmitter<Value>();
  private onClose = new Subject<boolean>();
  public placeholderText:  string          = '';
  static nextId:       number              = 0;
  public stateChanges: Subject<void>       = new Subject<void>();
  public focused:      boolean             = false;
  public touched:      boolean             = false;
  public errorState:   boolean             = false;
  public isVoid:       boolean             = true;
  public controlType:  string              = 'dynamic-locked-times-input';
  public id:           string              = `dynamic-locked-times-input-${ DynamicLockedTimesComponent.nextId++ }`;
  public describedBy:  string              = '';
  public onChange = (_: any) => {};
  public onTouched = () => {};

  protected form: FormGroup<OuterForm> | null = null;

  protected readonly typeToken: {
    form: FormGroup<InnerForm>;
    day?: number;
  };

  @HostBinding('attr.tabindex') __tabindex = 0;

  constructor (
    @Optional() @Self() public ngControl: NgControl,
    private _focusMonitor: FocusMonitor,
    private _elementRef:   ElementRef<HTMLElement>,
  ) {

    _focusMonitor.monitor(_elementRef, true)
    .subscribe((origin) => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  public resetValue(): void {
    this.value = this._pristineValue;
    this.pristine = true;
    this._setFormValue(this._pristineValue);
  }

  public opened(): void {
    this.form = new FormGroup({
      type:   new FormControl<Type>(undefined, { nonNullable: true, validators: ExtendedValidators.enum(types as unknown as any[]) }),
      values: new FormArray(_.times(this.numDays, d => createFormGroup(d))),
    });
    this._setFormValue(this._value);

    this.isVoid = false;

    fromEvent(document, 'keydown')
    .pipe(takeUntil(this.onClose))
    .subscribe((event: KeyboardEvent) => {
      const key = event.key.toLowerCase();
      if (key == 'escape') this.trigger?.closeMenu();
    });

    // perform operations when the type changes
    // (important to subscribe after the initial value is set)
    this.form?.controls.type.valueChanges
    .pipe(
      takeUntil(this.onClose),
      distinctUntilChanged(),
    )
    .subscribe(x => {
      // make all days enabled
      if (x == 'single') this.form?.controls.values.enable({ emitEvent: false });

      // mirror all values of the first day to the other ones
      // (this is carried out before the value change is emitted on the parent form)
      if (x == 'multiple') {
        const arr = this.form?.controls.values!;
        for (let d = 0; d < arr.length; d++) {
          if (d == 0) continue;
          arr.at(d).patchValue({
            earliest:  DateService.sanitizeDate(arr.at(0).value.earliest!, d)!,
            latest:    DateService.sanitizeDate(arr.at(0).value.latest!  , d)!,
            duration:  arr.at(0).value.duration,
            locations: arr.at(0).value.locations,
          }, { emitEvent: false });
        }
      }
    });


    //
    this.form?.valueChanges
    .pipe(
      takeUntil(this.onClose),
      // fix type
      map(x => x as typeof x),
      // take into account type
      map(x => {
        if (x.type == 'none') return [];
        if (x.type == 'single') {
          // mirror all values of the first day to the other ones
          const val0 = x.values?.at(0);
          val0 && x.values?.forEach((val, d) => {
            if (d == 0) return;

            val.earliest  = DateService.sanitizeDate(val0.earliest!, d)!;
            val.latest    = DateService.sanitizeDate(val0.latest!  , d)!;
            val.duration  = val0.duration;
            val.locations = val0.locations;
          });

          // run change detection
          this.stateChanges.next();
        }

        return (x.values ?? []) as Required<NonNullable<typeof x.values>[0]>[];
      }),
      // map to external value
      map(val => val.map(x => {
          const id               = x.id;
          const type             = 'LUNCH';
          const duration         = x.duration.duration;
          const durationVariance = x.duration.variation;
          const earliest         = DateService.sanitizeDate(x.earliest)!;
          const latest           = DateService.sanitizeDate(x.latest  )!;
          const intervals        = [{ start: earliest, end: latest }];
          const coalesced        = x.locations.map(to => ({ to: { id: to }, toModel: 'locations' }));

          // try to keep the start time
          const start = x.start ?? earliest;
          const end   = start.clone().add(duration, 'minutes');

          return _.omitBy({ id, intervals, duration, durationVariance, type, coalesced, start, end }, _.isNil);
        })
      )
    )
    .subscribe(val => {
      // need to stringify all values (moments) before comparing
      const pristineVal = this._pristineValue ? JSON.parse(JSON.stringify(removeMetaData(this._pristineValue))) : this._pristineValue;
      const currentVal  = val ? JSON.parse(JSON.stringify(removeMetaData(val))) : val;
      this.pristine = _.isEqual(pristineVal, currentVal);

      // update values
      // (need to set pristine value if pristine as the locations will otherwise show up as "Unknown")
      this._value = this.pristine ? this._pristineValue : val;

      this._handleInput();
    });
  }

  protected matSelectEscapePress (event: Event, matSelect: MatSelect) {
    // the panel must be open
    if ( ! matSelect.panelOpen) return;

    matSelect.close();
    event.stopPropagation();
  }

  public closed(): void {
    if (this.saveOnClose && ! this.pristine) {
      // reset if the value is invalid
      if ( ! this.valid) {
        this.resetValue();
        return;
      }

      this.emitter.emit(this.value);
      if ( ! this.ngControl) {
        this.pristine = true;
        this._pristineValue = this.value;
      }
    }
    this.form = null;
    this.onClose.next(true);
    this.isVoid = true;
  }

  public submit(): void {
    this.emitter.emit(this.value);
  }


  private _setFormGroupValue (
    fg:  FormGroup<InnerForm> | undefined,
    val: Partial<LockedTime.populated> | undefined,
    day: number
  ) {
    const earliest = (val?.intervals?.[0]?.start ? DateService.sanitizeDate(val?.intervals?.[0]?.start, day) : null) ?? getDefaultStart(day);
    const latest   = (val?.intervals?.[0]?.end   ? DateService.sanitizeDate(val?.intervals?.[0]?.end  , day) : null) ?? getDefaultEnd  (day);

    fg?.setValue({
      id:        val?.id ?? null,
      earliest:  earliest,
      latest:    latest,
      start:     val?.start ? moment.utc(val?.start) : null,
      end:       val?.end   ? moment.utc(val?.end)   : null,
      duration:  { duration: val?.duration ?? defaultDuration, variation: val?.durationVariance ?? defaultVariance },
      locations: val?.coalesced?.filter(x => x.toModel == 'locations').map(x => x.to?.id).filter(Boolean) ?? defaultLocations
    }, { emitEvent: false });
  }

  private _setFormValue (value: Value): void {
    // abort if form is not yet initialized
    const form = this.form;
    if ( ! form) return;

    // get the distinct number of locked times since we always store "numDays" of them
    const distinct = _.uniqBy(value,
        x => JSON.stringify({
          earliest:         moment.utc(x.intervals?.[0]?.start).format('HH:mm'),
          latest:           moment.utc(x.intervals?.[0]?.end  ).format('HH:mm'),
          duration:         x.duration,
          durationVariance: x.durationVariance,
          coalesced:        x.coalesced?.filter(x => x.toModel == 'locations').map(x => x?.to?.id).filter(Boolean)
        })
      );
    if (value == null)
    return form.controls.type.setValue(undefined, { emitEvent: false });
    // one may disable lunches hence the singe representation should be used only if
    // they are all the same and there is one for each day
    let type: Type;
    if      (value.length == 0)                                     type = 'none';
    else if (distinct.length == 1 && value.length == this._numDays) type = 'single';
    else                                                            type = 'multiple';
    form.controls.type.setValue(type, { emitEvent: false });

    // load the values
    for (let d = 0; d < this.numDays; d++) {
      // find the locked time for this day
      const val = value.find(x => {
        const start = x?.intervals?.[0]?.start;
        return start ? DateService.getDayIndex(start) == d : false;
      });

      this._setFormGroupValue(form.controls.values.at(d), val, d);

      // disable the form control if the locked time is not found otherwise enable it
      // (in the case of resetting the value this is required)
      if ( ! val && type == 'multiple') form.controls.values.at(d)?.disable();
      else                              form.controls.values.at(d)?.enable();
    }
  }

  public onStateChange(): void {
    asyncScheduler.schedule(() => {
      const { value } = this.form?.controls.type ?? {};
      let contentHeight = value == 'multiple' ? 510 : 185;
      let margin        = 24;
      let toolbarHeight = 48;
      let panel         = $('.dynamic-locked-times-panel');
      let top           = panel.offset()!.top;
      let size          = $( window ).height()!;
      if (top + contentHeight + margin + toolbarHeight > size) {
        panel.css('transform', `translateY(${ size - top - contentHeight - margin - toolbarHeight }px)`);
      } else {
        panel.css('transform', `translateY(0px)`);
      }
    });
  }

  get empty() {
    return this._value === undefined;
  }

  get valid(): boolean {
    return this.form?.valid ?? false;
  }

  get shouldLabelFloat() { return this.focused || ! this.empty; }

  get pristine(): boolean {
    return this._pristine;
  }
  set pristine(_val: boolean) {
    const val      = coerceBooleanProperty(_val);
    this._pristine = val;

    if (val) asyncScheduler.schedule(() => this.ngControl?.control?.markAsPristine());
    else this.ngControl?.control?.markAsDirty();

    this.stateChanges.next();
  }
  private _pristine:  boolean = true;

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get voidText(): string { return this._voidText; }
  set voidText(value: string) {
    this._voidText = value;
    this.stateChanges.next();
  }
  private _voidText: string = '';

  @Input()
  get reset(): boolean { return this._reset; }
  set reset(value: boolean) {
    this._reset = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _reset = true;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get disableActions(): boolean { return this._disableActions; }
  set disableActions(value: boolean | string) {
    this._disableActions = coerceBooleanProperty(value);
  }
  private _disableActions = true;

  @Input()
  get hideOptionDefault(): boolean { return this._hideOptionDefault; }
  set hideOptionDefault(value: boolean | string) {
    this._hideOptionDefault = coerceBooleanProperty(value);
  }
  private _hideOptionDefault: boolean = false;

  @Input()
  get saveOnClose(): boolean { return this._saveOnClose; }
  set saveOnClose(value: boolean | string) {
    this._saveOnClose = coerceBooleanProperty(value);
  }
  private _saveOnClose: boolean = false;

  get days(): number[] {
    return this._days;
  }
  private _days = [...Array(5).keys()];

  @Input()
  get numDays(): number { return this._numDays; }
  set numDays(value: number) {
    this._numDays = coerceNumberProperty(value, 5);
    this._days    = [...Array(this._numDays).keys()];
    this.stateChanges.next();
  }
  private _numDays = 5;

  @Input()
  get coalesced(): boolean { return this._coalesced; }
  set coalesced(value: boolean) {
    this._coalesced = coerceBooleanProperty(value);
  }
  private _coalesced = false;

  @Input()
  get locations(): Location.populated[] { return this._locations; }
  set locations(value: Location.populated[] | null) {
    this._locations = _.orderBy(value, 'displayName') ?? [];
  }
  private _locations: Location.populated[] = [];

  @Input()
  get value(): Value {
    return this._value;
  }
  set value(_val: Value | null) {
    _val = _val;

    // must keep the displayNames of coalesced locations as they are used in the display value component
    this._value = this._pristineValue = _val;
    this.stateChanges.next();
  }
  private _value:         Value = [];
  private _pristineValue: Value = [];

  onFocusIn(event: FocusEvent) {
    if (! this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent) {
    if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
    this.onClose.next(true);
    this.onClose.complete();
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent) {
    this.trigger?.openMenu();
  }

  writeValue(val: Value): void {
    this.value = val;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  _handleInput(): void {
    this.onChange(this.value);
  }

  static ngAcceptInputType_disabled: boolean | string | null | undefined;
  static ngAcceptInputType_required: boolean | string | null | undefined;
}

