import { Component,
         Inject,                           } from '@angular/core';
import { FormControl,
         FormGroup                         } from '@angular/forms';
import { combineLatest,
         firstValueFrom                    } from 'rxjs';

import { MatDialogRef,
         MAT_DIALOG_DATA                   } from 'app/common';
import { HttpService,
         PushNotificationService           } from 'app/core';
import { apiConstants                      } from 'app/constants';
import { Company                           } from 'app/shared/interfaces';


export type Organization = Pick<Company.complete, 'id' | 'name' | 'environment' | 'associatedPartner'>;

export type Data = {
  organization: Organization;
}

type Version = {
  value: string;
  name:  string;
}

type Environment = {
  value: string;
  name:  string;
}

type Form = {
  version:     FormControl<string | null>;
  environment: FormControl<string | null>;
}

function isVX (val: string) {
  return val[0] === 'v' && ! isNaN(parseInt(val.slice(1)));
}


@Component({
  selector: 'app-configure-compute-environment',
  templateUrl: './configure-compute-environment.component.html',
  styleUrls: ['./configure-compute-environment.component.scss']
})
export class ConfigureComputeEnvironmentComponent {
  protected loading = true;

  protected readonly environments: Environment[] = [
    { value: 'dummy-run', name: 'Dummy run'},
    { value: 'dry-run',   name: 'Dry run'},
    { value: 'custom',    name: 'Custom'},
    { value: 'demo',      name: 'Demo'}
  ];
  protected versions: Version[] = [
    { value: 'v0', name: 'v0'},
    { value: 'v1', name: 'v1'},
  ];

  protected readonly form: FormGroup<Form>;

  private readonly organization: Organization;

  constructor (
    @Inject(MAT_DIALOG_DATA) { organization }: Data,
    protected dialogRef:    MatDialogRef<ConfigureComputeEnvironmentComponent>,
    private   _http:        HttpService,
    private _notifications: PushNotificationService
  ) {
    this.organization = organization;

    this.form = new FormGroup<Form>({
      version:     new FormControl<string | null>(null),
      environment: new FormControl<string | null>(null),
    });
    this.form.get('environment')?.disable();

    firstValueFrom(combineLatest({
      computeEnvironment: this._http.get(`${ apiConstants.ADMIN }/companies/${ this.organization.id }/compute-environment`),
      versions:           this._http.get(`${ apiConstants.ADMIN }/compute_environments/versions`)
    }))
    .then(({ computeEnvironment, versions }) => {
      // patch form value
      this.form.patchValue({
        version:     computeEnvironment.version     ?? null,
        environment: computeEnvironment.environment ?? null,
      });

      // append to the list of available versions and order
      this.versions = this.versions.concat((versions as string[]).map(v => ({ value: v, name: v })));
      this.versions.sort((a, b) => {
        // the default version should be first (value is null)
        if (a.value === null) return -1;
        if (b.value === null) return 1;

        // then all versions on the form v0, v1, v2, ...
        if (   isVX(a.value) && ! isVX(b.value)) return -1;
        if ( ! isVX(a.value) &&   isVX(b.value)) return 1;

        // then order the rest alphabetically
        return a.value.localeCompare(b.value);
      });

      this.loading = false;
    })
    .catch(err => {
      console.warn(err);
      this._notifications.pushError();
      this.dialogRef.close();
    });
  }

  protected async submit() {
    this.loading = true;

    const val = this.form.value;
    try {
      const res = await firstValueFrom(this._http.post(`${ apiConstants.ADMIN }/companies/${ this.organization.id }/compute-environment`, val));
      this.dialogRef.close(true);
    } catch (err) {
      console.warn(err);
      this._notifications.pushError();
    }

    this.loading = false;
  }
}
