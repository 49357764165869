import _                        from 'lodash';

import { Populated as P       } from "@app/shared/interfaces";
import { InputAnalysisService } from './input-analysis.service';
import { Remark               } from './types';

export function getName (
  this:    InputAnalysisService,
  entity?: Remark['entities'][0]
): string {
  if (entity?.is == 'holiday') return entity.displayName ?? this._translate.instant('common.unknown');

  return this._common.deriveName(entity).value;
}

export function sortMapFun (
  r1: Remark,
  r2: Remark
): number {

  let code1 = r1.code.substring(r1.code.indexOf(', '));
  let code2 = r2.code.substring(r2.code.indexOf(', '));

  let name1 = this.getName(r1.entities[0]);
  let name2 = this.getName(r2.entities[0]);

  if (code1 != code2) {
    // primary
    return code1?.localeCompare(code2);
  } else {
    // secondary
    return name1?.localeCompare(name2);
  }
}


export function t (
  this: InputAnalysisService,
  ...x: Parameters<typeof this._translate.instant>
) {
  return this._translate.instant(...x);
}


type Is = (P.location | P.person | P.teacher | P.group | P.event | P.lockedTime)['is'];

export function parseCompositeId (val: string): { id: string, is?: Is } {
  if ( ! val?.includes('.')) return { id: val }

  const [collection, id] = val.split('.');
  if      (collection == 'locations'                              ) return { id, is: 'location'   };
  else if (collection == 'groups'                                 ) return { id, is: 'group'      };
  else if (collection == 'teachers'                               ) return { id, is: 'teacher'    };
  else if (collection == 'persons'                                ) return { id, is: 'person'     };
  else if (collection == 'events' || collection == 'courseevents' ) return { id, is: 'event'      };
  else if (collection == 'lockedtimes'                            ) return { id, is: 'lockedTime' };
  else return { id: val };
}