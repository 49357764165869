import { Tutorial                        } from './tutorials.types';

export const TUTORIALS: Tutorial[] = [
  {
    icon:        'assets/images/svg/tutorials/Schemavarningar,generellt färger.svg',
    padding:     true,
    type:        'video',
    source: {
      sv: {
        youtubeId:   'OA4cOf72GxM'
      }
    },
    id:          '1',
    duration:    5,
    related:     ['2'],
    tags:        ['warnings']
  }, {
    icon:        'assets/images/svg/tutorials/Schemavarningar,generellt färger.svg',
    padding:     true,
    type:        'video',
    source: {
      sv: {
        youtubeId:   'JLtdRXYhDac'
      }
    },
    id:          '2',
    duration:    3,
    related:     ['1'],
    tags:        ['warnings']
  }, {
    icon:        'assets/images/svg/tutorials/Tidsram.svg',
    padding:     false,
    type:        'video',
    source: {
      sv: {
        youtubeId:   'G0o7zE26rNQ'
      }
    },
    id:          '3',
    duration:    2,
    related:     ['17'],
    tags:        ['conditions']
  }, {
    icon:        'assets/images/svg/tutorials/Lunch.svg',
    padding:     true,
    type:        'video',
    source: {
      sv: {
        youtubeId:   'yz4fx9RdRB4'
      }
    },
    id:          '4',
    duration:    2,
    related:     ['9', '18', '12', '26'],
    tags:        ['conditions']
  }, {
    icon:        'assets/images/png/undraw_teacher_35j2.png',
    padding:     false,
    type:        'video',
    source: {
      sv: {
        youtubeId:   'lzfkYOIGNnQ'
      }
    },
    id:          '5',
    duration:    2,
    collections: ['teachers'],
    tags:        ['conditions']
  }, {
    icon:        'assets/images/svg/tutorials/Fixerade lektioner.svg',
    padding:     true,
    type:        'video',
    source: {
      sv: {
        youtubeId:   'p_76vejJEhg'
      }
    },
    id:          '6',
    duration:    3,
    related:     ['7'],
    tags:        ['edit']
  }, {
    icon:        'assets/images/svg/tutorials/Manuel schemaläggning, placera lektioner.svg',
    padding:     true,
    type:        'video',
    source: {
      sv: {
        youtubeId:   '4mL03PQmPO4'
      }
    },
    id:          '7',
    duration:    3,
    tags:        ['edit']
  }, {
    icon:        'assets/images/svg/tutorials/Tillåtna dagar deltid.svg',
    padding:     true,
    type:        'video',
    source: {
      sv: {
        youtubeId:   'GMakEEd9Va4'
      }
    },
    id:          '8',
    duration:    1,
    tags:        ['conditions']
  }, {
    icon:        'assets/images/svg/tutorials/Förbjuda tider.svg',
    padding:     true,
    type:        'video',
    source: {
      sv: {
        youtubeId:   'j18QQOyuJQs'
      }
    },
    id:          '9',
    duration:    2,
    tags:        ['conditions']
  }, {
    icon:        'assets/images/png/undraw_exams_g4ow.png',
    padding:     false,
    type:        'video',
    source: {
      sv: {
        youtubeId:   'V8OQ8cUj5Qw'
      }
    },
    id:          '10',
    duration:    3,
    collections: ['groups', 'persons'],
    tags:        ['data']
  }, {
    icon:        'assets/images/svg/tutorials/Minuter per vecka.svg',
    padding:     true,
    type:        'video',
    source: {
      sv: {
        youtubeId:   'fXOT5E9hrds'
      }
    },
    id:          '11',
    duration:    2,
    related:     ['12'],
    tags:        ['data']
  }, {
    icon:        'assets/images/svg/tutorials/Rapporter, Data.svg',
    padding:     true,
    type:        'video',
    source: {
      sv: {
        youtubeId:   '38qGHPd_Y24'
      }
    },
    id:          '12',
    duration:    3,
    tags:        ['other']
  }, {
    icon:        'assets/images/svg/tutorials/Utbildning.svg',
    padding:     true,
    type:        'video',
    source: {
      sv: {
        youtubeId:   'Kt8rVFurjWE'
      }
    },
    id:          '13',
    duration:    12,
    related:     ['32', '10', '12', '24', '33', '34'],
    tags:        ['education']
  },
  {
    icon:        'assets/images/svg/tutorials/Granska schema.svg',
    padding:     true,
    type:        'video',
    source: {
      sv: {
        youtubeId:   'G7huEH4H0lw'
      }
    },
    id:          '15',
    duration:    2,
    tags:        ['adjustments']
  }, {
    icon:        'assets/images/svg/tutorials/Redigera kolumner.svg',
    padding:     true,
    type:        'video',
    source: {
      sv: {
        youtubeId:   'oaXLlRdWRUI'
      }
    },
    id:          '16',
    duration:    1,
    tags:        ['settings']
  }, {
    icon:        'assets/images/svg/tutorials/Schemainställningar.svg',
    padding:     true,
    type:        'video',
    source: {
      sv: {
        youtubeId:   '6Aa6UIhhvmo'
      }
    },
    id:          '17',
    duration:    3,
    related:     ['12'],
    tags:        ['settings']
  }, {
    icon:        'assets/images/svg/tutorials/Rast, förbjuden tid.svg',
    padding:     true,
    type:        'video',
    source: {
      sv: {
        youtubeId:   'bSZK0f3xTSU'
      }
    },
    id:          '18',
    duration:    6,
    related:     ['28', '26', '25', '12', '3'],
    tags:        ['conditions']
  }, {
    icon:        'assets/images/svg/tutorials/Inställningar, Grundinställningar, Skriva ut.svg',
    padding:     true,
    type:        'video',
    source: {
      sv: {
        youtubeId:   'gEg2c9pm2kU'
      }
    },
    id:          '19',
    duration:    4,
    tags:        ['settings']
  }, {
    icon:        'assets/images/png/undraw_map_1r69.png',
    padding:     true,
    type:        'video',
    source: {
      sv: {
        youtubeId:   'Q8Lu8T0cmLI'
      }
    },
    id:          '20',
    duration:    3,
    collections: ['locations'],
    related:     ['30'],
    tags:        ['conditions']
  }, {
    icon:        'assets/images/svg/tutorials/Kortast tillåtna rast.svg',
    padding:     true,
    type:        'video',
    source: {
      sv: {
        youtubeId:   '9kgrPtl_8FE'
      }
    },
    id:          '21',
    duration:    2,
    related:     ['17', '18'],
    tags:        ['conditions']
  }, {
    icon:        'assets/images/svg/Artificial_intelligence-modified.svg',
    padding:     true,
    type:        'video',
    source: {
      sv: {
        youtubeId:   '-B2mmrGiKOs'
      }
    },
    id:          '22',
    duration:    17,
    related:     ['23', '12', '25'],
    tags:        ['education']
  }, {
    icon:        'assets/images/svg/tutorials/Analysmodul varningar.svg',
    padding:     true,
    type:        'video',
    source: {
      sv: {
        youtubeId:   'agenL9caE0g'
      }
    },
    id:          '23',
    duration:    9,
    related:     ['24'],
    tags:        ['inputAnalyzer']
  }, {
    icon:        'assets/images/svg/tutorials/Analysmodul, Analys, Underlagsanalys.svg',
    padding:     true,
    type:        'video',
    source: {
      sv: {
        youtubeId:   'FV3FLnvJGPE'
      }
    },
    id:          '24',
    duration:    3,
    related:     ['23'],
    tags:        ['inputAnalyzer']
  }, {
    icon:        'assets/images/svg/tutorials/Längdvariation.svg',
    padding:     true,
    type:        'video',
    source: {
      sv: {
        youtubeId:   'wleP06MsvDQ'
      }
    },
    id:          '25',
    duration:    3,
    tags:        ['conditions']
  }, {
    icon:        'assets/images/svg/tutorials/Lektioner.svg',
    padding:     true,
    type:        'video',
    source: {
      sv: {
        youtubeId:   'pyvvo-0Ed9s'
      }
    },
    id:          '26',
    duration:    5,
    related:     ['11'],
    tags:        ['data']
  }, {
    icon:        'assets/images/svg/tutorials/Perioder.svg',
    padding:     true,
    type:        'video',
    source: {
      sv: {
        youtubeId:   'E4ZHLaal-sw'
      }
    },
    id:          '27',
    duration:    3,
    tags:        ['conditions']
  }, {
    icon:        'assets/images/svg/tutorials/date-picker.svg',
    padding:     true,
    type:        'video',
    source: {
      sv: {
        youtubeId:   'Tf1SrDU7lTA'
      }
    },
    id:          '28',
    duration:    2,
    tags:        ['data']
  }, {
    icon:        'assets/images/svg/tutorials/Anpassad sökning.svg',
    padding:     true,
    type:        'video',
    source: {
      sv: {
        youtubeId:   'vwX56hHoedc'
      }
    },
    id:          '29',
    duration:    2,
    tags:        ['settings']
  }, {
    icon:        'assets/images/svg/tutorials/Lägga till salar, lärare, elever och grupper.svg',
    padding:     true,
    type:        'video',
    source: {
      sv: {
        youtubeId:   '0YI5_2vOAsY'
      }
    },
    id:          '30',
    duration:    3,
    tags:        ['other']
  }, {
    icon:        'assets/images/svg/tutorials/Parallella kurser.svg',
    padding:     true,
    type:        'video',
    source: {
      sv: {
        youtubeId:   'WoPw-_t11LQ'
      }
    },
    id:          '31',
    duration:    4,
    related:     ['25'],
    tags:        ['conditions']
  }, {
    icon:        'assets/images/svg/tutorials/Excel import.svg',
    padding:     true,
    type:        'video',
    source: {
      sv: {
        youtubeId:   'zLpcxZpQKtE'
      }
    },
    id:          '32',
    duration:    11,
    related:     ['36'],
    tags:        ['education']
  }, {
    icon:        'assets/images/svg/tutorials/manual_edits.svg',
    padding:     true,
    type:        'video',
    source: {
      sv: {
        youtubeId:   'Yi0SgpDLLKA'
      }
    },
    id:          '33',
    duration:    37,
    related:     ['34'],
    tags:        ['education']
  }, {
    icon:        'assets/images/svg/tutorials/manual_edits.svg',
    padding:     true,
    type:        'video',
    source: {
      sv: {
        youtubeId:   'TggWcypB7us'
      }
    },
    id:          '34',
    duration:    25,
    related:     ['33'],
    tags:        ['education']
  }, {
    icon:        'assets/images/svg/tutorials/manual_edits.svg',
    padding:     true,
    type:        'video',
    source: {
      sv: {
        youtubeId:   'TzlPxAsJSpc'
      }
    },
    id:          '35',
    duration:    2,
    tags:        ['education']
  }, {
    icon:        'assets/images/svg/tutorials/Excel import.svg',
    padding:     true,
    type:        'video',
    source: {
      sv: {
        youtubeId:   'aKowEm-wp7Q'
      }
    },
    id:          '36',
    duration:    6,
    related:     ['32'],
    tags:        ['education']
  }, {
    icon:        'assets/images/png/undraw_exams_g4ow.png',
    padding:     false,
    type:        'video',
    source: {
      sv: {
        youtubeId:   'PGdI35AdKTc'
      }
    },
    id:          '37',
    duration:    11,
    related:     ['16'],
    collections: ['groups', 'persons'],
    tags:        ['data']
  }, {
    icon:        'assets/images/png/undraw_exams_g4ow.png',
    padding:     false,
    type:        'video',
    source: {
      sv: {
        youtubeId:   'y1lzgJxwGiw'
      }
    },
    id:          '38',
    duration:    9,
    related:     ['1', '2', '6'],
    tags:        ['adjustments', 'edit']
  }, {
    icon:        'assets/images/png/undraw_exams_g4ow.png',
    padding:     false,
    type:        'video',
    source: {
      sv: {
        youtubeId:   'aGn-LtmVej0'
      }
    },
    id:          '39',
    duration:    6,
    tags:        ['other']
  }, {
    icon:        '/assets/integrations/schools/icons/schoolsoft.png',
    padding:     true,
    type:        'video',
    source: {
      sv: {
        youtubeId:   'JYMnH6ghkfk'
      }
    },
    id:          '40',
    duration:    25,
    tags:        ['other']
  },
];