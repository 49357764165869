import _                                   from 'lodash';

import { Util                            } from '@app/common';

export function resolve<T>(
  value: T,
  detail: any,
  modifiedValue: string | null,
  cutPath: number | null | undefined
) {
  const [path, ...paths] = detail.path;
  let _value = structuredClone(_.get(value, detail.path[0]));
  // If the value is modified replace the value
  if (modifiedValue !== undefined) {
    if (paths.length != 0)
      _.set(_value, paths, modifiedValue);
    else
      _value = modifiedValue;
  }

  // If the value is cut remove the value
  if (cutPath != null) {
    if (cutPath === 0) {
      return Array.isArray(_value) ? [] : {};
    }
    const split = [...paths];
    split.splice(cutPath);
    Util.functions.unset(_value, split);
  }

  return _value
}