import { Inject,
         Injectable,
         OnDestroy                    } from '@angular/core';
import { BehaviorSubject,
         Observable,
         debounceTime,
         filter,
         map,
         startWith,
         Subject,
         takeUntil                    } from 'rxjs';
import _                                from 'lodash';

import { EnvironmentService,
         StorageService,
         UserPreferencesService       } from '@app/core';
import { Collection                   } from '../../types';
import { COLLECTION                   } from '../../constants';
import { Columns,
         TableColumnProperties        } from './types';
import { storageKeys,
         coursesColumns,
         eventsColumns,
         groupsColumns,
         locationsColumns,
         personsColumns,
         teachersColumns              } from './constants';

export * from './types';
export * from './constants';


type StoredColumns = Record<string, boolean>


@Injectable({
  providedIn: 'root'
})
export class TableColumnsService<C extends Collection> implements OnDestroy {
  private readonly onDestroy = new Subject<void>();

  protected readonly columns: Columns;

  private _columns = new BehaviorSubject<Columns[C] | null>(null);

  constructor (
    @Inject(COLLECTION) private collection: C,
    private environment: EnvironmentService,
    private preferences: UserPreferencesService,
    private storage:     StorageService,
  ) {
    this.columns = {
      locations: locationsColumns(this.environment, this.preferences.displayPublicId),
      persons:   personsColumns  (this.environment, this.preferences.displayPublicId),
      teachers:  teachersColumns (this.environment, this.preferences.displayPublicId),
      groups:    groupsColumns   (this.environment, this.preferences.displayPublicId),
      courses:   coursesColumns  (this.environment, this.preferences.displayPublicId),
      events:    eventsColumns   (this.environment, this.preferences.displayPublicId),
    }

    // subscribe to search keys changes from the local storage
    this.storage
    .onStorage(storageKeys[this.collection])
    .pipe(
      takeUntil(this.onDestroy),
      startWith(null),
      debounceTime(0),
      map(x => {
        const value = x?.value;
        if ( ! value) return null;
        try {
          return JSON.parse(value) as StoredColumns;
        } catch {
          return null
        }
      }),
      map(mimic => {
        if (mimic) {
          // order according the keys
          const order = Object.keys(mimic);
          const columns: Columns[C] = _(structuredClone(this.columns[this.collection]))
            .entries()
            .sortBy(([key, _]) => order.indexOf(key))
            .fromPairs()
            .value();

          // set enabled property
          Object.entries(mimic).forEach(([key, value]) => {
            const column = (columns as Record<string, TableColumnProperties>)[key] as TableColumnProperties | undefined;
            if (column) column.enabled = value;
          });

          return columns;
        } else {
          // return as is
          return this.columns[this.collection];
        }
      })
    )
    .subscribe(this._columns);
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  public store (columns: StoredColumns) {
    this.storage.set(storageKeys[this.collection], JSON.stringify(columns));
  }

  public get onChange (): Observable<Columns[C]> {
    return this._columns.pipe(filter(Boolean));
  }

  public get watch (): Observable<Columns[C] | null> {
    return this._columns
  }

  public get () { return this.columns[this.collection]; }
}