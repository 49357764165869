import { NgModule                        } from '@angular/core';
import { CommonModule                    } from '@angular/common';
import { ReactiveFormsModule,
         FormsModule as AngFormsModule   } from '@angular/forms';
import { ScrollingModule                 } from '@angular/cdk/scrolling';

import { NgxCleaveDirectiveModule        } from 'ngx-cleave-directive';

import { TranslationModule               } from 'app/core/translate/translate.module';

import { AppCommonModule                 } from 'app/common/common.module';

import { FormFieldsModule                } from 'app/shared/form-fields/form-fields.module';
import { ComponentsModule                } from 'app/shared/components/components.module';

import { CompanyComponent                } from './company/company.component';

import { ChangePasswordFormComponent     } from './change-password-form/change-password-form.component';

import { CourseComponent                 } from './course/course.component';
import { EventComponent                  } from './event/event.component';
import { DivisionComponent               } from './division/division.component';
import { GroupsComponent                 } from './groups/groups.component';
import { LocationsComponent              } from './locations/locations.component';
import { PersonsComponent                } from './persons/persons.component';
import { TeachersComponent               } from './teachers/teachers.component';
import { UsersModule                     } from './users/users.module';

import { PipesModule                     } from '../pipes/pipes.module';

@NgModule({
  declarations: [
    ChangePasswordFormComponent,
    CompanyComponent,
    CourseComponent,
    EventComponent,
    DivisionComponent,
    GroupsComponent,
    LocationsComponent,
    PersonsComponent,
    TeachersComponent,
  ],
  exports: [
    ChangePasswordFormComponent,
    CompanyComponent,
    CourseComponent,
    EventComponent,
    DivisionComponent,
    GroupsComponent,
    LocationsComponent,
    PersonsComponent,
    TeachersComponent,
    UsersModule,
  ],
  imports: [
    CommonModule,
    AngFormsModule,
    ReactiveFormsModule,
    ComponentsModule,
    FormFieldsModule,
    AppCommonModule,
    TranslationModule,
    NgxCleaveDirectiveModule,
    PipesModule,
    ScrollingModule
  ]
})
export class FormsModule { }
