<h1 mat-dialog-title>
  <h1 class="royal-font-highlight">
    {{ 'common.custom_search' | translate }}
  </h1>
  <mat-icon *ngIf="onNone | async" class="warning" [matTooltip]="'tables.components.custom-search.empty' | translate">
    warning
  </mat-icon>
</h1>

<mat-dialog-content>
  <mat-selection-list [formControl]="ctrl">
    <mat-list-option checkboxPosition="before"
      *ngFor="let x of options | async; trackBy: trackByFn"
      [value]="x"
    >
      <span [innerHtml]="names[x] | translate | capitalizeFirst"></span>
    </mat-list-option>
  </mat-selection-list>
</mat-dialog-content>

<mat-dialog-actions>
  <mat-checkbox color="primary"
                class="toggle-all"
                [checked]="onAll | async"
                (change)="toggleAll($event)">
    {{ 'common.toggle_all' | translate }}
  </mat-checkbox>
  <span class="spacer"></span>
  <button mat-button mat-dialog-close type="button">{{ 'common.close' | translate }}</button>
</mat-dialog-actions>
