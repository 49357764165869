
import { OrganizationType } from "../../environment/environment.types";
import { LanguageConstant } from "../types";

type Key = `${OrganizationType}.${Extract<LanguageConstant, 'sv'>}`;


// a dictionary of expressions to be substituted per language and organization type
// (the symbol to be replaced are the template words in double square brackets)
// (note that the keys nor the values should not contain any double square brackets)
export const LANG_SV_SUBS: { [key in Key]?: Record<string, string> } = {
  'sports_facility.sv': {
    // ämne -> aktivitetstyp
    'aktivitetstyp':                 'förening',
    'en aktivitetstyp':              'en förening',
    'aktivitetstyper':               'föreningar',
    'aktivitetstyperna':             'föreningarna',
    'aktiviteter med aktivitetstyp': 'lag som tillhör en förening',
    // ------------------------
    // elev -> person
    'person':                    'deltagare',
    'en person':                 'en deltagare',
    'personen':                  'deltagaren',
    'personens':                 'deltagarens',
    'personer':                  'deltagare',
    'personerna':                'deltagarna',
    // ------------------------
    'grupp':                   'grupp',
    'en grupp':                'en grupp',
    'den grupp':               'den grupp',
    'gruppen':                 'gruppen',
    'gruppens':                'gruppens',
    'grupper':                 'grupper',
    'grupperna':               'grupperna',
    // ------------------------
    // kurs -> aktivitet
    'aktivitet':                    'lag',
    'en aktivitet':                 'ett lag',
    'vilken aktivitet':             'vilket lag',
    'någon aktivitet':              'något lag',
    'aktiviteten':                  'laget',
    'aktivitetens':                 'lagets',
    'aktiviteter':                  'lag',
    'aktiviteterna':                'lagen',
    'aktivitetsnamn':               'lagnamn',
    'aktivitetens aktivitetstyp':   'lagtyp',
    'en parallell aktivitet':       'ett parallellt lag',
    'parallella aktiviteter':       'parallella lag',
    'parallella aktiviteterna':     'parallella lagen',
    'aktivitetsunderlag':           'lag',
    'aktivitetstid':                'bokningstid',
    'aktivitetstiden':              'bokningstiden',
    // ------------------------
    // lärare -> anställda
    'anställd':                  'coach',
    'en anställd':               'en coach',
    'den anställda':             'coachen',
    'anställda':                 'coacher',
    'den anställdas':            'coachens',
    'de anställda':              'coacherna',
    // ------------------------
    // låst tid -> förbjuden tid
    'förbjuden tid':           'förbjuden tid',
    'förbjudna tider':         'förbjudna tider',
    // ------------------------
    // lektion -> schemahändelse
    'schemahändelse':                             'bokning',
    'en schemahändelse':                          'en bokning',
    'en eller flera schemahändelser':             'en eller flera bokningar',
    'schemahändelser':                            'bokningar',
    'schemahändelse(r)':                          'bokning(ar)',
    'schemahändelsen':                            'bokningen',
    'schemahändelsens':                           'bokningens',
    'schemahändelserna':                          'bokningarna',
    'schemahändelsernas':                         'bokningarnas',
    'ihopkopplad schemahändelse':                 'ihopkopplad bokning',
    'en ihopkopplad schemahändelse':              'en ihopkopplad bokning',
    'ihopkopplade schemahändelser':               'ihopkopplade bokningar',
    'schemahändelse&shy;inställning':             'boknings&shy;inställning',
    'gruppens schemahändelser':                   'gruppens bokningar',
    'personens schemahändelser':                  'deltagarens bokningar',
    'den anställdas schemahändelser':             'coachens bokningar',
    'aktivitetens schemahändelser':               'lagets bokningar',
    'en aktivitets schemahändelser':              'ett lags bokningar',
    'parallella aktiviteters schemahändelser':    'parallella lags bokningar',
    'en eller flera tillhörande schemahändelser': 'en eller flera tillhörande bokningar',
    // ------------------------
    // lektionslängd -> längd på schemahändelse
    'längd på schemahändelse':                   'bokningslängd',
    'längd på schemahändelser':                  'bokningslängder',
    'längd på schemahändelsen':                  'bokningslängden',
    'den faktiska längden på en schemahändelse': 'den faktiska längden på en bokning',
    'den faktiska längden på schemahändelsen':   'den faktiska längden på bokningen',
    'längder på schemahändelser':                'bokningslängder',
    'längd(er) på schemahändelse(r)':            'bokningslängd(er)',
    'längden på schemahändelser':                'bokningslängden',
    // ------------------------
    // rast -> uppehåll
    'uppehåll':                                   'uppehåll',
    'kortast tillåtna uppehåll':                  'kortast tillåtna uppehåll',
    'det kortaste tillåtna uppehållet':           'det kortaste tillåtna uppehållet',
    'tidsmarginal':                               'tidsmarginal',
    'kortast tillåtna tidsmarginal':              'kortast tillåtna tidsmarginal',
    'den kortaste tillåtna tidsmarginalen':       'den kortaste tillåtna tidsmarginalen',
    'kortast tillåtna uppehåll och tidsmarginal': 'Kortast tillåtna uppehåll och tidsmarginal',
    // ------------------------
    'lunch':                   'lunch',
    'en lunch':                'en lunch',
    'luncher':                 'luncher',
    'lunchen':                 'lunchen',
    'luncherna':               'luncherna',
    'lunchraster':             'lunchraster',
    'lunchrasterna':           'lunchrasterna',
    // ------------------------
    // sal -> lokal
    'lokal':                     'anläggning',
    'en lokal':                  'en anläggning',
    'ingen lokal':               'ingen anläggning',
    'lokalen':                   'anläggningen',
    'lokaler':                   'anläggningar',
    'lokalerna':                 'anläggningarna',
    'grupp av lokaler':          'grupp av anläggningar',
    'rang på lokal':             'rang på anläggning',
    // ------------------------
    'delta på':                  'delta på',
    // ------------------------
    'den schemalagda tiden':     'den schemalagda tiden',
    'dess schemalagda tid':      'dess schemalagda tid',
    'garanterad schemalagd tid': 'garanterad schemalagd tid',
    'schemalagd&shy;tid':         'schemalagd&shy;tid',
    // ------------------------
    'period 1 eller period 2':           'sommarsäsong eller vintersäsong',
    'såsom språkval, slöjd, iv m.m.' :   '',
    'vara schemalagda samtidigt':        'vara schemalagda samtidigt',
    'anställd, person, grupp, och lokal': 'anläggning',
    'grupper, anställda och lokaler':     'anläggningar',
  },
  'school.sv': {
    // ämne -> aktivitetstyp
    'aktivitetstyp':                 'ämne',
    'en aktivitetstyp':              'ett ämne',
    'aktivitetstyper':               'ämnen',
    'aktivitetstyperna':             'ämnena',
    'aktiviteter med aktivitetstyp': 'kurser med ämne',
    // ------------------------
    // elev -> person
    'person':                    'elev',
    'en person':                 'en elev',
    'personen':                  'eleven',
    'personens':                 'elevens',
    'personer':                  'elever',
    'personerna':                'eleverna',
    // ------------------------
    'grupp':                   'grupp',
    'en grupp':                'en grupp',
    'den grupp':               'den grupp',
    'gruppen':                 'gruppen',
    'gruppens':                'gruppens',
    'grupper':                 'grupper',
    'grupperna':               'grupperna',
    // ------------------------
    // kurs -> aktivitet
    'aktivitet':                    'kurs',
    'en aktivitet':                 'en kurs',
    'vilken aktivitet':             'vilken kurs',
    'någon aktivitet':              'någon kurs',
    'aktiviteten':                  'kursen',
    'aktivitetens':                 'kursens',
    'aktiviteter':                  'kurser',
    'aktiviteterna':                'kurserna',
    'aktivitetsnamn':               'kursnamn',
    'aktivitetens aktivitetstyp':   'kursens ämne',
    'en parallell aktivitet':       'en parallell kurs',
    'parallella aktiviteter':       'parallella kurser',
    'parallella aktiviteterna':     'parallella kurserna',
    'aktivitetsunderlag':           'kursunderlag',
    'aktivitetstid':                'lektionstid',
    'aktivitetstiden':              'lektionstiden',
    // ------------------------
    // lärare -> anställda
    'anställd':                  'lärare',
    'en anställd':               'en lärare',
    'den anställda':             'läraren',
    'anställda':                 'lärare',
    'den anställdas':            'lärarens',
    'de anställda':              'lärarna',
    // ------------------------
    // låst tid -> förbjuden tid
    'förbjuden tid':           'förbjuden tid',
    'förbjudna tider':         'förbjudna tider',
    // ------------------------
    // lektion -> schemahändelse
    'schemahändelse':                             'lektion',
    'en schemahändelse':                          'en lektion',
    'en eller flera schemahändelser':             'en eller flera lektioner',
    'schemahändelser':                            'lektioner',
    'schemahändelse(r)':                          'lektion(er)',
    'schemahändelsen':                            'lektionen',
    'schemahändelsens':                           'lektionens',
    'schemahändelserna':                          'lektionerna',
    'schemahändelsernas':                         'lektionernas',
    'ihopkopplad schemahändelse':                 'ihopkopplad lektion',
    'en ihopkopplad schemahändelse':              'en ihopkopplad lektion',
    'ihopkopplade schemahändelser':               'ihopkopplade lektioner',
    'schemahändelse&shy;inställning':             'lektions&shy;inställning',
    'gruppens schemahändelser':                   'gruppens lektioner',
    'personens schemahändelser':                  'elevens lektioner',
    'den anställdas schemahändelser':             'lärarens lektioner',
    'aktivitetens schemahändelser':               'kursens lektioner',
    'en aktivitets schemahändelser':              'en kurs lektioner',
    'parallella aktiviteters schemahändelser':    'parallella kursers lektioner',
    'en eller flera tillhörande schemahändelser': 'en eller flera tillhörande lektioner',
    // ------------------------
    // lektionslängd -> längd på schemahändelse
    'längd på schemahändelse':                   'lektionslängd',
    'längd på schemahändelser':                  'lektionslängder',
    'längd på schemahändelsen':                  'lektionslängden',
    'den faktiska längden på en schemahändelse': 'den faktiska längden på en lektion',
    'den faktiska längden på schemahändelsen':   'den faktiska längden på lektionen',
    'längder på schemahändelser':                'lektionslängder',
    'längd(er) på schemahändelse(r)':            'lektionslängd(er)',
    'längden på schemahändelser':                'lektionslängden',
    // ------------------------
    // rast -> uppehåll
    'uppehåll':                                   'rast',
    'kortast tillåtna uppehåll':                  'kortast tillåtna rast',
    'det kortaste tillåtna uppehållet':           'den kortaste tillåtna rasten',
    'tidsmarginal':                               'tidsmarginal',
    'kortast tillåtna tidsmarginal':              'kortast tillåtna tidsmarginal',
    'den kortaste tillåtna tidsmarginalen':       'den kortaste tillåtna tidsmarginalen',
    'kortast tillåtna uppehåll och tidsmarginal': 'Kortast tillåtna rast och tidsmarginal',
    // ------------------------
    'lunch':                   'lunch',
    'en lunch':                'en lunch',
    'luncher':                 'luncher',
    'lunchen':                 'lunchen',
    'luncherna':               'luncherna',
    'lunchraster':             'lunchraster',
    'lunchrasterna':           'lunchrasterna',
    // ------------------------
    // sal -> lokal
    'lokal':                     'sal',
    'en lokal':                  'en sal',
    'ingen lokal':               'ingen sal',
    'lokalen':                   'salen',
    'lokaler':                   'salar',
    'lokalerna':                 'salarna',
    'grupp av lokaler':          'grupp av salar',
    'rang på lokal':             'rang på sal',
    // ------------------------
    'delta på':                  'delta på',
    // ------------------------
    'den schemalagda tiden':     'den schemalagda tiden',
    'dess schemalagda tid':      'dess schemalagda tid',
    'garanterad schemalagd tid': 'garanterad schemalagd tid',
    'schemalagd&shy;tid':         'schemalagd&shy;tid',
    // ------------------------
    'period 1 eller period 2':           'HT eller VT',
    'såsom språkval, slöjd, iv m.m.' :   'Såsom språkval, slöjd, IV m.m.',
    'vara schemalagda samtidigt':        'vara schemalagda samtidigt',
    'anställd, person, grupp, och lokal': 'lärare, elev, grupp, och sal',
    'grupper, anställda och lokaler':     'grupper, lärare och salar',
  }
};