import _                                   from 'lodash';
import { Util                            } from '@app/common';

import { SourceCore                      } from '../source.core';

import { Options,
         SourceData                      } from '../source.interface';

import { SerializerService               } from './serializer';

export function set(
  this:     SourceCore,
  _options: Omit<Options.Default, 'onDestroy'>,
  update:   Partial<SourceData> | Partial<SourceData>[]
): void {
  const { collection } = _options;
  if (! Util.TypeGuards.isCollection(collection))
    return;
  const value = SerializerService.use(update, collection);
  // Notify user if serialize failed
  if (value === null) {
    this._snackbar.open(this._translate.instant('source.validation_fail'), '', { duration: 3000, panelClass: 'info-snackbar' });
    return;
  };
  // stringify with JSON so that all properties are serializable (e.g. Date and other native objects)
  try {
    this._systemCore.set(_.pick(_options, ['did', 'collection']), value);
  } catch(err) {
    this._logger.error(err);
  }
}