function computeExecutionTime(originalMethod: Function, identifier: string) {
  return function (...args: any[]) {
    const start = performance.now();

    const result = originalMethod.apply(this, args);

    const stop = performance.now();

    console.log(identifier, `execution time: ${ (stop - start).toFixed(2) }ms`);

    return result;
  }
}

export function Performance(identifier: string) {
  return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
    const { set, get } = descriptor;
    if (set)
      descriptor.set = computeExecutionTime(set, identifier);
    else
      descriptor.value = computeExecutionTime(descriptor.value, identifier);

    return descriptor;
  }
}