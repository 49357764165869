<app-loading [loading]="loading | async"></app-loading>

<div class="message-container" *ngIf="error | async; else content">
  <img src="assets/images/svg/warning.svg">
  <span>{{ 'shared.dialogs.upload-file.finalize.error' | translate }}</span>
</div>

<ng-template #content>
  <mat-list>
    <mat-list-item>
      <span matListItemTitle>{{ (_division | async)?.displayName }}</span>
      <span matListItemLine>{{ (_division | async)?.start | toDate | date:'YYYY-MM-dd' }} - {{ (_division | async)?.end | toDate | date:'YYYY-MM-dd' }}</span>
    </mat-list-item>
  </mat-list>

  <section class="errors-and-warnings" *ngIf="(warnings | async | length) || (errors | async | length)">
    <mat-divider></mat-divider>
    <div mat-subheader>{{ 'shared.dialogs.upload-file.finalize.errors_and_warnings' | translate }}</div>

    <!-- fixes an issue with initially opened panels -->
    <mat-accordion>
      <mat-expansion-panel *ngIf="warnings | async | length; let numWarnings">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ numWarnings }} {{ (numWarnings == 1 ? 'common.warning' : 'common.warnings') | translate | lowercase }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ul>
          <li *ngFor="let warning of warnings | async">{{ warning }}</li>
        </ul>
      </mat-expansion-panel>
      <mat-expansion-panel *ngIf="errors | async | length; let numErrors">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ numErrors }} {{ (numErrors == 1 ? 'common.error' : 'common.errors') | translate | lowercase }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ul>
          <li *ngFor="let error of errors | async">{{ error }}</li>
        </ul>
      </mat-expansion-panel>
    </mat-accordion>
  </section>

  <section class="collections" *ngIf="mapped | async">
    <mat-divider></mat-divider>
    <div mat-subheader>{{ 'shared.dialogs.upload-file.finalize.content' | translate }}</div>

    <mat-selection-list [formControl]="ctrl">
      <ng-container *ngFor="let x of collections">
        <mat-list-option
          *ngVar="lengthOf(mapped | async, x.collection) as length"
          [value]="x.collection"
          [disabled]="! length"
        >
          <mat-icon matListItemIcon>{{ x.collection | collectionIcon }}</mat-icon>
          <div matListItemTitle>
            {{ x.collection != 'settings' ? length : '' }}
            {{ 'common.' + (length == 1 ? x.base : x.collection ) | translate }}
          </div>
        </mat-list-option>
      </ng-container>
    </mat-selection-list>
  </section>
</ng-template>