type Instantiable<T = any> = new (...args: any[]) => T;

export class ShallowMap<M, N> extends Map<M, N> {

  public init(sub: M, value: N): void {
    if (! this.has(sub))
      this.set(sub, value);
  }

  public lazyInit(sub: M, constructor: Instantiable): void {
    if (! this.has(sub))
      this.set(sub, new constructor());
  }

  public getExtended(sub: M, constructor: Instantiable): N | undefined {
    this.lazyInit(sub, constructor);
    return this.get(sub);
  }
}

type CallbackFn<T, M, N> = (val: ShallowMap<M, N>, key: T) => void;

export class DeepMap<T, M, N> {
  private _map: Map<T, ShallowMap<M, N> | N> = new Map<T, ShallowMap<M, N> | N>();

  public set(top: T, sub: M | undefined, value: N): void {
    if (! this._map.has(top) || ! (this._map.get(top) instanceof ShallowMap))
      this._map.set(top, new ShallowMap<M, N>());
    if (sub === undefined)
      this._map.set(top, value);
    else
      (this._map.get(top) as Map<M, N>).set(sub, value);
  }

  public init(top: T, sub: M | undefined, value: N): void {
    if (! this.has(top, sub))
      this.set(top, sub, value);
  }

  public lazyInit(top: T, sub: M | undefined, constructor: Instantiable): void {
    if (! this.has(top, sub))
      this.set(top, sub, new constructor());
  }

  public get(top: T, sub: M): N | undefined {
    if (! this._map.get(top))
      return undefined;

    return (this._map.get(top) as ShallowMap<M, N>).get(sub) as N;
  }

  public getShallow(top: T): ShallowMap<M, N> | undefined {
    if (! this._map.get(top))
      return undefined;

    return this._map.get(top) as ShallowMap<M, N>;
  }

  public has(top: T, sub?: M): boolean {
    if (! this._map.has(top))
      return false;

    if (sub === undefined)
      return this._map.has(top);

    if (! (this._map.get(top) instanceof ShallowMap))
      return false;

    return (this._map.get(top) as ShallowMap<M, N>).has(sub);
  }

  public size(top?: T): number {
    if (top !== undefined)
      return (this._map.get(top) as ShallowMap<M, N>)?.size ?? 0;
    return this._map.size;
  }

  public delete(top: T, sub?: M): void {
    if (! this._map.get(top))
      return;

    if (sub === undefined)
      this._map.delete(top);
    else {
      (this._map.get(top) as Map<M, N>).delete(sub!);
      if (! (this._map.get(top) as ShallowMap<M, N>)?.size) {
        this._map.delete(top);
      }
    }
  }

  public forEach(callback: CallbackFn<T, M, N>): void {
    this._map.forEach((v, k) => v instanceof ShallowMap && callback(v, k));
  }

  public every(top: T, callback: (val?: N, key?: M) => void): void {
    if (! this._map.get(top)) return callback();

    (this._map.get(top) as ShallowMap<M, N>)!.forEach(callback);
  }
}
