<app-loading [loading]="loading | async"></app-loading>

<div class="message-container" *ngIf="error | async; else emptyTmpl">
  <img src="assets/images/svg/warning.svg">
  <span>{{ 'shared.dialogs.upload-file.select-map.error' | translate }}</span>
</div>

<ng-template #emptyTmpl>
  <div class="message-container" *ngIf="maps | async | negate; else list">
    <img src="assets/images/svg/warning.svg">
    <span>{{ 'shared.dialogs.upload-file.select-map.unknown' | translate }}</span>
  </div>
</ng-template>

<ng-template #list>
  <mat-selection-list [multiple]="false" [formControl]="ctrl">

    <!-- determined map -->
    <ng-container *ngIf="(maps | async)?.determined; let map; else alternatives">
      <div mat-subheader>
        {{ 'shared.dialogs.upload-file.select-map.determined' | translate }}
      </div>
      <mat-list-option [value]="map">
        <mat-icon matListItemIcon class="royal-icon">verified</mat-icon>
        <div matListItemTitle>{{ map.name }}</div>
      </mat-list-option>
    </ng-container>

    <ng-template #alternatives>
      <!-- strong similarity -->
      <ng-container *ngIf="(maps | async)?.strong?.length">
        <div mat-subheader>
          {{ 'shared.dialogs.upload-file.select-map.strong' | translate }}
        </div>
        <mat-list-option *ngFor="let map of (maps | async)?.strong" [value]="map">
          <div matListItemTitle>{{ map.name }}</div>
        </mat-list-option>
      </ng-container>

      <!-- weak similarity -->
      <ng-container *ngIf="(maps | async)?.weak?.length">
        <div mat-subheader>
          {{ 'shared.dialogs.upload-file.select-map.weak' | translate }}
        </div>
        <mat-list-option *ngFor="let map of (maps | async)?.weak" [value]="map">
          <div matListItemTitle>{{ map.name }}</div>
        </mat-list-option>
      </ng-container>
    </ng-template>

  </mat-selection-list>
</ng-template>


