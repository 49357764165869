import { NgModule                        } from '@angular/core';
import { CommonModule                    } from '@angular/common';

import { TranslationModule               } from 'app/core/translate/translate.module';
import { AppCommonModule                 } from 'app/common/common.module';
import { UnrecoverableAppStateComponent  } from './unrecoverable-app-state.component';


@NgModule({
  declarations: [
    UnrecoverableAppStateComponent
  ],
  imports: [
    CommonModule,
    TranslationModule,
    AppCommonModule
  ]
})
export class UnrecoverableAppStateModule { }
