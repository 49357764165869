import { Inject,
         Injectable,
         InjectionToken,
         OnInit,                         } from '@angular/core';
import { takeUntilDestroyed              } from '@angular/core/rxjs-interop';
import { FormControl,
         NgControl,
         ValidatorFn,
         Validators                      } from '@angular/forms';
import { BehaviorSubject,
         combineLatest,
         filter,
         map,
         of,
         pairwise,
         startWith                       } from 'rxjs';


// A primitive form field component, i.e., a parent form control maps to a single child control
//
// The value of the parent form control is sent to the child form control via the value accessor.
// The parent form control does not know about the child form control, but the child form control
// knows about the parent form control.
//
// ┌─────────────────────┐   Value Accessor   ┌────────────────────┐
// │ parent form control │  --------------->  │ child form control │
// └─────────────────────┘                    └────────────────────┘
//
// This base class provides the following functionality:
// - composite validators live in the parent form control and updates the error states of relevant child form controls.
// - the required validator of the parent form control is propagated to the child form control.
// - all validators of the child form control are propagated to the parent form control.
//

// needed as an empty @Inject() is not permitted and we must inject as otherwise:
// > error NG2003: No suitable injection token for parameter 'validators' of class 'PrimitiveCore'.
const VALIDATORS_TOKEN = new InjectionToken<ValidatorFn[]>('ValidatorsToken');

@Injectable()
export class PrimitiveCore<T> implements OnInit {

  protected parentHasRequiredValidator$ = new BehaviorSubject(false);

  protected validators$ = new BehaviorSubject<ValidatorFn[]>([]);

  constructor (
    protected ngControl: NgControl | null,
    @Inject(VALIDATORS_TOKEN)
    validators: ValidatorFn[]
  ) {

    combineLatest([
      of(validators),
      this.parentHasRequiredValidator$.pipe(map(x => x ? [Validators.required] : null)),
      this.addedValidators$.pipe(map(x => Object.values(x))),
    ])
    .pipe(
      takeUntilDestroyed(),
      map(x => x.flat().filter(Boolean))
    )
    .subscribe(this.validators$);

    // update the validators when the min/max validators change
    combineLatest({
      control:    this.control$.pipe(filter(Boolean)),
      validators: this.validators$.pipe(startWith([]), pairwise()),
    })
    .pipe(takeUntilDestroyed())
    .subscribe(({ control, validators: [ prev, next ] }) => {

      // update the validators of the form control
      control.removeValidators([...prev]);
      control.setValidators   ([...next]);

      // update the validators of the ngControl too
      this.ngControl?.control?.removeValidators([...prev]);
      this.ngControl?.control?.setValidators   ([...next]);

      // update the validity of the form control
      control                 .updateValueAndValidity({ emitEvent: false });
      this.ngControl?.control?.updateValueAndValidity({ emitEvent: false });
    });

  }


  ngOnInit(): void {
    if (this.ngControl?.control?.hasValidator(Validators.required)) {
      this.parentHasRequiredValidator$.next(true);
    }
  }


  /** @description the id allows previous validators with the same id to be overwritten. to remove a validator provide null */
  protected addValidatorById (id: string, fn: ValidatorFn | null): void {
    const obj = this.addedValidators$.value;
    if (fn == null) delete obj[id];
    else            obj[id] = fn;
    this.addedValidators$.next(obj);
  }
  private addedValidators$ = new BehaviorSubject<Record<string, ValidatorFn>>({ });

  /** @description forwards the form control to the base class */
  protected forwardControl (value: FormControl) { this.control$.next(value); }
  private control$ = new BehaviorSubject<FormControl | null>(null);

  // Need to include the parent form control's error state in case of composite validators that live in the parent form control
  // For Mat Errors to become visible the Mat For Field Control needs to be in an error
  get errorState(): boolean { return (this.control$.value ? this.control$.value.invalid : true) || !! this.ngControl?.control?.invalid }

}
