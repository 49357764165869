import { NgModule                        } from '@angular/core';
import { CommonModule                    } from '@angular/common';
import { FormsModule,
         ReactiveFormsModule             } from '@angular/forms';

import { AppCommonModule                 } from 'app/common/common.module';
import { TranslationModule               } from 'app/core/translate/translate.module';
import { EditColumnsModule               } from './edit-columns/edit-columns.module';
import { ColumnHeaderModule              } from './column-header/column-header.module';
import { CreateModule                    } from './create/create.module';
import { CreateCoursesModule             } from './create-courses/create-courses.module';
import { CustomSearchModule              } from './custom-search/custom-search.module';
import { NoColumnsModule                 } from './no-columns/no-columns.module';

@NgModule({
  declarations: [
  ],
  exports: [
    ColumnHeaderModule,
    EditColumnsModule,
    CreateModule,
    CreateCoursesModule,
    CustomSearchModule,
    NoColumnsModule,
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    TranslationModule,
    FormsModule,
    ReactiveFormsModule,
  ]
})
export class ComponentsModule { }
