import { Component,
         Input,
         ElementRef,
         OnDestroy,
         Optional,
         Self,
         HostBinding,
         EventEmitter,
         Output,
         ViewEncapsulation,
         ChangeDetectionStrategy,
         ViewChild,
         OnInit                          } from '@angular/core';
import { ControlValueAccessor,
         NgControl,
         FormControl                     } from '@angular/forms';
import { MatMenuTrigger                  } from '@angular/material/menu';
import { MatFormFieldControl             } from '@angular/material/form-field';
import { FocusMonitor                    } from '@angular/cdk/a11y';
import { coerceBooleanProperty           } from '@angular/cdk/coercion';
import { takeUntilDestroyed              } from '@angular/core/rxjs-interop';
import { animationFrameScheduler,
         BehaviorSubject,
         Subject                         } from 'rxjs';
import { debounceTime,
         takeUntil                       } from 'rxjs/operators';
import { CleaveOptions                   } from 'cleave.js/options';
import moment                              from 'moment';
import $                                   from 'jquery';

import { DateService                     } from 'app/shared/services';
import { ExtendedValidators,
         invalidTimeKey                  } from 'app/shared/forms/validators';
import { MatList                         } from 'app/common';
import { PrimitiveCore                   } from '../primitive-core';

type Time = {
  value:     string;
  invalid?:  boolean;
  selected?: boolean;
}

type Type = moment.Moment | string;

const validators = [ ExtendedValidators.isTime ];

@Component({
  selector: 'app-form-field-time',
  templateUrl: './time.component.html',
  styleUrls: ['./time.component.scss'],
  providers: [
    {
      provide:     MatFormFieldControl,
      useExisting: TimeComponent
    }
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimeComponent
  extends PrimitiveCore<Type>
  implements OnInit, OnDestroy, ControlValueAccessor, MatFormFieldControl<Type>
{
  public  readonly stateChanges = new Subject<void>();
  private readonly onDestroy    = new Subject<void>();

  @ViewChild(MatMenuTrigger) trigger?: MatMenuTrigger;
  @ViewChild('hoursList',   { read: ElementRef }) hoursListRef?:   ElementRef<MatList>;
  @ViewChild('minutesList', { read: ElementRef }) minutesListRef?: ElementRef<MatList>;

  @Output('onChange') changeEmitter = new EventEmitter<Type>();
  @Output('onClose')  closeEmitter  = new EventEmitter<Type>();

  public placeholderText: string  = '';
  static nextId:          number  = 0;
  public focused:         boolean = false;
  public controlType:     string  = 'time-input';
  public id:              string  = `time-input-${ TimeComponent.nextId++ }`;
  public describedBy:     string  = '';
  public isVoid:          boolean = true;
  public onChange  = (_: any) => {};
  public onTouched = () => {};

  protected readonly timeStrControl = new FormControl<string>(moment.utc().format('HH:mm'), { nonNullable: true });

  public hours:   Time[]= [];
  public minutes: Time[]= [];
  public cleave: CleaveOptions = {
    time:        true,
    timePattern: ['h', 'm'],
    onValueChanged: (x: { target: { value: string }}) => {
      // update the form control if the values are not the same in order to rerun the validators
      // (otherwise the last checked value may be the one before cleave.js has updated the value, like 12:555 or 122:55)
      if (x.target.value != this.timeStrControl.value)
        this.timeStrControl.setValue(x.target.value);
    }
  };


  @HostBinding('attr.tabindex') __tabindex = 0;

  constructor (
    @Optional() @Self() public ngControl: NgControl | null,
    private _focusMonitor: FocusMonitor,
    private _elementRef:   ElementRef<HTMLElement>
  ) {
    super(ngControl, validators);

    this.forwardControl(this.timeStrControl);

    this.parentHasRequiredValidator$
    .pipe(takeUntilDestroyed())
    .subscribe(x => this.required = x);



    this._setValues();

    _focusMonitor.monitor(_elementRef, true)
    .subscribe((origin: any) => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    // from text to moment
    this.timeStrControl.valueChanges
    .pipe(
      takeUntil(this.onDestroy)
    )
    .subscribe(x => {
      this._value.next(DateService.fromTimeString(x, this._day));
      this._handleOverflow();
      this._handleInput();
      this.stateChanges.next();

      // mark as pristine if the value is the same as the pristine value
      this._value.value.isSame(this._pristineValue)
        ? this.ngControl?.control?.markAsPristine()
        : this.ngControl?.control?.markAsDirty();
    });
  }

  ngOnInit(): void {
    super.ngOnInit();

    // listen to the status changes of the ngControl to make the error visible immediately without touching the input
    this.ngControl?.control?.statusChanges
    .pipe(
      takeUntil(this.onDestroy),
      debounceTime(0)   // to avoid "ExpressionChangedAfterItHasBeenCheckedError"
    )
    .subscribe(() => {
      // mark as touched to immediately show the error
      this.ngControl?.control?.markAsTouched();

      // we also need to emit a state change to the form field
      this.stateChanges.next();
    });
  }

  public setTime (time: string): void {
    this._value.next(DateService.fromTimeString(time, this._day));
    this._handleOverflow();
    this._handleInput();
    if (this.timeStrControl.dirty) this.changeEmitter.next(this.value);
    this.timeStrControl.markAsDirty();
    this.stateChanges.next();
  }

  private afterSet () {
    this._handleInput();
    this.timeStrControl.setValue(this._value.value.format('HH:mm'), { emitEvent: false });
    this.changeEmitter.next(this.value);
    this.stateChanges.next();

      // mark as pristine if the value is the same as the pristine value
    this._value.value.isSame(this._pristineValue)
      ? this.ngControl?.control?.markAsPristine()
      : this.ngControl?.control?.markAsDirty();
  }

  public setHours(hour: string): void {
    const val = this._value.value.clone();
    val.set({ hour: parseInt(hour) });
    this._value.next(val);
    this._handleOverflow();

    this.afterSet();
  }

  public setMinutes(minute: string): void {
    const val = this._value.value.clone();
    val.set({ minute: parseInt(minute) });
    this._value.next(val);

    this.afterSet();
  }

  public closed(): void {
    // if the value is invalid, revert to the previous value
    if (this.timeStrControl.errors?.[invalidTimeKey]) {
      this._value.next(this._pristineValue);
      this.timeStrControl.setValue(this._pristineValue.format('HH:mm'), { emitEvent: false });
    }

    this.afterSet();
    this.isVoid = true;
  }

  public opened() {
    this.isVoid = false;
    animationFrameScheduler.schedule(() => {
      const elem = $(`#input`)[0];
      elem?.focus();
      this._scrollTo();
    });
  }

  public open () {
    this.trigger?.openMenu();
  }

  private _scrollTo() {
    [this.hoursListRef, this.minutesListRef]
    .filter(Boolean)
    .map(x => $(x.nativeElement))
    .forEach(list => {
      // find the selected element
      const selected = list.find('.selected');
      if ( ! selected.length) return;

      // try scroll such that it is centered
      let dy = selected.index() * selected.height()!
             - 0.5 * (list.height()! - selected.height()!);
      list.scrollTop(dy);
    });
  }

  private _isBefore(a: moment.Moment, b: moment.Moment): boolean {
    return (a.hours() * 60 + a.minutes()) <= (b.hours() * 60 + b.minutes());
  }

  private _isAfter(a: moment.Moment, b: moment.Moment): boolean {
    return (a.hours() * 60 + a.minutes()) >= (b.hours() * 60 + b.minutes());
  }

  private _setValues(): void {
    this.hours = [...Array(24).keys()].map((x: number) => { return { value: String(x).padStart(2, '0') } });
    this.minutes = [...Array(60 / this.discretization).keys()].map((x: number) => { return { value: String(x * this.discretization).padStart(2, '0') } });
  }

  private _handleOverflow(): void {
    if (this.min) {
      const min = moment.utc(this.min, 'HH:mm');
      if (this._isBefore(this._value.value, min)) {
        const val = this._value.value.clone();
        val.set({ minute: parseInt(min.clone().add(this.discretization, 'm').format('mm')) });
        this._value.next(val);
      }
    }

    if (this.max) {
      const max = moment.utc(this.max, 'HH:mm');
      if (this._isAfter(this._value.value, max)) {
        const val = this._value.value.clone();
        val.set({ minute: parseInt(max.clone().subtract(this.discretization, 'm').format('mm')) });
        this._value.next(val);
      }
    }
  }

  private _setValidity(): void {
    this._setHourValidity();
    this._setMinuteValidity();
  }

  private _setMinuteValidity(): void {
    this.minutes.forEach((minute: Time) => minute.invalid = false);
    if (this.min) {
      const min = moment.utc(this.min, 'HH:mm');
      if (this._value.value.hours() == min.hours())
        this.minutes.forEach((minute: Time) => minute.invalid || (minute.invalid = (parseInt(minute.value) <= min.minutes())));
    }
    if (this.max) {
      const max = moment.utc(this.max, 'HH:mm');
      if (this._value.value.hours() == max.hours())
        this.minutes.forEach((minute: Time) => minute.invalid || (minute.invalid = (parseInt(minute.value) >= max.minutes())));
    }
  }

  private _setHourValidity(): void {
    this.hours.forEach((hour: Time) => hour.invalid = false);
    if (this.min) {
      const min = moment.utc(this.min, 'HH:mm');
      this.hours.forEach((hour: Time) => hour.invalid || (hour.invalid = (parseInt(hour.value) < min.hours())));
    }
    if (this.max) {
      const max = moment.utc(this.max, 'HH:mm').subtract(this.discretization, 'm');
      this.hours.forEach((hour: Time) => hour.invalid || (hour.invalid = (parseInt(hour.value) > max.hours())));
    }
  }

  private _setSelected(): void {
    const _hour   = this._value.value.format('HH');
    const _minute = this._value.value.format('mm');

    this.hours  .forEach(x => x.selected = _hour   == x.value);
    this.minutes.forEach(x => x.selected = _minute == x.value);
  }

  get empty(): boolean { return ! this.value; }

  get displayValue(): string {
    return this._value.value.format('HH:mm');
  }

  get shouldLabelFloat() { return this.focused || ! this.empty; }

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get max(): string | undefined { return this._max; }
  set max(max: string | undefined) {

    if (max == '24:00') {
      console.warn('max="24:00" is invalid. Use max="23:59" instead.');
      max = '23:59';
    }

    this._max         = max;
    this.maxValidator = max;
    this._setValidity();
    this.stateChanges.next();
  }
  private _max: string | undefined = undefined;

  @Input()
  get min(): string | undefined { return this._min; }
  set min(min: string | undefined) {
    this._min         = min;
    this.minValidator = min;
    this._setValidity();
    this.stateChanges.next();
  }
  private _min: string | undefined = undefined;

  @Input()
  set minValidator (val: Type | null | undefined) { this.addValidatorById('min', val != null ? ExtendedValidators.minTime(val) : null) }


  @Input()
  set maxValidator (val: Type | null | undefined) { this.addValidatorById('max', val != null ? ExtendedValidators.maxTime(val) : null) }

  @Input()
  get discretization(): number { return this._discretization; }
  set discretization(value: number) {
    this._discretization = value;
    this._setValues();
    this.stateChanges.next();
  }
  private _discretization: number = 5;

  @Input()
  get value(): Type {
    return this._isString ? this._value.value.format('HH:mm') : this._value.value;
  }
  set value(_val: Type | undefined) {
    if ( ! _val) return;

    let val: moment.Moment;
    if (typeof _val == 'string') {
      this._isString = true;
      val = DateService.sanitizeDate(moment.utc(_val, 'HH:mm'), this._day)!;
    } else {
      this._isString = false;
      this._day = DateService.getDayIndex(_val);
      val = DateService.sanitizeDate(_val, this._day)!;
    }
    this._pristineValue = val.clone();

    this._value.next(val);
    this.timeStrControl.setValue(val.format('HH:mm'), { emitEvent: false });
    this.stateChanges.next();
    this._handleInput();
  }
  private _isString = false;
  private _day: number = 0;
  private _value = new BehaviorSubject(moment.utc());
  private _pristineValue: moment.Moment = moment.utc();

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent) {
    if (! this.disabled)
      this.trigger?.openMenu();
  }

  writeValue(val: moment.Moment): void {
    this.value = val;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  _handleInput(): void {
    this._setSelected();
    this._setValidity();
    this.onChange(this.value);
  }

  static ngAcceptInputType_disabled: boolean | string | null | undefined;
  static ngAcceptInputType_required: boolean | string | null | undefined;
}
