import { Injectable            } from '@angular/core';
import { Router,
         ActivatedRoute,
         Routes                } from '@angular/router';
import { Subject,
         Observable            } from 'rxjs';
import { first                 } from 'rxjs/operators';
import $                         from 'jquery';

import { Role                  } from 'app/constants';
import { publicRoutes,
         privateRoutes,
         adminRoutes,
         partnerRoutes,
         restrictedRoutes,
         administratorRoutes,
         voidRoutes            } from 'app/core/routing/routes';

@Injectable({
  providedIn: 'root'
})
export class RoutingService {
  private state:   number;
  private loaded:  boolean          = false;
  private _onLoad: Subject<boolean> = new Subject<boolean>();

  constructor (
    private router: Router,
    private route:  ActivatedRoute
  ) {
    $(window).on('load', (() => {
      if (this._activeRoute == undefined)
        this.setVoidRoutes();
      this.loaded = true;
      this.updateConfig();
      this._onLoad.next(true);
      this._onLoad.complete();
    }).bind(this));
  }

  public onLoad(): Observable<boolean> {
    return this._onLoad.asObservable().pipe(first())
  }

  public setVoidRoutes(): void {
    this.router.resetConfig(voidRoutes);
    this.updateConfig();
  }

  public setPublicRoutes(): void {
    if (publicRoutes == this._activeRoute)
      return;

    this._activeRoute = publicRoutes;
    this.router.resetConfig(publicRoutes);
    if (this.loaded)
      this.updateConfig();
  }

  public setPrivateRoutes (role: Role): void {

    let newRoutes: Routes;
    if      (role == 'admin')         newRoutes = adminRoutes;
    else if (role == 'partner')       newRoutes = partnerRoutes;
    else if (role == 'administrator') newRoutes = administratorRoutes;
    else if (role == 'unrestricted')  newRoutes = privateRoutes;
    else if (role == 'restricted')    newRoutes = restrictedRoutes;
    else                              newRoutes = voidRoutes;

    if (newRoutes == this._activeRoute) return;

    this._activeRoute = newRoutes;

    this.router.resetConfig(newRoutes);

    if (this.loaded)
      this.updateConfig();
  }
  private _activeRoute: Routes;

  private updateConfig(): void {
    this.router.navigate([window.location.pathname], {
      relativeTo: this.route,
      queryParamsHandling: "preserve"
    });
  }

  public setAdminRoutes(): void {
    if (this.state == 1)
      return;
    this.state = 1;
    this.router.resetConfig(adminRoutes);
    if (this.loaded)
      this.updateConfig();
  }
}