import { CommonModule                      } from '@angular/common';
import { Component,
         Inject                            } from '@angular/core';
import { HttpService                       } from '@app/core';
import { apiConstants                      } from '@app/constants';
import { Types                             } from '@app/shared/interfaces';
import { FormControl,
         FormGroup,
         ReactiveFormsModule               } from '@angular/forms';
import { AppCommonModule                   } from '@app/common/common.module';
import { TranslationModule                 } from '@app/core/translate/translate.module';

import { MAT_DIALOG_DATA,
         MatDialogRef                      } from 'app/common';

import { Form,
         Data                              } from './app-features.types';

export { Data };

@Component({
  templateUrl: './app-features.component.html',
  styleUrls: ['./app-features.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    AppCommonModule,
    ReactiveFormsModule,
    TranslationModule
  ]
})
export class AppFeaturesComponent {
  protected readonly form: FormGroup<Form>;

  constructor (
    private _http: HttpService,
    private _dialogRef: MatDialogRef<AppFeaturesComponent>,
    @Inject(MAT_DIALOG_DATA) private data: Data,
  ) {
    this.form = new FormGroup<Form>({
      substitutionPlanner: new FormControl<boolean>(data.organization?.environment?.appFeatures?.substitutionPlanner?.enabled ?? false, { nonNullable: true }),
    });
  }

  protected submit() {
    const body = Object.keys(this.form.value)
    .reduce((acc: NonNullable<Types.companies['environment']['appFeatures']>, key: keyof Form) => {
      acc[key] = { enabled: this.form.value[key]! };
      return acc;
    }, {});

    this._http.post(`${ apiConstants.ADMIN }/companies/${ this.data.organization.id }/environments/app_features`, body)
    .subscribe(() => {
      this._dialogRef.close();
    });
  }
}