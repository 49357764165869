<div class="buttons-wrapper hide-below-1280">

  <button *ngFor="let b of processedButtons | async"
          mat-icon-button color="primary"
          [matTooltip]="translatable(b.label) ? (b.label.translate | translate) : b.label"
          [ngClass]="b.classes"
          [disabled]="b.disabled"
          (click)="element && b.action(element)">
    <mat-icon class="royal-icon" [fontSet]="b.iconFontSet">{{ b.icon }}</mat-icon>
  </button>

</div>

<div class="hide-above-1280">
  <button mat-icon-button class="account-button" [matMenuTriggerFor]="menu">
    <mat-icon class="royal-icon">more_vert</mat-icon>
  </button>

  <mat-menu yPosition="below" #menu="matMenu" [overlapTrigger]="false">

    <button *ngFor="let b of processedButtons | async"
            mat-menu-item color="primary"
            [ngClass]="b.classes"
            [disabled]="b.disabled"
            (click)="element && b.action(element)">
      <mat-icon class="royal-icon" [fontSet]="b.iconFontSet">{{ b.icon }}</mat-icon>
      {{ translatable(b.label) ? (b.label.translate | translate) : b.label }}
    </button>
  </mat-menu>
</div>