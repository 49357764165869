import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from 'app/common';

@Component({
  selector: 'app-confirm-remove',
  templateUrl: './confirm-remove.component.html',
  styleUrls: ['./confirm-remove.component.scss']
})
export class ConfirmRemoveComponent implements OnInit {
  public confirmed:boolean = false;

  constructor(public dialogRef: MatDialogRef<ConfirmRemoveComponent>) { }

  ngOnInit() {
  }

  public delete() {
    this.dialogRef.close(true);
  }
}
