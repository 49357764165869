import { Pipe,
         PipeTransform                   } from '@angular/core';

import { Event                           } from 'app/shared/interfaces';
import { TranslateService,
         UserPreferencesService          } from 'app/core';
import { TableCore                       } from '../table-core';


@Pipe({
  name: 'combineCourseNames'
})
export class CombineCourseNamesPipe implements PipeTransform {

  constructor (
    private _translate:   TranslateService,
    private _preferences: UserPreferencesService
  ) {}

  transform(arr: Event.populated[] | null): string {
    if ( ! arr?.length) return '';

    // one or more courses selected
    if (TableCore.isSame(arr.map(x => x.course))) {
      return arr[0].course?.displayName ?? this._translate.instant('common.nameless')
        + (this._preferences.displayPublicId && arr[0].course?.ids ? ' (' + arr[0].course.ids + ')' : '');
    } else {
      return '-';
    }
  }
}


@Pipe({
  name: 'combineCourseSubjects'
})
export class CombineCourseSubjectsPipe implements PipeTransform {

  constructor (private _translate: TranslateService) {}

  transform(arr: Event.populated[] | null): string {
    if ( ! arr?.length) return '';

    // one or more courses selected
    if (TableCore.isSame(arr.map(x => x.course))) {
      return arr[0].course?.subject ?? '';
    } else {
      return '-';
    }
  }
}