<h1 mat-dialog-title>
  {{ title | translate }}
</h1>

<mat-dialog-content>
  <div class="grid" [style.--num-columns]="columns | async | length" [class.dense-3]="formArray.length > 3">
    <ng-container *ngFor="let fg of formArray.controls; let i = index" [formGroup]="fg">

      <mat-button-toggle-group *ngIf="columns | async | includes:'species'"
          formControlName="species"
          (paste)="paste('species', $event, i)">
        <mat-button-toggle value="class">{{ 'attributes.groups.species.class' | translate }}</mat-button-toggle>
        <mat-button-toggle [value]="null">{{ 'attributes.groups.species.none' | translate }}</mat-button-toggle>
      </mat-button-toggle-group>

      <mat-form-field appearance="outline" subscriptSizing="dynamic"
        class="displayName"
        *ngIf="columns | async | includes:'displayName'"
      >
        <mat-label>{{ 'attributes.shared.displayName' | translate | capitalizeFirst }}</mat-label>
        <input matInput formControlName="displayName" type="text" autocomplete="off" (paste)="paste('displayName', $event, i)">
      </mat-form-field>

      <mat-form-field appearance="outline" subscriptSizing="dynamic"
        class="firstName"
        *ngIf="columns | async | includes:'firstName'"
      >
        <mat-label>{{ 'attributes.person.firstName' | translate | capitalizeFirst }}</mat-label>
        <input matInput formControlName="firstName" autocomplete="off" (paste)="paste('firstName', $event, i)">
      </mat-form-field>

      <mat-form-field appearance="outline" subscriptSizing="dynamic"
        class="lastName"
        *ngIf="columns | async | includes:'lastName'"
      >
        <mat-label>{{ 'attributes.person.lastName' | translate | capitalizeFirst }}</mat-label>
        <input matInput formControlName="lastName" autocomplete="off" (paste)="paste('lastName', $event, i)">
      </mat-form-field>

      <mat-form-field appearance="outline" subscriptSizing="dynamic"
        class="ids"
        *ngIf="columns | async | includes:'ids'"
      >
        <mat-label>{{ 'attributes.shared.ids' | translate | capitalizeFirst }}</mat-label>
        <input matInput formControlName="ids" autocomplete="off" (paste)="paste('ids', $event, i)">
      </mat-form-field>

      <button mat-icon-button (click)="remove(i)" cypress="delete">
        <mat-icon>delete</mat-icon>
      </button>

    </ng-container>
  </div>
</mat-dialog-content>


<mat-dialog-actions>
  <button mat-button color="accent"
    cypress="one-more"
    (click)="addOneMore($event)"
  >
  {{ 'common.add_one_more' | translate }}
  </button>
  <span class="spacer" [style.min-width.em]="3"></span>
  <button mat-button mat-dialog-close type="button">
    {{ 'common.cancel' | translate }}
  </button>
  <button mat-button color="primary" type="button"
    [disabled]=" ! numEntities"
    cypress="submit"
    (click)="submit($event)" (keydown.enter)="submit($event)"
  >
    {{ 'common.create' | translate }}
    {{ numEntities | async | allowGreaterThan:1 }}
  </button>
</mat-dialog-actions>
