import { Injectable                } from '@angular/core';
import moment                        from 'moment';
import _                             from 'lodash';

import { TranslateService          } from 'app/core';
import { commonConstants           } from 'app/constants';
import { Util                      } from 'app/common';

@Injectable({
  providedIn: 'root'
})
export class DateService {
  public dayNames:   string[]      = commonConstants.DAYS;

  constructor(private _translate: TranslateService) { }

  static get firstDay(): moment.Moment {
    return DateService._firstDay.clone();
  }
  private static _firstDay: moment.Moment = moment.utc('1970-01-05');

  public static fromTimeString (timeString: string, day: number = 0): moment.Moment {
    const [hours, minutes] = timeString.includes(':') ?
      timeString.split(':').map(time => parseInt(time)) :
      [parseInt(timeString), 0];
    return DateService.firstDay.add(day, 'day').set('hour', hours).set('minute', minutes);
  }

  public fromDiscretizedTime(start: number, discretization: number, day?: number): moment.Moment {
    if (day == null) return DateService.firstDay                .add(start * discretization, 'm');
    else             return DateService.firstDay.add(day, 'day').add(start * discretization, 'm');
  }

  public minutes2timeString (minutes: number): string {
    const hours = Math.floor(minutes / 60);
    const mins  = Math.round(minutes % 60);
    return `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}`;
  }

  public fromDate(date: moment.Moment | string): string {
    let _date = moment.utc(date);
    if (! _date.isValid)
      return '';
    return _date.format('HH:mm');
  }

  public static sanitizeDate (date: moment.Moment | string, day?: number): moment.Moment | null {
    let _date = moment.utc(date);
    if ( ! _date.isValid) return null;

    const _day = day ?? DateService.getDayIndex(_date);

    return DateService.firstDay
      .add(_day,                 'day')
      .add(_date.hours(),        'hours')
      .add(_date.minutes(),      'minutes')
      .add(_date.seconds(),      'seconds')
      .add(_date.milliseconds(), 'milliseconds');
  }

  static toDiscretizedTime (mnt: moment.Moment | string, dt: number): number {
    mnt            = moment.utc(mnt);
    const midnight = mnt.clone().startOf('day');
    return Math.round(mnt.diff(midnight, 'minutes') / dt);
  }


  static getDayIndex = Util.functions.getDayIndex;

  public getTranslatedDayName(val: number | string | moment.Moment, format: 'long' | 'short' | 'narrow' = 'long'): string {
    // if moment is passed, get the day index
    // (if a number is passed we assume that monday = 0, etc.)
    if (moment.isMoment(val) || _.isString(val)) {
      val = DateService.getDayIndex(val);
    }

    return this._translate.instant(`common.day_${format}_` + val);
  }

  public minuteDiff(a: moment.Moment, b: moment.Moment): number {
    return Math.abs((a.hours() * 60 + a.minutes()) - (b.hours() * 60 + b.minutes()));
  }

  public hourDiff(a: moment.Moment, b: moment.Moment): number {
    if (! a || !b) return 0;
    return Math.abs(((a.hours() * 60 + a.minutes()) - (b.hours() * 60 + b.minutes())) / 60);
  }

  public isInValidInterval(date: moment.Moment | string | undefined, numDays: number): boolean {
    if (! date) return false;

    const _date = moment.utc(date);

    if (! _date.isValid()) return false;

    return _date.isBetween(DateService._firstDay, DateService._firstDay.clone().add(numDays, 'days'), null, '[]');
  }
}
