import { Util                             } from '@app/common';
import { Populated as P                   } from '@app/shared/interfaces';
import { ExtendedDivisionSettings,
         ScheduleData,
         SchedulePatch,
         operations                       } from './shared-worker/types';

// function mapping the date ranges of a period to milliseconds since epoch
export function inPlaceToTimestamp (period: Partial<P.period>) {
  (period.ranges as any) = (period.ranges ?? []).map(x => ({
    start: new Date(x.start.toString()).getTime(),
    end:   new Date(x.end  .toString()).getTime()
  }));
}


// a more convenient type as lookups are quicker
type _Patch<T extends object> = {
  [K in Exclude<operations.All, operations.Removed>]?: Record<string, Partial<T>>;
} & {
  [K in operations.Removed]?: Record<string, string>
}

type MergeableSchedulePatch = {
  settings?:      _Patch<ExtendedDivisionSettings>
  rootIntervals?: _Patch<P.rootInterval>;
  periods?:       _Patch<P.period>;
  persons?:       _Patch<P.person>;
  groups?:        _Patch<P.group>;
  locations?:     _Patch<P.location>;
  teachers?:      _Patch<P.teacher>;
  courses?:       _Patch<P.course>;
  events?:        _Patch<P.event>;
  lockedTimes?:   _Patch<P.lockedTime>;
  overlapGroups?: _Patch<P.overlapGroup>;
}


// a deep object assign but the value of each updated entity is overwritten
// i.e., at a certain depth, the value of each key is overwritten
export function mergePatches (
  acc:   MergeableSchedulePatch,
  patch: MergeableSchedulePatch
): MergeableSchedulePatch {
  Util.functions.objectEntries(patch).forEach(([collectionKey, value]) => {
    if ( ! acc[collectionKey]) acc[collectionKey] = { };
    const col = acc[collectionKey]!

    // one operation at a time
    Util.functions.objectEntries(value).forEach(([operation, items]) => {
      if ( ! col[operation]) col[operation] = { };
      const colOp = col[operation]!;

      // loop over each item in the operation
      Util.functions.objectEntries(items).forEach(([id, item]) => {
        if (operation == operations.removed) {
          colOp[id] = id;
          return;
        }

        // each present value is overwritten
        if ( ! colOp[id]) colOp[id] = item as any;
        else              Object.assign(colOp[id], item);
      });
    });



    // references as typescript otherwise requires a bunch of "!"
    const created = col.created;
    const updated = col.updated;
    const removed = col.removed;

    // remove deleted items from either created or updated
    // (as they are no longer present)
    if (removed) {
      Util.functions.objectEntries(removed).forEach(([id, _item]) => {
        if (created && created[id]) delete created[id];
        if (updated && updated[id]) delete updated[id];
      });

      // remove the created and updated record if empty
      if (created && ! Object.keys(created).length) delete col.created;
      if (updated && ! Object.keys(updated).length) delete col.updated;
    }

    // items present in both the created and updated are merged
    // (as the updated is the most recent)
    if (created && updated) {
      Util.functions.objectEntries(created).forEach(([id, _item]) => {
        if (updated[id]) {
          Object.assign(created[id], updated[id]);
          delete updated[id];
        }
      });

      // remove the updated record if empty
      if ( ! Object.keys(updated).length) delete col.updated;
    }

  });

  return acc;
}

export function mapToMergeable (x: SchedulePatch): MergeableSchedulePatch {
  const res: MergeableSchedulePatch = { };

  Util.functions.objectEntries(x).forEach(([key, value]) => {
    for (const o of operations.all) {
      const items = value[o];
      if ( ! items) continue;

      // try initialize
      if ( ! res[key]    ) res[key]     = { };
      if ( ! res[key]![o]) res[key]![o] = { };

      items.forEach(item => {
        if ( ! item) return;
        const id = typeof item == 'string' ? item : item.id;

        if (typeof item == 'object') {
          if ('updatedAt' in item) delete item.updatedAt;
          if ('CONTEXT'   in item) delete item.CONTEXT;
        }

        res[key]![o]![id] = item;
      });
    }
  });

  return res;
}


export function mapFromMergeable (x: MergeableSchedulePatch): SchedulePatch {
  const res: SchedulePatch = { };

  Util.functions.objectEntries(x).forEach(([key, value]) => {
    for (const o of operations.all) {
      const items = value[o];
      if ( ! items) continue;

      // try initialize
      if ( ! res[key]    ) res[key]     = { };
      if ( ! res[key]![o]) res[key]![o] = [ ];

      Util.functions.objectEntries(items).forEach(([, val]) => {
        if ( ! val) return;
        res[key]![o]!.push(val as any);
      });
    }
  });


  return res;
}



export function serializeData (data: ScheduleData): ScheduleData {
  // loop over each collection
  Util.functions.objectEntries(data).forEach(([key, collection]) => {
    if (key == 'division' || key == 'settings') return;

    collection.forEach((item: any) => {
      delete item.belongsTo;
      delete item.createdAt;
      delete item.is;
      delete item.ids;

      if (key == 'periods') {
        // map the start and end date of the ranges to milliseconds since epoch
        // (was difficult to handle readable dates in the wasm module as it crashed in certain browsers...)
        inPlaceToTimestamp(item);
      }

      if (key == 'events') {
        const event = item as P.event;
        item.course         = event.course?.id;
        item.overlapSpecies = event.overlapSpecies?.id;
      }


      if (key == 'persons') {
        const person = item as P.person;
        item.group = person.group?.id;
      }

      if (key == 'groups') {
        const group = item as P.group;
        item.members      = item.members ?? [];
        item.rootInterval = group.rootInterval?.id;
      }

      if (key == 'teachers') {
        const teacher = item as P.teacher;
        item.rootInterval = teacher.rootInterval?.id;
      }

      if (key == 'overlapGroups') {
        const overlapGroup = item as P.overlapGroup;
        item.coalesced = overlapGroup.coalesced?.map(x => ({ ...x, to: x.to.id }));
        item.species   = overlapGroup.species  ?.map(x => ({ ...x, to: x.to.id }));
      }

      // serialize the groups exclude array
      if (key == 'courses' || key == 'events') {
        const groups: P.event['groups'] | undefined = item.groups;
        groups?.forEach(group => {
          if (group.exclude && group.exclude.length) {
            (group.exclude as any) = group.exclude.map(x => x.id);
          }
        });

        const locations: P.event['locations'] | undefined = item.locations;
        locations?.forEach(location => {
          if (location.locations && location.locations.length) {
            (location.locations as any) = location.locations.map(x => x.id);
          }
        });

        const overlapGroup: P.course['overlapGroup'] | undefined = item.overlapGroup;
        if (overlapGroup) {
          (item.overlapGroup as any) = overlapGroup.id;
        }
      }

      if (key != 'overlapGroups') {

        // loop over each entry of the item and if it is an array that contains objects with id, replace by the id
        Util.functions.objectEntries(item).forEach(([k, v]) => {
          if (k == 'inLocations') {
            // remove null values
            item[k] = v.filter(Boolean);
            v = item[k];
          }

          if (Array.isArray(v) && v.length && v[0].id) {
            // console.log(`${key}.${k}`);b
            item[k] = v.map((x: any) => x.id);
          }
        });

        // loop over each entry of the item and if it is an array that contains objects with to which itself is an object with id, replace the to with the id
        Util.functions.objectEntries(item).forEach(([k, v]) => {
          if (Array.isArray(v) && v.length && v[0].to && v[0].to.id) {
            // console.log(`${key}.${k}`);
            item[k] = v.map((x: any) => {
              x.to = x.to.id;
              return x;
            });
          }
        });

      }


      // // TEMP: to test System::establishParticipation() with invalid references
      // if (key == 'courses') {
      //   // console.log(item.id, item.groups)

      //   if (item.id == 'm8tGIjqd') {
      //     item.groups.push({ to: 'fake group', exclude: ['fage p']})
      //   }
      // }

    });

  });

  return data;
}