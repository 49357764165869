@if (form) {
  <form [formGroup]="form"
        novalidate
        class="form"
        (keydown)="keydown($event)"
        [ngSwitch]="type">
    <ng-template ngSwitchCase="default">
      <div class="content">

        <div class="area-1">

          <div class="form-field-wrapper">
            <mat-icon class="form-field-icon">event_note</mat-icon>
            <mat-form-field class="form-field" appearance="outline" floatLabel="always">
              <mat-label>{{ 'attributes.shared.displayName' | translate }}</mat-label>
              <input matInput type="text" formControlName="displayName" autocomplete="off">
              <mat-error><app-form-field-error [control]="form.controls.displayName"></app-form-field-error></mat-error>
            </mat-form-field>
          </div>

          <div class="form-field-wrapper" *ngIf="ids">
            <mat-icon class="form-field-icon">fingerprint</mat-icon>
            <mat-form-field class="form-field" appearance="outline" floatLabel="always">
              <mat-label>{{ 'attributes.shared.ids' | translate }}</mat-label>
              <input matInput type="text" formControlName="ids" required autocomplete="off">
              <mat-error><app-form-field-error [control]="form.controls.ids"></app-form-field-error></mat-error>
            </mat-form-field>
          </div>

          <div class="form-field-wrapper">
            <mat-icon class="form-field-icon">subject</mat-icon>
            <mat-form-field class="form-field" appearance="outline" floatLabel="always">
              <mat-label>{{ 'attributes.shared.subject' | translate }}</mat-label>
              <input matInput type="text" formControlName="subject" autocomplete="off">
              <mat-error><app-form-field-error [control]="form.controls.subject"></app-form-field-error></mat-error>
            </mat-form-field>
          </div>

          <div class="form-field-wrapper">
            <mat-icon class="form-field-icon">expand</mat-icon>
            <mat-form-field class="form-field" appearance="outline" floatLabel="always">
              <mat-label>{{ 'attributes.course.eventDurationVariation' | translate }}</mat-label>
              <app-form-field-duration formControlName="eventDurationVariance"
                                      prefix="±"
                                      [suffix]="'common.minutes' | translate | lowercase"
                                      [voidText]="settings?.defaultEventDurationVariance"
                                      [setVoidText]="'common.useDefaultValue' | translate:{ value: settings?.defaultEventDurationVariance }"
                                      inherit
                                      [min]="0"
                                      nullable>
              </app-form-field-duration>
              <mat-error><app-form-field-error [control]="form.controls.eventDurationVariance"></app-form-field-error></mat-error>
            </mat-form-field>
          </div>

          <div class="form-field-wrapper" *ngIf="periods">
            <mat-icon class="form-field-icon">{{ 'periods' | collectionIcon }}</mat-icon>
            <mat-form-field class="form-field" appearance="outline" floatLabel="always">
              <mat-label>{{ 'attributes.shared.period' | translate }}</mat-label>
              <app-form-field-period formControlName="period"
                                    disableActions
                                    disableTooltip
                                    inherit
                                    [voidText]="(settings?.period?.displayName ?? ('attributes.shared.period.entire' | translate)) | capitalizeFirst"
                                    [setVoidText]="'common.useDefaultValue' | translate:{ value: (settings?.period?.displayName ?? ('attributes.shared.period.entire' | translate)) | capitalizeFirst }"
                                    [options]="periodList">
              </app-form-field-period>
              <mat-error><app-form-field-error [control]="form.controls.period"></app-form-field-error></mat-error>
            </mat-form-field>
          </div>

        </div>

        <div class="area-2">
          <div class="form-field-wrapper" *ngIf="teachers">
            <mat-icon class="form-field-icon">{{ 'teachers'  | collectionIcon }}</mat-icon>
            <mat-form-field #teachersField class="form-field" appearance="outline" floatLabel="always">
              <mat-label>{{ 'attributes.shared.teachers' | translate }}</mat-label>
              <div class="full-width full-height min-height-24 flex flex-align-center"
                  formField="teachers"
                  [control]="form.controls.teachers"
                  [formControlAccessor]="teachersField"
                  formControlAccessorName="teachers">
              </div>
              <mat-error><app-form-field-error [control]="form.controls.teachers"></app-form-field-error></mat-error>
            </mat-form-field>
          </div>

          <div class="form-field-wrapper" *ngIf="groups">
            <mat-icon class="form-field-icon">{{ 'groups'  | collectionIcon }}</mat-icon>
            <mat-form-field #groupsField class="form-field" appearance="outline" floatLabel="always">
              <mat-label>{{ 'attributes.shared.groups' | translate }}</mat-label>
              <div class="full-width full-height min-height-24 flex flex-align-center"
                  formField="groups-persons"
                  [control]="form.controls.groups"
                  [formControlAccessor]="groupsField"
                  formControlAccessorName="groups"
                  [coalescedControl]="form.controls.participants">
              </div>
              <mat-error><app-form-field-error [control]="form.controls.groups"></app-form-field-error></mat-error>
            </mat-form-field>
          </div>

          <div class="form-field-wrapper" *ngIf="locations">
            <mat-icon class="form-field-icon">{{ 'locations'  | collectionIcon }}</mat-icon>
            <mat-form-field class="form-field" appearance="outline" floatLabel="always">
              <mat-label>{{ 'attributes.shared.locations' | translate }}</mat-label>
              <app-form-field-available-locations formControlName="locations"
                                                  voidText="-"
                                                  add
                                                  disableActions
                                                  disableTooltip
                                                  [list]="locationList">
              </app-form-field-available-locations>
              <mat-error><app-form-field-error [control]="form.controls.locations"></app-form-field-error></mat-error>
            </mat-form-field>
          </div>

          <div class="form-field-wrapper">
            <mat-icon class="form-field-icon">transfer_within_a_station</mat-icon>
            <mat-form-field class="form-field" appearance="outline" floatLabel="always">
              <mat-label>{{ 'attributes.shared.minBreakLength' | translate }}</mat-label>
              <app-form-field-break-length formControlName="minBreakLength"
                                          [defaultValue]="settings?.defaultEventMinimumBreakLength"
                                          [voidText]="settings?.defaultEventMinimumBreakLength + ' ' + ('common.minutes' | translate | lowercase)"
                                          [setVoidText]="'common.useDefaultValue' | translate:{ value: settings?.defaultEventMinimumBreakLength }"
                                          disableActions>
              </app-form-field-break-length>
              <mat-error><app-form-field-error [control]="form.controls.minBreakLength"></app-form-field-error></mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="area-3">

          <div class="form-field-wrapper">
            <mat-icon class="form-field-icon">calendar_today</mat-icon>
            <mat-form-field class="form-field" appearance="outline" floatLabel="always">
              <mat-label>{{ 'attributes.shared.days' | translate }}</mat-label>
              <app-form-field-available-days formControlName="days"
                                            [numDays]="settings?.numDays"
                                            disableActions>
              </app-form-field-available-days>
              <mat-error><app-form-field-error [control]="form.controls.days"></app-form-field-error></mat-error>
            </mat-form-field>
          </div>

          <div class="form-field-wrapper" *ngIf="intervals">
            <mat-icon class="form-field-icon">compress</mat-icon>
            <mat-form-field class="form-field" appearance="outline" floatLabel="always">
              <mat-label>{{ 'attributes.shared.intervals' | translate }}</mat-label>
              <app-form-field-intervals formControlName="intervals"
                                        [numDays]="settings?.numDays"
                                        [availableDays]="form.controls.days.value"
                                        [voidText]="settings?.dayStart + ' - ' + settings?.dayEnd"
                                        [setVoidText]="'common.useDefaultValue' | translate:{ value: settings?.dayStart + ' - ' + settings?.dayEnd }"
                                        disableActions>
              </app-form-field-intervals>
              <mat-error><app-form-field-error [control]="form.controls.intervals"></app-form-field-error></mat-error>
            </mat-form-field>
          </div>

          <div class="form-field-wrapper" *ngIf="centerOfAttraction">
            <mat-icon class="form-field-icon">compress</mat-icon>
            <mat-form-field class="form-field" appearance="outline" floatLabel="always">
              <mat-label>{{ 'attributes.shared.centerOfAttraction' | translate }}</mat-label>
              <app-form-field-center-of-attraction formControlName="centerOfAttraction"
                                                  [preselected]="settings?.potentialCenter"
                                                  [voidText]="settings?.potentialCenter"
                                                  [setVoidText]="'common.useDefaultValue' | translate:{ value: settings?.potentialCenter }"
                                                  disableActions>
              </app-form-field-center-of-attraction>
              <mat-error><app-form-field-error [control]="form.controls.centerOfAttraction"></app-form-field-error></mat-error>
            </mat-form-field>
          </div>

          <div class="form-field-wrapper" *ngIf="lockedTimes">
            <mat-icon class="form-field-icon">{{ 'lockedTimes'  | collectionIcon }}</mat-icon>
            <mat-form-field class="form-field" appearance="outline" floatLabel="always">
              <mat-label>{{ 'attributes.shared.lockedTimes' | translate }}</mat-label>
              <app-form-field-locked-times formControlName="lockedTimes"
                                          [numDays]="settings?.numDays"
                                          voidText="-"
                                          disableActions
                                          [hideCalendar]="hideCalendars">
              </app-form-field-locked-times>
              <mat-error><app-form-field-error [control]="form.controls.lockedTimes"></app-form-field-error></mat-error>
            </mat-form-field>
          </div>

        </div>
      </div>
    </ng-template>

    <ng-template ngSwitchCase="row" class="content-row">
      <div class="content-row">

        <div class="form-field-wrapper" *ngIf="ids">
          <mat-form-field class="form-field" appearance="outline" floatLabel="always" style="font-size: 12px">
            <mat-label>{{ 'attributes.shared.ids' | translate }}</mat-label>
            <input matInput type="text" formControlName="ids" required id="ids" autocomplete="off">
            <mat-error><app-form-field-error [control]="form.controls.ids"></app-form-field-error></mat-error>
          </mat-form-field>
        </div>


        <div class="form-field-wrapper" *ngIf="displayName">
          <mat-form-field class="form-field" appearance="fill" style="font-size: 12px">
            <input matInput
                  type="text"
                  formControlName="displayName"
                  id="displayName"
                  placeholder="{{ 'attributes.shared.displayName' | translate }}"
                  autocomplete="off">
          </mat-form-field>
        </div>

      </div>
    </ng-template>

  </form>
}