import { Component,
         AfterViewInit,
         ViewChild,
         Input,
         Output,
         EventEmitter                      } from '@angular/core';
import { BehaviorSubject,
         filter,
         Subject,
         takeUntil,                        } from 'rxjs';
import moment                                from 'moment';
import colormap                              from 'colormap';
import _                                     from 'lodash';

import { CalendarComponent as Calendar,
         CalendarOptions                   } from 'app/shared';
import { DateService                       } from 'app/shared/services';
import { CalendarInterval                  } from 'app/shared/interfaces/intervals';
import { InCalendarEvent                   } from '@app/shared/calendar/calendar/types';
import { Util                              } from '@app/common';
import { AnalysisOutput,
         FocusedEvent,
         Interval                          } from '../../event-density.component';

export type Focused = { intervalIndex: number, setIndex: number } | undefined;

export const warmCMP = colormap({ colormap: 'autumn', format: 'rgbaString', 'alpha': 0.8 });
export const coldCMP = colormap({ colormap: 'winter', format: 'rgbaString', 'alpha': 0.8 });

export function getColor (
  cmap: string[],
  i:    number
): string {
  i = Math.max(0, Math.min(1, i));
  return cmap[Math.round(i * (cmap.length - 1))]
}

@Component({
  selector: 'app-input-analyzer-event-density-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.sass']
})
export class CalendarComponent implements AfterViewInit {
  private onDestroy = new Subject<void>();

  @ViewChild(Calendar) calendar?: Calendar;

  private dt: number = 5;
  public options: CalendarOptions = { bottomFade: false, selectMode: true };

  private onDefaultBusinessHours   = new BehaviorSubject<CalendarInterval[] | null>(null);
  private onDefaultBinaryIntervals = new BehaviorSubject<CalendarInterval[] | null>(null);
  private onDefaultEvents          = new BehaviorSubject<InCalendarEvent [] | null>(null);
  private onBusinessHours          = new BehaviorSubject<CalendarInterval[] | null>(null);
  private onBinaryIntervals        = new BehaviorSubject<CalendarInterval[] | null>(null);
  private onEvents                 = new BehaviorSubject<InCalendarEvent [] | null>(null);

  constructor (private _date: DateService) { };

  ngAfterViewInit () {
    this.calendar?.onEventMouseEnter
    .pipe(takeUntil(this.onDestroy))
    .subscribe(({ source: eventRef }) => {
      if (eventRef.extendedProps?.intervalIndex == undefined) return;
      if (eventRef.extendedProps?.setIndex      == undefined) return;
      const data = {
        intervalIndex: eventRef.extendedProps?.intervalIndex,
        setIndex:      eventRef.extendedProps?.setIndex
      };
      this.focused.next(data);
    });

    this.calendar?.onEventMouseLeave
    .pipe(takeUntil(this.onDestroy))
    .subscribe(() => {
      this.focused.next(this.defaultFocused);
    });


    this.calendar?.onSelectedEvents
    .pipe(takeUntil(this.onDestroy))
    .subscribe(ids => {
      if (ids.length) {
        const [intervalIndex, setIndex] = ids[0].split('.').map(x => parseInt(x));
        this.defaultFocused = { intervalIndex: intervalIndex, setIndex: setIndex };
        this.focused.next(this.defaultFocused);
      } else {
        this.defaultFocused = undefined;
      }
    });


    this.onCalendarFrame
    .pipe(
      takeUntil(this.onDestroy),
      filter(Boolean)
    )
    .subscribe(frame => {
      if (this.calendar) this.calendar.visibleRange = [ frame ];
    });


    this.onBusinessHours
    .pipe(
      takeUntil(this.onDestroy),
      Util.operators.defaultIfNull(this.onDefaultBusinessHours)
    )
    .subscribe(x => {
      this.calendar?.setBusinessHours(x ?? []);
    });


    this.onBinaryIntervals
    .pipe(
      takeUntil(this.onDestroy),
      Util.operators.defaultIfNull(this.onDefaultBinaryIntervals)
    )
    .subscribe(binary => {
      this.calendar?.setBackgroundEvents(binary ?? []);
    });


    this.onEvents
    .pipe(
      takeUntil(this.onDestroy),
      Util.operators.defaultIfNull(this.onDefaultEvents)
    )
    .subscribe(events => {
      this.calendar?.setForegroundEvents(events)
    });

  }

  ngOnDestroy () {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  @Input()
  set defaultTimeFrame (intervals: Interval[][] | undefined | null) {
    if ( ! intervals) return;

    this.onDefaultBusinessHours.next(
      intervals
      .flatMap((seq, d) =>
        seq.map(i => ({
          start: this._date.fromDiscretizedTime(i.beg, this.dt, d),
          end:   this._date.fromDiscretizedTime(i.end, this.dt, d)
        })
      ))
    );

    this.onDefaultBinaryIntervals.next(
      intervals
      .flatMap((seq, d) =>
        seq.filter(x => x.binary).map(i => ({
          start:           this._date.fromDiscretizedTime(i.beg, this.dt, d),
          end:             this._date.fromDiscretizedTime(i.end, this.dt, d),
          backgroundColor: 'transparent',
          classNames:      ['binary']
        })
      ))
    );
  }

  @Input()
  set defaultIntervals (intervals: AnalysisOutput["intervals"] | undefined | null) {
    if ( ! intervals) return;

    this.onDefaultEvents.next(this.intervals2events(intervals, { classes: ['selectable'] }));
  }

  @Input()
  set focusedEvent (arg: FocusedEvent | undefined) {
    if (arg) {

      this.onBusinessHours.next(
        arg.businessHours
        .flatMap((seq, d) =>
          seq.map(i => ({
            start: this._date.fromDiscretizedTime(i.beg, this.dt, d),
            end:   this._date.fromDiscretizedTime(i.end, this.dt, d)
          })
        ))
      );

      this.onBinaryIntervals.next(
        arg.businessHours
        .flatMap((seq, d) =>
          seq.filter(x => x.binary).map(i => ({
            start:           this._date.fromDiscretizedTime(i.beg, this.dt, d),
            end:             this._date.fromDiscretizedTime(i.end, this.dt, d),
            backgroundColor: 'transparent',
            classNames:      ['binary']
          })
        ))
      );

      this.onEvents.next(this.intervals2events(arg.intervals));
    } else {
      // reset
      this.onBusinessHours  .next(null);
      this.onBinaryIntervals.next(null);
      this.onEvents         .next(null);
    }
  }

  @Input()
  set frame (int: { beg: string, end: string } | undefined) {
    if ( ! int) return;

    this.onCalendarFrame.next({
      start: moment.utc(int.beg, 'HH:mm'),
      end:   moment.utc(int.end, 'HH:mm'),
    });
  }
  private onCalendarFrame = new BehaviorSubject<{ start: moment.Moment, end: moment.Moment } | null>(null);

  @Input()
  set numDays (num: number) { this._numDays = num; }
  get numDays () { return this._numDays; }
  private _numDays: number = 5;

  @Output()
  private focused = new EventEmitter<Focused>();
  private defaultFocused: Focused;



  private intervals2events (
    intervals: AnalysisOutput["intervals"],
    optional?: { classes?: string[]; }
  ): InCalendarEvent[] {
    let outEvents: InCalendarEvent[] = [];

    intervals.forEach((i, intervalIndex) => {
      i.interactionSets.forEach((set, setIndex) => {
        // some mass needs to be distributed over the interval
        const filling = set.combinedMass / (i.intervalSize - set.padding)
        const fillingPercent = Math.round(filling * 100);
        if ( ! fillingPercent) return;

        let color: string;
        if (set.feasible) {
          if (set.events.every(x => x.event?.is == 'event' ? x.event.fixedStart : false)) {
            // only fixed times are located here
            color = 'rgba(0, 0, 0, 1)';
          } else {
            // cold color
            color = getColor(coldCMP, filling);
          }
        } else {
          // warm color (1 = orange, 0 = red)
          // orange if just about overpopulated, make red if doubly populated or worse
          let r = 1 - Math.min(1, filling - 1);

          color = getColor(warmCMP, r);
        }

        // store
        outEvents.push({
          start:           this._date.fromDiscretizedTime(i.interval.beg, this.dt, 0),
          end:             this._date.fromDiscretizedTime(i.interval.end, this.dt, 0),
          title:           fillingPercent + '%',
          backgroundColor: color,
          id:              `${intervalIndex}.${setIndex}`,
          classNames:      ['density-interval', ...(optional?.classes || [])],
          extendedProps:   { intervalIndex, setIndex }
        });

      });
    });

    return outEvents;
  }
}
