<app-container>
  @if (loading$ | async) {
    <div class="loading flex-align-center" [@inAnimation]>
      {{ 'common.loading' | translate }}...
    </div>
  } @else {
    <!-- week/period -->
    @if ((weeks$ | async)?.length) {
      <mat-form-field appearance="outline" subscriptSizing="dynamic" [@inAnimation] class="white no-outline medium">
        <mat-icon matPrefix>date_range</mat-icon>
        <mat-select
          [panelWidth]="null"
          [placeholder]="'common.week' | translate"
          hideSingleSelectionIndicator
          [ngModel]="selectedWeek$ | async | toNgModel"
          (ngModelChange)="setWeek($event)"
          panelClass="fit-content no-vertical-padding flex-option align-baseline dark-scrollbar"
        >
          <mat-select-trigger>
            @if (selectedWeek$ | async | typeGuard:isSingleWeek; as week) {
              {{ 'common.week_short' | translate | capitalizeFirst }} {{ week?.week?.number }}
              <sup>{{ week?.week?.excludedDays?.length ? -week.week!.excludedDays.length : '' }}</sup>
            }
            @else if (canSelectAllWeeks) {
              {{ 'common.all' | translate }}
            }
          </mat-select-trigger>

          <mat-option class="default-option" *ngIf="canSelectAllWeeks" value="all">{{ 'common.all' | translate }}</mat-option>
          @for (week of weeks$ | async; track $index) {
            <mat-option [value]="week.id">
              <span class="week-no">
                {{ 'common.week_short' | translate | capitalizeFirst }} {{ week.number }}
                <sup>{{ week.excludedDays.length ? -week.excludedDays.length : '' }}</sup>
              </span>
              <span class="spacer"></span>
              <span class="date-range">{{ week.string }}</span>
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
    }

    <!-- groups, teachers, locations, etc. -->
    @for (x of selectableEntities; track $index) {
      @if ((x.options$ | async)?.length) {
        <app-virtual-select
          [@inAnimation]
          [attr.cypress]="x.collection"
          [panelClass]="x.collection"
          [icon]="(x.collection | collectionIcon) || ''"
          [placeholder]="x.label | translate"
          [onOptions]="x.options$"
          [value]="selectedEntities$ | async | filterPrimary:x.collection"
          [class.is-selected]="selectedEntities$ | async | filterPrimary:x.collection"
          [openInNew]="openInNew"
          (onOpenInNew)="openNew(x.path, $event)"
          (onValueChange)="setPrimary(x.path, $event?.id)"
        ></app-virtual-select>
      }
    }

    <!-- secondary entities -->
    @if ( ! disableMultiselect) {
      <div class="coalesced-wrapper flex-align-center" [@inAnimation]>
        @for (x of selectedEntities$ | async | filterSecondary:selectableEntities; track $index) {
          <div class="additional-button">
            <div>
              <mat-icon inline>
                {{ x.key | collectionIcon }}
              </mat-icon>
              <span>{{ x.entity| displayName }}</span>
              <mat-icon inline class="clickable" (click)="removeSelectedByIndex($index + 1)">
                close
              </mat-icon>
            </div>
          </div>
        }

        @if (showSecondarySelect$ | async) {
          <button mat-icon-button
                  class="white"
                  [@inAnimation]
                  [disabled]="disableSecondarySelect$ | async"
                  [matMenuTriggerFor]="secondary"
                  (menuOpened)="menuOpened()" (menuClosed)="menuClosed()"
                  matTooltip="{{ 'shared.components.schema-filter-dropdowns coalesced' | translate }}"
          >
            <mat-icon>add_circle_outline</mat-icon>
          </button>
        }
      </div>
    }
  }
</app-container>

<!-- secondary entities menu -->
<mat-menu #secondary="matMenu">
  <!-- the following @if is to make the content lazy loaded  -->
  @if (renderMenuContent$ | async) {
    @for (menu of selectableEntities; track $index) {
      @if ((menu.options$ | async)?.length) {
        <button mat-menu-item [matMenuTriggerFor]="inner">
          <mat-icon>{{ menu.collection | collectionIcon }}</mat-icon>
          {{ menu.label | translate }}
        </button>
        <mat-menu #inner="matMenu" class="dark-scrollbar">
          @for (x of menu.options$ | async; track $index) {
            <button mat-menu-item
                    (click)="addSecondary(menu.collection, x.id)"
                    [disabled]="selectedEntities$ | async | filterBy:['key']:menu.collection | pluck:'id' | includes:x.id"
            >
              {{ x | displayName }}
            </button>
          }
        </mat-menu>
      }
    }
  }
</mat-menu>
