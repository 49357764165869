<!-- force rerender on stateChanges.next() otherwise it is not guaranteed to show the latest values due to some lazy rlatestering-->
{{ stateChanges | async }}

<div class="dynamic-locked-times-select-label clickable"
     (menuClosed)="closed()"
     (menuOpened)="opened()"
     [matMenuTriggerFor]="menu">
     <app-form-field-dynamic-locked-times-display-value [value]="value"
                                                        [numDays]="numDays"
                                                        [voidText]="voidText">
     </app-form-field-dynamic-locked-times-display-value>
</div>

<mat-menu yPosition="below"
          #menu="matMenu"
          class="dynamic-locked-times-panel no-vertical-padding"
          [overlapTrigger]="true">

  <ng-template matMenuContent>

    <!-- in order to prevent the menu from closing when clicking or tabbing to change focus -->
    <div (click)="$event.stopPropagation()"
         (keydown.tab)="$event.stopPropagation()"
         (keydown.shift.tab)="$event.stopPropagation()">

      <div class="toolbar">
        <div class="row">
          <button mat-stroked-button
                  class="action reset"
                  color="primary"
                  (click)="resetValue()"
                  *ngIf="reset && ! pristine">
            <div>
              <span>{{ 'common.restore' | translate }}</span>
              <mat-icon class="royal-icon">undo</mat-icon>
            </div>
          </button>
        </div>
      </div>

      <div class="content">

        <div [formGroup]="form" *ngIf="! isVoid && form">
          <mat-radio-group class="radio-group" formControlName="type" (change)="onStateChange()">
            <mat-radio-button value="none" class="radio-button">
              {{ 'attributes.shared.dynamicLockedTime.none' | translate }}
            </mat-radio-button>
            <mat-radio-button value="single" class="radio-button">
              {{ 'attributes.shared.dynamicLockedTime.repeated' | translate }}
            </mat-radio-button>
            <mat-radio-button value="multiple" class="radio-button">
              {{ 'attributes.shared.dynamicLockedTime.custom' | translate }}
            </mat-radio-button>
          </mat-radio-group>

          <ng-container [ngSwitch]="form.value.type">
            <div *ngSwitchCase="'single'" class="container single">
              <ng-container *ngTemplateOutlet="formGroup; context: { form: form.controls.values.controls[0] }"></ng-container>
            </div>

            <div *ngSwitchCase="'multiple'" class="container multiple dense-3">
              <ng-container *ngFor="let control of form.controls.values.controls; let i = index">
                <ng-container *ngTemplateOutlet="formGroup; context: { form: control, day: i }"></ng-container>
              </ng-container>
            </div>
          </ng-container>
        </div>

      </div>

      <mat-divider class="divider" *ngIf="! disableActions"></mat-divider>

      <div class="actions" *ngIf="! disableActions">
        <button mat-stroked-button
                color="primary"
                type="button"
                class="button"
                (click)="trigger?.closeMenu()">
          {{ 'common.cancel' | translate }}
        </button>
        <button mat-flat-button
                color="primary"
                type="button"
                [disabled]="! valid"
                (click)="submit(); trigger?.closeMenu()"
                class="button right">
          {{ 'common.save' | translate }}
        </button>
      </div>

    </div>

  </ng-template>
</mat-menu>



<ng-template #formGroup [templateTypes]="typeToken" let-form="form" let-day="day">

  <div *ngIf="day != null" class="day-label" [class.disabled]="form.disabled">
    {{ 'common.day_short_' + day | translate }}
  </div>

  <mat-form-field appearance="outline"
    class="earliest no-padding-bottom"
    [class.interval-error]="form.errors?.interval"
    [class.overflow-error]="form.errors?.overflow"
    [subscriptSizing]="day == null ? 'fixed' : 'dynamic'"
    (keydown.enter)="earliest.open()"
  >
    <mat-label>{{ 'common.earliest' | translate }}</mat-label>
    <app-form-field-time #earliest [formControl]="form.controls.earliest" class="earliest"></app-form-field-time>
    <mat-error><app-form-field-error [control]="form.controls.earliest"></app-form-field-error></mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline"
    class="latest no-padding-bottom"
    [class.interval-error]="form.errors?.interval"
    [class.overflow-error]="form.errors?.overflow"
    [subscriptSizing]="day == null ? 'fixed' : 'dynamic'"
    (keydown.enter)="latest.open()"
  >
    <mat-label>{{ 'common.latest' | translate }}</mat-label>
    <app-form-field-time #latest [formControl]="form.controls.latest" class="latest"></app-form-field-time>
    <mat-error><app-form-field-error [control]="form.controls.latest"></app-form-field-error></mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline"
    class="duration no-padding-bottom"
    [class.interval-error]="form.errors?.interval"
    [class.overflow-error]="form.errors?.overflow"
    [subscriptSizing]="day == null ? 'fixed' : 'dynamic'"
  >
    <mat-label>
      <span class="pointer-events-auto" [matTooltip]="'shared.form-fields.dynamic-locked-times.duration.tooltip' | translate">
        {{ 'attributes.shared.duration' | translate }}
      </span>
    </mat-label>
    <app-form-field-duration-with-variation [formControl]="form.controls.duration" unitLength="short"></app-form-field-duration-with-variation>
    <span matTextSuffix class="form-field-suffix">{{ 'common.minutes_short' | translate }}</span>
  </mat-form-field>

  <mat-form-field appearance="outline" floatLabel="always"
    class="locations no-padding-bottom"
    [subscriptSizing]="day == null ? 'fixed' : 'dynamic'"
  >
    <mat-label>{{ 'common.locations' | translate }}</mat-label>
    <mat-select #locationsSelect [formControl]="form.controls.locations" multiple panelClass="dark-scrollbar locations-panel" placeholder="-" (keydown.escape)="matSelectEscapePress($event, locationsSelect)">
      <mat-option *ngFor="let location of locations" [value]="location.id">
        {{ location | displayName }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-checkbox *ngIf="day != null"
                class="toggle"
                [checked]="! form.disabled"
                (change)="form.enabled ? form.disable() : form.enable()">
  </mat-checkbox>

</ng-template>