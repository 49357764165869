import { NgModule                        } from '@angular/core';
import { CommonModule                    } from '@angular/common';
import { FormsModule,
         ReactiveFormsModule             } from '@angular/forms';

import { AppCommonModule                 } from 'app/common/common.module';
import { PipesModule                     } from 'app/shared/pipes/pipes.module';
import { TranslationModule               } from 'app/core/translate/translate.module';
import { CustomSearchComponent           } from './custom-search.component';

@NgModule({
  declarations: [
    CustomSearchComponent
  ],
  exports: [
    CustomSearchComponent
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    TranslationModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule
  ]
})
export class CustomSearchModule { }
