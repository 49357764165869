<mat-dialog-content class="content">

  <app-save-icon class="save-icon"
                 *ngIf="saving">
  </app-save-icon>

  <button mat-icon-button
          type="button"
          class="close-button"
          matTooltip="{{ 'common.close' | translate }}"
          mat-dialog-close>
    <mat-icon class="royal-icon">close</mat-icon>
  </button>

  <mat-tab-group class="tab-content" animationDuration="0ms">

    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon inline
                  class="royal-icon"
                  style="margin-right: 12px">event</mat-icon>
        {{ 'common.course' | translate }}
      </ng-template>
      <ng-template matTabContent>
        <app-courses-table [did]="did"
                          selectable
                          delete
                          [editable]="editable"
                          (onSave)="saving = $event">
        </app-courses-table>
      </ng-template>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon inline
                  class="royal-icon"
                  style="margin-right: 12px">people</mat-icon>
        {{ 'common.groups' | translate }}
      </ng-template>
      <ng-template matTabContent>
        <app-groups-table [did]="did"
                          selectable
                          delete
                          [editable]="editable"
                          (onSave)="saving = $event">
        </app-groups-table>
      </ng-template>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon inline
                  class="royal-icon"
                  style="margin-right: 12px">school</mat-icon>
        {{ 'common.teachers' | translate }}
      </ng-template>
      <ng-template matTabContent>
        <app-teachers-table [did]="did"
                            selectable
                            delete
                            [editable]="editable"
                            (onSave)="saving = $event">
        </app-teachers-table>
      </ng-template>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon inline
                  class="royal-icon"
                  style="margin-right: 12px">room</mat-icon>
        {{ 'common.locations' | translate }}
      </ng-template>
      <ng-template matTabContent>
        <app-locations-table [did]="did"
                             selectable
                             delete
                             [editable]="editable"
                             (onSave)="saving = $event">
        </app-locations-table>
      </ng-template>
    </mat-tab>

  </mat-tab-group>

</mat-dialog-content>