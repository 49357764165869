import { CalendarComponent       } from './calendar.component';
import { animationFrameScheduler } from 'rxjs';


function deriveVisibleRange (
  $calendar: JQuery<HTMLElement>
) {
  // get slot height
  const $calendarBody = $calendar.find('.fc-scrollgrid-section-body');

  // try first to access the slot height from the css variable
  // and if not possible fallback to the computed style
  // TODO: [RSA-222] the ".css(...)" caused the error "Cannot read properties of undefined (reading 'replace')"
  // (https://app.royalschedule.com/app-logs/6409a67961c5a97a00a94755)
  console.log($calendarBody);
  let variable = $calendarBody.css('--cell-height');
  const slotHeight = variable?.includes('px')
                   ? parseFloat(variable)
                   : $calendarBody.find('.fc-timegrid-slot').height()!;

  // compute start and end in minutes from midnight
  const $scroller = $calendar.find('.fc-scrollgrid-section-body .fc-scroller');
  const start = $scroller.scrollTop()! / slotHeight * 60;
  const end   = start + $scroller.height()! / slotHeight * 60;

  return { start, end };
}

function clipAndShiftRange (
  start: number,
  end:   number,
) {
  // clip
  start = Math.max(start, 0);
  end   = Math.min(end  , 24 * 60);

  // make sure that the calendar view is at least one hour long
  const min = 60; // minutes
  if (end - start < min) {
    const mid = (start + end) / 2;
    start = mid - min / 2;
    end   = mid + min / 2;

    // shift to ensure that the calendar is not out of bounds
    if (start < 0) {
      start = 0;
      end   = min;
    } else if (end > 24 * 60) {
      start = 24 * 60 - min;
      end   = 24 * 60;
    }
  }

  return { start, end };
}


export function scaleVertically (
  this:    CalendarComponent,
  range?: { start: number, end: number }
) {
  const $calendarBody = this.$calendar.find('.fc-scrollgrid-section-body');

  let scrollTo:   number;
  let slotHeight: number;
  if (range) {
    // ensure the the range is well behaved
    const { start, end } = clipAndShiftRange(range.start, range.end);
    scrollTo = start;

    // store number of visible hours to use in case of vertical scaling
    this._numVisibleHours = (end - start) / 60;

    // set scale of the viewer (slot = 1 hour)
    slotHeight = $calendarBody.height()! / this._numVisibleHours;
  } else {
    // derive visible time range from the DOM
    let { start, end } = deriveVisibleRange(this.$calendar);
    scrollTo = start;

    // compute new slot height such that the number of visible hours is maintained
    const prevSlotHeight = parseFloat($calendarBody.css('--cell-height')!);
    slotHeight = (end - start) * prevSlotHeight / 60 / this._numVisibleHours;
  }

  // update slot height
  $calendarBody.css('--cell-height', `${ slotHeight }px`);

  // scroll to the correct start time after the DOM has been updated
  animationFrameScheduler.schedule(() => {
    // DO NOT USE FULLCALENDARS VERSION OF SCROLLING, IT SUCKS!
    // this.calendar.getApi().scrollToTime(this._date.minutes2timeString(scrollTo));
    this.$calendar.find('tbody .fc-scroller').scrollTop(scrollTo / 60 * slotHeight);

    // update calendar positions
    this.rerender();
  });
}

export function zoom (
  this:  CalendarComponent,
  shift: number
) {
  // derive visible time range from the DOM
  let { start, end } = deriveVisibleRange(this.$calendar);

  // shift bounds equally
  start -= shift / 2;
  end   += shift / 2;

  // ensure the the range is well behaved
  ({ start, end } = clipAndShiftRange(start, end));

  // scale
  this.scaleVertically({ start, end });
}


let timeoutRef: number | undefined;
export function scaleHorizontally (
  this: CalendarComponent
) {
  // set proper width of calendar
  // (such that the scrollbar does not hide parts of the last day nor have to much room)

  // get width of scrollbar
  let scrollerWidth = 8; // pixels
  try {
    const element = this.$calendar.find('.fc-scroller')![0];
    const styles  = window.getComputedStyle(element, '::-webkit-scrollbar')

    if (styles['width']) scrollerWidth = parseInt(styles['width']);
  } catch { }

  // fetch elements with explicit width selector
  const elemSelectors = this.$calendar.find('.fc-timegrid-body[style*="width"], table[style*="width"]');

  // subtract scroller width
  // (await full calendars internal update such that the width isn't overridden)
  clearTimeout(timeoutRef);
  (timeoutRef as any) = setTimeout(() => {
      // add smooth transition class "smooth-width"
      elemSelectors.addClass('smooth-width');
      elemSelectors.width(this.$calendar.width()! - scrollerWidth);

      // remove smooth transition class when the transition is done
      setTimeout(() => elemSelectors.removeClass('smooth-width'), 150);   // must match value in "calendar.component.scss"
  }, 100);
}