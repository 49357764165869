<div class="content">
  <mat-dialog-content>
    <app-change-password-form></app-change-password-form>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-stroked-button color="primary" mat-dialog-close>{{ 'common.cancel' | translate }}</button>
    <span class="spacer"></span>
    <button mat-raised-button color="primary" [disabled]=" ! changePasswordComponent?.form?.valid" (click)="submit()">{{ 'common.save' | translate }}</button>
  </mat-dialog-actions>

  <app-submit-pane [hasActions]="true"></app-submit-pane>
</div>
