import { Injectable                            } from '@angular/core';
import lodash                                    from 'lodash';
import deepdash                                  from 'deepdash-es';
import { BehaviorSubject,
         map,
         Observable,
         share                                 } from 'rxjs';

import { pathFactory,
         storageConstants,                     } from 'app/constants';
import { AuthService,
         StorageService                        } from 'app/core';
import { HttpService                           } from '../http/http.service';

const _ = deepdash(lodash);


type Type            = { type:            'school' | 'sports facility' | 'unknown' };
type CountryOfOrigin = { countryOfOrigin: 'Sweden' | 'Germany' | 'other' | 'unknown' };

type SingleOrganizationPreference = Type
                                  | CountryOfOrigin;

export type OrganizationPreferences = Type
                                    & CountryOfOrigin;

const defaultOrganizationPreferences: OrganizationPreferences = {
  type:            'unknown',
  countryOfOrigin: 'unknown',
}

@Injectable({
  providedIn: 'root'
})
export class OrganizationPreferencesService {

  private preferences: BehaviorSubject<OrganizationPreferences>;

  constructor (
    private _auth:    AuthService,
    private _storage: StorageService,
    private _http:    HttpService,
  ) {
    // load default preferences
    this.preferences = new BehaviorSubject(structuredClone(defaultOrganizationPreferences));

    // update preferences when the authentication state is changed
    this._auth.onIsAuthenticated
    .subscribe(isAuthenticated => {
      if (isAuthenticated) {
        // logged in: fetch and store the preferences
        this._http
        .get(pathFactory('COMPANY', 'preferences'))
        .subscribe({
          next: (val: OrganizationPreferences) => { this.load(val); }
        });
      } else {
        // logged out: reset to default
        this._set(structuredClone(defaultOrganizationPreferences));
      }
    });
  }


  private load (preferences?: Partial<OrganizationPreferences> | undefined) {
    // empty preferences
    if ( ! preferences) return;

    // remove junk
    let clean: Partial<OrganizationPreferences> = _.pickDeep(preferences, _.paths(defaultOrganizationPreferences) as any);

    if ( ! clean) return;

    // override
    // ....

    this._set(clean);

    // store
    this.store();
  }


  public watch (): Observable<OrganizationPreferences> {
    return this.preferences.asObservable();
  }

  public get type () { return this.preferences.value.type; }
  public setType (val: Type['type']): Observable<void> {
    console.log('SHOULD NOT BE POSSIBLE IF NOT ADMIN')
    return this.set({ type: val });
  }

  public get countryOfOrigin () { return this.preferences.value.countryOfOrigin; }
  public setCountryOfOrigin (val: CountryOfOrigin['countryOfOrigin']): Observable<void> {
    console.log('SHOULD NOT BE POSSIBLE IF NOT ADMIN')
    return this.set({ countryOfOrigin: val });
  }

  private store () {
    this._storage.set(storageConstants.ORGANIZATION_PREFERENCES, JSON.stringify(this.preferences.value));
  }

  private _set (val: Partial<OrganizationPreferences>) {
    // merge in new preference
    let updatedPreferences = {} as OrganizationPreferences;
    _.merge(updatedPreferences, this.preferences.value, val);

    // override
    // ...

    this.preferences.next(updatedPreferences);
  }

  private set (
    preference: SingleOrganizationPreference
  ) {
    let prevPreference: SingleOrganizationPreference
      = _.pickDeep(this.preferences.value, _.paths(preference) as any);

    // set
    this._set(preference);

    // post
    const body = { preferences: preference };
    const obs = this._http
      .post(pathFactory('COMPANY', 'preferences'), body)
      .pipe(share());   // since we share the observable and don't want two requests to take place

    obs.subscribe({
      next: (res) => {
        // store only if successful
        this.store();
      },
      error: (error) => {
        // revert if unsuccessful
        this._set(prevPreference);
      }
    });

    return obs.pipe(map(x => void 0));
  }

}