import { Pipe,
         PipeTransform                   } from '@angular/core';
import _                                   from 'lodash';

import { resolve                         } from './debug.util';

@Pipe({
  name: 'validationError',
  standalone: true
})
export class ValidationErrorPipe implements PipeTransform {

  transform(value: any, detail: any): string {
    return _.get(value, detail.path[0], {})
  }
}

@Pipe({
  name: 'validationResolve',
  standalone: true,
  pure: true
})
export class ValidationResolvePipe implements PipeTransform {

  transform(
    value: any,
    detail: any,
    modifiedValue: string | null,
    cutPath: number | null | undefined
  ) {
    return resolve(value, detail, modifiedValue, cutPath);
  }
}
