import { Component,
         Input,
         ElementRef,
         OnDestroy,
         Optional,
         HostBinding,
         Self,
         EventEmitter,
         Output,
         ChangeDetectionStrategy,
         ViewChild,
         AfterViewInit                    } from '@angular/core';
import { ControlValueAccessor,
         NgControl,
         FormControl,
         FormGroup,
         ValidationErrors,
         ValidatorFn                      } from '@angular/forms';
import { MatFormFieldControl              } from '@angular/material/form-field';
import { FocusMonitor                     } from '@angular/cdk/a11y';
import { coerceBooleanProperty,           } from '@angular/cdk/coercion';
import { asyncScheduler,
         BehaviorSubject,
         debounceTime,
         Subject,
         takeUntil                       } from 'rxjs';
import _                                   from 'lodash';
import $                                   from 'jquery';


type Form = {
  hours:   FormControl<string | null>;
  minutes: FormControl<string | null>;
};

function formValueToTotalMinutes ({ hours, minutes }: FormGroup<Form>['value']): number | null {
  // if either value is missing, return null
  // (might potentially be an error state, but that is handled elsewhere)
  if ( ! hours || ! minutes) return null;

  // convert value to minutes and round to nearest multiple of 5
  const totalMinutes = (parseInt(hours) ?? 0) * 60 + (parseInt(minutes) ?? 0);
  const rounded = Math.round(totalMinutes / 5) * 5;

  // if the value is not a number, return null
  // (probably an error state as well...)
  if (isNaN(rounded)) return null;

  return rounded;
}

function totalMinutesToFormValue (
  totalMinutes: number | null
): { hours: null, minutes: null } | { hours: string, minutes: string } {
  // if the value is null, return null
  if (totalMinutes == null) return { hours: null, minutes: null };

  // update form values
  const hours   = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  // convert to strings and remove any zero decimals
  const hoursStr   = hours  .toString().replace(/\.0+$/, '');
  const minutesStr = minutes.toString().replace(/\.0+$/, '');

  // update form only if the value has changed
  return { hours: hoursStr, minutes: minutesStr };
}

function validateNumberOrNull (form: FormGroup<Form>): ValidationErrors | null {
  const { hours, minutes } = form.value;
  if (( ! hours) == ( ! minutes)) return null;
  return { invalid: true };
}

function validateMax (maxHours: number): ValidatorFn {
  return (form: FormGroup<Form>): ValidationErrors | null =>  {
    const { hours, minutes } = form.value;
    if ( ! hours || ! minutes) return null;

    // if the return value is null we have encountered a NaN value
    const totMins = formValueToTotalMinutes(form.value);

    if (totMins == null) return { invalid: true };
    if (totMins > maxHours * 60) return { max: true };
    return null;
  };
}

function getValidators (max?: number | null): ValidatorFn[] {

  return [
    validateNumberOrNull,
    max != null ? validateMax(max) : null,
  ].filter(Boolean);
}


@Component({
  selector: 'app-form-field-num-hours',
  templateUrl: './num-hours.component.html',
  styleUrls: ['./num-hours.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: NumHoursComponent
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NumHoursComponent implements AfterViewInit,
                                          OnDestroy,
                                          ControlValueAccessor,
                                          MatFormFieldControl<number | null> {

  @Output('onChange') emitter = new EventEmitter<number | null>();
  public durations:   number[]      = [];
  static nextId:      number        = 0;
  public stateChanges:Subject<void> = new Subject<void>();
  public focused:     boolean       = false;
  public errorState:  boolean       = false;
  public controlType: string        = 'num-hours-input';
  public id:          string        = `num-hours-input-${ NumHoursComponent.nextId++ }`;
  public describedBy: string        = '';
  public onChange = (_: any) => {};
  public onTouched = () => {};

  private readonly onDestroy = new Subject<void>();

  @ViewChild('hours')   hoursInput?:   ElementRef<HTMLInputElement>;
  @ViewChild('minutes') minutesInput?: ElementRef<HTMLInputElement>;

  protected form = new FormGroup<Form>({
    hours:   new FormControl(null),
    minutes: new FormControl(null)
  }, getValidators());

  protected readonly cleave_number = {
    numeral:             true,
    delimiter:           '',
    stripLeadingZeroes:  true,
    numeralPositiveOnly: true,
    numeralDecimalScale: 0,
  };

  // tab goes directly to minute/hour input rather than the form field
  @HostBinding('attr.tabindex') __tabindex = -1;

  constructor (
    @Optional() @Self() public ngControl: NgControl,
    private _focusMonitor: FocusMonitor,
    private _elementRef:   ElementRef<HTMLElement>
  ) {
    _focusMonitor.monitor(_elementRef, true)
    .subscribe((origin: any) => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    // update validators for the form
    this.max$
    .pipe(takeUntil(this.onDestroy))
    .subscribe(max => {
      this.form.setValidators(getValidators(max));
      this.form.updateValueAndValidity();
    });

    // update the form when a new value is set
    this._valueInMinutes$
    .pipe(takeUntil(this.onDestroy))
    .subscribe(x => {
      this.setFormValue(x);
      this.stateChanges.next();
    });

    // update error state
    this.form.statusChanges
    .pipe(takeUntil(this.onDestroy))
    .subscribe(() => {
      this.errorState = this.form.invalid;
      this.stateChanges.next();
    });

    // update the external value whenever the form value changes
    this.form.valueChanges
    .pipe(
      takeUntil(this.onDestroy),
      debounceTime(0)
    )
    .subscribe(x => {
      const minutes = formValueToTotalMinutes(x);
      const hours   = minutes != null ? minutes / 60 : null;
      this._valueInHours$.next(hours);
    });

    // emit when the external hour value is changed
    this._valueInHours$
    .pipe(takeUntil(this.onDestroy))
    .subscribe(x => {
      this.onChange(x);
      this.emitter.emit(x);
    });
  }

  protected setFormValue (numMinutes: number | null) {
    const val = totalMinutesToFormValue(numMinutes);

    // update form only if the value has changed
    if (this.form.value.hours !== val.hours || this.form.value.minutes !== val.minutes) {
      this.form.setValue(val);
    }
  }

  protected hoursKeydown (event: KeyboardEvent) {
    // if tab, focus the minutes input
    if (event.key === 'Tab' && ! event.shiftKey) {
      event.preventDefault();
      this.minutesInput?.nativeElement.focus();
    }
  }

  protected minutesKeydown (event: KeyboardEvent) {
    // if shift+tab, focus the hours input
    if (event.key === 'Tab' && event.shiftKey) {
      event.preventDefault();
      this.hoursInput?.nativeElement.focus();
    }

    // if backspace and empty, focus the hours input
    const nullishMinutes = this.form.value.minutes == null || this.form.value.minutes === '';
    if (event.key === 'Backspace' && nullishMinutes) {
      event.preventDefault();
      this.hoursInput?.nativeElement.focus();
    }
  }

  protected blur (input: 'hours' | 'minutes') {
    // the minutes or hour input has lost focus
    // > format the value to the nearest 5 minutes move any excess minutes to the hours input

    // if we blurred the hour format with a value, and the minutes input is empty, allow the formatting to take place with minutes = 0
    // otherwise, both inputs must have a value
    let { hours, minutes } = this.form.value;
    if (input === 'hours' && hours && ! minutes) minutes = '0';
    else if ( ! hours || ! minutes) return;

    // convert back and forth so that the value is rounded to the nearest 5 minutes and excess minutes are moved to the hours input
    const roundedTotMins = formValueToTotalMinutes({ hours, minutes });
    const val = totalMinutesToFormValue(roundedTotMins);

    // update form only if the value has changed
    if (this.form.value.hours !== val.hours || this.form.value.minutes !== val.minutes) {
      this.form.setValue(val);
    }
  }

  // @Input()
  // get min(): number | null { return this.onMin.value; }
  // set min(val: number | null) { this.onMin.next(val); }
  // private onMin = new BehaviorSubject<number | null>(null);

  @Input()
  get max(): number | null { return this.max$.value; }
  set max(val: number | null) { this.max$.next(val); }
  private max$ = new BehaviorSubject<number | null>(null);

  get empty() {
    return this._valueInMinutes$.value == null;
  }

  get shouldLabelFloat() { return this.focused || !this.empty; }

  get pristine(): boolean {
    return this._pristine;
  }
  set pristine(_val: boolean) {
    const val      = coerceBooleanProperty(_val);
    this._pristine = val;

    if (val) asyncScheduler.schedule(() => this.ngControl.control?.markAsPristine());

    this.stateChanges.next();
  }
  private _pristine:  boolean = true;

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string = '-';

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.form?.disable() : this.form?.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): number | null { return this._valueInHours$.value }
  set value(numHours: number | null) {
    this._valueInMinutes$.next(typeof numHours === 'number' ? numHours * 60 : null);
  }
  private _valueInMinutes$ = new BehaviorSubject<number | null>(null);
  private _valueInHours$   = new BehaviorSubject<number | null>(null)

  ngAfterViewInit(): void {
    // propagate valid state to the parent form control
    this.ngControl.control?.addValidators(() => {
      return this.form.valid ? null : this.form.errors;
    });
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();

    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick (event: MouseEvent) {
    if ( ! this.disabled) {
      // if we clicked inside the hours or minutes section, focus the corresponding input. Otherwise focus the hours input
      const $target = $(event.target!);
      if      ($target.closest('section.hours')  .length) this.hoursInput  ?.nativeElement.focus();
      else if ($target.closest('section.minutes').length) this.minutesInput?.nativeElement.focus();
      else                                                this.hoursInput  ?.nativeElement.focus();
    }
  }

  writeValue(val: number | null): void {
    this.value = val;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  _handleInput(): void {
    this.onChange(this.value);
  }

  static ngAcceptInputType_disabled: boolean | string | null | undefined;
  static ngAcceptInputType_required: boolean | string | null | undefined;
}
