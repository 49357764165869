import _                          from 'lodash';
import { BehaviorSubject,
         Subject,
         takeUntil              } from 'rxjs';
import { COMMA,
         ENTER,
         SPACE                  } from '@angular/cdk/keycodes';
import { Component,
         ElementRef,
         EventEmitter,
         Input,
         OnDestroy,
         Output,
         ViewChild              } from '@angular/core';
import { FormControl,
         FormGroup              } from '@angular/forms';
import { MatChipInputEvent      } from '@angular/material/chips';
import { EnvironmentService,
         UserPreferencesService } from '@app/core';
import { TableColumnsService    } from '../../../../services/table-columns/table-columns.service';


export type PasteData = {
  key:    keyof CourseForm['controls'],
  values: string[]
};

export type CourseForm = FormGroup<{
  ids:                FormControl<string | null>;
  displayName:        FormControl<string | null>;
  subject:            FormControl<string | null>;
  plannedDuration:    FormControl<string | null>;
  events:             FormControl<number[]>;
}>;

export function toMultipleOf5 (value: number | string | undefined | null): number {
  if (_.isNil(value)) return 0;
  const num = _.isString(value) ? parseInt(value) : value;
  if (isNaN(num)) return 0;
  return Math.floor(num / 5) * 5;
}

// export function toInteger (value: number | string | undefined | null): number {
//   if (_.isNil(value)) return 0;
//   const num = _.isString(value) ? parseInt(value) : value;
//   if (isNaN(num)) return 0;
//   return Math.floor(num);
// }

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class FormComponent implements OnDestroy {
  private onDestroy = new Subject<void>();

  protected readonly separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];

  protected readonly cleave_number = {
    numeral:             true,
    delimiter:           '',
    numeralPositiveOnly: true,
    stripLeadingZeroes:  true
  };

  protected displayName    = false;
  protected displaySubject = false;
  protected displayIds     = false;

  @ViewChild('entityInput') entityInput: ElementRef<HTMLInputElement>;
  protected entityCtrl = new FormControl<string | number>('');

  constructor (
    protected preferences: UserPreferencesService,
    protected columns:     TableColumnsService<'courses'>,
    private   _env:        EnvironmentService,
  ) {

    this._onBulk
    .pipe(takeUntil(this.onDestroy))
    .subscribe(bulk => {
      if (this._env.organizationType == 'sports_facility') {
        // sports facility
        this.displayName    = true;
        this.displaySubject = bulk === false;
      } else {
        // school
        this.displayName = bulk === false;
        this.displaySubject = true;
      }
      this.displayIds = bulk === false;
    });
  }

  ngOnDestroy () {
    this.onDestroy.next()
    this.onDestroy.complete()
  }

  protected addEvent (event: MatChipInputEvent) {
    if ( ! event.value) return;

    const duration = toMultipleOf5(event.value) || 5;

    const prevDurations = this.formGroup?.get('events')?.value || [];
    this.formGroup?.get('events')?.setValue([...prevDurations, duration]);

    // reset input after selection
    this.entityInput.nativeElement.value = '';
    this.entityCtrl.setValue(null);
  }

  protected removeEvent (index: number) {
    const prevDurations = this.formGroup?.get('events')?.value || [];
    prevDurations.splice(index, 1);
    this.formGroup?.get('events')?.setValue([...prevDurations]);
  }

  protected makeMultipleOf5 (control: FormControl<string | null>) {
    // abort if value is empty (empty value should not be rounded)
    const value = control?.value;
    if ( ! value) return;

    control?.setValue(toMultipleOf5(value).toString());
  }

  // protected makeInteger (control: FormControl<string | null> | null) {
  //   // abort if value is empty (empty value should not be rounded)
  //   const value = control?.value;
  //   if ( ! value) return;

  //   control?.setValue(toInteger(value).toString());
  // }

  protected paste (key: keyof CourseForm['controls'], event: ClipboardEvent) {
    // there must be clipboard data
    const pastedText = event.clipboardData?.getData('text/plain');
    if ( ! pastedText) return;

    // the form value must be empty
    if ( ! this.formGroup || (Array.isArray(this.formGroup.value[key]) ? this.formGroup.value[key]?.length : this.formGroup.value[key])) return;

    // there must be a newline in the clipboard data
    if ( ! pastedText.includes('\n')) return;
    const lines = pastedText.split('\n').map(x => x.trim()).filter(Boolean)

    // prevent the default paste action
    event.preventDefault();

    // emit the paste event
    this.onPaste.emit({ key, values: lines });
    return true;
  }

  protected pasteDurations (event: ClipboardEvent) {
    // there must be clipboard data
    const pastedText = event.clipboardData?.getData('text/plain');
    if ( ! pastedText) return;

    const newDurations = pastedText.split(',').map(x => toMultipleOf5(x)).filter(Boolean);
    const prevDurations = this.formGroup?.get('events')?.value || [];
    this.formGroup?.get('events')?.setValue([...prevDurations, ...newDurations]);

    // prevent the default paste action
    event.preventDefault();
  }

  ////
  //// IO
  ////
  @Input()
  set bulk (value: boolean | undefined) { this._onBulk.next(!! value) }
  private _onBulk = new BehaviorSubject<boolean>(false);

  @Input()
  formGroup?: CourseForm;

  @Output()
  removed = new EventEmitter<void>();

  @Output()
  onPaste = new EventEmitter<PasteData>();
}
