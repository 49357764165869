import { InputAnalysisService           } from './input-analysis.service';
import { Code, Remark, VerifiedOut      } from './types';



export function processVerified (
  this:              InputAnalysisService,
  { version, data }: VerifiedOut
): Remark[] {
  if (data?.criticalVerifierErrors   ) (this.criticalVerifierErrors    as any) = data.criticalVerifierErrors;
  if (data?.noncriticalVerifierErrors) (this.noncriticalVerifierErrors as any) = data.noncriticalVerifierErrors;
  this.criticalErrorEncountered = (this.criticalVerifierErrors?.length ?? 0) > 0;


  const remarks = new Array<Remark>();

  this.criticalVerifierErrors?.forEach(err => {
    console.warn(err)
    this._logger.error(new Error(`Critical error ${err.code} from input verifier: ${JSON.stringify(err.msg)} and ${JSON.stringify(err.src)}`));
    remarks.push(this.createRemark('error', err.code as Code, version, undefined, 0));
  });

  this.noncriticalVerifierErrors?.forEach(err => {
    // locate first supported type in source stack
    const entity = err.src.find(x => x.type == 'event' || x.type == 'forced overlapping events set');
    if ( ! entity) {
      this._logger.error(new Error(`Could not find a supported entity type for error ${err.code}`));
      return;
    }

    if (entity?.type == 'event') {
      const event = this.findEvent(entity.val.id);
      if ( ! event) return;
      remarks.push(this.createRemark('error', err.code as Code, version, event, 20));
    }
    else if (entity?.type == 'forced overlapping events set') {
      const events = entity.val.map((x: { id: string }) => this.findEvent(x.id));
      remarks.push(this.createRemark('error', err.code as Code, version, events, 20));
    }
    else {
      this._logger.error(new Error(`Could not find a supported entity type for error ${err.code}`));
      return;
    }

  });

  return remarks;
}