import { Component,
         OnInit,
         Input,
         ElementRef,
         OnDestroy,
         Optional,
         Self,
         HostBinding,
         EventEmitter,
         Output,
         ViewEncapsulation,
         ChangeDetectionStrategy,
         ViewChild                 } from '@angular/core';
import { FormGroup,
         UntypedFormControl,
         ControlValueAccessor,
         NgControl,
         FormArray,
         UntypedFormBuilder,
         Validators }                from '@angular/forms';
import { Subject,
         BehaviorSubject           } from 'rxjs';
import { takeUntil                 } from 'rxjs/operators';
import { MatRadioChange            } from '@angular/material/radio';
import { MatFormFieldControl       } from '@angular/material/form-field';
import { FocusMonitor              } from '@angular/cdk/a11y';
import { coerceBooleanProperty     } from '@angular/cdk/coercion';
import { coerceNumberProperty      } from '@angular/cdk/coercion';
import { MatSelect                 } from '@angular/material/select';
import moment                        from 'moment';

import { DateService               } from 'app/shared/services';

import { TranslateService          } from 'app/core';

import { commonConstants           } from 'app/constants';

@Component({
  selector: 'app-form-field-day',
  templateUrl: './day.component.html',
  styleUrls: ['./day.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: DayComponent
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DayComponent implements OnInit,
                                     OnDestroy,
                                     ControlValueAccessor,
                                     MatFormFieldControl<number> {
  @ViewChild(MatSelect)      trigger:           MatSelect;
  @Output('onChange') emitter: EventEmitter<number> = new EventEmitter<number>();
  public placeholderText:  string          = '';
  static nextId:       number              = 0;
  public stateChanges: Subject<void>       = new Subject<void>();
  public focused:      boolean             = false;
  public errorState:   boolean             = false;
  public controlType:  string              = 'day-input';
  public id:           string              = `day-input-${ DayComponent.nextId++ }`;
  public describedBy:  string              = '';
  public onChange = (_: any) => {};
  public onTouched = () => {};
  public control:      UntypedFormControl         = new UntypedFormControl();
  private onDestroy:   Subject<boolean>    = new Subject<boolean>();

  @HostBinding('attr.tabindex') __tabindex = 0;

  constructor(private _fb:           UntypedFormBuilder,
              public date:           DateService,
              private _focusMonitor: FocusMonitor,
              private _translate:    TranslateService,
              private _elementRef:   ElementRef<HTMLElement>,
              @Optional() @Self() public ngControl: NgControl) {

    _focusMonitor.monitor(_elementRef, true)
    .subscribe((origin: any) => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
  }

  get empty() {
    return this.control?.value == null;
  }

  get valid(): boolean {
    return this.control?.valid;
  }

  get displayValue(): string {
    const { value } = this.control;
    let day : string   = this.date.getTranslatedDayName(value);
    return `${ day }`;
  }

  get shouldLabelFloat() { return (this.focused && ! this.disabled) || ! this.empty; }

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    if (this._disabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get numDays(): number { return this._numDays; }
  set numDays(value: number) {
    this._numDays = coerceNumberProperty(value, 5);
    this._days    = [...Array(this._numDays).keys()];
    this.stateChanges.next();
  }
  private _numDays = 5;
  private _days    = [...Array(5).keys()];

  get days(): number[] {
    return this._days;
  }

  @Input()
  get value(): number {
    const { value } = this.control;
    return value;
  }
  set value(_val: number) {
    let val = _val;
    this.control?.setValue(val);
    this.stateChanges.next();
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
    this.onDestroy.next(true);
    this.onDestroy.complete();
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent) {
    this.trigger?.open();
  }

  writeValue(val: number): void {
    this.value = val;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  _handleInput(): void {
    this.emitter.emit(this.value);
    this.onChange(this.value);
  }

  static ngAcceptInputType_disabled: boolean | string | null | undefined;
  static ngAcceptInputType_required: boolean | string | null | undefined;

}
