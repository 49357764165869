import { NgModule                        } from '@angular/core';
import { CommonModule                    } from '@angular/common';

import { AppCommonModule                 } from 'app/common/common.module';

import { DisplayValueComponent           } from './display-value/display-value.component';
import { FilterAvailablePipe             } from './display-value/display-value.pipe';
import { PipesModule                     } from '@app/shared/pipes/pipes.module';
import { TranslationModule               } from '@app/core/translate/translate.module';
import { NgPipesModule                   } from 'ngx-pipes';

@NgModule({
  declarations: [
    DisplayValueComponent,
    FilterAvailablePipe
  ],
  exports: [
    DisplayValueComponent
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    PipesModule,
    TranslationModule,
    NgPipesModule
  ]
})
export class ComponentsModule { }
