<mat-drawer-container class="drawer-container">
  <!-- <mat-drawer #filterNav class="filter-container" mode="side" position="end">
    <app-filter #filterComponent></app-filter>
  </mat-drawer> -->

  <div class="royal-table-container">

    <mat-toolbar>

      <app-search class="search"></app-search>

      <!-- <button mat-flat-button
              type="button"
              color="primary"
              [matBadge]="filterComponent?.activeFilters"
              matTooltip="{{ 'common.filter' | translate }}"
              (click)="showFilter = ! showFilter; filterNav.toggle()"
              class="filter">
        <mat-icon class="white-icon add-icon">filter_list</mat-icon>
      </button> -->

      <div class="spacer"></div>

      <button mat-mini-fab
              type="button"
              color="primary"
              matTooltip="{{ 'tables.admin-tables.users.actions.add' | translate }}"
              class="add"
              (click)="openAddEditDialog()">
        <mat-icon class="white-icon add-icon">add</mat-icon>
      </button>
    </mat-toolbar>

    <div class="table-wrapper">
      <app-loading [loading]="dataSource.isLoading"></app-loading>

      <div class="table-container dark-scrollbar">
        <app-fetch-fail-pane [show]="dataSource.error"></app-fetch-fail-pane>

        <table mat-table [dataSource]="dataSource" class="table" matSort>

          <ng-container matColumnDef="username">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'tables.admin-tables.users.columns.username' | translate }}</th>
            <td mat-cell *matCellDef="let element">{{ element.username }}</td>
          </ng-container>

          <ng-container matColumnDef="company">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'tables.admin-tables.users.columns.organization' | translate }}</th>
            <td mat-cell *matCellDef="let element">{{ element.company?.name }}</td>
          </ng-container>

          <ng-container matColumnDef="role">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'tables.admin-tables.users.columns.role' | translate }}</th>
            <td mat-cell *matCellDef="let element">{{ element.role | role }}</td>
          </ng-container>

          <ng-container matColumnDef="associatedPartner">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'common.associated_partner' | translate }}</th>
            <td mat-cell *matCellDef="let element">
              <app-icon [value]="element.associatedPartner"></app-icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="activation">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="verifyToken">{{ 'tables.admin-tables.users.columns.activation' | translate }}</th>

            <td mat-cell *matCellDef="let element">
              <button
                mat-icon-button
                [matTooltip]="'tables.admin-tables.users.actions.copy_verification_link' | translate"
                *ngIf="element.verifyToken"
                (click)="copyVerificationLink(element)"
              >
                <mat-icon class="royal-icon">filter_none</mat-icon>
              </button>
            </td>
          </ng-container>

          <ng-container matColumnDef="online">
            <th mat-header-cell *matHeaderCellDef>{{ 'tables.admin-tables.users.columns.online' | translate }}</th>
            <td mat-cell *matCellDef="let element">
              <mat-icon [ngClass]=" element.online ? 'online' : 'offline'">public</mat-icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="lastLogin">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'tables.admin-tables.users.columns.last_login' | translate }}</th>
            <td mat-cell *matCellDef="let element">
              <span [matTooltip]="element.lastLogin | localeDate">
                {{ onNow | async | durationSince:element.lastLogin | capitalizeFirst }}
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="createdAt">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'common.created_at' | translate }}</th>
            <td mat-cell *matCellDef="let element">
              <span [matTooltip]="element.createdAt | localeDate">
                {{ onNow | async | durationSince:element.createdAt | capitalizeFirst }}
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="actions" stickyEnd>
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <app-table-action-buttons [element]="element" [buttons]="actionButtons"></app-table-action-buttons>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>

          <tr mat-row *matRowDef="let row; columns: columns;"></tr>

        </table>
      </div>

      <mat-paginator class="paginator"
                    *ngIf="paginator"
                    [length]="dataSource ? dataSource.resultsLength : 0"
                    [pageIndex]="0"
                    [pageSize]="pageSize"
                    [pageSizeOptions]="pageSizes">
      </mat-paginator>
    </div>
  </div>

</mat-drawer-container>