import { Component,
         ChangeDetectionStrategy,
         Input                           } from '@angular/core';
import { coerceNumberProperty            } from '@angular/cdk/coercion';
import moment                              from 'moment';
import _                                   from 'lodash';

import { TranslateService                } from 'app/core';

import { DateService                     } from 'app/shared/services';
import { Populated as P,                 } from 'app/shared/interfaces';
import { DisplayNamePipe                 } from '@app/shared/pipes/common/common.pipe';

type LunchLabel = {
  day:              number;
  start:            string;
  end:              string;
  duration?:         number;
  durationVariance?: number;
  locations?:        any;
}

@Component({
  selector: 'app-form-field-dynamic-locked-times-display-value',
  templateUrl: './display-value.component.html',
  styleUrls: ['./display-value.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DisplayValueComponent {
  public type: string | undefined;

  constructor(private _translate:   TranslateService,
              public date:          DateService,
              private _displayName: DisplayNamePipe) { }

  private _updateLabel(): void {
    this.type = undefined;
    if (this._value?.length === 0) {
      this._label   = this._translate.instant('attributes.shared.dynamicLockedTime.none');
      this._tooltip = '';
    } else if (this._value?.length! >= 1) {
      if (! this._value?.[0]?.intervals?.[0])
        return;
      if (this._value.length !== this._numDays)
        this.type = 'multiple';
      else {
       const distinct: (Partial<P.lockedTime> | string)[] = _.uniqBy(this._value.flat(), (elem) => {
          const { coalesced, duration, durationVariance } = elem;
          return JSON.stringify({
            coalesced: coalesced?.filter(x => x.toModel == 'locations')
              // according to its type "x.to" should not be null, but it sometimes is...
              // (https://app.royalschedule.com/app-logs/6409d564db681a47f19a0eda)
              .filter(x => !! x.to)
              .map(x => x.to.id),
            duration,
            durationVariance
          });
        });

        if (distinct.length != 1) {
          this.type = 'multiple';
        } else {
          const distinctIntervals = _.uniq(_.map(this._value, ({ intervals }) => (intervals?.map(({ start, end }) => moment.utc(start).format('HHmm') + moment.utc(end).format('HHmm'))))?.flat());
          if (distinctIntervals.length == 1)
            this.type = 'single';
          else
            this.type = 'multiple';
        }
      }
      if (this.type == 'multiple') {
        this._label = this.days.map(d => {
          const lunch = this._value?.find(x => DateService.getDayIndex(moment.utc(x.start)) == d);
          if (lunch) {
            return {
              day:              d,
              start:            moment.utc(lunch?.intervals?.[0]?.start).format('HH:mm'),
              end:              moment.utc(lunch?.intervals?.[0]?.end).format('HH:mm'),
              duration:         lunch.duration,
              durationVariance: lunch.durationVariance,
              locations:        lunch.coalesced?.filter(x => x.toModel == 'locations').map(({ to }) => this._displayName.transform(to))
            }
          } else {
            return undefined;
          }
        });
        this._tooltip = this._label.map((x, d) => {
          const day = this.date.getTranslatedDayName(d, 'short');
          if (x) {
            return [
              `${ day }: ${ x.start } - ${ x.end }`,
              `${ x.duration } ± ${ x.durationVariance ?? 0 } ${ this._translate.instant('common.minutes_short') }`,
              x.locations?.join(', ')
            ].filter(Boolean).join(', ');
          } else {
            return `${ day }: -`;
          }
        }).join('\n\n');
      } else {
        const { intervals: [{ start, end }], duration, durationVariance, coalesced } = this._value[0];
        const locations = coalesced?.filter(x => x.toModel == 'locations').map(({ to }) => this._displayName.transform(to))
        const _val    = [
          `${ moment.utc(start).format('HH:mm') } - ${ moment.utc(end).format('HH:mm') }`,
          `${ duration } ± ${ durationVariance ?? 0 } ${ this._translate.instant('common.minutes_short') }`,
          locations?.join(', ') ?? ''
        ];
        this._label   = _val;
        this._tooltip = Array.from({ length: this._numDays }, (_, d) => {
          const day = this.date.getTranslatedDayName(d, 'short');
            return day + ': ' + _val.filter(Boolean).join(', ');
        }).join('\n\n');
      }
    } else if (this._value === null) {
      this._label   = this._translate.instant('attributes.shared.dynamicLockedTime.default');
      this._tooltip = '';
    } else {
      this._label   = '';
      this._tooltip = '';
    }
  }

  @Input()
  set value(value: Partial<P.lockedTime>[] | null | undefined) {
    this._value = value;
    /*if (_.inRange(value?.length!, 2, this.numDays) || value?.length == this.numDays)
      this.type = 'multiple';
    else
      this.type = undefined;*/
    this._updateLabel();
  }
  public _value: Partial<P.lockedTime>[] | null | undefined;

  get label(): string | string[] | (LunchLabel | undefined)[] | undefined {
    return this._label;
  }
  private _label: string | string[] | (LunchLabel | undefined)[] | undefined;

  get tooltip(): string {
    return this._tooltip;
  }
  private _tooltip: string;

  @Input()
  get voidText(): string { return this._voidText; }
  set voidText(value: string) {
    this._voidText = value;
  }
  private _voidText: string = '';

  get days(): number[] {
    return this._days;
  }
  private _days = [...Array(5).keys()];

  @Input()
  get numDays(): number { return this._numDays; }
  set numDays(value: number) {
    this._numDays = coerceNumberProperty(value, 5);
    this._days    = [...Array(this._numDays).keys()];
    this._updateLabel();
  }
  private _numDays = 5;


  protected isStringArray (value: any): string[] | undefined {
    return value.every((x: any) => typeof x == 'string') ? value : undefined;
  }

  protected isMultipleDayArray (value: any): LunchLabel[] | undefined {
    return _.isArray(value) ? value : undefined;
  }
}
