import { Injectable                      } from '@angular/core';
import { HttpClient,
         HttpHeaders,
         HttpResponse,
         HttpParams                      } from '@angular/common/http';
import { Observable,
         Subject                         } from 'rxjs';
import { retryWhen,
         map                             } from 'rxjs/operators';

import { environment                     } from 'environments';

import { retryStrategy                   } from './retry';

import { Http                            } from './http.interface';

@Injectable({
  providedIn: 'root'
})
export class HttpService implements Http {
  protected onStatus: Subject<void> = new Subject<void>();
  protected retryStrategy = retryStrategy;

  constructor(protected _http:   HttpClient) { }

  public watchStatus(): Observable<void> {
    return this.onStatus.asObservable();
  }

  public get(url:string, params?:any & { headers: any }, skipRetry?: boolean): Observable<any> {
    let headers = new HttpHeaders(params?.headers);
    delete params?.headers;
    headers = headers.set('paginate', 'false');

    return this._http.get(
      environment.API_BASE_URL + url,
      {
        params:          new HttpParams({ fromObject: params }),
        headers:         headers,
        reportProgress:  true,
        observe:         'response',
        //withCredentials: true
      }
    )
    .pipe(
      retryWhen(this.retryStrategy(skipRetry)),
      map((res: HttpResponse<any>) => res.body)
    );
  }

  public getEncoded(url:string, params?:any): Observable<any> {

    return this._http.get(environment.API_BASE_URL + url, {
      params:         new HttpParams({ fromObject: params }),
      reportProgress: true,
      responseType:   'text'
    })
    .pipe(
      retryWhen(this.retryStrategy())
    )
  }

  public getBare(url:string, params?:any): Observable<any> {

    return this._http.get(url, {
      params:         new HttpParams({ fromObject: params }),
      reportProgress: true,
      responseType:   'blob'
    })
    .pipe(
      retryWhen(this.retryStrategy())
    )
  }

  public getPaginated(url:string, params:any, _options: any) {
    let headers = new HttpHeaders();
    headers = headers.set('page', JSON.stringify(_options.page ?? 1));
    headers = headers.set('per-page', JSON.stringify(_options.perPage ?? 10));
    if (_options.sortId) {
      headers = headers.set('sort-id', _options.sortId);
      headers = headers.set('sort-order', _options.sortOrder);
    }

    return this._http
    .get(
      environment.API_BASE_URL + url,
      {
        headers: headers,
        observe: 'response',
        reportProgress: true,
        params: new HttpParams({ fromObject: params })
      }
    )
    .pipe(
      retryWhen(this.retryStrategy()),
      map((res: HttpResponse<any>) => res.body)
    );
  }

  public post(url:string, body:any, params?:any, skipRetry?: boolean): Observable<any> {
    return this._http
    .post(
      environment.API_BASE_URL + url,
      body,
      {
        params: new HttpParams({ fromObject: params }),
        observe: 'response',
        reportProgress: true,
      }
    )
    .pipe(
      retryWhen(this.retryStrategy(skipRetry)),
      map((res: HttpResponse<any>) => res.body)
    );
  }

  public patch(url:string, body:any, params?:any): Observable<any> {
    return this._http
    .patch(
      environment.API_BASE_URL + url,
      body,
      {
        params: new HttpParams({ fromObject: params }),
        observe: 'response',
        reportProgress: true,
      }
    )
    .pipe(
      retryWhen(this.retryStrategy()),
      map((res: HttpResponse<any>) => res.body)
    );
  }

  public file(url: string, file: File, params?: any): Observable<any> {
    let formData: FormData = new FormData();
    formData.set('file', file, file.name);
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/json');
    return this._http
    .post(
      environment.API_BASE_URL + url,
      formData,
      {
        params: new HttpParams({ fromObject: params }),
        headers: headers,
        observe: 'response',
        reportProgress: true,
      })
    .pipe(
      retryWhen(this.retryStrategy()),
      map((res: HttpResponse<any>) => res.body)
    );
  }

  public fileAndData(url: string, file: File, data: any, params?: any): Observable<any> {
    let formData: FormData = new FormData();
    formData.set('file', file, file.name);
    formData.set('data', JSON.stringify(data)) // appears in "req.body.data"
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/json');
    return this._http
    .post(
      environment.API_BASE_URL + url,
      formData,
      {
        params: new HttpParams({ fromObject: params }),
        headers: headers,
        observe: 'response',
        reportProgress: true,
      })
    .pipe(
      retryWhen(this.retryStrategy()),
      map((res: HttpResponse<any>) => res.body)
    );
  }

  public delete(url:string, params?:any):Observable<any> {
    return this._http.delete(environment.API_BASE_URL + url, { params: new HttpParams({ fromObject: params }), observe: 'response' })
    .pipe(
      retryWhen(this.retryStrategy()),
      map((res: HttpResponse<any>) => res.body)
    );
  }
}