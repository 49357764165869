import { NgModule                        } from '@angular/core';
import { CommonModule                    } from '@angular/common';
import { FormsModule,
         ReactiveFormsModule             } from '@angular/forms';

import { CalendarModule                  } from 'app/shared/calendar/calendar.module';

import { TranslationModule               } from 'app/core/translate/translate.module';

import { AppCommonModule                 } from 'app/common/common.module';

import { ComponentsModule                } from 'app/shared/components/components.module';

import { DynamicCleaveDirective          } from './text.directive';
import { TextComponent                   } from './text.component';
import { NgxCleaveDirectiveModule        } from 'ngx-cleave-directive';

@NgModule({
  declarations: [
    TextComponent,
    DynamicCleaveDirective
  ],
  exports: [
    TextComponent
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    TranslationModule,
    CalendarModule,
    FormsModule,
    ComponentsModule,
    ReactiveFormsModule,
    NgxCleaveDirectiveModule
  ]
})
export class TextFormFieldModule { }
