import { Component,
         OnInit                          } from '@angular/core';
import { MatDialogRef                    } from 'app/common';

@Component({
  templateUrl: './unsaved-changes.component.html',
  styleUrls: ['./unsaved-changes.component.scss']
})
export class UnsavedChangesComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<UnsavedChangesComponent, 'discard' | 'save'>) { }

  ngOnInit(): void {
  }

  public close(action: 'discard' | 'save'): void {
    this.dialogRef.close(action);
  }

}
