import { Component,
         OnInit,
         Input,
         OnDestroy                       } from '@angular/core';
import { Validators,
         FormControl,
         FormGroup                       } from '@angular/forms';
import { Observable,
         Subject                         } from 'rxjs';
import { takeUntil,
         filter,
         map                             } from 'rxjs/operators';

import { TranslateService                } from 'app/core';
import { MatSnackBar                     } from 'app/common';

import { ExtendedValidators              } from '../validators';

type Form = {
  username: FormControl<null | string>;
  password: FormGroup<{
    password:        FormControl<string>;
    confirmPassword: FormControl<string>;
  }>;
  oldPassword: FormControl<null | string>;
};

@Component({
  selector: 'app-change-password-form',
  templateUrl: './change-password-form.component.html',
  styleUrls: [
    './change-password-form.component.scss',
    '../styles.scss'
  ]
})
export class ChangePasswordFormComponent implements OnInit,
                                                    OnDestroy {
  public form = new FormGroup<Form>({
    username: new FormControl(null, { }),
    password: new FormGroup({
      password:        new FormControl('', { nonNullable: true, validators: [Validators.required, Validators.minLength(5)] }),
      confirmPassword: new FormControl('', { nonNullable: true, validators: [Validators.required, Validators.minLength(5)] })
    }, ExtendedValidators.compare('password', 'confirmPassword')),
    oldPassword: new FormControl(null, { })

  });

  public hiddenFields:any = {
    oldPassword: true
  };
  private onDestroy = new Subject<boolean>();
  private onReset = new Subject<boolean>();

  constructor(private _snackBar: MatSnackBar,
              private _translate: TranslateService) {
    this.resetForm();
  }

  ngOnInit() {
  }

  @Input()
  set username(val: string) {
    let _val: any = val ?? '';
    this.form.controls.username.setValue(_val, { emitEvent: false })
  }

  @Input()
  set oldPassword (val: any) {
    this.hiddenFields.oldPassword = false;
    this.setForm({  }, { hiddenFields: this.hiddenFields} );
  }

  public setForm (
    data: { username?: string },
    options: {
      filterInvalid?: boolean,
      hiddenFields?: any
    } = {
      filterInvalid: true,
      hiddenFields: { oldPassword: true }
    }
  ): Observable<any> {
    this.resetForm();
    if (options?.hiddenFields?.oldPassword !== true) {
      this.form.controls.oldPassword.setValidators(Validators.compose([Validators.required]))
      this.form.controls.oldPassword.updateValueAndValidity();
    }

    if (data.username) {
      this.form.controls.username.setValue(data?.username, { emitEvent: false })
    }

    /*
      destroy current observer if it exist
    */
    this.onReset.next(true);

    /*
      set values to course values
    */
    this.hiddenFields = options.hiddenFields;

    /*
      observer called every time the form is changed and valid
    */
    return this.form.valueChanges
      .pipe(
        takeUntil(this.onDestroy),
        takeUntil(this.onReset),
        filter((x) => {
          return ! options.filterInvalid || this.form.valid;
        }),
        map((val:any) => {
          return {
            password:    val.password.password,
            oldPassword: val.oldPassword,
          }
        }));
  }

  public resetForm():void {
    this.form.reset();
  }

  ngOnDestroy() {
    this.onDestroy.next(true);
    this.onDestroy.complete();
    this.onReset.complete();
  }
}
