<form class="search-field select-wide flex-align-center dense-5" [class.white]="white">

  <mat-form-field [appearance]="appearance" subscriptSizing="dynamic">
    <input matInput [formControl]="control" [placeholder]="placeholder | translate">
    <mat-icon matPrefix [class.white]="white">search</mat-icon>
    <button mat-icon-button
            matSuffix
            type="button"
            [class.hidden]=" ! control.value"
            (click)="control.setValue('')">
      <mat-icon [class.white]="white">clear</mat-icon>
    </button>
  </mat-form-field>

</form>