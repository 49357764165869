<!-- force rerender on stateChanges.next() otherwise it is not guaranteed to show the latest values due to some lazy rendering-->
{{ stateChanges | async }}

<div class="intervals-select-label clickable"
     [class.intervals-small-label]="form?.value?.type == 'multiple'"
     (menuClosed)="closed()"
     (menuOpened)="opened()"
     [matMenuTriggerFor]="menu">
  <app-form-field-intervals-display-value [value]="value"
                                          [numDays]="numDays"
                                          [availableDays]="availableDays"
                                          [inheritAvailableDays]="inheritAvailableDays"
                                          [inherit]="inherit"
                                          [inheritValue]="inheritValue"
                                          [voidText]="voidText">
  </app-form-field-intervals-display-value>
</div>

<mat-menu #menu yPosition="below"
          class="interval-panel no-vertical-padding transition-all"
          [overlapTrigger]="true">
  <ng-template matMenuContent>

    <div *ngIf="(onNumDays | async) == null">
      {{ 'common.loading' | translate }}
    </div>

    <ng-container *ngIf="onNumDays | async">

      <div class="toolbar" (click)="$event.stopPropagation()">
        <div class="row">
          <button mat-stroked-button
                  class="action reset"
                  color="primary"
                  (click)="resetValue()"
                  *ngIf="! pristine && reset && valid">
            <div>
              <span>{{ 'common.restore' | translate }}</span>
              <mat-icon class="royal-icon">undo</mat-icon>
            </div>
          </button>

          <button mat-stroked-button
                  class="action invalid"
                  *ngIf="! valid"
                  color="primary">
            <div>
              <span>{{ 'common.invalid' | translate }}</span>
              <mat-icon class="royal-icon">warnings</mat-icon>
            </div>
          </button>
        </div>
      </div>

      <div (click)="$event.stopPropagation()"
          class="content"
          *ngIf="! isVoid && form"
          [formGroup]="form">

        <mat-radio-group class="radio-group" formControlName="type">
          <mat-radio-button value="none" class="radio-button">
            {{ setVoidText || ('common.no_value' | translate) }}
          </mat-radio-button>
          <mat-radio-button value="single" class="radio-button">
            {{ 'attributes.shared.intervals.single' | translate }}
          </mat-radio-button>
          <mat-radio-button value="multiple" class="radio-button">
            {{ 'attributes.shared.intervals.multiple' | translate }}
          </mat-radio-button>
        </mat-radio-group>

        <div [ngSwitch]="form.value.type" class="container">

          <ng-container *ngSwitchCase="'single'" formGroupName="single">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'attributes.shared.start' | translate }}</mat-label>
              <app-form-field-time formControlName="start"></app-form-field-time>
              <mat-error><app-form-field-error [control]="form.controls.single.controls.start"></app-form-field-error></mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>{{ 'attributes.shared.end' | translate }}</mat-label>
              <app-form-field-time formControlName="end"></app-form-field-time>
              <mat-error><app-form-field-error [control]="form.controls.single.controls.end"></app-form-field-error></mat-error>
            </mat-form-field>
          </ng-container>

          <ng-container *ngSwitchCase="'multiple'" formGroupName="multiple">
            <div class="grid dense-3">
              <ng-container *ngFor="let control of form.controls.multiple.controls; let i = index"
                            [formGroup]="form.controls.multiple.at(i)">
                <div class="day">{{ 'common.day_short_' + i | translate }}</div>
                <mat-form-field appearance="outline" subscriptSizing="dynamic">
                  <mat-label>{{ 'attributes.shared.start' | translate }}</mat-label>
                  <app-form-field-time formControlName="start"></app-form-field-time>
                  <mat-error><app-form-field-error [control]="control.controls.start"></app-form-field-error></mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" subscriptSizing="dynamic">
                  <mat-label>{{ 'attributes.shared.end' | translate }}</mat-label>
                  <app-form-field-time formControlName="end"></app-form-field-time>
                  <mat-error><app-form-field-error [control]="control.controls.end"></app-form-field-error></mat-error>
                </mat-form-field>
              </ng-container>
            </div>
          </ng-container>

        </div>

      </div>

      <mat-divider class="divider"
                  *ngIf="! disableActions"
                  (click)="$event.stopPropagation()"></mat-divider>

      <div (click)="$event.stopPropagation()" class="actions" *ngIf="! disableActions">
        <button mat-stroked-button
                color="primary"
                type="button"
                class="button"
                (click)="trigger?.closeMenu()">
          {{ 'common.cancel' | translate }}
        </button>
        <button mat-flat-button
                color="primary"
                type="button"
                [disabled]="! valid"
                (click)="submit(); trigger?.closeMenu()"
                class="button right">
          {{ 'common.save' | translate }}
        </button>
      </div>
    </ng-container>
  </ng-template>

</mat-menu>


