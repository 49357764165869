import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component,
         Input                 } from '@angular/core';

import { Util                  } from '@app/common';
import { inOutAnimation        } from '@app/shared/animations';


@Component({
  selector: 'app-empty',
  templateUrl: './empty.component.html',
  styleUrls: ['./empty.component.scss'],
  animations: [ inOutAnimation ],
})
export class EmptyComponent {
  @Input()
  set visible (value: boolean | string | null | undefined) { this._visible = coerceBooleanProperty(value); }
  get visible () { return this._visible; }
  private _visible = false;

  @Input()
  set collection (value: Util.Types.Collection | 'holidays') {
    // console.log(value);
    if (value === 'periods'  ) this.entityTranslationKey = 'common.periods';   // also used in the periods module
    if (value === 'locations') this.entityTranslationKey = 'common.locations';
    if (value === 'persons'  ) this.entityTranslationKey = 'common.persons';
    if (value === 'teachers' ) this.entityTranslationKey = 'common.teachers';
    if (value === 'groups'   ) this.entityTranslationKey = 'common.groups';
    if (value === 'courses'  ) this.entityTranslationKey = 'common.courses';
    if (value === 'events'   ) this.entityTranslationKey = 'common.events';
    if (value === 'holidays' ) this.entityTranslationKey = 'common.holidays';
  }
  protected entityTranslationKey: string;
}
