import { TemplatePortal                  } from '@angular/cdk/portal';
import { Injectable,
         OnDestroy                       } from '@angular/core';
import { asyncScheduler,
         BehaviorSubject,
         combineLatest,
         MonoTypeOperatorFunction,
         Observable,
         Subject,
         timer                           } from 'rxjs';
import { debounceTime,
         distinctUntilChanged,
         filter,
         map,
         shareReplay,
         switchMap,
         takeUntil                       } from 'rxjs/operators';
import _                                   from 'lodash';
import moment                              from 'moment';

import { LoggerService,
         sourceSelectPipe,
         SourceService                   } from '@app/core';
import { MatDialog,
         MatDialogRef                    } from '@app/common';
import { Day,
         Division,
         Generation                      } from '@app/shared/interfaces';
import { FailedGenerationDialogComponent } from '../components/failed-generation-dialog/failed-generation-dialog.component';

@Injectable()
export class SharedService implements OnDestroy {
  private readonly onDestroy = new Subject<void>();
  public readonly  onRetry   = new Subject<void>();
  public readonly settings   = new BehaviorSubject<Division['settings'] | null>(null);

  public error = false;

  /** @description toggles the loader of the "sub-page" component */
  public subscribeToLoading (
    loading:   Observable<boolean>,
    completed: Observable<unknown> | MonoTypeOperatorFunction<unknown>
  ) {
    loading = loading.pipe(
      // make sure it completes since we rely on the complete event to remove it from the list
      (completed instanceof Observable ? takeUntil(completed) : completed),
      // ensure that the observable has a value such that the combineLatest fires immediately
      shareReplay(1)
    ) as Observable<boolean>;

    // add to the observable list
    this.globalLoadingObservables.next([...this.globalLoadingObservables.value, loading]);

    // when the observable completes, remove it from the list
    loading
    .pipe(takeUntil(this.onDestroy))
    .subscribe({
      complete: () => this.globalLoadingObservables.next(this.globalLoadingObservables.value.filter(x => x != loading))
    });
  }
  private readonly globalLoadingObservables = new BehaviorSubject<Observable<Boolean>[]>([]);

  public readonly globalLoading = this.globalLoadingObservables.pipe(
    switchMap(observables => combineLatest(observables)),
    map(observables => observables.some(Boolean)),
    // to prevent ExpressionChangedAfterItHasBeenCheckedError
    debounceTime(0),
  );


  constructor (
    private _source: SourceService,
    private _logger: LoggerService,
    private _dialog: MatDialog
  ) { }

  public ngOnDestroy (): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  public get toolbar() { return this._portal }
  public set toolbar(portal: TemplatePortal<unknown> | null) {
    asyncScheduler.schedule(() => this._portal = portal);
  }
  private _portal: TemplatePortal<unknown> | null


  public get did (): string { return this._did }
  public set did (did: string) {
    // proceed only if did has changed
    if (this.did == did) return;

    // store did
    this._did = did;

    // unsubscribe previous subscriptions
    this.onNewDid.next();

    // listen to division changes
    this._source
    .getStrictDivision({ did: this.did, coalesced: ['generations'], onDestroy: this.onDestroy })
    .pipe(
      takeUntil(this.onDestroy),
      takeUntil(this.onNewDid)
    )
    .subscribe(division => {

      const { running } = division;
      if ( ! _.isEqual(running, this._onRunning.value)) this._onRunning.next(running);

      const isRunning = running?.status == 'PENDING' || running?.status == 'STARTED';
      if ( ! _.isEqual(isRunning, this._onIsRunning.value)) this._onIsRunning.next(isRunning);

      const editable = ! isRunning;
      if ( ! _.isEqual(editable, this._onEditable.value)) this._onEditable.next(editable);

      const runningProgress = running?.progress ?? null;
      if ( ! _.isEqual(runningProgress, this._onRunningProgress.value)) this._onRunningProgress.next(runningProgress);
    });

    // listen to division settings changes
    this._source
    .getStrictSettings({ did: this.did, onDestroy: this.onDestroy })
    .pipe(
      takeUntil(this.onDestroy),
      takeUntil(this.onNewDid),
    )
    .subscribe(settings => {

      this.settings.next(settings);

      const { numDays = null } = settings;
      if ( ! _.isEqual(numDays, this._onNumDays.value)) this._onNumDays.next(numDays);

      const days = Array.from({ length: numDays ?? 0 }, (_, i) => ({ is: 'day' as const, day: i }));
      if ( ! _.isEqual(days, this._onDays.value)) this._onDays.next(days);
    });

    // listen for a failed job
    let failedJobDialogRef: null | MatDialogRef<FailedGenerationDialogComponent> = null;
    this._source
    .getStrictDivision({ did: this.did, onDestroy: this.onDestroy })
    .pipe(
      takeUntil(this.onDestroy),
      takeUntil(this.onNewDid),
      map(division => division.running?.id),
      filter(Boolean),
      distinctUntilChanged(),
      switchMap(id => this._source.getStrictGenerations({ did: this.did, onDestroy: this.onDestroy }, sourceSelectPipe({ id }))),
      map(generations => generations.at(0)),
      filter(Boolean),
      // open at most once for each failed job
      map(x => _.pick(x, 'id', 'status', 'error')),
      distinctUntilChanged((a, b) => _.isEqual(a, b)),
    )
    .subscribe(({ status, error }: Pick<Generation, 'id' | 'status' | 'error'>) => {
      // open the failed generation dialog
      if (status == 'FAILED' && ! failedJobDialogRef) {
        failedJobDialogRef = this._dialog.open(FailedGenerationDialogComponent);
        failedJobDialogRef.afterClosed().subscribe(() => failedJobDialogRef = null);
        // also send an error to the logger
        this._logger.error(new Error(`The job ${ this._onRunning.value?.id } of division ${ this.did } has failed due to: ${ error?.toString() }`));
      }
    });


    // TEMP: listen for jobs stuck at pending/running every five minutes
    combineLatest({
      now:      timer(0, 5 * 60 * 1000).pipe(map(() => moment.utc())),
      division: this._source.getStrictDivision({ did: this.did, onDestroy: this.onDestroy })
    })
    .pipe(
      takeUntil(this.onDestroy),
      takeUntil(this.onNewDid),
      debounceTime(1000)
    )
    .subscribe(({ now, division }) => {
      const RunningStatus = division.running?.status;
      if (RunningStatus != 'PENDING' && RunningStatus != 'STARTED') return;

      const diff = now.diff(moment.utc(division.running?.createdAt), 'minutes');
      if (diff > 30) this._logger.error(new Error(`The job ${ division.running?.id } of division ${ division.id } has been pending/running for ${ diff } minutes`));
    });
  }
  private _did: string;
  private readonly onNewDid = new Subject<void>();

  public get onRunning (): Observable<Division['running'] | null> { return this._onRunning; }
  private readonly _onRunning = new BehaviorSubject<Division['running'] | null>(null);

  public get onIsRunning (): Observable<boolean | null> { return this._onIsRunning; }
  private readonly _onIsRunning = new BehaviorSubject<boolean | null>(null);

  public get onEditable (): Observable<boolean | null> { return this._onEditable; }
  private readonly _onEditable = new BehaviorSubject<boolean | null>(null);

  public get onRunningProgress (): Observable<number | null> { return this._onRunningProgress; }
  private readonly _onRunningProgress = new BehaviorSubject<number | null>(null);

  public get onNumDays (): Observable<number | null> { return this._onNumDays; }
  private readonly _onNumDays = new BehaviorSubject<number | null>(null);

  public get onDays (): Observable<Pick<Day, 'is' | 'day'>[] | null> { return this._onDays; }
  private readonly _onDays = new BehaviorSubject<Pick<Day, 'is' | 'day'>[] | null>(null);

}