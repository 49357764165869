import { NgModule                        } from '@angular/core';

import { VirtualSelectModule             } from './virtual-select/virtual-select.module';

@NgModule({
  declarations: [
  ],
  exports: [
    VirtualSelectModule
  ],
  imports: [
  ]
})
export class ComponentsModule { }
