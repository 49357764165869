<h1 mat-dialog-title class="header royal">
  {{ 'shared.dialogs.login.title' | translate }}
</h1>

<div class="description royal-text-secondary">
  {{ 'shared.dialogs.login.description' | translate }}
</div>

<mat-dialog-content>
  <form [formGroup]="form"
        [style.height]="'100%'"
        class="content"
        novalidate
        (ngSubmit)="login(form.value, form.valid)">

    <mat-form-field [hidden]="true">
      <input matInput id="username" name="username" type="text" formControlName="username" autocomplete="username">
    </mat-form-field>

    <div class="username">
      <mat-icon inline>account_circle</mat-icon>
      <span>{{ data?.username }}</span>
    </div>

    <mat-form-field class="password" appearance="outline">
      <mat-label>{{ 'view.public.login.password' | translate }}</mat-label>
      <input matInput [type]="hide ? 'password' : 'text'" formControlName="password">
      <mat-icon matSuffix inline (click)="hide = !hide" class="royal-icon clickable suffix">
        {{ hide ? 'visibility_off' : 'visibility' }}
      </mat-icon>
      <mat-error><app-form-field-error [control]="form.controls.password"></app-form-field-error></mat-error>
    </mat-form-field>

    <div class="actions">
      <button mat-button
              mat-dialog-close
              color="primary"
              type="button"
              class="close-button">
        {{ 'common.close' | translate }}
      </button>

      <button mat-flat-button
              class="login-button"
              type="submit"
              color="primary"
              [disabled]=" ! form.valid || submitted">
        {{ 'common.login' | translate }}
      </button>
    </div>
  </form>
</mat-dialog-content>
