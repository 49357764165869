import _                                 from "lodash";
import { Util                          } from "@app/common";
import { OrganizationType              } from "../environment/environment.types";
import { LanguageConstant,
         SubstitutionRecordKey         } from "./types";
import { substitutionRecords           } from "./constants";

type Substitution = {
  template:    string;
  symbol:      string;
  replacement: string;
  substitutions: {
    key:         string;
    original:    string;
    substituted: string;
    missing:     boolean;
  }[];
}


export function getSubstitutionRecord (
  lang:             LanguageConstant,
  organizationType: OrganizationType,
) {
  // fetch the substitution dictionary
  const key = organizationType + '.' + lang as SubstitutionRecordKey;
  return substitutionRecords[key] ?? { };
}



function replaceMatches (
  str:                          string,
  matches:                      RegExpMatchArray[],
  dictionary:                   Record<string, string>,
  replacementModifierFunction?: (replacement: string, template: string, missing: boolean) => string
) {
  const substitutions: {
    symbol:      string,
    template:    string,
    replacement: string,
    modified:    string,
    missing:     boolean
  }[] = [];

  // to keep track of the length change of the string created when replacing the symbols
  let totDiff = 0;
  matches.forEach((match) => {
    const symbol   = match[0];                // [[Xxxx...]]
    const template = match[1].toLowerCase();  //   xxxx...

    // look up the replacement and use the template if not found
    let replacement = dictionary[template] ?? template;

    // match the capitalization
    if (template.toLocaleUpperCase()[0] === match[1][0]) replacement = replacement.capitalizeFirst();

    // apply the modifier function
    const missing = ! dictionary[template];
    const modified = replacementModifierFunction?.(replacement, template, missing) ?? replacement;

    // replace the symbol and update the total difference
    const idx = match.index! + totDiff;
    str = str.slice(0, idx) + modified + str.slice(idx + symbol.length);
    totDiff += modified.length - symbol.length;

    // the templates are partaking in the substitution
    substitutions.push({ symbol, template, replacement, modified, missing });
  });

  return { string: str, substitutions };
}


export function substituteInTranslation (
  translationRecord:   Record<string, string>,
  substitutionRecord:  Record<string, string>,
  returnSubstitutions: boolean = false
): Substitution[] {

  // a regex to find symbols of the form [[...]]
  const re = /\[\[([^\]]*)\]\]/g;

  // loop through all translation entries and carry out the substitution
  const substitutionsMap = new Map<string, Substitution>();
  Util.functions.objectEntries(translationRecord)
  .forEach(([key, original]) => {

    // find all symbols of the form [[...]]
    const matches = [...original.matchAll(re)];
    if ( ! matches.length) return;

    // replace the symbols and update the translation
    translationRecord[key] = replaceMatches(original, matches, substitutionRecord).string;

    // fetch the substitutions and wrap in tags
    if (returnSubstitutions) {
      const { string, substitutions } = replaceMatches(original, matches, substitutionRecord,
        (replacement, template, missing) => `[[${template}${ missing ? '_missing' : '' }]]${replacement}[[/${template}${ missing ? '_missing' : '' }]]`);

      _.chain(substitutions)
      .uniqBy('template')
      .value()
      .forEach(({ template, replacement, missing }) => {
        const item = substitutionsMap.get(template) ?? { template, replacement, symbol: `[[${template}]]`, substitutions: [] };
        item.substitutions.push({ key, original, substituted: string, missing });
        substitutionsMap.set(template, item);
      });
    }
  });

  return [...substitutionsMap.values()];
}