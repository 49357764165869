@if (loading) {
  <div class="backdrop"
      [@anim]
      [style.position]="position"
      [class.no-overlay]="noOverlay"
      [class.white-overlay]="whiteOverlay"
      [style.border-radius]="borderRadius">

    <svg #svg [class.dark]="dark">
      <!--
        Mask:
        - Everything under a white pixel will be visible
        - Everything under a black pixel will be invisible
      -->
      <mask id="mask1">
        <rect x="0" y="0" width="100%" height="100%" fill="white"/>
        <rect #ne class="ne" fill="black"/>
      </mask>
      <mask id="mask2">
        <rect x="0" y="0" width="100%" height="100%" fill="white"/>
        <rect #sw class="sw" fill="black"/>
      </mask>
        <rect #sw class="sw" mask="url(#mask1)" fill="white"/>
        <rect #ne class="ne" mask="url(#mask2)" fill="white"/>
    </svg>

    @if (description) {
      <div class="description">{{ description }}</div>
    }
  </div>
}