import { Injectable                       } from '@angular/core';
import { Observable,
         asyncScheduler,
         throwError,
         BehaviorSubject,
         fromEvent,
         merge,
         firstValueFrom} from 'rxjs';
import { first,
         finalize,
         switchMap,
         tap,
         skip                             } from 'rxjs/operators';
import { io,
         Socket as IOSocket               } from 'socket.io-client';
import { nanoid                           } from 'nanoid';

import { environment                      } from 'environments';

import { DeepMap                          } from 'app/common';
import { MatSnackBar                      } from 'app/common';
import { AuthService                      } from 'app/core/auth/auth.service';
import { BroadcastService                 } from 'app/core/broadcast/broadcast.service';
import { LoggerService                    } from 'app/core/logger/logger.service';

import { ConnectionReference,
         Namespace,
         Did,
         Socket,
         ConnectionMap                    } from './socket.interface';

@Injectable({
  providedIn: 'root'
})
export class SocketService implements Socket {
  /*
    socket: connection to server
    socketChange: called when socket connect
  */
  private connections: ConnectionMap = new DeepMap();

  constructor(private _broadcast: BroadcastService,
              private _snackBar:  MatSnackBar,
              private _logger:    LoggerService,
              private _auth:      AuthService) {

    //this.connect().pipe(catchError(this.connectError.bind(this)));

    this._auth.onIsAuthenticated
    .pipe(skip(1))  // skip the initial hardcoded false value
    .subscribe((isAuthenticated: boolean) => {
      if (isAuthenticated) {
        /*if (! this.connections.has(''))
          this.connect().pipe(catchError(this.connectError.bind(this)));*/
      } else {
        this.closeAll();
      }
    });
  }

  private _init(namespace: string = '', did?: Did) {

    if (this.connections.has(namespace, did)) return;

    let connected = new Set<string>();

    this.connections.set(namespace, did, { connected });
  }

  private _openSocket(namespace: string = '', did?: Did): IOSocket {
    let socket = io(`${ environment.API_BASE_URL }${ namespace }`, {
      transports: ['websocket'],
      forceNew: true,
      auth: {
        token: this._auth.getToken()
      },
      query: {
        ...did && { did },
      }
    });

    socket.on('connecting', () => {
      this._logger.verbose(`Socket with namespace \'${ namespace }\' connecting`);
    }).on('connect', () => {
      //this._logger.verbose(`Socket with namespace \'${ namespace }\' connected successfully`);
    }).on('connect_error', (err: Error) => {
      if (err.message == 'Unauthorized') {
        this._auth.checkAuthentication();
        asyncScheduler.schedule(() => {
          (socket.io.opts as any).auth.token = this._auth.getToken();
          socket.connect();
        }, 1000);
      }
      this._logger.verbose(`Socket with namespace \'${ namespace }\' encountered a connection error. Message \'${ err.message }\' `);
    }).on('connect_failed', () => {
      this._logger.verbose(`Socket with namespace \'${ namespace }\' failed to connect`);
    }).on('reconnect', () => {
      this._logger.verbose(`Socket with namespace \'${ namespace }\' is reconnecting`);
    }).on('reconnect_failed', () => {
      this._logger.verbose(`Socket with namespace \'${ namespace }\' failed with reconnect`);
    }).on('reconnect_error', (err: Error) => {
      this._logger.verbose(`Socket with namespace \'${ namespace }\' encountered a reconnect error \'${ err.message }\'`);
    }).on('reconnect_attempt', () => {
      this._logger.verbose(`Socket with namespace \'${ namespace }\' attempted reconnect`);
    }).on('disconnect', () => {
      this._logger.verbose(`Socket with namespace \'${ namespace }\' disconnected`);
    }).on('error', (err: Error) => {
      this._logger.verbose(`Socket with namespace \'${ namespace }\' encountered an error \'${ err.message }\'`);
    });

    return socket;
  }

  private connect(namespace: string = '', did: Did): Observable<IOSocket | never> {
    if (this.connections.get(namespace, did)?.socket) {
      return this.connections.get(namespace, did)!.subject!.asObservable();
    }

    if (! this._auth.isAuthenticated || ! this._auth.getToken()) {
      const error = new Error(`Socket failed to connect due to no authenticated user`);
      this._logger.error(error);
      return throwError(error);
    }

    const socket  = this._openSocket(namespace, did);

    let subject   = new BehaviorSubject<IOSocket>(socket);

    Object.assign(this.connections.get(namespace, did)!, { subject, socket });

    return this.connections.get(namespace, did)!.subject!.asObservable();
  }

  public subscribeToChannel(channel: string, did: Did, namespace: string = ''): Observable<any> {
    const id: string = nanoid();

    this._init(namespace, did);

    this.connections.get(namespace, did)?.connected?.add(id);

    return merge(
      this.connect(namespace, did)
      .pipe(
        switchMap((socket: IOSocket) => {
          return fromEvent(socket, channel)
          .pipe(
            tap(() => {
              this._logger.verbose(`Channel \'${ namespace }/${ channel }\' received data`);
            }),
            tap((data: { code: string }) => {
              if (data?.code == 'invalid_token') {
                this._snackBar.open(`Channel \'${ namespace }/${ channel }\' got unauthorized`, undefined, { duration: 3000, panelClass: 'warn-snackbar' })
                this._logger.error(new Error(`(Core::Translate::Service) Channel \'${ namespace }/${ channel }\' got unauthorized`));
              }
            })
          )
        })
      ),
      this._broadcast.subscribeToChannel(channel, true, namespace)
    )
    .pipe(finalize(() => {
      this.connections.get(namespace, did)?.connected?.delete(id);
      if (! this.connections.get(namespace, did)?.connected?.size)
        this.close(namespace, did);
    }))
  }

  public async emit(channel: string, data: any, namespace: string = '', did: Did): Promise<void> {
    try {
      const socket = await firstValueFrom(this.connect(namespace, did));
      this._broadcast.emit(channel, data, namespace);
      socket.emit(channel, data);
      this._logger.verbose(`Channel \'${ namespace }/${ channel }\' emitted data`);
      return;
    } catch(err) {
      const reason = err instanceof Error ? err.message : err;
      this._logger.warn(`Socket emit error channel: ${ channel }, namespace: \'${ namespace }\', reason ${reason}`);
      return Promise.reject(err);
    }
  }

  public close(namespace: string = '', did: string): void {
    if (! this.connections.has(namespace, did))
      return;
    if (this.connections.get(namespace, did)!.socket) {
      this._logger.verbose(`Closed namespace \'${ namespace }\'`);
      this.connections.get(namespace, did)!.socket!.disconnect();
    } else {
      this._logger.warn(`Tried to close non existing namespace \'${ namespace }\'`);
    }
    this.connections.delete(namespace, did);
  }

  public closeAll() {
    this._logger.verbose(`Closing all namespaces`);
    this.connections.forEach((map: Map<Did, ConnectionReference>, namespace: Namespace) => {
      map.forEach((refrence: ConnectionReference, did: Did) => {
        refrence.socket?.disconnect();
        refrence.subject?.complete();
      })

      //this.connections.delete(key);
    });
  }

  public isConnected(namespace: string = '', did: string): boolean {
    return this.connections.get(namespace, did)?.socket?.connected ?? false;
  }
}