<h1 matDialogTitle>
  {{ 'shared.dialogs.duplicate-schedule.title' | translate }}
</h1>

<mat-dialog-content>
  <mat-form-field appearance="outline" floatLabel="always">
    <mat-label>{{ 'attributes.shared.displayName' | translate }}</mat-label>
    <input matInput [formControl]="ctrl">
    <mat-icon matSuffix fontSet="material-icons-outlined" *ngIf="loading | async" @inOutAnimation class="rotating">motion_photos_on</mat-icon>
    <mat-error><app-form-field-error [control]="ctrl"></app-form-field-error></mat-error>
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close tabindex="-1">
    {{ 'common.cancel' | translate }}
  </button>
  <button mat-button color="primary"
    [disabled]="ctrl.invalid || (loading | async)"
    (click)="submit()" (key.enter)="submit()"
  >
    {{ 'common.duplicate' | translate }}
  </button>
</mat-dialog-actions>