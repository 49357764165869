import { NgModule                        } from '@angular/core';
import { CommonModule                    } from '@angular/common';
import { FormsModule,
         ReactiveFormsModule             } from '@angular/forms';

import { NgxCleaveDirectiveModule        } from 'ngx-cleave-directive';

import { CalendarModule                  } from 'app/shared/calendar/calendar.module';

import { TranslationModule               } from 'app/core/translate/translate.module';

import { AppCommonModule                 } from 'app/common/common.module';

import { ComponentsModule                } from 'app/shared/components/components.module';

import { TimeComponent                   } from './time.component';

@NgModule({
  declarations: [
    TimeComponent
  ],
  exports: [
    TimeComponent
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    TranslationModule,
    NgxCleaveDirectiveModule,
    CalendarModule,
    FormsModule,
    ComponentsModule,
    ReactiveFormsModule
  ]
})
export class TimeFormFieldModule { }
