import { Component,
         Inject                          } from '@angular/core';
import { MatDialogRef,
         MAT_DIALOG_DATA                 } from 'app/common';
import { FormControl,
         Validators,
         FormGroup                       } from '@angular/forms';
import { take                            } from 'rxjs';
import Countries                           from 'i18n-iso-countries';
import _                                   from 'lodash';

import { HttpService,
         PushNotificationService,
         UserPreferencesService          } from 'app/core';
import { apiConstants                    } from 'app/constants';
import { Company                         } from 'app/shared/interfaces';


export type Data = {
  organization: Company.complete;
  urlRootDir:   typeof apiConstants.ADMIN | typeof apiConstants.PARTNER;
}

type Identifier     = Company.complete['identifiers'][number];
type IdentifierType = Identifier['type'];

const identifierTypes: Record<IdentifierType, string> = {
  'VAT':        'tables.admin-tables.organizations.configure-identifiers.identifier-types.vat',
  'SchoolCode': 'tables.admin-tables.organizations.configure-identifiers.identifier-types.school_code',
  'SchoolSoft': 'SchoolSoft',
  'Edlevo':     'Edlevo',
  'Sdui':       'Sdui',
};

type Form = {
  country: FormControl<string | null>;
  type:    FormControl<IdentifierType>;
  value:   FormControl<string | null>;
}


@Component({
  templateUrl: './configure-identifiers.component.html',
  styleUrls: ['./configure-identifiers.component.scss']
})
export class ConfigureIdentifiersComponent {
  protected loading = false;

  private _changed = false;

  protected readonly organization: Company.complete;
  protected readonly urlRootDir: typeof apiConstants.ADMIN | typeof apiConstants.PARTNER;

  protected readonly countriesMap:  Record<string, string>;
  protected readonly countriesList: { code: string, name: string }[];
  protected readonly types = identifierTypes;
  protected readonly form: FormGroup<Form>;

  constructor (
    @Inject(MAT_DIALOG_DATA) { organization, urlRootDir }: Data,
    private _dialogRef:    MatDialogRef<ConfigureIdentifiersComponent>,
    private _http:         HttpService,
    private _userPref:     UserPreferencesService,
    private _notification: PushNotificationService,
  ) {
    this.organization = organization;
    this.urlRootDir   = urlRootDir;

    // hijack beforeClosed to prevent closing without passing along whether the identifiers were changed
    this._dialogRef.beforeClosed()
    .pipe(take(1))
    .subscribe(() => this._dialogRef.close(this._changed));

    // load countries
    const lang = Countries.isValid(this._userPref.language) ? this._userPref.language : 'en';
    Countries.registerLocale(require(`i18n-iso-countries/langs/${ lang }.json`));
    this.countriesMap = Countries.getNames(lang, { select: 'official' });
    this.countriesList = _(this.countriesMap)
      .map((name, code) => ({ code, name }))
      .orderBy('name')
      .value();

    // set default country depending on language
    let defaultCountry: string | null = null;
    switch (this._userPref.language) {
      case 'sv': defaultCountry = 'SE'; break
      case 'en': defaultCountry = 'GB'; break
      case 'ca': defaultCountry = 'ES'; break
      case 'fr': defaultCountry = 'FR'; break
      case 'de': defaultCountry = 'DE'; break
      case 'es': defaultCountry = 'ES'; break
    }

    this.form = new FormGroup<Form>({
      country: new FormControl<string | null> (defaultCountry, { validators: Validators.required }),
      type:    new FormControl<IdentifierType>('SchoolCode',   { nonNullable: true, validators: Validators.required }),
      value:   new FormControl<string | null> (null,           { validators: Validators.required }),
    });
  }

  protected add () {
    // all values must exist
    const value = this.form.value
    if ( ! value.value  ) return;
    if ( ! value.country) return;
    if ( ! value.type   ) return;

    this._changed = true;
    this.loading = true;
    this._http
    .post(`${ this.urlRootDir }/companies/${ this.organization.id }/identifiers/add`, value)
    .subscribe({
      next: () => {
        this.loading = false;
        this.organization.identifiers?.push(value as Identifier);
        this.form.controls.value.reset();
      },
      error: () => {
        this.loading = false;
        this._notification.pushError({ translate: 'tables.admin-tables.organizations.configure-identifiers.actions.add.failure' });
      }
    });
  }

  protected pull (identifier: Identifier) {
    this._changed = true;
    this.loading = true;
    this._http
    .post(`${ this.urlRootDir }/companies/${ this.organization.id }/identifiers/pull`, identifier)
    .subscribe({
      next: () => {
        this.loading = false;
        this.organization.identifiers = _.pull(this.organization.identifiers, identifier);
      },
      error: () => {
        this.loading = false;
        this._notification.pushError({ translate: 'tables.admin-tables.organizations.configure-identifiers.actions.delete.failure' });
      }
    });
  }
}
