import _                                   from 'lodash';

import { PublicId                        } from 'app/shared/interfaces';

import { SourceCore                      } from '../source.core';

import { Options                        } from '../source.interface';

export function unset(this: SourceCore, _options: Omit<Options.Default, 'onDestroy'>, id: PublicId | PublicId[]): void {
  this._systemCore.unset(_options, id);
  /*
    EMIT unset to system instance
  */
}