import { CommonModule                    } from '@angular/common';
import { ChangeDetectionStrategy,
         Input,
         Component,
         signal                          } from '@angular/core';
import { BehaviorSubject                 } from 'rxjs';

import { apiConstants                    } from '@app/constants';

import { HttpService,
         TranslateService,
         EnvironmentService              } from '@app/core';

import { TranslationModule               } from '@core/translate/translate.module';
import { IntegrationType                 } from '@app/core/environment/environment.constants';

import { AppCommonModule                 } from '@common/common.module';

import { PipesModule                     } from '@app/shared/pipes/pipes.module'
import { ComponentsModule                } from '@app/shared/components/components.module';

import { FilterCollectionsPipe           } from '../../sync.pipe';

import { Validated                       } from './validator.types';

@Component({
  selector: 'app-sync-validator',
  standalone: true,
  imports: [
    CommonModule,
    AppCommonModule,
    ComponentsModule,
    FilterCollectionsPipe,
    PipesModule,
    TranslationModule
  ],
  templateUrl: './validator.component.html',
  styleUrl: './validator.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ValidatorComponent {
  protected validated    = new BehaviorSubject<Validated | null>(null);
  protected loading      = signal(true);
  protected error        = signal(false);

  public valid           = signal(false);

  @Input() collections: (keyof Validated)[];

  constructor(
    private _http:        HttpService,
    protected translate:  TranslateService,
  ) {
  }

  protected pin() {

  }

  private _getValidated() {
    if (! this._partner || ! this._did)
      return;

    this._http.get(`${ apiConstants.CONNECTIONS }/${ this._partner }/${ this._did }/validate`)
    .subscribe({
      next: (res: Validated) => {
        this.validated.next(res);
        this.valid.set(! Object.values(res).some(x => ! x.valid));
        this.error.set(false);
        this.loading.set(false);
        console.log(res);
      },
      error: () => {
        this.error.set(true);
        this.loading.set(false);
      }
    });
  }

  @Input()
  set partner(val: IntegrationType | undefined) {
    if (! val) return;
    this._partner = val;
    this._getValidated();
  }
  private _partner: IntegrationType;

  @Input()
  set did(val: string | undefined) {
    if (! val) return;
    this._did = val;
    this._getValidated();
  }
  private _did: string;
}
