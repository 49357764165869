<h1 mat-dialog-title>{{ 'common.edit_columns' | translate }}</h1>

<mat-dialog-content>
  <div cdkDropList class="dark-vertical-scrollbar" (cdkDropListDropped)="drop($event)">
    <div class="item" *ngFor="let x of onColumns | async; trackBy: trackByFn" cdkDrag>
      <mat-icon class="royal-icon">drag_indicator</mat-icon>
      <span [innerHTML]="x.title | translate"></span>
      <div class="spacer"></div>
      <mat-slide-toggle [(ngModel)]="x.enabled" [attr.cypress]="x.name"></mat-slide-toggle>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button (click)="reset()">{{ 'common.restore' | translate }}</button>
  <span class="spacer"></span>
  <button mat-button mat-dialog-close type="button">{{ 'common.close' | translate }}</button>
</mat-dialog-actions>
