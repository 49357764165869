import { Component,
         Input,
         OnDestroy                } from '@angular/core';
import { BehaviorSubject,
         Subject,
         combineLatest,
         filter,
         takeUntil                } from 'rxjs';


export type ActionButton<T = unknown> = {
  icon:      string | { icon: string, outlined: boolean };
  label:     string | { translate: string };
  action:    (element: T) => void;
  disabled?: boolean | ((element: T) => boolean);
  classes?:  string[];
} | undefined;

type ProcessedActionButton<T = unknown> = {
  icon:        string;
  iconFontSet: string;
  label:       string | { translate: string };
  action:      (element: T) => void;
  disabled?:   boolean;
  classes?:    string[];
};

@Component({
  selector: 'app-table-action-buttons',
  templateUrl: './table-action-buttons.component.html',
  styleUrls: ['./table-action-buttons.component.scss']
})
export class TableActionButtons<T> implements OnDestroy {
  private readonly onDestroy = new Subject<void>();

  protected readonly processedButtons = new BehaviorSubject<ProcessedActionButton<T>[]>([]);

  constructor () {

    // evaluate the state of the buttons
    combineLatest({
      element: this._element.pipe(filter(Boolean)),
      buttons: this._buttons
    })
    .pipe(
      takeUntil(this.onDestroy),
    )
    .subscribe(({ element, buttons }) => {
      const processedButtons = buttons.map(button => {
        const disabled = typeof button.disabled == 'function' ? button.disabled(element) : button.disabled;

        const icon        = typeof button.icon == 'object' ? button.icon.icon : button.icon;
        const iconFontSet = typeof button.icon == 'object' && button.icon.outlined ? 'material-icons-outlined' : 'material-icons';

        return {
          ...button,
          disabled,
          icon,
          iconFontSet
        };
      });
      this.processedButtons.next(processedButtons);
    });

  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  protected translatable (label: string | { translate: string }): label is { translate: string } {
    return typeof label == 'object';
  }

  ////
  //// buttons
  ////
  @Input()
  set buttons (val: ActionButton<T>[] | undefined) { this._buttons.next(val?.filter(Boolean) ?? []); }
  private readonly _buttons = new BehaviorSubject<NonNullable<ActionButton<T>>[]>([]);

  // TODO: make required
  @Input()
  get element (): T | null { return this._element.value; }
  set element (val: T | null) { this._element.next(val); }
  private readonly _element = new BehaviorSubject<T | null>(null);
}