<!-- force rerender on stateChanges.next() otherwise it is not guaranteed to show the latest values due to some lazy rendering-->
{{ stateChanges | async }}

<div class="label" *ngIf="!focused; else form">
  {{ value | sex }}
</div>
<ng-template #form>
  <mat-button-toggle-group [value]="value" (change)="submit($event.value)">
    <mat-button-toggle value="Man">{{ 'common.sex.man' | translate }}</mat-button-toggle>
    <mat-button-toggle value="Woman">{{ 'common.sex.woman' | translate }}</mat-button-toggle>
    <mat-button-toggle [value]="null">{{ 'common.no_value' | translate }}</mat-button-toggle>
  </mat-button-toggle-group>
</ng-template>
