<!-- force rerender on stateChanges.next() otherwise it is not guaranteed to show the latest values due to some lazy rendering-->
{{ stateChanges | async }}

<div class="wrapper"
  [class.disabled]="disabled"
  (menuClosed)="closed()"
  (menuOpened)="opened()"
  [matMenuTriggerFor]="menu"
>
  <app-form-field-planned-duration-display-value
    [primaryValueAndUnit]="primaryDisplayValue"
    [secondaryValueAndUnit]="secondaryDisplayValue"
  ></app-form-field-planned-duration-display-value>
</div>

<mat-menu yPosition="below" #menu="matMenu" [overlapTrigger]="false" class="planned-duration-panel no-vertical-padding">
  <ng-template matMenuContent>

    @if ( ! hideToolbar) {
      <div class="toolbar" (click)="$event.stopPropagation()">
        <div class="row">
          @if (valueCtrl.dirty || unitCtrl.dirty) {
            <button mat-stroked-button
              class="action reset"
              color="primary"
              (click)="resetValue()"
            >
              <div>
                <span>{{ 'common.restore' | translate }}</span>
                <mat-icon class="royal-icon">undo</mat-icon>
              </div>
            </button>
          }

          <span class="spacer"></span>

          @if (nullable) {
            <button mat-stroked-button
              class="action reset"
              color="primary"
              (click)="clearValue()"
            >
              <div>
                <span>{{ 'common.reset' | translate }}</span>
              </div>
            </button>
          }

        </div>
      </div>
    }

    <div class="content" (click)="$event.stopPropagation()">

      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <input matInput #input
          [formControl]="valueCtrl"
          [cleave]="cleaveIntegerFormat"
          autocomplete="off">
      </mat-form-field>

      <mat-button-toggle-group [formControl]="unitCtrl">
        <mat-button-toggle value="hrs">{{ 'common.hours' | translate }}</mat-button-toggle>
        <mat-button-toggle value="min/week">{{ 'common.minutes-per-week' | translate }}</mat-button-toggle>
      </mat-button-toggle-group>

    </div>
  </ng-template>
</mat-menu>