import { Component,
         OnInit,
         Input,
         ElementRef,
         OnDestroy,
         Optional,
         Self,
         HostBinding,
         ViewEncapsulation,
         EventEmitter,
         ChangeDetectionStrategy,
         Output,
         ViewChild,
         ChangeDetectorRef} from '@angular/core';
import { ControlValueAccessor,
         NgControl,
         UntypedFormBuilder              } from '@angular/forms';
import { asyncScheduler                  } from 'rxjs';
import { CdkDragDrop,
         CdkDragExit,
         moveItemInArray,
         transferArrayItem               } from '@angular/cdk/drag-drop';
import { apiConstants                    } from 'app/constants';
import { MatSlideToggleChange            } from '@angular/material/slide-toggle';
import { MatMenuTrigger                  } from '@angular/material/menu';
import { MatFormFieldControl             } from '@angular/material/form-field';
import { FocusMonitor                    } from '@angular/cdk/a11y';
import { coerceBooleanProperty           } from '@angular/cdk/coercion';
import { fromEvent,
         Subject                         } from 'rxjs';
import { takeUntil                       } from 'rxjs/operators';
import _                                   from 'lodash';

import { TranslateService                } from 'app/core';
import { HttpService                     } from 'app/core';

import { AvailableLocation as Av,
         Location                        } from 'app/shared/interfaces';
import { DefectType                      } from '@app/mirror/types';

type DropType = Partial<Location> & { id: string };


type AvailableLocation = Av.populated;

@Component({
  selector: 'app-form-field-available-locations',
  templateUrl: './available-locations.component.html',
  styleUrls: ['./available-locations.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: AvailableLocationsComponent
    }
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvailableLocationsComponent implements OnInit,
                                                    OnDestroy,
                                                    ControlValueAccessor,
                                                    MatFormFieldControl<AvailableLocation[] | null | undefined> {

  @ViewChild(MatMenuTrigger)      trigger:           MatMenuTrigger;
  @Input()                        did:               string;
  @Output('onChange') emitter  = new EventEmitter<AvailableLocation[] | null | undefined>();
  @Output('onSelect') onSelect = new EventEmitter<any>();
  private onClose:    Subject<boolean>    = new Subject<boolean>();
  private onDestroy:  Subject<boolean>    = new Subject<boolean>();
  static nextId:      number               = 0;
  public focused:     boolean              = false;
  public errorState:  boolean              = false;
  public disableDrag: boolean              = false;
  public controlType: string               = 'available-locations-input';
  public id:          string               = `available-locations-input-${ AvailableLocationsComponent.nextId++ }`;
  public describedBy: string               = 'Available locations input field';
  public stateChanges:Subject<void>        = new Subject<void>();
  private _label:     string[][];
  public subSets:     DropType[][]         = [];
  public repository:  DropType[]           = [];
  public onChange = (_: any) => {};
  public onTouched = () => {};

  @HostBinding('attr.tabindex') __tabindex = 0;

  constructor(private _fb:           UntypedFormBuilder,
              private http:          HttpService,
              private _translate:    TranslateService,
              private _focusMonitor: FocusMonitor,
              private _changeDetectorRef: ChangeDetectorRef,
              private _elementRef:   ElementRef<HTMLElement>,
              @Optional() @Self() public ngControl: NgControl) {

    _focusMonitor.monitor(_elementRef, true)
    .subscribe((origin: any) => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
    this.onClose.next(true);
    this.onClose.complete();
    this.onDestroy.next(true);
    this.onDestroy.complete();
  }

  public closed(): void {
    if (this.saveOnClose) {
      if(! this._pristine) {
        this.pristine = true;
        this.emitter.emit(this.value);
      }

      if (! this._selectPristine && this.value?.length) {
        this._selectPristine = true;
        this.onSelect.next(this._selected);
      }
    }

    this.onClose.next(true);
  }

  public opened(): void {
    if (! this.list)
      this._getList();
    this.subSets = this._toDeepStructure(this._value as AvailableLocation[]);
    this._setRepository();

    fromEvent(document, 'keydown')
    .pipe(takeUntil(this.onClose))
    .subscribe((event: any) => {
      if (event.key == 'Escape') {
        this.trigger?.closeMenu()
      }
    });
  }

  private _getList(): void {
    this.http.get(`${ apiConstants.DIVISIONS }/${ this.did }/${ apiConstants.LOCATIONS }/list`)
    .subscribe((res: { docs: Location.populated[] }) => {
      this.list = res.docs;
    }, (err: Error) => {
    });
  }

  public inheritToggle(event: MatSlideToggleChange) {
    this.inheritValue = event.checked;
    this._computeValue();
    this._handleInput();
  }

  public resetValue(): void {
    this.value = this._pristineValue;
    this.subSets = this._toDeepStructure(this._value as AvailableLocation[]);
    this._setRepository();
    this.pristine = true;
  }

  private _getAsFlatStructure(): AvailableLocation[] {
    let groupIndex: number = 0;
    return this.subSets.map((set: DropType[]) => {
      let index: number = groupIndex++;
      return set.map((data: DropType) => {
        return {
          groupIndex: index,
          locations: [
            {
              id:           data.id,
              ids:          data.ids,
              displayName:  data.displayName
            }
          ]
        }
      })
    }).flat();
  }

  private _toDeepStructure(_val: AvailableLocation[]): DropType[][] {
    let val = _(_val ?? [])
      .map(x => ({
        groupIndex: (this.singleSet ? 0 : x.groupIndex) ?? 0,
        mapped:     this._map(x)
      }))
      .groupBy(x => x.groupIndex)
      .toArray()
      .map(x => x.map(y => y.mapped).filter(Boolean))
      .value();

    if (this.singleSet) val = [val.flat()];

    return val;
  }

  private _setRepository(): void {
    const _subSets = this.subSets.flat().map((set: DropType) => set.id);

    this.repository = this.list?.filter(source => ! _subSets?.includes(source.id!))
    .map((x: Location.populated & { availability: any }) => {
      return {
      id:           x.id,
      ids:          x.ids,
      displayName:  x.displayName,
      availability: x.availability
    }})
    .sort((a, b) => {
      // handle the case where displayName is undefined
      // (nameless locations should be at the end)
      if ( ! a?.displayName && ! b?.displayName) return 0;
      if ( ! a?.displayName) return 1;
      if ( ! b?.displayName) return -1;

      return a.displayName.localeCompare(b.displayName, this._translate.currentLanguage?.id);
    })
  }

  public addSet(): void {
    this.subSets.push([] as DropType[]);
    this.inheritValue = false;
  }

  public remove(elements: DropType[], index: number): void {
    let length = this.subSets[index - 1]?.length;
    for (let i = 0; i < length; i++) {
      transferArrayItem(elements, this.repository, 0, 0);
    }
    this.subSets.splice(index - 1, 1);
    this._computeValue();
    this._handleInput();
  }

  /*
    Do a empty container exist in the subSets?
    otherwise create one and remove it on drop
    if not dropped in the container
  */
  public dragStarted(): void {
    if (this.subSets.some(x => x.length == 0))
      return;
    this._dragContainerIndex = this.subSets.length;
    this.subSets.push([] as DropType[]);
    this._changeDetectorRef.detectChanges();
  }
  private _dragContainerIndex?: number;

  private _map(element: AvailableLocation /* & { availability: any } */): DropType | null {
    if ( ! element?.locations?.length) return null;
    return {
      id:           element.locations[0].id,
      ids:          element.locations[0].ids,
      displayName:  element.locations[0].displayName
    }
  }

  private _computeValue(): void {
    this._value = this.inheritValue ? null : this._getAsFlatStructure();
    this.pristine = _.isEqual(this._value, this._pristineValue);
  }

  public drop(event: CdkDragDrop<DropType[], any>) {
    if (this._dragContainerIndex != null && (
          event.previousContainer === event.container ||
          this._dragContainerIndex != parseInt(event.container.id)
        )
    ) {
      this.subSets.splice(this._dragContainerIndex, 1);
      delete this._dragContainerIndex;
    }

    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      this.inheritValue = false;
      transferArrayItem(event.previousContainer.data,
                        event.container.data,
                        event.previousIndex,
                        event.currentIndex);
      this._computeValue();
      this._handleInput();
    }
  }

  public dropListExit(ev: CdkDragExit): void {
    const { container, item } = ev;

    const { data } = item;

    if (data?.id == this._selected?.id)
      this.selectChange(null);
  }

  public submit(): void {
    this.emitter.emit(this.value);
  }

  public selectChange(selected: { id: string } | null): void {
    this._selected = selected;
    this._selectPristine = false;
  }

  get empty(): boolean {
    if (this.selectable)
      return false;
    return ! this.inheritValue && ! this.value?.length;
  }

  get shouldLabelFloat() { return this.focused || ! this.empty; }

  get pristine(): boolean {
    return this._pristine;
  }
  set pristine(_val: boolean) {
    const val      = coerceBooleanProperty(_val);
    this._pristine = val;

    if (val) asyncScheduler.schedule(() => this.ngControl?.control?.markAsPristine());

    this.stateChanges.next();
  }
  private _pristine:  boolean = true;

  @Input()
  get list(): Location.populated[] { return this._list }
  set list(value: Location.populated[] | null) {
    this._list = value ?? [];
    this._setRepository();
  }
  private _list: Location.populated[];

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get voidText(): string { return this._voidText; }
  set voidText(value: string) {
    this._voidText = value;
    this.stateChanges.next();
  }
  private _voidText: string = '';

  @Input()
  get reset(): boolean { return this._reset; }
  set reset(value: boolean | string) {
    this._reset = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _reset = true;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get add(): boolean { return this._add; }
  set add(value: boolean | string) {
    this._add = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _add = false;

  @Input()
  get inherit(): boolean { return this._inherit; }
  set inherit(value: boolean | string) {
    this._inherit = coerceBooleanProperty(value);
    // this.inheritValue = ! this.value;
    this.stateChanges.next();
  }
  private _inherit: boolean;
  public inheritValue: boolean;

  @Input()
  get selectable(): boolean { return this._selectable; }
  set selectable(value: boolean) {
    this._selectable = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _selectable: boolean = false;

  @Input()
  get selected(): { id: string } | null { return this._selected; }
  set selected(value: { id: string } | null) {
    this._selected   = value ?? null;
    this._selectable = true;
    this.stateChanges.next();
  }
  private _selected: { id: string } | null;
  private _selectPristine: boolean = true;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.disableDrag = this._disabled;
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get disableActions(): boolean { return this._disableActions; }
  set disableActions(value: boolean | string) {
    this._disableActions = coerceBooleanProperty(value);
  }
  private _disableActions = true;

  @Input()
  get saveOnClose(): boolean { return this._saveOnClose; }
  set saveOnClose(value: boolean | string) {
    this._saveOnClose = coerceBooleanProperty(value);
  }
  private _saveOnClose: boolean = false;

  @Input()
  get singleSet(): boolean { return this._singleSet; }
  set singleSet(value: boolean | string) {
    this._singleSet = coerceBooleanProperty(value);
  }
  private _singleSet = false;

  @Input()
  get availabilityMap(): Map<string, DefectType> { return this._availabilityMap; }
  set availabilityMap(value: Map<string, DefectType> | null) {
    if ( ! value) return;
    this._availabilityMap = value;
  }
  private _availabilityMap = new Map<string, DefectType>();

  @Input()
  get value(): AvailableLocation[] | null | undefined {
    return this._value;
  }
  set value(_val: AvailableLocation[] | null | undefined) {
    this._value = this._pristineValue = _val;
    if (this.inherit) this.inheritValue = !_val;
    this.stateChanges.next();
  }
  private _value:         AvailableLocation[] | null | undefined;
  private _pristineValue: AvailableLocation[] | null | undefined;

  @Input({ transform: coerceBooleanProperty })
  disableTooltip: boolean = false;


  // get disableTooltip(): boolean { return this._disableTooltip; }
  // set disableTooltip(value: boolean) {
  //   this._disableTooltip = coerceBooleanProperty(value);
  // }
  // private _disableTooltip: boolean = false;

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent) {
    this.trigger?.openMenu();
  }

  writeValue(val: AvailableLocation[]): void {
    this.value = val;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  _handleInput(): void {
    this.onChange(this.value);
  }

  static ngAcceptInputType_disabled: boolean | string | null | undefined;
  static ngAcceptInputType_required: boolean | string | null | undefined;
}
