import { Directive,
         HostBinding,
         HostListener,
         Input,
         ElementRef,
         Optional              } from '@angular/core';
import { AbstractControl,
         FormControl           } from '@angular/forms';
import { BehaviorSubject,
         combineLatest,
         of,
         startWith,
         Subject,
         takeUntil             } from 'rxjs';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

import { DefectType            } from '@app/mirror/types';
import { Collection            } from '@app/core/source/source.interface';

import { FormControlComponent,
         FormFieldsService     } from './form-fields.service';
import { State                 } from './form-fields.types';
import { MatFormField          } from '@angular/material/form-field';

export type CoalescedType = 'groups' | 'persons' | 'teachers';

@Directive({
  selector: '[formField]',
})
export class FormFieldsDirective {
  @HostBinding('class') elementClass = 'clickable';
  @HostBinding('attr.tabindex') __tabindex = 0;

  @HostListener('click', ['$event'])
  private _onClick(event: PointerEvent){
    if (! this._editable) return;
    event.stopPropagation();
    this._core.open(
      this._state,
      this._id,
      this._collection,
      this._path,
      this._coalescedPath,
      this._flat,
      this._elemRef,
      this._value,
      this._control,
      this._formControlAccessor?._control as FormControlComponent,
      this._coalescedValue,
      this._coalescedControl,
      this._availabilityMap

    );
  }

  private _onDestroy: Subject<boolean>;

  constructor(
    private readonly _core: FormFieldsService,
    private readonly _elemRef: ElementRef
  ) { }

  ngOnInit() {
    if (this._formControlAccessor)
      this._core.setFormControl(
        this._elemRef,
        this._value,
        this._formControlAccessor,
        this._formControlAccessorName
      );

    if (this._coalescedControl || this._control) {
      this._onDestroy = new Subject<boolean>();
      combineLatest([
        this._coalescedControl?.valueChanges ?? of(),
        this._control?.valueChanges ?? of(),
      ]).pipe(
        takeUntil(this._onDestroy),
        startWith([this._coalescedControl?.value, this._control?.value])
      ).subscribe(([coalescedValue, value]) => {
        this._core.setDisplayValue(
          this._state,
          this._elemRef,
          value ?? this._value,
          coalescedValue ?? this._coalescedValue,
        );
      });
    }
  }

  @Input()
  set formField (state: State) {
    this._state = state;
  }
  private _state: State;

  @Input()
  set id (id: string | string[]) {
    this._id = id;
  }
  private _id: string | string[];

  /**
   * @description Specify the collection of the field.
   *              Combine with path to use source set function.
   *              If collection is events then allow inheritance.
   */
  @Input()
  set collection (collection: Collection) {
    this._collection = collection;
  }
  private _collection: Collection;

  /**
   * @description Specify the path of the field. ie 'group' for persons
   *              Combine with collection to use source set function.
   */
  @Input()
  set path (path: string) {
    this._path = path;
  }
  private _path: string;

  /**
   * @description Specify the path of the coalesced field. ie 'participants' for course/event
   */
  @Input()
  set coalescedPath (path: string) {
    this._coalescedPath = path;
  }
  private _coalescedPath: string;

  /**
   * @description Specify the form control accessor name. Used in a mat-form-field.
   */
  @Input()
  set formControlAccessorName (val: string) {
    this._formControlAccessorName = val;
  }
  private _formControlAccessorName: string;

  /**
   * @description Specify the form control accessor. Used in a mat-form-field.
   *              If not set, the form control will be set to the parent form.
   *              If supplied the form control will be modified by form value change.
   */
  @Input()
  set formControlAccessor(val: MatFormField) {
    this._formControlAccessor = val;
  }
  private _formControlAccessor: MatFormField;

  @Input()
  set value (value: any) {
    this._value = value;
    this._core.setDisplayValue(
      this._state,
      this._elemRef,
      this._value,
      this._coalescedValue,
      this._inheritedValue,
      this._inheritedCoalescedValue
    );
  }
  private _value: any;

  @Input()
  set inheritedValue (value: any) {
    this._inheritedValue = value;
    this._core.setDisplayValue(
      this._state,
      this._elemRef,
      this._value,
      this._coalescedValue,
      this._inheritedValue,
      this._inheritedCoalescedValue
    );
  }
  private _inheritedValue: any;

  /**
   * @description If supplied the form control will be modified by form value change.
   */
  @Input()
  set coalescedControl (coalescedControl: FormControl | AbstractControl) {
    this._coalescedControl = coalescedControl;
  }
  private _coalescedControl: FormControl| AbstractControl;

  /**
   * @description If supplied the form control will be modified by form value change.
   */
  @Input()
  set control (control: FormControl | AbstractControl) {
    this._control = control;
  }
  private _control: FormControl| AbstractControl;

  @Input()
  set coalescedValue (value: any) {
    this._coalescedValue = value;
    this._core.setDisplayValue(
      this._state,
      this._elemRef,
      this._control?.value ?? this._value,
      this._coalescedValue,
      this._inheritedValue,
      this._inheritedCoalescedValue
    );
  }
  private _coalescedValue: any;

  @Input()
  set inheritedCoalescedValue (value: any) {
    this._inheritedCoalescedValue = value;
    this._core.setDisplayValue(
      this._state,
      this._elemRef,
      this._control?.value ?? this._value,
      this._coalescedValue,
      this._inheritedValue,
      this._inheritedCoalescedValue
    );
  }
  private _inheritedCoalescedValue: any;

  @Input()
  set disabled (val: boolean) {
    this._disabled = val;
  }
  private _disabled: boolean;

  @Input()
  set editable (val: boolean | string) {
    this._editable = coerceBooleanProperty(val);
  }
  private _editable: boolean = true;

  @Input()
  set availabilityMap (map: BehaviorSubject<null | Map<string, DefectType>> | null) {
    this._availabilityMap = map;
  }
  private _availabilityMap: BehaviorSubject<null | Map<string, DefectType>> | null;

  @Input()
  set flat (val: boolean | string) {
    this._flat = coerceBooleanProperty(val);
  }
  private _flat: boolean;

  ngOnDestroy() {
    if (! this._onDestroy) return;
    this._onDestroy.next(true);
    this._onDestroy.complete();
  }
}

@Directive({
  selector: '[tableFormField]',
})
export class TableFormFieldsDirective extends FormFieldsDirective {
  static parentClassList = ['outline-primary', 'border-radius'];
  static parentSelector  = 'td';
}
