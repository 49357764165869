import { ViewChild,
         Directive,
         Input,
         Output,
         EventEmitter,                                      } from '@angular/core';
import { coerceNumberProperty,
         coerceBooleanProperty                              } from '@angular/cdk/coercion';
import { BehaviorSubject,
         Subject                                            } from 'rxjs';
import { takeUntil                                          } from 'rxjs/operators';
import _                                                      from 'lodash';
import moment                                                 from 'moment';
import { FullCalendarComponent                              } from '@fullcalendar/angular';
import   interactionPlugin                                    from '@fullcalendar/interaction';
import   timeGridPlugin                                       from '@fullcalendar/timegrid';
import   momentPlugin                                         from '@fullcalendar/moment';
import { CalendarOptions                                    } from '@fullcalendar/core'

import { commonConstants                                    } from '@app/constants/common';
import { DateService                                        } from '@app/shared/services';
import { Period,
         Week                                               } from '@app/shared/interfaces';
import { CalendarOptions as Options                         } from '../calendar.interface';
import { EventSource,
         BackgroundEvent,
         ForegroundEvent                                    } from './types';
import { openPopOver                                        } from './etc';
import { Handler                                            } from './handler';
import { mapToBackgroundEvent,
         setBackgroundEvents                                } from './background-event-methods';
import { addForegroundEvent,
         getForegroundEvents,
         mapToForegroundEvent,
         removeForegroundEvent,
         removeForegroundEvents,
         setForegroundEvents                                } from './foreground-event-methods';
import { scaleHorizontally,
         scaleVertically,
         zoom                                               } from './scale-and-zoom';

const defaultEventColor = commonConstants.COLORS.EVENT_DEFAULT;

type Position = {
  start: moment.Moment | Date | null;
  end:   moment.Moment | Date | null;
}
@Directive()
export abstract class CalendarCore {
  // background event methods
  public    setBackgroundEvents  = setBackgroundEvents;
  protected mapToBackgroundEvent = mapToBackgroundEvent;

  // foreground event methods
  public    setForegroundEvents    = setForegroundEvents;
  public    getForegroundEvents    = getForegroundEvents;
  public    addForegroundEvent     = addForegroundEvent;
  public    removeForegroundEvent  = removeForegroundEvent;
  public    removeForegroundEvents = removeForegroundEvents;
  protected mapToForegroundEvent   = mapToForegroundEvent;

  // etc
  protected scaleHorizontally = scaleHorizontally;
  protected openPopOver       = openPopOver;
  protected scaleVertically   = scaleVertically;
  protected zoom              = zoom;

  // initialize handler class
  // (Need to to this since the methods are no longer static.
  //  They cant be in order to compare the class against the interface)
  private readonly _handler = new Handler();

  protected onDestroy  = new Subject<void>();
  protected onRerender = new Subject<void>();

  @ViewChild('calendar')   calendar:   FullCalendarComponent;
  // @ViewChild('popoverDiv') popoverDiv: PopoverComponent;

  // jquery selector of the calendar
  protected $calendar: JQuery<HTMLElement>;

  // subject and corresponding watch methods
  protected _onEventDragStart:  Subject<{ source: EventSource                                              }> = new Subject();
  protected _onEventDragStop:   Subject<{ source: EventSource,                         jsEvent: MouseEvent }> = new Subject();
  protected _onEventResize:     Subject<{ source: EventSource                                              }> = new Subject();
  protected _onEventDrop:       Subject<{ source: EventSource, oldPosition: Position, jsEvent: MouseEvent }> = new Subject();
  protected _onEventReceive:    Subject<{ source: EventSource                                              }> = new Subject();
  protected _onEventMouseEnter: Subject<{ source: EventSource                                              }> = new Subject();
  protected _onEventMouseLeave: Subject<{ source: EventSource                                              }> = new Subject();

  /** @summary Triggered when event dragging begins. */
  public get onEventDragStart  () { return this._onEventDragStart   .pipe(takeUntil(this.onDestroy)); }
  /** @summary Triggered when event dragging stops. */
  public get onEventDragStop   () { return this._onEventDragStop    .pipe(takeUntil(this.onDestroy)); }
  /** @summary Triggered when dragging stops and the event has moved to a different day/time. */
  public get onEventDrop       () { return this._onEventDrop        .pipe(takeUntil(this.onDestroy)); }
  /**
   * @summary Called when an external draggable element with associated event data was dropped onto the calendar.
   * Or an event from another calendar.
   * */
  public get onEventReceive    () { return this._onEventReceive     .pipe(takeUntil(this.onDestroy)); }
  /** @summary Triggered when resizing stops and the event has changed in duration. */
  public get onEventResize     () { return this._onEventResize      .pipe(takeUntil(this.onDestroy)); }
  /** @summary Triggered when the user mouses over an event. Similar to the native mouseenter. */
  public get onEventMouseEnter () { return this._onEventMouseEnter  .pipe(takeUntil(this.onDestroy)); }
  /** @summary Triggered when the user mouses out of an event. Similar to the native mouseleave. */
  public get onEventMouseLeave () { return this._onEventMouseLeave  .pipe(takeUntil(this.onDestroy)); }

  // selected events and corresponding watch method
  // (may be triggered externally)
  protected selectedEvents = new BehaviorSubject<string[]>([]);
  public get onSelectedEvents () { return this.selectedEvents.pipe(takeUntil(this.onDestroy)); }
  protected selectedOverlapGroup?:   string;   // the id of the selected events overlap group if all selected events are in the same group
  protected selectedOverlapSpecies?: string;   // - || -

  // changes to the selected events and corresponding watch method
  // (may only be triggered internally)
  protected eventSelectionChange = new Subject<{ change: string | null, selected: string[] }>();
  public get onEventSelectionChange () { return this.eventSelectionChange.pipe(takeUntil(this.onDestroy)); }

  // the event being dragged
  // (in case of an update the events will be rerendered and the one being dragged appeared twice,
  //  hence we need to keep track of it)
  protected draggedEvent?: string;

  // ordinary events
  protected foregroundEvents = new BehaviorSubject<ForegroundEvent[]>([]);
  protected overlapGroups: string[] = [];   // ids of the overlapping groups of the FG events

  // background events such as unavailable intervals
  protected backgroundEvents = new BehaviorSubject<BackgroundEvent[]>([]);

  // if the alt key is pressed
  protected moveIndividually = new BehaviorSubject<boolean>(false);
  protected multipleSelect   = new BehaviorSubject<boolean>(false);

  protected calendarOptions: CalendarOptions = {
    headerToolbar: false,
    timeZone: 'UTC',
    initialView: 'timeGridWeek',
    titleFormat: { weekday: 'short' },
    dayHeaderFormat: { weekday: 'short' },
    // dayHeaderContent: (args) => {
    //   return moment(args.date).format('ddd Do')
    // },
    slotLabelInterval: '01:00:00',
    slotDuration: '01:00:00',
    snapDuration: '00:05:00',
    scrollTimeReset: false,
    scrollTime: '00:00:00',
    allDaySlot: false,
    eventTimeFormat: {
      hour: 'numeric',
      minute: '2-digit',
      hour12: false
    },
    slotLabelFormat: {
      hour: 'numeric',
      minute: '2-digit',
      hour12: false
    },
    firstDay: 1,
    hiddenDays: [ 0, 6 ],
    height: '100%',
    droppable: true,
    plugins: [
      interactionPlugin,
      timeGridPlugin,
      momentPlugin
    ],
    displayEventTime:      true,
    displayEventEnd:       true,
    slotEventOverlap:      false,
    editable:              false,
    eventDurationEditable: false,
    eventColor:            defaultEventColor,
    eventMinHeight:        0,
    dragRevertDuration:    0,

    // do not resize the calendar, we will do it manually
    handleWindowResize: false,

    // ensure that we use the same name convention here
    eventAllow:      this._handler.eventAllow     .bind(this),
    eventClick:      this._handler.eventClick     .bind(this),
    eventResize:     this._handler.eventResize    .bind(this),
    eventDrop:       this._handler.eventDrop      .bind(this),
    eventReceive:    this._handler.eventReceive   .bind(this),
    eventDragStart:  this._handler.eventDragStart .bind(this),
    eventDragStop:   this._handler.eventDragStop  .bind(this),
    dateClick:       this._handler.dateClick      .bind(this),
    eventClassNames: (x: any) => { return this._handler.eventClassNames.bind(this)(x) },
    eventDidMount:   (x: any) => { return this._handler.eventDidMount  .bind(this)(x) },
    eventMouseEnter: this._handler.eventMouseEnter.bind(this),
    eventMouseLeave: this._handler.eventMouseLeave.bind(this),

    // event firing whenever the calendar is resized
    _resize: () => scaleHorizontally.bind(this)(),

    // event firing whenever the content of the calendar headers are updated
    dayHeaderContent:  this._handler.dayHeaderContent.bind(this),
  };

  constructor (
    protected _date: DateService
  ) { }


  // sets the time limits of the calendar
  @Input()
  set visibleRange (intervals: { start: moment.Moment, end: moment.Moment }[] | null) {
    if (! intervals?.length) return;
    // get min and max time as minutes from midnight
    let start = intervals
      .map(({ start }) => start.diff(start.clone().startOf('day'), 'minutes'))
      .reduce((a, b) => Math.min(a, b), Infinity);
    let end = intervals
      .map(({ end }) => end.diff(end.clone().startOf('day'), 'minutes'))
      .reduce((a, b) => Math.max(a, b), -Infinity);

    // add one hour of padding in each direction
    const pad = 60;
    start -= pad;
    end   += pad;

    this._visibleRange.next({ start, end });
  }
  protected _visibleRange = new BehaviorSubject({
    start: 0  * 60,
    end:   24 * 60,
  });
  protected _numVisibleHours = 24;

  @Input()
  get options () { return this._options }
  set options (_val) {
    const val: Options = _val ?? {};
    this._options = _val;
    this.calendarOptions.editable = val.editMode;
    if (val.canResizeEvents) {
      this.calendarOptions.eventDurationEditable = true;
    }

    Object.assign(this.calendarOptions.dayHeaderFormat!, { day: val.showDayNumber ? 'numeric' : undefined });

    // default value
    if ( ! ('bottomFade' in this._options)) this._options.bottomFade = true;
  }
  private _options: Options = {};

  @Input()
  set singleDay (val: number | null) {
    this.calendarOptions.hiddenDays = Array(7).fill(0).map((_, i) => i).filter(i => val != null && i != val - 1);
    this.calendarOptions.firstDay   = val == null ? 1 : val - 1;
  }

  @Input()
  set disableCorrectEventPosition(val: boolean | string) {
    this._disableCorrectEventPosition = coerceBooleanProperty(val);
  }
  protected _disableCorrectEventPosition = false;

  @Input()
  get numDays(): number { return this._numDays }
  set numDays(_val: number | null | undefined) {
    this._numDays = coerceNumberProperty(_val, 5);

    // hide/show days
    if      (this.numDays == 5) this.calendarOptions.hiddenDays = [0, 6];
    else if (this.numDays == 7) this.calendarOptions.hiddenDays = [];

    // set start and end date
    this._startDate = DateService.firstDay.startOf('day');
    this._endDate   = DateService.firstDay.endOf  ('day').add(this.numDays - 1, 'days');
  }
  private _numDays: number = 5;

  @Input()
  get selectedWeek () { return this._selectedWeek }
  set selectedWeek (week: Week | null | undefined) {
    this._selectedWeek = week ?? null;
    // trigger a rerender of the calendar headers
    this.calendarOptions.dayHeaderFormat = structuredClone(this.calendarOptions.dayHeaderFormat);
  }
  protected _selectedWeek: Week | null = null;

  get startDate () { return this._startDate.clone() }
  get endDate   () { return this._endDate  .clone() }
  private _startDate: moment.Moment = DateService.firstDay.startOf('day');
  private _endDate:   moment.Moment = DateService.firstDay.endOf  ('day').add(this.numDays - 1, 'days');

  // enables/disables the ability to select multiple events by ctrl/cmd + click
  @Input()
  get multiselectMode() { return this._multiselectMode }
  set multiselectMode(_val) {
    this._multiselectMode = coerceBooleanProperty(_val);
  }
  private _multiselectMode: boolean = false;

  // a filter function that checks whether an event is allowed to be selected
  @Input()
  public isSelectableEvent: (e: EventSource) => boolean = () => true;

  @Input()
  get defaultColor(): string { return this._defaultColor }
  set defaultColor(_val: string) {
    // must match a color
    if ( ! (new RegExp('^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3}|[A-Fa-f0-9]{8})$')).test(_val)) {
      console.warn(`Not a recognized color: ${_val}`);
      return;
    }

    this.calendarOptions.eventColor = _val;
    this._defaultColor              = _val;
  }
  private _defaultColor: string = commonConstants.COLORS.PRIMARY;

  @Input()
  set listenToTimeAxisHover (_val: boolean | string) {
    // emit only truthy values and only once
    const val = coerceBooleanProperty(_val);
    if (val && ! this.onListenToTimeAxisHover.value)
      this.onListenToTimeAxisHover.next(val);
  }
  protected onListenToTimeAxisHover = new BehaviorSubject<boolean>(false);

  @Input()
  get defaultPeriod () { return this._defaultPeriod }
  set defaultPeriod (period: Period | null | undefined) {
    this._defaultPeriod = period ?? null;

    // force a rerender of the calendar
    this.onRerender.next();
  }
  private _defaultPeriod: Period | null = null;

  @Input({ transform: coerceBooleanProperty })
  get loading () { return this._loading }
  set loading (val: boolean) { this._loading = val; }
  private _loading: boolean = false;

  @Output() onTimeAxisHover = new EventEmitter<moment.Moment | undefined>();
}
