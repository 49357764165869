import { AbstractControl,
         FormControl,
         FormGroup,
         ValidationErrors,
         ValidatorFn                     } from '@angular/forms';
import moment                              from 'moment';

import { DateService                     } from '../services';


const regex = new RegExp('^([0-1]?[0-9]|2[0-3]):[0-5][0 | 5]$');

const _dateValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const value = control.value;
  return moment(value).isValid() ? null : { invalidDate: true };
}

export const invalidTimeKey = 'invalidTime';
const _timeValidator: ValidatorFn = (control: AbstractControl<string | moment.Moment>): Record<typeof invalidTimeKey, true> | null => {
  const value = control.value;
  if (typeof value == 'string') {
    return regex.test(value) ? null : { [invalidTimeKey]: true };
  } else {
    return value.isValid() && regex.test(value.format('HH:mm')) ? null : { [invalidTimeKey]: true };
  }
}

const _multipleOf = (factor: number): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const value: number = control.value ?? 0;
    return value % factor == 0 ? null : { invalidMultiple: true };
  }
}

const _discretizedBy = (factor: number): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    try {
      const value: moment.Moment | string = control.value;

      if (value instanceof moment) {
        if (! (value as moment.Moment).isValid())
          return { invalidTime: true };
        return (value as moment.Moment).minutes() % factor == 0 ? null : { invalidDiscretization: true };
      } else {
        if (! regex.test(value as string))
          return { invalidTime: true };
        const [hours, minutes] = (value as string).split(':');
        return parseInt(minutes) % factor == 0 ? null : { invalidDiscretization: true };
      }
    } catch(err) {
      return { unkownError: true };
    }
  }
}
const _isSameDay = (start: string, end: string): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    let _start: moment.Moment = control.get(start)?.value;
    let _end:   moment.Moment = control.get(end)?.value;

    if (_start?.day() == _end?.day())
      return null;
    else
      return { invalidDay: true };
  }
}


function validTime (val: moment.Moment | string): boolean {
  if (typeof val === 'object' ? val.isValid() : regex.test(val)) return true;
  return false;
}

function addError (control: AbstractControl, errorKey: string): void {
  const errors = control.errors ?? {};
  errors[errorKey] = true;
  control.setErrors(errors);
}

function removeError (control: AbstractControl, errorKey: string): void {
  const errors = control.errors ?? {};
  delete errors[errorKey];
  control.setErrors(Object.keys(errors).length > 0 ? errors : null);
}

namespace ValidateThat {
  type FG = FormGroup<Record<string, FormControl<moment.Moment | string | number>>>;
  type TimeControl     = FormControl<moment.Moment | string> | undefined;
  type DurationControl = FormControl<any> | undefined;

  type KeyAndValueAccessor = { key: string, valueAccessor?: (val: any) => any } | string;

  function standardize (key: KeyAndValueAccessor): Exclude<KeyAndValueAccessor, string> {
    return typeof key === 'string' ? { key } : key;
  }

  export const validator = (
    time1:      string,
    comparator: 'isBefore' | 'isSameOrBefore',
    time2:      string,
    margin?:    KeyAndValueAccessor,
  ): ValidatorFn => {
    return (fg: FG): ValidationErrors | null => {
      const startCtrl  = fg.controls[time1] as TimeControl;
      const endCtrl    = fg.controls[time2] as TimeControl;
      const marginCtrl = margin != null ? fg.controls[standardize(margin).key] as DurationControl : undefined;
      if ( ! startCtrl || ! endCtrl) {
        console.error(`(IsLaterTime::validator) Could not find form controls ${ time1 } and/or ${ time2 }`);
        return null;
      }
      if (margin && ! marginCtrl) {
        console.error(`(IsLaterTime::validator) Could not find form control ${ margin }`);
        return null;
      }

      const t1 = startCtrl.value;
      const t2 = endCtrl  .value;
      if ( ! validTime(t1)) return { invalidTime: true };
      if ( ! validTime(t2)) return { invalidTime: true };

      const mnt1 = DateService.fromTimeString(typeof t1 === 'string' ? t1 : t1.format('HH:mm'));
      const mnt2 = DateService.fromTimeString(typeof t2 === 'string' ? t2 : t2.format('HH:mm'));

      let marginVal = 0;
      if (margin && marginCtrl) {
        const value = marginCtrl.value;
        const valueAccessor = standardize(margin).valueAccessor;
        marginVal = valueAccessor ? valueAccessor(value) : value;
      }

      if (mnt1.clone().add(marginVal, 'minutes')[comparator](mnt2)) {
        removeError(startCtrl, 'time_too_late');
        removeError(endCtrl,   'time_too_early');
        return null
      } else {
        addError(startCtrl, 'time_too_late');
        addError(endCtrl,   'time_too_early');
        return { invalidTimes: true };
      }
    }
  }
}


function validateThat (
  time1:    moment.Moment | string,
  cmpFn:    'isBefore' | 'isAfter' | 'isSameOrBefore' | 'isSameOrAfter',
  time2:    moment.Moment | string,
  errorKey: string
): ValidationErrors | null {
    if ( ! validTime(time1)) return { invalidTime: true };
    if ( ! validTime(time2)) return { invalidTime: true };

    const mnt1 = DateService.fromTimeString(typeof time1 === 'string' ? time1 : time1.format('HH:mm'));
    const mnt2 = DateService.fromTimeString(typeof time2 === 'string' ? time2 : time2.format('HH:mm'));

    return mnt1[cmpFn](mnt2) ? null : { [errorKey]: true };
}

const _minTime = (min: moment.Moment | string): ValidatorFn => {
  return (control: FormControl<moment.Moment | string>): ValidationErrors | null => {
    return validateThat(min, 'isBefore', control.value, 'time_too_early');
  }
}

const _maxTime = (max: moment.Moment | string): ValidatorFn => {
  return (control: FormControl<moment.Moment | string>): ValidationErrors | null => {
    return validateThat(max, 'isAfter', control.value, 'time_too_late');
  }
}

const _isLaterDate = (start: string, end: string): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const _start = moment(control.get(start)?.value);
    const _end   = moment(control.get(end)?.value);

    if (! (_start.isValid() && _end.isValid()))
      return { invalidDate: true };

    return _start.isBefore(_end) ? null : { invalidLaterDate: true };
  }
}

const _compare = (master: string, slave: string): ValidatorFn => {
  return (control: any): { [key: string]: boolean } | null => {
    const _master: AbstractControl = control.controls[master];
    const _slave:  AbstractControl = control.controls[slave];

    const valid = _master?.value === _slave?.value;

    if (! valid)
      _slave.setErrors({ equal: true });

    return valid ? null : { equal: false };
  };
}

const _enum = (values: any[]): ValidatorFn => {
  return (control: any): { [key: string]: boolean } | null => {
    const { value } = control;

    return values.includes(value) ? null : { equal: false };
  }
}

export class ExtendedValidators {
  static isTime         = _timeValidator;
  static isDate         = _dateValidator;
  static isMultipleOf   = _multipleOf;
  static validateThat   = ValidateThat.validator;
  static isLaterDate    = _isLaterDate;
  static discretizedBy  = _discretizedBy;
  static isSameDay      = _isSameDay;
  static minTime        = _minTime;
  static maxTime        = _maxTime;
  static compare        = _compare;
  static enum           = _enum;
}