import { Injectable                      } from '@angular/core';
import { Router                          } from '@angular/router';
import { Observable,
         Subject,
         BehaviorSubject                 } from 'rxjs';
import { debounceTime,
         filter,
         map,
         pairwise,
         startWith                       } from 'rxjs/operators';

import { RoutingService                  } from 'app/core/routing/routing.service';
import { HttpService                     } from 'app/core/http/http.service';
import { StorageService                  } from 'app/core/storage/storage.service';
import { ThreadService                   } from 'app/core/thread/thread.service';
import { EnvironmentService              } from 'app/core/environment/environment.service';
import { BroadcastService                } from 'app/core/broadcast/broadcast.service';
import { LoggerService                   } from 'app/core/logger/logger.service';
import { storageKeys as tableColumnsKeys } from 'app/shared/tables/schedule-data-tables/services/table-columns/constants';
import { storageKeys as customSearchKeys } from '@app/shared/tables/schedule-data-tables/services/custom-search/constants';
import { storageConstants                } from 'app/constants';
import { MatDialog                       } from 'app/common';
import { Session,
         AuthData                        } from './auth.interface';
import { Core                            } from './auth.core';


@Injectable({
  providedIn: 'root'
})
export class AuthService extends Core {
  protected onCheck = new Subject<void>();

  /** @description maps access tokens that are up for renewal to a subject that will cancel the renewal process when triggered */
  protected scheduledTokenRenewals = new Map<string, Subject<void>>();

  /** @description the active authentication details, there might be several ones but only one can be active at a time */
  public get onActive(): Observable<Session | null> { return this._active; }
  protected _active = new BehaviorSubject<Session | null>(null);

  /** @description the parent authentication details, the one above the active one, if any */
  public get onParent(): Observable<Session | null> { return this._parent; }
  protected _parent = new BehaviorSubject<Session | null>(null);

  /** @description the number of identities that are authenticated */
  public get numIdentities   (): number             { return this._numIdentities.value; }
  public get onNumIdentities (): Observable<number> { return this._numIdentities; }
  protected _numIdentities = new BehaviorSubject<number>(0);

  public get isAuthenticated   (): boolean             { return this._isAuthenticated.value; }
  public get onIsAuthenticated (): Observable<boolean> { return this._isAuthenticated; }
  protected _isAuthenticated = new BehaviorSubject<boolean>(false);

  public get isAdmin   (): boolean             { return this._isAdmin.value; }
  public get onIsAdmin (): Observable<boolean> { return this._isAdmin; }
  protected _isAdmin = new BehaviorSubject<boolean>(false);


  constructor (
    protected _routing:     RoutingService,
    protected _router:      Router,
    protected _http:        HttpService,
    protected _environment: EnvironmentService,
    protected _broadcast:   BroadcastService,
    protected _logger:      LoggerService,
    protected _dialog:      MatDialog,
    protected _thread:      ThreadService,
    protected _storage:     StorageService
  ) {
    super();

    // must be set up before all other subscriptions
    this.onCheck.pipe(
      debounceTime(200),
      filter(() => ! this._storage.isPurging)
    ).subscribe(() => this._check());


    // the active authentication details
    this._storage.onStorage(storageConstants.AUTH_DETAILS)
    .pipe(map(() => this._loadAndValidateSessions()))
    .subscribe(sessions => {
      // check validity of token
      this.checkAuthentication();

      // how many identities are authenticated
      this._numIdentities.next(sessions.length);

      // the active auth is the last one
      const activeSession = sessions.at(-1);
      this._active.next(activeSession ?? null);
      this._isAuthenticated.next( !! activeSession);

      // the parent auth is the one above the active one
      const parentAuth = sessions.at(-2);
      this._parent.next(parentAuth ?? null);

      // check if the root identity is an admin
      this._isAdmin.next(sessions.at(0)?.role === 'admin');

      // set routes
      if (activeSession) this._routing.setPrivateRoutes(activeSession.role);
      else               this._routing.setPublicRoutes();

      // set environment
      this._environment.theme            = activeSession?.theme            ?? activeSession?.associatedPartner ?? null;
      this._environment.organizationType = activeSession?.organizationType ?? null;
      this._environment.appFeatures      = activeSession?.appFeatures;
    });

    // navigate back to the url where the latest session ended.
    // need to occur after the routs are updated
    // must be separated as to not redirect if, e.g., the access token is updated
    this._storage.onStorage(storageConstants.AUTH_DETAILS)
    .pipe(
      map(() => this._loadAndValidateSessions().map(x => x.returnToUrl)),
      pairwise()
    )
    .subscribe(([prev, curr]) => {
      // if there are fewer sessions now than before, it means one was removed
      if (prev.length > curr.length) {
        this._router.navigateByUrl(prev.at(-1) ?? '/');
      }
    });

    // unsubscribe from all scheduled token renewals of discarded auth details
    this._storage.onStorage(storageConstants.AUTH_DETAILS)
    .pipe(
      map(() => this._loadAndValidateSessions().map(x => x.accessToken)),
      startWith(new Array<string>()),
      pairwise()
    )
    .subscribe(([prev, next]) => {

      // find out which auth details were discarded
      const discarded = prev.filter(x => ! next.includes(x));
      discarded.forEach(x => {
        // console.log('discarded', x.substring(0, 5) + '...' + x.substring(x.length - 5));   // for testing
        this.scheduledTokenRenewals.get(x)?.next();
        this.scheduledTokenRenewals.get(x)?.complete();
        this.scheduledTokenRenewals.delete(x);
      });
    });


    // listen for 401
    this._http.watchStatus()
    .subscribe(() => {
      this._logger.debug('(Auth::service) Http service got a 401');
      this._openRenewDialog();
    });
  }

  /** @description adds a new auth identity which also becomes the active one */
  public login(data: AuthData): void {
    // // TEMP: require the refresh token to be present
    if ( ! data.token.refreshToken) {
      console.warn('refresh token is missing', data);
      // return
    }

    this._addNewSession(data);
    this._storeGlobals(data);

  }

  /** @description deletes the active auth identity and sets the parent one as active */
  public loginToParent(): void {
    this._removeActiveSession();
  }

  /** @description deletes all auth identities */
  public logout(): void {
    this._http.get('auth/logout', undefined, true)
    .subscribe(() => {
    });
    // We do not want to remove everything from localStorage. For example the table column preferences
    // of the schedule data are stored here and should persist from session to session for convenience.
    const preservedKeys = [
      ...Object.values(tableColumnsKeys),
      ...Object.values(customSearchKeys)
    ];
    this._storage.clear(preservedKeys);
  }

  public checkAuthentication():void {
    this.onCheck.next();
  }

}