import { AfterViewInit,
         Component,
         ElementRef,
         EventEmitter,
         HostBinding,
         HostListener,
         Input,
         OnDestroy,
         Optional,
         Output,
         Self,
         ViewChild                          } from '@angular/core';
import { CommonModule                       } from '@angular/common';
import { ControlValueAccessor,
         FormControl,
         FormsModule,
         NgControl,
         ReactiveFormsModule                } from '@angular/forms';
import { MatFormFieldControl                } from '@angular/material/form-field';
import { MatMenuTrigger                     } from '@angular/material/menu';
import { FocusMonitor                       } from '@angular/cdk/a11y';
import { coerceBooleanProperty              } from '@angular/cdk/coercion';
import { COMMA,
         ENTER,
         TAB                                } from '@angular/cdk/keycodes';
import { MatChipInputEvent                  } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent,
         MatOption                          } from '@angular/material/autocomplete';
import { MatInput                           } from '@angular/material/input';
import { BehaviorSubject,
         Observable,
         Subject,
         combineLatest,
         fromEvent,
         map,
         startWith,
         takeUntil                          } from 'rxjs';
import { NgPipesModule                      } from 'ngx-pipes';
import { NgxCleaveDirectiveModule           } from 'ngx-cleave-directive';
import _                                      from 'lodash';

import { TranslationModule                  } from 'app/core/translate/translate.module';
import { AppCommonModule                    } from 'app/common/common.module';
import { Tags, PartialTags                               } from 'app/shared/interfaces';
import { DisplayValueComponent              } from './components/display-value/display-value.component';
import { MinutesFormFieldModule             } from '../minutes/minutes.module';
import { NumHoursFormFieldModule            } from '../num-hours/num-hours.module';

type Tag = PartialTags[number];

type Value = PartialTags | null | undefined;


function compareFn (a: Tag, b: Tag): number {
  // first compare partial
  if (  a.partial && ! b.partial) return -1;
  if (! a.partial &&   b.partial) return 1;

  // then compare value
  return a.value.localeCompare(b.value);
}

@Component({
  standalone: true,
  selector: 'app-form-field-tags',
  templateUrl: 'tags.component.html',
  styleUrl: 'tags.component.scss',
  imports: [
    CommonModule,
    AppCommonModule,
    NgPipesModule,
    TranslationModule,
    FormsModule,
    NgxCleaveDirectiveModule,
    ReactiveFormsModule,
    DisplayValueComponent,
    MinutesFormFieldModule,
    NumHoursFormFieldModule,
  ],
  // in order to use the component as a form field control, we need to provide it as a form field control
  providers: [
    { provide: MatFormFieldControl, useExisting: TagsComponent }
  ],
})
export class TagsComponent
  implements AfterViewInit, OnDestroy, ControlValueAccessor, MatFormFieldControl<Value> {

  @ViewChild(MatMenuTrigger) trigger?: MatMenuTrigger;

  @ViewChild(MatInput) input?: MatInput;

  @Output('onChange') emitter = new EventEmitter<Value>();

  private onClose     = new Subject<void>();
  static nextId       = 0;
  public stateChanges = new Subject<void>();
  public focused      = false;
  public errorState   = false;
  public controlType  = 'tags-input';
  public id           = `tags-input-${ TagsComponent.nextId++ }`;
  public describedBy  = '';
  public onChange:  any = () => {};
  public onTouched: any = () => {};


  protected readonly separatorKeysCodes: number[] = [ENTER, COMMA, TAB];

  protected readonly ctrl = new FormControl<Tag | string>('');

  protected readonly suggestedTags$: Observable<Tags>;
  protected readonly valueChanged$ = new BehaviorSubject<boolean>(false);


  // to be able to tab to the input
  @HostBinding('attr.tabindex') __tabindex = 0;

  @HostListener('keydown', ['$event'])
  onKeydown(event: KeyboardEvent) {
    // open unless tab (or shift+tab) is pressed
    console.log('host keydown', event.key)

    if (event.key == 'Tab') return;
    this.trigger?.openMenu();
  }

  constructor (
    private _focusMonitor: FocusMonitor,
    private _elementRef:   ElementRef<HTMLElement>,
    @Optional() @Self()
    public ngControl: NgControl
  ) {

    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.focused && ! origin) this.onTouched();
      this.focused = !! origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }


    this.suggestedTags$ = combineLatest({
        all:      this._tagOptions$,
        selected: this.value$,
        input:    this.ctrl.valueChanges.pipe(startWith(''), map(x => x && typeof x === 'object' ? x : { value: (x ?? '').trim() }))
      })
      .pipe(
        map(({ all, selected, input }) => {
          // already selected tags (ignore partial ones)
          const fullySelected = (selected ?? [])
            .filter(x => ! x.partial)
            .map(x => x.value);

          return all.filter(x => {
            // must match the input value
            if ( ! x.value.includes(input.value)) return false;

            // must not be already selected
            if (fullySelected.includes(x.value)) return false;

            return true;
          });
        })
      );


    combineLatest({
      selected: this.value$,
      pristine: this._pristineValue$
    })
    .pipe(
      map(({ selected, pristine }) => {
        // strict null vs undefined comparison
        // (so we may reset the bulk inputs of different values (undefined -> null)
        if ( ! selected || ! pristine) return selected !== pristine;

        const a = structuredClone(selected)?.sort(compareFn);
        const b = structuredClone(pristine)?.sort(compareFn);
        return ! _.isEqual(a, b);
      })
    )
    .subscribe(this.valueChanged$);



    // // to use in combination with MatFormFieldControl
    // combineLatest({
    //   value: value$,
    //   unit:  unit$,
    // })
    // .pipe(
    //   filter(() => this.valid()),
    //   map(({ value, unit }) => {
    //     if (value == null) return null;

    //     // strip the value of any non-numeric characters
    //     // (it seems this triggers before cleave directive has a chance to format the value, so we need to do it manually)
    //     value = value.replace(/\D/g, '');

    //     return `${value} ${unit}`;
    //   })
    // )
    // .subscribe(x => this.onChange(x));
  }

  public closed(): void {
    if (this.saveOnClose && this.valueChanged$.value) this.emitter.emit(this.value);
    this._clearInput();
    this.onClose.next();
  }

  public opened(): void {
    fromEvent(document, 'keydown')
    .pipe(takeUntil(this.onClose))
    .subscribe((event: KeyboardEvent) => {
      if (event.key == 'Escape' || event.key == 'Enter') this.trigger?.closeMenu()
    });

    // focus on the input element, need to wait for the next tick
    // (wait a bit for animations to complete so that the position and size of the menu is calculated correctly)
    setTimeout(() => this.input?.focus(), 200);
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
  }


  public resetValue(): void {
    this._clearInput();
    this.value = this._pristineValue$.value;
  }

  protected clearValue(): void {
    this._clearInput();
    this.value$.next(null);
  }

  private _clearInput() {
    if (this.input) this.input.value = '';
    this.ctrl.setValue(null);
  }

  protected remove (tag: Tag): void {
    // compare both type and value
    const tags = this.value$.value?.filter(x => x.type != tag.type || x.value != tag.value);
    this.value$.next(tags);
  }

  private _addTag (tag: Tag): void {
    // add unless identical teg already exists
    const fullySelected = (this.value$.value ?? []).filter(x => ! x.partial);
    if ( ! fullySelected.find(x => x.type == tag.type && x.value == tag.value)) {

      // add (or overide if partial version exists)
      const _tags = (this.value$.value ?? []).filter(x => ! (x.type == tag.type && x.value == tag.value));
      const tags = [..._tags, tag].sort(compareFn);
      this.value$.next(tags);
    }

    // if a separator key was pressed, this function executes before the keydown event
    // hence, to close the menu if tab or enter was hit without there being any value
    // we must delay the clearing of the input a bit so that the keydown event can use its state
    setTimeout(() => this._clearInput());
  }

  protected add (event: MatChipInputEvent): void {
    const value = event.value.trim();
    if ( ! value) return;

    this._addTag({ value });
  }

  protected keydown (event: KeyboardEvent): void {
    // prevent the menu from closing when pressing enter or tab after adding a tag
    if ((event.key == 'Enter' || event.key == 'Tab') && this.input?.value) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  protected selected (event: MatAutocompleteSelectedEvent): void {
    // add unless identical teg already exists
    const tag = (event.option as MatOption<Tag>).value;
    this._addTag(tag);
  }





  get empty() { return ! this.value; }

  get shouldLabelFloat() { return this.focused || ! this.empty; }

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input({ transform: coerceBooleanProperty })
  public nullable: boolean = false;

  @Input({ transform: coerceBooleanProperty })
  public hideToolbar: boolean = false;

  @Input()
  get value(): NonNullable<Value> {
    return this.value$.value ?? [];
  }
  set value(value: Value) {
    // order
    value = value?.sort(compareFn);

    this.value$         .next(value);
    this._pristineValue$.next(value);
    this.stateChanges   .next();
  }
  protected readonly value$  = new BehaviorSubject<Value>([]);
  private readonly _pristineValue$ = new BehaviorSubject<Value>(null);

  @Input()
  set tagOptions (value: Tags | null) {
    this._tagOptions$.next(value ?? []);
  }
  private readonly _tagOptions$ = new BehaviorSubject<Tags>([]);

  @Input()
  get saveOnClose(): boolean { return this._saveOnClose; }
  set saveOnClose(value: boolean | string) {
    this._saveOnClose = coerceBooleanProperty(value);
  }
  private _saveOnClose: boolean = false;

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
    this.onClose.next();
    this.onClose.complete();
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick (event: MouseEvent) {
    // open the menu when MatFormFieldControl is clicked
    this.trigger?.openMenu();
  }

  writeValue(val: Value): void {
    this.value = val;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  static ngAcceptInputType_disabled: boolean | string | null | undefined;
  static ngAcceptInputType_required: boolean | string | null | undefined;
}