import { NgModule                                } from '@angular/core';
import { CommonModule                            } from '@angular/common';

import { AppCommonModule                         } from 'app/common/common.module';
import { TranslationModule                       } from 'app/core/translate/translate.module';
import { FormFieldsModule                        } from 'app/shared/form-fields/form-fields.module';
import { ComponentsModule as AppComponentsModule } from 'app/shared/components/components.module';
import { FormsModule      as AppFormsModule      } from 'app/shared/forms/forms.module';

import { CoursesModule                           } from './courses/courses.module';
import { EventsModule                            } from './events/events.module';
import { GroupsModule                            } from './groups/groups.module';
import { PersonsModule                           } from './persons/persons.module';
import { TeachersModule                          } from './teachers/teachers.module';
import { LocationsModule                         } from './locations/locations.module';
import { ComponentsModule                        } from './components/components.module';


@NgModule({
  declarations: [
  ],
  exports: [
    CoursesModule,
    EventsModule,
    GroupsModule,
    PersonsModule,
    TeachersModule,
    LocationsModule
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    TranslationModule,
    AppFormsModule,
    AppComponentsModule,
    FormFieldsModule,
    ComponentsModule
  ]
})
export class ScheduleDataTablesModule { }
