import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'destruct'
})
export class DestructPipe implements PipeTransform {

  transform<T extends object>(value: (null | T | { to: T })[]) {
    return value.filter(Boolean).map(x => 'to' in x ? x.to : x);
  }
}