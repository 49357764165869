import { checkIfMaster,
         killMaster,
         checkIfMasterExist              } from './thread.check';
import { _listen                         } from './thread.sync';
import { _emitPing,
         _receivePing                    } from './thread.ping';

export class Core {
  protected _killMaster     = killMaster;
  protected _listen         = _listen;
  protected _emitPing       = _emitPing;
  protected _receivePing    = _receivePing;
  protected checkIfMaster   = checkIfMaster;
  public checkIfMasterExist = checkIfMasterExist;
}