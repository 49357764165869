import { NgModule                        } from '@angular/core';
import { CommonModule                    } from '@angular/common';

import { ClickOutsideDirective           } from './click-outside/click-outside.directive';
import { TableDeleteDirective            } from './table-delete/table-delete.directive';
import { VarDirective                    } from './ng-var/ng-var.directive';
import { TemplateTypesDirective          } from './template-types/template-types.directive';
import { ResizeObserverDirective         } from './resize-observer/resize-observer.directive';
import { TypeSafeMatCellDefDirective     } from './type-safe-mat-cell-def/type-safe-mat-cell-def.directive';

import { TooltipModule                   } from './tooltip/tooltip.module';

@NgModule({
  declarations: [
    ClickOutsideDirective,
    TableDeleteDirective,
    VarDirective,
    TemplateTypesDirective,
    ResizeObserverDirective,
    TypeSafeMatCellDefDirective
  ],
  exports: [
    ClickOutsideDirective,
    TableDeleteDirective,
    VarDirective,
    TemplateTypesDirective,
    ResizeObserverDirective,
    TypeSafeMatCellDefDirective,
    TooltipModule
  ],
  imports: [
    CommonModule
  ]
})
export class DirectivesModule { }
