import { _check                                      } from './auth.check';
import { _renewAccess                                } from './auth.renew';
import { _openRenewDialog                            } from './auth.dialog';
import { getToken,
         getUserId,
         getUsername,
         getCreatedAt,
         getAssociatedPartner,
         getOrganizationNames,
         getTokenExpirationTime,
         _loadAndValidateSessions                    } from './auth.get';
import { _storeGlobals,
         _updateToken,
         _transformAuthData,
         _addNewSession,
         _removeActiveSession,
         _replaceSession                             } from './auth.set';

export class Core {
  protected _check                   = _check;
  protected _renewAccess             = _renewAccess;
  protected _openRenewDialog         = _openRenewDialog;
  protected _loadAndValidateSessions = _loadAndValidateSessions;
  protected _storeGlobals            = _storeGlobals;
  protected _updateToken             = _updateToken;
  protected _addNewSession           = _addNewSession;
  protected _removeActiveSession     = _removeActiveSession;
  protected _replaceSession          = _replaceSession;
  protected _transformAuthData       = _transformAuthData;

  public getToken               = getToken;
  public getTokenExpirationTime = getTokenExpirationTime;
  public getUserId              = getUserId;
  public getUsername            = getUsername;
  public getCreatedAt           = getCreatedAt;
  public getAssociatedPartner   = getAssociatedPartner;
  public getOrganizationNames   = getOrganizationNames;
}