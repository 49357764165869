import { NgModule                        } from '@angular/core';
import { CommonModule                    } from '@angular/common';
import { FormsModule,
         ReactiveFormsModule             } from '@angular/forms';
import { ScrollingModule                 } from '@angular/cdk/scrolling';
import { NgPipesModule                   } from 'ngx-pipes';

import { AppCommonModule                 } from 'app/common/common.module';
import { TranslationModule               } from 'app/core/translate/translate.module';
import { PipesModule                     } from 'app/shared/pipes/pipes.module';
import { ToolbarContainerModule          } from '../toolbar-container/toolbar-container.module';
import { SearchModule                    } from '../search/search.module';
import { FilterSecondaryPipe,
         ToNgModelPipe,
         FilterPrimaryPipe               } from './schema-filter-dropdowns.pipe';
import { SchemaFilterDropdownsComponent  } from './schema-filter-dropdowns.component';
import { ComponentsModule                } from './components/components.module';

@NgModule({
  declarations: [
    SchemaFilterDropdownsComponent,
  ],
  exports: [
    SchemaFilterDropdownsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ScrollingModule,
    ReactiveFormsModule,
    AppCommonModule,
    TranslationModule,
    PipesModule,
    NgPipesModule,
    SearchModule,
    ToolbarContainerModule,
    ComponentsModule,
    FilterPrimaryPipe,
    FilterSecondaryPipe,
    ToNgModelPipe
  ]
})
export class SchemaFilterDropdownsModule { }
