import { ComponentRef,
         Directive,
         ElementRef,
         EmbeddedViewRef,
         EventEmitter,
         HostListener,
         Input,
         Output,
         TemplateRef,
         ViewContainerRef                } from '@angular/core';
import { Subject,
         distinctUntilChanged,
         take,
         filter,
         fromEvent,
         takeUntil,
         Observable,
         timer,
         auditTime,
         switchMap                       } from 'rxjs';
import { TemplatePortal                  } from '@angular/cdk/portal';
import { Router                          } from '@angular/router';
import _                                   from 'lodash';

import { UserPreferencesService          } from '@app/core';

import { matTooltipDefaultOptions        } from '@app/app.module';

import { Tutorial                        } from '@app/shared/services/tutorials/tutorials.types';
import { TutorialsService                } from '@app/shared/services/tutorials/tutorials.service';
import { Catch                           } from '@app/shared/decorators/catch/catch.decorator';

import { TooltipComponent                } from './tooltip.component';


export const tooltipPositions = ['above', 'below', 'left', 'right'] as const;
export const defaultTooltipPosition = 'below';
export type TooltipPosition = typeof tooltipPositions[number];

export const tooltipThemes = ['dark', 'light'] as const;
export const defaultTooltipTheme = 'dark';
export type TooltipTheme = typeof tooltipThemes[number];

const onSingleton = new Subject<number>();

@Directive({
  selector: '[tooltip]'
})
export class TooltipDirective {
  static nextId:             number = 0;
  public id:                 number =  TooltipDirective.nextId++;
  @Input() tooltip:          string = '';
  @Input() tooltipTutorial:  Tutorial['id'];
  @Input() tooltipPosition:  TooltipPosition = defaultTooltipPosition;
  @Input() tooltipContent:   TemplateRef<unknown>;
  @Input() tooltipTheme:     TooltipTheme = defaultTooltipTheme;
  @Input() tooltipShowDelay: number = matTooltipDefaultOptions.showDelay;
  @Input() tooltipHideDelay: number = matTooltipDefaultOptions.hideDelay;
  @Output() onTooltipAction = new EventEmitter<void>();

  protected _touchTimeout?: number;
  protected _hideTimeout?:  number;
  protected _showTimeout?:  number;

  protected _onDestroy =    new Subject<void>();

  @HostListener('document:keydown.esc')
  private keypress(ev: KeyboardEvent) {
    this.destroy();
  }

  @HostListener('mouseenter')
  onMouseEnter(): void {
    window.clearTimeout(this._showTimeout);
    this._showTimeout = window.setTimeout(this.initializeTooltip.bind(this), this.tooltipShowDelay);
  }

  @HostListener('touchstart', ['$event'])
  onTouchStart($event: TouchEvent): void {
    $event.preventDefault();
    window.clearTimeout(this._touchTimeout);
    this._touchTimeout = window.setTimeout(this.initializeTooltip.bind(this), 500);
  }

  @HostListener('touchend')
  onTouchEnd(): void {
    window.clearTimeout(this._touchTimeout);
    this.setHideTooltipTimeout();
  }

  @HostListener('mouseleave', ['$event'])
  onMouseLeave(ev: MouseEvent): void {
    window.clearTimeout(this._showTimeout);
    if (! this.componentRef?.instance) return;
    const { top, left, height, width } = this.componentRef?.instance.boundingClientRect;

    let initialDistance = Math.sqrt(Math.pow(ev.pageX - left - (width / 2), 2) + Math.pow(ev.pageY - top - (height / 2), 2));
    let steps = Array(3).fill(-10);
    const onDestroy = new Subject<void>();
    const onTimeout = new Subject<Observable<number> | null>();
    onTimeout.pipe(
      filter((timer): timer is Observable<number> => timer !== null),
      switchMap((timer) => timer.pipe(takeUntil(onTimeout))),
      takeUntil(onDestroy),
      takeUntil(this._onDestroy),
    ).subscribe(() => {
      this.destroy();
      onDestroy.next();
      onDestroy.complete();
    });

    onTimeout.next(timer(100));

    fromEvent(document, 'mousemove')
    .pipe(
      takeUntil(onDestroy),
      takeUntil(this._onDestroy),
      auditTime(10),

    )
    .subscribe((e: MouseEvent) => {
      const { pageX, pageY } = e;
      /*
        Is the mouse still within the tooltip?
        If entering the tooltip, it should be closed as soon as the mouse leaves it.
      */
      if (pageX > left && pageX < left + width && pageY > top && pageY < top + height) {
        initialDistance = -Infinity;
        onTimeout.next(null);
        return;
      }
      /*
        Calculate the distance between the mouse and the center of the tooltip.
      */
      const distance = Math.sqrt(Math.pow(pageX - left - (width / 2), 2) + Math.pow(pageY - top - (height / 2), 2));
      steps.push(distance - initialDistance);
      steps.shift();
      /*
        Is the average mouse movement away from the center of the tooltip?
      */
      if (_.mean(steps) > 0) {
        this.destroy();
        onDestroy.next();
        onDestroy.complete();
        return;
      }
      onTimeout.next(timer(100));
      initialDistance = distance;
    });
  }

  ngOnDestroy(): void {
    this.destroy();
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  destroy(): void {
    if (this.componentRef !== null) {
      this._viewContainerRef.clear();
      this.componentRef.destroy();
      this.componentRef = null;
      window.clearTimeout(this._showTimeout);
    }
  }

  private componentRef: ComponentRef<TooltipComponent> | null = null;

  constructor(
    protected elementRef:             ElementRef,
    protected _viewContainerRef:      ViewContainerRef,
    protected _tutorials:             TutorialsService,
    protected _router:                Router,
    protected _preferences:           UserPreferencesService
  ) {}

  private setHideTooltipTimeout() {
    this._hideTimeout = window.setTimeout(this.destroy.bind(this), this.tooltipHideDelay);
  }

  @Catch({ onError: console.error })
  private initializeTooltip() {
    if (! this._preferences?.displayTooltips)
      return;

    if (this.componentRef != null)
      return;

    onSingleton.next(this.id);

    /*
      If another tooltip is opened, destroy this.
    */
    onSingleton.pipe(
      distinctUntilChanged(),
      filter(id => id !== this.id),
      take(1),
      takeUntil(this._onDestroy)
    ).subscribe(() => {
      this.destroy();
    });

    this.componentRef = this._viewContainerRef.createComponent(TooltipComponent);
    const domElem =  (this.componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);
    this.setTooltipComponentProperties();
    this._showTimeout = window.setTimeout(this.showTooltip.bind(this), this.tooltipShowDelay);
  }

  private showTooltip() {
    if (this.componentRef !== null) {
      this.componentRef.instance.visible = true;
    }
  }

  private setTooltipComponentProperties() {
    if (this.componentRef !== null) {
      this.componentRef.instance.tooltip   = this.tooltip;
      this.componentRef.instance.hasAction = this._tutorials.has(this.tooltipTutorial);
      this.componentRef.instance.position  = this.tooltipPosition;
      this.componentRef.instance.theme     = this.tooltipTheme;
      if (this.tooltipContent)
        this.componentRef.instance.content = new TemplatePortal(this.tooltipContent, this._viewContainerRef);

      this.componentRef.instance.onAction.subscribe(() => {
        if (this.tooltipTutorial) {
          const url = this._router
            .createUrlTree(['tutorial'], { queryParams: { v: this.tooltipTutorial } })
            .toString();
          window.open(url);
        }

        this.onTooltipAction.next();
      });

      const { left, right, top, bottom } = this.elementRef.nativeElement.getBoundingClientRect();

      switch(this.tooltipPosition) {
        case 'below': {
          this.componentRef.instance.center = Math.round((right - left) / 2 + left);
          this.componentRef.instance.top = Math.round(bottom + 0);
          break;
        }
        case 'above': {
          this.componentRef.instance.center = Math.round((right - left) / 2 + left);
          this.componentRef.instance.top = Math.round(top);
          break;
        }
        case 'right': {
          this.componentRef.instance.center = Math.round(right);
          this.componentRef.instance.top = Math.round(top + (bottom - top) / 2);
          break;
        }
        case 'left': {
          this.componentRef.instance.center = Math.round(left);
          this.componentRef.instance.top = Math.round(top + (bottom - top) / 2);
          break;
        }
        default: {
          break;
        }
      }
    }
  }
}
