import { Pipe,
         PipeTransform                   } from '@angular/core';
import { UserPreferencesService          } from '@app/core';
import { Course,
         OverlapGroup                    } from '@app/shared/interfaces';
import _                                   from 'lodash';
import { EventSet                        } from '../components/events/events.component';


@Pipe({
  name: 'isFiltered'
})
export class IsFilteredPipe implements PipeTransform {

  transform (value: { id: string }, filter: { groups?: string[], teachers?: string[] } | undefined | null): boolean {
    if ( ! filter) return false;
    return filter.groups?.includes(value.id) || filter.teachers?.includes(value.id) || false;
  }
}



@Pipe({
  name: 'filterEventSets'
})
export class FilterEventSetsPipe implements PipeTransform {
  constructor (private _preferences: UserPreferencesService) {}


  transform (
    sets:   EventSet[] | null,
    filter: { groups?: string[], teachers?: string[] } | undefined | null,
    search: string | undefined | null,
  ): EventSet[] {
    if ( ! sets) return [];

    // filter
    if (filter) {
      sets = sets.filter(({ events }) => {

        if (filter.groups) {
          let setGroups = _.uniq(events
            .map(x => x.groups)
            .flat()
            .filter(Boolean)
            .map(({ to }) => to.id));

          // the two arrays share a common element
          if (filter.groups.some(x => setGroups.includes(x))) return true;
        }

        if (filter.teachers) {
        let setTeachers = _.uniq(events
          .map(x => x.teachers)
          .flat()
          .filter(Boolean)
          .map(({ to }) => to.id));

          // the two arrays share a common element
          if (filter.teachers.some(x => setTeachers.includes(x))) return true;
        }

        return false;
      });
    }

    // search
    if (search) {
      const s = search.toLowerCase();
      sets = sets.filter(({ events }) => {
        if (events.some(e => e        .displayName?.toLowerCase().includes(s))) return true;
        if (events.some(e => e.course?.displayName?.toLowerCase().includes(s))) return true;
        if (events.some(e => e.period?.displayName?.toLowerCase().includes(s))) return true;
        if (events.some(e => e.preferredDuration.toString().includes(s))) return true;
        if (events.some(e => e.groups  ?.some(g => g.to.displayName?.toLowerCase().includes(s)))) return true;
        if (events.some(e => e.teachers?.some(t => t.to.displayName?.toLowerCase().includes(s)))) return true;
        return false;
      });
    }

    return sets;
  }
}


function getId (x: string | { id: string }): string {
  return typeof x === 'string' ? x : x.id;
}

@Pipe({
  name: 'verifyEventOverlapSpecies'
})
export class VerifyEventOverlapSpeciesPipe implements PipeTransform {

  transform (
    overlapGroup:                       OverlapGroup.populated,
    courseMap:                          null | Map<string, Course.populated>,
    requireForcedOverlappingEventsSets: boolean
  ): boolean {
    // events not required to have overlap species
    if ( ! requireForcedOverlappingEventsSets) return true;

    // fetch the number of events
    const numEvents = (overlapGroup.coalesced ?? [])
      .flatMap(x => courseMap?.get(getId(x.to))?.events)
      .filter((e): e is NonNullable<typeof e> => !! e)
      .length;

    // verify that the number of overlap species is equal to the number of events
    return overlapGroup.species?.length === numEvents;
  }
}