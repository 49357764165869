import { Component,
         DestroyRef,
         Input,
         inject                          } from '@angular/core';
import { Router,
         ActivatedRoute                  } from '@angular/router';
import { coerceBooleanProperty           } from '@angular/cdk/coercion';
import { animate,
         style,
         transition,
         trigger                         } from '@angular/animations';
import { takeUntilDestroyed              } from '@angular/core/rxjs-interop';
import { BehaviorSubject,
         Observable,
         combineLatest,
         of                              } from 'rxjs';
import { filter,
         skip,
         map,
         switchMap,
         delay                           } from 'rxjs/operators';
import _                                   from 'lodash';
import moment                              from 'moment';

import { QueryService                    } from 'app/shared/services'
import { getWeekRange                    } from '@app/common/wrappers/weeks-and-periods';
import { Week,
         AllWeeks,
         allWeeks,
         Tags                            } from '@app/shared/interfaces';
import { Util                            } from "@app/common/util";
import { Collection,
         collections,
         Query,
         SelectedEntity,
         EntitySelectData,
         Entity                          } from './types';
import { EnvironmentCollectionPipe       } from '@app/core/environment/environment.pipe';


function identicalWeeks (
  a: string           | AllWeeks,
  b: Pick<Week, 'id'> | AllWeeks | null
): boolean {
  if (a === allWeeks && b === allWeeks) return true;
  if (a === allWeeks || b === allWeeks) return false;
  return a === b?.id;
}


@Component({
  selector: 'app-schema-filter-dropdowns',
  templateUrl: './schema-filter-dropdowns.component.html',
  styleUrls: ['./schema-filter-dropdowns.component.scss'],
  providers: [ ],
  animations: [
    trigger('inAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.5s ease-in', style({ opacity: 1 }))
      ])
    ])
  ]
})
export class SchemaFilterDropdownsComponent {
  private readonly destroyRef = inject(DestroyRef)

  // what collections to await for data in order to set loading to false
  private readonly onToBeAwaited = new BehaviorSubject<Set<BehaviorSubject<unknown> > >(new Set());
  protected readonly loading$ = new BehaviorSubject(true);

  private readonly _renderMenuContent$ = new BehaviorSubject(false);
  protected readonly renderMenuContent$;

  // stores the selected entities
  protected readonly selectedEntities$ = new BehaviorSubject<SelectedEntity[]>([])
  protected readonly selectedWeek$     = new BehaviorSubject<{ id: string, week?: Week } | AllWeeks | null>(null);

  // options for the select elements
  private   readonly courses$   = new BehaviorSubject<Entity[] | null>(null);
  private   readonly groups$    = new BehaviorSubject<Entity[] | null>(null);
  private   readonly teachers$  = new BehaviorSubject<Entity[] | null>(null);
  private   readonly locations$ = new BehaviorSubject<Entity[] | null>(null);
  private   readonly persons$   = new BehaviorSubject<Entity[] | null>(null);
  private   readonly tags$      = new BehaviorSubject<Entity[] | null>(null);
  protected readonly weeks$     = new BehaviorSubject<Week  [] | null>(null);

  // data used to render the select elements
  private readonly _unfilteredSelects: EntitySelectData[] = [
    {
      collection: 'courses',
      path:       'courses',
      label:      'common.course',
      options$:   this.courses$
    }, {
      collection: 'groups',
      path:       'groups',
      label:      'common.group',
      options$:   this.groups$
    }, {
      collection: 'teachers',
      path:       'teachers',
      label:      'common.teacher',
      options$:   this.teachers$
    }, {
      collection: 'locations',
      path:       'locations',
      label:      'common.location',
      options$:   this.locations$
    }, {
      collection: 'persons',
      path:       'persons',
      label:      'common.persons',
      options$:   this.persons$
    }, {
      collection: 'tags',
      path:       'tags',
      label:      'attributes.shared.tags',
      options$:   this.tags$
    }
  ];

  protected readonly selectableEntities;
  protected readonly showSecondarySelect$;
  protected readonly disableSecondarySelect$;

  constructor (
    private route:                 ActivatedRoute,
    private router:                Router,
    private query:                 QueryService,
    private environmentCollection: EnvironmentCollectionPipe
  ) {
    // filter out the collections that are not to be shown, depending on the organization type etc.
    this.selectableEntities = this._unfilteredSelects
      .filter(x => this.environmentCollection.transform(x.collection, 'app-schema-filter-dropdowns'));

    this.showSecondarySelect$ = combineLatest(this.selectableEntities.map(x => x.options$))
      .pipe(map(x => x.some(options => options?.length)));

    this.disableSecondarySelect$ = this.selectedEntities$
      .pipe(map(x => {
        // keep only the selected entities
        const numSelected = x.filter(x => this.selectableEntities.some(y => y.collection == x.key)).length;

        // there can be at most 3 secondary selects
        if (numSelected == 0 || numSelected > 3) return true;
        return false;
      }));

      this.renderMenuContent$ = this._renderMenuContent$.pipe(
          // wait 200ms for the animation to finish before hiding the content
          switchMap(x => x ? of(true) : of(false).pipe(delay(2000))),
        );


    // append the entity to each selected
    combineLatest({
      courses:   this.courses$,
      groups:    this.groups$,
      teachers:  this.teachers$,
      locations: this.locations$,
      persons:   this.persons$,
      tags:      this.tags$,
      selected:  this.selectedEntities$
    })
    .pipe(
      takeUntilDestroyed(),
      skip(1) // skip the first emit which is always emitted because of BS
    )
    .subscribe(({ courses, groups, teachers, locations, persons, tags, selected }) => {
      // append
      selected.forEach(x => {
        switch (x.key) {
          case 'courses':   x.entity = courses  ?.find(y => y.id === x.id); break;
          case 'groups':    x.entity = groups   ?.find(y => y.id === x.id); break;
          case 'teachers':  x.entity = teachers ?.find(y => y.id === x.id); break;
          case 'locations': x.entity = locations?.find(y => y.id === x.id); break;
          case 'persons':   x.entity = persons  ?.find(y => y.id === x.id); break;
          case 'tags':      x.entity = tags     ?.find(y => y.id === x.id); break;
        }
      });
    });

    // append week to selected week
    combineLatest({
      weeks:        this.weeks$       .pipe(filter(Boolean)),
      selectedWeek: this.selectedWeek$.pipe(filter(Boolean))
    })
    .pipe(takeUntilDestroyed())
    .subscribe(({ weeks, selectedWeek }) => {
      if ( ! weeks.length) return;

      if (selectedWeek != allWeeks) {
        // ensure that the date is valid, if not set current date
        if ( ! moment.utc(selectedWeek.id, 'YYYY-MM-DD', true).isValid()) {
          this.setWeek(moment.utc().format('YYYY-MM-DD'), true); // true to not push automatic query change to history
          return;
        }

        // reset the selected week if it is not in the list of weeks
        if ( ! weeks.find(x => x.id == selectedWeek.id)) {
          const start       = weeks[0].range.start;
          const end         = weeks[weeks.length - 1].range.end;
          const currentWeek = getWeekRange(moment.utc(selectedWeek.id));
          if      (currentWeek.start.isBefore(start)) this.setWeek(weeks[0].id,                                                  true); // true to not push automatic query change to history
          else if (currentWeek.end  .isAfter (end))   this.setWeek(weeks[weeks.length - 1].id,                                   true); // true to not push automatic query change to history
          else                                        this.setWeek(weeks.find(x => x.range.start.isSame(currentWeek.start))!.id, true); // true to not push automatic query change to history
          return;
        }

        // append week data
        selectedWeek.week = weeks.find(x => x.id == selectedWeek.id);
      }

      // forces query to be set
      // (in order to sync with bottom sheet filter for small screens)
      // (this will however give rise to two emits when clicking a defect...)
      // THIS INTERFERES WITH OTHER THINGS AND IS THEREFORE DISABLED
      // -> the two filters need to communicate somehow else
      //    preferably the should be the same components with different style depending on the screen size
      // this._setQuery();
    });

    // listen to loading status
    this.onToBeAwaited
    .pipe(takeUntilDestroyed())
    .subscribe(toBeAwaited => {
       this.loading$.next(toBeAwaited.size != 0);
    });

    // subscribe to changes in the query parameters
    // (it fires immediately)
    this.query.onChange()
    .pipe(takeUntilDestroyed())
    .subscribe(query => {
      // parse selected entities from query
      const selected: SelectedEntity[] = [];
      let selectedWeek: string = moment.utc().format('YYYY-MM-DD');   // must always be set
      Util.functions.objectEntries(query)
      .forEach(x => {
        // filter entries manually as _.pick does not preserve the
        // order which in turn may change the primary selection
        if ( ! collections.includes(x[0] as Collection)) return;
        const [ key, ids ] = x as Util.Types.Entry<Query>;

        if      (key == 'week')   selectedWeek = ids;
        else                      ids.forEach(id => selected.push({ key, id }));
      });

      // emit only if different
      if ( ! _.isEqual(selected, this.selectedEntities$.value.map(x => _.pick(x, ['key', 'id'])))) {
        this.selectedEntities$.next(selected);
      }
      if ( ! identicalWeeks(selectedWeek, this.selectedWeek$.value)) {
        this.selectedWeek$.next(selectedWeek == allWeeks ? allWeeks : { id: selectedWeek });
      }

    });

  }


  private await (bh: BehaviorSubject<unknown>) {
    this.onToBeAwaited.value.add(bh);
    this.onToBeAwaited.next(this.onToBeAwaited.value);
  }
  private awaited (bh: BehaviorSubject<unknown>) {
    this.onToBeAwaited.value.delete(bh);
    this.onToBeAwaited.next(this.onToBeAwaited.value);
  }


  private emit<T> (
    bs:     BehaviorSubject<T[] | null>,
    val:    undefined | null | T[] | Observable<T[] | null>,
    sortBy: (keyof T)[]
  ) {
    // await collection data
    this.await(bs);

    if (val instanceof Observable) {
      val.pipe(takeUntilDestroyed(this.destroyRef), filter(Boolean))
      .subscribe(x => {
        this.awaited(bs);
        bs.next(_.sortBy(x, sortBy))
      });
    } else {
      this.awaited(bs);
      if (val) {
        bs.next(_.sortBy(val, sortBy));
      }
    }
  }

  protected setWeek (
    id:         string | AllWeeks,
    replaceUrl: boolean = false
  ) {
    // set value
    this.selectedWeek$.next(id == allWeeks ? allWeeks : { id });

    // update the query
    this._setQuery(replaceUrl);
  }

  protected setPrimary (key: Exclude<Collection, 'week'>, id: string | undefined) {
    if (id) {
      const primary = { key, id };
      const  [ , ...secondary ] = this.selectedEntities$.value;

      // ensure that the new primary is not present in the secondary
      this.selectedEntities$.next([ primary, ...secondary.filter(x => x.key !== key || x.id !== id) ]);

      // update the query
      this._setQuery();
    } else {
      this.removeSelectedByIndex(0);
    }
  }

  protected addSecondary (key: Exclude<Collection, 'week'>, id: string): void {
    // ensure the secondary is not already present
    if (this.selectedEntities$.value.some(x => x.key == key && x.id == id)) return;

    this.selectedEntities$.next([...this.selectedEntities$.value, { key, id }]);
    this._setQuery();
  }

  protected removeSelectedByIndex (index: number) {
    const selected = this.selectedEntities$.value.filter((_, i) => i != index);
    this.selectedEntities$.next(selected);
    this._setQuery();
  }

  private _setQuery (replaceUrl: boolean = false) {
    const query: Query = { };
    this.selectedEntities$.value.forEach(({ key, id }) => {
      if (query[key]) query[key]!.push(id);
      else            query[key] = [id];
    });

    // should not set the week if no weeks exist
    // (internally the week is always selected)
    if (this.weeks$.value?.length && this.selectedWeek$.value)
      query.week = this.selectedWeek$.value == allWeeks ? allWeeks : this.selectedWeek$.value.id;

    // remove entities from the query that have no options (their dropdowns are empty)
    //   (this solves the problem when a defect with two events are selected and one
    //   later selects, lets say, a group. without filtering one event would still remain
    //   in the query and affecting the schedule frame without the user knowing)
    const filteredQuery: Query = { };
    if (this.weeks$.value?.length) filteredQuery.week = query.week;
    this.selectableEntities.forEach(x => {
      // ignore empty collections
      if ( ! x.options$.value || x.options$.value.length == 0) return;

      // have to do this for type safety
      const val = query[x.path];
      if (val != undefined) (filteredQuery[x.path] as typeof val) = val;
    });

    this.query.setQuery(filteredQuery, replaceUrl);
  }

  protected openNew (key: Collection, value: { id: string }): void {
    const url = this.router
      .createUrlTree([], { relativeTo: this.route, queryParams: this.query.mapStructureToQuery({ [key]: [value.id] }) })
      .toString();
    window.open(url);
  }

  protected isSingleWeek (val: Util.Types.GetObservableType<typeof this.selectedWeek$>): val is Exclude<typeof val, AllWeeks> {
    return val != allWeeks;
  }

  protected menuOpened () {
    this._renderMenuContent$.next(true);
  }

  protected menuClosed () {
    this._renderMenuContent$.next(false);
  }

  ////
  //// IO
  ////
  @Input()
  get openInNew ()  { return this._openInNew; }
  set openInNew (x: string | boolean) { this._openInNew = coerceBooleanProperty(x);}
  private _openInNew: boolean = true;

  @Input({ transform: coerceBooleanProperty })
  get disableMultiselect ()  { return this._disableMultiselect; }
  set disableMultiselect (x) { this._disableMultiselect = x; }
  private _disableMultiselect: boolean = true;

  @Input({ transform: coerceBooleanProperty })
  get canSelectAllWeeks ()  { return this._canSelectAllWeeks; }
  set canSelectAllWeeks (x) { this._canSelectAllWeeks = x; }
  private _canSelectAllWeeks: boolean = true;

  @Input() set courses   (val: undefined | null | Entity[] | Observable<Entity[] | null>) { this.emit(this.courses$,   val, ['displayName']); }
  @Input() set groups    (val: undefined | null | Entity[] | Observable<Entity[] | null>) { this.emit(this.groups$,    val, ['displayName']); }
  @Input() set teachers  (val: undefined | null | Entity[] | Observable<Entity[] | null>) { this.emit(this.teachers$,  val, ['displayName']); }
  @Input() set locations (val: undefined | null | Entity[] | Observable<Entity[] | null>) { this.emit(this.locations$, val, ['displayName']); }
  @Input() set persons   (val: undefined | null | Entity[] | Observable<Entity[] | null>) { this.emit(this.persons$,   val, ['displayName']); }
  @Input() set tags      (val: undefined | null | Tags     | Observable<Tags     | null>) {
    // map to tags -> entities
    let out: undefined | null | Entity[] | Observable<Entity[] | null>;
    if (val && val instanceof Array)      out = val.map(x => ({ id: x.value, displayName: x.value }));
    if (val && val instanceof Observable) out = val.pipe(map(x => x?.map(x => ({ id: x.value, displayName: x.value })) ?? null));
    this.emit(this.tags$, out, ['displayName']);
  }
  @Input() set weeks     (val: undefined | null | Week  [] | Observable<Week  [] | null>) { this.emit(this.weeks$,     val, ['id'         ]); }
}