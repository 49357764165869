import { Pipe,
         PipeTransform                   } from '@angular/core';
import { Observable                      } from 'rxjs';
import _                                   from 'lodash';
import moment                              from 'moment';

import { CommonService,
         DateService,
         NameableEntity                  } from 'app/shared/services';
import { EnvironmentService,
         TranslateService                } from 'app/core';
import { AvailableLocation,
         Course,
         Event,
         LockedTime,
         MinBreakLength,
         Populated as P                  } from 'app/shared/interfaces';
import { commonConstants                 } from 'app/constants';
import { Util                            } from 'app/common/util';
import { EventSource                     } from 'app/shared/calendar/calendar/types';

@Pipe({
  name: 'conditional'
})
export class ConditionalPipe implements PipeTransform {

  transform(condition: boolean, ...args: [any, any]): any {
    const [value1, value2] = args;
    return condition ? value1 : value2;
  }
}

@Pipe({
  name: 'default'
})
export class DefaultPipe implements PipeTransform {

  transform(condition: boolean, ...args: any[]): any {
    const [defaultValue] = args;
    return condition ?? defaultValue;
  }
}

@Pipe({
  name: 'displayName'
})
export class DisplayNamePipe implements PipeTransform {

  constructor(private _common: CommonService) { }

  transform (entity: NameableEntity): string {
    return this._common.deriveName(entity).value;
  }
}



type DisplayIconEntity = Pick<P.group | P.teacher | P.person, 'is'>
@Pipe({
  name: 'displayIcon'
})
export class DisplayIconPipe implements PipeTransform {

  constructor (private _environment: EnvironmentService) { }

  transform (entity: DisplayIconEntity | undefined): string {
    let collection: Util.Types.Collection | undefined;
    if      (entity?.is == 'group'  ) collection = 'groups';
    else if (entity?.is == 'teacher') collection = 'teachers';
    else if (entity?.is == 'person' ) collection = 'persons';

    const { collections } = this._environment.getState() ?? { };
    return collection ? collections?.[collection]?.icon ?? 'question_mark' : 'question_mark';
  }
}


@Pipe({
  name: 'displayNames'
})
export class DisplayNamesPipe implements PipeTransform {

  constructor (private _displayName: DisplayNamePipe) { }

  transform(entities: NameableEntity[] | undefined | null, obs?: Observable<any>): string {
    if (entities) return entities.map(x => this._displayName.transform(x)).join(', ');
    else          return '';

  }
}

@Pipe({
  name: 'displayAvailableLocations'
})
export class DisplayAvailableLocationsPipe implements PipeTransform {

  constructor (private _displayName: DisplayNamePipe) { }

  transform (entities: AvailableLocation.populated[] | undefined, obs?: Observable<any>): string {
    return _(entities ?? [])
      .groupBy(x => x.groupIndex)
      .toArray()
      .map(x => x
        .map(y => this._displayName.transform({ ...y.locations && y.locations[0], is: 'location'}))
        .join(', ')
      )
      .join(' + ');
  }
}


type DisplayableEvent =
  // event
  {
    displayName?: string;
    course?:      string | { displayName?: string };
  } |
  // locked time
  {
    displayName?: string;
  } |
  // unknown
  {

  };

@Pipe({
  name: 'eventDisplayName'
})
export class EventDisplayNamePipe implements PipeTransform {

  constructor(private _translate: TranslateService) { }

  transform(entity: DisplayableEvent | undefined | null): string | undefined | null {
    if ( ! entity) return;

    if ('displayName' in entity) return entity.displayName;
    if ('course' in entity && entity.course) {
      if (typeof entity.course === 'string') return entity.course;
      if ('displayName' in entity.course) return entity.course.displayName;
    }

    return this._translate.instant('common.nameless');
  }
}

@Pipe({
  name: 'sex'
})
export class SexPipe implements PipeTransform {
  constructor(private _translate: TranslateService) { }
  transform(sex: string | null | undefined): string  {
    switch (sex) {
      case 'Man':
        return this._translate.instant('common.sex.man')
      case 'Woman':
        return this._translate.instant('common.sex.woman')
      default:
        return '';
    }
  }
}

@Pipe({
  name: 'contrast'
})
export class ContrastPipe implements PipeTransform {
  transform(color: string, opacity?: number): string  {
    return CommonService.contrastService(color, opacity);
  }
}

@Pipe({
  name: 'stringCompare'
})
export class StringComparePipe implements PipeTransform {

  transform(entity?: string, arg?: string): boolean {
    if (entity && arg)
      return entity?.localeCompare?.(arg) === 0;
    else
      return false;
  }
}

@Pipe({
  name: 'hasKey'
})
export class HasKeyPipe implements PipeTransform {
  transform<T extends object | null> (obj: T, key: Util.Types.KeysOfUnion<NonNullable<T>>): boolean {
    return obj && (key as string) in obj;
  }
}

@Pipe({
  name: 'hasValue'
})
export class HasValuePipe implements PipeTransform {
  transform (elements: any[] | undefined, key?: string, obs?: any): boolean {

    if ( ! elements) return false;

    if (key) {
      return elements.some(x => x[key] != undefined);
    } else {
      return elements.some(x => x != undefined);
    }
  }
}

@Pipe({
  name: 'firstKey'
})
export class FirstKeyPipe implements PipeTransform {
  transform(obj: { [key: string]: any; }): string | null {
    var keys = Object.keys(obj);
    if (keys && keys.length > 0) return keys[0];
    return null;
  }
}

@Pipe({
  name: 'readableDuration'
})
export class ReadableDurationPipe implements PipeTransform {

  constructor(private _translate: TranslateService) { }

  transform (val: number | undefined, options: { long?: boolean, html?: boolean, hoursOnly?: boolean } = { long: true, html: false, hoursOnly: false }): string {
    if (val == null) return '';

    let h = Math.trunc(val / 60);
    let m = val - h*60;

    let hunit: string;
    let munit: string;
    if (options.long) {
      hunit = ' ' + this._translate.instant('common.hours'  ).toLowerCase() + ' ';
      munit = ' ' + this._translate.instant('common.minutes').toLowerCase();
    } else {
      hunit = 'h ';
      munit = 'm';
    }
    if (options.html) {
      hunit = `<span class="duration hour  ">${hunit}</span>`;
      munit = `<span class="duration minute">${munit}</span>`;
    }

    if (options.hoursOnly) return h + hunit;

    let hstring =  h > 0               ? h + hunit : '';
    let mstring = (m > 0 || ! hstring) ? m + munit : '';

    return hstring + mstring;
  }
}

@Pipe({
  name: 'inInterval'
})
export class InIntervalPipe implements PipeTransform {
  transform (
    value:        number | undefined,
    preferred:    number | undefined,
    ...variances: (number | undefined | null)[]
  ): boolean {
    if (value == undefined || preferred == undefined) return false;

    const variance = variances.find((x): x is NonNullable<typeof x> => x != null) ?? 0;
    return _.inRange(value, preferred - variance, preferred + variance) || value == preferred + variance;
  }
}

@Pipe({
  name: 'readableBreakLength'
})
export class ReadableBreakLengthPipe implements PipeTransform {

  constructor(private _translate: TranslateService) { }

  transform (val: MinBreakLength | undefined): string {
    if (typeof val == 'boolean') {
      return this._translate.instant('attributes.shared.minBreakLength.none');
    } else if (typeof val == 'number') {
      return `${val ?? 0} ${ this._translate.instant('common.minutes').toLowerCase() }`;
    } else if (Array.isArray(val)) {
      return `${ [val[0] ?? 0, val[1] ?? 0].join(' & ')}  ${ this._translate.instant('common.minutes').toLowerCase() }`;
    } else {
      return '';
    }
  }
}

@Pipe({
  name: 'readableLockedTimes'
})
export class ReadableLockedTimesPipe implements PipeTransform {

  constructor(private _translate: TranslateService,
              private _date:      DateService) { }

  transform (val: LockedTime[] | null | undefined, showName: boolean = false): string {

    val = (val ?? []).sort((a: any, b: any) => a.day - b.day);

    return val.map(x => {
      if ( ! (x.start && x.end)) return this._translate.instant('common.erroneous_value');

      let day   = this._date.getTranslatedDayName(moment.utc(x.start), 'short');
      let start = this._date.fromDate(x.start as moment.Moment);
      let end   = this._date.fromDate(x.end as moment.Moment);
      return `${ day } ${ start } - ${ end }${showName && x.displayName ? ' (' + x.displayName + ')' : ''}`;
    }).join(', ');
  }
}

@Pipe({
  name: 'eventColor'
})
export class EventColorPipe implements PipeTransform {
  transform (
    event:   Pick<Event.populated, 'is' | 'id' | 'color'> & { course?: { color?: string } } | Pick<LockedTime.populated, 'is'> | undefined | null,
    opacity: string = ''
  ): string {
    if ( ! event) return '';

    if ('is' in event && event.is === 'event') {
      return (event.color ??  event.course?.color ?? commonConstants.COLORS.EVENT_DEFAULT) + opacity;
    } else {
      return commonConstants.COLORS.LOCKED_TIME_DEFAULT + opacity;
    }
  }
}

@Pipe({
  name: 'courseColor'
})
export class CourseColorPipe implements PipeTransform {
  transform (
    course:  { color?: string } | undefined,
    opacity: string = ''
  ): string {
    if ( ! course) return '';

    return (course.color ?? commonConstants.COLORS.EVENT_DEFAULT) + opacity;
  }
}

@Pipe({
  name: 'objectEntries'
})
export class ObjectEntriesPipe implements PipeTransform {
  transform<T extends Object = Object> (obj: T | undefined | null) {
    return obj ? Util.functions.objectEntries(obj).map(x => ({ key: x[0], val: x[1] })) : [];
  }
}

@Pipe({
  name: 'objectValues'
})
export class ObjectValuesPipe implements PipeTransform {
  transform<T extends Object = Object> (obj: T | undefined | null): Util.Types.Value<T>[] {
    return obj ? Object.values(obj) : [];
  }
}

type TypeGuard<T> = (a: unknown) => a is T;
@Pipe({
  name: 'typeGuard',
})
export class TypeGuardPipe implements PipeTransform {
  transform<T> (value: unknown, typeGuard: TypeGuard<T>): T | undefined {
    return typeGuard(value) ? value : undefined;
  }
}


@Pipe({
  name: 'log',
})
export class LogPipe implements PipeTransform {
  transform<T> (value: T, clone?: boolean): T {
    console.log(clone ? _.cloneDeep(value) : value);
    return value
  }
}


@Pipe({
  name: 'allowGreaterThan',
})
export class AllowGreaterThanPipe implements PipeTransform {
  transform<T extends number> (value: T | null | undefined, threshold: T): T | null {
    if (value == null) return null;
    return value > threshold ? value : null;
  }
}


@Pipe({
  name: 'negate',
})
export class NegatePipe implements PipeTransform {
  transform (value: any): boolean {
    return ! value;
  }
}


@Pipe({
  name: 'stringify',
})
export class StringifyPipe implements PipeTransform {
  transform (value: string | undefined | null): string {
    return value ?? '';
  }
}


@Pipe({
  name: 'eventDurations',
})
export class EventDurationsPipe implements PipeTransform {
  constructor (private _translate: TranslateService) { }
  transform (value: Pick<Event.populated, 'preferredDuration'>[] | undefined): string {
    const min = this._translate.instant('common.minutes_short').toLowerCase();

    return _(value ?? [])
      .map(x => x.preferredDuration)
      .sort()
      .countBy(x => x)
      .map((count, duration) => (count > 1 ? `${count} × ` : ``) + `${duration} ${min}`)
      .join(', ');
  }
}

@Pipe({
  name: 'not',
})
export class NotPipe implements PipeTransform {
  transform (value: unknown): boolean {
    return ! value;
  }
}