import { NgModule                        } from '@angular/core';
import { CommonModule                    } from '@angular/common';
import { FormsModule,
         ReactiveFormsModule             } from '@angular/forms';
import { DragDropModule                  } from '@angular/cdk/drag-drop';

import { AppCommonModule                 } from 'app/common/common.module';
import { PipesModule                     } from 'app/shared/pipes/pipes.module';
import { TranslationModule               } from 'app/core/translate/translate.module';
import { EditColumnsComponent            } from './edit-columns.component';


@NgModule({
  declarations: [
    EditColumnsComponent,
  ],
  exports: [
    EditColumnsComponent
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    TranslationModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,
    PipesModule
  ]
})
export class EditColumnsModule { }
