import { Injectable,
         OnDestroy                         } from '@angular/core';

import { Observable,
         BehaviorSubject,
         Subject                           } from 'rxjs';
import { takeUntil,
         map,
         mergeWith                         } from 'rxjs/operators';
import _                                     from 'lodash';

import { DataSourceService                 } from 'app/shared/services';

type Id = { id: string }
type Entry = Id & { [key: string]: any };

@Injectable()
export class SelectionService
<SelectType extends Id = Entry, SourceType extends SelectType = SelectType>
implements OnDestroy {
  private onDestroy = new Subject<void>();
  private onReset   = new Subject<void>();

  private _onSelect    = new Subject<SelectType[]>();
  private _onSelection = new BehaviorSubject<Map<string, SelectType> >(new Map<string, SelectType>());

  constructor() { }

  set dataSource (val: DataSourceService) {
    this._dataSource = val;
    this.source = this._dataSource.dataChange;
  }
  private _dataSource: DataSourceService;

  set source (val: Observable<SourceType[]>) {
    this.onReset.next()
    val
    .pipe(takeUntil(this.onDestroy.pipe(mergeWith(this.onReset))))
    .subscribe(value => this._value = value);
  }
  private _value: SourceType[];

  private _isAllSelected(): boolean {
    const numSelected = this._onSelection.value.size;
    const numRows     = this._value?.length;
    return numSelected >= numRows;
  }

  private _select (row: SourceType, emit: boolean = true): SourceType | null {
    if (! row?.id || this._onSelection.value.has(row.id)) return null;

    // update values
    this._onSelection.value.set(row.id, row);

    // try emit
    emit && this._onSelection.next(this._onSelection.value);
    emit && this._onSelect.next([row]);

    return row;
  }

  private _deselect(row: SelectType, emit: boolean = true): SelectType | null {
    if (! row?.id || ! this._onSelection.value.has(row.id)) return null;

    // update value
    this._onSelection.value.delete(row.id);

    // try emit
    emit && this._onSelection.next(this._onSelection.value);
    emit && this._onSelect.next([]);

    return row;
  }

  get length(): number {
    return this._onSelection.value.size;
  }

  get empty(): boolean {
    return ! this._onSelection.value.size;
  }

  get checked(): boolean {
    return !!this._onSelection.value.size && this._isAllSelected();
  }

  get indeterminate(): boolean {
    return !!this._onSelection.value.size && ! this._isAllSelected();
  }

  get selection(): SelectType[] {
    return Array.from(this._onSelection.value.values());
  }

  get selected(): string[] {
    return Array.from(this._onSelection.value.keys());
  }

  public setSelected (val: SelectType[]): void {
    let map = new Map(val.map(x => [x.id, x]));

    // emit only if different
    if ( ! _.isEqual(map, this._onSelection.value))
      this._onSelection.next(map);
  }

  // setSelected (items: SelectType[]) {
  //   this._onSelection.next(new Map(items.map(x => [x.id, x])));
  // }

  public onSelect(): Observable<SelectType[]> {
    return this._onSelect.asObservable();
  }

  /* DEPRECATED */  public onSelection(): Observable<SelectType[]> {
  /* DEPRECATED */    return this._onSelection.asObservable().pipe(map(x => [...x.values()]));
  /* DEPRECATED */  }
  public get onSelected () { return this._onSelection.pipe(map(x => [...x.values()])); }




  public isSelected(row: SelectType | string): boolean {
    return this._onSelection.value.has(_.isString(row) ? row : row?.id);
  }

  public select (row: SourceType): void {
    this._select(row);
  }

  public deselect(row: SelectType): void {
    this._deselect(row);
  }

  public deselectAll(): void {
    this._onSelection.next(new Map());
    this._onSelect.next([]);
  }

  public toggle (row: SourceType): boolean {
    if (this.isSelected(row)) {
      this._deselect(row)
      return false;
    } else {
      this._select(row);
      return true;
    }
  }

  public clear(): void {
    this._onSelection.value.clear();
    this._onSelection.next(this._onSelection.value);
    this._onSelect.next([]);
  }

  public setAll(selected: boolean) {
    const action =selected ? '_select' : '_deselect';

    const selectedRows: SelectType[] = [];
    this._value?.forEach(row => {
      this[action](row, false) && selectedRows.push(row);
    });

    this._onSelection.next(this._onSelection.value);
    this._onSelect.next(selectedRows);
    return selectedRows;
  }

  public toggleAll(): void {
    if (this._isAllSelected()) {
      this.clear()
    } else {
      const selectedRows: SelectType[] = [];
      this._value?.forEach(row => {
        this._select(row, false) && selectedRows.push(row);
      });

      // emit
      this._onSelection.next(this._onSelection.value);
      this._onSelect.next(selectedRows);
    }
  }

  public patch(update: Partial<SourceType>): void {
    this._onSelection.value.forEach(elem => Object.assign(elem, update));
    this._onSelection.next(this._onSelection.value);
  }

  public get(id: string) {
    return this._onSelection.value.get(id);
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
    this.onDestroy.next();
    this.onReset  .complete();
  }
}
