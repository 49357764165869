import { Component, Input } from '@angular/core';
import { Tutorial } from '@app/shared/services/tutorials/tutorials.types';


@Component({
  selector: 'app-column-header',
  templateUrl: './column-header.component.html',
  styleUrls: ['./column-header.component.scss']
})
export class ColumnHeaderComponent {

  ////
  //// IO
  ////

  // title
  @Input() title: string;
  @Input() description: string;
  @Input() tutorial: Tutorial['id'];
}
