import { Component,
         Input                    } from '@angular/core';
import { AbstractControl, FormControl              } from '@angular/forms';
import { BehaviorSubject,
         map,
         merge,
         startWith,
         switchMap                } from 'rxjs';

@Component({
  selector: 'app-form-field-error',
  templateUrl: './form-field-error.component.html',
  styleUrls: ['./form-field-error.component.scss']
})
export class FormFieldErrorComponent {
  protected readonly error;

  @Input()
  set control (x: null | undefined | FormControl | FormControl[] | AbstractControl<any, any> | AbstractControl<any, any>[]) {
    if ( ! x) return;
    this._controls.next(Array.isArray(x) ? x : [x]);
  }
  private readonly _controls = new BehaviorSubject<(FormControl | AbstractControl<any, any>)[]>([]);

  constructor () {
    this.error = this._controls
    .pipe(
      switchMap(x => merge(...x.map(x => x.valueChanges)).pipe(startWith(null))),   // startWith to fire immediately
      map(() => {
        const error = this._controls.value.flatMap(x => x.errors ? Object.keys(x.errors).at(0) : []).at(0);
        return error ? `validators.${ error }` : null;
      })
    );
  }
}
