import { NgModule                             } from '@angular/core';
import { CommonModule                         } from '@angular/common';
import { RouterModule                         } from '@angular/router';

import { AppCommonModule                      } from 'app/common/common.module';
import { TranslationModule                    } from 'app/core/translate/translate.module';
import { ComponentsModule                     } from 'app/shared/components/components.module';
import { PipesModule                          } from 'app/shared/pipes/pipes.module';
import { DirectivesModule                     } from 'app/shared/directives/directives.module';

import { InputIssuesComponent                 } from './input-issues.component';

@NgModule({
  declarations: [
    InputIssuesComponent
  ],
  exports: [
    InputIssuesComponent
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    TranslationModule,
    ComponentsModule,
    PipesModule,
    RouterModule,
    DirectivesModule
  ]
})
export class InputIssuesModule { }
