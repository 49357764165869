import { firstValueFrom                  } from 'rxjs';

import { apiConstants                    } from 'app/constants';
import { AuthService                     } from './auth.service';

export async function _renewAccess (
  this:         AuthService,
  refreshToken: string
) {
  // if we are not the master thread, attempt to become the master thread
  if ( ! this._thread.isMaster) {
    try {
      const becameNewMaster = await this._thread.checkIfMasterExist();
      if (becameNewMaster) this._renewAccess(refreshToken);
    } catch (err) {
      this._logger.error(err);
    }
    return;
  }

  // if we are the master thread, attempt to renew the access token
  try {
    const res = await firstValueFrom(this._http.post(`${ apiConstants.AUTH }/renew`, { refreshToken }, undefined, true));
    this._updateToken(refreshToken, res.token);
  } catch (err) {
    this._logger.error(err);
    this.logout();
  }
}