import { NgModule                        } from '@angular/core';
import { CommonModule                    } from '@angular/common';
import { FormsModule,
         ReactiveFormsModule             } from '@angular/forms';

import { CalendarModule                  } from 'app/shared/calendar/calendar.module';

import { TranslationModule               } from 'app/core/translate/translate.module';

import { AppCommonModule                 } from 'app/common/common.module';

import { ComponentsModule                } from 'app/shared/components/components.module';

import { DayFormFieldModule              } from 'app/shared/form-fields/day/day.module';
import { TimeFormFieldModule             } from 'app/shared/form-fields/time/time.module';

import { ComponentsModule as SubModule   } from './components/components.module';

import { LockedTimesComponent            } from './locked-times.component';
import { LockedTimesCalendar             } from './calendar.component';
import { CoreService                     } from './core.service';

@NgModule({
  declarations: [
    LockedTimesComponent,
    LockedTimesCalendar
  ],
  exports: [
    LockedTimesComponent,
    SubModule
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    TranslationModule,
    DayFormFieldModule,
    TimeFormFieldModule,
    CalendarModule,
    FormsModule,
    SubModule,
    ComponentsModule,
    ReactiveFormsModule
  ],
  providers: [
    CoreService
  ]
})
export class LockedTimesFormFieldModule { }
