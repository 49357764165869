import { Component,
         ViewChild,
         AfterViewInit,
         Input,
         ViewContainerRef,
         Inject                          } from '@angular/core';
import { coerceBooleanProperty           }  from '@angular/cdk/coercion';
import { Observable,
         combineLatest                   } from 'rxjs';
import { takeUntil,
         map,
         filter,
         debounceTime,
         shareReplay                     } from 'rxjs/operators';
import _                                   from 'lodash';

import { MatDialog,
         Util                            } from 'app/common';
import { DataSourceService,
         DialogsService                  } from 'app/shared/services';
import { DivisionSettings,
         Populated as P,
         PartialTags,                    } from 'app/shared/interfaces';
import { EventComponent as Form          } from 'app/shared/forms/event/event.component';
import { commonConstants                 } from 'app/constants';
import { EnvironmentService,
         sourceSelectPipe,
         SourceService,
         StorageService,
         UserPreferencesService          } from 'app/core';
import { SelectionService                } from 'app/shared/services/selection/selection.service';
import { ChangeEvent                     } from 'app/core/source/core/types';
import { Collection                      } from '../types';
import { COLLECTION                      } from '../constants';
import { TableCore                       } from '../table-core';
import { Columns,
         TableColumnsService,
         eventsColumns                   } from '../services/table-columns/table-columns.service';
import { CustomSearchService             } from '../services/custom-search/custom-search.service';
import { StateService                    } from '../services/state/state.service';


const collection = 'events' satisfies Collection;

type RowType = P.event & { linkedEvents?: P.event[] };

@Component({
  selector: 'app-events-table',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss'],
  providers: [
    DataSourceService,
    SelectionService,
    SourceService,
    { provide: COLLECTION, useValue: collection },
    TableColumnsService,
    CustomSearchService,
    StateService,
    // provide secondary table columns for the course table
    {
      provide: 'courseTableColumns',
      deps: [EnvironmentService, UserPreferencesService, StorageService],
      useFactory: (a: EnvironmentService, b: UserPreferencesService, c: StorageService) => new TableColumnsService('courses', a, b, c)
    }
  ],
})
export class EventsComponent extends TableCore<typeof collection, /* Populated.event */RowType>
                             implements AfterViewInit {

  @ViewChild(Form)
  protected form?: Form;
  protected settings:  Observable<DivisionSettings>;
  protected groups:    Observable<P.group[]>;
  protected persons:   Observable<P.person[]>;
  protected teachers:  Observable<P.teacher[]>;
  protected locations: Observable<P.location[]>;
  protected periods:   Observable<P.period[]>;

  protected defaultEventColor = commonConstants.COLORS.EVENT_DEFAULT;

  // used to accurately display update inherited values
  protected onDataChange: Observable<ChangeEvent<P.course>>;

  protected preferredDurationBulkValue:  null | undefined | NonNullable<P.event['preferredDuration']>;
  protected durationVarianceBulkValue:   null | undefined | NonNullable<P.event['durationVariance']>;
  protected durationBulkValue:           null | undefined | NonNullable<P.event['duration']>;
  protected groupsBulkValue:             null | undefined | NonNullable<P.event['groups']>;
  protected teachersBulkValue:           null | undefined | NonNullable<P.event['teachers']>;
  protected locationsBulkValue:          null | undefined | NonNullable<P.event['locations']>;
  protected daysBulkValue:               null | undefined | NonNullable<P.event['days']>;
  protected intervalsBulkValue:          null | undefined | NonNullable<P.event['intervals']>;
  protected periodBulkValue:             null | undefined | NonNullable<P.event['period']>;
  protected centerOfAttractionBulkValue: null | undefined | NonNullable<P.event['centerOfAttraction']>;
  protected lockedTimesBulkValue:        null | undefined | NonNullable<P.event['lockedTimes']>;
  protected minBreakLengthBulkValue:     null | undefined | NonNullable<P.event['minBreakLength']>;
  protected colorBulkValue:              null | undefined | NonNullable<P.event['color']>;
  protected participantsBulkValue:       null | undefined | NonNullable<P.event['participants']>;
  protected tagsBulkValue:               null | undefined | PartialTags;

  protected onSelection: Observable<RowType[]>;

  constructor (
    protected dataSource:          DataSourceService<RowType>,
    protected selection:           SelectionService<RowType>,
    protected environment:         EnvironmentService,
    protected preferences:         UserPreferencesService,
    protected source:              SourceService,
    protected dialog:              DialogsService,
    protected matDialog:           MatDialog,
    protected viewContainerRef:    ViewContainerRef,
    protected state:               StateService       <typeof collection>,
    protected tableColumns:        TableColumnsService<typeof collection>,
    protected customSearch:        CustomSearchService<typeof collection>,
    @Inject('courseTableColumns') private courseTableColumns: TableColumnsService<'courses'>
  ) {
    super(collection, viewContainerRef, dataSource, selection, preferences, source, dialog, matDialog, state, tableColumns, customSearch);

    this.onSelection = this.selection.onSelection();
  }

// so that the type of the row is correct
private getPopulatedEventsWrapper(...args: Parameters<typeof this.source.getPopulatedEvents>): Observable<RowType[]> {
  return this.source.getPopulatedEvents(...args);
}

  ngAfterViewInit () {
    super.ngAfterViewInit();

    this.afterSourceGroupBy(
      ['groups', 'teachers', 'persons', 'locations', 'settings', 'events', 'courses', 'periods', 'overlapGroups'],
      [],
      this.getPopulatedEventsWrapper.bind(this)
    )
    .then(() => {

      const combined = combineLatest({
        data:          this.data,
        overlapGroups: this.source.getPopulatedOverlapGroups({ did: this.did, onDestroy: this.onDestroy }),
      })
      .pipe(
        debounceTime(0),
        map(({ data, overlapGroups }) => {

          // attach linked events
          data?.docs.forEach(event => {
            const overlapSpecies = event.overlapSpecies;
            if (overlapSpecies) {
              const overlapGroup = overlapGroups.find(x => x.id === overlapSpecies.id);
              const speciesId    = overlapGroup?.species?.find(x => x.to.id === event.id)?.id;
              event.linkedEvents = overlapGroup?.species
                ?.filter(x => x.id == speciesId)
                ?.map(x => Object.assign(x.to, { is: 'event' }))
                ?.filter(x => x.id !== event.id);
            }
          });

          return data;
        }),
        shareReplay(1),
        filter(Boolean)
      );

      this.dataSource.init({ source: combined });
      this.selection.dataSource = this.dataSource;

      this.settings  = this.source.getStrictSettings    ({ did: this.did, onDestroy: this.onDestroy });
      this.teachers  = this.source.getPopulatedTeachers ({ did: this.did, onDestroy: this.onDestroy });
      this.groups    = this.source.getPopulatedGroups   ({ did: this.did, onDestroy: this.onDestroy });
      this.locations = this.source.getPopulatedLocations({ did: this.did, onDestroy: this.onDestroy });
      this.periods   = this.source.getPopulatedPeriods  ({ did: this.did, onDestroy: this.onDestroy });
      this.persons   = this.source.getPopulatedStudents ({ did: this.did, onDestroy: this.onDestroy });

      // to rerender inherited values
      this.onDataChange = this.source.onCoursesChange({ did: this.did, onDestroy: this.onDestroy });

      this.subscribeToSelection();
    });
  }

  // subscribes to selection events
  private subscribeToSelection () {
    this.source
    .getPopulatedEvents({ did: this.did, skipNoFilter: true, onDestroy: this.onDestroy },
      sourceSelectPipe(
        this.selection.onSelection().pipe(map(x => ({ 'id': x.map(y => y.id) })))
      ))
    .pipe(
      takeUntil(this.onDestroy),
      filter(Boolean),
      debounceTime(200)
    )
    .subscribe(xs => {
      this.preferredDurationBulkValue  = TableCore.isSame(xs.map(x => x.preferredDuration));
      this.durationVarianceBulkValue   = TableCore.isSame(xs.map(x => x.durationVariance   ?? null));
      this.durationBulkValue           = TableCore.isSame(xs.map(x => x.duration           ?? null));
      this.groupsBulkValue             = TableCore.isSame(xs.map(x => x.groups             ?? []));
      this.teachersBulkValue           = TableCore.isSame(xs.map(x => x.teachers           ?? []));
      this.participantsBulkValue       = TableCore.isSame(xs.map(x => x.participants       ?? []));
      this.locationsBulkValue          = TableCore.isSame(xs.map(x => x.locations          ?? []));
      this.daysBulkValue               = TableCore.isSame(xs.map(x => x.days               ?? []), ['day', 'rank']);
      this.intervalsBulkValue          = TableCore.isSame(xs.map(x => x.intervals          ?? []), ['start', 'end']);
      this.periodBulkValue             = TableCore.isSame(xs.map(x => x.period             ?? null));
      this.centerOfAttractionBulkValue = TableCore.isSame(xs.map(x => x.centerOfAttraction ?? null));
      this.lockedTimesBulkValue        = TableCore.isSame(xs.map(x => x.lockedTimes        ?? []), ['start', 'end', 'displayName']);
      this.minBreakLengthBulkValue     = TableCore.isSame(xs.map(x => x.minBreakLength     ?? null));
      this.colorBulkValue              = TableCore.isSame(xs.map(x => x.color              ?? this.defaultEventColor));
      this.tagsBulkValue               = TableCore.getSelectedTags(xs);
    });
  }

  protected create(event: Partial<P.event>): void {
    throw 'IMPLEMENT ME!'
  }


  @Input()
  get minContent(): boolean { return this._minContent; }
  set minContent(value: boolean) {
    this._minContent = coerceBooleanProperty(value);
  }
  private _minContent: boolean;

  @Input()
  set useCourseColumns(value: true) {
    if ( ! value) return;

    this.courseTableColumns.watch
    .pipe(
      takeUntil(this.onDestroy),
      filter(Boolean),
      map(x => {

        // fetch event columns and enable all of them
        const columns = eventsColumns(this.environment, this.preferences.displayPublicId)
        Object.values(columns).forEach(x => x.enabled = true);

        // start from empty object
        const res: Columns[typeof collection] = {
          // should never be shown: course, subject

          // should always be shown
          preferredDuration: columns.preferredDuration,
          fixedStart:        columns.fixedStart
        };

        // order according to the course columns
        Util.functions.objectEntries(x).forEach(([key, value]) => {
          // ignore all hidden columns
          if ( ! value.enabled) return;

          // identical names
          if (key === 'displayName')        res.displayName        = columns.displayName;
          if (key === 'groups')             res.groups             = columns.groups;
          if (key === 'teachers')           res.teachers           = columns.teachers;
          if (key === 'locations')          res.locations          = columns.locations;
          if (key === 'days')               res.days               = columns.days;
          if (key === 'intervals')          res.intervals          = columns.intervals;
          if (key === 'period')             res.period             = columns.period;
          if (key === 'centerOfAttraction') res.centerOfAttraction = columns.centerOfAttraction;
          if (key === 'lockedTimes')        res.lockedTimes        = columns.lockedTimes;
          if (key === 'minBreakLength')     res.minBreakLength     = columns.minBreakLength;
          if (key === 'color')              res.color              = columns.color;
          if (key === 'ids')                res.ids                = columns.ids;
          if (key === 'tags')               res.tags               = columns.tags;

          // have slightly different names
          if (key === 'eventDurationVariance') res.durationVariance = columns.durationVariance;
          if (key === 'overlapGroup')          res.overlapSpecies   = columns.overlapSpecies;
        });

        return res;
      })
    )
    .subscribe(this.secondaryTableColumns);
  }
}
