<!-- force rerender on stateChanges.next() otherwise it is not guaranteed to show the latest values due to some lazy rendering-->
{{ stateChanges | async }}

<div class="available-locations-select-label clickable"
     [matMenuTriggerFor]="menu"
     (menuClosed)="closed()"
     (menuOpened)="opened()">
     <app-form-field-available-locations-display-value [value]="value"
                                                       [selected]="selected"
                                                       [voidText]="voidText"
                                                       [inherit]="inherit"
                                                       [disableTooltip]="disableTooltip">
     </app-form-field-available-locations-display-value>
</div>

<mat-menu yPosition="below"
          #menu="matMenu"
          class="available-locations-panel no-vertical-padding"
          [overlapTrigger]="true">

  <ng-template matMenuContent>
    <div class="toolbar" (click)="$event.stopPropagation()">
      <div class="row">
        <button mat-icon-button
                [style.grid-area]="'add'"
                class="button"
                type="button"
                *ngIf="add"
                matTooltip="{{ 'common.add' | translate }}"
                (click)="addSet()">
          <mat-icon class="royal-icon">add_box</mat-icon>
        </button>
        <mat-slide-toggle
                *ngIf="inherit"
                class="inherit-toggle"
                labelPosition="before"
                color="accent"
                (change)="inheritToggle($event)"
                [checked]="inheritValue">
          {{ 'attributes.event.inherit' | translate }}
        </mat-slide-toggle>

        <button mat-stroked-button
                class="action reset"
                color="primary"
                (click)="resetValue()"
                *ngIf="! pristine && reset">
          <div>
            <span>{{ 'common.restore' | translate }}</span>
            <mat-icon class="royal-icon">undo</mat-icon>
          </div>
        </button>
      </div>
    </div>

    <div cdkDropListGroup (click)="$event.stopPropagation()">
      <div class="content">

        <fieldset class="container dark-scrollbar">

          <legend>{{ 'shared.form-fields.available-locations.locations' | translate }}</legend>
          <div class="empty-repository" *ngIf="! repository?.length">
            <img src="assets/images/svg/dots.svg">
            <span>{{ 'shared.form-fields.available-locations.empty-repository' | translate }}</span>
          </div>
          <div
            cdkDropList
            #mainList="cdkDropList"
            [cdkDropListData]="repository"
            class="list list-full-height"
            (cdkDropListDropped)="drop($event)">
            <div class="list-item drag-cursor availability-state location-drag-item"
                *ngFor="let item of repository"
                cdkDrag
                (cdkDragStarted)="dragStarted()"
                [cdkDragDisabled]="disableDrag"
                [ngClass]="availabilityMap.get(item.id)">
              <div class="cdk-drag-placeholder-available-locations" *cdkDragPlaceholder></div>
              <mat-icon class="royal-icon">drag_indicator</mat-icon>
              <div class="list-text">{{ item | displayName }}</div>
            </div>
          </div>

        </fieldset>

        <fieldset class="container dark-scrollbar" *ngIf="singleSet">

          <legend>{{ 'shared.form-fields.available-locations.groups' | translate }}</legend>

          <div
            cdkDropList
            [cdkDropListData]="subSets[0]"
            (cdkDropListExited)="dropListExit($event)"
            class="list list-full-height"
            (cdkDropListDropped)="drop($event)">
            <mat-radio-group [value]="selected" (change)="selectChange($event.value)">

              <div class="radio-button list-item no-room pointer-cursor"
                  (click)="selectChange(null)">
                <mat-icon class="royal-icon">horizontal_rule</mat-icon>
                <div class="list-text">{{ 'shared.form-fields.available-locations.select-null' | translate }}</div>
                <mat-radio-button *ngIf="selectable"
                                  labelPosition="before"
                                  [value]="null">
                </mat-radio-button>
              </div>
              <div class="radio-button list-item pointer-cursor availability-state location-drag-item"
                  *ngFor="let item of subSets[0]"
                  [cdkDragDisabled]="disableDrag"
                  [cdkDragData]="item"
                  cdkDrag
                  (click)="selectChange(item)"
                  [ngClass]="availabilityMap.get(item.id)">
                <div class="cdk-drag-placeholder-available-locations" *cdkDragPlaceholder></div>
                <mat-icon class="royal-icon drag-cursor"
                          cdkDragHandle
                          (click)="$event.stopPropagation()">
                  drag_indicator
                </mat-icon>
                <div class="list-text">{{ item | displayName }}</div>
                <mat-radio-button *ngIf="selectable"
                                  labelPosition="before"
                                  [checked]="item?.id == selected?.id"
                                  [value]="item">
                </mat-radio-button>
              </div>
            </mat-radio-group>
          </div>

        </fieldset>

        <div class="container" *ngIf="! singleSet">
          <div class="empty-icon-container" *ngIf="! subSets?.length">
            <img src="assets/images/svg/locations.svg"
                class="empty-icon">
          </div>
          <div class="wrapper dark-scrollbar">
            <div *ngFor="let group of subSets; let index = index">
              <div class="header">
                <div class="list-header">
                  {{ 'shared.form-fields.available-locations.groups' | translate }}
                  {{ index + 1 }}
                </div>
                <button mat-icon-button
                        style="justify-self: end"
                        type="button"
                        matTooltip="{{ 'common.delete' | translate }}"
                        (click)="remove(subSets[index], index + 1)">
                  <mat-icon class="royal-icon">delete_outline</mat-icon>
                </button>
              </div>

              <div class="sub-list-container dark-scrollbar">

                <div
                  cdkDropList
                  id="{{index}}"
                  [cdkDropListData]="subSets[index]"
                  class="list border"
                  (cdkDropListDropped)="drop($event)">
                  <mat-radio-group>
                    <div class="radio-button list-item location-drag-item"
                        *ngFor="let item of subSets[index]"
                        [cdkDragDisabled]="disableDrag"
                        cdkDrag>
                      <div class="cdk-drag-placeholder-available-locations" *cdkDragPlaceholder></div>
                      <mat-icon class="royal-icon">drag_indicator</mat-icon>
                      <div class="list-text">{{ item | displayName }}</div>
                      <mat-radio-button *ngIf="selectable"
                                        labelPosition="before"
                                        [checked]="item?.id == selected?.id"
                                        [value]="item.id">

                      </mat-radio-button>
                    </div>
                  </mat-radio-group>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <mat-divider class="divider"
                *ngIf="! disableActions"
                (click)="$event.stopPropagation()"></mat-divider>

    <div (click)="$event.stopPropagation()" *ngIf="! disableActions">
      <button mat-stroked-button
              color="primary"
              type="button"
              class="button"
              (click)="trigger.closeMenu()">
        {{ 'common.cancel' | translate }}
      </button>
      <button mat-flat-button
              color="primary"
              type="button"
              (click)="submit(); trigger.closeMenu()"
              class="button right">
        {{ 'common.save' | translate }}
      </button>
    </div>
  </ng-template>
</mat-menu>