<h1 mat-dialog-title class="royal-text-primary royal-font-highlight">
  {{ 'shared.dialogs.unsaved-changes.title' | translate }}
</h1>

<p mat-dialog-content class="royal-text-secondary">
  {{ 'shared.dialogs.unsaved-changes.description' | translate }}
</p>

<mat-dialog-actions>
  <button mat-button
          mat-dialog-close>
    {{ 'common.cancel' | translate }}
  </button>

  <span class="spacer"></span>

    <button mat-button
          (click)="close('discard')">
    {{ 'common.discard' | translate }}
  </button>
  <button mat-button
          (click)="close('save')">
    {{ 'common.save' | translate }}
  </button>
</mat-dialog-actions>