import { Component,
         DestroyRef,
         ElementRef,
         inject,
         Input,
         ViewChild                            } from '@angular/core';
import { CommonModule                         } from '@angular/common';
import { takeUntilDestroyed                   } from '@angular/core/rxjs-interop';
import { BehaviorSubject,
         Subject,
         combineLatest,
         filter,
         from,
         map,
         of,
         share,
         shareReplay,
         startWith,
         switchMap,
         take                                 } from 'rxjs';
import _                                        from 'lodash';

import { SourceService,
         TranslateService                     } from '@app/core';
import { AppCommonModule                      } from '@app/common/common.module';
import { TranslationModule                    } from '@app/core/translate/translate.module';
import { inOutAnimation                       } from '@app/shared/animations';
import { ExcelTemplateService                 } from '@app/shared/services';
import { PipesModule                          } from '@app/shared/pipes/pipes.module';


@Component({
  selector: 'app-excel',
  standalone: true,
  templateUrl: './excel.component.html',
  styleUrl: './excel.component.scss',
  providers: [ ExcelTemplateService ],
  imports: [ CommonModule, AppCommonModule, TranslationModule, PipesModule ],
  animations: [ inOutAnimation ]
})
export class ExcelComponent {
  private readonly destroyRef = inject(DestroyRef);
  protected readonly acceptedTypes;

  protected readonly prepareAndDownload = new Subject<void>();
  protected readonly fileInputChange    = new Subject<Event | null>();
  protected readonly onSubmit           = new Subject<void>();

  protected readonly file;
  protected readonly displayCourses;
  protected readonly warnings;


  @ViewChild('fileInput')
  private readonly fileInput: ElementRef<HTMLInputElement> | undefined;

  constructor (
    private _excel:        ExcelTemplateService,
    private _translate:    TranslateService,
    private _source:       SourceService,
  ) {

    this.acceptedTypes = this._excel.acceptedTypes;

    // fetches the uploaded file
    this.file = this.fileInputChange
    .pipe(
      takeUntilDestroyed(),
      map(event => {
        if ( ! event) return null;
        const target = event.target as HTMLInputElement;
        const file = target.files?.[0];
        return file ?? null
      }),
      startWith(null),
      shareReplay(1),
    )

    // maps the uploaded file
    const mapped = this.file
    .pipe(
      switchMap(x => x ? from(this._excel.mapFile(x)) : of(null)),
      shareReplay(1)
    )

    // submits when the button is clicked
    this.onSubmit
    .pipe(
      takeUntilDestroyed(),
      switchMap(() => combineLatest({
          did:    this._did.pipe(                     filter(Boolean)),
          mapped: mapped   .pipe(map(x => x?.mapped), filter(Boolean)),
        })
        .pipe(take(1))
      ),
    )
    .subscribe(({ did, mapped }) => {
      // reset the file input
      this.reset();

      // merge events into courses if both courses and events are present
      // Parallel writes cause race condition
      if (mapped?.courses && mapped?.events) {
        const courses = mapped.courses.map(x => {
          const events = mapped.events!.filter(y => y.course === x.id);
          return {
            ...x,
            events: events
          }
        });
        this._source.set({ did: did, collection: 'courses' }, courses);
        return;
      }

      if (mapped?.courses) this._source.set({ did: did, collection: 'courses' }, mapped.courses);
      if (mapped?.events ) this._source.set({ did: did, collection: 'events'  }, mapped.events );
    });


    ////
    //// UI
    ////

    // combines all warnings
    this.warnings = mapped.pipe(
      map(x => [
        x?.errors   ?? [],
        x?.warnings ?? [],
        x?.mapped?.courses?.length === 0 ? this._translate.instant('dialogs.create-courses.components.excel.warnings.no_courses') : [],
      ].flat(2))
    );

    // number of courses currently shown
    this.numCourses = mapped.pipe(
      map(x => x?.mapped?.courses?.length ?? 0),
      startWith(0)
    )

    // maps the courses to be displayed in the table
    this.displayCourses = mapped
    .pipe(
      map(out => {
        const mapped = out?.mapped;
        if ( ! mapped) return null;


        const entries = _(mapped.events)
          .groupBy(x => x.course)
          .mapValues(x => x.map(x => x.preferredDuration).filter(Boolean).sort())
          .entries()
          .value();
        const durationsMap = new Map(entries);

        const groupsNamesMap    = new Map(mapped.groups   ?.map(x => [x.id, x.displayName]));
        const teachersNamesMap  = new Map(mapped.teachers ?.map(x => [x.id, x.displayName]));
        const locationsNamesMap = new Map(mapped.locations?.map(x => [x.id, x.displayName]));

        return mapped.courses?.map(x =>
          ({
            ids:                x.ids,
            displayName:        x.displayName,
            subject:            x.subject,
            plannedDuration:    x.plannedDuration,
            groups:             x.groups  ?.map(x => this.getDisplayName(x.to, groupsNamesMap  )).filter(Boolean).sort().join(', '),
            teachers:           x.teachers?.map(x => this.getDisplayName(x.to, teachersNamesMap)).filter(Boolean).sort().join(', '),
            locations:          _(x.locations)
                                  .groupBy(x => x.groupIndex)
                                  .mapValues(x => x
                                    .map(x => this.getDisplayName(x.locations?.[0], locationsNamesMap))
                                    .filter(Boolean)
                                    .sort()
                                    .join(', ')
                                  )
                                  .values()
                                  .join(' + '),
            events:             (x.id ? durationsMap.get(x.id) : null)?.join(', ')
          })
        );
      })
    )
  }

  private getDisplayName (
    x:    undefined | string | { id: string } | { toString: () => string },
    maps: Map<string | undefined, string | undefined>
  ): string | undefined {
    let id: string | undefined;
    if (typeof x === 'object' && 'id' in x) id = x.id;
    if (typeof x == 'string'              ) id = x;

    const name = maps.get(id);
    if ( ! name) return this._translate.instant('common.unknown');
    return name;
  }


  protected downloadTemplate () {
    const did = this._did.value;

    did && from(this._source.groupBy({
      did,
      collections: ['divisions', 'groups', 'teachers', 'locations', /* 'courses', */ 'settings', 'persons']
    }))
    .pipe(
      switchMap(() => {
        return combineLatest({
          division:  this._source.getStrictDivision    ({ did, onDestroy: this.destroyRef }),
          settings:  this._source.getStrictSettings    ({ did, onDestroy: this.destroyRef }),
          locations: this._source.getPopulatedLocations({ did, onDestroy: this.destroyRef }),
          teachers:  this._source.getPopulatedTeachers ({ did, onDestroy: this.destroyRef }),
          groups:    this._source.getPopulatedGroups   ({ did, onDestroy: this.destroyRef }),
          persons:   this._source.getPopulatedStudents ({ did, onDestroy: this.destroyRef }),
          courses:  /* this._source.getPopulatedCourses  ({ did }) */ of ([]),
        })
      }),
      take(1)
    )
    .subscribe(data => this._excel.downloadPopulated(data, ['settings', 'locations', 'teachers', 'groups', 'persons']));
  }


  ////
  //// IO and public properties
  ////
  @Input({ required: true })
  public set did (x: string) { this._did.next(x); }
  private _did = new BehaviorSubject<string | null>(null);

  public readonly numCourses;

  public reset () {
    if (this.fileInput) this.fileInput.nativeElement.value = ''
    this.fileInputChange.next(null);
  }

  public submit () {
    this.onSubmit.next();
  }
}
