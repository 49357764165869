import { storageConstants                } from 'app/constants';
import { LoginComponent                  } from 'app/shared/dialogs/login/login.component';
import { AuthService                     } from './auth.service';

let OPENED: boolean = false;

export function _openRenewDialog (
  this: AuthService
): void {
  // if the user was not previously logged in, do not open the dialog as the username field is disabled
  // (this since we do not want the user to change as the data has not been thrown away yet)
  const username = this._storage.get(storageConstants.USERNAME);
  if ( ! username) {
    this.logout();
    return;
  }

  // TEMP: if the user has more than one auth identity, force logout
  if (this.numIdentities > 1) {
    this.logout();
    return;
  }

  // prevent multiple dialogs from opening
  if (OPENED) return;
  OPENED = true;

  this._dialog.open(LoginComponent, {
    data: { username }
  })
  .afterClosed()
  .subscribe(res => {
    OPENED = false;
    const { credentials, renewed } = res ?? { };
    if (renewed) {
      if (credentials) this._replaceSession(credentials);
    } else {
      this.logout();
    }
  });
}
