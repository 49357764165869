import { AfterViewInit,
         Component,
         DestroyRef,
         ViewChild,
         inject,
         signal                          } from '@angular/core';
import { takeUntilDestroyed              } from '@angular/core/rxjs-interop';
import { MatStepper,
         StepperOrientation              } from '@angular/material/stepper';
import { BreakpointObserver              } from '@angular/cdk/layout';
import { BehaviorSubject,
         Observable,
         Subject,
         combineLatest                   } from 'rxjs';
import { debounceTime,
         filter,
         map,
         mergeWith,
         startWith                       } from 'rxjs/operators';

import { LoggerService,
         HttpService,
         PushNotificationService         } from 'app/core';
import { apiConstants                    } from 'app/constants';
import { Division                        } from 'app/shared/interfaces';
import { MatDialogRef                    } from 'app/common';
import { FinalizeComponent               } from './components/finalize/finalize.component';


export type PartialDivision = Partial<Pick<Division, 'displayName' | 'start' | 'end'>>;

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements AfterViewInit {
  private readonly destroyRef = inject(DestroyRef)

  protected uploading = signal(false);

  @ViewChild('stepper')
  private stepper?: MatStepper;

  public stepperOrientation: Observable<StepperOrientation>;

  @ViewChild(FinalizeComponent)
  protected finalize?: FinalizeComponent;

  protected readonly hasNextStep = new BehaviorSubject<boolean>(true);
  protected readonly allowNext = new BehaviorSubject<boolean>(false);

  // "stepper.steps.changes" nor "stepper.selectionChange" are not triggered when the stepper completed status changes
  private readonly emitStepsChange = new Subject<void>();

  constructor (
    public  dialogRef:           MatDialogRef<UploadFileComponent, string>,
    private _http:               HttpService,
    private _logger:             LoggerService,
    private _notification:       PushNotificationService,
    private _breakpointObserver: BreakpointObserver
  ) {

    this.stepperOrientation
      = this._breakpointObserver
      .observe('(min-width: 800px)')
      .pipe(map(({matches}) => (matches ? 'horizontal' : 'vertical')));
  }

  ngAfterViewInit () {
    if ( ! this.stepper) return;

    // initially prevents stepper navigation to uncompleted steps
    this.stepper.steps.forEach(step => step.completed = false);

    const steps = this.stepper.steps.changes
    .pipe(
      mergeWith(this.emitStepsChange),
      map(() => this.stepper?.steps),
      filter(Boolean),
      startWith(this.stepper.steps)
    );
    const selectedIndex = this.stepper.selectionChange
    .pipe(
      map(x => x.selectedIndex),
      startWith(this.stepper.selectedIndex)
    );
    combineLatest({ steps, selectedIndex })
    .pipe(
      takeUntilDestroyed(this.destroyRef),
      debounceTime(0)   // need to debounce for the values to be set
    )
    .subscribe(({ steps, selectedIndex }) => {
      // need to do this here since otherwise a change detection error is thrown for unknown reasons...
      this.hasNextStep.next(selectedIndex < (steps.length - 1));

      this.allowNext.next(steps.get(selectedIndex)?.completed ?? false);
    });
  }

  protected tryComplete (stepIndex: number, completed: boolean) {
    const step = this.stepper?.steps.get(stepIndex);
    if (step) {
      step.completed = completed;

      // need to emit this event manually
      this.emitStepsChange.next();
    }
  }

  protected selectFile (): void {
    // reset before moving on
    this.stepper?.reset();

    // move on
    if (this.stepper?.selected) this.stepper.selected.completed = true;
    this.stepper?.next();
  }

  protected selectMap (): void {
    // move on
    if (this.stepper?.selected) this.stepper.selected.completed = true;
    this.stepper?.next();
  }

  protected upload (event: Event) {
    // ensure that the for is not submitted twice by the same event (keydown and click)
    event.preventDefault();

    const division = this.finalize?.output.value;
    if ( ! division) {
      this._logger.error(new Error('Attempted to upload nullish division'));
      this._notification.pushError();
      return;
    }

    const referenceKey = this.finalize?.referenceKey.value;

    this.uploading.set(true);
    this._http.post(`${ apiConstants.DIVISIONS }`, { division }, { ref: referenceKey })
    .subscribe({
      next: ({ division }: { division: Division }) => {
        this.uploading.set(false);
        this.dialogRef.close(division.id);
      },
      error: err => {
        this.uploading.set(false);
        this._logger.error(err);
        this._notification.pushError();
      }
    });
  }
}
