import { Inject,
         Injectable,
         OnDestroy                      } from '@angular/core';
import { BehaviorSubject,
         EMPTY,
         Observable,
         Subject,
         catchError,
         combineLatest,
         debounceTime,
         delay,
         distinctUntilChanged,
         filter,
         finalize,
         fromEvent,
         map,
         merge,
         scan,
         share,
         shareReplay,
         startWith,
         switchMap,
         take,
         takeUntil                      } from 'rxjs';
import { nanoid                         } from 'nanoid';
import { saveAs                         } from 'file-saver';
import * as Comlink                       from 'comlink';

import { environment                    } from '@env/environment';
import { LoggerService,
         PushNotificationService,
         SourceService                  } from '@app/core';
import { DIVISION_ID                    } from 'app/constants';
import { Populated as P                 } from '@app/shared/interfaces';
import { Api,
         Callbacks,
         SchedulePatch,
         ScheduleData                   } from './shared-worker/types';
import { DateService                    } from '../date/date.service';
import { Interface,
         Defects$,
         ScheduleData$,
         UnavailableIntervals$,
         LocationAvailability$,
         GroupAvailability$,
         UnavailableDays$               } from './types';
import { inPlaceToTimestamp,
         mergePatches,
         mapToMergeable,
         mapFromMergeable,
         serializeData                  } from './functions';
import { WasmObservable                 } from './shared-worker/wasm-types';

// toggle here to launch a shared worker or a worker
const workerType: 'SharedWorker' | 'Worker' = 'SharedWorker';

type Window = globalThis.Window & typeof globalThis & {
  analysisModuleRequestLog: () => void;
}


@Injectable({
  providedIn: 'root'
})
export class AnalysisModuleService implements OnDestroy, Interface {
  private readonly onDestroy = new Subject<void>();

  // a unique client id in order to identify us when communicating with the shared worker
  private readonly _cid = nanoid(8);


  private readonly sharedWorker = workerType == 'SharedWorker' ?
    new SharedWorker(new URL('./shared-worker/main.worker', import.meta.url), { name: 'Royal_Schedule_Analysis_Module', type: 'module' }) :
    new Worker      (new URL('./shared-worker/main.worker', import.meta.url), { type: 'module' });
  private readonly api = Comlink.wrap<Api>(this.sharedWorker instanceof SharedWorker ? this.sharedWorker.port : this.sharedWorker);


  private /* readonly */ system$?:        Observable<ScheduleData>;
  private /* readonly */ systemPatches$?: Observable<SchedulePatch>;

  private readonly ready$         = new BehaviorSubject<boolean>(false);
  private readonly deregistering$ = new BehaviorSubject<boolean>(false);

  constructor (
    @Inject(DIVISION_ID)
    private readonly _did:    string,
    private readonly _logger: LoggerService,
    private readonly _source: SourceService,
    private readonly _notify: PushNotificationService
  ) {

    this._source.groupBy({
      did: this._did,
      collections: [
        'divisions',
        'settings',
        'rootIntervals',
        'periods',
        'persons',
        'locations',
        'groups',
        'teachers',
        'courses',
        'events',
        'lockedTimes',
        'overlapGroups'
      ]
    })
    .then(() => {

      // preferably we would like to do this in parallel to the group by, but then the "system$" and "systemPatches$" observables would not be ready
      // -> could solve using a second order observable...
      void this.setupSharedWorker();

      this.system$ = combineLatest({
        division:      this._source.getStrictDivision        ({ did: this._did, onDestroy: this.onDestroy }),
        settings:      this._source.getStrictSettings        ({ did: this._did, onDestroy: this.onDestroy }).pipe(map(x => ({ ...x, dt: 5 }))),
        rootIntervals: this._source.getPopulatedRootIntervals({ did: this._did, onDestroy: this.onDestroy }),
        periods:       this._source.getPopulatedPeriods      ({ did: this._did, onDestroy: this.onDestroy }),
        persons:       this._source.getPopulatedPersons      ({ did: this._did, onDestroy: this.onDestroy }),
        locations:     this._source.getPopulatedLocations    ({ did: this._did, onDestroy: this.onDestroy }),
        groups:        this._source.getPopulatedGroups       ({ did: this._did, onDestroy: this.onDestroy }),
        teachers:      this._source.getPopulatedTeachers     ({ did: this._did, onDestroy: this.onDestroy }),
        courses:       this._source.getPopulatedCourses      ({ did: this._did, onDestroy: this.onDestroy }),
        events:        this._source.getPopulatedEvents       ({ did: this._did, onDestroy: this.onDestroy }),
        lockedTimes:   this._source.getPopulatedLockedTimes  ({ did: this._did, onDestroy: this.onDestroy }),
        overlapGroups: this._source.getPopulatedOverlapGroups({ did: this._did, onDestroy: this.onDestroy })
      })
      .pipe(
        map(serializeData),
        share()
      )

      const patches$ = merge(
        this._source.onSettingsChange                     ({ did: this._did, onDestroy: this.onDestroy }).pipe(map(x => ({ settings:      x } as SchedulePatch))),
        this._source.onRootIntervalsChange<P.rootInterval>({ did: this._did, onDestroy: this.onDestroy }).pipe(map(x => ({ rootIntervals: x } as SchedulePatch))),
        this._source.onPeriodsChange      <P.period>      ({ did: this._did, onDestroy: this.onDestroy })
          .pipe(
            map(x => {
              // map the start and end date of the ranges to milliseconds since epoch
              // (was difficult to handle readable dates in the wasm module as it crashed in certain browsers...)
              x.created?.forEach(inPlaceToTimestamp);
              x.updated?.forEach(inPlaceToTimestamp);

              return x;
            }),
            map(x => ({ periods:       x } as SchedulePatch))
          ),
        this._source.onPersonsChange      <P.person>      ({ did: this._did, onDestroy: this.onDestroy }).pipe(map(x => ({ persons:       x } as SchedulePatch))),
        this._source.onLocationsChange    <P.location>    ({ did: this._did, onDestroy: this.onDestroy }).pipe(map(x => ({ locations:     x } as SchedulePatch))),
        this._source.onGroupsChange       <P.group>       ({ did: this._did, onDestroy: this.onDestroy }).pipe(map(x => ({ groups:        x } as SchedulePatch))),
        this._source.onTeachersChange     <P.teacher>     ({ did: this._did, onDestroy: this.onDestroy }).pipe(map(x => ({ teachers:      x } as SchedulePatch))),
        this._source.onCoursesChange      <P.course>      ({ did: this._did, onDestroy: this.onDestroy }).pipe(map(x => ({ courses:       x } as SchedulePatch))),
        this._source.onEventsChange       <P.event>       ({ did: this._did, onDestroy: this.onDestroy }).pipe(map(x => ({ events:        x } as SchedulePatch))),
        this._source.onLockedTimesChange  <P.lockedTime>  ({ did: this._did, onDestroy: this.onDestroy }).pipe(map(x => ({ lockedTimes:   x } as SchedulePatch))),
        this._source.onOverlapGroupsChange<P.overlapGroup>({ did: this._did, onDestroy: this.onDestroy }).pipe(map(x => ({ overlapGroups: x } as SchedulePatch)))
      )
      .pipe(
        map(mapToMergeable),
      );

      // while we generate a schedule, accumulate all patches and emit the combined one when done
      const resetScan = new Subject<void>();
      this.systemPatches$ = combineLatest({
        generating: this._source.getStrictDivision({ did: this._did, onDestroy: this.onDestroy })
          .pipe(
            map(x => !! x.running),
            distinctUntilChanged()
          ),
        patches: resetScan.pipe(
            startWith(null),
            switchMap(() => patches$.pipe(
              // in order to reset the scan seed we need to explicitly call the scan operator with a seed
              scan((acc, patch) => mergePatches(acc, patch), { } as ReturnType<typeof mergePatches>)
            ))
          )
      })
      .pipe(
        filter(({ generating }) => ! generating),
        // await possible late patches before emitting the accumulated value
        // (it might happen that "running" emits before the last affected events do)
        debounceTime(100),
        map(({ patches }) => {
          // reset for the next accumulation of patched values
          resetScan.next();

          // omit running status
          return patches;
        }),
        map(mapFromMergeable),
      );

    })
    .catch(err => {
      this._logger.error(err);
    });

    // whichever comes first, on destroy or beforeunload (tab is closed), unsubscribe
    merge(this.onDestroy, fromEvent(window, 'beforeunload'))
    .pipe(take(1))
    .subscribe(() => {
      this.deregistering$.next(true);
      void this.api.deregister(this._cid);
    });

    // expose the requestLog function to the window object
    (window as Window).analysisModuleRequestLog = () => this.requestLog();
  }

  ngOnDestroy () {
    this.onDestroy.next();
    this.onDestroy.complete();

    // terminate the worker
    if (this.sharedWorker instanceof SharedWorker) this.sharedWorker.port.close();
    else                                           this.sharedWorker.terminate();
  }

  private async setupSharedWorker () {
    if ( workerType == 'SharedWorker' && typeof SharedWorker === 'undefined'
      || workerType == 'Worker'       && typeof Worker       === 'undefined'
    ) {
      this._logger.error(new Error(`${ workerType } is not supported in this environment.`));
      if ( ! environment.production) {
        this._notify.push(`${ workerType } is not supported in this environment.`, 'generalError');
      }
    }

    const onError: Callbacks.error = (err, logError) => {

      // push temporary error notification
      if ( ! environment.production) {
        const msg =
          err instanceof Error   ? err.message :
          typeof err == 'string' ? err :
          JSON.stringify(err);

        this._notify.push(msg, 'generalError', {
          title: 'WASM encountered an error',
          icon: 'sentiment_very_dissatisfied',
          buttons: [
            { text: 'Download log', action: () => this.requestLog() }
          ],
          duration: false
        });
      }

      const error =
        err instanceof Error   ? err :
        typeof err == 'string' ? new Error(err) :
        new Error(JSON.stringify(err));

      // only one client should log the error to avoid duplicate logs
      if (logError) this._logger.error(error);
      else          console.error(error);
    };

    const onRequestInitialization: Callbacks.requestInitialization = (
      initialize: (data: ScheduleData) => void
    ) => {
      console.log('>initialization requested')
      if ( ! this.system$) throw new Error('system$ not initialized.');
      this.system$.pipe(take(1)).subscribe(x => {
        console.log('initialization data', x);
        initialize(x);
      });
    };

    const onRequestProvision: Callbacks.requestProvision = (
      patch: (data: SchedulePatch) => void
    ) => {
      console.log('>provision requested')
      if ( ! this.system$ || ! this.systemPatches$) {
        throw new Error('system$ or systemPatches$ not initialized.');
      }

      this.system$.pipe(
        takeUntil(this.onDestroy),
        take(1),
        delay(0), /* THIS SHOULD NOT BE NEEDED? (FOR SOME REASON debounceTime did not work here...) */
        switchMap(() => this.systemPatches$!),
        takeUntil(this.onDestroy)
      )
      .subscribe(x => {
        console.log('patching', x);
        patch(x);
      });
    }

    // ensure that we hav'nt already deregistered before registering
    console.assert( ! this.deregistering$.value, 'Cannot re-register after deregistering.');

    await this.api.register(this._cid, this._did,
      Comlink.proxy(() => this.ready$.next(true)),
      Comlink.proxy(onError),
      Comlink.proxy(onRequestInitialization),
      Comlink.proxy(onRequestProvision),
    );
  }

  // request log function that will be exposed to the window object allowing it to be run from the console
  private requestLog () {
    void this.api.getLog(this._cid, this._did, Comlink.proxy((log: any[]) => {
      console.log('------------[ LOG ]------------');
      console.log(log);
      console.log('-------------------------------');


      // store the log file as: log_${division.displayName}_YYYY-MM-DD_HH-MM-SS.json
      this._source.getStrictDivision({ did: this._did, onDestroy: this.onDestroy })
      .pipe(
        map(x => x.displayName ?? this._did),
        take(1)
      )
      .subscribe(displayName => {
        const blob    = new Blob([JSON.stringify(log, null, 2)], { type: 'application/json' });
        const dateStr = new Date().toISOString().replace(/[:T]/g, '-').split('.')[0];
        const fname   = `log_${displayName}_${dateStr}.json`;
        saveAs(blob, fname);
      });

    }));
  }

  // private doStuff () {
  //   console.log('WASM READY -> DO STUFF <3');

  //   // this.api.subscribe(this._cid, this._did, 'foo', Comlink.proxy((data: any) => {
  //   //   console.log('>>>', data);
  //   // }));

  //   // setTimeout(() => {
  //   //   console.log('unsubscribing');

  //   //   this.api.unsubscribe(this._cid, this._did, 'foo');
  //   // }, 30000);

  //   // deregister the client when the component is destroyed

  //   // setTimeout(() => {
  //   //   this.onDestroy.next();
  //   // }, 5000);


  // }


  private setupSubscription<T> (
    observable: WasmObservable,
    map?: (data: any) => T
  ): Observable<T> {
    // if we are in the process of deregistering, return an empty observable
    if (this.deregistering$.value) return EMPTY;

    let state: 'awaiting ready' | 'requested' | 'subscribed' | 'failed' | 'unsubscribed' = 'awaiting ready';

    const subscriptionId = new BehaviorSubject<null | [string, number]>(null);

    return this.ready$.pipe(
      filter(Boolean),
      take(1),
      switchMap(() => {
        const subj = new Subject<T>();

        state = 'requested';
        void this.api.subscribe(this._cid, this._did, observable, Comlink.proxy((data: any) => {
          if (map) subj.next(map(data));
          else     subj.next(data);
        }))
        .then(x => {
          if (x) {
            state = 'subscribed';
            subscriptionId.next(x);
          } else {
            state = 'failed';
            throw Error(`Failed to subscribe to ${ JSON.stringify(observable) }`);
          }
        });

        return subj;
      }),
      catchError(err => {
        this._logger.error(err);
        return EMPTY;
      }),
      finalize(() => {
        // if were still awaiting ready, we have not yet communicated with the shared worker and can safely ignore any cleanup
        if (state == 'awaiting ready') return;

        // await the subscriptionId to be set before unsubscribing
        if (state == 'requested' || state == 'subscribed') {
          // TODO: if requested but not subscribed, try to chase the process and rome it before it actually gets subscribed! This unless more callbacks have been attached...
          subscriptionId.pipe(
            filter((x): x is NonNullable<typeof x> => x !== null),
            take(1)
          )
          .subscribe(x => {
            state = 'unsubscribed';
            void this.api.unsubscribe(this._cid, this._did, ...x);
          });
          return;
        }

        this._logger.error(new Error(`Unable to unsubscribe from ${ JSON.stringify(observable) } due to missing subscriptionId (state: ${ state }).`));
      }),
      // if deregistering before ready, this will ensure that no new wasm subscription is made
      takeUntil(this.deregistering$.pipe(filter(Boolean))),
      shareReplay({ bufferSize: 1, refCount: true })  // refCount: true will unsubscribe when there are no more subscribers
    );
  }


  ////
  //// public methods
  ////
  public unavailableIntervals$ (data: UnavailableIntervals$.Argument): UnavailableIntervals$.Return {
    return this.setupSubscription<UnavailableIntervals$.Aux.Interval[]>(
      { to: 'unavailable_intervals', data },
      (data) => {
        return data.map((x: any) => ({
          start:           DateService.firstDay.add(x.day, 'd').add(x.start * 5, 'm'),
          end:             DateService.firstDay.add(x.day, 'd').add(x.end   * 5, 'm'),
          backgroundColor: 'transparent',
          classNames:      ['unavailable-interval', x.type]
        }));
      }
    );
  }

  public unavailableDays$ (data: UnavailableDays$.Argument): UnavailableDays$.Return {
    return this.setupSubscription<number[]>(
      { to: 'unavailable_days', data }
    );
  }

  public scheduleFrame$ (data: ScheduleData$.Argument): ScheduleData$.Return {
    return this.setupSubscription<ScheduleData$.Aux.Interval[]>(
      { to: 'schedule_frame', data },
      (data) => {
        return data.map((x: any) => ({
          start: DateService.firstDay.add(x.day, 'd').add(x.start * 5, 'm'),
          end:   DateService.firstDay.add(x.day, 'd').add(x.end   * 5, 'm'),
          type:  x.type as 'binary' | 'float'
        }));
      }
    );
  }

  public defects$ (): Defects$.Return {
    return this.setupSubscription<Defects$.Aux.Defect[]>(
      { to: 'defects' }
    );
  }

  public locationAvailability$ (data: LocationAvailability$.Argument): LocationAvailability$.Return {
    return this.setupSubscription<LocationAvailability$.Aux.Availability[]>(
      { to: 'location_availability', data }
    );
  }

  public groupAvailability$ (data: GroupAvailability$.Argument): GroupAvailability$.Return {
    return this.setupSubscription<GroupAvailability$.Aux.Availability[]>(
      { to: 'group_availability', data }
    );
  }

}
