import { NgModule                        } from '@angular/core';
import { CommonModule                    } from '@angular/common';

import { TranslationModule               } from 'app/core/translate/translate.module';
import { NoColumnsComponent              } from './no-columns.component';

@NgModule({
  declarations: [
    NoColumnsComponent,
  ],
  exports: [
    NoColumnsComponent
  ],
  imports: [
    CommonModule,
    TranslationModule
  ]
})
export class NoColumnsModule { }