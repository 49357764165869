import { NgModule                        } from '@angular/core';
import { CommonModule                    } from '@angular/common';
import { FormsModule,
         ReactiveFormsModule             } from '@angular/forms';

import { TranslationModule               } from 'app/core/translate/translate.module';

import { AppCommonModule                 } from 'app/common/common.module';

import { ComponentsModule                } from 'app/shared/components/components.module';

import { ComponentsModule as SubModule   } from './components/components.module';

import { AvailableDaysComponent          } from './available-days.component';


@NgModule({
  declarations: [
    AvailableDaysComponent
  ],
  exports: [
    AvailableDaysComponent,
    SubModule
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    TranslationModule,
    FormsModule,
    SubModule,
    ComponentsModule,
    ReactiveFormsModule
  ]
})
export class AvailableDaysFormFieldModule { }
