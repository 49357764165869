<form [formGroup]="form"
      novalidate
      class="form"
      (keydown)="keydown($event)"
      [ngSwitch]="type">
  <ng-template ngSwitchCase="default">
    <div class="content-default">


    </div>
  </ng-template>

  <ng-template ngSwitchCase="row" class="content-row">
    <div class="content-row">

      <div class="form-field-wrapper" *ngIf="options?.showIds">
        <mat-form-field class="form-field" appearance="outline" style="font-size: 12px">
          <mat-label>{{ 'attributes.shared.ids' | translate }}</mat-label>
          <input matInput type="text" formControlName="ids" required id="ids" autocomplete="off">
          <mat-error><app-form-field-error [control]="form.controls.ids"></app-form-field-error></mat-error>
        </mat-form-field>
      </div>

      <div class="form-field-wrapper" *ngIf="options?.showDisplayName">
        <mat-form-field class="form-field" appearance="fill" style="font-size: 12px">
          <input matInput
                type="text"
                formControlName="displayName"
                id="displayName"
                placeholder="{{ 'attributes.shared.displayName' | translate }}"
                autocomplete="off">
        </mat-form-field>
      </div>

    </div>
  </ng-template>

</form>
