import { NgModule                          } from '@angular/core';
import { CommonModule                      } from '@angular/common';
import { CreateComponent                   } from './create/create.component';
import { EditComponent                     } from './edit/edit.component';
import { FormsModule                       } from 'app/shared/forms/forms.module';
import { TranslationModule                 } from 'app/core/translate/translate.module';
import { AppCommonModule                   } from 'app/common/common.module';
import { ComponentsModule                  } from 'app/shared//components/components.module';

@NgModule({
  declarations: [
    CreateComponent,
    EditComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    TranslationModule,
    ComponentsModule,
    AppCommonModule
  ]
})
export class DivisionsModule { }
