import { Component,
         Input,
         AfterViewInit,
         EventEmitter,
         Output,
         ViewChild,
         inject,
         DestroyRef                      } from '@angular/core';
import { coerceBooleanProperty           } from '@angular/cdk/coercion';
import { CdkVirtualScrollViewport        } from '@angular/cdk/scrolling';
import { MatSelect                       } from '@angular/material/select';
import { takeUntilDestroyed              } from '@angular/core/rxjs-interop';
import { MatOption                       } from '@angular/material/core';
import { BehaviorSubject,
         take,
         takeUntil                       } from 'rxjs';
import $                                   from 'jquery';

import { SearchComponent                 } from '@app/shared/components/search/search.component';
import { Entity                          } from '../../types';



type Option = Entity

type Value<T extends Option> = {
  id:      string;
  entity?: T;
}

function toValue<T extends Option> (val: T): Value<T> {
  return {
    id:     val.id,
    entity: val,
  }
}

@Component({
  selector: 'app-virtual-select',
  templateUrl: './virtual-select.component.html',
  styleUrls: ['./virtual-select.component.scss']
})
export class VirtualSelectComponent<T extends Option> implements AfterViewInit {
  // needed as the "takeUntilDestroyed" is located in the "ngAfterViewInit" method
  private destroyRef = inject(DestroyRef)

  protected activeOptionValue: T | null | undefined = undefined;
  protected transparentPanel                        = false;

  @ViewChild(MatSelect)                select: MatSelect;
  @ViewChild(CdkVirtualScrollViewport) scroll: CdkVirtualScrollViewport;

  constructor () { }

  ngAfterViewInit () {

    this.select._openedStream
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe(() => {

      this.scroll.renderedRangeStream
      .pipe(takeUntil(this.select._closedStream))
      .subscribe({
        next: () => {
          // an active option value must be set
          if ( ! this.activeOptionValue) return;

          // time out to delay after the rendered range has been updated
          setTimeout(() => {
            const opt = (this.select.options.toArray() as MatOption<T | null>[]).find(x => x.value?.id == this.activeOptionValue?.id);
            if (opt) this.select._keyManager.setActiveItem(opt);
          }, 0);
        },
        // reset the active option value when the select is closed
        complete: () => delete this.activeOptionValue
      });
    })


    // override the navigation of the select panel
    // (and als clear the onDestroy function to avoid memory leaks)
    this.destroyRef.onDestroy(() => this.select._keyManager.onKeydown = () => {});
    this.select._keyManager.onKeydown = (event: KeyboardEvent) => {
      // abort if not arrow up or down
      const key = event.key;
      if (key != 'ArrowDown' && key != 'ArrowUp') return;

      if (this.select.panelOpen) {
        // if the search is visible and we are at the first item, go to the search
        const searchVisible = this.select._elementRef.nativeElement.classList.contains('search-visible');
        if (searchVisible && key == 'ArrowUp' && this.select._keyManager.activeItemIndex == 0) {
          // focuses the input and sets the cursor to the end
          const input = $(this.select.panel.nativeElement).find('app-search input')[0];
          if (input && input instanceof HTMLInputElement) {
            input.focus();
            setTimeout(() => input.selectionStart = input.selectionEnd = input.value.length, 0);
          }
          return;
        }

        // navigate up down in the select panel
        if (key == 'ArrowDown') {
          this.select._keyManager.setNextItemActive();

          // if this new item is the hidden one, go back to the previous one
          if (this.select._keyManager.activeItem?._getHostElement().classList.contains('hidden-mat-option')) {
            this.select._keyManager.setPreviousItemActive();
          }
        }
        else {
          this.select._keyManager.setPreviousItemActive();
        }

        // update active option value since we need to restore it whenever the virtual scroll is updated
        // (timeout needed to not get too far ahead of the function listening to the virtual scroll which needs to be time outed too)
        setTimeout(() => this.activeOptionValue = this.select._keyManager.activeItem?.value, 0);

        // ensure that the selected item is visible
        setTimeout(() => {
          const $viewport = $(this.scroll.elementRef.nativeElement);
          const $active = $viewport.find('mat-option.mat-mdc-option-active');
          if ( ! $viewport.length || ! $active.length) return;

          // if the active element is not visible, scroll to it
          const viewportOffsetTop = $viewport.offset()?.top ?? 0;
          const viewportHeight    = $viewport.height()      ?? 0;
          const activeOffsetTop   = $active  .offset()?.top ?? 0;
          const activeHeight      = $active  .height()      ?? 0;
          const marginTop         = activeOffsetTop - viewportOffsetTop;
          const marginBottom      = (viewportOffsetTop + viewportHeight) - (activeOffsetTop + activeHeight);

          const viewportScrollTop = $viewport.scrollTop() ?? 0;
          if      (marginTop    < 0) $viewport.scrollTop(viewportScrollTop + marginTop);
          else if (marginBottom < 0) $viewport.scrollTop(viewportScrollTop - marginBottom);
        }, 0);

      } else {
        const options = this.onOptions?.value;
        if ( ! options) return;

        // find index of current value
        const index = this.value ? options.findIndex(x => this.compareWith(x, this.value?.entity)) : -1;

        if (key == 'ArrowDown') {
          if (index == -1) this.value = toValue(options[0]);
          else             this.value = toValue(options[Math.min(index + 1, options.length - 1)]);
        } else {
          if      (index == -1) return;
          else if (index == 0)  this.value = undefined;
          else                  this.value = toValue(options[Math.max(index - 1, 0)]);
        }
        this.onValueChange.emit(this.value?.entity);

        // update the options in order to make sure the selected one is displayed correctly in the trigger
        this.updateOptions(this.value?.entity);
      }
    }

  }

  private updateOptions (val: T | null | undefined) {
    const opts = this.select.options.toArray() as MatOption<T | null>[];

    // set the value of the last option (the one with class "hidden-mat-option") to be the selected one
    const opt = opts.at(-1);
    if ( ! opt?._getHostElement().classList.contains('hidden-mat-option')) {
      console.log(opt, opts)
      throw new Error('last option is not the hidden one');
    }
    opt.value = val ?? null;

    // update the options
    this.select.options.reset(opts);
  }


  protected compareWith (a: T | null | undefined, b: T | null | undefined) {
    return a?.id == b?.id;
  }

  protected computeHeight (arr: any[] | null, filter: string | null, height: number,) {
    // +1 because of the "none" option which is only visible when there is no filter
    const numItems = (arr?.length ?? 0) + (filter ? 0 : 1);

    // 275 is the max height of the select panel
    return Math.min(275, numItems * height);
  }

  protected scrollToSelected (
    select: MatSelect,
    scroll: CdkVirtualScrollViewport
  ) {
    const value = select.value as T | null;

    const index = (value ? this.onOptions?.value?.findIndex(x => x.id == value.id) ?? -1 : -1) + 1;
    const height = $(scroll.elementRef.nativeElement).height() ?? 0;
    const offset = Math.max(0, index * 48 - (height - 48));
    scroll.scrollToOffset(offset);

    // sometimes the scroll is not updated correctly so trigger a scroll event to sort it out
    const $scroll = $(scroll.elementRef.nativeElement);
    $scroll.scrollTop(($scroll.scrollTop() ?? 0) + 1);
  }

  protected showSearchField (
    key:    KeyboardEvent,
    search: SearchComponent,
  ) {
    // cannot be escape, enter, backspace, etc
    if (key.key.length > 1) return;

    // if the search field is in focus, abort
    const $input = $(search.elementRef.nativeElement).find('input');
    if ($input.is(':focus')) return;

    // add letter to the search filed and focus it
    search.value += key.key;
    $input.trigger('focus');
  }

  protected goToList (select: MatSelect) {
    // focus the select element to allow for keyboard navigation
    select._elementRef.nativeElement.focus();

    // the timeout is needed because otherwise the second option will be activated
    setTimeout(() => select._keyManager.setFirstItemActive(), 0);
  }


  ////
  //// IO
  ////
  @Input() icon: string;
  @Input() placeholder: string;

  @Input()
  get value(): Value<T> | undefined | null { return this._value; }
  set value(val: Value<T> | undefined | null) {
    this._value = val;

    if ( ! val ||  ! this.select) return;

    // if the option corresponding to the value to be selected is not present we must
    // open the select in order to trigger the rerender of the matSelectTrigger
    const exists = this.select.options.some((x: MatOption<T | undefined>) => x.value?.id == val.id);
    if (exists) return;

    // subscribe to the opened change event
    this.select.openedChange
    .pipe(take(2))
    .subscribe(open => {
      // close immediately after opening and then make panel visible again
      if (open) this.select.close();
      else      this.transparentPanel = false;
    });

    // trigger the opening of the select
    this.transparentPanel = true;
    this.select.open();
  }
  private _value: Value<T> | undefined | null;

  @Input() onOptions: BehaviorSubject<T[] | null> | undefined;

  @Input({ transform: coerceBooleanProperty }) openInNew: boolean = false;

  @Input()
  set panelClass (val: string | string[]) {
    this._panelClass.next(Array.isArray(val) ? val.join(' ') : val);
  }
  protected _panelClass = new BehaviorSubject('');

  @Output() onOpenInNew   = new EventEmitter<T>();
  @Output() onValueChange = new EventEmitter<T | undefined | null>();
}