import _                                   from 'lodash';

type Instantiable<T = any> = new (...args: any[]) => T;

type Option = {
  onError?: (err: Error) => void;
};

export function Catch(options?: Option) {
  return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
    const originalMethod = descriptor.value;

    descriptor.value = function(...args: any[]) {
      try {
        const result = originalMethod.apply(this, args);
        return result;
      } catch(err) {

        if (_.isString(err))
          err = new Error(err);

        if (! (err instanceof Error))
          return;
        if ('_logger' in this)
          this._logger?.error(err);
        if (options?.onError instanceof Function)
          return options.onError(err);
      }
    };

    return descriptor;
  }
}