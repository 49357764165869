<h1 mat-dialog-title>
  {{ 'common.schedule' | translate }}
</h1>

<app-submit-pane [hasActions]="true"></app-submit-pane>

<mat-dialog-content>
  <app-division-form displayName start end></app-division-form>
</mat-dialog-content>

<mat-divider></mat-divider>

<mat-dialog-actions align="end">
  <button
    mat-button
    mat-dialog-close
    type="button">
    {{ 'common.cancel' | translate }}
  </button>

  <button
    mat-button
    form="division-form"
    type="submit"
    [disabled]=" division?.form?.pristine || ! division?.form?.valid"
    (click)="submit()"
    color="primary">
    {{ 'common.update' | translate }}
  </button>
</mat-dialog-actions>
