import { NgModule                        } from '@angular/core';
import { CommonModule                    } from '@angular/common';
import { FormsModule,
         ReactiveFormsModule             } from '@angular/forms';

import { TranslationModule               } from 'app/core/translate/translate.module';
import { AppCommonModule                 } from 'app/common/common.module';
import { DayFormFieldModule              } from 'app/shared/form-fields/day/day.module';
import { TimeFormFieldModule             } from 'app/shared/form-fields/time/time.module';
import { ComponentsModule                } from 'app/shared/components/components.module';
import { ComponentsModule as SubModule   } from './components/components.module';
import { CenterOfAttractionComponent     } from './center-of-attraction.component';

@NgModule({
  declarations: [
    CenterOfAttractionComponent
  ],
  exports: [
    CenterOfAttractionComponent,
    SubModule
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    TranslationModule,
    DayFormFieldModule,
    TimeFormFieldModule,
    SubModule,
    FormsModule,
    ComponentsModule,
    ReactiveFormsModule
  ]
})
export class CenterOfAttractionFormFieldModule { }
