import { Component                    } from '@angular/core';
import { COMMA,
         ENTER,
         SPACE                        } from '@angular/cdk/keycodes';
import { FormControl                  } from '@angular/forms';
import { MatDialogRef                 } from '@angular/material/dialog';
import { MatChipInputEvent            } from '@angular/material/chips';
import { CleaveOptions                } from 'cleave.js/options';
import _                                from 'lodash';

import { UserPreferencesService       } from '@app/core';
import { toMultipleOf5                } from '@app/shared/tables/schedule-data-tables/components/create-courses/components/form/form.component';


@Component({
  templateUrl: './add-duration-set.component.html',
  styleUrls: ['./add-duration-set.component.scss']
})
export class AddDurationSetComponent {

  protected readonly cleave_number: CleaveOptions = {
    numeral:             true,
    delimiter:           '',
    numeralPositiveOnly: true,
    stripLeadingZeroes:  true
  };
  protected readonly ctrl = new FormControl<string[]>([], { nonNullable: true });

  readonly separatorKeysCodes = [ENTER, COMMA, SPACE] as const;

  constructor (
    protected dialogRef:   MatDialogRef<AddDurationSetComponent>,
    protected preferences: UserPreferencesService
  ) { }

  protected add (event: MatChipInputEvent) {
    if ( ! event.value) return;

    const duration = toMultipleOf5(event.value) || 5;
    const prevDurations = this.ctrl.value;
    this.ctrl.setValue([...prevDurations, duration.toString()]);

    // reset input after selection
    event.chipInput!.clear();
  }

  protected remove (index: number) {
    const prevDurations = this.ctrl.value;
    prevDurations.splice(index, 1);
    this.ctrl.setValue(prevDurations);
  }

  protected submit (event: Event) {
    // ensure that the for is not submitted twice by the same event (keydown and click)
    event.preventDefault();

    // convert to numbers
    const val = this.ctrl.value.map(x => parseInt(x));
    this.preferences.addDurationSet(val);
    this.dialogRef.close(val);
  }

}
