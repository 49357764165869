import { Component,
         ViewChild,
         AfterViewInit,
         ViewContainerRef                } from '@angular/core';
import { Observable, Subject                      } from 'rxjs';
import { debounceTime,
         map,
         filter,
         takeUntil                       } from 'rxjs/operators';
import _                                   from 'lodash';

import { MatDialog                       } from '@app/common';
import { DataSourceService,
         DialogsService                  } from 'app/shared/services';
import { DivisionSettings,
         PartialTags,
         Populated                       } from 'app/shared/interfaces';
import { EnvironmentService,
         sourceHasPipe,
         sourceSelectPipe,
         SourceService,
         UserPreferencesService          } from 'app/core';
import { Options as formOptions          } from 'app/shared/forms/groups/groups.component';
import { GroupsComponent as Form         } from 'app/shared/forms/groups/groups.component';
import { SelectionService                } from 'app/shared/services/selection/selection.service';
import { Collection                      } from '../types';
import { COLLECTION                      } from '../constants';
import { TableCore                       } from '../table-core';
import { TableColumnsService             } from '../services/table-columns/table-columns.service';
import { CustomSearchService             } from '../services/custom-search/custom-search.service';
import { StateService                    } from '../services/state/state.service';


const collection = 'groups' satisfies Collection;

@Component({
  selector: 'app-groups-table',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss'],
  providers: [
    DataSourceService,
    SelectionService,
    SourceService,
    { provide: COLLECTION, useValue: collection },
    TableColumnsService,
    CustomSearchService,
    StateService,
  ]
})
export class GroupsComponent extends TableCore<typeof collection, Populated.group>
                             implements AfterViewInit {

  @ViewChild(Form)
  protected form?: Form;
  protected formOptions: formOptions = {
    showIds:         false,
    showDisplayName: true
  };

  protected settings:      Observable<DivisionSettings>;
  protected persons:       Observable<Populated.person[]>;
  protected groups:        Observable<Populated.group[]>;
  protected locations:     Observable<Populated.location[]>;
  protected rootIntervals: Observable<Populated.rootInterval[]>;

  protected typeSelect = new Subject<Populated.group['species']>();

  protected typeOptions = [
    { value: 'all',     label: 'common.all'                  },
    { value: 'class',   label: 'common.groups.species.class' },
    { value: 'subject', label: 'common.groups.species.none'  }
  ];

  // bulk edit values
  protected lunchBulkValue:          null | undefined | NonNullable<Populated.group['lunch']>;
  protected daysBulkValue:           null | undefined | NonNullable<Populated.group['days']>;
  protected intervalsBulkValue:      null | undefined | NonNullable<Populated.group['intervals']>;
  protected rootIntervalBulkValue:   null | undefined | NonNullable<Populated.group['rootInterval']>;
  protected lockedTimesBulkValue:    null | undefined | NonNullable<Populated.group['lockedTimes']>;
  protected minBreakLengthBulkValue: null | undefined | NonNullable<Populated.group['minBreakLength']>;
  protected parentGroupsBulkValue:   null | undefined | NonNullable<Populated.group['parentGroups']>;
  protected membersBulkValue:        null | undefined | NonNullable<Populated.group['members']>;
  protected tagsBulkValue:           null | undefined | PartialTags;
  protected isCompleteSpeciesBulk:   boolean;
  protected isSubSpeciesBulk:        boolean;

  constructor (
    protected dataSource:       DataSourceService<Populated.group>,
    protected selection:        SelectionService<Populated.group>,
    protected environment:      EnvironmentService,
    protected preferences:      UserPreferencesService,
    protected source:           SourceService,
    protected dialog:           DialogsService,
    protected matDialog:        MatDialog,
    protected viewContainerRef: ViewContainerRef,
    protected state:            StateService       <typeof collection>,
    protected tableColumns:     TableColumnsService<typeof collection>,
    protected customSearch:     CustomSearchService<typeof collection>,
  ) {
    super(collection, viewContainerRef, dataSource, selection, preferences, source, dialog, matDialog, state, tableColumns, customSearch);
  }

  ngAfterViewInit () {
    super.ngAfterViewInit();

    this.afterSourceGroupBy(
      ['groups', 'settings', 'locations', 'persons', 'rootIntervals'],
      [],
      this.source.getPopulatedGroups
    )
    .then(() => {
      this.dataSource.init({ source: this.data });
      this.selection.dataSource = this.dataSource;

      this.settings      = this.source.getStrictSettings        ({ did: this.did, onDestroy: this.onDestroy });
      this.persons       = this.source.getPopulatedStudents     ({ did: this.did, onDestroy: this.onDestroy });
      this.groups        = this.source.getPopulatedGroups       ({ did: this.did, onDestroy: this.onDestroy }, sourceHasPipe({ species: true }));
      this.locations     = this.source.getPopulatedLocations    ({ did: this.did, onDestroy: this.onDestroy });
      this.rootIntervals = this.source.getPopulatedRootIntervals({ did: this.did, onDestroy: this.onDestroy });

      this.subscribeToSelection();
    });
  }


  // subscribes to selection events
  private subscribeToSelection () {
    this.source
    .getPopulatedGroups({ did: this.did, skipNoFilter: true, onDestroy: this.onDestroy },
      sourceSelectPipe(
        this.selection.onSelection().pipe(map(x => ({ 'id': x.map(y => y.id) })))
      ))
    .pipe(
      takeUntil(this.onDestroy),
      filter(Boolean),
      debounceTime(200)
    )
    .subscribe(xs => {
      /*
        TEMP FIX
        only allow class types to set extended bulk values
        only allow void type to set parentGroups
      */
      this.isCompleteSpeciesBulk   = xs.every(x => x.species == 'class');
      this.isSubSpeciesBulk        = xs.every(x => x.species == null);
      this.parentGroupsBulkValue   = TableCore.isSame(xs.map(x => x.parentGroups   ?? []), ['id']);
      this.lunchBulkValue          = TableCore.isSame(xs.map(x => x.lunch          ?? []), ['intervals', 'duration', 'durationVariance']);
      this.daysBulkValue           = TableCore.isSame(xs.map(x => x.days           ?? []), ['day', 'rank']);
      this.intervalsBulkValue      = TableCore.isSame(xs.map(x => x.intervals      ?? []), ['start', 'end']);
      this.rootIntervalBulkValue   = TableCore.isSame(xs.map(x => x.rootInterval   ?? null), ['id']);
      this.lockedTimesBulkValue    = TableCore.isSame(xs.map(x => x.lockedTimes    ?? []), ['start', 'end', 'displayName']);
      this.minBreakLengthBulkValue = TableCore.isSame(xs.map(x => x.minBreakLength ?? null));
      this.parentGroupsBulkValue   = TableCore.isSame(xs.map(x => x.parentGroups   ?? null), ['id']);
      this.membersBulkValue        = TableCore.isSame(xs.map(x => x.members        ?? []), ['id']);
      this.tagsBulkValue           = TableCore.getSelectedTags(xs);
    });
  }
}
