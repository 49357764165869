import { Component,
         Input,
         ElementRef,
         OnDestroy,
         Optional,
         Self,
         EventEmitter,
         HostBinding,
         Output,
         ViewEncapsulation,
         ChangeDetectionStrategy,
         ViewChild                 } from '@angular/core';
import { ControlValueAccessor,
         NgControl,
         FormGroup,
         FormControl               } from '@angular/forms';
import { MatFormFieldControl       } from '@angular/material/form-field';
import { FocusMonitor              } from '@angular/cdk/a11y';
import { coerceBooleanProperty     } from '@angular/cdk/coercion';
import { coerceNumberProperty      } from '@angular/cdk/coercion';
import { MatMenuTrigger            } from '@angular/material/menu';
import { Subject,
         asyncScheduler,
         fromEvent                 } from 'rxjs';
import { startWith,
         takeUntil                 } from 'rxjs/operators';
import moment                        from 'moment';
import _                             from 'lodash';

import { Event                     } from 'app/shared/interfaces';
import { DateService               } from 'app/shared/services';
import { commonConstants           } from 'app/constants';


type Value = Pick<Event.populated, 'start' | 'end' | 'fixedStart'>;

type Form = {
  enabled: FormControl<boolean>;
  day:     FormControl<number>;
  start:   FormControl<moment.Moment>;
};

@Component({
  selector: 'app-form-field-fixed-start',
  templateUrl: './fixed-start.component.html',
  styleUrls: ['./fixed-start.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: FixedStartComponent
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FixedStartComponent
  implements OnDestroy, ControlValueAccessor, MatFormFieldControl<Value> {

  @ViewChild(MatMenuTrigger) trigger?: MatMenuTrigger;
  @Output('onChange') emitter = new EventEmitter<Value>();

  public placeholderText:  string          = '';
  static nextId:       number              = 0;
  public stateChanges: Subject<void>       = new Subject<void>();
  public focused:      boolean             = false;
  public errorState:   boolean             = false;
  public controlType:  string              = 'fixed-start-input';
  public id:           string              = `fixed-start-input-${ FixedStartComponent.nextId++ }`;
  public describedBy:  string              = '';
  public onChange = (_: any) => {};
  public onTouched = () => {};
  public days:         string[]            = commonConstants.DAYS;

  private readonly onDestroy = new Subject<boolean>();
  private readonly onClose   = new Subject<boolean>();

  public form: null | FormGroup<Form> = null;

  @HostBinding('attr.tabindex') __tabindex = 0;

  constructor (
    @Optional() @Self() public ngControl: NgControl,
    private _focusMonitor: FocusMonitor,
    private _elementRef:   ElementRef<HTMLElement>,
  ) {

    _focusMonitor.monitor(_elementRef, true)
    .subscribe((origin: any) => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  public resetValue(): void {
    this.value = this._pristineValue;
    this.pristine = true;
    this._setForm(this.value);
  }

  private _setForm (value: Value): void {
    const { start, fixedStart } = value;

    const startMnt = start ? moment.utc(start) : DateService.fromTimeString('08:00', 0);
    const val: Required<FormGroup<Form>['value']> = {
      enabled: !! fixedStart,
      day:     DateService.getDayIndex(startMnt),
      start:   DateService.sanitizeDate(startMnt, 0)!
    };

    if ( ! this.form) {
      this.form = new FormGroup<Form>({
        enabled: new FormControl(val.enabled, { nonNullable: true }),
        day:     new FormControl(val.day,     { nonNullable: true }),
        start:   new FormControl(val.start,   { nonNullable: true })
      });
    } else {
      this.form.setValue(val, { emitEvent: false });
    }
  }

  protected closed(): void {
    if (this.saveOnClose && this.valid && ! this.pristine) {
      this.emitter.emit(this.value);
      this.value = this._value;
      this.resetValue();
    }

    this.form = null;

    this.onClose.next(true);
  }

  protected opened(): void {
    this._setForm(this._value);
    this._setValue();

    this.form!.valueChanges
    .pipe(takeUntil(this.onClose))
    .subscribe(() => this._handleInput());

    // set enabled/disabled
    this.form?.controls.enabled.valueChanges
    .pipe(
      takeUntil(this.onClose),
      startWith(this.form?.value.enabled)
    )
    .subscribe(x => {
      if (x) {
        this.form?.controls.day  .enable({ emitEvent: false });
        this.form?.controls.start.enable({ emitEvent: false });
      } else {
        this.form?.controls.day  .disable({ emitEvent: false });
        this.form?.controls.start.disable({ emitEvent: false });
      }
    });


    fromEvent(document, 'keydown')
    .pipe(takeUntil(this.onClose))
    .subscribe((event: any) => {
      if (event.key == 'Escape'/* || event.key == 'Enter'*/) {
        this.trigger?.closeMenu()
      }
    });
  }

  protected submit(): void {
    this.emitter.emit(this.value);
  }

  private _setValue(): void {
    const value = this.form?.value ?? {};

    if (value.enabled && value.start) {
      const start = DateService.sanitizeDate(value.start, value.day)!;
      const end   = start.clone().add(this.duration, 'minutes');
      this._value = {
        fixedStart: true,
        start:      start.toISOString(),
        end:        end  .toISOString(),
      };
      this.pristine = _.isEqual(this._value, this._pristineValue);
    }
    else {
      this._value   = { fixedStart: false };
      this.pristine = !! this._value.fixedStart == !! this._pristineValue.fixedStart;
    }
  }

  get empty() {
    return false;
  }

  get valid(): boolean {
    return this.form?.valid ?? true;
  }

  get shouldLabelFloat() { return this.focused || ! this.empty; }

  get pristine(): boolean { return this._pristine }
  set pristine(_val: boolean) {
    const val      = coerceBooleanProperty(_val);
    this._pristine = val;

    if (val) asyncScheduler.schedule(() => this.ngControl?.control?.markAsPristine());

    this.stateChanges.next();
  }
  private _pristine:  boolean = true;

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get reset(): boolean { return this._reset; }
  set reset(value: boolean) {
    this._reset = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _reset = true;

  @Input()
  get voidText(): string { return this._voidText; }
  set voidText(value: string) {
    this._voidText = value;
    this.stateChanges.next();
  }
  private _voidText: string = '';

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get disableActions(): boolean { return this._disableActions; }
  set disableActions(value: boolean | string) {
    this._disableActions = coerceBooleanProperty(value);
  }
  private _disableActions = true;

  @Input()
  get saveOnClose(): boolean { return this._saveOnClose; }
  set saveOnClose(value: boolean | string) {
    this._saveOnClose = coerceBooleanProperty(value);
  }
  private _saveOnClose: boolean = false;

  @Input()
  get numDays(): number { return this._numDays; }
  set numDays(value: number) {
    this._numDays = coerceNumberProperty(value, 5);
    this.stateChanges.next();
  }
  private _numDays = 5;


  @Input()
  get preferredDuration(): number { return this._preferredDuration; }
  set preferredDuration(value: number) {
    this._preferredDuration = value
  }
  private _preferredDuration: number;

  @Input()
  get duration(): number { return this._duration ?? this._preferredDuration; }
  set duration(value: number | undefined) {
    this._duration = value
  }
  private _duration: number | undefined;

  @Input()
  get discretization(): number { return this._discretization; }
  set discretization(value: number) {
    this._discretization = coerceNumberProperty(value, 5);
    this.stateChanges.next();
  }
  private _discretization: number = 5;

  @Input()
  get value(): Value {
    return this._value;
  }
  set value(_val: Value) {
    if ( ! _val) return;

    const val = _.pick(_val, ['start', 'end', 'fixedStart']);
    val.fixedStart = !! val.fixedStart;   // convert to boolean

    this._value = this._pristineValue = val;
    this.pristine = true;
    this.stateChanges.next();
  }
  private _value:         Value;
  private _pristineValue: Value;

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
    this.onDestroy.next(true);
    this.onDestroy.complete();
    this.onClose.next(true);
    this.onClose.complete();
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent) {
    this.trigger?.openMenu();
  }

  writeValue(val: Value): void {
    this.value = val;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  _handleInput(): void {
    this._setValue();
    this.onChange(this.value);
  }

  static ngAcceptInputType_disabled: boolean | string | null | undefined;
  static ngAcceptInputType_required: boolean | string | null | undefined;
}
