import lodash                                from 'lodash';
import deepdash                              from 'deepdash-es';

import { Collection                        } from '../../types';
import { SearchKeys, FormValue, FormLabels } from './types';

const _ = deepdash(lodash);

const BASE = 'TABLE_CUSTOM_SEARCH'
export const storageKeys: Record<Collection, string> = {
  groups:    `${BASE}/GROUPS`,
  teachers:  `${BASE}/TEACHERS`,
  locations: `${BASE}/LOCATIONS`,
  persons:   `${BASE}/PERSONS`,
  courses:   `${BASE}/COURSES`,
  events:    `${BASE}/EVENTS`,
};


function mapLocationFormValue (v: Partial<FormValue['locations']>): SearchKeys['locations'] {
  return {
    displayName: !! v.displayName,
    ids:         !! v.ids,
    tags: {
      value: !! v.tags,
    },
  }
}

function mapPersonFormValue (v: Partial<FormValue['persons']>): SearchKeys['persons'] {
  return {
    firstName: !! v.firstName,
    lastName:  !! v.lastName,
    ids:       !! v.ids,
    tags: {
      value: !! v.tags,
    },
    SSN: {
      value: !! v.SSN,
    },
    emails: {
      value: !! v.emails,
    },
    phoneNumbers: {
      value: !! v.phoneNumbers,
    },
    group: {
      displayName: !! v.group,
      ids:         !! v.group && !! v.ids,
    },
  }
}

function mapTeacherFormValue (v: Partial<FormValue['teachers']>): SearchKeys['teachers'] {
  return {
    displayName: !! v.displayName,
    ids:         !! v.ids,
    tags: {
      value: !! v.tags,
    },
  }
}

function mapGroupFormValue (v: Partial<FormValue['groups']>): SearchKeys['groups'] {
  return {
    displayName: !! v.displayName,
    ids:         !! v.ids,
    tags: {
      value: !! v.tags,
    },
    members: {
      displayName: !! v.members,
      ids:         !! v.members && !! v.ids,
    },
  }
}

function mapCourseFormValue (v: Partial<FormValue['courses']>): SearchKeys['courses'] {
  return {
    displayName:           !! v.displayName,
    ids:                   !! v.ids,
    tags: {
      value: !! v.tags,
    },
    subject:               !! v.subject,
    plannedDuration:       !! v.plannedDuration,
    /* TO BE DEPRECATED */ minutesPerWeek:        !! v.minutesPerWeek,
    /* TO BE DEPRECATED */ expectedTotalHours:    !! v.expectedTotalHours,
    eventDurationVariance: !! v.eventDurationVariation,
    groups: {
      to: {
        displayName: !! v.groups,
        ids:         !! v.groups && !! v.ids,
      }
    },
    teachers: {
      to: {
        displayName: !! v.teachers,
        ids:         !! v.teachers && !! v.ids,
      }
    },
    events: {
      displayName:       !! v.displayName,
      ids:               !! v.ids,
      preferredDuration: !! v.preferredDuration,
      durationVariance:  !! v.eventDurationVariation,
      groups: {
        to: {
          displayName: !! v.groups,
          ids:         !! v.groups && !! v.ids,
        }
      },
      teachers: {
        to: {
          displayName: !! v.teachers,
          ids:         !! v.teachers && !! v.ids,
        }
      }
    }
  }
}

function mapEventFormValue (v: Partial<FormValue['events']>): SearchKeys['events'] {
  return {
    displayName:       !! v.displayName,
    ids:               !! v.ids,
    tags: {
      value: !! v.tags,
    },
    preferredDuration: !! v.preferredDuration,
    groups: {
      to: {
        displayName: !! v.groups,
        ids:         !! v.groups && !! v.ids,
      }
    },
    teachers: {
      to: {
        displayName: !! v.teachers,
        ids:         !! v.teachers && !! v.ids,
      }
    },
    course: {
      displayName: !! v.course,
      ids:         !! v.course && !! v.ids,
      subject:     !! v.subject,   // course need not be enabled
      groups: {
        to: {
          displayName: !! v.groups,
          ids:         !! v.groups && !! v.ids,
        }
      },
      teachers: {
        to: {
          displayName: !! v.teachers,
          ids:         !! v.teachers && !! v.ids,
        }
      },
    }
  }
}


type Value = Parameters<
  typeof mapLocationFormValue |
  typeof mapPersonFormValue   |
  typeof mapTeacherFormValue  |
  typeof mapGroupFormValue    |
  typeof mapCourseFormValue   |
  typeof mapEventFormValue
>[0];
export function formValueToSearchKeys<C extends Collection> (collection: C, value: Value /* Partial<FormValue[C]> */) {
  let nested: Object = { }
  if      (collection === 'locations') nested = mapLocationFormValue(value);
  else if (collection === 'persons'  ) nested = mapPersonFormValue  (value);
  else if (collection === 'teachers' ) nested = mapTeacherFormValue (value);
  else if (collection === 'groups'   ) nested = mapGroupFormValue   (value);
  else if (collection === 'courses'  ) nested = mapCourseFormValue  (value);
  else if (collection === 'events'   ) nested = mapEventFormValue   (value);
  else {
    // should not reach here
    throw new Error(`Invalid collection: ${collection}`);
  }

  // flatten the object
  const flat = _.index(nested);

  // remove all falsy values and convert to array
  const arr = _(flat)
    .entries()
    .filter(x => !! x[1])
    .map(x => x[0])
    .value();

  return arr;
}


export const defaultFormValue: FormValue = {
  courses: {
    displayName:            true,
    ids:                    true,
    tags:                   true,
    subject:                true,
    plannedDuration:        true,
    /* TO BE DEPRECATED */ minutesPerWeek:         true,
    /* TO BE DEPRECATED */ expectedTotalHours:     true,
    eventDurationVariation: true,
    groups:                 true,
    teachers:               true,
    preferredDuration:      true,
  },
  groups: {
    displayName: true,
    ids:         true,
    tags:        true,
    members:     true,
  },
  teachers: {
    displayName: true,
    ids:         true,
    tags:        true,
  },
  locations: {
    displayName: true,
    ids:         true,
    tags:        true,
  },
  persons: {
    firstName:    true,
    lastName:     true,
    ids:          true,
    tags:         true,
    SSN:          true,
    emails:       true,
    phoneNumbers: true,
    group:        true,
  },
  events: {
    displayName:       true,
    ids:               true,
    tags:              true,
    preferredDuration: true,
    groups:            true,
    teachers:          true,
    course:            true,
    subject:           true,
  }
};

export const FromControlLabels: FormLabels = {
  courses: {
    displayName:            'attributes.shared.displayName',
    ids:                    'attributes.shared.ids',
    tags:                   'attributes.shared.tags',
    subject:                'attributes.shared.subject',
    plannedDuration:        'attributes.course.plannedDuration',
    /* TO BE DEPRECATED */ minutesPerWeek:         'attributes.course.minutesPerWeek',
    /* TO BE DEPRECATED */ expectedTotalHours:     'attributes.course.expectedTotalHours',
    eventDurationVariation: 'attributes.course.eventDurationVariation',
    groups:                 'attributes.shared.groups',
    teachers:               'attributes.shared.teachers',
    preferredDuration:      'attributes.event.preferredDuration',
  },
  groups: {
    displayName: 'attributes.shared.displayName',
    ids:         'attributes.shared.ids',
    tags:        'attributes.shared.tags',
    members:     'attributes.groups.members',
  },
  teachers: {
    displayName: 'attributes.shared.displayName',
    ids:         'attributes.shared.ids',
    tags:        'attributes.shared.tags',
  },
  locations: {
    displayName: 'attributes.shared.displayName',
    ids:         'attributes.shared.ids',
    tags:        'attributes.shared.tags',
  },
  persons: {
    firstName:    'attributes.person.firstName',
    lastName:     'attributes.person.lastName',
    ids:          'attributes.shared.ids',
    tags:         'attributes.shared.tags',
    SSN:          'attributes.person.SSN',
    emails:       'attributes.shared.emails',
    phoneNumbers: 'attributes.shared.phoneNumbers',
    group:        'attributes.person.group',
  },
  events: {
    displayName:       'attributes.shared.displayName',
    ids:               'attributes.shared.ids',
    tags:              'attributes.shared.tags',
    preferredDuration: 'attributes.event.preferredDuration',
    groups:            'attributes.shared.groups',
    teachers:          'attributes.shared.teachers',
    course:            'attributes.event.course',
    subject:           'attributes.shared.subject',
  }
}