import { Component,
         Input,
         Output,
         EventEmitter,
         ViewChildren,
         AfterViewInit,
         QueryList,
         DestroyRef,
         inject                          } from '@angular/core';
import { animate,
         style,
         transition,
         trigger                         } from '@angular/animations';
import { takeUntilDestroyed              } from '@angular/core/rxjs-interop';
import { FormControl                     } from '@angular/forms';
import { MatOption                       } from '@angular/material/core';
import { Subject,
         BehaviorSubject,
         of,
         combineLatest,
         merge                           } from 'rxjs';
import { takeUntil,
         filter,
         map,
         startWith,
         take,
         switchMap,
         distinctUntilChanged            } from 'rxjs/operators';
import _                                   from 'lodash';

import { Populated as P,
         Day as _Day,
         Week as _Week                   } from 'app/shared/interfaces';
import { Util                            } from "app/common/util";
import { EnvironmentService,
         TranslateService                } from 'app/core';
import { MatSelect                       } from 'app/common';


const collections = ['teachers', 'groups', 'locations', 'persons', 'days', 'weeks', 'courses'] as const;
type Collection = typeof collections[number];

export const all = 'all';
export type All = typeof all;

type Day = { is: 'day'; id: string; displayName: string; }
type Week = _Week & { is: 'week', displayName: string };
export type Entity = Week | Day | P.group | P.teacher | P.location | P.person | P.course;
export type BareEntity = { id: string };

type SelectElementData<C extends Collection> = {
  collection: C;
  multiselect?: boolean;
  /** @description whether the user can select no option, only relevant for single select */
  nullable?: boolean;
  /** @description options to be displayed in the header, only relevant for single select */
  headerOptions?: { value: All, name: string }[];
  label: {
    singular: string;
    plural:   string;
  };
  icon?:     string;
  width:    'narrow' | 'medium' | 'wide';
  panelWidth: 'auto' | null;
  readonly options: BehaviorSubject<Entity[]>;
  readonly ctrl: FormControl<null | BareEntity | string | (BareEntity | string)[]>;
};

export type Selection = {
  weeks?:     (BareEntity | string | All)[];
  days?:      (BareEntity | string | All)[];
  groups?:    (BareEntity | string | All)[];
  teachers?:  (BareEntity | string | All)[];
  locations?: (BareEntity | string | All)[];
  persons?:   (BareEntity | string | All)[];
  courses?:   (BareEntity | string | All)[];
};

@Component({
  selector: 'app-multiple-select-filter',
  templateUrl: './multiple-select-filter.component.html',
  styleUrls: ['./multiple-select-filter.component.scss'],
  animations: [
    trigger('inAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.5s ease-in', style({ opacity: 1 }))
      ])
    ])
  ]
})
export class MultipleSelectFilterComponent implements AfterViewInit {
  // inject destroy reference
  private readonly _destroyRef = inject(DestroyRef);

  @ViewChildren(MatSelect) readonly matSelects: QueryList<MatSelect>;

  // what collections to await for data in order to set loading to false
  private readonly onToBeAwaited = new BehaviorSubject<Set<BehaviorSubject<any> > >(new Set());
  protected readonly loading = new BehaviorSubject<boolean>(false);

  // used to indicate that all options are selected
  protected readonly all: All = all;

  // default values to be used if stored ones are not found
  private readonly _defaultSelection = new BehaviorSubject<Selection>({ });

  // if the user has modified the selection
  protected readonly userModified = new Subject<void>();


  protected readonly selectedTypeToken: {
    selected?: MatOption<Entity | All> | MatOption<Entity | All>[];
  };
  protected readonly optionTypeToken: {
    option: Entity;
  };

  // data used to render the select elements
  protected readonly data: { [C in Collection]: SelectElementData<C> };
  protected readonly dataArray: SelectElementData<Collection>[];

  private readonly _opened$ = new BehaviorSubject(false);
  public get opened$ () { return this._opened$.asObservable(); }

  constructor (
    private _translate: TranslateService,
    private _env:       EnvironmentService
  ) {
    const { collections: envc } = this._env.getState() ?? { };

    this.data = {
      weeks: {
        collection: 'weeks',
        multiselect: false,
        nullable: false,
        headerOptions: [
          { value: all, name: this._translate.instant('common.all') }
        ],
        label: {
          singular: 'common.week',
          plural:   'common.weeks'
        },
        icon:    'date_range',
        width:   'medium',
        panelWidth: null,
        options: new BehaviorSubject<Week[]>([]),
        ctrl: new FormControl([], { nonNullable: true })
      },
      days: {
        collection: 'days',
        multiselect: false,
        nullable: false,
        label: {
          singular: 'common.day',
          plural:   'common.days'
        },
        icon:    'today',
        width:   'medium',
        panelWidth: 'auto',
        options: new BehaviorSubject<Day[]>([]),
        ctrl: new FormControl([], { nonNullable: true })
      },
      courses: {
        collection: 'courses',
        multiselect: true,
        label: {
          singular: 'common.course',
          plural:   'common.courses'
        },
        icon: envc.courses?.icon ?? 'question_mark',
        width:   'wide',
        panelWidth: 'auto',
        options: new BehaviorSubject<P.course[]>([]),
        ctrl: new FormControl([], { nonNullable: true })
      },
      groups: {
        collection: 'groups',
        multiselect: true,
        label: {
          singular: 'common.group',
          plural:   'common.groups'
        },
        icon: envc.groups?.icon ?? 'question_mark',
        width:   'wide',
        panelWidth: 'auto',
        options: new BehaviorSubject<P.group[]>([]),
        ctrl: new FormControl([], { nonNullable: true })
      },
      teachers: {
        collection: 'teachers',
        multiselect: true,
        label: {
          singular: 'common.teacher',
          plural:   'common.teachers'
        },
        icon: envc.teachers?.icon ?? 'question_mark',
        width:   'wide',
        panelWidth: 'auto',
        options: new BehaviorSubject<P.teacher[]>([]),
        ctrl: new FormControl([], { nonNullable: true })
      },
      locations: {
        collection: 'locations',
        multiselect: true,
        label: {
          singular: 'common.location',
          plural:   'common.locations'
        },
        icon: envc.locations?.icon ?? 'question_mark',
        width:   'wide',
        panelWidth: 'auto',
        options: new BehaviorSubject<P.location[]>([]),
        ctrl: new FormControl([], { nonNullable: true })
      },
      persons: {
        collection: 'persons',
        multiselect: true,
        label: {
          singular: 'common.person',
          plural:   'common.persons'
        },
        icon: envc.persons?.icon ?? 'question_mark',
        width:   'wide',
        panelWidth: 'auto',
        options: new BehaviorSubject<P.person[]>([]),
        ctrl: new FormControl([], { nonNullable: true })
      }
    };

    this.dataArray = Object.values(this.data);

    // emit selected values
    combineLatest(
      Object.fromEntries(
        Util.functions
        .objectKeyVals(this.data)
        .map(({ key, val }) => ([
          key,
          val.ctrl.valueChanges.pipe(
            startWith(val.ctrl.value),
            map(x => (Array.isArray(x) ? x : (x == null ? [] : [x]))
              .map(x => typeof x == 'string' ? x : { id: x.id })
            )
          )
        ]))
      )
    )
    .pipe(takeUntilDestroyed())
    .subscribe((x: Selection) => {
      //
      const selection: Selection = { };
      Util.functions.objectEntries(x)
      .forEach(([ collection, value ]) => {
        // omit empty selections
        if ( ! value.length) return;

        if (this.data[collection].multiselect) {
          if (value.includes(all)) selection[collection] = [all];
          else                     selection[collection] = value;
        } else {
          selection[collection] = value;
        }
      });

      // emit
      {
        // first we need to replace the multiselect "all" option with the actual options
        const outSelection = structuredClone(selection);
        Util.functions.objectEntries(x)
        .forEach(([ collection, value ]) => {
          if (this.data[collection].multiselect && value[0] == all) {
            outSelection[collection] = this.data[collection].options.value.map(x => ({ id: x.id }));
          }
        });
        this.onSelected.emit(outSelection);
      }

      // store in session storage
      if (this._sessionStorageKey.value) {
        window.sessionStorage.setItem(this._sessionStorageKey.value, JSON.stringify(selection));
      }
      if (this._writeOnlySessionStorageKey.value) {
        window.sessionStorage.setItem(this._writeOnlySessionStorageKey.value, JSON.stringify(selection));
      }
    });


    // update selected values based on changes to the the options
    merge(
      ...Object.values(this.data)
      .map(val => combineLatest({
        collection: of(val.collection),
        options:    val.options,
      }))
    )
    .pipe(
      takeUntilDestroyed(),
    )
    .subscribe(({ collection, options }) => {
      // if some selected alternatives have been deleted, remove them from the selection
      {
        const selected = this.data[collection].ctrl.value;
        if (Array.isArray(selected)) {
          const _options = [...options, all];
          const persisting = _options.filter(x => selected.some(y => this.compareWithFn(x, y)));
          if (persisting.length != selected.length) {
            this.data[collection].ctrl.setValue(persisting);
          }
        } else if (selected) {
          const additional = (this.data[collection].headerOptions ?? []).map(x => x.value as string);
          const _options = [...options, ...additional];
          const persisting = _options.some(y => this.compareWithFn(selected, y));
          if ( ! persisting) this.data[collection].ctrl.setValue(null);
        }
      }

      // is multiple select
      {
        const selected = this.data[collection].ctrl.value;
        if (Array.isArray(selected)) {
          // maintain all selected if the "all" option is selected
          if (selected.includes(all)) {
            if ( ! options.every(x => selected.some(y => this.compareWithFn(x, y)))) {
              this.data[collection].ctrl.setValue([all, ...options]);
            }
          }
        }
      }
    });


    // initially load values from session storage and use default values
    combineLatest({
      storedValue: this._sessionStorageKey.pipe(
        filter(Boolean),
        take(1),
        map(x => {
          const item = window.sessionStorage.getItem(x);
          if ( ! item) return { };
          try {
            const parsed = JSON.parse(item);
            if (parsed && typeof parsed == 'object') return parsed;
            return { };
          } catch {
            return { };
          }
        }),
        startWith({ })
      ),
      defaultValue: this._defaultSelection.pipe(
        takeUntil(this.userModified)
      )
    })
    .pipe(takeUntilDestroyed())
    .subscribe(({ storedValue, defaultValue }) => {
      // override default values with the stored values
      const selection = Object.assign({ }, defaultValue, storedValue);

      Util.functions.objectEntries(selection)
      .forEach(([ collection, value ]) => {
        // ensure that the collection is valid
        if ( ! this.isCollection(collection)) return;

        if (this.data[collection].multiselect) {
            // "all" is currently the only string option in multiselect
          if (value.includes('all')) this.tryToggleAll(collection, all, true);
          else this.data[collection].ctrl.setValue(value);
        } else {
          this.data[collection].ctrl.setValue(value[0]);
        }
      });
    });


    // listen to loading status
    this.onToBeAwaited
    .pipe(
      takeUntilDestroyed(),
      map(x => x.size != 0),
    )
    .subscribe(this.loading);
  }

  ngAfterViewInit () {
    // listen to changes in the select open state
    this.matSelects.changes
    .pipe(
      startWith(null),
      map(() => this.matSelects.toArray()),
      switchMap(selects => selects.length == 0
        ? of([])
        : combineLatest(selects.map(x => x.openedChange.pipe(startWith(x.panelOpen))))
      ),
      map(panelsOpen => panelsOpen.some(Boolean)),
      distinctUntilChanged(),
      takeUntilDestroyed(this._destroyRef)
    )
    .subscribe(x => {
      this._opened$.next(x);
      this.onOpened.emit(x);
    });
  }


  protected tryToggleAll (collection: Collection, val: Entity | All, isSelected: boolean) {
    // must be multiselect
    const selected = this.data[collection].ctrl.value;
    if ( ! Array.isArray(selected)) return;

    const options = this.data[collection].options.value;
    const ctrl    = this.data[collection].ctrl;

    if (val == all) {
      if (isSelected) ctrl.setValue([all, ...options]);
      else            ctrl.setValue([]);
    }
    else {
      // update selected state of "all" option
      const allIsChecked = selected.includes(all);
      const numSelected  = selected.length - (allIsChecked ? 1 : 0);
      if      ( ! allIsChecked && numSelected >= options.length) ctrl.setValue([all, ...options]);
      else if (   allIsChecked && numSelected <  options.length) ctrl.setValue(selected.filter(x => x !== all));
    }
  }

  protected compareWithFn (optionValue: Entity | string | null, selectedValue: Entity | string | null) {
    if (       optionValue == null     ||        selectedValue == null    ) return optionValue    == selectedValue;
    if (typeof optionValue == 'object' && typeof selectedValue == 'object') return optionValue.id == selectedValue.id;
    if (typeof optionValue == 'string' && typeof selectedValue == 'string') return optionValue    == selectedValue;
    return false;
  }

  protected isArray (x: unknown): x is unknown[] {
    return Array.isArray(x);
  }
  protected isAll (x: unknown): x is All {
    return typeof x == 'string' && x === all;
  }
  protected isWeek (x: unknown): x is Week {
    return !! x && typeof x == 'object' && 'is' in x && x.is === 'week';
  }
  protected isDay (x: unknown): x is Day {
    return !! x && typeof x == 'object' && 'is' in x && x.is === 'day';
  }
  protected displayName (x: undefined | Entity | string) {
    return typeof x == 'object' ? x.displayName : x
  }
  protected isSelected (selected: unknown | unknown[]) {
    if (Array.isArray(selected)) return selected.length != 0;
    return selected != null;
  }

  private isCollection (x: unknown): x is Collection {
    return collections.includes(x as Collection);
  }


  ////
  //// IO
  ////
  @Input() set weeks     (val: _Week[]      | 'skip' | 'await') { this.mapInput('weeks',     val, false); }
  @Input() set days      (val: _Day[]       | 'skip' | 'await') { this.mapInput('days',      val, false); }
  @Input() set groups    (val: P.group[]    | 'skip' | 'await') { this.mapInput('groups',    val, true ); }
  @Input() set teachers  (val: P.teacher[]  | 'skip' | 'await') { this.mapInput('teachers',  val, true ); }
  @Input() set locations (val: P.location[] | 'skip' | 'await') { this.mapInput('locations', val, true ); }
  @Input() set persons   (val: P.person[]   | 'skip' | 'await') { this.mapInput('persons',   val, true ); }
  @Input() set courses   (val: P.course[]   | 'skip' | 'await') { this.mapInput('courses',   val, true ); }

  private await (bh: BehaviorSubject<any>) {
    this.onToBeAwaited.value.add(bh);
    this.onToBeAwaited.next(this.onToBeAwaited.value);
  }
  private awaited (bh: BehaviorSubject<any>) {
    this.onToBeAwaited.value.delete(bh);
    this.onToBeAwaited.next(this.onToBeAwaited.value);
  }



  private mapInput<T extends Exclude<Entity, Day | Week> | _Day | _Week> (
    collection: Collection,
    val:        T[] | 'skip' | 'await',
    sort:       boolean
  ) {
    if (val === 'skip') return;
    if (val === 'await') {
      this.await(this.data[collection].options);
      return;
    }

    // map
    let entities: Entity[];
    if (collection === 'days') {
      entities = (val as _Day[]).map(x => ({
        is:          'day',
        id:          x.day.toString(),
        displayName: x.day.toString(),
      }));
    } else if (collection === 'weeks') {
      entities = (val as _Week[]).map(x => ({
        ...x,
        is:          'week',
        displayName: x.id
      }));
    }
    else {
      entities = val as Exclude<Entity, Day | Week>[];
    }
    // attempt to sort
    if (sort) entities.sort((a, b) => (a.displayName ?? '').localeCompare(b.displayName ?? ''));

    this.data[collection].options.next(entities as any);


    // we are done loading the particular collection
    this.awaited(this.data[collection].options);
  }

  @Input()
  set defaultWeeksValue (val: null | undefined | string | string[]) {
    if (val == null) return;
    const prev = this._defaultSelection.value;
    const weeks = (_.isArray(val) ? val : [val]).map(x => ({ id: x }));
    this._defaultSelection.next({ ...prev, weeks });
  };

  @Input()
  set defaultDaysValue (val: null | undefined | number | number[]) {
    if (val == null) return;
    const prev = this._defaultSelection.value;
    const days = (_.isArray(val) ? val : [val]).map(x => ({ id: x.toString() }));
    this._defaultSelection.next({ ...prev, days });
  };

  @Input()
  set sessionStorageKey (val: string) { this._sessionStorageKey.next(val); }
  private _sessionStorageKey = new BehaviorSubject<string>('');


  /**
   * @description The purpose of the write only session storage is to possibly overwrite an out of
   * data structure that would give rise to a runtime error. Give the "sessionStorageKey" a temporary value
   * and set "writeOnlySessionStorageKey" to its previous value. Later when the data is updated, the
   * "sessionStorageKey" can be returned to its original value.
   */
  @Input()
  set writeOnlySessionStorageKey (val: string) { this._writeOnlySessionStorageKey.next(val); }
  private _writeOnlySessionStorageKey = new BehaviorSubject<string>('');

  @Output() onSelected = new EventEmitter<Selection>();

  @Output() onOpened = new EventEmitter<boolean>();
}