<h1 mat-dialog-title class="header">Debug</h1>

<mat-dialog-content>
  <button mat-flat-button
          (click)="terminate()"
          color="warn">
    terminate
  </button>

  <!-- <div>
    <mat-radio-group [(ngModel)]="state" (ngModelChange)="stateChange($event)">
      <mat-radio-button value="Legacy">Legacy</mat-radio-button>
      <mat-radio-button value="Coalesced">Coalesced</mat-radio-button>
    </mat-radio-group>
  </div> -->

  <div mat-dialog-subtitle>Threads</div>
  <mat-divider></mat-divider>
  <p>{{ thread.numThreads }}</p>


  <div mat-dialog-subtitle>errors</div>

  <mat-divider></mat-divider>

  <div>
    <div *ngFor="let division of errors">
      <div mat-subheader>Schedule: {{ division.division }}</div>
      @for(collection of division.collections; track $index) {
        <div>
          @for(item of collection.errors; track $index) {
            <fieldset class="error border-radius padding margin-bottom">
              <legend>{{ ('common.' + collection.collection) | translate }}</legend>
              <div class="toolbar flex flex-justify-space-between flex-align-center padding-bottom">
                @if(item._original.firstName) {
                  <div>
                    {{ item._original.firstName }}
                    {{ item._original.lastName }}
                  </div>
                } @else {
                  <div>{{ item._original.displayName ?? item._original.id }}</div>
                }

                <button mat-icon-button
                        (click)="toggleEditor(item)">
                  <mat-icon>{{ item.code ? 'code_off' : 'code' }}</mat-icon>
                </button>
              </div>

              @for(detail of item.details; track $index) {
                <div class="detail border-radius"
                     [class.margin-bottom]="! item.code"
                     [class.padding-bottom-16]="! detail.minimized || item.code">
                  <div class="flex flex-justify-space-between flex-align-center margin-left">
                   <div class="title royal-text-secondary">{{ detail.message }}</div>

                    @if(! item.code) {
                      <button mat-icon-button
                              (click)="detail.minimized = !detail.minimized">
                        <mat-icon>{{ detail.minimized ? 'keyboard_arrow_down' : 'keyboard_arrow_up' }}</mat-icon>
                      </button>
                    }
                  </div>

                  @if(! detail.minimized && ! item.code) {
                    <mat-list class="margin-inline">
                      <!-- <mat-list-item>
                        <mat-icon matListItemIcon>description</mat-icon>
                        <div class="royal-text-secondary">{{ detail.message }}</div>
                      </mat-list-item> -->
                      <!-- <mat-list-item>
                        <mat-icon matListItemIcon>warning</mat-icon>
                        {{ detail.context.label }}
                      </mat-list-item> -->

                      @if(detail.context?.value) {
                        <mat-list-item>
                          <mat-icon matListItemIcon>warning</mat-icon>
                          <div class="royal-text-secondary">{{ detail.context?.value }}</div>
                        </mat-list-item>
                      }

                      @if(detail.context?.valids) {
                        <mat-list-item>
                          <mat-icon matListItemIcon>edit</mat-icon>
                          <mat-select class="input" [(ngModel)]="detail.modifiedValue" [placeholder]="'common.select' | translate">
                            @for(valid of detail.context?.valids; track $index) {
                              <mat-option [value]="valid">{{ valid ?? (valid | json) }}</mat-option>
                            }
                        </mat-select>
                        </mat-list-item>
                      } @else {
                        <mat-list-item>
                          <mat-icon matListItemIcon>edit</mat-icon>
                          <input matInput [placeholder]="detail.context?.value" class="input" [(ngModel)]="detail.modifiedValue"/>
                        </mat-list-item>
                      }

                      @if(detail.path.length > 1) {
                        <mat-list-item>
                          <mat-icon matListItemIcon>backspace</mat-icon>
                          <mat-button-toggle-group [(ngModel)]="detail.cutPath" value="modify">
                            <mat-button-toggle value="modify" selected>None</mat-button-toggle>
                            @for(path of detail.path; track $index) {
                              <mat-button-toggle [value]="$index">{{ path }}</mat-button-toggle>
                            }
                          </mat-button-toggle-group>
                        </mat-list-item>
                      }
                    </mat-list>

                    <div class="context mat-elevation-z2 border-radius-16 padding-inline margin-block-8 margin-inline flex">
                      <pre class="flex-1">{{ item._original | validationError:detail | json }}</pre>
                      <!-- create an svg that is a arrow to the right-->
                      <svg preserveAspectRatio="none" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 51.388 51.388" xml:space="preserve"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <g> <path d="M9.169,51.388c-0.351,0-0.701-0.157-0.93-0.463c-0.388-0.514-0.288-1.243,0.227-1.634l31.066-23.598L8.461,2.098 C7.95,1.708,7.85,0.977,8.237,0.463c0.395-0.517,1.126-0.615,1.64-0.225l33.51,25.456L9.877,51.151 C9.664,51.31,9.415,51.388,9.169,51.388z"></path> </g> </g> </g></svg>
                      <pre class="flex-1">{{ item._original | validationResolve:detail:detail.modifiedValue:detail.cutPath | json }}</pre>
                    </div>
                    }

                  <!-- @for(context of detail.context?.details; track $index) {
                    <div class="context mat-elevation-z2 border-radius-16 padding-inline margin-block-8">
                      <div>{{ context.message }}</div>
                      <div>{{ context.path }}</div>
                      <pre>{{ context.context | json }}</pre>
                    </div>
                  } -->
                </div>
              }

              @if (item.code && item.options) {
                <json-editor class="margin-block padding-8 white-bg"
                             [options]="item.options"
                             [data]="item._original"
                             (change)="item.json = $event">
                </json-editor>
              }

              <div class="flex flex-justify-end">
                <button mat-button
                        color="warn"
                        (click)="resolve(item, division.division, collection.collection)"
                        class="resolve">
                  Resolve
                </button>
              </div>
            </fieldset>
          }
        </div>
      }
    </div>
  </div>

  <!-- <div mat-dialog-subtitle>Source</div>
  <mat-list>
    <div mat-subheader>Divisions</div>
    <div *ngFor="let division of source">
      {{division.division}}
          <div mat-subheader>Collections</div>
      <mat-list-item *ngFor="let collection of division.collections">
        {{collection.collection}} {{collection.numDocs}}
      </mat-list-item>
    </div>
  </mat-list>

  <div mat-dialog-subtitle>References</div>

  <mat-divider></mat-divider>

  <mat-list>
    <div mat-subheader>Divisions</div>
    <div *ngFor="let division of references">
      {{division.division}}
    <div mat-subheader>Collections</div>
      <mat-list-item *ngFor="let collection of division.collections">
        {{collection.collection}} {{collection.numDocs}}
      </mat-list-item>
    </div>
  </mat-list> -->
</mat-dialog-content>

<mat-divider></mat-divider>

<mat-dialog-actions class="actions-content">
  <button mat-button
          mat-dialog-close
          class="cancel">
    {{ 'common.cancel' | translate}}
  </button>
</mat-dialog-actions>