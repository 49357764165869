<!-- force rerender on stateChanges.next() otherwise it is not guaranteed to show the latest values due to some lazy rendering-->
{{ stateChanges | async }}

<div class="time-form-field-select clickable"
     (click)="disabled ? $event.stopPropagation(): null"
     [class.time-form-field-disabled]="disabled"
     (menuClosed)="closed()"
     (menuOpened)="opened()"
     [matMenuTriggerFor]="menu">
     {{ displayValue }}
</div>

<mat-menu yPosition="below"
          #menu="matMenu"
          class="time-panel no-vertical-padding"
          [overlapTrigger]="true">
  <ng-template matMenuContent>

    <div (click)="$event.stopPropagation()" class="container">

      <div class="time-content">
        <input matInput id="input"
          (keydown.enter)="trigger?.closeMenu()"
          [cleave]="cleave"
          [formControl]="timeStrControl"
        >
      </div>

      <mat-action-list #hoursList class="hours-content content dark-scrollbar">
        <ng-container *ngFor="let hour of hours">
          <button mat-list-item
                  *ngIf=" ! hour.invalid"
                  [class.selected]="hour.selected"
                  (click)="setHours(hour.value)">
            {{ hour.value }}
          </button>
        </ng-container>
      </mat-action-list>

      <mat-action-list #minutesList class="minutes-content content dark-scrollbar">
        <ng-container *ngFor="let minute of minutes">
          <button mat-list-item
                  *ngIf=" ! minute.invalid"
                  [class.selected]="minute.selected"
                  (click)="setMinutes(minute.value)">
            {{ minute.value }}
          </button>
        </ng-container>
      </mat-action-list>
    </div>
  </ng-template>

</mat-menu>
