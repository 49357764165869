import { commonConstants                   } from 'app/constants/common';

export const defaultColors = [
  commonConstants.COLORS.EVENT_DEFAULT, '#8A00A1', '#5322A8', '#313AA8',
  '#16AFCC', '#1497F2', '#1E7EEC', '#118575',
  '#40A53E', '#7BBA39', '#C2D92C', '#EDD92C',
  '#FFB60B', '#FD8603', '#FE3E1B', '#DD004F',
  '#ec407a', '#8C8C8C', '#4E6A78', '#644338'
] as const;

export const sduiColors = [
  commonConstants.COLORS.EVENT_DEFAULT,
  '#711FFF', //purple
  '#2596F8', //blue
  '#39D3E5', //turquise
  '#3BE6B3', //blue-green
  '#7BCF51', //green
  '#FCC623', //yellow
  '#FF8F1F', //orange
  // '#802D0D', //orange-dark NOT
  // '#E53B3B', //red NOT
  '#800D0D', //red-dark,
  '#FC216A', //pink
  '#B849D4', //violet
  '#A68575'  //brown
] as const;