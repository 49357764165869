import { NgModule                        } from '@angular/core';
import { CommonModule                    } from '@angular/common';
import { FormsModule,
         ReactiveFormsModule             } from '@angular/forms';

import { TranslationModule               } from 'app/core/translate/translate.module';

import { MatInputModule                 } from '@angular/material/input';

import { AppCommonModule                 } from 'app/common/common.module';

import { ComponentsModule                } from 'app/shared/components/components.module';
import { PipesModule                     } from '@shared/pipes/pipes.module';
import { NgPipesModule                   } from 'ngx-pipes';

import { TimeFormFieldModule             } from 'app/shared/form-fields/time/time.module';
import { DurationWithVariationModule     } from '../duration-with-variation/duration-with-variation.module';

import { ComponentsModule as SubModule   } from './components/components.module';

import { DynamicLockedTimesComponent     } from './dynamic-locked-times.component';
import { DirectivesModule                } from '@app/shared/directives/directives.module';


@NgModule({
  declarations: [
    DynamicLockedTimesComponent
  ],
  exports: [
    DynamicLockedTimesComponent,
    SubModule
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    TranslationModule,
    FormsModule,
    SubModule,
    PipesModule,
    TimeFormFieldModule,
    DurationWithVariationModule,
    ComponentsModule,
    ReactiveFormsModule,
    NgPipesModule,
    DirectivesModule,
    MatInputModule
  ]
})
export class DynamicLockedTimesFormFieldModule { }
