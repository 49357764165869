import { Component,
         OnInit,
         ViewChild,
         Optional,
         Inject,
         DestroyRef,
         inject} from '@angular/core';
import { Observable                      } from 'rxjs';
import { take                            } from 'rxjs/operators';
import { MatDialogRef,
         MAT_DIALOG_DATA,
         MatTabGroup                     } from 'app/common';

import { SubmitPaneComponent             } from 'app/shared/components';
import { DivisionSettings,
         Populated as P,                 } from 'app/shared/interfaces';
import { CourseComponent                 } from 'app/shared/forms/course/course.component';
import { EventComponent                  } from '@app/shared/forms/event/event.component';
import { SourceService,
         sourceSelectPipe                } from 'app/core';
import { inOutAnimation                  } from '@app/shared/animations';

export type Data = {
  did:      string;
  courseId: string;
  eventId:  string;
}

@Component({
  selector: 'app-edit-course-and-event',
  templateUrl: './edit-course-and-event.component.html',
  styleUrls: ['./edit-course-and-event.component.scss'],
  providers: [ SourceService ],
  animations: [ inOutAnimation ]
})
export class EditCourseAndEventComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);
  @ViewChild(MatTabGroup, { static: true }) tabGroup:           MatTabGroup;
  @ViewChild(SubmitPaneComponent, { static: true }) submitPane: SubmitPaneComponent;
  @ViewChild(CourseComponent) courseForm:                       CourseComponent;
  @ViewChild(EventComponent) courseEventForm:             EventComponent;
  protected settings:  Observable<DivisionSettings>;
  protected groups:    Observable<P.group[]  | null>;
  protected persons:   Observable<P.person[]  | null>;
  protected teachers:  Observable<P.teacher[]  | null>;
  protected locations: Observable<P.location[]  | null>;
  protected events:    Observable<P.event[]  | null>;
  protected periods:   Observable<P.period[]  | null>;
  public did:                   string;
  public courseId:              string;
  public eventId:         string;
  public submitted:             boolean;
  public loading:               boolean           = true;
  private _pristineCourseValue: P.course;
  private _pristineEventValue:  P.event;

  constructor (
    public dialogRef: MatDialogRef<EditCourseAndEventComponent>,
    private _source:  SourceService,
    @Optional() @Inject(MAT_DIALOG_DATA) _data: Data
  ) {
    this.courseId = _data.courseId;
    this.eventId  = _data.eventId;
    this.did      = _data.did;
  }

  ngOnInit() {
    this.submitPane.setLoadingState();

    this._source.groupBy({
      collections: [
        'courses',
        'events',
        'groups',
        'persons',
        'teachers',
        'locations',
        'settings'
        ,
      ],
      did: this.did
    })
    .then(() => {
      this.settings  = this._source.getStrictSettings    ({ did: this.did, onDestroy: this.destroyRef });
      this.locations = this._source.getPopulatedLocations({ did: this.did, onDestroy: this.destroyRef });
      this.periods   = this._source.getPopulatedPeriods  ({ did: this.did, onDestroy: this.destroyRef });

      this._source.getPopulatedCourses({ did: this.did, onDestroy: this.destroyRef }, sourceSelectPipe({ id: this.courseId }))
      .pipe(take(1))
      .subscribe(([course, ..._]) => {
        this.courseForm.value     = course;
        this._pristineCourseValue = course;
      });

      this._source.getPopulatedEvents({ did: this.did, onDestroy: this.destroyRef }, sourceSelectPipe({ id: this.eventId }))
      .pipe(take(1))
      .subscribe(([event, ..._]) => {
        this.courseEventForm.value = event;
        this._pristineEventValue   = event;
      });

      this.loading = false;
      this.submitPane?.setDefaultState();
    });
  }

  get valid(): boolean {
    return this.courseForm?.valid && this.courseEventForm?.valid;
  }

  get pristine(): boolean {
    const _pristine: boolean | undefined = this.courseForm?.pristine && this.courseEventForm?.pristine;
    this.dialogRef.disableClose = ! _pristine;
    return _pristine == undefined ? true : _pristine;
  }

  public resetValue(): void {
    this.courseForm.value      = this._pristineCourseValue;
    this.courseEventForm.value = this._pristineEventValue;
  }

  public submit (event: Event) {
    // ensure that the for is not submitted twice by the same event (keydown and click)
    event.preventDefault();

    if (! this.courseForm.pristine)
      this._source.set({ collection: 'courses', did: this.did }, { ...this.courseForm.value, id: this.courseId });
    if (! this.courseEventForm.pristine)
      this._source.set({ collection: 'events', did: this.did }, { ...this.courseEventForm.value, id: this.eventId });

    this.dialogRef.close();
  }
}