import { Component,
         Input                           } from '@angular/core';
import { takeUntilDestroyed              } from '@angular/core/rxjs-interop';
import { FormControl                     } from '@angular/forms';
import { BehaviorSubject,
         combineLatest,
         filter                          } from 'rxjs';
import { CoreTypes                       } from '@royalschedule/maps';

import { EnvironmentService,
         LoggerService,
         TranslateService                } from 'app/core';
import { Util                            } from 'app/common';
import { ParsedFile                      } from 'app/shared/services';
import { PartialDivision                 } from '../../upload-file.component';
import { FormData as DivisionFormData    } from '../configure/configure.component';
import { SelectedMap                     } from '../select-map/select-map.component';
import { translateMessages               } from '@app/shared/services/excel-template/functions';


export type OutputDivision = PartialDivision & CoreTypes.V2.division;
type Options               = DivisionFormData['options'];
@Component({
  selector: 'app-finalize',
  templateUrl: './finalize.component.html',
  styleUrls: ['./finalize.component.scss']
})
export class FinalizeComponent {
  protected mapped   = new BehaviorSubject<OutputDivision | null>(null);
  protected errors   = new BehaviorSubject<(string | { code: string; context?: any; })[]>([]);
  protected warnings = new BehaviorSubject<(string | { code: string; context?: any; })[]>([]);

  private allCollections: { collection: Util.Types.Collection, base: string }[] = [
    { collection: 'settings',      base: 'settings' },
    { collection: 'periods',       base: 'period' },
    { collection: 'rootIntervals', base: 'rootInterval' },
    { collection: 'groups',        base: 'group' },
    { collection: 'teachers',      base: 'teacher' },
    { collection: 'persons',       base: 'person' },
    { collection: 'locations',     base: 'location' },
    { collection: 'courses',       base: 'course' },
    { collection: 'overlapGroups', base: 'overlapGroup' },
    { collection: 'lockedTimes',   base: 'lockedTime' },
    { collection: 'events',        base: 'event' },
  ]
  protected readonly collections = this.allCollections.filter(x => this.environment.hasCollection(x.collection));

  protected readonly ctrl = new FormControl<Util.Types.Collection[]>([]);

  protected readonly loading = new BehaviorSubject(true);

  public readonly error        = new BehaviorSubject<Boolean>(false);
  public readonly output       = new BehaviorSubject<OutputDivision | null>(null);
  public readonly referenceKey = new BehaviorSubject<'id' | 'ids'>('id');

  constructor (
    protected environment: EnvironmentService,
    private _logger:       LoggerService,
    private _translate:    TranslateService
  ) {

    combineLatest({
      active:   this._active,
      file:     this._file,
      map:      this._map,
      division: this._division,
      options:  this._options
    })
    .pipe(
      takeUntilDestroyed(),
      filter(x => !! x.active)   // the mapping is expensive and should not be done when typing the schedule name etc in another tab
    )
    .subscribe(({ file, map, division, options }) => {
      // must be set
      if ( ! file || ! map || ! division) return;

      // try perform map
      this.loading.next(true);
      try {
        const out = map.map.from.schedules(file.content, options as any) as OutputDivision;
        console.log('out', out)
        // append name and period of the division
        this.mapped.next(Object.assign(out, division));

        // what type of reference key that should be used
        // use foreign id (ids) unless we are using out own structure
        let refKey: 'id' | 'ids' = 'ids'
        if      (map.name == 'Royal Schedule'                   && file.type == 'json') refKey = 'id';
        else if (map.name == 'Royal Schedule Excel'             && file.type == 'xlsx') refKey = 'id';
        else if (/Royal Schedule Algorithm v\d+/.test(map.name) && file.type == 'json') refKey = 'id';
        this.referenceKey.next(refKey);

        if (map.name == 'Royal Schedule Excel') {
          // fetch + translate errors and warnings
          this.errors  .next('meta' in out && out.meta?.errors   ? translateMessages(out.meta.errors ,  this._translate) ?? [] : []);
          this.warnings.next('meta' in out && out.meta?.warnings ? translateMessages(out.meta.warnings, this._translate) ?? [] : []);
        } else {
          // fetch errors and warnings
          this.errors  .next('meta' in out ? out.meta?.errors   ?? [] : []);
          this.warnings.next('meta' in out ? out.meta?.warnings ?? [] : []);
        }

        // log raw
        if (this.errors  .value?.length) console.log('errors',   this.errors  .value);
        if (this.warnings.value?.length) console.log('warnings', this.warnings.value);

        this.loading.next(false);
        this.error.next(false);
      } catch (err) {
        this.mapped.next(null);
        this._logger.error(err);

        this.loading.next(false);
        this.error.next(true);
      }
    });

    // set selected collections to those that exist and are not empty
    this.mapped
    .pipe(takeUntilDestroyed())
    .subscribe(data => {
      if ( ! data) return;

      const collections = this.allCollections
      .map(x => x.collection)
      .filter(x => {
        const val = x in data ? (data as any)[x] as unknown : null;
        if ( ! val) return false;
        if (Array.isArray(val)) return val.length > 0;
        if (typeof val === 'object') return Object.keys(val).length > 0;
        return false
      });

      this.ctrl.setValue(collections);
    });

    // compute output
    combineLatest({
      collections: this.ctrl.valueChanges,
      mapped:      this.mapped
    })
    .pipe(takeUntilDestroyed())
    .subscribe(({ collections, mapped }) => {
      if ( ! mapped || ! collections) {
        this.output.next(null);
        return;
      }

      const out = {...mapped};
      for (const x of this.allCollections) {
        if (collections.includes(x.collection)) continue;
        delete (out as any)[x.collection];
      }

      this.output.next(out);
    });

  }


  protected lengthOf (val: any, key: string): number {
    const collection = val && key in val ? val[key] : null;
    if ( ! collection) return 0;
    if (Array.isArray(collection)) return collection.length;
    if (typeof collection === 'object') return Object.keys(collection).length;
    return 0;
  }

  @Input()
  set file (value: ParsedFile | null) {
    this._file.next(value ?? null);
  }
  private _file = new BehaviorSubject<ParsedFile | null>(null);

  @Input()
  set map(value: SelectedMap | null | undefined) {
    this._map.next(value ?? null);
  }
  private _map = new BehaviorSubject<SelectedMap | null>(null);

  @Input()
  set division (value: PartialDivision | null) {
    this._division.next(value ?? null);
  }
  protected _division = new BehaviorSubject<PartialDivision | null>(null);

  @Input()
  set options (value: Options | null) {
    this._options.next(value ?? null);
  }
  private _options = new BehaviorSubject<Options | null>(null);

  @Input()
  set active (value: boolean) {
    this._active.next(value);
  }
  private _active = new BehaviorSubject<boolean>(false);
}
