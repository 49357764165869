
import { Observable,
         combineLatest                   } from 'rxjs';
import { filter,
         map                             } from 'rxjs/operators';
import { extendMoment                    } from 'moment-range';
import _                                   from 'lodash';
import _moment                             from 'moment';

import { Populated as P,
         DivisionSettings,
         Division,
         Week,
         MomentInterval,                 } from 'app/shared/interfaces';

const moment = extendMoment(_moment);


type MomentRange = { start: moment.Moment; end: moment.Moment };

export function getWeekRange (date: string | moment.Moment | Date) {
  return moment.range(
    moment.utc(date).clone().startOf('isoWeek').startOf('day'),
    moment.utc(date).clone().endOf  ('isoWeek').endOf  ('day')
  );
}

export function listIncludedWeeks ({ start, end }: MomentRange): Omit<Week, 'excludedDays'>[] {
  // get range of weeks
  const startWeek = getWeekRange(start);
  const endWeek   = getWeekRange(end);

  // store all weeks
  const weeks: Omit<Week, 'excludedDays'>[] = [];
  let itr = startWeek.start.clone();
  while (itr.isBefore(endWeek.end)) {
    const range = getWeekRange(itr);
    weeks.push({
      id:     itr.format('YYYY-MM-DD'),
      range:  range,
      number: range.start.week(),
      string: range.start.format('YYYY-MM-DD') + ' - ' + range.end.format('YYYY-MM-DD'),
    });
    itr.add(7, 'days');
  }

  return weeks;
}


export function getDaysInInterval (
  range:           MomentRange,
  includeWeekends: boolean
): string[] {
  const days: string[] = [];
  let itr = range.start.clone();
  while (itr.isSameOrBefore(range.end)) {
    if      (includeWeekends)                  days.push(itr.format('YYYY-MM-DD'));
    else if (itr.day() != 0 && itr.day() != 6) days.push(itr.format('YYYY-MM-DD'));
    itr.add(1, 'days');
  }
  return days;
}



export function computeSelectableWeeks (
  scheduleRange: MomentInterval,
  settings:      DivisionSettings
): Week[] {

  const numDays = settings.numDays ?? 5;

  // fetch all weeks in the range
  const extendedWeeks = listIncludedWeeks(scheduleRange).map(w => ({
    ...w,
    days:         getDaysInInterval(w.range, numDays == 7),
    excludedDays: [],
  }) as Week & { days: string[] });

  // remove days from the first and last week outside the schedule period
  extendedWeeks.at(0)?.days.forEach((d, i) => {
    if (d && moment.utc(d).isBefore(scheduleRange.start)) {
      extendedWeeks.at(0)!.days[i] = '';
      extendedWeeks.at(0)!.excludedDays.push({ date: d, day: i });
    }
  });
  extendedWeeks.at(-1)?.days.forEach((d, i) => {
    if (d && moment.utc(d).isAfter(scheduleRange.end)) {
      extendedWeeks.at(-1)!.days[i] = '';
      extendedWeeks.at(-1)!.excludedDays.push({ date: d, day: i });
    }
  });

  // loop over calendar exceptions and remove days
  settings.calendarExceptions?.forEach(e => {
    const days = getDaysInInterval({ start: moment.utc(e.start), end: moment.utc(e.end) }, numDays == 7);
    extendedWeeks.forEach(w => {
      w.days.forEach((d, i) => {
        if (d && days.includes(d)) {
          w.days[i] = '';
          w.excludedDays.push({ date: d, day: i, reason: e.description ?? '' });
        }
      });
    });
  });

  // remove temporary entry
  return extendedWeeks.map(w => _.omit(w, 'days') satisfies Week);
}


export function listSelectableWeeks (
  division: Observable<Division>,
  settings: Observable<DivisionSettings>
): Observable<Week[]> {
  return combineLatest([
      division.pipe(map(x => x ? { start: moment.utc(x.start), end: moment.utc(x.end) } : null), filter(Boolean)),
      settings
    ])
    .pipe(map(x => computeSelectableWeeks(...x)));
}

export function getDefaultWeek (weeks: Observable<Week[] | null>) {
  return weeks
  .pipe(
    filter(Boolean),
    map(weeks => {
      const now = moment.utc();
      if      (now.isBefore(weeks[0               ].range.start)) return weeks[0];
      else if (now.isAfter (weeks[weeks.length - 1].range.end  )) return weeks[weeks.length - 1];
      else return weeks.find(week => week.range.contains(now))!;
    })
  );
}

export function getSelectedPeriods (
  selectedWeeks: Observable<string[] | null>,
  periods:       Observable<P.period[]>,
  settings:      Observable<DivisionSettings>,
) {
  return combineLatest({
    weeks:         selectedWeeks.pipe(map(x => x ? x.map(y => moment.utc(y)) : null)),
    periods:       periods      .pipe(map(x => structuredClone(x))),
    defaultPeriod: settings     .pipe(map(x => x?.period))
  })
  .pipe(
    map(({ weeks, periods, defaultPeriod }) => {
      // in case of no periods
      if ( ! periods.length || ! weeks) return null

      // the active periods are those that contain the selected week
      const selected = periods.filter(p =>
        p.ranges.some(r =>
          weeks.some(w => moment.range(moment.utc(r.start), moment.utc(r.end)).contains(w))
        )
      );

      // if the default period is undefined i.e., all weeks, or if it exist and is selected, include "undefined"
      if ( ! defaultPeriod || selected.some(x => x.id == defaultPeriod.id))
        return [...selected, undefined];
      return selected;
    })
  );
}