<!-- force rerender on stateChanges.next() otherwise it is not guaranteed to show the latest values due to some lazy rendering-->
{{ stateChanges | async }}

<div class="wrapper"
     [style.pointer-events]="disabled ? 'none' : ''"
     (menuClosed)="closed()"
     (menuOpened)="opened()"
     [matMenuTriggerFor]="menu">
  <app-form-field-color-display-value [color]="value"
                                      [nullColor]="nullValue">
  </app-form-field-color-display-value>
</div>

<mat-menu yPosition="below" #menu="matMenu" [overlapTrigger]="false">
  <ng-template matMenuContent>
    <div class="content" (click)="$event.stopPropagation()">

      <div class="circle null"
          *ngIf="nullable"
          [matTooltip]="setVoidText || ('common.no_value' | translate)"
          (click)="value = null; _handleInput()"
          [style.border-color]="nullValue">
        <mat-icon [style.color]="value == null ? 'black' : 'white'">check</mat-icon>
      </div>

      <div class="circle"
          *ngFor="let color of colors"
          (click)="value = color; _handleInput()"
          [style.background]="color">
        <mat-icon [style.color]="value == color ? 'white' : color">check</mat-icon>
      </div>
    </div>
  </ng-template>
</mat-menu>