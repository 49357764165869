import { Component,
         OnInit,
         Input,
         ElementRef,
         OnDestroy,
         Optional,
         Self,
         HostBinding,
         EventEmitter,
         Output,
         ViewEncapsulation,
         ChangeDetectionStrategy,
         ViewChild                       } from '@angular/core';
import { ControlValueAccessor,
         NgControl,
         FormControl,
         FormGroup,
         FormArray                       } from '@angular/forms';
import { MatFormFieldControl             } from '@angular/material/form-field';
import { FocusMonitor                    } from '@angular/cdk/a11y';
import { coerceBooleanProperty           } from '@angular/cdk/coercion';
import { coerceNumberProperty            } from '@angular/cdk/coercion';
import { MatMenu,
         MatMenuTrigger                  } from '@angular/material/menu';
import { Subject,
         asyncScheduler,
         fromEvent,
         BehaviorSubject                 } from 'rxjs';
import { takeUntil                       } from 'rxjs/operators';
import moment                              from 'moment';
import _                                   from 'lodash';
import $                                   from 'jquery';

import { DateService                     } from 'app/shared/services';
import { Interval                        } from 'app/shared/interfaces';
import { Day                             } from 'app/shared/interfaces';
import { ExtendedValidators              } from 'app/shared/forms/validators';
import { IntervalType as Types           } from './intervals.interface';


type Form_pair = {
  start: FormControl<moment.Moment>;
  end:   FormControl<moment.Moment>;
};

type Form = {
  type:     FormControl<Types>;
  single:   FormGroup<Form_pair>;
  multiple: FormArray<FormGroup<Form_pair>>;
};


const defaultStart = '08:00';
const defaultEnd   = '17:00';

@Component({
  selector: 'app-form-field-intervals',
  templateUrl: './intervals.component.html',
  styleUrls: ['./intervals.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: IntervalsComponent
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IntervalsComponent implements OnInit,
                                           OnDestroy,
                                           ControlValueAccessor,
                                           MatFormFieldControl<Interval[] | undefined> {

  @ViewChild(MatMenuTrigger) trigger?: MatMenuTrigger;
  @ViewChild(MatMenu, { read: MatMenu }) menuElementRef?: MatMenu;

  @Output('onChange') emitter = new EventEmitter<Interval[] | null | undefined>();
  public placeholderText:  string          = '';
  static nextId:       number              = 0;
  public stateChanges: Subject<void>       = new Subject<void>();
  public focused:      boolean             = false;
  public errorState:   boolean             = false;
  public controlType:  string              = 'interval-input';
  public id:           string              = `interval-input-${ IntervalsComponent.nextId++ }`;
  public describedBy:  string              = '';
  public onChange = (_: any) => {};
  public onTouched = () => {};
  public transform:    Map<string, string> = new Map<string, string>();
  public form:         FormGroup<Form> | null;
  private onDestroy:   Subject<boolean>    = new Subject<boolean>();
  private onClose:     Subject<boolean>    = new Subject<boolean>();
  public isVoid:       boolean             = true;

  @HostBinding('attr.tabindex') __tabindex = 0;

  constructor (
    @Optional() @Self() public ngControl: NgControl,
    private _focusMonitor: FocusMonitor,
    private _elementRef:   ElementRef<HTMLElement>,
  ) {
    _focusMonitor.monitor(_elementRef, true)
    .subscribe((origin: any) => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
  }

  private _createFormGroup (start: string, end: string) {
    const fg =  new FormGroup({
      start: new FormControl(DateService.fromTimeString(start, 0), { nonNullable: true }),
      end:   new FormControl(DateService.fromTimeString(end,   0), { nonNullable: true }),
    }, { validators: ExtendedValidators.validateThat('start', 'isBefore', 'end') } );

    return fg;
  }

  private _setFormGroup (fg: FormGroup<Form_pair>, val: Interval): void {
    fg.controls.start.setValue(DateService.sanitizeDate(val.start, 0)!, { emitEvent: false });
    fg.controls.end  .setValue(DateService.sanitizeDate(val.end,   0)!, { emitEvent: false });
  }


  private _setForm(): void {
    const _val = this._value;

    let typeVal: Types = undefined;

    // event that is inheriting from course
    if (_val == undefined && this.inheritValue) typeVal = 'none';

    // groups/courses that inherit the default
    else if (_val?.length === 0 && ! this.inheritValue) typeVal = 'none';

    else if (_val?.length) {
      if      (_val.length == 1) typeVal = 'single';
      else if (_val.length >  1) typeVal = 'multiple';
    }

    // create form
    if ( ! this.form || this.form.controls.multiple.length != this.numDays) {
      const type     = new FormControl(typeVal, { nonNullable: true });
      const single   = this._createFormGroup(defaultStart, defaultEnd);
      const multiple = new FormArray(Array.from({ length: this.numDays }).map(() => this._createFormGroup(defaultStart, defaultEnd)));
      this.form = new FormGroup<Form>({ type, single, multiple });
    }

    // set form values
    this.form.controls.type.setValue(typeVal, { emitEvent: false });
    if (_val?.length == 1) {
      this._setFormGroup(this.form.controls.single, _val[0]);
    } else if (_val?.length == this.numDays) {
      this.form.controls.multiple.controls
      .forEach((control, index) => this._setFormGroup(control, _val[index]));
    }
  }

  private _setValue(): void {
    if ( ! this.form) return;

    const { value } = this.form;
    switch (value.type) {
      case 'none':
        this._value = this.inheritValue ? null : [];
        break;
      case 'single':
        const { start, end } = value.single!;
        this._value = [{ start: start!.toISOString() as any, end: end!.toISOString() as any }];
        break;
      case 'multiple':
        this._value = value.multiple!.map(({ start, end }: any) => { return { start: start?.toISOString(), end: end?.toISOString() }})
        break;
      default:
        this._value = [];
        break;
    }

    this.pristine = _.isEqual(this._value, this._pristineValue);
  }

  public resetValue(): void {
    this.value = this._pristineValue;
    this._setForm();
    this.pristine = true;
  }

  public closed(): void {
    this.isVoid = true;
    this.onClose.next(true);

    if (this.saveOnClose && ! this.valid) {
      this.pristine = true;
      this.value = this._pristineValue;
      this._handleInput();
    }

    if (this.saveOnClose && this.valid && ! this._pristine) {
      this.pristine = true;
      this._handleInput();
      this.emitter.emit(this.value);
      this._pristineValue = this.value;
    }

    asyncScheduler
    .schedule(() => {
      this.form = null;
    })
  }

  public opened(): void {
    this._setForm();
    //this._pristine = true;
    this.isVoid    = false;

    this.form?.valueChanges
    .pipe(takeUntil(this.onClose))
    .subscribe(() => {
      this._setValue();
      this._handleInput();
    });


    this.form?.controls.type.valueChanges
    .pipe(takeUntil(this.onClose))
    .subscribe(type => {
      const panelId = this.menuElementRef?.panelId;
      if ( ! panelId) return;
      const $menu = $(`#${ panelId }`);

      if ( ! $menu.length) return;
      asyncScheduler.schedule(() => {
        const contentHeight = $menu.height()!;
        const margin        = 24;
        const top           = $menu.offset()!.top;
        const size          = $( window ).height()!;
        if (top + contentHeight + margin > size) {
          $menu.css('transform', `translateY(${ size - top - contentHeight - margin }px)`);
        } else {
          $menu.css('transform', `translateY(0px)`);
        }
      });
    });


    fromEvent(document, 'keydown')
    .pipe(takeUntil(this.onClose))
    .subscribe((event: any) => {
      if (event.key == 'Escape') {
        this.trigger?.closeMenu()
      }
    });
  }

  public submit(): void {
    this.emitter.emit(this.value);
  }

  get empty() {
    return ! this._value?.length;
  }

  get valid(): boolean {
    switch (this.form?.value.type) {
      case 'none':
        return true;
      case 'single':
        return this.form?.controls.single.valid;
      case 'multiple':
        return this.form?.controls.multiple.valid;
      default:
        return true;
    }
  }

  get shouldLabelFloat() { return this.focused || ! this.empty; }

  get pristine(): boolean { return this._pristine; }
  set pristine(_val: boolean) {
    const val      = coerceBooleanProperty(_val);
    this._pristine = val;

    if (val) asyncScheduler.schedule(() => this.ngControl?.control?.markAsPristine());

    this.stateChanges.next();
  }
  private _pristine:  boolean = true;

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get voidText(): string { return this._voidText; }
  set voidText(value: string) {
    this._voidText = value;
    this.stateChanges.next();
  }
  private _voidText: string = '';

  @Input()
  get reset(): boolean { return this._reset; }
  set reset(value: boolean) {
    this._reset = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _reset = true;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get disableActions(): boolean { return this._disableActions; }
  set disableActions(value: boolean | string) {
    this._disableActions = coerceBooleanProperty(value);
  }
  private _disableActions = true;

  @Input()
  get saveOnClose(): boolean { return this._saveOnClose; }
  set saveOnClose(value: boolean | string) {
    this._saveOnClose = coerceBooleanProperty(value);
  }
  private _saveOnClose: boolean = false;

  get days(): number[] { return this._days; }
  private _days = [...Array(5).keys()];

  @Input()
  get numDays(): number { return this._numDays; }
  set numDays(value: number | null | undefined) {
    this._numDays = coerceNumberProperty(value, 5);
    this._days    = [...Array(this._numDays).keys()];
    this._setForm();
    this.stateChanges.next();

    // renders the form
    // (to prevent using 5 days on a 7 day schedule)
    if (value) this.onNumDays.next(value);
  }
  private _numDays = 5;
  protected onNumDays = new BehaviorSubject<number | null>(null);

  @Input()
  get availableDays(): Day[] | undefined | null { return this._availableDays; }
  set availableDays(value: Day[] | undefined | null) {
    this._availableDays = value;
  }
  private _availableDays: Day[] | undefined | null;

  @Input()
  get value(): Interval[] | null | undefined {
    return this._value;
  }
  set value(_val: Interval[] | null | undefined) {
    this._value = this._pristineValue = _val?.map(({ start, end }) => ({ start, end }));

    this.stateChanges.next();
  }
  private _value:         Interval[] | null | undefined;
  private _pristineValue: Interval[] | null | undefined;

  @Input()
  get inherit(): boolean { return this._inherit; }
  set inherit(value: boolean | string) {
    this._inherit = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _inherit:    boolean;

  @Input()
  get inheritValue(): Interval[] | null | undefined { return this._inheritValue; }
  set inheritValue(value: Interval[] | null | undefined) {
    this._inheritValue = value;
    this.stateChanges.next();
  }
  private _inheritValue: Interval[] | null | undefined;

  @Input()
  get inheritAvailableDays(): Day[] | null | undefined { return this._inheritAvailableDays; }
  set inheritAvailableDays(value: Day[] | null | undefined) {
    this._inheritAvailableDays = value;
    this.stateChanges.next();
  }
  private _inheritAvailableDays: Day[] | null | undefined;

  @Input()
  get setVoidText(): string { return this._setVoidText; }
  set setVoidText(value: string) {
    this._setVoidText = value;
  }
  private _setVoidText: string;

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
    this.onDestroy.next(true);
    this.onDestroy.complete();
    this.onClose.next(true);
    this.onClose.complete();
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent) {
    this.trigger?.openMenu();
  }

  writeValue(val: Interval[]): void {
    this.value = val;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  _handleInput(): void {
    this.onChange(this.value);
  }

  static ngAcceptInputType_disabled: boolean | string | null | undefined;
  static ngAcceptInputType_required: boolean | string | null | undefined;
}