export const LANG_CA_NAME = 'ca';

export const LANG_CA_TRANS = {
  "account.index.header": "Gestionar compte",
  "account.notifications.header": "Notificacions",
  "account.notifications.menu": "Notificacions",
  "account.notifications.page.descr": "La taula inferior permet ajustar diversos aspectes del sistema de notificacions utilitzat a l’aplicació.",
  "account.notifications.page.title": "Notificacions",
  "account.security.header": "Seguretat",
  "account.security.menu": "Seguretat",
  "account.security.page.change-password.descr": "Ompli el formulari inferior per canviar la seva contrasenya d’accés.",
  "account.security.page.change-password.error.descr": "La contrasenya actual no coincideix amb la seva. Torni-ho a provar.",
  "account.security.page.change-password.error.title": "No s’ha pogut actualitzar la contrasenya",
  "account.security.page.change-password.success.descr": "La seva contrasenya s’ha actualitzat!",
  "account.security.page.change-password.success.title": "Èxit",
  "account.security.page.change-password.title": "Canviar contrasenya",
  "account.security.page.title": "Seguretat",
  "account.shortcuts.header": "Dreceres",
  "account.shortcuts.menu": "Dreceres",
  "account.shortcuts.page.category.editor.description": "Dreceres per l’editor d’horaris.",
  "account.shortcuts.page.category.editor.title": "Editor",
  "account.shortcuts.page.description": "A continuació es llisten les dreceres de teclat per a les accions més comunes de l’aplicació. Pot canviar-les segons la seva preferència fent clic a la drecera i prement la nova combinació de tecles.",
  "account.shortcuts.page.editor/hideEvent.description": "Canvia l’estat ocult de les lliçons seleccionades.",
  "account.shortcuts.page.editor/hideEvent.label": "Ocultar lliçó",
  "account.shortcuts.page.editor/moveEventEarlier.description": "Mou les lliçons seleccionades 5 minuts abans.",
  "account.shortcuts.page.editor/moveEventEarlier.label": "Moure lliçó abans",
  "account.shortcuts.page.editor/moveEventIndividually.description": "Mantingui premuda aquesta combinació de tecles mentre arrossega una lliçó enllaçada per moure-la individualment.",
  "account.shortcuts.page.editor/moveEventIndividually.label": "Moure lliçó enllaçada individualment",
  "account.shortcuts.page.editor/moveEventLater.description": "Mou les lliçons seleccionades 5 minuts després.",
  "account.shortcuts.page.editor/moveEventLater.label": "Moure lliçó després",
  "account.shortcuts.page.editor/moveEventMuchEarlier.description": "Mou les lliçons seleccionades 60 minuts abans.",
  "account.shortcuts.page.editor/moveEventMuchEarlier.label": "Moure lliçó molt abans",
  "account.shortcuts.page.editor/moveEventMuchLater.description": "Mou les lliçons seleccionades 60 minuts després.",
  "account.shortcuts.page.editor/moveEventMuchLater.label": "Moure lliçó molt després",
  "account.shortcuts.page.editor/moveEventNextDay.description": "Mou les lliçons seleccionades al dia següent.",
  "account.shortcuts.page.editor/moveEventNextDay.label": "Moure lliçó al dia següent",
  "account.shortcuts.page.editor/moveEventPrevDay.description": "Mou les lliçons seleccionades al dia anterior.",
  "account.shortcuts.page.editor/moveEventPrevDay.label": "Moure lliçó al dia anterior",
  "account.shortcuts.page.editor/multipleSelectEvents.description": "Mantenir premuda aquesta tecla li permetrà seleccionar diverses lliçons.",
  "account.shortcuts.page.editor/multipleSelectEvents.label": "Seleccionar diverses lliçons",
  "account.shortcuts.page.editor/parkEvent.description": "Canvia l’estat aparcat de les lliçons seleccionades.",
  "account.shortcuts.page.editor/parkEvent.label": "Aparcar lliçó",
  "account.shortcuts.page.editor/pinEvent.description": "Canvia l’estat fixat de les lliçons seleccionades.",
  "account.shortcuts.page.editor/pinEvent.label": "Fixar lliçó",
  "account.shortcuts.page.interference": "La comanda per aquesta drecera ja s’utilitza per \"{{ label }}\" de la mateixa categoria. Si us plau, triï’n un altre.",
  "account.shortcuts.page.notifications.error": "No s’ha pogut actualitzar la drecera. Si us plau, torni-ho a provar.",
  "account.shortcuts.page.restore.description": "Restaura tots els dreceres de teclat als seus valors per defecte.",
  "account.shortcuts.page.title": "Dreceres de teclat",
  "account.workspace.header": "Espai de treball",
  "account.workspace.menu": "Espai de treball",
  "account.workspace.page.backgroundDataAnalysis.descr": "Aquesta funcionalitat analitza de forma contínua les dades de l'horari i et proporciona suggeriments per a millores. L'anàlisi contínua pot afectar el rendiment general de l'aplicació i per això pot estar desactivada. Més endavant es pot tornar a activar en qualsevol moment.",
  "account.workspace.page.backgroundDataAnalysis.label": "Anàlisi de les dades de l'horari",
  "account.workspace.page.defaultEventDurations.descr": "Aquí, pot definir conjunts de durades de lliçó per defecte per facilitar la creació de lliçons.",
  "account.workspace.page.defaultEventDurations.label": "Durades de lliçó per defecte",
  "account.workspace.page.descr": "Aquests tipus de preferències li permeten modificar i personalitzar l’espai de treball de l’aplicació.",
  "account.workspace.page.displayCenterOfAttraction.descr": "El temps preferit determina quin punt en el temps s’extrauen les lliçons durant el procediment d’assignació automàtica. En habilitar aquesta opció, el temps preferit per defecte pot ser substituït per cursos i lliçons individuals.",
  "account.workspace.page.displayCenterOfAttraction.label": "Mostrar l’opció de curs i lliçó <i>temps preferit</i>",
  "account.workspace.page.displayChat.descr": "Si el xat a la cantonada inferior esquerra és visible.",
  "account.workspace.page.displayChat.label": "Mostrar xat",
  "account.workspace.page.displayEventsTable.descr": "En aquesta taula, trobarà les lliçons de tots els cursos.",
  "account.workspace.page.displayEventsTable.label": "Mostrar taula de lliçons",
  "account.workspace.page.displayPersonsTable.descr": "En aquesta taula, trobarà estudiants de tots els grups i aquells que no són membres de cap grup.",
  "account.workspace.page.displayPersonsTable.label": "Mostrar taula d’estudiants",
  "account.workspace.page.displayTooltips.descr": "Si haurien de ser visibles els consells d'eines amb informació que expliqui més el concepte.",
  "account.workspace.page.displayTooltips.label": "Mostrar consells sobre eines",
  "account.workspace.page.language.descr": "Estableix l’idioma de l’aplicació.",
  "account.workspace.page.notifications.error": "No s’ha pogut actualitzar la preferència. Torneu-ho a provar.",
  "account.workspace.page.title": "Espai de treball",
  "attributeDescriptions.course.centerOfAttraction": "Les lliçons que pertanyen al curs s’extrauen al seu temps preferit durant el procés d’assignació automàtica",
  "attributeDescriptions.course.color": "Estableix el color de visualització per a les lliçons que pertanyen al curs",
  "attributeDescriptions.course.days": "Estableix els dies permesos per a les lliçons que pertanyen al curs",
  "attributeDescriptions.course.eventDurationVariation": "Estableix la diferència màxima entre la durada real d’una lliçó i la seva durada preferida per a les lliçons que pertanyen al curs. No obstant això, es conserva la durada total del curs.",
  "attributeDescriptions.course.events": "Les lliçons que pertanyen al curs",
  "attributeDescriptions.course.groups": "Estableix els grups que faran les lliçons que pertanyen al curs",
  "attributeDescriptions.course.intervals": "Estableix el marc de temps per a les lliçons que pertanyen al curs",
  "attributeDescriptions.course.locations": "Estableix les sales que es poden reservar per a les lliçons que pertanyen al curs",
  "attributeDescriptions.course.lockedTimes": "Estableix intervals de temps prohibitius per a les lliçons que pertanyen al curs",
  "attributeDescriptions.course.minBreakLength": "Estableix la pausa més curta permesa abans i després de les lliçons que pertanyen al curs",
  "attributeDescriptions.course.overlapGroup": "Defineix un conjunt de cursos les lliçons dels quals es permeten superposar. No obstant això, les lliçons que pertanyen al mateix curs mai es permeten superposar. Durant el procés d’assignació automàtica, es fa un intent per emparellar les lliçons dins d’un grup de la millor manera possible.",
  "attributeDescriptions.course.period": "Estableix el període de temps per a les lliçons que pertanyen al curs, per exemple, tardor, primavera o setmanes senars i parelles. Tingueu en compte que abans que es pugui seleccionar un període, s’ha de crear.",
  "attributeDescriptions.course.subject": "El tema del curs",
  "attributeDescriptions.course.teachers": "Estableix els docents que ensenyaran les lliçons que pertanyen al curs",
  "attributeDescriptions.event.centerOfAttraction": "La lliçó s’extrau al seu temps preferit durant el procés d’assignació automàtica",
  "attributeDescriptions.event.color": "Estableix el color de visualització de la lliçó",
  "attributeDescriptions.event.course": "El curs al qual pertany la lliçó",
  "attributeDescriptions.event.courseId": "L’ID públic del curs al qual pertany la lliçó",
  "attributeDescriptions.event.days": "Estableix els dies permesos de la lliçó",
  "attributeDescriptions.event.duration": "Estableix la durada de la lliçó",
  "attributeDescriptions.event.durationVariation": "Estableix la diferència màxima entre la durada real de la lliçó i la seva durada preferida. No obstant això, es conserva la durada total del curs.",
  "attributeDescriptions.event.fixedStart": "Fixa l’hora d’inici de la lliçó",
  "attributeDescriptions.event.groups": "Estableix els grups que faran la lliçó",
  "attributeDescriptions.event.intervals": "Estableix el marc de temps per a la lliçó",
  "attributeDescriptions.event.locations": "Estableix les sales que es poden reservar per a la lliçó",
  "attributeDescriptions.event.lockedTimes": "Estableix intervals de temps prohibitius per a la lliçó",
  "attributeDescriptions.event.minBreakLength": "Estableix la pausa més curta permesa abans i després de la lliçó",
  "attributeDescriptions.event.overlapSpecies": "Les lliçons enllaçades es programaran al mateix temps",
  "attributeDescriptions.event.period": "Estableix el període de temps per a la lliçó, per exemple, tardor, primavera o setmanes senars i parelles. Tingueu en compte que abans que es pugui seleccionar un període, s’ha de crear.",
  "attributeDescriptions.event.preferredDuration": "Estableix la durada preferida de la lliçó. Després del procés d’assignació automàtica, la durada real no superarà \"durada preferida ± variació de durada\"",
  "attributeDescriptions.event.teachers": "Estableix els docents que ensenyaran la lliçó",
  "attributeDescriptions.group.days": "Estableix els dies permesos del grup",
  "attributeDescriptions.group.intervals": "Estableix el marc de temps per al grup",
  "attributeDescriptions.group.lockedTimes": "Estableix intervals de temps prohibitius per al grup",
  "attributeDescriptions.group.lunch": "Estableix les pauses per dinar del grup",
  "attributeDescriptions.group.minBreakLength": "Estableix la pausa més curta permesa entre lliçons per al grup",
  "attributeDescriptions.group.rootIntervals": "Estableix els blocs del grup",
  "attributeDescriptions.groups.members": "Estableix els membres del grup",
  "attributeDescriptions.groups.species": "Estableix el tipus de grup",
  "attributeDescriptions.groups.parentGroups": "Estableix les classes constituents del grup basades en els membres del grup",
  "attributeDescriptions.location.days": "Estableix els dies permesos de la sala",
  "attributeDescriptions.location.lockedTimes": "Estableix intervals de temps prohibitius per a la sala",
  "attributeDescriptions.location.minBreakLength": "Estableix el marge de temps més curt entre dues reserves de la sala",
  "attributeDescriptions.person.emails": "Estableix el correu electrònic de l’estudiant",
  "attributeDescriptions.person.firstName": "Estableix el nom de l’estudiant",
  "attributeDescriptions.person.group": "Estableix el grup de l’estudiant",
  "attributeDescriptions.person.lastName": "Estableix el cognom de l’estudiant",
  "attributeDescriptions.person.lockedTimes": "Estableix intervals de temps prohibitius per a l’estudiant",
  "attributeDescriptions.person.lunch": "Estableix les pauses per dinar de l’estudiant",
  "attributeDescriptions.person.phoneNumbers": "Estableix el número de telèfon de l’estudiant",
  "attributeDescriptions.person.sex": "Estableix el gènere de l’estudiant",
  "attributeDescriptions.person.SSN": "Estableix el número de la seguretat social de l’estudiant",
  "attributeDescriptions.shared.displayName": "Un nom utilitzat en tota l’aplicació",
  "attributeDescriptions.teacher.days": "Estableix els dies permesos del docent",
  "attributeDescriptions.teacher.emails": "Estableix el correu electrònic del docent",
  "attributeDescriptions.teacher.firstName": "Estableix el nom del docent",
  "attributeDescriptions.teacher.intervals": "Estableix el marc de temps per al docent",
  "attributeDescriptions.teacher.lastName": "Estableix el cognom del docent",
  "attributeDescriptions.teacher.lockedTimes": "Estableix intervals de temps prohibitius per al docent",
  "attributeDescriptions.teacher.lunch": "Estableix les pauses per dinar del docent",
  "attributeDescriptions.teacher.minBreakLength": "Estableix la pausa més curta permesa entre lliçons per al docent",
  "attributes.course.eventDurationVariation": "Variació de la durada de la lliçó",
  "attributes.course.events": "Lliçons",
  "attributes.course.overlapGroup": "Cursos paral·lels",
  "attributes.division.default.dayRank": "Classificació per defecte del dia",
  "attributes.division.default.dependencyMinimumBreakLength": "Per a les aules",
  "attributes.division.default.dependencyRank": "Classificació per defecte de l’aula",
  "attributes.division.default.eventDurationVariation": "Variació de la durada de la lliçó",
  "attributes.division.default.eventMinimumBreakLength": "Entre lliçons",
  "attributes.division.default.groupMinimumBreakLength": "Per a grups i docents",
  "attributes.division.default.lunch": "Pausa per dinar per defecte",
  "attributes.division.default.maxNumDailyWorkingHours": "Diari",
  "attributes.division.default.maxNumWorkingHours": "Total",
  "attributes.division.default.period": "Període",
  "attributes.division.end": "Data de finalització",
  "attributes.division.published": "publicar",
  "attributes.division.settings.dayEnd": "Final del dia",
  "attributes.division.settings.dayStart": "Inici del dia",
  "attributes.division.settings.discretization": "Discretització",
  "attributes.division.settings.numDays": "Nombre de dies",
  "attributes.division.settings.potentialCenter": "Temps preferit",
  "attributes.division.start": "Data d’inici",
  "attributes.event.course": "Curs",
  "attributes.event.courseId": "ID del curs",
  "attributes.event.current_duration": "Durada actual de la lliçó",
  "attributes.event.durationVariation": "Variació de la durada",
  "attributes.event.fixedStart": "Hora d’inici fixa",
  "attributes.event.inherit": "Heretat del curs",
  "attributes.event.overlapSpecies": "Lliçons enllaçades",
  "attributes.event.preferredDuration": "Durada de la lliçó",
  "attributes.generation.status.COMPLETED": "Fet",
  "attributes.generation.status.PENDING": "Esperant",
  "attributes.generation.status.STARTED": "Iniciat",
  "attributes.groups.parentGroups": "Classes constituents",
  "attributes.groups.species": "Tipus de grup",
  "attributes.groups.species.class": "Classe",
  "attributes.groups.species.none": "Grup docent",
  "attributes.groups.members": "Membres",
  "attributes.teachers.signature": "Signatura",
  "attributes.integrations.APPROVED": "Actiu",
  "attributes.integrations.DISABLED": "Desactivat",
  "attributes.integrations.PENDING": "Pendent",
  "attributes.integrations.REJECTED": "Rebutjat",
  "attributes.location.days": "Dies disponibles",
  "attributes.location.minBreakLength": "Marge de temps",
  "attributes.person.firstName": "Nom",
  "attributes.person.group": "Grup",
  "attributes.person.lastName": "Cognoms",
  "attributes.person.sex": "Gènere",
  "attributes.person.SSN": "Número de la Seguretat Social",
  "attributes.shared.centerOfAttraction": "Temps preferit",
  "attributes.shared.createdAt": "Creat",
  "attributes.shared.color": "Color",
  "attributes.shared.course": "Curs",
  "attributes.shared.day": "Dia",
  "attributes.shared.days": "Dies permesos",
  "attributes.shared.displayName": "Nom",
  "attributes.shared.duration": "Durada",
  "attributes.shared.durationVariation": "Variació de la durada",
  "attributes.shared.dynamicLockedTime.custom": "Establert per a cada dia",
  "attributes.shared.dynamicLockedTime.default": "Valor per defecte",
  "attributes.shared.dynamicLockedTime.none": "Cap",
  "attributes.shared.dynamicLockedTime.repeated": "Mateix per a cada dia",
  "attributes.shared.emails": "Correu electrònic",
  "attributes.shared.end": "Final",
  "attributes.shared.endTime": "Hora de finalització",
  "attributes.shared.groups": "Grups",
  "attributes.shared.intervals": "Marc de temps",
  "attributes.shared.intervals.multiple": "Diferent per a cada dia",
  "attributes.shared.intervals.none": "Utilitza el marc de temps per defecte",
  "attributes.shared.intervals.single": "Mateix per a cada dia",
  "attributes.shared.locations": "Aules permeses",
  "attributes.shared.lockedTimes": "Temps prohibit",
  "attributes.shared.lunch": "Dinar",
  "attributes.shared.minBreakLength": "Durada de les pauses",
  "attributes.shared.minBreakLength.asymmetrical": "Durades diferents",
  "attributes.shared.minBreakLength.asymmetrical.after": "Després",
  "attributes.shared.minBreakLength.asymmetrical.before": "Abans",
  "attributes.shared.minBreakLength.asymmetrical.tooltip": "{{ before }} abans i {{ after }} després",
  "attributes.shared.minBreakLength.default": "Valor per defecte",
  "attributes.shared.minBreakLength.none": "Cap",
  "attributes.shared.minBreakLength.symmetrical": "Durades iguals abans i després",
  "attributes.shared.period": "Període",
  "attributes.shared.period.entire": "Totes les setmanes",
  "attributes.shared.phoneNumbers": "Telèfons",
  "attributes.shared.rootIntervals": "Blocs",
  "attributes.shared.start": "Inici",
  "attributes.shared.startTime": "Hora d'inici",
  "attributes.shared.subject": "Assignatura",
  "attributes.shared.teachers": "Docents",
  "auth.role.admin": "Administrador",
  "auth.role.partner": "Soci",
  "auth.role.unrestricted": "Usuari regular",
  "common.goto_schedule": "Vés a la programació",
  "common.+_n_more": "+ {{n}} més",
  "common.a_lunch_belonging_to_the_group": "un dinar que pertany al grup",
  "common.a_lunch_belonging_to_the_person": "un dinar que pertany a l'estudiant",
  "common.a_lunch_belonging_to_the_teacher": "un dinar que pertany al docent",
  "common.about": "Estat",
  "common.add": "Afegir",
  "common.add_course": "Afegir curs",
  "common.add_event": "Afegir classe(s): {{ event-durations }}",
  "common.add_event_advanced": "Afegir classe(s)",
  "common.add_filter": "Afegir filtre",
  "common.add_group": "Afegir grup",
  "common.add_location": "Afegir aula",
  "common.add_one_more": "Afegir un més",
  "common.add_period": "Afegir període",
  "common.add_person": "Afegir estudiant",
  "common.add_teacher": "Afegir docent",
  "common.advanced_settings": "Configuració avançada",
  "common.all": "Tots",
  "common.alone": "Sol",
  "common.an_event_belonging_to_the_course": "una classe que pertany al curs",
  "common.analysis": "Anàlisi",
  "common.and": "i",
  "common.and1Other": "i un altre",
  "common.andNOthers": "i {{n}} més",
  "common.associated_partner": "Soci associat",
  "common.back": "Enrere",
  "common.back_to_login": "Tornar a l'inici de sessió",
  "common.calendar_view": "Vista calendari",
  "common.cancel": "Cancel·lar",
  "common.change_password": "Canviar contrasenya",
  "common.chooseOther": "Tria un altre",
  "common.clear": "Netejar",
  "common.close": "Tancar",
  "common.complete": "Completar",
  "common.computing": "Informàtica",
  "common.confirmation": "Confirmació",
  "common.connect": "Connectar",
  "common.connect_error": "Error de connexió",
  "common.copy": "Copia",
  "common.country": "País",
  "common.counts": "Comptes",
  "common.course": "Curs",
  "common.courses": "Cursos",
  "common.create": "Crear",
  "common.create_course": "Crear curs",
  "common.create_group": "Crear grup",
  "common.create_location": "Crear aula",
  "common.create_person": "Crear estudiant",
  "common.create_schedule": "Crea un horari",
  "common.create_teacher": "Crear docent",
  "common.created_at": "Creat",
  "common.creating": "Creant",
  "common.creation_fail": "Alguna cosa ha anat malament",
  "common.creation_success": "Creat",
  "common.custom_search": "Cerca personalitzada",
  "common.day": "Dia",
  "common.days": "Dies",
  "common.default": "Per defecte",
  "common.delete": "Eliminar",
  "common.delete_account": "Eliminar compte",
  "common.deleting": "Eliminant",
  "common.discard": "Descartar",
  "common.download": "Descarregar",
  "common.drop_here": "Deixar aquí",
  "common.duplicate": "Duplicar",
  "common.duplicated": "Duplicat",
  "common.earliest": "Més aviat",
  "common.edit": "Editar",
  "common.edit_columns": "Editar columnes",
  "common.edit_failed": "No se han podido guardar los cambios",
  "common.edited_at": "Última modificació",
  "common.email": "correu electrònic",
  "common.empty": "Buit",
  "common.erroneous_value": "Valor no vàlid",
  "common.error": "Error",
  "common.errors": "errors",
  "common.event": "Classe",
  "common.event_durations": "durada de la lliçó",
  "common.events": "Classes",
  "common.expand": "Expandir",
  "common.export": "Exportar",
  "common.filter": "Filtrar",
  "common.flaw": "defecte",
  "common.flaws": "defectes",
  "common.forgot_password": "Has oblidat la contrasenya?",
  "common.generalErrorMessage": "Ups, alguna cosa ha anat malament...",
  "common.generic_error": "Alguna cosa ha anat malament",
  "common.generic_help": "Estem treballant per solucionar el problema.",
  "common.goto-tutorial": "anar al tutorial",
  "common.group": "Grup",
  "common.groups": "Grups",
  "common.help": "Ajuda",
  "common.hide": "Amagar",
  "common.hour": "Hora",
  "common.hour_short": "h",
  "common.hours": "Hores",
  "common.hours_short": "h",
  "common.ignore": "Ignorar",
  "common.import": "Importar",
  "common.inheritFromCourse": "Heretar del curs",
  "common.inheritFromCourseValue": "Heretar del curs ({{value}})",
  "common.invalid": "Invàlid",
  "common.invalid_division_id": "No s'ha pogut trobar l'horari",
  "common.invalid_value": "Valor no vàlid",
  "common.language": "Idioma",
  "common.latest": "Més tard",
  "common.linked_events": "Lliçons enllaçades",
  "common.list_view": "Vista de llista",
  "common.loading": "Carregant",
  "common.location": "Aula",
  "common.locations": "Aules",
  "common.locked": "Bloquejat",
  "common.locked_time": "Temps prohibit",
  "common.locked_times": "Temps prohibit",
  "common.lockedTime": "Interval de temps prohibitius",
  "common.lockedTimes": "Intervals de temps prohibitius",
  "common.login": "Iniciar sessió",
  "common.logout": "Tancar sessió",
  "common.lunch": "Dinar",
  "common.make": "make",
  "common.makes": "makes",
  "common.manage_account": "Gestiona el teu compte",
  "common.minimize": "Minimitzar",
  "common.minute": "Minut",
  "common.minute_short": "min",
  "common.minutes": "Minuts",
  "common.minutes_short": "min",
  "common.modified_after": "Modificat després de",
  "common.nameless": "Sense nom",
  "common.next": "Següent",
  "common.no": "No",
  "common.no_available_data": "No hi ha dades disponibles",
  "common.no_events_to_show": "No hi ha lliçons per mostrar",
  "common.no_match": "No hi ha coincidències",
  "common.no_value": "Sense valor",
  "common.none_plural": "Cap",
  "common.none_singular": "Cap",
  "common.not_placed": "No col·locat",
  "common.of": "de",
  "common.or": "O",
  "common.other": "Altres",
  "common.others": "Altres",
  "common.overlapGroup": "Conjunt de cursos paral·lels",
  "common.overlapGroups": "Conjunts de cursos paral·lels",
  "common.period": "Període",
  "common.periods": "Períodes",
  "common.person": "Alumne/a",
  "common.persons": "Alumnat",
  "common.print": "Imprimir",
  "common.related-content": "Contingut relacionat",
  "common.reload": "Recarregar",
  "common.remaining": "Restant",
  "common.remove": "Eliminar",
  "common.rename": "Reanomenar",
  "common.resend": "Reenviar",
  "common.reset": "Netejar",
  "common.reset_filter": "Netejar filtre",
  "common.reset_password_fail": "No s'ha pogut enviar un enllaç de restabliment a aquest usuari",
  "common.reset_password_success": "S'ha enviat un enllaç de restabliment a",
  "common.restore": "Restaurar",
  "common.retry": "Tornar-ho a provar",
  "common.rootInterval": "Bloc",
  "common.rootIntervals": "Bloc",
  "common.save": "Guardar",
  "common.save_failed": "Error en desar",
  "common.saved": "Guardat",
  "common.saving": "Guardant",
  "common.schedule": "Horari",
  "common.school": "Escola",
  "common.school_year": "Curs escolar",
  "common.screen_size": "La mida de la pantalla és massa petita",
  "common.search": "Cercar",
  "common.search.course": "Cercar curs",
  "common.search.event": "Cercar lliçó",
  "common.seconds": "Segons",
  "common.seconds_short": "s",
  "common.select": "Seleccionar",
  "common.select_entity_to_show_events": "Selecciona un grup, docent o aula per mostrar les seves lliçons.",
  "common.select_file": "Selecciona un fitxer",
  "common.selected": "Seleccionat",
  "common.selected_file": "Fitxer seleccionat: {{file}}",
  "common.settings": "Configuració",
  "common.sex.man": "Home",
  "common.sex.woman": "Dona",
  "common.share": "Compartir",
  "common.show": "Mostrar",
  "common.show_all_events": "Mostrar totes les lliçons",
  "common.sports_facility": "Instal·lació esportiva",
  "common.subject": "Matèria",
  "common.subjects": "Assignatures",
  "common.sync": "Sincronitzar",
  "common.synchronising": "Sincronitzant",
  "common.teacher": "Docent",
  "common.teachers": "Docents",
  "common.terms": "Termes i condicions",
  "common.the_course": "El curs",
  "common.the_courses": "Els cursos",
  "common.the_event": "La classe",
  "common.the_events": "Les classes",
  "common.the_group": "El grup",
  "common.the_groups": "Els grups",
  "common.the_location": "L’aula",
  "common.the_locations": "Les aules",
  "common.the_lunch": "El dinar",
  "common.the_lunches": "Els dinars",
  "common.the_period": "El període",
  "common.the_periods": "Els períodes",
  "common.the_person": "L’alumne/a",
  "common.the_persons": "L’alumnat",
  "common.the_subjects": "Les assignatures",
  "common.the_teacher": "El docent",
  "common.the_teachers": "Els docents",
  "common.time_settings": "Configuració de l'hora",
  "common.toggle_all": "Seleccionar tots",
  "common.total": "Total",
  "common.type": "Tipus",
  "common.unknown": "desconegut",
  "common.update": "Actualitzar",
  "common.updated": "Actualitzat",
  "common.upload": "Pujar",
  "common.useDefault": "Utilitzar per defecte",
  "common.useDefaultValue": "Utilitzar per defecte ({{value}})",
  "common.username": "Nom d'usuari",
  "common.users": "Usuaris",
  "common.value": "Valor",
  "common.versions": "Versions",
  "common.warning": "advertència",
  "common.warnings": "advertències",
  "common.week": "Setmana",
  "common.week_short": "setm.",
  "common.weeks": "Setmanes",
  "common.yes": "Sí",
  "common.YYYY-MM-DD": "DD-MM-YYYY",
  "common.zoom_center": "Restaurar zoom",
  "common.zoom_in": "Ampliar",
  "common.zoom_out": "Reduir",
  "common.today": "Avui",
  "common.tomorrow": "Demà",
  "common.this_week": "Aquesta setmana",
  "common.next_week": "La setmana que ve",
  "common.this_month": "Aquest mes",
  "common.next_month": "El mes que ve",
  "common.modify": "Modificar",
  "common.calendar": "Calendari",
  "common.list": "Llista",
  "common.changes": "Canvis",
  "common.cancelled": "Cancel·lat",
  "common.no_selected": "No seleccionat",
  "common.no_exceptions": "No hi ha excepcions",
  "common.no_absences": "No hi ha absències",
  "common.substitutions": "Excepcions",
  "common.absences": "Absències",
  "common.absentees": "Absents",
  "common.reason": "Motiu",
  "common.filter_by_groups": "Filtrar per grups",
  "common.not_available_for_current_selection": "No disponible per a la selecció actual",
  'common.cancel_event': 'Cancel·lar classe',
  'common.comment': 'Comentari',
  "common.error_code": "Codi d'error",
  "current language": "Català",
  "dialogs.admentumImport.step_1.descr": "Aquí podeu triar quin horari i els cursos associats, docents, etc. per importar de la plataforma Admentum. Si teniu diversos horaris per a un curs escolar, es poden diferenciar pel seu número d'identificació.",
  "dialogs.admentumImport.step_1.required": "Si us plau, trieu un horari per continuar.",
  "dialogs.admentumImport.step_1.title": "Trieu un horari",
  "dialogs.admentumImport.step_2.descr": "En triar una setmana, s'importaran totes les lliçons dins d'aquesta setmana. En conseqüència, si no es tria cap setmana, no s'importaran lliçons d'Admentum.",
  "dialogs.admentumImport.step_2.title": "Trieu una setmana",
  "dialogs.admentumImport.step_3.descr": "Podeu canviar el nom de l'horari en qualsevol moment després de la importació.",
  "dialogs.admentumImport.step_3.label": "Nom de l'horari",
  "dialogs.admentumImport.step_3.required": "Si us plau, doneu un nom a l'horari.",
  "dialogs.admentumImport.step_3.title": "Donar un nom a l'horari",
  "dialogs.create-courses.by-hand.label": "A mà",
  "dialogs.create-courses.in-bulk.for_each_of": "Crear curs(s) per a cada un de",
  "dialogs.create-courses.in-bulk.label": "En massa",
  "inputAnalysis.computing": "Calculant...",
  "inputAnalysis.dialog.description": "Les dades de l'horari s'analitzen contínuament i els resultats es presenten a les tres pestanyes de la dreta. Cada pestanya representa una categoria de defectes en les dades de l'horari, que es descriuen a continuació.",
  "inputAnalysis.dialog.empty_category": "No hi ha comentaris d'aquesta categoria",
  "inputAnalysis.dialog.overview.title": "Resum",
  "inputAnalysis.dialog.title": "Anàlisi de les dades de l'horari",
  "inputAnalysis.disabled": "L'anàlisi de les dades està desactivada però es pot activar en qualsevol moment a la configuració de l'espai de treball.",
  "inputAnalysis.entities.theLunch": "El dinar pertanyent a {{ theGroupOrTeacher }}",
  "inputAnalysis.errors.21.10.0.description": "No hi ha cap dia permès per a la lliçó i els grups, docents i aules que hi estan connectats. Vegeu quins dies estan bloquejats a continuació:",
  "inputAnalysis.errors.21.10.0.title": "{{The-entity}} no té dies permesos segons les dades de l'horari.",
  "inputAnalysis.errors.21.10.1.description": "Això podria ser degut a diverses raons, per exemple, intervals de temps prohibit per grups i docents connectats, o dies exclosos per les aules triades. Utilitzeu l'eina de calendari a continuació per saber quina és la causa d'aquest cas particular i com es podria resoldre.",
  "inputAnalysis.errors.21.10.1.title": "{{The-entity}} no encaixa dins del seu marc de temps disponible.",
  "inputAnalysis.errors.24.0.description": "Cada lliçó per si mateixa té almenys un dia disponible, però el problema sorgeix quan estan connectats i, per tant, programats el mateix dia.",
  "inputAnalysis.errors.24.0.title": "{{The-entities}} estan connectats però no comparteixen un dia permès.",
  "inputAnalysis.errors.24.1.description": "Cada lliçó per si mateixa pot encaixar en algun interval de temps, però el problema sorgeix quan estan connectats i, per tant, programats junts.",
  "inputAnalysis.errors.24.1.title": "{{The-entities}} estan connectats però no es poden programar junts a causa de les restriccions d'interval de temps",
  "inputAnalysis.errors.description": "No es pot programar automàticament fins que aquests errors s'hagin resolt.",
  "inputAnalysis.errors.missing_overlap_species.description": "Les lliçons pertanyents a cursos paral·lels no estan garantides per programar-se al mateix temps després que s'hagi generat un horari, és a dir, llevat que estiguin connectats. Aquest error crític es pot desactivar a la configuració de l'horari.",
  "inputAnalysis.errors.missing_overlap_species.title": "{{The-entities}} {{is/are-parallel-courses}}, però una o més lliçons associades no estan connectades.",
  "inputAnalysis.errors.notification_title": "Les dades de l'horari contenen errors crítics.",
  "inputAnalysis.errors.title": "Errors crítics",
  "inputAnalysis.etc.modify_period": "Modifica el període",
  "inputAnalysis.etc.schedule_settings": "Configuració de l'horari",
  "inputAnalysis.etc.schedule-settings": "Configuració de l'horari",
  "inputAnalysis.etc.specify_forced_lesson_overlaps": "connecta lliçons",
  "inputAnalysis.issues.description": "Es pot programar automàticament però el resultat no serà òptim. Per tant, recomanem que aquests problemes es resolguin abans de continuar.",
  "inputAnalysis.issues.notification_title": "Les dades de l'horari contenen problemes",
  "inputAnalysis.issues.overloaded group.description": "Serà difícil o impossible programar aquest grup sense cap conflicte d'horari.",
  "inputAnalysis.issues.overloaded group.title": "{{The-entity}} està sobrecarregat amb lliçons.",
  "inputAnalysis.issues.title": "Problemes",
  "inputAnalysis.modules.event intervals.affecting_events": "Lliçons que afecten",
  "inputAnalysis.modules.event intervals.description": "Passeu el ratolí sobre els docents, grups, etc. a continuació per veure com els seus intervals de temps no disponibles, que es mostren al calendari en vermell, afecten la lliçó. També podeu fer el contrari, és a dir, passeu el ratolí sobre el calendari per veure si els docents, grups, etc. corresponents permeten la col·locació de la lliçó. Si el seu fons es torna ratllat, significa que aquesta col·locació no està permesa.",
  "inputAnalysis.modules.event intervals.this event": "Aquesta lliçó",
  "inputAnalysis.modules.overloaded group.contributing events": "Lliçons contribuents",
  "inputAnalysis.modules.overloaded group.description": "El percentatge mostra quant de temps d'un interval de temps est ocupat per les classes. <b>Tots els percentatges superiors al 100% indiquen un conflicte d'horari garantit.</b> <br> <b>Exemple:</b> Si es permet col·locar una classe de 30 minuts en un interval de temps d'una hora, el percentatge de reserva és del 50%. No obstant això, si s'han de col·locar classes equivalents a dues hores en el mateix interval, el percentatge de reserva és del 200%, el que provocarà un conflicte.<br> Errors comuns que poden haver contribuït a que el percentatge de reserva sigui superior al 100%: <ul> <li>Obliar de crear conjunts de classes superposades. Recorda col·locar totes les classes que s'han de impartir en paral·lel en un conjunt de classes superposades.</li> <li>Massa classes al matí / vespre.</li> <li>Massa classes en dies específics.</li></ul>",
  "inputAnalysis.modules.overloaded group.legend.bad": "Mala",
  "inputAnalysis.modules.overloaded group.legend.compact": "Compacte",
  "inputAnalysis.modules.overloaded group.legend.conflict": "Conflicte",
  "inputAnalysis.modules.overloaded group.legend.fixed": "Fixat",
  "inputAnalysis.modules.overloaded group.legend.good": "Bé",
  "inputAnalysis.modules.overloaded group.legend.sparse": "Escàs",
  "inputAnalysis.notices.completely unconnected event.description": "Aquests tipus de lliçons completament desconnectades s'ignoren per l'algorisme ja que no afecten res.",
  "inputAnalysis.notices.completely unconnected event.title": "{{The-entity}} no està connectat a un docent, un estudiant, un grup o una sala.",
  "inputAnalysis.notices.description": "Pot indicar que les dades de l'horari són incompletes.",
  "inputAnalysis.notices.empty course.description": "Els cursos sense classes seran ignorats per l'algorisme.",
  "inputAnalysis.notices.empty course.title": "{{The-entity}} no conté cap classe.",
  "inputAnalysis.notices.empty_period.description": "Has oblidat incloure setmanes en el període?",
  "inputAnalysis.notices.empty_period.title": "{{The-entity}} està buit.",
  "inputAnalysis.notices.missing_overlap_species.description": "Les classes que pertanyen a cursos paral·lels no estan garantides per programar-se al mateix temps després que s'hagi generat un horari, és a dir, a menys que estiguin vinculats.",
  "inputAnalysis.notices.missing_overlap_species.title": "{{The-entities}} {{is/are-parallel-courses}}, però una o més classes associades no estan vinculades.",
  "inputAnalysis.notices.notification_title": "Les dades de l'horari poden ser incompletes.",
  "inputAnalysis.notices.overflowing_period.description": "Has oblidat actualitzar el període després de canviar el període de l'horari?",
  "inputAnalysis.notices.overflowing_period.title": "{{The-entity}} es troba parcialment fora del període de l'horari.",
  "inputAnalysis.notices.title": "Comentaris",
  "inputAnalysis.notices.unconnected event.description": "Has oblidat afegir un docent, un estudiant o un grup?",
  "inputAnalysis.notices.unconnected event.title": "{{The-entity}} no està connectat a un docent, un estudiant o un grup.",
  "inputAnalysis.ok.notification_title": "No hi ha comentaris sobre les dades de l'horari",
  "schedule.guaranteedTuition.label": "Matrícula garantida",
  "schedule.guaranteedTuition.tabs.calendarExceptions.descr": "Aquí podeu afegir vacances i dies lliures. Aquests dies no es tindran en compte quan es calculi el temps total programat.",
  "schedule.guaranteedTuition.tabs.calendarExceptions.form.description.label": "Descripció",
  "schedule.guaranteedTuition.tabs.calendarExceptions.form.range.label": "Rang de dates",
  "schedule.guaranteedTuition.tabs.calendarExceptions.tabLabel": "Afegir dies lliures",
  "schedule.guaranteedTuition.tabs.calendarExceptions.title": "Afegir dies lliures",
  "schedule.guaranteedTuition.tabs.dayCount.descr": "La següent taula mostra el nombre de vegades que apareix cada dia a l'horari, donada la seva data d'inici i finalització, així com els dies lliures especificats anteriorment.",
  "schedule.guaranteedTuition.tabs.dayCount.title": "Resum",
  "schedule.guaranteedTuition.tabs.guaranteedTuition.empty": "No hi ha lliçons per considerar per a la setmana seleccionada. Tingueu en compte que les lliçons han d'estar programades, no només planificades.",
  "schedule.guaranteedTuition.tabs.guaranteedTuition.no_selected_entity": "Seleccioneu un grup, un docent o una assignatura per obtenir un resum del seu temps total programat. Tingueu en compte que només els cursos que tenen una assignatura establerta s'inclouen en els càlculs.",
  "schedule.guaranteedTuition.tabs.guaranteedTuition.tabLabel": "Matrícula garantida",
  "schedule.guaranteedTuition.tabs.guaranteedTuition.table.per_day": "Per dia",
  "schedule.guaranteedTuition.tabs.guaranteedTuition.table.total_count": "Nombre de lliçons",
  "schedule.header": "Horari",
  "schedule.navigation.automate": "Generar<br>horari",
  "schedule.navigation.data": "Dades<br>de l'horari",
  "schedule.navigation.dutyAssignment": "Assignació<br>de deures",
  "schedule.navigation.edit": "Editor<br>de l'horari",
  "schedule.navigation.examine": "Examinar<br>horari",
  "schedule.navigation.guaranteedTuition": "Matrícula<br>garantida",
  "schedule.navigation.lessonSettings": "Configuració<br>de lliçons",
  "schedule.navigation.reports": "Informes/<wbr>Estadístiques",
  "schedule.navigation.scheduleDataAnalysis": "Anàlisi<br>de dades",
  "schedule.navigation.scheduleWarnings": "Advertències<br>de l'horari",
  "schedule.navigation.settings": "Configuració<br>de l'horari",
  "schedule.navigation.substitution_planner": "Planificador<br>de substitucions",
  "schedule.print.header": "Imprimir",
  "schedule.print.page.toolbar.color.descr": "Imprimeix sense color per estalviar tinta d'impressora.",
  "schedule.print.page.toolbar.color.label": "Utilitza colors",
  "schedule.print.page.toolbar.print.descr": "Des del quadre de diàleg d'impressió també és possible guardar el document com a fitxer PDF.",
  "schedule.print.page.toolbar.print.label": "Imprimir",
  "schedule.print.page.toolbar.QR.descr": "L'enllaç condueix al visualitzador web.",
  "schedule.print.page.toolbar.QR.label": "Inclou l'enllaç QR",
  "schema-defects.category.critical.label": "Crític",
  "schema-defects.category.minor.label": "Menor",
  "schema-defects.category.significant.label": "Significatiu",
  "schema-defects.courseClustering": "Agrupació de cursos",
  "schema-defects.courseDurationMismatch": "Durada total de l'esdeveniment",
  "schema-defects.dailyOvertime": "Hores extres diàries",
  "schema-defects.doubleBooking": "Doble reserva",
  "schema-defects.eventDurationOverflow": "Durada de l'esdeveniment",
  "schema-defects.fixed.count.explanation": "Hi ha {{ count }} advertència(s) d'horari d'aquesta categoria que sorgeixen totalment a causa de lliçons fixes.",
  "schema-defects.forcedOverlappingEventsDay": "Lliçons superposades forçades",
  "schema-defects.insufficientBookingMargin": "Marge de temps",
  "schema-defects.insufficientBreakLength": "Durada de la pausa",
  "schema-defects.missingDependency": "Aula que falta",
  "schema-defects.missingGroupsAndTeachers": "Falten docent i grup",
  "schema-defects.non-fixed.count.explanation": "Hi ha {{ count }} advertències d'horari d'aquesta categoria que sorgeixen per altres motius que no siguin les lliçons implicades fixes.",
  "schema-defects.overflow": "Marc de l'horari",
  "schema-defects.overlap": "Col·lisió",
  "schema-defects.overtime": "Hores extres",
  "schema-defects.unavailableDay": "Dia prohibit",
  "settings.index.copy": "Clau API copiada",
  "settings.index.error": "No s'ha pogut crear la clau API",
  "settings.integrationsOut.form.title": "Crea una clau API",
  "settings.integrationsOut.menu": "Exportació API",
  "settings.integrationsOut.page.descr": "Utilitzant la funció següent, podeu crear noves claus API per fer que les dades de l'horari estiguin disponibles per a altres proveïdors de serveis. Poseu-vos en contacte amb l'altre proveïdor i comproveu si poden importar dades d'horaris a través de l'estàndard API SS 12000.",
  "settings.integrationsOut.page.title": "Exportació de dades via API (SS 12000:2020)",
  "settings.integrationsOut.shared.description": "Descripció",
  "settings.integrationsOut.shared.displayName": "Nom",
  "settings.integrationsOut.shared.integrationType": "Plataforma",
  "settings.integrationsOut.table.copyKey": "Copia la clau",
  "settings.integrationsOut.table.createdAt": "Creat",
  "settings.integrationsOut.table.legend": "Claus API",
  "settings.integrationsOut.table.usedAt": "Última activitat",
  "shared.components.schema-filter-dropdowns coalesced": "Afegeix filtre",
  "shared.components.schema-filter-dropdowns search course event": "Cerca lliçó",
  "shared.components.user-menu.to-parent-organization": "Tornar a {{ name }}",
  "shared.dialogs.add-duration-set.label": "Introduïu la durada de la lliçó",
  "shared.dialogs.add-duration-set.title": "Nou conjunt de durades",
  "shared.dialogs.company.description": "Crea i afegeix una escola a la vostra organització.",
  "shared.dialogs.company.title": "Crea una escola",
  "shared.dialogs.confirm-action.title": "Esteu segur?",
  "shared.dialogs.confirm-remove.confirm": "Ho entenc",
  "shared.dialogs.confirm-remove.sub-title": "Aquesta acció no es pot revertir",
  "shared.dialogs.confirm-remove.title": "Confirma la supressió",
  "shared.dialogs.duplicate-schedule.title": "Duplica l'horari",
  "shared.dialogs.generate-schedule.action.submit": "Horari",
  "shared.dialogs.generate-schedule.analysis.flawless.description": "Les vostres dades d'horari s'han analitzat i no hem trobat res a comentar. Bé fet!",
  "shared.dialogs.generate-schedule.analysis.flawless.title": "Preparat",
  "shared.dialogs.generate-schedule.analysis.flaws.description": "Les vostres dades d'horari s'han analitzat i hem trobat els següents {{flaws}} que poden necessitar resolució.",
  "shared.dialogs.generate-schedule.description": "Aquest procés genera un nou horari basat en les dades de l'horari i l'horari actual s'eliminarà. Si voleu guardar l'horari tal com està ara abans de continuar, podeu duplicar-lo.",
  "shared.dialogs.generate-schedule.error": "No s'ha pogut processar la vostra sol·licitud, torneu-ho a intentar en uns minuts. Si el problema persisteix, poseu-vos en contacte amb l'equip d'assistència i us ajudarem encantats.",
  "shared.dialogs.generate-schedule.title": "Programació automàtica",
  "shared.dialogs.login.description": "Per renovar la vostra sessió heu d'iniciar sessió de nou. Si voleu cancel·lar la vostra sessió, simplement tanqueu aquest diàleg.",
  "shared.dialogs.login.title": "Sessió caducada",
  "shared.dialogs.overlappableEventSets.description.forced": "Les lliçons enllaçades s'organitzaran al mateix temps i al centre.",
  "shared.dialogs.overlappableEventSets.description.set": "L'algorisme d'organització automàtica s'esforça per organitzar les lliçons que pertanyen a cursos paral·lels al mateix temps. No obstant això, això no sempre és possible o permès. Per exemple, no està permès que les lliçons d'un curs s'organitzin al mateix temps, llevat que estiguin enllaçades.",
  "shared.dialogs.overlappableEventSets.error.set": "Hi ha lliçons associades que no estan enllaçades.",
  "shared.dialogs.overlappableEventSets.forceOverlap": "Enllaça lliçons",
  "shared.dialogs.overlappableEventSets.gridView": "Vista de graella",
  "shared.dialogs.overlappableEventSets.listView": "Vista de llista",
  "shared.dialogs.overlappableEventSets.new.forced": "Deixa caure una lliçó aquí per formar un nou conjunt de lliçons enllaçades",
  "shared.dialogs.overlappableEventSets.new.set": "Deixa caure un curs aquí per formar un nou conjunt de cursos paral·lels",
  "shared.dialogs.overlappableEventSets.noCourses": "No s'han trobat cursos",
  "shared.dialogs.overlappableEventSets.noEvents": "No s'han trobat lliçons",
  "shared.dialogs.overlappableEventSets.warning.forced": "Ha d'incloure almenys dues lliçons",
  "shared.dialogs.overlappableEventSets.warning.set": "Ha d'incloure almenys dos cursos",
  "shared.dialogs.sync.collections": "Seleccioneu les dades de l'horari",
  "shared.dialogs.sync.error.0": "No s'ha pogut autenticar amb la plataforma seleccionada amb les vostres credencials. Si es tracta d'un error, poseu-vos en contacte amb el servei d'assistència.",
  "shared.dialogs.sync.error.1": "No s'han pogut obtenir les dades de la plataforma seleccionada. Pot ser que hi hagi alguna configuració incorrecta",
  "shared.dialogs.sync.error.3": "No s'ha pogut sincronitzar les dades de l'horari. Un o més alumnes estan connectats a diverses classes. Això no està permès. Poseu-vos en contacte amb l'assistència amb el vostre codi d'error per obtenir ajuda.",
  "shared.dialogs.sync.error.4": "No s'ha pogut sincronitzar les dades de l'horari. Un o més IDs no són únics. Poseu-vos en contacte amb l'assistència amb el vostre codi d'error per obtenir ajuda.",
  "shared.dialogs.sync.last_sync": "Última sincronització",
  "shared.dialogs.sync.modified": "Només es modifica després",
  "shared.dialogs.sync.platform": "Seleccioneu la plataforma",
  "shared.dialogs.sync.synced_data": "Dades d'horari modificades",
  "shared.dialogs.sync.time": "Seleccioneu període",
  "shared.dialogs.sync.title": "Sincronitza el teu horari",
  "shared.dialogs.sync.validation": "Validar les dades de l'horari",
  "shared.dialogs.sync.validation.title": "Hi ha alguns problemes amb les dades de l'horari. Si us plau, resolgueu-los abans de continuar.",
  "shared.dialogs.sync.validation.indistinguishable.courses": "amb el nom \"{{name}}\" comparteixen el mateix nom, docents i grups.",
  "shared.dialogs.sync.validation.indistinguishable.events": "pertanyent al curs \"{{name}}\" comparteixen els mateixos docents i grups i temps.",
  "shared.dialogs.sync.validation.ids_missing": "falten les IDs",
  "shared.dialogs.sync.validation.ids_not_unique": "tenen IDs idèntiques: \"{{ids}}\"",
  "shared.dialogs.sync.validation.valid": "Les dades són vàlides",
  "shared.dialogs.sync.push_success.title": "Sincronització correcta",
  "shared.dialogs.sync.push_success.description": "Les dades de l'horari s'han sincronitzat correctament amb la plataforma seleccionada. Vés a la plataforma per veure els canvis.",
  "shared.dialogs.unrecoverable-app-state.description": "L'aplicació ha entrat en un estat irrecuperable. Torneu a carregar la pàgina per continuar.",
  "shared.dialogs.unrecoverable-app-state.title": "Estat irrecuperable",
  "shared.dialogs.unsaved-changes.description": "Hi ha canvis no desats. Voleu desar-los o descartar-los?",
  "shared.dialogs.unsaved-changes.title": "Canvis no desats",
  "shared.dialogs.upload-file.configure.format_specific": "Configuració específica del format",
  "shared.dialogs.upload-file.configure.label": "Configura",
  "shared.dialogs.upload-file.configure.PlanDigital.displayName.label": "Utilitza com a nom del curs",
  "shared.dialogs.upload-file.configure.PlanDigital.periods.label": "Selecciona els períodes a importar",
  "shared.dialogs.upload-file.configure.PlanDigital.periods.placeholder": "Cal seleccionar almenys un període",
  "shared.dialogs.upload-file.configure.SchoolSoft.interval.descr": "Exclou les classes que no estan programades durant el període seleccionat ({{start}} - {{end}}).",
  "shared.dialogs.upload-file.configure.SchoolSoft.interval.label": "Filtra els cursos basant-te en el període seleccionat",
  "shared.dialogs.upload-file.configure.Skola24.deduceGroupsFromCourseName.descr": "Intenta identificar i afegir els grups que es troben en el nom del curs si estan separats per un guió, com ara 10FYS01a-<b>NA20E</b>-LABb o 10-AH-<b>EK21C</b>.",
  "shared.dialogs.upload-file.configure.Skola24.deduceGroupsFromCourseName.label": "Deduïu els grups del nom del curs",
  "shared.dialogs.upload-file.configure.Skola24.ignoreEmptyCourses.label": "Ignora els cursos sense classes",
  "shared.dialogs.upload-file.configure.Skola24.removeUnreferencedGroups.descr": "Els grups que no es troben en almenys un curs o una classe s'ignoraran.",
  "shared.dialogs.upload-file.configure.Skola24.removeUnreferencedGroups.label": "Ignora els grups no utilitzats",
  "shared.dialogs.upload-file.configure.Skola24.removeUnreferencedTeachers.descr": "Els docents que no es troben en almenys un curs o una classe s'ignoraran.",
  "shared.dialogs.upload-file.configure.Skola24.removeUnreferencedTeachers.label": "Ignora els docents no utilitzats",
  "shared.dialogs.upload-file.configure.Skola24.week.ignoreAllEvents": "Ignora totes les classes",
  "shared.dialogs.upload-file.configure.Skola24.week.label": "Importa les classes de la setmana seleccionada",
  "shared.dialogs.upload-file.finalize.content": "Contingut de l'horari",
  "shared.dialogs.upload-file.finalize.error": "No s'ha pogut processar el fitxer seleccionat",
  "shared.dialogs.upload-file.finalize.errors_and_warnings": "S'han trobat errors i avisos",
  "shared.dialogs.upload-file.finalize.label": "Finalitza",
  "shared.dialogs.upload-file.label": "Puja un fitxer",
  "shared.dialogs.upload-file.select-file.download_template": "Descarrega la plantilla d'Excel (opcional)",
  "shared.dialogs.upload-file.select-file.label": "Selecciona un fitxer",
  "shared.dialogs.upload-file.select-map.determined": "Coincidència exacta",
  "shared.dialogs.upload-file.select-map.error": "No s'ha pogut identificar el format del fitxer",
  "shared.dialogs.upload-file.select-map.label": "Selecciona el format",
  "shared.dialogs.upload-file.select-map.strong": "Format(s) probable(s)",
  "shared.dialogs.upload-file.select-map.unknown": "Format desconegut",
  "shared.dialogs.upload-file.select-map.weak": "Format(s) similar(s)",
  "shared.dialogs.users.description": "Escriviu una adreça de correu electrònic i seleccioneu una escola per crear un inici de sessió a l'escola seleccionada.",
  "shared.dialogs.users.title": "Creeu un usuari",
  "shared.dialogs.version.current_version": "Versió actual",
  "shared.dialogs.version.description": "Hi ha disponible una nova versió de l'aplicació. Torneu a carregar la pàgina per obtenir la versió més recent.",
  "shared.dialogs.version.description_hard_reload": "A vegades, una recàrrega normal no és suficient i es requereix una recàrrega manual. Normalment, això es fa prement <kbd>Ctrl</kbd> + <kbd>Shift</kbd> + <kbd>R</kbd> a Windows o <kbd>Cmd</kbd> + <kbd>Shift</kbd> + <kbd>R</kbd> a Mac, però probablement depèn del vostre navegador.",
  "shared.dialogs.version.latest_version": "Última versió",
  "shared.dialogs.version.title": "Nova versió disponible",
  "shared.form-fields.available-locations.empty-repository": "No hi ha sales disponibles",
  "shared.form-fields.available-locations.groups": "Grup de sales",
  "shared.form-fields.available-locations.locations": "Sales",
  "shared.form-fields.available-locations.select-null": "Sense sala",
  "shared.form-fields.available-locations.selected-null": "Sense sala",
  "shared.form-fields.components.schema-filter-dropdowns search course event": "Cerca la lliçó",
  "shared.form-fields.dynamic-locked-times.duration.tooltip": "La durada preferida i la variació màxima de la durada de la pausa per dinar. L'algorisme de programació seleccionarà una durada adequada dins de l'interval especificat, però mantindrà la durada combinada de totes les pauses per dinar igual a la seva durada preferida sumada.",
  "shared.form-fields.fixed-start.disabled": "Cap",
  "shared.form-fields.fixed-start.enabled": "Hora d'inici fixa",
  "shared.form-fields.groups.selection-warning": "un o més estudiants d'aquest grup estan seleccionats per separat",
  "shared.form-fields.periods.no-periods": "No hi ha períodes per seleccionar. Afegiu períodes a l'horari.",
  "shared.form-fields.root-intervals.no-root-intervals": "No hi ha blocs per seleccionar. Afegiu blocs a l'horari.",
  "shared.forms.change-password-form.current_password": "Contrasenya actual",
  "shared.forms.change-password-form.new_password": "Contrasenya nova",
  "shared.forms.change-password-form.repeat_password": "Repetiu la contrasenya",
  "shared.forms.company.name": "Nom",
  "shared.forms.company.organization": "Organització principal",
  "shared.forms.company.organizationType": "Tipus d'organització",
  "shared.forms.company.schoolCode": "Codi",
  "shared.forms.company.theme": "Tema",
  "shared.forms.users.company": "Seleccioneu l'escola",
  "shared.forms.users.username": "Correu electrònic",
  "source.set_fail": "No s'ha pogut actualitzar el valor",
  "source.set_success": "Actualització correcta",
  "source.set_timeout": "Temps d'espera d'actualització",
  "source.validation_fail": "Actualització no vàlida",
  "substitutions.are_parallel_courses": "són cursos paral·lels",
  "substitutions.is_a_parallel_course": "és un curs paral·lel",
  "table.itemsPerPageLabel": "Files per pàgina",
  "table.nextPageLabel": "Pàgina següent",
  "table.previousPageLabel": "Pàgina anterior",
  "tables.admin-tables.divisions.actions.columns.displayName": "Nom",
  "tables.admin-tables.divisions.actions.columns.id": "ID",
  "tables.admin-tables.divisions.actions.columns.organization": "Organització",
  "tables.admin-tables.divisions.actions.delete": "Elimina l'horari",
  "tables.admin-tables.divisions.actions.delete.failure": "No s'ha pogut eliminar l'horari",
  "tables.admin-tables.divisions.actions.download": "Descarrega l'horari",
  "tables.admin-tables.divisions.actions.login": "Inicia sessió a l'horari",
  "tables.admin-tables.divisions.actions.login.failure": "No s'ha pogut iniciar sessió",
  "tables.admin-tables.organizations.actions.add": "Crea una organització",
  "tables.admin-tables.organizations.actions.configure_environment": "Configura l'entorn de càlcul",
  "tables.admin-tables.organizations.actions.configure_identifiers": "Configura els identificadors",
  "tables.admin-tables.organizations.actions.delete": "Elimina l'organització",
  "tables.admin-tables.organizations.actions.delete.failure": "No s'ha pogut eliminar l'organització",
  "tables.admin-tables.organizations.actions.edit": "Editar organización",
  "tables.admin-tables.organizations.actions.generate_access_key": "Genera una clau d'accés",
  "tables.admin-tables.organizations.actions.login": "Inicia sessió a l'organització",
  "tables.admin-tables.organizations.actions.login.failure": "No s'ha pogut iniciar la sessió",
  "tables.admin-tables.organizations.add-edit.actions.add.failure": "No s'ha pogut crear l'organització",
  "tables.admin-tables.organizations.add-edit.labels.name": "Nom",
  "tables.admin-tables.organizations.add-edit.labels.organization": "Organització pare",
  "tables.admin-tables.organizations.add-edit.labels.organization_type": "Tipus d'organització",
  "tables.admin-tables.organizations.add-edit.labels.school_code": "Codi de centre",
  "tables.admin-tables.organizations.add-edit.labels.theme": "Tema",
  "tables.admin-tables.organizations.add-edit.title.add": "Crea una organització",
  "tables.admin-tables.organizations.add-edit.title.edit": "Editar organización",
  "tables.admin-tables.organizations.columns.divisions": "No. horaris",
  "tables.admin-tables.organizations.columns.environment": "Entorn",
  "tables.admin-tables.organizations.columns.identifiers": "No. identificadors",
  "tables.admin-tables.organizations.columns.name": "Nom",
  "tables.admin-tables.organizations.columns.organization": "Organització pare",
  "tables.admin-tables.organizations.columns.organizationType": "Tipus d'organització",
  "tables.admin-tables.organizations.columns.theme": "Tema",
  "tables.admin-tables.organizations.columns.version": "Versió de l'algorisme",
  "tables.admin-tables.organizations.configure-compute-environment.title": "Configura l'entorn de càlcul",
  "tables.admin-tables.organizations.configure-identifiers.actions.add.failure": "No s'ha pogut afegir l'identificador",
  "tables.admin-tables.organizations.configure-identifiers.actions.delete.failure": "No s'ha pogut eliminar l'identificador",
  "tables.admin-tables.organizations.configure-identifiers.identifier-types.school_code": "Codi de centre",
  "tables.admin-tables.organizations.configure-identifiers.identifier-types.vat": "NIF",
  "tables.admin-tables.organizations.configure-identifiers.title": "Configura els identificadors",
  "tables.admin-tables.organizations.app_features.substitution_planner": "Activa el planificador de substitucions",
  "tables.admin-tables.organizations.app_features.title": "Gestiona les funcionalitats de l'aplicació",
  "tables.admin-tables.users.actions.add": "Crea un usuari",
  "tables.admin-tables.users.actions.copy_verification_link": "Copia l'enllaç d'activació",
  "tables.admin-tables.users.actions.delete": "Elimina l'usuari",
  "tables.admin-tables.users.actions.delete.failure": "No s'ha pogut eliminar l'usuari",
  "tables.admin-tables.users.actions.edit": "Editar usuario",
  "tables.admin-tables.users.actions.login": "Inicia sessió a l'organització de l'usuari",
  "tables.admin-tables.users.actions.login.failure": "No s'ha pogut iniciar sessió",
  "tables.admin-tables.users.actions.mail": "Envia un correu electrònic d'activació a l'usuari",
  "tables.admin-tables.users.actions.mail.failure": "No s'ha pogut enviar el correu electrònic d'activació",
  "tables.admin-tables.users.actions.mail.success": "S'ha enviat el correu electrònic d'activació",
  "tables.admin-tables.users.add-edit.actions.add.failure": "No s'ha pogut crear l'usuari",
  "tables.admin-tables.users.add-edit.actions.fetch_companies.failure": "No s'han pogut obtenir les empreses",
  "tables.admin-tables.users.add-edit.actions.fetch_from_skolverket.failure": "No s'ha pogut obtenir la informació de Skolverket",
  "tables.admin-tables.users.add-edit.labels.organization": "Organització",
  "tables.admin-tables.users.add-edit.labels.role": "Rol d'usuari",
  "tables.admin-tables.users.add-edit.labels.username": "Nom d'usuari (correu electrònic)",
  "tables.admin-tables.users.add-edit.title.add": "Crea un usuari",
  "tables.admin-tables.users.add-edit.title.edit": "Editar usuario",
  "tables.admin-tables.users.columns.activation": "Enllaç d'activació",
  "tables.admin-tables.users.columns.last_login": "Últim inici de sessió",
  "tables.admin-tables.users.columns.online": "En línia",
  "tables.admin-tables.users.columns.organization": "Organització",
  "tables.admin-tables.users.columns.role": "Rol d'usuari",
  "tables.admin-tables.users.columns.username": "Nom d'usuari",
  "tables.components.custom-search.empty": "Tingueu en compte que desactivar la cerca de totes les columnes donarà com a resultat resultats de cerca buits.",
  "tables.components.empty.hint": "Creeu-ne un fent clic al botó \"+\" a la cantonada superior dreta",
  "tables.components.empty.title": "Encara no existeixen {{ entities }}",
  "tables.components.no-columns.hint": "Feu clic al botó \"{{ button }}\" a la cantonada superior dreta per seleccionar quines columnes voleu mostrar",
  "tables.components.no-columns.title": "No hi ha columnes de taula visibles",
  "tables.courses.values.overridden": "El valor no és heretat per totes les lliçons del curs",
  "tables.notifications.alerts.error.title": "No s'ha pogut actualitzar les preferències",
  "tables.notifications.alerts.success.descr": "Si hi ha altres pestanyes obertes, cal recarregar-les perquè els canvis tinguin lloc.",
  "tables.notifications.alerts.success.title": "Preferències actualitzades correctament",
  "tables.notifications.columns.category.descr": "Les notificacions de cada categoria es mostren juntes.",
  "tables.notifications.columns.category.name": "Categoria",
  "tables.notifications.columns.category.values.generalConfirmation": "Confirmacions generals",
  "tables.notifications.columns.category.values.generalError": "Errors generals",
  "tables.notifications.columns.category.values.inputAnalysisError": "Analitzador d'entrada: errors crítics",
  "tables.notifications.columns.category.values.inputAnalysisNotice": "Analitzador d'entrada: avisos",
  "tables.notifications.columns.category.values.inputAnalysisWarning": "Analitzador d'entrada: advertències",
  "tables.notifications.columns.category.values.other": "Altres",
  "tables.notifications.columns.location.descr": "On a la pantalla on apareixeran les notificacions d'aquesta categoria.",
  "tables.notifications.columns.location.name": "Ubicació",
  "tables.notifications.columns.location.values.bottom left": "Cantonada inferior esquerra",
  "tables.notifications.columns.location.values.bottom right": "Cantonada inferior dreta",
  "tables.notifications.columns.location.values.top left": "Cantonada superior esquerra",
  "tables.notifications.columns.location.values.top right": "Cantonada superior dreta",
  "tables.notifications.columns.mode.descr": "Si aquestes notificacions estan activades, silenciades o desactivades.",
  "tables.notifications.columns.mode.name": "Mode",
  "tables.notifications.columns.mode.values.mute": "Silencia",
  "tables.notifications.columns.mode.values.off": "Desactiva",
  "tables.notifications.columns.mode.values.on": "Activa",
  "tables.notifications.columns.sound.descr": "Quin so sonarà quan es rebi una notificació.",
  "tables.notifications.columns.sound.name": "So",
  "tables.notifications.columns.test.descr": "Prova enviant una notificació fictícia.",
  "tables.notifications.columns.test.name": "Prova",
  "tables.notifications.columns.test.value": "envia",
  "tables.notifications.columns.volume.descr": "Volum del so de la notificació.",
  "tables.notifications.columns.volume.name": "Volum",
  "validators.email": "No és un correu electrònic vàlid",
  "validators.equal": "Els valors no coincideixen",
  "validators.invalidDate": "Data no vàlida",
  "validators.invalidDiscretization": "Ha de ser un múltiple de 5 minuts",
  "validators.invalidLaterDate": "Ha de ser una data posterior",
  "validators.invalidMultiple": "Ha de ser un múltiple de 5",
  "validators.invalidTime": "Hora no vàlida (intervals de 5 min)",
  "validators.matDatepickerMax": "Ha de seleccionar una data anterior",
  "validators.matDatepickerMin": "Ha de seleccionar una data posterior",
  "validators.matEndDateInvalid": "Interval de dates no vàlid",
  "validators.matStartDateInvalid": "Interval de dates no vàlid",
  "validators.max": "Aquest valor és massa alt",
  "validators.min": "Aquest valor és massa baix",
  "validators.minLength": "Massa curt",
  "validators.minlength": "Massa curt",
  "validators.noMatch": "Correu electrònic o contrasenya incorrectes",
  "validators.outOfBounds": "El valor està fora de límits",
  "validators.required": "Aquest camp és obligatori",
  "validators.time_too_early": "Hora massa primerenca",
  "validators.time_too_late": "Hora massa tardana",
  "validators.usernameOccupied": "El correu electrònic està ocupat",
  "view.administrator.organizations.table.add": "Crea una escola",
  "view.administrator.organizations.table.createdAt": "Creat el",
  "view.administrator.organizations.table.header": "Escoles",
  "view.administrator.organizations.table.login": "Entra en aquesta escola",
  "view.administrator.organizations.table.name": "Nom",
  "view.administrator.page.menu.organizations": "Les meves escoles",
  "view.administrator.users.table.add": "Crea un usuari",
  "view.administrator.users.table.administrator": "Administrador",
  "view.administrator.users.table.company": "Escola/Organització",
  "view.administrator.users.table.createdAt": "Creat el",
  "view.administrator.users.table.header": "Comptes d'usuari",
  "view.administrator.users.table.login": "Inicia sessió en aquesta escola",
  "view.administrator.users.table.mail.fail": "No s'ha pogut enviar l'enllaç d'activació a l'usuari",
  "view.administrator.users.table.mail.success": "Enllaç d'activació enviat a l'usuari",
  "view.administrator.users.table.mail.tooltip": "Envia l'enllaç d'activació a l'usuari",
  "view.administrator.users.table.restricted": "Restringit",
  "view.administrator.users.table.role": "Rol d'usuari",
  "view.administrator.users.table.unrestricted": "Planificador",
  "view.administrator.users.table.username": "Correu electrònic",
  "view.demo.schedule.components.editor_contact_sub_title": "Per saber més sobre el nostre producte, omple el teu correu electrònic i ens posarem en contacte amb tu.",
  "view.demo.schedule.components.editor_contact_title": "El calendari està acabat",
  "view.demo.schedule.components.introduction_contact_submit": "Estic interessat",
  "view.demo.schedule.components.introduction_slide_1": "Benvingut!",
  "view.demo.schedule.components.introduction_slide_2": "Hem preomplert les dades del calendari per a una escola sueca de mida mitjana.",
  "view.demo.schedule.components.introduction_slide_3": "L'únic que queda és fer clic a \"iniciar la planificació automàtica\", esperar un parell de minuts i ja tindràs el teu calendari creat.",
  "view.demo.schedule.page.exit": "Sortir de la demostració",
  "view.demo.schedule.page.restart": "Reiniciar la demostració",
  "view.partner.page.menu.divisions": "Horaris",
  "view.partner.page.menu.organizations": "Organitza&shy;cions",
  "view.partner.page.menu.users": "Usuaris",
  "view.private.integrations.in.connectionTo": "Connexió a",
  "view.private.integrations.in.descr": "A continuació trobaràs possibles integracions d'API per intercanviar informació amb proveïdors de serveis externs.",
  "view.private.integrations.in.error.get": "No s'ha pogut obtenir les dades del calendari. Si el problema persisteix, contacteu amb l'equip d'assistència.",
  "view.private.integrations.in.error.postOrPatch": "No s'han pogut guardar els detalls de la integració. Si el problema persisteix, contacteu amb l'equip d'assistència.",
  "view.private.integrations.in.import": "Importa les dades",
  "view.private.integrations.in.menu": "Integracions",
  "view.private.integrations.in.no_integrations": "Sense integracions",
  "view.private.integrations.in.SchoolSoftDialog.tab1.title": "Nom i període",
  "view.private.integrations.in.SchoolSoftDialog.tab2.descr": "Actualment no és possible importar les aules directament a través de l'API de SchoolSoft. No obstant això, fins que això es resolgui, es poden importar copiant i enganxant les dades corresponents en un format separat per pestanyes o comes, que podeu trobar <a href=\"https://sms.schoolsoft.se/{{webapp}}/jsp/admin/right_admin_import_room.jsp?menu=admin_import_room\" target=\"_blank\">aquí</a>.",
  "view.private.integrations.in.SchoolSoftDialog.tab2.error": "No s'ha pogut analitzar el text",
  "view.private.integrations.in.SchoolSoftDialog.tab2.label": "Enganxa el text de la taula aquí",
  "view.private.integrations.in.SchoolSoftDialog.tab2.title": "Importa les aules",
  "view.private.integrations.in.setupDialog.key": "Clau d'API",
  "view.private.integrations.in.setupDialog.meta.schoolapp": "schoolapp",
  "view.private.integrations.in.setupDialog.meta.schoolId": "ID de l'escola",
  "view.private.integrations.in.setupDialog.meta.webapp": "webapp",
  "view.private.integrations.in.setupDialog.startsWith": "Comença amb {{value}}",
  "view.private.integrations.in.setupDialog.title": "Detalls de la integració",
  "view.private.integrations.in.success": "S'ha importat correctament el calendari",
  "view.private.integrations.in.title": "Integracions via API",
  "view.private.integrations.in.tooltip.delete": "Suprimeix els detalls de la integració",
  "view.private.integrations.in.tooltip.modify": "Modifica els detalls de la integració",
  "view.private.integrations.in.validation.error.401": "Detalls de la integració no vàlids",
  "view.private.integrations.in.validation.error.404": "Detalls de la integració no vàlids",
  "view.private.integrations.in.validation.error.500": "No s'han pogut validar els detalls de la integració",
  "view.private.integrations.in.validation.error.SchoolSoft.key": "Clau d'API no vàlida",
  "view.private.integrations.in.validation.error.SchoolSoft.schoolapp": "Valor schoolapp no vàlid",
  "view.private.integrations.in.validation.error.SchoolSoft.webapp": "Valor webapp no vàlid",
  "view.private.integrations.in.validation.error.unknown": "No s'han pogut validar els detalls de la integració",
  "view.private.integrations.in.validation.valid": "Detalls de la integració vàlids",
  "view.private.integrations.in.validation.validating": "Validant els detalls de la integració",
  "view.private.menu.schema": "Horaris",
  "view.private.menu.settings": "Configuració",
  "view.private.menu.tutorial": "Tutorial",
  "view.private.menu.users": "Usuaris",
  "view.private.schema.components.admentum.subtitle": "Exporta a Admentum",
  "view.private.schema.components.failed-generation-dialog.description": "S'ha produït un error durant el procés d'assignació automàtica i estem treballant en una solució. Si us plau, torneu-ho a intentar més tard durant el dia o poseu-vos en contacte amb el suport si voleu rebre una notificació quan el problema estigui resolt.",
  "view.private.schema.components.failed-generation-dialog.title": "La programació automàtica ha fallat",
  "view.private.schema.components.subtitle": "Exporta a",
  "view.private.schema.components.idunsoft.subtitle": "Exporta a IdunSoft",
  "view.private.schema.components.infomentor.subtitle": "Exporta a InfoMentor",
  "view.private.schema.components.link.subtitle": "Copia l'enllaç a aquest horari",
  "view.private.schema.components.link.success": "Enllaç copiat",
  "view.private.schema.components.link.title": "Visor web",
  "view.private.schema.components.royalschedule.subtitle": "Exporta a Royal Schedule",
  "view.private.schema.components.schoolsoft_displayName.subtitle": "Exporta a SchoolSoft usant noms com a referències",
  "view.private.schema.components.schoolsoft.subtitle": "Exporta a SchoolSoft",
  "view.private.schema.components.table.actions": "Accions",
  "view.private.schema.components.table.basis": "Dades de l'horari",
  "view.private.schema.components.table.clone": "Duplica l'horari",
  "view.private.schema.components.table.createdAt": "Creat",
  "view.private.schema.components.table.editedAt": "Última modificació",
  "view.private.schema.components.table.header": "Els meus horaris",
  "view.private.schema.components.table.interval": "Durada",
  "view.private.schema.components.table.link": "enllaç al visor web",
  "view.private.schema.components.table.name": "Nom de l'horari",
  "view.private.schema.components.table.public": "Públic",
  "view.private.schema.edit.toolbar.colorDescription": "Descripció del color",
  "view.private.schema.edit.toolbar.selectedPeriods": "Períodes inclosos",
  "view.private.schema.editor.components.user-manual.title": "Manual d'usuari",
  "view.private.schema.page.create": "Crea un horari",
  "view.private.schema.page.download_fail": "No s'ha pogut baixar la programació",
  "view.private.schema.page.header": "Horaris",
  "view.private.schema.page.sync": "Sincronitza les dades",
  "view.private.schema.page.upload": "Puja un fitxer",
  "view.private.schema.sub-pages.schema-editor.components.event-form.fix-start": "Fixa el dia i l'hora d'inici",
  "view.private.schema.sub-pages.schema-editor.components.event-form.force-overlap": "Enllaça les classes",
  "view.private.schema.sub-pages.schema-editor.components.event-form.make-invisible": "Amaga en el visor d'horaris",
  "view.private.schema.sub-pages.schema-editor.components.event-form.make-visible": "Mostra en el visor d'horaris",
  "view.private.schema.sub-pages.schema-editor.components.event-form.park": "Aparca",
  "view.private.schema.sub-pages.schema-editor.components.event-form.remove-force-overlap": "Desenllaça les classes",
  "view.private.schema.sub-pages.schema-editor.components.event-form.unfix-start": "Desfixa el dia i l'hora d'inici",
  "view.private.schema.sub-pages.schema-editor.components.list.filter": "Filtra les classes",
  "view.private.schema.sub-pages.schema-editor.components.list.filter.not_placed": "Classes aparcades",
  "view.private.schema.sub-pages.schema-editor.components.list.filter.period": "Només períodes inclosos",
  "view.private.schema.sub-pages.schema-editor.components.list.filter.placed": "Classes col·locades",
  "view.private.schema.sub-pages.schema-editor.components.progress.info_PENDING": "El procés d'horarització s'ha posat en cua. Començarà en un parell de minuts",
  "view.private.schema.sub-pages.schema-editor.components.progress.info_STARTED": "El procés d'horarització ha començat. Pot trigar un parell de minuts abans que es completi.",
  "view.private.schema.sub-pages.schema-editor.page.limited_state": "L'anàlisi de col·lisions està desactivat",
  "view.private.schema.sub-pages.schema-editor.page.start_job": "Inicia l'horarització automàtica",
  "view.private.schema.sub-pages.settings.components.default-values-form.fieldset.break-lengths": "Durada mínima de les pauses",
  "view.private.schema.sub-pages.settings.components.default-values-form.fieldset.other": "Altres",
  "view.private.schema.sub-pages.settings.components.default-values-form.fieldset.time-frame": "Marc de temps",
  "view.private.schema.sub-pages.settings.components.default-values-form.fieldset.working-hours": "Hores de treball",
  "view.private.schema.sub-pages.settings.components.options-form.fieldset.overlappable-lessons-set": "Classes enllaçades",
  "view.private.schema.sub-pages.settings.components.options-form.requireForcedOverlappingEventsSets.description": "Si està activat, les classes pertanyents a cursos paral·lels han d'estar enllaçades. Les classes enllaçades estan garantides que estaran programades al mateix temps després que s'hagi generat un horari. Les classes pertanyents a cursos paral·lels que <b>no</b> estan enllaçades normalment es permet que estiguin programades al mateix temps, però aquest resultat no està garantit després que s'hagi generat un horari.",
  "view.private.schema.sub-pages.settings.components.options-form.requireForcedOverlappingEventsSets.label": "Requereix classes enllaçades",
  "view.private.schema.sub-pages.settings.page.tab.default-values": "Valors per defecte",
  "view.private.schema.sub-pages.settings.page.tab.options": "Opcions",
  "view.private.schema.sub-pages.settings.page.tab.periods": "Períodes",
  "view.private.schema.sub-pages.settings.page.tab.rootIntervals": "Blocs",
  "view.private.schema.sub-pages.statistics.duty-assignments.empty": "No hi ha dades disponibles",
  "view.private.schema.sub-pages.statistics.duty-assignments.header": "Assignacions de càrrega",
  "view.private.schema.sub-pages.statistics.duty-assignments.min_of_min_per_week": "{{current}} de {{total}}  min/setmana",
  "view.private.schema.sub-pages.statistics.duty-assignments.min_per_week": "Min/setmana",
  "view.private.schema.sub-pages.tooltips.locked": "Mentre s'està generant un horari automàticament, està bloquejat per a modificacions.",
  "view.private.settings.language.label": "Idioma",
  "view.private.settings.language.title": "Idioma",
  "view.private.settings.menu.sync": "Sincronització",
  "view.private.settings.sync.button.select-schedule": "Selecciona l'horari",
  "view.private.settings.sync.components.table.sync_toggle": "Sincronització activada",
  "view.private.settings.sync.descr": "Utilitzant les funcions que hi ha a continuació, pots activar i desactivar les plataformes externes per sincronitzar les teves dades. Si la sincronització està activada, les teves dades seran enviades a la(s) plataforma(s) que hagis permès. Això es pot desactivar en qualsevol moment.",
  "view.private.settings.sync.in": "Horaris que obtenen actualitzacions de plataformes externes",
  "view.private.settings.sync.no-selected-out": "Selecciona un horari perquè les teves plataformes externes hi accedeixin.",
  "view.private.settings.sync.out": "Horaris actius",
  "view.private.settings.sync.tab.add-platforms": "Afegeix una plataforma",
  "view.private.settings.sync.tab.my-platforms": "Les meves plataformes",
  "view.private.settings.sync.title": "Integracions de plataformes",
  "view.private.settings.sync.tooltip.REJECTED": "La sol·licitud per crear una sincronització entre les nostres plataformes ha estat rebutjada. Contacta amb l'equip de suport si això és un error.",
  "view.private.tutorial.page.advanced": "Avançat",
  "view.private.tutorial.page.basic": "Bàsics",
  "view.private.tutorial.page.description.2.1": "Restriccions dels docents",
  "view.private.tutorial.page.description.2.2": "Crea lliçons i varia la seva durada",
  "view.private.tutorial.page.description.2.3": "Afegeix aules permeses",
  "view.private.tutorial.page.description.2.4": "Cursos paral·lels i lliçons enllaçades",
  "view.private.tutorial.page.description.2.5": "Anàlisi de les dades de l'horari",
  "view.private.tutorial.page.description.2.6": "Ajustaments iteratius",
  "view.private.tutorial.page.description.api": "Sincronitza les dades",
  "view.private.tutorial.page.description.bulk-update": "Aprèn a editar en massa",
  "view.private.tutorial.page.description.courses": "Aprèn a afegir cursos i esdeveniments",
  "view.private.tutorial.page.description.create": "Crea un horari",
  "view.private.tutorial.page.description.excel": "Puja un fitxer d'Excel",
  "view.private.tutorial.page.description.groups": "Aprèn a afegir grups",
  "view.private.tutorial.page.description.in-locations": "Aprèn a modificar les aules permeses",
  "view.private.tutorial.page.description.locations": "Aprèn a afegir aules",
  "view.private.tutorial.page.description.locked-times": "Aprèn a afegir intervals de temps prohibitius",
  "view.private.tutorial.page.description.teacher": "Aprèn a afegir docents",
  "view.private.tutorial.page.description.time-frame": "Gestiona el marc de temps dels horaris",
  "view.private.tutorial.page.description.upload_json": "Puja un fitxer RS",
  "view.private.tutorial.page.filter.category": "Categoria",
  "view.private.tutorial.page.filter.category.adjustments": "Ajustaments",
  "view.private.tutorial.page.filter.category.conditions": "Condicions",
  "view.private.tutorial.page.filter.category.data": "Dades de l'horari",
  "view.private.tutorial.page.filter.category.edit": "Programació manual",
  "view.private.tutorial.page.filter.category.education": "Educació",
  "view.private.tutorial.page.filter.category.export": "Exporta les dades de l'horari",
  "view.private.tutorial.page.filter.category.import": "Importa les dades de l'horari",
  "view.private.tutorial.page.filter.category.inputAnalyzer": "Analitzador d'entrada",
  "view.private.tutorial.page.filter.category.other": "Altres",
  "view.private.tutorial.page.filter.category.settings": "Configuració",
  "view.private.tutorial.page.filter.category.warnings": "avisos",
  "view.private.tutorial.page.filter.level": "Dificultat",
  "view.private.tutorial.page.filter.level.advanced": "Avançat",
  "view.private.tutorial.page.filter.level.beginner": "Principiant",
  "view.private.tutorial.page.filter.level.intermediate": "Intermedi",
  "view.private.tutorial.page.header": "Tutorial",
  "view.private.tutorial.page.type.video": "Guia en vídeo",
  "view.public.activation.approved": "Aprovat",
  "view.public.activation.expired": "Enllaç caducat",
  "view.public.activation.invalid": "Enllaç no vàlid",
  "view.public.activation.verifying": "Verificant...",
  "view.public.demo.valid_config": "Configurant l'entorn",
  "view.public.login.fail": "No s'ha pogut iniciar la sessió",
  "view.public.login.password": "Contrasenya",
  "view.public.login.status_email_required": "Cal un correu electrònic vàlid",
  "view.public.login.status_invalid_credentials": "Credencials no vàlides",
  "view.public.login.status_invalid_email_format": "Format de correu electrònic no vàlid",
  "view.public.login.status_password_required": "Cal una contrasenya",
  "view.public.login.status_reset_password_fail": "No s'ha pogut enviar l'enllaç de restabliment",
  "view.public.login.status_reset_password_success": "Enllaç de restabliment enviat a {{email}} si existeix un usuari amb aquest correu",
  "view.public.login.username": "Correu electrònic",
  "view.public.web-viewer.not_public": "L'horari no és públic",
  "view.void.status": "Validant credencials",
  "account.advanced.menu": "Avançat",
  "account.advanced.page.title": "Avançat",
  "account.advanced.page.descr": "Configuració i accions més avançades que poden ser útils.",
  "account.advanced.page.clear-all-and-reload.label": "Neteja totes les dades i recarrega",
  "account.advanced.page.clear-all-and-reload.description": "Això netejarà totes les dades de l'aplicació, finalitzarà tots els processos i recarregarà totes les pestanyes obertes de l'aplicació simultàniament. Això pot ser útil si teniu problemes amb l'aplicació. Tingueu en compte que això finalitzarà la vostra sessió d'autenticació i haureu d'iniciar sessió de nou.",
  "account.advanced.page.clear-all-and-reload.action": "Neteja i recarrega",
  "view.private.schema.sub-pages.examine.page.before_select": "Selecciona els grups, els docents i les aules que vols comparar. Pots triar quin dia i possiblement quina setmana vols mostrar.",
  "common.show_remaining": "Mostra el que queda",
  "common.from_time": "De",
  "common.to_time": "A",
  "shared.dialogs.edit-course-and-event.title": "Editar",
  "common.parallel_courses": "Cursos paral·lels",
  "common.open_tutorial": "Obre el tutorial",
  "common.second": "Segon",
  "common.second_short": "s",
  "shared.components.svg-schedule.generating.title": "Generant horaris imprimibles",
  "shared.components.svg-schedule.generating.completed_n_of_m": "{{n}} de {{m}} completats",
  "shared.components.svg-schedule.generating.estimated_time_remaining": "Temps estimat restant: {{time}}",
  "schedule.print.page.toolbar.print_date.label": "Data d'impressió",
  "schedule.print.page.toolbar.lockedTimes.label": "Temps prohibit",
  "schedule.print.page.toolbar.print_date.descr": "La data i l'hora actuals.",
  "schedule.print.page.toolbar.lockedTimes.descr": "Si s'han d'incloure o no els temps prohibitius.",
  "schedule.print.page.page.before_select": "Seleccioneu els grups, els docents i les aules dels quals voleu imprimir l'horari.",
  "schedule.print.page.toolbar.time_frame.label": "Marc de temps",
  "schedule.print.page.toolbar.time_frame.descr": "Dibuixa el marc de temps i els dies permesos.",
  "schedule.print.page.toolbar.fit_text.label": "Ajusta el text",
  "schedule.print.page.toolbar.fit_text.descr": "Intenta ajustar el text reduint la mida de la font.",
  "inputAnalysis.issues.overloaded group aborted.title": "No s'ha pogut determinar si {{the-entity}} està sobrecarregat de lliçons.",
  "inputAnalysis.issues.overloaded group aborted.description": "El càlcul s'ha interromput perquè ha trigat massa temps a completar-se.",
  "common.date_range": "Interval de dates",
  "inputAnalysis.notices.overflowing_holiday.title": "{{The-entity}} està parcialment fora del període de l'horari.",
  "inputAnalysis.notices.overflowing_holiday.description": "Has oblidat actualitzar el dia festiu després de canviar el període de l'horari?",
  "common.the_holiday": "el dia festiu",
  "common.the_holidays": "els dies festius",
  "common.holiday": "dia festiu",
  "common.holidays": "dies festius",
  "view.private.schema.sub-pages.settings.page.tab.holidays": "Dies festius",
  "view.private.schema.sub-pages.settings.page.tab.holidays.dialog.add.title": "Afegeix un dia festiu",
  "view.private.schema.sub-pages.settings.page.tab.holidays.dialog.edit.title": "Edita un dia festiu",
  "inputAnalysis.etc.modify_holiday": "Modifica el dia festiu",
  "common.printed": "Imprès",
  "schedule.print.page.toolbar.display_dates.label": "Mostra les dates",
  "schedule.print.page.toolbar.display_dates.descr": "Cal seleccionar una setmana específica.",
  "common.delete_all": "Suprimeix-ho tot",
  "schedule.navigation.daysCount": "Comptador de dies",
  "common.whole": "Tot",
  "view.private.schema.sub-pages.statistics.days-count.caption": "La taula mostra quants cops apareix un dia en cada període i també el seu nombre total de dies. El període \"{{ whole }}\" es refereix al període determinat per la data d'inici i la data de finalització de l'horari.",
  "dialogs.create-courses.using-excel.label": "Excel",
  "dialogs.create-courses.components.excel.instructions": "Primer, descarregueu la plantilla d'Excel que hem preparat amb les vostres dades d'horari existents (excepte els cursos). A continuació, a la pestanya del curs, introduïu els cursos que voleu crear. Quan estigueu satisfets, deseu el fitxer i pugeu-lo de nou aquí per obtenir una visió general dels cursos que es crearan, així com una llista d'errors detectats.",
  "shared.services.excel-template.errors.invalid_file_type": "Tipus de fitxer no vàlid. Només admetem fitxers dels tipus {{ types }}.",
  "shared.services.excel-template.errors.unable_to_parse": "No s'ha pogut analitzar el fitxer. Si us plau, contacteu amb l'equip d'assistència.",
  "shared.services.excel-template.errors.incorrect_mapping": "Tipus de mapeig no reconegut. Si us plau, contacteu amb l'equip d'assistència.",
  "shared.services.excel-template.errors.unable_to_map": "No s'ha pogut mapejar les dades de l'horari. Si us plau, contacteu amb l'equip d'assistència.",
  "maps.excel.messages.missing_settings_sheet": "No s'ha pogut trobar la pestanya de configuració al fitxer d'Excel.",
  "maps.excel.messages.missing_locations_sheet": "No s'ha pogut trobar la pestanya d'aules al fitxer d'Excel.",
  "maps.excel.messages.missing_groups_sheet": "No s'ha pogut trobar la pestanya de grups al fitxer d'Excel.",
  "maps.excel.messages.missing_teachers_sheet": "No s'ha pogut trobar la pestanya de docents al fitxer d'Excel.",
  "maps.excel.messages.missing_persons_sheet": "No s'ha pogut trobar la pestanya d'estudiants al fitxer d'Excel.",
  "maps.excel.messages.missing_courses_sheet": "No s'ha pogut trobar la pestanya de cursos al fitxer d'Excel.",
  "maps.excel.messages.ignoring_duplicate_location": "S'han trobat dues o més aules amb el nom \"{{ context }}\". S'ignoraran totes menys la primera.",
  "maps.excel.messages.ignoring_duplicate_group": "S'han trobat dues o més grups amb el nom \"{{ context }}\". S'ignoraran totes menys la primera.",
  "maps.excel.messages.ignoring_duplicate_teacher": "S'han trobat dos o més docents amb el nom \"{{ context }}\". S'ignoraran tots menys el primer.",
  "maps.excel.messages.ignoring_duplicate_person": "S'han trobat dos o més estudiants amb el nom \"{{ context }}\". S'ignoraran tots menys el primer.",
  "maps.excel.messages.ignoring_duplicate_course": "S'han trobat dos o més cursos amb el nom \"{{ context }}\". S'ignoraran tots menys el primer.",
  "maps.excel.messages.invalid_group_reference": "No s'ha pogut trobar el grup amb el nom \"{{ context }}\".",
  "maps.excel.messages.invalid_location_reference": "No s'ha pogut trobar l'aula amb el nom \"{{ context }}\".",
  "maps.excel.messages.invalid_teacher_reference": "No s'ha pogut trobar el docent amb el nom \"{{ context }}\".",
  "maps.excel.messages.location_referenced_multiple_times": "L'aula \"{{ context }}\" està referenciada diverses vegades des del mateix curs",
  "maps.excel.messages.teacher_referenced_multiple_times": "El docent \"{{ context }}\" està referenciat diverses vegades des del mateix curs",
  "maps.excel.messages.group_referenced_multiple_times": "El grup \"{{ context }}\" està referenciat diverses vegades des del mateix curs",
  "maps.excel.messages.invalid_number_of_days": "Valor no vàlid \"{{ context }}\" especificat per al nombre de dies de l'horari. El valor ha de ser 5 (dilluns-divendres) o 7 (dilluns-diumenge).",
  "maps.excel.messages.invalid_sex": "Valor no vàlid \"{{ context }}\" especificat com a gènere. El valor ha de ser \"Man\" o \"Woman\".",
  "maps.excel.messages.invalid_total_hours": "Valor no vàlid \"{{ context }}\" especificat com a nombre total d'hores. El valor ha de ser un enter positiu.",
  "maps.excel.messages.invalid_minutes_per_week": "Valor no vàlid \"{{ context }}\" especificat com a nombre de minuts per setmana. El valor ha de ser un múltiple positiu de 5.",
  "maps.excel.messages.invalid_lesson_durations": "Valor no vàlid \"{{ context }}\" especificat com a durada de les lliçons. El valor ha de ser un o més números separats per comes, on cada nombre és un múltiple positiu de 5.",
  "shared.services.excel-template.template.readonly": "No editable",
  "attributes.shared.ids": "ID extern",
  "attributeDescriptions.shared.ids": "Un ID extern únic, útil per a fins d'exportació",
  "attributeDescriptions.shared.createdAt": "La data i l'hora de creació de l'entitat",
  "attributeDescriptions.teachers.signature": "La signatura del docent",
  "account.workspace.page.displayPublicId.descr": "L'ID extern d'una entitat (curs, lliçó, docent, etc.) és important quan exportem les dades a una plataforma diferent. Si les dades de l'horari s'han importat a Royal Schedule, el valor de l'ID extern no s'hauria de modificar en general, ja que això pot donar lloc a conflictes de dades quan s'exporta l'horari. Per als usuaris inexperts, per tant, recomanem amagar l'ID extern.",
  "account.workspace.page.displayPublicId.label": "Mostra l'ID extern",
  "maps.excel.messages.forbidden_group_reference": "El grup \"{{ context }}\" és un grup de docents però s'està utilitzant com a classe.",
  "dialogs.create-courses.components.excel.warnings.no_courses": "No s'han trobat cursos al fitxer d'Excel.",
  "schedule.exceptions.new.title": "Nova excepció",
  "schedule.exceptions.new.type-select": "Tipus",
  "schedule.exceptions.new.date-select": "Data",
  "schedule.exceptions.new.absentee-select": "Absent",
  "view.private.schema.components.excel.subtitle": "Exporta les dades de l'horari en format Excel",
  "view.private.schema.components.additio.subtitle": "Exporta a Additio",
  "common.groups.species.none": "Grups docents",
  "common.groups.species.class": "Grups",
  "common.the_groups.species.none": "Els grups docents",
  "common.the_groups.species.class": "Els grups",
  "dialogs.create-courses.in-bulk.no_options": "No hi ha grups ni docents per triar. Primer crea grups i docents abans de crear cursos en massa.",
  "view.private.schema.sub-pages.schema-editor.components.event-form.show_schedule": "Mostra l'horari",
  "common.minutes-per-week_short": "min/setm",
  "common.minutes-per-week": "Minuts/setmana",
  "attributes.course.plannedDuration": "Durada planificada",
  "attributeDescriptions.course.plannedDuration": "La durada planificada de l'horari del curs en minuts per setmana o en hores totals. El valor no especificat es calcula automàticament i s'arrodoneix cap amunt.",
  "inputAnalysis.issues.plannedDuration.title": "{{The-entity}} no compleix la seva durada planificada.",
  "inputAnalysis.issues.plannedDuration.description": "La durada planificada és de <b>{{planned-value}}</b> minuts/setmana, però la durada preferida dels esdeveniments només suma <b>{{actual-value}}</b> minuts/setmana.",
  "schedule.guaranteedTuition.tabs.guaranteedTuition.table.planned_duration": "Durada planificada",
  "schedule.guaranteedTuition.tabs.guaranteedTuition.table.planned_duration_not_met": "La durada planificada no s'ha complert per un o més cursos representats per aquesta fila.",
  "schedule.guaranteedTuition.tabs.guaranteedTuition.table.actual_duration": "Durada real",
  "tables.courses.values.plannedDuration.warning": "La durada planificada no s'ha complert.",
  "maps.excel.messages.ambiguous_planned_duration": "No podeu especificar la durada planificada en minuts/setmana i hores al mateix temps. Si us plau, trieu una d'aquestes opcions: {{ context }}.",
  /* TO BE DEPRECATED */ "attributes.course.minutesPerWeek": "<s>Minuts/setmana</s>",
  /* TO BE DEPRECATED */ "attributes.course.expectedTotalHours": "<s>Temps total programat esperat</s>",
  /* TO BE DEPRECATED */ "attributeDescriptions.course.expectedTotalHours": "Reemplaçat per 'Durada planificada'.",
  /* TO BE DEPRECATED */ "attributeDescriptions.course.minutesPerWeek": "Reemplaçat per 'Durada planificada'.",
  "attributes.shared.tags": "Etiquetes",
  "attributeDescriptions.shared.tags": "Les etiquetes s'utilitzen per categoritzar i filtrar.",
  "shared.form-fields.tags.input.placeholder": "Nova etiqueta...",
  "validators.invalid": "Valor no vàlid",
  "maps.excel.messages.duplicate_group_member": "L'estudiant \"{{ context }}\" està llistat més d'un cop al grup.",
  "maps.excel.messages.invalid_person_reference": "No s'ha pogut trobar l'estudiant amb el número de la seguretat social \"{{ context }}\".",
  "maps.excel.messages.person_in_multiple_classes": "L'estudiant \"{{ context }}\" es troba en diverses classes.",
  "shared.dialogs.user-inactive.title": "Inactivitat detectada",
  "shared.dialogs.user-inactive.description": "Sembla que has estat absent una estona. Per mantenir tot en funcionament correctament, si us plau, fes clic al botó \"Recarrega\". Això actualitzarà totes les teves pestanyes i assegurarà que tot estigui actualitzat.",
  "shared.views.schedule-viewer.before_select": "Selecciona de qui vols veure l'horari i per a quina setmana.",
  "shared.dialogs.overlappableEventSets.auto-link.description": "Enllaça automàticament",
  "shared.dialogs.overlappableEventSets.unable_to_auto-link.description": "No s'ha pogut enllaçar automàticament les lliçons. Per a que això funcioni, els cursos paral·lels han de contenir el mateix nombre de lliçons de la mateixa durada i les lliçons no han d'estar ja totalment enllaçades.",
  "shared.dialogs.overlappableEventSets.auto-link_N": "Enllaça {{ N }}",
  "shared.dialogs.overlappableEventSets.auto-link_N.description": "Enllaça automàticament les lliçons per a {{ N }} conjunts de cursos paral·lels. Per a que això funcioni, els cursos paral·lels han de contenir el mateix nombre de lliçons de la mateixa durada i les lliçons no han d'estar ja totalment enllaçades.",
  "shared.dialogs.overlappableEventSets.unable_to_auto-link_N.description": "No hi ha conjunts de cursos paral·lels que compleixin els requisits perquè les seves lliçons es puguin enllaçar automàticament. Per a que això funcioni, els cursos paral·lels han de contenir el mateix nombre de lliçons de la mateixa durada i les lliçons no han d'estar ja totalment enllaçades.",
  "tables.courses.action.connect-courses": "Connecta",
  "tables.courses.action.disconnect-courses": "Desconnecta",
  "maps.excel.messages.invalid_course_reference": "No s'ha pogut trobar el curs amb l'ID \"{{ context }}\".",
};

// PLEASE LEAVE
// translate the value of the commented out key-value pairs to the language of this file and based on its context
