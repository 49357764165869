import { Component,
         Input                           } from '@angular/core';
import { Util                            } from 'app/common';
import { TranslationModule               } from 'app/core/translate/translate.module';

type Unit = Util.Types.PlannedDurationUnit;

@Component({
  standalone: true,
  selector: 'app-form-field-planned-duration-display-value',
  templateUrl: './display-value.component.html',
  styleUrl: './display-value.component.scss',
  imports: [
    TranslationModule,
  ]
})
export class DisplayValueComponent {

  constructor() { }

  @Input({ required: true})
  set primaryValueAndUnit(x: string | null) {
    if ( ! x) {
      this.primaryUnit = null;
      return;
    }

    const [value, unit] = x.split(' ');
    this.primaryValue = value;
    this.primaryUnit = unit as Unit;
  }

  protected primaryValue: string;
  protected primaryUnit: Unit | null = null;

  @Input({ required: true})
  set secondaryValueAndUnit(x: string | null) {
    if ( ! x) {
      this.secondaryUnit = null;
      return;
    }

    const [value, unit] = x.split(' ');
    this.secondaryValue = value;
    this.secondaryUnit = unit as Unit;
  }
  protected secondaryValue: string;
  protected secondaryUnit: Unit | null = null;
}
