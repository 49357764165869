import _                        from 'lodash';

import { Populated as P       } from '@app/shared/interfaces';
import { InputAnalysisService } from './input-analysis.service';
import { Entity, Remark       } from './types';


function standardizeEntities (
  _entities: undefined | Entity | Entity[],
): Exclude<Entity, P.overlapGroup>[] {
  return [_entities]
  .flat()
  .filter(Boolean)
  .flatMap(x => {
    if (x.is == 'overlapGroup') {
      // in case of an overlap groups, convert to an array of courses
      return (x.coalesced ?? []).map(y => ({ ...y.to, is: 'course' } as P.course));
    }
    if (x.is == 'lockedTime' && x.coalesced && x.coalesced.length > 1) {
      // in case of a locked time linked to multiple entities, ensure that the location is the last one
      x.coalesced = _.orderBy(x.coalesced, x => x.toModel == 'locations');
    }

    return x as Exclude<Entity, P.overlapGroup>;
  });
}


export function createRemark (
  this:            InputAnalysisService,
  type:            Remark['type'],
  code:            Remark['code'],
  version:         Remark['version'],
  _entities:       undefined | Entity | Entity[],
  delayTime:       number,
  translationArgs: { [key: string]: string | number } = { },
  translationType: Remark['type'] = type
): Remark {
  const key      = _.isArray(_entities) ? 'the-entities' : 'the-entity';
  const entities = standardizeEntities(_entities);

  // fetch value ids
  const id = entities[0]?.id ?? 'unknown';

  const remark: Omit<Remark, 'message' | 'explanation'> = {
    type, code, version, entities, delayTime,
    id: `${type}.${code}.${id}`
  };

  // add both lowercase and uppercase versions
  translationArgs[key]                   = this.makeReadable(entities, { chip: true, capitalize: false });
  translationArgs[key.capitalizeFirst()] = this.makeReadable(entities, { chip: true, capitalize: true  });

  const message     = this._translate.instant(`inputAnalysis.${translationType}s.${code}.title`,       translationArgs).capitalizeFirst();
  const explanation = this._translate.instant(`inputAnalysis.${translationType}s.${code}.description`, translationArgs);

  return { ...remark, message, explanation };
}