import { Component,
         OnInit,
         Input,
         ElementRef,
         OnDestroy,
         Optional,
         HostBinding,
         Self,
         HostListener,
         EventEmitter,
         Output                    } from '@angular/core';
import { UntypedFormGroup,
         ControlValueAccessor,
         NgControl,
         Validators                } from '@angular/forms';
import { Subject                   } from 'rxjs';
import { MatFormFieldControl       } from '@angular/material/form-field';
import { FocusMonitor              } from '@angular/cdk/a11y';
import { coerceBooleanProperty     } from '@angular/cdk/coercion';

@Component({
  selector: 'app-form-field-options',
  templateUrl: './options.component.html',
  styleUrls: ['./options.component.scss'],
  providers: [
    {
      provide:     MatFormFieldControl,
      useExisting: OptionsComponent
    }
  ]
})
export class OptionsComponent implements OnInit,
                                         OnDestroy,
                                         ControlValueAccessor,
                                         MatFormFieldControl<string | null | undefined> {
  @Output('onChange') emitter = new EventEmitter<string | null | undefined>();
  static nextId:      number        = 0;
  public stateChanges:Subject<void> = new Subject<void>();
  public focused:     boolean       = false;
  public errorState:  boolean       = false;
  public controlType: string        = 'options-input';
  public id:          string        = `options-input-${ OptionsComponent.nextId++ }`;
  public describedBy: string        = '';
  public onChange = (_: any) => {};
  public onTouched = () => {};

  public form: UntypedFormGroup;

  @HostBinding('attr.tabindex') __tabindex = 0;

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement: Node) {
    this.focused = this._elementRef.nativeElement.contains(targetElement);
  }

  constructor(private _focusMonitor: FocusMonitor,
              private _elementRef:   ElementRef<HTMLElement>,
              @Optional() @Self() public ngControl: NgControl) {

    _focusMonitor.monitor(_elementRef, true)
    .subscribe((origin) => {
      if (this.focused && ! origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
  }

  public submit(event: string): void {
    console.log(event)
    this._value = event;
    this.focused = false;
    this.emitter.emit(this.value);
  }

  get empty() {
    const { value: { duration } } = this.form;

    return duration == null;
  }

  get shouldLabelFloat() { return this.focused || !this.empty; }

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.form.disable() : this.form.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get saveOnFocusLost(): boolean { return this._saveOnFocusLost; }
  set saveOnFocusLost(value: boolean | string) {
    this._saveOnFocusLost = coerceBooleanProperty(value);
  }
  private _saveOnFocusLost = false;

  @Input()
  get value(): string | null | undefined { return this._value }
  set value(_val: string | null | undefined) {
    this._value = _val;
  }
  private _value: string | null | undefined = '';

  @Input()
  get requireValue(): boolean { return this._requireValue; }
  set requireValue(value: boolean) {
    this._requireValue = coerceBooleanProperty(value);
    if (this._requireValue)
      this.form.controls.text.setValidators(Validators.required)
  }
  private _requireValue = false;

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent) {
  }

  writeValue(val: string): void {
    this.value = val ?? '';
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  _handleInput(): void {
    this.onChange(this.value);
  }

  static ngAcceptInputType_disabled: boolean | string | null | undefined;
  static ngAcceptInputType_required: boolean | string | null | undefined;
}
