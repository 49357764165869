import _                                   from 'lodash';
import { Types                           } from 'app/shared/interfaces';
import { Collection                      } from './system-core';

type Serializer<T extends { id: string } & any> = {
  serialize: (
    Extract<keyof T, string> |
    (
      Serializer<T[Extract<keyof T, string>]> & { path: Extract<keyof T, string>; }
    )
  )[];
};

function createSerializer<T>(...serialize: Serializer<T>['serialize']): Serializer<T> {
  return { serialize };
}

function _serialize(val: unknown): (string | object | null)[] | string | object | null | undefined {
  if (_.isNil(val))
    return val;

  if (_.isArray(val))
    return val.map(_serialize).filter((x): x is null | string => x !== undefined).flat();

  if (_.isObject(val)) {
    // Object reference can lack id then return the deserialized object
    if ('id' in val) {
      if (! _.isString(val.id))
        throw new Error(`(Source::Core::Serialize) Invalid id: ${ val.id }`);
      return val.id;
    }

    return val;
  }

  if (_.isString(val))
    return val;

  throw new Error(`(Source::Core::Serialize) Invalid value: ${ val }`);
}

function _sanitize<T extends object>(
  data:    T | T[],
  options: Serializer<T>,
  ...args: any
): T | T[] | null {
  try {
    for (const option of options.serialize) {
      if (_.isString(option)) {
        if (Array.isArray(data)) {
          for (const item of data)
            if (option in item)
              item[option] = _serialize(item[option]) as any;
          continue;
        }
        if (! (option in data)) continue;
        data[option] = _serialize(data[option]) as any;
      } else {
        const path = option.path;
        if (Array.isArray(data)) {
          for (const item of data) {
            if (! (path in item)) continue;
            const value = item[path];
            _sanitize<any>(value, _.pick(option, 'serialize'));
          }

          continue;
        }

        if (! (path in data)) continue;
        _sanitize<any>(data[path], _.pick(option, 'serialize'));

      }
    }
    return data;
  } catch (err) {
    return null;
  }
}

export class SerializerService {
  static division         = createSerializer<Types.divisions>();
  static divisionSettings = createSerializer<Types.settings>();
  static lockedTime       = createSerializer<Types.lockedtimes & any>({
    path: 'coalesced',
    serialize: ['to']
  });
  static overlapGroup     = createSerializer<Types.overlapgroups & any>({
    path: 'coalesced',
    serialize: ['to']
  });
  static course           = createSerializer<Types.courses & any>(
    // 'events',
    'period',
    {
      path: 'groups',
      serialize: ['to', 'exclude']
    }, {
      path: 'teachers',
      serialize: ['to']
    }, {
      path: 'participants',
      serialize: ['to']
    }, {
      path: 'locations',
      serialize: ['locations']
    },
    'overlapGroup',
    {
      ...SerializerService.lockedTime,
      path:     'lockedTimes'
    }
  );
  static event           = createSerializer<Types.courses & any>(
    'period',
    'course',
    {
      path: 'groups',
      serialize: ['to', 'exclude']
    }, {
      path: 'teachers',
      serialize: ['to']
    }, {
      path: 'participants',
      serialize: ['to']
    }, {
      path: 'locations',
      serialize: ['locations']
    },
    'overlapGroup',
    {
      ...SerializerService.lockedTime,
      path:     'lockedTimes'
    }
  );
  static group           = createSerializer<Types.groups & any>(
    'members',
    'rootInterval',
    'parentGroups',
    'subGroups',
    {
      ...SerializerService.lockedTime,
      path:     'lockedTimes'
    }, {
      ...SerializerService.lockedTime,
      path:     'lunch'
    }
  );
  static location        = createSerializer<Types.locations & any>(
    {
      ...SerializerService.lockedTime,
      path:     'lockedTimes'
    }, {
      ...SerializerService.lockedTime,
      path:     'lunch'
    }
  );
  static period          = createSerializer<Types.periods & any>();
  static person          = createSerializer<Types.persons & any>(
    'group',
    {
      ...SerializerService.lockedTime,
      path:     'lockedTimes'
    }, {
      ...SerializerService.lockedTime,
      path:     'lunch'
    }
  );
  static rootInterval    = createSerializer<Types.rootInterval & any>();
  static setting         = createSerializer<Types.settings & any>(
    'period'
  );
  static teacher         = createSerializer<Types.teachers & any>(
    {
      ...SerializerService.lockedTime,
      path:     'lockedTimes'
    }, {
      ...SerializerService.lockedTime,
      path:     'lunch'
    },
    'person'
  );
  static generation      = createSerializer<Types.generations & any>();

  static use<T extends object>(
    data:       T | T[],
    collection: Collection
  ): T | T[] | null {
    try {
      switch (collection) {
        case 'courses':
          return _sanitize<T>(data, SerializerService.course);
        case 'courseevents' as any:
        case 'events':
          return _sanitize<T>(data, SerializerService.event);
        case 'groups':
          return _sanitize<T>(data, SerializerService.group);
        case 'locations':
          return _sanitize<T>(data, SerializerService.location);
        case 'lockedTimes':
          return _sanitize<T>(data, SerializerService.lockedTime);
        case 'overlapGroups':
          return _sanitize<T>(data, SerializerService.overlapGroup);
        case 'periods':
          return _sanitize<T>(data, SerializerService.period);
        case 'persons':
          return _sanitize<T>(data, SerializerService.person);
        case 'rootIntervals':
          return _sanitize<T>(data, SerializerService.rootInterval);
        case 'settings':
          return _sanitize<T>(data, SerializerService.setting);
        case 'teachers':
          return _sanitize<T>(data, SerializerService.teacher);
        case 'generations':
          return _sanitize<T>(data, SerializerService.generation);
        default:
          return data;
      }
    } catch (err) {
      console.error(err);
      return null;
    }
  }
}