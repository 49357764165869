import { DestroyRef,
         inject,
         Inject,
         Injectable                } from '@angular/core';
import { extendMoment              } from 'moment-range';
import { combineLatest,
         filter,
         from,
         map,
         of,
         switchMap                 } from 'rxjs';
import Moment                        from 'moment';
import _                             from 'lodash';

import { Populated as P,
         Division,
         DivisionSettings,
         CalendarException,
         Week,
         allWeeks                  } from 'app/shared/interfaces';
import { DateService               } from 'app/shared/services';
import { SourceService             } from 'app/core';
import { DIVISION_ID               } from 'app/constants';
import { computeSelectableWeeks    } from 'app/common/wrappers/weeks-and-periods';
import { DaysCountPerWeek,
         Period                    } from './types';

const moment = extendMoment(Moment);

@Injectable()
export class ScheduleRangeService {
  private readonly destroyRef = inject(DestroyRef);

  constructor (
    @Inject(DIVISION_ID)
    private readonly _did:    string,
    private readonly _source: SourceService,
  ) {
  }

  weeks$ () {
    return from(this._source.groupBy({ did: this._did, collections: [ 'divisions', 'settings' ] }))
      .pipe(
        switchMap(() => {
          const division$ = this._source.getStrictDivision({ did: this._did, onDestroy: this.destroyRef });
          const settings$ = this._source.getStrictSettings({ did: this._did, onDestroy: this.destroyRef });

          return combineLatest([division$, settings$]);
        }),
        map(x => ScheduleRangeService.computeWeeks(...x))
      );
  }

  dayCountPerMonthPerWeek$ (options: { allWeeksOnly?: boolean } = { }) {
    return from(this._source.groupBy({ did: this._did, collections: [ 'divisions', 'settings', 'periods' ] }))
      .pipe(
        switchMap(() => {
          const numDays$            = this._source.getStrictSettings  ({ did: this._did, onDestroy: this.destroyRef }).pipe(map(x => x.numDays), filter(Boolean));
          const calendarExceptions$ = this._source.getStrictSettings  ({ did: this._did, onDestroy: this.destroyRef }).pipe(map(ScheduleRangeService.getCalendarExceptions));
          const scheduleRange$      = this._source.getStrictDivision  ({ did: this._did, onDestroy: this.destroyRef }).pipe(map(ScheduleRangeService.getScheduleRange));
          const periods$            = this._source.getPopulatedPeriods({ did: this._did, onDestroy: this.destroyRef }).pipe(map(ScheduleRangeService.getPeriodRanges));
          const weeks$              = options.allWeeksOnly ? of([]) : this.weeks$();

          return combineLatest([numDays$, scheduleRange$, calendarExceptions$,  periods$, weeks$]);
        }),
        map(x => ScheduleRangeService.computeDayCountPerMonthPerWeek(...x))
      );
  }

  numWeeksPerPeriod$ () {
    return from(this._source.groupBy({ did: this._did, collections: [ 'divisions', 'settings', 'periods' ] }))
      .pipe(
        switchMap(() => {
          const division$ = this._source.getStrictDivision  ({ did: this._did, onDestroy: this.destroyRef });
          const settings$ = this._source.getStrictSettings  ({ did: this._did, onDestroy: this.destroyRef });
          const periods$  = this._source.getPopulatedPeriods({ did: this._did, onDestroy: this.destroyRef });

          return combineLatest([division$, settings$, periods$]);
        }),
        map(x => ScheduleRangeService.computeNumWeeksPerPeriod(...x))
      );
  }





  private static getScheduleRange (division: Division): { start: moment.Moment, end: moment.Moment } {
    return { start: moment.utc(division.start), end: moment.utc(division.end) };
  }

  private static getPeriodRanges (periods: P.period[]): Period[] {
    return periods.map(x => ({ ...x, ranges: x.ranges.map(r => moment.range(moment.utc(r.start), moment.utc(r.end))) }));
  }

  private static getCalendarExceptions (settings: DivisionSettings): CalendarException[] {
    return (settings.calendarExceptions ?? [])
      .map(x => ({ ...x, start: moment.utc(x.start), end: moment.utc(x.end) }))
      .sort((a, b) => a.start.diff(b.start));
  }

  private static computeWeeks (
    division: Division,
    settings: DivisionSettings
  ) {
    return computeSelectableWeeks(ScheduleRangeService.getScheduleRange(division), settings);
  }

  private static computeDayCountPerMonthPerWeek (
    numDays:            number,
    scheduleRange:      { start: moment.Moment, end: moment.Moment },
    calendarExceptions: CalendarException[],
    periods:            Period[],
    weeks:              Week[]
  ): DaysCountPerWeek {
    // add all days included in the scheduled period
    const daysInSchedule = new Set<string>();
    for (let m = scheduleRange.start.clone(); m.isSameOrBefore(scheduleRange.end); m.add(1, 'days')) {   // TODO: if start and end date is to contain the time, use isBefore
      if (numDays > DateService.getDayIndex(m)) daysInSchedule.add(m.format('YYYY-MM-DD'));
    }

    // remove all days specified by the calendar exceptions
    calendarExceptions.forEach(e => {
      for (let m = e.start.clone(); m.isBefore(e.end); m.add(1, 'days')) {
        daysInSchedule.delete(m.format('YYYY-MM-DD'))
      }
    });

    const out: DaysCountPerWeek = {
      [allWeeks]: []
    };

    // the complete period
    [undefined, ...periods].forEach(period => {

      // further filter days by the periods
      let periodDays = new Set<string>();
      if (period) {
        period?.ranges.forEach(range => {
          for (let m = range.start.clone();  m.isBefore(range.end); m.add(1, 'days')) {
            if (daysInSchedule.has(m.format('YYYY-MM-DD'))) periodDays.add(m.format('YYYY-MM-DD'));
          }
        });
      } else {
        periodDays = new Set(daysInSchedule);
      }

      // sum upp the number of different days
      const dayCount = _.times(numDays, () => 0);
      periodDays.forEach(d => {
        const dayIndex = DateService.getDayIndex(moment.utc(d));
        dayCount[dayIndex]++;
      });
      out[allWeeks].push({ period, count: dayCount });
    });


    // loop over all weeks and the entire schedule period
    weeks.forEach(w => {
      // get the number of days in this week
      const dayCount = _.times(numDays, () => 0);
      for (let m = w.range.start.clone(); m.isBefore(w.range.end); m.add(1, 'days')) {

        if (daysInSchedule.has(m.format('YYYY-MM-DD'))) {
          const dayIndex = DateService.getDayIndex(moment.utc(m));
          dayCount[dayIndex]++;
        }
      }

      // add the total schedule period and all periods
      out[w.id] = [
        { period: undefined, count: dayCount },
        ...periods.filter(p =>   p.ranges.some(r => r.overlaps(w.range))).map(p => ({ period: p, count: dayCount                  })),
        ...periods.filter(p => ! p.ranges.some(r => r.overlaps(w.range))).map(p => ({ period: p, count: _.times(numDays, () => 0) }))
      ];
    });

    return out;
  }

  static computeNumWeeksPerPeriod (
    division: Division,
    settings: DivisionSettings,
    periods:  P.period[]
  ): Map<undefined | string, number> {
    const numDays = settings.numDays;
    if ( ! numDays) return new Map();

    const scheduleRange      = ScheduleRangeService.getScheduleRange      (division);
    const calendarExceptions = ScheduleRangeService.getCalendarExceptions (settings);
    const _periods           = ScheduleRangeService.getPeriodRanges       (periods);
    const compressed         = ScheduleRangeService.computeDayCountPerMonthPerWeek(numDays, scheduleRange, calendarExceptions, _periods, []);

    // compute the average number of weeks per period
    return new Map<undefined | string, number>(Array.from(compressed.all, x => [x.period?.id, _.sum(x.count) / numDays]));
  }
}
