import { NgModule                        } from '@angular/core';

import { FilterModule                    } from './filter/filter.module';
import { IconModule                      } from './icon/icon.module';

@NgModule({
  declarations: [
  ],
  exports: [
    FilterModule,
    IconModule
  ],
  imports: [
  ]
})
export class ComponentsModule { }
