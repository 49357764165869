import { CommonModule                     } from '@angular/common';
import { Component,
         ChangeDetectionStrategy,
         Input,                           } from '@angular/core';
import { BehaviorSubject                  } from 'rxjs';

import { PartialTags                      } from '@app/shared/interfaces';


type Value = PartialTags | null | undefined;

@Component({
  standalone: true,
  selector: 'app-form-field-tags-display-value',
  templateUrl: './display-value.component.html',
  styleUrl: './display-value.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule
  ]
})
export class DisplayValueComponent {

  @Input()
  set value(value: Value) {
    this.value$.next(value);
  }
  protected value$ = new BehaviorSubject<Value>([]);

}
