<form [formGroup]="form"
      novalidate
      class="form"
      id="division-form">

  <div class="form-field-wrapper" *ngIf="published">
    <mat-slide-toggle formControlName="published" class="form-field">
      {{ 'attributes.division.published' | translate }}
    </mat-slide-toggle>
  </div>

  <div class="form-field-wrapper" *ngIf="displayName">
    <mat-icon class="form-field-icon">badge</mat-icon>
    <mat-form-field class="form-field" appearance="outline" cypress="displayName">
      <mat-label>{{ 'attributes.shared.displayName' | translate }}</mat-label>
      <input matInput type="text" formControlName="displayName" required autocomplete="off">
      <mat-error><app-form-field-error [control]="form.controls.displayName"></app-form-field-error></mat-error>
    </mat-form-field>
  </div>

  <div class="form-field-wrapper" *ngIf="numDays">
    <mat-icon class="form-field-icon">calendar_view_week</mat-icon>
      <mat-form-field class="form-field" appearance="outline" floatLabel="always">
      <mat-label>{{ 'attributes.division.settings.numDays' | translate }}</mat-label>
      <mat-select formControlName="numDays"
                  panelClass="dark-scrollbar">
        <mat-option [value]="5">5</mat-option>
        <mat-option [value]="7">7</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="form-field-wrapper" *ngIf="start && end">
    <mat-icon class="form-field-icon">date_range</mat-icon>
    <mat-form-field appearance="outline" floatLabel="always" class="form-field">
      <mat-label>{{ 'common.date_range' | translate }}</mat-label>
      <mat-date-range-input [rangePicker]="picker">
        <input matStartDate formControlName="start" [cleave]="cleaveDateFormat[0]" placeholder="{{ 'common.YYYY-MM-DD' | translate}}">
        <input matEndDate   formControlName="end"   [cleave]="cleaveDateFormat[1]" placeholder="{{ 'common.YYYY-MM-DD' | translate}}">
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
      <mat-error><app-form-field-error [control]="[form.controls.start, form.controls.end]"></app-form-field-error></mat-error>
    </mat-form-field>
  </div>



</form>