import { storageConstants                } from 'app/constants';
import { AuthData,
         Session,
         Token                           } from './auth.interface';
import { AuthService                     } from './auth.service';


/**
 * @description Adds a new session the user is authenticated with. The new session also becomes the active one.
 */
export function _addNewSession (
  this: AuthService,
  data: AuthData
) {
  const sessions = this._loadAndValidateSessions();

  // append new session
  sessions.push(this._transformAuthData(data, sessions));

  // update (will trigger observables setup in the constructor)
  this._storage.set(storageConstants.AUTH_DETAILS, JSON.stringify(sessions));
}

export function _transformAuthData(
  this:     AuthService,
  data:     AuthData,
  sessions: Session[] = []
): Session {
  return {
    organizationName:  data.company?.name,
    organizationType:  data.environment?.organizationType,
    appFeatures:       data.environment?.appFeatures,
    associatedPartner: data.associatedPartner,
    theme:             data.environment?.theme,
    accessToken:       data.token.accessToken,
    expiresAt:         data.token.expiresAt,
    role:              data.role,
    refreshToken:      data.token.refreshToken,
    // store current url so we can redirect back if we return to the previous session
    returnToUrl:       sessions.length ? this._router.url : undefined
  }
}

/**
 * @description Removes the currently active session. The previous session becomes the active one and if there is no previous session, the user is logged out.
 */
export function _removeActiveSession (
  this: AuthService
) {
  const authDetails = this._loadAndValidateSessions();

  // remove the last element from the array
  authDetails.pop();

  // update (will trigger observables setup in the constructor)
  this._storage.set(storageConstants.AUTH_DETAILS, JSON.stringify(authDetails));
}

/**
 * @description Replaces the currently active session with the given one.
 */
export function _replaceSession (
  this: AuthService,
  data: AuthData
) {
  // update (will trigger observables setup in the constructor)
  console.log('replacing session with:', data);
  this._storage.set(storageConstants.AUTH_DETAILS, JSON.stringify([this._transformAuthData(data)]));
}


/**
 * @description Updates the access token of the session with the given refresh token.
 */
export function _updateToken (
  this:             AuthService,
  prevRefreshToken: string,
  newToken:         Token
) {
  const authDetails = this._loadAndValidateSessions();

  // find session
  const session = authDetails.find(x => x.refreshToken === prevRefreshToken);
  if ( ! session) {
    console.warn('Could not find session with refresh token:', prevRefreshToken);
    return;
  }

  // update the token
  session.accessToken  = newToken.accessToken;
  session.expiresAt    = newToken.expiresAt;
  session.refreshToken = newToken.refreshToken;

  // update (will trigger observables setup in the constructor)
  this._storage.set(storageConstants.AUTH_DETAILS, JSON.stringify(authDetails));
}

/**
 * @description Stores session independent authentication data.
 */
export function _storeGlobals (
  this: AuthService,
  data: AuthData
) {
  if (data.user?.username)        this._storage.set(storageConstants.USERNAME,        data.user.username);
  if (data.user?.id)              this._storage.set(storageConstants.USER_ID,         data.user.id);
  if (data.user?.createdAt)       this._storage.set(storageConstants.USER_CREATED_AT, data.user.createdAt);
  if (data.preferences?.language) this._storage.set(storageConstants.LANGUAGE,        data.preferences.language);
}