import { Pipe,
         PipeTransform                   } from '@angular/core';
import { Week,
         AllWeeks,
         allWeeks                        } from '@app/shared/interfaces';
import { Collection,
         SelectedEntity,
         EntitySelectData                } from './types';

@Pipe({
  standalone: true,
  name: 'filterPrimary'
})
export class FilterPrimaryPipe implements PipeTransform {
  transform (selection: SelectedEntity[] | null, collection: Collection): SelectedEntity | null {
    const entity = selection?.at(0);
    if (entity?.key == collection) return entity;
    return null;
  }
}

@Pipe({
  standalone: true,
  name: 'filterSecondary'
})
export class FilterSecondaryPipe implements PipeTransform {
  transform (val: SelectedEntity[] | null, selects: EntitySelectData[]): SelectedEntity[] {
    return (val ?? [])
      // restrict to the present select element
      .filter(x => selects.some(y => y.collection == x.key))
      // keep all but the first element
      .slice(1);
  }
}

@Pipe({
  standalone: true,
  name: 'toNgModel'
})
export class ToNgModelPipe implements PipeTransform {
  transform (val: { id: string, week?: Week } | AllWeeks | null): string | AllWeeks | undefined {
    return val == allWeeks ? allWeeks : val?.id
  }
}