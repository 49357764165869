import { NgModule                        } from '@angular/core';
import { CommonModule                    } from '@angular/common';
import { ScrollingModule                 } from '@angular/cdk/scrolling';
import { AppCommonModule                 } from 'app/common/common.module';
import { TranslationModule               } from 'app/core/translate/translate.module';
import { PipesModule                     } from 'app/shared/pipes/pipes.module';
import { SearchModule                    } from 'app/shared/components/search/search.module';

import { VirtualSelectComponent          } from './virtual-select.component';
import { FilterOptionsPipe               } from './virtual-select.pipe';

@NgModule({
  declarations: [
    VirtualSelectComponent,
    FilterOptionsPipe
  ],
  exports: [
    VirtualSelectComponent
  ],
  imports: [
    CommonModule,
    ScrollingModule,
    AppCommonModule,
    TranslationModule,
    PipesModule,
    SearchModule
  ]
})
export class VirtualSelectModule { }
