import { Component,
         OnInit,
         Inject,
         ViewChild                         } from '@angular/core';
import { BehaviorSubject                   } from 'rxjs';

import { MAT_DIALOG_DATA,
         MatDialogRef                      } from 'app/common';
import { Company                           } from '@shared/interfaces';

import { HttpService                       } from 'app/core';

import { apiConstants                      } from 'app/constants';
import { School                            } from '@app/shared/forms/company/company.component';

type Data = {
  organization?: boolean;
  theme?:        boolean;
  schoolCode?:   boolean;
  schools?:      BehaviorSubject<School[]>;
}

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit {
  @ViewChild('form', { static: true }) form:any;
  public submitted: boolean            = false;
  public error:     string | undefined;

  constructor(public dialogRef: MatDialogRef<CompanyComponent>,
              private _http:    HttpService,
              @Inject(MAT_DIALOG_DATA) public data: Data) { }

  ngOnInit() {
    this.form.formChange()
    .subscribe((next: any) => {
      this.dialogRef.close();
    })
  }


  public submit(company: Partial<Omit<Company.complete, 'id'> >): void {
    this.error = undefined;
    this.submitted = true;
    this._http
    .post(`${ apiConstants.ADMINISTRATOR }`, { company })
    .subscribe({
      next:  (res: any) => {
        this.dialogRef.close(true);
      },
      error: (err: any) => {
        this.error = 'common.generic_error';
        this.submitted = false;
      }
    });
  }
}
