import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AppCommonModule } from 'app/common/common.module';

import { InputAnalyzerComponent } from './input-analyzer.component';

import { ComponentsModule } from './components/components.module';

@NgModule({
  declarations: [
  	InputAnalyzerComponent
  ],
  exports: [
  	InputAnalyzerComponent,
    ComponentsModule
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    ComponentsModule
  ]
})
export class InputAnalyzerModule { }
