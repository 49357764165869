<div class="container mat-elevation-z8"
     [ngClass]="'animate-' + options?.animate"
     (click)="$event.stopPropagation()">

  <header>
    <h2 mat-card-title class="royal-text-secondary">
      {{ options?.event | eventDisplayName }}
    </h2>

    <button mat-icon-button
            type="button"
            (click)="close()"
            [matTooltip]="'common.close' | translate">
      <mat-icon class="royal-icon">close</mat-icon>
    </button>
  </header>

  <div class="content dark-scrollbar">
    <mat-list>
      @if (options?.event | typeGuard:hasStartAndEnd; as event) {
        <mat-list-item>
          <mat-icon matListItemIcon class="royal-icon">access_time</mat-icon>
          <h4 matListItemTitle>{{ event.start | date:'EEEE, MMM d':'UTC':translate.currentLanguage?.id }}</h4>
          <p matListItemLine>{{ event.start | date:'HH:mm':'UTC' }} - {{ event.end | date:'HH:mm':'UTC' }} </p>
        </mat-list-item>
      }

      @if (options?.event | typeGuard:hasTeachers; as event) {
        @if (('teachers' | environmentCollection) && event?.teachers?.length) {
          <mat-list-item>
            <mat-icon matListItemIcon class="royal-icon">{{ 'teachers' | collectionIcon }}</mat-icon>
              <div class="chip-list">
                @for (teacher of event.teachers.slice(0, limits.teachers) | destruct; track $index) {
                  <div>{{ teacher | displayName }}</div>
                }
                @if (event.teachers.length > limits.teachers) {
                  <div class="clickable" (click)="limits.teachers = limits.teachers + 20">
                    +{{ event.teachers.length - limits.teachers }} {{ 'common.others' | translate }}
                  </div>
                }
              </div>
          </mat-list-item>
        }
      }

      @if (options?.event | typeGuard:hasGroups; as event) {
        @if (('teachers' | environmentCollection) && event?.groups?.length) {
          <mat-list-item>
            <mat-icon matListItemIcon class="royal-icon">{{ 'groups' | collectionIcon }}</mat-icon>
              <div class="chip-list">
                @for (teacher of event.groups.slice(0, limits.groups) | destruct; track $index) {
                  <div>{{ teacher | displayName }}</div>
                }
                @if (event.groups.length > limits.groups) {
                  <div class="clickable" (click)="limits.groups = limits.groups + 20">
                    +{{ event.groups.length - limits.groups }} {{ 'common.others' | translate }}
                  </div>
                }
              </div>
          </mat-list-item>
        }
      }

      @if (options?.event | typeGuard:hasInLocations; as event) {
        @if (('locations' | environmentCollection) && event?.inLocations?.length) {
          <mat-list-item>
            <mat-icon matListItemIcon class="royal-icon">{{ 'locations' | collectionIcon }}</mat-icon>
              <div class="chip-list">
                @for (location of event.inLocations.slice(0, limits.locations) | destruct; track $index) {
                  <div>{{ location | displayName }}</div>
                }
                @if (event.inLocations.length > limits.locations) {
                  <div class="clickable" (click)="limits.locations = limits.locations + 20">
                    +{{ event.inLocations.length - limits.locations }} {{ 'common.others' | translate }}
                  </div>
                }
              </div>
          </mat-list-item>
        }
      }

      @if (options?.event | typeGuard:hasWeeks; as event) {
        @if (event?.weeks?.length) {
          <mat-list-item>
            <mat-icon matListItemIcon class="royal-icon">view_week</mat-icon>
            <div matListItemLine>
              {{ event.weeks | interval }}
            </div>
          </mat-list-item>
        }
      }
    </mat-list>
  </div>
</div>