import { Component,
         OnInit,
         Input,
         ElementRef,
         OnDestroy,
         Optional,
         ViewEncapsulation,
         HostBinding,
         ChangeDetectionStrategy,
         Self,
         EventEmitter,
         Output,
         ViewChild                      } from '@angular/core';
import { UntypedFormControl,
         ControlValueAccessor,
         NgControl                       } from '@angular/forms';
import { MatSlideToggleChange            } from '@angular/material/slide-toggle';
import { MatFormFieldControl             } from '@angular/material/form-field';
import { FocusMonitor                    } from '@angular/cdk/a11y';
import { coerceBooleanProperty           } from '@angular/cdk/coercion';
import { coerceNumberProperty            } from '@angular/cdk/coercion';
import { MatMenuTrigger                  } from '@angular/material/menu';
import { Subject,
         fromEvent,
         asyncScheduler                  } from 'rxjs';
import { takeUntil                       } from 'rxjs/operators';
import _                                   from 'lodash';

import { Day                             } from 'app/shared/interfaces';

import { MAT_CHECKBOX_DEFAULT_OPTIONS    } from 'app/common';

import { TranslateService                } from 'app/core';

@Component({
  selector: 'app-form-field-available-days',
  templateUrl: './available-days.component.html',
  styleUrls: ['./available-days.component.scss'],
  providers: [
    {
      provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
      useValue: { clickAction: 'noop' }
    }, {
      provide: MatFormFieldControl,
      useExisting: AvailableDaysComponent
    }
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvailableDaysComponent implements OnInit,
                                               OnDestroy,
                                               ControlValueAccessor,
                                               MatFormFieldControl<Day[] | null | undefined> {
  @ViewChild(MatMenuTrigger)      trigger?:           MatMenuTrigger;
  @Output('onChange') emitter = new EventEmitter<Day[] | null | undefined>();
  private onClose:     Subject<boolean>    = new Subject<boolean>();
  static nextId:       number              = 0;
  public stateChanges: Subject<void>       = new Subject<void>();
  public focused:      boolean             = false;
  public errorState:   boolean             = false;
  public controlType:  string              = 'available-days-input';
  public id:           string              = `available-days-input-${ AvailableDaysComponent.nextId++ }`;
  public describedBy:  string              = '';
  public onChange                          = (_: any) => {};
  public onTouched                         = () => {};
  public control:      UntypedFormControl | null;

  @HostBinding('attr.tabindex') __tabindex = 0;

  constructor(private _focusMonitor: FocusMonitor,
              private _translate:    TranslateService,
              private _elementRef:   ElementRef<HTMLElement>,
              @Optional() @Self() public ngControl: NgControl) {

    _focusMonitor.monitor(_elementRef, true)
    .subscribe((origin: any) => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
  }

  public resetValue(): void {
    this.value = this._pristineValue;
    this.pristine = true;
    this._setForm();
  }

  public opened(): void {
    this.control = new UntypedFormControl();

    this._setForm();

    fromEvent(document, 'keydown')
    .pipe(takeUntil(this.onClose))
    .subscribe((event: any) => {
      if (event.key == 'Escape') {
        this.trigger?.closeMenu()
      }
    });
  }

  public closed(): void {
    if (this.saveOnClose && this.valid && ! this.pristine) {
      this.pristine = true;
      this._pristineValue = this.value;
      this.emitter.emit(this.value);
    }

    if (this.saveOnClose && ! this.valid) {
      this.value     = this._pristineValue;
      this._valid    = true;
      this.pristine = true;
    }

    asyncScheduler
    .schedule(() => {
      this.control = null;
    });

    this.onClose.next(true);
  }

  public submit(): void {
    this.emitter.emit(this.value);
  }

  public inheritToggle(event: MatSlideToggleChange) {
    this.inherit?.setValue(event.checked);
    this._handleInput();
  }

  public masterToggle(): void {
    if (this.inherit?.value) return;
    this.control?.setValue(!this.control?.value?.length ? this._days : []);
    this._handleInput();
  }

  private _setForm(): void {
    this.inherit?.setValue(this.value === null);

    const val = this.value ?? [];

    if (this.value != null)
      this.control?.setValue(val.length ? val.map((x: any) => x.day) : this._days);
    else
      this.control?.setValue([]);
  }

  private _setValue(): void {

    if (this.inherit?.value) {
      this._value = null;
      this.valid  = true;
      this.pristine = _.isEqual(this.value, this._pristineValue);
    } else {
      const { value } = this.control ?? {};
      const _value: Day[] = value.map((day: any) => ({ day: day }));
      this._value = (value.length == this.numDays) ? [] : _value;
      this.valid  = value.length != 0;
      this.pristine = _.isEqual(_value, this._pristineValue);
    }
  }

  get valid(): boolean { return this._valid; }
  set valid(value: boolean) {
    this._valid = coerceBooleanProperty(value);
    this.errorState = ! this._valid;
  }
  private _valid: boolean = true;

  get empty() {
    return false
  }

  get shouldLabelFloat() { return this.focused || ! this.empty; }

  get pristine(): boolean {
    return this._pristine;
  }
  set pristine(_val: boolean) {
    const val      = coerceBooleanProperty(_val);
    this._pristine = val;

    if (val) asyncScheduler.schedule(() => this.ngControl?.control?.markAsPristine());

    this.stateChanges.next();
  }
  private _pristine:  boolean = true;

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get voidText(): string { return this._voidText; }
  set voidText(value: string) {
    this._voidText = value;
    this.stateChanges.next();
  }
  private _voidText: string = '';

  @Input()
  get reset(): boolean { return this._reset; }
  set reset(value: boolean) {
    this._reset = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _reset = true;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get inherit(): UntypedFormControl | null { return this._inherit; }
  set inherit(value: UntypedFormControl | null | string) {
    if (_.isString(value)) {
      this._inherit = new UntypedFormControl();
    } else {
      this._inherit = value ? value : new UntypedFormControl();
    }
    this.stateChanges.next();
  }
  private _inherit: UntypedFormControl | null;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get disableActions(): boolean { return this._disableActions; }
  set disableActions(value: boolean | string) {
    this._disableActions = coerceBooleanProperty(value);
  }
  private _disableActions = true;

  @Input()
  get saveOnClose(): boolean { return this._saveOnClose; }
  set saveOnClose(value: boolean | string) {
    this._saveOnClose = coerceBooleanProperty(value);
  }
  private _saveOnClose: boolean = false;

  @Input()
  get numDays(): number { return this._numDays; }
  set numDays(value: number | undefined) {
    this._numDays = coerceNumberProperty(value, 5);
    this._days    = [...Array(this._numDays).keys()];
    this.parseRawValue();
    this.stateChanges.next();
  }
  private _numDays = 5;

  @Input()
  get days(): number[] { return this._days; }
  private _days = [...Array(5).keys()];

  @Input()
  get value(): Day[] | null | undefined {
    return this._value;
  }
  set value(_val: Day[] | null | undefined) {
    this._rawValue = _val;
    this.parseRawValue();
    this.stateChanges.next();
  }
  private _rawValue:      Day[] | null | undefined;
  private _value:         Day[] | null | undefined;
  private _pristineValue: Day[] | null | undefined;

  private parseRawValue () {
    const _size = coerceNumberProperty(this._rawValue?.length, -1);

    if (_size == 0 || _size == this.numDays) {
      this._value         = [];
      this._pristineValue = this._days?.map((day: number) => { return { day } });
    } else if (_size > 0) {
      this._value = this._pristineValue = this._rawValue!.map(({ day }) => { return { day } });
    } else {
      this._value = this._pristineValue = this._rawValue
    }
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
    this.onClose.next(true);
    this.onClose.complete();
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent) {
    this.trigger?.openMenu();
  }

  writeValue(val: Day[]): void {
    this.value = val;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  _handleInput(): void {
    this._setValue();
    this.onChange(this.value);
  }

  static ngAcceptInputType_disabled: boolean | string | null | undefined;
  static ngAcceptInputType_required: boolean | string | null | undefined;
}
