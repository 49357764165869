import { AfterViewInit,
         Component,
         Input,
         OnDestroy                           } from '@angular/core';
import { SafeHtml                            } from '@angular/platform-browser';
import { BehaviorSubject,
         combineLatest,
         Subject                             } from 'rxjs';
import { filter,
         takeUntil                           } from 'rxjs/operators';
import $                                       from 'jquery';
import _                                       from 'lodash';
import { IntervalCollection                  } from '@royalschedule/input-verifier-v4';

import { Populated as P                      } from '@app/shared/interfaces';
import { InputAnalysisService                } from '@app/shared/services';
import { LoggerService,
         SourceService,
         TranslateService                    } from '@app/core';
import { commonConstants                     } from '@app/constants';
import { coldCMP,
         Focused,
         getColor,
         warmCMP                             } from './components/calendar/calendar.component';

export const eps = 1e-6;


type Group = P.group | P.teacher | P.person;
type Event = P.event | P.lockedTime;

export type Interval = {
  beg:    number;
  end:    number;
  binary?: boolean;
}

export type AnalysisOutput = {
  feasible: boolean;
  group: {
    id:        string;
    timeFrame: Interval[][];
  };
  events: {
    id:        string;
    event?:    Event;
    intervals: IntervalCollection;
  }[];
  intervals: {
    interval: Interval;
    intervalSize: number;
    interactionSets: {
      combinedMass: number;
      padding: number;
      feasible: boolean;
      events: {
        id:    string;
        event?: Event;
        mass:  number;
      }[];
    }[];
  }[];
}

export type FocusedEvent = {
  intervals:     AnalysisOutput['intervals'];
  businessHours: Interval[][];
}

type Source = {
  events:      P.event[],
  lockedTimes: P.lockedTime[],
}

function numMinutes2timeStr (minutes: number): string {
  let hour   = Math.trunc(minutes / 60);
  let minute = minutes % 60
  let minute_str = minute.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })
  return hour + ':' + minute_str;
}

function getEvent (
  val:    string,
  source: Source
): P.event | P.lockedTime | undefined {
  const { is, id } = InputAnalysisService.parseCompositeId(val);

  switch (is) {
    case 'event':      return source.events     .find(x => x.id === id);
    case 'lockedTime': return source.lockedTimes.find(x => x.id === id);
  }

  return undefined;
}

@Component({
  selector: 'app-input-analyzer-event-density',
  templateUrl: './event-density.component.html',
  styleUrls: ['./event-density.component.sass'],
  providers: [SourceService]
})
export class EventDensityComponent implements AfterViewInit, OnDestroy {
  private onDestroy = new Subject<void>();

  protected numDays: number = 5;
  protected dt: number = 5;

  protected defaultEventColor = commonConstants.COLORS.EVENT_DEFAULT;

  // determines zoom and initial scroll position
  protected frame?: { beg: string, end: string };

  // the group's intervals to be used as business hours
  protected onGroupIntervals = new BehaviorSubject<AnalysisOutput['intervals']>([]);
  protected onGroupTimeFrame = new BehaviorSubject<{ beg: number, end: number }[][]>([]);

  // when one hovers/selects an interval
  protected focusedInterval?: {
    day:    string,
    beg:    string,
    end:    string,
    events: AnalysisOutput['intervals'][number]['interactionSets'][number]['events']
  };

  // when one selects an event from the event list
  protected focusedEvent?: FocusedEvent;

  protected cmap_warm: string[] = []
  protected cmap_cold: string[] = []


  protected feasible: boolean;


  constructor (
    protected inputAnalysis: InputAnalysisService,
    private   _translate:    TranslateService,
    private   _logger:       LoggerService,
    private   _source:       SourceService
  ) {
    // initiate color gradients
    this.cmap_warm = [];
    this.cmap_cold = [];
    let N = 100
    for (let i = 0; i < N; i++) {
      this.cmap_warm.push(getColor(warmCMP, i / (N - 1)))
      this.cmap_cold.push(getColor(coldCMP, i / (N - 1)))
    }
    this.cmap_warm.reverse()

    // fetch did
    const did = inputAnalysis.did;
    if ( ! did) {
      this._logger.error(new Error(`(EventDensityComponent::constructor) did is undefined, probably because the input analyzer is deactivated.`))
      return;
    }

    //
    this._source.groupBy({ did,
      collections: ['settings', 'events', 'lockedTimes']
    })
    .then(() => {

      combineLatest({
        data:     this._onData.pipe(filter(Boolean)),
        source: combineLatest({
          settings:    this._source.getStrictSettings      ({ did, onDestroy: this.onDestroy }),
          events:      this._source.getPopulatedEvents     ({ did, onDestroy: this.onDestroy }),
          lockedTimes: this._source.getPopulatedLockedTimes({ did, onDestroy: this.onDestroy })
        })
      })
      .pipe(takeUntil(this.onDestroy))
      .subscribe(({ data, source }) => {
        // set num days and frame
        this.numDays = source.settings.numDays!;
        this.frame   = { beg: source.settings.dayStart!, end: source.settings.dayEnd! };

        // store feasibility
        this.feasible = data.feasible;

        // store the group's intervals
        this.onGroupTimeFrame.next(data.group.timeFrame);

        // remove events from intervals that do not contribute to the mass in
        // any meaningful way and afterwards remove intervals that are identical
        data.intervals.forEach(i => {
          // first remove events
          i.interactionSets.forEach(set => {
            set.events = set.events.filter(e => Math.round(e.mass / i.intervalSize * 100));
          });

          // then remove identical interaction sets
          i.interactionSets = _.uniqWith(i.interactionSets, (a, b) => {
            return a.combinedMass == b.combinedMass
                && a.padding      == b.padding
                && a.feasible     == b.feasible
                && _.isEqual(_.orderBy(a.events, 'id'), _.orderBy(b.events, 'id'));
          });
        });

        // join also the event from source
        data.events.forEach(x => x.event = getEvent(x.id, source));
        data.intervals.forEach(i =>
          i.interactionSets.forEach(set =>
            set.events.forEach(e => e.event = getEvent(e.id, source))
          )
        );

        // emit
        this.onGroupIntervals.next(data.intervals);

      });
    });


  }

  ngAfterViewInit (): void {
    // fix content height to enable overflow scroll
    let height = $('app-input-analyzer-event-density').height();
    height && $('app-input-analyzer-event-density').height(height);
  }

  ngOnDestroy () {
    this.onDestroy.next();
    this.onDestroy.complete();
  }


  @Input()
  description: SafeHtml | undefined;


  @Input()
  set group (group: Pick<Group, 'is' | 'id'>) {
    // fetch result
    const id = InputAnalysisService.prependCollection(group);
    const res = this.inputAnalysis.eventMassDistributionAnalysis?.find(x => x.group.id == id)
    if ( ! res) {
      this._logger.error(new Error(`event mass distribution analysis result not found for group "${id}" of type "${group.is}"`));
      return;
    }
    if ('aborted' in res) {
      this._logger.error(new Error(`the event mass distribution analysis was aborted for group "${id}" of type "${group.is}" after ${res.aborted} ms`));
      return;
    }

    // need to clone such that we do not modify the original object if we rerun this function without it being updated
    this._onData.next(structuredClone(res));
  }
  private _onData = new BehaviorSubject<AnalysisOutput | null>(null);


  protected setFocusedInterval (arg: Focused) {
    // unset
    if ( ! arg) {
      this.focusedInterval = undefined;
      return;
    }

    // fetch interval and interaction set
    const interval = this._onData.value!.intervals[arg.intervalIndex];
    const interSet = interval.interactionSets[arg.setIndex].events

    // parse interval time information
    const dayIndex = Math.trunc(interval.interval.beg * this.dt / 24 / 60);
    const day      = this._translate.instant(`common.day_${dayIndex}`);
    const beg      = numMinutes2timeStr(interval.interval.beg * this.dt % (24 * 60));
    const end      = numMinutes2timeStr(interval.interval.end * this.dt % (24 * 60));

    // store
    this.focusedInterval = { day, beg, end, events: interSet};
  }


  protected setFocusedEvent (id?: string) {
    // reset
    if ( ! id) {
      this.focusedEvent = undefined;
      return;
    }

    // remove all other events from intervals and intervals and sets that become unpopulated
    let intervals
      = structuredClone((this._onData.value!.intervals ?? []))
        .map(int => {
          const interactionSets = int.interactionSets
            .map(set => {
              const events       = set.events.filter(e => e.id == id)
              const combinedMass = events[0]?.mass ?? 0;
              const padding      = set.padding;
              const feasible     = combinedMass <= int.intervalSize - set.padding + eps;
              return { events, combinedMass, padding, feasible };
            })
            .filter(set => set.events.length > 0);

          return { ...int, interactionSets }
        })
        .filter(i => i.interactionSets.length > 0);

    // try join adjacent intervals if they are equally populated
    let i = 0;
    while (i < intervals.length - 1) {
      const curr = intervals[i];
      const next = intervals[i + 1];

      // must be adjacent
      if (curr.interval.end != next.interval.beg) {
        i++;
        continue;
      }

      // check if the two intervals are equally populated
      let currFillingPercent = curr.interactionSets.map(set => Math.round(set.combinedMass / curr.intervalSize * 100));
      let nextFillingPercent = next.interactionSets.map(set => Math.round(set.combinedMass / next.intervalSize * 100));
      if ( ! _.isEqual(currFillingPercent, nextFillingPercent)) {
        i++;
        continue
      }

      // join intervals
      curr.interval.end = next.interval.end;
      curr.intervalSize += next.intervalSize;
      for (let j = 0; j < curr.interactionSets.length; j++) {
        curr.interactionSets[j].combinedMass   += next.interactionSets[j].combinedMass;
        curr.interactionSets[j].events[0].mass += next.interactionSets[j].events[0].mass;
      }

      // remove
      intervals.splice(i + 1, 1);
    }

    // business hours
    const businessHours = this._onData.value!.events.find(x => x.id == id)?.intervals ?? [];

    // trigger send to calendar component
    this.focusedEvent = { intervals, businessHours };
  }

  protected isEvent (event: Event | undefined): event is P.event {
    return event?.is == 'event';
  }
  protected isLockedTime (event: Event | undefined): event is P.lockedTime {
    return event?.is == 'lockedTime';
  }

}
