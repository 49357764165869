<!-- force rerender on stateChanges.next() otherwise it is not guaranteed to show the latest values due to some lazy rendering-->
{{ stateChanges | async }}

<div class="days-select-label clickable"
     (menuClosed)="closed()"
     (menuOpened)="opened()"
     [matMenuTriggerFor]="menu">
    <app-form-field-available-days-display-value [value]="value"
                                                 [inherit]="!!inherit"
                                                 [voidText]="voidText">
    </app-form-field-available-days-display-value>
</div>

<mat-menu yPosition="below"
          #menu="matMenu"
          class="available-days-panel no-vertical-padding"
          [overlapTrigger]="true">
  <ng-template matMenuContent>

    <div class="toolbar" (click)="$event.stopPropagation()">
      <div class="row">

        @if (inherit) {
          <mat-slide-toggle
            class="inherit-toggle"
            labelPosition="before"
            color="accent"
            (change)="inheritToggle($event)"
            [checked]="inherit.value">
            {{ 'attributes.event.inherit' | translate }}
          </mat-slide-toggle>
        }

        <button mat-stroked-button
                class="action reset"
                color="primary"
                (click)="resetValue()"
                *ngIf="! pristine && reset && valid">
          <div>
            <span>{{ 'common.restore' | translate }}</span>
            <mat-icon class="royal-icon">undo</mat-icon>
          </div>
        </button>

        <button mat-stroked-button
                class="action invalid"
                *ngIf="! pristine && ! valid"
                color="primary">
          <div>
            <span>{{ 'common.invalid' | translate }}</span>
            <mat-icon class="royal-icon">warnings</mat-icon>
          </div>
        </button>
      </div>
      <div class="row">
        <mat-checkbox
          color="accent"
          (click)="masterToggle()"
          [disabled]="inherit?.value"
          [checked]="control?.value?.length == numDays"
          [indeterminate]="control?.value?.length < numDays && control?.value?.length > 0">
        </mat-checkbox>
      </div>
    </div>

    <div (click)="$event.stopPropagation()" *ngIf="control" class="content">

      <mat-selection-list
        [formControl]="control"
        (selectionChange)="_handleInput()"
      >
        <mat-list-option *ngFor="let day of days"
                        checkboxPosition="before"
                        [disabled]="inherit?.value"
                        [value]="day">
          {{ 'common.day_' + day | translate }}
        </mat-list-option>
      </mat-selection-list>

    </div>

    <mat-divider class="divider"
                *ngIf="! disableActions"
                (click)="$event.stopPropagation()"></mat-divider>

    <div (click)="$event.stopPropagation()"
        class="actions"
        *ngIf="! disableActions">
      <button mat-stroked-button
              color="primary"
              type="button"
              class="button"
              (click)="trigger?.closeMenu()">
        {{ 'common.cancel' | translate }}
      </button>
      <button mat-flat-button
              color="primary"
              type="button"
              [disabled]="! valid"
              (click)="submit(); trigger?.closeMenu()"
              class="button right">
        {{ 'common.save' | translate }}
      </button>
    </div>
  </ng-template>

</mat-menu>


