import { Component,
         ViewChild,
         AfterViewInit,
         OnInit                            } from '@angular/core';
import { MatDialogRef                      } from 'app/common';
import { DivisionComponent                 } from 'app/shared/forms/division/division.component';
import { SubmitPaneComponent               } from 'app/shared/components';
import { apiConstants                      } from 'app/constants';
import { HttpService,
         PushNotificationService           } from 'app/core';
import { DivisionComponent as Form         } from 'app/shared/forms/division/division.component';
import { Division                          } from '@app/shared/interfaces';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent implements OnInit, AfterViewInit {

  @ViewChild(Form, { static: true })                form:       Form;
  @ViewChild(DivisionComponent)                     division?:   DivisionComponent;
  @ViewChild(SubmitPaneComponent, { static: true }) submitPane: SubmitPaneComponent;

  public loading:   boolean;
  public submitted: boolean;

  constructor (
    protected dialogRef: MatDialogRef<CreateComponent, string>,
    private   _http:     HttpService,
    private   _push:     PushNotificationService
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.form.setFormValue();
  }

  public submit(): void {
    this.submitted = true;
    this.submitPane.setSavingState();
    let model = {
      division: this.division?.value
    };
    this._http.post(`${ apiConstants.DIVISIONS }`, model, undefined)
    .subscribe({
      next: ({ division }: { division: Division }) => {
        if ( ! division.id) this._push.pushError();

        this.submitPane.setCreationSuccessState()
        .then(() => this.dialogRef.close(division.id));
      },
      error: (err: Error) => {
        this.submitPane.setCreationFail();
        this.submitted = false;
      }
    });
  }
}
