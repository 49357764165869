import { Injectable,
         Injector,
         TemplateRef                     } from '@angular/core';
import { ComponentType                   } from '@angular/cdk/portal';
import { MatDialogConfig                 } from '@angular/material/dialog';
import { BehaviorSubject,
         Observable                      } from 'rxjs';
import { filter,
         first,
         map                             } from 'rxjs/operators';

import { MatDialog,
         MatDialogRef                    } from 'app/common';
import { Types                           } from '@app/shared/interfaces';
import { TermsDialogComponent,
         CompanyComponent,
         ConfirmRemoveComponent,
         ChangePasswordComponent,
         UploadFileComponent,
         LoginComponent,
         ConfirmActionComponent,
         UnsavedChangesComponent,
         ParallelCoursesComponent,
         SyncComponent,
         InputIssuesComponent,
         GenerateScheduleComponent,
         AddDurationSetComponent,
         DebugComponent,
         Division,
         PartialDivision                 } from 'app/shared/dialogs';
import { EditScheduleDataComponent       } from 'app/shared/dialogs/edit-schedule-data/edit-schedule-data.component';
import { CreateComponent                 } from '@app/shared/dialogs/divisions/create/create.component';
import { DuplicateScheduleComponent      } from '@app/shared/dialogs/duplicate-schedule/duplicate-schedule.component';
import { EditCourseAndEventComponent,
         Data as EditCourseAndEventComponentData } from '@app/shared/dialogs/edit-course-and-event/edit-course-and-event.component';

@Injectable({
  providedIn: 'root'
})
export class DialogsService {

  constructor(private dialog: MatDialog) { }

  private _open<T, D = any, R = any> (
    component: ComponentType<T> | TemplateRef<T>,
    options?:  MatDialogConfig<D>
  ): MatDialogRef<T, R> {
    return this.dialog.open<T, D, R>(component, options);
  }

  public openCompanyDialog(data?: { organization?: boolean, theme?: boolean, schoolCode: boolean, schools: BehaviorSubject<object[]> }) {
    return this._open(CompanyComponent, {
      data
    }).afterClosed();
  }

  public openRemoveDialog() {
    return this._open<ConfirmRemoveComponent, { }, boolean>(ConfirmRemoveComponent).afterClosed();
  }

  public openEditCourseAndCourseEventDialog(
    data: EditCourseAndEventComponentData,
    injector: Injector
  ) {
    return this._open<EditCourseAndEventComponent, EditCourseAndEventComponentData, any>(
      EditCourseAndEventComponent,
      {
        data:         data,
        disableClose: true,
        injector:     injector
      }
    )
    .afterClosed()
    .pipe(filter(Boolean));
  }

  public openChangePasswordDialog() {
    return this._open(ChangePasswordComponent)
            .afterClosed()
            .pipe(filter((val) => { return val === true }));
  }

  public openUploadFileDialog () {
    return this._open<UploadFileComponent, {}, string>(UploadFileComponent, {
      maxHeight:  '90vh',
      maxWidth:   '800px',
      panelClass: 'position-relative'
    }).afterClosed();
  }

  public openTermsDialog() {
    return this._open(TermsDialogComponent, {
      disableClose: false,
      width: '700px',
      role: 'dialog',
      panelClass: ['responsive-dialog']
    }).afterClosed();
  }

  public openEditScheduleDataDialog(data: { did: string, editable: boolean }) {
    return this._open(EditScheduleDataComponent, {
      panelClass:   'full-width-dialog',
      width:        '98vw',
      height:       '98vh',
      disableClose: true,
      data:         data
    })
    .afterClosed();
  }

  public openCreateDivisionDialog () {
    return this._open<CreateComponent, {
    }, string>(Division.create)
    .afterClosed();
  }

  public openEditDivisionDialog(division: PartialDivision):Observable<PartialDivision | undefined> {
    return this._open(Division.edit, {
      data: { division },
    })
    .afterClosed();
  }

  public openConfirmActionDialog(): Observable<boolean> {
    let dialog = this._open(ConfirmActionComponent) as MatDialogRef<ConfirmActionComponent>;

    // map to true or false
    return dialog.afterClosed().pipe(map((val) => (val == true)));
  }

  public openUnsavedChangesDialog(): Observable<'discard' | 'save'> {
    return this._open(UnsavedChangesComponent)
    .afterClosed();
  }

  public openLoginDialog(): Observable<any> {
    return this._open(LoginComponent)
    .afterClosed();
  }

  public openDuplicateScheduleDialog (
    did:         string,
    displayName: string
  ): Observable<true | undefined> {
    return this._open<DuplicateScheduleComponent, { did: string, displayName: string }, true>(
      DuplicateScheduleComponent,
      {
        panelClass: 'responsive-dialog',
        data: { did, displayName },
      }
    ).afterClosed();
  }

  public openOverlapGroupsDialog (did: string, overlapGroupId?: string) {
    return this._open<ParallelCoursesComponent, { did: string, overlapGroupId?: string }, undefined>(
      ParallelCoursesComponent,
      { data: { did, overlapGroupId },
        width:     '77vw',
        maxHeight: '95vh'
      })
      .afterClosed();
  }

  public openSyncDialog(
    data: {
      division?:        Types.divisions,
      integrationType?: Types.integrations['integrationType']
    }
  ) {
    return this._open<SyncComponent, typeof data, undefined>(
      SyncComponent,
      {
        data,
        width:      '77vw',
        height:     '90vh',
        panelClass: ['position-relative', 'no-padding', 'responsive-dialog']
      })
      .afterClosed();
  }

  public openInputIssuesDialog (
    did:       string,
    targetId?: string
  ): void {
    if (this._inputIssuesDialogRef && targetId) {
      // if already open, do not open a new one
      this._inputIssuesDialogRef.componentInstance.goTo(targetId);
    } else {
      // if not open, open a new one
      this._inputIssuesDialogRef
        = this._open(InputIssuesComponent, {
            width:        '98vw',
            maxWidth:     '1500px',
            autoFocus:    false,
            data:         { did, targetId }
          });

      this._inputIssuesDialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(() => {
        delete this._inputIssuesDialogRef;
      });
    }
  }
  private _inputIssuesDialogRef?: MatDialogRef<InputIssuesComponent>;

  public openGenerateScheduleDialog (
    did:          string,
    onIsRunning?: Observable<boolean | null>
  ): Observable<any> {
    return this._open(
      GenerateScheduleComponent,
      {
        data: { did, onIsRunning },
        maxWidth: '900px'
      }
    ).afterClosed();
  }

  public openDebugDialog(): Observable<void> {
    return this._open(DebugComponent)
               .afterClosed();
  }

  public openAddDurationSetDialog(): Observable<number[]> {
    return this._open(AddDurationSetComponent)
               .afterClosed();
  }

}