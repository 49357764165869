import { Component,
         OnInit,
         AfterViewInit,
         ViewChild,
         Input,
         EventEmitter,
         Output,
         OnDestroy                         } from '@angular/core';
import { BehaviorSubject,
         combineLatest,
         debounceTime,
         filter,
         Subject,
         takeUntil                         } from 'rxjs';
import moment                                from 'moment';
import { computeEventCenter                } from '@royalschedule/input-verifier-v4';

import { CalendarComponent as Calendar,
         CalendarOptions                   } from 'app/shared';
import { DateService                       } from 'app/shared/services';

import { Populated as P                    } from 'app/shared/interfaces';
import { InCalendarBackgroundEvent         } from '@app/shared/calendar/calendar/types';
import { EventColorPipe                    } from '@app/shared/pipes/common/common.pipe';


const dt = 5;

function duration2size (duration: number) {
  return Math.floor(duration / dt);
}

type Interval = {
  beg:     number;
  end:     number;
  color?:  string;
  binary?: boolean;
}

type Frame = { start: moment.Moment, end: moment.Moment };

type Event = P.event | P.lockedTime;

@Component({
  selector: 'app-input-analyzer-event-intervals-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.sass']
})
export class CalendarComponent implements OnInit, AfterViewInit, OnDestroy {
  // get access to child directive
  @ViewChild(Calendar) calendar: Calendar;

  private onDestroy  = new Subject<void>();
  protected onPosition = new Subject<moment.Moment | undefined>();

  public options: CalendarOptions = { bottomFade: false };


  constructor (private _date:       DateService,
               private _eventColor: EventColorPipe) { }

  ngOnInit () { }

  ngAfterViewInit () {

    //
    this._frame.pipe(
      takeUntil(this.onDestroy),
      filter(Boolean),
      debounceTime(0)   // needed in order for the rendering to be completed
    )
    .subscribe(frame => this.calendar.visibleRange = [ frame ]);

    //
    this._intervals
    .pipe(takeUntil(this.onDestroy))
    .subscribe(intervals => {
      this.calendar.setBackgroundEvents(intervals);
    });

    combineLatest({
      position:     this.onPosition,
      event:        this.onEvent,
      linkedEvents: this.onLinkedEvents
    })
    .pipe(
      takeUntil(this.onDestroy),
      debounceTime(10)
    )
    .subscribe(({ position, event, linkedEvents }) => {
      this._insertEvent(position, event, linkedEvents);
    });
  }

  ngOnDestroy () {
    this.onDestroy.next();
    this.onDestroy.complete();
  }


  @Input()
  set intervals (intervals: Interval[]) {
    const val: InCalendarBackgroundEvent[] = intervals.map(i => ({
      start:           this._date.fromDiscretizedTime(i.beg, dt),
      end:             this._date.fromDiscretizedTime(i.end, dt),
      backgroundColor: i.color || 'transparent',
      classNames:      [i.binary ? 'binary' : ''],
      title:           i.binary ? `${ (i.end - i.beg) * dt } min` : ''
    }));
    this._intervals.next(val);
  }
  private _intervals = new BehaviorSubject<InCalendarBackgroundEvent[] | null>(null);

  @Input()
  set frame (int: { beg: string, end: string }) {
    this._frame.next({
      start: DateService.fromTimeString(int.beg),
      end:   DateService.fromTimeString(int.end)
    });
  }
  private _frame = new BehaviorSubject<Frame | null>(null);


  @Input()
  set event (val: Event | undefined | null) {
    this.onEvent.next(val ?? null);
  }
  private onEvent = new BehaviorSubject<Event | null>(null);


  @Input()
  set linkedEvents (val: Event[] | undefined | null) {
    this.onLinkedEvents.next(val ?? []);
  }
  private onLinkedEvents = new BehaviorSubject<(Event)[]>([ ]);

  private duration2size (duration: number) {
    return Math.floor(duration / dt);
  }

  // private _mom?: moment.Moment;
  private _insertEvent (
    mousePosition: moment.Moment | undefined,
    event:         Event | null,
    linkedEvents: (Event)[]
  ) {
    // then try to reinsert it at the new time
    if (event && mousePosition) {

      // integer division: ~~(nom / denom)
      const duration = event.is == 'event' ? event.preferredDuration : event.duration!;
      const halfDuration = ~~(duration / 10) * dt;

      const start = mousePosition.clone().subtract(halfDuration, 'minutes');
      const end   = mousePosition.clone().add(duration - halfDuration, 'minutes');

      // combine main and linked events
      const events = [event, ...linkedEvents]
        .map((e, i) => {

          // compute event offset to longest one
          const _duration = e.is == 'event' ? e.preferredDuration : e.duration!;
          const offset = computeEventCenter(duration2size(duration ))[0]*dt
                       - computeEventCenter(duration2size(_duration))[0]*dt;

          const _start = start .clone().add(offset,    'minutes');
          const _end   = _start.clone().add(_duration, 'minutes');

          const id              = e.id;
          const title           = i == 0 ? duration + ' min' : '';
          const backgroundColor = this._eventColor.transform(e);
          const classNames      = [i == 0 ? 'main-event' : 'linked-event'];

          return { id, classNames, backgroundColor, title, start: _start, end: _end };
        });

      // add events
      this.calendar?.setForegroundEvents(events);

      this.placeholderEventLocation.emit({
        day:   DateService.getDayIndex(start),
        start: (start.hours()*60 + start.minutes()) / dt,
        end:   (end  .hours()*60 + end  .minutes()) / dt
      });
    } else {
      // reset
      this.calendar?.removeForegroundEvents();
      this.placeholderEventLocation.emit();
    }
  }


  @Input()
  set numDays (x) { this._numDays = x }
  get numDays ()  { return this._numDays; }
  private _numDays: number = 5;


  @Output()
  private placeholderEventLocation = new EventEmitter<{ day: number, start: number, end: number }>();
}
