@if (!loading() && form) {
  <form [formGroup]="form"
      novalidate
      class="form"
      (keydown)="keydown($event)">
      @switch (type) {
        @case ('column') {

        <div class="content-default">
          <div class="area-1">
            <div class="form-field-wrapper" *ngIf="displayName">
              <mat-icon class="form-field-icon">event_note</mat-icon>
              <mat-form-field class="form-field" appearance="outline" floatLabel="always">
                <mat-label>{{ 'attributes.shared.displayName' | translate }}</mat-label>
                <input matInput type="text" formControlName="displayName" autocomplete="off" [placeholder]="course?.displayName ?? ''">
                <mat-error><app-form-field-error [control]="form.controls.displayName"></app-form-field-error></mat-error>
              </mat-form-field>
            </div>

            <div class="form-field-wrapper" *ngIf="ids">
              <mat-icon class="form-field-icon">fingerprint</mat-icon>
              <mat-form-field class="form-field" appearance="outline" floatLabel="always">
                <mat-label>{{ 'attributes.shared.ids' | translate }}</mat-label>
                <input matInput type="text" formControlName="ids" autocomplete="off">
                <mat-error><app-form-field-error [control]="form.controls.ids"></app-form-field-error></mat-error>
              </mat-form-field>
            </div>

            <div class="form-field-wrapper" *ngIf="duration">
              <mat-icon class="form-field-icon">vertical_align_center</mat-icon>
              <mat-form-field class="form-field" appearance="outline" floatLabel="always">
                <mat-label>{{ 'attributes.shared.duration' | translate }}</mat-label>
                <app-form-field-duration required
                                        formControlName="duration"
                                        [suffix]="'common.minutes' | translate | lowercase">
                </app-form-field-duration>
                <!-- <span matTextSuffix class="form-field-suffix">{{ 'common.minutes' | translate }}</span> -->
                <mat-error><app-form-field-error [control]="form.controls.duration"></app-form-field-error></mat-error>
              </mat-form-field>
            </div>

            <div class="form-field-wrapper" *ngIf="preferredDuration">
              <mat-icon class="form-field-icon">vertical_align_center</mat-icon>
              <mat-form-field class="form-field" appearance="outline" floatLabel="always">
                <mat-label>{{ 'attributes.event.preferredDuration' | translate }}</mat-label>
                <app-form-field-duration required
                                        formControlName="preferredDuration"
                                        [suffix]="'common.minutes' | translate | lowercase">
                </app-form-field-duration>
                <!-- <span matTextSuffix class="form-field-suffix">{{ 'common.minutes' | translate }}</span> -->
                <mat-error><app-form-field-error [control]="form.controls.preferredDuration"></app-form-field-error></mat-error>
              </mat-form-field>
            </div>

            <div class="form-field-wrapper" *ngIf="durationVariance">
              <mat-icon class="form-field-icon">expand</mat-icon>
              <mat-form-field class="form-field" appearance="outline" floatLabel="always">
                <mat-label>{{ 'attributes.event.durationVariation' | translate }}</mat-label>
                <app-form-field-duration formControlName="durationVariance"
                                        disableActions
                                        disableTooltip
                                        prefix="±"
                                        [suffix]="'common.minutes' | translate | lowercase"
                                        [voidText]="course?.eventDurationVariance ?? settings?.defaultEventDurationVariance"
                                        [setVoidText]="'common.inheritFromCourseValue' | translate:{ value: course?.eventDurationVariance ?? settings?.defaultEventDurationVariance }"
                                        inherit
                                        [min]="0"
                                        nullable>
                </app-form-field-duration>
                <!-- <span matTextSuffix class="form-field-suffix">{{ 'common.minutes' | translate }}</span> -->
                <mat-error><app-form-field-error [control]="form.controls.durationVariance"></app-form-field-error></mat-error>
              </mat-form-field>
            </div>

            <div class="form-field-wrapper" *ngIf="periods">
              <mat-icon class="form-field-icon">{{ 'periods'  | collectionIcon }}</mat-icon>
              <mat-form-field class="form-field" appearance="outline" floatLabel="always">
                <mat-label>{{ 'attributes.shared.period' | translate }}</mat-label>
                <app-form-field-period formControlName="period"
                                      disableActions
                                      disableTooltip
                                      inherit
                                      [voidText]="(course?.period?.displayName ?? settings?.period?.displayName ?? ('attributes.shared.period.entire' | translate)) | capitalizeFirst"
                                      [setVoidText]="'common.inheritFromCourseValue' | translate:{ value: (course?.period?.displayName ?? settings?.period?.displayName ?? ('attributes.shared.period.entire' | translate)) | capitalizeFirst }"
                                      [options]="periodList">
                </app-form-field-period>
                <mat-error><app-form-field-error [control]="form.controls.period"></app-form-field-error></mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="area-2">
            <div class="form-field-wrapper" *ngIf="teachers">
              <mat-icon class="form-field-icon">{{ 'teachers'  | collectionIcon }}</mat-icon>
              <mat-form-field #teachersField class="form-field" appearance="outline" floatLabel="always">
                <mat-label>{{ 'attributes.shared.teachers' | translate }}</mat-label>
                <div class="full-width full-height min-height-24 flex flex-align-center"
                    formField="teachers"
                    collection="events"
                    [control]="form.controls.teachers"
                    [formControlAccessor]="teachersField">
                </div>
                <mat-error><app-form-field-error [control]="form.controls.teachers"></app-form-field-error></mat-error>
              </mat-form-field>
            </div>

            <div class="form-field-wrapper" *ngIf="groups">
              <mat-icon class="form-field-icon">{{ 'groups'  | collectionIcon }}</mat-icon>
              <mat-form-field #groupsField class="form-field" appearance="outline" floatLabel="always">
                <mat-label>{{ 'attributes.shared.groups' | translate }}</mat-label>

                <div class="full-width full-height min-height-24 flex flex-align-center"
                    formField="groups-persons"
                    collection="events"
                    [control]="form.controls.groups"
                    [formControlAccessor]="groupsField"
                    [coalescedControl]="form.controls.participants">
                </div>
                <mat-error><app-form-field-error [control]="form.controls.groups"></app-form-field-error></mat-error>
              </mat-form-field>
            </div>

            <div class="form-field-wrapper" *ngIf="locations">
              <mat-icon class="form-field-icon">{{ 'locations'  | collectionIcon }}</mat-icon>
              <mat-form-field class="form-field" appearance="outline" floatLabel="always">
                <mat-label>{{ 'attributes.shared.locations' | translate }}</mat-label>
                <app-form-field-available-locations formControlName="locations"
                                                    disableActions
                                                    disableTooltip
                                                    add
                                                    inherit
                                                    [voidText]="(course?.locations | pick:'locations.0' | displayNames) || '-'"
                                                    [list]="locationList">
                </app-form-field-available-locations>
                <mat-error><app-form-field-error [control]="form.controls.locations"></app-form-field-error></mat-error>
              </mat-form-field>
            </div>

            <div class="form-field-wrapper" *ngIf="minBreakLength">
              <mat-icon class="form-field-icon">transfer_within_a_station</mat-icon>
              <mat-form-field class="form-field" appearance="outline" floatLabel="always">
                <mat-label>{{ 'attributes.shared.minBreakLength' | translate }}</mat-label>
                <app-form-field-break-length formControlName="minBreakLength"
                                            disableActions
                                            inherit
                                            [defaultValue]="course?.minBreakLength ?? settings?.defaultEventMinimumBreakLength"
                                            [voidText]="(course?.minBreakLength | readableBreakLength) || (settings?.defaultEventMinimumBreakLength | readableBreakLength)"
                                            [setVoidText]="'common.inheritFromCourseValue' | translate:{ value: (course?.minBreakLength | readableBreakLength) || (settings?.defaultEventMinimumBreakLength | readableBreakLength) }">
                </app-form-field-break-length>
                <mat-error><app-form-field-error [control]="form.controls.minBreakLength"></app-form-field-error></mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="area-3">

            <div class="form-field-wrapper" *ngIf="days">
              <mat-icon class="form-field-icon">calendar_today</mat-icon>
              <mat-form-field class="form-field" appearance="outline" floatLabel="always">
                <mat-label>{{ 'attributes.shared.days' | translate }}</mat-label>
                <app-form-field-available-days formControlName="days"
                                              [numDays]="settings?.numDays"
                                              inherit
                                              [voidText]="(course?.days | dayNames:'short') || ('common.all' | translate)"
                                              disableActions
                                              inherit>
                </app-form-field-available-days>
                <mat-error><app-form-field-error [control]="form.controls.days"></app-form-field-error></mat-error>
              </mat-form-field>
            </div>

            <div class="form-field-wrapper" *ngIf="intervals">
              <mat-icon class="form-field-icon">compress</mat-icon>
              <mat-form-field class="form-field" appearance="outline" floatLabel="always">
                <mat-label>{{ 'attributes.shared.intervals' | translate }}</mat-label>
                <app-form-field-intervals formControlName="intervals"
                                          disableActions
                                          inherit
                                          [numDays]="settings?.numDays"
                                          [availableDays]="form.controls.days.value"
                                          [inheritAvailableDays]="course?.days"
                                          [inheritValue]="course?.intervals"
                                          [voidText]="settings?.dayStart + ' - ' + settings?.dayEnd"
                                          [setVoidText]="'common.inheritFromCourse' | translate">
                </app-form-field-intervals>
                <mat-error><app-form-field-error [control]="form.controls.intervals"></app-form-field-error></mat-error>
              </mat-form-field>
            </div>

            <div class="form-field-wrapper" *ngIf="centerOfAttraction">
              <mat-icon class="form-field-icon">compress</mat-icon>
              <mat-form-field class="form-field" appearance="outline" floatLabel="always">
                <mat-label>{{ 'attributes.shared.centerOfAttraction' | translate }}</mat-label>
                <app-form-field-center-of-attraction formControlName="centerOfAttraction"
                                                    [preselected]="course?.centerOfAttraction || settings?.potentialCenter"
                                                    [voidText]="course?.centerOfAttraction || settings?.potentialCenter"
                                                    [setVoidText]="'common.inheritFromCourseValue' | translate:{ value: course?.centerOfAttraction || settings?.potentialCenter }"
                                                    disableActions>
                </app-form-field-center-of-attraction>
                <mat-error><app-form-field-error [control]="form.controls.centerOfAttraction"></app-form-field-error></mat-error>
              </mat-form-field>
            </div>

            <div class="form-field-wrapper" *ngIf="lockedTimes">
              <mat-icon class="form-field-icon">lock_clock</mat-icon>
              <mat-form-field class="form-field" appearance="outline" floatLabel="always">
                <mat-label>{{ 'attributes.shared.lockedTimes' | translate }}</mat-label>
                <app-form-field-locked-times formControlName="lockedTimes"
                                            inherit
                                            [numDays]="settings?.numDays"
                                            [voidText]="(course?.lockedTimes | readableLockedTimes) || '-'"
                                            disableActions
                                            [hideCalendar]="hideCalendars">
                </app-form-field-locked-times>
                <mat-error><app-form-field-error [control]="form.controls.lockedTimes"></app-form-field-error></mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
      }
    @case('row') {
      <div class="content-row">

        <div class="form-field-wrapper" *ngIf="duration">
          <mat-form-field class="form-field" appearance="outline" floatLabel="always" style="font-size: 12px">
            <mat-label>{{ 'attributes.shared.duration' | translate }}</mat-label>
            <app-form-field-duration required formControlName="duration"></app-form-field-duration>
            <mat-error><app-form-field-error [control]="form.controls.duration"></app-form-field-error></mat-error>
          </mat-form-field>
        </div>

        <!-- <div class="form-field-wrapper" *ngIf="false">
          <mat-form-field class="form-field" appearance="outline" floatLabel="always" style="font-size: 12px">
            <mat-label>{{ 'attributes.event.course' | translate }}</mat-label>
            <app-form-field-course required formControlName="course"></app-form-field-course>
            <mat-error><app-form-field-error [control]="form.controls.course"></app-form-field-error></mat-error>
          </mat-form-field>
        </div> -->

      </div>
    }
  }
</form>
}
@if(loading()) {
  <app-loading loading></app-loading>
}