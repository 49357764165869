////
//// more or less copy-pasta from: https://nartc.me/blog/typed-mat-cell-def/
////
//// usage: <table #table mat-table ...>
////          <... mat-cell *matCellDef="let division; table: table" ....>
////                                                   ^^^^^^^^^^^^
////

import { CdkCellDef, CdkTable } from "@angular/cdk/table";
import { Directive, Input     } from "@angular/core";
import { MatCellDef, MatTable } from "@angular/material/table";


@Directive({
    selector: "[matCellDef]", // same selector as MatCellDef
    providers: [{ provide: CdkCellDef, useExisting: TypeSafeMatCellDefDirective }],
})
export class TypeSafeMatCellDefDirective<T> extends MatCellDef {
    // leveraging syntactic-sugar syntax when we use *matCellDef

    // @Input() matCellDefDataSource:
    //     | Array<T>
    //     | Observable<Array<T>>
    //     | MatTableDataSource<T>
    //     | DataSourceService<T extends Row ? T : never>;

    // use this instead of the above to not affect change detection or whatever
    // (see comment of the website)
    @Input() matCellDefTable?: MatTable<T>;

    // if we use <table cdk-table ...> instead of <table mat-table ...>
    @Input() cdkCellDefTable?: CdkTable<T>;

    // ngTemplateContextGuard flag to help with the Language Service
    static ngTemplateContextGuard<T>(
        dir: TypeSafeMatCellDefDirective<T>,
        ctx: unknown,
    ): ctx is { $implicit: T; index: number } {
        return true;
    }
}