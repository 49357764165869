import { Injectable,
         NgZone,
         OnDestroy                                                    } from '@angular/core';
import { BehaviorSubject,
         distinctUntilChanged,
         map,
         merge,
         startWith,
         Subject,
         throttleTime                                                 } from 'rxjs';

import { AuthService,
         LoggerService                                                } from 'app/core';
import { tabInactiveDelay,
         tabInactiveDelayThrottleTime as _throttleTime                } from './constants';
import { Broadcast                                                    } from './Broadcast';
import { SharedWorkerWrapper                                          } from './Shared-worker/Shared-worker-wrapper';


@Injectable({
  providedIn: 'root'
})
export class UserInactivityService implements OnDestroy {
  private readonly onDestroy = new Subject<void>();

  private readonly bc = new Broadcast<void>('UserInactivityService::reload');

  private readonly _tabInactive$                   = new BehaviorSubject<boolean>(false);
  private readonly _tabActive$                     = new BehaviorSubject<boolean>(true );
  private readonly _globallyInactive$              = new BehaviorSubject<boolean>(false);
  private readonly _globallyActive$                = new BehaviorSubject<boolean>(true );
  private readonly _globalAuthenticatedInactivity$ = new BehaviorSubject<boolean>(false);

  constructor (
    auth:   AuthService,
    logger: LoggerService,
    ngZone: NgZone
  ) {
    // run outside of angular to prevent change detection
    ngZone.runOutsideAngular(() => {

      // run cleanup also when the tab is closed
      window.addEventListener('beforeunload', () => this.ngOnDestroy());

      // register to the shared worker which notifies the shared workers of the tab's activity state and in turn subscribes to the global activity state
      const sharedWorker = new SharedWorkerWrapper(auth.onIsAuthenticated, this.tabActive$, this.onDestroy, logger, ngZone);
      if ( ! sharedWorker.isSupported()) return;
      const globalState$ = sharedWorker.register();

      // setup event listeners
      const event$ = new Subject<void>();
      window.addEventListener('mousemove',      () => event$.next());
      window.addEventListener('click',          () => event$.next());
      window.addEventListener('keypress',       () => event$.next());
      window.addEventListener('DOMMouseScroll', () => event$.next());
      window.addEventListener('mousewheel',     () => event$.next());
      window.addEventListener('touchmove',      () => event$.next());
      window.addEventListener('MSPointerMove',  () => event$.next());

      // determine if the tab is active
      // (the startWith is crucial as the tab might never be touched and thus never become inactive)
      // (add a small (in comparison) throttleTime before resilientDebounceTime to prevent it from running to often as it is relatively expensive)
      merge(
        event$.pipe(startWith(null),                                                                                                    map(() => true )),
        event$.pipe(startWith(null), throttleTime(_throttleTime), sharedWorker.resilientDebounceTime(tabInactiveDelay - _throttleTime), map(() => false))
      )
      .pipe(
        distinctUntilChanged()
      )
      .subscribe(active => {
        this._tabActive$  .next(   active);
        this._tabInactive$.next( ! active);
      });

      // listen to the global active state
      globalState$
      .pipe(
        map(x => x.active),
        distinctUntilChanged()
      )
      .subscribe(active => {
        this._globallyActive$  .next(   active);
        this._globallyInactive$.next( ! active);
      });

      // listen to the global inactivity while authenticated state
      globalState$
      .pipe(
        map(x => x.authenticatedInactivityEncountered),
        distinctUntilChanged()
      )
      .subscribe(inactivity => this._globalAuthenticatedInactivity$.next(inactivity));

      // listen to reload and reset messages
      this.bc.onMessage.subscribe(() => window.location.reload());

    });
  }

  ngOnDestroy () {
    // close the broadcast channel
    this.bc.close();

    this.onDestroy.next();
    this.onDestroy.complete();
  }


  public get tabInactive$      () { return this._tabInactive$     .asObservable(); }
  public get tabActive$        () { return this._tabActive$       .asObservable(); }
  public get globallyInactive$ () { return this._globallyInactive$.asObservable(); }
  public get globallyActive$   () { return this._globallyActive$  .asObservable(); }

  /**
   * @description
   * Returns an observable that emits true if the user has been found to be inactive while authenticated.
   * If true, the only way to reset is to reload all tabs simultaneously which can be done through invoking the "reloadAndReset" method.
   */
  public get globalAuthenticatedInactivity$ () { return this._globalAuthenticatedInactivity$.asObservable(); }
  public get globalAuthenticatedInactivity  () { return this._globalAuthenticatedInactivity$.value;          }

  public reloadAllTabs () {
    this.bc.postMessage();
  }


}