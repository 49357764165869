<mat-drawer-container>
  <!-- <mat-drawer #filterNav mode="side" position="end">
    <app-filter #filterComponent></app-filter>
  </mat-drawer> -->

  <div class="royal-table-container">

    <mat-toolbar>

      <app-search class="search"></app-search>

      <!-- <button mat-flat-button
              type="button"
              color="primary"
              [matBadge]="filterComponent?.activeFilters"
              matTooltip="{{ 'common.filter' | translate }}"
              (click)="showFilter = ! showFilter; filterNav.toggle()"
              class="filter">
        <mat-icon class="white-icon add-icon">filter_list</mat-icon>
      </button> -->

      <div class="spacer"></div>

      <button mat-mini-fab
              type="button"
              color="primary"
              matTooltip="{{ 'tables.admin-tables.organizations.actions.add' | translate }}"
              class="add"
              (click)="openAddEditDialog()">
        <mat-icon class="white-icon add-icon">add</mat-icon>
      </button>

    </mat-toolbar>

    <div class="table-wrapper">
      <app-loading [loading]="dataSource.isLoading"></app-loading>

      <div class="table-container dark-scrollbar">
        <app-fetch-fail-pane [show]="dataSource.error"></app-fetch-fail-pane>

        <table mat-table [dataSource]="dataSource" class="table" matSort>

          <ng-container matColumnDef="name">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ 'tables.admin-tables.organizations.columns.name' | translate }}</th>
            <td mat-cell *matCellDef="let element">{{ element.name }}</td>
          </ng-container>

          <ng-container matColumnDef="version">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'tables.admin-tables.organizations.columns.version' | translate }}</th>
            <td mat-cell *matCellDef="let element">
              <span [class.highlight]="element.computeEnvironment?.version">{{ element.computeEnvironment?.version ?? ('common.default' | translate) }}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="organization">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ 'tables.admin-tables.organizations.columns.organization' | translate }}</th>
            <td mat-cell *matCellDef="let element">{{ element.organization?.name }}</td>
          </ng-container>

          <ng-container matColumnDef="organizationType">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ 'tables.admin-tables.organizations.columns.organizationType' | translate }}</th>
            <td mat-cell *matCellDef="let element">{{ element.environment?.organizationType | organizationType }}</td>
          </ng-container>

          <ng-container matColumnDef="associatedPartner">
            <th mat-header-cell *matHeaderCellDef>{{ 'common.associated_partner' | translate }}</th>
            <td mat-cell *matCellDef="let element">
              <app-icon [value]="element.associatedPartner"></app-icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="theme">
            <th mat-header-cell *matHeaderCellDef>{{ 'tables.admin-tables.organizations.columns.theme' | translate }}</th>
            <td mat-cell *matCellDef="let element">
              <app-icon [value]="element.environment?.theme"></app-icon>
            </td>
          </ng-container>

          <!-- no longer used -->
          <!-- <ng-container matColumnDef="environment">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'tables.admin-tables.organizations.columns.environment' | translate }}</th>
            <td mat-cell *matCellDef="let element">{{ element.computeEnvironment?.environment ?? 'Default' }}</td>
          </ng-container> -->

          <ng-container matColumnDef="divisions">
            <th mat-header-cell *matHeaderCellDef>{{ 'tables.admin-tables.organizations.columns.divisions' | translate }}</th>
            <td mat-cell *matCellDef="let element">{{ element.divisions?.length ?? 0 }}</td>
          </ng-container>

          <ng-container matColumnDef="identifiers">
            <th mat-header-cell *matHeaderCellDef>{{ 'tables.admin-tables.organizations.columns.identifiers' | translate }}</th>
            <td mat-cell *matCellDef="let element">{{ element.identifiers?.length ?? 0 }}</td>
          </ng-container>

          <ng-container matColumnDef="createdAt">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ 'common.created_at' | translate }}</th>
            <td mat-cell *matCellDef="let element">
              <span [matTooltip]="element.createdAt | localeDate">
                {{ onNow | async | durationSince:element.createdAt | capitalizeFirst }}
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="actions" stickyEnd>
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <app-table-action-buttons [element]="element" [buttons]="actionButtons"></app-table-action-buttons>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>

          <tr mat-row *matRowDef="let row; columns: columns;"></tr>

        </table>
      </div>

      <mat-paginator class="paginator"
                    *ngIf="paginator"
                    showFirstLastButtons
                    [length]="dataSource ? dataSource.resultsLength : 0"
                    [pageIndex]="0"
                    [pageSize]="pageSize"
                    [pageSizeOptions]="pageSizes">
      </mat-paginator>
    </div>

  </div>
</mat-drawer-container>