<h1 mat-dialog-title class="header royal-text-primary">{{ 'shared.dialogs.confirm-remove.title' | translate }}</h1>

<mat-dialog-content class="checkbox-content">
  <p class="royal-text-secondary">{{ 'shared.dialogs.confirm-remove.sub-title' | translate }}</p>
  <mat-checkbox [(ngModel)]="confirmed" class="checkbox" cypress="confirm">
    {{ 'shared.dialogs.confirm-remove.confirm' | translate }}
  </mat-checkbox>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button
          mat-dialog-close>
    {{ 'common.cancel' | translate}}
  </button>

  <button mat-button
          cypress="submit"
          color="primary"
          [disabled]=" ! confirmed "
          (click)="delete()">
    {{ 'common.delete' | translate}}
  </button>
</mat-dialog-actions>