export const LANG_FR_NAME = 'fr';

export const LANG_FR_TRANS = {
  "account.index.header": "Gérer le compte",
  "account.notifications.header": "Notifications",
  "account.notifications.menu": "Notifications",
  "account.notifications.page.descr": "Le tableau ci-dessous permet d'ajuster divers aspects du système de notification utilisé dans l'application.",
  "account.notifications.page.title": "Notifications",
  "account.security.header": "Sécurité",
  "account.security.menu": "Sécurité",
  "account.security.page.change-password.descr": "Remplissez le formulaire ci-dessous pour changer votre mot de passe de connexion.",
  "account.security.page.change-password.error.descr": "Le mot de passe actuel fourni ne correspond pas à votre mot de passe actuel. Veuillez réessayer.",
  "account.security.page.change-password.error.title": "Impossible de mettre à jour le mot de passe",
  "account.security.page.change-password.success.descr": "Votre mot de passe a maintenant été mis à jour!",
  "account.security.page.change-password.success.title": "Succès",
  "account.security.page.change-password.title": "Changer le mot de passe",
  "account.security.page.title": "Sécurité",
  "account.shortcuts.header": "Raccourcis",
  "account.shortcuts.menu": "Raccourcis",
  "account.shortcuts.page.category.editor.description": "Raccourcis pour l'éditeur d'emploi du temps.",
  "account.shortcuts.page.category.editor.title": "Éditeur",
  "account.shortcuts.page.description": "Ci-dessous sont répertoriés les raccourcis clavier pour les actions courantes dans l'application. Vous pouvez les modifier selon vos préférences en cliquant sur le raccourci et en appuyant sur la nouvelle combinaison de touches.",
  "account.shortcuts.page.editor/hideEvent.description": "Bascule l'état masqué des leçons sélectionnées.",
  "account.shortcuts.page.editor/hideEvent.label": "Masquer la leçon",
  "account.shortcuts.page.editor/moveEventEarlier.description": "Déplace les leçons sélectionnées 5 minutes plus tôt.",
  "account.shortcuts.page.editor/moveEventEarlier.label": "Déplacer la leçon plus tôt",
  "account.shortcuts.page.editor/moveEventIndividually.description": "Maintenez cette combinaison de touches enfoncée tout en faisant glisser une leçon liée pour la déplacer individuellement.",
  "account.shortcuts.page.editor/moveEventIndividually.label": "Déplacer la leçon liée individuellement",
  "account.shortcuts.page.editor/moveEventLater.description": "Décale les leçons sélectionnées de 5 minutes plus tard.",
  "account.shortcuts.page.editor/moveEventLater.label": "Déplacez la leçon plus tard.",
  "account.shortcuts.page.editor/moveEventMuchEarlier.description": "Déplace les leçons sélectionnées 60 minutes plus tôt.",
  "account.shortcuts.page.editor/moveEventMuchEarlier.label": "Déplacer la leçon beaucoup plus tôt",
  "account.shortcuts.page.editor/moveEventMuchLater.description": "Déplacez les leçons sélectionnées de 60 minutes plus tard.",
  "account.shortcuts.page.editor/moveEventMuchLater.label": "Déplacez la leçon beaucoup plus tard.",
  "account.shortcuts.page.editor/moveEventNextDay.description": "Déplace les leçons sélectionnées au jour suivant.",
  "account.shortcuts.page.editor/moveEventNextDay.label": "Déplacer la leçon au jour suivant",
  "account.shortcuts.page.editor/moveEventPrevDay.description": "Déplace les leçons sélectionnées au jour précédent.",
  "account.shortcuts.page.editor/moveEventPrevDay.label": "Déplacer la leçon au jour précédent",
  "account.shortcuts.page.editor/multipleSelectEvents.description": "En maintenant cette touche enfoncée, vous pourrez sélectionner plusieurs leçons.",
  "account.shortcuts.page.editor/multipleSelectEvents.label": "Sélectionner plusieurs leçons",
  "account.shortcuts.page.editor/parkEvent.description": "Bascule l'état garé des leçons sélectionnées.",
  "account.shortcuts.page.editor/parkEvent.label": "Leçon de parc",
  "account.shortcuts.page.editor/pinEvent.description": "Bascule l'état épinglé des leçons sélectionnées.",
  "account.shortcuts.page.editor/pinEvent.label": "Leçon de broche",
  "account.shortcuts.page.interference": "La commande de ce raccourci est déjà utilisée par \"{{ label }}\" de la même catégorie. Veuillez en choisir un autre.",
  "account.shortcuts.page.notifications.error": "Impossible de mettre à jour le raccourci. Veuillez réessayer.",
  "account.shortcuts.page.restore.description": "Restaurer tous les raccourcis à leurs valeurs par défaut.",
  "account.shortcuts.page.title": "Raccourcis clavier",
  "account.workspace.header": "Espace de travail",
  "account.workspace.menu": "Espace de travail",
  "account.workspace.page.backgroundDataAnalysis.descr": "Cette fonctionnalité analyse en continu les données de votre emploi du temps et vous propose des suggestions d'amélioration. L'analyse continue affecte parfois les performances globales de l'application et peut donc être désactivée. Elle peut être réactivée à tout moment.",
  "account.workspace.page.backgroundDataAnalysis.label": "Analyse des données de l'emploi du temps",
  "account.workspace.page.defaultEventDurations.descr": "Ici, vous pouvez définir des ensembles de durées de leçon par défaut pour faciliter la création de leçons.",
  "account.workspace.page.defaultEventDurations.label": "Durées de leçon par défaut",
  "account.workspace.page.descr": "Ces types de préférences vous permettent de modifier et de personnaliser l'espace de travail de l'application.",
  "account.workspace.page.displayCenterOfAttraction.descr": "L'heure préférée détermine le point dans le temps où les leçons sont dessinées pendant la procédure de planification automatique. En activant cette option, l'heure préférée par défaut peut être remplacée pour les cours et les leçons individuels.",
  "account.workspace.page.displayCenterOfAttraction.label": "Afficher l'option de cours et de leçon <i>heure préférée</i>",
  "account.workspace.page.displayChat.descr": "Si le chat dans le coin inférieur gauche est visible.",
  "account.workspace.page.displayChat.label": "Afficher le chat",
  "account.workspace.page.displayEventsTable.descr": "Dans ce tableau, vous trouverez les leçons de tous les cours.",
  "account.workspace.page.displayEventsTable.label": "Afficher le tableau des leçons",
  "account.workspace.page.displayPersonsTable.descr": "Dans ce tableau, vous trouverez des étudiants de tous les groupes et ceux qui ne sont membres d'aucun groupe.",
  "account.workspace.page.displayPersonsTable.label": "Afficher le tableau des étudiants",
  "account.workspace.page.displayTooltips.descr": "Si des infobulles contenant des informations expliquant plus en détail le concept doivent être visibles.",
  "account.workspace.page.displayTooltips.label": "Afficher les infobulles",
  "account.workspace.page.language.descr": "Définit la langue de l'application.",
  "account.workspace.page.notifications.error": "Impossible de mettre à jour la préférence. Veuillez réessayer.",
  "account.workspace.page.title": "Espace de travail",
  "attributeDescriptions.course.centerOfAttraction": "Les leçons appartenant au cours sont dessinées à leur heure préférée pendant le processus de planification automatique",
  "attributeDescriptions.course.color": "Définit la couleur d'affichage des leçons appartenant au cours",
  "attributeDescriptions.course.days": "Définit les jours autorisés pour les leçons appartenant au cours",
  "attributeDescriptions.course.eventDurationVariation": "Définit la différence maximale entre la durée réelle d'une leçon et sa durée préférée pour les leçons appartenant au cours. La durée totale du cours est cependant préservée.",
  "attributeDescriptions.course.events": "Les leçons appartenant au cours",
  "attributeDescriptions.course.groups": "Définit les groupes qui suivront les leçons appartenant au cours",
  "attributeDescriptions.course.intervals": "Définit la plage horaire pour les leçons appartenant au cours",
  "attributeDescriptions.course.locations": "Définit les salles qui peuvent être réservées pour les leçons appartenant au cours",
  "attributeDescriptions.course.lockedTimes": "Définit des intervalles de temps interdits pour les leçons appartenant au cours",
  "attributeDescriptions.course.minBreakLength": "Définit la pause la plus courte autorisée avant et après les leçons appartenant au cours",
  "attributeDescriptions.course.overlapGroup": "Définit un ensemble de cours dont les leçons sont autorisées à se chevaucher. Les leçons appartenant au même cours ne sont cependant jamais autorisées à se chevaucher. Pendant le processus de planification automatique, les leçons au sein d'un tel groupe sont tentées d'être associées de la meilleure façon possible.",
  "attributeDescriptions.course.period": "Définit la période de temps pour les leçons appartenant au cours, par exemple, automne, printemps ou semaines impaires et paires. Veuillez noter qu'avant qu'une période puisse être sélectionnée, elle doit être créée.",
  "attributeDescriptions.course.subject": "Le sujet du cours",
  "attributeDescriptions.course.teachers": "Définit les enseignants qui enseigneront les leçons appartenant au cours",
  "attributeDescriptions.event.centerOfAttraction": "La leçon est dessinée à son heure préférée pendant le processus de planification automatique",
  "attributeDescriptions.event.color": "Définit la couleur d'affichage de la leçon",
  "attributeDescriptions.event.course": "Le cours auquel appartient la leçon",
  "attributeDescriptions.event.courseId": "L'ID public du cours auquel appartient la leçon",
  "attributeDescriptions.event.days": "Définit les jours autorisés de la leçon",
  "attributeDescriptions.event.duration": "Définit la durée de la leçon",
  "attributeDescriptions.event.durationVariation": "Définit la différence maximale entre la durée réelle de la leçon et sa durée préférée. La durée totale du cours est cependant préservée.",
  "attributeDescriptions.event.fixedStart": "Fixe l'heure de début de la leçon",
  "attributeDescriptions.event.groups": "Définit les groupes qui suivront la leçon",
  "attributeDescriptions.event.intervals": "Définit la plage horaire pour la leçon",
  "attributeDescriptions.event.locations": "Définit les salles qui peuvent être réservées pour la leçon",
  "attributeDescriptions.event.lockedTimes": "Définit des intervalles de temps interdits pour la leçon",
  "attributeDescriptions.event.minBreakLength": "Définit la pause la plus courte autorisée avant et après la leçon",
  "attributeDescriptions.event.overlapSpecies": "Les leçons liées sont planifiées en même temps",
  "attributeDescriptions.event.period": "Définit la période de temps pour la leçon, par exemple, automne, printemps ou semaines impaires et paires. Veuillez noter qu'avant qu'une période puisse être sélectionnée, elle doit avoir été créée.",
  "attributeDescriptions.event.preferredDuration": "Définit la durée préférée de la leçon. Après le processus de planification automatique, la durée réelle ne dépassera pas \"durée préférée ± variation de durée\"",
  "attributeDescriptions.event.teachers": "Définit les enseignants qui enseigneront la leçon",
  "attributeDescriptions.group.days": "Définit les jours autorisés du groupe",
  "attributeDescriptions.group.intervals": "Définit la plage horaire pour le groupe",
  "attributeDescriptions.group.lockedTimes": "Définit des intervalles de temps interdits pour le groupe",
  "attributeDescriptions.group.lunch": "Définit les pauses déjeuner du groupe",
  "attributeDescriptions.group.minBreakLength": "Définit la pause la plus courte autorisée entre les leçons pour le groupe",
  "attributeDescriptions.group.rootIntervals": "Définit les blocs du groupe",
  "attributeDescriptions.groups.members": "Définit les membres du groupe",
  "attributeDescriptions.groups.species": "Définit le type de groupe",
  "attributeDescriptions.groups.parentGroups": "Définit les classes de constituants du groupe en fonction des membres du groupe.",
  "attributeDescriptions.location.days": "Définit les jours autorisés de la salle",
  "attributeDescriptions.location.lockedTimes": "Définit des intervalles de temps interdits pour la salle",
  "attributeDescriptions.location.minBreakLength": "Définit la marge de temps la plus courte entre deux réservations de la salle",
  "attributeDescriptions.person.emails": "Définit l'e-mail de l'étudiant",
  "attributeDescriptions.person.firstName": "Définit le prénom de l'étudiant",
  "attributeDescriptions.person.group": "Définit le groupe de l'étudiant",
  "attributeDescriptions.person.lastName": "Définit le nom de famille de l'étudiant",
  "attributeDescriptions.person.lockedTimes": "Définit des intervalles de temps interdits pour l'étudiant",
  "attributeDescriptions.person.lunch": "Définit les pauses déjeuner de l'étudiant",
  "attributeDescriptions.person.phoneNumbers": "Définit le numéro de téléphone de l'étudiant",
  "attributeDescriptions.person.sex": "Définit le sexe de l'étudiant",
  "attributeDescriptions.person.SSN": "Définit le numéro de sécurité sociale de l'étudiant",
  "attributeDescriptions.shared.displayName": "Un nom utilisé dans toute l'application",
  "attributeDescriptions.teacher.days": "Définit les jours autorisés de l'enseignant",
  "attributeDescriptions.teacher.emails": "Définit l'e-mail de l'enseignant",
  "attributeDescriptions.teacher.firstName": "Définit le prénom de l'enseignant",
  "attributeDescriptions.teacher.intervals": "Définit la plage horaire pour l'enseignant",
  "attributeDescriptions.teacher.lastName": "Définit le nom de famille de l'enseignant",
  "attributeDescriptions.teacher.lockedTimes": "Définit des intervalles de temps interdits pour l'enseignant",
  "attributeDescriptions.teacher.lunch": "Définit les pauses déjeuner de l'enseignant",
  "attributeDescriptions.teacher.minBreakLength": "Définit la pause la plus courte autorisée entre les leçons pour l'enseignant",
  "attributes.course.eventDurationVariation": "Variation de la durée de la leçon",
  "attributes.course.events": "Leçons",
  "attributes.course.overlapGroup": "Cours parallèles",
  "attributes.division.default.dayRank": "Classement par défaut des jours",
  "attributes.division.default.dependencyMinimumBreakLength": "Pour les salles",
  "attributes.division.default.dependencyRank": "Classement par défaut des salles",
  "attributes.division.default.eventDurationVariation": "Variation de la durée de la leçon",
  "attributes.division.default.eventMinimumBreakLength": "Entre les leçons",
  "attributes.division.default.groupMinimumBreakLength": "Pour les groupes et les enseignants",
  "attributes.division.default.lunch": "Pause déjeuner par défaut",
  "attributes.division.default.maxNumDailyWorkingHours": "Quotidien",
  "attributes.division.default.maxNumWorkingHours": "Total",
  "attributes.division.default.period": "Période",
  "attributes.division.end": "Date de fin",
  "attributes.division.published": "publier",
  "attributes.division.settings.dayEnd": "Fin de la journée",
  "attributes.division.settings.dayStart": "Début de la journée",
  "attributes.division.settings.discretization": "Discrétisation",
  "attributes.division.settings.numDays": "Nombre de jours",
  "attributes.division.settings.potentialCenter": "Heure préférée",
  "attributes.division.start": "Date de début",
  "attributes.event.course": "Cours",
  "attributes.event.courseId": "ID du cours",
  "attributes.event.current_duration": "Durée actuelle de la classe",
  "attributes.event.durationVariation": "Variation de la durée",
  "attributes.event.fixedStart": "Heure de début fixe",
  "attributes.event.inherit": "Hérité du cours",
  "attributes.event.overlapSpecies": "Leçons liées",
  "attributes.event.preferredDuration": "Durée de la leçon",
  "attributes.generation.status.COMPLETED": "Terminé",
  "attributes.generation.status.PENDING": "En attente",
  "attributes.generation.status.STARTED": "Démarré",
  "attributes.groups.parentGroups": "Classes de constituants",
  "attributes.groups.species.none": "Groupe d'enseignants",
  "attributes.groups.species.class": "Classe",
  "attributes.groups.species": "Type de groupe",
  "attributes.groups.members": "Membres",
  "attributes.teachers.signature": "Signature",
  "attributes.integrations.APPROVED": "Actif",
  "attributes.integrations.DISABLED": "Désactivé",
  "attributes.integrations.PENDING": "En attente",
  "attributes.integrations.REJECTED": "Rejeté",
  "attributes.location.days": "Jours disponibles",
  "attributes.location.minBreakLength": "Marge de temps",
  "attributes.person.firstName": "Prénom",
  "attributes.person.group": "Groupe",
  "attributes.person.lastName": "Nom",
  "attributes.person.sex": "Sexe",
  "attributes.person.SSN": "Numéro de sécurité sociale",
  "attributes.shared.centerOfAttraction": "Heure préférée",
  "attributes.shared.createdAt": "Créé",
  "attributes.shared.color": "Couleur",
  "attributes.shared.course": "Cours",
  "attributes.shared.day": "Jour",
  "attributes.shared.days": "Jours autorisés",
  "attributes.shared.displayName": "Nom",
  "attributes.shared.duration": "Durée",
  "attributes.shared.durationVariation": "Variation de la durée",
  "attributes.shared.dynamicLockedTime.custom": "Défini pour chaque jour",
  "attributes.shared.dynamicLockedTime.default": "Valeur par défaut",
  "attributes.shared.dynamicLockedTime.none": "Aucun",
  "attributes.shared.dynamicLockedTime.repeated": "Même pour chaque jour",
  "attributes.shared.emails": "Email",
  "attributes.shared.end": "Fin",
  "attributes.shared.endTime": "Heure de fin",
  "attributes.shared.groups": "Groupes",
  "attributes.shared.intervals": "Plage horaire",
  "attributes.shared.intervals.multiple": "Différent pour chaque jour",
  "attributes.shared.intervals.none": "Utiliser la plage horaire par défaut",
  "attributes.shared.intervals.single": "Même pour chaque jour",
  "attributes.shared.locations": "Salles autorisées",
  "attributes.shared.lockedTimes": "Heures interdites",
  "attributes.shared.lunch": "Déjeuner",
  "attributes.shared.minBreakLength": "Durée des pauses",
  "attributes.shared.minBreakLength.asymmetrical": "Durées différentes",
  "attributes.shared.minBreakLength.asymmetrical.after": "Après",
  "attributes.shared.minBreakLength.asymmetrical.before": "Avant",
  "attributes.shared.minBreakLength.asymmetrical.tooltip": "{{ before }} avant et {{ after }} après",
  "attributes.shared.minBreakLength.default": "Valeur par défaut",
  "attributes.shared.minBreakLength.none": "Aucun",
  "attributes.shared.minBreakLength.symmetrical": "Durées égales avant et après",
  "attributes.shared.period": "Période",
  "attributes.shared.period.entire": "Toutes les semaines",
  "attributes.shared.phoneNumbers": "Numéros de téléphone",
  "attributes.shared.rootIntervals": "Blocs",
  "attributes.shared.start": "Début",
  "attributes.shared.startTime": "Heure de début",
  "attributes.shared.subject": "Sujet",
  "attributes.shared.teachers": "Enseignants",
  "auth.role.admin": "Administrateur",
  "auth.role.partner": "Partenaire",
  "auth.role.unrestricted": "Utilisateur régulier",
  "common.goto_schedule": "Aller à l'horaire",
  "common.+_n_more": "+ {{n}} de plus",
  "common.a_lunch_belonging_to_the_group": "un déjeuner appartenant au groupe",
  "common.a_lunch_belonging_to_the_person": "un déjeuner appartenant à l'élève",
  "common.a_lunch_belonging_to_the_teacher": "un déjeuner appartenant au enseignant",
  "common.about": "Statut",
  "common.add": "Ajouter",
  "common.add_course": "Ajouter un cours",
  "common.add_event": "Ajouter le(s) cours: {{ event-durations }}",
  "common.add_event_advanced": "Ajouter le(s) cours",
  "common.add_filter": "Ajouter un filtre",
  "common.add_group": "Ajouter un groupe",
  "common.add_location": "Ajouter une salle",
  "common.add_one_more": "Ajouter un autre",
  "common.add_period": "Ajouter une période",
  "common.add_person": "Ajouter un élève",
  "common.add_teacher": "Ajouter un enseignant",
  "common.advanced_settings": "Paramètres avancés",
  "common.all": "Tous",
  "common.alone": "Seul",
  "common.an_event_belonging_to_the_course": "un cours appartenant au cours",
  "common.analysis": "Analyse",
  "common.and": "et",
  "common.and1Other": "et un autre",
  "common.andNOthers": "et {{n}} autres",
  "common.associated_partner": "Partenaire associé",
  "common.back": "Retour",
  "common.back_to_login": "Retour à la connexion",
  "common.calendar_view": "Vue calendrier",
  "common.cancel": "Annuler",
  "common.change_password": "Changer le mot de passe",
  "common.chooseOther": "Choisir un autre",
  "common.clear": "Effacer",
  "common.close": "Fermer",
  "common.complete": "Compléter",
  "common.computing": "Calcul",
  "common.confirmation": "Confirmation",
  "common.connect": "Connexion",
  "common.connect_error": "Erreur de connexion",
  "common.copy": "Copier",
  "common.country": "Pays",
  "common.counts": "Comptes",
  "common.course": "Cours",
  "common.courses": "Cours",
  "common.create": "Créer",
  "common.create_course": "Créer un cours",
  "common.create_group": "Créer un groupe",
  "common.create_location": "Créer une salle",
  "common.create_person": "Créer un élève",
  "common.create_schedule": "Créer un planning",
  "common.create_teacher": "Créer un enseignant",
  "common.created_at": "Créé",
  "common.creating": "Création",
  "common.creation_fail": "Quelque chose s'est mal passé",
  "common.creation_success": "Créé",
  "common.custom_search": "Recherche personnalisée",
  "common.day": "Jour",
  "common.days": "Jours",
  "common.default": "Défaut",
  "common.delete": "Supprimer",
  "common.delete_account": "Supprimer le compte",
  "common.deleting": "Suppression",
  "common.discard": "Annuler",
  "common.download": "Télécharger",
  "common.drop_here": "Déposer ici",
  "common.duplicate": "Dupliquer",
  "common.duplicated": "Dupliqué",
  "common.earliest": "Plus tôt",
  "common.edit": "Modifier",
  "common.edit_columns": "Modifier les colonnes",
  "common.edit_failed": "Impossible d'enregistrer les modifications",
  "common.edited_at": "Dernière modification",
  "common.email": "email",
  "common.empty": "Vide",
  "common.erroneous_value": "Valeur invalide",
  "common.error": "Erreur",
  "common.errors": "erreurs",
  "common.event": "Classe",
  "common.event_durations": "durée des leçons",
  "common.events": "Classes",
  "common.expand": "Développer",
  "common.export": "Exporter",
  "common.filter": "Filtrer",
  "common.flaw": "défaut",
  "common.flaws": "défauts",
  "common.forgot_password": "Mot de passe oublié?",
  "common.generalErrorMessage": "Oups, quelque chose s'est mal passé...",
  "common.generic_error": "Quelque chose s'est mal passé",
  "common.generic_help": "Nous travaillons à résoudre le problème.",
  "common.goto-tutorial": "Aller au tutoriel",
  "common.group": "Groupe",
  "common.groups": "Groupes",
  "common.help": "Aide",
  "common.hide": "Cacher",
  "common.hour": "Heure",
  "common.hour_short": "h",
  "common.hours": "Heures",
  "common.hours_short": "h",
  "common.ignore": "Ignorer",
  "common.import": "Importer",
  "common.inheritFromCourse": "Hériter du cours",
  "common.inheritFromCourseValue": "Hériter du cours ({{value}})",
  "common.invalid": "Invalide",
  "common.invalid_division_id": "Le planning n'a pas pu être trouvé",
  "common.invalid_value": "Valeur invalide",
  "common.language": "Langue",
  "common.latest": "Plus tard",
  "common.linked_events": "Leçons liées",
  "common.list_view": "Vue liste",
  "common.loading": "Chargement",
  "common.location": "Salle",
  "common.locations": "Salles",
  "common.locked": "Verrouillé",
  "common.locked_time": "Temps interdit",
  "common.locked_times": "Temps interdits",
  "common.lockedTime": "Intervalle de temps interdit",
  "common.lockedTimes": "Intervalle de temps interdit",
  "common.login": "Connexion",
  "common.logout": "Déconnexion",
  "common.lunch": "Repas",
  "common.make": "faire",
  "common.makes": "fait",
  "common.manage_account": "Gérer votre compte",
  "common.minimize": "Minimiser",
  "common.minute": "Minute",
  "common.minute_short": "min",
  "common.minutes": "Minutes",
  "common.minutes_short": "min",
  "common.modified_after": "Modifié après",
  "common.nameless": "Sans titre",
  "common.next": "Suivant",
  "common.no": "Non",
  "common.no_available_data": "Pas de données disponibles",
  "common.no_events_to_show": "Pas de leçons à afficher",
  "common.no_match": "Aucune correspondance",
  "common.no_value": "Pas de valeur",
  "common.none_plural": "Aucun",
  "common.none_singular": "Aucun",
  "common.not_placed": "Non placé",
  "common.of": "de",
  "common.or": "Ou",
  "common.other": "Autre",
  "common.others": "autres",
  "common.overlapGroup": "Ensemble de cours parallèles",
  "common.overlapGroups": "Ensembles de cours parallèles",
  "common.period": "Période",
  "common.periods": "Périodes",
  "common.person": "Élève",
  "common.persons": "Élèves",
  "common.print": "Imprimer",
  "common.related-content": "Contenu connexe",
  "common.reload": "Recharger",
  "common.remaining": "Restant",
  "common.remove": "Supprimer",
  "common.rename": "Renommer",
  "common.resend": "Renvoyer",
  "common.reset": "Effacer",
  "common.reset_filter": "Réinitialiser le filtre",
  "common.reset_password_fail": "Impossible d'envoyer un lien de réinitialisation à cet utilisateur",
  "common.reset_password_success": "Un lien de réinitialisation a été envoyé à",
  "common.restore": "Restaurer",
  "common.retry": "Réessayer",
  "common.rootInterval": "Bloc",
  "common.rootIntervals": "Bloc",
  "common.save": "Sauvegarder",
  "common.save_failed": "Échec de l'enregistrement",
  "common.saved": "Sauvegardé",
  "common.saving": "Sauvegarde en cours",
  "common.schedule": "Emploi du temps",
  "common.school": "École",
  "common.school_year": "Année scolaire",
  "common.screen_size": "Taille d'écran trop petite",
  "common.search": "Rechercher",
  "common.search.course": "Rechercher un cours",
  "common.search.event": "Rechercher une leçon",
  "common.seconds": "Secondes",
  "common.seconds_short": "s",
  "common.select": "Sélectionner",
  "common.select_entity_to_show_events": "Sélectionnez un groupe, un enseignant ou une salle pour afficher ses leçons.",
  "common.select_file": "Sélectionner un fichier",
  "common.selected": "Sélectionné",
  "common.selected_file": "Fichier sélectionné: {{file}}",
  "common.settings": "Paramètres",
  "common.sex.man": "Homme",
  "common.sex.woman": "Femme",
  "common.share": "Partager",
  "common.show": "Afficher",
  "common.show_all_events": "Afficher toutes les leçons",
  "common.sports_facility": "Équipement sportif",
  "common.subject": "Matière",
  "common.subjects": "Sujets",
  "common.sync": "Synchroniser",
  "common.synchronising": "Synchronisation",
  "common.teacher": "Enseignant",
  "common.teachers": "Enseignants",
  "common.terms": "Conditions générales",
  "common.the_course": "Le cours",
  "common.the_courses": "Les cours",
  "common.the_event": "La classe",
  "common.the_events": "Les classes",
  "common.the_group": "Le groupe",
  "common.the_groups": "Les groupes",
  "common.the_location": "La salle",
  "common.the_locations": "Les salles",
  "common.the_lunch": "Le déjeuner",
  "common.the_lunches": "Les déjeuners",
  "common.the_period": "La période",
  "common.the_periods": "Les périodes",
  "common.the_person": "L’élève",
  "common.the_persons": "Les élèves",
  "common.the_subjects": "Les sujets",
  "common.the_teacher": "L’enseignant",
  "common.the_teachers": "Les enseignants",
  "common.time_settings": "Paramètres de temps",
  "common.toggle_all": "Tout sélectionner",
  "common.total": "Total",
  "common.type": "Type",
  "common.unknown": "inconnu",
  "common.update": "Mettre à jour",
  "common.updated": "Mis à jour",
  "common.upload": "Télécharger",
  "common.useDefault": "Utiliser la valeur par défaut",
  "common.useDefaultValue": "Utiliser la valeur par défaut ({{value}})",
  "common.username": "Nom d'utilisateur",
  "common.users": "Utilisateurs",
  "common.value": "Valeur",
  "common.versions": "Versions",
  "common.warning": "avertissement",
  "common.warnings": "avertissements",
  "common.week": "Semaine",
  "common.week_short": "sem.",
  "common.weeks": "Semaines",
  "common.yes": "Oui",
  "common.YYYY-MM-DD": "DD-MM-YYYY",
  "common.zoom_center": "Restaurer le zoom",
  "common.zoom_in": "Zoom avant",
  "common.zoom_out": "Zoom arrière",
  "common.today": "Aujourd'hui",
  "common.tomorrow": "Demain",
  "common.this_week": "Cette semaine",
  "common.next_week": "La semaine prochaine",
  "common.this_month": "Ce mois-ci",
  "common.next_month": "Le mois prochain",
  "common.modify": "Modifier",
  "common.calendar": "Calendrier",
  "common.list": "Liste",
  "common.changes": "Modifications",
  "common.cancelled": "Annulé",
  "common.no_selected": "Aucun sélectionné",
  "common.no_exceptions": "Aucune exception",
  "common.no_absences": "Aucune absence",
  "common.substitutions": "Remplacements",
  "common.absences": "Absences",
  "common.absentees": "Absents",
  "common.reason": "Raison",
  "common.filter_by_groups": "Filtrer par groupes",
  "common.not_available_for_current_selection": "Non disponible pour la sélection actuelle",
  'common.cancel_event': 'Annuler la leçon',
  'common.comment': 'Commentaire',
  "common.error_code": "Code d'erreur",
  "current language": "Français",
  "dialogs.admentumImport.step_1.descr": "Ici, vous pouvez choisir quel horaire et quels cours associés, enseignants, etc. importer de la plate-forme Admentum. Si vous avez plusieurs horaires pour une année scolaire, ils peuvent être différenciés par leur numéro d'identification.",
  "dialogs.admentumImport.step_1.required": "Veuillez choisir un horaire pour continuer.",
  "dialogs.admentumImport.step_1.title": "Choisissez un horaire",
  "dialogs.admentumImport.step_2.descr": "En choisissant une semaine, toutes les leçons de cette semaine seront importées. Par conséquent, si aucune semaine n'est choisie, aucune leçon ne sera importée d'Admentum.",
  "dialogs.admentumImport.step_2.title": "Choisissez une semaine",
  "dialogs.admentumImport.step_3.descr": "Vous pouvez changer le nom de l'horaire à n'importe quel moment après l'importation.",
  "dialogs.admentumImport.step_3.label": "Nom de l'horaire",
  "dialogs.admentumImport.step_3.required": "Veuillez donner un nom à l'horaire.",
  "dialogs.admentumImport.step_3.title": "Donnez un nom à l'horaire",
  "dialogs.create-courses.by-hand.label": "À la main",
  "dialogs.create-courses.in-bulk.for_each_of": "Créer un ou plusieurs cours pour chacun des",
  "dialogs.create-courses.in-bulk.label": "En vrac",
  "inputAnalysis.computing": "Calcul...",
  "inputAnalysis.dialog.description": "Les données du planning sont analysées en continu et les résultats sont présentés dans les trois onglets de droite. Chaque onglet représente une catégorie de défauts dans les données du planning, qui sont décrits ci-dessous.",
  "inputAnalysis.dialog.empty_category": "Il n'y a pas de remarques appartenant à cette catégorie",
  "inputAnalysis.dialog.overview.title": "Aperçu",
  "inputAnalysis.dialog.title": "Analyse des données de l'horaire",
  "inputAnalysis.disabled": "L'analyse des données est désactivée mais peut être activée à tout moment dans les paramètres de l'espace de travail.",
  "inputAnalysis.entities.theLunch": "Le déjeuner appartenant à {{ theGroupOrTeacher }}",
  "inputAnalysis.errors.21.10.0.description": "Il n'y a pas de jour commun autorisé pour la leçon et les groupes, les enseignants et les salles qui y sont connectés. Voir quels jours sont bloqués ci-dessous:",
  "inputAnalysis.errors.21.10.0.title": "{{The-entity}} n'a pas de jours autorisés selon les données de l'horaire.",
  "inputAnalysis.errors.21.10.1.description": "Cela peut être dû à plusieurs raisons, par exemple, des intervalles de temps interdits par des groupes et des enseignants connectés, ou des jours exclus en raison des salles choisies. Utilisez l'outil de calendrier ci-dessous pour savoir quelle est la cause de ce cas particulier et comment il pourrait être résolu.",
  "inputAnalysis.errors.21.10.1.title": "{{The-entity}} ne rentre pas dans sa plage horaire disponible.",
  "inputAnalysis.errors.24.0.description": "Chaque leçon en soi a au moins un jour disponible, mais le problème se pose lorsqu'elles sont liées et donc programmées le même jour.",
  "inputAnalysis.errors.24.0.title": "{{The-entities}} sont liés mais ne partagent pas un jour autorisé.",
  "inputAnalysis.errors.24.1.description": "Chaque leçon en soi peut s'insérer dans un certain intervalle de temps, mais le problème se pose lorsqu'elles sont liées et donc programmées ensemble.",
  "inputAnalysis.errors.24.1.title": "{{The-entities}} sont liés mais ne peuvent pas être programmés ensemble en raison de contraintes d'intervalle de temps",
  "inputAnalysis.errors.description": "Impossible de planifier automatiquement tant que ces erreurs n'ont pas été résolues.",
  "inputAnalysis.errors.missing_overlap_species.description": "Les leçons appartenant à des cours parallèles ne sont pas garanties d'être programmées en même temps après qu'un horaire a été généré, c'est-à-dire à moins qu'elles ne soient liées. Cette erreur critique peut être désactivée dans les paramètres de l'horaire.",
  "inputAnalysis.errors.missing_overlap_species.title": "{{The-entities}} {{is/are-parallel-courses}}, mais une ou plusieurs leçons associées ne sont pas liées.",
  "inputAnalysis.errors.notification_title": "Le planning contient des erreurs critiques.",
  "inputAnalysis.errors.title": "Erreurs critiques",
  "inputAnalysis.etc.modify_period": "Modifier la période",
  "inputAnalysis.etc.schedule_settings": "Paramètres de l'horaire",
  "inputAnalysis.etc.schedule-settings": "Paramètres de l'horaire",
  "inputAnalysis.etc.specify_forced_lesson_overlaps": "lier les leçons",
  "inputAnalysis.issues.description": "Il est possible de planifier automatiquement mais le résultat ne sera pas optimal. Nous vous recommandons donc de résoudre ces problèmes avant de continuer.",
  "inputAnalysis.issues.notification_title": "Le planning contient des problèmes",
  "inputAnalysis.issues.overloaded group.description": "Il sera difficile ou impossible de programmer ce groupe sans aucun conflit d'horaire.",
  "inputAnalysis.issues.overloaded group.title": "{{The-entity}} est surchargé de leçons.",
  "inputAnalysis.issues.title": "Problèmes",
  "inputAnalysis.modules.event intervals.affecting_events": "Leçons affectant",
  "inputAnalysis.modules.event intervals.description": "Passez la souris sur les enseignants, les groupes, etc. ci-dessous pour voir comment leurs intervalles de temps indisponibles, affichés dans le calendrier en rouge, affectent la leçon. Vous pouvez également faire l'inverse, c'est-à-dire passer la souris sur le calendrier pour voir si le placement correspondant de la leçon est autorisé par ces enseignants, groupes, etc. Si leur arrière-plan devient rayé, cela signifie que le placement n'est pas autorisé.",
  "inputAnalysis.modules.event intervals.this event": "Cette leçon",
  "inputAnalysis.modules.overloaded group.contributing events": "Leçons contributives",
  "inputAnalysis.modules.overloaded group.description": "Le pourcentage indique dans quelle mesure un intervalle de temps est occupé par des leçons. <b>Tous les pourcentages supérieurs à 100% indiquent un conflit d'horaire garanti.</b> <br> <b>Exemple:</b> Si une leçon de 30 minutes peut être placée sur un intervalle de temps d'une heure, le pourcentage de réservation est de 50%. Si, cependant, des leçons équivalentes à deux heures doivent être placées sur le même intervalle, le pourcentage de réservation est de 200%, ce qui entraînera un conflit.<br> Erreurs courantes qui peuvent avoir contribué au pourcentage de réservation supérieur à 100%: <ul> <li>Oublié de créer des ensembles de leçons chevauchantes. N'oubliez pas de placer toutes les leçons qui doivent être enseignées en parallèle dans un ensemble de leçons chevauchantes. <li>Trop de leçons le matin/le soir.</li><li>Trop de leçons certains jours.</li></ul>",
  "inputAnalysis.modules.overloaded group.legend.bad": "Mauvais",
  "inputAnalysis.modules.overloaded group.legend.compact": "Compact",
  "inputAnalysis.modules.overloaded group.legend.conflict": "Conflit",
  "inputAnalysis.modules.overloaded group.legend.fixed": "Fixé",
  "inputAnalysis.modules.overloaded group.legend.good": "Bon",
  "inputAnalysis.modules.overloaded group.legend.sparse": "Épars",
  "inputAnalysis.notices.completely unconnected event.description": "Ces types de leçons complètement déconnectées sont ignorés par l'algorithme car ils n'affectent rien.",
  "inputAnalysis.notices.completely unconnected event.title": "{{The-entity}} n'est pas connecté à un enseignant, un étudiant, un groupe ou une salle.",
  "inputAnalysis.notices.description": "Peut indiquer que le planning est incomplet.",
  "inputAnalysis.notices.empty course.description": "Les cours sans leçons seront ignorés par l'algorithme.",
  "inputAnalysis.notices.empty course.title": "{{The-entity}} ne contient aucune leçon.",
  "inputAnalysis.notices.empty_period.description": "Avez-vous oublié d'inclure des semaines dans la période?",
  "inputAnalysis.notices.empty_period.title": "{{The-entity}} est vide.",
  "inputAnalysis.notices.missing_overlap_species.description": "Les leçons appartenant à des cours parallèles ne sont pas garanties d'être programmées en même temps après la génération d'un horaire, à moins qu'elles ne soient liées.",
  "inputAnalysis.notices.missing_overlap_species.title": "{{The-entities}} {{is/are-parallel-courses}}, mais une ou plusieurs leçons associées ne sont pas liées.",
  "inputAnalysis.notices.notification_title": "Le planning peut être incomplet.",
  "inputAnalysis.notices.overflowing_period.description": "Avez-vous oublié de mettre à jour la période après avoir modifié la période de l'horaire?",
  "inputAnalysis.notices.overflowing_period.title": "{{The-entity}} se trouve partiellement en dehors de la période de l'horaire.",
  "inputAnalysis.notices.title": "Remarques",
  "inputAnalysis.notices.unconnected event.description": "Avez-vous oublié d'ajouter un enseignant, un étudiant ou un groupe?",
  "inputAnalysis.notices.unconnected event.title": "{{The-entity}} n'est pas connecté à un enseignant, un étudiant ou un groupe.",
  "inputAnalysis.ok.notification_title": "Aucune remarque concernant le planning",
  "schedule.guaranteedTuition.label": "Frais de scolarité garantis",
  "schedule.guaranteedTuition.tabs.calendarExceptions.descr": "Ici, vous pouvez ajouter des jours fériés et des jours de congé. Ces jours ne seront pas pris en compte lors du calcul du temps total programmé.",
  "view.private.schema.sub-pages.settings.page.tab.holidays.dialog.form.description.label": "Description",
  "view.private.schema.sub-pages.settings.page.tab.holidays.dialog.form.range.label": "Plage de dates",
  "schedule.guaranteedTuition.tabs.calendarExceptions.tabLabel": "Ajouter des jours de congé",
  "schedule.guaranteedTuition.tabs.calendarExceptions.title": "Ajouter des jours de congé",
  "schedule.guaranteedTuition.tabs.dayCount.descr": "Le tableau suivant indique le nombre de fois où chaque jour apparaît dans l'horaire, compte tenu de sa date de début et de fin ainsi que des jours de congé spécifiés ci-dessus.",
  "schedule.guaranteedTuition.tabs.dayCount.title": "Résumé",
  "schedule.guaranteedTuition.tabs.guaranteedTuition.empty": "Il n'y a pas de leçons à prendre en compte pour la semaine sélectionnée. Notez que les leçons doivent être planifiées, pas seulement planifiées.",
  "schedule.guaranteedTuition.tabs.guaranteedTuition.no_selected_entity": "Sélectionnez un groupe, un enseignant ou un sujet pour obtenir un résumé de son temps total programmé. Veuillez noter que seuls les cours ayant un sujet défini sont inclus dans les calculs.",
  "schedule.guaranteedTuition.tabs.guaranteedTuition.tabLabel": "Frais de scolarité garantis",
  "schedule.guaranteedTuition.tabs.guaranteedTuition.table.per_day": "Par jour",
  "schedule.guaranteedTuition.tabs.guaranteedTuition.table.total_count": "Nombre de leçons",
  "schedule.header": "Horaire",
  "schedule.navigation.automate": "Générer<br>horaire",
  "schedule.navigation.data": "Données<br>de l'horaire",
  "schedule.navigation.dutyAssignment": "Affectation<br>devoir",
  "schedule.navigation.edit": "Éditeur<br>horaire",
  "schedule.navigation.examine": "Examiner<br>horaire",
  "schedule.navigation.guaranteedTuition": "Frais de scolarité<br>garantis",
  "schedule.navigation.lessonSettings": "Paramètres<br>de leçon",
  "schedule.navigation.reports": "Rapports/<wbr>Statistiques",
  "schedule.navigation.scheduleDataAnalysis": "Analyse<br>des données",
  "schedule.navigation.scheduleWarnings": "Avertissements<br>de l'horaire",
  "schedule.navigation.settings": "Paramètres<br>de l'horaire",
  "schedule.navigation.substitution_planner": "Planificateur<br>de remplacement",
  "schedule.print.header": "Imprimer",
  "schedule.print.page.toolbar.color.descr": "Imprimez sans couleur pour économiser l'encre de l'imprimante.",
  "schedule.print.page.toolbar.color.label": "Utiliser des couleurs",
  "schedule.print.page.toolbar.print.descr": "Dans la boîte de dialogue d'impression, il est également possible d'enregistrer le document au format PDF.",
  "schedule.print.page.toolbar.print.label": "Imprimer",
  "schedule.print.page.toolbar.QR.descr": "Le lien mène au visualiseur Web.",
  "schedule.print.page.toolbar.QR.label": "Inclure le lien QR",
  "schema-defects.category.critical.label": "Critique",
  "schema-defects.category.minor.label": "Mineur",
  "schema-defects.category.significant.label": "Important",
  "schema-defects.courseClustering": "Regroupement de cours",
  "schema-defects.courseDurationMismatch": "Durée totale de l'événement",
  "schema-defects.dailyOvertime": "Heures supplémentaires quotidiennes",
  "schema-defects.doubleBooking": "Double réservation",
  "schema-defects.eventDurationOverflow": "Durée de l'événement",
  "schema-defects.fixed.count.explanation": "Il y a {{ count }} avertissement(s) d'horaire de cette catégorie qui surviennent entièrement en raison de leçons fixes.",
  "schema-defects.forcedOverlappingEventsDay": "Leçons superposées forcées",
  "schema-defects.insufficientBookingMargin": "Marge de temps",
  "schema-defects.insufficientBreakLength": "Durée de la pause",
  "schema-defects.missingDependency": "Salle manquante",
  "schema-defects.missingGroupsAndTeachers": "Enseignant et groupe manquants",
  "schema-defects.non-fixed.count.explanation": "Il y a {{ count }} avertissements d'horaire de cette catégorie qui surviennent pour des raisons autres que les leçons concernées étant fixes.",
  "schema-defects.overflow": "Cadre de l'horaire",
  "schema-defects.overlap": "Collision",
  "schema-defects.overtime": "Heures supplémentaires",
  "schema-defects.unavailableDay": "Jour interdit",
  "settings.index.copy": "Clé API copiée",
  "settings.index.error": "Impossible de créer la clé API",
  "settings.integrationsOut.form.title": "Créer une clé API",
  "settings.integrationsOut.menu": "Exportation API",
  "settings.integrationsOut.page.descr": "À l'aide de la fonction ci-dessous, vous pouvez créer de nouvelles clés API pour rendre les données d'horaire disponibles à d'autres fournisseurs de services. Contactez l'autre fournisseur et vérifiez s'il peut importer des données d'horaire via la norme API SS 12000.",
  "settings.integrationsOut.page.title": "Exportation de données via API (SS 12000:2020)",
  "settings.integrationsOut.shared.description": "Description",
  "settings.integrationsOut.shared.displayName": "Nom",
  "settings.integrationsOut.shared.integrationType": "Plateforme",
  "settings.integrationsOut.table.copyKey": "Copier la clé",
  "settings.integrationsOut.table.createdAt": "Créé",
  "settings.integrationsOut.table.legend": "Clés API",
  "settings.integrationsOut.table.usedAt": "Dernière activité",
  "shared.components.schema-filter-dropdowns coalesced": "Ajouter un filtre",
  "shared.components.schema-filter-dropdowns search course event": "Rechercher une leçon",
  "shared.components.user-menu.to-parent-organization": "Retour à {{ name }}",
  "shared.dialogs.add-duration-set.label": "Entrez la (les) durée (s) de la leçon",
  "shared.dialogs.add-duration-set.title": "Nouvel ensemble de durée",
  "shared.dialogs.company.description": "Crée et ajoute une école à votre organisation.",
  "shared.dialogs.company.title": "Créer une école",
  "shared.dialogs.confirm-action.title": "Êtes-vous sûr?",
  "shared.dialogs.confirm-remove.confirm": "Je comprends",
  "shared.dialogs.confirm-remove.sub-title": "Cette action ne peut pas être annulée",
  "shared.dialogs.confirm-remove.title": "Confirmer la suppression",
  "shared.dialogs.duplicate-schedule.title": "Dupliquer le planning",
  "shared.dialogs.generate-schedule.action.submit": "Planifier",
  "shared.dialogs.generate-schedule.analysis.flawless.description": "Vos données de planning ont été analysées et nous n'avons rien trouvé à signaler. Bien joué !",
  "shared.dialogs.generate-schedule.analysis.flawless.title": "Prêt",
  "shared.dialogs.generate-schedule.analysis.flaws.description": "Vos données de planning ont été analysées et nous avons trouvé les {{ flaws }} suivants qui doivent peut-être être résolus.",
  "shared.dialogs.generate-schedule.description": "Ce processus génère un nouveau planning basé sur les données du planning et le planning actuel sera donc écrasé. Si vous souhaitez enregistrer le planning tel qu'il est maintenant avant de continuer, vous pouvez le dupliquer.",
  "shared.dialogs.generate-schedule.error": "Impossible de traiter votre demande, veuillez réessayer dans quelques minutes. Si le problème persiste, veuillez contacter l'équipe d'assistance et nous vous aiderons avec plaisir.",
  "shared.dialogs.generate-schedule.title": "Planification automatique",
  "shared.dialogs.login.description": "Pour renouveler votre session, vous devez vous reconnecter. Si vous souhaitez annuler votre session, vous fermez simplement cette boîte de dialogue.",
  "shared.dialogs.login.title": "Session expirée",
  "shared.dialogs.overlappableEventSets.description.forced": "Les leçons liées seront programmées en même temps et centrées.",
  "shared.dialogs.overlappableEventSets.description.set": "L'algorithme de planification automatique s'efforce de planifier les leçons appartenant à des cours parallèles en même temps. Cependant, ce n'est pas toujours possible ou autorisé. Par exemple, il n'est pas autorisé que les leçons d'un cours soient programmées en même temps à moins qu'elles ne soient liées.",
  "shared.dialogs.overlappableEventSets.error.set": "Il y a des leçons associées qui ne sont pas liées.",
  "shared.dialogs.overlappableEventSets.forceOverlap": "Lier les leçons",
  "shared.dialogs.overlappableEventSets.gridView": "Vue grille",
  "shared.dialogs.overlappableEventSets.listView": "Vue liste",
  "shared.dialogs.overlappableEventSets.new.forced": "Déposez une leçon ici pour former un nouvel ensemble de leçons liées",
  "shared.dialogs.overlappableEventSets.new.set": "Déposez un cours ici pour former un nouvel ensemble de cours parallèles",
  "shared.dialogs.overlappableEventSets.noCourses": "Aucun cours trouvé",
  "shared.dialogs.overlappableEventSets.noEvents": "Aucune leçon trouvée",
  "shared.dialogs.overlappableEventSets.warning.forced": "Doit inclure au moins deux leçons",
  "shared.dialogs.overlappableEventSets.warning.set": "Doit inclure au moins deux cours",
  "shared.dialogs.sync.collections": "Sélectionner les données de l'horaire",
  "shared.dialogs.sync.error.0": "Impossible de s'authentifier auprès de la plateforme sélectionnée avec vos informations d'identification. Si c'est une erreur, contactez le support.",
  "shared.dialogs.sync.error.1": "Impossible d'obtenir les données de la plate-forme sélectionnée. Il se peut que la configuration soit incorrecte",
  "shared.dialogs.sync.error.3": "Impossible de synchroniser les données de l'horaire. Un ou plusieurs élèves sont connectés à plusieurs cours. Cela n'est pas autorisé. Contactez le support avec votre code d'erreur pour obtenir de l'aide.",
  "shared.dialogs.sync.error.4": "Impossible de synchroniser les données de l'horaire. Un ou plusieurs identifiants ne sont pas uniques. Contactez le support avec votre code d'erreur pour obtenir de l'aide.",
  "shared.dialogs.sync.last_sync": "Dernière synchronisation",
  "shared.dialogs.sync.modified": "Modifié uniquement après",
  "shared.dialogs.sync.platform": "Sélectionnez la plate-forme",
  "shared.dialogs.sync.synced_data": "Données modifiées de l'échéancier",
  "shared.dialogs.sync.time": "Sélectionnez une période",
  "shared.dialogs.sync.title": "Synchronisez votre emploi du temps",
  "shared.dialogs.sync.validation": "Valider les données de l'horaire",
  "shared.dialogs.sync.validation.title": "Il y a quelques problèmes avec les données de l'horaire. Veuillez les résoudre avant de continuer.",
  "shared.dialogs.sync.validation.indistinguishable.courses": "avec le nom \"{{name}}\" partagent le même nom, les mêmes enseignants et les mêmes groupes.",
  "shared.dialogs.sync.validation.indistinguishable.events": "appartenant au cours \"{{name}}\" partagent les mêmes enseignants, groupes et horaires.",
  "shared.dialogs.sync.validation.ids_missing": "manquent d'identifiants",
  "shared.dialogs.sync.validation.ids_not_unique": "ont des identifiants identiques: \"{{ids}}\"",
  "shared.dialogs.sync.validation.valid": "Les données sont valides",
  "shared.dialogs.sync.push_success.title": "Synchronisation réussie",
  "shared.dialogs.sync.push_success.description": "Les données de l'horaire ont été synchronisées avec succès avec la plate-forme sélectionnée. Allez sur la plateforme pour voir les changements.",
  "shared.dialogs.unrecoverable-app-state.description": "L'application a atteint un état irrécupérable. Veuillez recharger la page pour continuer.",
  "shared.dialogs.unrecoverable-app-state.title": "État irrécupérable",
  "shared.dialogs.unsaved-changes.description": "Il y a des changements non enregistrés. Voulez-vous les enregistrer ou les ignorer?",
  "shared.dialogs.unsaved-changes.title": "Changements non enregistrés",
  "shared.dialogs.upload-file.configure.format_specific": "Paramètres spécifiques au format",
  "shared.dialogs.upload-file.configure.label": "Configurer",
  "shared.dialogs.upload-file.configure.PlanDigital.displayName.label": "Utiliser comme nom de cours",
  "shared.dialogs.upload-file.configure.PlanDigital.periods.label": "Sélectionner les périodes à importer",
  "shared.dialogs.upload-file.configure.PlanDigital.periods.placeholder": "Au moins une période doit être sélectionnée",
  "shared.dialogs.upload-file.configure.SchoolSoft.interval.descr": "Exclure les leçons qui ne sont pas planifiées pendant la période sélectionnée ({{start}} - {{end}}).",
  "shared.dialogs.upload-file.configure.SchoolSoft.interval.label": "Filtrer les cours en fonction de la période sélectionnée",
  "shared.dialogs.upload-file.configure.Skola24.deduceGroupsFromCourseName.descr": "Essayez d'identifier et d'ajouter des groupes qui se trouvent dans le nom du cours s'ils sont séparés par un tiret, comme 10FYS01a-<b>NA20E</b>-LABb ou 10-AH-<b>EK21C</b>.",
  "shared.dialogs.upload-file.configure.Skola24.deduceGroupsFromCourseName.label": "Déduire les groupes du nom du cours",
  "shared.dialogs.upload-file.configure.Skola24.ignoreEmptyCourses.label": "Ignorer les cours sans leçons",
  "shared.dialogs.upload-file.configure.Skola24.removeUnreferencedGroups.descr": "Les groupes qui ne sont pas trouvés dans au moins un cours ou une leçon seront ignorés.",
  "shared.dialogs.upload-file.configure.Skola24.removeUnreferencedGroups.label": "Ignorer les groupes inutilisés",
  "shared.dialogs.upload-file.configure.Skola24.removeUnreferencedTeachers.descr": "Les enseignants qui ne sont pas trouvés dans au moins un cours ou une leçon seront ignorés.",
  "shared.dialogs.upload-file.configure.Skola24.removeUnreferencedTeachers.label": "Ignorer les enseignants inutilisés",
  "shared.dialogs.upload-file.configure.Skola24.week.ignoreAllEvents": "Ignorer toutes les leçons",
  "shared.dialogs.upload-file.configure.Skola24.week.label": "Importer les leçons de la semaine sélectionnée",
  "shared.dialogs.upload-file.finalize.content": "Contenu du planning",
  "shared.dialogs.upload-file.finalize.error": "Impossible de traiter le fichier sélectionné",
  "shared.dialogs.upload-file.finalize.errors_and_warnings": "Erreurs et avertissements rencontrés",
  "shared.dialogs.upload-file.finalize.label": "Finaliser",
  "shared.dialogs.upload-file.label": "Télécharger un fichier",
  "shared.dialogs.upload-file.select-file.download_template": "Télécharger le modèle Excel (facultatif)",
  "shared.dialogs.upload-file.select-file.label": "Sélectionner un fichier",
  "shared.dialogs.upload-file.select-map.determined": "Correspondance exacte",
  "shared.dialogs.upload-file.select-map.error": "Impossible d'identifier le format du fichier",
  "shared.dialogs.upload-file.select-map.label": "Sélectionner le format",
  "shared.dialogs.upload-file.select-map.strong": "Format(s) probable(s)",
  "shared.dialogs.upload-file.select-map.unknown": "Format inconnu",
  "shared.dialogs.upload-file.select-map.weak": "Format(s) similaire(s)",
  "shared.dialogs.users.description": "Saisissez une adresse e-mail et sélectionnez une école afin de créer un identifiant pour l'école sélectionnée.",
  "shared.dialogs.users.title": "Créer un utilisateur",
  "shared.dialogs.version.current_version": "Version actuelle",
  "shared.dialogs.version.description": "Une nouvelle version de l'application est disponible. Veuillez recharger la page pour obtenir la dernière version.",
  "shared.dialogs.version.description_hard_reload": "Parfois, un simple rechargement n'est pas suffisant et un rechargement manuel est nécessaire. En général, cela se fait en appuyant sur <kbd>Ctrl</kbd> + <kbd>Shift</kbd> + <kbd>R</kbd> sur Windows ou <kbd>Cmd</kbd> + <kbd>Shift</kbd> + <kbd>R</kbd> sur Mac, mais cela dépend probablement de votre navigateur.",
  "shared.dialogs.version.latest_version": "Dernière version",
  "shared.dialogs.version.title": "Nouvelle version disponible",
  "shared.form-fields.available-locations.empty-repository": "Aucune salle disponible",
  "shared.form-fields.available-locations.groups": "Groupe de salles",
  "shared.form-fields.available-locations.locations": "Salles",
  "shared.form-fields.available-locations.select-null": "Aucune salle",
  "shared.form-fields.available-locations.selected-null": "Aucune salle",
  "shared.form-fields.components.schema-filter-dropdowns search course event": "Rechercher une leçon",
  "shared.form-fields.dynamic-locked-times.duration.tooltip": "La durée préférée et la variation maximale de durée de la pause déjeuner. L'algorithme de planification sélectionnera une durée appropriée dans la plage spécifiée mais conservera la durée combinée de toutes les pauses déjeuner égale à leur durée préférée cumulée.",
  "shared.form-fields.fixed-start.disabled": "Aucun",
  "shared.form-fields.fixed-start.enabled": "Heure de début fixe",
  "shared.form-fields.groups.selection-warning": "un ou plusieurs élèves de ce groupe sont sélectionnés séparément",
  "shared.form-fields.periods.no-periods": "Il n'y a pas de périodes à sélectionner. Veuillez ajouter des périodes à l'emploi du temps.",
  "shared.form-fields.root-intervals.no-root-intervals": "Il y a pas de blocs à sélectionner. Veuillez ajouter des blocs à l'emploi du temps.",
  "shared.forms.change-password-form.current_password": "Mot de passe actuel",
  "shared.forms.change-password-form.new_password": "Nouveau mot de passe",
  "shared.forms.change-password-form.repeat_password": "Répéter le mot de passe",
  "shared.forms.company.name": "Nom",
  "shared.forms.company.organization": "Organisation parente",
  "shared.forms.company.organizationType": "Type d'organisation",
  "shared.forms.company.schoolCode": "Code",
  "shared.forms.company.theme": "Thème",
  "shared.forms.users.company": "Sélectionner l'école",
  "shared.forms.users.username": "Email",
  "source.set_fail": "Impossible de mettre à jour la valeur",
  "source.set_success": "Mise à jour réussie",
  "source.set_timeout": "Mise à jour expirée",
  "source.validation_fail": "Mise à jour invalide",
  "substitutions.are_parallel_courses": "sont des cours parallèles",
  "substitutions.is_a_parallel_course": "est un cours parallèle",
  "table.itemsPerPageLabel": "Lignes par page",
  "table.nextPageLabel": "Page suivante",
  "table.previousPageLabel": "Page précédente",
  "tables.admin-tables.divisions.actions.columns.displayName": "Nom",
  "tables.admin-tables.divisions.actions.columns.id": "ID",
  "tables.admin-tables.divisions.actions.columns.organization": "Organisation",
  "tables.admin-tables.divisions.actions.delete": "Supprimer l'horaire",
  "tables.admin-tables.divisions.actions.delete.failure": "Impossible de supprimer l'horaire",
  "tables.admin-tables.divisions.actions.download": "Télécharger l'horaire",
  "tables.admin-tables.divisions.actions.login": "Se connecter à l'horaire",
  "tables.admin-tables.divisions.actions.login.failure": "Échec de la connexion",
  "tables.admin-tables.organizations.actions.add": "Créer une organisation",
  "tables.admin-tables.organizations.actions.configure_environment": "Configurer l'environnement de calcul",
  "tables.admin-tables.organizations.actions.configure_identifiers": "Configurer les identifiants",
  "tables.admin-tables.organizations.actions.delete": "Supprimer l'organisation",
  "tables.admin-tables.organizations.actions.delete.failure": "Impossible de supprimer l'organisation",
  "tables.admin-tables.organizations.actions.edit": "Modifier l'organisation",
  "tables.admin-tables.organizations.actions.generate_access_key": "Générer une clé d'accès",
  "tables.admin-tables.organizations.actions.login": "Se connecter à l'organisation",
  "tables.admin-tables.organizations.actions.login.failure": "Échec de la connexion",
  "tables.admin-tables.organizations.add-edit.actions.add.failure": "Impossible de créer l'organisation",
  "tables.admin-tables.organizations.add-edit.labels.name": "Nom",
  "tables.admin-tables.organizations.add-edit.labels.organization": "Organisation parente",
  "tables.admin-tables.organizations.add-edit.labels.organization_type": "Type d'organisation",
  "tables.admin-tables.organizations.add-edit.labels.school_code": "Code de l'unité scolaire",
  "tables.admin-tables.organizations.add-edit.labels.theme": "Thème",
  "tables.admin-tables.organizations.add-edit.title.add": "Créer une organisation",
  "tables.admin-tables.organizations.add-edit.title.edit": "Modifier l'organisation",
  "tables.admin-tables.organizations.columns.divisions": "No. emplois du temps",
  "tables.admin-tables.organizations.columns.environment": "Environnement",
  "tables.admin-tables.organizations.columns.identifiers": "No. identifiants",
  "tables.admin-tables.organizations.columns.name": "Nom",
  "tables.admin-tables.organizations.columns.organization": "Organisation parente",
  "tables.admin-tables.organizations.columns.organizationType": "Type d'organisation",
  "tables.admin-tables.organizations.columns.theme": "Thème",
  "tables.admin-tables.organizations.columns.version": "Version de l'algorithme",
  "tables.admin-tables.organizations.configure-compute-environment.title": "Configurer l'environnement de calcul",
  "tables.admin-tables.organizations.configure-identifiers.actions.add.failure": "Impossible d'ajouter l'identifiant",
  "tables.admin-tables.organizations.configure-identifiers.actions.delete.failure": "Impossible de supprimer l'identifiant",
  "tables.admin-tables.organizations.configure-identifiers.identifier-types.school_code": "Code de l'unité scolaire",
  "tables.admin-tables.organizations.configure-identifiers.identifier-types.vat": "Numéro de TVA",
  "tables.admin-tables.organizations.configure-identifiers.title": "Configurer les identifiants",
  "tables.admin-tables.organizations.app_features.substitution_planner": "Activer le planificateur de remplacement",
  "tables.admin-tables.organizations.app_features.title": "Gérer les fonctionnalités de l'application",
  "tables.admin-tables.users.actions.add": "Créer un utilisateur",
  "tables.admin-tables.users.actions.copy_verification_link": "Copier le lien d'activation",
  "tables.admin-tables.users.actions.delete": "Supprimer l'utilisateur",
  "tables.admin-tables.users.actions.delete.failure": "Impossible de supprimer l'utilisateur",
  "tables.admin-tables.users.actions.edit": "Modifier l'utilisateur",
  "tables.admin-tables.users.actions.login": "Se connecter à l'organisation de l'utilisateur",
  "tables.admin-tables.users.actions.login.failure": "Échec de la connexion",
  "tables.admin-tables.users.actions.mail": "Envoyer un email d'activation à l'utilisateur",
  "tables.admin-tables.users.actions.mail.failure": "Impossible d'envoyer l'email d'activation",
  "tables.admin-tables.users.actions.mail.success": "Email d'activation envoyé",
  "tables.admin-tables.users.add-edit.actions.add.failure": "Impossible de créer l'utilisateur",
  "tables.admin-tables.users.add-edit.actions.fetch_companies.failure": "Impossible de récupérer les entreprises",
  "tables.admin-tables.users.add-edit.actions.fetch_from_skolverket.failure": "Impossible de récupérer les informations de Skolverket",
  "tables.admin-tables.users.add-edit.labels.organization": "Organisation",
  "tables.admin-tables.users.add-edit.labels.role": "Rôle de l'utilisateur",
  "tables.admin-tables.users.add-edit.labels.username": "Nom d'utilisateur (email)",
  "tables.admin-tables.users.add-edit.title.add": "Créer un utilisateur",
  "tables.admin-tables.users.add-edit.title.edit": "Modifier l'utilisateur",
  "tables.admin-tables.users.columns.activation": "Lien d'activation",
  "tables.admin-tables.users.columns.last_login": "Dernière connexion",
  "tables.admin-tables.users.columns.online": "En ligne",
  "tables.admin-tables.users.columns.organization": "Organisation",
  "tables.admin-tables.users.columns.role": "Rôle de l'utilisateur",
  "tables.admin-tables.users.columns.username": "Nom d'utilisateur",
  "tables.components.custom-search.empty": "Notez que désactiver la recherche pour toutes les colonnes entraînera des résultats de recherche vides.",
  "tables.components.empty.hint": "Créez-en un en cliquant sur le bouton \"+\" dans le coin supérieur droit",
  "tables.components.empty.title": "Il n'y a pas encore de {{ entities }}",
  "tables.components.no-columns.hint": "Cliquez sur le bouton \"{{ button }}\" dans le coin supérieur droit pour sélectionner les colonnes à afficher",
  "tables.components.no-columns.title": "Aucune colonne de tableau visible",
  "tables.courses.values.overridden": "La valeur n'est pas héritée par toutes les leçons du cours",
  "tables.notifications.alerts.error.title": "Impossible de mettre à jour les préférences",
  "tables.notifications.alerts.success.descr": "Si d'autres onglets sont ouverts, ils doivent être rechargés pour que les modifications prennent effet.",
  "tables.notifications.alerts.success.title": "Préférences mises à jour avec succès",
  "tables.notifications.columns.category.descr": "Les notifications de chaque catégorie sont affichées ensemble.",
  "tables.notifications.columns.category.name": "Catégorie",
  "tables.notifications.columns.category.values.generalConfirmation": "Confirmations générales",
  "tables.notifications.columns.category.values.generalError": "Erreurs générales",
  "tables.notifications.columns.category.values.inputAnalysisError": "Analyseur d'entrée: erreurs critiques",
  "tables.notifications.columns.category.values.inputAnalysisNotice": "Analyseur d'entrée: avis",
  "tables.notifications.columns.category.values.inputAnalysisWarning": "Analyseur d'entrée: avertissements",
  "tables.notifications.columns.category.values.other": "Autre",
  "tables.notifications.columns.location.descr": "Où sur l'écran les notifications de cette catégorie apparaîtront.",
  "tables.notifications.columns.location.name": "Emplacement",
  "tables.notifications.columns.location.values.bottom left": "Coin inférieur gauche",
  "tables.notifications.columns.location.values.bottom right": "Coin inférieur droit",
  "tables.notifications.columns.location.values.top left": "Coin supérieur gauche",
  "tables.notifications.columns.location.values.top right": "Coin supérieur droit",
  "tables.notifications.columns.mode.descr": "Si ces notifications sont activées, en sourdine ou désactivées.",
  "tables.notifications.columns.mode.name": "Mode",
  "tables.notifications.columns.mode.values.mute": "Muet",
  "tables.notifications.columns.mode.values.off": "Désactivé",
  "tables.notifications.columns.mode.values.on": "Activé",
  "tables.notifications.columns.sound.descr": "Quel son sera joué lorsqu'une notification est reçue.",
  "tables.notifications.columns.sound.name": "Son",
  "tables.notifications.columns.test.descr": "Essayez en envoyant une notification factice.",
  "tables.notifications.columns.test.name": "Test",
  "tables.notifications.columns.test.value": "envoyer",
  "tables.notifications.columns.volume.descr": "Le volume de la notification.",
  "tables.notifications.columns.volume.name": "Volume",
  "validators.email": "Email invalide",
  "validators.equal": "Les valeurs ne correspondent pas",
  "validators.invalidDate": "Date invalide",
  "validators.invalidDiscretization": "Doit être un multiple de 5 minutes",
  "validators.invalidLaterDate": "Doit être une date ultérieure",
  "validators.invalidMultiple": "Doit être un multiple de 5",
  "validators.invalidTime": "Heure invalide (intervalles de 5 min)",
  "validators.matDatepickerMax": "Doit sélectionner une date antérieure",
  "validators.matDatepickerMin": "Doit sélectionner une date ultérieure",
  "validators.matEndDateInvalid": "Plage de dates invalide",
  "validators.matStartDateInvalid": "Plage de dates invalide",
  "validators.max": "Cette valeur est trop élevée",
  "validators.min": "Cette valeur est trop basse",
  "validators.minLength": "Trop court",
  "validators.minlength": "Trop court",
  "validators.noMatch": "Mauvais email ou mot de passe",
  "validators.outOfBounds": "La valeur est hors limites",
  "validators.required": "Ce champ est requis",
  "validators.time_too_early": "Heure trop matinale",
  "validators.time_too_late": "Heure trop tardive",
  "validators.usernameOccupied": "Email occupé",
  "view.administrator.organizations.table.add": "Créer une école",
  "view.administrator.organizations.table.createdAt": "Créé le",
  "view.administrator.organizations.table.header": "Écoles",
  "view.administrator.organizations.table.login": "Entrer dans cette école",
  "view.administrator.organizations.table.name": "Nom",
  "view.administrator.page.menu.organizations": "Mes écoles",
  "view.administrator.users.table.add": "Créer un utilisateur",
  "view.administrator.users.table.administrator": "Administrateur",
  "view.administrator.users.table.company": "École/Organisation",
  "view.administrator.users.table.createdAt": "Créé le",
  "view.administrator.users.table.header": "Comptes utilisateurs",
  "view.administrator.users.table.login": "Se connecter à cette école",
  "view.administrator.users.table.mail.fail": "Le lien d'activation n'a pas pu être envoyé à l'utilisateur",
  "view.administrator.users.table.mail.success": "Lien d'activation envoyé à l'utilisateur",
  "view.administrator.users.table.mail.tooltip": "Envoyer le lien d'activation à l'utilisateur",
  "view.administrator.users.table.restricted": "Restreint",
  "view.administrator.users.table.role": "Rôle de l'utilisateur",
  "view.administrator.users.table.unrestricted": "Planificateur",
  "view.administrator.users.table.username": "Email",
  "view.demo.schedule.components.editor_contact_sub_title": "Pour en savoir plus sur notre produit, remplissez votre email et nous vous contacterons avec plus d'informations.",
  "view.demo.schedule.components.editor_contact_title": "Le planning est terminé",
  "view.demo.schedule.components.introduction_contact_submit": "Je suis intéressé",
  "view.demo.schedule.components.introduction_slide_1": "Bienvenue !",
  "view.demo.schedule.components.introduction_slide_2": "Nous avons pré-rempli les données du planning pour une école suédoise de taille moyenne.",
  "view.demo.schedule.components.introduction_slide_3": "Il ne vous reste plus qu'à cliquer sur \"démarrer la planification automatique\", attendre quelques minutes et vous aurez créé votre planning.",
  "view.demo.schedule.page.exit": "Quitter la démo",
  "view.demo.schedule.page.restart": "Redémarrer la démo",
  "view.partner.page.menu.divisions": "Emplois du temps",
  "view.partner.page.menu.organizations": "Organisa&shy;tions",
  "view.partner.page.menu.users": "Utilisa&shy;teurs",
  "view.private.integrations.in.connectionTo": "Connexion à",
  "view.private.integrations.in.descr": "Ci-dessous, vous trouverez des intégrations API possibles pour échanger des informations avec des fournisseurs de services externes.",
  "view.private.integrations.in.error.get": "Les données du planning n'ont pas pu être récupérées. Veuillez contacter l'équipe d'assistance si le problème persiste.",
  "view.private.integrations.in.error.postOrPatch": "Les détails de l'intégration n'ont pas pu être enregistrés. Veuillez contacter l'équipe d'assistance si le problème persiste.",
  "view.private.integrations.in.import": "Importer des données",
  "view.private.integrations.in.menu": "Intégrations",
  "view.private.integrations.in.no_integrations": "Aucune intégration",
  "view.private.integrations.in.SchoolSoftDialog.tab1.title": "Nom et période",
  "view.private.integrations.in.SchoolSoftDialog.tab2.descr": "Il n'est actuellement pas possible d'importer directement les salles via l'API de SchoolSoft. Cependant, en attendant que cela soit résolu, vous pouvez les importer en copiant-collant les données correspondantes dans un format tabulé ou séparé par des virgules, que vous pouvez trouver <a href=\"https://sms.schoolsoft.se/{{webapp}}/jsp/admin/right_admin_import_room.jsp?menu=admin_import_room\" target=\"_blank\">ici</a>.",
  "view.private.integrations.in.SchoolSoftDialog.tab2.error": "Impossible d'analyser le texte",
  "view.private.integrations.in.SchoolSoftDialog.tab2.label": "Collez le texte du tableau ici",
  "view.private.integrations.in.SchoolSoftDialog.tab2.title": "Importer des salles",
  "view.private.integrations.in.setupDialog.key": "Clé API",
  "view.private.integrations.in.setupDialog.meta.schoolapp": "schoolapp",
  "view.private.integrations.in.setupDialog.meta.schoolId": "ID de l'école",
  "view.private.integrations.in.setupDialog.meta.webapp": "webapp",
  "view.private.integrations.in.setupDialog.startsWith": "Commence par {{value}}",
  "view.private.integrations.in.setupDialog.title": "Détails de l'intégration",
  "view.private.integrations.in.success": "Le planning a été importé avec succès",
  "view.private.integrations.in.title": "Intégrations via API",
  "view.private.integrations.in.tooltip.delete": "Supprimer les détails de l'intégration",
  "view.private.integrations.in.tooltip.modify": "Modifier les détails de l'intégration",
  "view.private.integrations.in.validation.error.401": "Détails de l'intégration invalides",
  "view.private.integrations.in.validation.error.404": "Détails de l'intégration invalides",
  "view.private.integrations.in.validation.error.500": "Les détails de l'intégration n'ont pas pu être validés",
  "view.private.integrations.in.validation.error.SchoolSoft.key": "Clé API invalide",
  "view.private.integrations.in.validation.error.SchoolSoft.schoolapp": "Valeur schoolapp invalide",
  "view.private.integrations.in.validation.error.SchoolSoft.webapp": "Valeur webapp invalide",
  "view.private.integrations.in.validation.error.unknown": "Les détails de l'intégration n'ont pas pu être validés",
  "view.private.integrations.in.validation.valid": "Détails de l'intégration valides",
  "view.private.integrations.in.validation.validating": "Validation des détails de l'intégration",
  "view.private.menu.schema": "Plannings",
  "view.private.menu.settings": "Paramètres",
  "view.private.menu.tutorial": "Tutoriel",
  "view.private.menu.users": "Utilisateurs",
  "view.private.schema.components.admentum.subtitle": "Exporter vers Admentum",
  "view.private.schema.components.failed-generation-dialog.description": "Une erreur s'est produite lors du processus de planification automatique et nous travaillons sur une solution. Veuillez réessayer ultérieurement dans la journée ou contacter le support si vous souhaitez être informé(e) lorsque le problème sera résolu.",
  "view.private.schema.components.failed-generation-dialog.title": "La génération automatique a échoué",
  "view.private.schema.components.subtitle": "Exporter vers",
  "view.private.schema.components.idunsoft.subtitle": "Exporter vers IdunSoft",
  "view.private.schema.components.infomentor.subtitle": "Exporter vers InfoMentor",
  "view.private.schema.components.link.subtitle": "Copier le lien vers ce planning",
  "view.private.schema.components.link.success": "Lien copié",
  "view.private.schema.components.link.title": "Visionneuse web",
  "view.private.schema.components.royalschedule.subtitle": "Exporter vers Royal Schedule",
  "view.private.schema.components.schoolsoft_displayName.subtitle": "Exporter vers SchoolSoft en utilisant les noms comme références",
  "view.private.schema.components.schoolsoft.subtitle": "Exporter vers SchoolSoft",
  "view.private.schema.components.table.actions": "Actions",
  "view.private.schema.components.table.basis": "Données du planning",
  "view.private.schema.components.table.clone": "Dupliquer le planning",
  "view.private.schema.components.table.createdAt": "Créé",
  "view.private.schema.components.table.editedAt": "Dernière modification",
  "view.private.schema.components.table.header": "Mes plannings",
  "view.private.schema.components.table.interval": "Durée",
  "view.private.schema.components.table.link": "lien vers la visionneuse web",
  "view.private.schema.components.table.name": "Nom du planning",
  "view.private.schema.components.table.public": "Public",
  "view.private.schema.edit.toolbar.colorDescription": "Description de la couleur",
  "view.private.schema.edit.toolbar.selectedPeriods": "Périodes incluses",
  "view.private.schema.editor.components.user-manual.title": "Manuel d'utilisation",
  "view.private.schema.page.create": "Créer un planning",
  "view.private.schema.page.download_fail": "Impossible de télécharger le programme",
  "view.private.schema.page.header": "Plannings",
  "view.private.schema.page.sync": "Synchroniser les données",
  "view.private.schema.page.upload": "Importer un fichier",
  "view.private.schema.sub-pages.schema-editor.components.event-form.fix-start": "Épingler le jour et l'heure de début",
  "view.private.schema.sub-pages.schema-editor.components.event-form.force-overlap": "Lier les leçons",
  "view.private.schema.sub-pages.schema-editor.components.event-form.make-invisible": "Masquer dans la visionneuse de planning",
  "view.private.schema.sub-pages.schema-editor.components.event-form.make-visible": "Afficher dans la visionneuse de planning",
  "view.private.schema.sub-pages.schema-editor.components.event-form.park": "Garer",
  "view.private.schema.sub-pages.schema-editor.components.event-form.remove-force-overlap": "Dissocier les leçons",
  "view.private.schema.sub-pages.schema-editor.components.event-form.unfix-start": "Détacher le jour et l'heure de début",
  "view.private.schema.sub-pages.schema-editor.components.list.filter": "Filtrer les leçons",
  "view.private.schema.sub-pages.schema-editor.components.list.filter.not_placed": "Leçons garées",
  "view.private.schema.sub-pages.schema-editor.components.list.filter.period": "Périodes incluses uniquement",
  "view.private.schema.sub-pages.schema-editor.components.list.filter.placed": "Leçons placées",
  "view.private.schema.sub-pages.schema-editor.components.progress.info_PENDING": "Le processus de planification a été placé dans la file d'attente. Il démarrera dans quelques minutes",
  "view.private.schema.sub-pages.schema-editor.components.progress.info_STARTED": "Le processus de planification a démarré. Il peut prendre quelques minutes avant d'être terminé.",
  "view.private.schema.sub-pages.schema-editor.page.limited_state": "L'analyse des collisions est désactivée",
  "view.private.schema.sub-pages.schema-editor.page.start_job": "Démarrer la planification automatique",
  "view.private.schema.sub-pages.settings.components.default-values-form.fieldset.break-lengths": "Durée minimale de pause",
  "view.private.schema.sub-pages.settings.components.default-values-form.fieldset.other": "Autre",
  "view.private.schema.sub-pages.settings.components.default-values-form.fieldset.time-frame": "Cadre temporel",
  "view.private.schema.sub-pages.settings.components.default-values-form.fieldset.working-hours": "Heures de travail",
  "view.private.schema.sub-pages.settings.components.options-form.fieldset.overlappable-lessons-set": "Leçons liées",
  "view.private.schema.sub-pages.settings.components.options-form.requireForcedOverlappingEventsSets.description": "Si activé, les leçons appartenant à des cours parallèles doivent être liées. Les leçons liées sont garanties d'être planifiées en même temps après qu'un emploi du temps a été généré. Les leçons appartenant à des cours parallèles qui <b>ne sont pas</b> liées sont généralement autorisées à être planifiées en même temps, mais ce résultat n'est pas garanti après qu'un emploi du temps a été généré.",
  "view.private.schema.sub-pages.settings.components.options-form.requireForcedOverlappingEventsSets.label": "Exiger des leçons liées",
  "view.private.schema.sub-pages.settings.page.tab.default-values": "Valeurs par défaut",
  "view.private.schema.sub-pages.settings.page.tab.options": "Options",
  "view.private.schema.sub-pages.settings.page.tab.periods": "Périodes",
  "view.private.schema.sub-pages.settings.page.tab.rootIntervals": "Blocs",
  "view.private.schema.sub-pages.statistics.duty-assignments.empty": "Pas de données disponibles",
  "view.private.schema.sub-pages.statistics.duty-assignments.header": "Affectations de service",
  "view.private.schema.sub-pages.statistics.duty-assignments.min_of_min_per_week": "{{current}} de {{total}}  min/week",
  "view.private.schema.sub-pages.statistics.duty-assignments.min_per_week": "Min/semaine",
  "view.private.schema.sub-pages.tooltips.locked": "Pendant que l'emploi du temps est en cours de génération automatique, il est verrouillé pour modification.",
  "view.private.settings.language.label": "Langue",
  "view.private.settings.language.title": "Langue",
  "view.private.settings.menu.sync": "Synchronisation",
  "view.private.settings.sync.button.select-schedule": "Sélectionner un emploi du temps",
  "view.private.settings.sync.components.table.sync_toggle": "Synchroniser",
  "view.private.settings.sync.descr": "En utilisant les fonctions ci-dessous, vous pouvez activer et désactiver les plateformes externes pour synchroniser vos données. Si la synchronisation est activée, vos données seront envoyées à la ou aux plateformes que vous avez autorisées. Cela peut être désactivé à tout moment.",
  "view.private.settings.sync.in": "Emplois du temps qui récupèrent les mises à jour des plateformes externes",
  "view.private.settings.sync.no-selected-out": "Sélectionnez un emploi du temps pour que vos plateformes externes y accèdent.",
  "view.private.settings.sync.out": "Horaires actifs",
  "view.private.settings.sync.tab.add-platforms": "Ajouter une plateforme",
  "view.private.settings.sync.tab.my-platforms": "Mes plateformes",
  "view.private.settings.sync.title": "Intégrations de plateforme",
  "view.private.settings.sync.tooltip.REJECTED": "La demande de création d'une synchronisation entre nos plateformes a été rejetée. Contactez l'équipe d'assistance si c'est une erreur.",
  "view.private.tutorial.page.advanced": "Avancé",
  "view.private.tutorial.page.basic": "Bases",
  "view.private.tutorial.page.description.2.1": "Contraintes des enseignants",
  "view.private.tutorial.page.description.2.2": "Créer des leçons et des variations de longueur",
  "view.private.tutorial.page.description.2.3": "Ajouter des salles autorisées",
  "view.private.tutorial.page.description.2.4": "Cours parallèles et leçons liées",
  "view.private.tutorial.page.description.2.5": "Analyse des données de l'emploi du temps",
  "view.private.tutorial.page.description.2.6": "Ajustements itératifs",
  "view.private.tutorial.page.description.api": "Synchroniser les données",
  "view.private.tutorial.page.description.bulk-update": "Apprenez à modifier en masse",
  "view.private.tutorial.page.description.courses": "Apprenez à ajouter des cours et des événements",
  "view.private.tutorial.page.description.create": "Créer un emploi du temps",
  "view.private.tutorial.page.description.excel": "Télécharger un fichier Excel",
  "view.private.tutorial.page.description.groups": "Apprenez à ajouter des groupes",
  "view.private.tutorial.page.description.in-locations": "Apprenez à modifier les salles autorisées",
  "view.private.tutorial.page.description.locations": "Apprenez à ajouter des salles",
  "view.private.tutorial.page.description.locked-times": "Apprenez à ajouter des intervalles de temps interdits",
  "view.private.tutorial.page.description.teacher": "Apprenez à ajouter des enseignants",
  "view.private.tutorial.page.description.time-frame": "Gérer le cadre temporel des emplois du temps",
  "view.private.tutorial.page.description.upload_json": "Télécharger un fichier RS",
  "view.private.tutorial.page.filter.category": "Catégorie",
  "view.private.tutorial.page.filter.category.adjustments": "Ajustements",
  "view.private.tutorial.page.filter.category.conditions": "Conditions",
  "view.private.tutorial.page.filter.category.data": "Données de l'emploi du temps",
  "view.private.tutorial.page.filter.category.edit": "Planification manuelle",
  "view.private.tutorial.page.filter.category.education": "Éducation",
  "view.private.tutorial.page.filter.category.export": "Exporter les données de l'emploi du temps",
  "view.private.tutorial.page.filter.category.import": "Importer les données de l'emploi du temps",
  "view.private.tutorial.page.filter.category.inputAnalyzer": "Analyseur d'entrée",
  "view.private.tutorial.page.filter.category.other": "Autre",
  "view.private.tutorial.page.filter.category.settings": "Réglages",
  "view.private.tutorial.page.filter.category.warnings": "Avertissements",
  "view.private.tutorial.page.filter.level": "Difficulté",
  "view.private.tutorial.page.filter.level.advanced": "Avancé",
  "view.private.tutorial.page.filter.level.beginner": "Débutant",
  "view.private.tutorial.page.filter.level.intermediate": "Intermédiaire",
  "view.private.tutorial.page.header": "Tutoriel",
  "view.private.tutorial.page.type.video": "Guide vidéo",
  "view.public.activation.approved": "Approuvé",
  "view.public.activation.expired": "Lien expiré",
  "view.public.activation.invalid": "Lien invalide",
  "view.public.activation.verifying": "Vérification...",
  "view.public.demo.valid_config": "Configuration de l'environnement",
  "view.public.login.fail": "Échec de la connexion",
  "view.public.login.password": "Mot de passe",
  "view.public.login.status_email_required": "Email valide requis",
  "view.public.login.status_invalid_credentials": "Identifiants invalides",
  "view.public.login.status_invalid_email_format": "Format d'email invalide",
  "view.public.login.status_password_required": "Mot de passe requis",
  "view.public.login.status_reset_password_fail": "Impossible d'envoyer le lien de réinitialisation",
  "view.public.login.status_reset_password_success": "Lien de réinitialisation envoyé à {{email}} si un tel utilisateur existe",
  "view.public.login.username": "Email",
  "view.public.web-viewer.not_public": "L'emploi du temps n'est pas public",
  "view.void.status": "Validation des identifiants",
  "account.advanced.menu": "Avancé",
  "account.advanced.page.title": "Avancé",
  "account.advanced.page.descr": "Paramètres et actions plus avancés qui peuvent parfois être utiles.",
  "account.advanced.page.clear-all-and-reload.label": "Effacer toutes les données et recharger",
  "account.advanced.page.clear-all-and-reload.description": "Cela effacera toutes les données de l'application, mettra fin à tous les processus et rechargera simultanément tous les onglets ouverts de l'application. Cela peut être utile si vous rencontrez des problèmes avec l'application. Notez que cela mettra fin à votre session d'authentification et que vous devrez vous reconnecter.",
  "account.advanced.page.clear-all-and-reload.action": "Effacer et recharger",
  "view.private.schema.sub-pages.examine.page.before_select": "Sélectionnez les groupes, les enseignants et les salles que vous souhaitez comparer. Vous pouvez également choisir le jour et éventuellement la semaine à afficher.",
  "common.show_remaining": "Afficher le reste",
  "common.from_time": "De",
  "common.to_time": "À",
  "shared.dialogs.edit-course-and-event.title": "Modifier",
  "common.parallel_courses": "Cours parallèles",
  "common.open_tutorial": "Ouvrir le tutoriel",
  "common.second": "Seconde",
  "common.second_short": "s",
  "shared.components.svg-schedule.generating.title": "Génération des emplois du temps imprimables",
  "shared.components.svg-schedule.generating.completed_n_of_m": "{{n}} de {{m}} terminé",
  "shared.components.svg-schedule.generating.estimated_time_remaining": "Temps restant estimé: {{time}}",
  "schedule.print.page.toolbar.print_date.label": "Date d'impression",
  "schedule.print.page.toolbar.lockedTimes.label": "Heures interdites",
  "schedule.print.page.toolbar.print_date.descr": "La date et l'heure actuelles.",
  "schedule.print.page.toolbar.lockedTimes.descr": "Inclure ou non les heures interdites.",
  "schedule.print.page.page.before_select": "Sélectionnez les groupes, les enseignants et les salles dont vous souhaitez imprimer l'emploi du temps.",
  "schedule.print.page.toolbar.time_frame.label": "Cadre temporel",
  "schedule.print.page.toolbar.time_frame.descr": "Dessine le cadre temporel et les jours autorisés.",
  "schedule.print.page.toolbar.fit_text.label": "Ajuster le texte",
  "schedule.print.page.toolbar.fit_text.descr": "Essaie d'ajuster le texte en réduisant la taille de la police.",
  "inputAnalysis.issues.overloaded group aborted.title": "Impossible de décider si {{the-entity}} est surchargé de leçons.",
  "inputAnalysis.issues.overloaded group aborted.description": "Le calcul a été interrompu car il a pris trop de temps pour se terminer.",
  "common.date_range": "Plage de dates",
  "inputAnalysis.notices.overflowing_holiday.title": "{{The-entity}} se trouve partiellement en dehors de la période de l'horaire.",
  "inputAnalysis.notices.overflowing_holiday.description": "Avez-vous oublié de mettre à jour le jour férié après avoir modifié la période de l'horaire?",
  "common.the_holiday": "le jour férié",
  "common.the_holidays": "les jours fériés",
  "common.holiday": "jour férié",
  "common.holidays": "jours fériés",
  "view.private.schema.sub-pages.settings.page.tab.holidays": "Jours fériés",
  "view.private.schema.sub-pages.settings.page.tab.holidays.dialog.add.title": "Ajouter un jour férié",
  "view.private.schema.sub-pages.settings.page.tab.holidays.dialog.edit.title": "Modifier le jour férié",
  "inputAnalysis.etc.modify_holiday": "Modifier le jour férié",
  "common.printed": "Imprimé",
  "schedule.print.page.toolbar.display_dates.label": "Afficher les dates",
  "schedule.print.page.toolbar.display_dates.descr": "Une semaine spécifique doit être sélectionnée.",
  "common.delete_all": "Supprimer tout",
  "schedule.navigation.daysCount": "Nombre de jours",
  "common.whole": "Entier",
  "view.private.schema.sub-pages.statistics.days-count.caption": "Le tableau montre combien de fois un jour est présent dans chaque période et aussi leur nombre total de jours. La période \"{{ whole }}\" fait référence à la période déterminée par la date de début et de fin de l'horaire.",
  "dialogs.create-courses.using-excel.label": "Excel",
  "dialogs.create-courses.components.excel.instructions": "Téléchargez d'abord le modèle Excel que nous avons préparé avec vos données d'horaire existantes (sauf les cours). Ensuite, dans son onglet de cours, entrez les cours que vous souhaitez créer. Lorsque vous êtes satisfait, enregistrez le fichier et téléchargez-le à nouveau ici pour obtenir un aperçu des cours qui seront créés ainsi qu'une liste des erreurs rencontrées.",
  "shared.services.excel-template.errors.invalid_file_type": "Type de fichier invalide. Nous ne prenons en charge que les fichiers de types {{ types }}.",
  "shared.services.excel-template.errors.unable_to_parse": "Impossible d'analyser le fichier. Veuillez contacter l'équipe d'assistance.",
  "shared.services.excel-template.errors.incorrect_mapping": "Type de mappage invalide. Veuillez contacter l'équipe d'assistance.",
  "shared.services.excel-template.errors.unable_to_map": "La cartographie des données de l'horaire a échoué. Veuillez contacter l'équipe d'assistance.",
  "maps.excel.messages.missing_settings_sheet": "Impossible de trouver la feuille de paramètres dans le fichier Excel.",
  "maps.excel.messages.missing_locations_sheet": "Impossible de trouver la feuille des salles dans le fichier Excel.",
  "maps.excel.messages.missing_groups_sheet": "Impossible de trouver la feuille des groupes dans le fichier Excel.",
  "maps.excel.messages.missing_teachers_sheet": "Impossible de trouver la feuille des enseignants dans le fichier Excel.",
  "maps.excel.messages.missing_persons_sheet": "Impossible de trouver la feuille des étudiants dans le fichier Excel.",
  "maps.excel.messages.missing_courses_sheet": "Impossible de trouver la feuille des cours dans le fichier Excel.",
  "maps.excel.messages.ignoring_duplicate_location": "Deux salles ou plus avec le nom \"{{ context }}\" ont été trouvées. Ignorer toutes sauf la première.",
  "maps.excel.messages.ignoring_duplicate_group": "Deux groupes ou plus avec le nom \"{{ context }}\" ont été trouvés. Ignorer toutes sauf la première.",
  "maps.excel.messages.ignoring_duplicate_teacher": "Deux enseignants ou plus avec le nom \"{{ context }}\" ont été trouvés. Ignorer toutes sauf la première.",
  "maps.excel.messages.ignoring_duplicate_person": "Deux étudiants ou plus avec le nom \"{{ context }}\" ont été trouvés. Ignorer toutes sauf la première.",
  "maps.excel.messages.ignoring_duplicate_course": "Deux cours ou plus avec le nom \"{{ context }}\" ont été trouvés. Ignorer toutes sauf la première.",
  "maps.excel.messages.invalid_group_reference": "Impossible de trouver le groupe avec le nom \"{{ context }}\".",
  "maps.excel.messages.invalid_location_reference": "Impossible de trouver la salle avec le nom \"{{ context }}\".",
  "maps.excel.messages.invalid_teacher_reference": "Impossible de trouver l'enseignant avec le nom \"{{ context }}\".",
  "maps.excel.messages.location_referenced_multiple_times": "La salle \"{{ context }}\" est référencée plusieurs fois à partir du même cours",
  "maps.excel.messages.teacher_referenced_multiple_times": "L'enseignant \"{{ context }}\" est référencé plusieurs fois à partir du même cours",
  "maps.excel.messages.group_referenced_multiple_times": "Le groupe \"{{ context }}\" est référencé plusieurs fois à partir du même cours",
  "maps.excel.messages.invalid_number_of_days": "Valeur \"{{ context }}\" spécifiée invalide pour le nombre de jours de l'horaire. La valeur doit être 5 (lundi-vendredi) ou 7 (lundi-dimanche).",
  "maps.excel.messages.invalid_sex": "Valeur \"{{ context }}\" spécifiée invalide comme sexe. La valeur doit être \"Man\" ou \"Woman\".",
  "maps.excel.messages.invalid_total_hours": "Valeur \"{{ context }}\" spécifiée invalide comme nombre total d'heures. La valeur doit être un entier positif.",
  "maps.excel.messages.invalid_minutes_per_week": "Valeur \"{{ context }}\" spécifiée invalide comme nombre de minutes par semaine. La valeur doit être un multiple positif de 5.",
  "maps.excel.messages.invalid_lesson_durations": "Valeur \"{{ context }}\" spécifiée invalide comme durées de leçon. La valeur doit être un ou plusieurs nombres séparés par des virgules, où chaque nombre est un multiple positif de 5.",
  "shared.services.excel-template.template.readonly": "Non modifiable",
  "attributes.shared.ids": "ID étranger",
  "attributeDescriptions.shared.ids": "Un ID étranger unique, utile pour l'exportation",
  "attributeDescriptions.shared.createdAt": "La date et l'heure de création de l'entité",
  "attributeDescriptions.teachers.signature": "Signature de l'enseignant",
  "account.workspace.page.displayPublicId.descr": "L'ID étranger d'une entité (cours, leçon, enseignant, etc.) est important lors de l'exportation des données vers une autre plateforme. Si les données de l'horaire ont été importées dans Royal Schedule, la valeur de l'ID étranger ne doit en général pas être modifiée car cela peut entraîner des conflits de données lors de l'exportation de l'horaire. Pour les utilisateurs inexpérimentés, nous recommandons donc de masquer l'ID étranger.",
  "account.workspace.page.displayPublicId.label": "Afficher l'ID étranger",
  "maps.excel.messages.forbidden_group_reference": "Le groupe \"{{ context }}\" est un groupe d'enseignement mais est utilisé comme classe.",
  "dialogs.create-courses.components.excel.warnings.no_courses": "Aucun cours n'a été trouvé dans le fichier Excel.",
  "schedule.exceptions.new.title": "Nouvelle exception",
  "schedule.exceptions.new.type-select": "Type",
  "schedule.exceptions.new.date-select": "Date",
  "schedule.exceptions.new.absentee-select": "Absent",
  "view.private.schema.components.excel.subtitle": "Exporter les données de l'horaire au format Excel",
  "view.private.schema.components.additio.subtitle": "Exporter vers Additio",
  "common.groups.species.none": "Groupes d'enseignants",
  "common.groups.species.class": "Classe",
  "common.the_groups.species.none": "Les groupes d'enseignants",
  "common.the_groups.species.class": "Les classes",
  "dialogs.create-courses.in-bulk.no_options": "Il n'y a pas de groupes et d'enseignants parmi lesquels choisir. Créez d'abord des groupes et des enseignants avant de créer des cours en masse.",
  "view.private.schema.sub-pages.schema-editor.components.event-form.show_schedule": "Afficher l'horaire",
  "common.minutes-per-week_short": "min/semaine",
  "common.minutes-per-week": "Minutes/semaine",
  "attributes.course.plannedDuration": "Durée planifiée",
  "attributeDescriptions.course.plannedDuration": "La durée planifiée de l'horaire du cours en minutes par semaine ou en heures totales. La valeur non spécifiée est calculée automatiquement et arrondie.",
  "inputAnalysis.issues.plannedDuration.title": "{{The-entity}} ne satisfait pas sa durée planifiée.",
  "inputAnalysis.issues.plannedDuration.description": "La durée planifiée est de <b>{{planned-value}}</b> minutes/semaine, mais la durée préférée des événements n'ajoute que <b>{{actual-value}}</b> minutes/semaine.",
  "schedule.guaranteedTuition.tabs.guaranteedTuition.table.planned_duration": "Durée planifiée",
  "schedule.guaranteedTuition.tabs.guaranteedTuition.table.planned_duration_not_met": "La durée planifiée n'est pas respectée pour un ou plusieurs cours représentés par cette ligne.",
  "schedule.guaranteedTuition.tabs.guaranteedTuition.table.actual_duration": "Durée réelle",
  "tables.courses.values.plannedDuration.warning": "La durée planifiée n'est pas respectée.",
  "maps.excel.messages.ambiguous_planned_duration": "Vous ne pouvez pas spécifier la durée planifiée en minutes/semaine et en heures en même temps. Veuillez choisir l'une de ces options: {{ context }}.",
  /* TO BE DEPRECATED */ "attributes.course.minutesPerWeek": "<s>Minutes/semaine</s>",
  /* TO BE DEPRECATED */ "attributes.course.expectedTotalHours": "<s>Temps total prévu</s>",
  /* TO BE DEPRECATED */ "attributeDescriptions.course.expectedTotalHours": "Remplacé par 'Durée planifiée'.",
  /* TO BE DEPRECATED */ "attributeDescriptions.course.minutesPerWeek": "Remplacé par 'Durée planifiée'.",
  "attributes.shared.tags": "Tags",
  "attributeDescriptions.shared.tags": "Les tags sont utilisés pour catégoriser et filtrer.",
  "shared.form-fields.tags.input.placeholder": "Nouveau tag...",
  "validators.invalid": "Valeur invalide",
  "maps.excel.messages.duplicate_group_member": "L'étudiant \"{{ context }}\" est listé plus d'une fois dans le groupe.",
  "maps.excel.messages.invalid_person_reference": "Impossible de trouver l'étudiant avec le numéro de sécurité sociale \"{{ context }}\".",
  "maps.excel.messages.person_in_multiple_classes": "L'étudiant \"{{ context }}\" est trouvé dans plusieurs classes.",
  "shared.dialogs.user-inactive.title": "Inactivité détectée",
  "shared.dialogs.user-inactive.description": "Il semble que vous soyez parti un moment. Pour que tout fonctionne correctement, veuillez cliquer sur le bouton \"Recharger\". Cela rafraîchira tous vos onglets et s'assurera que tout est à jour.",
  "shared.views.schedule-viewer.before_select": "Sélectionnez l'horaire que vous souhaitez afficher et pour quelle semaine.",
  "shared.dialogs.overlappableEventSets.auto-link.description": "Enllaça automàticament",
  "shared.dialogs.overlappableEventSets.unable_to_auto-link.description": "No es pot enllaçar les lliçons automàticament. Per a que això funcioni, els cursos paral·lels han de contenir el mateix nombre de lliçons de la mateixa durada i les lliçons no han d'estar ja totalment enllaçades.",
  "shared.dialogs.overlappableEventSets.auto-link_N": "Enllaça {{ N }}",
  "shared.dialogs.overlappableEventSets.auto-link_N.description": "Enllaça automàticament les lliçons per a {{ N }} conjunts de cursos paral·lels. Per a que això funcioni, els cursos paral·lels han de contenir el mateix nombre de lliçons de la mateixa durada i les lliçons no han d'estar ja totalment enllaçades.",
  "shared.dialogs.overlappableEventSets.unable_to_auto-link_N.description": "No hi ha conjunts de cursos paral·lels que compleixin els requisits perquè les seves lliçons es puguin enllaçar automàticament. Per a que això funcioni, els cursos paral·lels han de contenir el mateix nombre de lliçons de la mateixa durada i les lliçons no han d'estar ja totalment enllaçades.",
  "tables.courses.action.connect-courses": "Connecta",
  "tables.courses.action.disconnect-courses": "Desconnecta",
  "maps.excel.messages.invalid_course_reference": "Impossible de trouver le cours avec l'ID \"{{ context }}\".",
};

// PLEASE LEAVE
// translate the value of the commented out key-value pairs to the language of this file and based on its context
