import { Component,
         OnInit,
         Input,
         OnDestroy,
         Output,
         ViewChild,
         ElementRef,
         TemplateRef,
         HostBinding,
         Optional,
         Self,
         ViewContainerRef,
         ViewEncapsulation,
         ChangeDetectionStrategy,
         EventEmitter                    } from '@angular/core';
import { ControlValueAccessor,
         UntypedFormControl,
         NgControl                       } from '@angular/forms';
import { CdkPortalOutletAttachedRef,
         ComponentPortal,
         Portal,
         TemplatePortal                  } from '@angular/cdk/portal';
import { MatFormFieldControl             } from '@angular/material/form-field';
import { FocusMonitor                    } from '@angular/cdk/a11y';
import { coerceBooleanProperty           } from '@angular/cdk/coercion';
import { coerceNumberProperty            } from '@angular/cdk/coercion';
import { MatMenuTrigger                  } from '@angular/material/menu';
import { MatSlideToggleChange            } from '@angular/material/slide-toggle';
import { asyncScheduler,
         fromEvent,
         Subject                         } from 'rxjs';
import { filter,
         takeUntil                       } from 'rxjs/operators';
import $                                   from 'jquery';
import moment                              from 'moment';
import _                                   from 'lodash';

import { LockedTime as CoreType          } from 'app/shared/interfaces';
import { LockedTimesCalendar             } from './calendar.component';
import { CoreService                     } from './core.service';

type LockedTime = CoreType.populated;

@Component({
  selector: 'app-form-field-locked-times',
  templateUrl: './locked-times.component.html',
  styleUrls: ['./locked-times.component.scss'],
  providers: [
    {
      provide:     MatFormFieldControl,
      useExisting: LockedTimesComponent
    }
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LockedTimesComponent  implements OnInit,
                                              OnDestroy,
                                              ControlValueAccessor,
                                              MatFormFieldControl<LockedTime[] | null | undefined>  {
  @ViewChild('listPortalContent', { static: true }) listPortalContent: TemplateRef<any>;
  @ViewChild('voidPortalContent', { static: true }) voidPortalContent: TemplateRef<any>;
  @ViewChild(MatMenuTrigger)      trigger?:           MatMenuTrigger;
  @Output('onChange') emitter = new EventEmitter<LockedTime[] | null | undefined>();
  static nextId:       number              = 0;
  public stateChanges: Subject<void>       = new Subject<void>();
  public focused:      boolean             = false;
  public errorState:   boolean             = false;
  public controlType:  string              = 'locked-times-input';
  public id:           string              = `locked-times-input-${ LockedTimesComponent.nextId++ }`;
  public describedBy:  string              = '';
  public onChange = (_: any) => {};
  public onTouched = () => {};
  private calendarPortal:      ComponentPortal<LockedTimesCalendar> | null;
  private listPortal:          TemplatePortal<any> | null;
  public selectedPortal:       Portal<any> | null;
  private onDestroy:           Subject<boolean> = new Subject<boolean>();
  private onClose:             Subject<boolean> = new Subject<boolean>();
  public isOpen:               boolean          = false;
  public isCalendar:           boolean          = false;

  @HostBinding('attr.tabindex') __tabindex = 0;

  constructor(private _viewContainerRef: ViewContainerRef,
              private _focusMonitor:     FocusMonitor,
              public core:               CoreService,
              private _elementRef:       ElementRef<HTMLElement>,
              @Optional() @Self() public ngControl: NgControl) {

    _focusMonitor.monitor(_elementRef, true)
    .subscribe((origin: any) => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    // pass the value to the core service for validation purposes
    this.core.numDays = this.numDays
  }

  public restoreValue(): void {
    this.inherit?.setValue(this._pristineValue === null);

    // toggle calendar edit mode
    this.core.editable.next(! this.inherit?.value);

    this.value = this._pristineValue;
    this.pristine = true;
    this.core.value = this._pristineValue;
    this.core.registerChange();
    this.onChange(this.value);
  }

  public closed(): void {
    this.isCalendar = false;

    if (this.saveOnClose && this.valid && ! this.pristine) {
      this.emitter.emit(this.inherit?.value ? null : this.value);
      this.pristine = true;
    }

    if (this.saveOnClose && ! this.valid) {
      this.value = this._pristineValue;
      this.pristine = true;
    }

    asyncScheduler.schedule(() => {
      this.core.reset();
      this.listPortal = null;
      this.calendarPortal = null;
      this.selectedPortal = null;
    });

    this.onClose.next(true);
  }

  public opened(): void {
    this.listPortal     = new TemplatePortal(this.listPortalContent, this._viewContainerRef);
    this.calendarPortal = new ComponentPortal<LockedTimesCalendar>(LockedTimesCalendar, null);

    this.selectedPortal = this.listPortal;

    this.core.value = this._value;

    this.core.onChange()
    .pipe(
      takeUntil(this.onClose),
      filter((value: any) => !! value)
    )
    .subscribe((value: LockedTime[]) => {
      this._setValue(value);
      this.pristine = _.isEqual(this._value, this._pristineValue);
      this._handleInput();
    });

    fromEvent(document, 'keydown')
    .pipe(takeUntil(this.onClose))
    .subscribe((event: KeyboardEvent) => {
      event.stopPropagation();
      if (event.key == 'Tab') {
        event.stopPropagation();
      }
      if (event.key == 'Escape') {
        this.trigger?.closeMenu();
      }
    });
  }

  public onComponentRendering(ref: CdkPortalOutletAttachedRef) {
    // pass number of days to calendar
    if (ref && 'instance' in ref && ref.instance.numDays)
      ref.instance.numDays = this.numDays;
  }

  private _setValue(value: LockedTime[] | null | undefined): void {
    if (this.inherit?.value) {
      this._value = null;
    } else {
      this._value = value?.map(({ displayName, start, end, id }) => {
        displayName = displayName || null;
        return {
          displayName,
          ...start && { start: moment.utc(start)?.toISOString() } ,
          ...end   && { end:   moment.utc(end)?.toISOString() } ,
          day: undefined as any,  // hax
          ...!this._omitId && { id }
        } as any;  // hax
      }) ?? null;
    }

    setTimeout(() => this._setPristine(), 0);
  }

  private _setPristine () {
    this.pristine = _.isEqual(this.value, this._pristineValue);
  }

  public submit(): void {
    this.emitter.emit(this.value);
  }

  public inheritToggle(event: MatSlideToggleChange) {
    this.inherit?.setValue(event.checked);

    // toggle empty array vs inherit
    if      (this._value == null)    this._setValue([]);
    else if ( ! this._value?.length) this._setValue(null);

    // toggle calendar edit mode
    this.core.editable.next(! this.inherit?.value);

    this.core.form.controls.forEach(control => {
      this.inherit?.value ? control.disable() : control.enable();
    });

    this._setPristine();
    this._handleInput();
  }

  get valid(): boolean {
    return this.core.form?.valid || !! this.inherit;
  }

  get empty() {
    return ! this._value?.length;
  }

  get shouldLabelFloat() { return this.focused || ! this.empty; }

  get pristine(): boolean {
    return this._pristine;
  }
  set pristine(_val: boolean) {
    const val      = coerceBooleanProperty(_val);
    this._pristine = val;

    if (val) asyncScheduler.schedule(() => this.ngControl?.control?.markAsPristine());
    else this.ngControl?.control?.markAsDirty();

    this.stateChanges.next();
  }
  private _pristine: boolean = true;

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get voidText(): string { return this._voidText; }
  set voidText(value: string) {
    this._voidText = value;
    this.stateChanges.next();
  }
  private _voidText: string = '';

  @Input()
  get reset(): boolean { return this._reset; }
  set reset(value: boolean) {
    this._reset = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _reset = true;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get disableActions(): boolean { return this._disableActions; }
  set disableActions(value: boolean | string) {
    this._disableActions = coerceBooleanProperty(value);
  }
  private _disableActions = true;

  @Input()
  get saveOnClose(): boolean { return this._saveOnClose; }
  set saveOnClose(value: boolean | string) {
    this._saveOnClose = coerceBooleanProperty(value);
  }
  private _saveOnClose: boolean = false;

  @Input()
  get omitId(): boolean { return this._omitId; }
  set omitId(value: boolean | string) {
    this._omitId = coerceBooleanProperty(value);
  }
  private _omitId: boolean = false;

  @Input()
  get numDays(): number { return this._numDays; }
  set numDays(value: number | null | undefined) {
    this._numDays = coerceNumberProperty(value, 5);
    this._days    = [...Array(this._numDays).keys()];
    this.stateChanges.next();
  }
  private _numDays = 5;
  private _days    = [...Array(5).keys()];

  @Input()
  get inherit(): UntypedFormControl | null { return this._inherit; }
  set inherit(value: UntypedFormControl | null | string) {
    if (_.isString(value)) {
      this._inherit = new UntypedFormControl();
    } else {
      this._inherit = value ? value : new UntypedFormControl();
    }
    this.stateChanges.next();
  }
  private _inherit: UntypedFormControl | null;

  @Input()
  get value(): LockedTime[] | null | undefined {
    return this._value;
  }
  set value(_val: LockedTime[] | null | undefined) {
    // try set inheritance status
    this.inherit?.setValue(_val === null)

    this._setValue(_val);
    this._pristineValue = this._value;

    this.stateChanges.next();
  }
  private _value:         (LockedTime & { day: number })[] | null;
  private _pristineValue: (LockedTime & { day: number })[] | null;

  @Input()
  get hideCalendar(   ): boolean  { return this._hideCalendar;                        }
  set hideCalendar(_val: boolean) { this._hideCalendar = coerceBooleanProperty(_val); }
  private _hideCalendar: boolean = false;

  public toggleCalendar(): void {
    this.isCalendar = ! this.isCalendar;
    this.selectedPortal = this.isCalendar ? this.calendarPortal : this.listPortal;

    if (! this.isCalendar) {
      asyncScheduler.schedule(() => {
        const panel = $('.locked-time-panel');
        const contentHeight = 195;
        const footerHeight  = 48;
        const toolbarHeight = 48;
        const top           = panel.offset()!.top;
        const wh            = $( window ).height()!;
        if (top + contentHeight + footerHeight + toolbarHeight > wh) {
          panel.css('transform', `translateY(${ wh - top - contentHeight - footerHeight - toolbarHeight }px)`);
        } else {
          panel.css('transform', `translateY(0px)`);
        }

      });
      return;
    }
    asyncScheduler.schedule(() => {
      const contentHeight = 500;
      const footerHeight  = 48;
      const toolbarHeight = 48;
      const panel         = $('.locked-time-panel');
      const top           = panel.offset()!.top;
      const size          = $( window ).height()!;
      if (top + contentHeight + footerHeight + toolbarHeight > size) {
        panel.css('transform', `translateY(${ size - top - contentHeight - footerHeight - toolbarHeight }px)`);
      }
    })
  }


  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
    this.onDestroy.next(true);
    this.onDestroy.complete();
    this.onClose.next(true);
    this.onClose.complete();
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent) {
    this.trigger?.openMenu();
  }

  writeValue(val: LockedTime[]): void {
    this.value = val;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  _handleInput(): void {
    this.onChange(this.value);
  }

  static ngAcceptInputType_disabled: boolean | string | null | undefined;
  static ngAcceptInputType_required: boolean | string | null | undefined;
}
