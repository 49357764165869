import { Component,
         Input,
         OnInit                            } from '@angular/core';
import { UntypedFormControl,
         ValidatorFn,
         Validators,
         FormControl,
         FormGroup                         } from '@angular/forms';
import { coerceBooleanProperty             } from '@angular/cdk/coercion';
import { DateAdapter,
         MAT_DATE_FORMATS,
         MAT_DATE_LOCALE                   } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS,
         MomentDateAdapter                 } from '@angular/material-moment-adapter';
import { asyncScheduler,
         Observable,
         Subject                           } from 'rxjs';
import { takeUntil,
         filter,
         map                               } from 'rxjs/operators';
import { CleaveOptions                     } from 'cleave.js/options';
import moment                                from 'moment';


import { Division, DivisionSettings        } from '@app/shared/interfaces';

export type FormData = Pick<Division, 'displayName' | 'start' | 'end' | 'published'>
                     & { settings?: Pick<DivisionSettings, 'numDays'> };

@Component({
  selector: 'app-division-form',
  templateUrl: './division.component.html',
  styleUrls: ['./division.component.scss'],
  providers: [
    // these two providers makes the datepicker return utc moments
    {
      provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
      useValue: { useUtc: true }
    }, {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    // date picker display options
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'YYYY-MM-DD',
        },
        display: {
          dateInput: 'YYYY-MM-DD',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY',
        },
      },
    },
  ]
})
export class DivisionComponent implements OnInit {
  private readonly onDestroy = new Subject<boolean>();
  private readonly onReset   = new Subject<boolean>();

  protected readonly cleaveDateFormat = (['start', 'end'] as const).map((control): CleaveOptions => ({
    date:        true,
    delimiter:   '-',
    datePattern: ['Y', 'm', 'd'],
    dateMin:     undefined,
    dateMax:     undefined,
    // Fix for cleave.js not updating the form value when the input is invalid and modified by cleave
    onValueChanged: (e) => {
      if (e.target.value.length === 10) {
        this.form.controls[control].setValue(moment.utc(e.target.value, 'YYYY-MM-DD'), { emitEvent: false });
      }
    }
  }));

  public loading        = true;
  public submitted      = false;
  public discretization = 5;

  public form = this.createForm();

  private _periods: [moment.Moment, moment.Moment][] = [
    //Höstterminen 2023
    [
      moment.utc('2023.09.21 00:00', moment.defaultFormat),
      moment.utc('2023.12.21 00:00', moment.defaultFormat)
    ],
    //Vårterminen 2024
    [
      moment.utc('2024.01.08 00:00', moment.defaultFormat),
      moment.utc('2024.06.12 00:00', moment.defaultFormat)
    ],
    //Höstterminen 2024
    [
      moment.utc('2024.09.19 00:00', moment.defaultFormat),
      moment.utc('2024.12.20 00:00', moment.defaultFormat)
    ],
    //Vårterminen 2025
    [
      moment.utc('2025.01.07 00:00', moment.defaultFormat),
      moment.utc('2025.06.11 00:00', moment.defaultFormat)
    ]
  ];

  constructor () { }

  ngOnInit() { }

  private _getDefaultDates(): [moment.Moment, moment.Moment] {
    const now = moment();

    let period = this._periods.find(([start, end]: [moment.Moment, moment.Moment]) => now.isBetween(start, end));
    if (period) return period;

    period = this._periods.find(([start, end]: [moment.Moment, moment.Moment]) => start.diff(now) > 0);
    if (period) return period;

    return this._periods[0];
  }

  @Input()
  get published(): boolean { return this._published; }
  set published(value: boolean) {
    this._published = coerceBooleanProperty(value);
  }
  private _published: boolean = false;

  @Input()
  get displayName(): boolean { return this._displayName; }
  set displayName(value: boolean | string) {
    this._displayName = coerceBooleanProperty(value);
  }
  private _displayName: boolean = true;

  @Input()
  get start(): boolean { return this._start; }
  set start(value: boolean | string) {
    this._start = coerceBooleanProperty(value);
  }
  private _start: boolean = false;

  @Input()
  get end(): boolean { return this._end; }
  set end(value: boolean | string) {
    this._end = coerceBooleanProperty(value);
  }
  private _end: boolean = false;

  @Input()
  get numDays(): boolean { return this._numDays; }
  set numDays(value: boolean | string) {
    this._numDays = coerceBooleanProperty(value);
  }
  private _numDays: boolean = false;

  @Input()
  get formGroup(): FormGroup { return this.form; }
  set formGroup(value: FormGroup) {
    this.form = value;
  }

  get value(): Partial<FormData> { return this.mapForm(this.form.value); }

  get valid(): boolean { return this.form.valid; }

  public setFormValue (
    value?:  FormData,
    options: { filterInvalid?: boolean } = { filterInvalid: true }
  ): Observable<Partial<FormData>> {

    asyncScheduler.schedule(() => {
      this.loading = false;
      this.onReset.next(true);

      value?.published   && this.form.controls.published.setValue(value.published,     { emitEvent: false });
      value?.displayName && this.form.controls.displayName.setValue(value.displayName, { emitEvent: false });

      const [start, end] = this._getDefaultDates();
      this.form.controls.start.setValue(value?.start ?? start, { emitEvent: false });
      this.form.controls.end.setValue(  value?.end   ?? end,   { emitEvent: false });

      this.form.controls.numDays.setValue(value?.settings?.numDays ?? 5, { emitEvent: false });
    });

    // observer called every time the form is changed and valid
    return this.form.valueChanges
      .pipe(
        takeUntil(this.onDestroy),
        takeUntil(this.onReset),
        filter(() => {
          return ! options.filterInvalid || this.form.valid;
        }),
        map(x => this.mapForm(x))
      );
  }

  private mapForm (data: typeof this.form.value): Partial<FormData> {
    // removes entries that are not shown
    return {
      ...this.published   && { published:           data.published },
      ...this.displayName && { displayName:         data.displayName },
      ...this.start       && { start:               data.start },
      ...this.end         && { end:                 data.end },
      ...this.numDays     && { settings: { numDays: data.numDays } },
    };
  }

  public createForm () {
    let form = new FormGroup({
      published:   new FormControl<FormData['published']>      (undefined, { nonNullable: true }),
      displayName: new FormControl<FormData['displayName']>    (undefined, { nonNullable: true, validators: [Validators.required] }),
      start:       new FormControl<FormData['start']>          (undefined, { nonNullable: true, validators: [Validators.required] }),
      end:         new FormControl<FormData['end']>            (undefined, { nonNullable: true, validators: [Validators.required] }),
      numDays:     new FormControl<DivisionSettings['numDays']>(5,         { nonNullable: true, validators: [Validators.required] })
    });

    form.addValidators(this._dateValidator());

    return form;
  }

  private _dateValidator(): ValidatorFn {
    return (control: any): {[key: string]: boolean} | null => {
      const start: UntypedFormControl = control.controls.start;
      const end:   UntypedFormControl = control.controls.end;
      let valid = false;
      try {
        valid = moment(start.value).isBefore(moment(end.value));
      } catch(err) {
        return { date: false };
      }
      return valid ? null : { equal: false };
    };
  }

  ngOnDestroy(): void {
    this.onDestroy.next(true);
    this.onDestroy.complete();
    this.onReset.complete();
  }
}
