import { Component,
         OnInit,
         Input,
         ElementRef,
         OnDestroy,
         Optional,
         Self,
         HostBinding,
         EventEmitter,
         Output,
         ViewEncapsulation,
         ViewChild                       } from '@angular/core';
import { ControlValueAccessor,
         NgControl,
         FormControl,
         FormGroup                       } from '@angular/forms';
import { MatFormFieldControl             } from '@angular/material/form-field';
import { FocusMonitor                    } from '@angular/cdk/a11y';
import { coerceBooleanProperty           } from '@angular/cdk/coercion';
import { MatMenuTrigger                  } from '@angular/material/menu';
import { Subject,
         asyncScheduler,
         fromEvent                       } from 'rxjs';
import { startWith,
         takeUntil                       } from 'rxjs/operators';
import moment                              from 'moment';
import _                                   from 'lodash';

import { DateService                     } from 'app/shared/services';
import { ExtendedValidators              } from 'app/shared/forms/validators';


type Value         = string | undefined | null;
type InternalValue = moment.Moment | null;

type Form = {
  type:  FormControl<'none' | 'inherits' | 'value'>;
  value: FormGroup<{
    time: FormControl<moment.Moment>;
  }>;
};

@Component({
  selector: 'app-form-field-center-of-attraction',
  templateUrl: './center-of-attraction.component.html',
  styleUrls: ['./center-of-attraction.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: CenterOfAttractionComponent
    }
  ]
})
export class CenterOfAttractionComponent
  implements OnDestroy, ControlValueAccessor, MatFormFieldControl<string | undefined>
{

  public readonly stateChanges = new Subject<void>();
  private readonly onDestroy   = new Subject<boolean>();
  private readonly onClose     = new Subject<boolean>();

  @ViewChild(MatMenuTrigger) trigger?: MatMenuTrigger;

  @Output('onChange') emitter = new EventEmitter<string | null | undefined>();

  public placeholderText:  string          = '';
  static nextId:       number              = 0;
  public focused:      boolean             = false;
  public errorState:   boolean             = false;
  public controlType:  string              = 'interval-input';
  public id:           string              = `interval-input-${ CenterOfAttractionComponent.nextId++ }`;
  public describedBy:  string              = '';
  public onChange = (_: any) => {};
  public onTouched = () => {};
  public transform:    Map<string, string> = new Map<string, string>();
  public form:         FormGroup<Form> | null;
  public isVoid:       boolean             = true;

  @HostBinding('attr.tabindex') __tabindex = 0;

  constructor (
    @Optional() @Self() public ngControl: NgControl,
    public date:           DateService,
    private _focusMonitor: FocusMonitor,
    private _elementRef:   ElementRef<HTMLElement>
  ) {
    _focusMonitor.monitor(_elementRef, true)
    .subscribe((origin: any) => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }


  private _setForm(): void {
    const val = this._value;
    const type = val ? 'value' : ( val === null ? 'inherits' : 'none' );

    if ( ! this.form) {
      this.form = new FormGroup<Form>({
        type:  new FormControl(type, { nonNullable: true, validators: [ExtendedValidators.enum(['none', 'inherits', 'value'])] }),
        value: new FormGroup({
          time: new FormControl(DateService.fromTimeString(this.preselected, 0), { nonNullable: true }),
        })
      });
    }

    this.form.controls.type.setValue(type);   // need to emit as we listen to it in order to determine the disabled state
    const time = DateService.fromTimeString(val ?? this.preselected, 0)
    this.form.controls.value.controls.time.setValue(time, { emitEvent: false });
  }

  private _setValue(): void {
    if ( ! this.form) return;

    // need the raw value as disabled fields are otherwise ignored
    const value = this.form.getRawValue();
    if (value.type == 'none') {
      this._value = undefined;
    } else if (value.type == 'inherits') {
      this._value = null;
    } else {
      // string, moment -> HH:MM
      let time = value.value.time;
      if (_.isString(time) && time.length == 5) this._value = time;
      else                                      this._value = this.date.fromDate(time);
    }
    this._setInternalValue();

    this.pristine = _.isEqual(this._value, this._pristineValue);
  }

  private _setInternalValue () {
    if (this.value) this._internalValue = DateService.fromTimeString(this.value, 0);
    else            this._internalValue = null;
  }

  public resetValue(): void {
    this.value = this._pristineValue;
    this._setInternalValue();
    this._setForm();
    this.pristine = true;
  }

  public closed(): void {
    this.isVoid = true;
    this.onClose.next(true);

    if (this.saveOnClose && ! this.valid) {
      this.pristine = true;
      this.value = this._pristineValue;
      this._handleInput();
    }

    if (this.saveOnClose && this.valid && ! this._pristine) {
      this.pristine = true;
      this._handleInput();
      this.emitter.emit(this.value);
      this._pristineValue = this.value;
    }

    asyncScheduler
    .schedule(() => {
      this.form = null;
    })
  }

  public opened(): void {
    this._setForm();
    this.isVoid    = false;

    this.form?.valueChanges
    .pipe(takeUntil(this.onClose))
    .subscribe(() => {
      this._setValue();
      this._handleInput();
    });

    // set enabled/disabled
    this.form?.controls.type.valueChanges
    .pipe(
      takeUntil(this.onClose),
      startWith(this.form?.controls.type.value)
    )
    .subscribe(x => {
      if (x == 'value') this.form?.controls.value.enable ({ emitEvent: false });
      else              this.form?.controls.value.disable({ emitEvent: false });
    });


    fromEvent(document, 'keydown')
    .pipe(takeUntil(this.onClose))
    .subscribe((event: any) => {
      if (event.key == 'Escape') {
        this.trigger?.closeMenu()
      }
    });
  }

  public submit(): void {
    this.emitter.emit(this.value);
  }

  get empty() {
    return ! this._value?.length;
  }

  get valid(): boolean {
    if (this.form?.value.type == 'value') {
      return !! this.form?.controls.value.valid;
    }
    return true;
  }

  get shouldLabelFloat() { return this.focused || ! this.empty; }

  get pristine(): boolean { return this._pristine; }
  set pristine(_val: boolean) {
    const val      = coerceBooleanProperty(_val);
    this._pristine = val;

    if (val) asyncScheduler.schedule(() => this.ngControl?.control?.markAsPristine());
    else this.ngControl?.control?.markAsDirty();

    this.stateChanges.next();
  }
  private _pristine:  boolean = true;

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get voidText(): string { return this._voidText; }
  set voidText(value: string | number | undefined) {
    this._voidText = value?.toString() ?? '';
    this.stateChanges.next();
  }
  private _voidText: string = '';

  @Input()
  get reset(): boolean { return this._reset; }
  set reset(value: boolean) {
    this._reset = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _reset = true;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get disableActions(): boolean { return this._disableActions; }
  set disableActions(value: boolean | string) {
    this._disableActions = coerceBooleanProperty(value);
  }
  private _disableActions = true;

  @Input()
  get saveOnClose(): boolean { return this._saveOnClose; }
  set saveOnClose(value: boolean | string) {
    this._saveOnClose = coerceBooleanProperty(value);
  }
  private _saveOnClose: boolean = false;

  @Input()
  get value(): Value { return this._value; }
  set value(_val: Value) {
    this._value = this._pristineValue = _val;
    this._setInternalValue();

    this.stateChanges.next();
  }
  private _value:         Value;
  private _pristineValue: Value;

  @Input()
  get internalValue (): InternalValue { return this._internalValue; }
  private _internalValue: InternalValue;

  @Input()
  get preselected(): string { return this._preselected; }
  set preselected(value: string | undefined) {
    if ( ! value) return;
    this._preselected = value;
  }
  private _preselected: string = '12:00';

  @Input()
  get setVoidText(): string { return this._setVoidText; }
  set setVoidText(value: string) {
    this._setVoidText = value;
  }
  private _setVoidText: string;

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
    this.onDestroy.next(true);
    this.onDestroy.complete();
    this.onClose.next(true);
    this.onClose.complete();
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent) {
    this.trigger?.openMenu();
  }

  writeValue(val: Value): void {
    this.value = val;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  _handleInput(): void {
    this.onChange(this.value);
  }

  static ngAcceptInputType_disabled: boolean | string | null | undefined;
  static ngAcceptInputType_required: boolean | string | null | undefined;
}