export const apiConstants = {
  ADMINISTRATOR:           'administrators',
  APPLOGS:                 'app_logs',
  ACTIVATION:              'activation',
  CALENDAR_EVENTS:         'calendar_events',
  DIVISIONS:               'divisions',
  AUTH:                    'auth',
  RESETPASSWORD:           'reset',
  PERSONS:                 'persons',
  PERIODS:                 'periods',
  ROOTINTERVALS:           'root_intervals',
  GROUPS:                  'groups',
  TEACHERS:                'teachers',
  COURSES:                 'courses',
  COURSEEVENTS:            'events',
  GENERATIONS:             'generations',
  LOCATIONS:               'locations',
  LOCKEDTIMES:             'locked_times',
  OVERLAPGROUPS:           'overlap_groups',
  SETTINGS:                'settings',
  EMPLOYEES:               'users',
  USERS:                   'users',
  OVERVIEW:                'overview',
  STATISTICS:              'statistics',
  TIMEFRAMES:              'lock',
  PUBLIC:                  'public',
  SCHEMA:                  'schemas',
  VERIFY:                  'verify',
  PARTNER:                 'partner',
  ADMIN:                   'admin',
  ADMIN_COMPANIES:         'companies',
  COMPANIES:               'companies',
  COMPANY:                 '',
  JOBS:                    'jobs',
  DEMO:                    'demo',
  INTEGRATIONS:            'integrations',
  CONNECTIONS:             'connections',
  FILE_CONVERSION:         'file-conversion',
  EXCEPTIONS:              'exceptions',
} as const;

export function pathFactory (
  base:    keyof typeof apiConstants,
  ...path: string[]
): string {
  return [
    ...apiConstants[base] == '' ? []: [apiConstants[base]],
    ...path
  ].join('/');
}
