<span class="value">
  @if (primaryUnit === 'hrs') {
    {{ primaryValue }} {{ 'common.hours_short' | translate }}
  } @else if (primaryUnit === 'min/week') {
    {{ primaryValue }} {{ 'common.minutes-per-week_short' | translate }}
  }
  @else {
    <span class="empty">-</span>
  }
</span>

<span class="secondary">
  @if (secondaryUnit === 'hrs') {
    ({{ secondaryValue }} {{ 'common.hours_short' | translate }})
  } @else if (secondaryUnit === 'min/week') {
    ({{ secondaryValue }} {{ 'common.minutes-per-week_short' | translate }})
  }
</span>