import { Component,
         ElementRef,
         OnInit,
         TemplateRef,
         ViewContainerRef                } from '@angular/core';
import { TemplatePortal                  } from '@angular/cdk/portal';
import { Subject                         } from 'rxjs';

import { TooltipTheme,
         TooltipPosition,
         defaultTooltipPosition,
         defaultTooltipTheme             } from './tooltip.directive';

@Component({
  selector: 'tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements OnInit {
  public position:  TooltipPosition = defaultTooltipPosition;
  public theme:     TooltipTheme    = defaultTooltipTheme;
  public tooltip:   string          = '';
  public hasAction: boolean         = false;
  public visible:   boolean         = false;
  public center:    number          = 0;
  public top:       number          = 0;
  protected _onAction               = new Subject<void>();

  set content(template: TemplatePortal) {
    this._portal = template;
  }
  protected _content: TemplateRef<unknown>;
  protected _portal:  TemplatePortal;

  constructor(protected _elementRef:       ElementRef,
              protected _viewContainerRef: ViewContainerRef) {}

  ngOnInit(): void {}

  get width(): number {
    return this._elementRef.nativeElement.children[0]?.getBoundingClientRect().width;
  }

  get height(): number {
    return this._elementRef.nativeElement.children[0]?.getBoundingClientRect().height;
  }

  get boundingClientRect(): ClientRect {
    return this._elementRef.nativeElement.children[0]?.getBoundingClientRect();
  }

  get onAction() { return this._onAction.asObservable() }

  ngOnDestroy() {
    this._onAction.complete();
  }
}