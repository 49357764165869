<h1 mat-dialog-title class="header">
  {{ 'shared.dialogs.confirm-action.title' | translate }}
</h1>

<mat-dialog-actions class="actions-content">
  <button mat-button
          color="primary"
          mat-dialog-close
          class="cancel">
    {{ 'common.cancel' | translate }}
  </button>
  <button mat-raised-button
          color="primary"
          class="delete"
          (click)="confirm()">
    {{ 'common.yes' | translate }}
  </button>
</mat-dialog-actions>