
<h1 mat-dialog-title>{{'shared.dialogs.upload-file.label' | translate}}</h1>

<mat-dialog-content class="dark-scrollbar no-padding position-relative">
  <app-loading [loading]="uploading()"></app-loading>

  <mat-stepper #stepper linear
    [orientation]="(stepperOrientation | async) ?? 'horizontal'"
    [class.disabled]="uploading"
  >

    <mat-step label="{{ 'shared.dialogs.upload-file.select-file.label' | translate }}" #step>
      <div class="wrapper">
        <app-select-file #file
          (onSelect)="selectFile()"
        ></app-select-file>
      </div>
    </mat-step>

    <mat-step label="{{ 'shared.dialogs.upload-file.select-map.label' | translate }}">
      <div class="wrapper">
        <app-select-map #map
          [input]="(file.selected | async)?.content"
          (onSelect)="selectMap()"
        ></app-select-map>
      </div>
    </mat-step>

    <mat-step label="{{ 'shared.dialogs.upload-file.configure.label' | translate }}">
      <div class="wrapper">
        <app-configure #configure
          [mapName]="(map.selected | async)?.name"
          [input]="(file.selected | async)?.content"
          (validChange)="tryComplete(2, $event)"
        ></app-configure>
      </div>
    </mat-step>

    <mat-step label="{{ 'shared.dialogs.upload-file.finalize.label' | translate }}">
      <div class="wrapper">
        <app-finalize #finalize
          [active]="stepper.selectedIndex == 3"
          [file]="file.selected | async"
          [division]="configure.division | async"
          [options]="configure.options | async"
          [map]="map.selected | async">
        </app-finalize>
      </div>
    </mat-step>

  </mat-stepper>
</mat-dialog-content>


<mat-dialog-actions>
  <button mat-button
    [disabled]="stepper.selectedIndex == 0"
    (click)="stepper.previous()" (keydown.enter)="stepper.previous()"
  >
    {{ 'common.back' | translate }}
  </button>
  <span class="spacer"></span>
  <button mat-button mat-dialog-close>{{ 'common.cancel' | translate }}</button>
  <button mat-button color="primary"
    *ngIf="hasNextStep | async; else submit"
    [disabled]="allowNext | async | negate"
    (click)="stepper.next()" (keydown.enter)="stepper.next()"
  >
    {{ 'common.next' | translate }}
  </button>
  <ng-template #submit>
    <button mat-button color="primary"
      [disabled]="uploading() || (finalize?.error | async) || (finalize?.output | async | negate)"
      (click)="upload($event)" (keydown.enter)="upload($event)"
    >
      {{ 'common.upload' | translate }}
    </button>
  </ng-template>
</mat-dialog-actions>
