import { BehaviorSubject,
         combineLatest,
         debounceTime,
         map,
         Observable,
         shareReplay,
         startWith,
         Subject,
         switchMap,
         takeUntil                    } from 'rxjs';
import { AfterViewInit,
         Component,
         ElementRef,
         Inject,
         OnDestroy,
         ViewChild                    } from '@angular/core';
import { FormArray,
         FormControl,
         FormGroup                    } from '@angular/forms';
import { MatChipInputEvent            } from '@angular/material/chips';
import { MatDialogRef,
         MAT_DIALOG_DATA              } from '@angular/material/dialog';
import { COMMA,
         ENTER                        } from '@angular/cdk/keycodes';
import _                                from 'lodash';
import $                                from 'jquery';

import { EnvironmentService,
         SourceService                } from '@app/core';
import { Util                         } from '@app/common';
import { Populated  as P,
         Serialized as S              } from '@app/shared/interfaces';
import { inAnimation,
         inOutAnimation               } from '@app/shared/animations';
import { CourseForm,
         PasteData,
         toMultipleOf5                } from './components/form/form.component';
import { ExcelComponent               } from './components/excel/excel.component';

export type InData    = { did: string; };
export type Option    = { id: string; displayName?: string };
export type AllOption = { all: true };

type _Course = Omit<S.courses, 'id' | 'belongsTo' | 'events' | 'groups' | 'teachers'> & {
  events?:   Pick<P.event, 'preferredDuration'>[];
  groups?:   { to: Pick<P.group,   'id'> }[];
  teachers?: { to: Pick<P.teacher, 'id'> }[];
}

type Key = 'classes' | 'otherGroups' | 'teachers';

type Select = {
  key:        Key;
  collection: Util.Types.Collection;
  label:      string;
  options$:   BehaviorSubject<Option[]>;
  selected$:  BehaviorSubject<(Option | AllOption)[]>;
};


function compare (a: Option, b: Option) {
  if ( ! a.displayName && ! b.displayName) return 0;
  if ( ! a.displayName) return -1;
  if ( ! b.displayName) return 1;
  return a.displayName.localeCompare(b.displayName);
}

@Component({
  templateUrl: './create-courses.component.html',
  styleUrls: ['./create-courses.component.scss'],
  providers: [
    SourceService
  ],
  animations: [
    inOutAnimation,
    inAnimation
  ]
})
export class CreateCoursesComponent implements AfterViewInit, OnDestroy {
  private readonly onDestroy = new Subject<void>();

  protected did: string;
  protected type: 'school' | 'sports_facility' = 'school';
  public tabIndex: number = 0 satisfies 0 | 1 | 2;
  protected readonly onTabChange = new BehaviorSubject(this.tabIndex);

  protected readonly manualFormArray = new FormArray<CourseForm>([this.initializeEmptyForm()]);
  protected readonly bulkFormArray   = new FormArray<CourseForm>([this.initializeEmptyForm()]);
  protected readonly numCourses: Observable<number>;

  protected readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  @ViewChild('entityInput') entityInput: ElementRef<HTMLInputElement>;
  protected readonly entityCtrl = new FormControl<string | P.group>('');

  protected readonly onSelectedSubjects = new BehaviorSubject<string[]>([]);

  @ViewChild(ExcelComponent) excelComponent: ExcelComponent | undefined;
  protected numExcelCourses = new BehaviorSubject<number>(0);

  protected readonly select: Record<Key, Select> = {
    classes: {
      key:        'classes',
      collection: 'groups',
      label:      'common.the_groups.species.class',
      options$:   new BehaviorSubject<Option[]>([]),
      selected$:  new BehaviorSubject<(Option | AllOption)[]>([])
    },
    otherGroups: {
      key:        'otherGroups',
      collection: 'groups',
      label:      'common.the_groups.species.none',
      options$:   new BehaviorSubject<Option[]>([]),
      selected$:  new BehaviorSubject<(Option | AllOption)[]>([])
    },
    teachers: {
      key:        'teachers',
      collection: 'teachers',
      label:      'common.the_teachers',
      options$:   new BehaviorSubject<Option[]>([]),
      selected$:  new BehaviorSubject<(Option | AllOption)[]>([])
    }
  };

  protected totNumOptions = new BehaviorSubject<number | null>(null);

  protected readonly all: AllOption = { all: true };

  constructor (
    private _source:    SourceService,
    private _env:       EnvironmentService,
    private _dialogRef: MatDialogRef<CreateCoursesComponent>,
    @Inject(MAT_DIALOG_DATA)
    { did }: InData
  ) {
    this.did  = did;
    this.type = this._env.organizationType ?? 'school';
    this.get();

    const numSimple = this.manualFormArray.valueChanges
    .pipe(
      debounceTime(100),
      map(x => x
        .filter(x => Object.values(x).some(x => Array.isArray(x) ? x.length : !! x))
        .length
      ),
      startWith(0),
      shareReplay(1)
    );
    const numAdvanced = this.bulkFormArray.valueChanges
    .pipe(
      debounceTime(100),
      map(x => x
        .filter(x => Object.values(x).some(x => Array.isArray(x) ? x.length : !! x))
        .length
      ),
      startWith(0),
      shareReplay(1)
    );
    this.numCourses = this.onTabChange
    .pipe(
      switchMap(x => {
        if (x == 0) return numSimple;

        if (x == 1) {
          if (this.type == 'school') {
            return combineLatest([numAdvanced, this.select.classes.selected$, this.select.otherGroups.selected$, this.select.teachers.selected$])
            .pipe(
              map(([rows, classes, otherGroups, teachers]) => {
                const numSelected = classes    .filter(x => x !== this.all).length
                                  + otherGroups.filter(x => x !== this.all).length
                                  + teachers   .filter(x => x !== this.all).length;
                return rows * numSelected;
              })
            );
          } else {
            return combineLatest([numAdvanced, this.onSelectedSubjects]).pipe(map(([a, b]) => a * b.length));
          }
        }

        return this.numExcelCourses;
      })
    );
  }

  ngAfterViewInit () {
    this.excelComponent?.numCourses
    .pipe(takeUntil(this.onDestroy))
    .subscribe(this.numExcelCourses);
  }

  ngOnDestroy () {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  private initializeEmptyForm (): CourseForm {
    return new FormGroup({
      ids:                new FormControl<string | null>(null),
      displayName:        new FormControl<string | null>(null),
      subject:            new FormControl<string | null>(null),
      plannedDuration:    new FormControl<string | null>(null),
      events:             new FormControl<number[]>([], { nonNullable: true })
    });
  }


  private async get () {

    if (this.type != 'school') return;

    await this._source.groupBy({
      did:         this.did,
      collections: ['groups', 'teachers']
    });

    // prepare the select option sources
    this._source.getPopulatedTeachers({ did: this.did, onDestroy: this.onDestroy })       .pipe(takeUntil(this.onDestroy), map(x => x.sort(compare))).subscribe(this.select.teachers.options$);
    const groups$ = this._source.getPopulatedGroups({ did: this.did, onDestroy: this.onDestroy })
    groups$.pipe(map(x => x.filter(x => x.species == 'class'))).pipe(takeUntil(this.onDestroy), map(x => x.sort(compare))).subscribe(this.select.classes.options$);
    groups$.pipe(map(x => x.filter(x => x.species != 'class'))).pipe(takeUntil(this.onDestroy), map(x => x.sort(compare))).subscribe(this.select.otherGroups.options$);

    // check number of selectable
    combineLatest(Object.values(this.select).map(x => x.options$))
    .pipe(
      takeUntil(this.onDestroy),
      map(x => x.map(x => x.length).reduce((acc, x) => acc + x, 0))
    )
    .subscribe(this.totNumOptions);
  }


  protected selectToggle (key: Key, newSelected: (Option | AllOption)[]) {
    const val           = this.select[key].selected$.value;
    const options       = this.select[key].options$.value;
    const allWasChecked = val.includes(this.all);
    const allIsChecked  = newSelected.includes(this.all);

    let next: (Option | AllOption)[];
    if ( ! allWasChecked && allIsChecked) {
      // toggle all was checked
      next = [this.all, ...options];
    }
    else if (allWasChecked && ! allIsChecked) {
      // toggle all was unchecked
      next = [];
    }
    else {
      // check if all options apart from "all" are checked
      const _newSelected = newSelected.filter((x): x is Exclude<typeof x, AllOption> => x !== this.all);
      const newIds = _.sortBy(_newSelected.map(x => x.id));
      const allIds = _.sortBy(options     .map(x => x.id));
      if (_.isEqual(newIds, allIds)) next = [this.all, ..._newSelected];
      else                           next = _newSelected;
    }
    this.select[key].selected$.next(next);
  }

  protected removeSubject (val: string) {
    const selectedEntities = this.onSelectedSubjects.value;
    this.onSelectedSubjects.next(selectedEntities.filter(entity => entity != val));
  }

  protected addSubject (event: MatChipInputEvent) {
    const val = event.value.trim();
    if ( ! val) return;

    const next = _([...this.onSelectedSubjects.value, event.value])
      .sort()
      .sortedUniq()
      .value();
    this.onSelectedSubjects.next(next);

    // reset input after selection
    this.entityInput.nativeElement.value = '';
    this.entityCtrl.setValue(null);
  }

  protected remove (array: FormArray<CourseForm>, index: number) {
    if (array.length > 1) array.removeAt(index);
    else                  array.at(index)?.reset();
  }

  protected addCourse (event: PointerEvent | MouseEvent) {
    const array = this.tabIndex == 0 ? this.manualFormArray : this.bulkFormArray;
    array.push(this.initializeEmptyForm());

    // focus on the first input of the new form
    setTimeout(() => {
      if ( ! event.target) return;
      const target = $(event.target).closest('mat-dialog-container').find('mat-tab-body').eq(this.tabIndex).find('app-form').last().find('input').first();
      if ( ! target) return;
      target.trigger('focus');
    });
  }

  protected createCourses (type: 'simple' | 'advanced') {
    let courses = new Array<_Course>();

    if (type == 'simple') {
      // simply add as is
      this.manualFormArray.value.forEach(v => {
        const ids                = v.ids                                                        ?? undefined;
        const displayName        = v.displayName                                                ?? undefined;
        const subject            = v.subject                                                    ?? undefined;
        const plannedDuration    = v.plannedDuration                                            ?? undefined;
        const events             = v.events?.map(x => ({ preferredDuration: x, parked: true, visible: true })) ?? undefined;   // need to set the parked and visible flag
        courses.push({ ids, displayName, subject, plannedDuration, events });
      });
    } else {
      if (this.type == 'school') {
        const classes     = this.select.classes    .selected$.value.filter((x): x is Option => x !== this.all).map(x => ({ collection: 'groups'   as const, ...x }));
        const otherGroups = this.select.otherGroups.selected$.value.filter((x): x is Option => x !== this.all).map(x => ({ collection: 'groups'   as const, ...x }));
        const teachers    = this.select.teachers   .selected$.value.filter((x): x is Option => x !== this.all).map(x => ({ collection: 'teachers' as const, ...x }));
        [...classes, ...otherGroups, ...teachers].forEach(selected => {
          this.bulkFormArray.value.forEach(row => {
            const subject            = row.subject ?? undefined;
            const displayName        = selected.displayName && subject ? `${selected.displayName} - ${subject}` : undefined;
            const groups             = selected.collection == 'groups'   ? [{ to: { id: selected.id } }] : undefined;
            const teachers           = selected.collection == 'teachers' ? [{ to: { id: selected.id } }] : undefined;
            const plannedDuration    = row.plannedDuration ?? undefined;
            const events             = row.events?.map(x => ({ preferredDuration: x, parked: true, visible: true })) ?? undefined;   // need to set the parked and visible flag
            courses.push({ displayName, subject, plannedDuration, events, groups, teachers });
          });
        });
      } else {
        this.onSelectedSubjects.value.forEach(subject => {
          this.bulkFormArray.value.forEach(row => {
            const displayName        = row.displayName     ?? undefined;
            const plannedDuration    = row.plannedDuration ?? undefined;
            const events             = row.events?.map(x => ({ preferredDuration: x, parked: true, visible: true })) ?? undefined;   // need to set the parked and visible flag
            courses.push({ displayName, subject, plannedDuration, events });
          });
        });
      }
    }

    // remove empty forms
    courses = courses.filter(x => Object.values(x).some(x => Array.isArray(x) ? x.length : !! x));
    if (courses.length) {
      this._source.set({ did: this.did, collection: 'courses'}, courses);
      this._dialogRef.close();
    }
  }

  protected paste (array: FormArray<CourseForm>,  index: number, { key, values}: PasteData) {
    // try merge into already existing forms
    values.forEach((str, i) => {
      // if the form exist overwrite the corresponding value, otherwise create a new form
      if ( ! array.at(index + i)) array.push(this.initializeEmptyForm());
      if (key == 'events') {
        // need to parse the string into an array of numbers
        const arr = str.split(',').map(x => toMultipleOf5(x)).filter(Boolean);
        array.controls[index + i].controls[key].setValue(arr);
      } else {
        array.controls[index + i].controls[key].setValue(str as any);
      }
    });
  }

  protected submit (event: Event) {
    // ensure that the for is not submitted twice by the same event (keydown and click)
    event.preventDefault();

    if (this.tabIndex == 0) return this.createCourses('simple');
    if (this.tabIndex == 1) return this.createCourses('advanced');
    this.excelComponent?.submit();
    this._dialogRef.close();
  }

}