import { NgModule                        } from '@angular/core';
import { CommonModule                    } from '@angular/common';

import { TranslationModule               } from 'app/core/translate/translate.module';

import { AppCommonModule                 } from 'app/common/common.module';

import { PipesModule                     } from 'app/shared/pipes/pipes.module';

import { DirectivesModule                } from 'app/shared/directives/directives.module';

import { PopoverComponent                } from './popover.component';
import { DestructPipe                    } from './popover.pipe';

@NgModule({
  declarations: [
    PopoverComponent,
    DestructPipe
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    TranslationModule,
    DirectivesModule,
    PipesModule
  ]
})
export class PopoverModule { }