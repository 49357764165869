<form [formGroup]="form"
      (keydown.enter)="$event.preventDefault()"
      (ngSubmit)="submit()">
  <h1 mat-dialog-title>{{ 'shared.dialogs.sync.title' | translate }}</h1>

  <mat-slide-toggle (change)="showAdvanced.next($event.checked)"
                    class="advanced royal-text-secondary"
                    labelPosition="before">
    {{ 'common.advanced_settings' | translate }}
  </mat-slide-toggle>

  <mat-dialog-content class="dark-vertical-scrollbar">
    <fieldset *ngIf="! data.integrationType">
      <legend>{{ 'shared.dialogs.sync.platform' | translate }}</legend>

      <mat-chip-listbox formControlName="partner">
        <mat-chip-option *ngFor="let integration of integrations"
                        [value]="integration.value">
          <img matChipTrailingIcon [src]="integration.icon"/>
          {{ integration.name }}
        </mat-chip-option>
      </mat-chip-listbox>
    </fieldset>

    <fieldset *ngIf="! data.division">
      <legend>{{ 'common.create_schedule' | translate }}</legend>

      <app-division-form displayName start end></app-division-form>
    </fieldset>

    <fieldset>
      <legend>{{ 'shared.dialogs.sync.collections' | translate }}</legend>

      <mat-selection-list formControlName="collections">
        <mat-list-option *ngFor="let collection of collections | filterCollections: form.value.partner"
                         [value]="collection">
          <mat-icon matListItemIcon>{{ collection | collectionIcon }}</mat-icon>
          <div matListItemLine>
            {{ ('common.' + collection) | translate }}
          </div>
        </mat-list-option>
      </mat-selection-list>
    </fieldset>

    <fieldset *ngIf="showValidator | async">
      <legend>{{ 'shared.dialogs.sync.validation' | translate }}</legend>
      <app-sync-validator #validator
                          [did]="data.division?.id"
                          [partner]="form.value.partner"
                          [collections]="collections">
      </app-sync-validator>
    </fieldset>

    <fieldset *ngIf="showAdvanced | async" class="animate">
      <legend>{{ 'shared.dialogs.sync.modified' | translate }}</legend>

      <mat-radio-group formControlName="modifiedType">
        <mat-radio-button [value]="'lastSync'"
                          *ngIf="data.division"
                          [disabled]="! data.division.syncedAt">
          {{ 'shared.dialogs.sync.last_sync' | translate }}
          <ng-container *ngIf="data.division?.syncedAt">
            (<i>{{ data.division.syncedAt | date:'medium':undefined:translate.currentLanguage?.id }}</i>)
          </ng-container>
        </mat-radio-button>
        <mat-radio-button [value]="null">
          {{ 'common.all' | translate }}
        </mat-radio-button>
        <mat-radio-button [value]="'custom'">
          {{ 'common.select' | translate }}
        </mat-radio-button>
      </mat-radio-group>

      <mat-form-field>
        <mat-label>{{ 'common.modified_after' | translate }}</mat-label>
        <input matInput
               formControlName="modifiedAfter"
               [min]="data.division?.start"
               [max]="data.division?.end"
               [matDatepicker]="modifiedAfter">
        <mat-hint>MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle matIconSuffix [for]="modifiedAfter"></mat-datepicker-toggle>
        <mat-datepicker #modifiedAfter></mat-datepicker>
      </mat-form-field>
    </fieldset>

    <fieldset *ngIf="showAdvanced | async" class="animate">
      <legend>{{ 'shared.dialogs.sync.time' | translate }}</legend>

      <div [formGroup]="form.controls.interval">
        <mat-slide-toggle formControlName="useDefault" toggled>
          {{ 'common.useDefault' | translate }}
          <ng-container *ngIf="data.division">
            (<i>{{ data.division.start | toDate | date:'mediumDate':undefined:translate.currentLanguage?.id }}</i>
            -
            <i>{{ data.division.end | toDate | date:'mediumDate':undefined:translate.currentLanguage?.id }}</i>)
          </ng-container>
        </mat-slide-toggle>
      </div>

      <mat-form-field>
        <mat-date-range-input [formGroup]="form.controls.interval"
                              [min]="data.division?.start"
                              [max]="data.division?.end"
                              [rangePicker]="picker">
          <input matStartDate
                 formControlName="start"
                 placeholder="Start date">
          <input matEndDate
                 formControlName="end"
                 placeholder="End date">
        </mat-date-range-input>
        <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>

        <mat-error *ngIf="form.controls.interval.controls.start.hasError('matStartDateInvalid')">{{ 'validators.invalidDate' | translate }}</mat-error>
        <mat-error *ngIf="form.controls.interval.controls.end.hasError('matEndDateInvalid')">{{ 'validators.invalidDate' | translate }}</mat-error>
      </mat-form-field>
    </fieldset>

    <div class="alert error" *ngIf="(error | async) != null">
      <button mat-icon-button
              type="button"
              (click)="error.next(null)">
        <mat-icon>close</mat-icon>
      </button>
      <h4 class="title">{{ (error | async) | syncError | translate }}</h4>
      <mat-list *ngIf="errorMessages | async as messages">
        <mat-list-item *ngFor="let message of messages">
          <mat-icon class="error-text" matListItemIcon>error_outline</mat-icon>
          <p class="error-text">{{ message }}</p>
        </mat-list-item>
      </mat-list>
      <p class="description">{{ 'common.error_code' | translate }}: {{ (error | async)  }}</p>
    </div>

    <div class="alert success" *ngIf="(syncedData | async) as response">
      <button mat-icon-button
              type="button"
              (click)="syncedData.next(null)">
        <mat-icon>close</mat-icon>
      </button>
      @switch(response.action) {
        @case ('pull') {
          <mat-list>
            <div mat-subheader>{{ 'shared.dialogs.sync.synced_data' | translate }}</div>
            <mat-list-item *ngFor="let data of response.data">
              <mat-icon matListItemIcon>{{ data.coll | collectionIcon }}</mat-icon>
              <div matListItemLine>
                {{ data.count }} {{ ('common.' + data.coll) | translate }}
              </div>
            </mat-list-item>
          </mat-list>
          <button mat-button
                color="none"
                type="button"
                (click)="openSchedule()">
          {{ 'common.goto_schedule' | translate }}
        </button>
        }
        @case ('push') {
          <h3 class="title">{{ 'shared.dialogs.sync.push_success.title' | translate }}</h3>
          <p class="description">{{ 'shared.dialogs.sync.push_success.description' | translate }}</p>
        }
      }
    </div>

  </mat-dialog-content>

  <app-loading *ngIf="!! (submitted | async)"
               [loading]="!! (submitted | async)"
               [description]="'common.synchronising' | translate"></app-loading>

  <mat-dialog-actions align="end">
    <button
      mat-button
      mat-dialog-close
      type="button">
      {{ 'common.cancel' | translate }}
    </button>

    <button mat-button
            [disabled]="! ((! divisionForm || divisionForm.form.valid) && form.valid && (! validator || validator.valid())) || (submitted | async)"
            type="submit"
            color="primary">
      {{ 'common.sync' | translate }}
    </button>
  </mat-dialog-actions>
</form>
