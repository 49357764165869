import { CalendarComponent       } from './calendar.component';
import $                           from 'jquery';
import { EventApi                } from '@fullcalendar/core';

import { Options as PanelOptions } from '../popover/popover.types';


export function openPopOver (
  this:    CalendarComponent,
  event:   EventApi,
  jsEvent: MouseEvent
): void {
  ////
  //// find event container
  ////
  let container = $(jsEvent.target!).closest(".fc-event");

  ////
  //// compute event boundaries
  ////
  const container_top    = container.offset()!.top;
  const container_bottom = container_top + container.height()!;
  const container_left   = container.offset()!.left;
  const container_right  = container_left + container.width()!;

  ////
  //// open popover
  ////
  const paneOptions: PanelOptions = {
    source_top:    container_top,
    source_bottom: container_bottom,
    source_left:   container_left,
    source_right:  container_right,

    exist: true,
    edit:  this.options.editMode,
    event: this.getEventSource(event)
  }
  this._popoverService.setViewContainerRef(this._viewContainerRef);
  this._popoverService
  .open(paneOptions)
  .then(() => this._selectEvent(null))  // deselect event when closed
  .catch(() => { });
}