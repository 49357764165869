<div class="label" [matTooltip]="tooltip" matTooltipClass="new-line-tooltip"
     [class.multiple-lines]="type">
  <div *ngIf="type == 'single'; else multiple">
    <ng-container *ngIf="isStringArray(label) as val">
        <div class="row">
          <div class="column">
            <div class="container">
              <div class="nowrap">{{ val[0] }}</div>
              <div class="nowrap">{{ val[1] }}</div>
              <div class="nowrap">{{ val[2] }}</div>
            </div>
          </div>
        </div>
    </ng-container>
  </div>

  <ng-template #multiple>
    <div *ngIf="type == 'multiple'; else other" class="row">
      <ng-container *ngIf="isMultipleDayArray(this.label) as val">
        <ng-container *ngFor="let x of val">
          <div class="column max-width">
            <div class="container">
              <ng-container *ngIf="x">
                <div class="nowrap">{{ 'common.day_short_' + x.day | translate }}</div>
                <div class="nowrap">{{ x.start }}</div>
                <div class="nowrap">{{ x.end }}</div>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </ng-template>

  <ng-template #other>
    {{ label || voidText }}
  </ng-template>

</div>
