<!-- force rerender on stateChanges.next() otherwise it is not guaranteed to show the latest values due to some lazy rendering-->
{{ stateChanges | async }}

<div class="wrapper"
  [class.disabled]="disabled"
  (menuClosed)="closed()"
  (menuOpened)="opened()"
  [matMenuTriggerFor]="menu"
>
  <app-form-field-tags-display-value [value]="value$ | async"></app-form-field-tags-display-value>
</div>

<mat-menu yPosition="below" #menu="matMenu" [overlapTrigger]="false" class="planned-duration-panel no-vertical-padding">
  <ng-template matMenuContent>

    @if ( ! hideToolbar) {
      <div class="toolbar" (click)="$event.stopPropagation()">
        <div class="row">
          @if (valueChanged$ | async) {
            <button mat-stroked-button
              class="action reset"
              color="primary"
              (click)="resetValue()"
            >
              <div>
                <span>{{ 'common.restore' | translate }}</span>
                <mat-icon class="royal-icon">undo</mat-icon>
              </div>
            </button>
          }

          <span class="spacer"></span>

          @if (nullable) {
            <button mat-stroked-button
              class="action reset"
              color="primary"
              (click)="clearValue()"
            >
              <div>
                <span>{{ 'common.reset' | translate }}</span>
              </div>
            </button>
          }

        </div>
      </div>
    }

    <div class="content" (click)="$event.stopPropagation()">

      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <mat-chip-grid #chipGrid>
          @for (tag of value$ | async; track $index) {
            <mat-chip-row (removed)="remove(tag)" [class.partial]="tag.partial">
              {{ tag.value }}
              <button matChipRemove>
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip-row>
          }
        </mat-chip-grid>
        <input matInput
          [placeholder]="'shared.form-fields.tags.input.placeholder' | translate"
          [formControl]="ctrl"
          [matChipInputFor]="chipGrid"
          [matAutocomplete]="auto"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          (matChipInputTokenEnd)="add($event)"
          (keydown)="keydown($event)"
        />
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)" class="dark-scrollbar">
          @for (x of suggestedTags$ | async; track $index) {
            <mat-option [value]="x">
              {{ x.value }}
            </mat-option>
          }
        </mat-autocomplete>
      </mat-form-field>

    </div>

  </ng-template>
</mat-menu>