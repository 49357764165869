import { Observable                      } from 'rxjs';
import { proxy                           } from 'comlink';

import { Options                         } from '../source.interface';

import { SourceCore                      } from '../source.core';

type ZoneAwarePromise = Promise<Observable<any>>;

export function onChange(this: SourceCore, _options: Options.OnChange): ReturnType<typeof this._systemCore.onChange> {

 const subject = this._systemCore.onChange(_options);

  if (subject && ! (subject instanceof Observable)) {
    return new Observable<any>((observer) => {
      (subject as ZoneAwarePromise).then((ob: Observable<any>) => {
        ob.subscribe(proxy((a: any) => {
        observer.next(a)
      }));
      })
    })
  }

  return subject;
}