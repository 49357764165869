<h1 mat-dialog-title class="royal-text-primary">{{ 'shared.dialogs.version.title' | translate }}</h1>

<mat-dialog-content class="mat-typography">
  <p>{{ 'shared.dialogs.version.description' | translate }}</p>
  <p [innerHTML]="'shared.dialogs.version.description_hard_reload' | translate "></p>
  <div class="grid">
    <span class="royal-text-secondary">{{ 'shared.dialogs.version.current_version' | translate }}</span>
    <span>{{ currentVersion }}</span>
    <span class="royal-text-secondary">{{ 'shared.dialogs.version.latest_version' | translate }}</span>
    <span>{{ latestVersion }}</span>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="{ skip:   true }">{{ 'common.ignore' | translate }}</button>
  <button mat-button [mat-dialog-close]="{ reload: true }" cdkFocusInitial>{{ 'common.reload' | translate }}</button>
</mat-dialog-actions>