import _                           from 'lodash';
import { BehaviorSubject,
         combineLatest           } from 'rxjs';
import { filter,
         map                     } from 'rxjs/operators';
import moment                      from 'moment';
import { Component,
         Input,
         DestroyRef,
         inject                  } from '@angular/core';
import { takeUntilDestroyed      } from '@angular/core/rxjs-interop';
import { SafeHtml                } from '@angular/platform-browser';
import { computeEventCenter,
         intervalsToStartTimes,
         _uniteIntervals         } from '@royalschedule/input-verifier-v4';

import { Populated as P          } from 'app/shared/interfaces';
import { InputAnalysisService    } from 'app/shared/services';
import { LoggerService,
         SourceService           } from 'app/core';
import { IntervalAnalysis        } from 'app/shared/services/input-analysis/types';

const dt = 5;
const dtsPerDay = 24*60/dt;


function parseIntervals (
  val:        P.group['intervals'],
  days:       P.group['days'],
  defaultVal: { start: string, end: string },
  numDays:    number
): { beg:number, end: number, binary?: boolean }[][] {
  let out: { beg: number, end: number, binary?: boolean }[][] = [];

  for (let d = 0; d < numDays; d++) {
    // if the day is not allowed, skip
    if (days && days.length && ! days[d]) {
      out.push([ ]);
      continue;
    }

    const x = val ? val[d in val ? d : 0] : defaultVal;

    const beg = moment.utc(x.start).get('hour')*60/dt + moment.utc(x.start).get('minute')/dt;
    const end = moment.utc(x.end  ).get('hour')*60/dt + moment.utc(x.end  ).get('minute')/dt;
    out.push([{ beg, end }]);
  }

  return out;
}


const entitiesLabels: Record<keyof Entities, string> = {
  event:             'inputAnalysis.modules.event intervals.this event',
  locations:         'common.locations',
  teachers:          'common.teachers',
  persons:           'common.persons',
  groups:            'common.groups',
  linkedEvents:      'common.linked_events',
  interactingEvents: 'inputAnalysis.modules.event intervals.affecting_events'
};

function flatten (intervals: Interval[][]): Interval[] {
  return intervals.flatMap((x, d) => x.map(i => ({
    beg:    d*dtsPerDay + i.beg,
    end:    d*dtsPerDay + i.end,
    binary: i.binary
  })));
}

function setColor (intervals: Interval[], color: string): Interval[] {
  return intervals.map(i => ({ ...i, color }));
}

function inverse (
  intervals: Interval[],
  numDays:   number
): Interval[] {
  let inv: Interval[] = [];

  let beg = 0;
  intervals.forEach(i => {
    inv.push({ beg, end: i.beg });
    beg = i.end;
  });
  inv.push({ beg, end: numDays*dtsPerDay });

  // remove intervals with zero or negative length
  return inv.filter(i => i.end - i.beg > 0);
}

function fromTimeFrame (
  timeFrame:       Interval[][],
  size:            number,
  maxSizeVariance: number
) {
  // compute start times from the time frame
  const startTimes = intervalsToStartTimes(timeFrame, size, !! maxSizeVariance);

  // the permitted intervals are those which the event is not overflowing
  const permittedIntervals = flatten(startTimes).map(x => ({ beg: x.beg, end: x.end + size }));

  // keep all original binary intervals
  const binaryIntervals = flatten(timeFrame).filter(x => x.binary);

  return { permittedIntervals, binaryIntervals, startTimes };
}

type Interval = {
  beg:    number;
  end:    number;
  color?: string;
  binary?: boolean;
}

type Entity = P.location | P.teacher | P.person | P.group | P.event | P.lockedTime;


type IfEvent<T extends Entity, V> = T extends P.event | P.lockedTime ? V : { };

type EntityHolder<T extends Entity> = {
  entity:             T;
  permittedIntervals: Interval[];
  binaryIntervals:    Interval[];
  startTimes:         Interval[][];

  /** @description if the placeholder event is overflowing the start times of the entity */
  overflowing?: boolean;
} & IfEvent<T, {
  size: number;
}>;

type EntitySetHolder<T extends Entity> = {
  set:          EntityHolder<T>[],

  /** @description if the placeholder event is overflowing the start times of the entity */
  overflowing?: boolean
}

type Entities = {
  event?:             EntityHolder<P.event | P.lockedTime>[];
  teachers?:          EntityHolder<P.teacher>[];
  persons?:           EntityHolder<P.person>[];
  groups?:            EntityHolder<P.group>[];
  linkedEvents?:      EntityHolder<P.event | P.lockedTime>[];
  interactingEvents?: EntityHolder<P.event | P.lockedTime>[];
  locations?:         EntitySetHolder<P.location>[];
};

type Source = {
  locations:   P.location[],
  teachers:    P.teacher[],
  persons:     P.person[],
  groups:      P.group[],
  events:      P.event[],
  lockedTimes: P.lockedTime[],
}

function getEvent (
  val:    string,
  source: Source
): P.event | P.lockedTime | undefined {
  const { is, id } = InputAnalysisService.parseCompositeId(val);

  switch (is) {
    case 'event':      return source.events     .find(x => x.id === id);
    case 'lockedTime': return source.lockedTimes.find(x => x.id === id);
  }

  return undefined;
}

function getGroup (
  val:  string,
  data: Source
): P.teacher[] | P.person[] | P.group[] {
  const { is, id } = InputAnalysisService.parseCompositeId(val);

  if (is === 'teacher') return [data.teachers.find(x => x.id === id)].filter(Boolean);
  if (is === 'person')  return [data.persons .find(x => x.id === id)].filter(Boolean);

  if (is === 'group') {
    const group = data.groups.find(x => x.id === id);
    if ( ! group || group.species == 'class') return [group].filter(Boolean);

    // the group is an UG, thus find all classes from the members
    const classesIds = _.uniq((group.members ?? []).map(x => x.group?.id).filter(Boolean));
    const classes    = data.groups.filter(x => classesIds.includes(x.id));

    return [...classes, group];
  }

  return [];
}


@Component({
  selector: 'app-input-analyzer-event-intervals',
  templateUrl: './event-intervals.component.html',
  styleUrls: ['./event-intervals.component.sass'],
  providers: [SourceService]
})
export class EventIntervalsComponent {
  private readonly destroyRef = inject(DestroyRef);

  protected readonly mainEvent    = new BehaviorSubject<P.event | P.lockedTime | null>(null);
  protected readonly linkedEvents = new BehaviorSubject<(P.event | P.lockedTime)[]>([]);

  protected readonly entities = new BehaviorSubject<Entities>({ });
  protected readonly entitiesArray;



  protected startTimes: Interval[] = [];
  protected intervals:  Interval[] = [];

  protected numDays: number = 5;

  // determines zoom and initial scroll position
  protected frame: { beg: string, end: string } = { beg: '08:00', end: '18:00' };

  constructor (
    protected inputAnalysis: InputAnalysisService,
    private   _logger:       LoggerService,
    private   _source:       SourceService,
  ) {
    // fetch did
    const did = inputAnalysis.did;
    if ( ! did) {
      this._logger.error(new Error(`(EventIntervalsComponent::constructor) did is undefined, probably because the input analyzer is deactivated.`))
      return;
    }

    this._source.groupBy({ did,
      collections: ['settings', 'locations', 'groups', 'teachers', 'persons', 'events', 'lockedTimes']
    })
    .then(() => {

      combineLatest({
        data:        this._onData.pipe(filter(Boolean)),
        source: combineLatest({
          settings:    this._source.getStrictSettings      ({ did, onDestroy: this.destroyRef }),
          locations:   this._source.getPopulatedLocations  ({ did, onDestroy: this.destroyRef }),
          groups:      this._source.getPopulatedGroups     ({ did, onDestroy: this.destroyRef }),
          teachers:    this._source.getPopulatedTeachers   ({ did, onDestroy: this.destroyRef }),
          persons:     this._source.getPopulatedPersons    ({ did, onDestroy: this.destroyRef }),
          events:      this._source.getPopulatedEvents     ({ did, onDestroy: this.destroyRef }),
          lockedTimes: this._source.getPopulatedLockedTimes({ did, onDestroy: this.destroyRef })
        })
      })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(({ data, source }) => {
        const size            = data.event.size;
        const maxSizeVariance = data.event.maxSizeVariance;

        this.numDays = source.settings.numDays!;

        this.frame = {
          beg: source.settings.dayStart!,
          end: source.settings.dayEnd!
        };

        const entities: Entities = { };
        {
          const entity = getEvent(data.event.id, source);
          if (entity) {
            this.mainEvent.next(entity);
            const intervals = flatten(data.event.startTimes).map(x => ({ ...x, end: x.end + size }));
            entities.event = [{ entity, size: size, permittedIntervals: intervals, binaryIntervals: [], startTimes: data.event.startTimes }];
          }
        }

        entities.teachers = [];
        entities.persons  = [];
        entities.groups   = [];
        data.groups.map(x => x[0]).filter(Boolean).forEach(x => {
          getGroup(x.id, source).forEach(entity => {
            if (entity?.is === 'teacher') entities.teachers?.push({ entity, ...fromTimeFrame(x.timeFrame, size, maxSizeVariance) });
            if (entity?.is === 'person')  entities.persons ?.push({ entity, ...fromTimeFrame(x.timeFrame, size, maxSizeVariance) });
            if (entity?.is === 'group') {
              // if the input group was a UG it will result in that group and all classes connected to id
              // (the latter requires us to compute the time frame)
              if (InputAnalysisService.parseCompositeId(x.id).id === entity.id) {
                entities.groups?.push({ entity, ...fromTimeFrame(x.timeFrame, size, maxSizeVariance) });
              } else {
                // compute time
                const defaultInterval = { start: this.frame.beg, end: this.frame.end };
                const tf = parseIntervals(entity.intervals, entity.days, defaultInterval, this.numDays);
                entities.groups?.push({ entity, ...fromTimeFrame(tf, size, maxSizeVariance) });
              }
            }
          });
        });

        entities.locations = [];
        data.dependencies.forEach(_set => {
          const set = _set
            .map(x => {
              const entity = source.locations.find(y => y.id === x.id);
              return entity ? { entity, ...fromTimeFrame(x.timeFrame, size, maxSizeVariance) } : undefined;
            })
            .filter(Boolean);
          entities.locations?.push({ set });
        });

        entities.linkedEvents = [];
        data.linkedEvents.forEach(x => {
          const entity = getEvent(x.id, source);
          if (entity) {
            const intervals = flatten(x.startTimes).map(y => ({ ...y, end: y.end + x.size }));
            entities.linkedEvents?.push({ entity, size: x.size, permittedIntervals: intervals, binaryIntervals: [], startTimes: x.startTimes });
          }
        });
        this.linkedEvents.next(entities.linkedEvents.map(x => x.entity));


        entities.interactingEvents = [];
        data.fixedInteractingEvents.forEach(x => {
          const entity = getEvent(x.id, source);
          if (entity) {
            // inverse the permitted intervals since those are the ones blocking the event
            const intervals = this.inverse(flatten(x.startTimes).map(y => ({ ...y, end: y.end + size })));
            // convert start times from "e" to "event" for which they are overlapping
            const overlappingStartTimes = x.startTimes.map(y => y.map(z =>
              ({ beg: z.beg - size + 1, end: z.end + x.size - 1 })
            ));
            entities.interactingEvents?.push({ entity, size: x.size, permittedIntervals: intervals, binaryIntervals: [], startTimes: overlappingStartTimes });
          }
        });


        // add in the correct order
        this.entities.next(entities);
      });
    });

    this.entitiesArray = this.entities
    .pipe(
      takeUntilDestroyed(this.destroyRef),
      map(x => ([
        x.event             ? { key: entitiesLabels.event,             val: x.event             } : null,
        x.locations         ? { key: entitiesLabels.locations,         val: x.locations         } : null,
        x.teachers          ? { key: entitiesLabels.teachers,          val: x.teachers          } : null,
        x.persons           ? { key: entitiesLabels.persons,           val: x.persons           } : null,
        x.groups            ? { key: entitiesLabels.groups,            val: x.groups            } : null,
        x.linkedEvents      ? { key: entitiesLabels.linkedEvents,      val: x.linkedEvents      } : null,
        x.interactingEvents ? { key: entitiesLabels.interactingEvents, val: x.interactingEvents } : null,
      ].filter(Boolean)))
    )

  }



  @Input()
  description: SafeHtml | undefined;

  @Input()
  set event (event: Pick<P.event | P.lockedTime, 'is' | 'id'>) {
    // fetch result
    const id = InputAnalysisService.prependCollection(event);
    const res = this.inputAnalysis.intervalAnalysis?.find(x => x.event.id == id)
    if ( ! res) {
      this._logger.error(new Error(`interval analysis result not found for event "${id}" of type "${event.is}"`));
      return;
    }
    this._onData.next(res);
  }
  private _onData = new BehaviorSubject<IntervalAnalysis[number] | null>(null);

  protected setSecondaryIntervals<T extends Entity> (
    entities?: EntityHolder<T>[]
  ) {
    // debounce
    clearTimeout(this.debouncer)
    this.debouncer = setTimeout(() => {

      // reset
      if ( ! entities) {
        this.intervals = [];
        return;
      }

      const intervals = entities.map(x => x.permittedIntervals).reduce((acc, x) => _uniteIntervals([acc, x]), []);
      const inverse   = this.inverse(intervals, 'red');
      const binary    = entities.flatMap(x => x.binaryIntervals);
      this.intervals = [...inverse, ...binary];

    }, 10);
  }
  private debouncer: NodeJS.Timeout;


  protected computeOverlapping (event?: { day: number, start: number }) {

    // reset all
    if ( ! event) {
      this.entities.value.event            ?.forEach(x => delete x.overflowing);
      this.entities.value.teachers         ?.forEach(x => delete x.overflowing);
      this.entities.value.persons          ?.forEach(x => delete x.overflowing);
      this.entities.value.groups           ?.forEach(x => delete x.overflowing);
      this.entities.value.linkedEvents     ?.forEach(x => delete x.overflowing);
      this.entities.value.interactingEvents?.forEach(x => delete x.overflowing);
      this.entities.value.locations        ?.forEach(x => x.set.forEach(y => delete y.overflowing));
      this.entities.next(this.entities.value);
      return;
    }

    // loop over all entity holders
    [
      ...this.entities.value.event    ?? [],
      ...this.entities.value.teachers ?? [],
      ...this.entities.value.persons  ?? [],
      ...this.entities.value.groups   ?? [],
      ...this.entities.value.locations?.map(x => x.set).flat() ?? []
    ]
    .forEach(x => {
      const overflow = ! x.startTimes[event.day].some(i => i.beg <= event.start && event.start <= i.end);
      x.overflowing = overflow;
    });

    // need to process linked events separately
    const size = this.entities.value.event?.at(0)?.size ?? 0;
    this.entities.value.linkedEvents?.forEach(x => {
      const offset = computeEventCenter(x.size)[0]- computeEventCenter(size)[0]
      const beg    = event.start - offset;

      const overflow = ! x.startTimes[event.day].some(i => i.beg <= beg && beg <= i.end);
      x.overflowing = overflow;
    });


    // need to process interacting events separately
    this.entities.value.interactingEvents?.forEach(x => {
      const overflow = x.startTimes[event.day].some(i => i.beg <= event.start && event.start <= i.end);
      x.overflowing = overflow;
    });

    // emit
    this.entities.next(this.entities.value);
  }

  private inverse (
    intervals: Interval[],
    color?:    string
  ): Interval[] {
    let inv = inverse(intervals, this.numDays);
    if (color) inv = setColor(inv, color);

    return inv;
  }

  protected isEvent (event: Entity): event is P.event {
    return event?.is == 'event';
  }
  protected isLockedTime (event: Entity): event is P.lockedTime {
    return event?.is == 'lockedTime';
  }
  protected isEventOrLockedTime (event: Entity): event is P.event | P.lockedTime {
    return event?.is == 'event' || event?.is == 'lockedTime';
  }

  protected isNotEmpty<T>(val: T[] | undefined): val is T[] {
    return Array.isArray(val) && val.length > 0;
  }

  protected isKey (key: string): key is keyof Entities {
    return key in this.entities.value;
  }

  protected isSet (val: any): val is EntityHolder<Entity>[] {
    return Array.isArray(val) && val.length > 0 && 'entity' in val[0];
  }

  protected isSetOfSets (val: any): val is EntitySetHolder<P.location>[] {
    return Array.isArray(val) && val.length > 0 && 'set' in val[0];
  }

  protected trackCategory (index: number, item: { key: string }) {
    return item.key
  }
  protected trackEntity (index: number, item: EntityHolder<Entity>) {
    return item.entity.id;
  }

}
