import { Component,
         Input,
         OnDestroy} from '@angular/core';
import moment                              from 'moment';

import { DateService                     } from 'app/shared/services';
import { debounceTime, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-form-field-fixed-start-display-value',
  templateUrl: './display-value.component.html',
  styleUrls: ['./display-value.component.scss']
})
export class DisplayValueComponent implements OnDestroy {

  private onDestroy:     Subject<void> = new Subject<void>();
  private onLabelChange: Subject<void> = new Subject<void>();

  public empty:    boolean;
  public label:    string;

  constructor (private _date: DateService) {

    this.onLabelChange
    .pipe(takeUntil(this.onDestroy))
    .subscribe(() => this._updateLabel());
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  private _updateLabel(): void {
    if (this.value == null || ! this._valid) {
      this.empty   = true;
      this.label   = this.voidText;
    } else {
      this.empty   = false;
      let day      = this._date.getTranslatedDayName(this.value);
      let start    = this._date.fromDate(this.value);
      this.label   = `${ day } ${ start }`;
    }
  }

  @Input()
  get value(): moment.Moment | null { return this._value; }
  set value(value: string | moment.Moment | null | undefined | false) {
    this._value = value ? moment(value) : null;
    this.onLabelChange.next();
  }
  private _value: moment.Moment | null;


  @Input()
  get voidText(): string { return this._voidText; }
  set voidText(value: string) {
    this._voidText = value;
  }
  private _voidText: string = '';

  @Input()
  get valid(): boolean { return this._valid; }
  set valid(value: boolean | undefined) {
    this._valid = value ?? true;
    this.onLabelChange.next();
  }
  private _valid: boolean = true;
}
