<app-loading *ngIf="loading" borderRadius="4px"></app-loading>

<h1 mat-dialog-title class="royal-text-primary">
  {{ (addMode ? 'tables.admin-tables.users.add-edit.title.add' : 'tables.admin-tables.users.add-edit.title.edit') | translate }}
</h1>

<mat-dialog-content>
  <form [formGroup]="form" id="form" (ngSubmit)="submit()">

    <div class="form-field-wrapper">
      <mat-form-field class="form-field" appearance="outline">
        <mat-label class="form-label">{{ 'tables.admin-tables.users.add-edit.labels.username' | translate }}</mat-label>
        <input matInput formControlName="username" class="input">
        <mat-error><app-form-field-error [control]="form.controls.username"></app-form-field-error></mat-error>
      </mat-form-field>
    </div>

    <div formGroupName="access">
      <div class="form-field-wrapper">
        <mat-form-field appearance="outline" class="form-field">
          <mat-label>{{ 'tables.admin-tables.users.add-edit.labels.role' | translate }}</mat-label>
          <mat-select formControlName="role">
            <mat-option *ngFor="let role of roles" [value]="role">
              {{ role | role }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- selectedRole == unrestricted -->
      <div class="form-field-wrapper" *ngIf="form.controls.access.controls.organization">
        <mat-form-field class="form-field" appearance="outline">
          <mat-label>{{ 'tables.admin-tables.users.add-edit.labels.organization' | translate }}</mat-label>
          <mat-select formControlName="organization" panelClass="dark-scrollbar">
            <mat-option *ngFor="let org of organizations | async" [value]="org.id">
              {{ org.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- selectedRole == partner && userRole == admin -->
      <div class="form-field-wrapper" *ngIf="form.controls.access.controls.associatedPartner">
      <mat-form-field class="form-field" appearance="outline">
        <mat-label>{{ 'common.associated_partner' | translate }}</mat-label>
        <mat-select formControlName="associatedPartner" panelClass="image-icons" class="with-icon">
          <mat-select-trigger *ngIf="form.controls.access.controls.associatedPartner.value; let x">
            <img [src]="partners[x]?.icon"/>
            {{ partners[x]?.name }}
          </mat-select-trigger>
          <mat-option [value]="null">
            {{ 'common.none_singular' | translate }}
          </mat-option>
          <mat-option *ngFor="let x of partners | keyvalue" [value]="x.key">
            <img [src]="x.value?.icon"/>
            {{ x.value?.name }}
          </mat-option>
        </mat-select>
        <mat-error><app-form-field-error [control]="form.controls.access.controls.associatedPartner"></app-form-field-error></mat-error>
      </mat-form-field>
    </div>

    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button
          mat-dialog-close
          type="button">
    {{ 'common.cancel' | translate }}
  </button>

  <button mat-button
          type="submit"
          form="form"
          [disabled]="! form.valid"
          color="primary">
    {{ (addMode ? 'common.create' : 'common.save') | translate }}
  </button>
</mat-dialog-actions>