import { Component,
         Input,
         ElementRef,
         OnDestroy,
         Optional,
         HostBinding,
         Self,
         EventEmitter,
         Output,
         ChangeDetectionStrategy,
         ViewChild,                       } from '@angular/core';
import { ControlValueAccessor,
         NgControl,
         FormControl,
         FormGroup                       } from '@angular/forms';
import { MatFormFieldControl             } from '@angular/material/form-field';
import { FocusMonitor                    } from '@angular/cdk/a11y';
import { coerceBooleanProperty           } from '@angular/cdk/coercion';
import { asyncScheduler,
         BehaviorSubject,
         debounceTime,
         Subject,
         takeUntil,
         filter                          } from 'rxjs';
import _                                   from 'lodash';
import $                                   from 'jquery';

import { Util                            } from '@app/common';


type ExternalValue = {
  duration:  number;
  variation: number;
}
type InternalValue = ExternalValue;

type Form = {
  duration:  FormControl<string | null>;
  variation: FormControl<string | null>;
};

@Component({
  selector: 'app-form-field-duration-with-variation',
  templateUrl: './duration-with-variation.html',
  styleUrls: ['./duration-with-variation.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: DurationWithVariationComponent
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DurationWithVariationComponent implements OnDestroy,
                                          ControlValueAccessor,
                                          MatFormFieldControl<ExternalValue> {
  @Output('onChange') emitter = new EventEmitter<ExternalValue>();

  public durations:   number[]      = [];
  static nextId:      number        = 0;
  public stateChanges:Subject<void> = new Subject<void>();
  public focused:     boolean       = false;
  public errorState:  boolean       = false;
  public controlType: string        = 'duration-with-variation';
  public id:          string        = `duration-with-variation-${ DurationWithVariationComponent.nextId++ }`;
  public describedBy: string        = '';
  public onChange = (_: any) => {};
  public onTouched = () => {};

  private readonly onDestroy = new Subject<void>();

  protected onBlur = new Subject<void>();

  @ViewChild('duration')  durationInput?:  ElementRef<HTMLInputElement>;
  @ViewChild('variation') variationInput?: ElementRef<HTMLInputElement>;

  protected form = new FormGroup<Form>({
    duration:  new FormControl<string>('0', { nonNullable: true }),
    variation: new FormControl<string>('0', { nonNullable: true })
  });

  protected readonly cleave_number = {
    numeral:             true,
    delimiter:           '',
    numeralPositiveOnly: true,
    stripLeadingZeroes:  true,
  };

  @HostBinding('attr.tabindex') __tabindex = 0;

  constructor (
    @Optional() @Self() public ngControl: NgControl,
    private _focusMonitor: FocusMonitor,
    private _elementRef:   ElementRef<HTMLElement>
  ) {
    _focusMonitor.monitor(_elementRef, true)
    .subscribe((origin: any) => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    // update the external value whenever the form value changes
    this.form.valueChanges
    .pipe(
      takeUntil(this.onDestroy),
      debounceTime(0)
    )
    .subscribe(() => {
      const value = this.getValue();
      this.#externalValue.next(this.toExternalValue(value));
    });

    // emit whenever the external value changes
    this.#externalValue
    .pipe(
      takeUntil(this.onDestroy),
      filter(() => this.#emitExternalValueChanges),
    )
    .subscribe(x => {
      this.onChange(x);
      this.emitter.emit(x);
    });

    // update the form value whenever any input changes
    this.#internalValue
    .pipe(
      takeUntil(this.onDestroy)
    )
    .subscribe(value => {
      this.form.setValue({
        duration:  value.duration .toString(),
        variation: value.variation.toString()
      }, { emitEvent: false });
      this.stateChanges.next();
    });

    // apply restrictions to the form inputs
    this.onBlur
    .pipe(
      takeUntil(this.onDestroy)
    )
    .subscribe(() => {
      const value = this.getValue();

      if (value.duration .toString() != this.form.value.duration)  this.form.controls.duration .setValue(value.duration .toString(), { emitEvent: false });
      if (value.variation.toString() != this.form.value.variation) this.form.controls.variation.setValue(value.variation.toString(), { emitEvent: false });
    });
  }

  protected restrictInternalValue (value: InternalValue): InternalValue {
    // convert to multiples of 5 minutes
    value.duration  = Util.functions.toMultipleOf5(value.duration, 5);
    value.variation = Util.functions.toMultipleOf5(value.variation);

    return value
  }

  protected getValue (): InternalValue {
    // fetch values
    let duration  = this.form.value.duration  ? parseInt(this.form.value.duration)  : this.#defaultDuration;
    let variation = this.form.value.variation ? parseInt(this.form.value.variation) : this.#defaultVariation;

    // convert to multiples of 5 minutes
    return this.restrictInternalValue({ duration, variation });
  }

  private toInternalValue (value: ExternalValue): InternalValue {
    return this.restrictInternalValue(value);
  }
  private toExternalValue (value: InternalValue): ExternalValue {
    return value;
  }

  get empty() {
    return false
  }

  get shouldLabelFloat() { return this.focused || ! this.empty; }

  get pristine(): boolean {
    return this._pristine;
  }
  set pristine(_val: boolean) {
    const val      = coerceBooleanProperty(_val);
    this._pristine = val;

    if (val) asyncScheduler.schedule(() => this.ngControl.control?.markAsPristine());

    this.stateChanges.next();
  }
  private _pristine: boolean = true;

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.form?.disable() : this.form?.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  #defaultDuration = 60;
  #defaultVariation = 0;
  /* the only reason we separate these two is because "value" is constantly being probed by the form and thus it needs to be stored and not recomputed every time */
  #internalValue = new BehaviorSubject<InternalValue>({ duration: this.#defaultDuration, variation: this.#defaultVariation });
  #externalValue = new BehaviorSubject<ExternalValue>({ duration: this.#defaultDuration, variation: this.#defaultVariation });
  #emitExternalValueChanges = true;
  @Input()
  get value (): ExternalValue { return this.#externalValue.value; }
  set value (_val: ExternalValue | null) {
    if (_val == null) return;
    // store the external value (without emitting change event)
    this.#emitExternalValueChanges = false;
    this.#externalValue.next(_val);
    this.#emitExternalValueChanges = true;

    this.#internalValue.next(this.toInternalValue(_val));
  }

  @Input()
  unitLength: 'long' | 'short' = 'short';

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();

    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick (event: MouseEvent) {
    if ( ! this.disabled) {
      // focus the duration input if the user clicks anywhere on the container that is not an input
      const $target = $(event.target!);
      if ( ! $target.attr('formControlName')) this.durationInput?.nativeElement.focus();
    }
  }

  writeValue(val: ExternalValue): void {
    this.value = val;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  _handleInput(): void {
    this.onChange(this.value);
  }

  static ngAcceptInputType_disabled: boolean | string | null | undefined;
  static ngAcceptInputType_required: boolean | string | null | undefined;
}
