import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'paragraphs'
})
export class ParagraphsPipe implements PipeTransform {
  constructor (private sanitized: DomSanitizer) { }

  transform (
    value:      string | undefined,
    ...classes: string[]
  ): SafeHtml {
    if (value == undefined) return '';

    let paragraphs = value.split('\n')
                          .map(x => `<p class="${classes.join(' ')}">${x.trim()}</p>`)
                          .join('');
    return this.sanitized.bypassSecurityTrustHtml(paragraphs);
  }
}