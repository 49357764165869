import { Input,
         Directive,
         OnDestroy,
         ViewChild                         } from '@angular/core';
import { coerceArray,
         coerceBooleanProperty,
         coerceNumberProperty              } from '@angular/cdk/coercion';
import { MatPaginator                      } from 'app/common';
import { BehaviorSubject,
         Subject,
         takeUntil,
         timer                             } from 'rxjs';
import _                                     from 'lodash';
import moment                                from 'moment';

import { MatSort                           } from 'app/common';
import { apiConstants,
         ViewAccessType                    } from 'app/constants';
import { LoggerService                     } from 'app/core';
import { SearchComponent                   } from 'app/shared';
import { DataSourceService                 } from 'app/shared/services';
import { FilterComponent                   } from './components/filter/filter.component';


@Directive()
export abstract class TableCore<T extends { id: string }> implements OnDestroy {
  protected readonly onDestroy = new Subject<void>();

  protected readonly onNow = new BehaviorSubject<moment.Moment>(moment());

  @ViewChild(SearchComponent, { static: false })  searchComponent?: SearchComponent;
  @ViewChild(MatPaginator)     paginatorComponent?: MatPaginator;
  @ViewChild(MatSort)          sortComponent?:      MatSort;
  @ViewChild(FilterComponent)  filterComponent?:    FilterComponent;

  protected readonly urlRootDir: typeof apiConstants.ADMIN | typeof apiConstants.PARTNER;

  constructor (
    protected viewAccessType: ViewAccessType,
    protected dataSource:     DataSourceService<T>,
    protected logger:         LoggerService,
  ) {

    if      (viewAccessType === 'admin')   this.urlRootDir = apiConstants.ADMIN;
    else if (viewAccessType === 'partner') this.urlRootDir = apiConstants.PARTNER;
    else this.logger.error(new Error('Invalid view access type'));


    // update "onNow" every 5 seconds
    timer(0, 5000)
    .pipe(takeUntil(this.onDestroy))
    .subscribe(() => this.onNow.next(moment()));
  }

  ngOnDestroy () {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  public showFilter: boolean = false;

  set columns (val: (string | undefined)[]) { this._columns = _.uniq(val.filter(Boolean)); }
  get columns ()    { return this._columns; }
  private _columns: string[] = [];


  @Input()
  get paginator(): boolean { return this._paginator; }
  set paginator(value: boolean) {
    this._paginator = coerceBooleanProperty(value);
  }
  private _paginator: boolean = true;

  @Input()
  get pageSizes(): number[] { return this._pageSizes; }
  set pageSizes(value: number[]) {
    this._pageSizes = coerceArray(value).map((val: number) => coerceNumberProperty(val));
  }
  private _pageSizes: number[] = [10, 30, 50];

  @Input()
  get pageSize(): number { return this._pageSize; }
  set pageSize(value: number) {
    this._pageSize = coerceNumberProperty(value);
  }
  private _pageSize: number = this.pageSizes.at(1) ?? 10;
}