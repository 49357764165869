import { Component,
         ElementRef,
         HostListener                    } from '@angular/core';
import { Subject                         } from 'rxjs';

import { TranslateService                } from '@app/core';
import { DateService                     } from '@app/shared/services';
import { Populated as P                  } from '@app/shared/interfaces';
import { Options                         } from './popover.types';

@Component({
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss']
})
export class PopoverComponent {
  public translatedDayName: string;
  private isClosable: boolean = false;
  private onClose = new Subject<void>();

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement: ElementRef) {
    if (this.isClosable && ! this._elementRef.nativeElement.contains(targetElement))
      this.onClose.next();
    this.isClosable = true;
  }
  public limits = {
    teachers:  3,
    groups:    3,
    locations: 3,
  }
  public options?: Options;

  constructor (
    protected _date:       DateService,
    protected _elementRef: ElementRef,
    protected translate:   TranslateService,
  ) { }

  public setComponentProperties(options: Options, onClose: Subject<void>): void {
    this.onClose = onClose;
    this.limits = {
      teachers:  3,
      groups:    3,
      locations: 3,
    };
    this.options = options;
  }

  public close (): void {
    this.onClose.next();
  }

  ////
  //// type guards
  ////
  protected hasStartAndEnd (obj: Options['event']): obj is Options['event'] & { start: string, end: string } {
    if ( ! obj) return false;
    if ( ! obj.start || ! obj.end) return false;
    return true;
  }

  protected hasTeachers (obj: Options['event']): obj is Options['event'] & { teachers: NonNullable<P.event['teachers']> } {
    if ( ! obj) return false;
    return 'teachers' in obj && !! obj.teachers;
  }

  protected hasGroups (obj: Options['event']): obj is Options['event'] & { groups: NonNullable<P.event['groups']> } {
    if ( ! obj) return false;
    return 'groups' in obj && !! obj.groups;
  }

  protected hasInLocations (obj: Options['event']): obj is Options['event'] & { inLocations: NonNullable<P.event['inLocations']> } {
    if ( ! obj) return false;
    return 'inLocations' in obj && !! obj.inLocations;
  }

  protected hasWeeks (obj: Options['event']): obj is Options['event'] & { weeks: number[]} {
    if ( ! obj) return false;
    return 'weeks' in obj && !! obj.weeks;
  }

}