import { Component,
         Input,
         ElementRef,
         OnDestroy,
         Optional,
         HostBinding,
         Self,
         EventEmitter,
         Output,
         ChangeDetectionStrategy,
         ViewChild,
         signal,
         OnInit,                         } from '@angular/core';
import { ControlValueAccessor,
         NgControl,
         FormControl,
         Validators                      } from '@angular/forms';
import { MatFormFieldControl             } from '@angular/material/form-field';
import { FocusMonitor                    } from '@angular/cdk/a11y';
import { coerceBooleanProperty,
         coerceNumberProperty            } from '@angular/cdk/coercion';
import { takeUntilDestroyed              } from '@angular/core/rxjs-interop';
import { filter,
         Subject,
         takeUntil                       } from 'rxjs';

import { ExtendedValidators              } from '@app/shared/forms';
import { PrimitiveCore                   } from '../primitive-core';

const validators = [Validators.min(0), ExtendedValidators.isMultipleOf(5)];

type Value = number | null;

@Component({
  selector: 'app-form-field-minutes',
  templateUrl: './minutes.component.html',
  styleUrls: ['./minutes.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: MinutesComponent
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MinutesComponent
  extends PrimitiveCore<Value>
  implements OnDestroy, OnInit, ControlValueAccessor, MatFormFieldControl<Value>
{
  private readonly onDestroy = new Subject<void>();
  protected onBlur = new Subject<void>();
  protected readonly minutesCtrl = new FormControl<string>('0', { nonNullable: true });
  @ViewChild('minutes') minutesInput?: ElementRef<HTMLInputElement>;
  static nextId:      number  = 0;

  @Output('onChange') emitter = new EventEmitter<Value>();
  public stateChanges = new Subject<void>();
  public focused:     boolean = false;
  public controlType: string  = 'minutes-input';
  public id:          string  = `minutes-input-${ MinutesComponent.nextId++ }`;
  public describedBy: string  = '';
  public onChange = (_: any) => {};
  public onTouched = () => {};

  protected readonly numeralFormat = {
    numeral:             true,
    delimiter:           '',
    numeralPositiveOnly: true,
    stripLeadingZeroes:  true,
  };

  @HostBinding('attr.tabindex') __tabindex = 0;

  constructor (
    @Optional() @Self() public ngControl: NgControl | null,
    private _focusMonitor: FocusMonitor,
    private _elementRef:   ElementRef<HTMLElement>
  ) {
    super(ngControl, validators);

    this.forwardControl(this.minutesCtrl);

    this.parentHasRequiredValidator$
    .pipe(takeUntilDestroyed())
    .subscribe(x => this.required = x);












    _focusMonitor.monitor(_elementRef, true)
    .subscribe((origin: any) => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    // WITHOUT AUTO FORMAT: update the external value whenever the form value changes
    this.minutesCtrl.valueChanges
    .pipe(
      takeUntil(this.onDestroy),
      filter(() => ! this.$autoFormat())
    )
    .subscribe(x => {
      let minutes = x ? parseInt(x) : null;
      if (typeof minutes == 'number' && isNaN(minutes)) minutes = 0;

      // update external value
      this._value = minutes;
      this.onChange(this.value);
      this.emitter.emit(this.value);

      // need to occur after the onChange event
      this._pristineValue == minutes
        ? this.ngControl?.control?.markAsPristine()
        : this.ngControl?.control?.markAsDirty();
    });

    // WITH AUTO FORMAT: update the external value whenever the we are blurring the input
    this.onBlur
    .pipe(
      takeUntil(this.onDestroy),
      filter(() => this.$autoFormat())
    )
    .subscribe(() => {
      // round down to nearest 5 minutes
      let minutes = parseInt(this.minutesCtrl.value || '0');
      if (isNaN(minutes)) minutes = 0;
      minutes = Math.floor(minutes / 5) * 5;

      // update form value
      this.minutesCtrl.setValue(minutes.toString());
      this.stateChanges.next();

      // update external value
      this._value = minutes;
      this.onChange(this.value);
      this.emitter.emit(this.value);

      // need to occur after the onChange event
      this._pristineValue == minutes
        ? this.ngControl?.control?.markAsPristine()
        : this.ngControl?.control?.markAsDirty();
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  get empty () { return this._value == null; }

  get shouldLabelFloat () { return this.focused || ! this.empty; }

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.minutesCtrl.disable() : this.minutesCtrl.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): Value { return this._value }
  set value(_val: Value) {
    const minutes = coerceNumberProperty(_val, 0);
    this._pristineValue = minutes;
    this._value         = minutes;
    this.minutesCtrl.setValue(minutes.toString(), { emitEvent: false });
    this.stateChanges.next();
  }
  private _pristineValue: Value = 0;
  private _value: Value = 0;

  @Input({ transform: coerceBooleanProperty })
  get autoFormat(): boolean { return this.$autoFormat(); }
  set autoFormat(value: boolean) { this.$autoFormat.set(value); }
  private $autoFormat = signal(false);


  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();

    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick (event: MouseEvent) {
    if (this.disabled) return;
    this.minutesInput?.nativeElement.focus();
  }

  writeValue(val: number): void {
    this.value = val;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  _handleInput(): void {
    this.onChange(this.value);
  }

  static ngAcceptInputType_disabled: boolean | string | null | undefined;
  static ngAcceptInputType_required: boolean | string | null | undefined;
}
