<h1 mat-dialog-title class="royal-text-primary">
  {{ 'view.private.schema.components.failed-generation-dialog.title' | translate }}
</h1>

<mat-dialog-content class="mat-typography">
  <p>{{ 'view.private.schema.components.failed-generation-dialog.description' | translate }}</p>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close cdkFocusInitial>{{ 'common.close' | translate }}</button>
</mat-dialog-actions>