import { NgModule                                } from '@angular/core';
import { CommonModule                            } from '@angular/common';
import { NgPipesModule                           } from 'ngx-pipes';

import { AppCommonModule                         } from 'app/common/common.module';
import { TranslationModule                       } from 'app/core/translate/translate.module';
import { FormFieldsModule                        } from 'app/shared/form-fields/form-fields.module';
import { PipesModule                             } from 'app/shared/pipes/pipes.module';
import { DirectivesModule                        } from 'app/shared/directives/directives.module';
import { FormsModule      as AppFormsModule      } from 'app/shared/forms/forms.module';
import { ComponentsModule as AppComponentsModule } from 'app/shared/components/components.module';

import { ComponentsModule                        } from '../components/components.module';
import { CoursesComponent                        } from './courses.component';
import { EventTooltipPipe,
         RemoveSelfPipe,
         RestrictSetsPipe,
         GetPipe,
         PlannedDurationIsSatisfiedPipe          } from './courses.pipe';
import { EventTableDirective                     } from './courses.directive';


@NgModule({
  declarations: [
    CoursesComponent,
    RemoveSelfPipe,
    RestrictSetsPipe,
    EventTooltipPipe,
    GetPipe,
    PlannedDurationIsSatisfiedPipe,
    EventTableDirective
  ],
  exports: [
    CoursesComponent
  ],
  imports: [
    CommonModule,
    NgPipesModule,
    AppCommonModule,
    TranslationModule,
    FormFieldsModule,
    PipesModule,
    DirectivesModule,
    AppFormsModule,
    AppComponentsModule,
    ComponentsModule
  ]
})
export class CoursesModule { }
