import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'capitalizeFirst' })
export class CapitalizeFirstPipe implements PipeTransform {
  transform (str: string | undefined | null): string {
    return str?.capitalizeFirst() ?? '';
  }
}


/*
  <h1>{{longStr | truncate }}</h1>
  <!-- Outputs: A really long string that... -->

  <h1>{{longStr | truncate : 12 }}</h1>
  <!-- Outputs: A really lon... -->

  <h1>{{longStr | truncate : 12 : true }}</h1>
  <!-- Outputs: A really... -->

  <h1>{{longStr | truncate : 12 : false : '***' }}</h1>
  <!-- Outputs: A really lon*** -->
*/
@Pipe({
  name: 'truncateString'
})
export class TruncateStringPipe implements PipeTransform {
  transform (value: string, limit = 25, completeWords = false, ellipsis = '...'): any {
    if ( ! value ) return '';
    if (completeWords) {
      limit = value.substring(0, limit).lastIndexOf(' ');
    }
    return `${value.substring(0, limit)}${value.length > limit ? ellipsis : ''}`;
  }
}
