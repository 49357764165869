import { Component,
         Input,
         Output,
         EventEmitter                   } from '@angular/core';
import { UntypedFormGroup,
         FormControl,
         UntypedFormBuilder,
         Validators                     } from '@angular/forms';
import { asyncScheduler                  } from 'rxjs';
import $                                   from 'jquery';
import _                                   from 'lodash';

type type = 'row' | 'default';

export interface Options {
  showDisplayName?: boolean,
  showDays?:        boolean,
  showIds?:         boolean,
}

@Component({
  selector: 'app-teachers-form',
  templateUrl: './teachers.component.html',
  styleUrls: ['./teachers.component.scss']
})
export class TeachersComponent {
  @Output() onSubmit:                 EventEmitter<void> = new EventEmitter<void>();
  @Input('options') options:          Options;
  @Input()          type:             type             = 'default';
  public form:                        UntypedFormGroup;

  constructor(private _fb: UntypedFormBuilder) {
    this.form = this._fb.group({
      displayName:      [undefined, [<any>Validators.required]],
      ids:              [{ value: undefined, disabled: false }, []],
    });
  }

  ngOnInit(): void {
  }

  public keydown(event: KeyboardEvent): void {
    if (event.key == 'Enter' && this.form.valid) {
      this.onSubmit.next();
    }
  }

  @Input()
  set focus(_field: string) {
    asyncScheduler.schedule(() => {
      const elem = $(`#${ _field }`)[0];
      elem?.focus();
    })
  }

  public resetForm():void {
    this.form.reset();
  }

  private mapForm(val: any) {
    return _.pickBy(_.pick(val, Object.keys(this.form.controls)), _.identity);
  }

  public getForm() {
    return this.mapForm(this.form.value);
  }
}
