import { NgModule                         } from '@angular/core';
import { CommonModule                     } from '@angular/common';
import { FormsModule,
         ReactiveFormsModule              } from '@angular/forms';
import { RouterModule                     } from '@angular/router';
import { NgxCleaveDirectiveModule         } from 'ngx-cleave-directive';
import { NgPipesModule                    } from 'ngx-pipes';

import { AppCommonModule                  } from 'app/common/common.module';
import { TranslationModule                } from 'app/core/translate/translate.module';
import { PipesModule                      } from 'app/shared/pipes/pipes.module';
import { DirectivesModule                 } from 'app/shared/directives/directives.module';
import { SchemaFilterBottomSheetComponent } from './schema-filter-bottom-sheet/schema-filter-bottom-sheet.component';
import { SubmitPaneComponent              } from './submit-pane/submit-pane.component';
import { UserMenuComponent                } from './user-menu/user-menu.component';
import { MainToolbarComponent             } from './main-toolbar/main-toolbar.component';
import { SidenavComponent                 } from './sidenav/sidenav.component';
import { AreaComponent                    } from './area/area.component';
import { LoadingComponent                 } from './loading/loading.component';
import { FetchFailPaneComponent           } from './fetch-fail-pane/fetch-fail-pane.component';
import { SaveIconComponent                } from './save-icon/save-icon.component';
import { LanguageSelectComponent          } from './language-select/language-select.component';
import { FormFieldErrorComponent          } from './form-field-error/form-field-error.component';
import { LongPressButtonComponent         } from './long-press-button/long-press-button.component';
import { SvgScheduleComponent             } from './svg-schedule/svg-schedule.component';
import { TableActionButtons               } from './table-action-buttons/table-action-buttons.component';

import { SearchModule                     } from './search/search.module';
import { SchemaFilterDropdownsModule      } from './schema-filter-dropdowns/schema-filter-dropdowns.module';
import { MultipleSelectFilterModule       } from './multiple-select-filter/multiple-select-filter.module';
import { InputAnalyzerModule              } from './input-analyzer/input-analyzer.module';
import { EmptyModule                      } from './empty/empty.module';
import { ToolbarContainerModule           } from './toolbar-container/toolbar-container.module';
import { SvgImageModule                   } from './svg-image/svg-image.module';

@NgModule({
  declarations: [
    SchemaFilterBottomSheetComponent,
    SubmitPaneComponent,
    UserMenuComponent,
    MainToolbarComponent,
    SidenavComponent,
    AreaComponent,
    LoadingComponent,
    FetchFailPaneComponent,
    SaveIconComponent,
    LanguageSelectComponent,
    LongPressButtonComponent,
    FormFieldErrorComponent,
    SvgScheduleComponent,
    TableActionButtons
  ],
  exports: [
    SchemaFilterBottomSheetComponent,
    SchemaFilterDropdownsModule,
    MultipleSelectFilterModule,
    ToolbarContainerModule,
    SubmitPaneComponent,
    UserMenuComponent,
    MainToolbarComponent,
    SidenavComponent,
    AreaComponent,
    LoadingComponent,
    SearchModule,
    FetchFailPaneComponent,
    SaveIconComponent,
    LanguageSelectComponent,
    InputAnalyzerModule,
    LongPressButtonComponent,
    FormFieldErrorComponent,
    SvgScheduleComponent,
    TableActionButtons,
    EmptyModule,
    SvgImageModule
  ],
  imports: [
    CommonModule,
    FormsModule,
    PipesModule,
    ReactiveFormsModule,
    AppCommonModule,
    TranslationModule,
    RouterModule,
    NgxCleaveDirectiveModule,
    NgPipesModule,
    DirectivesModule
  ]
})
export class ComponentsModule { }
