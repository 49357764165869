import { OnDestroy,
         Injectable                      } from '@angular/core';
import { UntypedFormGroup,
         Validators,
         UntypedFormBuilder,
         FormArray,
         FormGroup,
         FormControl                     } from '@angular/forms';
import moment                              from 'moment';
import { nanoid                          } from 'nanoid';
import { Observable,
         asyncScheduler,
         Subject,
         BehaviorSubject                 } from 'rxjs';
import { takeUntil                       } from 'rxjs/operators';

import { ExtendedValidators              } from 'app/shared/forms/validators';
import { LockedTime as CoreType          } from 'app/shared/interfaces';
import { DateService                     } from 'app/shared/services';

import { LoggerService                   } from 'app/core';
import { coerceNumberProperty            } from '@angular/cdk/coercion';

type LockedTime = Partial<CoreType>;

type LockedTimeForm = {
  id:          FormControl<string>;
  day:         FormControl<number>;
  start:       FormControl<moment.Moment>;
  end:         FormControl<moment.Moment>;
  displayName: FormControl<string>;
}

@Injectable()
export class CoreService implements OnDestroy {
  public form:         FormArray<FormGroup<LockedTimeForm>>;
  private _onChange:   Subject<(LockedTime & { day: number })[]> = new Subject();
  private _onDestroy:  Subject<boolean>                          = new Subject();
  private _onReset:    Subject<boolean>                          = new Subject();

  public editable = new BehaviorSubject<boolean>(true);

  constructor(private _logger: LoggerService,
              private _fb:     UntypedFormBuilder) {
    this.form = _fb.array([]);
  }

  public reset(): void {
    this._onReset.next(true);
    this.form.clear();
  }

  public clear(): void {
    this.isVoidState = false;
    this.form.clear();
    asyncScheduler.schedule(() => this._onChange.next(this.value!));
  }

  public registerChange(): void {
    asyncScheduler.schedule(() => this._onChange.next(this.value!));
  }

  public onChange(): Observable<(LockedTime & { day: number })[]> {
    return this._onChange.asObservable();
  }

  public set(event: LockedTime): void {
    let form = this.form.controls.find(group => group.value.id == event.id);

    if (form == null)
      return this._logger.warn(`(FormFields:LockedTimes) Could not find form field ${ event.id }`);

    form.controls.start.setValue(moment.utc(event.start), { emitEvent: false });
    form.controls.end  .setValue(moment.utc(event.end), { emitEvent: false });
    form.controls.day  .setValue(DateService.getDayIndex(moment.utc(event.start)), { emitEvent: false });

    this._onChange.next(this.value!);
  }

  public add(): void {
    this.isVoidState = false;
    this.form.insert(0, this._setFormGroup({
        id:          nanoid(8),
        day:         0,
        start:       DateService.fromTimeString('12:00', 0),
        end:         DateService.fromTimeString('13:00', 0),
        displayName: ''
      })
    );
    this._onChange.next(this.value!);
  }

  public remove(index: number): void {
    this.isVoidState = false;
    this.form.removeAt(index);
    this._onChange.next(this.value!);
  }

  private _setFormGroup(form: LockedTime & { day: number }): UntypedFormGroup {
    const day = DateService.getDayIndex(moment.utc(form.start));
    const group: UntypedFormGroup = this._fb.group({
      id:          [form.id, []],
      day:         [day, [Validators.min(0), Validators.max(this.numDays - 1)]],
      start:       [DateService.sanitizeDate(moment.utc(form.start), day), []],
      end:         [DateService.sanitizeDate(moment.utc(form.end), day), []],
      displayName: [form.displayName ?? '', []]
    }, {
      validators: [
        ExtendedValidators.isSameDay('start', 'end'),
        ExtendedValidators.validateThat('start', 'isBefore', 'end')
      ],
      emitEvent: false
    });

    group.valueChanges
    .pipe(
      takeUntil(this._onDestroy),
      takeUntil(this._onReset)
    )
    .subscribe(() => {
      asyncScheduler.schedule(() => {
        this._onChange.next(this.value!);
      })
    });

    return group;
  }

  get value(): (LockedTime & { day: number })[] | null | undefined {
    if (this.isVoidState) return undefined;

    return this.form.value.map(lockedTime => ({
      id:          lockedTime.id,
      displayName: lockedTime.displayName,
      start:       DateService.sanitizeDate(lockedTime.start as any, <number>lockedTime.day)!,
      end:         DateService.sanitizeDate(lockedTime.end   as any, <number>lockedTime.day)!,
      day:         lockedTime.day!
    }));
  }
  set value(_val: (LockedTime & { day: number })[] | null | undefined) {
    this.isVoidState = _val == undefined;
    const val: LockedTime[] = _val ?? [];
    this._onReset.next(true);

    this.form = this._fb.array(val?.map((form: LockedTime & { day: number }) => this._setFormGroup(form)));
  }
  private isVoidState: boolean;


  get numDays(): number { return this._numDays; }
  set numDays(value: number) {
    this._numDays = coerceNumberProperty(value, 5);
  }
  private _numDays = 5;



  // get editable (): boolean { return this._editable; }
  // set editable (val: boolean) { this._editable = val; }
  // private _editable: boolean;

  ngOnDestroy() {
    this._onDestroy.next(true);
    this._onDestroy.complete();
    this._onReset.next(true);
    this._onReset.complete();
  }
}
