import { Component,
         Output,
         OnDestroy,
         EventEmitter,
         Input,
         ElementRef                      } from '@angular/core';
import { FormControl                     } from '@angular/forms';
import { coerceBooleanProperty           } from '@angular/cdk/coercion';
import { Subject,
         Observable,
         BehaviorSubject,
         asyncScheduler                  } from 'rxjs';
import { takeUntil                       } from 'rxjs/operators';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnDestroy {
  private readonly onDestroy = new Subject<void>();

  protected control = new FormControl<string | null>(null);
  private _onValue = new BehaviorSubject<string>('');


  constructor (
    public elementRef: ElementRef
  ) {
    // typed in the search input
    this.control.valueChanges
    .pipe(takeUntil(this.onDestroy))
    .subscribe(val => {
      this.emitter.emit(val ?? '')
      this._onValue.next(val ?? '');
    });
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }


  ////
  //// IO
  ////

  @Input()
  get value(): string { return this._onValue.value; }
  set value(val: string) { asyncScheduler.schedule(() => this.control.setValue(val)); }   // asyncScheduler to prevent ExpressionChangedAfterItHasBeenCheckedError...
  get onValue(): Observable<string> { return this._onValue; }

  @Input()
  get placeholder (): string { return this._placeholder; }
  set placeholder (value: string) { this._placeholder = value;}
  private _placeholder: string = 'common.search';

  @Input()
  get white (): boolean { return this._white; }
  set white (value: boolean | string) { this._white = coerceBooleanProperty(value); }
  private _white = false;

  @Input()
  get appearance (): 'fill' | 'outline' { return this._appearance; }
  set appearance (value: 'fill' | 'outline') { this._appearance = value; }
  private _appearance: 'fill' | 'outline' = 'outline';

  @Output('onChange') emitter = new EventEmitter<string>();
}
