import { Component,
         DestroyRef,
         inject,
         Inject,
         OnDestroy                   } from '@angular/core';
import { HttpErrorResponse           } from '@angular/common/http';
import { takeUntilDestroyed          } from '@angular/core/rxjs-interop';
import { Observable                  } from 'rxjs';

import { MatDialogRef,
         MAT_DIALOG_DATA             } from '@app/common';
import { apiConstants                } from '@app/constants';
import { HttpService,
         LoggerService,
         SourceService,
         UserPreferencesService      } from '@app/core';
import { inOutAnimation              } from '@app/shared/animations';
import { DialogsService,
         InputAnalysisService        } from '@app/shared/services';
import { categoriesDescription       } from '../input-issues/input-issues.component';

@Component({
  selector: 'app-generate-schedule',
  templateUrl: './generate-schedule.component.html',
  styleUrls: ['./generate-schedule.component.scss'],
  providers: [ SourceService ],
  animations: [ inOutAnimation ]
})
export class GenerateScheduleComponent implements OnDestroy {
  private readonly destroyRef = inject(DestroyRef);
  protected loading     = false;
  protected error       = false;
  protected duplicated  = false;
  protected submitted   = false;

  protected did:          string;
  protected displayName?: string;
  protected onIsRunning?: Observable<boolean | null>;

  protected readonly categoriesDescription = categoriesDescription;

  protected readonly numRemarks = {
    errors:  this.inputAnalysis.onNumErrors,
    issues:  this.inputAnalysis.onNumIssues,
    notices: this.inputAnalysis.onNumNotices
  } as const;

  protected readonly totNumRemarks = this.inputAnalysis.onTotNumRemarks;

  constructor (
    protected inputAnalysis:  InputAnalysisService,
    protected dialog:         DialogsService,
    protected preferences:    UserPreferencesService,
    private   _dialogRef:     MatDialogRef<GenerateScheduleComponent>,
    private   _http:          HttpService,
    private   _logger:        LoggerService,
    private   _source:        SourceService,
    private   _dialog:        DialogsService,
    @Inject(MAT_DIALOG_DATA)
    { did, onIsRunning }: { did: string, onIsRunning?: Observable<boolean> }
  ) {
    this.did         = did;
    this.onIsRunning = onIsRunning;

    this._source.getDivision({ did: this.did, onDestroy: this.destroyRef })
    .pipe(takeUntilDestroyed())
    .subscribe(x => this.displayName = x?.displayName);

    // need to activate the input analysis service if not already active
    void inputAnalysis.activate(this.did, this._source);
  }

  ngOnDestroy () {
    // deactivate input analysis service if preferences.backgroundDataAnalysis is falsy
    // (might ba a problem if multiple tabs are open, but very unlikely user behavior)
    if ( ! this.preferences.backgroundDataAnalysis) this.inputAnalysis.deactivate();
  }

  protected generate () {
    this.submitted = true;
    this.loading   = true;
    this._http
    .post(`${ apiConstants.DIVISIONS }/${ this.did }/${ apiConstants.JOBS }`, { })
    .subscribe({
      next: () => {
        this._dialogRef.close(true);
      },
      error: (err) => {
        this.submitted = false;
        this.error     = true;
        this.loading   = false;

        // send error unless its a 401 (user not logged in)
        if (err instanceof HttpErrorResponse && err.status == 401) return;
        this._logger.error(new Error(`could not start a job of did ${this.did}, status: ${err.status}`));
      }
    });
  }

  protected duplicate () {
    this._dialog.openDuplicateScheduleDialog(this.did, this.displayName ?? '')
    .subscribe(x => this.duplicated = !! x);
  }
}
