import { NgModule                        } from '@angular/core';
import { CommonModule                    } from '@angular/common';

import { TranslationModule               } from 'app/core/translate/translate.module';
import { AppCommonModule                 } from 'app/common/common.module';
import { VersionComponent                } from './version.component';


@NgModule({
  declarations: [
    VersionComponent
  ],
  imports: [
    CommonModule,
    TranslationModule,
    AppCommonModule
  ]
})
export class VersionModule { }
