import { NgModule                        } from '@angular/core';
import { CommonModule                    } from '@angular/common';
import { FormsModule,
         ReactiveFormsModule             } from '@angular/forms';

import { TranslationModule               } from 'app/core/translate/translate.module';

import { AppCommonModule                 } from 'app/common/common.module';

import { ComponentsModule                } from 'app/shared/components/components.module';

import { ComponentsModule as SubModule   } from './components/components.module';

import { IntervalsComponent              } from './intervals.component';

import { TimeFormFieldModule             } from 'app/shared/form-fields/time/time.module';

@NgModule({
  declarations: [
    IntervalsComponent
  ],
  exports: [
    IntervalsComponent,
    SubModule
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    TranslationModule,
    TimeFormFieldModule,
    FormsModule,
    SubModule,
    ComponentsModule,
    ReactiveFormsModule
  ]
})
export class IntervalsFormFieldModule { }
