import { Component,
         OnInit,
         OnDestroy,
         ViewChild,
         AfterViewInit,
         Input,
         signal                          } from '@angular/core';
import { coerceNumberProperty            } from '@angular/cdk/coercion';
import { InCalendarEvent                 } from '@app/shared/calendar/calendar/types';
import { merge,
         Subject                         } from 'rxjs';
import { takeUntil                       } from 'rxjs/operators';
import moment                              from 'moment';

import { CalendarComponent,
         CalendarOptions                 } from 'app/shared';
import { LockedTime as CoreType          } from 'app/shared/interfaces';
import { DateService                     } from 'app/shared/services';

import { CoreService                     } from './core.service';


type LockedTime = Partial<CoreType>;

@Component({
  selector: 'app-form-field-locked-times-calendar',
  template: `<app-calendar [options]="options" [visibleRange]="visibleRange()" [numDays]="numDays"></app-calendar>`,
  styleUrls: ['./locked-times.component.scss']
})
export class LockedTimesCalendar implements OnInit,
                                            AfterViewInit,
                                            OnDestroy {
  @ViewChild(CalendarComponent) calendar: CalendarComponent;
  private onDestroy = new Subject<void>();

  protected options: CalendarOptions  = {
    editMode:        this._core.editable.value,
    canResizeEvents: true,
    bottomFade:      false,
  };
  protected visibleRange = signal<{ start: moment.Moment, end: moment.Moment }[]>([{
    start: DateService.fromTimeString('08:00', 0),
    end:   DateService.fromTimeString('17:00', 0)
  }]);

  constructor (private _core:  CoreService) {
  }

  ngOnInit(): void {

    this._core.editable
    .pipe(takeUntil(this.onDestroy))
    .subscribe(editable => {
      this.options = {...this.options, editMode: editable};
    });

  }

  ngAfterViewInit() {
    this._setEvents(this._core.value ?? []);

    merge(
      this.calendar.onEventResize,
      this.calendar.onEventDrop,    // does not fire the first time for some reason (has to do with resizing)...
      this.calendar.onEventReceive  // ... instead the event receive event is triggered
    )
    .pipe(takeUntil(this.onDestroy))
    .subscribe(({ source }) => {
      this._core.set({
        id:    source.id,
        start: source.start,
        end:   source.end
      });
    });

    this._core.onChange()
    .pipe(takeUntil(this.onDestroy))
    .subscribe(val => {
      this._setEvents(val)
    });
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  private _setEvents (lockedTimes: (LockedTime & { day: number })[]) {
    const events: InCalendarEvent[] = lockedTimes.map(val => {

      const id    = val.id!;
      const start = DateService.sanitizeDate(val.start!, val.day)!;
      const end   = DateService.sanitizeDate(val.end!,   val.day)!;

      return {
        id, start, end,
        is:    'lockedTime',
        title:  val.displayName ?? ''
      }
    });
    this.calendar?.setForegroundEvents(events);
  }

  @Input()
  get numDays(): number { return this._numDays; }
  set numDays(value: number) {
    this._numDays = coerceNumberProperty(value, 5);
  }
  private _numDays = 5;
}