import { NgModule                        } from '@angular/core';
import { CommonModule                    } from '@angular/common';
import { ReactiveFormsModule,
         FormsModule                     } from '@angular/forms';

import { TranslationModule               } from '@core/translate/translate.module';
import { AppCommonModule                 } from '@common/common.module';
import { PipesModule                     } from '@app/shared/pipes/pipes.module'
import { FormsModule as AppForms         } from 'app/shared/forms/forms.module';

import { ComponentsModule                } from '@app/shared/components/components.module';

import { ValidatorComponent              } from './components/validator/validator.component';

import { SyncComponent                   } from './sync.component';
import { FilterCollectionsPipe,
         SyncErrorPipe                   } from './sync.pipe';

@NgModule({
  declarations: [
    SyncComponent,
    SyncErrorPipe,
  ],
  imports: [
    CommonModule,
    AppForms,
    AppCommonModule,
    ReactiveFormsModule,
    ComponentsModule,
    ValidatorComponent,
    FilterCollectionsPipe,
    FormsModule,
    PipesModule,
    TranslationModule
  ]
})
export class SyncModule { }
