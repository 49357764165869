import { Component,
         AfterViewInit,
         Inject,
         ComponentRef,
         Injector,
         EmbeddedViewRef,
         ViewContainerRef                } from '@angular/core';
import { ComponentPortal                 } from '@angular/cdk/portal';
import { BehaviorSubject,
         Observable,
         combineLatest,
         debounceTime,
         filter,
         map,
         startWith,
         switchMap                       } from 'rxjs';

import { SourceService                   } from 'app/core';
import { MatDialogRef,
         MAT_DIALOG_DATA                 } from 'app/common';
import { inOutAnimation                  } from 'app/shared/animations';
import { TutorialsService                } from 'app/shared/services/tutorials/tutorials.service';
import { CoursesComponent                } from './components/courses/courses.component';
import { EventsComponent                 } from './components/events/events.component';


@Component({
  selector: 'app-parallel-courses',
  templateUrl: './parallel-courses.component.html',
  styleUrls: ['./parallel-courses.component.scss'],
  providers: [ SourceService ],
  animations: [ inOutAnimation ]
})
export class ParallelCoursesComponent implements AfterViewInit {
  public readonly did: string;
  private readonly initialOverlapGroupId?: string;

  protected readonly loading:      Observable<boolean>;
  protected readonly dragDisabled: Observable<boolean>;

  private readonly awaitData: Promise<void>;

  protected tabIndex = 0;

  protected coursesComponentPortal?: ComponentPortal<CoursesComponent>;
  protected eventsComponentPortal?:  ComponentPortal<EventsComponent>;
  private readonly coursesComponent = new BehaviorSubject<CoursesComponent | null>(null);
  private readonly eventsComponent  = new BehaviorSubject<EventsComponent  | null>(null);

  constructor (
    protected dialogRef:        MatDialogRef<ParallelCoursesComponent>,
    protected tutorial:         TutorialsService,
    private   _source:            SourceService,
    private   _viewContainerRef:  ViewContainerRef,
    @Inject(MAT_DIALOG_DATA)
    { did, overlapGroupId }: { did: string, overlapGroupId?: string }
  ) {
    // store ids
    this.did                   = did;
    this.initialOverlapGroupId = overlapGroupId;

    // BUG IN MAT-TAB: we need to set the correct tab here already
    if (overlapGroupId) this.tabIndex = 1;

    // fetch data
    this.awaitData = this._source.groupBy({ collections: ['groups', 'teachers', 'courses', 'events', 'overlapGroups'], did: this.did });

    this.loading = combineLatest([this.coursesComponent, this.eventsComponent])
    .pipe(
      debounceTime(0),
      map(x => x.some(x => ! x)),
      startWith(true)
    );

    this.dragDisabled = combineLatest([this.coursesComponent, this.eventsComponent])
    .pipe(
      debounceTime(0),
      filter((x): x is [CoursesComponent, EventsComponent] => x.every(Boolean)),
      switchMap(x => combineLatest(x.map(x => x.dragDisabled))),
      map(x => x.some(Boolean)),
      startWith(false)
    )
  }

  async ngAfterViewInit() {
    // wait for the data to be loaded before initializing sub-components
    await this.awaitData;

    // initialize sub-components
    this.coursesComponentPortal = new ComponentPortal(
      CoursesComponent, null, Injector.create({
      providers: [ { provide: 'parent', useValue: this } ]
    }));

    this.eventsComponentPortal = new ComponentPortal(
      EventsComponent, null, Injector.create({
      providers: [ { provide: 'parent', useValue: this } ]
    }));
  }

  courseComponentAttached (ref: ComponentRef<CoursesComponent> | EmbeddedViewRef<CoursesComponent> | null) {
    if ( ! ref || ! ('instance' in ref)) return;
    this.coursesComponent.next(ref.instance);
  }

  eventsComponentAttached (ref: ComponentRef<EventsComponent> | EmbeddedViewRef<EventsComponent> | null) {
    if ( ! ref || ! ('instance' in ref)) return;
    this.eventsComponent.next(ref.instance);
    this.initialOverlapGroupId && this.gotoEventsTab({ id: this.initialOverlapGroupId });
  }

  protected openTutorial () {
    // z-index is set to 1000 to make sure the tutorial is on top of the dialog
    this.tutorial.open('31', this._viewContainerRef, undefined, { zIndex: 1000 });
  }

  protected gotoCoursesTab (event: Event) {
    // ensure that the function is not fired twice by the same event (keydown and click)
    event.preventDefault();
    this.tabIndex = 0;
  }

  public gotoEventsTab (val: { id: string }) {
    if (this.eventsComponent.value) {
      this.eventsComponent.value.overlappableEventSetId = val.id;
      this.tabIndex = 1;
    }
  }


  protected readonly numAutoLinkable$ = new BehaviorSubject(0);
  public setNumAutoLinkable (num: number) { this.numAutoLinkable$.next(num); }


  protected autoLink () {
    this.coursesComponent.value?.autoLinkAll();
  }

}