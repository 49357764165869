import { DestroyRef, Injectable,
         OnDestroy                       } from '@angular/core';
import { Observable,
         from,
         Subject,
         combineLatest,
         BehaviorSubject,
         of,
         identity                        } from 'rxjs';
import { map,
         startWith,
         filter,
         takeUntil,
         shareReplay                     } from 'rxjs/operators';
import { MatSort                         } from '@angular/material/sort';
import _                                   from 'lodash';

import { LoggerService                   } from '@core/logger/logger.service';
import { Performance                     } from '@shared/decorators/performance/performance.decorator';
import { Division,
         DivisionSettings,
         Populated as P,
         Shallow as S,
         Serialized,
         Generation                      } from '@shared/interfaces';
import { Util                            } from '@app/common/util';

import { ChangeEvent,
         Options,
         SourceData,
         Filter                          } from './source.interface';
import { _filter                         } from './source.filter';
import { _sort                           } from './source.sort';
import { _paginate                       } from './source.paginate';
import { SourceCore                      } from './source.core';
import { DefaultCoreDataTypes } from './core/system-core/structure/types';

type GetArgs    = [Omit<Options.Default, 'collection'>, ...Observable<undefined | null | Filter.Type | Filter.Type[]>[]];
type ChangeArgs = [Omit<Options.Default, 'collection'>];
type SetArgs<T> = [Omit<Options.Default, 'collection' | 'onDestroy'>, string, Util.Types.DeepPartial<Omit<T, 'id'> >];

type ValOrArrayVal<T> = T | T[];

type Is = (P.location | P.group | P.teacher | P.person | P.period | P.course | P.event | P.lockedTime | P.overlapGroup | P.rootInterval | P.generations | P.exception)['is'];

// select pipe
export function sourceSelectPipe
<T extends null | Record<string, undefined | ValOrArrayVal<string | number | boolean | null | undefined>> = null> (
  val: T | Observable<T>
): Observable<Filter.Select[] | null> {
  // if it is not an observable, make it one
  if (! (val instanceof Observable)) val = of(val);

  return val.pipe(
    map(val => {
      if ( ! val) return null

      return Object.entries(val)
        .map(([key, value]) => ({
          action: 'select',
          key:    key,
          value:  value
        }));
    })
  );
}

// match pipe
export function sourceMatchPipe
<T extends string = string> (
  obs:    Observable<T>,
  onKeys: string[] | Observable<string[]>
): Observable<Filter.Match> {
  return combineLatest({
    val:  obs,
    keys: onKeys instanceof Observable ? onKeys : of(onKeys)
  })
  .pipe(
    map(({ val, keys }) => ({
      action: 'match',
      key:    keys,
      value:  val
    }))
  );
}

// in time interval pipe
export function sourceInTimeIntervalPipe
<T extends { [key in string]?: ValOrArrayVal<string | number | boolean | null> } = { }> (
  onValue: Filter.InTimeInterval['value'] | Observable<Filter.InTimeInterval['value']>,
  key?:    Filter.InTimeInterval['key']
): Observable<Filter.InTimeInterval> {
  return (onValue instanceof Observable ? onValue : of(onValue))
  .pipe(
    map((value) => ({
      action: 'inTimeInterval',
      value,
      ...key && { key }
    }))
  );
}

// select pipe
export function sourceHasPipe
<T extends { [key in string]?: ValOrArrayVal<string | number | boolean | null> } = { }> (
  val: T | Observable<T>
): Observable<Filter.Has[]> {
  // if it is not an observable, make it one
  if (! (val instanceof Observable)) val = of(val);

  return val.pipe(
    map(val =>
      Object.entries(val)
      .map(([key, value]) => ({
        action: 'has',
        key:    key,
        value:  value
      }))
    )
  );
}

// one of pipe
export function sourceOneOfPipe
<T extends Object = Object> (
  obs:           Observable<T | null>,
  fun:           (entry: Util.Types.Entry<T>) => { key: string | string[], value: ValOrArrayVal<boolean | string> },
  filterBoolean: boolean = true
): Observable<Filter.OneOf | undefined> {
  return obs.pipe(
    filterBoolean ? filter(Boolean) : identity,
    map(val => {
      if ( ! val) return undefined;

      let key:   string[][]                        = [];
      let value: ValOrArrayVal<boolean | string>[] = [];
      Util.functions.objectEntries(val).forEach(e => {
        const x = fun(e);
        key.push(Util.functions.coerceArrayProperty(x.key));
        value.push(x.value);
      });

      return { action: 'oneOf' as const, key, value };
    })
  );
}

@Injectable()
export class SourceService implements OnDestroy {
  private readonly onDestroy: Subject<boolean> = new Subject<boolean>();

  public loading: boolean = false;

  constructor(protected _core:   SourceCore,
              protected _logger: LoggerService) { }

  //@Performance('(Source::Get)')
  public get<T = SourceData | SourceData[] | null>(_options: Options.Default, ...filters: Observable<undefined | null | Filter.Type | Filter.Type[]>[]): BehaviorSubject<T | null> {

    // contains all the modifiers that will be applied to the data
    const modifiers: Observable<Options.Mapped | { }>[] = [];

    filters.forEach(x => {
      modifiers.push(x.pipe(
        startWith({ }),
        map(x => ({ filter: x }))
      ));
    });

    if (_options.paginator) {
      const paginator = _options.paginator;
      modifiers.push(
        from(paginator.page)
        .pipe(
          startWith({
            pageIndex: paginator.pageIndex,
            pageSize:  paginator.pageSize,
          }),
          map(pagination => ({ pagination: {
            ...pagination,
            // We need to pass the paginator since we might need to modify the pageIndex when filtering
            // (e.g. if we search for a single item when at page two we need to go back to page one)
            matPaginator: paginator
          } as /* satisfies */ Options.Mapped['pagination'] }))
        )
      );
    }

    if (_options.sort) {
      const sort = _options.sort;
      if (sort instanceof MatSort) {
        modifiers.push(
          from(sort.sortChange)
          .pipe(
            startWith({
              active:    sort.active,
              direction: sort.direction
            }),
            map(sort => ({ sort: sort as /* satisfies */ Options.Mapped['sort'] }))
          )
        );
      } else {
        modifiers.push(of({ sort }))
      }
    }

    const onDestroy = _options.onDestroy instanceof Subject ? _options.onDestroy : new Subject<boolean>();
    const subject = new BehaviorSubject<T | null>(null);

    combineLatest([
      this._core.get(_.pick(_options, ['did', 'collection', 'coalesced', 'selectAbsences', 'start', 'end'])).pipe(filter(Boolean)),
      ...modifiers
    ])
    .pipe(
      map(_filter(_options.skipNoFilter)),
      map(_sort),
      map(_paginate),
      // potentially impacts performance as the SourceService isn't a singleton and thus the data is most likely cloned more than once
      // Rather, if the data is somewhere modified, it should be cloned there to not affect other subscribers
      // map(([docs]) => _.cloneDeep(docs)),
      map(([docs]) => docs as T),
      takeUntil(this.onDestroy),
      takeUntil(onDestroy),
    )
    .subscribe(subject);

    if (_options.onDestroy instanceof DestroyRef)
      _options.onDestroy.onDestroy(() => {
        onDestroy.next(true);
        onDestroy.complete();
        subject.next(null);
        subject.complete();
      });

    return subject;
  }

  @Performance('(Source::Set)')
  public set(_options: Omit<Options.Default, 'onDestroy'>, update: Record<string, any>): void {
    this._core.set(_options, update);
  }

  @Performance('(Source::Unset)')
  public unset(_options: Omit<Options.Default, 'onDestroy'>, id: string | string[]): void {
    this._core.unset(_options, id);
  }

  @Performance('(Source::GroupBy)')
  public groupBy(_options: Options.GroupBy): Promise<void> {
    return this._core.groupBy(_options);
  }

  public onChange<T extends DefaultCoreDataTypes = DefaultCoreDataTypes>(
    _options: Options.OnChange,
    ...observers: Observable<object>[]
  ): Observable<ChangeEvent<T> > {
    let changes: Observable<object>[] = [];

    if (observers.length)
      changes = changes.concat(
        observers.map((observer: Observable<object>) => {
          return observer.pipe(
            startWith({}),
            map(filter => ({ filter }))
          )
        })
      );


    return combineLatest([this._core.onChange(_.omit(_options, 'onDestroy')), ...changes])
    .pipe(
      /*switchMap(([docs, ...filters]: any) => {
        return new Observable<any>((observer) => {
          docs.subscribe(proxy((a: any) => observer.next([a, ...filters])));
        })
      }),*/
      map(([docs, ...filters]) => docs as ChangeEvent<T>),
      filter(Boolean),
      takeUntil(this.onDestroy),
      shareReplay({ bufferSize: 1, refCount: true })
     )
  }

  ngOnDestroy() {
    this.onDestroy.next(true);
    this.onDestroy.complete();
  }


  ////
  //// typed getters
  ////
  private getWrapper<T> (
    _options:   Options.Default,
    identifier: Is,
    ...filters: Observable<undefined | null | Filter.Type | Filter.Type[]>[]
  ): BehaviorSubject<T[]> {
    let subject = new BehaviorSubject<T[]>([]);
    this.get({..._options}, ...filters)
        .pipe(
          map(x => Array.isArray(x) ? x.map(y => Object.assign(y, { is: identifier })) : x),
        )
        .subscribe(x => subject.next(x as any));
    return subject;
  }

  public getLocations         <T = S.location          > (...[_options, ...observers]: GetArgs): BehaviorSubject<T[] | null> { return this.getWrapper<T>({..._options, collection: 'locations'         }, 'location',          ...observers); }
  public getGroups            <T = S.group             > (...[_options, ...observers]: GetArgs): BehaviorSubject<T[] | null> { return this.getWrapper<T>({..._options, collection: 'groups'            }, 'group',             ...observers); }
  public getPeriods           <T = S.period            > (...[_options, ...observers]: GetArgs): BehaviorSubject<T[] | null> { return this.getWrapper<T>({..._options, collection: 'periods'           }, 'period',            ...observers); }
  public getPersons           <T = S.person            > (...[_options, ...observers]: GetArgs): BehaviorSubject<T[] | null> { return this.getWrapper<T>({..._options, collection: 'persons'           }, 'person',            ...observers); }
  public getStudents          <T = S.person            > (...[_options, ...observers]: GetArgs): BehaviorSubject<T[] | null> { return this.getWrapper<T>({..._options, collection: 'students'          }, 'person',            ...observers); }
  public getTeachers          <T = S.teacher           > (...[_options, ...observers]: GetArgs): BehaviorSubject<T[] | null> { return this.getWrapper<T>({..._options, collection: 'teachers'          }, 'teacher',           ...observers); }
  public getCourses           <T = S.course            > (...[_options, ...observers]: GetArgs): BehaviorSubject<T[] | null> { return this.getWrapper<T>({..._options, collection: 'courses'           }, 'course',            ...observers); }
  public getEvents            <T = S.event             > (...[_options, ...observers]: GetArgs): BehaviorSubject<T[] | null> { return this.getWrapper<T>({..._options, collection: 'events'            }, 'event',             ...observers); }
  public getSchedules         <T = S.event             > (...[_options, ...observers]: GetArgs): BehaviorSubject<T[] | null> { return this.getWrapper<T>({..._options, collection: 'schedules'         }, 'event',             ...observers); }
  public getLockedTimes       <T = S.lockedTime        > (...[_options, ...observers]: GetArgs): BehaviorSubject<T[] | null> { return this.getWrapper<T>({..._options, collection: 'lockedTimes'       }, 'lockedTime',        ...observers); }
  public getOverlapGroups     <T = S.overlapGroup      > (...[_options, ...observers]: GetArgs): BehaviorSubject<T[] | null> { return this.getWrapper<T>({..._options, collection: 'overlapGroups'     }, 'overlapGroup',      ...observers); }
  public getRootInterval      <T = S.rootInterval      > (...[_options, ...observers]: GetArgs): BehaviorSubject<T[] | null> { return this.getWrapper<T>({..._options, collection: 'rootIntervals'     }, 'rootInterval',      ...observers); }
  public getCalendarExceptions<T = S.exception         > (...[_options, ...observers]: GetArgs): BehaviorSubject<T[] | null> { return this.getWrapper<T>({..._options, collection: 'exceptions'        }, 'exception',         ...observers); }
  public getSettings          <T = DivisionSettings    > (...[_options, ...observers]: GetArgs): BehaviorSubject<T   | null> { return this.get       <T>({..._options, collection: 'settings'          },                      ...observers); }
  public getDivision          <T = Division            > (...[_options, ...observers]: GetArgs): BehaviorSubject<T   | null> { return this.get       <T>({..._options, collection: 'divisions'         },                      ...observers); }
  public getGenerations       <T = S.generations       > (...[_options, ...observers]: GetArgs): BehaviorSubject<T[] | null> { return this.getWrapper<T>({..._options, collection: 'generations'       }, 'generation',        ...observers); }

  public getPopulatedLocations         <T = P.location         > (...[_options, ...observers]: GetArgs) { return this.getLocations         <T>(_options, ...observers).pipe(filter(Boolean)); }
  public getPopulatedGroups            <T = P.group            > (...[_options, ...observers]: GetArgs) { return this.getGroups            <T>(_options, ...observers).pipe(filter(Boolean)); }
  public getPopulatedPeriods           <T = P.period           > (...[_options, ...observers]: GetArgs) { return this.getPeriods           <T>(_options, ...observers).pipe(filter(Boolean)); }
  public getPopulatedPersons           <T = P.person           > (...[_options, ...observers]: GetArgs) { return this.getPersons           <T>(_options, ...observers).pipe(filter(Boolean)); }
  public getPopulatedStudents          <T = P.person           > (...[_options, ...observers]: GetArgs) { return this.getStudents          <T>(_options, ...observers).pipe(filter(Boolean)); }
  public getPopulatedTeachers          <T = P.teacher          > (...[_options, ...observers]: GetArgs) { return this.getTeachers          <T>(_options, ...observers).pipe(filter(Boolean)); }
  public getPopulatedCourses           <T = P.course           > (...[_options, ...observers]: GetArgs) { return this.getCourses           <T>(_options, ...observers).pipe(filter(Boolean)); }
  public getPopulatedEvents            <T = P.event            > (...[_options, ...observers]: GetArgs) { return this.getEvents            <T>(_options, ...observers).pipe(filter(Boolean)); }
  public getPopulatedSchedules         <T = P.event            > (...[_options, ...observers]: GetArgs) { return this.getSchedules         <T>(_options, ...observers).pipe(filter(Boolean)); }
  public getPopulatedLockedTimes       <T = P.lockedTime       > (...[_options, ...observers]: GetArgs) { return this.getLockedTimes       <T>(_options, ...observers).pipe(filter(Boolean)); }
  public getPopulatedCalendarExceptions<T = P.exception> (...[_options, ...observers]: GetArgs) { return this.getCalendarExceptions<T>(_options, ...observers).pipe(filter(Boolean)); }
  public getPopulatedOverlapGroups     <T = P.overlapGroup     > (...[_options, ...observers]: GetArgs) { return this.getOverlapGroups     <T>(_options, ...observers).pipe(filter(Boolean)); }
  public getPopulatedRootIntervals     <T = P.rootInterval     > (...[_options, ...observers]: GetArgs) { return this.getRootInterval      <T>(_options, ...observers).pipe(filter(Boolean)); }
  public getPopulatedGenerations       <T = P.generations      > (...[_options, ...observers]: GetArgs) { return this.getGenerations       <T>(_options, ...observers).pipe(filter(Boolean)); }

  public getStrictSettings   <T = DivisionSettings> (...[_options, ...observers]: GetArgs) { return this.getSettings   <T>(_options, ...observers).pipe(filter(Boolean)); }
  public getStrictDivision   <T = Division        > (...[_options, ...observers]: GetArgs) { return this.getDivision   <T>(_options, ...observers).pipe(filter(Boolean)); }
  public getStrictGenerations<T = Generation      > (...[_options, ...observers]: GetArgs) { return this.getGenerations<T>(_options, ...observers).pipe(filter(Boolean)); }


  ////
  //// typed on changers
  ////
  public onLocationsChange    <T extends DefaultCoreDataTypes = S.location      > (...[_options]: ChangeArgs): Observable<ChangeEvent<T> > { return this.onChange<T>({..._options, collection: 'locations'     }); }
  public onGroupsChange       <T extends DefaultCoreDataTypes = S.group         > (...[_options]: ChangeArgs): Observable<ChangeEvent<T> > { return this.onChange<T>({..._options, collection: 'groups'        }); }
  public onPeriodsChange      <T extends DefaultCoreDataTypes = S.period        > (...[_options]: ChangeArgs): Observable<ChangeEvent<T> > { return this.onChange<T>({..._options, collection: 'periods'       }); }
  public onPersonsChange      <T extends DefaultCoreDataTypes = S.person        > (...[_options]: ChangeArgs): Observable<ChangeEvent<T> > { return this.onChange<T>({..._options, collection: 'persons'       }); }
  public onTeachersChange     <T extends DefaultCoreDataTypes = S.teacher       > (...[_options]: ChangeArgs): Observable<ChangeEvent<T> > { return this.onChange<T>({..._options, collection: 'teachers'      }); }
  public onCoursesChange      <T extends DefaultCoreDataTypes = S.course        > (...[_options]: ChangeArgs): Observable<ChangeEvent<T> > { return this.onChange<T>({..._options, collection: 'courses'       }); }
  public onEventsChange       <T extends DefaultCoreDataTypes = S.event         > (...[_options]: ChangeArgs): Observable<ChangeEvent<T> > { return this.onChange<T>({..._options, collection: 'events'        }); }
  public onSchedulesChange    <T extends DefaultCoreDataTypes = S.event         > (...[_options]: ChangeArgs): Observable<ChangeEvent<T> > { return this.onChange<T>({..._options, collection: 'schedules'     }); }
  public onLockedTimesChange  <T extends DefaultCoreDataTypes = S.lockedTime    > (...[_options]: ChangeArgs): Observable<ChangeEvent<T> > { return this.onChange<T>({..._options, collection: 'lockedTimes'   }); }
  public onOverlapGroupsChange<T extends DefaultCoreDataTypes = S.overlapGroup  > (...[_options]: ChangeArgs): Observable<ChangeEvent<T> > { return this.onChange<T>({..._options, collection: 'overlapGroups' }); }
  public onRootIntervalsChange<T extends DefaultCoreDataTypes = S.rootInterval  > (...[_options]: ChangeArgs): Observable<ChangeEvent<T> > { return this.onChange<T>({..._options, collection: 'rootIntervals' }); }
  public onSettingsChange     <T extends DefaultCoreDataTypes = DivisionSettings> (...[_options]: ChangeArgs): Observable<ChangeEvent<T> > { return this.onChange<T>({..._options, collection: 'settings'      }); }
  public onDivisionChange     <T extends DefaultCoreDataTypes = Division        > (...[_options]: ChangeArgs): Observable<ChangeEvent<T> > { return this.onChange<T>({..._options, collection: 'divisions'     }); }
  public onGenerationsChange  <T extends DefaultCoreDataTypes = S.generations   > (...[_options]: ChangeArgs): Observable<ChangeEvent<T> > { return this.onChange<T>({..._options, collection: 'generations'   }); }


  ////
  //// typed setters
  ////
  public setEvent      (...[_options, id, data]: SetArgs<Serialized.events     >) { return this.set({..._options, collection: 'events'      }, Object.assign(data, { id })); }
  public setLockedTime (...[_options, id, data]: SetArgs<Serialized.lockedtimes>) { return this.set({..._options, collection: 'lockedTimes' }, Object.assign(data, { id })); }

}