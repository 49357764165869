import { Injectable,
         Injector                        } from '@angular/core';
import { Platform                        } from '@angular/cdk/platform';
import { HttpService                     } from 'app/core/http/http.service';
import { apiConstants                    } from 'app/constants';
import Package                             from 'app/../../package.json';
import { environment as _environment     } from 'environments';
import { Logger                          } from './logger.interface';
import { UserInactivityService           } from '../user-inactivity/user-inactivity.service';

const { version } = Package;

@Injectable({
  providedIn: 'root'
})
export class LoggerService implements Logger {
  constructor (
    private injector:  Injector,
    private _platform: Platform,
    private _http:     HttpService
  ) {
  }

  public error (
    error: unknown
  ) {
    if ( ! _environment.production) console.error(error);

    // if cypress is running we should fail the test by throwing the error
    if ('Cypress' in window && window.Cypress) throw error;

    const message = _environment.production ?
      'App encountered an error' :
      error instanceof Error ? error.message : JSON.stringify(error);
    console.log(`%cERROR  %c ${ message }`, 'background: #ff0033; color: white', 'color: #ff0033');

    this.sendError(error);
  }

  public warn (message: string): void {
    if (! _environment.production)
      console.log(`%cWARN   %c ${ message }`, 'background: orange; color: white', 'color: orange')
  }

  public verbose (message: string): void {
    if (! _environment.production && _environment.verbose)
      console.log(`%cVERBOSE%c ${ message }`, 'background: #02c7d1; color: white', 'color: #02c7d1')
  }

  public debug (message: string): void {
    if (! _environment.production)
      console.log(`%cDEBUG  %c ${ message }`, 'background: #9776f9; color: white', 'color: #9776f9')
  }

  public set numThreads (_value: number) { this._numThreads = _value; }
  private _numThreads: number;

  private _getPlatformName (): string {
    if (this._platform.ANDROID) return 'android';
    if (this._platform.BLINK)   return 'blink'; // Chrome/opera
    if (this._platform.SAFARI)  return 'safari';
    if (this._platform.EDGE)    return 'edge';
    if (this._platform.FIREFOX) return 'firefox';
    if (this._platform.TRIDENT) return 'trident'; // IE
    return 'unknown';
  }

  private _serializeError (err: unknown): string {
    if (err instanceof Error)           return err.stack || err.message;
    if (err && typeof err === 'object') return JSON.stringify(err);
    if (err && typeof err === 'string') return err;
    else                                return 'unknown';
  };

  private readonly coolDownTime = 10*1000;
  private readonly previousErrors: unknown[] = [];
  private numAllowedErrors = 10;
  private cooledDown       = true;
  private sendError (error: unknown) {
    // send only unique errors
    // (in the future we might want to share this information between tabs)
    const serializedError = this._serializeError(error);
    if (this.previousErrors.includes(serializedError)) return;
    this.previousErrors.push(serializedError);

    // check if allowed to continue
    if ( ! this.cooledDown || this.numAllowedErrors-- <= 0) return;

    // start cool down
    this.cooledDown = false;
    setTimeout(() => { this.cooledDown = true }, this.coolDownTime);

    if ( ! _environment.local /* || true */ ) {
      try {

        let message: string = '';
        let stack:   string = '';

        // if object type
        if (error && typeof error === 'object') {
          const rec = error as Record<string, unknown>;
          if ('message' in rec) {
            if (rec.message && typeof rec.message === 'string') message = rec.message;
            else                                                message = JSON.stringify(rec.message);
          }
          if ('stack' in rec) {
            if (rec.stack && typeof rec.stack === 'string') stack = rec.stack;
            else                                            stack = JSON.stringify(rec.stack);
          }
        }
        // if string type
        else if (error && typeof error === 'string') {
          message = error;
        }
        // if unknown type
        else {
          message = 'unknown';
        }

        const platform              = this._getPlatformName();
        const numThreads            = this._numThreads;

        const appVersion            = version;
        const buildTimestamp        = _environment.built ? _environment.timestamp : '-';
        const environment           = _environment.env;

        // use injector to avoid circular dependency while injecting UserInactivityService
        let inactivityExperienced: boolean | undefined;
        try { inactivityExperienced = this.injector.get(UserInactivityService).globalAuthenticatedInactivity }
        catch (err) { console.log(err); }

        const url = window.location.href;
        console.log({
          message,
          stack,
          platform,
          url,
          numThreads,
          appVersion,
          buildTimestamp,
          environment,
          inactivityExperienced
        })

        this._http.post(`${ apiConstants.APPLOGS }`, {
          message,
          stack,
          platform,
          url,
          numThreads,
          appVersion,
          buildTimestamp,
          environment,
          inactivityExperienced
        })
        .subscribe(() => {
          if (! _environment.production)
            console.log(`%cVERBOSE%c uploaded log file`, 'background: #02c7d1; color: white', 'color: #02c7d1');
        });
      } catch(err) {
        console.log(err);
      }
    }
  }

}
