import { Injectable,
         OnDestroy                        } from '@angular/core';
import { Location                         } from '@angular/common';
import { ActivatedRoute,
         Router                           } from '@angular/router';
import { Subject,
         Observable,
         BehaviorSubject                  } from 'rxjs';
import { takeUntil,
         map,
         startWith                        } from 'rxjs/operators';
import _                                    from 'lodash';
import { Util                             } from '@app/common/util';
import { AllWeeks                         } from '@app/shared/interfaces';

export type ShortName  = 'w'    | 't'        | 'g'      | 'p'       | 'l'         | 'e'      | 'b'           | 'c'       | 's'        | 'tab' | 'tag';
export type LongName   = 'week' | 'teachers' | 'groups' | 'persons' | 'locations' | 'events' | 'lockedTimes' | 'courses' | 'subjects' | 'tab' | 'tags';


/* MERGE THESE TWO QUERY STRUCTURES AS THEY NOW ARE IDENTICAL */
export type InQueryStructure = {
  teachers?:    string[];
  groups?:      string[];
  persons?:     string[];
  locations?:   string[];
  events?:      string[];
  lockedTimes?: string[];
  subjects?:    string[];
  courses?:     string[];
  tags?:        string[];

  tab?:         string;
  week?:        string;
};

export type OutQueryStructure = {
  teachers?:    string[];
  groups?:      string[];
  persons?:     string[];
  locations?:   string[];
  events?:      string[];
  lockedTimes?: string[];
  subjects?:    string[];
  courses?:     string[];
  tags?:        string[];

  tab?:         string;
  week?:        string | AllWeeks;
};

type Query = {
  [key in ShortName]?: string | string[];
};

@Injectable()
export class QueryService implements OnDestroy {
  private onDestroy = new Subject<void>();
  private _query    = new BehaviorSubject<Query>({});

  public long2short = new Map<LongName, ShortName>([
    ['teachers',    't'],
    ['groups',      'g'],
    ['persons',     'p'],
    ['locations',   'l'],
    ['events',      'e'],
    ['lockedTimes', 'b'],
    ['courses',     'c'],
    ['subjects',    's'],
    ['tags',        'tag'],
    ['tab',         'tab'],
    ['week',        'w']
  ]);
  public short2long: Map<ShortName, LongName>;

  constructor(private _location: Location,
              private _router:   Router,
              private _route:    ActivatedRoute) {

    this.short2long = new Map<ShortName, LongName>();
    this.long2short.forEach((value: ShortName, key: LongName) => {
      this.short2long.set(value, key);
    });

    this._route.queryParams
      .pipe(takeUntil(this.onDestroy))
      .subscribe(this._query);
  }

  get query (): OutQueryStructure { return this.mapQueryToStructure(this._query.value); }
  set query (x: InQueryStructure) { this.setQuery(x); }

  public setQuery (
    query:      InQueryStructure,
    replaceUrl: boolean = false
  ) {
    const queryParams = this.mapStructureToQuery(query);

    // do not set query if equal
    if (_.isEqual(queryParams, this._query.value)) return;

    // set new query param
    // this will trigger the "this._route.queryParams" observable
    this._router.navigate([], {
      queryParams, replaceUrl,
      relativeTo: this._route
    });
  }

  get params(): Query {
    return this._query.value;
  }

  public mapStructureToQuery (structure: InQueryStructure): Query {
    // must map over query to keep its order
    let out: Query = {};
    Util.functions.objectEntries(structure)
    .forEach(([key, val]) => {
      if (this.long2short.has(key)) {
        let shortName = this.long2short.get(key)!;
        out[shortName] = val;
      }
    });
    return out as Query;
  }

  public mapQueryToStructure (query: Query): OutQueryStructure {
    // must map over query to keep its order
    let out: OutQueryStructure = {};
    Util.functions.objectEntries(query)
    .forEach(([key, val]) => {
      if (this.short2long.has(key) && val.length) {
        let longName = this.short2long.get(key)!;

        if (longName == 'tab' || longName == 'week') val = _.isArray(val) ? val[0] : val;     // must not be array
        else                                         val = _.isArray(val) ? val    : [val];   // must be array

        out[longName] = val as any;
      }
    });
    return out;
  }

  public onChange (): Observable<OutQueryStructure> {
    return this._query
      .pipe(
        takeUntil(this.onDestroy),
        map(x => this.mapQueryToStructure(x))
      );
  }

  public onQuery (): Observable<OutQueryStructure> {
    return this._query
      .pipe(
        takeUntil(this.onDestroy),
        map(x => this.mapQueryToStructure(x)),
        startWith(this.mapQueryToStructure(this.query))
      );
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }
}