import { ErrorCode } from "./types";

export const minRowNumber = 3;
export const maxRowNumber = 2001;


export const settingsColumns = {
  number_of_days: 'A',
};

export const locationsColumns = {
  ID:         'A',
  foreign_ID: 'B',
  name:       'C',
};

export const groupsColumns = {
  ID:         'A',
  foreign_ID: 'B',
  name:       'C',
  is_class:   'D',
  members:    'E',
};

export const teachersColumns = {
  ID:         'A',
  foreign_ID: 'B',
  name:       'C',
};

export const personsColumns = {
  ID:         'A',
  foreign_ID: 'B',
  first_name: 'C',
  last_name:  'D',
  group:      'E',
  sex:        'F',
  SSN:        'G',
};

export const coursesColumns = {
  ID:               'A',
  foreign_ID:       'B',
  name:             'C',
  subject:          'D',
  groups:           'E',
  teachers:         'F',
  total_hours:      'G',
  minutes_per_week: 'H',
  lesson_durations: 'I',
  locations:        'J',
  overlap_group:    'K',
  merge_with:       'L',
};

export const translationCodeMapping: Record<ErrorCode, string> = {
  'missing_settings_sheet':             'maps.excel.messages.missing_settings_sheet',
  'invalid_number_of_days':             'maps.excel.messages.invalid_number_of_days',
  'missing_locations_sheet':            'maps.excel.messages.missing_locations_sheet',
  'missing_groups_sheet':               'maps.excel.messages.missing_groups_sheet',
  'missing_teachers_sheet':             'maps.excel.messages.missing_teachers_sheet',
  'ignoring_duplicate_location':        'maps.excel.messages.ignoring_duplicate_location',
  'ignoring_duplicate_group':           'maps.excel.messages.ignoring_duplicate_group',
  'ignoring_duplicate_teacher':         'maps.excel.messages.ignoring_duplicate_teacher',
  'missing_persons_sheet':              'maps.excel.messages.missing_persons_sheet',
  'invalid_group_reference':            'maps.excel.messages.invalid_group_reference',
  'invalid_sex':                        'maps.excel.messages.invalid_sex',
  'ignoring_duplicate_person':          'maps.excel.messages.ignoring_duplicate_person',
  'missing_courses_sheet':              'maps.excel.messages.missing_courses_sheet',
  'ignoring_duplicate_course':          'maps.excel.messages.ignoring_duplicate_course',
  'invalid_total_hours':                'maps.excel.messages.invalid_total_hours',
  'invalid_minutes_per_week':           'maps.excel.messages.invalid_minutes_per_week',
  'ambiguous_planned_duration':         'maps.excel.messages.ambiguous_planned_duration',
  'invalid_lesson_durations':           'maps.excel.messages.invalid_lesson_durations',
  'invalid_location_reference':         'maps.excel.messages.invalid_location_reference',
  'location_referenced_multiple_times': 'maps.excel.messages.location_referenced_multiple_times',
  'group_referenced_multiple_times':    'maps.excel.messages.group_referenced_multiple_times',
  'invalid_teacher_reference':          'maps.excel.messages.invalid_teacher_reference',
  'teacher_referenced_multiple_times':  'maps.excel.messages.teacher_referenced_multiple_times',
  'forbidden_group_reference':          'maps.excel.messages.forbidden_group_reference',
  'duplicate_group_member':             'maps.excel.messages.duplicate_group_member',
  'invalid_person_reference':           'maps.excel.messages.invalid_person_reference',
  'person_in_multiple_classes':         'maps.excel.messages.person_in_multiple_classes',
  'invalid_course_reference':           'maps.excel.messages.invalid_course_reference',
}

export const dataSheetNames = ['settings', 'locations', 'groups', 'teachers', 'persons', 'courses'] as const;