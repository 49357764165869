import _                                        from 'lodash';
import Excel                                    from 'exceljs';

import { LoggerService,
         TranslateService                     } from 'app/core';
import { LanguageConstant                     } from 'app/core/translate/types';
import { OrganizationType                     } from 'app/core/environment/environment.types';
import { Populated as P                       } from 'app/shared/interfaces';
import { coursesColumns,
         groupsColumns,
         locationsColumns,
         maxRowNumber,
         minRowNumber,
         settingsColumns,
         personsColumns,
         teachersColumns,
         translationCodeMapping,
         dataSheetNames                       } from './constants';
import { Data,
         ErrorCode,
         Logo,
         SheetName,
         DataSheetName                        } from './types';


////
//// download
////

type KeysMatching<T, V> = { [K in keyof T]-?: T[K] extends V ? K : never}[keyof T];

function appendRows <
  I          extends object,
  ColumnsMap extends Record<string, string>,
  Keys       extends KeysMatching<I, string | number | undefined | null>,
  Mapping    extends Record<keyof ColumnsMap, Keys | ((x: I) => string | number | null | undefined)>
> (
  doc:     { ws: Excel.Worksheet; valueColumns: ColumnsMap },
  data:    I[],
  mapping: Mapping,
  sortBy?: keyof Mapping & string
) {
  // convert mapping keys to column index/letter
  const columns = Object
    .entries(mapping)
    .filter((x): x is [typeof x[0], NonNullable<typeof x[1]>] => x[1] != null)
    .map(([key, value]) => ({ col: doc.valueColumns[key], key, value }));

  // map data to rows
  let rows = data
    .map(x => columns
      .map(({ col, value }) => {
        let v = typeof value === 'function'
          ? value(x)
          : value in x ? x[value] as string | number | undefined | null : null;

        // trim in case of string
        if (typeof v == 'string') v = v.trim();

        if (v == null                            ) return null;
        if (typeof v == 'string' && v.length == 0) return null;

        return { col, value: v };
      })
      .filter(Boolean)
    );

  // sort the rows
  if (sortBy) {
    // create sort-by-value and row pairs to prevent having to search for the column each comparison
    const col     = doc.valueColumns[sortBy];
    const indexed = rows.map(row => ({ val: row.find(x => x.col == col)?.value, row }));

    // sort the rows
    rows = _.orderBy(indexed, x => x.val).map(x => x.row);
  }

  // insert all rows
  rows.forEach((row, i) => {
    row.forEach(({ col, value }) => {
      doc.ws.getCell(`${col}${i + minRowNumber}`).value = value;
    });
  });
}

function langSheetPostfix (
  orgType:  OrganizationType,
  language: LanguageConstant,
) {
  if (orgType  == 'sports_facility') return 'sv_sports_facility';
  if (language == 'sv')              return 'sv_school';

  // other languages support school only
  const supportedLanguages: LanguageConstant[] = ['en', 'de', 'es'];
  return supportedLanguages.includes(language) ? language : 'en';
}


export function prepareTemplate (
  wb:              Excel.Workbook,
  logo:            Logo,
  orgType:         OrganizationType,
  language:        LanguageConstant,
  logger:          LoggerService,
  translate:       TranslateService,
  data?:           Data,
  readonlySheets?: DataSheetName[]
): Excel.Workbook | Error {
  const languageSheetName = 'language_' + langSheetPostfix(orgType, language);
  const ws_language     = wb.worksheets.find(x => x.name == languageSheetName                     );
  const ws_meta         = wb.worksheets.find(x => x.name == 'meta'         satisfies SheetName    );
  const ws_instructions = wb.worksheets.find(x => x.name == 'instructions' satisfies SheetName    );
  const ws_settings     = wb.worksheets.find(x => x.name == 'settings'     satisfies DataSheetName);
  const ws_locations    = wb.worksheets.find(x => x.name == 'locations'    satisfies DataSheetName);
  const ws_teachers     = wb.worksheets.find(x => x.name == 'teachers'     satisfies DataSheetName);
  const ws_groups       = wb.worksheets.find(x => x.name == 'groups'       satisfies DataSheetName);
  const ws_persons      = wb.worksheets.find(x => x.name == 'persons'      satisfies DataSheetName);
  const ws_courses      = wb.worksheets.find(x => x.name == 'courses'      satisfies DataSheetName);

  // ensure that all sheets exist
  if ( ! ws_language    ) return new Error(`No language sheet of the name ${languageSheetName}`);
  if ( ! ws_meta        ) return new Error('No meta sheet'        );
  if ( ! ws_instructions) return new Error('No instructions sheet');
  if ( ! ws_settings    ) return new Error('No settings sheet'    );
  if ( ! ws_locations   ) return new Error('No locations sheet'   );
  if ( ! ws_groups      ) return new Error('No groups sheet'      );
  if ( ! ws_teachers    ) return new Error('No teachers sheet'    );
  if ( ! ws_persons     ) return new Error('No persons sheet'     );
  if ( ! ws_courses     ) return new Error('No courses sheet'     );

  const instructions = { ws: ws_instructions, originalName: 'instructions' as const satisfies SheetName,                                     instructions: { dest: 'B8', src: 'B3' }, nameSrc: 'B2'                       };
  const settings     = { ws: ws_settings,     originalName: 'settings'     as const satisfies DataSheetName, valueColumns: settingsColumns,  instructions: { dest: 'E3', src: 'H3' }, nameSrc: 'H2', columnNamesSrc: 'H5' };
  const locations    = { ws: ws_locations,    originalName: 'locations'    as const satisfies DataSheetName, valueColumns: locationsColumns, instructions: { dest: 'G3', src: 'E3' }, nameSrc: 'E2', columnNamesSrc: 'E5' };
  const groups       = { ws: ws_groups,       originalName: 'groups'       as const satisfies DataSheetName, valueColumns: groupsColumns,    instructions: { dest: 'I3', src: 'D3' }, nameSrc: 'D2', columnNamesSrc: 'D5' };
  const teachers     = { ws: ws_teachers,     originalName: 'teachers'     as const satisfies DataSheetName, valueColumns: teachersColumns,  instructions: { dest: 'G3', src: 'C3' }, nameSrc: 'C2', columnNamesSrc: 'C5' };
  const persons      = { ws: ws_persons,      originalName: 'persons'      as const satisfies DataSheetName, valueColumns: personsColumns,   instructions: { dest: 'K3', src: 'F3' }, nameSrc: 'F2', columnNamesSrc: 'F5' };
  const courses      = { ws: ws_courses,      originalName: 'courses'      as const satisfies DataSheetName, valueColumns: coursesColumns,   instructions: { dest: 'P3', src: 'G3' }, nameSrc: 'G2', columnNamesSrc: 'G5' };

  // make sheets readonly by locking value column cells
  // (need to unlock even if not locked in the template as they for some reason become locked otherwise...)
  [settings, locations, groups, teachers, persons, courses].forEach(x => {
    const locked = !! readonlySheets?.includes(x.originalName);
    Object.values(x.valueColumns).forEach(col => {
      for (let r = minRowNumber; r < maxRowNumber + 1; r++) {
        x.ws.getCell(`${col}${r}`).protection = { locked };
      }
    });
  });

  // hide columns and rows
  [settings, locations, groups, teachers, persons, courses].forEach(x => {
    x.ws.columns.forEach((c, i) => c.hidden = i >= Object.values(x.valueColumns).length + 12);

    // set validation columns width to zero to hide them
    // (cannot hide validation columns as they no longer will updated for some reason)
    // APPARENTLY NOT...
    // Object.values(x.validationColumns).forEach((column) => x.ws.getColumn(column).width = 0);

    x.ws.getRow(1).hidden = true;
  });

  // copy instructions
  [instructions, settings, locations, groups, teachers, persons, courses].forEach(x => {
    const val = ws_language.getCell(x.instructions.src).value;

    // attempt to prepend a readonly message to the instructions for readonly sheets
    if ( val && typeof val == 'object' && 'richText' in val
      && readonlySheets?.includes(x.originalName as unknown as DataSheetName)
    ) {
      const font = val.richText.at(0)?.font;
      val.richText.unshift({
        font: { ...font, color: { argb: 'FFC00D09' } },
        text: translate.instant('shared.services.excel-template.template.readonly') + '\n\n'
      });
    }

    x.ws.getCell(x.instructions.dest).value = val;
  });

  // add logo
  instructions.ws.getCell('B2').value = '';
  const imgId = wb.addImage({ buffer: logo.buffer, extension: 'png' });
  const ext = { height: 80, width: 80 * logo.dimensions.width / logo.dimensions.height };
  instructions.ws.addImage(imgId, { tl:  { col: 1, row: 1 }, ext });

  // rename or hide sheets
  [instructions, settings, locations, groups, teachers, persons, courses].forEach(x => {
    const val = ws_language.getCell(x.nameSrc).value;
    if (typeof val == 'string' && val.trim().length) {
      // problems occur in case of the same name as the original sheet name
      // -> first rename to a temporary name and then to the desired name
      x.ws.name = Math.random().toString(36).substring(2, 15);
      x.ws.name = val.trim();
    }
    else {
      x.ws.state = 'hidden';
    }
  });

  // rename or hide columns
  [settings, locations, groups, teachers, persons, courses].forEach(({ ws, columnNamesSrc, originalName }) => {
    const columnsStr = ws_language.getCell(columnNamesSrc).value;
    if ( ! columnsStr || typeof columnsStr !== 'string') {
      logger.error(`Invalid column names for ${ws.name}`);
      return;
    }

    columnsStr
    .split(',')
    .map(x => x.trim())
    .forEach((x, c) => {
      // store the original sheet and column name in a hidden row
      const cell = ws.getCell(1, c + 1);
      if (typeof cell.value === 'string') cell.value = originalName + '.' + cell.value.toString().trim();

      // hidden if empty string or the name contains "[hidden]"
      const hidden = x.includes('[hidden]') || ! x.trim().length;
      if (hidden) ws.getColumn(c + 1).hidden = true;

      // remove "[hidden]" from the column name and trim
      const name = x.replace('[hidden]', '').trim();
      ws.getCell(2, c + 1).value = name
    });
  });

  // remove all language sheets
  wb.eachSheet(ws => ws.name.startsWith('language_') && wb.removeWorksheet(ws.id));

  // ensure that the meta sheet is hidden
  ws_meta.state = 'hidden';

  // loop over all courses and extract overlap group ids which are mapped to indices starting at 1
  const overlapGroupIdToKeyMap = new Map<string | undefined, number>();
  data?.courses.forEach(x => {
    if ( ! x.overlapGroup                            ) return;
    if (overlapGroupIdToKeyMap.has(x.overlapGroup.id)) return;
    overlapGroupIdToKeyMap.set(x.overlapGroup.id, overlapGroupIdToKeyMap.size + 1);
  });

  // create id map of persons for easy access
  const personsIdMap = new Map(data?.persons?.map(x => [x.id, x]));

  // insert cell values
  if (data) {
    appendRows(settings,  [data.settings], { number_of_days: 'numDays' });
    appendRows(locations, data.locations, { ID: 'id', foreign_ID: 'ids', name: 'displayName' }, 'name');
    appendRows(teachers,  data.teachers,  { ID: 'id', foreign_ID: 'ids', name: 'displayName' }, 'name');
    appendRows(groups,    data.groups,    {
      ID:         'id',
      foreign_ID: 'ids',
      name:       'displayName',
      is_class:   x => x.species == 'class' ? '1' : '',
      members:    x => x.members?.map(x => personsIdMap.get(x.id)?.SSN?.value || 'unknown')?.join(', ')
    }, 'name');
    appendRows(persons, data.persons, {
      ID:         'id',
      foreign_ID: 'ids',
      first_name: 'firstName',
      last_name:  'lastName',
      group:      x => x.group?.displayName,
      sex:        'sex',
      SSN:        x => x.SSN?.value
    }, 'last_name');
    appendRows(courses, data.courses, {
      ID:               'id',
      foreign_ID:       'ids',
      name:             'displayName',
      subject:          'subject',
      total_hours:      x => x.plannedDuration?.includes('hrs')      ? x.plannedDuration.split(' ')[0] : '',
      minutes_per_week: x => x.plannedDuration?.includes('min/week') ? x.plannedDuration.split(' ')[0] : '',
      groups:           x => x.groups  ?.map(x => x.to.displayName).sort().join(', '),
      teachers:         x => x.teachers?.map(x => x.to.displayName).sort().join(', '),
      locations:        x => _(x.locations)
                                .groupBy(x => x.groupIndex)
                                .mapValues(x => x
                                  .map(x => x.locations?.[0].displayName)
                                  .filter(Boolean)
                                  .sort()
                                  .join(', ')
                                )
                                .values()
                                .join(' + '),
      lesson_durations: x => x.events?.map(x => x.preferredDuration).sort().join(', '),
      overlap_group:    x => overlapGroupIdToKeyMap.get(x.overlapGroup?.id),
      merge_with:       () => undefined   // function not implemented for export purposes
    }, 'name');
  }

  return wb;
}


export function schoolDummyData (): Data {
  const division = { id: 'A', belongsTo: '' };
  const locations = [
    { id: '', belongsTo: division, displayName: 'A1' },
    { id: '', belongsTo: division, displayName: 'A2' },
  ];
  const teachers = [
    { id: '', belongsTo: division, displayName: 'NiKa' },
    { id: '', belongsTo: division, displayName: 'EmBl' },
  ];
  const groups = [
    { id: '', belongsTo: division, displayName: '3A', species: 'class' },
    { id: '', belongsTo: division, displayName: '3B', species: 'class' },
    { id: '', belongsTo: division, displayName: '4A', species: 'class' },
    { id: '', belongsTo: division, displayName: '4B', species: 'class' },
  ];
  const persons: P.person[] = [
    { id: '', belongsTo: division, firstName: 'Karl', lastName: 'Andersson', sex: 'Man',   SSN: { value: '19930101-0001' }, group: groups[0] },
    { id: '', belongsTo: division, firstName: 'Anna', lastName: 'Petterson', sex: 'Woman', SSN: { value: '19920101-0000' }, group: groups[2] },
  ];
  const events: P.event[] = [
    { id: '', belongsTo: division, preferredDuration: 45 },
    { id: '', belongsTo: division, preferredDuration: 60 },
  ];

  const data: Data = {
    division: division as any,
    settings: { numDays: 5 },
    locations: locations,
    teachers: teachers,
    groups: groups,
    persons: persons,
    courses: [
      { id: '', displayName: 'Matematik 3', subject: 'Matematik', plannedDuration: '100 hrs',      groups: [{ to: groups[0] }, { to: groups[1] }], teachers: [{ to: teachers[0] }], locations: [{ groupIndex: 0, locations: [locations[0]] }], events: [events[1], events[1]]                                  },
      { id: '', displayName: 'Svenska 4',   subject: 'Svenska',   plannedDuration: '225 min/week', groups: [{ to: groups[2] }, { to: groups[3] }], teachers: [{ to: teachers[1] }], locations: [{ groupIndex: 0, locations: [locations[1]] }], events: [events[0], events[0], events[0], events[0], events[0]] },
    ]
  };

  return data;
}


export function sportsFacilityDummyData (): Data {
  const division = { id: 'A', belongsTo: '' };
  const locations = [
    { id: '', belongsTo: division, displayName: 'Plan A' },
    { id: '', belongsTo: division, displayName: 'Rink 3' },
  ];
  const events: P.event[] = [
    { id: '', belongsTo: division, preferredDuration: 45 },
    { id: '', belongsTo: division, preferredDuration: 60 },
  ];

  const data: Data = {
    division: division as any,
    settings: { numDays: 7 },
    locations: locations,
    teachers: [],
    groups: [],
    persons: [],
    courses: [
      { id: '', displayName: 'Fotboll', subject: 'IFK Norrköping', plannedDuration: '100 hrs',      locations: [{ groupIndex: 0, locations: [locations[0]] }], events: [events[1], events[1]]                                  },
      { id: '', displayName: 'Hockey',  subject: 'Hästen hockey',  plannedDuration: '225 min/week', locations: [{ groupIndex: 0, locations: [locations[1]] }], events: [events[0], events[0], events[0], events[0], events[0]] },
    ]
  };

  return data;
}


////
//// upload
////

export function parseSheet (ws: Excel.Worksheet) {
  // include all columns until the first one without a value, i.e., empty value at row 1
  let columns: string[] = [];
  let maxRowNumber = 0;
  while (true) {
    const cell = ws.getCell(1, columns.length + 1);
    const val = cell.value;
    if ( ! val || typeof val != 'string') break;

    maxRowNumber = Math.max(maxRowNumber, ws.getColumn(columns.length + 1).values.length);
    columns.push(val);
  }

  // if all names contain a common prefix it is the original file name and if so is the case we remove it from the column names
  const prefixes = columns.map(x => x.includes('.') ? x.split('.')[0] : undefined);
  const originalName = prefixes.every(x => x == prefixes[0]) ? prefixes[0] : undefined;
  if (originalName) columns = columns.map(x => x.replace(originalName + '.', ''));

  // parse all rows
  const rows: Record<string, any>[] = [];
  for (let r = minRowNumber; r < maxRowNumber; r++) {
    const row: Record<string, string> = {};
    for (let c = 1; c < columns.length + 1; c++) {
      const cell = ws.getCell(r, c);
      if (cell.value) row[columns[c - 1]] = cell.value.toString();
    }
    rows.push(row);
  }

  return { name: originalName ?? ws.name, rows };
}

export function parseWorkbook (
  wb: Excel.Workbook
): Record<string, Record<string, any>[]> {
  const entries = wb.worksheets
    .map(x => parseSheet(x))
    .filter(x => x.name == 'meta' || dataSheetNames.includes(x.name as unknown as DataSheetName))
    .map(x => [x.name, x.rows])

  return Object.fromEntries(entries);
}



export function translateMessages (
  messages:  (string | { code: string; context?: string })[],
  translate: TranslateService
): string[] {
  return messages.map(x => {
    const code = (typeof x == 'string' ? x : x.code) as ErrorCode;
    const key  = translationCodeMapping[code] ?? code;

    return translate.instant(key, typeof x == 'string' ? undefined : { context: x.context });
  });
}