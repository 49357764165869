import { coerceBooleanProperty           } from '@angular/cdk/coercion';
import { Component,
        ChangeDetectionStrategy,
         Input                           } from '@angular/core';
import { TranslateService                } from '@app/core';
import { DisplayNamePipe                 } from '@app/shared/pipes/common/common.pipe';

import { Subject                         } from 'rxjs';
import _                                   from 'lodash';

import { Types,
         Value                           } from '../../groups.component';

@Component({
  selector: 'app-form-field-groups-display-value',
  templateUrl: './display-value.component.html',
  styleUrls: ['./display-value.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DisplayValueComponent {
  public inherits: boolean;
  public label:    Subject<string> = new Subject<string>();

  public tooltip:  string;

  constructor (private _translate:   TranslateService,
               private _displayName: DisplayNamePipe) {
  }

  ngAfterViewInit() {
    this._updateLabel()
  }

  private _wrapContent(content: number | string, className?: string): string {
    return `<span ${ className ? ('class=\"' + className + '\"') : '' }>${ content }</span>`;
  }

  private _getLabel(_value: Value[] | null, _coalescedValue?: Value[] | null): [string, string] {
    return [
      /*
        Label
      */
      (_value ?? [])
      // according to its type "_value[number]" should not be undefined, but it sometimes is...
      // (https://app.royalschedule.com/app-logs/6409f2dcdb681a47f19a1fa1)
      .filter(Boolean)
      .map(x => {
        const name = this._displayName.transform('to' in x ? x.to : x);

        if (! ('exclude' in x))
          return name;

        const set = new Set<string>(_.get(x, ['to', 'members'])?.map((y: Types) => y.id));

        const excluded = ('exclude' in x ? x.exclude?.filter(x => set.has(x.id)).length : false);
        return `${ name }${ excluded ? this._wrapContent('-' + excluded, 'superscript') : '' }`
      }).concat(
        _.values(_.groupBy(_coalescedValue ?? [], 'to.group.id'))
        .map(x => {
          const val  = _.get(x[0], ['to', 'group']);

          if (! val)
            return this._wrapContent(`${ x.length } ${ this._translate.instant('common.persons') }`, 'inherits');

          return this._wrapContent(this._displayName.transform(val) + this._wrapContent(x.length, 'superscript'), 'inherits');
        })
      ).join(', '),
      /*
        Tooltip
      */
      (_value ?? [])
      .filter(Boolean)
      .map(x => {
        const name = this._displayName.transform('to' in x ? x.to : x);

        if (! ('exclude' in x))
          return name;

        const set = new Set<string>(_.get(x, ['to', 'members'])?.map((y: Types) => y.id));
        const excluded = ('exclude' in x ? x.exclude?.filter(x => set.has(x.id)) : []);
        return `${ name } ${ excluded?.length ? '-' : '' }  ${excluded?.map(y => `${ this._displayName.transform(y) }`).join(', ') }`
      }).concat(
        _.values(_.groupBy(_coalescedValue ?? [], 'to.group.id'))
        .map(x => {
          const val  = _.get(x[0], ['to', 'group']);
          const name = val ? `(${ this._displayName.transform(val) })` : '•';
          return `${ name } ${ x.map(y => this._displayName.transform('to' in y ? y.to : y)).join(', ') }`;
        })
      ).join('\n\n')
    ]
  }

  private _updateLabel(): void {
    if ( (   this.inherit && this.value)
      || ( ! this.inherit && (this.value != null || this._coalescedValue != null)) )
    {
      //this.inherits = false;
      const [label, tooltip] = this._getLabel(this._value, this._coalescedValue);
      this.label.next(label);
      this.tooltip = tooltip
      return;
    }

    //this.inherits = true;
    const [label, tooltip] = this._getLabel(this._inheritedValue, this._inheritedCoalescedValue);
    this.label.next(label);
    this.tooltip  = tooltip;
  }

  @Input()
  get value(): Value[] | null { return this._value; }
  set value(value: Value | Value[] | null) {
    if (value === undefined) return;
    this._value = Array.isArray(value) ? value : (value === null ? null: [value]);
  }
  private _value: Value[] | null;

  @Input()
  get inheritedValue(): Value[] | null { return this._inheritedValue; }
  set inheritedValue(value: Value | Value[] | null) {
    if (value === undefined) return;
    this._inheritedValue = Array.isArray(value) ? value : value === null ? null : [value];
  }
  private _inheritedValue: Value[] | null;

  @Input()
  get coalescedValue(): Value[] | null { return this._coalescedValue; }
  set coalescedValue(value: Value | Value[] | null) {
    if (value === undefined) return;
    this._coalescedValue = Array.isArray(value) ? value : value === null ? null : [value];
  }
  private _coalescedValue: Value[] | null;

  @Input()
  get inheritedCoalescedValue(): Value[] | null { return this._inheritedCoalescedValue; }
  set inheritedCoalescedValue(value: Value | Value[] | null) {
    if (value === undefined) return;
    this._inheritedCoalescedValue = Array.isArray(value) ? value : value == null ? null : [value];
  }
  private _inheritedCoalescedValue: Value[] | null;

  @Input()
  get voidText(): string { return this._voidText; }
  set voidText(value: string) {
    this._voidText = value;
  }
  private _voidText: string = '';

  @Input()
  get disableTooltip(): boolean { return this._disableTooltip; }
  set disableTooltip(value: boolean) {
    this._disableTooltip = coerceBooleanProperty(value);
  }
  private _disableTooltip: boolean = false;

  @Input()
  get inherit(): boolean { return this._inherit; }
  set inherit(value: boolean | string) {
    this._inherit = coerceBooleanProperty(value);
  }
  private _inherit: boolean = false;

}
