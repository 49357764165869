import { NgModule, Optional, SkipSelf } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { RoutingService } from 'app/core/routing/routing.service';
import { voidRoutes } from 'app/core/routing/routes';

@NgModule({
  imports: [
    RouterModule.forRoot(voidRoutes, {
    onSameUrlNavigation: 'reload',
    paramsInheritanceStrategy: 'always'
}),
  ],
  providers: [
    RoutingService
  ],
  exports: [
    RouterModule
  ]
})
export class RoutingModule {
  constructor(@Optional() @SkipSelf() core: RoutingModule) {
    if (core) {
      throw new Error('Routing run multiple times');
    }
  }
}