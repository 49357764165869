import { DateSpanApi,
         DayHeaderContentArg,
         EventApi,
         EventClickArg,
         EventContentArg,
         EventDropArg,
         EventHoveringArg,
         EventMountArg                                      } from '@fullcalendar/core';
import { DateClickArg,
         EventDragStartArg,
         EventDragStopArg,
         EventReceiveArg,
         EventResizeDoneArg                                 } from '@fullcalendar/interaction';
import { BaseOptionsRefined,
         CalendarListenerRefiners                           } from '@fullcalendar/core/internal';
import moment                                                 from 'moment';
import $                                                      from 'jquery';
import _                                                      from 'lodash';

import { CommonService                                      } from '@app/shared/services';
import { CalendarComponent                                  } from './calendar.component';
import { _EventSource,
         ExtendedProperties,
         isEvent,
         isLockedTime,
         ForegroundEvent,
         BackgroundEvent                                    } from './types';
import { Util                                               } from '@app/common';


// interface that ensures that the handler methods are correctly implemented
type Interface = {
  [K in keyof CalendarListenerRefiners]?: ReturnType<CalendarListenerRefiners[K]>;
} & {
  [K in keyof BaseOptionsRefined]?: BaseOptionsRefined[K];
}


export class Handler implements Interface {

  ////
  //// Event Render Hooks
  ////

  /**
   * @summary a ClassName Input for adding classNames to the outermost event element.
   * If supplied as a callback function, it is called every time the associated event data changes
   * */
  eventClassNames (
    this: CalendarComponent,
    arg:  EventContentArg
  ): string | string[] {
    let classes: string[] = [];

    // highlight the selected event by appending a class
    if (this.selectedEvents.value.some(id => id == arg.event.id)) classes.push('selected-event-active');

    const source = arg.event.extendedProps.source as (ForegroundEvent | BackgroundEvent)['extendedProps']['source'];
    if ('id' in source && isEvent(source)) {
      // is event

      // indicate overlapping events
      if (this.selectedOverlapGroup && this.selectedOverlapGroup == source.course?.overlapGroup?.id) {
        classes.push('indicate-overlap-group');
      }

      // indicate forced overlapping events
      if (this.selectedOverlapSpecies && this.selectedOverlapSpecies == source.overlapSpecies?.species?.find(x => x.to.id == source.id)?.id) {
        classes.push('indicate-overlap-species');

        // indicate what events are currently glued and will be dragged together
        if (this.selectedEvents.value.length == 1) {
          const day = moment.utc(this.foregroundEvents.value.find(x => x.id == this.selectedEvents.value[0])?.start).startOf('day');
          if (moment.utc(source.start).startOf('day').isSame(day)) {
            classes.push('indicate-overlap-species-active');
          }
        }
      }
    }

    return classes;
  }

  /**
   * @summary called right after the element has been added to the DOM. If the event data changes, this is NOT called again.
   * */
  eventDidMount (
    this: CalendarComponent,
    arg:  EventMountArg
  ): void {
    const { el, event, backgroundColor    } = arg;
    const { title, display, extendedProps } = event;
    const { description, colors, source   } = extendedProps as ExtendedProperties & { source: _EventSource };

    // jquery selectors
    const $fcEvent               = $(el);
    const $fcEventMain           = $(el.querySelector('.fc-event-main')!);
    const $fcEventTitle          = $(el.querySelector('.fc-event-title')!);
    const $fcEventTitleContainer = $(el.querySelector('.fc-event-title-container')!);

    // is a background event
    if (display == 'background') {
      // add description to background events
      if (title && description) $fcEventTitle.append(`<br>${ description }`);
      return;
    }

    // add small icons container
    $fcEventMain.append('<div class="small-icons-container"></div>');
    const $smallIconsContainer = $fcEventMain.find('.small-icons-container');

    // add fixed event pin icon
    if ('fixedStart' in source && source.fixedStart) {
      $smallIconsContainer.append(`<span class="material-icons">push_pin</span>`);
    }

    // forced overlapping icon
    if (isEvent(source) && source.overlapSpecies) {
      $smallIconsContainer.append(`<span class="material-icons overlap-species-indicator">link</span>`);
    }

    // overlap group icon
    if (isEvent(source) && source.course?.overlapGroup) {
      const index = this.overlapGroups.indexOf(source.course.overlapGroup.id) + 1;
      $smallIconsContainer.append(`<span class="material-icons">filter_${ index < 10 ? index : '9_plus' }</span>`);
    }

    // try override background color with multiple colors
    if (colors?.length) {
      if (colors.length == 1) {
        $fcEvent.css('background-color', colors[0]);
      } else {
        const dist = 10;
        const stripes = (colors as string[]).flatMap((c, i) => [`${c} ${i*dist}px`, `${c} ${(i+1)*dist}px`]).join(',');
        $fcEvent.css('background', `repeating-linear-gradient(45deg, ${stripes})`);
      }
    }

    // set text color based on the background color
    {
      let textColor: string;
      const opacity = parseFloat(getComputedStyle(el).opacity);
      if (colors?.length) {
        // majority rule
        let textColors = colors.map(c => CommonService.contrastService(c, opacity))
        textColor = _(textColors).countBy().entries().maxBy(_.last)![0]
      } else if (backgroundColor && backgroundColor != 'undefined') {
        textColor = CommonService.contrastService(backgroundColor, opacity);
      } else {
        textColor = '#000000';
      }
      $fcEventMain.addClass(textColor == '#000000' ? 'dark-text' : 'bright-text');
    }

    if (isEvent(source) && (source.period || this.defaultPeriod)) {
      $fcEventTitleContainer.append(`<div class="fc-event-period">${ source.period?.displayName ?? this.defaultPeriod?.displayName }</div>`);
    }

    // add in locations, groups, and teachers as description
    if (isLockedTime(source)) {
      const descr = (source.coalesced ?? [])
        .map(x => this._displayNamePipe.transform(x.to))
        .join(', ');
      $fcEventTitleContainer.append(`<div class="fc-event-description">${ descr }</div>`);
    } else if (isEvent(source)) {
      const descr = [...source.teachers ?? [], ...source.groups ?? [], ...source.inLocations ?? []]
        .filter(Boolean)
        .map(x => this._displayNamePipe.transform(x == null ? null : ('to' in x ? x.to : x)))
        .join(', ');
      $fcEventTitleContainer.append(`<div class="fc-event-description">${ descr }</div>`);
    }

    // lunch icon
    if (isLockedTime(source) && source.type === 'LUNCH' && ($fcEventMain.innerWidth() ?? 0) > 100)
      $fcEventMain.append(`<span class="material-icons large">dining</span>`);

    // lock icon
    if (isLockedTime(source) &&source.type !== 'LUNCH' && ($fcEventMain.innerWidth() ?? 0) > 100)
      $fcEventMain.append(`<span class="material-icons large">lock</span>`);
  }

  ////
  //// Event Dragging & Resizing
  ////

  /**
   * @summary Triggered when event dragging begins.
   * */
  eventDragStart (
    this: CalendarComponent,
    arg:  EventDragStartArg
  ): void {
    //set dragged event
    this.draggedEvent = this.getEventSource(arg.event).id;

    // try select event
    if (this.options?.editMode || this.options?.selectMode) {
      const source = this.getEventSource(arg.event);
      if (this.isSelectableEvent(source)) this._selectEvent(source.id, 'set');
      else                                this._selectEvent(null,      'set');
    }

    this._onEventDragStart.next({ source: this.getEventSource(arg.event) });
  }

  /**
   * @summary Triggered when event dragging stops.
   * */
  eventDragStop (
    this: CalendarComponent,
    arg:  EventDragStopArg
  ): void {
    // reset
    this.draggedEvent = undefined;

    this._onEventDragStop.next({ source: this.getEventSource(arg.event), jsEvent: arg.jsEvent });
  }

  /**
   * @summary Triggered when dragging stops and the event has moved to a different day/time.
   * */
  eventDrop (
    this: CalendarComponent,
    arg:  EventDropArg
  ): void {
    // need to update the event source with new positions as these the source is sent onwards
    this.updateEventSource(arg.event);

    this._onEventDrop.next({
      source:      this.getEventSource(arg.event),
      oldPosition: _.pick(arg.oldEvent, 'start', 'end'),
      jsEvent:     arg.jsEvent
    });
  }

  /**
   * @summary Called when an external draggable element with associated event data was dropped
   * onto the calendar. Or an event from another calendar.
   * */
  eventReceive (
    this: CalendarComponent,
    arg:  EventReceiveArg
  ): void {
    // need to update the event source with new positions as these the source is sent onwards
    this.updateEventSource(arg.event);

    // emit
    this._onEventReceive.next({ source: this.getEventSource(arg.event) });
  }


  /**
   * @summary Triggered when resizing stops and the event has changed in duration.
   * */
  eventResize (
    this: CalendarComponent,
    arg:  EventResizeDoneArg
  ): void {
    this.updateEventSource(arg.event);
    this._onEventResize.next({ source: this.getEventSource(arg.event) });
  }

  /**
   * @summary Exact programmatic control over where an event can be dropped.
   * */
  eventAllow (
    this:        CalendarComponent,
    arg:         DateSpanApi,
    movingEvent: EventApi | null
  ): boolean {
    return moment.utc(arg.start).day() === moment.utc(arg.end).day();
  }

  ////
  //// mouse events
  ////

  /**
   * @summary Triggered when the user clicks an event.
   * */
  eventClick(
    this: CalendarComponent,
    arg:  EventClickArg
  ): void {
    // try select event
    if (this.options?.editMode || this.options?.selectMode) {
      const source = this.getEventSource(arg.event);
      const isSelectableEvent = this.isSelectableEvent(source);
      if (this.multiselectMode && this.multipleSelect.value) {
        if (isSelectableEvent) this._selectEvent(source.id, 'multiselect');
      } else {
        if (isSelectableEvent) this._selectEvent(source.id);
        else                   this._selectEvent(null);
      }
    }

    // open popover (will deselect event when closed)
    if (this.options.openEventPopOverOnClick) {
      this._selectEvent(this.getEventSource(arg.event).id, 'set');
      this.openPopOver(arg.event, arg.jsEvent)
    }
  }

  /**
   * @summary Triggered when the user mouses over an event. Similar to the native mouseenter.
   * */
  eventMouseEnter (
    this: CalendarComponent,
    arg: EventHoveringArg
  ): void {
    this._onEventMouseEnter.next({ source: this.getEventSource(arg.event) });
  }

  /**
   * @summary Triggered when the user mouses out of an event. Similar to the native mouseleave.
   * */
  eventMouseLeave (
    this: CalendarComponent,
    arg: EventHoveringArg
  ): void {
    this._onEventMouseLeave.next({ source: this.getEventSource(arg.event) });
  }

  /**
   * @summary Triggered when the user clicks on a date or a time.
   * */
  dateClick (
    this: CalendarComponent,
    arg:  DateClickArg
  ): void {
    // the calendar background was clicked, deselect the selected event
    this._selectEvent(null);
  }

  dayHeaderContent (
    this: CalendarComponent,
    arg:  DayHeaderContentArg
  ) {
    // if no week is selected, show only the day name
    if ( ! this.selectedWeek) {
      return arg.date.toLocaleString(this._translate.currentLanguage?.id, { weekday: 'short' });
    }

    // otherwise show the day name and the date of the month
    // (the week id is the first day (monday) of the week)
    const date = new Date(this.selectedWeek.id)
    date.setDate(date.getDate() + Util.functions.getDayIndex(arg.date));
    return date.toLocaleString(this._translate.currentLanguage?.id, { weekday: 'short', day: 'numeric' });
  }

}
