import { style, animate, trigger, transition, } from '@angular/animations';


export const inAnimation = trigger('inAnimation', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('0.5s ease-in', style({ opacity: 1 }))
  ])
]);


export const inOutAnimation = trigger('inOutAnimation', [
  transition(':enter', [
    style({ opacity: 0}),
    animate('80ms', style({ opacity: 1 }))
  ]),
  transition(':leave', [
    style({ opacity: 1}),
    animate('120ms', style({ opacity: 0 }))
  ])
]);


export const delayInOutAnimation = trigger('delayInOutAnimation', [
  transition(':enter', [
    style({ opacity: 0}),
    animate('80ms 600ms', style({ opacity: 1 }))
  ]),
  transition(':leave', [
    style({ opacity: 1}),
    animate('120ms', style({ opacity: 0 }))
  ])
]);