<h1 mat-dialog-title class="royal-text-primary">
  {{ 'tables.admin-tables.organizations.app_features.title' | translate }}
</h1>

<mat-dialog-content>
  <form [formGroup]="form">
    <mat-slide-toggle formControlName="substitutionPlanner">
      {{ 'tables.admin-tables.organizations.app_features.substitution_planner' | translate }}
    </mat-slide-toggle>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close type="button">
    {{ 'common.close' | translate }}
  </button>
  <button mat-button
          color="primary"
          type="submit"
          [disabled]="! form.valid || form.pristine"
          (click)="submit()">
    {{ 'common.save' | translate }}
  </button>
</mat-dialog-actions>