import { Subject,
         take,
         takeUntil,
         timer                           } from 'rxjs';
import moment                              from 'moment';

import { AuthService                     } from './auth.service';


export function _check (this: AuthService): void {
  // check each session
  const sessions = this._loadAndValidateSessions();
  sessions.forEach(x => {

    // the access token is valid if it exists and has not expired
    const now            = moment();
    const expirationDate = moment(x.expiresAt);
    const expired        = ! expirationDate.isValid() || now.isAfter(expirationDate);
    const invalidToken   = ! x.accessToken || expired;

    // if the toke is invalid...
    if (invalidToken) {
      // ... but there is a refresh token -> ask the main thread to renew the token automatically
      if (x.refreshToken) return this._renewAccess(x.refreshToken);
      // ... and there is no refresh token -> open the login dialog in all tabs
      else                return this._openRenewDialog();
    }

    // the token is deemed to be valid, thus we schedule a renew just before it is due to expire

    // may only schedule one renewal per token
    if (this.scheduledTokenRenewals.has(x.accessToken)) return;
    const onComplete = new Subject<void>();
    this.scheduledTokenRenewals.set(x.accessToken, onComplete);

    const expiresIn = expirationDate.subtract(1, 'second').diff(now, 'milliseconds');
    timer(expiresIn)
    // timer(Math.min(expiresIn, 5000))   // for testing
    .pipe(
      takeUntil(onComplete),
      take(1)
    )
    .subscribe(() => {
      if (x.refreshToken) this._renewAccess(x.refreshToken);
      else                this._openRenewDialog();
    });

  });
}