import { Injectable                              } from '@angular/core';
import { TranslateService as NgxTranslateService } from '@ngx-translate/core';
import { BehaviorSubject,
         combineLatest,
         Observable,
         Subject                                 } from 'rxjs';
import { debounceTime,
         distinctUntilChanged,
         map,
         share                                   } from 'rxjs/operators';

import { EnvironmentService,
         LoggerService,
         UserPreferencesService                  } from 'app/core';
import { AvailableLanguage,
         LanguageConstant                        } from './types';
import { availableLanguages,
         languageConstants                       } from './constants';

type Object = {
  [key: string]: string | Object;
}

@Injectable({
  providedIn: 'root'
})
export class TranslateService {
  private _onUse = new Subject<string>();

  //
  public get onAvailableLanguages (): Observable<AvailableLanguage[]> { return this._onAvailableLanguages; }
  private _onAvailableLanguages = new BehaviorSubject<AvailableLanguage[]>([]);

  //
  public get onCurrentLanguage (): Observable<AvailableLanguage | null> { return this._onCurrentLanguage; }
  public get currentLanguage (): AvailableLanguage | null { return this._onCurrentLanguage.value; }
  private _onCurrentLanguage = new BehaviorSubject<AvailableLanguage | null>(null);

  constructor (
    private _translate:   NgxTranslateService,
    private _logger:      LoggerService,
    private _preferences: UserPreferencesService,
    private _environment: EnvironmentService
  ) {
    _translate.addLangs(languageConstants);

    // watch for changes in the language and organization type
    combineLatest({
      lang:    this._preferences.watch('language'),
      orgType: this._environment.onOrganizationType.pipe(distinctUntilChanged())
    })
    .pipe(debounceTime(0))
    .subscribe(({ lang }) => {
      // the organization type cannot be passed as a parameter to the translation service
      // but is rather injected in the constructor of the TranslateCompiler. However it is here
      // that we need to watch for changes in the organization such that we can recompile the
      // translations.
      this.use(lang);
    });

    // fetch current available languages
    this._environment.onOrganizationType.pipe(distinctUntilChanged())
    .subscribe((x) => {
      this._onAvailableLanguages.next(this.getAvailableLanguages());
    });


    // fetch currently used language
    this._translate.onLangChange
    .subscribe(({ lang }: { lang: LanguageConstant }) => {
      this._onCurrentLanguage.next(this.getAvailableLanguages().find(x => x.id === lang) ?? null);
    });
  }

  private getAvailableLanguages (): AvailableLanguage[] {
    if (this._environment.organizationType === 'school') {
      return availableLanguages;
    }
    else if (this._environment.organizationType === 'sports_facility') {
      return availableLanguages.filter(x => x.id === 'sv');
    }
    else {
      // all languages are available until the organization type is set
      return availableLanguages;
    }
  }


  public use (_lang: string) {
    // filter languages that are not supported for the current organization type
    const availableLanguages = this.getAvailableLanguages();
    const lang = availableLanguages.find(x => x.id === _lang)?.id || availableLanguages[0].id;

    // forces recompilation of translations
    this._translate.reloadLang(lang);

    // sets the language
    this._translate.use(lang);

    // emits that the language has been changed
    this._onUse.next(lang);
  }


  public instant (
    translationKey:     string,
    interpolateParams?: any
  ): string {
    return this._translate.instant(translationKey, interpolateParams);
  }


  public hasTranslation (
    translationKey: string,
    language?:      string
  ): boolean {
    const translation: Object = this._translate.translations[language || this._translate.currentLang];
    const value = translationKey
      .split('.')
      .reduce((t, k) => t[k] || {}, translation || {});
    return value !== undefined;
  }

  public onLangChange(): Observable<string> { return this._changes; }
  private _changes = this._onUse.pipe(share());
}