import { onChange                        } from './change';
import { _useCommand                     } from './command';
import { _complete                       } from './complete';
import { groupBy                         } from './group';
import { get                             } from './get';
import { set                             } from './set';
import { unset                           } from './unset';

import { terminate,
         getDebugInfo                    } from './debug';
import { _getSystemType                  } from './system-instances';
export class Core {
  public get                   = get;
  public set                   = set;
  public unset                 = unset;
  public groupBy               = groupBy;
  public getDebugInfo          = getDebugInfo;
  public terminate             = terminate;
  public onChange              = onChange;

  protected _useCommand        = _useCommand;
  protected _complete          = _complete;
  protected _getSystemType     = _getSystemType;
}