import { Component,
         Input,
         Output,
         EventEmitter,
         OnInit } from '@angular/core';

@Component({
  selector: 'app-fetch-fail-pane',
  templateUrl: './fetch-fail-pane.component.html',
  styleUrls: ['./fetch-fail-pane.component.scss']
})
export class FetchFailPaneComponent implements OnInit {
  @Input()  show:   boolean            = false;
  @Output() reload: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
  }

}
