import { Directive,
         ElementRef,
         Input                           } from '@angular/core';
import { CleaveOptions                   } from 'cleave.js/options';
import Cleave                              from 'cleave.js';

@Directive({
  selector: '[dynamicCleave]'
})
export class DynamicCleaveDirective {

  constructor (private _elementRef : ElementRef) { }

  @Input()
  set dynamicCleave (cleave: CleaveOptions | undefined) {
    cleave && new Cleave(this._elementRef.nativeElement, cleave);
  }
}