<app-loading *ngIf="loading" borderRadius="4px"></app-loading>

<h1 mat-dialog-title class="royal-text-primary">
  {{ 'tables.admin-tables.organizations.configure-compute-environment.title' | translate }}
</h1>

<mat-dialog-content>
  <form [formGroup]="form">

    <div class="form-field-wrapper">
      <mat-icon class="form-field-icon">account_tree</mat-icon>
      <mat-form-field class="form-field" appearance="outline" floatLabel="always">
        <mat-label>Version</mat-label>
        <mat-select formControlName="version" panelClass="dark-scrollbar" [placeholder]="'common.default' | translate">
          <mat-option [value]="null">
            {{ 'common.default' | translate }}
          </mat-option>
          <mat-option *ngFor="let x of versions" [value]="x.value">
            {{ x.name }}
          </mat-option>
        </mat-select>
        <mat-error><app-form-field-error [control]="form.controls.version"></app-form-field-error></mat-error>
      </mat-form-field>
    </div>

    <div class="form-field-wrapper">
      <mat-icon class="form-field-icon">memory</mat-icon>
      <mat-form-field class="form-field" appearance="outline" floatLabel="always">
        <mat-label>Environment</mat-label>
        <mat-select formControlName="environment" panelClass="dark-scrollbar" [placeholder]="'common.default' | translate">
          <mat-option [value]="null">
            {{ 'common.default' | translate }}
          </mat-option>
          <mat-option *ngFor="let x of environments" [value]="x.value">
            {{ x.name }}
          </mat-option>
        </mat-select>
        <mat-error><app-form-field-error [control]="form.controls.environment"></app-form-field-error></mat-error>
      </mat-form-field>
    </div>

  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close type="button">
    {{ 'common.close' | translate }}
  </button>
  <button mat-button color="primary" type="submit" [disabled]=" ! form.valid" (click)="submit()">
    {{ 'common.save' | translate }}
  </button>
</mat-dialog-actions>