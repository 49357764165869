import { Util                            } from "@app/common";


export const organizationTypes = ['school', 'sports_facility'] as const;
export const themes            = ['royal_schedule', 'schoolsoft', 'sdui', 'additio', 'pupil', 'konecto'] as const;

export type OrganizationType = typeof organizationTypes[number];
export type Theme            = typeof themes[number];

export type SystemState = {
  [key in OrganizationType]: {
    collections: Partial<Record<Util.Types.Collection, {
      icon?: string,
      properties?: {
        omit?: {
          [key in string]: boolean;
        }
      },
      origins?: {
        omit?: {
          [key in string]: boolean;
        }
      }
    }>>
  }
}