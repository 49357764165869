import { NgModule                             } from '@angular/core';
import { CommonModule                         } from '@angular/common';
import { ReactiveFormsModule                  } from '@angular/forms';

import { AppCommonModule                      } from 'app/common/common.module';
import { TranslationModule                    } from 'app/core/translate/translate.module';
import { ComponentsModule                     } from 'app/shared/components/components.module';
import { DuplicateScheduleComponent           } from './duplicate-schedule.component';

@NgModule({
  declarations: [
    DuplicateScheduleComponent,
  ],
  exports: [
    DuplicateScheduleComponent
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    TranslationModule,
    ComponentsModule,
    ReactiveFormsModule
  ]
})
export class DuplicateScheduleModule { }
