import { NgModule                        } from '@angular/core';
import { CommonModule                    } from '@angular/common';
import { FormsModule,
         ReactiveFormsModule             } from '@angular/forms';
import { OverlayModule                   } from '@angular/cdk/overlay';
import { FullCalendarModule              } from '@fullcalendar/angular';

import { TranslationModule               } from 'app/core/translate/translate.module';
import { PipesModule                     } from 'app/shared/pipes/pipes.module';
import { AppCommonModule                 } from 'app/common/common.module';
import { DirectivesModule                } from 'app/shared/directives/directives.module';
import { PopoverModule                   } from './popover/popover.module';
import { CalendarComponent               } from './calendar/calendar.component';
import { DayClickMenuComponent           } from './day-click-menu/day-click-menu.component';

@NgModule({
  declarations: [
    CalendarComponent,
    DayClickMenuComponent
  ],
  exports: [
    CalendarComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    PopoverModule,
    ReactiveFormsModule,
    AppCommonModule,
    TranslationModule,
    PipesModule,
    FullCalendarModule,
    DirectivesModule,
    OverlayModule
  ]
})
export class CalendarModule { }
