<!-- <button mat-icon-button
        class="pin-button"
        type="button">
  <mat-icon>push_pin</mat-icon>
</button> -->
<app-loading [loading]="loading()"
             [description]="'common.loading' | translate"
></app-loading>

<div class="image-container" *ngIf="error()">
  <img
      class="error-image"
      src="assets/images/svg/warning.svg"
      alt="error">
    <span class="padding-top">{{ 'common.generic_error' | translate }}</span>
</div>

<div class="image-container" *ngIf="valid()">
  <img
      class="valid-image"
      src="assets/images/svg/checkmark.svg"
      alt="valid">
  <span class="padding-top">{{ 'shared.dialogs.sync.validation.valid' | translate }}</span>
</div>

<mat-list *ngIf="! error() && ! valid()">
  <h4 matSubheader>{{ 'shared.dialogs.sync.validation.title' | translate }}</h4>
  @for (collection of collections | filterCollections: partner; track $index) {
    @if (validated | async; as validatedItems) {
      @for (item of validatedItems?.[collection]?.displayName; track $index) {
        <mat-list-item>
          <mat-icon matListItemIcon>error_outline</mat-icon>
          <div matListItemTitle>
            {{ item.count }} {{ ('common.' + collection) | translate }}
            @switch (item.displayName) {
              @case (null) {
                {{ 'shared.dialogs.sync.validation.ids_missing' | translate }}
              }
              @default () {
                {{ 'shared.dialogs.sync.validation.ids_not_unique' | translate: { ids: item.displayName } }}
              }
            }
          </div>
          <!-- <div matListItemLine *ngIf="item.displayName != null">
            {{ item.docs | pick:'displayName' | join:', '}}
          </div> -->
        </mat-list-item>
      }
      @for (item of validatedItems?.[collection]?.unique; track $index) {
        @switch (collection) {
          @case ('events') {
            <mat-list-item>
              <mat-icon matListItemIcon>error_outline</mat-icon>
              <div matListItemTitle>
                {{ item.count }} {{ ('common.' + collection) | translate }}
                {{ 'shared.dialogs.sync.validation.indistinguishable.events' | translate: { name: item.course?.displayName ?? '' } }}
              </div>

              @if (item.start) {
                <div matListItemLine>
                  {{ item.start | date:'EEEE HH:mm':'UTC':translate.currentLanguage?.id }} - {{ item.end | date:'HH:mm':'UTC':translate.currentLanguage?.id }}
                </div>
              }
              <div matListItemLine>
                @if (item.teachers.length) {
                  @for (teacher of item.teachers; track $index) {
                    <span class="validated-list">
                      {{ teacher.displayName }}
                    </span>
                  }
                }
                @if (item.groups.length) {
                  @for (group of item.groups; track $index) {
                    <span class="validated-list">
                      {{ group.displayName }}
                    </span>
                  }
                }
              </div>
            </mat-list-item>
          }
          @case ('courses') {
            <mat-list-item>
              <mat-icon matListItemIcon>error_outline</mat-icon>
              <div matListItemTitle>
                {{ item.count }} {{ ('common.' + collection) | translate }}
                  {{ 'shared.dialogs.sync.validation.indistinguishable.courses' | translate: { ids: item.displayName } }}
              </div>
            @if (item.teachers.length) {
              <div matListItemLine>
                @for (teacher of item.teachers; track $index) {
                  <span class="validated-list">
                    {{ teacher.displayName }}
                  </span>
                }
              </div>
            }
            @if (item.groups.length) {
              <div matListItemLine>
                @for (group of item.groups; track $index) {
                  <span class="validated-list">
                    {{ group.displayName }}
                  </span>
                }
              </div>
            }
            </mat-list-item>
          }
        }
      }
    }
  }
</mat-list>