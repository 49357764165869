import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@app/core";
import { Option as _Option, AllOption } from "./create-courses.component"

type Option = Partial<_Option> & Partial<AllOption>;

@Pipe({
  name: 'selected'
})
export class SelectedPipe implements PipeTransform {
  constructor (private _translate: TranslateService) { }

  transform (
    val: Option[] | null | undefined,
  ): string {
    if ( ! val?.length) return '';

    if (val.some(x => !! x.all)) return this._translate.instant('common.all').capitalizeFirst();

    let str = val[0].displayName ?? this._translate.instant('common.unknown').capitalizeFirst();
    if (val.length > 1) {
      str += ` +  ${ val.length - 1 } `
          + this._translate.instant(`common.${ val.length - 1 > 1 ? 'others' : 'other' }`).toLowerCase();
    }
    return str;
  }
}