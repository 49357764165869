export const storageConstants = {
  AUTH_DETAILS:                  'auth_details',
  USERNAME:                      'username',
  USER_ID:                       'user_id',
  USER_CREATED_AT:               'user_created_at',
  LANGUAGE:                      'language',
  THREAD_ID:                     'thread_id',
  THREAD_ARRAY:                  'threads',
  DEMO_INITIALIZING:             'demo_initializing',
  DEMO_INITIALIZED:              'demo_initialized',
  DEMO_AVAILABLE_JOBS:           'demo_available_jobs',
  DEMO_INFORMED:                 'demo_informed',
  DEMO_CONTACT:                  'demo_contact',
  USER_PREFERENCES:              'user_preferences',
  ORGANIZATION_PREFERENCES:      'organization_preferences',
};