import { BehaviorSubject,
         map,
         firstValueFrom,
         filter                          } from 'rxjs';
import { NgZone                          } from '@angular/core';

import { LoggerService                   } from 'app/core';
import { Main2WorkerMsg,
         Worker2MainMsg                  } from './types';
import { AnalyzedOut,
         Schedule,
         VerifiedOut                     } from '../types';


/**
 * @description
 * A wrapper around the SharedWorker API to hide certain details such as an internal id which is used to identify the tab in the shared worker.
 */
export class SharedWorkerWrapper {
  private readonly id$ = new BehaviorSubject<string>('');
  private onVerifiedCb?: (arg: VerifiedOut) => void = () => {};
  private onAnalyzedCb?: (arg: AnalyzedOut) => void = () => {};

  private worker?: SharedWorker;

  constructor (
    private readonly logger:   LoggerService,
    private readonly ngZone:   NgZone
  ) {
    // ensure that SharedWorker is supported
    // (do not throw error as this will prevent the app from working)
    if (typeof SharedWorker == 'undefined') {
      this.logger.error('SharedWorker is not supported');
      return;
    }

    // register and start the shared worker
    // this.ngZone.runOutsideAngular(() => {
      this.worker = new SharedWorker(new URL('./main.worker', import.meta.url), { type: 'module', name: 'Royal_Schedule_Analyzer' });
      this.worker.port.start();

      // listen for messages and errors from the shared worker
      this.worker.port.addEventListener('message', (event: MessageEvent<Worker2MainMsg>) => {
        const data = event.data;
        // console.log('> message', data);

        if (data.type === 'registered') {
          this.id$.next(data.id);
        }
        else if (data.type === 'deregistered') {
          this.id$.next('');
          delete this.onVerifiedCb;
          delete this.onAnalyzedCb;
        }
        else if (data.type === 'verified') {
          this.onVerifiedCb?.(data.data);
        }
        else if (data.type === 'analyzed') {
          this.onAnalyzedCb?.(data.data);
        }

      });
      this.worker.port.addEventListener('error', (err) => {
        this.logger.error(err);
      });
    // });
  }

  public destroy () {
    this.deregister();
    this.worker?.port.removeAllListeners?.();
    this.worker?.port.close();
    delete this.worker;
    // for some reason the SharedWorker does not get garbage collected...
  }

  public register (
    did:        string,
    onVerified: (arg: VerifiedOut) => void,
    onAnalyzed: (arg: AnalyzedOut) => void
  ): Promise<void> {
    // ensure that we do not register twice without first deregistering
    if (this.id$.value) throw new Error('Already registered');

    // store the callbacks
    this.onVerifiedCb = onVerified;
    this.onAnalyzedCb = onAnalyzed;

    this.worker?.port.postMessage({ type: 'register', did } satisfies Main2WorkerMsg);

    return firstValueFrom(this.id$.pipe(
      filter(Boolean),
      map(() => undefined)
    ));

    return Promise.resolve();
  }

  public deregister () {
    if ( ! this.id$.value) return;
    console.log('deregistering', this.id$.value);
    this.worker?.port.postMessage({ type: 'deregister', id: this.id$.value } satisfies Main2WorkerMsg);
  }

  public analyze (
    version:  number,
    schedule: Schedule
  ) {
    // ensure that we are registered
    if ( ! this.id$.value) throw new Error('Not registered');

    this.worker?.port.postMessage({ type: 'analyze', version, schedule } satisfies Main2WorkerMsg);
  }

  public isSupported () {
    return this.worker ? true : false;
  }

}