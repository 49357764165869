import { NgModule                        } from '@angular/core';
import { CommonModule                    } from '@angular/common';
import { FormsModule,
         ReactiveFormsModule             } from '@angular/forms';

import { TranslationModule               } from '@core/translate/translate.module';

import { AppCommonModule                 } from '@common/common.module';

import { CalendarModule                  } from '@shared/calendar/calendar.module';
import { ComponentsModule                } from '@shared/components/components.module';
import { PipesModule                     } from '@shared/pipes/pipes.module';

import { OptionsComponent                } from './options.component';

@NgModule({
  declarations: [
    OptionsComponent
  ],
  exports: [
    OptionsComponent
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    PipesModule,
    TranslationModule,
    CalendarModule,
    FormsModule,
    ComponentsModule,
    ReactiveFormsModule
  ]
})
export class OptionsFormFieldModule { }
