import { Component,
         Inject                               } from '@angular/core';

import { MAT_DIALOG_DATA                      } from 'app/common';

export type DialogData = {
  currentVersion: string;
  latestVersion:  string;
};

@Component({
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss']
})
export class VersionComponent {

  protected currentVersion: string;
  protected latestVersion: string;

  constructor (@Inject(MAT_DIALOG_DATA)
               { currentVersion, latestVersion }: DialogData) {
    this.currentVersion = currentVersion;
    this.latestVersion  = latestVersion;
  }

}
