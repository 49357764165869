import { of                   } from 'rxjs';
import { takeUntil,
         delay                } from 'rxjs/operators';
import _                        from 'lodash';

import { Category             } from '@app/core/push-notification/types';
import { InputAnalysisService } from './input-analysis.service';
import { Remark               } from './types';


export function notify (
  this:   InputAnalysisService,
  remark: Remark
) {
  let title:     string = '';
  let category:  Category;
  let icon:      string = '';
  let iconClass: string = '';
  if (remark.type == 'error') {
    title     = this._translate.instant('inputAnalysis.errors.notification_title');
    category  = 'inputAnalysisError';
    icon      = 'troubleshoot';
    iconClass = 'error-red';
  }
  else if (remark.type == 'issue') {
    title     = this._translate.instant('inputAnalysis.issues.notification_title');
    category  = 'inputAnalysisWarning';
    icon      = 'troubleshoot';
    iconClass = 'warning-orange';
  }
  else if (remark.type == 'notice') {
    title     = this._translate.instant('inputAnalysis.notices.notification_title');
    category  = 'inputAnalysisNotice';
    icon      = 'troubleshoot';
    iconClass = 'remark-blue';
  } else {
    this._logger.error(new Error('unknown notification type'))
    category = 'other';
  }

  of(true).pipe(
    takeUntil(this.onDestroy),
    delay(remark.delayTime * 1000),   // convert from seconds to milliseconds
  ).subscribe(() => {

    // abort if the input analyzer is inactive or the version is outdated
    if ( ! this.active || this.version != remark.version) return;

    // also ensure that error/warning/notice still exists a delay is set
    if (remark.delayTime > 0) {
      if      (remark.type == 'error'  && ! this.errors .some(x => x.id == remark.id)) return;
      else if (remark.type == 'issue'  && ! this.issues .some(x => x.id == remark.id)) return;
      else if (remark.type == 'notice' && ! this.notices.some(x => x.id == remark.id)) return;
    }

    this._notifications.push(remark.message || '', category, {
      title:     title,
      icon:      icon,
      iconClass: iconClass,
      buttons: [{
        text:   this._translate.instant('common.show'),
        class:  'primary',
        action: () => this._dialog.openInputIssuesDialog(this.did!, remark.id)
      }]
    });
  });
}