import _                                   from 'lodash';

import { LoggerService                   } from '@app/core/logger/logger.service';
import { Logger                          } from '@app/core/logger/logger.interface';

import { SourceCore                      } from '../source.core';

import { Command                         } from './system-core';

export function _useCommand(this: SourceCore, command: Command): void {

  let action: keyof Command | string | undefined;

  if (_.isString(command))
    action = command;

  if (_.isObjectLike(command))
    action = Object.keys(command)[0] as keyof Command | string;

  switch (action) {
    case 'log':
      {
        const { log } = command as { log: { type: keyof Logger, message: string} };
        const { type, message } = log;
        (this._logger[type as keyof LoggerService] as Function)?.(message)
        break;
      }
    case 'notify':
      {
        const { notify } = command as { notify: { type: 'error' | 'verbose', message: string } };
        switch (notify.type) {
          case 'error':
            this.onNotify.next(this._translate.instant(notify.message))
            break;
          default:
            this._snackbar.open(this._translate.instant(notify.message), '', { duration: 3000, panelClass: 'info-snackbar' });
            break;
        }
        break;
      }
    case 'authenticated':
      {
        const { authenticated } = command as { authenticated: boolean };
        this._logger.verbose?.('(Source::Core::Command) System instance sent authenticate request');

        if (! authenticated) {
          this._logger.verbose?.(`(Source::Core::Command) System instance sent authenticate request and this thread is master.`);
          this._auth.checkAuthentication();
          (this.__systemCore as any).useCommand?.({ authenticate: this._auth.getToken() });
        }
        break;
      }
    case 'invalid':
      {
        const { invalid } = command as { invalid: { message: string } };
        this._logger.error?.(`(Source::Core::Command) ${ invalid.message }`);
        // this.onNotify.next(this._translate.instant(invalid.message))
        break;
      }
    default:
      console.log('no case for command ', action, command);
      break;
  }
}