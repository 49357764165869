import { Injectable                } from '@angular/core';
import { Observable,
         Subject,
         catchError,
         combineLatest,
         filter,
         from,
         map,
         of,
         shareReplay,
         switchMap,
         take,
         throwError                } from 'rxjs';
import { identify                  } from '@royalschedule/maps';
import saveAs                        from 'file-saver';
import Excel                         from 'exceljs';

import { EnvironmentService,
         HttpService,
         LoggerService,
         PushNotificationService,
         TranslateService          } from 'app/core';
import { LanguageConstant          } from 'app/core/translate/types';
import { schoolDummyData,
         prepareTemplate,
         sportsFacilityDummyData,
         parseWorkbook,
         translateMessages         } from './functions';
import { Data,
         DataSheetName,
         Logo                      } from './types';



@Injectable({
  providedIn: 'root'
})
export class ExcelTemplateService {


  private template = new Observable<Excel.Workbook>();
  private logo     = new Observable<Logo>();
  private language = new Observable<LanguageConstant>();

  constructor (
    private _environment:  EnvironmentService,
    private _http:         HttpService,
    private _logger:       LoggerService,
    private _notification: PushNotificationService,
    private _translate:    TranslateService
  ) {

    // fetches the template
    this.template = this._http.getBare('assets/excel-templates/template.xlsx')
    .pipe(
      catchError((err: unknown) => {
        this._logger.error(err);
        this._notification.pushError();
        return throwError(() => err);
      }),
      shareReplay(1),  // we need a fresh copy every time
      switchMap((blob: Blob) => from(blob.arrayBuffer())),
      switchMap(x => {
        // try to parse the file
        const workbook = new Excel.Workbook();
        return from(workbook.xlsx.load(x))
          .pipe(
            catchError(err => {
              this._logger.error(err);
              this._notification.pushError();
              return of(null);
            })
          )
      }),
      filter(Boolean)
    );

    // fetches the logo to be displayed in the template
    this.logo = this._http.getBare('assets/excel-templates/logos/' + this.logoFileName())
    .pipe(
      catchError(err => {
        this._logger.error(err);
        this._notification.pushError();
        return of(undefined);
      }),
      switchMap((blob: Blob) => {
        const img = new Image();
        img.src = URL.createObjectURL(blob);
        const dimensions = new Subject<{ width: number, height: number }>();
        img.onload = () => dimensions.next({ width: img.width, height: img.height });

        return combineLatest({
          dimensions: dimensions,
          buffer:     from(blob.arrayBuffer())
        })
      }),
      filter(Boolean),
      shareReplay(1)
    );

    // fetches the language
    this.language = this._translate.onCurrentLanguage.pipe(filter(Boolean), map(x => x.id));
  }


  private logoFileName () {
    return (this._environment.whiteLabel || 'royal_schedule') + '.png';
  }

  private async saveToFile (wb: Excel.Workbook, fileName: string) {
    const buffer   = await wb.xlsx.writeBuffer({ });
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const blob     = new Blob([buffer], { type: fileType });
    saveAs(blob, fileName);
  }

  private prepareAndDownload (
    data:            Data,
    readonlySheets?: DataSheetName[]
  ) {
    const out = new Subject<void>();
    combineLatest({
      template: this.template,
      logo:     this.logo,
      lang:     this.language
    })
    .pipe(
      switchMap(x => combineLatest({
        wb:   of(prepareTemplate(x.template, x.logo, this._environment.organizationType, x.lang, this._logger, this._translate, data, readonlySheets)),
        name: of(data.division.displayName ?? undefined)
      })),
      take(1)
    )
    .subscribe(({ wb, name }) => {
      if (wb instanceof Error) {
        this._logger.error(wb);
        this._notification.pushError();
        out.error(wb);
        return;
      }

      const dateString = new Date().toISOString().split('T')[0];
      const fileName = name ? `${name.trim()}_${dateString}.xlsx` : 'template.xlsx';

      void this.saveToFile(wb, fileName).then(() => {
        out.next();
        out.complete();
      });
    });

    return out;
  }


  ////
  //// IO
  ////

  public downloadRaw (): Observable<void> {
    const file = this.template.pipe(take(1));

    file.subscribe(x => void this.saveToFile(x, 'raw.xlsx'));

    return file.pipe(map(() => undefined));
  }

  public downloadBare (): Observable<void> {
    const data = this._environment.organizationType == 'school' ? schoolDummyData() : sportsFacilityDummyData();
    return this.prepareAndDownload(data);
  }

  public downloadPopulated (
    data:            Data,
    readonlySheets?: DataSheetName[]
  ): Observable<void> {
    return this.prepareAndDownload(data, readonlySheets);
  }

  public readonly acceptedTypes = ['.xls', '.xlsx', '.xlsm'];

  public mapFile (file: File) {
    return of(file).pipe(
      // must be one of the accepted files, otherwise propagate error
      map(x => {
        const extension = x.name.split('.').pop();
        if (extension && this.acceptedTypes.includes('.' + extension)) return x;
        return new Error('invalid_file_type');
      }),
      // parse the file
      switchMap(x => {
        if (x instanceof Error) return of(x);

        const workbook = new Excel.Workbook();
        return from(x.arrayBuffer()).pipe(switchMap(x => from(workbook.xlsx.load(x))));
      }),
      catchError(err => {
        this._logger.error(err);
        this._notification.pushError();
        return of(new Error('unable_to_parse'));
      }),
      // map the file
      map(x => {
        if (x instanceof Error) return x;

        const doc        = parseWorkbook(x);
        const determined = identify(doc).determined;

        if (determined?.name != 'Royal Schedule Excel') return new Error('incorrect_mapping');

        try {
          const mapped = determined.map.from.schedules(doc);

          // translate mapping errors and warnings
          const errors   = mapped.meta?.errors   ? translateMessages(mapped.meta.errors,   this._translate) : undefined;
          const warnings = mapped.meta?.warnings ? translateMessages(mapped.meta.warnings, this._translate) : undefined;
          if (mapped.meta?.errors  ) mapped.meta.errors   = errors;
          if (mapped.meta?.warnings) mapped.meta.warnings = warnings;

          return {
            mapped:   mapped,
            errors:   errors,
            warnings: warnings,
          }
        } catch (err) {
          return new Error('unable_to_map');
        }
      }),
      // take care of errors
      map(x => {
        if (x instanceof Error) return {
          mapped:   undefined,
          errors:   [ this._translate.instant('shared.services.excel-template.errors.' + x.message) ],
          warnings: []
        }

        return x;
      }),
      take(1)
    )


  }
}
