import { NgModule                                } from '@angular/core';
import { CommonModule                            } from '@angular/common';

import { AppCommonModule                         } from 'app/common/common.module';
import { TranslationModule                       } from 'app/core/translate/translate.module';
import { FormFieldsModule                        } from 'app/shared/form-fields/form-fields.module';
import { ComponentsModule as AppComponentsModule } from 'app/shared/components/components.module';
import { FormsModule      as AppFormsModule      } from 'app/shared/forms/forms.module';

import { ScheduleDataTablesModule                } from './schedule-data-tables/schedule-data-tables.module';
import { AdminTablesModule                       } from './admin-tables/admin-tables.module';


@NgModule({
  declarations: [
  ],
  exports: [
    ScheduleDataTablesModule,
    AdminTablesModule
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    TranslationModule,
    AppFormsModule,
    AppComponentsModule,
    FormFieldsModule
  ]
})
export class TablesModule { }
