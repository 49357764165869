import { NgModule                                } from '@angular/core';
import { CommonModule                            } from '@angular/common';

import { AppCommonModule                         } from 'app/common/common.module';
import { TranslationModule                       } from 'app/core/translate/translate.module';
import { FormFieldsModule                        } from 'app/shared/form-fields/form-fields.module';
import { ComponentsModule as AppComponentsModule } from 'app/shared/components/components.module';
import { FormsModule      as AppFormsModule      } from 'app/shared/forms/forms.module';

import { ComponentsModule                        } from './components/components.module';
import { UsersModule                             } from './users/users.module';
import { OrganizationsModule                     } from './organizations/organizations.module';
import { DivisionsModule                         } from './divisions/divisions.module';


@NgModule({
  declarations: [
  ],
  exports: [
    UsersModule,
    OrganizationsModule,
    DivisionsModule
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    TranslationModule,
    AppFormsModule,
    AppComponentsModule,
    FormFieldsModule,
    ComponentsModule
  ]
})
export class AdminTablesModule { }
