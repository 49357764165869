import { Injectable,
         ErrorHandler                    } from '@angular/core';
import { HttpErrorResponse               } from '@angular/common/http';

import { Subject                         } from 'rxjs';
import { throttleTime                    } from 'rxjs/operators';
import _                                   from 'lodash';

import { LoggerService                   } from 'app/core/logger/logger.service';
import { PushNotificationService         } from '@app/core/push-notification/push-notification.service';

@Injectable()
export class AppErrorHandler implements ErrorHandler {
  private _onError = new Subject<void>();

  constructor(private _logger:       LoggerService,
              private _notification: PushNotificationService) {
    /*
      Only emit error once every 10 seconds. If error cause an infinte loop
      it will spam notifications
    */
    // this._onError.pipe(throttleTime(10000)).subscribe(() => this._notification.pushError());
  }

  handleError(error: unknown) {

    if (_.isString(error))
      error = new Error(error);

    if (error instanceof HttpErrorResponse)
      return;

    if (! (error instanceof Error)) {
      console.log(error);
      error = new Error(`(Core::Error) Unknown error. Type of error: ${ typeof error }`);
    }

    if (! _.isError(error))
      return;

    this._logger.error(error);

    this._onError.next();
  }
}