import { NgModule                              } from '@angular/core';
import { CommonModule                          } from '@angular/common';

import { TranslationModule                     } from 'app/core/translate/translate.module';

import { AppCommonModule                       } from 'app/common/common.module';

import { EventIntervalsModule                  } from './event-intervals/event-intervals.module';
import { EventDensityModule                    } from './event-density/event-density.module';


@NgModule({
  declarations: [
  ],
  exports: [
    EventIntervalsModule,
    EventDensityModule
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    TranslationModule,
  ]
})
export class ComponentsModule { }
