<h1 mat-dialog-title class="royal-text-primary">{{ 'shared.dialogs.company.title' | translate }}</h1>
<p class="royal-text-secondary">{{ 'shared.dialogs.company.description' | translate }}</p>

<mat-dialog-content>
  <app-company-form #form
                    [organization]="data.organization"
                    [theme]="data.theme"
                    organizationType
                    [schoolCode]="data.schoolCode"
                    [schools]="data.schools | async"
                    (onSubmit)="submit($event)">
    <button mat-stroked-button
            mat-dialog-close
            type="button">
      {{ 'common.cancel' | translate }}
    </button>

    <span class="spacer"></span>

    <button mat-flat-button
            type="submit"
            [disabled]="! form?.valid"
            color="primary">
      {{ 'common.create' | translate }}
    </button>
  </app-company-form>
</mat-dialog-content>