import { NgModule                        } from '@angular/core';
import { CommonModule                    } from '@angular/common';

import { IsFilteredPipe,
         FilterEventSetsPipe,
         VerifyEventOverlapSpeciesPipe   } from './pipes.pipe';



@NgModule({
  declarations: [
    IsFilteredPipe,
    FilterEventSetsPipe,
    VerifyEventOverlapSpeciesPipe
  ],
  exports: [
    IsFilteredPipe,
    FilterEventSetsPipe,
    VerifyEventOverlapSpeciesPipe
  ],
  imports: [
    CommonModule
  ]
})
export class PipesModule { }