<app-loading *ngIf="loading" borderRadius="4px"></app-loading>

<h1 mat-dialog-title>Create API access key</h1>

<mat-dialog-content>
  <p class="royal-text-secondary">
    Creates a <i>jwt</i> token without expiration date that can be used for a ss12000 integration.
  </p>

  <form [formGroup]="form">

    <div class="form-field-wrapper">
      <mat-form-field class="form-field" appearance="outline">
        <mat-label>Origin</mat-label>
        <mat-select formControlName="origin" panelClass="image-icons dark-scrollbar" class="with-icon">
          <mat-select-trigger *ngIf="form.controls.origin.value; let x">
            <img [src]="integrations[x].icon"/>
            {{ integrations[x].name }}
          </mat-select-trigger>
          <mat-option *ngFor="let x of integrations | keyvalue" [value]="x.key">
            <img [src]="x.value.icon"/>
            {{ x.value.name }}
          </mat-option>
        </mat-select>
        <mat-error><app-form-field-error [control]="form.controls.origin"></app-form-field-error></mat-error>
      </mat-form-field>
    </div>

  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close type="button">
    {{ 'common.close' | translate }}
  </button>
  <button mat-button color="primary" type="submit" [disabled]=" ! form.valid" (click)="submit()">
    Generate and copy
  </button>
</mat-dialog-actions>