import { InjectionToken } from '@angular/core';

export const commonConstants = {
  DAYS: [
    'common.sunday',
    'common.monday',
    'common.tuesday',
    'common.wednesday',
    'common.thursday',
    'common.friday',
    'common.saturday'
  ],
  COLORS:{
    PRIMARY:             '#9776f9',
    ACCENT:              '#6879f9',
    EVENT_DEFAULT:       '#9776f9',
    LOCKED_TIME_DEFAULT: '#70757A'
  },
};


const viewAccessTypes = ['admin', 'administrator', 'partner', 'private', 'public', 'restricted', 'void'] as const;
export type ViewAccessType = typeof viewAccessTypes[number];
export const VIEW_ACCESS_TYPE = new InjectionToken<ViewAccessType>('ViewAccessType');


export const DIVISION_ID = new InjectionToken<string>('DivisionId');