<!-- force rerender on stateChanges.next() otherwise it is not guaranteed to show the latest values due to some lazy rendering-->
{{ stateChanges | async }}

<div class="locked-time-label clickable"
     [matMenuTriggerFor]="menu"
     (menuClosed)="closed()"
     (menuOpened)="opened()">
     <app-form-field-locked-times-display-value [value]="value"
                                                [voidText]="voidText"
                                                [inherit]="!! inherit">
     </app-form-field-locked-times-display-value>
</div>

<mat-menu yPosition="below"
          #menu="matMenu"
          class="locked-time-panel no-vertical-padding"
          [overlapTrigger]="true">
  <!-- the template causes "Cannot read properties of undefined (reading 'createEmbeddedView')" -->
  <!-- <ng-template matMenuContent> -->

    <div class="toolbar" (click)="$event.stopPropagation()" [class.inherit]="inherit">
      <div class="row">
        <button mat-icon-button
                *ngIf="! hideCalendar"
                class="button"
                type="button"
                matTooltip="{{ (isCalendar ? 'common.list_view' : 'common.calendar_view') | translate }}"
                (click)="toggleCalendar()"
                [disabled]="inherit?.value">
          <mat-icon class="royal-icon">{{ isCalendar ? 'view_list' : 'edit_calendar' }}</mat-icon>
        </button>

        <button mat-icon-button
                class="button"
                type="button"
                matTooltip="{{ 'common.add' | translate }}"
                (click)="core.add()"
                [disabled]="inherit?.value">
          <mat-icon class="royal-icon">add_box</mat-icon>
        </button>

        <button mat-stroked-button
                class="action grey-bg"
                color="primary"
                (click)="restoreValue()"
                *ngIf="! pristine && reset && valid">
          <div>
            <span>{{ 'common.restore' | translate }}</span>
            <mat-icon class="royal-icon">undo</mat-icon>
          </div>
        </button>

        <button mat-stroked-button
                class="action warn-bg white"
                *ngIf="! valid"
                color="primary">
          <div>
            <span>{{ 'common.invalid' | translate }}</span>
            <mat-icon>warnings</mat-icon>
          </div>
        </button>

        @if (inherit) {
          <mat-slide-toggle
            class="inherit-toggle"
            labelPosition="before"
            color="accent"
            (change)="inheritToggle($event)"
            [checked]="inherit.value">
            {{ 'attributes.event.inherit' | translate }}
          </mat-slide-toggle>
        }

      </div>
    </div>

    <div (click)="$event.stopPropagation()">
      <div class="content" [ngClass]="isCalendar ? 'calendar-view' : 'list-view'">

        <ng-template [cdkPortalOutlet]="selectedPortal" (attached)="onComponentRendering($event)"></ng-template>

        <ng-template #listPortalContent>

          <div *ngIf="! core.form.controls?.length" class="empty-icon-wrapper">
            <img src="assets/images/svg/list.svg">
            <button mat-button (click)="core.add()">{{ 'common.add' | translate }}</button>
          </div>

          <div class="form-container">
            <form class="dense-3"
                  *ngFor="let fg of core.form.controls; let i = index"
                  [formGroup]="fg"
                  (keydown.tab)="$event.stopPropagation()">

              <mat-form-field appearance="outline" subscriptSizing="dynamic" class="name">
                <mat-label>{{ 'attributes.shared.displayName' | translate }}</mat-label>
                <input matInput
                      (keydown)="$event.stopPropagation()"
                      formControlName="displayName"
                      autocomplete="off">
              </mat-form-field>

              <mat-form-field appearance="outline" subscriptSizing="dynamic" class="day">
                <mat-label>{{ 'attributes.shared.day' | translate }}</mat-label>
                <app-form-field-day formControlName="day" [numDays]="numDays"></app-form-field-day>
              </mat-form-field>

              <mat-form-field appearance="outline" subscriptSizing="dynamic" class="start">
                <mat-label>{{ 'attributes.shared.start' | translate }}</mat-label>
                <app-form-field-time formControlName="start"></app-form-field-time>
                <mat-error><app-form-field-error [control]="fg.controls.start"></app-form-field-error></mat-error>
              </mat-form-field>

              <mat-form-field appearance="outline" subscriptSizing="dynamic" class="end">
                <mat-label>{{ 'attributes.shared.end' | translate }}</mat-label>
                <app-form-field-time formControlName="end"></app-form-field-time>
                <mat-error><app-form-field-error [control]="fg.controls.end"></app-form-field-error></mat-error>
              </mat-form-field>

              <button mat-icon-button
                      type="button"
                      matTooltip="{{ 'common.delete' | translate }}"
                      [disabled]="inherit?.value"
                      (click)="core.remove(i)">
                <mat-icon class="royal-icon">delete_outline</mat-icon>
              </button>
            </form>
          </div>
        </ng-template>
      </div>
    </div>

    <mat-divider class="divider"
                *ngIf="! disableActions"
                (click)="$event.stopPropagation()"></mat-divider>

    <div (click)="$event.stopPropagation()" class="actions" *ngIf="! disableActions">
      <button mat-stroked-button
              color="primary"
              type="button"
              (click)="trigger?.closeMenu()">
        {{ 'common.cancel' | translate }}
      </button>
      <button mat-flat-button
              color="primary"
              type="button"
              [disabled]="! valid"
              (click)="submit(); trigger?.closeMenu()"
              class="button right">
        {{ 'common.save' | translate }}
      </button>
    </div>
  <!-- </ng-template> -->
</mat-menu>