import { NgModule                        } from '@angular/core';
import { CommonModule                    } from '@angular/common';
import { FormsModule,
         ReactiveFormsModule             } from '@angular/forms';
import { NgPipesModule                   } from 'ngx-pipes';

import { AppCommonModule                 } from 'app/common/common.module';
import { TranslationModule               } from 'app/core/translate/translate.module';
import { PipesModule                     } from 'app/shared/pipes/pipes.module';
import { DirectivesModule                } from 'app/shared/directives/directives.module';
import { ToolbarContainerModule          } from '../toolbar-container/toolbar-container.module';
import { MultipleSelectFilterComponent   } from './multiple-select-filter.component';
import { FetchFirstThreePipe             } from './multiple-select-filter.pipe';

@NgModule({
  declarations: [
    MultipleSelectFilterComponent,
    FetchFirstThreePipe
  ],
  exports: [
    MultipleSelectFilterComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AppCommonModule,
    TranslationModule,
    PipesModule,
    NgPipesModule,
    ToolbarContainerModule,
    DirectivesModule
  ]
})
export class MultipleSelectFilterModule { }
