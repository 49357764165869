import { Inject,
         Injectable             } from '@angular/core';
import { MatPaginatorIntl       } from '@angular/material/paginator';
import { TranslateService       } from 'app/core/translate/translate.service';

@Injectable({
  providedIn: 'root'
})
export class MatPaginatorIntlTrans extends MatPaginatorIntl {

  constructor(@Inject(TranslateService) private _translate: TranslateService) {
    super();
    this._translate.onLangChange()
    .subscribe((next: any) => {
      this.itemsPerPageLabel = this._translate.instant('table.itemsPerPageLabel');
      this.nextPageLabel     = this._translate.instant('table.nextPageLabel');
      this.previousPageLabel = this._translate.instant('table.previousPageLabel');
      this.changes.next();
    })
  }

  public getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) {
      return `0 ${ this._translate.instant('common.of') } ${ length }`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;
    return `${ startIndex + 1 } - ${ endIndex } ${ this._translate.instant('common.of') } ${ length }`;
  };

}