import { Component,
         ViewChild,
         AfterViewInit,
         Inject,
         inject,
         DestroyRef                      } from '@angular/core';
import { CdkDragDrop,
         CdkDragEnter,
         CdkDragExit,
         moveItemInArray,
         transferArrayItem               } from '@angular/cdk/drag-drop';
import { takeUntilDestroyed              } from '@angular/core/rxjs-interop';
import { BehaviorSubject,
         Subject,
         Observable,
         combineLatest                   } from 'rxjs';
import { map,
         debounceTime                    } from 'rxjs/operators';
import { nanoid                          } from 'nanoid';
import { computeEventCenter              } from '@royalschedule/input-verifier-v4';
import _                                   from 'lodash';
import $                                   from 'jquery';

import { DivisionSettings,
         Populated as P,
         Species                         } from 'app/shared/interfaces';
import { ParallelCoursesComponent,
         SearchComponent                 } from 'app/shared';
import { LoggerService,
         sourceMatchPipe,
         sourceSelectPipe,
         SourceService,
         TranslateService                } from 'app/core';
import { Value as FilterValue            } from '../filter/filter.interface';
import { FilterComponent                 } from '../filter/filter.component';
import { autoLink,
         isAutoLinkable                  } from './utils';


type DomProperties = { style?: { height?: number, top?: number, fontSize?: number, lineHeight?: number } };

type ExtEvent = P.event & DomProperties;

export type EventSet = { id: string , events: ExtEvent[] };

const delay = 0;

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss'],
  providers: [ SourceService ]
})
export class EventsComponent implements AfterViewInit {
  protected readonly path = 'shared.dialogs.overlappableEventSets';
  protected readonly typeToken: { event: ExtEvent, proportionality?: boolean };
  protected readonly destroyRef = inject(DestroyRef);
  private readonly dt = 5;
  protected readonly did: string;

  protected setEntered = false;

  protected emptyContainer    = [];

  protected openFilter:    boolean = false;
  protected onAddHover:    boolean = false;
  protected showEmptyList: boolean = true;

  protected readonly onFilter = new BehaviorSubject<FilterValue | null>(null);

  protected settings:             Observable<DivisionSettings>;
  protected groups:               Observable<P.group[]>;
  protected teachers:             Observable<P.teacher[]>;
  protected overlappableEventSet: Observable<P.overlapGroup | null>;

  protected readonly unusedEvents = new BehaviorSubject<ExtEvent[]>([]);
  protected readonly usedEvents   = new BehaviorSubject<EventSet[]>([]);

  protected onUsedSearch: Observable<string>;

  private   readonly _events$        = new BehaviorSubject<P.event[]>([]);
  protected readonly isAutoLinkable$ = new BehaviorSubject<boolean>(false);

  @ViewChild('leftSearch',    { static: false }) unusedSearch?: SearchComponent;
  @ViewChild('rightSearch',   { static: false }) usedSearch?:   SearchComponent;
  @ViewChild(FilterComponent, { static: false }) filter?:       FilterComponent;

  constructor (
    protected readonly translate: TranslateService,
    @Inject('parent')
    protected readonly parentComponent: ParallelCoursesComponent,
    private   readonly _source: SourceService,
    private   readonly _logger: LoggerService,
  ) {
    this.did = parentComponent.did;

    this.settings = this._source.getStrictSettings   ({ did: this.did, onDestroy: this.destroyRef });
    this.groups   = this._source.getPopulatedGroups  ({ did: this.did, onDestroy: this.destroyRef });
    this.teachers = this._source.getPopulatedTeachers({ did: this.did, onDestroy: this.destroyRef });

    this.overlappableEventSet = this._source
    .getPopulatedOverlapGroups({ did: this.did, onDestroy: this.destroyRef, skipNoFilter: true },
      sourceSelectPipe(this.onId.pipe(map(x => ({ id: x }))))
    )
    .pipe(map(x => x[0] ?? null));

    // expose to cypress
    if ('Cypress' in window && window.Cypress) {
      (window as unknown as { LinkedEventsDragDisabled: unknown }).LinkedEventsDragDisabled = this._dragDisabled.value;
    }
  }

  ngAfterViewInit () {

    this.onUsedSearch = this.usedSearch!.onValue;

    const setFilter = sourceSelectPipe(this.overlappableEventSet.pipe(
      map(x => ({ 'course.id': x?.coalesced?.map(x => x.to.id) ?? [] }))
    ));

    const searchKeys = ['displayName', 'course.displayName', 'groups.to.displayName', 'teachers.to.displayName', 'preferredDuration', 'period.displayName'];
    const onUnusedSearch = sourceMatchPipe(this.unusedSearch!.onValue, searchKeys);

    combineLatest({
      set:           this.overlappableEventSet,
      events:        this._source.getPopulatedSchedules<ExtEvent>({ did: this.did, onDestroy: this.destroyRef }, onUnusedSearch, setFilter),
      defaultPeriod: this._source.getStrictSettings({ did: this.did, onDestroy: this.destroyRef }).pipe(map(x => x.period))
    })
    .pipe(
      takeUntilDestroyed(this.destroyRef),
      debounceTime(delay)
    )
    .subscribe(({ set, events, defaultPeriod }) => {
      // add default period to event if missing
      // (this implementation is flawed since it occurs after the search. However, it works since since it modifies the references?)
     events.forEach(y => y.period ??= (defaultPeriod as any));

      if (set) {
        this.unusedEvents.next(events?.filter(x => ! x.overlapSpecies) ?? []);
      } else {
        // no overlappable event set yet selected
        this.unusedEvents.next([]);
      }

      // enable drag after the data has been updated
      this._dragDisabled.next(false);
    });

    this._source.getPopulatedSchedules<P.event>({ did: this.did, onDestroy: this.destroyRef }, setFilter)
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe(this._events$);

    combineLatest({
      set:           this.overlappableEventSet,
      // do not filter the events as this will unset the overlap species when updated
      events:        this._events$,
      defaultPeriod: this._source.getStrictSettings({ did: this.did, onDestroy: this.destroyRef }).pipe(map(x => x.period))
    })
    .pipe(
      takeUntilDestroyed(this.destroyRef),
      debounceTime(delay)
    )
    .subscribe(({ set, events, defaultPeriod }) => {
      // add default period to event if missing
      // (this implementation is flawed since it occurs after the search. However, it works since since it modifies the references?)
      events.forEach(y => y.period ??= (defaultPeriod as any));

      // whether the events can be auto linked
      this.isAutoLinkable$.next(isAutoLinkable(events));

      if (set) {
        // merge with species type
        const species = (set.species ?? [])
          .filter(x => x && x.id && x.to)   // temp fix as  "x.to" is sometimes undefined
          .map(({ id, to }) => {
            const event = events?.find(y => y.id == (_.isString(to) ? to : to.id));
            if (event) return { species: id, event }
            else       return null;
          })
          .filter(Boolean);

        // group events by their species type
        const usedEvents =_.map(_.groupBy(species, 'species'), val => ({
          id:     val[0].species,
          events: this.addStyleParams(val.map(x => x.event))
        }));
        this.usedEvents.next(usedEvents);
      } else {
        // no overlappable event set yet selected
        this.usedEvents.next([]);
      }
    });
  }


  private duration2size (duration: number) {
    return Math.floor(duration / this.dt);
  }

  private addStyleParams (events: P.event[]): ExtEvent[] {
    const extEvents = events as unknown as ExtEvent[];
    this.updateStyleParams(extEvents);
    return extEvents;
  }

  private updateStyleParams (events: ExtEvent[]) {
    const maxDuration = _.max(events.map(x => x.preferredDuration)) ?? 0;
    events.forEach(x => {
      const offset = computeEventCenter(this.duration2size(maxDuration))[0]*this.dt
                   - computeEventCenter(this.duration2size(x.preferredDuration))[0]*this.dt;

      const height = x.preferredDuration / maxDuration;
      const top    = offset / maxDuration;

      // compute the font size and line height to avoid a scroll bar
      const numRows          = 1 + 1 + (x.period ? 1 : 0) + (x.groups?.length ? 1 : 0) + (x.teachers?.length ? 1 : 0);
      const maxFontSize      = 16;
      const maxLineHeight    = 19;
      const maxContentHeight = 5 * maxLineHeight;
      const scale            = Math.max(0.7, Math.min((height * maxContentHeight) / (numRows * maxLineHeight), 1));
      const fontSize         = Math.floor(scale * maxFontSize);
      const lineHeight       = Math.floor(scale * maxLineHeight);

      x.style = { height: height * 100, top: top * 100, fontSize: fontSize, lineHeight: lineHeight };
    });
  }

  protected create (event: CdkDragDrop<ExtEvent[]>) {
    this.drop(event);
    // make the container empty once again
    this.emptyContainer = [];
  }

  protected drop(event: CdkDragDrop<ExtEvent[]>) {
    try {
      if (event.previousContainer === event.container) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);

        // update style properties
        this.updateStyleParams(event.container.data);

        // need to enable drag here since no data update is expected
        this._dragDisabled.next(false);
      } else {
        transferArrayItem(event.previousContainer.data,
                          event.container.data,
                          event.previousIndex,
                          event.currentIndex);
        this._update(event);

        // update style properties
        this.updateStyleParams(event.previousContainer.data);
        this.updateStyleParams(event.container.data);
      }
    } catch(err) {
      this._logger.error(err);
    }
  }


  protected delete (speciesId: string) {
    // await response before being able to drag again
    this._dragDisabled.next(true);

    this._source.set({ collection: 'overlapGroups', did: this.did }, {
      id:      this.overlappableEventSetId,
      species: this._getFlatSpecies().filter(x => x.id != speciesId)
    });
  }


  private _update(dropEvent: CdkDragDrop<P.event[]>) {
    // remove dragged event
    const draggedEvent = dropEvent.container.data.at(dropEvent.currentIndex);
    let species = this._getFlatSpecies().filter(x => x.to !== draggedEvent?.id);

    // try add dragged event to new species unless it is being made unused
    if (dropEvent.container.id !== null) {
      // find first index of species
      const speciesId = dropEvent.container.id ?? nanoid(8);
      const index =  species.findIndex(x => x.id == speciesId);

      // reinsert all events with the current species to get the correct order
      species = species.filter(x => x.id != speciesId);
      species.splice(
        index > -1 ? index : 0, 0,
        ...dropEvent.container.data.map(x => ({ to: x.id, id: speciesId, toModel: 'courseevents' }))
      );
    }

    // update
    this._source.set({ collection: 'overlapGroups', did: this.did }, {
      id: this.overlappableEventSetId, species
    });
  }


  private _getFlatSpecies () {
    return this.usedEvents.value
      .map(x => x.events.map(y => ({ to: y.id, id: x.id, toModel: 'courseevents' })))
      .flat();
  }


  protected recalculateStyleOnEnter ({ container, item }: CdkDragEnter<ExtEvent[], ExtEvent>) {
    // add item and recalculate style
    this.updateStyleParams([...container.data, item.data]);
  }

  protected recalculateStyleOnExit ({ container, item }: CdkDragExit<ExtEvent[], ExtEvent>) {
    // remove item and recalculate style
    this.updateStyleParams(container.data.filter(x => x.id != item.data.id));

    // reset style
    item.data.style = { height: 100, top: 0 };
  }

  protected stylePreview () {
    // appends class which in turns styles the preview
    $('.cdk-drag-preview').addClass('contains-event');
  }

  protected released () {
    // This function is triggered by the cdkDragReleased event when the user has released a drag item, before any animations have started.
    // This event is critical to prevent any crashes since the cdkDropListDropped is triggered after the animation.
    // Using only the latter would mean that the user might have already started another drag action which in turn
    // is what causes the crash in certain situations.
    this._dragDisabled.next(true);
  }

  protected trackBy (index: number, entity: { id: string }): string {
    return entity.id;
  }

  protected autoLink () {
    const sets = autoLink(this._events$.value);

    const updated: { id: string; species: Species<string, 'events'>[] } = {
      id:      this.overlappableEventSetId,
      species: sets.flatMap(events => {
        const id = nanoid(8);
        return events.map(x => ({ id, to: x.id, toModel: 'events' }));
      })
    };

    this._source.set({ collection: 'overlapGroups', did: this.did }, updated);
  }


  ////
  //// IO
  ////
  // disable drag when we expect a data change to prevent dragula from crashing
  get dragDisabled () { return this._dragDisabled as Observable<boolean>; }
  private _dragDisabled = new BehaviorSubject(false);

  get overlappableEventSetId ()    { return this._overlappableEventSetId; }
  set overlappableEventSetId (val) {
    this._overlappableEventSetId = val;
    this._onRefetchSet.next();
    this.onId.next(val);
  }
  private _overlappableEventSetId: string;
  private _onRefetchSet = new Subject<void>();
  private onId = new Subject<string>();
}