import { Component,
         OnInit,
         ViewChild,
         Optional,
         Inject                          } from '@angular/core';
import { MatDialogRef,
         MAT_DIALOG_DATA,
         MatTabGroup                     } from 'app/common';

interface input {
  did:      string;
  editable: boolean;
}

interface options {
  next?:     boolean,
  delete?:   boolean,
  clone?:    boolean
}

@Component({
  selector: 'app-edit-schedule-data',
  templateUrl: './edit-schedule-data.component.html',
  styleUrls: ['./edit-schedule-data.component.scss']
})
export class EditScheduleDataComponent implements OnInit {
  public saving:     boolean;
  public did:        string;
  public editable:   boolean = true;
  public options:    options = { delete: true, clone: false }

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data: input) {
    this.did      = data.did;
    this.editable = data.editable;
  }

  ngOnInit(): void {
  }

}
