<div class="wrapper" cdkDropListGroup [class.spinner-cursor]="dragDisabled | async">
  <div class="sidebar">
    <div class="toolbar">
      <app-search #leftSearch placeholder="common.search.course"
        cypress="search-available"
        class="search">
      </app-search>
    </div>

    <div class="content list dark-scrollbar"
      [id]="$any(null)"
      cdkDropList
      [cdkDropListData]="(courses | async) ?? []"
      (cdkDropListDropped)="drop($event)"
      (cdkDropListEntered)="showEmptyList = false"
      (cdkDropListExited)="showEmptyList = true"
    >

      <!-- courses -->
      @for (course of (courses | async); track $index) {
        <div class="drag-item"
          [style.--border-color]="course.to | courseColor"
          [style.--bg-color]="course.to | courseColor:'07'"
          [style.--bg-color-hover]="course.to | courseColor:'14'"
          cdkDrag
          (cdkDragReleased)="released()"
          [cdkDragDisabled]="dragDisabled | async"
        >
          <ng-container [ngTemplateOutlet]="courseDetailsTemplate" [ngTemplateOutletContext]="{ course }"></ng-container>
          <ng-container *cdkDragPlaceholder [ngTemplateOutlet]="placeholderTemplate" [ngTemplateOutletContext]="{ course }"></ng-container>
        </div>
      }

      <!-- show remaining -->
      @if (numHiddenCourses | async) {
        <div class="list-show-remaining" mat-ripple
          (click)="showRemaining($event)" (keydown.enter)="showRemaining($event)"
        >
          {{ 'common.show_remaining' | translate }} {{ numHiddenCourses | async }}
        </div>
      }

      <!-- empty list -->
      @if (showEmptyList && (courses | async)?.length == 0) {
        <div class="list-empty">
          {{ 'noCourses' | t:path }}
        </div>
      }

    </div>
  </div>

  <div class="main">
    <div class="toolbar">
      <app-search #rightSearch
                  cypress="search-used"
                  class="search"
                  placeholder="common.search.course">
      </app-search>

      <span class="spacer"></span>

      <app-overlap-group-filter [groups]="groups | async"
                                [teachers]="teachers | async"
                                (onChange)="onFilter.next($event)">
      </app-overlap-group-filter>
    </div>

    <div class="content dark-scrollbar">

      <!-- new -->
      <div class="new-set"
        [class.entered]="onAddHover"
        cdkDropList
        [id]="id"
        (cdkDropListEntered)="onAddHover = true"
        (cdkDropListExited)="onAddHover = false"
        [cdkDropListData]="emptyContainer"
        (cdkDropListDropped)="onAddHover = false; create($event)"
      >
        <mat-icon>add</mat-icon>
        <!-- <div class="text" [matTooltip]="'description.set' | t:path"> -->
        <div class="text">
          {{ 'new.set' | t:path }}
        </div>
      </div>

      <!-- one for each overlapping group -->
      @for (overlapGroup of overlapGroups | async; track trackBy) {
        <div class="set">
          <div class="toolbar flex-align-center">

            <button mat-icon-button (click)="delete(overlapGroup)">
              <mat-icon>delete_outline</mat-icon>
            </button>

            @if ((overlapGroup.coalesced ?? []) | lessThan:2) {
              <mat-icon class="warning" [matTooltip]="'warning.set' | t:path">
                warning
              </mat-icon>
            }

            <span class="spacer"></span>

            @if (overlapGroup.isAutoLinkable) {
              <button mat-icon-button
                (click)="autoLink(overlapGroup)"
                [matTooltip]="'shared.dialogs.overlappableEventSets.auto-link.description' | translate"
              >
                <mat-icon>auto_fix_normal</mat-icon>
              </button>
            } @else {
              <span class="unable-to-auto-link" [matTooltip]="'shared.dialogs.overlappableEventSets.unable_to_auto-link.description' | translate">
                <button mat-icon-button disabled>
                  <mat-icon>auto_fix_normal</mat-icon>
                </button>
              </span>
            }

            @if (overlapGroup | verifyEventOverlapSpecies:(courseMap$ | async):(settings | async | pluck:'requireForcedOverlappingEventsSets')) {
              <button mat-button
                class="reversed no-gap"
                cypress="go-to-linked-events"
                (click)="gotoEventsTab(overlapGroup)"
              >
                <mat-icon>chevron_right</mat-icon>
                {{ 'forceOverlap' | t:path }}
              </button>
            } @else {
              <button mat-button color="warn"
                class="reversed no-gap"
                [matTooltip]="'error.set' | t:path"
                cypress="go-to-linked-events"
                (click)="gotoEventsTab(overlapGroup)"
              >
                <mat-icon>chevron_right</mat-icon>
                {{ 'forceOverlap' | t:path }}
              </button>
            }
          </div>

          <div class="drop-content"
            [id]="overlapGroup.id"
            cdkDropList
            [cdkDropListData]="overlapGroup.coalesced ?? []"
            (cdkDropListDropped)="drop($event)"
          >
          @for (course of overlapGroup.coalesced; track trackByCoalesced) {
            <div
              class="drag-item"
              [style.--border-color]="course.to | courseColor"
              [style.--bg-color]="course.to | courseColor:'07'"
              [style.--bg-color-hover]="course.to | courseColor:'14'"
              cdkDrag
              (cdkDragReleased)="released()"
              [cdkDragDisabled]="dragDisabled | async"
            >
              <ng-container [ngTemplateOutlet]="courseDetailsTemplate" [ngTemplateOutletContext]="{ course }"></ng-container>
              <ng-container *cdkDragPlaceholder [ngTemplateOutlet]="placeholderTemplate" [ngTemplateOutletContext]="{ course }"></ng-container>
            </div>
          }
          </div>

        </div>
      }

    </div>
  </div>
</div>


<ng-template #courseDetailsTemplate [templateTypes]="typeToken" let-course="course">
  <div class="details">
    <div class="primary-line">
      <span>{{ course?.to | displayName }}</span>
      @if (course?.to?.period ?? (settings | async)?.period; as period) {
        <span class="period"> - {{ period | displayName }}</span>
      }
    </div>
    @if (course?.to?.events?.length) {
      <div class="flex-align-center secondary-line">
        <mat-icon inline>{{ 'events' | collectionIcon }}</mat-icon>
        <span>{{ course?.to?.events | eventDurations }}</span>
      </div>
    }
    @if (course?.to?.groups?.length) {
      <div class="flex-align-center secondary-line">
        <mat-icon inline>{{ 'groups' | collectionIcon }}</mat-icon>
        <span>{{ course?.to?.groups | pluck:'to' | orderBy:'displayName' | displayNames }}</span>
      </div>
    }
    @if (course?.to?.teachers?.length) {
      <div class="flex-align-center secondary-line">
        <mat-icon inline>{{ 'teachers' | collectionIcon }}</mat-icon>
        <span>{{ course?.to?.teachers | pluck:'to' | orderBy:'displayName' | displayNames }}</span>
      </div>
    }
  </div>

  <mat-icon class="royal-icon">drag_indicator</mat-icon>
</ng-template>


<ng-template #placeholderTemplate [templateTypes]="typeToken" let-course="course">
  <div
    class="drag-item"
    [style.--border-color]="course?.to | courseColor"
    [style.--bg-color]="course?.to | courseColor:'07'"
    [style.--bg-color-hover]="course?.to | courseColor:'14'"
  >
    <ng-container [ngTemplateOutlet]="courseDetailsTemplate" [ngTemplateOutletContext]="{ course }"></ng-container>
  </div>
</ng-template>