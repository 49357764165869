import { NgModule                        } from '@angular/core';
import { CommonModule                    } from '@angular/common';
import { FormsModule,
         ReactiveFormsModule             } from '@angular/forms';
import { DragDropModule                  } from '@angular/cdk/drag-drop';

import { AppCommonModule                 } from 'app/common/common.module';
import { TranslationModule               } from 'app/core/translate/translate.module';

import { ComponentsModule as AppShared   } from 'app/shared/components/components.module';
import { PipesModule                     } from 'app/shared/pipes/pipes.module';
import { DirectivesModule                } from 'app/shared/directives/directives.module';

import { EventsComponent                } from './events.component';
import { NgPipesModule                  } from 'ngx-pipes';

import { PipesModule as _PipesModule    } from '../../pipes/pipes.module';
import { FilterModule                   } from '../filter/filter.module';


@NgModule({
  declarations: [
    EventsComponent
  ],
  exports: [
    EventsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AppShared,
    PipesModule,
    DragDropModule,
    AppCommonModule,
    TranslationModule,
    NgPipesModule,
    _PipesModule,
    FilterModule,
    DirectivesModule
  ]
})
export class EventsModule { }
