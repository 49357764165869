import { Util                            } from '@app/common';

import { SystemState                     } from "./environment.types";

export type Integration = {
  name?:            string;
  value:            IntegrationType;
  category?:        'LMS' | 'SIS';
  logo:             `/assets/integrations/${'schools'|'sports_facilities'}/logos/${string}.${'png' |'svg'}`;
  icon?:            `/assets/integrations/${'schools'|'sports_facilities'}/icons/${string}.${'png' |'svg'}`;
  integrationType?: IntegrationType;
  collections?:     Util.Types.Collection[];
  action?:          'push' | 'pull' | 'push-pull';
  implicitAction?:  'push' | 'pull' | 'push-pull';
}

export enum IntegrationType {
  ADMENTUM   = 'Admentum',
  GOTIT      = 'Gotit',
  INFOMENTOR = 'Infomentor',
  ADELASKOLA = 'Adelaskola',
  SCHOOLSOFT = 'SchoolSoft',
  VKLASS     = 'vKlass',
  IST        = 'IST',
  MEITNER    = 'Meitner',
  SDUI       = 'Sdui',
  ADDITIO    = 'Additio',
  PUPIL      = 'Pupil',
  KONECTO    = 'Konecto',
  EDLEVO     = 'Edlevo',
}

type IntegrationTypes = IntegrationType.ADMENTUM   |
                        IntegrationType.GOTIT      |
                        IntegrationType.INFOMENTOR |
                        IntegrationType.SCHOOLSOFT |
                        IntegrationType.IST        |
                        IntegrationType.SDUI       |
                        IntegrationType.PUPIL      |
                        IntegrationType.KONECTO    |
                        IntegrationType.ADDITIO    |
                        IntegrationType.MEITNER    |
                        IntegrationType.EDLEVO     |
                        IntegrationType.VKLASS;

export const Integrations: Record<IntegrationTypes, Integration> = Object.freeze({
  [IntegrationType.ADMENTUM]: {
    name:  'Admentum',
    category: 'LMS',
    logo: '/assets/integrations/schools/logos/admentum.png',
    icon: '/assets/integrations/schools/icons/admentum.png',
    value: IntegrationType.ADMENTUM,
    collections: [
      'persons',
      'courses',
      'locations'
    ],
    action: 'pull',
    implicitAction: 'pull'
  },
  [IntegrationType.GOTIT]: {
    name:  'Gotit',
    category: 'LMS',
    logo: '/assets/integrations/schools/logos/gotit.png',
    icon: '/assets/integrations/schools/icons/gotit.png',
    value: IntegrationType.GOTIT,
    action: 'pull',
    implicitAction: 'pull'
  },
  [IntegrationType.INFOMENTOR]: {
    name:  'Infomentor',
    category: 'LMS',
    logo: '/assets/integrations/schools/logos/infomentor.png',
    icon: '/assets/integrations/schools/icons/infomentor.png',
    value: IntegrationType.INFOMENTOR,
    action: 'pull',
    implicitAction: 'pull'
  },
  [IntegrationType.SCHOOLSOFT]: {
    name:  'SchoolSoft',
    category: 'LMS',
    logo: '/assets/integrations/schools/logos/schoolsoft.svg',
    icon: '/assets/integrations/schools/icons/schoolsoft.png',
    value: IntegrationType.SCHOOLSOFT,
    collections: [
      'persons',
      'teachers',
      'locations',
      'groups'
    ],
    action: 'pull',
    implicitAction: 'pull'
  },
  [IntegrationType.IST]: {
    name:  'IST',
    category: 'LMS',
    icon:  '/assets/integrations/schools/icons/ist.png',
    logo:  '/assets/integrations/schools/logos/ist.svg',
    value: IntegrationType.IST,
    collections: [
      'persons',
      'courses',
      'groups',
      'teachers'
    ],
    action: 'pull',
    implicitAction: 'pull'
  },
  [IntegrationType.VKLASS]: {
    name: 'Vklass',
    category: 'LMS',
    icon: '/assets/integrations/schools/icons/vklass.svg',
    logo: '/assets/integrations/schools/logos/vklass.svg',
    value: IntegrationType.VKLASS,
    action: 'pull',
    implicitAction: 'pull'
  },
  [IntegrationType.MEITNER]: {
    name: 'Meitner',
    category: 'LMS',
    icon: '/assets/integrations/schools/icons/meitner.svg',
    logo: '/assets/integrations/schools/logos/meitner.svg',
    value: IntegrationType.MEITNER,
    action: 'pull',
    implicitAction: 'pull'
  },
  [IntegrationType.SDUI]: {
    name: 'Sdui',
    category: 'LMS',
    icon: '/assets/integrations/schools/icons/sdui.svg',
    logo: '/assets/integrations/schools/logos/sdui.svg',
    value: IntegrationType.SDUI,
    collections: [
      'locations',
      'courses',
      'groups',
      'teachers'
    ],
    action: 'push',
    implicitAction: 'push'
  },
  [IntegrationType.PUPIL]: {
    name: 'Pupil',
    category: 'LMS',
    icon: '/assets/integrations/schools/icons/pupil.svg',
    logo: '/assets/integrations/schools/logos/pupil.svg',
    value: IntegrationType.PUPIL,
    action: 'pull',
    implicitAction: 'pull'
  },
  [IntegrationType.KONECTO]: {
    name: 'Konecto',
    category: 'LMS',
    icon: '/assets/integrations/schools/icons/konecto.svg',
    logo: '/assets/integrations/schools/logos/konecto.svg',
    value: IntegrationType.KONECTO,
    action: 'pull',
    implicitAction: 'pull'
  },
  [IntegrationType.ADDITIO]: {
    name: 'Additio',
    category: 'LMS',
    icon: '/assets/integrations/schools/icons/additio.svg',
    logo: '/assets/integrations/schools/logos/additio.svg',
    value: IntegrationType.ADDITIO,
    action: 'pull',
    implicitAction: 'pull'
  },
  [IntegrationType.EDLEVO]: {
    name: 'Edlevo',
    category: 'LMS',
    icon: '/assets/integrations/schools/icons/edlevo.svg',
    logo: '/assets/integrations/schools/logos/edlevo.svg',
    value: IntegrationType.EDLEVO,
    collections: [
      'persons',
      'courses',
      'groups',
      'teachers'
    ],
    action: 'pull',
    implicitAction: 'pull'
  }
});

export const SystemStates: SystemState = Object.freeze({
  school: {
    collections: {
      settings: {
        icon: 'settings'
      },
      courses: {
        icon: 'menu_book',
        origins: {
          omit: {
            'app-schema-filter-dropdowns': true
          }
        }
      },
      events: {
        icon: 'event',
        origins: {
          omit: {
            'app-schema-filter-dropdowns': true
          }
        }
      },
      teachers: {
        icon: 'school'
      },
      groups: {
        icon: 'people'
      },
      locations: {
        icon: 'room'
      },
      overlapGroups: {
        icon: 'filter_none'
      },
      lockedTimes: {
        icon: 'lock_clock'
      },
      periods: {
        icon: 'date_range'
      },
      rootIntervals: {
        icon: 'calendar_view_month'
      },
      persons: {
        icon: 'person',
        properties: {
          omit: {
            SSN:          false,
            sex:          false,
            emails:       false,
            phoneNumbers: true,
            lunch:        true,
            lockedTimes:  true,
          }
        }
      }
    }
  },
  sports_facility: {
    collections: {
      settings: {
        icon: 'settings'
      },
      courses: {
        icon: 'groups'
      },
      events: {
        icon: 'event',
        origins: {
          omit: {
            'app-schema-filter-dropdowns': true
          }
        }
      },
      locations: {
        icon: 'room'
      },
      lockedTimes: {
        icon: 'lock_clock'
      },
      periods: {
        icon: 'date_range'
      },
      overlapGroups: {
        icon: 'filter_none'
      },
    }
  }
});