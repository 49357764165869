import { Directive, Input, TemplateRef } from '@angular/core';

////
//// USAGE
////

// TS:
// (no need to initialize the typeToken property)
// protected readonly typeToken: { param1: Type1, param2: Type2 };

// HTML:
// <ng-template #templateRef [templateTypes]="typeToken" let-param1="param1" let-param2="param2">
//   ...
// </ng-template>
// <ng-container *ngTemplateOutlet="templateRef; context: { param1: var1, param2: var2 }"></ng-container>

@Directive({selector: 'ng-template[templateTypes]'})
export class TemplateTypesDirective<TypeToken> {

  // how you tell the directive what the type should be
  @Input('templateTypes')
  typeToken: TypeToken;

  // the directive gets the template from Angular
  constructor(private contentTemplate: TemplateRef<TypeToken>) { }

  // this magic is how we tell Angular the context type for this directive, which then propagates down to the type of the template
  static ngTemplateContextGuard<TypeToken>(dir: TemplateTypesDirective<TypeToken>, ctx: unknown): ctx is TypeToken{ return true; }
}