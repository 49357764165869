export const LANG_ES_NAME = 'es';

export const LANG_ES_TRANS = {
  "account.index.header": "Gestionar cuenta",
  "account.notifications.header": "Notificaciones",
  "account.notifications.menu": "Notificaciones",
  "account.notifications.page.descr": "La tabla de abajo permite ajustar varios aspectos del sistema de notificaciones utilizado en toda la aplicación.",
  "account.notifications.page.title": "Notificaciones",
  "account.security.header": "Seguridad",
  "account.security.menu": "Seguridad",
  "account.security.page.change-password.descr": "Rellene el formulario de abajo para cambiar su contraseña de acceso.",
  "account.security.page.change-password.error.descr": "La contraseña actual proporcionada no coincide con la real. Por favor, inténtelo de nuevo.",
  "account.security.page.change-password.error.title": "No se puede actualizar la contraseña",
  "account.security.page.change-password.success.descr": "¡Su contraseña ha sido actualizada!",
  "account.security.page.change-password.success.title": "Éxito",
  "account.security.page.change-password.title": "Cambiar contraseña",
  "account.security.page.title": "Seguridad",
  "account.shortcuts.header": "Atajos",
  "account.shortcuts.menu": "Atajos",
  "account.shortcuts.page.category.editor.description": "Atajos para el editor de horarios.",
  "account.shortcuts.page.category.editor.title": "Editor",
  "account.shortcuts.page.description": "A continuación se enumeran los atajos de teclado para acciones comunes en la aplicación. Puede cambiarlos según sus preferencias haciendo clic en el atajo y presionando la nueva combinación de teclas.",
  "account.shortcuts.page.editor/hideEvent.description": "Alterna el estado oculto de las lecciones seleccionadas.",
  "account.shortcuts.page.editor/hideEvent.label": "Ocultar lección",
  "account.shortcuts.page.editor/moveEventEarlier.description": "Mueve las lecciones seleccionadas 5 minutos antes.",
  "account.shortcuts.page.editor/moveEventEarlier.label": "Mover lección antes",
  "account.shortcuts.page.editor/moveEventIndividually.description": "Mantenga presionada esta combinación de teclas mientras arrastra una lección vinculada para moverla individualmente.",
  "account.shortcuts.page.editor/moveEventIndividually.label": "Mover lección vinculada individualmente",
  "account.shortcuts.page.editor/moveEventLater.description": "Mueve las lecciones seleccionadas 5 minutos después.",
  "account.shortcuts.page.editor/moveEventLater.label": "Mover lección después",
  "account.shortcuts.page.editor/moveEventMuchEarlier.description": "Mueve las lecciones seleccionadas 60 minutos antes.",
  "account.shortcuts.page.editor/moveEventMuchEarlier.label": "Mover lección mucho antes",
  "account.shortcuts.page.editor/moveEventMuchLater.description": "Mueve las lecciones seleccionadas 60 minutos después.",
  "account.shortcuts.page.editor/moveEventMuchLater.label": "Mover lección mucho después",
  "account.shortcuts.page.editor/moveEventNextDay.description": "Mueve las lecciones seleccionadas al día siguiente.",
  "account.shortcuts.page.editor/moveEventNextDay.label": "Mover lección al día siguiente",
  "account.shortcuts.page.editor/moveEventPrevDay.description": "Mueve las lecciones seleccionadas al día anterior.",
  "account.shortcuts.page.editor/moveEventPrevDay.label": "Mover lección al día anterior",
  "account.shortcuts.page.editor/multipleSelectEvents.description": "Mantener presionada esta tecla le permitirá seleccionar varias lecciones.",
  "account.shortcuts.page.editor/multipleSelectEvents.label": "Seleccionar varias lecciones",
  "account.shortcuts.page.editor/parkEvent.description": "Alterna el estado estacionado de las lecciones seleccionadas.",
  "account.shortcuts.page.editor/parkEvent.label": "Estacionar lección",
  "account.shortcuts.page.editor/pinEvent.description": "Alterna el estado fijado de las lecciones seleccionadas.",
  "account.shortcuts.page.editor/pinEvent.label": "Fijar lección",
  "account.shortcuts.page.interference": "El comando para este acceso directo ya es utilizado por \"{{ label }}\" de la misma categoría. Por favor elija otro.",
  "account.shortcuts.page.notifications.error": "Error al actualizar el acceso directo. Por favor intente nuevamente.",
  "account.shortcuts.page.restore.description": "Restaurar todos los atajos a sus valores predeterminados.",
  "account.shortcuts.page.title": "Accesos directos del teclado",
  "account.workspace.header": "Espacio de trabajo",
  "account.workspace.menu": "Espacio de trabajo",
  "account.workspace.page.backgroundDataAnalysis.descr": "Esta función analiza continuamente los datos de su horario y le proporciona sugerencias para mejoras. El análisis continuo a veces afecta el rendimiento general de la aplicación y, por lo tanto, puede deshabilitarse. Más tarde se puede volver a habilitar en cualquier momento.",
  "account.workspace.page.backgroundDataAnalysis.label": "Análisis de datos de horario",
  "account.workspace.page.defaultEventDurations.descr": "Aquí, puede definir conjuntos de duraciones de lecciones predeterminadas para facilitar la creación de lecciones.",
  "account.workspace.page.defaultEventDurations.label": "Duraciones de lecciones predeterminadas",
  "account.workspace.page.descr": "Estos tipos de preferencias le permiten modificar y personalizar el espacio de trabajo de la aplicación.",
  "account.workspace.page.displayCenterOfAttraction.descr": "El tiempo preferido determina qué punto en el tiempo se dibujan las lecciones durante el procedimiento de programación automática. Al habilitar esta opción, el tiempo preferido predeterminado puede anularse para cursos y lecciones individuales.",
  "account.workspace.page.displayCenterOfAttraction.label": "Mostrar la opción de curso y lección <i>tiempo preferido</i>",
  "account.workspace.page.displayChat.descr": "Si el chat en la esquina inferior izquierda es visible.",
  "account.workspace.page.displayChat.label": "Mostrar chat",
  "account.workspace.page.displayEventsTable.descr": "En esta tabla, encontrará las lecciones de todos los cursos.",
  "account.workspace.page.displayEventsTable.label": "Mostrar tabla de lecciones",
  "account.workspace.page.displayPersonsTable.descr": "En esta tabla, encontrará estudiantes de todos los grupos y aquellos que no son miembros de ningún grupo.",
  "account.workspace.page.displayPersonsTable.label": "Mostrar tabla de estudiantes",
  "account.workspace.page.displayTooltips.descr": "Si deben ser visibles los tooltips con información que explique más detalladamente el concepto.",
  "account.workspace.page.displayTooltips.label": "Mostrar información sobre herramientas",
  "account.workspace.page.language.descr": "Establece el idioma de la aplicación.",
  "account.workspace.page.notifications.error": "Error al actualizar la preferencia. Por favor intente nuevamente.",
  "account.workspace.page.title": "Espacio de trabajo",
  "attributeDescriptions.course.centerOfAttraction": "Las lecciones que pertenecen al curso se dibujan a su tiempo preferido durante el proceso de programación automática",
  "attributeDescriptions.course.color": "Establece el color de visualización para las lecciones que pertenecen al curso",
  "attributeDescriptions.course.days": "Establece los días permitidos para las lecciones que pertenecen al curso",
  "attributeDescriptions.course.eventDurationVariation": "Establece la diferencia máxima entre la duración real de una lección y su duración preferida para las lecciones que pertenecen al curso. Sin embargo, se conserva la duración total del curso.",
  "attributeDescriptions.course.events": "Las lecciones que pertenecen al curso",
  "attributeDescriptions.course.groups": "Establece los grupos que tomarán las lecciones que pertenecen al curso",
  "attributeDescriptions.course.intervals": "Establece el marco de tiempo para las lecciones que pertenecen al curso",
  "attributeDescriptions.course.locations": "Establece las habitaciones que se pueden reservar para las lecciones que pertenecen al curso",
  "attributeDescriptions.course.lockedTimes": "Establece intervalos de tiempo prohibidos para las lecciones que pertenecen al curso",
  "attributeDescriptions.course.minBreakLength": "Establece el descanso más corto permitido antes y después de las lecciones que pertenecen al curso",
  "attributeDescriptions.course.overlapGroup": "Define un conjunto de cursos cuyas lecciones pueden superponerse. Sin embargo, las lecciones que pertenecen al mismo curso nunca se permiten superponerse. Durante el proceso de programación automática, se intenta emparejar las lecciones dentro de dicho grupo de la mejor manera posible.",
  "attributeDescriptions.course.period": "Establece el período de tiempo para las lecciones que pertenecen al curso, por ejemplo, otoño, primavera o semanas impares y pares. Tenga en cuenta que antes de que se pueda seleccionar un período, debe crearse.",
  "attributeDescriptions.course.subject": "El tema del curso",
  "attributeDescriptions.course.teachers": "Establece los maestros que enseñarán las lecciones que pertenecen al curso",
  "attributeDescriptions.event.centerOfAttraction": "La lección se dibuja a su tiempo preferido durante el proceso de programación automática",
  "attributeDescriptions.event.color": "Establece el color de visualización de la lección",
  "attributeDescriptions.event.course": "El curso al que pertenece la lección",
  "attributeDescriptions.event.courseId": "El ID público del curso al que pertenece la lección",
  "attributeDescriptions.event.days": "Establece los días permitidos de la lección",
  "attributeDescriptions.event.duration": "Establece la duración de la lección",
  "attributeDescriptions.event.durationVariation": "Establece la diferencia máxima entre la duración real de la lección y su duración preferida. Sin embargo, se conserva la duración total del curso.",
  "attributeDescriptions.event.fixedStart": "Fija la hora de inicio de la lección",
  "attributeDescriptions.event.groups": "Establece los grupos que tomarán la lección",
  "attributeDescriptions.event.intervals": "Establece el marco de tiempo para la lección",
  "attributeDescriptions.event.locations": "Establece las habitaciones que se pueden reservar para la lección",
  "attributeDescriptions.event.lockedTimes": "Establece intervalos de tiempo prohibidos para la lección",
  "attributeDescriptions.event.minBreakLength": "Establece el descanso más corto permitido antes y después de la lección",
  "attributeDescriptions.event.overlapSpecies": "Las lecciones vinculadas se pegan y se programarán al mismo tiempo",
  "attributeDescriptions.event.period": "Establece el período de tiempo para la lección, por ejemplo, otoño, primavera o semanas impares y pares. Tenga en cuenta que antes de que se pueda seleccionar un período, debe haberse creado.",
  "attributeDescriptions.event.preferredDuration": "Establece la duración preferida de la lección. Después del proceso de programación automática, la duración real no excederá de \"duración preferida ± variación de duración\"",
  "attributeDescriptions.event.teachers": "Establece los maestros que enseñarán la lección",
  "attributeDescriptions.group.days": "Establece los días permitidos del grupo",
  "attributeDescriptions.group.intervals": "Establece el marco de tiempo para el grupo",
  "attributeDescriptions.group.lockedTimes": "Establece intervalos de tiempo prohibidos para el grupo",
  "attributeDescriptions.group.lunch": "Establece los descansos para almorzar del grupo",
  "attributeDescriptions.group.minBreakLength": "Establece el descanso más corto permitido entre lecciones para el grupo",
  "attributeDescriptions.group.rootIntervals": "Establece los bloques del grupo",
  "attributeDescriptions.groups.members": "Establece los miembros del grupo",
  "attributeDescriptions.groups.species": "Establece el tipo de grupo",
  "attributeDescriptions.groups.parentGroups": "Establece las clases constituyentes del grupo basadas en los miembros del grupo.",
  "attributeDescriptions.location.days": "Establece los días permitidos de la habitación",
  "attributeDescriptions.location.lockedTimes": "Establece intervalos de tiempo prohibidos para la habitación",
  "attributeDescriptions.location.minBreakLength": "Establece el margen de tiempo más corto entre dos reservas de la habitación",
  "attributeDescriptions.person.emails": "Establece el correo electrónico del estudiante",
  "attributeDescriptions.person.firstName": "Establece el nombre del estudiante",
  "attributeDescriptions.person.group": "Establece el grupo del estudiante",
  "attributeDescriptions.person.lastName": "Establece el apellido del estudiante",
  "attributeDescriptions.person.lockedTimes": "Establece intervalos de tiempo prohibidos para el estudiante",
  "attributeDescriptions.person.lunch": "Establece los descansos para almorzar del estudiante",
  "attributeDescriptions.person.phoneNumbers": "Establece el número de teléfono del estudiante",
  "attributeDescriptions.person.sex": "Establece el género del estudiante",
  "attributeDescriptions.person.SSN": "Establece el número de seguro social del estudiante",
  "attributeDescriptions.shared.displayName": "Un nombre utilizado en toda la aplicación",
  "attributeDescriptions.teacher.days": "Establece los días permitidos del maestro",
  "attributeDescriptions.teacher.emails": "Establece el correo electrónico del maestro",
  "attributeDescriptions.teacher.firstName": "Establece el nombre del maestro",
  "attributeDescriptions.teacher.intervals": "Establece el marco de tiempo para el maestro",
  "attributeDescriptions.teacher.lastName": "Establece el apellido del maestro",
  "attributeDescriptions.teacher.lockedTimes": "Establece intervalos de tiempo prohibidos para el maestro",
  "attributeDescriptions.teacher.lunch": "Establece los descansos para almorzar del maestro",
  "attributeDescriptions.teacher.minBreakLength": "Establece el descanso más corto permitido entre lecciones para el maestro",
  "attributes.course.eventDurationVariation": "Variación de la duración de la lección",
  "attributes.course.events": "Lecciones",
  "attributes.course.overlapGroup": "Cursos paralelos",
  "attributes.division.default.dayRank": "Clasificación de día predeterminada",
  "attributes.division.default.dependencyMinimumBreakLength": "Para habitaciones",
  "attributes.division.default.dependencyRank": "Clasificación de habitación predeterminada",
  "attributes.division.default.eventDurationVariation": "Variación de la duración de la lección",
  "attributes.division.default.eventMinimumBreakLength": "Entre lecciones",
  "attributes.division.default.groupMinimumBreakLength": "Para grupos y maestros",
  "attributes.division.default.lunch": "Descanso para almorzar predeterminado",
  "attributes.division.default.maxNumDailyWorkingHours": "Diario",
  "attributes.division.default.maxNumWorkingHours": "Total",
  "attributes.division.default.period": "Período",
  "attributes.division.end": "Fecha de finalización",
  "attributes.division.published": "publicar",
  "attributes.division.settings.dayEnd": "Fin del día",
  "attributes.division.settings.dayStart": "Inicio del día",
  "attributes.division.settings.discretization": "Discretización",
  "attributes.division.settings.numDays": "Número de días",
  "attributes.division.settings.potentialCenter": "Hora preferida",
  "attributes.division.start": "Fecha de inicio",
  "attributes.event.course": "Curso",
  "attributes.event.courseId": "ID del curso",
  "attributes.event.current_duration": "Duración actual de la lección",
  "attributes.event.durationVariation": "Variación de la duración",
  "attributes.event.fixedStart": "Hora de inicio fija",
  "attributes.event.inherit": "Heredar del curso",
  "attributes.event.overlapSpecies": "Lecciones vinculadas",
  "attributes.event.preferredDuration": "Duración de la lección",
  "attributes.generation.status.COMPLETED": "Hecho",
  "attributes.generation.status.PENDING": "Esperando",
  "attributes.generation.status.STARTED": "Empezado",
  "attributes.groups.parentGroups": "Tipo de grupo",
  "attributes.groups.species.none": "Grupo de enseñanza",
  "attributes.groups.species.class": "Clase",
  "attributes.groups.species": "Tipo de grupo",
  "attributes.groups.members": "Miembros",
  "attributes.teachers.signature": "Firma",
  "attributes.integrations.APPROVED": "Activo",
  "attributes.integrations.DISABLED": "Discapacitado",
  "attributes.integrations.PENDING": "Pendiente",
  "attributes.integrations.REJECTED": "Rechazado",
  "attributes.location.days": "Días disponibles",
  "attributes.location.minBreakLength": "Margen de tiempo",
  "attributes.person.firstName": "Nombre de pila",
  "attributes.person.group": "Grupo",
  "attributes.person.lastName": "Apellido",
  "attributes.person.sex": "Género",
  "attributes.person.SSN": "Número de seguro social",
  "attributes.shared.centerOfAttraction": "Hora preferida",
  "attributes.shared.createdAt": "Creado",
  "attributes.shared.color": "Color",
  "attributes.shared.course": "Curso",
  "attributes.shared.day": "Día",
  "attributes.shared.days": "Días permitidos",
  "attributes.shared.displayName": "Nombre",
  "attributes.shared.duration": "Duración",
  "attributes.shared.durationVariation": "Variación de la duración",
  "attributes.shared.dynamicLockedTime.custom": "Establecer para cada día",
  "attributes.shared.dynamicLockedTime.default": "Valor predeterminado",
  "attributes.shared.dynamicLockedTime.none": "Ninguna",
  "attributes.shared.dynamicLockedTime.repeated": "Igual para cada día",
  "attributes.shared.emails": "Email",
  "attributes.shared.end": "Fin",
  "attributes.shared.endTime": "Hora de finalización",
  "attributes.shared.groups": "Grupos",
  "attributes.shared.intervals": "Marco de tiempo",
  "attributes.shared.intervals.multiple": "Diferente para cada día",
  "attributes.shared.intervals.none": "Usar marco de tiempo predeterminado",
  "attributes.shared.intervals.single": "Igual para cada día",
  "attributes.shared.locations": "Aulas permitidas",
  "attributes.shared.lockedTimes": "Tiempos prohibidos",
  "attributes.shared.lunch": "Almuerzo",
  "attributes.shared.minBreakLength": "Longitudes de descanso",
  "attributes.shared.minBreakLength.asymmetrical": "Longitudes diferentes",
  "attributes.shared.minBreakLength.asymmetrical.after": "Después",
  "attributes.shared.minBreakLength.asymmetrical.before": "Antes",
  "attributes.shared.minBreakLength.asymmetrical.tooltip": "{{ before }} antes y {{ after }} después",
  "attributes.shared.minBreakLength.default": "Valor predeterminado",
  "attributes.shared.minBreakLength.none": "Ninguna",
  "attributes.shared.minBreakLength.symmetrical": "Longitudes iguales antes y después",
  "attributes.shared.period": "Período",
  "attributes.shared.period.entire": "Todas las semanas",
  "attributes.shared.phoneNumbers": "Números de teléfono",
  "attributes.shared.rootIntervals": "Bloques",
  "attributes.shared.start": "Inicio",
  "attributes.shared.startTime": "Hora de inicio",
  "attributes.shared.subject": "Asignatura",
  "attributes.shared.teachers": "Docentes",
  "auth.role.admin": "Administrador",
  "auth.role.partner": "Socio",
  "auth.role.unrestricted": "Usuario regular",
  "common.goto_schedule": "Ir al calendario",
  "common.+_n_more": "+ {{n}} más",
  "common.a_lunch_belonging_to_the_group": "un almuerzo perteneciente al grupo",
  "common.a_lunch_belonging_to_the_person": "un almuerzo perteneciente al estudiante",
  "common.a_lunch_belonging_to_the_teacher": "un almuerzo perteneciente al docente",
  "common.about": "Estado",
  "common.add": "Añadir",
  "common.add_course": "Añadir curso",
  "common.add_event": "Añadir lección(es): {{ event-durations }}",
  "common.add_event_advanced": "Añadir lección(es)",
  "common.add_filter": "Añadir filtro",
  "common.add_group": "Añadir grupo",
  "common.add_location": "Añadir sala",
  "common.add_one_more": "Añadir uno más",
  "common.add_period": "Añadir período",
  "common.add_person": "Añadir estudiante",
  "common.add_teacher": "Añadir docente",
  "common.advanced_settings": "Configuración avanzada",
  "common.all": "Todos",
  "common.alone": "Solo",
  "common.an_event_belonging_to_the_course": "una lección perteneciente al curso",
  "common.analysis": "Análisis",
  "common.and": "y",
  "common.and1Other": "y otro",
  "common.andNOthers": "y {{n}} otros",
  "common.associated_partner": "Socio asociado",
  "common.back": "Atrás",
  "common.back_to_login": "Volver al inicio de sesión",
  "common.calendar_view": "Vista de calendario",
  "common.cancel": "Cancelar",
  "common.change_password": "Cambiar contraseña",
  "common.chooseOther": "Elegir otro",
  "common.clear": "Limpiar",
  "common.close": "Cerrar",
  "common.complete": "Completar",
  "common.computing": "Computación",
  "common.confirmation": "Confirmación",
  "common.connect": "Conectar",
  "common.connect_error": "Error de conexión",
  "common.copy": "Copiar",
  "common.country": "País",
  "common.counts": "Cuenta",
  "common.course": "Curso",
  "common.courses": "Cursos",
  "common.create": "Crear",
  "common.create_course": "Crear curso",
  "common.create_group": "Crear grupo",
  "common.create_location": "Crear sala",
  "common.create_person": "Crear estudiante",
  "common.create_schedule": "Crear horario",
  "common.create_teacher": "Crear docente",
  "common.created_at": "Creado",
  "common.creating": "Creando",
  "common.creation_fail": "Algo salió mal",
  "common.creation_success": "Creado",
  "common.custom_search": "Búsqueda personalizada",
  "common.day": "Día",
  "common.days": "Días",
  "common.default": "Predeterminado",
  "common.delete": "Eliminar",
  "common.delete_account": "Eliminar cuenta",
  "common.deleting": "Eliminando",
  "common.discard": "Descartar",
  "common.download": "Descargar",
  "common.drop_here": "Soltar aquí",
  "common.duplicate": "Duplicar",
  "common.duplicated": "Duplicado",
  "common.earliest": "Más temprano",
  "common.edit": "Editar",
  "common.edit_columns": "Editar columnas",
  "common.edit_failed": "No se pudieron guardar los cambios",
  "common.edited_at": "Última edición",
  "common.email": "correo electrónico",
  "common.empty": "Vacío",
  "common.erroneous_value": "Valor inválido",
  "common.error": "Error",
  "common.errors": "errores",
  "common.event": "Clase",
  "common.event_durations": "duración de la lección",
  "common.events": "Clases",
  "common.expand": "Expandir",
  "common.export": "Exportar",
  "common.filter": "Filtrar",
  "common.flaw": "error",
  "common.flaws": "errores",
  "common.forgot_password": "¿Olvidaste tu contraseña?",
  "common.generalErrorMessage": "Ups, algo salió mal...",
  "common.generic_error": "Algo salió mal",
  "common.generic_help": "Estamos trabajando en resolver el problema.",
  "common.goto-tutorial": "Ir al tutorial",
  "common.group": "Grupo",
  "common.groups": "Grupos",
  "common.help": "Ayuda",
  "common.hide": "Ocultar",
  "common.hour": "Hora",
  "common.hour_short": "h",
  "common.hours": "Horas",
  "common.hours_short": "h",
  "common.ignore": "Ignorar",
  "common.import": "Importar",
  "common.inheritFromCourse": "Heredar del curso",
  "common.inheritFromCourseValue": "Heredar del curso ({{value}})",
  "common.invalid": "Inválido",
  "common.invalid_division_id": "No se pudo encontrar el horario",
  "common.invalid_value": "Valor inválido",
  "common.language": "Idioma",
  "common.latest": "Más tarde",
  "common.linked_events": "Lecciones vinculadas",
  "common.list_view": "Vista de lista",
  "common.loading": "Cargando",
  "common.location": "Aula",
  "common.locations": "Aulas",
  "common.locked": "Bloqueado",
  "common.locked_time": "Tiempo prohibido",
  "common.locked_times": "Tiempos prohibidos",
  "common.lockedTime": "Hora prohibida",
  "common.lockedTimes": "Horas prohibidas",
  "common.login": "Iniciar sesión",
  "common.logout": "Cerrar sesión",
  "common.lunch": "Comida",
  "common.make": "hacer",
  "common.makes": "hace",
  "common.manage_account": "Administrar tu cuenta",
  "common.minimize": "Minimizar",
  "common.minute": "Minuto",
  "common.minute_short": "min",
  "common.minutes": "Minutos",
  "common.minutes_short": "min",
  "common.modified_after": "Modificado después de",
  "common.nameless": "Sin título",
  "common.next": "Siguiente",
  "common.no": "No",
  "common.no_available_data": "No hay datos disponibles",
  "common.no_events_to_show": "No hay lecciones para mostrar",
  "common.no_match": "Sin coincidencias",
  "common.no_value": "Sin valor",
  "common.none_plural": "Ninguno",
  "common.none_singular": "Ninguno",
  "common.not_placed": "No programado",
  "common.of": "de",
  "common.or": "O",
  "common.other": "Otro",
  "common.others": "otros",
  "common.overlapGroup": "Conjunto de cursos paralelos",
  "common.overlapGroups": "Conjuntos de cursos paralelos",
  "common.period": "Período",
  "common.periods": "Períodos",
  "common.person": "Alumno/a",
  "common.persons": "Alumnado",
  "common.print": "Imprimir",
  "common.related-content": "Contenido relacionado",
  "common.reload": "Recargar",
  "common.remaining": "Restante",
  "common.remove": "Eliminar",
  "common.rename": "Renombrar",
  "common.resend": "Reenviar",
  "common.reset": "Limpiar",
  "common.reset_filter": "Limpiar filtro",
  "common.reset_password_fail": "No se pudo enviar un enlace de restablecimiento a este usuario",
  "common.reset_password_success": "Se envió un enlace de restablecimiento a",
  "common.restore": "Restaurar",
  "common.retry": "Reintentar",
  "common.rootInterval": "Bloque",
  "common.rootIntervals": "Bloque",
  "common.save": "Guardar",
  "common.save_failed": "Error al guardar",
  "common.saved": "Guardado",
  "common.saving": "Guardando",
  "common.schedule": "Horario",
  "common.school": "Escuela",
  "common.school_year": "Año escolar",
  "common.screen_size": "Tamaño de pantalla demasiado pequeño",
  "common.search": "Buscar",
  "common.search.course": "Buscar curso",
  "common.search.event": "Buscar lección",
  "common.seconds": "Segundos",
  "common.seconds_short": "s",
  "common.select": "Seleccionar",
  "common.select_entity_to_show_events": "Seleccione un grupo, docente o sala para mostrar sus lecciones.",
  "common.select_file": "Seleccionar archivo",
  "common.selected": "Seleccionado",
  "common.selected_file": "Archivo seleccionado: {{file}}",
  "common.settings": "Configuración",
  "common.sex.man": "Hombre",
  "common.sex.woman": "Mujer",
  "common.share": "Compartir",
  "common.show": "Mostrar",
  "common.show_all_events": "Mostrar todas las lecciones",
  "common.sports_facility": "Instalación deportiva",
  "common.subject": "Materia",
  "common.subjects": "Asignaturas",
  "common.sync": "Sincronizar",
  "common.synchronising": "  Sincronizando\n",
  "common.teacher": "Docente",
  "common.teachers": "Docentes",
  "common.terms": "Términos y condiciones",
  "common.the_course": "El curso",
  "common.the_courses": "Los cursos",
  "common.the_event": "La clase",
  "common.the_events": "Las clases",
  "common.the_group": "El grupo",
  "common.the_groups": "Los grupos",
  "common.the_location": "El aula",
  "common.the_locations": "Las aulas",
  "common.the_lunch": "El almuerzo",
  "common.the_lunches": "Los almuerzos",
  "common.the_period": "El período",
  "common.the_periods": "Los periodos",
  "common.the_person": "El alumno/a",
  "common.the_persons": "El alumnado",
  "common.the_subjects": "Las asignaturas",
  "common.the_teacher": "El docente",
  "common.the_teachers": "Los docentes",
  "common.time_settings": "Configuración de tiempo",
  "common.toggle_all": "Seleccionar todo",
  "common.total": "Total",
  "common.type": "Tipo",
  "common.unknown": "desconocido",
  "common.update": "Actualizar",
  "common.updated": "Actualizado",
  "common.upload": "Subir",
  "common.useDefault": "Usar predeterminado",
  "common.useDefaultValue": "Usar predeterminado ({{value}})",
  "common.username": "Nombre de usuario",
  "common.users": "Usuarios",
  "common.value": "Valor",
  "common.versions": "Versiones",
  "common.warning": "advertencia",
  "common.warnings": "advertencias",
  "common.week": "Semana",
  "common.week_short": "sem.",
  "common.weeks": "Semanas",
  "common.yes": "Sí",
  "common.YYYY-MM-DD": "DD-MM-YYYY",
  "common.zoom_center": "Restaurar zoom",
  "common.zoom_in": "Acercar",
  "common.zoom_out": "Alejar",
  "common.today": "Hoy",
  "common.tomorrow": "Mañana",
  "common.this_week": "Esta semana",
  "common.next_week": "Próxima semana",
  "common.this_month": "Este mes",
  "common.next_month": "Próximo mes",
  "common.modify": "Modificar",
  "common.calendar": "Calendario",
  "common.list": "Lista",
  "common.changes": "Cambios",
  "common.cancelled": "Cancelado",
  "common.no_selected": "No seleccionado",
  "common.no_exceptions": "No hay excepciones",
  "common.no_absences": "No hay ausencias",
  "common.substitutions": "Sustituciones",
  "common.absences": "Ausencias",
  "common.absentees": "Ausentes",
  "common.reason": "Razón",
  "common.filter_by_groups": "Filtrar por grupos",
  "common.not_available_for_current_selection": "No disponible para la selección actual",
  'common.cancel_event': 'Cancelar lección',
  'common.comment': 'Comentario',
  "common.error_code": "Código de error",
  "current language": "Español",
  "dialogs.admentumImport.step_1.descr": "Aquí puede elegir qué horario y cursos asociados, docentes, etc. importar desde la plataforma Admentum. Si tiene varios horarios para un año escolar, se pueden diferenciar por su número de identificación.",
  "dialogs.admentumImport.step_1.required": "Por favor, elija un horario para continuar.",
  "dialogs.admentumImport.step_1.title": "Elegir un horario",
  "dialogs.admentumImport.step_2.descr": "Al elegir una semana, se importarán todas las lecciones de esa semana. En consecuencia, si no se elige ninguna semana, no se importarán lecciones de Admentum.",
  "dialogs.admentumImport.step_2.title": "Elegir una semana",
  "dialogs.admentumImport.step_3.descr": "Puede cambiar el nombre del horario en cualquier momento después de la importación.",
  "dialogs.admentumImport.step_3.label": "Nombre del horario",
  "dialogs.admentumImport.step_3.required": "Por favor, dé un nombre al horario.",
  "dialogs.admentumImport.step_3.title": "Dar un nombre al horario",
  "dialogs.create-courses.by-hand.label": "A mano",
  "dialogs.create-courses.in-bulk.for_each_of": "Crear curso(s) para cada uno de",
  "dialogs.create-courses.in-bulk.label": "En masa",
  "inputAnalysis.computing": "Computando...",
  "inputAnalysis.dialog.description": "Los datos del horario se analizan continuamente y los resultados se presentan en las tres pestañas de la derecha. Cada pestaña representa una categoría de errores en los datos del horario, que se describen a continuación.",
  "inputAnalysis.dialog.empty_category": "No hay comentarios en esta categoría",
  "inputAnalysis.dialog.overview.title": "Resumen",
  "inputAnalysis.dialog.title": "Análisis de datos del horario",
  "inputAnalysis.disabled": "El análisis de datos está deshabilitado pero se puede habilitar en cualquier momento en la configuración del espacio de trabajo.",
  "inputAnalysis.entities.theLunch": "El almuerzo perteneciente a {{ theGroupOrTeacher }}",
  "inputAnalysis.errors.21.10.0.description": "No hay un día permitido común para la lección y los grupos, docentes y salas que están conectados a ella. Vea qué días están bloqueados a continuación:",
  "inputAnalysis.errors.21.10.0.title": "{{The-entity}} no tiene días permitidos según los datos del horario.",
  "inputAnalysis.errors.21.10.1.description": "Esto podría deberse a varias razones, por ejemplo, intervalos de tiempo prohibidos por grupos y docentes conectados, o días excluidos debido a las salas elegidas. Utilice la herramienta de calendario a continuación para averiguar cuál es la causa de este caso en particular y cómo se podría resolver.",
  "inputAnalysis.errors.21.10.1.title": "{{The-entity}} no encaja dentro de su marco de tiempo disponible.",
  "inputAnalysis.errors.24.0.description": "Cada lección por sí sola tiene al menos un día disponible, pero el problema surge cuando están vinculadas y, por lo tanto, se programan el mismo día.",
  "inputAnalysis.errors.24.0.title": "{{The-entities}} están vinculados pero no comparten un día permitido.",
  "inputAnalysis.errors.24.1.description": "Cada lección por sí sola puede encajar en algún intervalo de tiempo, pero el problema surge cuando están vinculadas y, por lo tanto, se programan juntas.",
  "inputAnalysis.errors.24.1.title": "{{The-entities}} están vinculados pero no se pueden programar juntos debido a las restricciones del intervalo de tiempo",
  "inputAnalysis.errors.description": "No se puede programar automáticamente hasta que se resuelvan estos errores.",
  "inputAnalysis.errors.missing_overlap_species.description": "Las lecciones pertenecientes a cursos paralelos no están garantizadas para programarse al mismo tiempo después de que se haya generado un horario, es decir, a menos que estén vinculadas. Este error crítico se puede desactivar en la configuración del horario.",
  "inputAnalysis.errors.missing_overlap_species.title": "{{The-entities}} {{is/are-parallel-courses}}, pero una o más lecciones asociadas no están vinculadas.",
  "inputAnalysis.errors.notification_title": "Los datos del horario contienen errores críticos.",
  "inputAnalysis.errors.title": "Errores críticos",
  "inputAnalysis.etc.modify_period": "Modificar el período",
  "inputAnalysis.etc.schedule_settings": "Configuración del horario",
  "inputAnalysis.etc.schedule-settings": "Configuración del horario",
  "inputAnalysis.etc.specify_forced_lesson_overlaps": "vincular lecciones",
  "inputAnalysis.issues.description": "Se puede programar automáticamente, pero el resultado no será óptimo. Por lo tanto, recomendamos que se resuelvan estos problemas antes de continuar.",
  "inputAnalysis.issues.notification_title": "Los datos del horario contienen problemas",
  "inputAnalysis.issues.overloaded group.description": "Será difícil o imposible programar este grupo sin conflictos de horario.",
  "inputAnalysis.issues.overloaded group.title": "{{The-entity}} está sobrecargado con lecciones.",
  "inputAnalysis.issues.title": "Problemas",
  "inputAnalysis.modules.event intervals.affecting_events": "Lecciones que afectan",
  "inputAnalysis.modules.event intervals.description": "Pase el mouse sobre los maestros, grupos, etc. a continuación para ver cómo sus intervalos de tiempo no disponibles, que se muestran en el calendario en rojo, afectan la lección. También puede hacer lo contrario, es decir, pasar el mouse sobre el calendario para ver si los maestros, grupos, etc. correspondientes permiten la colocación de la lección. Si su fondo se vuelve rayado, significa que no se permite la colocación.",
  "inputAnalysis.modules.event intervals.this event": "Esta lección",
  "inputAnalysis.modules.overloaded group.contributing events": "Lecciones que contribuyen",
  "inputAnalysis.modules.overloaded group.description": "El porcentaje muestra cuánto de un intervalo de tiempo está ocupado por lecciones. <b>Todos los porcentajes superiores al 100% indican un conflicto de horario garantizado.</b> <br> <b>Ejemplo:</b> Si una lección de 30 minutos se puede colocar en un intervalo de tiempo de una hora, el porcentaje de reserva es del 50%. Sin embargo, si las lecciones equivalentes a dos horas se colocan en el mismo intervalo, el porcentaje de reserva es del 200%, lo que provocará un conflicto.<br> Los errores comunes que pueden haber contribuido a que el porcentaje de reserva sea mayor que el 100%: <ul> <li> Olvidó crear conjuntos de lecciones superpuestas. Recuerde colocar todas las lecciones que se enseñarán en paralelo en un conjunto de lecciones superpuestas. <li> Demasiadas lecciones por la mañana / tarde. </li> <li> Demasiadas lecciones en días específicos. </li> </ul>",
  "inputAnalysis.modules.overloaded group.legend.bad": "Malo",
  "inputAnalysis.modules.overloaded group.legend.compact": "Compacto",
  "inputAnalysis.modules.overloaded group.legend.conflict": "Conflicto",
  "inputAnalysis.modules.overloaded group.legend.fixed": "Fijo",
  "inputAnalysis.modules.overloaded group.legend.good": "Bueno",
  "inputAnalysis.modules.overloaded group.legend.sparse": "Disperso",
  "inputAnalysis.notices.completely unconnected event.description": "Estos tipos de lecciones completamente desconectadas son ignoradas por el algoritmo ya que no afectan nada.",
  "inputAnalysis.notices.completely unconnected event.title": "{{The-entity}} no está conectado a un maestro, un estudiante, un grupo o una habitación.",
  "inputAnalysis.notices.description": "Puede indicar que los datos del horario están incompletos.",
  "inputAnalysis.notices.empty course.description": "Los cursos sin lecciones serán ignorados por el algoritmo.",
  "inputAnalysis.notices.empty course.title": "{{The-entity}} no contiene ninguna lección.",
  "inputAnalysis.notices.empty_period.description": "¿Olvidaste incluir semanas en el período?",
  "inputAnalysis.notices.empty_period.title": "{{The-entity}} está vacío.",
  "inputAnalysis.notices.missing_overlap_species.description": "Las lecciones pertenecientes a cursos paralelos no están garantizadas para programarse al mismo tiempo después de que se haya generado un horario, es decir, a menos que estén vinculadas.",
  "inputAnalysis.notices.missing_overlap_species.title": "{{The-entities}} {{is/are-parallel-courses}}, pero una o más lecciones asociadas no están vinculadas.",
  "inputAnalysis.notices.notification_title": "Los datos del horario pueden estar incompletos.",
  "inputAnalysis.notices.overflowing_period.description": "¿Olvidaste actualizar el período después de cambiar el período del horario?",
  "inputAnalysis.notices.overflowing_period.title": "{{The-entity}} se encuentra parcialmente fuera del período del horario.",
  "inputAnalysis.notices.title": "Observaciones",
  "inputAnalysis.notices.unconnected event.description": "¿Olvidaste agregar un maestro, un estudiante o un grupo?",
  "inputAnalysis.notices.unconnected event.title": "{{The-entity}} no está conectado a un maestro, un estudiante o un grupo.",
  "inputAnalysis.ok.notification_title": "No hay observaciones sobre los datos del horario",
  "schedule.guaranteedTuition.label": "Matrícula garantizada",
  "schedule.guaranteedTuition.tabs.calendarExceptions.descr": "Aquí puede agregar días festivos y días libres. Estos días no se tendrán en cuenta cuando se calcule el tiempo total programado.",
  "view.private.schema.sub-pages.settings.page.tab.holidays.dialog.form.description.label": "Descripción",
  "view.private.schema.sub-pages.settings.page.tab.holidays.dialog.form.range.label": "Rango de fechas",
  "schedule.guaranteedTuition.tabs.calendarExceptions.tabLabel": "Agregar días libres",
  "schedule.guaranteedTuition.tabs.calendarExceptions.title": "Agregar días libres",
  "schedule.guaranteedTuition.tabs.dayCount.descr": "La siguiente tabla muestra la cantidad de veces que aparece cada día en el horario, dada su fecha de inicio y finalización, así como los días libres especificados anteriormente.",
  "schedule.guaranteedTuition.tabs.dayCount.title": "Resumen",
  "schedule.guaranteedTuition.tabs.guaranteedTuition.empty": "No hay lecciones que considerar para la semana seleccionada. Tenga en cuenta que las lecciones deben programarse, no solo planificarse.",
  "schedule.guaranteedTuition.tabs.guaranteedTuition.no_selected_entity": "Seleccione un grupo, maestro o tema para obtener un resumen de su tiempo total programado. Tenga en cuenta que solo se incluyen en los cálculos los cursos que tienen un tema establecido.",
  "schedule.guaranteedTuition.tabs.guaranteedTuition.tabLabel": "Matrícula garantizada",
  "schedule.guaranteedTuition.tabs.guaranteedTuition.table.per_day": "Por día",
  "schedule.guaranteedTuition.tabs.guaranteedTuition.table.total_count": "Número de lecciones",
  "schedule.header": "Horario",
  "schedule.navigation.automate": "Generar<br>horario",
  "schedule.navigation.data": "Datos del<br>horario",
  "schedule.navigation.dutyAssignment": "Asignación de<br>deberes",
  "schedule.navigation.edit": "Editor de<br>horario",
  "schedule.navigation.examine": "Examinar<br>horario",
  "schedule.navigation.guaranteedTuition": "Matrícula<br>garantizada",
  "schedule.navigation.lessonSettings": "Configuración de<br>lección",
  "schedule.navigation.reports": "Informes/<wbr>Estadísticas",
  "schedule.navigation.scheduleDataAnalysis": "Análisis de<br>datos",
  "schedule.navigation.scheduleWarnings": "Advertencias de<br>horario",
  "schedule.navigation.settings": "Configuración de<br>horario",
  "schedule.navigation.substitution_planner": "Planificador de<br>sustituciones",
  "schedule.print.header": "Imprimir",
  "schedule.print.page.toolbar.color.descr": "Imprima sin color para ahorrar tinta de impresora.",
  "schedule.print.page.toolbar.color.label": "Usar colores",
  "schedule.print.page.toolbar.print.descr": "Desde el cuadro de diálogo de impresión también es posible guardar el documento como un archivo PDF.",
  "schedule.print.page.toolbar.print.label": "Imprimir",
  "schedule.print.page.toolbar.QR.descr": "El enlace conduce al visor web.",
  "schedule.print.page.toolbar.QR.label": "Incluir enlace QR",
  "schema-defects.category.critical.label": "Crítico",
  "schema-defects.category.minor.label": "Menor",
  "schema-defects.category.significant.label": "Significativo",
  "schema-defects.courseClustering": "Agrupación de cursos",
  "schema-defects.courseDurationMismatch": "Duración total del evento",
  "schema-defects.dailyOvertime": "Horas extras diarias",
  "schema-defects.doubleBooking": "Doble reserva",
  "schema-defects.eventDurationOverflow": "Duración del evento",
  "schema-defects.fixed.count.explanation": "Hay {{ count }} advertencia(s) de horario de esta categoría que surgen completamente debido a que las lecciones están fijas.",
  "schema-defects.forcedOverlappingEventsDay": "Lecciones superpuestas forzadas",
  "schema-defects.insufficientBookingMargin": "Margen de tiempo",
  "schema-defects.insufficientBreakLength": "Duración de la pausa",
  "schema-defects.missingDependency": "Falta de dependencia",
  "schema-defects.missingGroupsAndTeachers": "Falta de docente y grupo",
  "schema-defects.non-fixed.count.explanation": "Hay {{ count }} advertencias de horario de esta categoría que surgen por razones distintas a que las lecciones involucradas estén fijas.",
  "schema-defects.overflow": "Marco de horario",
  "schema-defects.overlap": "Colisión",
  "schema-defects.overtime": "Horas extras",
  "schema-defects.unavailableDay": "Día prohibido",
  "settings.index.copy": "Clave API copiada",
  "settings.index.error": "No se pudo crear la clave API",
  "settings.integrationsOut.form.title": "Crear una clave API",
  "settings.integrationsOut.menu": "Exportación de API",
  "settings.integrationsOut.page.descr": "Usando la función a continuación, puede crear nuevas claves API para poner los datos del horario a disposición de otros proveedores de servicios. Póngase en contacto con el otro proveedor y compruebe si pueden importar datos de horario a través del estándar de API SS 12000.",
  "settings.integrationsOut.page.title": "Exportación de datos a través de API (SS 12000:2020)",
  "settings.integrationsOut.shared.description": "Descripción",
  "settings.integrationsOut.shared.displayName": "Nombre",
  "settings.integrationsOut.shared.integrationType": "Plataforma",
  "settings.integrationsOut.table.copyKey": "Copiar clave",
  "settings.integrationsOut.table.createdAt": "Creado",
  "settings.integrationsOut.table.legend": "Claves API",
  "settings.integrationsOut.table.usedAt": "Última actividad",
  "shared.components.schema-filter-dropdowns coalesced": "Agregar filtro",
  "shared.components.schema-filter-dropdowns search course event": "Buscar lección",
  "shared.components.user-menu.to-parent-organization": "Volver a {{ name }}",
  "shared.dialogs.add-duration-set.label": "Ingrese la duración de la(s) lección(es)",
  "shared.dialogs.add-duration-set.title": "Nuevo conjunto de duración",
  "shared.dialogs.company.description": "Crea y agrega una escuela a tu organización.",
  "shared.dialogs.company.title": "Crear una escuela",
  "shared.dialogs.confirm-action.title": "¿Estás seguro?",
  "shared.dialogs.confirm-remove.confirm": "Entiendo",
  "shared.dialogs.confirm-remove.sub-title": "Esta acción no se puede revertir",
  "shared.dialogs.confirm-remove.title": "Confirmar eliminación",
  "shared.dialogs.duplicate-schedule.title": "Duplicar horario",
  "shared.dialogs.generate-schedule.action.submit": "Programar",
  "shared.dialogs.generate-schedule.analysis.flawless.description": "Sus datos del horario se han analizado y no hemos encontrado nada que comentar. ¡Bien hecho!",
  "shared.dialogs.generate-schedule.analysis.flawless.title": "Listo",
  "shared.dialogs.generate-schedule.analysis.flaws.description": "Sus datos del horario se han analizado y hemos encontrado los siguientes {{ flaws }} que pueden necesitar resolverse.",
  "shared.dialogs.generate-schedule.description": "Este proceso genera un nuevo horario basado en los datos del horario y el horario actual se sobrescribirá. Si desea guardar el horario tal como está ahora antes de continuar, puede duplicarlo.",
  "shared.dialogs.generate-schedule.error": "No se puede procesar su solicitud, inténtelo de nuevo en unos minutos. Si el problema persiste, póngase en contacto con el equipo de soporte y estaremos encantados de ayudarle.",
  "shared.dialogs.generate-schedule.title": "Programación automática",
  "shared.dialogs.login.description": "Para renovar tu sesión, debes iniciar sesión nuevamente. Si deseas cancelar tu sesión, simplemente cierra este diálogo.",
  "shared.dialogs.login.title": "Sesión expirada",
  "shared.dialogs.overlappableEventSets.description.forced": "Las lecciones vinculadas se programarán al mismo tiempo y centradas.",
  "shared.dialogs.overlappableEventSets.description.set": "El algoritmo de programación automática se esfuerza por programar las lecciones que pertenecen a cursos paralelos al mismo tiempo. Sin embargo, esto no siempre es posible o permitido. Por ejemplo, no está permitido que las lecciones de un curso se programen al mismo tiempo a menos que estén vinculadas.",
  "shared.dialogs.overlappableEventSets.error.set": "Hay lecciones asociadas que no están vinculadas.",
  "shared.dialogs.overlappableEventSets.forceOverlap": "Vincular lecciones",
  "shared.dialogs.overlappableEventSets.gridView": "Vista de cuadrícula",
  "shared.dialogs.overlappableEventSets.listView": "Vista de lista",
  "shared.dialogs.overlappableEventSets.new.forced": "Suelta una lección aquí para formar un nuevo conjunto de lecciones vinculadas",
  "shared.dialogs.overlappableEventSets.new.set": "Suelta un curso aquí para formar un nuevo conjunto de cursos paralelos",
  "shared.dialogs.overlappableEventSets.noCourses": "No se encontraron cursos",
  "shared.dialogs.overlappableEventSets.noEvents": "No se encontraron lecciones",
  "shared.dialogs.overlappableEventSets.warning.forced": "Debe incluir al menos dos lecciones",
  "shared.dialogs.overlappableEventSets.warning.set": "Debe incluir al menos dos cursos",
  "shared.dialogs.sync.collections": "Seleccione los datos del programa",
  "shared.dialogs.sync.error.0": "No se ha podido autenticar con la plataforma seleccionada con sus credenciales. Si se trata de un error, póngase en contacto con el servicio de asistencia.",
  "shared.dialogs.sync.error.1": "No se han podido obtener los datos de la plataforma seleccionada. Puede que algo esté mal configurado",
  "shared.dialogs.sync.error.3": "No se pudo sincronizar los datos del horario. Uno o más estudiantes están conectados a varias clases. Esto no está permitido. Póngase en contacto con el soporte con su código de error para obtener ayuda.",
  "shared.dialogs.sync.error.4": "No se pudo sincronizar los datos del horario. Uno o más ids no son únicos. Póngase en contacto con el soporte con su código de error para obtener ayuda.",
  "shared.dialogs.sync.last_sync": "Última sincronización",
  "shared.dialogs.sync.modified": "Sólo modificado después de",
  "shared.dialogs.sync.platform": "Seleccione la plataforma",
  "shared.dialogs.sync.synced_data": "Datos de programación modificados",
  "shared.dialogs.sync.time": "Seleccione el período",
  "shared.dialogs.sync.title": "Sincronice su agenda",
  "shared.dialogs.sync.validation": "Validar datos del horario",
  "shared.dialogs.sync.validation.title": 'Hay algunos problemas con los datos del horario. Por favor, resuélvalos antes de continuar.',
  "shared.dialogs.sync.validation.indistinguishable.courses": "con nombre \"{{name}}\" comparten el mismo nombre, docentes y grupos.",
  "shared.dialogs.sync.validation.indistinguishable.events": "pertenecientes al curso \"{{name}}\" comparten los mismos docentes y grupos y tiempo.",
  "shared.dialogs.sync.validation.ids_missing": "faltan nombre",
  "shared.dialogs.sync.validation.ids_not_unique": "tienen nombre idénticos: \"{{ids}}\"",
  "shared.dialogs.sync.validation.valid": "Los datos son válidos",
  "shared.dialogs.sync.push_success.title": "Sincronización exitosa",
  "shared.dialogs.sync.push_success.description": "Los datos del horario se han sincronizado correctamente con la plataforma seleccionada. Vaya a la plataforma para ver los cambios.",
  "shared.dialogs.unrecoverable-app-state.description": "La aplicación ha entrado en un estado irrecuperable. Por favor, recarga la página para continuar.",
  "shared.dialogs.unrecoverable-app-state.title": "Estado irrecuperable",
  "shared.dialogs.unsaved-changes.description": "Hay cambios no guardados presentes. ¿Quieres guardarlos o descartarlos?",
  "shared.dialogs.unsaved-changes.title": "Cambios no guardados",
  "shared.dialogs.upload-file.configure.format_specific": "Configuración específica del formato",
  "shared.dialogs.upload-file.configure.label": "Configurar",
  "shared.dialogs.upload-file.configure.PlanDigital.displayName.label": "Usar como nombre del curso",
  "shared.dialogs.upload-file.configure.PlanDigital.periods.label": "Seleccionar períodos para importar",
  "shared.dialogs.upload-file.configure.PlanDigital.periods.placeholder": "Debe seleccionarse al menos un período",
  "shared.dialogs.upload-file.configure.SchoolSoft.interval.descr": "Excluir lecciones que no están programadas durante el período seleccionado ({{start}} - {{end}}).",
  "shared.dialogs.upload-file.configure.SchoolSoft.interval.label": "Filtrar cursos en función del período seleccionado",
  "shared.dialogs.upload-file.configure.Skola24.deduceGroupsFromCourseName.descr": "Intentar identificar y agregar grupos que se encuentran en el nombre del curso si están separados por un guión, como 10FYS01a-<b>NA20E</b>-LABb o 10-AH-<b>EK21C</b>.",
  "shared.dialogs.upload-file.configure.Skola24.deduceGroupsFromCourseName.label": "Deducir grupos del nombre del curso",
  "shared.dialogs.upload-file.configure.Skola24.ignoreEmptyCourses.label": "Ignorar cursos sin lecciones",
  "shared.dialogs.upload-file.configure.Skola24.removeUnreferencedGroups.descr": "Los grupos que no se encuentran en al menos un curso o lección se ignorarán.",
  "shared.dialogs.upload-file.configure.Skola24.removeUnreferencedGroups.label": "Ignorar grupos no utilizados",
  "shared.dialogs.upload-file.configure.Skola24.removeUnreferencedTeachers.descr": "Los docentes que no se encuentran en al menos un curso o lección se ignorarán.",
  "shared.dialogs.upload-file.configure.Skola24.removeUnreferencedTeachers.label": "Ignorar docentes no utilizados",
  "shared.dialogs.upload-file.configure.Skola24.week.ignoreAllEvents": "Ignorar todas las lecciones",
  "shared.dialogs.upload-file.configure.Skola24.week.label": "Importar lecciones de la semana seleccionada",
  "shared.dialogs.upload-file.finalize.content": "Contenido del horario",
  "shared.dialogs.upload-file.finalize.error": "No se puede procesar el archivo seleccionado",
  "shared.dialogs.upload-file.finalize.errors_and_warnings": "Se encontraron errores y advertencias",
  "shared.dialogs.upload-file.finalize.label": "Finalizar",
  "shared.dialogs.upload-file.label": "Subir archivo",
  "shared.dialogs.upload-file.select-file.download_template": "Descargar plantilla de Excel (opcional)",
  "shared.dialogs.upload-file.select-file.label": "Seleccionar archivo",
  "shared.dialogs.upload-file.select-map.determined": "Coincidencia exacta",
  "shared.dialogs.upload-file.select-map.error": "No se puede identificar el formato del archivo",
  "shared.dialogs.upload-file.select-map.label": "Seleccionar formato",
  "shared.dialogs.upload-file.select-map.strong": "Formato(s) probable(s)",
  "shared.dialogs.upload-file.select-map.unknown": "Formato desconocido",
  "shared.dialogs.upload-file.select-map.weak": "Formato(s) similar(es)",
  "shared.dialogs.users.description": "Escribe una dirección de correo electrónico y selecciona una escuela para crear un inicio de sesión en la escuela seleccionada.",
  "shared.dialogs.users.title": "Crear usuario",
  "shared.dialogs.version.current_version": "Versión actual",
  "shared.dialogs.version.description": "Hay una nueva versión de la aplicación disponible. Por favor, recarga la página para obtener la última versión.",
  "shared.dialogs.version.description_hard_reload": "A veces una recarga regular no es suficiente y se requiere una recarga manual. Normalmente esto se hace pulsando <kbd>Ctrl</kbd> + <kbd>Shift</kbd> + <kbd>R</kbd> en Windows o <kbd>Cmd</kbd> + <kbd>Shift</kbd> + <kbd>R</kbd> en Mac, pero probablemente depende de tu navegador.",
  "shared.dialogs.version.latest_version": "Última versión",
  "shared.dialogs.version.title": "Nueva versión disponible",
  "shared.form-fields.available-locations.empty-repository": "No hay salas disponibles",
  "shared.form-fields.available-locations.groups": "Grupo de salas",
  "shared.form-fields.available-locations.locations": "Salas",
  "shared.form-fields.available-locations.select-null": "No hay sala",
  "shared.form-fields.available-locations.selected-null": "No hay sala",
  "shared.form-fields.components.schema-filter-dropdowns search course event": "Buscar lección",
  "shared.form-fields.dynamic-locked-times.duration.tooltip": "La duración preferida y la variación máxima de la duración de la pausa para el almuerzo. El algoritmo de programación seleccionará una duración adecuada dentro del rango especificado, pero mantendrá la duración combinada de todas las pausas para el almuerzo igual a su duración preferida sumada.",
  "shared.form-fields.fixed-start.disabled": "Ninguno",
  "shared.form-fields.fixed-start.enabled": "Hora de inicio fija",
  "shared.form-fields.groups.selection-warning": "uno o más estudiantes de este grupo están seleccionados por separado",
  "shared.form-fields.periods.no-periods": "No hay periodos para seleccionar. Por favor, añade periodos al horario.",
  "shared.form-fields.root-intervals.no-root-intervals": "No hay bloques para seleccionar. Por favor, añade bloques al horario.",
  "shared.forms.change-password-form.current_password": "Contraseña actual",
  "shared.forms.change-password-form.new_password": "Nueva contraseña",
  "shared.forms.change-password-form.repeat_password": "Repetir contraseña",
  "shared.forms.company.name": "Nombre",
  "shared.forms.company.organization": "Organización principal",
  "shared.forms.company.organizationType": "Tipo de organización",
  "shared.forms.company.schoolCode": "Código",
  "shared.forms.company.theme": "Tema",
  "shared.forms.users.company": "Seleccionar escuela",
  "shared.forms.users.username": "Email",
  "source.set_fail": "No se pudo actualizar el valor",
  "source.set_success": "Actualización correcta",
  "source.set_timeout": "Tiempo de actualización agotado",
  "source.validation_fail": "Actualización no válida",
  "substitutions.are_parallel_courses": "son cursos paralelos",
  "substitutions.is_a_parallel_course": "es un curso paralelo",
  "table.itemsPerPageLabel": "Filas por página",
  "table.nextPageLabel": "Página siguiente",
  "table.previousPageLabel": "Página anterior",
  "tables.admin-tables.divisions.actions.columns.displayName": "Nombre",
  "tables.admin-tables.divisions.actions.columns.id": "ID",
  "tables.admin-tables.divisions.actions.columns.organization": "Organización",
  "tables.admin-tables.divisions.actions.delete": "Eliminar horario",
  "tables.admin-tables.divisions.actions.delete.failure": "Error al eliminar horario",
  "tables.admin-tables.divisions.actions.download": "Descargar horario",
  "tables.admin-tables.divisions.actions.login": "Iniciar sesión en el horario",
  "tables.admin-tables.divisions.actions.login.failure": "Error al iniciar sesión",
  "tables.admin-tables.organizations.actions.add": "Crear organización",
  "tables.admin-tables.organizations.actions.configure_environment": "Configurar entorno de cálculo",
  "tables.admin-tables.organizations.actions.configure_identifiers": "Configurar identificadores",
  "tables.admin-tables.organizations.actions.delete": "Eliminar organización",
  "tables.admin-tables.organizations.actions.delete.failure": "Error al eliminar organización",
  "tables.admin-tables.organizations.actions.edit": "Editar organización",
  "tables.admin-tables.organizations.actions.generate_access_key": "Generar clave de acceso",
  "tables.admin-tables.organizations.actions.login": "Iniciar sesión en la organización",
  "tables.admin-tables.organizations.actions.login.failure": "Error al iniciar sesión",
  "tables.admin-tables.organizations.add-edit.actions.add.failure": "Error al crear organización",
  "tables.admin-tables.organizations.add-edit.labels.name": "Nombre",
  "tables.admin-tables.organizations.add-edit.labels.organization": "Organización principal",
  "tables.admin-tables.organizations.add-edit.labels.organization_type": "Tipo de organización",
  "tables.admin-tables.organizations.add-edit.labels.school_code": "Código de unidad escolar",
  "tables.admin-tables.organizations.add-edit.labels.theme": "Tema",
  "tables.admin-tables.organizations.add-edit.title.add": "Crear organización",
  "tables.admin-tables.organizations.add-edit.title.edit": "Editar organización",
  "tables.admin-tables.organizations.columns.divisions": "No. horarios",
  "tables.admin-tables.organizations.columns.environment": "Entorno",
  "tables.admin-tables.organizations.columns.identifiers": "No. identificadores",
  "tables.admin-tables.organizations.columns.name": "Nombre",
  "tables.admin-tables.organizations.columns.organization": "Organización principal",
  "tables.admin-tables.organizations.columns.organizationType": "Tipo de organización",
  "tables.admin-tables.organizations.columns.theme": "Tema",
  "tables.admin-tables.organizations.columns.version": "Versión del algoritmo",
  "tables.admin-tables.organizations.configure-compute-environment.title": "Configurar entorno de cálculo",
  "tables.admin-tables.organizations.configure-identifiers.actions.add.failure": "Error al agregar identificador",
  "tables.admin-tables.organizations.configure-identifiers.actions.delete.failure": "Error al eliminar identificador",
  "tables.admin-tables.organizations.configure-identifiers.identifier-types.school_code": "Código de unidad escolar",
  "tables.admin-tables.organizations.configure-identifiers.identifier-types.vat": "Número de IVA",
  "tables.admin-tables.organizations.configure-identifiers.title": "Configurar identificadores",
  "tables.admin-tables.organizations.app_features.substitution_planner": "Activar planificador de sustituciones",
  "tables.admin-tables.organizations.app_features.title": "Funciones de la aplicación",
  "tables.admin-tables.users.actions.add": "Crear usuario",
  "tables.admin-tables.users.actions.copy_verification_link": "Copiar enlace de activación",
  "tables.admin-tables.users.actions.delete": "Eliminar usuario",
  "tables.admin-tables.users.actions.delete.failure": "Error al eliminar usuario",
  "tables.admin-tables.users.actions.edit": "Editar usuario",
  "tables.admin-tables.users.actions.login": "Iniciar sesión en la organización del usuario",
  "tables.admin-tables.users.actions.login.failure": "Error al iniciar sesión",
  "tables.admin-tables.users.actions.mail": "Enviar correo electrónico de activación al usuario",
  "tables.admin-tables.users.actions.mail.failure": "Error al enviar correo electrónico de activación",
  "tables.admin-tables.users.actions.mail.success": "Correo electrónico de activación enviado",
  "tables.admin-tables.users.add-edit.actions.add.failure": "Error al crear usuario",
  "tables.admin-tables.users.add-edit.actions.fetch_companies.failure": "Error al obtener empresas",
  "tables.admin-tables.users.add-edit.actions.fetch_from_skolverket.failure": "Error al obtener información de Skolverket",
  "tables.admin-tables.users.add-edit.labels.organization": "Organización",
  "tables.admin-tables.users.add-edit.labels.role": "Rol de usuario",
  "tables.admin-tables.users.add-edit.labels.username": "Nombre de usuario (correo electrónico)",
  "tables.admin-tables.users.add-edit.title.add": "Crear usuario",
  "tables.admin-tables.users.add-edit.title.edit": "Editar usuario",
  "tables.admin-tables.users.columns.activation": "Enlace de activación",
  "tables.admin-tables.users.columns.last_login": "Último inicio de sesión",
  "tables.admin-tables.users.columns.online": "En línea",
  "tables.admin-tables.users.columns.organization": "Organización",
  "tables.admin-tables.users.columns.role": "Rol de usuario",
  "tables.admin-tables.users.columns.username": "Nombre de usuario",
  "tables.components.custom-search.empty": "Desactivar la búsqueda en todas las columnas dará como resultado una búsqueda vacía.",
  "tables.components.empty.hint": "Crea uno haciendo click en el botón \"+\" en la esquina superior derecha",
  "tables.components.empty.title": "No existen {{ entities }} todavía",
  "tables.components.no-columns.hint": "Haz click en el botón \"{{ button }}\" en la esquina superior derecha para seleccionar qué columnas mostrar",
  "tables.components.no-columns.title": "No hay columnas visibles",
  "tables.courses.values.overridden": "El valor no es heredado por todas las lecciones del curso",
  "tables.notifications.alerts.error.title": "No se pudo actualizar las preferencias",
  "tables.notifications.alerts.success.descr": "Si hay otras pestañas abiertas, necesitan ser recargadas para que los cambios tengan efecto.",
  "tables.notifications.alerts.success.title": "Preferencias actualizadas correctamente",
  "tables.notifications.columns.category.descr": "Las notificaciones de cada categoría se muestran juntas.",
  "tables.notifications.columns.category.name": "Categoría",
  "tables.notifications.columns.category.values.generalConfirmation": "Confirmaciones generales",
  "tables.notifications.columns.category.values.generalError": "Errores generales",
  "tables.notifications.columns.category.values.inputAnalysisError": "Analizador de entradas: errores críticos",
  "tables.notifications.columns.category.values.inputAnalysisNotice": "Analizador de entradas: avisos",
  "tables.notifications.columns.category.values.inputAnalysisWarning": "Analizador de entradas: advertencias",
  "tables.notifications.columns.category.values.other": "Otro",
  "tables.notifications.columns.location.descr": "Dónde en la pantalla aparecerán las notificaciones de esta categoría.",
  "tables.notifications.columns.location.name": "Ubicación",
  "tables.notifications.columns.location.values.bottom left": "Esquina inferior izquierda",
  "tables.notifications.columns.location.values.bottom right": "Esquina inferior derecha",
  "tables.notifications.columns.location.values.top left": "Esquina superior izquierda",
  "tables.notifications.columns.location.values.top right": "Esquina superior derecha",
  "tables.notifications.columns.mode.descr": "Si estas notificaciones están activadas, silenciadas o desactivadas.",
  "tables.notifications.columns.mode.name": "Modo",
  "tables.notifications.columns.mode.values.mute": "Silenciar",
  "tables.notifications.columns.mode.values.off": "Desactivar",
  "tables.notifications.columns.mode.values.on": "Activar",
  "tables.notifications.columns.sound.descr": "Qué sonido se reproducirá cuando se reciba una notificación.",
  "tables.notifications.columns.sound.name": "Sonido",
  "tables.notifications.columns.test.descr": "Probar enviando una notificación de prueba.",
  "tables.notifications.columns.test.name": "Probar",
  "tables.notifications.columns.test.value": "enviar",
  "tables.notifications.columns.volume.descr": "El volumen del sonido de la notificación.",
  "tables.notifications.columns.volume.name": "Volumen",
  "validators.email": "Email no válido",
  "validators.equal": "Los valores no coinciden",
  "validators.invalidDate": "Fecha no válida",
  "validators.invalidDiscretization": "Debe ser un múltiplo de 5 minutos",
  "validators.invalidLaterDate": "Debe ser una fecha posterior",
  "validators.invalidMultiple": "Debe ser un múltiplo de 5",
  "validators.invalidTime": "Hora no válida (intervalos de 5 min)",
  "validators.matDatepickerMax": "Debe seleccionar una fecha anterior",
  "validators.matDatepickerMin": "Debe seleccionar una fecha posterior",
  "validators.matEndDateInvalid": "Rango de fechas no válido",
  "validators.matStartDateInvalid": "Rango de fechas no válido",
  "validators.max": "Este valor es demasiado alto",
  "validators.min": "Este valor es demasiado bajo",
  "validators.minLength": "Demasiado corto",
  "validators.minlength": "Demasiado corto",
  "validators.noMatch": "Email o contraseña incorrectos",
  "validators.outOfBounds": "El valor está fuera de los límites",
  "validators.required": "Este campo es obligatorio",
  "validators.time_too_early": "Hora demasiado temprana",
  "validators.time_too_late": "Hora demasiado tarde",
  "validators.usernameOccupied": "El email está ocupado",
  "view.administrator.organizations.table.add": "Crear escuela",
  "view.administrator.organizations.table.createdAt": "Creado el",
  "view.administrator.organizations.table.header": "Escuelas",
  "view.administrator.organizations.table.login": "Entrar en esta escuela",
  "view.administrator.organizations.table.name": "Nombre",
  "view.administrator.page.menu.organizations": "Mis escuelas",
  "view.administrator.users.table.add": "Crear usuario",
  "view.administrator.users.table.administrator": "Administrador",
  "view.administrator.users.table.company": "Escuela/Organización",
  "view.administrator.users.table.createdAt": "Creado el",
  "view.administrator.users.table.header": "Cuentas de usuario",
  "view.administrator.users.table.login": "Entrar en esta escuela",
  "view.administrator.users.table.mail.fail": "No se pudo enviar el enlace de activación al usuario",
  "view.administrator.users.table.mail.success": "Enlace de activación enviado al usuario",
  "view.administrator.users.table.mail.tooltip": "Enviar enlace de activación al usuario",
  "view.administrator.users.table.restricted": "Restringido",
  "view.administrator.users.table.role": "Rol de usuario",
  "view.administrator.users.table.unrestricted": "Planificador",
  "view.administrator.users.table.username": "Email",
  "view.demo.schedule.components.editor_contact_sub_title": "Para obtener más información sobre nuestro producto, rellene su email y nos pondremos en contacto con usted.",
  "view.demo.schedule.components.editor_contact_title": "El horario está terminado",
  "view.demo.schedule.components.introduction_contact_submit": "Estoy interesado",
  "view.demo.schedule.components.introduction_slide_1": "¡Bienvenido!",
  "view.demo.schedule.components.introduction_slide_2": "Hemos pre-rellenado los datos del horario para una escuela sueca de tamaño medio.",
  "view.demo.schedule.components.introduction_slide_3": "Lo único que queda es hacer clic en \"iniciar programación automática\", esperar un par de minutos y habrá creado su horario.",
  "view.demo.schedule.page.exit": "Salir de la demo",
  "view.demo.schedule.page.restart": "Reiniciar demo",
  "view.partner.page.menu.divisions": "Horarios",
  "view.partner.page.menu.organizations": "Organiza&shy;ciones",
  "view.partner.page.menu.users": "Usuarios",
  "view.private.integrations.in.connectionTo": "Conexión a",
  "view.private.integrations.in.descr": "A continuación encontrará posibles integraciones de API para intercambiar información con proveedores de servicios externos.",
  "view.private.integrations.in.error.get": "No se pudieron obtener los datos del horario. Póngase en contacto con el equipo de soporte si el problema persiste.",
  "view.private.integrations.in.error.postOrPatch": "No se pudieron guardar los detalles de la integración. Póngase en contacto con el equipo de soporte si el problema persiste.",
  "view.private.integrations.in.import": "Importar datos",
  "view.private.integrations.in.menu": "Integraciones",
  "view.private.integrations.in.no_integrations": "No hay integraciones",
  "view.private.integrations.in.SchoolSoftDialog.tab1.title": "Nombre y periodo",
  "view.private.integrations.in.SchoolSoftDialog.tab2.descr": "Actualmente no es posible importar aulas directamente a través de la API de SchoolSoft. Sin embargo, hasta que se resuelva este problema, se pueden importar copiando y pegando los datos correspondientes en un formato separado por tabuladores o comas, que puede encontrar <a href=\"https://sms.schoolsoft.se/{{webapp}}/jsp/admin/right_admin_import_room.jsp?menu=admin_import_room\" target=\"_blank\">aquí</a>.",
  "view.private.integrations.in.SchoolSoftDialog.tab2.error": "No se puede analizar el texto",
  "view.private.integrations.in.SchoolSoftDialog.tab2.label": "Pegue aquí el texto de la tabla",
  "view.private.integrations.in.SchoolSoftDialog.tab2.title": "Importar aulas",
  "view.private.integrations.in.setupDialog.key": "Clave API",
  "view.private.integrations.in.setupDialog.meta.schoolapp": "schoolapp",
  "view.private.integrations.in.setupDialog.meta.schoolId": "ID de la escuela",
  "view.private.integrations.in.setupDialog.meta.webapp": "webapp",
  "view.private.integrations.in.setupDialog.startsWith": "Empieza con {{value}}",
  "view.private.integrations.in.setupDialog.title": "Detalles de la integración",
  "view.private.integrations.in.success": "El horario se ha importado correctamente",
  "view.private.integrations.in.title": "Integraciones a través de API",
  "view.private.integrations.in.tooltip.delete": "Eliminar detalles de la integración",
  "view.private.integrations.in.tooltip.modify": "Modificar detalles de la integración",
  "view.private.integrations.in.validation.error.401": "Detalles de la integración no válidos",
  "view.private.integrations.in.validation.error.404": "Detalles de la integración no válidos",
  "view.private.integrations.in.validation.error.500": "No se pudieron validar los detalles de la integración",
  "view.private.integrations.in.validation.error.SchoolSoft.key": "Clave API no válida",
  "view.private.integrations.in.validation.error.SchoolSoft.schoolapp": "Valor de schoolapp no válido",
  "view.private.integrations.in.validation.error.SchoolSoft.webapp": "Valor de webapp no válido",
  "view.private.integrations.in.validation.error.unknown": "No se pudieron validar los detalles de la integración",
  "view.private.integrations.in.validation.valid": "Detalles de la integración válidos",
  "view.private.integrations.in.validation.validating": "Validando detalles de la integración",
  "view.private.menu.schema": "Horarios",
  "view.private.menu.settings": "Configuración",
  "view.private.menu.tutorial": "Tutorial",
  "view.private.menu.users": "Usuarios",
  "view.private.schema.components.admentum.subtitle": "Exportar a Admentum",
  "view.private.schema.components.failed-generation-dialog.description": "Se produjo un error durante el proceso de programación automática y estamos trabajando en una solución. Por favor, inténtelo de nuevo más tarde durante el día o póngase en contacto con el soporte si desea ser notificado cuando el problema se resuelva.",
  "view.private.schema.components.failed-generation-dialog.title": "La programación automática falló",
  "view.private.schema.components.subtitle": "Exportar a",
  "view.private.schema.components.idunsoft.subtitle": "Exportar a IdunSoft",
  "view.private.schema.components.infomentor.subtitle": "Exportar a InfoMentor",
  "view.private.schema.components.link.subtitle": "Copiar el enlace a este horario",
  "view.private.schema.components.link.success": "Enlace copiado",
  "view.private.schema.components.link.title": "Visor web",
  "view.private.schema.components.royalschedule.subtitle": "Exportar a Royal Schedule",
  "view.private.schema.components.schoolsoft_displayName.subtitle": "Exportar a SchoolSoft usando nombres como referencias",
  "view.private.schema.components.schoolsoft.subtitle": "Exportar a SchoolSoft",
  "view.private.schema.components.table.actions": "Acciones",
  "view.private.schema.components.table.basis": "Datos del horario",
  "view.private.schema.components.table.clone": "Duplicar horario",
  "view.private.schema.components.table.createdAt": "Creado",
  "view.private.schema.components.table.editedAt": "Última edición",
  "view.private.schema.components.table.header": "Mis horarios",
  "view.private.schema.components.table.interval": "Duración",
  "view.private.schema.components.table.link": "enlace al visor web",
  "view.private.schema.components.table.name": "Nombre del horario",
  "view.private.schema.components.table.public": "Público",
  "view.private.schema.edit.toolbar.colorDescription": "Descripción del color",
  "view.private.schema.edit.toolbar.selectedPeriods": "Períodos incluidos",
  "view.private.schema.editor.components.user-manual.title": "Manual de usuario",
  "view.private.schema.page.create": "Crear horario",
  "view.private.schema.page.download_fail": "No se ha podido descargar el horario",
  "view.private.schema.page.header": "Horarios",
  "view.private.schema.page.sync": "Sincronizar datos",
  "view.private.schema.page.upload": "Subir archivo",
  "view.private.schema.sub-pages.schema-editor.components.event-form.fix-start": "Fijar el día y la hora de inicio",
  "view.private.schema.sub-pages.schema-editor.components.event-form.force-overlap": "Vincular lecciones",
  "view.private.schema.sub-pages.schema-editor.components.event-form.make-invisible": "Ocultar en el visor de horarios",
  "view.private.schema.sub-pages.schema-editor.components.event-form.make-visible": "Mostrar en el visor de horarios",
  "view.private.schema.sub-pages.schema-editor.components.event-form.park": "Aparcar",
  "view.private.schema.sub-pages.schema-editor.components.event-form.remove-force-overlap": "Desvincular lecciones",
  "view.private.schema.sub-pages.schema-editor.components.event-form.unfix-start": "Desfijar el día y la hora de inicio",
  "view.private.schema.sub-pages.schema-editor.components.list.filter": "Filtrar lecciones",
  "view.private.schema.sub-pages.schema-editor.components.list.filter.not_placed": "Lecciones aparcadas",
  "view.private.schema.sub-pages.schema-editor.components.list.filter.period": "Solo períodos incluidos",
  "view.private.schema.sub-pages.schema-editor.components.list.filter.placed": "Lecciones colocadas",
  "view.private.schema.sub-pages.schema-editor.components.progress.info_PENDING": "El proceso de programación se ha colocado en cola. Comenzará en un par de minutos",
  "view.private.schema.sub-pages.schema-editor.components.progress.info_STARTED": "El proceso de programación ha comenzado. Puede tardar un par de minutos antes de que se complete.",
  "view.private.schema.sub-pages.schema-editor.page.limited_state": "El análisis de colisiones está desactivado",
  "view.private.schema.sub-pages.schema-editor.page.start_job": "Iniciar programación automática",
  "view.private.schema.sub-pages.settings.components.default-values-form.fieldset.break-lengths": "Longitud mínima de descanso",
  "view.private.schema.sub-pages.settings.components.default-values-form.fieldset.other": "Otro",
  "view.private.schema.sub-pages.settings.components.default-values-form.fieldset.time-frame": "Marco de tiempo",
  "view.private.schema.sub-pages.settings.components.default-values-form.fieldset.working-hours": "Horas de trabajo",
  "view.private.schema.sub-pages.settings.components.options-form.fieldset.overlappable-lessons-set": "Lecciones vinculadas",
  "view.private.schema.sub-pages.settings.components.options-form.requireForcedOverlappingEventsSets.description": "Si está habilitado, las lecciones pertenecientes a cursos paralelos deben estar vinculadas. Las lecciones que están vinculadas están garantizadas para programarse al mismo tiempo después de que se haya generado un horario. Las lecciones pertenecientes a cursos paralelos que <b>no</b> están vinculadas generalmente se pueden programar al mismo tiempo, pero este resultado no está garantizado después de que se haya generado un horario.",
  "view.private.schema.sub-pages.settings.components.options-form.requireForcedOverlappingEventsSets.label": "Requerir lecciones vinculadas",
  "view.private.schema.sub-pages.settings.page.tab.default-values": "Valores predeterminados",
  "view.private.schema.sub-pages.settings.page.tab.options": "Opciones",
  "view.private.schema.sub-pages.settings.page.tab.periods": "Períodos",
  "view.private.schema.sub-pages.settings.page.tab.rootIntervals": "Bloques",
  "view.private.schema.sub-pages.statistics.duty-assignments.empty": "No hay datos disponibles",
  "view.private.schema.sub-pages.statistics.duty-assignments.header": "Asignaciones de deberes",
  "view.private.schema.sub-pages.statistics.duty-assignments.min_of_min_per_week": "{{current}} de {{total}}  min/week",
  "view.private.schema.sub-pages.statistics.duty-assignments.min_per_week": "Min/week",
  "view.private.schema.sub-pages.tooltips.locked": "Mientras se genera un horario automáticamente, está bloqueado para su modificación.",
  "view.private.settings.language.label": "Idioma",
  "view.private.settings.language.title": "Idioma",
  "view.private.settings.menu.sync": "Sincronizar",
  "view.private.settings.sync.button.select-schedule": "Seleccionar horario",
  "view.private.settings.sync.components.table.sync_toggle": "Sincronizar",
  "view.private.settings.sync.descr": "Usando las funciones a continuación, puede habilitar y deshabilitar las plataformas externas para sincronizar sus datos. Si la sincronización está habilitada, sus datos se enviarán a la(s) plataforma(s) que haya permitido. Esto se puede apagar en cualquier momento.",
  "view.private.settings.sync.in": "Horarios que obtienen actualizaciones de plataformas externas",
  "view.private.settings.sync.no-selected-out": "Seleccione un horario para que sus plataformas externas accedan.",
  "view.private.settings.sync.out": "Horarios activos",
  "view.private.settings.sync.tab.add-platforms": "Agregar una plataforma",
  "view.private.settings.sync.tab.my-platforms": "Mis plataformas",
  "view.private.settings.sync.title": "Integraciones de plataforma",
  "view.private.settings.sync.tooltip.REJECTED": "La solicitud para crear una sincronización entre nuestras plataformas fue rechazada. Póngase en contacto con el equipo de soporte si se trata de un error.",
  "view.private.tutorial.page.advanced": "Avanzado",
  "view.private.tutorial.page.basic": "Básico",
  "view.private.tutorial.page.description.2.1": "Restricciones del docente",
  "view.private.tutorial.page.description.2.2": "Crear lecciones y variación de longitud",
  "view.private.tutorial.page.description.2.3": "Agregar aulas permitidas",
  "view.private.tutorial.page.description.2.4": "Cursos paralelos y lecciones vinculadas",
  "view.private.tutorial.page.description.2.5": "Análisis de datos de horario",
  "view.private.tutorial.page.description.2.6": "Ajustes iterativos",
  "view.private.tutorial.page.description.api": "Sincronizar datos",
  "view.private.tutorial.page.description.bulk-update": "Aprenda a editar en masa",
  "view.private.tutorial.page.description.courses": "Aprenda a agregar cursos y eventos",
  "view.private.tutorial.page.description.create": "Crear horario",
  "view.private.tutorial.page.description.excel": "Subir un archivo de Excel",
  "view.private.tutorial.page.description.groups": "Aprenda a agregar grupos",
  "view.private.tutorial.page.description.in-locations": "Aprenda a modificar las aulas permitidas",
  "view.private.tutorial.page.description.locations": "Aprenda a agregar salas",
  "view.private.tutorial.page.description.locked-times": "Aprenda a agregar intervalos de tiempo prohibidos",
  "view.private.tutorial.page.description.teacher": "Aprenda a agregar docentes",
  "view.private.tutorial.page.description.time-frame": "Administrar el marco de tiempo de los horarios",
  "view.private.tutorial.page.description.upload_json": "Subir archivo RS",
  "view.private.tutorial.page.filter.category": "Categoría",
  "view.private.tutorial.page.filter.category.adjustments": "Ajustes",
  "view.private.tutorial.page.filter.category.conditions": "Condiciones",
  "view.private.tutorial.page.filter.category.data": "Datos de horario",
  "view.private.tutorial.page.filter.category.edit": "Programación manual",
  "view.private.tutorial.page.filter.category.education": "Educación",
  "view.private.tutorial.page.filter.category.export": "Exportar datos de horario",
  "view.private.tutorial.page.filter.category.import": "Importar datos de horario",
  "view.private.tutorial.page.filter.category.inputAnalyzer": "Analizador de entrada",
  "view.private.tutorial.page.filter.category.other": "Otros",
  "view.private.tutorial.page.filter.category.settings": "Ajustes",
  "view.private.tutorial.page.filter.category.warnings": "Advertencias",
  "view.private.tutorial.page.filter.level": "Dificultad",
  "view.private.tutorial.page.filter.level.advanced": "Avanzado",
  "view.private.tutorial.page.filter.level.beginner": "Principiante",
  "view.private.tutorial.page.filter.level.intermediate": "Intermedio",
  "view.private.tutorial.page.header": "Tutorial",
  "view.private.tutorial.page.type.video": "Guía de video",
  "view.public.activation.approved": "Aprobado",
  "view.public.activation.expired": "Enlace caducado",
  "view.public.activation.invalid": "Enlace inválido",
  "view.public.activation.verifying": "Verificando...",
  "view.public.demo.valid_config": "Configurando el entorno",
  "view.public.login.fail": "Error al iniciar sesión",
  "view.public.login.password": "Contraseña",
  "view.public.login.status_email_required": "Se requiere un correo electrónico válido",
  "view.public.login.status_invalid_credentials": "Credenciales inválidas",
  "view.public.login.status_invalid_email_format": "Formato de correo electrónico inválido",
  "view.public.login.status_password_required": "Se requiere contraseña",
  "view.public.login.status_reset_password_fail": "No se puede enviar el enlace de restablecimiento",
  "view.public.login.status_reset_password_success": "Enlace de restablecimiento enviado a {{email}} si existe dicho usuario",
  "view.public.login.username": "Correo electrónico",
  "view.public.web-viewer.not_public": "El horario no es público",
  "view.void.status": "Validando credenciales",
  "account.advanced.menu": "Avanzado",
  "account.advanced.page.title": "Avanzado",
  "account.advanced.page.descr": "Configuración y acciones más avanzadas que pueden ser útiles.",
  "account.advanced.page.clear-all-and-reload.label": "Borrar todos los datos y recargar",
  "account.advanced.page.clear-all-and-reload.description": "Esto borrará todos los datos de la aplicación, terminará todos los procesos y finalmente recargará todas las pestañas abiertas de la aplicación simultáneamente. Esto puede ser útil si está experimentando problemas con la aplicación. Tenga en cuenta que esto terminará su sesión de autenticación y tendrá que iniciar sesión de nuevo.",
  "account.advanced.page.clear-all-and-reload.action": "Borrar y recargar",
  "view.private.schema.sub-pages.examine.page.before_select": "Seleccione los grupos, docentes y salas que desea comparar. Puede elegir qué día y posiblemente qué semana mostrar.",
  "common.show_remaining": "Mostrar restante",
  "common.from_time": "Desde",
  "common.to_time": "Hasta",
  "shared.dialogs.edit-course-and-event.title": "Editar",
  "common.parallel_courses": "Cursos paralelos",
  "common.open_tutorial": "Abrir tutorial",
  "common.second": "Segundo",
  "common.second_short": "s",
  "shared.components.svg-schedule.generating.title": "Generando horarios imprimibles",
  "shared.components.svg-schedule.generating.completed_n_of_m": "{{n}} de {{m}} completado",
  "shared.components.svg-schedule.generating.estimated_time_remaining": "Tiempo estimado restante: {{time}}",
  "schedule.print.page.toolbar.print_date.label": "Fecha de impresión",
  "schedule.print.page.toolbar.lockedTimes.label": "Horas prohibidas",
  "schedule.print.page.toolbar.print_date.descr": "La fecha y hora actuales.",
  "schedule.print.page.toolbar.lockedTimes.descr": "Si incluir o no las horas prohibidas.",
  "schedule.print.page.page.before_select": "Seleccione los grupos, docentes y salas cuyo horario desea imprimir.",
  "schedule.print.page.toolbar.time_frame.label": "Marco de tiempo",
  "schedule.print.page.toolbar.time_frame.descr": "Dibuja el marco de tiempo y los días permitidos.",
  "schedule.print.page.toolbar.fit_text.label": "Ajustar texto",
  "schedule.print.page.toolbar.fit_text.descr": "Intenta ajustar el texto reduciendo el tamaño de la fuente.",
  "inputAnalysis.issues.overloaded group aborted.title": "No se pudo decidir si {{the-entity}} está sobrecargado de lecciones.",
  "inputAnalysis.issues.overloaded group aborted.description": "El cálculo se interrumpió porque tardó demasiado en completarse.",
  "common.date_range": "Rango de fechas",
  "inputAnalysis.notices.overflowing_holiday.title": "{{The-entity}} se encuentra parcialmente fuera del período del horario.",
  "inputAnalysis.notices.overflowing_holiday.description": "¿Olvidó actualizar el día festivo después de cambiar el período del horario?",
  "common.the_holiday": "el día festivo",
  "common.the_holidays": "los días festivos",
  "common.holiday": "día festivo",
  "common.holidays": "días festivos",
  "view.private.schema.sub-pages.settings.page.tab.holidays": "Días festivos",
  "view.private.schema.sub-pages.settings.page.tab.holidays.dialog.add.title": "Añadir día festivo",
  "view.private.schema.sub-pages.settings.page.tab.holidays.dialog.edit.title": "Editar día festivo",
  "inputAnalysis.etc.modify_holiday": "Modificar día festivo",
  "common.printed": "Impreso",
  "schedule.print.page.toolbar.display_dates.label": "Mostrar fechas",
  "schedule.print.page.toolbar.display_dates.descr": "Debe seleccionarse una semana específica.",
  "common.delete_all": "Eliminar todo",
  "schedule.navigation.daysCount": "Conteo de días",
  "common.whole": "Todo",
  "view.private.schema.sub-pages.statistics.days-count.caption": "La tabla muestra cuántas veces un día está presente en cada período y también su número total de días. El período \"{{ whole }}\" se refiere al período determinado por la fecha de inicio y finalización del horario.",
  "dialogs.create-courses.using-excel.label": "Excel",
  "dialogs.create-courses.components.excel.instructions": "Primero descargue la plantilla de Excel que hemos preparado con los datos de su horario existente (excepto los cursos). Luego, en su pestaña de cursos, ingrese los cursos que desea crear. Cuando esté satisfecho, guarde el archivo y cárguelo nuevamente aquí para obtener una descripción general de los cursos que se crearán, así como una lista de errores encontrados.",
  "shared.services.excel-template.errors.invalid_file_type": "Tipo de archivo no válido. Solo admitimos archivos de tipos {{ types }}.",
  "shared.services.excel-template.errors.unable_to_parse": "No se puede analizar el archivo. Póngase en contacto con el equipo de soporte.",
  "shared.services.excel-template.errors.incorrect_mapping": "Tipo de asignación no válido. Póngase en contacto con el equipo de soporte.",
  "shared.services.excel-template.errors.unable_to_map": "Error al asignar datos de horario. Póngase en contacto con el equipo de soporte.",
  "maps.excel.messages.missing_settings_sheet": "No se pudo encontrar la hoja de configuración en el archivo de Excel.",
  "maps.excel.messages.missing_locations_sheet": "No se pudo encontrar la hoja de salas en el archivo de Excel.",
  "maps.excel.messages.missing_groups_sheet": "No se pudo encontrar la hoja de grupos en el archivo de Excel.",
  "maps.excel.messages.missing_teachers_sheet": "No se pudo encontrar la hoja de docentes en el archivo de Excel.",
  "maps.excel.messages.missing_persons_sheet": "No se pudo encontrar la hoja de estudiantes en el archivo de Excel.",
  "maps.excel.messages.missing_courses_sheet": "No se pudo encontrar la hoja de cursos en el archivo de Excel.",
  "maps.excel.messages.ignoring_duplicate_location": "Se encontraron dos o más salas con el nombre \"{{ context }}\". Ignorando todas menos la primera.",
  "maps.excel.messages.ignoring_duplicate_group": "Se encontraron dos o más grupos con el nombre \"{{ context }}\". Ignorando todas menos la primera.",
  "maps.excel.messages.ignoring_duplicate_teacher": "Se encontraron dos o más docentes con el nombre \"{{ context }}\". Ignorando todas menos la primera.",
  "maps.excel.messages.ignoring_duplicate_person": "Se encontraron dos o más estudiantes con el nombre \"{{ context }}\". Ignorando todas menos la primera.",
  "maps.excel.messages.ignoring_duplicate_course": "Se encontraron dos o más cursos con el nombre \"{{ context }}\". Ignorando todas menos la primera.",
  "maps.excel.messages.invalid_group_reference": "No se pudo encontrar el grupo con el nombre \"{{ context }}\".",
  "maps.excel.messages.invalid_location_reference": "No se pudo encontrar la sala con el nombre \"{{ context }}\".",
  "maps.excel.messages.invalid_teacher_reference": "No se pudo encontrar el docente con el nombre \"{{ context }}\".",
  "maps.excel.messages.location_referenced_multiple_times": "La sala \"{{ context }}\" se referencia varias veces desde el mismo curso",
  "maps.excel.messages.teacher_referenced_multiple_times": "El docente \"{{ context }}\" se referencia varias veces desde el mismo curso",
  "maps.excel.messages.group_referenced_multiple_times": "El grupo \"{{ context }}\" se referencia varias veces desde el mismo curso",
  "maps.excel.messages.invalid_number_of_days": "Valor no válido \"{{ context }}\" especificado para el número de días del horario. El valor debe ser 5 (lunes-viernes) o 7 (lunes-domingo).",
  "maps.excel.messages.invalid_sex": "Valor no válido \"{{ context }}\" especificado como género. El valor debe ser \"Man\" o \"Woman\".",
  "maps.excel.messages.invalid_total_hours": "Valor no válido \"{{ context }}\" especificado como el número total de horas. El valor debe ser un número entero positivo.",
  "maps.excel.messages.invalid_minutes_per_week": "Valor no válido \"{{ context }}\" especificado como el número de minutos por semana. El valor debe ser un múltiplo positivo de 5.",
  "maps.excel.messages.invalid_lesson_durations": "Valor no válido \"{{ context }}\" especificado como duración de la lección. El valor debe ser uno o más números separados por comas, donde cada número es un múltiplo positivo de 5.",
  "shared.services.excel-template.template.readonly": "Solo lectura",
  "attributes.shared.ids": "ID externo",
  "attributeDescriptions.shared.ids": "Un ID externo único, útil para fines de exportación",
  "attributeDescriptions.shared.createdAt": "La fecha y hora en que se creó la entidad",
  "attributeDescriptions.teachers.signature": "Firma del docente",
  "account.workspace.page.displayPublicId.descr": "El ID externo de una entidad (curso, lección, docente, etc.) es importante cuando se exportan datos a otra plataforma. Si los datos del horario se han importado en Royal Schedule, el valor del ID externo no debe modificarse en general, ya que esto puede dar lugar a conflictos de datos cuando se exporta el horario. Para usuarios inexpertos, por lo tanto, recomendamos ocultar el ID externo.",
  "account.workspace.page.displayPublicId.label": "Mostrar ID externo",
  "maps.excel.messages.forbidden_group_reference": "El grupo \"{{ context }}\" es un grupo de enseñanza pero se está utilizando como clase.",
  "dialogs.create-courses.components.excel.warnings.no_courses": "No se encontraron cursos en el archivo de Excel.",
  "schedule.exceptions.new.title": "Nueva excepción",
  "schedule.exceptions.new.type-select": "Tipo",
  "schedule.exceptions.new.date-select": "Fecha",
  "schedule.exceptions.new.absentee-select": "Ausente",
  "view.private.schema.components.excel.subtitle": "Exportar datos de horario en formato Excel",
  "view.private.schema.components.additio.subtitle": "Exportar a Additio",
  "common.groups.species.none": "Grupos de enseñanza",
  "common.groups.species.class": "Clases",
  "common.the_groups.species.none": "Los grupos de enseñanza",
  "common.the_groups.species.class": "Las clases",
  "dialogs.create-courses.in-bulk.no_options": "No hay grupos y docentes para elegir. Primero cree grupos y docentes antes de crear cursos en masa.",
  "view.private.schema.sub-pages.schema-editor.components.event-form.show_schedule": "Mostrar horario",
  "common.minutes-per-week_short": "min/semana",
  "common.minutes-per-week": "Minutos/semana",
  "attributes.course.plannedDuration": "Duración planificada",
  "attributeDescriptions.course.plannedDuration": "La duración planificada programada del curso en minutos por semana o en horas totales. El valor no especificado se calcula automáticamente y se redondea hacia arriba.",
  "inputAnalysis.issues.plannedDuration.title": "{{The-entity}} no satisface su duración planificada.",
  "inputAnalysis.issues.plannedDuration.description": "La duración planificada es de <b>{{planned-value}}</b> minutos/semana, pero la duración preferida de los eventos solo suma <b>{{actual-value}}</b> minutos/semana.",
  "schedule.guaranteedTuition.tabs.guaranteedTuition.table.planned_duration": "Duración planificada",
  "schedule.guaranteedTuition.tabs.guaranteedTuition.table.planned_duration_not_met": "La duración planificada no se cumple para uno o más cursos representados por esta fila.",
  "schedule.guaranteedTuition.tabs.guaranteedTuition.table.actual_duration": "Duración real",
  "tables.courses.values.plannedDuration.warning": "La duración planificada no se cumple.",
  "maps.excel.messages.ambiguous_planned_duration": "No puede especificar la duración planificada en minutos/semana y horas al mismo tiempo. Elija uno de estos: {{ context }}.",
  /* TO BE DEPRECATED */ "attributes.course.minutesPerWeek": "<s>Minutos/semana</s>",
  /* TO BE DEPRECATED */ "attributes.course.expectedTotalHours": "<s>Tiempo total programado esperado</s>",
  /* TO BE DEPRECATED */ "attributeDescriptions.course.expectedTotalHours": "Reemplazado por 'Duración planificada'.",
  /* TO BE DEPRECATED */ "attributeDescriptions.course.minutesPerWeek": "Reemplazado por 'Duración planificada'.",
  "attributes.shared.tags": "Etiquetas",
  "attributeDescriptions.shared.tags": "Las etiquetas se utilizan para categorizar y filtrar.",
  "shared.form-fields.tags.input.placeholder": "Nueva etiqueta...",
  "validators.invalid": "Valor no válido",
  "maps.excel.messages.duplicate_group_member": "El estudiante \"{{ context }}\" está listado más de una vez en el grupo.",
  "maps.excel.messages.invalid_person_reference": "No se pudo encontrar al estudiante con el número de seguridad social \"{{ context }}\".",
  "maps.excel.messages.person_in_multiple_classes": "El estudiante \"{{ context }}\" se encuentra en varias clases.",
  "shared.dialogs.user-inactive.title": "Inactividad detectada",
  "shared.dialogs.user-inactive.description": "Parece que has estado inactivo por un tiempo. Para mantener todo funcionando sin problemas, haz clic en el botón \"Recargar\". Esto actualizará todas tus pestañas y asegurará que todo esté actualizado.",
  "shared.views.schedule-viewer.before_select": "Seleccione a quién desea ver y para qué semana.",
  "shared.dialogs.overlappableEventSets.auto-link.description": "Vincular automáticamente",
  "shared.dialogs.overlappableEventSets.unable_to_auto-link.description": "No se pueden vincular automáticamente las lecciones. Para que esto funcione, los cursos paralelos deben contener el mismo número de lecciones de la misma duración y las lecciones no deben estar completamente vinculadas.",
  "shared.dialogs.overlappableEventSets.auto-link_N": "Vincular {{ N }}",
  "shared.dialogs.overlappableEventSets.auto-link_N.description": "Vincula automáticamente las lecciones para {{ N }} conjuntos de cursos paralelos. Para que esto funcione, los cursos paralelos deben contener el mismo número de lecciones de la misma duración y las lecciones no deben estar completamente vinculadas.",
  "shared.dialogs.overlappableEventSets.unable_to_auto-link_N.description": "No hay conjuntos de cursos paralelos que cumplan con los requisitos para que sus lecciones se vinculen automáticamente. Para que esto funcione, los cursos paralelos deben contener el mismo número de lecciones de la misma duración y las lecciones no deben estar completamente vinculadas.",
  "tables.courses.action.connect-courses": "Conectar",
  "tables.courses.action.disconnect-courses": "Desconectar",
  "maps.excel.messages.invalid_course_reference": "No se pudo encontrar el curso con el ID \"{{ context }}\".",
};

// PLEASE LEAVE
// translate the value of the commented out key-value pairs to the language of this file and based on its context
