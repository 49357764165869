import { Component,
         Renderer2,
         AfterViewInit,
         ApplicationRef,
         NgZone,
         OnDestroy                      } from '@angular/core';
import { Router,
         NavigationStart                } from '@angular/router';
import { MatIconRegistry                } from '@angular/material/icon';
import { DomSanitizer                   } from '@angular/platform-browser';
import { Platform                       } from '@angular/cdk/platform';
import { combineLatest,
         timer                          } from 'rxjs';
import { filter,
         distinctUntilChanged,
         debounceTime,
         take,
         map,
         pairwise,
         startWith                      } from 'rxjs/operators';
import $                                  from 'jquery';
import axios                              from 'axios';
import { productFruits                  } from 'product-fruits';

import { environment                    } from 'environments/environment';
import { PushNotificationService,
         UserPreferencesService,
         StorageService,
         VersionService,
         KeyboardShortcutsService,
         BroadcastService,
         UserInactivityService          } from 'app/core';
import { AuthService,
         EnvironmentService,
         ThreadService                  } from 'app/core';
import { DialogsService                 } from 'app/shared/services';
import { BROADCAST_CONSTANTS            } from 'app/constants';
import { MatDialog                      } from './common';
import { UserInactiveComponent          } from './shared/dialogs/user-inactive/user-inactive.component';

// import all extensions
import 'app/../extensions';


type _Window = Window & {
  productFruits?: {
    services?: {
      destroy: () => void;
    }
  }
  AfterViewInit?: boolean;
};


type Browser = 'is-chrome' | 'is-firefox' | 'is-safari' | 'is-edge' | 'is-ie' | 'is-other';


function setDisplayNoneImportant (elem: JQuery<HTMLElement>) {
  // cannot set priority "!important" from .css
  elem.attr('style', (i, s) => (s || '') + '; display: none !important;');
}


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit, OnDestroy {

  constructor(
    private readonly _router:          Router,
    private readonly _dialog:          DialogsService,
    private readonly _auth:            AuthService,
    private readonly _environment:     EnvironmentService,
    private readonly _thread:          ThreadService,
    private readonly _notifications:   PushNotificationService,
    private readonly _userPreferences: UserPreferencesService,
    private readonly _shortcuts:       KeyboardShortcutsService,
    private readonly _storage:         StorageService,
    private readonly _platform:        Platform,
    private readonly _renderer:        Renderer2,
    private readonly _matDialog:       MatDialog,
    private readonly _iconRegistry:    MatIconRegistry,
    private readonly _sanitizer:       DomSanitizer,
    private readonly _version:         VersionService,
    private readonly _broadcast:       BroadcastService,
    private readonly _userInactivity:  UserInactivityService,
            appRef:           ApplicationRef,
            ngZone:           NgZone
  ) {
    // listen for keyboard shortcuts without triggering change detection
    ngZone.runOutsideAngular(() => {
      window.addEventListener('keydown', (event) => {
        if ((event.metaKey || event.ctrlKey) && event.key === 'd') {
          // if the user presses cmd/ctrl + d, open the debug dialog inside the zone for styles to be properly applied
          ngZone.run(() => {
            this._dialog.openDebugDialog();
            event.preventDefault();
          });
        }
      });
    });

    // listen for the tab close event
    window.addEventListener('beforeunload', () => {
      this.ngOnDestroy();
    });

    ////
    //// announces the stable state of the application
    ////
    if (false) {
      appRef.isStable.subscribe((isStable) => {
        console.log('is stable', isStable);
      });
    }

    // subscribe to clear all and reload event
    this._broadcast.subscribeToChannel(BROADCAST_CONSTANTS.CLEAR_ALL_AND_RELOAD, false)
    .subscribe(() => void (async () => {
        // clear local storage and session storage
        localStorage.clear();
        sessionStorage.clear();

        // deprecate all cookies
        document.cookie.split(";").forEach(function(c) {
          document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
        });

        // clear all indexedDB databases
        (await indexedDB.databases()).forEach(db => {
          if ( ! db.name) return;
          // (perhaps listen to the result before proceeding?)
          indexedDB.deleteDatabase(db.name);
        });

        // unregister all service workers
        (await navigator.serviceWorker.getRegistrations()).forEach(x => void x.unregister());

        // finally we reload
        window.location.reload();
    })());


    /*
      If frontend is not isolated, load hubspot chat
      disable it also if Cypress is running
    */
    if ( ! environment.isolated && ! ('Cypress' in window)) {
      // load hubspot chat
      const script = this._renderer.createElement('script') as HTMLScriptElement;
      script.type = 'text/javascript';
      script.id = 'hs-script-loader';
      script.src = '//js-na1.hs-scripts.com/8832548.js';
      this._renderer.appendChild(document.body, script);
      // load youtube api
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      document.body.appendChild(tag);

      let isInitiated = false;
      // initialize product fruits
      combineLatest([
        this._auth.onIsAuthenticated
        .pipe(
          distinctUntilChanged(),
          debounceTime(0),
        ),
        this._userPreferences.watch('language')
        .pipe(
          startWith(null),
          distinctUntilChanged(),
          pairwise(),
          debounceTime(0),
        )
      ])
      .pipe(debounceTime(1000))
      .subscribe(([isAuthenticated, language]) => {
        // have user logged out destroy
        if (isInitiated && (isAuthenticated === false)) {
          productFruits.safeExec(() => {
            isInitiated = false;
            try {
              (window as _Window).productFruits?.services?.destroy();
            } catch (e) {
              console.log(e);
            }
          });
          return;
        }

        // have language changed destroy
        if (isInitiated && (language[0] !== language[1])) {
          productFruits.safeExec(() => {
            isInitiated = false;
            try {
              (window as _Window).productFruits?.services?.destroy();
            } catch (e) {
              console.log(e);
            }
          });
        }

        const username = this._auth.getUsername();

        if (! username) return;

        setTimeout(() => {
          isInitiated = true;
          productFruits.init(
            environment.development ? 'pXtjN0trZX34j2Np' : 'PpivQfJtXHKYQmNA',
            language[1] ?? 'en',
            {
              username,
              signUpAt: this._auth.getCreatedAt(),
            }
          );
        });
      });
    }

    ////
    //// add icons
    ////
    void axios.get<string>('assets/icons/push_pin_not.svg').then(res => _iconRegistry.addSvgIconLiteral('push_pin_not', _sanitizer.bypassSecurityTrustHtml(res.data)) );
    void axios.get<string>('assets/icons/tab_close.svg'   ).then(res => _iconRegistry.addSvgIconLiteral('tab_close',    _sanitizer.bypassSecurityTrustHtml(res.data)) );

    ////
    //// announce browser to CSS
    ////
    let browser: Browser;
    if      (this._platform.BLINK)   browser = 'is-chrome';
    else if (this._platform.FIREFOX) browser = 'is-firefox';
    else if (this._platform.SAFARI)  browser = 'is-safari';
    else if (this._platform.EDGE)    browser = 'is-edge';
    else if (this._platform.TRIDENT) browser = 'is-ie';
    else                             browser = 'is-other';
    $('body').addClass(browser);

    ////
    //// toggle hubspot chat
    ////
    combineLatest({
      nav:         this._router.events.pipe(filter((x): x is NavigationStart => x instanceof NavigationStart)),
      displayChat: this._userPreferences.watch('displayChat'),
      userRole:    this._auth.onActive.pipe(map(x => x?.role), distinctUntilChanged()),
      timer:       ngZone.runOutsideAngular(() => timer(0, 1000)).pipe(take(2))   // fire off immediately and then after 1 second to ensure that the chat has been loaded
    })
    .subscribe(({ nav, displayChat, userRole }) => {
      const elem = $('#hubspot-messages-iframe-container');

      // always hide if admin
      if (userRole === 'admin') {
        setDisplayNoneImportant(elem);
        return;
      }

      // always hide if schedule viewer when not authenticated
      //
      //                                          matches "did"                case insensitive
      //                                         ╭─────────────────╮          ╭─╮
      const scheduleViewerURLRegExp = /\/schema\/([0-9a-z\-_]){7,14}($|\?(.)+)/i;
      //                                                            ╰────────╯
      //                                                             matches end of line
      //                                                             OR
      //                                                             query
      const isScheduleViewer = scheduleViewerURLRegExp.test(nav.url)
      if (isScheduleViewer && ! this._auth.isAuthenticated) {
        setDisplayNoneImportant(elem);
        return;
      }

      // toggle on/off the chat
      if (displayChat) {
        elem.css('display', '');
      } else {
        setDisplayNoneImportant(elem);
      }
    })


    ////
    //// initiate notifications service
    ////
    this._auth.onIsAuthenticated
    .subscribe(isAuthenticated => {
      if (isAuthenticated) {
        // logged in
        this._notifications.activate();
      } else {
        // logged out
        this._matDialog.openDialogs.forEach(dialog => dialog.close());
        this._notifications.deactivate();
      }
    });

    ////
    //// listen to global inactivity to open dialog that forces a reload
    ////
    this._userInactivity.globalAuthenticatedInactivity$
    .pipe(filter(Boolean))
    .subscribe(() => this._matDialog.open(UserInactiveComponent, { maxWidth: '600px' }));

  }

  ngAfterViewInit() {
    // expose to cypress
    if ('Cypress' in window) (window as _Window).AfterViewInit = true;
  }

  ngOnDestroy() {
    this._thread.destroy();
  }
}
