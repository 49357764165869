import _                                  from 'lodash';
import { errorCodes                     } from "@royalschedule/input-verifier";

import { Util                           } from '@app/common';
import { InputAnalysisService           } from './input-analysis.service';
import { AnalyzedOut, Code, Remark      } from './types';


const noncriticalErrorCodes: Code[] = [
  `${errorCodes.event}.10.0`,                   // no allowed day for event
  `${errorCodes.event}.10.1`,                   // no allowed interval time for event
  `${errorCodes.forcedOverlappingEventsSet}.0`, // no allowed day for linked events
  `${errorCodes.forcedOverlappingEventsSet}.1`, // no allowed interval for linked events
];

export function processAnalyzed (
  this:              InputAnalysisService,
  { version, data }: AnalyzedOut
): Remark[] {
  if (data?.intervalAnalysis             ) (this.intervalAnalysis              as any) = data.intervalAnalysis;
  if (data?.eventMassDistributionAnalysis) (this.eventMassDistributionAnalysis as any) = data.eventMassDistributionAnalysis;


  const remarks = new Array<Remark>();

  eventLoop:
  for (let elem of this.intervalAnalysis ?? []) {
    let _event = elem.event;

    // the warnings should not be already given as errors
    // for (let err of this.errors) {
    //   // the only possible errors are event related
    //   if (err.belongsTo?.type == 'event' || err.belongsTo?.type  == 'lockedTime') {
    //     const _id = InputAnalysisService.prependCollection(err.belongsTo?.val!)
    //     if (_event.id == _id) continue eventLoop;
    //   }
    // }

    // no available time
    if ( ! elem.numFinalStartTimes) {
      // fetch event
      const event = this.findEvent(_event.id);
      if ( ! event) continue;

      // skip events with fixed start as their intervals are not as important
      if (event.is == 'event' && event.fixedStart) continue;

      // ignore the issue if a similar error is already present
      const isRedundant = this.errors.some(x =>
           x.entities.some(e => e.is == event.is && e.id == event.id)   // the error is related to the event
        && x.version == version                                         // the error is related to the current version
        && noncriticalErrorCodes.includes(x.code)                       // the error has one of the codes we are looking for
      );
      if (isRedundant) continue;

      const code: Code = `${errorCodes.event}.10.1`;
      remarks.push(this.createRemark('issue', code, version, event, 0, {}, 'error'));
    }
  }



  groupLoop:
  for (let elem of this.eventMassDistributionAnalysis ?? []) {

    // fetch group, teacher ot person
    const group = this.findGroup(elem.group.id);
    if ( ! group) continue;

    // // should not be present in the error list
    // for (let err of this.errors) {
    //   if (err.belongsTo?.type == group?.is && err.belongsTo?.val?.id == group.id) {
    //     continue groupLoop;
    //   }
    // }

    if ('aborted' in elem) {
      remarks.push(this.createRemark('issue', 'overloaded group aborted', version, group, 0));
      continue;
    }

    // has infeasibilities
    if ( ! elem.feasible) {
      remarks.push(this.createRemark('issue', 'overloaded group', version, group, 0));
    }
  }

  return remarks;
}