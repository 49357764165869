import { firstValueFrom                  } from 'rxjs';
import { ThreadService                   } from './thread.service';

export function _listen(this: ThreadService): void {
  this._broadcast
  .subscribeToChannel('thread_sync')
  .subscribe(async ({ remove, sync }) => {
    if (this._threadId.value == undefined)
      await firstValueFrom(this._threadId);

    if (sync != undefined) {
      this._logger.debug(`(Thread::Service) Thread with id: [${ sync }] requested sync, my id: [${ this._threadId.value }]`);

      if (sync > this._threadId.value!) {
        this._logger.debug(`(Thread::Service) This thread is older than requesting thread`);
        this._broadcast.emit(`thread_sync_${ sync }`, { sync: this._threadId.value });
        if (! this.isMaster)
          this.checkIfMaster();
      }
    }

    if (remove != undefined) {
      this._logger.debug(`(Thread::Service) Thread closed: [${ remove }]`);
      this._database.delete(this._collection, remove);
    }
  });
}