<div *ngIf="formGroup" [formGroup]="formGroup" class="flex-align-center">

  <mat-form-field appearance="outline" floatLabel="always" subscriptSizing="dynamic"
    *ngIf="displayName"
    class="displayName"
  >
    <mat-label [innerHTML]="'attributes.shared.displayName' | translate | capitalizeFirst"></mat-label>
    <input matInput formControlName="displayName" autocomplete="off" (paste)="paste('displayName', $event)">
  </mat-form-field>

  <mat-form-field appearance="outline" floatLabel="always" subscriptSizing="dynamic"
    *ngIf="displaySubject"
    class="subject"
  >
    <mat-label [innerHTML]="'attributes.shared.subject' | translate | capitalizeFirst"></mat-label>
    <input matInput formControlName="subject" autocomplete="off" (paste)="paste('subject', $event)">
  </mat-form-field>

  <mat-form-field appearance="outline" floatLabel="always" subscriptSizing="dynamic"
    *ngIf="(columns.watch | async)?.plannedDuration?.enabled"
    class="plannedDuration"
  >
    <mat-label [innerHTML]="'attributes.course.plannedDuration' | translate | capitalizeFirst"></mat-label>
    <app-form-field-planned-duration
      formControlName="plannedDuration"
      nullifyEmpty
      saveOnClose
      hideToolbar
    >
    </app-form-field-planned-duration>
  </mat-form-field>

  <mat-form-field appearance="outline" floatLabel="always" subscriptSizing="dynamic"
    class="events"
  >
    <mat-label [innerHTML]="'common.event_durations' | translate | capitalizeFirst"></mat-label>
    <mat-chip-grid #chipList formControlName="events">
      <mat-chip-row *ngFor="let x of formGroup.get('events')?.value; let i = index" (removed)="removeEvent(i)">
        {{ x }}
      </mat-chip-row>
      <input
        #entityInput
        [formControl]="entityCtrl"
        [cleave]="cleave_number"
        [placeholder]="'common.minutes' | translate | lowercase"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        matChipInputAddOnBlur
        (matChipInputTokenEnd)="addEvent($event)"
        (paste)="paste('events', $event) || pasteDurations($event)"
        autocomplete="off"
      >
    </mat-chip-grid>
  </mat-form-field>

  <mat-form-field appearance="outline" floatLabel="always" subscriptSizing="dynamic"
    class="ids"
    *ngIf="displayIds && (columns.watch | async)?.ids?.enabled"
  >
    <mat-label [innerHTML]="'attributes.shared.ids' | translate | capitalizeFirst"></mat-label>
    <input matInput formControlName="ids" autocomplete="off" (paste)="paste('ids', $event)">
  </mat-form-field>

  <button mat-icon-button (click)="removed.emit()" cypress="delete">
    <mat-icon>delete</mat-icon>
  </button>

</div>