import { Injectable                      } from '@angular/core';
import { Title                           } from '@angular/platform-browser';
import $                                   from 'jquery';
import _                                   from 'lodash';

import { Util                            } from 'app/common';
import { Company                         } from '@app/shared/interfaces';

import { Clone                           } from 'app/shared/decorators/clone/clone.decorator';
import { SystemStates,
         IntegrationType,
         Integration,
         Integrations                    } from './environment.constants';
import { Theme,
         OrganizationType                } from './environment.types';
import { BehaviorSubject,
         Observable,
         distinctUntilChanged            } from 'rxjs';


const mutable = Util.functions.mutable;

const defaultOrganizationType: OrganizationType = 'school';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {
  ////
  //// organization type (is fed from the auth service)
  ////
  public set organizationType (value: OrganizationType | undefined | null) {
    if (value) this._organizationType.next(value);
    else       this._organizationType.next(defaultOrganizationType);
  }
  public get organizationType   (): OrganizationType             { return this._organizationType.value; }
  public get onOrganizationType (): Observable<OrganizationType> { return this._organizationType }
  private readonly _organizationType = new BehaviorSubject<OrganizationType>(defaultOrganizationType);

  ////
  //// theme (is fed from the auth service)
  ////
  public set theme (value: Theme | undefined | null) {
    if (value) this._theme.next(value);
    else       this._theme.next(this.getDefaultTheme());
  }
  public get theme   (): Theme             { return this._theme.value; }
  public get onTheme (): Observable<Theme> { return this._theme }
  private readonly _theme = new BehaviorSubject<Theme>(this.getDefaultTheme());

  ////
  //// app features (is fed from the auth service)
  ////
  public set appFeatures (value: Company.complete['environment']['appFeatures']) {
    this._appFeatures.next(value);
  }
  public get appFeatures   (): Company.complete['environment']['appFeatures']             { return this._appFeatures.value; }
  public get onAppFeatures (): Observable<Company.complete['environment']['appFeatures']> { return this._appFeatures }
  private readonly _appFeatures = new BehaviorSubject<Company.complete['environment']['appFeatures']>({});


  public readonly themeClass:    string;
  public readonly logo:          string;
  public readonly symbol:        string;
  public readonly title:         string;
  public readonly isWhiteLabel:  boolean;
  public readonly whiteLabel:    Theme | undefined;
  public readonly whiteLabelItn: IntegrationType | undefined;

  constructor (
    private   _title:   Title
  ) {

    // implement theme
    this.onTheme
    .pipe(distinctUntilChanged())
    .subscribe(theme => this.setTheme(theme));
  }

  private getDefaultTheme (): Theme {
    switch (location.hostname) {
      // case 'localhost':
      case 'dev-app.aischema.se':
      case 'stage-app.aischema.se':
      case 'app.aischema.se':
      case 'aischema.se':
        return 'schoolsoft';
      case 'sdui.royalschedule.com':
      case 'planner.sdui.app':
        return 'sdui';
      case 'dev-additio.royalschedule.com':
      case 'additio.royalschedule.com':
        return 'additio';
      default:
        return 'royal_schedule';
    }
  }

  private setTheme(theme: Theme): void {

    let faviconDir: string;
    switch (theme) {
      case 'schoolsoft':
        mutable(this).isWhiteLabel  = true;
        mutable(this).whiteLabel    = 'schoolsoft';
        mutable(this).whiteLabelItn = IntegrationType.SCHOOLSOFT;
        mutable(this).logo          = 'assets/whitelabel/aischema/logo-white.svg';
        mutable(this).symbol        = 'assets/whitelabel/aischema/logo-white.svg';
        mutable(this).themeClass    = 'scs-theme';
        mutable(this).title         = 'AI schema | Royal schedule';
        faviconDir = 'aischema/';
        break;
      case 'sdui':
        mutable(this).isWhiteLabel  = true;
        mutable(this).whiteLabel    = 'sdui';
        mutable(this).whiteLabelItn = IntegrationType.SDUI;
        mutable(this).logo          = 'assets/whitelabel/sdui/logo-white.svg';
        mutable(this).symbol        = 'assets/whitelabel/sdui/symbol.svg';
        mutable(this).themeClass    = 'sdui-theme';
        mutable(this).title         = 'Sdui | Royal schedule';
        faviconDir = 'sdui/';
        break;
      case 'additio':
        mutable(this).isWhiteLabel  = true;
        mutable(this).whiteLabel    = 'additio';
        mutable(this).whiteLabelItn = IntegrationType.ADDITIO;
        mutable(this).logo          = 'assets/whitelabel/additio/logo-white.svg';
        mutable(this).symbol        = 'assets/whitelabel/additio/logo-white.svg';
        mutable(this).themeClass    = 'additio-theme';
        mutable(this).title         = 'Additio | Royal schedule';
        faviconDir = 'additio_app/';
        break;
      case 'pupil':
        mutable(this).isWhiteLabel  = true;
        mutable(this).whiteLabel    = 'additio';
        mutable(this).whiteLabelItn = IntegrationType.PUPIL;
        mutable(this).logo          = 'assets/whitelabel/pupil/logo-white.svg';
        mutable(this).symbol        = 'assets/whitelabel/pupil/logo-white.svg';
        mutable(this).themeClass    = 'pupil-theme';
        mutable(this).title         = 'Pupil | Royal schedule';
        faviconDir = 'pupil/';
        break;
      case 'konecto':
        mutable(this).isWhiteLabel  = true;
        mutable(this).whiteLabel    = 'konecto';
        mutable(this).whiteLabelItn = IntegrationType.KONECTO;
        mutable(this).logo          = 'assets/whitelabel/konecto/logo-white.svg';
        mutable(this).symbol        = 'assets/whitelabel/konecto/logo-white.svg';
        mutable(this).themeClass    = 'konecto-theme';
        mutable(this).title         = 'Konecto | Royal schedule';
        faviconDir = 'sdui/';
        break;
      default:
        mutable(this).isWhiteLabel  = false;
        mutable(this).whiteLabel    = undefined;
        mutable(this).whiteLabelItn = undefined;
        mutable(this).logo          = 'assets/rs-logo-white.svg';
        mutable(this).symbol        = 'assets/rs-symbol-white.svg';
        mutable(this).themeClass    = 'default-theme';
        mutable(this).title         = 'Royal schedule';
        faviconDir = '';
        break;
    }

    $('body').removeClass('sdui-theme scs-theme additio-theme default-theme').addClass(this.themeClass);

    this._title.setTitle(this.title);

    const favIcon = document.querySelectorAll('#favIcon')!;
    favIcon.forEach((elem: HTMLLinkElement) => {
      const file: string = elem.href.split('/').pop()!;
      elem.href = `assets/favicon/${ faviconDir }${ file }`;
    });
  }

  @Clone
  public getIntegrations(): Integration[] {
   if (this.organizationType == 'sports_facility')
      return [];

    switch (this.theme) {
      case 'schoolsoft':
        return [
          Integrations[IntegrationType.SCHOOLSOFT]
        ];
      case 'sdui':
        return [
          Integrations[IntegrationType.SDUI]
        ];
      case 'additio':
        return [
          Integrations[IntegrationType.ADDITIO]
        ];
      case 'pupil':
        return [
          Integrations[IntegrationType.PUPIL]
        ];
      case 'konecto':
        return [
          Integrations[IntegrationType.KONECTO]
        ];
      default:
        return [
          Integrations[IntegrationType.ADMENTUM],
          Integrations[IntegrationType.GOTIT],
          //Integrations[IntegrationType.INFOMENTOR],
          Integrations[IntegrationType.SCHOOLSOFT],
          Integrations[IntegrationType.VKLASS],
          Integrations[IntegrationType.IST],
          Integrations[IntegrationType.EDLEVO],
          // Integrations[IntegrationType.MEITNER]
        ];
    }
  }

  public isIntegration(val: string): val is keyof Integration {
    return _.get(Integrations, val) != undefined;
  }

  @Clone
  public getIntegration(integration: keyof Integration | IntegrationType): Integration | undefined {
    return _.get(Integrations, integration);
  }

  public getState() {
    return SystemStates[this.organizationType];
  }

  public hasCollection(collection: Util.Types.Collection): boolean {
    return !! SystemStates[this.organizationType].collections[collection];
  }

  public hasColumn(sourceCollection: Util.Types.Collection, key: string, targetCollection?: Util.Types.Collection): boolean {
    if (                    ! this.hasCollection(sourceCollection)) return false;
    if (targetCollection && ! this.hasCollection(targetCollection)) return false;

    const type = this.organizationType;
    const properties = SystemStates[type].collections[sourceCollection]?.properties ?? { };
    return ! properties?.omit?.[key];
  }

  get collections(): Util.Types.Collection[] {
    return _.keys(SystemStates[this.organizationType].collections) as Util.Types.Collection[];
  }
}
