
import { Component,
         Inject                                } from '@angular/core';
import { FormControl                           } from '@angular/forms';
import { takeUntilDestroyed                    } from '@angular/core/rxjs-interop';
import { BehaviorSubject                       } from 'rxjs';

import { MatCheckboxChange,
         Util                                  } from '@app/common';
import { Collection                            } from '../../types';
import { COLLECTION                            } from '../../constants';
import { CustomSearchService,
         FormValue,
         FromControlLabels                     } from '../../services/custom-search/custom-search.service';


type Key<C extends Collection>  = keyof FormValue[C];
type Keys<C extends Collection> = Key<C>[];

@Component({
  templateUrl: './custom-search.component.html',
  styleUrls: ['./custom-search.component.scss'],
})
export class CustomSearchComponent<C extends Collection> {
  protected readonly names: Record<Key<C>, string> = FromControlLabels[this.collection] as any;

  protected readonly options = new BehaviorSubject<Keys<C>>([]);
  protected readonly ctrl    = new FormControl<Keys<C>>([], { nonNullable: true });
  protected readonly onAll   = new BehaviorSubject<boolean | null>(null);
  protected readonly onNone  = new BehaviorSubject<boolean | null>(null);

  constructor (
    @Inject(COLLECTION) private collection: C,
    private _custom: CustomSearchService<C>
  ) {
    this._custom.onValue()
    .pipe(takeUntilDestroyed())
    .subscribe(record => {
      // the emitted value already takes into account visible and hidden columns
      this.options.next(Util.functions.objectKeys(record));

      // update form value
      const trueKeys = Util.functions.objectKeyVals(record).filter(x => x.val).map(x => x.key);
      this.ctrl.setValue(trueKeys, { emitEvent: false });

      // wether all or none are selected
      this.onAll .next(trueKeys.length === Object.keys(record).length);
      this.onNone.next(trueKeys.length === 0);
    });

    // store the form value
    this.ctrl.valueChanges
    .pipe(takeUntilDestroyed())
    .subscribe(x => {
      // we need to include all keys with a falsy/truthy value
      const record = Object.fromEntries(this.options.value.map(x => [x, false])) as Partial<FormValue[C]>;
      x.forEach(key => record[key] = true as any);
      this._custom.store(record);
    });
  }

  protected toggleAll (event: MatCheckboxChange) {
    this.ctrl.setValue(event.checked ? this.options.value : [], { emitEvent: true });
  }

  protected trackByFn (_: number, item: Key<C>) {
    return item;
  }

}