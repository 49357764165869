import { coerceBooleanProperty            } from '@angular/cdk/coercion';
import { Component,
         Input,
         OnDestroy                        } from '@angular/core';

import { TranslateService                 } from 'app/core';

import { MinBreakLength                   } from '@app/shared/interfaces';
import { Subject, takeUntil               } from 'rxjs';
import { ReadableBreakLengthPipe          } from '@app/shared/pipes/common/common.pipe';

@Component({
  selector: 'app-form-field-break-length-display-value',
  templateUrl: './display-value.component.html',
  styleUrls: ['./display-value.component.scss'],
  providers: [ReadableBreakLengthPipe]
})
export class DisplayValueComponent implements OnDestroy {

  private onDestroy:     Subject<void> = new Subject<void>();
  private onLabelChange: Subject<void> = new Subject<void>();

  public inherits: boolean;
  public label:    string;
  public tooltip:  string;

  constructor (private _translate:           TranslateService,
               private _readableBreakLength: ReadableBreakLengthPipe) {

    this.onLabelChange
    .pipe(takeUntil(this.onDestroy))
    .subscribe(() => this._updateLabel());
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  private _updateLabel(): void {
    if (this.value != null) {
      this.inherits = false;
      this.label    = this._readableBreakLength.transform(this.value);

      this.tooltip = '';
      if (this.value === false) {
        this. tooltip = this._translate.instant('attributes.shared.minBreakLength.none')
      } else {
        const before = typeof this.value === 'number' ? this.value : this.value[0];
        const after  = typeof this.value === 'number' ? this.value : this.value[1];
        this.tooltip = this._translate.instant('attributes.shared.minBreakLength.asymmetrical.tooltip', {
          before: before + ' ' + this._translate.instant('common.minutes').toLowerCase(),
          after:  after  + ' ' + this._translate.instant('common.minutes').toLowerCase()
        });
      }

    } else {
      this.inherits = true;
      this.label    = this.voidText;
      this.tooltip  = '';
    }
  }

  @Input()
  get value() { return this._value; }
  set value(value) {
    this._value = value;
    this.onLabelChange.next();
  }
  private _value: MinBreakLength | null | undefined;

  @Input()
  get voidText(): string { return this._voidText; }
  set voidText(value: string) {
    this._voidText = value;
    this.onLabelChange.next();
  }
  private _voidText: string = '';

  @Input()
  get inherit(): boolean { return this._inherit; }
  set inherit(value: boolean | string) {
    this._inherit = coerceBooleanProperty(value);
    this.onLabelChange.next();
  }
  private _inherit: boolean;

}
