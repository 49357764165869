<ng-template #abort>
  <div class="container">
    <p>Unable to give further information about the error at this time.</p>
  </div>
</ng-template>

<div class="container" *ngIf=" ! inputAnalysis.criticalErrorEncountered; else abort">

  <div class="row">

    <app-input-analyzer-event-intervals-calendar class="calendar"
                                                 [numDays]="numDays"
                                                 [intervals]="intervals"
                                                 [frame]="frame"
                                                 [event]="mainEvent | async"
                                                 [linkedEvents]="linkedEvents | async"
                                                 (placeholderEventLocation)="computeOverlapping($event)">
    </app-input-analyzer-event-intervals-calendar>

    <div class="content dark-vertical-scrollbar">

      <div *ngIf="description" [innerHTML]="description"></div>

      <div [innerHTML]="'inputAnalysis.modules.event intervals.description' | translate | paragraphs:'description'"></div>

      <ng-container *ngFor="let x of entitiesArray| async; trackBy: trackCategory">
        <fieldset *ngIf="x.val?.length">
          <legend >{{ x.key | translate }}</legend>
          <mat-chip-set>
            <!-- set of sets -->
            <ng-container *ngIf="isSetOfSets(x.val)">
              <mat-chip class="location-set"
                        *ngFor="let set of x.val"
                        [class.single-child]="set.set.length == 1"
                        (mouseenter)="setSecondaryIntervals(set.set)"
                        (mouseleave)="setSecondaryIntervals()"
                        disableRipple
                        [class.overflowing]="set.overflowing"
              >
                <mat-chip-set>
                  <mat-chip *ngFor="let x of set.set; trackBy: trackEntity"
                            (mouseenter)="setSecondaryIntervals([x]); $event.stopPropagation()"
                            (mouseleave)="setSecondaryIntervals(set.set)"
                            disableRipple
                            [class.overflowing]="x.overflowing">
                    {{ x.entity | displayName }}
                  </mat-chip>
                </mat-chip-set>
              </mat-chip>
            </ng-container>

            <!-- set of events -->
            <ng-container *ngIf="x.val | typeGuard:isSet as set">
              <mat-chip *ngFor="let x of set; trackBy: trackEntity"
                        (mouseenter)="setSecondaryIntervals([x])"
                        (mouseleave)="setSecondaryIntervals()"
                        disableRipple
                        [class.overflowing]="x.overflowing"
                        [style.background]="isEventOrLockedTime(x.entity) ? (x.entity | eventColor:'50') : null"
              >
                <span *ngIf="isEvent(x.entity); else lockedTime">
                  <mat-icon inline *ngIf="x.entity.fixedStart">push_pin</mat-icon>
                  {{ x.entity | displayName }} - {{ x.entity.preferredDuration }} {{ 'common.minutes_short' | translate }}
                </span>
                <ng-template #lockedTime>
                  <span *ngIf="isLockedTime(x.entity); else other">
                    {{ x.entity | displayName }} - {{ x.entity.duration }} {{ 'common.minutes_short' | translate }}
                  </span>
                </ng-template>
                <ng-template #other>
                  <span>
                    {{ x.entity | displayName }}
                  </span>
                </ng-template>
              </mat-chip>
            </ng-container>

          </mat-chip-set>

        </fieldset>
      </ng-container>

    </div>

  </div>

</div>
