import { Pipe,
         PipeTransform                     } from '@angular/core';
import _                                     from 'lodash';

import { Util                              } from '@app/common';

@Pipe({
  name: 'lessThan'
})
export class ArrayGreaterThanPipe implements PipeTransform {

  constructor() { }

  transform(value: unknown[], ...args: number[]): boolean {
    const [min] = args;

    return value?.length < min;
  }
}

@Pipe({
  name: 'greaterThan'
})
export class ArrayLessThanPipe implements PipeTransform {

  constructor() { }

  transform(value: unknown[], ...args: number[]): boolean {
    const [max] = args;

    return value?.length > max;
  }
}

@Pipe({
  name: 'isLength'
})
export class ArrayLengthPipe implements PipeTransform {

  constructor() { }

  transform(value: unknown[], ...args: number[]): boolean {
    const [length] = args;

    return value?.length == length;
  }
}

@Pipe({
  name: 'interval'
})
export class ArrayIntervalPipe implements PipeTransform {

  constructor() { }

  transform(values: number[], step: number = 1): string {
    if (step < 1) return '';

    const intervals = _.sortBy(values).reduce((acc: [number, number | null][], val: number) => {
      let interval = [...acc].pop();
      if (! interval) {
        acc.push([val, null]);
        return acc;
      }

      const [start, end] = interval;

      if (end == null) {
        interval = [start, val];
        acc[acc.length - 1] = interval;
        return acc;
      }

      if (val - end  > step) {
        acc.push([val, null]);
        return acc;
      }

      interval = [start, val];
      acc[acc.length - 1] = interval;
      return acc;
    }, []);

    return intervals.map(interval => interval.join('-')).join(', ');
  }
}

@Pipe({name: 'pick'})
export class PickPipe implements PipeTransform {
  transform (input: any[] | undefined | null, path: string): any {
    if (input) return input.map(val => _.get(val, path));
    else       return undefined;
  }
}

@Pipe({name: 'first'})
export class FirstPipe implements PipeTransform {
  transform<T> (arr: T[]): T {
    return arr[0];
  }
}

@Pipe({name: 'includes'})
export class IncludesPipe implements PipeTransform {
  transform<T> (arr: T[] | null | undefined, val: T): boolean {
    return !! arr?.includes(val);
  }
}

@Pipe({name: 'truncate'})
export class TruncatePipe implements PipeTransform {
  transform<T> (arr: T[], n: number): T[] {
    return arr.slice(0, n);
  }
}

@Pipe({name: 'filter'})
export class FilterPipe implements PipeTransform {
  transform<T> (arr: T[], ...val: any[]): T[] {
    return arr.filter(x => ! val.includes(x));
  }
}

@Pipe({name: 'filterElements'})
export class FilterElementPipe implements PipeTransform {
  transform<T extends object, R extends object = T> (
    arr:        T[] | null,
    val:        R[] | R | null,
    sourceKey?: Extract<keyof T, string>,
    targetKey:  Extract<keyof R, string> | Extract<keyof T, string> | undefined = sourceKey
  ): T[] | null {
    if (! arr) return arr;
    const _val = Array.isArray(val) ? val.map(x => targetKey ? Util.functions.get(x, targetKey as any, true) : x) :[((targetKey && val) ? Util.functions.get(val, targetKey as any, true) : val)];
    return arr.filter(x => ! _val.includes(sourceKey ? Util.functions.get(x, sourceKey as any, true) : x));
  }
}

@Pipe({name: 'pickElements'})
export class PickElementsPipe implements PipeTransform {
  transform<T extends object, R extends object = T> (
    arr:        T[] | null,
    val:        R[] | R,
    sourceKey?: Extract<keyof T, string>,
    targetKey:  Extract<keyof R, string> | Extract<keyof T, string> | undefined = sourceKey
  ): T[] | null {
    if (! arr) return arr;
    const _val = Array.isArray(val) ? val.map(x => targetKey ? Util.functions.get(x, targetKey as any, true) : x) :[((targetKey && val) ? Util.functions.get(val, targetKey as any, true) : val)];
    return arr.filter(x => _val.includes(sourceKey ? Util.functions.get(x, sourceKey as any, true) : x));
  }
}


@Pipe({name: 'length'})
export class LengthPipe implements PipeTransform {
  transform<T extends Array<any>> (arr: T | null | undefined): number {
    return arr?.length ?? 0;
  }
}