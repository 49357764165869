<div class="wrapper" cdkDropListGroup [class.spinner-cursor]="dragDisabled | async">
  <div class="sidebar">
    <div class="toolbar">
      <app-search #leftSearch placeholder="common.search.event"
        cypress="search-available"
        class="search">
      </app-search>
    </div>

    <div class="content list dark-scrollbar"
      [id]="$any(null)"
      cdkDropList
      [cdkDropListData]="(unusedEvents | async) ?? []"
      (cdkDropListDropped)="drop($event)"
      (cdkDropListEntered)="showEmptyList = false"
      (cdkDropListExited)="showEmptyList = true"
    >
      <!-- events -->
      <div *ngFor="let event of unusedEvents | async; trackBy: trackBy"
          class="drag-item"
          cdkDrag
          [cdkDragData]="event"
          [cdkDragDisabled]="dragDisabled | async"
          (cdkDragReleased)="released()"
        >
        <ng-container [ngTemplateOutlet]="eventTemplate" [ngTemplateOutletContext]="{ event }"></ng-container>
        <ng-container *cdkDragPlaceholder [ngTemplateOutlet]="placeholderTemplate" [ngTemplateOutletContext]="{ event }"></ng-container>
      </div>

      <!-- empty list -->
      <div *ngIf="showEmptyList && (unusedEvents | async)?.length == 0" class="list-empty">
        {{ 'noEvents' | t:path }}
      </div>

    </div>
  </div>

  <div class="main">
    <div class="toolbar">
      <app-search #rightSearch
                  cypress="search-used"
                  class="search"
                  placeholder="common.search.event">
      </app-search>

      <span class="spacer"></span>

      <app-overlap-group-filter [groups]="groups | async"
                                [teachers]="teachers | async"
                                (onChange)="onFilter.next($event)">
      </app-overlap-group-filter>
    </div>

    <div class="content dark-scrollbar">

      <!-- new -->
      <div class="top-content-wrapper">

        @if (isAutoLinkable$ | async) {
          <button mat-icon-button
            (click)="autoLink()"
            [matTooltip]="'shared.dialogs.overlappableEventSets.auto-link.description' | translate"
          >
            <mat-icon>auto_fix_normal</mat-icon>
          </button>
        } @else {
          <span class="unable-to-auto-link" [matTooltip]="'shared.dialogs.overlappableEventSets.unable_to_auto-link.description' | translate">
            <button mat-icon-button disabled>
              <mat-icon>auto_fix_normal</mat-icon>
            </button>
          </span>
        }

        <div class="text">
          {{ 'common.or' | translate | uppercase }}
        </div>

        <div class="new-set"
          [class.entered]="onAddHover"
          [id]="$any(undefined)"
          cdkDropList
          (cdkDropListEntered)="onAddHover = true"
          (cdkDropListExited)="onAddHover = false"
          [cdkDropListData]="emptyContainer"
          (cdkDropListDropped)="onAddHover = false; create($event)"
        >
          <mat-icon>add</mat-icon>
          <!-- <div class="text" [matTooltip]="'description.forced' | t:path"> -->
          <div class="text">
            {{ 'new.forced' | t:path }}
          </div>
        </div>

      </div>

      <!-- one for each linked event set -->
      <div *ngFor="let eventSet of usedEvents | async | filterEventSets:(onFilter | async):((onUsedSearch | async) ?? ''); trackBy: trackBy" class="set">
        <div class="toolbar flex-align-center">

          <span class="spacer"></span>

          <!-- SHOULD WE EVEN USE THIS? -->
          <mat-icon class="warning"
            *ngIf="eventSet.events | lessThan:2"
            [matTooltip]="'warning.forced' | t:path"
          >
            warning
          </mat-icon>

          <button mat-icon-button (click)="delete(eventSet.id)">
            <mat-icon>delete_outline</mat-icon>
          </button>

        </div>

        <div class="drop-content dark-scrollbar"
          cdkDropList
          [id]="eventSet.id"
          [cdkDropListData]="eventSet.events"
          cdkDropListOrientation="horizontal"
          (cdkDropListEntered)="recalculateStyleOnEnter($any($event))"
          (cdkDropListExited)="recalculateStyleOnExit($any($event))"
          (cdkDropListDropped)="drop($event)"
        >
          <div *ngFor="let event of eventSet?.events; trackBy: trackBy"
            cdkDrag
            [cdkDragData]="event"
            [cdkDragDisabled]="dragDisabled | async"
            (cdkDragStarted)="stylePreview()"
            (cdkDragReleased)="released()"
            class="drag-item"
          >
            <ng-container [ngTemplateOutlet]="eventTemplate" [ngTemplateOutletContext]="{ event }"></ng-container>
            <ng-container *cdkDragPlaceholder [ngTemplateOutlet]="placeholderTemplate" [ngTemplateOutletContext]="{ event }"></ng-container>
          </div>
        </div>

      </div>


    </div>
  </div>
</div>


<ng-template #eventTemplate [templateTypes]="typeToken" let-event="event">
  <div class="event dark-scrollbar"
    [style.background]="event | eventColor"
    [style.color]="event | eventColor | contrast"
    [style.--top.%]="event.style?.top"
    [style.--height.%]="event.style?.height"
    [style.--font-size.px]="event.style?.fontSize"
    [style.--line-height.px]="event.style?.lineHeight"
  >
    <div class="primary-line flex-align-center">
      <span cypress="duration">{{ event.preferredDuration }} {{ 'common.minutes_short' | translate }}</span>
      <span class="spacer"></span>
      @if (event.parked) {
        <mat-icon inline>local_parking</mat-icon>
      }
      @if (event.visible == false) {
        <mat-icon inline>visibility_off</mat-icon>
      }
      @if (event.fixedStart) {
        <mat-icon inline>push_pin</mat-icon>
        <span>{{ event.start | toDate | date:'ccc h:mm':'UTC':translate.currentLanguage?.id }}</span>
      }
    </div>

    <div class="primary-line text-overflow-ellipsis">
      {{ event | displayName }}
    </div>

    <div class="secondary-line flex-align-center" *ngIf="event.period; let period">
      <mat-icon inline>date_range</mat-icon>
      <div class="text-overflow-ellipsis">
        {{ period | displayName }}
      </div>
    </div>

    <div class="secondary-line flex-align-center" *ngIf="event.groups?.length">
      <mat-icon inline>people</mat-icon>
      <div class="text-overflow-ellipsis">
        {{ event.groups | pluck:'to' | displayNames }}
        <!-- <span *ngFor="let group of event.groups | orderBy:'displayName'"
              [class.filtered]="group.to | isFiltered:(onFilter | async)">{{ group.to | displayName }}</span> -->
      </div>
    </div>

    <div class="secondary-line flex-align-center" *ngIf="event.teachers?.length">
      <mat-icon inline>school</mat-icon>
      <div class="text-overflow-ellipsis">
        {{ event.teachers | pluck:'to' | displayNames }}
        <!-- <span *ngFor="let teacher of event.teachers | orderBy:'displayName'"
              [class.filtered]="teacher.to | isFiltered:(onFilter | async)">{{ teacher.to | displayName }}</span> -->
      </div>
    </div>
  </div>
</ng-template>


<ng-template #placeholderTemplate [templateTypes]="typeToken" let-event="event">
  <div class="drag-item">
    <ng-container [ngTemplateOutlet]="eventTemplate" [ngTemplateOutletContext]="{ event }"> </ng-container>
  </div>
</ng-template>