import { NgModule                        } from '@angular/core';
import { CommonModule                    } from '@angular/common';

import { AppCommonModule                 } from 'app/common/common.module';

import { ComponentsModule                } from './components/components.module';

import { EventIntervalsComponent         } from './event-intervals.component';
import { TranslationModule               } from '@app/core/translate/translate.module';
import { PipesModule                     } from '@app/shared/pipes/pipes.module';


@NgModule({
  declarations: [
    EventIntervalsComponent,
  ],
  exports: [
    EventIntervalsComponent
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    AppCommonModule,
    TranslationModule,
    PipesModule
  ]
})
export class EventIntervalsModule { }
