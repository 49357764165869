import moment                from 'moment';
import { LockedTime, Event } from '@app/shared/interfaces';
import { EventInput        } from '@fullcalendar/core';

type _Event      = Event.populated;
type _LockedTime = LockedTime.populated;
type _Plain      = {
  is?:   'event' | 'lockedTime',
  id:    string;
  start: moment.Moment;
  end:   moment.Moment;
}

export type _EventSource = _Event | _LockedTime | _Plain;

export type ExtendedProperties = {
  // multiple selected events colors
  colors?:        string[]

  // event density
  intervalIndex?: number;
  setIndex?:      number;

  // is used where?
  description?:   string;
};

// what is given back to the user
export type EventSource = _EventSource & {
  extendedProps?: ExtendedProperties
}

export type InCalendarEvent = EventSource & {
  // here we add internal fullcalendar properties
  title?:           string;   // (overrides the autogenerated title)
  backgroundColor?: string;   // (overrides the autogenerated color)
  classNames?:      string[]; // (css class names given to the fc event)
}

export type InCalendarBackgroundEvent = {
  start:            moment.Moment;
  end:              moment.Moment;
  title?:           string;
  description?:     string;
  backgroundColor?: string;
  classNames?:      string[];
}

export type ForegroundEvent = EventInput & { extendedProps: ExtendedProperties & { source: _EventSource } };
export type BackgroundEvent = EventInput & { extendedProps: ExtendedProperties & { source: { } } };


export function isEvent (x: _EventSource): x is Event.populated {
  return 'is' in x && x.is === 'event';
}
export function isLockedTime (x: _EventSource): x is LockedTime.populated {
  return 'is' in x && x.is === 'lockedTime';
}
