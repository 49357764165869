import { Component,
         Input,
         OnDestroy} from '@angular/core';
import moment                              from 'moment';

import { DateService                      } from 'app/shared/services';
import { debounceTime, Subject, takeUntil } from 'rxjs';
import _                                    from 'lodash';

@Component({
  selector: 'app-form-field-center-of-attraction-display-value',
  templateUrl: './display-value.component.html',
  styleUrls: ['./display-value.component.scss']
})
export class DisplayValueComponent implements OnDestroy {

  private onDestroy:     Subject<void> = new Subject<void>();
  private onLabelChange: Subject<void> = new Subject<void>();

  public empty:    boolean;
  public label:    string;
  public tooltip:  string;

  constructor (private _date: DateService) {

    this.onLabelChange
    .pipe(takeUntil(this.onDestroy))
    .subscribe(() => this._updateLabel());
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  private _updateLabel(): void {
    if (this.value == null) {
      this.empty   = true;
      this.label   = this.voidText;
      this.tooltip = '';
    } else {
      this.empty   = false;
      this.label   = this.value.toString();
      this.tooltip = this.label;
    }
  }

  @Input()
  get value(): string | moment.Moment | null | undefined { return this._value; }
  set value(value: string | moment.Moment | null | undefined) {
    if (_.isString(value) && value.length == 5) this._value = value;
    else this._value = value ? this._date.fromDate(moment.utc(value)) : null;
    this.onLabelChange.next();
  }
  private _value: string | moment.Moment | null | undefined;


  @Input()
  get voidText(): string { return this._voidText; }
  set voidText(value: string | number | undefined) {
    this._voidText = value?.toString() ?? '';
    this.onLabelChange.next();
  }
  private _voidText: string = '';

  @Input()
  get valid(): boolean { return this._valid; }
  set valid(value: boolean) {
    this._valid = value ?? true;
    this.onLabelChange.next();
  }
  private _valid: boolean = true;
}
