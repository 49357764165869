import { NgModule                        } from '@angular/core';
import { CommonModule                    } from '@angular/common';
import { FormsModule,
         ReactiveFormsModule             } from '@angular/forms';

import { TranslationModule               } from 'app/core/translate/translate.module';

import { DragDropModule                  } from '@angular/cdk/drag-drop';

import { PipesModule                     } from 'app/shared/pipes/pipes.module';

import { AppCommonModule                 } from 'app/common/common.module';

import { ComponentsModule                } from 'app/shared/components/components.module';

import { ComponentsModule as SubModule   } from './components/components.module';

import { AvailableLocationsComponent     } from './available-locations.component';


@NgModule({
  declarations: [
    AvailableLocationsComponent
  ],
  exports: [
    AvailableLocationsComponent,
    SubModule
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    TranslationModule,
    PipesModule,
    DragDropModule,
    FormsModule,
    SubModule,
    ComponentsModule,
    ReactiveFormsModule
  ]
})
export class AvailableLocationsFormFieldModule { }
