import { Injectable,
         NgZone,
         EventEmitter                                       } from '@angular/core';
import { MatPaginator,
         MatSort                                            } from 'app/common';
import { HttpService,
         SocketService                                      } from 'app/core';
import { DataSource                                         } from '@angular/cdk/table';
import { Observable,
         BehaviorSubject,
         merge,
         asyncScheduler,
         of,
         Subject                                            } from 'rxjs';
import { startWith,
         switchMap,
         map,
         catchError,
         filter,
         debounceTime,
         takeUntil                                          } from 'rxjs/operators';
import _                                                      from 'lodash'
import { SearchComponent                                    } from 'app/shared';


export type Filter = {
  change: () => Observable<any>;
  value:  any;
}

type Row = { id: string; }

type Type = 'local' | 'cloud';

export interface DataSourceOptions<T extends Row = any> {
  fetchType?: Type;
  url?:       string;
  data?:      T[];
  meta?:      any;
  param?:     string;
  params?:    any;
  page?:      number;
  perPage?:   number;
  pages?:     number;
  sortId?:    string;
  sortOrder?: string;
  watch?:     boolean;
  return?:    boolean;
  namespace?: string;
  channel?:   string;
  source?:    Observable<T[] | { docs: T[], totalDocs: number } | null>;
  paginator?: MatPaginator;
  sort?:      MatSort;
  search?:    SearchComponent;
  filter?:    Filter;
}

@Injectable()
export class DataSourceService<T extends Row = any> extends DataSource<T> {
  private onDestroy = new Subject<void>();

  public _isLoading = new BehaviorSubject<boolean>(true);
  public error:            boolean                   = false;
  public resultsLength:    number;
  public meta:             any                       = {};

  private _options:        DataSourceOptions<T>;
  private _filterChange  = new BehaviorSubject<string>('');
  private _dataChange    = new BehaviorSubject<T[]>([]);
  private _updateChange  = new BehaviorSubject<boolean>(true);


  set isLoading (val: boolean) { this._isLoading.next(val); }
  get isLoading () { return this._isLoading.value; }
  get onIsLoading (): Observable<boolean> { return this._isLoading; }

  get data () {
    return this._dataChange.value;
  }

  get dataChange () {
    return this._dataChange;
  }

  get searchfilter () {
    return this._filterChange.value;
  }

  set filter (val: string) {
    this._filterChange.next(val);
  }

  constructor(private _http:  HttpService,
              private ngZone: NgZone,
              private socket: SocketService) {
    super();
  }

  public init(val: DataSourceOptions<T>) {
    asyncScheduler.schedule(() => {
      this._options           = val;
      this._options.fetchType = val.fetchType;
      this._options.data      = val.data ?? [];
      this.meta               = val.meta ?? {};

      if (this._options.source) {
        this._options.source
        .pipe(
          takeUntil(this.onDestroy),
          filter(Boolean),
        )
        .subscribe((ret) => {
          this._isLoading.next(false);

          if (! ('docs' in ret)) {
            this._dataChange.next(ret);
            return;
          }

          const { docs, totalDocs } = ret;
          this.resultsLength = totalDocs;
          this._dataChange.next(docs);
        })
      } else {
        this._options.fetchType = val.fetchType ?? 'cloud';
      }

      if (this._options.fetchType === 'local') {

        this._isLoading.next(false);
        this._dataChange
        .pipe(takeUntil(this.onDestroy))
        .subscribe(val => {
          this.resultsLength = val.length;
        })
        this._dataChange.next(val.data ?? []);
      }

      if (this._options.fetchType === 'cloud')
        this.watchFilterChange()
        .pipe(
          takeUntil(this.onDestroy),
          debounceTime(300),
          switchMap(() => {
            this._isLoading.next(true);
            return this._getFiltered(this.searchfilter);
          })
        )
        .subscribe(data => {
          this._dataChange.next(data);
        })
    });
  }

  public update() {
    this._updateChange.next(! this._updateChange.value);
  }

  public has(_id: string):boolean {
    return !!this.data?.find((el) => el.id === _id);
  }

  public set(_data: T[]): void {
    this._dataChange.next(_data);
  }

  public remove(elementId: string): void {
    this._dataChange.next(this.data.filter((el) => el.id !== elementId));
  }

  public add(element: T): void {
    this._dataChange.next([element, ...this.data]);
  }

  public patchValue(elementId: string, patch: Partial<T>): void {
    let element = this.data.find((el) => el.id === elementId);
    if (element)
      Object.assign(element, patch);
    this._dataChange.next(this.data);
  }

  private _getFiltered(filter: string){
    if (this._options.paginator) {
      this._options.page = this._options.paginator.pageIndex + 1;
      this._options.perPage = this._options.paginator.pageSize;
    }

    if (this._options.sort) {
      this._options.sortId = this._options.sort.active;
      this._options.sortOrder = this._options.sort.direction;
    }

    let params = this._options.params ?? {};

    if (this._options.return) {
      params.return = true;
      this._options.return = false;
    }

    if (this._options.search?.value != null) {
      params.search = this._options.search.value || null;
    }

    if (this._options.filter?.value) {
      Object.assign(params, this._options.filter.value);
    }

    if (filter)
      params.filter = filter;

    if (this._options.fetchType === 'cloud' && this._options.url) {
      return this._http
      .getPaginated(this._options.url, _.pickBy(params, _.identity), this._options)
      .pipe(
        map(data => {
          this.error         = false;
          this._isLoading.next(false);
          this.resultsLength = data.totalDocs;
          if (data.meta)
            this.meta        = data.meta
          return data.docs;
        }),
        catchError(() => {
          this._isLoading.next(false);
          this.error     = true;
          return of([]);
        })
      );

    } else {
      return this._filterLocalData();
    }
  }

  private watchFilterChange(): Observable<any[]> {
    let changes:(Observable<any> | EventEmitter<any>)[] = [
      this._filterChange,
      this._updateChange
    ];
    if (this._options.sort) {
      changes.push(this._options.sort.sortChange);

      this._options.sort.sortChange
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => { if (this._options.paginator) this._options.paginator.pageIndex = 0; });
    }
    if (this._options.paginator)
      changes.push(this._options.paginator.page);

    if (this._options.search) {
      changes.push(this._options.search.onValue);

      this._options.search.onValue
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => { if (this._options.paginator) this._options.paginator.pageIndex = 0; });
    }

    if (this._options.filter) {
      changes.push(this._options.filter.change());

      this._options.filter.change()
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => { if (this._options.paginator) this._options.paginator.pageIndex = 0; });
    }
    return merge(...changes).pipe(startWith(null));
  }

  private _filterLocalData() {
    return of(this.data.slice(0, this._options.perPage))
  }

  connect() {
    return this._dataChange;
  }

  disconnect() {
    //this._dataChange.next([]);
    this.onDestroy.next();
    this.onDestroy.complete();
  }
}
