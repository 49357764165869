import { Component,
         AfterViewInit,
         OnDestroy,
         Inject,
         ViewContainerRef                                 } from '@angular/core';
import { Router                                           } from '@angular/router';
import { MatDialog                                        } from '@angular/material/dialog';

import { AuthService,
         HttpService,
         LoggerService,
         PushNotificationService                          } from 'app/core';
import { apiConstants,
         VIEW_ACCESS_TYPE,
         ViewAccessType                                   } from 'app/constants';
import { Company as _Company                              } from 'app/shared/interfaces';
import { DataSourceService,
         DialogsService                                   } from 'app/shared/services';
import { ActionButton                                     } from 'app/shared/components/table-action-buttons/table-action-buttons.component';
import { TableCore                                        } from '../table-core';
import { AddEditComponent,
         Data as AddEditComponentData                     } from './components/add-edit/add-edit.component';
import { ConfigureIdentifiersComponent,
         Data as ConfigureIdentifiersComponentData        } from './components/configure-identifiers/configure-identifiers.component';
import { ConfigureComputeEnvironmentComponent,
         Data as ConfigureComputeEnvironmentComponentData } from './components/configure-compute-environment/configure-compute-environment.component';
import { IntegrationsComponent,
         Data as IntegrationsComponentData                } from './components/integrations/integrations.component';
import { AppFeaturesComponent,
         Data as AppFeaturesComponentData                 } from './components/app-features/app-features.component';


type Organization = _Company.complete;

@Component({
  selector: 'app-organizations-table',
  templateUrl: './organizations.component.html',
  styleUrls: ['./organizations.component.scss'],
  providers: [
    DataSourceService
  ]
})
export class OrganizationsComponent extends TableCore<Organization> implements OnDestroy, AfterViewInit {
  protected actionButtons: ActionButton<Organization>[] = [
    {
      icon:    'login',
      label:   { translate: 'tables.admin-tables.organizations.actions.login' },
      action:  (x) => { this.login(x) }
    },
    this.viewAccessType == 'admin' ? {
      icon:    'vpn_key',
      label:   { translate: 'tables.admin-tables.organizations.actions.generate_access_key' },
      action:  (x) => { this.generateAccessKey(x) }
    } : undefined,
     this.viewAccessType == 'admin' ? {
      icon:    'developer_board',
      label:   { translate: 'tables.admin-tables.organizations.actions.configure_environment' },
      action:  (x) => { this.configureEnvironment(x) }
    } : undefined,
    {
      icon:    'widgets',
      label:   { translate: 'tables.admin-tables.organizations.actions.app_features' },
      action:  (x) => { this.openAppFeaturesDialog(x) }
    },
    {
      icon:    'fingerprint',
      label:   { translate: 'tables.admin-tables.organizations.actions.configure_identifiers' },
      action:  (x) => { this.configureIdentifiers(x) },
    },
    {
      icon:    { icon: 'settings', outlined: true },
      label:   { translate: 'tables.admin-tables.organizations.actions.edit' },
      action:  (x) => { this.openAddEditDialog(x) }
    },
    {
      icon:    'delete_outline',
      label:   { translate: 'tables.admin-tables.organizations.actions.delete' },
      action:  (x) => { this.deleteOne(x) }
    }
  ];


  constructor (
    @Inject(VIEW_ACCESS_TYPE)
    protected readonly viewAccessType: ViewAccessType,
    protected dataSource:        DataSourceService<Organization>,
    protected logger:            LoggerService,
    private   _matDialog:        MatDialog,
    private   _dialog:           DialogsService,
    private   _viewContainerRef: ViewContainerRef,
    private   _auth:             AuthService,
    private   _http:             HttpService,
    private   _notification:     PushNotificationService,
    private   _router:           Router,
  ) {
    super(viewAccessType, dataSource, logger);

    this.columns = [
      'name',
      // 'organization',
      this.viewAccessType == 'admin' ? 'organizationType' : undefined,
      this.viewAccessType == 'admin' ? 'associatedPartner' : undefined,
      this.viewAccessType == 'admin' ? 'theme' : undefined,
      'divisions',
      'identifiers',
      this.viewAccessType == 'admin' ? 'version' : undefined,
      // this.role == 'admin' ? 'environment' : undefined,
      'createdAt',
      'actions'
    ];

  }

  ngAfterViewInit() {
    // cannot fetch data in constructor because of @ViewChild
    this.dataSource.init({
      url:       `${ this.urlRootDir }/${ apiConstants.COMPANIES }`,
      paginator: this.paginatorComponent ?? undefined,
      sort:      this.sortComponent      ?? undefined,
      search:    this.searchComponent    ?? undefined,
      filter:    this.filterComponent    ?? undefined,
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  protected deleteOne (org: Organization) {
    this._dialog.openRemoveDialog()
    .subscribe((result) => {
      if ( ! result) return;

      this._http.delete(`${ this.urlRootDir }/${ apiConstants.COMPANIES }/${ org.id }`, undefined)
      .subscribe({
        next:  () => this.dataSource.update(),
        error: (err) => {
          this.logger.error(err);
          this._notification.pushError({ translate: 'tables.admin-tables.organizations.actions.delete.failure' });
        }
      });
    });
  }

  protected openAppFeaturesDialog (org: Organization) {
    this._matDialog.open<AppFeaturesComponent, AppFeaturesComponentData>(AppFeaturesComponent, {
      viewContainerRef: this._viewContainerRef,
      panelClass: ['position-relative'],
      data: {
        organization: org
      }
    })
    .afterClosed()
    .subscribe(() => this.dataSource.update());
  }

  protected openAddEditDialog (org?: Organization) {
    this._matDialog.open<AddEditComponent, AddEditComponentData>(AddEditComponent, {
      viewContainerRef: this._viewContainerRef,
      data: {
        urlRootDir:   this.urlRootDir,
        organization: org
      }
    })
    .afterClosed()
    .subscribe(res => res && this.dataSource.update());
  }


  protected login (org: Organization): void {
    this._http.get(`${ this.urlRootDir }/authenticate/company/${ org.id }`)
    .subscribe({
      next: res => {
        this._auth.login(res);

        // go main page
        this._router.navigateByUrl('/');
      },
      error: err => {
        this.logger.error(err);
        this._notification.pushError({ translate: 'tables.admin-tables.organizations.actions.login.failure' });
      }
    });
  }

  public generateAccessKey (org: Organization): void {
    this._matDialog.open<IntegrationsComponent, IntegrationsComponentData>(IntegrationsComponent, {
      viewContainerRef: this._viewContainerRef,
      panelClass: ['position-relative'],
      data: {
        organization: org
      }
    });
  }

  public configureEnvironment (org: Organization): void {
    this._matDialog.open<ConfigureComputeEnvironmentComponent, ConfigureComputeEnvironmentComponentData>(ConfigureComputeEnvironmentComponent, {
      viewContainerRef: this._viewContainerRef,
      panelClass: ['position-relative'],
      data: {
        organization: org
      }
    })
    .afterClosed()
    .subscribe(res => res && this.dataSource.update());
  }

  public configureIdentifiers (org: Organization): void {
    this._matDialog.open<ConfigureIdentifiersComponent, ConfigureIdentifiersComponentData>(ConfigureIdentifiersComponent, {
      viewContainerRef: this._viewContainerRef,
      panelClass: ['position-relative'],
      data: {
        organization: org,
        urlRootDir:   this.urlRootDir
      }
    })
    .afterClosed()
    .subscribe(res => res && this.dataSource.update());
  }

}