export const Categories = [
  'inputAnalysisError',
  'inputAnalysisWarning',
  'inputAnalysisNotice',
  'generalError',
  'generalConfirmation',
  'other'
] as const;
export type Category = typeof Categories[number];

export const Modes = ['on', 'mute', 'off'] as const;
export type Mode = typeof Modes[number];

export const Locations = ['top left', 'top right', 'bottom left', 'bottom right'] as const;
export type Location = typeof Locations[number];

// export const Volume = ['top left', 'top right', 'bottom left', 'bottom right'] as const;
export type Volume = number;

export const Sounds = [
  {
    name:  'definite',
    audio: new Audio('assets/sounds/definite-555.mp3')
  }, {
    name: 'conclusive',
    audio: new Audio('assets/sounds/conclusive-message-tone.mp3')
  }, {
    name: 'micro',
    audio: new Audio('assets/sounds/micro-86.mp3')
  }, {
    name: 'solemn',
    audio: new Audio('assets/sounds/solemn-522.mp3')
  }, {
    name: 'showstopper',
    audio: new Audio('assets/sounds/show-stopper-102.mp3')
  }, {
    name: 'just saying',
    audio: new Audio('assets/sounds/just-saying-593.mp3')
  }, {
    name: 'victory',
    audio: new Audio('assets/sounds/victory-108.mp3')
  }, {
    name: 'done for you',
    audio: new Audio('assets/sounds/done-for-you-612.mp3')
  }, {
    name: 'open up',
    audio: new Audio('assets/sounds/open-up-587.mp3')
  }, {
    name:  'bing bing bing',
    audio: new Audio('assets/sounds/bing-bing.mp3')
  }
] as const;
export type Sound = typeof Sounds[number]['name'];

export type CategoryArgs = {
  category: Category;
  location: Location;
  sound:    Sound;
  mode:     Mode;
  volume:   Volume;
};

export type Button = {
  text:   string;
  action: () => void;
  class?: string;
  close?: boolean;
}

export type NotificationArgs = {
  id:         number;
  duration:   number | false;
  title:      string | false;
  titleClass: string;
  icon:       string | false;
  iconClass:  string;
  buttons:    Button[] | false;
}

export type Message = string | { translate: string };

export type Stack = {
  notifications: {message: Message, args: NotificationArgs}[],
  args:  CategoryArgs
};


const _defaultCategorySettings: Omit<CategoryArgs, 'category'>
  = { location: 'top right', sound: 'definite', mode: 'on', volume: 100 };

export function defaultCategorySettings (x: Category): Omit<CategoryArgs, 'category'> {
  let settings = _defaultCategorySettings;

  if (x == 'generalError') {
    settings.location = 'bottom left';
    settings.sound    = 'showstopper';
  }

  return settings
}

export type Categories = { [key in Category]: Omit<CategoryArgs, 'category'> };

export const defaultCategories
  = Object.fromEntries(Categories.map(x => [x, defaultCategorySettings(x)])) as Categories



