import { Component,
         OnInit,
         Inject,
         ChangeDetectionStrategy,
         HostListener                    } from '@angular/core';
import { Subject,
         map,
         combineLatest,
         shareReplay,
         BehaviorSubject                 } from 'rxjs';

import { TranslateService                } from '@app/core';

import { Tutorial                        } from '../../page/page.component';
import { TUTORIAL_DATA                   } from '@app/shared/services/tutorials/tutorials.service';

@Component({
  selector: 'app-video-guide',
  templateUrl: './video-guide.component.html',
  styleUrls: ['./video-guide.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoGuideComponent implements OnInit {
  protected selected = new BehaviorSubject<Tutorial | null>(null);
  public onDestroy = new Subject<void>();
  protected source   = combineLatest([
    this.selected,
    this.translate.onCurrentLanguage
  ])
  .pipe(
    map(([selected, language]) => (selected?.source[language?.id ?? 'en'])),
    shareReplay()
  );

  constructor(protected translate: TranslateService,
              @Inject(TUTORIAL_DATA) public data: { selected: Tutorial, tutorials: Tutorial[] }) {
                console.log('VideoGuideComponent constructor', data)
    this.selected.next(this.data.selected);
  }

  ngOnInit(): void {

  }

  public openVideo(tutorialId: Tutorial['id']) {
    const tutorial = this.data.tutorials.find(tutorial => tutorial.id === tutorialId);
    if (tutorial)
      this.selected.next(tutorial);
  }

  close(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }
}