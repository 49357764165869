import { Component,
         Input,
         OnDestroy                        } from '@angular/core';
import { coerceBooleanProperty,
         coerceNumberProperty             } from '@angular/cdk/coercion';

import { TranslateService                 } from 'app/core';

import { Day                              } from 'app/shared/interfaces';

import { DateService                      } from 'app/shared/services';
import { debounceTime, Subject, takeUntil } from 'rxjs';
import { DayNamesPipe                     } from '@app/shared/pipes/date/date.pipe';

@Component({
  selector: 'app-form-field-available-days-display-value',
  templateUrl: './display-value.component.html',
  styleUrls: ['./display-value.component.scss'],
  providers: [DayNamesPipe]
})
export class DisplayValueComponent implements OnDestroy {

  private onDestroy:     Subject<void> = new Subject<void>();
  private onLabelChange: Subject<void> = new Subject<void>();

  public inherits: boolean;
  public label:    string;
  public tooltip:  string;

  constructor (private _translate: TranslateService,
               private _date:      DateService,
               private _dayNames:  DayNamesPipe) {

    this.onLabelChange
    .pipe(takeUntil(this.onDestroy))
    .subscribe(() => this._updateLabel());
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  private _updateLabel(): void {

    const days: any = this._value;

    if (this.inherit) {
      // event
      if (days == null) {
        this.inherits = true;
        this.label    = this.voidText;
        this.tooltip  = '';
      } else {
        this.inherits = false;
        if (days.length == 0) {
          this.label   = this._translate.instant('common.all');
          this.tooltip = this.label;
        } else {
          this.label   = this._dayNames.transform(days, 'short');
          this.tooltip = this._dayNames.transform(days).split(', ').join('\n\n');
        }
      }
    } else {
      // class, group, teacher, dependency
      if (days == null) {
        this.inherits = true;
        this.label    = this.voidText;
        this.tooltip  = '';
      }
      else if (days.length == 0) {
        this.inherits = true;
        this.label    = this._translate.instant('common.all');
        this.tooltip  = this.label;
      }
      else {
        this.inherits = false;
        this.label    = this._dayNames.transform(days, 'short');
        this.tooltip  = this._dayNames.transform(days).split(', ').join('\n\n');
      }
    }
  }

  @Input()
  get value(): Day[] | null | undefined { return this._value; }
  set value(value: Day[] | null | undefined) {
    this._value = value;
    this.onLabelChange.next();
  }
  private _value: Day[] | null | undefined;

  @Input()
  get voidText(): string { return this._voidText; }
  set voidText(value: string) {
    this._voidText = value;
    this.onLabelChange.next();
  }
  private _voidText: string = '';

  @Input()
  get inherit(): boolean { return this._inherit; }
  set inherit(value: boolean | string) {
    this._inherit = coerceBooleanProperty(value);
    this.onLabelChange.next();
  }
  private _inherit: boolean;
}
