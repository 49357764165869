import { Pipe, PipeTransform } from "@angular/core";
import { MatOption           } from "@angular/material/core";

import { All, all, Entity    } from "./multiple-select-filter.component";


@Pipe({
  name: 'standardizeSelected'
})
export class FetchFirstThreePipe implements PipeTransform {

  transform (
    selected: null | undefined | MatOption<Entity | All> | MatOption<Entity | All>[],
  ): null | All | (undefined | Entity)[] {
    if ( ! selected) return null;

    if (Array.isArray(selected)) {
      if ( ! selected.length) return null;

      if (selected.some(x => x.value == all)) return all;

      return selected.map(x => x.value as Entity | undefined);
    }
    else {
      if (selected.value == all) return all;

      return [selected.value];
    }
  }
}