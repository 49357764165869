import _                                   from 'lodash';
import moment                              from 'moment';
import { Populated as P                  } from 'app/shared/interfaces';


type Options = {
  override?: boolean;
};

export function isAutoLinkable (events: P.event[], options: Options = { }): boolean {
  // there must be events
  if ( ! events.length) return false;

  // is not auto-linkable if all events are already linked, unless we override
  if ( ! options.override && events.every(x => !! x.overlapSpecies)) return false;

  // get sorted preferred event durations per course
  const sets = _(events)
    .groupBy(x => x.course?.id ?? `event.${ x.id }`)
    .values()
    .map(x => x.map(x => x.preferredDuration).sort())
    .value();

  // must be at least two sets
  if (sets.length < 2) return false;

  // the sets must be equal
  if ( ! sets.every(x => _.isEqual(x, sets[0]))) return false;

  return true
}

export function autoLink (events: P.event[]): P.event[][] {
  // must be auto-linkable with override
  if ( ! isAutoLinkable(events, { override: true })) return [];

  // group by course and sort by preferred duration
  const eventsByCourse = _(events)
    .groupBy(x => x.course?.id ?? `event.${ x.id }`)
    .values()
    .map(x => x.sort((a, b) => {
      // primarily sort by preferred duration
      if (a.preferredDuration < b.preferredDuration) return -1;
      if (a.preferredDuration > b.preferredDuration) return 1;

      // secondarily sort by start time to avoid randomness
      return moment.utc(a.start)?.diff(moment.utc(b.start));
    }))
    .value();

  // invert from rows to columns to get clusters of events with the same preferred duration, one from each course
  const linkedEvents = _.unzip(eventsByCourse);

  return linkedEvents;
}