import { NgModule                 } from '@angular/core';
import { CommonModule             } from '@angular/common';

import { AppCommonModule          } from 'app/common/common.module';
import { IconComponent            } from './icon.component';


@NgModule({
  declarations: [
    IconComponent
  ],
  exports: [
    IconComponent
  ],
  imports: [
    CommonModule,
    AppCommonModule
  ]
})
export class IconModule { }
