import { Observable                      } from 'rxjs';
import { ProxyMarked,
         proxy                           } from 'comlink';

import { Options                         } from '../source.interface';

import { SourceCore                      } from '../source.core';

// type ZoneAwarePromise = Promise<Observable<any>>;

export function get(this: SourceCore, _options: Omit<Options.Default, 'onDestroy'>, ...observers: Observable<object>[]) {
  /*
    get from system instance
    convert proxy to actual observable
  */
 return this._systemCore.get(_options);
  /*const subject: ZoneAwarePromise | Observable<any> = this._systemCore.get(_options, ...observers);
  //if (subject instanceof proxyMarker)
            //console.log(proxyMarker, from(docs))
  if (! (subject instanceof Observable)) {
    return new Observable<any>((observer) => {
      let sub: any;
      (subject as ZoneAwarePromise).then((ob: Observable<any>) => {
        //console.log('unssdsdsadubsribe ge', Object.keys(ob as any));
        sub = ob.subscribe(
          proxy((docs: any) => {
            observer.next(docs)
          })
        );
      })

      return () => {
        //console.log('unsubsribe ge', Object.keys(sub as any));
        (sub as any).complete();
      }
    })
  }

  return subject//this._systemCore.get(_options, ...observers);*/
}