export const LANG_DE_NAME = 'de';

export const LANG_DE_TRANS = {
  "account.index.header": "Konto verwalten",
  "account.notifications.header": "Benachrichtigungen",
  "account.notifications.menu": "Benachrichtigungen",
  "account.notifications.page.descr": "Die Tabelle unten erlaubt es Ihnen, verschiedene Aspekte des Benachrichtigungssystems zu ändern, das überall in der App verwendet wird.",
  "account.notifications.page.title": "Benachrichtigungen",
  "account.security.header": "Sicherheit",
  "account.security.menu": "Sicherheit",
  "account.security.page.change-password.descr": "Füllen Sie das Formular unten aus, um Ihr Passwort zu ändern.",
  "account.security.page.change-password.error.descr": "Das eingegebene aktuelle Passwort stimmt nicht mit dem tatsächlichen überein. Bitte versuchen Sie es erneut.",
  "account.security.page.change-password.error.title": "Das Passwort konnte nicht aktualisiert werden",
  "account.security.page.change-password.success.descr": "Ihr Passwort wurde nun aktualisiert!",
  "account.security.page.change-password.success.title": "Erfolg",
  "account.security.page.change-password.title": "Passwort ändern",
  "account.security.page.title": "Sicherheit",
  "account.shortcuts.header": "Tastenkürzel",
  "account.shortcuts.menu": "Tastenkürzel",
  "account.shortcuts.page.category.editor.description": "Tastenkürzel für den Stundenplan-Editor.",
  "account.shortcuts.page.category.editor.title": "Editor",
  "account.shortcuts.page.description": "Unten sind Tastenkürzel für häufige Aktionen in der App aufgelistet. Sie können sie nach Ihren Wünschen ändern, indem Sie auf das Kürzel klicken und die neue Tastenkombination drücken.",
  "account.shortcuts.page.editor/hideEvent.description": "Schaltet den ausgeblendeten Zustand der ausgewählten Unterrichtsstunden um.",
  "account.shortcuts.page.editor/hideEvent.label": "Unterrichtsstunde ausblenden",
  "account.shortcuts.page.editor/moveEventEarlier.description": "Verschiebt die ausgewählten Unterrichtsstunden um 5 Minuten zum Tagesanfang.",
  "account.shortcuts.page.editor/moveEventEarlier.label": "Unterrichtsstunde zum Tagesanfang verschieben",
  "account.shortcuts.page.editor/moveEventIndividually.description": "Halten Sie diese Tastenkombination gedrückt, während Sie eine verknüpfte Unterrichtsstunde ziehen, um sie einzeln zu verschieben.",
  "account.shortcuts.page.editor/moveEventIndividually.label": "Verknüpfte Unterrichtsstunde einzeln verschieben",
  "account.shortcuts.page.editor/moveEventLater.description": "Verschiebt die ausgewählten Unterrichtsstunden um 5 Minuten zum Tagesende.",
  "account.shortcuts.page.editor/moveEventLater.label": "Unterrichtsstunde später verschieben",
  "account.shortcuts.page.editor/moveEventMuchEarlier.description": "Verschiebt die ausgewählten Unterrichtsstunden um 60 Minuten zum Tagesanfang.",
  "account.shortcuts.page.editor/moveEventMuchEarlier.label": "Unterrichtsstunde auf wesentlich früher verschieben",
  "account.shortcuts.page.editor/moveEventMuchLater.description": "Verschiebt die ausgewählten Unterrichtsstunden um 60 Minuten zum Tagesende.",
  "account.shortcuts.page.editor/moveEventMuchLater.label": "Unterrichtsstunde auf wesentlich später verschieben",
  "account.shortcuts.page.editor/moveEventNextDay.description": "Verschiebt die ausgewählten Unterrichtsstunden auf den nächsten Tag.",
  "account.shortcuts.page.editor/moveEventNextDay.label": "Unterrichtsstunde auf den nächsten Tag verschieben",
  "account.shortcuts.page.editor/moveEventPrevDay.description": "Verschiebt die ausgewählten Unterrichtsstunden auf den vorherigen Tag.",
  "account.shortcuts.page.editor/moveEventPrevDay.label": "Unterrichtsstunde auf den vorherigen Tag verschieben",
  "account.shortcuts.page.editor/multipleSelectEvents.description": "Wenn Sie diese Taste gedrückt halten, können Sie mehrere Unterrichtsstunden auswählen.",
  "account.shortcuts.page.editor/multipleSelectEvents.label": "Mehrere Unterrichtsstunden auswählen",
  "account.shortcuts.page.editor/parkEvent.description": "Schaltet den geparken Zustand der ausgewählten Unterrichtsstunden um.",
  "account.shortcuts.page.editor/parkEvent.label": "Unterrichtsstunde parken",
  "account.shortcuts.page.editor/pinEvent.description": "Schaltet den fixierten Zustand der ausgewählten Unterrichtsstunden um",
  "account.shortcuts.page.editor/pinEvent.label": "Unterrichtsstunde fixieren",
  "account.shortcuts.page.interference": "Der Befehl für diese Tastenkombination wird bereits von \"{{ label }}\" der gleichen Kategorie verwendet. Bitte wählen Sie eine andere aus.",
  "account.shortcuts.page.notifications.error": "Die Tastenkombination konnte nicht aktualisiert werden. Bitte versuchen Sie es erneut.",
  "account.shortcuts.page.restore.description": "Stellen Sie alle Verknüpfungen auf ihre Standardwerte zurück.",
  "account.shortcuts.page.title": "Tastenkombinationen",
  "account.workspace.header": "Arbeitsbereich",
  "account.workspace.menu": "Arbeitsbereich",
  "account.workspace.page.backgroundDataAnalysis.descr": "Diese Funktion analysiert kontinuierlich Ihre Stundenplandaten und gibt Ihnen Verbesserungsvorschläge. Die kontinuierliche Analyse beeinträchtigt manchmal die Gesamtleistung der Anwendung und kann daher deaktiviert werden. Später kann es jederzeit wieder aktiviert werden.",
  "account.workspace.page.backgroundDataAnalysis.label": "Hintergrunddatenanalyse",
  "account.workspace.page.defaultEventDurations.descr": "Hier können Sie Sets von Standard-Unterrichtsstundesdauern definieren, um das Erstellen von Unterrichtsstunden zu erleichtern.",
  "account.workspace.page.defaultEventDurations.label": "Standard-Unterrichtsstundendauern",
  "account.workspace.page.descr": "Diese Arten von Einstellungen ermöglichen es Ihnen, den Arbeitsbereich der App zu ändern und anzupassen.",
  "account.workspace.page.displayCenterOfAttraction.descr": "Die bevorzugte Zeit bestimmt, zu welchem Zeitpunkt Unterrichtsstunden während der automatischen Stundenplanberechnung gezeichnet werden. Durch Aktivieren dieser Option kann die Standardvorliebe für einzelne Kurse und Unterrichtsstunden überschrieben werden.",
  "account.workspace.page.displayCenterOfAttraction.label": "Die <i>bevorzugte Zeit</i> Kurs- und Unterrichtsstundenoption anzeigen",
  "account.workspace.page.displayChat.descr": "Ob der Support-Chat in der linken unteren Ecke sichtbar ist.",
  "account.workspace.page.displayChat.label": "Support-Chat anzeigen",
  "account.workspace.page.displayEventsTable.descr": "In dieser Tabelle finden Sie die Unterrichtsstunden aus allen Kursen.",
  "account.workspace.page.displayEventsTable.label": "Unterrichtsstunden-Tabelle anzeigen",
  "account.workspace.page.displayPersonsTable.descr": "In dieser Tabelle finden Sie Studenten aus allen Gruppen und solche, die nicht Mitglieder einer Gruppe sind.",
  "account.workspace.page.displayPersonsTable.label": "Studenten-Tabelle anzeigen",
  "account.workspace.page.displayTooltips.descr": "ob Tooltips mit Informationen, die das Konzept näher erläutern, sichtbar sein sollen.",
  "account.workspace.page.displayTooltips.label": "Tooltips anzeigen",
  "account.workspace.page.language.descr": "Legt die Sprache der App fest.",
  "account.workspace.page.notifications.error": "Die Einstellung konnte nicht aktualisiert werden. Bitte versuchen Sie es erneut.",
  "account.workspace.page.title": "Arbeitsbereich",
  "attributeDescriptions.course.centerOfAttraction": "Die Unterrichtsstunden, die dem Kurs angehören, werden während der automatischen Stundenplanberechnung zu ihrer bevorzugten Zeit eingeplant",
  "attributeDescriptions.course.color": "Legt die Anzeigefarbe für die Unterrichtsstunden fest, die dem Kurs angehören",
  "attributeDescriptions.course.days": "Legt die erlaubten Tage für die Unterrichtsstunden fest, die dem Kurs angehören",
  "attributeDescriptions.course.eventDurationVariation": "Legt den maximalen Unterschied zwischen der tatsächlichen Dauer einer Unterrichtsstunde und ihrer bevorzugten Dauer für Unterrichtsstunden fest, die dem Kurs angehören. Die Gesamtdauer des Kurses wird jedoch beibehalten.",
  "attributeDescriptions.course.events": "Die Unterrichtsstunden, die dem Kurs angehören",
  "attributeDescriptions.course.groups": "Legt die Klassen fest, die die Unterrichtsstunden, die dem Kurs angehören, belegen werden",
  "attributeDescriptions.course.intervals": "Legt den Zeitrahmen für die Unterrichtsstunden fest, die dem Kurs angehören",
  "attributeDescriptions.course.locations": "Legt die Räume fest, die für die Unterrichtsstunden, die dem Kurs angehören, gebucht werden können",
  "attributeDescriptions.course.lockedTimes": "Legt die verbotenen Zeitintervalle für die Unterrichtsstunden fest, die dem Kurs angehören",
  "attributeDescriptions.course.minBreakLength": "Legt die kürzeste erlaubte Pause vor und nach den Unterrichtsstunden fest, die dem Kurs angehören",
  "attributeDescriptions.course.overlapGroup": "Definiert eine Gruppe von Kursen, deren Unterrichtsstunden sich überschneiden dürfen. Die Unterrichtsstunden, die dem gleichen Kurs angehören, dürfen sich jedoch niemals überschneiden. Während der automatischen Stundenplanberechnung wird versucht Unterrichtsstunden innerhalb einer solchen Gruppe, so gut wie möglich zu paaren.",
  "attributeDescriptions.course.period": "Legt das Wochenschema für die Unterrichtsstunden fest, die dem Kurs angehören, z. B. Herbst, Frühling oder ungerade und gerade Wochen. Bitte beachten Sie, dass ein Wochenschema erst ausgewählt werden kann, wenn es erstellt wurde.",
  "attributeDescriptions.course.subject": "Das Fach des Kurses",
  "attributeDescriptions.course.teachers": "Legt die Lehrer fest, die die Unterrichtsstunden, die dem Kurs angehören, unterrichten werden",
  "attributeDescriptions.event.centerOfAttraction": "Die Unterrichtsstunde wird während der automatischen Stundenplanberechnung zu ihrer bevorzugten Zeit eingeplant",
  "attributeDescriptions.event.color": "Legt die Anzeigefarbe der Unterrichtsstunde fest",
  "attributeDescriptions.event.course": "Der Kurs, dem die Unterrichtsstunde angehört",
  "attributeDescriptions.event.courseId": "Die öffentliche ID des Kurses, dem die Unterrichtsstunde angehört",
  "attributeDescriptions.event.days": "Legt die erlaubten Tage der Unterrichtsstunde fest",
  "attributeDescriptions.event.duration": "Legt die Dauer der Unterrichtsstunde fest",
  "attributeDescriptions.event.durationVariation": "Legt den maximalen Unterschied zwischen der tatsächlichen Dauer einer Unterrichtsstunde und ihrer bevorzugten Dauer fest. Die Gesamtdauer des Kurses wird jedoch beibehalten.",
  "attributeDescriptions.event.fixedStart": "Fixiert die Startzeit der Unterrichtsstunde",
  "attributeDescriptions.event.groups": "Legt die Klassen fest, die die Unterrichtsstunde belegen werden",
  "attributeDescriptions.event.intervals": "Legt den Zeitrahmen für die Unterrichtsstunde fest",
  "attributeDescriptions.event.locations": "Legt die Räume fest, die für die Unterrichtsstunde gebucht werden können",
  "attributeDescriptions.event.lockedTimes": "Legt die verbotenen Zeitintervalle für die Unterrichtsstunde fest",
  "attributeDescriptions.event.minBreakLength": "Legt die kürzeste erlaubte Pause vor und nach der Unterrichtsstunde fest",
  "attributeDescriptions.event.overlapSpecies": "Verknüpfte Unterrichtsstunden werden zusammengehalten und werden zur gleichen Zeit geplant",
  "attributeDescriptions.event.period": "Legt das Wochenschema für die Unterrichtsstunde fest, z. B. Herbst, Frühling oder ungerade und gerade Wochen. Bitte beachten Sie, dass ein Wochenschema erst ausgewählt werden kann, wenn er erstellt wurde.",
  "attributeDescriptions.event.preferredDuration": "Legt die bevorzugte Dauer der Unterrichtsstunde fest. Nach der automatischen Stundenplanberechnung wird die tatsächliche Dauer jedoch nicht 'Vorzugsdauer ± Dauervariation' überschreiten",
  "attributeDescriptions.event.teachers": "Legt die Lehrer fest, die die Unterrichtsstunde unterrichten werden",
  "attributeDescriptions.group.days": "Legt die erlaubten Tage der Klasse fest",
  "attributeDescriptions.group.intervals": "Legt den Zeitrahmen für die Klasse fest",
  "attributeDescriptions.group.lockedTimes": "Legt die verbotenen Zeitintervalle für die Klasse fest",
  "attributeDescriptions.group.lunch": "Legt die Mittagspausen der Klasse fest",
  "attributeDescriptions.group.minBreakLength": "Legt die kürzeste erlaubte Pause zwischen Unterrichtsstunden für die Klasse fest",
  "attributeDescriptions.group.rootIntervals": "Legt das Zeitraster der Klasse fest",
  "attributeDescriptions.groups.members": "Legt die Mitglieder der Gruppe fest",
  "attributeDescriptions.groups.species": "Legt den Typ der Gruppe fest",
  "attributeDescriptions.groups.parentGroups": "Legt die Bestandteile der Gruppe auf der Grundlage der Mitglieder der Gruppe fest.",
  "attributeDescriptions.location.days": "Legt die erlaubten Tage des Raumes fest",
  "attributeDescriptions.location.lockedTimes": "Legt die verbotenen Zeitintervalle für den Raum fest",
  "attributeDescriptions.location.minBreakLength": "Legt die kürzeste Zeitmarge zwischen zwei Buchungen des Raumes fest",
  "attributeDescriptions.person.emails": "Legt die E-Mail-Adresse des Schülers fest",
  "attributeDescriptions.person.firstName": "Legt den Vornamen des Schülers fest",
  "attributeDescriptions.person.group": "Legt die Gruppe des Schülers fest",
  "attributeDescriptions.person.lastName": "Legt den Nachnamen des Schülers fest",
  "attributeDescriptions.person.lockedTimes": "Legt die verbotenen Zeitintervalle für den Schüler fest",
  "attributeDescriptions.person.lunch": "Legt die Mittagspausen des Schülers fest",
  "attributeDescriptions.person.phoneNumbers": "Legt die Telefonnummer des Schülers fest",
  "attributeDescriptions.person.sex": "Legt das Geschlecht des Schülers fest",
  "attributeDescriptions.person.SSN": "Legt die Sozialversicherungsnummer des Schülers fest",
  "attributeDescriptions.shared.displayName": "Ein Name, der in der App verwendet wird",
  "attributeDescriptions.teacher.days": "Legt die erlaubten Tage des Lehrers fest",
  "attributeDescriptions.teacher.emails": "Legt die E-Mail-Adresse des Lehrers fest",
  "attributeDescriptions.teacher.firstName": "Legt den Vornamen des Lehrers fest",
  "attributeDescriptions.teacher.intervals": "Legt den Zeitrahmen für den Lehrer fest",
  "attributeDescriptions.teacher.lastName": "Legt den Nachnamen des Lehrers fest",
  "attributeDescriptions.teacher.lockedTimes": "Legt die verbotenen Zeitintervalle für den Lehrer fest",
  "attributeDescriptions.teacher.lunch": "Legt die Mittagspausen des Lehrers fest",
  "attributeDescriptions.teacher.minBreakLength": "Legt die kürzeste erlaubte Pause zwischen Unterrichtsstunden für den Lehrer fest",
  "attributes.course.eventDurationVariation": "Unterrichtsstundendauer-Variation",
  "attributes.course.events": "Unterrichtsstunden",
  "attributes.course.overlapGroup": "Parallele Kurse",
  "attributes.division.default.dayRank": "Standard-Tages-Ranking",
  "attributes.division.default.dependencyMinimumBreakLength": "Für Räume",
  "attributes.division.default.dependencyRank": "Standard-Raum-Ranking",
  "attributes.division.default.eventDurationVariation": "Unterrichtsstundendauer-Variation",
  "attributes.division.default.eventMinimumBreakLength": "Zwischen Unterrichtsstunden",
  "attributes.division.default.groupMinimumBreakLength": "Für Klassen und Lehrer",
  "attributes.division.default.lunch": "Standard-Mittagspause",
  "attributes.division.default.maxNumDailyWorkingHours": "Täglich",
  "attributes.division.default.maxNumWorkingHours": "Wochenstunden",
  "attributes.division.default.period": "Zeitraum / Wochenschema",
  "attributes.division.end": "Enddatum",
  "attributes.division.published": "veröffentlichen",
  "attributes.division.settings.dayEnd": "Tag-Ende",
  "attributes.division.settings.dayStart": "Tag-Start",
  "attributes.division.settings.discretization": "Diskretisierung",
  "attributes.division.settings.numDays": "Anzahl der Tage",
  "attributes.division.settings.potentialCenter": "Bevorzugte Zeit",
  "attributes.division.start": "Startdatum",
  "attributes.event.course": "Kurs",
  "attributes.event.courseId": "Kurs-ID",
  "attributes.event.current_duration": "Aktuelle Unterrichtsdauer",
  "attributes.event.durationVariation": "Dauer-Variation",
  "attributes.event.fixedStart": "Feste Startzeit",
  "attributes.event.inherit": "Vom Kurs übernehmen",
  "attributes.event.overlapSpecies": "Verknüpfte Unterrichtsstunden",
  "attributes.event.preferredDuration": "Unterrichtsstundendauer",
  "attributes.generation.status.COMPLETED": "Fertig",
  "attributes.generation.status.PENDING": "Warten",
  "attributes.generation.status.STARTED": "Gestartet",
  "attributes.groups.parentGroups": "Klassen von Bestandteilen",
  "attributes.groups.species.class": "Klasse",
  "attributes.groups.species.none": "Lehrende Gruppe",
  "attributes.groups.species": "Typ der Gruppe",
  "attributes.groups.members": "Mitglieder",
  "attributes.teachers.signature": "Signatur",
  "attributes.integrations.APPROVED": "Aktiv",
  "attributes.integrations.DISABLED": "Deaktiviert",
  "attributes.integrations.PENDING": "Ausstehend",
  "attributes.integrations.REJECTED": "Abgelehnt",
  "attributes.location.days": "Verfügbare Tage",
  "attributes.location.minBreakLength": "Zeit-Marge",
  "attributes.person.firstName": "Vorname",
  "attributes.person.group": "Gruppe",
  "attributes.person.lastName": "Nachname",
  "attributes.person.sex": "Geschlecht",
  "attributes.person.SSN": "Sozialversicherungsnummer",
  "attributes.shared.centerOfAttraction": "Bevorzugte Zeit",
  "attributes.shared.createdAt": "Erstellt",
  "attributes.shared.color": "Farbe",
  "attributes.shared.course": "Kurs",
  "attributes.shared.day": "Tag",
  "attributes.shared.days": "Erlaubte Tage",
  "attributes.shared.displayName": "Name",
  "attributes.shared.duration": "Länge",
  "attributes.shared.durationVariation": "Dauer-Variation",
  "attributes.shared.dynamicLockedTime.custom": "Für jeden Tag festlegen",
  "attributes.shared.dynamicLockedTime.default": "Standardwert",
  "attributes.shared.dynamicLockedTime.none": "Keine",
  "attributes.shared.dynamicLockedTime.repeated": "Für jeden Tag gleich",
  "attributes.shared.emails": "Email",
  "attributes.shared.end": "Ende",
  "attributes.shared.endTime": "Endzeit",
  "attributes.shared.groups": "Klassen",
  "attributes.shared.intervals": "Zeitrahmen",
  "attributes.shared.intervals.multiple": "Für jeden Tag unterschiedlich",
  "attributes.shared.intervals.none": "Standardzeitrahmen verwenden",
  "attributes.shared.intervals.single": "Für jeden Tag gleich",
  "attributes.shared.locations": "Erlaubte Räume",
  "attributes.shared.lockedTimes": "Verbotene Zeiten",
  "attributes.shared.lunch": "Mittagspause",
  "attributes.shared.minBreakLength": "Pausenlängen",
  "attributes.shared.minBreakLength.asymmetrical": "Verschiedene Längen",
  "attributes.shared.minBreakLength.asymmetrical.after": "Danach",
  "attributes.shared.minBreakLength.asymmetrical.before": "Davor",
  "attributes.shared.minBreakLength.asymmetrical.tooltip": "{{ before }} vor und {{ after }} nach",
  "attributes.shared.minBreakLength.default": "Standardwert",
  "attributes.shared.minBreakLength.none": "Keine",
  "attributes.shared.minBreakLength.symmetrical": "Gleiche Längen davor und danach",
  "attributes.shared.period": "Wochenschema",
  "attributes.shared.period.entire": "Alle Wochen",
  "attributes.shared.phoneNumbers": "Telefonnummern",
  "attributes.shared.rootIntervals": "Zeitraster",
  "attributes.shared.start": "Start",
  "attributes.shared.startTime": "Startzeit",
  "attributes.shared.subject": "Fach",
  "attributes.shared.teachers": "Lehrer",
  "auth.role.admin": "Administrator",
  "auth.role.partner": "Partner",
  "auth.role.unrestricted": "Regulärer Benutzer",
  "common.goto_schedule": "Zum Zeitplan gehen",
  "common.+_n_more": "+ {{n}} mehr",
  "common.a_lunch_belonging_to_the_group": "ein Mittagessen, das der Klasse zugeordnet ist",
  "common.a_lunch_belonging_to_the_person": "ein Mittagessen, das der Schüler/in zugeordnet ist",
  "common.a_lunch_belonging_to_the_teacher": "ein Mittagessen, das der Lehrkraft zugeordnet ist",
  "common.about": "Status",
  "common.add": "Hinzufügen",
  "common.add_course": "Kurs hinzufügen",
  "common.add_event": "Lehrstunde(n) hinzufügen: {{ event-durations }}",
  "common.add_event_advanced": "Lehrstunde(n) hinzufügen",
  "common.add_filter": "Wochenschema hinzufügen",
  "common.add_group": "Gruppe hinzufügen",
  "common.add_location": "Raum hinzufügen",
  "common.add_one_more": "Noch einen hinzufügen",
  "common.add_period": "Zeitraum hinzufügen",
  "common.add_person": "Schüler hinzufügen",
  "common.add_teacher": "Lehrer hinzufügen",
  "common.advanced_settings": "Erweiterte Einstellungen",
  "common.all": "Alle",
  "common.alone": "Allein",
  "common.an_event_belonging_to_the_course": "eine Lehrstunde, die dem Kurs zugeordnet ist",
  "common.analysis": "Analyse",
  "common.and": "und",
  "common.and1Other": "und eine andere",
  "common.andNOthers": "und {{n}} andere",
  "common.associated_partner": "Zugehöriger Partner",
  "common.back": "Zurück",
  "common.back_to_login": "Zurück zum Login",
  "common.calendar_view": "Kalenderansicht",
  "common.cancel": "Abbrechen",
  "common.change_password": "Passwort ändern",
  "common.chooseOther": "Andere wählen",
  "common.clear": "Löschen",
  "common.close": "Schließen",
  "common.complete": "Fertig",
  "common.computing": "Berechnung",
  "common.confirmation": "Bestätigung",
  "common.connect": "Verbinden",
  "common.connect_error": "Verbindungsfehler",
  "common.copy": "Kopieren",
  "common.country": "Land",
  "common.counts": "Zähler",
  "common.course": "Kurs",
  "common.courses": "Kurse",
  "common.create": "Erstellen",
  "common.create_course": "Kurs erstellen",
  "common.create_group": "Klasse erstellen",
  "common.create_location": "Raum erstellen",
  "common.create_person": "Schüler erstellen",
  "common.create_schedule": "Stundenplan erstellen",
  "common.create_teacher": "Lehrer erstellen",
  "common.created_at": "Erstellt",
  "common.creating": "Erstellen",
  "common.creation_fail": "Etwas ist schief gelaufen",
  "common.creation_success": "Erstellt",
  "common.custom_search": "benutzerdefinierte Suche",
  "common.day": "Tag",
  "common.days": "Tage",
  "common.default": "Standard",
  "common.delete": "Löschen",
  "common.delete_account": "Konto löschen",
  "common.deleting": "Löschen",
  "common.discard": "Verwerfen",
  "common.download": "Herunterladen",
  "common.drop_here": "Hierher ziehen",
  "common.duplicate": "Duplizieren",
  "common.duplicated": "Dupliziert",
  "common.earliest": "Früheste",
  "common.edit": "Bearbeiten",
  "common.edit_columns": "Spalten bearbeiten",
  "common.edit_failed": "Änderungen konnten nicht gespeichert werden",
  "common.edited_at": "Zuletzt bearbeitet",
  "common.email": "E-Mail",
  "common.empty": "Leer",
  "common.erroneous_value": "Ungültiger Wert",
  "common.error": "Fehler",
  "common.errors": "Fehler",
  "common.event": "Unterrichtsstunde",
  "common.event_durations": "Unterrichtsdauer",
  "common.events": "Unterrichtsstunden",
  "common.expand": "Erweitern",
  "common.export": "Exportieren",
  "common.filter": "Filter",
  "common.flaw": "Fehler",
  "common.flaws": "Fehler",
  "common.forgot_password": "Passwort vergessen?",
  "common.generalErrorMessage": "Hoppla, etwas ist schief gelaufen...",
  "common.generic_error": "Etwas ist schief gelaufen",
  "common.generic_help": "Wir arbeiten daran, das Problem zu lösen.",
  "common.goto-tutorial": "Zum Lernprogramm",
  "common.group": "Klasse",
  "common.groups": "Klassen",
  "common.help": "Hilfe",
  "common.hide": "Ausblenden",
  "common.hour": "Stunde",
  "common.hour_short": "Std",
  "common.hours": "Stunden",
  "common.hours_short": "Std",
  "common.ignore": "Ignorieren",
  "common.import": "Importieren",
  "common.inheritFromCourse": "Von Kurs übernehmen",
  "common.inheritFromCourseValue": "Von Kurs übernehmen ({{value}})",
  "common.invalid": "Ungültig",
  "common.invalid_division_id": "Stundenplan konnte nicht gefunden werden",
  "common.invalid_value": "Ungültiger Wert",
  "common.language": "Sprache",
  "common.latest": "Späteste",
  "common.linked_events": "Verknüpfte Unterrichtsstunden",
  "common.list_view": "Listenansicht",
  "common.loading": "Laden",
  "common.location": "Raum",
  "common.locations": "Räume",
  "common.locked": "Gesperrt",
  "common.locked_time": "Verbotene Zeit",
  "common.locked_times": "Verbotene Zeiten",
  "common.lockedTime": "Verbotene Zeit",
  "common.lockedTimes": "Verbotene Zeiten",
  "common.login": "Anmelden",
  "common.logout": "Abmelden",
  "common.lunch": "Mittagspause",
  "common.make": "machen",
  "common.makes": "macht",
  "common.manage_account": "Verwalten Sie Ihr Konto",
  "common.minimize": "Minimieren",
  "common.minute": "Minute",
  "common.minute_short": "min",
  "common.minutes": "Minuten",
  "common.minutes_short": "min",
  "common.modified_after": "Geändert nach",
  "common.nameless": "Ohne Titel",
  "common.next": "Weiter",
  "common.no": "Nein",
  "common.no_available_data": "Keine Unterrichtsstunden zum Anzeigen",
  "common.no_events_to_show": "Keine Lektionen zum Anzeigen",
  "common.no_match": "Keine Übereinstimmung",
  "common.no_value": "Kein Wert",
  "common.none_plural": "Keine",
  "common.none_singular": "Keine",
  "common.not_placed": "Nicht platziert",
  "common.of": "von",
  "common.or": "Oder",
  "common.other": "Andere",
  "common.others": "Andere",
  "common.overlapGroup": "Set von parallelen Kursen",
  "common.overlapGroups": "Sets von parallelen Kursen",
  "common.period": "Wochenschema",
  "common.periods": "Wochenschemas",
  "common.person": "Schüler/in",
  "common.persons": "Schüler/innen",
  "common.print": "Drucken",
  "common.related-content": "Verwandte Inhalte",
  "common.reload": "Neu laden",
  "common.remaining": "Verbleibend",
  "common.remove": "Entfernen",
  "common.rename": "Umbenennen",
  "common.resend": "Erneut senden",
  "common.reset": "Löschen",
  "common.reset_filter": "Filter zurücksetzen",
  "common.reset_password_fail": "Konnte keinen Link zum Zurücksetzen an diesen Benutzer senden",
  "common.reset_password_success": "Ein Link zum Zurücksetzen wurde gesendet an",
  "common.restore": "Wiederherstellen",
  "common.retry": "Wiederholen",
  "common.rootInterval": "Zeitraster",
  "common.rootIntervals": "Zeitraster",
  "common.save": "Speichern",
  "common.save_failed": "Speichern fehlgeschlagen",
  "common.saved": "Gespeichert",
  "common.saving": "Speichert",
  "common.schedule": "Stundenplan",
  "common.school": "Schule",
  "common.school_year": "Schuljahr",
  "common.screen_size": "Bildschirmgröße zu klein",
  "common.search": "Suche",
  "common.search.course": "Suche nach Kurs",
  "common.search.event": "Suche nach Unterrichtsstunde",
  "common.seconds": "Sekunden",
  "common.seconds_short": "s",
  "common.select": "Auswählen",
  "common.select_entity_to_show_events": "Wählen Sie eine Gruppe, einen Lehrer oder einen Raum aus, um seine Unterrichtsstunden anzuzeigen.",
  "common.select_file": "Datei auswählen",
  "common.selected": "Ausgewählt",
  "common.selected_file": "Ausgewählte Datei: {{file}}",
  "common.settings": "Einstellungen",
  "common.sex.man": "Mann",
  "common.sex.woman": "Frau",
  "common.share": "Teilen",
  "common.show": "Anzeigen",
  "common.show_all_events": "Alle Unterrichtsstunden anzeigen",
  "common.sports_facility": "Sportanlage",
  "common.subject": "Fach",
  "common.subjects": "Fächer",
  "common.sync": "Synchronisieren",
  "common.synchronising": "Synchronisiert",
  "common.teacher": "Lehrkraft",
  "common.teachers": "Lehrkräfte",
  "common.terms": "Allgemeine Geschäftsbedingungen",
  "common.the_course": "Der Kurs",
  "common.the_courses": "Die Kurse",
  "common.the_event": "Der Unterricht",
  "common.the_events": "Die Unterrichte",
  "common.the_group": "Die Klasse",
  "common.the_groups": "Die Klassen",
  "common.the_location": "Der Raum",
  "common.the_locations": "Die Räume",
  "common.the_lunch": "Das Mittagessen",
  "common.the_lunches": "Die Mittagessen",
  "common.the_period": "Die Position",
  "common.the_periods": "Das Wochenschema",
  "common.the_person": "Der/die Schüler/in",
  "common.the_persons": "Die Schüler/innen",
  "common.the_subjects": "Die Fächer",
  "common.the_teacher": "Die Lehrkraft",
  "common.the_teachers": "Die Lehrkräfte",
  "common.time_settings": "Zeiteinstellungen",
  "common.toggle_all": "Alle aus-/abwählen",
  "common.total": "Gesamt",
  "common.type": "Typ",
  "common.unknown": "unbekannt",
  "common.update": "Aktualisieren",
  "common.updated": "Aktualisiert",
  "common.upload": "Hochladen",
  "common.useDefault": "Standard verwenden",
  "common.useDefaultValue": "Standard verwenden ({{value}})",
  "common.username": "Benutzername",
  "common.users": "Benutzer",
  "common.value": "Wert",
  "common.versions": "Versionen",
  "common.warning": "Warnung",
  "common.warnings": "Warnungen",
  "common.week": "Woche",
  "common.week_short": "KW",
  "common.weeks": "Wochen",
  "common.yes": "Ja",
  "common.YYYY-MM-DD": "YYYY-MM-DD",
  "common.zoom_center": "Zoom zurücksetzen",
  "common.zoom_in": "Hineinzoomen",
  "common.zoom_out": "Herauszoomen",
  "common.today": "Heute",
  "common.tomorrow": "Morgen",
  "common.this_week": "Diese Woche",
  "common.next_week": "Nächste Woche",
  "common.this_month": "Dieser Monat",
  "common.next_month": "Nächster Monat",
  "common.modify": "Ändern",
  "common.calendar": "Kalender",
  "common.list": "Liste",
  "common.changes": "Änderungen",
  "common.cancelled": "Abgesagt",
  "common.no_selected": "Keine ausgewählt",
  "common.no_exceptions": "Keine Ausnahmen",
  "common.no_absences": "Keine Abwesenheiten",
  "common.substitutions": "Ausnahmen",
  "common.absences": "Abwesenheiten",
  "common.absentees": "Abwesende",
  "common.reason": "Grund",
  "common.filter_by_groups": "Nach Klassen filtern",
  "common.not_available_for_current_selection": "Nicht verfügbar für die aktuelle Auswahl",
  'common.cancel_event': 'Unterrichtsstunde absagen',
  'common.comment': 'Kommentar',
  "common.error_code": "Fehlercode",
  "current language": "Deutsch",
  "dialogs.admentumImport.step_1.descr": "Hier können Sie auswählen, welche Stundenplan und zugehörige Kurse, Lehrer usw. aus der Admentum-Plattform importiert werden sollen. Wenn Sie für ein Schuljahr mehrere Stundenpläne haben, können Sie diese anhand ihrer ID-Nummer unterscheiden.",
  "dialogs.admentumImport.step_1.required": "Bitte wählen Sie einen Stundenplan, um fortzufahren.",
  "dialogs.admentumImport.step_1.title": "Wählen Sie einen Stundenplan",
  "dialogs.admentumImport.step_2.descr": ". Durch die Auswahl einer Woche werden alle Unterrichtsstunden innerhalb dieser Woche importiert. Wenn keine Woche ausgewählt wird, werden keine Unterrichtsstunden.",
  "dialogs.admentumImport.step_2.title": "Wählen Sie eine Woche",
  "dialogs.admentumImport.step_3.descr": "Sie können den Stundenplanname jederzeit nach dem Import ändern.",
  "dialogs.admentumImport.step_3.label": "Stundenplanname",
  "dialogs.admentumImport.step_3.required": "Bitte geben Sie dem Stundenplan einen Namen.",
  "dialogs.admentumImport.step_3.title": "Geben Sie dem Stundenplan einen Namen",
  "dialogs.create-courses.by-hand.label": "Manuell",
  "dialogs.create-courses.in-bulk.for_each_of": "Erstelle Kurs(e) für jeden von",
  "dialogs.create-courses.in-bulk.label": "Massenweise",
  "inputAnalysis.computing": "Berechnung...",
  "inputAnalysis.dialog.description": "Die Stundenplandaten werden kontinuierlich analysiert und die Ergebnisse werden in den drei Registerkarten rechts dargestellt. Jede Registerkarte repräsentiert eine Kategorie von Fehlern in den Stundenplandaten, die unten beschrieben werden.",
  "inputAnalysis.dialog.empty_category": "Es gibt keine Bemerkungen in dieser Kategorie",
  "inputAnalysis.dialog.overview.title": "Übersicht",
  "inputAnalysis.dialog.title": "Stundenplandatenanalyse",
  "inputAnalysis.disabled": "Die Datenanalyse ist deaktiviert, kann aber jederzeit unter den Workspace-Einstellungen aktiviert werden.",
  "inputAnalysis.entities.theLunch": "Die Mittagspause, die zu {{ theGroupOrTeacher }} gehört",
  "inputAnalysis.errors.21.10.0.description": "Es gibt keinen gemeinsamen erlaubten Tag für die Unterrichtsstunde und die Klasse, Lehrer und Räume, die damit verbunden sind. Siehe unten, welche Tage blockiert sind:",
  "inputAnalysis.errors.21.10.0.title": "{{The-entity}} hat keine erlaubten Tage gemäß den Stundenplandaten.",
  "inputAnalysis.errors.21.10.1.description": "Dies kann aus mehreren Gründen auftreten, z. B. Zeitintervalle, die von verbundenen Klassen und Lehrern verboten sind, oder Tage, die aufgrund von ausgewählten Räumen ausgeschlossen sind. Verwenden Sie das Kalenderwerkzeug unten, um herauszufinden, was der Grund für diesen bestimmten Fall ist und wie er gelöst werden kann.",
  "inputAnalysis.errors.21.10.1.title": "{{The-entity}} passt nicht in seinen verfügbaren Zeitrahmen.",
  "inputAnalysis.errors.24.0.description": "Jede Unterrichtsstunde hat sich selbst mindestens einen Tag zur Verfügung, aber das Problem tritt auf, wenn sie verknüpft sind und somit am selben Tag geplant werden.",
  "inputAnalysis.errors.24.0.title": "{{The-entities}} sind verknüpft, aber haben keinen gemeinsamen erlaubten Tag.",
  "inputAnalysis.errors.24.1.description": "Jede Unterrichtsstunde kann sich selbst in ein Zeitintervall passen, aber das Problem tritt auf, wenn sie verknüpft sind und somit zusammen geplant werden.",
  "inputAnalysis.errors.24.1.title": "{{The-entities}} sind verknüpft, können aber aufgrund von Zeitintervallbeschränkungen nicht zusammen geplant werden.",
  "inputAnalysis.errors.description": "Automatische Planung nicht möglich, bis diese Fehler behoben sind.",
  "inputAnalysis.errors.missing_overlap_species.description": "Unterrichtsstunden, die zu parallelen Kursen gehören, werden nicht garantiert zur gleichen Zeit geplant, nachdem ein Stundenplan erstellt wurde, es sei denn, sie sind verknüpft. Dieser kritische Fehler kann in den Stundenplaneinstellungen deaktiviert werden.",
  "inputAnalysis.errors.missing_overlap_species.title": "{{The-entities}} {{is/are-parallel-courses}}, aber eine oder mehrere zugehörige Unterrichtsstunden sind nicht verknüpft.",
  "inputAnalysis.errors.notification_title": "Die Stundenplandaten enthalten kritische Fehler.",
  "inputAnalysis.errors.title": "Kritische Fehler",
  "inputAnalysis.etc.modify_period": "Ändern Sie das Wochenschema",
  "inputAnalysis.etc.schedule_settings": "Stundenplaneinstellungen",
  "inputAnalysis.etc.schedule-settings": "Stundenplaneinstellungen",
  "inputAnalysis.etc.specify_forced_lesson_overlaps": "Lektionen verknüpfen",
  "inputAnalysis.issues.description": "Automatische Planung möglich, aber das Ergebnis wird nicht optimal sein. Wir empfehlen daher, diese Probleme zu beheben, bevor Sie fortfahren.",
  "inputAnalysis.issues.notification_title": "Die Stundenplandaten enthalten Probleme",
  "inputAnalysis.issues.overloaded group.description": "Es wird schwierig oder unmöglich sein, diese Gruppe ohne jegliche Stundenplankonflikte zu planen.",
  "inputAnalysis.issues.overloaded group.title": "{{The-entity}} ist mit Unterrichtsstunden überlastet.",
  "inputAnalysis.issues.title": "Probleme",
  "inputAnalysis.modules.event intervals.affecting_events": "Beeinflussende Unterrichtsstunden",
  "inputAnalysis.modules.event intervals.description": "Fahren Sie mit der Maus über die Lehrer, Klassen usw. unten, um zu sehen, wie ihre nicht verfügbaren Zeitintervalle, die im Kalender in Rot angezeigt werden, die Lektion beeinflussen. Sie können auch das Gegenteil tun, d. H. Fahren Sie mit der Maus über den Kalender, um zu sehen, ob der entsprechende Platz der Lektion von diesen Lehrern, Klassen usw. erlaubt ist. Wenn der Hintergrund gestreift wird, bedeutet dies, dass der Platz nicht erlaubt ist.",
  "inputAnalysis.modules.event intervals.this event": "Diese Unterrichtsstunde",
  "inputAnalysis.modules.overloaded group.contributing events": "Beitragende Unterrichtsstunden",
  "inputAnalysis.modules.overloaded group.description": "Der Prozentsatz zeigt, wie viel eines Zeitintervalls von Unterrichtsstunden besetzt ist. <b>Alle Prozentsätze über 100% deuten auf einen garantierten Konflikt im Stundenplan hin.</b> <br> <b>Beispiel:</b> Wenn eine 30-minütige Unterrichtsstunde auf einem einstündigen Zeitintervall geplant werden darf, beträgt der Buchungsprozentsatz 50%. Wenn jedoch Unterrichtsstunden, die der Länge von zwei Stunden entsprechen, auf demselben Intervall geplant werden, beträgt der Buchungsprozentsatz 200%, was zu einem Konflikt führt. <br> Häufige Fehler, die dazu beitragen können, dass der Buchungsprozentsatz größer als 100% ist: <ul> <li>Übersehen Sie die Erstellung von überlappenden Unterrichtsstundensätzen. Vergessen Sie nicht, alle Unterrichtsstunden, die parallel unterrichtet werden sollen, in einen überlappenden Unterrichtsstundessatz zu platzieren. <li>Zu viele Unterrichtsstunden am Morgen / Abend.</li><li>Zu viele Unterrichtsstunden an bestimmten Tagen.</li></ul>",
  "inputAnalysis.modules.overloaded group.legend.bad": "Schlecht",
  "inputAnalysis.modules.overloaded group.legend.compact": "Kompakt",
  "inputAnalysis.modules.overloaded group.legend.conflict": "Konflikt",
  "inputAnalysis.modules.overloaded group.legend.fixed": "Festgelegt",
  "inputAnalysis.modules.overloaded group.legend.good": "Gut",
  "inputAnalysis.modules.overloaded group.legend.sparse": "Spärlich",
  "inputAnalysis.notices.completely unconnected event.description": "Diese Arten von völlig unverbundenen Unterrichtsstunden werden vom Algorithmus ignoriert, da sie nichts beeinflussen.",
  "inputAnalysis.notices.completely unconnected event.title": "{{The-entity}} ist nicht mit einem Lehrer, einer Gruppe, einem Schüler oder einem Raum verbunden.",
  "inputAnalysis.notices.description": "Kann darauf hinweisen, dass die Stundenplandaten unvollständig sind.",
  "inputAnalysis.notices.empty course.description": "Kurse ohne Unterrichtsstunden werden vom Algorithmus ignoriert.",
  "inputAnalysis.notices.empty course.title": "{{The-entity}} enthält keine Unterrichtsstunden.",
  "inputAnalysis.notices.empty_period.description": "Haben Sie vergessen, Wochen in das Wochenschema einzubeziehen?",
  "inputAnalysis.notices.empty_period.title": "{{The-entity}} ist leer.",
  "inputAnalysis.notices.missing_overlap_species.description": "Unterrichtsstunden, die zu parallelen Kursen gehören, sind nicht garantiert, nachdem ein Stundenplan erstellt wurde, zu derselben Zeit geplant zu werden, es sei denn, sie sind verknüpft.",
  "inputAnalysis.notices.missing_overlap_species.title": "{{The-entities}} {{is/are-parallel-courses}}, aber eine oder mehrere zugehörige Unterrichtsstunden sind nicht verknüpft.",
  "inputAnalysis.notices.notification_title": "Die Stundenplandaten können unvollständig sein.",
  "inputAnalysis.notices.overflowing_period.description": "Haben Sie vergessen, den Zeitraum nach der Änderung des Zeitraums des Stundenplans zu aktualisieren?",
  "inputAnalysis.notices.overflowing_period.title": "{{The-entity}} liegt teilweise außerhalb des Zeitrahmens des Stundenplans.",
  "inputAnalysis.notices.title": "Bemerkungen",
  "inputAnalysis.notices.unconnected event.description": "Haben Sie vergessen, einen Lehrer, einen Schüler oder eine Gruppe hinzuzufügen?",
  "inputAnalysis.notices.unconnected event.title": "{{The-entity}} ist nicht mit einem Lehrer, einem Schüler oder einer Gruppe verbunden.",
  "inputAnalysis.ok.notification_title": "Keine Bemerkungen zu den Stundenplandaten",
  "schedule.guaranteedTuition.label": "Garantierte Gebühren",
  "schedule.guaranteedTuition.tabs.calendarExceptions.descr": "Hier können Sie Ferien, Feiertage und andere unterrichtsfreie Tage hinzufügen. Diese Tage werden nicht berücksichtigt, wenn die gesamte geplante Zeit berechnet wird.",
  "view.private.schema.sub-pages.settings.page.tab.holidays.dialog.form.description.label": "Beschreibung",
  "view.private.schema.sub-pages.settings.page.tab.holidays.dialog.form.range.label": "Datumsbereich",
  "schedule.guaranteedTuition.tabs.calendarExceptions.tabLabel": "Fügen Sie Ferientage hinzu",
  "schedule.guaranteedTuition.tabs.calendarExceptions.title": "Fügen Sie Ferientage hinzu",
  "schedule.guaranteedTuition.tabs.dayCount.descr": "Die folgende Tabelle zeigt an, wie oft jeder Tag im Stundenplan erscheint, unter Berücksichtigung des Anfangs- und Enddatums sowie der oben angegebenen freien Tage.",
  "schedule.guaranteedTuition.tabs.dayCount.title": "Zusammenfassung",
  "schedule.guaranteedTuition.tabs.guaranteedTuition.empty": "Es gibt keine Unterrichtsstunden, die für die ausgewählte Woche berücksichtigt werden müssen. Beachten Sie, dass die Unterrichtsstunden geplant und nicht nur geplant sein müssen.",
  "schedule.guaranteedTuition.tabs.guaranteedTuition.no_selected_entity": "Wählen Sie eine Gruppe, einen Lehrer oder ein Fach aus, um eine Zusammenfassung der gesamten geplanten Zeit zu erhalten. Bitte beachten Sie, dass nur Kurse, die ein festgelegtes Fach haben, in die Berechnungen einbezogen werden.",
  "schedule.guaranteedTuition.tabs.guaranteedTuition.tabLabel": "Garantierte Gebühren",
  "schedule.guaranteedTuition.tabs.guaranteedTuition.table.per_day": "Pro Tag",
  "schedule.guaranteedTuition.tabs.guaranteedTuition.table.total_count": "Anzahl der Unterrichtsstunden",
  "schedule.header": "Stundenplan",
  "schedule.navigation.automate": "Stundenplan<br>generieren",
  "schedule.navigation.data": "Stundenplan<br>Daten",
  "schedule.navigation.dutyAssignment": "Dienst<br>zuweisen",
  "schedule.navigation.edit": "Stundenplan<br>Editor",
  "schedule.navigation.examine": "Stundenplan<br>untersuchen",
  "schedule.navigation.guaranteedTuition": "Garantierte<br>Gebühren",
  "schedule.navigation.lessonSettings": "Stunden<br>Details",
  "schedule.navigation.reports": "Berichte/<wbr>Statistiken",
  "schedule.navigation.scheduleDataAnalysis": "Daten<br>Analyse",
  "schedule.navigation.scheduleWarnings": "Stundenplan<br>Warnungen",
  "schedule.navigation.settings": "Stundenplan<br>Einstellungen",
  "schedule.navigation.substitution_planner": "Vertretungs<br>planung",
  "schedule.print.header": "Drucken",
  "schedule.print.page.toolbar.color.descr": "Drucken Sie ohne Farbe, um Tinte zu sparen.",
  "schedule.print.page.toolbar.color.label": "Verwenden Sie Farben",
  "schedule.print.page.toolbar.print.descr": "Aus dem Druckdialogfenster können Sie das Dokument auch als PDF-Datei speichern.",
  "schedule.print.page.toolbar.print.label": "Drucken",
  "schedule.print.page.toolbar.QR.descr": "Der Link führt zum Web-Viewer.",
  "schedule.print.page.toolbar.QR.label": "QR-Link einfügen",
  "schema-defects.category.critical.label": "Kritisch",
  "schema-defects.category.minor.label": "Klein",
  "schema-defects.category.significant.label": "Signifikant",
  "schema-defects.courseClustering": "Kurskonzentration",
  "schema-defects.courseDurationMismatch": "Gesamtdauer des Ereignisses",
  "schema-defects.dailyOvertime": "Tägliche Überstunden",
  "schema-defects.doubleBooking": "Doppelbuchung",
  "schema-defects.eventDurationOverflow": "Ereignisdauer",
  "schema-defects.fixed.count.explanation": "Es gibt {{ count }} Stundenplanwarnung(en) dieser Kategorie, die ausschließlich aufgrund von festen Unterrichtsstunden entstehen.",
  "schema-defects.forcedOverlappingEventsDay": "Erzwungene überschneidende Unterrichtsstunden",
  "schema-defects.insufficientBookingMargin": "Zeitmarge",
  "schema-defects.insufficientBreakLength": "Pausenlänge",
  "schema-defects.missingDependency": "Fehlende Räume",
  "schema-defects.missingGroupsAndTeachers": "Fehlender Lehrer und Gruppe",
  "schema-defects.non-fixed.count.explanation": "Es gibt {{ count }} Stundenplanwarnungen dieser Kategorie, die aus anderen Gründen entstehen als die beteiligten Unterrichtsstunden festgelegt sind.",
  "schema-defects.overflow": "Stundenplanrahmen",
  "schema-defects.overlap": "Kollision",
  "schema-defects.overtime": "Überstunden",
  "schema-defects.unavailableDay": "Verbotener Tag",
  "settings.index.copy": "API-Schlüssel kopiert",
  "settings.index.error": "API-Schlüssel konnte nicht erstellt werden",
  "settings.integrationsOut.form.title": "Erstellen Sie einen API-Schlüssel",
  "settings.integrationsOut.menu": "API-Export",
  "settings.integrationsOut.page.descr": "Mit der untenstehenden Funktion können Sie neue API-Schlüssel erstellen, um Stundenplandaten anderen Dienstleistern zur Verfügung zu stellen. Kontaktieren Sie den anderen Anbieter und prüfen Sie, ob sie Stundenplandaten über das SS 12000-API-Standard importieren können.",
  "settings.integrationsOut.page.title": "Datenexport über API (SS 12000:2020)",
  "settings.integrationsOut.shared.description": "Beschreibung",
  "settings.integrationsOut.shared.displayName": "Name",
  "settings.integrationsOut.shared.integrationType": "Plattform",
  "settings.integrationsOut.table.copyKey": "Schlüssel kopieren",
  "settings.integrationsOut.table.createdAt": "Erstellt",
  "settings.integrationsOut.table.legend": "API-Schlüssel",
  "settings.integrationsOut.table.usedAt": "Letzte Aktivität",
  "shared.components.schema-filter-dropdowns coalesced": "Filter hinzufügen",
  "shared.components.schema-filter-dropdowns search course event": "Suche nach Unterrichtsstunde",
  "shared.components.user-menu.to-parent-organization": "Zurück zu {{ name }}",
  "shared.dialogs.add-duration-set.label": "Geben Sie die Unterrichtsstundendauer(n) ein",
  "shared.dialogs.add-duration-set.title": "Neues Dauer-Set",
  "shared.dialogs.company.description": "Erstellt und fügt Ihrer Organisation eine Schule hinzu.",
  "shared.dialogs.company.title": "Schule erstellen",
  "shared.dialogs.confirm-action.title": "Bist du sicher?",
  "shared.dialogs.confirm-remove.confirm": "Ich verstehe",
  "shared.dialogs.confirm-remove.sub-title": "Diese Aktion kann nicht rückgängig gemacht werden",
  "shared.dialogs.confirm-remove.title": "Entfernung bestätigen",
  "shared.dialogs.duplicate-schedule.title": "Stundenplan duplizieren",
  "shared.dialogs.generate-schedule.action.submit": "Stundenplan",
  "shared.dialogs.generate-schedule.analysis.flawless.description": "Ihre Stundenplandaten wurden analysiert und wir haben nichts zu bemerken gefunden. Gut gemacht!",
  "shared.dialogs.generate-schedule.analysis.flawless.title": "Bereit",
  "shared.dialogs.generate-schedule.analysis.flaws.description": "Ihre Stundenplandaten wurden analysiert und wir haben die folgenden {{ flaws }} gefunden, die möglicherweise behoben werden müssen.",
  "shared.dialogs.generate-schedule.description": "Dieser Prozess generiert einen neuen Stundenplan basierend auf den Stundenplandaten und der aktuelle Stundenplan wird daher überschrieben. Wenn Sie den Stundenplan so speichern möchten, wie er jetzt ist, bevor Sie fortfahren, können Sie ihn duplizieren.",
  "shared.dialogs.generate-schedule.error": "Ihre Anfrage kann nicht verarbeitet werden. Bitte versuchen Sie es in ein paar Minuten erneut. Wenn das Problem weiterhin besteht, kontaktieren Sie bitte das Support-Team und wir helfen Ihnen gerne weiter.",
  "shared.dialogs.generate-schedule.title": "Automatische Planung",
  "shared.dialogs.login.description": "Um Ihre Sitzung zu erneuern, müssen Sie sich erneut anmelden. Wenn Sie Ihre Sitzung abbrechen möchten, schließen Sie einfach dieses Dialogfeld.",
  "shared.dialogs.login.title": "Sitzung abgelaufen",
  "shared.dialogs.overlappableEventSets.description.forced": "Die verknüpften Unterrichtsstunden werden zur gleichen Zeit und zentriert geplant.",
  "shared.dialogs.overlappableEventSets.description.set": "Der automatische Planungsalgorithmus versucht, Unterrichtsstunden, die zu parallelen Kursen gehören, zur gleichen Zeit zu planen. Dies ist jedoch nicht immer möglich oder erlaubt. Zum Beispiel ist es nicht erlaubt, dass Unterrichtsstunden eines Kurses zur gleichen Zeit geplant werden, es sei denn, sie sind verknüpft.",
  "shared.dialogs.overlappableEventSets.error.set": "Es gibt zugeordnete Unterrichtsstunden, die nicht verknüpft sind.",
  "shared.dialogs.overlappableEventSets.forceOverlap": "Unterrichtsstunden verknüpfen",
  "shared.dialogs.overlappableEventSets.gridView": "Rasteransicht",
  "shared.dialogs.overlappableEventSets.listView": "Listenansicht",
  "shared.dialogs.overlappableEventSets.new.forced": "Ziehen Sie eine Unterrichtsstund hierher, um ein neues Set verknüpfter Unterrichtsstunden zu bilden",
  "shared.dialogs.overlappableEventSets.new.set": "Ziehen Sie einen Kurs hierher, um ein neues Set paralleler Kurse zu bilden",
  "shared.dialogs.overlappableEventSets.noCourses": "Keine Kurse gefunden",
  "shared.dialogs.overlappableEventSets.noEvents": "Keine Unterrichtsstunden gefunden",
  "shared.dialogs.overlappableEventSets.warning.forced": "Sollte mindestens zwei Unterrichtsstunden enthalten",
  "shared.dialogs.overlappableEventSets.warning.set": "Sollte mindestens zwei Kurse enthalten",
  "shared.dialogs.sync.collections": "Zeitplandaten auswählen",
  "shared.dialogs.sync.error.0": "Die Authentifizierung mit der ausgewählten Plattform konnte mit Ihren Anmeldedaten nicht durchgeführt werden. Wenn dies ein Fehler ist, kontaktieren Sie den Support.",
  "shared.dialogs.sync.error.1": "Die Daten konnten nicht von der ausgewählten Plattform abgerufen werden. Möglicherweise ist etwas falsch eingestellt",
  "shared.dialogs.sync.error.3": "Die Stundenplandaten konnten nicht synchronisiert werden. Ein oder mehrere Schüler sind mit mehreren Klassen verbunden. Dies ist nicht erlaubt. Kontaktieren Sie den Support mit Ihrem Fehlercode für Hilfe.",
  "shared.dialogs.sync.error.4": "Die Stundenplandaten konnten nicht synchronisiert werden. Ein oder mehrere Namen fehlen. Kontaktieren Sie den Support mit Ihrem Fehlercode für Hilfe.",
  "shared.dialogs.sync.last_sync": "Letzte Synchronisierung",
  "shared.dialogs.sync.modified": "Nur geändert nach",
  "shared.dialogs.sync.platform": "Plattform wählen",
  "shared.dialogs.sync.synced_data": "Geänderte Zeitplandaten",
  "shared.dialogs.sync.time": "Zeitraum auswählen",
  "shared.dialogs.sync.title": "Synchronisieren Sie Ihren Zeitplan",
  "shared.dialogs.sync.validation": "Überprüfen Sie die Stundenplandaten",
  "shared.dialogs.sync.validation.title": 'Es gibt einige Probleme mit den Stundenplandaten. Bitte beheben Sie diese, bevor Sie fortfahren.',
  "shared.dialogs.sync.validation.indistinguishable.courses": "mit dem Namen \"{{name}}\" teilen sich den gleichen Namen, Lehrkraft und klassen.",
  "shared.dialogs.sync.validation.indistinguishable.events": "gehören zum Kurs \"{{name}}\" und teilen sich die gleichen Lehrer und Gruppen und Zeit.",
  "shared.dialogs.sync.validation.ids_missing": "fehlen Namen",
  "shared.dialogs.sync.validation.ids_not_unique": "haben identische Namen: \"{{ids}}\"",
  "shared.dialogs.sync.validation.valid": "Die Stundenplandaten sind gültig",
  "shared.dialogs.sync.push_success.title": "Synchronisierung erfolgreich",
  "shared.dialogs.sync.push_success.description": "Die Stundenplandaten wurden erfolgreich mit der ausgewählten Plattform synchronisiert. Gehen Sie zur Plattform, um die Änderungen zu sehen.",
  "shared.dialogs.unrecoverable-app-state.description": "Die Anwendung hat einen unwiederherstellbaren Zustand erreicht. Bitte laden Sie die Seite neu, um fortzufahren.",
  "shared.dialogs.unrecoverable-app-state.title": "Unwiederherstellbarer Zustand",
  "shared.dialogs.unsaved-changes.description": "Es sind ungespeicherte Änderungen vorhanden. Möchten Sie sie speichern oder verwerfen?",
  "shared.dialogs.unsaved-changes.title": "Nicht gespeicherte Änderungen",
  "shared.dialogs.upload-file.configure.format_specific": "Format spezifische Einstellungen",
  "shared.dialogs.upload-file.configure.label": "Konfigurieren",
  "shared.dialogs.upload-file.configure.PlanDigital.displayName.label": "Als Kursname verwenden",
  "shared.dialogs.upload-file.configure.PlanDigital.periods.label": "Wochenschemas auswählen",
  "shared.dialogs.upload-file.configure.PlanDigital.periods.placeholder": "Mindestens ein Wochenschema muss ausgewählt werden",
  "shared.dialogs.upload-file.configure.SchoolSoft.interval.descr": "Unterrichtsstunden ausschließen, die nicht während des ausgewählten Zeitraums ({{start}} - {{end}}) geplant sind.",
  "shared.dialogs.upload-file.configure.SchoolSoft.interval.label": "Kurse basierend auf dem ausgewählten Zeitraum filtern",
  "shared.dialogs.upload-file.configure.Skola24.deduceGroupsFromCourseName.descr": "Versuchen Sie, Gruppen zu identifizieren und hinzuzufügen, die im Kursnamen gefunden werden, wenn sie durch einen Bindestrich getrennt sind, wie z.B. 10FYS01a-<b>NA20E</b>-LABb oder 10-AH-<b>EK21C</b>.",
  "shared.dialogs.upload-file.configure.Skola24.deduceGroupsFromCourseName.label": "Gruppen aus dem Kursnamen ableiten",
  "shared.dialogs.upload-file.configure.Skola24.ignoreEmptyCourses.label": "Kurse ohne Unterrichtsstunden ignorieren",
  "shared.dialogs.upload-file.configure.Skola24.removeUnreferencedGroups.descr": "Gruppen, die in mindestens einem Kurs oder einer Unterrichtsstunde nicht gefunden werden, werden ignoriert.",
  "shared.dialogs.upload-file.configure.Skola24.removeUnreferencedGroups.label": "Nicht verwendete Gruppen ignorieren",
  "shared.dialogs.upload-file.configure.Skola24.removeUnreferencedTeachers.descr": "Lehrer, die in mindestens einem Kurs oder einer Unterrichtsstunde nicht gefunden werden, werden ignoriert.",
  "shared.dialogs.upload-file.configure.Skola24.removeUnreferencedTeachers.label": "Nicht verwendete Lehrer ignorieren",
  "shared.dialogs.upload-file.configure.Skola24.week.ignoreAllEvents": "Alle Unterrichtsstunden ignorieren",
  "shared.dialogs.upload-file.configure.Skola24.week.label": "Unterrichtsstunden aus der ausgewählten Woche importieren",
  "shared.dialogs.upload-file.finalize.content": "Stundenplan-Inhalt",
  "shared.dialogs.upload-file.finalize.error": "Die ausgewählte Datei konnte nicht verarbeitet werden",
  "shared.dialogs.upload-file.finalize.errors_and_warnings": "Fehler und Warnungen",
  "shared.dialogs.upload-file.finalize.label": "Fertigstellen",
  "shared.dialogs.upload-file.label": "Datei hochladen",
  "shared.dialogs.upload-file.select-file.download_template": "Excel-Vorlage herunterladen (optional)",
  "shared.dialogs.upload-file.select-file.label": "Datei auswählen",
  "shared.dialogs.upload-file.select-map.determined": "Exakte Übereinstimmung",
  "shared.dialogs.upload-file.select-map.error": "Das Format der Datei konnte nicht erkannt werden",
  "shared.dialogs.upload-file.select-map.label": "Format auswählen",
  "shared.dialogs.upload-file.select-map.strong": "Wahrscheinliches Format",
  "shared.dialogs.upload-file.select-map.unknown": "Unbekanntes Format",
  "shared.dialogs.upload-file.select-map.weak": "Ähnliches Format",
  "shared.dialogs.users.description": "Geben Sie eine E-Mail-Adresse und eine Schule an, um sich bei der ausgewählten Schule anzumelden.",
  "shared.dialogs.users.title": "Benutzer erstellen",
  "shared.dialogs.version.current_version": "Aktuelle Version",
  "shared.dialogs.version.description": "Eine neue Version der Anwendung ist verfügbar. Bitte laden Sie die Seite neu, um die neueste Version zu erhalten.",
  "shared.dialogs.version.description_hard_reload": "Manchmal reicht ein gewöhnliches Neuladen nicht aus und ein manuelles hartes Neuladen ist erforderlich. Dies wird in der Regel durch Drücken von <kbd>Strg</kbd> + <kbd>Shift</kbd> + <kbd>R</kbd> unter Windows oder <kbd>Cmd</kbd> + <kbd>Shift</kbd> + <kbd>R</kbd> auf einem Mac durchgeführt, hängt jedoch wahrscheinlich von Ihrem Browser ab.",
  "shared.dialogs.version.latest_version": "Neueste Version",
  "shared.dialogs.version.title": "Neue Version verfügbar",
  "shared.form-fields.available-locations.empty-repository": "Keine verfügbaren Räume",
  "shared.form-fields.available-locations.groups": "Raumgruppe",
  "shared.form-fields.available-locations.locations": "Räume",
  "shared.form-fields.available-locations.select-null": "Kein Raum",
  "shared.form-fields.available-locations.selected-null": "Kein Raum",
  "shared.form-fields.components.schema-filter-dropdowns search course event": "Suche nach Unterrichtsstunde",
  "shared.form-fields.dynamic-locked-times.duration.tooltip": "Die bevorzugte Dauer und die maximale Dauerabweichung der Mittagspause. Der Planungsalgorithmus wählt eine geeignete Dauer innerhalb des angegebenen Bereichs aus, hält die kombinierte Dauer aller Mittagspausen jedoch gleich der Summe ihrer bevorzugten Dauer.",
  "shared.form-fields.fixed-start.disabled": "Keine",
  "shared.form-fields.fixed-start.enabled": "Feste Startzeit",
  "shared.form-fields.groups.selection-warning": "ein oder mehrere Schüler dieser Gruppe sind separat ausgewählt",
  "shared.form-fields.periods.no-periods": "Es gibt keine Wochenschemas, aus denen Sie auswählen können. Bitte fügen Sie Wochenschemas zum Stundenplan hinzu.",
  "shared.form-fields.root-intervals.no-root-intervals": "Es sind keine Zeitraster zur Auswahl vorhanden. Legen Sie für den Stundenplan mindestens ein Zeitraster an.",
  "shared.forms.change-password-form.current_password": "Aktuelles Passwort",
  "shared.forms.change-password-form.new_password": "Neues Passwort",
  "shared.forms.change-password-form.repeat_password": "Passwort wiederholen",
  "shared.forms.company.name": "Name",
  "shared.forms.company.organization": "Übergeordnete Organisation",
  "shared.forms.company.organizationType": "Organisationstyp",
  "shared.forms.company.schoolCode": "Code",
  "shared.forms.company.theme": "Thema",
  "shared.forms.users.company": "Schule auswählen",
  "shared.forms.users.username": "E-Mail",
  "source.set_fail": "Der Wert konnte nicht aktualisiert werden",
  "source.set_success": "Update erfolgreich",
  "source.set_timeout": "Update abgelaufen",
  "source.validation_fail": "Ungültiges Update",
  "substitutions.are_parallel_courses": "sind parallele Kurse",
  "substitutions.is_a_parallel_course": "ist ein paralleler Kurs",
  "table.itemsPerPageLabel": "Zeilen pro Seite",
  "table.nextPageLabel": "Nächste Seite",
  "table.previousPageLabel": "Vorherige Seite",
  "tables.admin-tables.divisions.actions.columns.displayName": "Name",
  "tables.admin-tables.divisions.actions.columns.id": "ID",
  "tables.admin-tables.divisions.actions.columns.organization": "Organisation",
  "tables.admin-tables.divisions.actions.delete": "Plan löschen",
  "tables.admin-tables.divisions.actions.delete.failure": "Plan konnte nicht gelöscht werden",
  "tables.admin-tables.divisions.actions.download": "Plan herunterladen",
  "tables.admin-tables.divisions.actions.login": "Bei dem Plan anmelden",
  "tables.admin-tables.divisions.actions.login.failure": "Anmeldung fehlgeschlagen",
  "tables.admin-tables.organizations.actions.add": "Organisation erstellen",
  "tables.admin-tables.organizations.actions.configure_environment": "Berechnungsumgebung konfigurieren",
  "tables.admin-tables.organizations.actions.configure_identifiers": "Identifikatoren konfigurieren",
  "tables.admin-tables.organizations.actions.delete": "Organisation löschen",
  "tables.admin-tables.organizations.actions.delete.failure": "Organisation konnte nicht gelöscht werden",
  "tables.admin-tables.organizations.actions.edit": "Organisation bearbeiten",
  "tables.admin-tables.organizations.actions.generate_access_key": "Zugriffsschlüssel generieren",
  "tables.admin-tables.organizations.actions.login": "Bei Organisation anmelden",
  "tables.admin-tables.organizations.actions.login.failure": "Anmeldung fehlgeschlagen",
  "tables.admin-tables.organizations.add-edit.actions.add.failure": "Organisation konnte nicht erstellt werden",
  "tables.admin-tables.organizations.add-edit.labels.name": "Name",
  "tables.admin-tables.organizations.add-edit.labels.organization": "Übergeordnete Organisation",
  "tables.admin-tables.organizations.add-edit.labels.organization_type": "Organisationstyp",
  "tables.admin-tables.organizations.add-edit.labels.school_code": "Schuleinheitscode",
  "tables.admin-tables.organizations.add-edit.labels.theme": "Thema",
  "tables.admin-tables.organizations.add-edit.title.add": "Organisation erstellen",
  "tables.admin-tables.organizations.add-edit.title.edit": "Organisation bearbeiten",
  "tables.admin-tables.organizations.columns.divisions": "Anzahl Stundenpläne",
  "tables.admin-tables.organizations.columns.environment": "Umgebung",
  "tables.admin-tables.organizations.columns.identifiers": "Anzahl Identifikatoren",
  "tables.admin-tables.organizations.columns.name": "Name",
  "tables.admin-tables.organizations.columns.organization": "Übergeordnete Organisation",
  "tables.admin-tables.organizations.columns.organizationType": "Organisationstyp",
  "tables.admin-tables.organizations.columns.theme": "Thema",
  "tables.admin-tables.organizations.columns.version": "Algorithmusversion",
  "tables.admin-tables.organizations.configure-compute-environment.title": "Berechnungsumgebung konfigurieren",
  "tables.admin-tables.organizations.configure-identifiers.actions.add.failure": "Identifikator konnte nicht hinzugefügt werden",
  "tables.admin-tables.organizations.configure-identifiers.actions.delete.failure": "Identifikator konnte nicht gelöscht werden",
  "tables.admin-tables.organizations.configure-identifiers.identifier-types.school_code": "Schuleinheitscode",
  "tables.admin-tables.organizations.configure-identifiers.identifier-types.vat": "MwSt.-Nummer",
  "tables.admin-tables.organizations.configure-identifiers.title": "Identifikatoren konfigurieren",
  "tables.admin-tables.organizations.app_features.substitution_planner": "Vertretungsplanung aktivieren",
  "tables.admin-tables.organizations.app_features.title": "App-Funktionen",
  "tables.admin-tables.users.actions.add": "Benutzer erstellen",
  "tables.admin-tables.users.actions.copy_verification_link": "Aktivierungslink kopieren",
  "tables.admin-tables.users.actions.delete": "Benutzer löschen",
  "tables.admin-tables.users.actions.delete.failure": "Benutzer konnte nicht gelöscht werden",
  "tables.admin-tables.users.actions.edit": "Benutzer bearbeiten",
  "tables.admin-tables.users.actions.login": "Bei der Organisation des Benutzers anmelden",
  "tables.admin-tables.users.actions.login.failure": "Anmeldung fehlgeschlagen",
  "tables.admin-tables.users.actions.mail": "Aktivierungs-E-Mail an Benutzer senden",
  "tables.admin-tables.users.actions.mail.failure": "Aktivierungs-E-Mail konnte nicht gesendet werden",
  "tables.admin-tables.users.actions.mail.success": "Aktivierungs-E-Mail gesendet",
  "tables.admin-tables.users.add-edit.actions.add.failure": "Benutzer konnte nicht erstellt werden",
  "tables.admin-tables.users.add-edit.actions.fetch_companies.failure": "Unternehmen konnten nicht geladen werden",
  "tables.admin-tables.users.add-edit.actions.fetch_from_skolverket.failure": "Informationen von Skolverket konnten nicht abgerufen werden",
  "tables.admin-tables.users.add-edit.labels.organization": "Organisation",
  "tables.admin-tables.users.add-edit.labels.role": "Benutzerrolle",
  "tables.admin-tables.users.add-edit.labels.username": "Benutzername (E-Mail)",
  "tables.admin-tables.users.add-edit.title.add": "Benutzer erstellen",
  "tables.admin-tables.users.add-edit.title.edit": "Benutzer bearbeiten",
  "tables.admin-tables.users.columns.activation": "Aktivierungslink",
  "tables.admin-tables.users.columns.last_login": "Letzte Anmeldung",
  "tables.admin-tables.users.columns.online": "Online",
  "tables.admin-tables.users.columns.organization": "Organisation",
  "tables.admin-tables.users.columns.role": "Benutzerrolle",
  "tables.admin-tables.users.columns.username": "Benutzername",
  "tables.components.custom-search.empty": "Beachten Sie, dass die Deaktivierung der Suche für alle Spalten zu leeren Suchergebnissen führt.",
  "tables.components.empty.hint": "Erstellen Sie eine, indem Sie auf die \"+\" Schaltfläche in der oberen rechten Ecke klicken",
  "tables.components.empty.title": "Es existieren noch keine {{ entities }}",
  "tables.components.no-columns.hint": "Klicken Sie auf die \"{{ button }}\" Schaltfläche in der oberen rechten Ecke, um auszuwählen, welche Spalten angezeigt werden sollen",
  "tables.components.no-columns.title": "Keine sichtbaren Tabellenspalten",
  "tables.courses.values.overridden": "Der Wert wird nicht von allen KursUnterrichtsstunden geerbt",
  "tables.notifications.alerts.error.title": "Der Wert wird nicht automatisch von allen Unterrichtsstunden des Kurses übernommen",
  "tables.notifications.alerts.success.descr": "Wenn andere Registerkarten geöffnet sind, müssen diese neu geladen werden, damit die Änderungen wirksam werden.",
  "tables.notifications.alerts.success.title": "Einstellungen erfolgreich aktualisiert",
  "tables.notifications.columns.category.descr": "Benachrichtigungen innerhalb jeder Kategorie werden zusammen angezeigt.",
  "tables.notifications.columns.category.name": "Kategorie",
  "tables.notifications.columns.category.values.generalConfirmation": "Allgemeine Bestätigungen",
  "tables.notifications.columns.category.values.generalError": "Allgemeine Fehler",
  "tables.notifications.columns.category.values.inputAnalysisError": "Eingabeanalyse: kritische Fehler",
  "tables.notifications.columns.category.values.inputAnalysisNotice": "Eingabeanalyse: Hinweise",
  "tables.notifications.columns.category.values.inputAnalysisWarning": "Eingabeanalyse: Warnungen",
  "tables.notifications.columns.category.values.other": "Andere",
  "tables.notifications.columns.location.descr": "Wo auf dem Bildschirm die Benachrichtigungen dieser Kategorie angezeigt werden.",
  "tables.notifications.columns.location.name": "Ort",
  "tables.notifications.columns.location.values.bottom left": "Untere linke Ecke",
  "tables.notifications.columns.location.values.bottom right": "Untere rechte Ecke",
  "tables.notifications.columns.location.values.top left": "Obere linke Ecke",
  "tables.notifications.columns.location.values.top right": "Obere rechte Ecke",
  "tables.notifications.columns.mode.descr": "Zeigt an ob diese Benachrichtigungen eingeschaltet, stummgeschaltet oder ausgeschaltet sind.",
  "tables.notifications.columns.mode.name": "Modus",
  "tables.notifications.columns.mode.values.mute": "Stumm",
  "tables.notifications.columns.mode.values.off": "Aus",
  "tables.notifications.columns.mode.values.on": "An",
  "tables.notifications.columns.sound.descr": "Welcher Ton wird abgespielt, wenn eine Benachrichtigung empfangen wird.",
  "tables.notifications.columns.sound.name": "Ton",
  "tables.notifications.columns.test.descr": "Probieren Sie es aus, indem Sie eine Test-Benachrichtigung senden.",
  "tables.notifications.columns.test.name": "Test",
  "tables.notifications.columns.test.value": "senden",
  "tables.notifications.columns.volume.descr": "Die Lautstärke des Benachrichtigungston.",
  "tables.notifications.columns.volume.name": "Lautstärke",
  "validators.email": "Keine gültige E-Mail",
  "validators.equal": "Werte stimmen nicht überein",
  "validators.invalidDate": "Ungültiges Datum",
  "validators.invalidDiscretization": "Muss ein Vielfaches von 5 Minuten sein",
  "validators.invalidLaterDate": "Muss ein späteres Datum sein",
  "validators.invalidMultiple": "Muss ein Vielfaches von 5 sein",
  "validators.invalidTime": "Ungültige Zeit (5 Min. Intervalle)",
  "validators.matDatepickerMax": "Muss ein früheres Datum auswählen",
  "validators.matDatepickerMin": "Muss ein späteres Datum auswählen",
  "validators.matEndDateInvalid": "Ungültiger Datumsbereich",
  "validators.matStartDateInvalid": "Ungültiger Datumsbereich",
  "validators.max": "Dieser Wert ist zu hoch",
  "validators.min": "Dieser Wert ist zu niedrig",
  "validators.minLength": "Zu kurz",
  "validators.minlength": "Zu kurz",
  "validators.noMatch": "Falsche E-Mail oder falsches Passwort",
  "validators.outOfBounds": "Der Wert ist außerhalb der Grenzen",
  "validators.required": "Dieses Feld ist erforderlich",
  "validators.time_too_early": "zeit zu früh",
  "validators.time_too_late": "zeit zu spät",
  "validators.usernameOccupied": "E-Mail ist besetzt",
  "view.administrator.organizations.table.add": "Schule erstellen",
  "view.administrator.organizations.table.createdAt": "Erstellt am",
  "view.administrator.organizations.table.header": "Schulen",
  "view.administrator.organizations.table.login": "Geben Sie diese Schule ein",
  "view.administrator.organizations.table.name": "Name",
  "view.administrator.page.menu.organizations": "Meine Schulen",
  "view.administrator.users.table.add": "Benutzer erstellen",
  "view.administrator.users.table.administrator": "Administrator",
  "view.administrator.users.table.company": "Schule / Organisation",
  "view.administrator.users.table.createdAt": "Erstellt am",
  "view.administrator.users.table.header": "Benutzerkonten",
  "view.administrator.users.table.login": "Melden Sie sich in dieser Schule an",
  "view.administrator.users.table.mail.fail": "Der Aktivierungslink konnte dem Benutzer nicht gesendet werden",
  "view.administrator.users.table.mail.success": "Aktivierungslink an Benutzer gesendet",
  "view.administrator.users.table.mail.tooltip": "Aktivierungslink an Benutzer senden",
  "view.administrator.users.table.restricted": "Eingeschränkt",
  "view.administrator.users.table.role": "Benutzerrolle",
  "view.administrator.users.table.unrestricted": "Stundenplaner",
  "view.administrator.users.table.username": "E-Mail",
  "view.demo.schedule.components.editor_contact_sub_title": "Um mehr über unser Produkt zu erfahren, geben Sie Ihre E-Mail-Adresse ein, und wir werden Sie mit weiteren Informationen kontaktieren.",
  "view.demo.schedule.components.editor_contact_title": "Der Stundenplan ist fertig",
  "view.demo.schedule.components.introduction_contact_submit": "Ich bin interessiert",
  "view.demo.schedule.components.introduction_slide_1": "Willkommen!",
  "view.demo.schedule.components.introduction_slide_2": "Wir haben die Stundenplan-Daten für eine mittelgroße schwedische Schule vorbelegt.",
  "view.demo.schedule.components.introduction_slide_3": "Das Einzige, was noch übrig ist, ist, auf \"automatisches Planen\" zu klicken, ein paar Minuten zu warten, und Sie haben Ihren ZeitplanStundenplan erstellt.",
  "view.demo.schedule.page.exit": "Demo beenden",
  "view.demo.schedule.page.restart": "Demo neu starten",
  "view.partner.page.menu.divisions": "Stunden&shy;pläne",
  "view.partner.page.menu.organizations": "Organisa&shy;tionen",
  "view.partner.page.menu.users": "Benutzer",
  "view.private.integrations.in.connectionTo": "Verbindung zu",
  "view.private.integrations.in.descr": "Unten finden Sie mögliche API-Integrationen zum Austausch von Informationen mit externen Dienstleistern.",
  "view.private.integrations.in.error.get": "Stundenplan-Daten konnten nicht abgerufen werden. Bitte kontaktieren Sie das Support-Team, wenn das Problem weiterhin besteht.",
  "view.private.integrations.in.error.postOrPatch": "Die Integration-Details konnten nicht gespeichert werden. Bitte kontaktieren Sie das Support-Team, wenn das Problem weiterhin besteht.",
  "view.private.integrations.in.import": "Daten importieren",
  "view.private.integrations.in.menu": "Integrationen",
  "view.private.integrations.in.no_integrations": "Keine Integrationen",
  "view.private.integrations.in.SchoolSoftDialog.tab1.title": "Name und Wochenschema",
  "view.private.integrations.in.SchoolSoftDialog.tab2.descr": "Es ist derzeit nicht möglich, Räume direkt über die SchoolSoft-API zu importieren. Bis dies jedoch gelöst ist, können sie durch Kopieren und Einfügen der entsprechenden Daten in einem Tab- oder Komma-getrennten Format importiert werden, die Sie <a href=\"https://sms.schoolsoft.se/{{webapp}}/jsp/admin/right_admin_import_room.jsp?menu=admin_import_room\" target=\"_blank\">hier</a> finden.",
  "view.private.integrations.in.SchoolSoftDialog.tab2.error": "Der Text kann nicht analysiert werden",
  "view.private.integrations.in.SchoolSoftDialog.tab2.label": "Fügen Sie den Tabellen-Text hier ein",
  "view.private.integrations.in.SchoolSoftDialog.tab2.title": "Räume importieren",
  "view.private.integrations.in.setupDialog.key": "API-Schlüssel",
  "view.private.integrations.in.setupDialog.meta.schoolapp": "schoolapp",
  "view.private.integrations.in.setupDialog.meta.schoolId": "Schul-ID",
  "view.private.integrations.in.setupDialog.meta.webapp": "webapp",
  "view.private.integrations.in.setupDialog.startsWith": "Beginnt mit {{value}}",
  "view.private.integrations.in.setupDialog.title": "Integration-Details",
  "view.private.integrations.in.success": "Der Stundenplan wurde erfolgreich importiert",
  "view.private.integrations.in.title": "Integrationen über API",
  "view.private.integrations.in.tooltip.delete": "Integration-Details löschen",
  "view.private.integrations.in.tooltip.modify": "Integration-Details ändern",
  "view.private.integrations.in.validation.error.401": "Ungültige Integration-Details",
  "view.private.integrations.in.validation.error.404": "Ungültige Integration-Details",
  "view.private.integrations.in.validation.error.500": "Die Integration-Details konnten nicht überprüft werden",
  "view.private.integrations.in.validation.error.SchoolSoft.key": "Ungültiger API-Schlüssel",
  "view.private.integrations.in.validation.error.SchoolSoft.schoolapp": "Ungültiger schoolapp-Wert",
  "view.private.integrations.in.validation.error.SchoolSoft.webapp": "Ungültiger webapp-Wert",
  "view.private.integrations.in.validation.error.unknown": "Die Integration-Details konnten nicht überprüft werden",
  "view.private.integrations.in.validation.valid": "Gültige Integration-Details",
  "view.private.integrations.in.validation.validating": "Integration-Details werden überprüft",
  "view.private.menu.schema": "Stundenpläne",
  "view.private.menu.settings": "Einstellungen",
  "view.private.menu.tutorial": "Tutorial",
  "view.private.menu.users": "Benutzer",
  "view.private.schema.components.admentum.subtitle": "Exportieren nach zu Admentum",
  "view.private.schema.components.failed-generation-dialog.description": "Bei der automatischen Planung ist ein Fehler aufgetreten und wir arbeiten an einer Lösung. Bitte versuchen Sie es später am Tag erneut oder kontaktieren Sie den Support, wenn Sie benachrichtigt werden möchten, wenn das Problem behoben ist.",
  "view.private.schema.components.failed-generation-dialog.title": "Die automatische Planung ist fehlgeschlagen",
  "view.private.schema.components.subtitle": "Exportieren nach zu",
  "view.private.schema.components.idunsoft.subtitle": "Exportieren nach zu IdunSoft",
  "view.private.schema.components.infomentor.subtitle": "Exportieren nach zu InfoMentor",
  "view.private.schema.components.link.subtitle": "Kopieren Sie den Link zu diesem Stundenplan",
  "view.private.schema.components.link.success": "Link kopiert",
  "view.private.schema.components.link.title": "Web-Viewer",
  "view.private.schema.components.royalschedule.subtitle": "Exportieren nach zu Royal Schedule",
  "view.private.schema.components.schoolsoft_displayName.subtitle": "Exportieren nach zu SchoolSoft mit Namen als Referenzen",
  "view.private.schema.components.schoolsoft.subtitle": "Exportieren nach zu SchoolSoft",
  "view.private.schema.components.table.actions": "Aktionen",
  "view.private.schema.components.table.basis": "Stundenplan-Daten",
  "view.private.schema.components.table.clone": "Stundenplan duplizieren",
  "view.private.schema.components.table.createdAt": "Erstellt",
  "view.private.schema.components.table.editedAt": "Zuletzt bearbeitet",
  "view.private.schema.components.table.header": "Meine Stundenpläne",
  "view.private.schema.components.table.interval": "Dauer",
  "view.private.schema.components.table.link": "Link zum Web-Viewer",
  "view.private.schema.components.table.name": "Stundenplan-Name",
  "view.private.schema.components.table.public": "Öffentlich",
  "view.private.schema.edit.toolbar.colorDescription": "Farbbeschreibung",
  "view.private.schema.edit.toolbar.selectedPeriods": "Einbezogene Wochenschemas",
  "view.private.schema.editor.components.user-manual.title": "Benutzerhandbuch",
  "view.private.schema.page.create": "Stundenplan erstellen",
  "view.private.schema.page.download_fail": "Zeitplan konnte nicht heruntergeladen werden",
  "view.private.schema.page.header": "Stundenpläne",
  "view.private.schema.page.sync": "Daten synchronisieren",
  "view.private.schema.page.upload": "Datei hochladen",
  "view.private.schema.sub-pages.schema-editor.components.event-form.fix-start": "Den Tag und die Startzeit fixieren",
  "view.private.schema.sub-pages.schema-editor.components.event-form.force-overlap": "Unterrichtsstunden verbinden",
  "view.private.schema.sub-pages.schema-editor.components.event-form.make-invisible": "In Schedule Viewer ausblenden",
  "view.private.schema.sub-pages.schema-editor.components.event-form.make-visible": "In Schedule Viewer anzeigen",
  "view.private.schema.sub-pages.schema-editor.components.event-form.park": "Parken",
  "view.private.schema.sub-pages.schema-editor.components.event-form.remove-force-overlap": "Unterrichtsstunden trennen",
  "view.private.schema.sub-pages.schema-editor.components.event-form.unfix-start": "Den Tag und die Startzeit lösen",
  "view.private.schema.sub-pages.schema-editor.components.list.filter": "Unterrichtsstunden filtern",
  "view.private.schema.sub-pages.schema-editor.components.list.filter.not_placed": "Geparkte Unterrichtsstunden",
  "view.private.schema.sub-pages.schema-editor.components.list.filter.period": "Nur einbezogene Wochenschemas",
  "view.private.schema.sub-pages.schema-editor.components.list.filter.placed": "Platzierte Unterrichtsstunden",
  "view.private.schema.sub-pages.schema-editor.components.progress.info_PENDING": "Der Stundenplanungsprozess wurde in die Warteschlange gestellt. Er wird in ein paar Minuten starten",
  "view.private.schema.sub-pages.schema-editor.components.progress.info_STARTED": "Der Stundenplanungsprozess hat begonnen. Es kann ein paar Minuten dauern, bis er abgeschlossen ist.",
  "view.private.schema.sub-pages.schema-editor.page.limited_state": "Kollisionserkennung ist deaktiviert",
  "view.private.schema.sub-pages.schema-editor.page.start_job": "Automatische Stundenplanung starten",
  "view.private.schema.sub-pages.settings.components.default-values-form.fieldset.break-lengths": "Minimale Pausenlänge",
  "view.private.schema.sub-pages.settings.components.default-values-form.fieldset.other": "Andere",
  "view.private.schema.sub-pages.settings.components.default-values-form.fieldset.time-frame": "Zeitrahmen",
  "view.private.schema.sub-pages.settings.components.default-values-form.fieldset.working-hours": "Arbeitszeiten",
  "view.private.schema.sub-pages.settings.components.options-form.fieldset.overlappable-lessons-set": "Verbundene Unterrichtsstunden",
  "view.private.schema.sub-pages.settings.components.options-form.requireForcedOverlappingEventsSets.description": "Wenn aktiviert, müssen Unterrichtsstunden, die zu parallelen Kursen gehören, verbunden werden. Unterrichtsstunden, die verbunden sind, werden nach der Erstellung eines Stundenplans zu derselben Zeit geplant. Unterrichtsstunden, die zu parallelen Kursen gehören und <b>nicht</b> verbunden sind, werden normalerweise zu derselben Zeit geplant, aber dieses Ergebnis ist nach der Erstellung eines Stundenplans nicht garantiert.",
  "view.private.schema.sub-pages.settings.components.options-form.requireForcedOverlappingEventsSets.label": "Verbundene Unterrichtsstunden erfordern",
  "view.private.schema.sub-pages.settings.page.tab.default-values": "Standardwerte",
  "view.private.schema.sub-pages.settings.page.tab.options": "Optionen",
  "view.private.schema.sub-pages.settings.page.tab.periods": "Wochenschemas",
  "view.private.schema.sub-pages.settings.page.tab.rootIntervals": "Zeitraster",
  "view.private.schema.sub-pages.statistics.duty-assignments.empty": "Keine Daten verfügbar",
  "view.private.schema.sub-pages.statistics.duty-assignments.header": "Dienstzuweisungen",
  "view.private.schema.sub-pages.statistics.duty-assignments.min_of_min_per_week": "{{current}} von {{total}}  min/Woche",
  "view.private.schema.sub-pages.statistics.duty-assignments.min_per_week": "Min/Woche",
  "view.private.schema.sub-pages.tooltips.locked": "Während ein Stundenplan automatisch erstellt wird, ist er für Änderungen gesperrt.",
  "view.private.settings.language.label": "Sprache",
  "view.private.settings.language.title": "Sprache",
  "view.private.settings.menu.sync": "Sync",
  "view.private.settings.sync.button.select-schedule": "Stundenplan auswählen",
  "view.private.settings.sync.components.table.sync_toggle": "Sync an",
  "view.private.settings.sync.descr": "Mit den untenstehenden Funktionen können Sie externe Plattformen aktivieren und deaktivieren, um Ihre Daten zu synchronisieren. Wenn die Synchronisierung aktiviert ist, werden Ihre Daten an die Plattform(en) gesendet, die Sie erlaubt haben. Dies kann jederzeit deaktiviert werden.",
  "view.private.settings.sync.in": "Stundenpläne, die Aktualisierungen von externen Plattformen abrufen",
  "view.private.settings.sync.no-selected-out": "Wählen Sie einen Stundenplan aus, auf den Ihre externen Plattformen zugreifen können.",
  "view.private.settings.sync.out": "Active Stundenplan",
  "view.private.settings.sync.tab.add-platforms": "Eine Plattform hinzufügen",
  "view.private.settings.sync.tab.my-platforms": "Meine Plattformen",
  "view.private.settings.sync.title": "Plattformintegrationen",
  "view.private.settings.sync.tooltip.REJECTED": "Die Anforderung, eine Synchronisierung zwischen unseren Plattformen zu erstellen, wurde abgelehnt. Wenden Sie sich bei einem Fehler an das Support-Team.",
  "view.private.tutorial.page.advanced": "Fortgeschritten",
  "view.private.tutorial.page.basic": "Grundlagen",
  "view.private.tutorial.page.description.2.1": "Lehrerbeschränkungen",
  "view.private.tutorial.page.description.2.2": "Erstellen Sie Unterrichtsstunden und Längenvariationen",
  "view.private.tutorial.page.description.2.3": "Erlaubte Räume hinzufügen",
  "view.private.tutorial.page.description.2.4": "Parallele Kurse und verknüpfte Unterrichtsstunden",
  "view.private.tutorial.page.description.2.5": "Analyse von Stundenplandaten",
  "view.private.tutorial.page.description.2.6": "Iterative Anpassungen",
  "view.private.tutorial.page.description.api": "Daten synchronisieren",
  "view.private.tutorial.page.description.bulk-update": "Lernen Sie, wie Sie in Massen bearbeiten",
  "view.private.tutorial.page.description.courses": "Lernen Sie, wie Sie Kurse und Veranstaltungen hinzufügen",
  "view.private.tutorial.page.description.create": "Stundenplan erstellen",
  "view.private.tutorial.page.description.excel": "Laden Sie eine Excel-Datei hoch",
  "view.private.tutorial.page.description.groups": "Lernen Sie, wie Sie Klassen hinzufügen",
  "view.private.tutorial.page.description.in-locations": "Lernen Sie, wie Sie erlaubte Räume ändern",
  "view.private.tutorial.page.description.locations": "Lernen Sie, wie Sie Räume hinzufügen",
  "view.private.tutorial.page.description.locked-times": "Lernen Sie, wie Sie verbotene Zeitintervalle hinzufügen",
  "view.private.tutorial.page.description.teacher": "Lernen Sie, wie Sie Lehrer hinzufügen",
  "view.private.tutorial.page.description.time-frame": "Verwalten Sie den Zeitrahmen der Stundenpläne",
  "view.private.tutorial.page.description.upload_json": "RS-Datei hochladen",
  "view.private.tutorial.page.filter.category": "Kategorie",
  "view.private.tutorial.page.filter.category.adjustments": "Anpassungen",
  "view.private.tutorial.page.filter.category.conditions": "Bedingungen",
  "view.private.tutorial.page.filter.category.data": "Stundenplandaten",
  "view.private.tutorial.page.filter.category.edit": "Manuelle Planung",
  "view.private.tutorial.page.filter.category.education": "Bildung",
  "view.private.tutorial.page.filter.category.export": "Stundenplandaten exportieren",
  "view.private.tutorial.page.filter.category.import": "Stundenplandaten importieren",
  "view.private.tutorial.page.filter.category.inputAnalyzer": "Eingang Analysator",
  "view.private.tutorial.page.filter.category.other": "Sonstiges",
  "view.private.tutorial.page.filter.category.settings": "Einstellungen",
  "view.private.tutorial.page.filter.category.warnings": "Warnungen",
  "view.private.tutorial.page.filter.level": "Schwierigkeit",
  "view.private.tutorial.page.filter.level.advanced": "Fortgeschritten",
  "view.private.tutorial.page.filter.level.beginner": "Anfänger",
  "view.private.tutorial.page.filter.level.intermediate": "Mittel",
  "view.private.tutorial.page.header": "Tutorial",
  "view.private.tutorial.page.type.video": "Videoguide",
  "view.public.activation.approved": "Genehmigt",
  "view.public.activation.expired": "Link abgelaufen",
  "view.public.activation.invalid": "Ungültiger Link",
  "view.public.activation.verifying": "Überprüfung...",
  "view.public.demo.valid_config": "Umgebung konfigurieren",
  "view.public.login.fail": "Anmeldung fehlgeschlagen",
  "view.public.login.password": "Passwort",
  "view.public.login.status_email_required": "Gültige E-Mail erforderlich",
  "view.public.login.status_invalid_credentials": "Ungültige Anmeldeinformationen",
  "view.public.login.status_invalid_email_format": "Ungültiges E-Mail-Format",
  "view.public.login.status_password_required": "Passwort erforderlich",
  "view.public.login.status_reset_password_fail": "Der Link zum Zurücksetzen kann nicht gesendet werden",
  "view.public.login.status_reset_password_success": "Der Link zum Zurücksetzen wurde an {{email}} gesendet, wenn ein solcher Benutzer existiert",
  "view.public.login.username": "E-Mail",
  "view.public.web-viewer.not_public": "Der Stundenplan ist nicht öffentlich",
  "view.void.status": "Anmeldeinformationen überprüfen",
  "account.advanced.menu": "Erweitert",
  "account.advanced.page.title": "Erweitert",
  "account.advanced.page.descr": "Erweiterte Einstellungen und Aktionen, die manchmal nützlich sein können.",
  "account.advanced.page.clear-all-and-reload.label": "Alle Daten löschen und neu laden",
  "account.advanced.page.clear-all-and-reload.description": "Dadurch werden alle Anwendungsdaten gelöscht, alle Arbeiter beendet und schließlich alle geöffneten Registerkarten der Anwendung gleichzeitig neu geladen. Dies kann nützlich sein, wenn Sie Probleme mit der Anwendung haben. Beachten Sie, dass dadurch Ihre Authentifizierungssitzung beendet wird und Sie sich erneut anmelden müssen.",
  "account.advanced.page.clear-all-and-reload.action": "Löschen und neu laden",
  "view.private.schema.sub-pages.examine.page.before_select": "Wählen Sie die Gruppen, Lehrer und Räume aus, die Sie vergleichen möchten. Sie können auch den Tag und die Woche auswählen, die Sie anzeigen möchten.",
  "common.show_remaining": "Zeige verbleibende",
  "common.from_time": "Von",
  "common.to_time": "Bis",
  "shared.dialogs.edit-course-and-event.title": "Bearbeiten",
  "common.parallel_courses": "Parallele Kurse",
  "common.open_tutorial": "Tutorial öffnen",
  "common.second": "Sekunde",
  "common.second_short": "s",
  "shared.components.svg-schedule.generating.title": "Erstellen von druckbaren Stundenplänen",
  "shared.components.svg-schedule.generating.completed_n_of_m": "{{n}} von {{m}} abgeschlossen",
  "shared.components.svg-schedule.generating.estimated_time_remaining": "Voraussichtliche verbleibende Zeit: {{time}}",
  "schedule.print.page.toolbar.print_date.label": "Druckdatum",
  "schedule.print.page.toolbar.lockedTimes.label": "Verbotene Zeiten",
  "schedule.print.page.toolbar.print_date.descr": "Das aktuelle Datum und die aktuelle Uhrzeit.",
  "schedule.print.page.toolbar.lockedTimes.descr": "Ob verbotene Zeiten enthalten sein sollen oder nicht.",
  "schedule.print.page.page.before_select": "Wählen Sie die Gruppen, Lehrer und Räume aus, deren Stundenplan Sie drucken möchten.",
  "schedule.print.page.toolbar.time_frame.label": "Zeitrahmen",
  "schedule.print.page.toolbar.time_frame.descr": "Zeichnet den Zeitrahmen und die erlaubten Tage.",
  "schedule.print.page.toolbar.fit_text.label": "Text anpassen",
  "schedule.print.page.toolbar.fit_text.descr": "Versucht, den Text durch Verringerung der Schriftgröße anzupassen.",
  "inputAnalysis.issues.overloaded group aborted.title": "Es ist nicht möglich zu entscheiden, ob {{the-entity}} mit Unterrichtsstunden überlastet ist.",
  "inputAnalysis.issues.overloaded group aborted.description": "Die Berechnung wurde abgebrochen, da sie zu lange dauerte.",
  "common.date_range": "Datumsbereich",
  "inputAnalysis.notices.overflowing_holiday.title": "{{The-entity}} liegt teilweise außerhalb des Zeitraums des Stundenplans.",
  "inputAnalysis.notices.overflowing_holiday.description": "Haben Sie vergessen, den Feiertag nach der Änderung des Zeitraums des Stundenplans zu aktualisieren?",
  "common.the_holiday": "der Feiertag",
  "common.the_holidays": "die Feiertage",
  "common.holiday": "Feiertag",
  "common.holidays": "Feiertage",
  "view.private.schema.sub-pages.settings.page.tab.holidays": "Feiertage",
  "view.private.schema.sub-pages.settings.page.tab.holidays.dialog.add.title": "Feiertag hinzufügen",
  "view.private.schema.sub-pages.settings.page.tab.holidays.dialog.edit.title": "Feiertag bearbeiten",
  "inputAnalysis.etc.modify_holiday": "Feiertag bearbeiten",
  "common.printed": "Gedruckt",
  "schedule.print.page.toolbar.display_dates.label": "Anzeigedaten",
  "schedule.print.page.toolbar.display_dates.descr": "Eine bestimmte Woche muss ausgewählt werden.",
  "common.delete_all": "Alle löschen",
  "schedule.navigation.daysCount": "Tage zählen",
  "common.whole": "Ganz",
  "view.private.schema.sub-pages.statistics.days-count.caption": "Die Tabelle zeigt, wie oft ein Tag in jedem Zeitraum vorhanden ist und auch ihre Gesamtzahl an Tagen. Der Zeitraum \"{{ whole }}\" bezieht sich auf den Zeitraum, der durch das Start- und Enddatum des Stundenplans bestimmt wird.",
  "dialogs.create-courses.using-excel.label": "Excel",
  "dialogs.create-courses.components.excel.instructions": "Laden Sie zunächst die Excel-Vorlage herunter, die wir mit Ihren vorhandenen Stundenplandaten (außer Kursen) vorbereitet haben. Geben Sie dann in der Registerkarte Kurs die Kurse ein, die Sie erstellen möchten. Wenn Sie zufrieden sind, speichern Sie die Datei und laden Sie sie hier erneut hoch, um einen Überblick über die zu erstellenden Kurse sowie eine Liste der aufgetretenen Fehler zu erhalten.",
  "shared.services.excel-template.errors.invalid_file_type": "Ungültiger Dateityp. Wir unterstützen nur Dateien der Typen {{ types }}.",
  "shared.services.excel-template.errors.unable_to_parse": "Die Datei kann nicht analysiert werden. Bitte kontaktieren Sie das Support-Team.",
  "shared.services.excel-template.errors.incorrect_mapping": "Ungültiger Zuordnungstyp. Bitte kontaktieren Sie das Support-Team.",
  "shared.services.excel-template.errors.unable_to_map": "Die Zuordnung von Stundenplandaten ist fehlgeschlagen. Bitte kontaktieren Sie das Support-Team.",
  "maps.excel.messages.missing_settings_sheet": "Die Einstellungen konnten nicht in der Excel-Datei gefunden werden.",
  "maps.excel.messages.missing_locations_sheet": "Die Räume konnten nicht in der Excel-Datei gefunden werden.",
  "maps.excel.messages.missing_groups_sheet": "Die Klassen konnten nicht in der Excel-Datei gefunden werden.",
  "maps.excel.messages.missing_teachers_sheet": "Die Lehrer konnten nicht in der Excel-Datei gefunden werden.",
  "maps.excel.messages.missing_persons_sheet": "Die Schüler konnten nicht in der Excel-Datei gefunden werden.",
  "maps.excel.messages.missing_courses_sheet": "Die Kurse konnten nicht in der Excel-Datei gefunden werden.",
  "maps.excel.messages.ignoring_duplicate_location": "Zwei oder mehr Räume mit dem Namen \"{{ context }}\" gefunden. Ignoriere alle außer dem ersten.",
  "maps.excel.messages.ignoring_duplicate_group": "Zwei oder mehr Klassen mit dem Namen \"{{ context }}\" gefunden. Ignoriere alle außer dem ersten.",
  "maps.excel.messages.ignoring_duplicate_teacher": "Zwei oder mehr Lehrer mit dem Namen \"{{ context }}\" gefunden. Ignoriere alle außer dem ersten.",
  "maps.excel.messages.ignoring_duplicate_person": "Zwei oder mehr Schüler mit dem Namen \"{{ context }}\" gefunden. Ignoriere alle außer dem ersten.",
  "maps.excel.messages.ignoring_duplicate_course": "Zwei oder mehr Kurse mit dem Namen \"{{ context }}\" gefunden. Ignoriere alle außer dem ersten.",
  "maps.excel.messages.invalid_group_reference": "Die Klasse mit dem Namen \"{{ context }}\" konnte nicht gefunden werden.",
  "maps.excel.messages.invalid_location_reference": "Der Raum mit dem Namen \"{{ context }}\" konnte nicht gefunden werden.",
  "maps.excel.messages.invalid_teacher_reference": "Der Lehrer mit dem Namen \"{{ context }}\" konnte nicht gefunden werden.",
  "maps.excel.messages.location_referenced_multiple_times": "Der Raum \"{{ context }}\" wird mehrmals aus demselben Kurs referenziert",
  "maps.excel.messages.teacher_referenced_multiple_times": "Der Lehrer \"{{ context }}\" wird mehrmals aus demselben Kurs referenziert",
  "maps.excel.messages.group_referenced_multiple_times": "Die Klasse \"{{ context }}\" wird mehrmals aus demselben Kurs referenziert",
  "maps.excel.messages.invalid_number_of_days": "Ungültiger Wert \"{{ context }}\" für die Anzahl der Tage des Stundenplans angegeben. Der Wert muss 5 (Montag-Freitag) oder 7 (Montag-Sonntag) sein.",
  "maps.excel.messages.invalid_sex": "Ungültiger Wert \"{{ context }}\" als Geschlecht angegeben. Der Wert muss \"Man\" oder \"Woman\" sein.",
  "maps.excel.messages.invalid_total_hours": "Ungültiger Wert \"{{ context }}\" als Gesamtzahl der Stunden angegeben. Der Wert muss eine positive Ganzzahl sein.",
  "maps.excel.messages.invalid_minutes_per_week": "Ungültiger Wert \"{{ context }}\" als Anzahl der Minuten pro Woche angegeben. Der Wert muss ein positives Vielfaches von 5 sein.",
  "maps.excel.messages.invalid_lesson_durations": "Ungültiger Wert \"{{ context }}\" als Unterrichtsdauer angegeben. Der Wert muss eine oder mehrere durch Kommas getrennte Zahlen sein, wobei jede Zahl ein positives Vielfaches von 5 ist.",
  "shared.services.excel-template.template.readonly": "Nicht bearbeitbar",
  "attributes.shared.ids": "Fremde ID",
  "attributeDescriptions.shared.ids": "Eine eindeutige fremde ID, die für Exportzwecke nützlich ist",
  "attributeDescriptions.shared.createdAt": "Das Datum und die Uhrzeit, zu denen die Entität erstellt wurde",
  "attributeDescriptions.teachers.signature": "Die Unterschrift des Lehrers",
  "account.workspace.page.displayPublicId.descr": "Die fremde ID einer Entität (Kurs, Unterrichtsstunde, Lehrer usw.) ist wichtig, wenn Daten auf eine andere Plattform exportiert werden. Wenn die Stundenplandaten in Royal Schedule importiert wurden, sollte der Wert der fremden ID im Allgemeinen nicht geändert werden, da dies zu Datenkonflikten führen kann, wenn der Stundenplan exportiert wird. Für unerfahrene Benutzer empfehlen wir daher, die fremde ID auszublenden.",
  "account.workspace.page.displayPublicId.label": "Zeige fremde ID",
  "maps.excel.messages.forbidden_group_reference": "Die Klasse \"{{ context }}\" wird als Lehrgruppe verwendet, ist aber eine Klasse.",
  "dialogs.create-courses.components.excel.warnings.no_courses": "Keine Kurse in der Excel-Datei gefunden.",
  "schedule.exceptions.new.title": "Neue Abwesenheitsnotiz",
  "schedule.exceptions.new.type-select": "Typ",
  "schedule.exceptions.new.date-select": "Datum",
  "schedule.exceptions.new.absentee-select": "Abwesender",
  "view.private.schema.components.excel.subtitle": "Stundenplandaten im Excel-Format exportieren",
  "view.private.schema.components.additio.subtitle": "Exportieren nach Additio",
  "common.groups.species.class": "Klassen",
  "common.groups.species.none": "Lehrgruppen",
  "common.the_groups.species.none": "Die Lehrgruppen",
  "common.the_groups.species.class": "Die Klassen",
  "dialogs.create-courses.in-bulk.no_options": "Es gibt keine Klassen und Lehrer zur Auswahl. Erstellen Sie zuerst Klassen und Lehrer, bevor Sie Kurse in Massen erstellen.",
  "view.private.schema.sub-pages.schema-editor.components.event-form.show_schedule": "Zeige Stundenplan",
  "common.minutes-per-week_short": "min/Woche",
  "common.minutes-per-week": "Minuten/Woche",
  "attributes.course.plannedDuration": "Geplante Dauer",
  "attributeDescriptions.course.plannedDuration": "Die geplante Dauer des Kurses in Minuten pro Woche oder insgesamt in Stunden. Der nicht spezifizierte Wert wird automatisch berechnet und aufgerundet.",
  "inputAnalysis.issues.plannedDuration.title": "{{The-entity}} erfüllt nicht seine geplante Dauer.",
  "inputAnalysis.issues.plannedDuration.description": "Die geplante Dauer beträgt <b>{{planned-value}}</b> Minuten/Woche, aber die bevorzugte Dauer der Veranstaltungen ergibt nur <b>{{actual-value}}</b> Minuten/Woche.",
  "schedule.guaranteedTuition.tabs.guaranteedTuition.table.planned_duration": "Geplante Dauer",
  "schedule.guaranteedTuition.tabs.guaranteedTuition.table.planned_duration_not_met": "Die geplante Dauer wird für einen oder mehrere Kurse, die durch diese Zeile repräsentiert werden, nicht erfüllt.",
  "schedule.guaranteedTuition.tabs.guaranteedTuition.table.actual_duration": "Tatsächliche Dauer",
  "tables.courses.values.plannedDuration.warning": "Die geplante Dauer wird nicht erfüllt.",
  "maps.excel.messages.ambiguous_planned_duration": "Sie können die geplante Dauer nicht gleichzeitig in Minuten/Woche und Stunden angeben. Bitte wählen Sie eine dieser Optionen: {{ context }}.",
  /* TO BE DEPRECATED */ "attributes.course.minutesPerWeek": "<s>Minuten/Woche</s>",
  /* TO BE DEPRECATED */ "attributes.course.expectedTotalHours": "<s>Erewartete Gesamtstunden</s>",
  /* TO BE DEPRECATED */ "attributeDescriptions.course.expectedTotalHours": "Ersetzt durch 'Geplante Dauer'.",
  /* TO BE DEPRECATED */ "attributeDescriptions.course.minutesPerWeek": "Ersetzt durch 'Geplante Dauer'.",
  "attributes.shared.tags": "Schlagwörter",
  "attributeDescriptions.shared.tags": "Schlagwörter werden verwendet, um zu kategorisieren und zu filtern.",
  "shared.form-fields.tags.input.placeholder": "Neues Schlagwort...",
  "validators.invalid": "Ungültiger Wert",
  "maps.excel.messages.duplicate_group_member": "Der Schüler \"{{ context }}\" ist mehr als einmal in der Gruppe aufgeführt.",
  "maps.excel.messages.invalid_person_reference": "Der Schüler mit der Sozialversicherungsnummer \"{{ context }}\" konnte nicht gefunden werden.",
  "maps.excel.messages.person_in_multiple_classes": "Der Schüler \"{{ context }}\" ist in mehreren Klassen zu finden.",
  "shared.dialogs.user-inactive.title": "Inaktivität erkannt",
  "shared.dialogs.user-inactive.description": "Es sieht so aus, als wären Sie eine Weile weg gewesen. Um alles reibungslos zu halten, klicken Sie bitte auf die Schaltfläche \"Neu laden\". Dadurch werden alle Ihre Registerkarten aktualisiert und sichergestellt, dass alles auf dem neuesten Stand ist.",
  "shared.views.schedule-viewer.before_select": "Wählen Sie aus, wessen Stundenplan Sie für welche Woche anzeigen möchten.",
  "shared.dialogs.overlappableEventSets.auto-link.description": "Automatisch verlinken",
  "shared.dialogs.overlappableEventSets.unable_to_auto-link.description": "Die Unterrichtsstunden können nicht automatisch verlinkt werden. Damit dies funktioniert, müssen die parallelen Kurse die gleiche Anzahl von Unterrichtsstunden der gleichen Länge enthalten und die Unterrichtsstunden dürfen nicht bereits vollständig verlinkt sein.",
  "shared.dialogs.overlappableEventSets.auto-link_N": "Verlinken {{ N }}",
  "shared.dialogs.overlappableEventSets.auto-link_N.description": "Verlinkt automatisch die Unterrichtsstunden für {{ N }} Sätze paralleler Kurse. Damit dies funktioniert, müssen die parallelen Kurse die gleiche Anzahl von Unterrichtsstunden der gleichen Länge enthalten und die Unterrichtsstunden dürfen nicht bereits vollständig verlinkt sein.",
  "shared.dialogs.overlappableEventSets.unable_to_auto-link_N.description": "Es gibt keine Sätze paralleler Kurse, die die Anforderungen erfüllen, dass ihre Unterrichtsstunden automatisch verlinkt werden. Damit dies funktioniert, müssen die parallelen Kurse die gleiche Anzahl von Unterrichtsstunden der gleichen Länge enthalten und die Unterrichtsstunden dürfen nicht bereits vollständig verlinkt sein.",
  "tables.courses.action.connect-courses": "Verbinden",
  "tables.courses.action.disconnect-courses": "Trennen",
  "maps.excel.messages.invalid_course_reference": "Der Kurs mit der ID \"{{ context }}\" konnte nicht gefunden werden.",
};

// PLEASE LEAVE
// translate the value of the commented out key-value pairs to the language of this file and based on its context
