import { Injectable                            } from '@angular/core';
import { BehaviorSubject,
         distinctUntilChanged,
         map,
         Observable,
         share,
         startWith                             } from 'rxjs';
import lodash                                    from 'lodash';
import deepdash                                  from 'deepdash-es';
const _ = deepdash(lodash);

import { apiConstants,
         storageConstants                      } from 'app/constants';
import { AuthService,
         EnvironmentService,
         HttpService,
         StorageService                        } from 'app/core';
import { Util                                  } from '@app/common';
import { Categories,
         defaultCategories                     } from '../push-notification/types';
import { LanguageConstant                      } from './../translate/types';


type DisplayChat            = { displayChat:            boolean          };
type DisplayPublicId        = { displayPublicId:        boolean          };
type DisplayEventsTable     = { displayEventsTable:     boolean          };
type DisplayTooltips        = { displayTooltips:        boolean          };
type DisplayPersonsTable    = { displayPersonsTable:    boolean          };
type BackgroundDataAnalysis = { backgroundDataAnalysis: boolean          };
type Language               = { language:               LanguageConstant };
type Notifications          = { notifications:          Categories       };

type DurationSets = { durationSets: number[][] };
const defaultDurationSets: number[][] = [
  [40],
  [60],
  [80],
  [40, 40],
  [60, 60],
  [80, 80],
  [40, 40, 60],
  [40, 60, 60],
  [40, 60, 80],
];
const maxNumDurationSets = 15;



export type ShortcutCategories = 'editor' | 'data';
type ShortcutEntry<category extends ShortcutCategories, id extends string, > = { [key in `${category}/${id}`]: string[]; };
export type EditorShortcuts =
    ShortcutEntry<'editor', 'parkEvent'>
  & ShortcutEntry<'editor', 'pinEvent'>
  & ShortcutEntry<'editor', 'hideEvent'>
  & ShortcutEntry<'editor', 'moveEventEarlier'>
  & ShortcutEntry<'editor', 'moveEventMuchEarlier'>
  & ShortcutEntry<'editor', 'moveEventLater'>
  & ShortcutEntry<'editor', 'moveEventMuchLater'>
  & ShortcutEntry<'editor', 'moveEventPrevDay'>
  & ShortcutEntry<'editor', 'moveEventNextDay'>
  & ShortcutEntry<'editor', 'multipleSelectEvents'>
  & ShortcutEntry<'editor', 'moveEventIndividually'>;
export type DataShortcuts = { }

type KeyboardShortcuts = { keyboardShortcuts:
  EditorShortcuts & DataShortcuts
};
const defaultKeyboardShortcuts: KeyboardShortcuts['keyboardShortcuts'] = {
  'editor/parkEvent':             ['p'],
  'editor/pinEvent':              ['f'],
  'editor/hideEvent':             ['h'],
  'editor/moveEventEarlier':      ['arrowup'],
  'editor/moveEventMuchEarlier':  ['shift', 'arrowup'],
  'editor/moveEventLater':        ['arrowdown'],
  'editor/moveEventMuchLater':    ['shift', 'arrowdown'],
  'editor/moveEventPrevDay':      ['arrowleft'],
  'editor/moveEventNextDay':      ['arrowright'],
  'editor/multipleSelectEvents':  ['shift'],
  'editor/moveEventIndividually': ['alt'],
}



type SingleUserPreference = DisplayChat
                          | DisplayPublicId
                          | DisplayEventsTable
                          | DisplayTooltips
                          | DisplayPersonsTable
                          | BackgroundDataAnalysis
                          | Language
                          | Partial<Notifications>
                          | DurationSets
                          | KeyboardShortcuts;

export type UserPreferences = DisplayChat
                            & DisplayPublicId
                            & DisplayEventsTable
                            & DisplayTooltips
                            & DisplayPersonsTable
                            & BackgroundDataAnalysis
                            & Language
                            & Notifications
                            & DurationSets
                            & KeyboardShortcuts;

// alphabetical order
const record: Record<LanguageConstant, string> =  {
  ca: 'ca',
  de: 'de',
  // el: 'el',
  en: 'en',
  es: 'es',
  fr: 'fr',
  sv: 'sv',
};
const inverseRecord = Object.fromEntries(Object.entries(record).map(([key, value]) => [value, key])) as Record<string, LanguageConstant>;
const defaultLanguage: LanguageConstant = 'en';

function mapLang (_lang: string): LanguageConstant {
  // a regex that catches the characters before the first hypen
  const langRegex = /([^-]*)/;
  let lang = _lang.toLowerCase().match(langRegex)?.[0];

  // map to the language constant
  return inverseRecord[lang ?? ''] ?? defaultLanguage;
}

@Injectable({
  providedIn: 'root'
})
export class UserPreferencesService {

  private preferences: BehaviorSubject<UserPreferences>;

  public readonly defaults: UserPreferences = Object.freeze({
    displayChat:            true,
    displayPublicId:        false,
    displayEventsTable:     false,
    displayTooltips:        true,
    displayPersonsTable:    this._environment.theme === 'royal_schedule' || this._environment.theme === 'schoolsoft' ? true : false,
    backgroundDataAnalysis: true,
    language:               mapLang(navigator.language),
    notifications:          defaultCategories,
    durationSets:           defaultDurationSets,
    keyboardShortcuts:      defaultKeyboardShortcuts,
  });

  constructor (
    private _auth:        AuthService,
    private _http:        HttpService,
    private _environment: EnvironmentService,
    private _storage:     StorageService
  ) {
    this.preferences = new BehaviorSubject(structuredClone(this.defaults))
    this._storage
    .watchStorage(storageConstants.USER_PREFERENCES)
    .pipe(
      startWith({ value: null }),
      map(({ value }) => {
        if ( ! value) return structuredClone(this.defaults);
        try {
          return JSON.parse(value) as UserPreferences;
        } catch {
          return null
        }
      })
    )
    .subscribe(this.preferences);


    ////
    //// update preferences when the authentication state is changed
    ////
    this._auth.onIsAuthenticated
    .pipe(distinctUntilChanged())
    .subscribe(isAuthenticated => {
      if (isAuthenticated) {
        // logged in: fetch and store the preferences
        this._http
        .get(`${ apiConstants.USERS }/preferences`)
        .subscribe({
          next: (val: UserPreferences) => { this.load(val); }
        });
      } else {
        // logged out: reset to default
        this._set(structuredClone(this.defaults));
      }
    });
  }


  private load (remotePreferences?: Partial<UserPreferences> | undefined) {
    // empty preferences
    if ( ! remotePreferences) return;

    // remove junk
    const cleanPreferences: Partial<UserPreferences> = _.pickDeep(remotePreferences ?? { }, _.paths(_.omit(structuredClone(this.defaults), 'keyboardShortcuts')) as string[]) ?? { };

    // fetch keyboard shortcuts separately to get entire array content
    cleanPreferences.keyboardShortcuts ??= structuredClone(this.defaults.keyboardShortcuts);
    if ('keyboardShortcuts' in remotePreferences) {
      Util.functions.objectKeys(defaultKeyboardShortcuts)
      .forEach(key => {
        if ( ! remotePreferences?.keyboardShortcuts?.[key]) return;
        cleanPreferences.keyboardShortcuts![key] = remotePreferences.keyboardShortcuts[key];
      });
    }

    // overrides
    // 1. pick only a limited number of durationSets as determined by defaultUserPreferences
    if ('durationSets' in remotePreferences) cleanPreferences.durationSets = remotePreferences.durationSets!;

    this._set(cleanPreferences);
  }

  // public watch (key?: keyof UserPreferences): Observable<UserPreferences> {
  //   return this.preferences;
  // }

  public watch<
    T extends keyof UserPreferences,
    R extends Observable<UserPreferences[T]>
  > (key: T): R {
    return this.preferences.pipe(map(preferences => preferences[key])/* , distinctUntilChanged() */) as R;
  }


  public get displayChat () { return this.preferences.value.displayChat; }
  public setDisplayChat (val: DisplayChat['displayChat']): Observable<void> {
    return this.set({ displayChat: val });
  }

  public get displayPublicId () { return this.preferences.value.displayPublicId; }
  public setDisplayPublicId (val: DisplayPublicId['displayPublicId']): Observable<void> {
    return this.set({ displayPublicId: val });
  }

  public get displayEventsTable () { return this.preferences.value.displayEventsTable; }
  public setDisplayEventsTable (val: DisplayEventsTable['displayEventsTable']): Observable<void> {
    return this.set({ displayEventsTable: val });
  }

  public get displayTooltips () { return this.preferences.value.displayTooltips; }
  public setDisplayTooltips (val: DisplayTooltips['displayTooltips']): Observable<void> {
    return this.set({ displayTooltips: val });
  }

  public get displayPersonsTable () { return this.preferences.value.displayPersonsTable; }
  public setDisplayPersonsTable (val: DisplayPersonsTable['displayPersonsTable']): Observable<void> {
    return this.set({ displayPersonsTable: val });
  }

  public get backgroundDataAnalysis () { return this.preferences.value.backgroundDataAnalysis; }
  public setBackgroundDataAnalysis (val: BackgroundDataAnalysis['backgroundDataAnalysis']): Observable<void> {
    return this.set({ backgroundDataAnalysis: val });
  }

  public get language () { return this.preferences.value.language; }
  public setLanguage (val: Language['language']): Observable<void> {
    return this.set({ language: val });
  }

  public get notifications () { return this.preferences.value.notifications; }
  public setNotifications (val: Notifications['notifications']): Observable<void> {
    return this.set({ notifications: val });
  }

  public get durationSets () { return this.preferences.value.durationSets; }
  public setDurationSets (val: DurationSets['durationSets']): Observable<void> {
    return this.set({ durationSets: val });
  }

  public get keyboardShortcuts () { return this.preferences.value.keyboardShortcuts; }
  public setKeyboardShortcuts (val: KeyboardShortcuts['keyboardShortcuts']): Observable<void> {
    return this.set({ keyboardShortcuts: val });
  }


  private _set (val: Partial<UserPreferences>) {
    // merge in new preference
    let updatedPreferences = {} as UserPreferences;
    _.merge(updatedPreferences, this.preferences.value, val);

    // overrides
    // 1. otherwise the durationSets array is merged with old one in a strange way
    if ('durationSets' in val) updatedPreferences.durationSets = val.durationSets!;

    // 2. pick all of the keyboard shortcuts arrays (the deepPick/merge might miss some)
    if ('keyboardShortcuts' in val) {
      Util.functions.objectKeys(defaultKeyboardShortcuts)
      .forEach(key => {
        if ( ! val.keyboardShortcuts?.[key]) return;
        updatedPreferences.keyboardShortcuts[key] = val.keyboardShortcuts[key]
      });
    }

    // store to local storage as this will be emitted to "this.preferences"
    this._storage.set(storageConstants.USER_PREFERENCES, JSON.stringify(updatedPreferences));
  }


  private set (
    preference: SingleUserPreference
  ) {
    let prevPreference: SingleUserPreference
      = _.pickDeep(this.preferences.value, _.paths(preference) as any);

    // set
    this._set(preference);

    // post
    let body = { preferences: preference };
    let obs = this._http
      .post(`${ apiConstants.USERS }/preferences`, body)
      .pipe(share());   // since we share the observable and don't want two requests to take place

    obs.subscribe({
      next: (res) => {
        // success
      },
      error: (error) => {
        // revert if unsuccessful
        this._set(prevPreference);
      }
    });

    return obs.pipe(map(x => void 0));
  }


  // (try) add a duration set
  public addDurationSet (set: number[]) {

    // must contain at least one duration
    if ( ! set.length) return;

    // check if set is already present
    let sets = structuredClone(this.durationSets);
    let i = sets.findIndex(x => _.isEqual(_.sortBy(x), _.sortBy(set)));

    // if the set is already present at the first position we are golden
    if (i == 0) return;

    // if it exists at any other position remove the set
    if (i > -1) sets.splice(i, 1);

    // combine
    sets = [set, ...sets];

    // truncate
    if (sets.length >= maxNumDurationSets) sets.length = maxNumDurationSets;

    // update
    this.setDurationSets(sets);
  }

}