import { NgModule                         } from '@angular/core';
import { CommonModule                     } from '@angular/common';
import { FormsModule,
         ReactiveFormsModule              } from '@angular/forms';
import { RouterModule                     } from '@angular/router';

import { AppCommonModule                  } from 'app/common/common.module';

import { TranslationModule                } from 'app/core/translate/translate.module';

import { SearchComponent                  } from './search.component';

@NgModule({
  declarations: [
    SearchComponent,
  ],
  exports: [
    SearchComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AppCommonModule,
    TranslationModule,
    RouterModule,
  ]
})
export class SearchModule { }
