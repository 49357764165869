<div class="content">

  <div class="close">
    <button mat-icon-button
            (click)="close()"
            matTooltip="{{ 'common.close' | translate }}">
      <mat-icon class="royal-icon">expand_more</mat-icon>
    </button>
  </div>

  <mat-nav-list class="filter-list" [class.maximized]="! activeType">

    <ng-container *ngIf="(onWeeks | async)?.length">
      <mat-list-item (click)="setActive('week')" [class.selected]="activeType == 'week'">
        <mat-icon matListItemIcon class="royal-icon">date_range</mat-icon>
        <h4 matListItemTitle>
          {{ 'common.week' | translate }} {{ selectedWeek?.number }}
          <sup>{{ selectedWeek?.excludedDays?.length ? -selectedWeek!.excludedDays.length : '' }}</sup>
        </h4>
        <div matListItemMeta><mat-icon class="right-arrow">chevron_right</mat-icon></div>
      </mat-list-item>
      <mat-divider></mat-divider>
    </ng-container>

    <ng-container *ngIf="(onTeachers | async)?.length">
      <mat-list-item (click)="setActive('teachers')" [class.selected]="activeType == 'teachers'">
        <mat-icon matListItemIcon class="royal-icon">school</mat-icon>
        <h4 matListItemTitle>{{ 'common.teachers' | translate }}</h4>
        <div matListItemMeta><mat-icon class="right-arrow">chevron_right</mat-icon></div>
      </mat-list-item>
      <mat-divider></mat-divider>
    </ng-container>

    <ng-container *ngIf="(onGroups | async)?.length">
      <mat-list-item (click)="setActive('groups')" [class.selected]="activeType == 'groups'">
        <mat-icon matListItemIcon class="royal-icon">people</mat-icon>
        <h4 matListItemTitle>{{ 'common.groups' | translate }}</h4>
        <div matListItemMeta><mat-icon class="right-arrow">chevron_right</mat-icon></div>
      </mat-list-item>
      <mat-divider></mat-divider>
    </ng-container>

    <ng-container *ngIf="(onLocations | async)?.length">
      <mat-list-item (click)="setActive('locations')" [class.selected]="activeType == 'locations'">
        <mat-icon matListItemIcon class="royal-icon">room</mat-icon>
        <h4 matListItemTitle>{{ 'common.locations' | translate }}</h4>
        <div matListItemMeta><mat-icon class="right-arrow">chevron_right</mat-icon></div>
      </mat-list-item>
      <mat-divider></mat-divider>
    </ng-container>

  </mat-nav-list>


  <mat-nav-list class="sub-filter-list dark-scrollbar" [class.minimized]="! activeType">
    <mat-list-item *ngFor="let option of selectOptions | async"
                   [class.selected]="(onSelected | async)?.[activeType] == option.id"
                   (click)="select(activeType, option)">
      <h4 matListItemTitle *ngIf="isWeek(option)" class="week-option">
        <span>{{ 'common.week_short' | translate | capitalizeFirst }} {{ option.number }}
          <sup>{{ option.excludedDays.length ? -option.excludedDays.length : '' }}</sup>
        </span>
        <span>{{ option.string }}</span>
      </h4>
      <h4 matListItemTitle *ngIf=" ! isWeek(option)">{{ option | displayName }}</h4>
    </mat-list-item>
  </mat-nav-list>

</div>