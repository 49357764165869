<!-- <video controls autoplay *ngIf="data.url">
  <source [src]="data.url" type="video/mp4">
  Your browser does not support the video tag.
</video> -->
<button mat-icon-button
        class="close"
        (click)="close()">
  <mat-icon>close</mat-icon>
</button>

<div *ngIf="(source | async)?.youtubeId" class="youtube-player">
  <youtube-player [videoId]="(source | async)?.youtubeId"
                  suggestedQuality="highres">
  </youtube-player>

  <mat-divider></mat-divider>

  <div class="dark-scrollbar royal-text-secondary">
    <div [innerHTML]="('tutorials.' + (selected | async)?.id + '.description' | translate) | paragraphs">
    </div>

    <ng-container *ngIf="(selected | async)?.related">
      <mat-nav-list>
        <mat-divider></mat-divider>

        <div mat-subheader>{{ 'common.related-content' | translate }}</div>

        <mat-list-item *ngFor="let related of (selected | async)?.related" (click)="openVideo(related)">
          <mat-icon matListItemIcon class="royal-icon">videocam</mat-icon>
          <div matListItemLine>
            {{ 'tutorials.' + related + '.title' | translate }}
            <span class="spacer"></span>
            <button color="primary" mat-icon-button>
              <mat-icon>chevron_right</mat-icon>
            </button>
          </div>
        </mat-list-item>
      </mat-nav-list>
    </ng-container>

  </div>
</div>
