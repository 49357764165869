import _                                  from 'lodash';
import Moment                             from 'moment';
import { extendMoment                   } from 'moment-range';

import { Util                           } from '@app/common';
import { getWeekRange                   } from '@app/common/wrappers/weeks-and-periods';
import { Populated as P                 } from '@app/shared/interfaces';
import { InputAnalysisService           } from './input-analysis.service';
import { Holiday,
         Remark,
         Schedule                       } from './types';
import { ScheduleRangeService           } from '../schedule-range/schedule-range.service';

const moment = extendMoment(Moment);

function getId (x: string | { id: string }): string {
  return typeof x === 'string' ? x : x.id;
}

function missingOverlapSpecies (
  overlapGroups: P.overlapGroup[],
  courseMap:     Map<string, P.course>
): P.overlapGroup[] {
  return overlapGroups
    .filter(overlapGroup => {
      // fetch the number of events
      const numEvents = (overlapGroup.coalesced ?? [])
        .flatMap(x => courseMap.get(getId(x.to))?.events)
        .filter(Boolean)
        .length;

      // verify that the number of overlap species is equal to the number of events
      return overlapGroup.species?.length !== numEvents
    });
}


export function processData (
  this:                                                            InputAnalysisService,
  version:                                                         number,
  { division, settings, periods, courses, events, overlapGroups }: Schedule
): Remark[] {
  const remarks = new Array<Remark>();

  // create a course map
  const courseMap = new Map(courses.map(course => [course.id, course]));

  ////
  //// mismatching minutes per week
  ////
  const numWeeksPerPeriod = ScheduleRangeService.computeNumWeeksPerPeriod(division, settings, periods);
  courses.forEach(course => {
    let args: { 'planned-value': number | string, 'actual-value': number } | undefined = undefined;

    // extract and parse planned duration
    const plannedDuration = Util.functions.extractAndParsePlannedDuration(course);
    if ( ! plannedDuration) return;
    const [value, unit] = plannedDuration;

    // actual minutes per week of the course
    const actualMinutes = _.sum(course.events?.map(x => x.preferredDuration));

    if (unit == 'min/week') {
      // if already in min/week, simply compare
      if (actualMinutes >= value) return;
      args = { 'planned-value': value, 'actual-value': actualMinutes };
    } else {
      // otherwise, convert to min/week and compare
      const numWeeks = numWeeksPerPeriod.get(course.period?.id ?? settings.period?.id);

      let planned = value * 60 / (numWeeks ?? 0);
      planned = Math.ceil(planned / 5) * 5;

      if (actualMinutes >= planned) return;
      args = { 'planned-value': isFinite(planned) ? planned : '∞', 'actual-value': actualMinutes };
    }

    remarks.push(this.createRemark('issue', 'plannedDuration', version, course, 20, args));
  });


  ////
  //// empty courses
  ////
  courses.forEach(course => {
    if ( ! course.events?.length) {
      remarks.push(this.createRemark('notice', 'empty course', version, course, 20));
    }
  });


  ////
  //// unconnected events
  ////
  events.forEach(event => {
    // if in an intermediate state the courses might not exist momentarily
    // (occurs when a course with such events is deleted)
    if ( ! event.course) {
      console.warn(`event.course == undefined`, event);
      // must abort
      return;
    }

    const numLocations    = event.locations?.length    ?? event.course?.locations   ?.length ?? 0;
    const numGroups       = event.groups   ?.length    ?? event.course?.groups      ?.length ?? 0;
    const numTeachers     = event.teachers ?.length    ?? event.course?.teachers    ?.length ?? 0;
    const numParticipants = event.participants?.length ?? event.course?.participants?.length ?? 0;

    if (numLocations + numGroups + numTeachers + numParticipants == 0) {
      remarks.push(this.createRemark('notice', 'completely unconnected event', version, event, 20));
    }
    else if (this._environment.organizationType != 'sports_facility' && numGroups + numTeachers + numParticipants == 0) {
      remarks.push(this.createRemark('notice', 'unconnected event', version, event, 20));
    }
  });


  ////
  //// none forced overlappable events set
  ////
  missingOverlapSpecies(overlapGroups, courseMap)
  .forEach(overlapGroup => {
    const type = settings.requireForcedOverlappingEventsSets ? 'error' : 'notice';
    const numCourses: number = overlapGroup.coalesced?.length ?? 0;
    const args = {
      'is/are-parallel-courses': numCourses > 1 ?
        this._translate.instant('substitutions.are_parallel_courses') :
        this._translate.instant('substitutions.is_a_parallel_course')
    }
    remarks.push(this.createRemark(type, 'missing_overlap_species', version, [overlapGroup], 20, args));
  });


  ////
  //// empty or overflowing periods
  ////
  {
    const scheduleRange = moment.range(
      getWeekRange(division.start!).start,
      getWeekRange(division.end!  ).end
    );

    periods.forEach(period => {
      // empty period
      if ( ! period.ranges.length) {
        remarks.push(this.createRemark('notice', 'empty_period', version, period, 20));
        return;
      }

      // check overflowing
      const periodStart = getWeekRange(period.ranges[0                       ].start).start;
      const periodEnd   = getWeekRange(period.ranges[period.ranges.length - 1].end  ).end;
      if ( ! scheduleRange.contains(periodStart) || ! scheduleRange.contains(periodEnd)) {
        remarks.push(this.createRemark('notice', 'overflowing_period', version, period, 20));
      }
    });
  }


  ////
  //// overflowing calendar exceptions
  ////
  {
    const scheduleStart = moment.utc(division.start!);
    const scheduleEnd   = moment.utc(division.end!);
    settings.calendarExceptions?.forEach(x => {
      const exceptionStart = moment.utc(x.start);
      const exceptionEnd   = moment.utc(x.end);

      if (exceptionStart.isBefore(scheduleStart) || exceptionEnd.isAfter(scheduleEnd)) {
        const exception: Holiday = { is: 'holiday', id: x.description ?? '', displayName: x.description };
        remarks.push(this.createRemark('notice', 'overflowing_holiday', version, exception, 20));
      }
    });
  }

  return remarks;
}