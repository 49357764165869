export const LANG_EL_NAME = 'el';

export const LANG_EL_TRANS = {
  "account.index.header": "Διαχείριση λογαριασμού",
  "account.notifications.header": "Ειδοποιήσεις",
  "account.notifications.menu": "Ειδοποιήσεις",
  "account.notifications.page.descr": "Ο παρακάτω πίνακας επιτρέπει την προσαρμογή διαφόρων πτυχών του συστήματος ειδοποιήσεων που χρησιμοποιείται σε όλη την εφαρμογή.",
  "account.notifications.page.title": "Ειδοποιήσεις",
  "account.security.header": "Ασφάλεια",
  "account.security.menu": "Ασφάλεια",
  "account.security.page.change-password.descr": "Συμπληρώστε την παρακάτω φόρμα για να αλλάξετε τον κωδικό πρόσβασής σας.",
  "account.security.page.change-password.error.descr": "Ο τρέχων κωδικός πρόσβασης που δόθηκε δεν ταιριάζει με τον πραγματικό σας κωδικό. Παρακαλώ δοκιμάστε ξανά.",
  "account.security.page.change-password.error.title": "Αδυναμία ενημέρωσης του κωδικού πρόσβασης",
  "account.security.page.change-password.success.descr": "Ο κωδικός πρόσβασής σας έχει ενημερωθεί!",
  "account.security.page.change-password.success.title": "Επιτυχία",
  "account.security.page.change-password.title": "Αλλαγή κωδικού πρόσβασης",
  "account.security.page.title": "Ασφάλεια",
  "account.workspace.header": "Χώρος εργασίας",
  "account.workspace.menu": "Χώρος εργασίας",
  "account.workspace.page.defaultEventDurations.descr": "Εδώ μπορείτε να ορίσετε σύνολα προεπιλεγμένων διαρκειών μαθημάτων για να διευκολύνετε τη δημιουργία μαθημάτων.",
  "account.workspace.page.defaultEventDurations.label": "Προεπιλεγμένη διάρκεια μαθήματος",
  "account.workspace.page.descr": "Αυτού του είδους οι προτιμήσεις σας επιτρέπουν να τροποποιείτε και να προσαρμόζετε το χώρο εργασίας της εφαρμογής.",
  "account.workspace.page.displayCenterOfAttraction.descr": "Το κέντρο έλξης (ή κέντρο ημέρας, όπως αναφέρεται σε παγκόσμιο επίπεδο) καθορίζει σε ποιο χρονικό σημείο θα γίνει η προσέλκυση των μαθημάτων κατά τη διαδικασία του αυτόματου προγραμματισμού. Με την ενεργοποίηση αυτής της επιλογής το παγκόσμιο κέντρο ημέρας μπορεί να παρακαμφθεί για μεμονωμένα μαθήματα και μαθήματα.",
  "account.workspace.page.displayCenterOfAttraction.label": "Εμφάνιση του κέντρου έλξης και της ιδιοκτησίας μαθήματος",
  "account.workspace.page.displayChat.descr": "Εάν η συνομιλία στην κάτω αριστερή γωνία είναι ορατή ή όχι.",
  "account.workspace.page.displayChat.label": "Εμφάνιση συνομιλίας",
  "account.workspace.page.displayEventsTable.descr": "Σε αυτόν τον πίνακα θα βρείτε τα μαθήματα από όλα τα μαθήματα.",
  "account.workspace.page.displayEventsTable.label": "Εμφάνιση πίνακα μαθημάτων",
  "account.workspace.page.displayPublicId.descr": "Το δημόσιο αναγνωριστικό μιας οντότητας (μάθημα, μάθημα, καθηγητής κ.λπ.) είναι σημαντικό κατά την εξαγωγή δεδομένων σε μια άλλη πλατφόρμα. Εάν τα δεδομένα του προγράμματος έχουν εισαχθεί στο Royal Schedule, η τιμή του δημόσιου αναγνωριστικού δεν θα πρέπει γενικά να τροποποιηθεί, καθώς αυτό μπορεί να προκαλέσει συγκρούσεις δεδομένων κατά την εξαγωγή του προγράμματος. Για τους μη έμπειρους χρήστες συνιστούμε, επομένως, την απόκρυψη του δημόσιου αναγνωριστικού.",
  "account.workspace.page.displayPublicId.label": "Εμφάνιση ταυτότητας δημόσια",
  "account.workspace.page.displayTooltips.descr": "Εάν θα πρέπει να είναι ορατές οι συμβουλές εργαλείων με πληροφορίες που εξηγούν περαιτέρω την έννοια.",
  "account.workspace.page.displayTooltips.label": "Εμφάνιση συμβουλών εργαλείων",
  "account.workspace.page.language.descr": "Ορίζει τη γλώσσα της εφαρμογής.",
  "account.workspace.page.notifications.error": "Απέτυχε η ενημέρωση της προτίμησης. Προσπαθήστε ξανά.",
  "account.workspace.page.title": "Χώρος εργασίας",
  "attributeDescriptions.course.centerOfAttraction": "Τα μαθήματα του μαθήματος προσελκύονται στο κέντρο έλξης κατά τη διάρκεια της αυτόματης διαδικασίας προγραμματισμού",
  "attributeDescriptions.course.color": "Ορίζει το χρώμα εμφάνισης για τα μαθήματα του μαθήματος",
  "attributeDescriptions.course.days": "Ορίζει τις επιτρεπόμενες ημέρες για τα μαθήματα του μαθήματος",
  "attributeDescriptions.course.eventDurationVariation": "Ορίζει τη μέγιστη επιτρεπόμενη διαφορά διάρκειας από την προτιμώμενη διάρκεια για τα μαθήματα του μαθήματος. Ωστόσο, η συνολική διάρκεια των μαθημάτων του μαθήματος διατηρείται.",
  "attributeDescriptions.course.events": "Τα μαθήματα που ανήκουν στο μάθημα",
  "attributeDescriptions.course.groups": "Ορίζει τις ομάδες που θα παρακολουθήσουν τα μαθήματα του μαθήματος",
  "attributeDescriptions.course.intervals": "Καθορίζει τις ημερήσιες ώρες έναρξης και λήξης των μαθημάτων του μαθήματος",
  "attributeDescriptions.course.locations": "Ορίζει τις αίθουσες που μπορούν να χρησιμοποιηθούν από τα μαθήματα του μαθήματος",
  "attributeDescriptions.course.lockedTimes": "Ορίζει απαγορευμένα χρονικά διαστήματα για τα μαθήματα του μαθήματος",
  "attributeDescriptions.course.minBreakLength": "Καθορίζει την απαίτηση διάλειμμα μήκος για τα μαθήματα του μαθήματος",
  "attributeDescriptions.course.overlapGroup": "Ορίζει ένα σύνολο μαθημάτων των οποίων τα μαθήματα επιτρέπεται να επικαλύπτονται. Ωστόσο, τα μαθήματα που ανήκουν στο ίδιο μάθημα δεν επιτρέπεται ποτέ να επικαλύπτονται. Κατά τη διάρκεια της διαδικασίας αυτόματου προγραμματισμού, τα μαθήματα εντός μιας τέτοιας ομάδας προσπαθούν να αντιστοιχιστούν με τον καλύτερο δυνατό τρόπο.",
  "attributeDescriptions.course.subject": "Το θέμα του μαθήματος",
  "attributeDescriptions.course.teachers": "Ορίζει τους καθηγητές που θα διδάξουν τα μαθήματα του μαθήματος",
  "attributeDescriptions.event.centerOfAttraction": "Το μάθημα οδηγείται στο κέντρο έλξης κατά τη διάρκεια της διαδικασίας αυτόματου προγραμματισμού.",
  "attributeDescriptions.event.color": "Ορίζει το χρώμα εμφάνισης του μαθήματος",
  "attributeDescriptions.event.course": "Το μάθημα στο οποίο ανήκει το μάθημα",
  "attributeDescriptions.event.courseId": "Το δημόσιο αναγνωριστικό του μαθήματος στο οποίο ανήκει το μάθημα",
  "attributeDescriptions.event.days": "Ορίζει τις επιτρεπόμενες ημέρες για το μάθημα",
  "attributeDescriptions.event.duration": "Ορίζει τη διάρκεια του μαθήματος",
  "attributeDescriptions.event.durationVariation": "Ορίζει τη μέγιστη επιτρεπόμενη διαφορά διάρκειας του μαθήματος από την προτιμώμενη διάρκεια. Ωστόσο, η συνολική διάρκεια του μαθήματος παραμένει σταθερή.",
  "attributeDescriptions.event.fixedStart": "Καθορίζει την ώρα έναρξης του μαθήματος",
  "attributeDescriptions.event.groups": "Ορίζει τις ομάδες που θα παρακολουθήσουν το μάθημα",
  "attributeDescriptions.event.intervals": "Καθορίζει τις καθημερινές ώρες έναρξης και λήξης του μαθήματος",
  "attributeDescriptions.event.locations": "Ορίζει τις αίθουσες που μπορούν να χρησιμοποιηθούν από το μάθημα",
  "attributeDescriptions.event.lockedTimes": "Ορίζει απαγορευμένα χρονικά διαστήματα για το μάθημα",
  "attributeDescriptions.event.minBreakLength": "Καθορίζει την απαίτηση μήκους διαλείμματος του μαθήματος",
  "attributeDescriptions.event.preferredDuration": "Ορίζει την προτιμώμενη διάρκεια του μαθήματος. Μετά την αυτόματη διαδικασία προγραμματισμού η πραγματική διάρκεια δεν θα υπερβαίνει την \"προτιμώμενη διάρκεια ± μεταβολή διάρκειας\".",
  "attributeDescriptions.event.teachers": "Ορίζει τους εκπαιδευτικούς που θα διδάξουν το μάθημα",
  "attributeDescriptions.group.days": "Ορίζει τις επιτρεπόμενες ημέρες για την ομάδα και έτσι ενδεχομένως περιορίζει περαιτέρω τα μαθήματα που λαμβάνει η ομάδα",
  "attributeDescriptions.group.intervals": "Καθορίζει τις καθημερινές ώρες έναρξης και λήξης της ομάδας και έτσι ενδεχομένως περιορίζει περαιτέρω τα μαθήματα που πραγματοποιούνται από την ομάδα",
  "attributeDescriptions.group.lockedTimes": "Ορίζει απαγορευμένα χρονικά διαστήματα για την ομάδα και έτσι ενδεχομένως περιορίζει περαιτέρω τα μαθήματα που λαμβάνει η ομάδα",
  "attributeDescriptions.group.lunch": "Ορίζει διαλείμματα για γεύμα για την ομάδα",
  "attributeDescriptions.group.minBreakLength": "Πιθανόν να περιορίζει περαιτέρω τη διάρκεια των διαλειμμάτων των μαθημάτων που πραγματοποιούνται από την ομάδα",
  "attributeDescriptions.groups.members": "Ορίζει τα μέλη για αυτή την ομάδα",
  "attributeDescriptions.location.days": "Καθορίζει τις διαθέσιμες ημέρες της αίθουσας και έτσι ενδεχομένως περιορίζει περαιτέρω τα μαθήματα που χρησιμοποιούν την αίθουσα",
  "attributeDescriptions.location.lockedTimes": "Ορίζει απαγορευμένα χρονικά διαστήματα της αίθουσας και έτσι ενδεχομένως περιορίζει περαιτέρω τα μαθήματα που χρησιμοποιούν την αίθουσα",
  "attributeDescriptions.location.minBreakLength": "Ορίζει το μικρότερο χρονικό περιθώριο μεταξύ δύο κρατήσεων μαθήματος της αίθουσας. Αυτό μπορεί να περιορίσει περαιτέρω τη διάρκεια των διαλειμμάτων των μαθημάτων που χρησιμοποιούν την αίθουσα.",
  "attributeDescriptions.person.group": "Ορίστε την ομάδα στην οποία ανήκει αυτό το άτομο",
  "attributeDescriptions.person.lockedTimes": "Ορίζει απαγορευμένα χρονικά διαστήματα για το άτομο και έτσι ενδεχομένως περιορίζει περαιτέρω τα μαθήματα που λαμβάνει το άτομο",
  "attributeDescriptions.person.lunch": "Καθορίζει τα διαλείμματα γεύματος για το άτομο",
  "attributeDescriptions.shared.displayName": "Ένα όνομα που χρησιμοποιείται σε όλη την εφαρμογή",
  "attributeDescriptions.shared.ids": "Ένα μοναδικό δημόσιο αναγνωριστικό, χρήσιμο για σκοπούς εξαγωγής",
  "attributeDescriptions.teacher.days": "Καθορίζει τις επιτρεπόμενες ημέρες για τον εκπαιδευτικό και έτσι ενδεχομένως περιορίζει περαιτέρω τα μαθήματα που διδάσκει ο εκπαιδευτικός",
  "attributeDescriptions.teacher.intervals": "Καθορίζει τις καθημερινές ώρες έναρξης και λήξης για τον εκπαιδευτικό και έτσι ενδεχομένως περιορίζει περαιτέρω τα μαθήματα που διδάσκει ο εκπαιδευτικός",
  "attributeDescriptions.teacher.lockedTimes": "Ορίζει απαγορευμένα χρονικά διαστήματα για τον εκπαιδευτικό και έτσι ενδεχομένως περιορίζει περαιτέρω τα μαθήματα που διδάσκει ο εκπαιδευτικός",
  "attributeDescriptions.teacher.lunch": "Καθορίζει τα διαλείμματα γεύματος για τον εκπαιδευτικό",
  "attributeDescriptions.teacher.minBreakLength": "Ενδεχομένως περιορίζει περαιτέρω τα διαλείμματα των μαθημάτων που διδάσκει ο εκπαιδευτικός",
  "attributes.course.eventDurationVariation": "Μεταβολή της διάρκειας του μαθήματος",
  "attributes.course.events": "Μαθήματα",
  "attributes.course.overlapGroup": "Ομάδα επικάλυψης",
  "attributes.division.default.dayRank": "Προεπιλεγμένη κατάταξη ημέρας",
  "attributes.division.default.dependencyMinimumBreakLength": "Προεπιλεγμένο μήκος διαλείμματος για αίθουσες",
  "attributes.division.default.dependencyRank": "Προεπιλεγμένη θέση αίθουσας",
  "attributes.division.default.eventDurationVariation": "Προεπιλεγμένη μεταβολή της διάρκειας του μαθήματος",
  "attributes.division.default.eventMinimumBreakLength": "Προεπιλεγμένο μήκος διαλείμματος για μαθήματα",
  "attributes.division.default.lunch": "Προεπιλεγμένο γεύμα",
  "attributes.division.default.maxNumDailyWorkingHours": "Προεπιλεγμένος αριθμός ημερήσιων ωρών εργασίας",
  "attributes.division.default.maxNumWorkingHours": "Σύνολο",
  "attributes.division.end": "Ημερομηνία λήξης",
  "attributes.division.published": "δημοσίευση",
  "attributes.division.settings.dayEnd": "Τέλος ημέρας",
  "attributes.division.settings.dayStart": "Έναρξη ημέρας",
  "attributes.division.settings.discretization": "Διακριτοποίηση",
  "attributes.division.settings.numDays": "Αριθμός ημερών",
  "attributes.division.settings.potentialCenter": "Κέντρο ημέρας",
  "attributes.division.start": "Ημερομηνία έναρξης",
  "attributes.event.course": "Μάθημα",
  "attributes.event.courseId": "Id μαθήματος",
  "attributes.event.durationVariation": "Μεταβολή της διάρκειας",
  "attributes.event.fixedStart": "Σταθερή ώρα έναρξης",
  "attributes.event.inherit": "Κληρονομεί από την πορεία",
  "attributes.event.preferredDuration": "Προτιμώμενη διάρκεια",
  "attributes.generation.status.COMPLETED": "Πραγματοποιήθηκε",
  "attributes.generation.status.PENDING": "Αναμονή",
  "attributes.generation.status.STARTED": "Ξεκίνησε",
  "attributes.groups.parentGroups": "Κατηγορίες συστατικών",
  "attributes.groups.species.none": "Ομάδα διδασκαλίας",
  "attributes.groups.species.class": "Τάξη",
  "attributes.groups.species": "Τύπος ομάδας",
  "attributes.groups.members": "Μέλη",
  "attributes.location.days": "Διαθέσιμες ημέρες",
  "attributes.location.minBreakLength": "Περιθώριο κράτησης",
  "attributes.person.group": "Ομάδα",
  "attributes.shared.centerOfAttraction": "Κέντρο έλξης",
  "attributes.shared.color": "Χρώμα",
  "attributes.shared.course": "Τμήμα",
  "attributes.shared.day": "Ημέρα",
  "attributes.shared.days": "Επιτρεπόμενες ημέρες",
  "attributes.shared.displayName": "Όνομα",
  "attributes.shared.duration": "Μήκος",
  "attributes.shared.durationVariation": "Απόκλιση",
  "attributes.shared.dynamicLockedTime.custom": "Ρύθμιση για κάθε ημέρα",
  "attributes.shared.dynamicLockedTime.default": "Προεπιλεγμένη τιμή",
  "attributes.shared.dynamicLockedTime.none": "Κανένα",
  "attributes.shared.dynamicLockedTime.repeated": "Το ίδιο για κάθε ημέρα",
  "attributes.shared.end": "τέλος",
  "attributes.shared.endTime": "Ώρα λήξης",
  "attributes.shared.groups": "Ομάδες",
  "attributes.shared.ids": "Ταυτότητα",
  "attributes.shared.intervals": "Χρονικό πλαίσιο",
  "attributes.shared.intervals.multiple": "Διαφορετικό για κάθε ημέρα",
  "attributes.shared.intervals.none": "Χρήση προεπιλεγμένου χρονικού πλαισίου",
  "attributes.shared.intervals.single": "Το ίδιο για κάθε ημέρα",
  "attributes.shared.locations": "Επιτρεπόμενες αίθουσες",
  "attributes.shared.lockedTimes": "Κλειδωμένοι χρόνοι",
  "attributes.shared.lunch": "Γεύμα",
  "attributes.shared.minBreakLength": "Μήκη διαλείμματος",
  "attributes.shared.minBreakLength.asymmetrical": "Διαφορετικά μήκη",
  "attributes.shared.minBreakLength.asymmetrical.after": "Μετά ",
  "attributes.shared.minBreakLength.asymmetrical.before": "Πριν από ",
  "attributes.shared.minBreakLength.default": "Προεπιλεγμένη τιμή",
  "attributes.shared.minBreakLength.none": "Κανένα",
  "attributes.shared.minBreakLength.symmetrical": "Ίσα μήκη πριν & μετά",
  "attributes.shared.start": "έναρξη",
  "attributes.shared.startTime": "Ώρα έναρξης",
  "attributes.shared.subject": "Θέμα",
  "attributes.shared.teachers": "Εκπαιδευτικοί",
  "common.goto_schedule": "Πηγαίνετε στο πρόγραμμα",
  "common.about": "Κατάσταση",
  "common.add": "Προσθήκη",
  "common.add_course": "Προσθήκη μαθήματος",
  "common.add_event": "Προσθέστε μάθημα(τα): {{ event-durations }}",
  "common.add_event_advanced": "Προσθέστε μάθημα(α)",
  "common.add_filter": "Προσθήκη φίλτρου",
  "common.add_group": "Προσθήκη ομάδας",
  "common.add_location": "Προσθήκη αίθουσας",
  "common.add_person": "Προσθήκη ατόμου",
  "common.add_teacher": "Προσθήκη εκπαιδευτικού",
  "common.advanced_settings": "Προηγμένες ρυθμίσεις",
  "common.all": "Όλα",
  "common.alone": "Μόνος",
  "common.an_event_belonging_to_the_course": "ένα μάθημα που ανήκει στο τμήμα",
  "common.analysis": "Ανάλυση",
  "common.and": "και",
  "common.and1Other": "και ένα άλλο",
  "common.andNOthers": "και {{n}} άλλοι",
  "common.back": "Πίσω",
  "common.back_to_login": "Επιστροφή στην είσοδο",
  "common.calendar_view": "Προβολή ημερολογίου",
  "common.cancel": "Ακύρωση",
  "common.change_password": "Αλλαγή κωδικού πρόσβασης",
  "common.chooseOther": "Επιλέξτε άλλα",
  "common.clear": "Διαγραφή",
  "common.close": "Κλείστε το",
  "common.complete": "Πλήρης",
  "common.computing": "Πληροφορική",
  "common.connect": "Σύνδεση",
  "common.connect_error": "Σφάλμα σύνδεσης",
  "common.counts": "Μετράει",
  "common.course": "Τμήμα",
  "common.courses": "Μαθήματα",
  "common.create": "Δημιουργία",
  "common.creating": "Δημιουργία",
  "common.creation_fail": "Κάτι πήγε στραβά",
  "common.creation_success": "Δημιουργήθηκε",
  "common.day": "Ημέρα",
  "common.days": "Ημέρες",
  "common.delete": "Διαγραφή",
  "common.delete_account": "Κατάργηση λογαριασμού",
  "common.deleting": "Διαγραφή",
  "common.download": "Λήψη",
  "common.drop_here": "Σύρε εδώ",
  "common.earliest": "Παλαιότερο",
  "common.edit": "Επεξεργασία",
  "common.email": "email",
  "common.erroneous_value": "Εσφαλμένη τιμή",
  "common.error": "Σφάλμα",
  "common.errors": "σφάλματα",
  "common.event": "Μάθημα",
  "common.events": "Μαθήματα",
  "common.expand": "Επεκτείνετε το",
  "common.export": "Εξαγωγή",
  "common.filter": "Φίλτρο",
  "common.forgot_password": "Ξεχάσατε τον κωδικό πρόσβασης;",
  "common.generalErrorMessage": "Ουπς, κάτι πήγε στραβά...",
  "common.generic_error": "Κάτι πήγε στραβά",
  "common.generic_help": "Εργαζόμαστε για την επίλυση του προβλήματος.",
  "common.goto-tutorial": "Πηγαίνετε στο σεμινάριο",
  "common.group": "Ομάδα",
  "common.groups": "Ομάδες",
  "common.help": "Βοήθεια",
  "common.hide": "Απόκρυψη",
  "common.hour": "Ώρα",
  "common.hours": "Ώρες",
  "common.import": "Εισαγωγή",
  "common.inheritFromCourse": "Κληρονομεί από την πορεία",
  "common.inheritFromCourseValue": "Κληρονομεί από την πορεία ({{value}})",
  "common.invalid": "Μη έγκυρο",
  "common.invalid_division_id": "Το πρόγραμμα δεν βρέθηκε",
  "common.invalid_value": "Μη έγκυρη τιμή",
  "common.language": "Γλώσσα",
  "common.latest": "Τελευταία",
  "common.list_view": "Προβολή λίστας",
  "common.loading": "Φόρτωση",
  "common.location": "Αίθουσα",
  "common.locations": "Αίθουσες",
  "common.locked": "Κλειδωμένο",
  "common.locked_time": "Κλειδωμένος χρόνος",
  "common.locked_times": "Κλειδωμένοι χρόνοι",
  "common.login": "Συνδεθείτε",
  "common.logout": "Αποσύνδεση",
  "common.lunch": "Γεύμα",
  "common.make": "φτιάξτε το",
  "common.makes": "δημιουργεί",
  "common.manage_account": "Διαχειριστείτε το λογαριασμό σας",
  "common.minutes": "Λεπτά",
  "common.minutes_short": "Ελάχιστο",
  "common.modified_after": "Τροποποιήθηκε μετά από",
  "common.nameless": "Ανώνυμος",
  "common.next": "Επόμενο",
  "common.no": "Όχι",
  "common.no_events_to_show": "Δεν υπάρχουν μαθήματα για εμφάνιση",
  "common.no_value": "Καμία αξία",
  "common.none_plural": "Κανένα",
  "common.none_singular": "Κανένα",
  "common.of": "του",
  "common.or": "Ή",
  "common.other": "Άλλο",
  "common.others": "Άλλοι",
  "common.persons": "Άτομα",
  "common.print": "Εκτύπωση",
  "common.related-content": "Σχετικό περιεχόμενο",
  "common.reload": "επαναφόρτωση",
  "common.remaining": "Υπόλοιπο",
  "common.resend": "Επαναποστολή",
  "common.reset": "Σαφής",
  "common.reset_filter": "Επαναφορά φίλτρου",
  "common.reset_password_fail": "Δεν ήταν δυνατή η αποστολή συνδέσμου επαναφοράς σε αυτό τον χρήστη",
  "common.reset_password_success": "Ο σύνδεσμος επαναφοράς αποστέλλεται σε",
  "common.restore": "Επαναφορά",
  "common.retry": "Επανάληψη",
  "common.rootInterval": "Μπλοκ",
  "common.save": "Αποθήκευση",
  "common.save_failed": "Η αποθήκευση απέτυχε",
  "common.saved": "Αποθηκευμένο",
  "common.saving": "Αποθήκευση",
  "common.schedule": "Πρόγραμμα",
  "common.school_year": "Σχολικό έτος",
  "common.screen_size": "Μικρό μέγεθος οθόνης",
  "common.search": "Αναζήτηση",
  "common.search.course": "Αναζήτηση μαθήματος",
  "common.search.event": "Αναζήτηση για μάθημα",
  "common.select": "Επιλέξτε",
  "common.selected": "Επιλεγμένα",
  "common.settings": "Ρυθμίσεις",
  "common.sex.man": "Άνθρωπος",
  "common.sex.woman": "Γυναίκα",
  "common.share": "Κοινή χρήση",
  "common.show": "Εμφάνιση",
  "common.subject": "Θέμα",
  "common.sync": "Συγχρονισμός",
  "common.synchronising": "Συγχρονισμός",
  "common.teacher": "Δάσκαλος",
  "common.teachers": "Δάσκαλοι",
  "common.terms": "Όροι και προϋποθέσεις",
  "common.the_course": "Το μάθημα",
  "common.the_courses": "Τα τμήματα",
  "common.the_event": "Το μάθημα",
  "common.the_events": "Τα μαθήματα",
  "common.the_group": "Η ομάδα",
  "common.the_groups": "Οι ομάδες",
  "common.the_location": "Η αίθουσα",
  "common.the_locations": "Οι αίθουσες",
  "common.the_teacher": "Ο δάσκαλος",
  "common.the_teachers": "Οι δάσκαλοι",
  "common.time_settings": "Ρυθμίσεις ώρας",
  "common.toggle_all": "Εναλλαγή όλων",
  "common.total": "Σύνολο",
  "common.unknown": "άγνωστο",
  "common.update": "Ενημέρωση",
  "common.updated": "Ενημερωμένο",
  "common.upload": "Ανέβασμα",
  "common.useDefault": "Χρήση προεπιλογής",
  "common.useDefaultValue": "Χρήση προεπιλεγμένου ({{value}})",
  "common.users": "Χρήστες",
  "common.versions": "Εκδόσεις",
  "common.warning": "προειδοποίηση",
  "common.warnings": "προειδοποιήσεις",
  "common.week": "Εβδομάδα",
  "common.yes": "Ναι",
  "common.YYYY-MM-DD": "Ημερομηνία",
  "common.zoom_center": "Επαναφορά ζουμ",
  "common.zoom_in": "Μεγέθυνση",
  "common.zoom_out": "Απομεγέθυνση",
  "common.today": "Σήμερα",
  "common.tomorrow": "Αύριο",
  "common.this_week": "Αυτή την εβδομάδα",
  "common.next_week": "Επόμενη εβδομάδα",
  "common.this_month": "Αυτό το μήνα",
  "common.next_month": "Επόμενος μήνας",
  "common.modify": "Τροποποίηση",
  "common.calendar": "Ημερολόγιο",
  "common.list": "Λίστα",
  "common.changes": "Αλλαγές",
  "common.cancelled": "Ακυρώθηκε",
  "common.no_selected": "Δεν επιλέχθηκε",
  "common.no_exceptions": "Δεν υπάρχουν εξαιρέσεις",
  "common.no_absences": "Δεν υπάρχουν απουσίες",
  "common.reason": "Λόγος",
  "common.error_code": "Κωδικός σφάλματος",
  "current language": "Ελληνικά",
  "dialogs.admentumImport.step_1.descr": "Εδώ μπορείτε να επιλέξετε ποιο πρόγραμμα και τα σχετικά μαθήματα, καθηγητές κ.λπ. θα εισαχθούν από την πλατφόρμα Admentum. Εάν έχετε πολλαπλά προγράμματα για ένα μόνο σχολικό έτος, μπορούν να διαφοροποιηθούν με βάση τον αριθμό ταυτότητάς τους.",
  "dialogs.admentumImport.step_1.required": "Παρακαλώ επιλέξτε ένα πρόγραμμα για να συνεχίσετε.",
  "dialogs.admentumImport.step_1.title": "Επιλέξτε ένα πρόγραμμα",
  "dialogs.admentumImport.step_2.descr": "Επιλέγοντας μια εβδομάδα θα εισαχθούν όλα τα μαθήματα εντός της εβδομάδας αυτής. Συνεπώς, εάν δεν επιλεγεί καμία εβδομάδα, δεν θα εισαχθούν μαθήματα από το Admentum.",
  "dialogs.admentumImport.step_2.title": "Επιλέξτε μια εβδομάδα",
  "dialogs.admentumImport.step_3.descr": "Μπορείτε να αλλάξετε το όνομα του προγράμματος οποιαδήποτε στιγμή μετά την εισαγωγή.",
  "dialogs.admentumImport.step_3.label": "Όνομα προγράμματος",
  "dialogs.admentumImport.step_3.required": "Παρακαλώ δώστε ένα όνομα στο πρόγραμμα.",
  "dialogs.admentumImport.step_3.title": "Δώστε ένα όνομα στο πρόγραμμα",
  "inputAnalysis.computing": "Υπολογισμός...",
  "inputAnalysis.dialog.description": "Τα δεδομένα του χρονοδιαγράμματος αναλύονται αυτόματα και το αποτέλεσμα παρουσιάζεται παρακάτω.",
  "inputAnalysis.dialog.title": "Ανάλυση δεδομένων προγράμματος",
  "inputAnalysis.entities.theLunch": "Το γεύμα που ανήκει στον",
  "inputAnalysis.errors.21.10.0.description": "Οι ημέρες που είναι διαθέσιμες για το μάθημα απαγορεύονται από έναν ή περισσότερους καθηγητές, ομάδες ή τοποθεσίες που σχετίζονται με το εν λόγω μάθημα.",
  "inputAnalysis.errors.21.10.0.title": "{{The-event}} έχει προγραμματιστεί σε ημέρα που δεν επιτρέπεται στις προτιμήσεις σας.",
  "inputAnalysis.errors.21.10.1.description": "Αυτό μπορεί να οφείλεται σε διάφορους λόγους, π.χ. χρονικά διαστήματα που απαγορεύονται από συνδεδεμένες ομάδες και καθηγητές, ή ημέρες που αποκλείονται λόγω επιλεγμένων τοποθεσιών κ.λπ. Χρησιμοποιήστε το παρακάτω εργαλείο ημερολογίου για να μάθετε ποια είναι η αιτία της συγκεκριμένης περίπτωσης και πώς μπορεί να επιλυθεί.",
  "inputAnalysis.errors.21.10.1.title": "{{The-event}} δεν χωράει μέσα στο διαθέσιμο χρονικό πλαίσιο.",
  "inputAnalysis.errors.24.0.description": "Κάθε μάθημα από μόνο του έχει τουλάχιστον μία ημέρα στη διάθεσή του, αλλά το πρόβλημα προκύπτει όταν αναγκάζονται να επικαλύπτονται και να προγραμματίζονται την ίδια ημέρα.",
  "inputAnalysis.errors.24.0.title": "{{The-events}} αναγκάζονται να επικαλύπτονται, αλλά δεν μοιράζονται ούτε μία διαθέσιμη ημέρα.",
  "inputAnalysis.errors.24.1.description": "Κάθε μάθημα από μόνο του μπορεί να χωρέσει σε κάποιο χρονικό διάστημα, αλλά το πρόβλημα προκύπτει όταν αναγκάζονται να επικαλύπτονται και, συνεπώς, να προγραμματίζονται μαζί.",
  "inputAnalysis.errors.24.1.title": "{{The-events}} αναγκάζονται να αλληλεπικαλύπτονται αλλά δεν μπορούν να προγραμματιστούν μαζί λόγω περιορισμών χρονικών διαστημάτων",
  "inputAnalysis.errors.description": "Ο αλγόριθμος δεν μπορεί να δημιουργήσει ένα χρονοδιάγραμμα αν δεν διευθετηθούν αυτά τα είδη σφαλμάτων.",
  "inputAnalysis.errors.missing_overlap_species.description": "Χωρίς τον προσδιορισμό των αναγκαστικών επικαλύψεων μαθημάτων, ο αλγόριθμος δεν μπορεί να εγγυηθεί την επιδιωκόμενη επικάλυψη μεταξύ των μαθημάτων στο σύνολο των επικαλυπτόμενων μαθημάτων. Αυτό το σφάλμα μπορεί να απενεργοποιηθεί στις ρυθμίσεις του προγράμματος.",
  "inputAnalysis.errors.missing_overlap_species.title": "{{The-courses}} {{make(s)}} ένα σετ επικαλυπτόμενων μαθημάτων με εξαναγκασμένες επικαλύψεις μαθημάτων που λείπουν.",
  "inputAnalysis.errors.notification_title": "Τα δεδομένα του χρονοδιαγράμματος περιέχουν κρίσιμα σφάλματα",
  "inputAnalysis.errors.title": "Κρίσιμα σφάλματα",
  "inputAnalysis.etc.schedule_settings": "Ρυθμίσεις προγράμματος",
  "inputAnalysis.etc.schedule-settings": "Ρυθμίσεις προγράμματος",
  "inputAnalysis.etc.specify_forced_lesson_overlaps": "Καθορισμός αναγκαστικών επικαλύψεων μαθημάτων",
  "inputAnalysis.issues.description": "Αυτού του είδους τα προβλήματα υποδεικνύουν ατέλειες στα δεδομένα του χρονοδιαγράμματος που επηρεάζουν αρνητικά το παραγόμενο χρονοδιάγραμμα. Ωστόσο, ο αλγόριθμος εξακολουθεί να είναι σε θέση να χειριστεί δεδομένα εισόδου που περιέχουν αυτά τα ζητήματα.",
  "inputAnalysis.issues.notification_title": "Τα δεδομένα του χρονοδιαγράμματος περιέχουν λάθη",
  "inputAnalysis.issues.overloaded group.description": "Θα είναι δύσκολο ή αδύνατο να προγραμματιστεί αυτή η ομάδα χωρίς συγκρούσεις στο πρόγραμμα.",
  "inputAnalysis.issues.overloaded group.title": "{{The-group-or-teacher}} είναι υπερφορτωμένο με μαθήματα.",
  "inputAnalysis.issues.title": "Θέματα",
  "inputAnalysis.modules.event intervals.this event": "Αυτό το μάθημα",
  "inputAnalysis.modules.overloaded group.contributing events": "Συμβαλόμενα μαθήματα",
  "inputAnalysis.modules.overloaded group.description": "Τα ποσοστά υποδεικνύουν τη συνολική διάρκεια του μαθήματος σε κάθε διάστημα.\n<b>Παράδειγμα:</b> <br> Εάν θέλετε να προγραμματίσετε ένα μάθημα 30 λεπτών σε διάστημα 1 ώρας, θα έχετε ποσοστό κράτησης 50%. Ωστόσο, αν θέλετε να προγραμματίσετε 2 ώρες συνολικού χρόνου μαθήματος σε διάστημα 1 ώρας, θα έχετε ποσοστό κράτησης 200%. \n<b>Οποιοδήποτε ποσοστό μεγαλύτερο από το 100% θα οδηγήσει σε σύγκρουση.</b>\nΤα πιο συνηθισμένα λάθη όταν το ποσοστό κράτησης είναι μεγαλύτερο από 100%:  <ul> <li>Ξεχασμένες επικαλυπτόμενες ομάδες. Θυμηθείτε να τοποθετήσετε όλα τα μαθήματα που πρέπει να είναι παράλληλα σε μια επικαλυπτόμενη ομάδα. Για παράδειγμα, γλώσσες, τέχνες και χειροτεχνία και IV.</li> <li>Πάρα πολλά μαθήματα κατά τη διάρκεια των περιόδων π.μ./μ.μ.</li><li>Πάρα πολλά μαθήματα κατά τη διάρκεια συγκεκριμένων ημερών.</li></ul></br>",
  "inputAnalysis.modules.overloaded group.legend.bad": "Κακό",
  "inputAnalysis.modules.overloaded group.legend.compact": "Συμπαγής",
  "inputAnalysis.modules.overloaded group.legend.conflict": "Σύγκρουση",
  "inputAnalysis.modules.overloaded group.legend.fixed": "Σταθερό",
  "inputAnalysis.modules.overloaded group.legend.good": "Καλή",
  "inputAnalysis.modules.overloaded group.legend.sparse": "Αραιή",
  "inputAnalysis.notices.completely unconnected event.description": "Αυτού του είδους τα εντελώς ασύνδετα μαθήματα αγνοούνται από τον αλγόριθμο, καθώς δεν επηρεάζουν τίποτα.",
  "inputAnalysis.notices.completely unconnected event.title": "{{The-event}} δεν συνδέεται με έναν καθηγητή, μια ομάδα ή μια αίθουσα.",
  "inputAnalysis.notices.description": "Άλλες πληροφορίες σχετικά με τα δεδομένα του προγράμματος.",
  "inputAnalysis.notices.empty course.description": "Τα μαθήματα χωρίς μαθήματα θα αγνοηθούν από τον αλγόριθμο.",
  "inputAnalysis.notices.empty course.title": "{{ the-course }} δεν περιέχει μαθήματα.",
  "inputAnalysis.notices.missing_overlap_species.description": "Χωρίς τον προσδιορισμό των αναγκαστικών επικαλύψεων μαθημάτων, ο αλγόριθμος δεν μπορεί να εγγυηθεί την επιδιωκόμενη επικάλυψη μεταξύ των μαθημάτων στο σύνολο των επικαλυπτόμενων μαθημάτων.",
  "inputAnalysis.notices.missing_overlap_species.title": "{{The-courses}} {{make(s)}} ένα σετ αλληλεπικαλυπτόμενων μαθημάτων με εξαναγκασμένες επικαλύψεις μαθημάτων που λείπουν.",
  "inputAnalysis.notices.notification_title": "Τα δεδομένα του προγράμματος είναι ελλιπή",
  "inputAnalysis.notices.title": "Προσοχή",
  "inputAnalysis.notices.unconnected event.description": "Μήπως ξεχάσατε να δώσετε στο μάθημα έναν δάσκαλο ή/και μια ομάδα;",
  "inputAnalysis.notices.unconnected event.title": "{{The-event}} δεν συνδέεται ούτε με δάσκαλο ούτε με ομάδα.",
  "inputAnalysis.ok.notification_title": "Τα δεδομένα του προγράμματος φαίνονται εντάξει!",
  "schedule.guaranteedTuition.label": "Εγγυημένα δίδακτρα",
  "schedule.guaranteedTuition.tabs.calendarExceptions.descr": "Εδώ μπορείτε να προσθέσετε αργίες και ρεπό. Οι ημέρες αυτές δεν θα ληφθούν υπόψη κατά τον υπολογισμό του συνολικού προγραμματισμένου χρόνου.",
  "view.private.schema.sub-pages.settings.page.tab.holidays.dialog.form.description.label": "Περιγραφή",
  "view.private.schema.sub-pages.settings.page.tab.holidays.dialog.form.range.label": "Εύρος ημερομηνιών",
  "schedule.guaranteedTuition.tabs.calendarExceptions.tabLabel": "Προσθέστε αργίες",
  "schedule.guaranteedTuition.tabs.calendarExceptions.title": "Προσθέστε αργίες",
  "schedule.guaranteedTuition.tabs.dayCount.descr": "Ο ακόλουθος πίνακας δείχνει τον αριθμό των φορών που κάθε ημέρα υπάρχει στο πρόγραμμα, δεδομένης της ημερομηνίας έναρξης και λήξης της, καθώς και των εκτός λειτουργίας ημερών που καθορίστηκαν παραπάνω. Τα Σάββατα και οι Κυριακές δεν λαμβάνονται υπόψη.",
  "schedule.guaranteedTuition.tabs.dayCount.title": "Περίληψη",
  "schedule.guaranteedTuition.tabs.guaranteedTuition.no_selected_entity": "Επιλέξτε μια ομάδα, έναν καθηγητή ή ένα μάθημα για μια σύνοψη του συνολικού προγραμματισμένου χρόνου. Σημειώστε ότι στους υπολογισμούς περιλαμβάνονται μόνο τα μαθήματα που έχουν καθορισμένο θέμα.",
  "schedule.guaranteedTuition.tabs.guaranteedTuition.tabLabel": "Εγγυημένα δίδακτρα",
  "schedule.guaranteedTuition.tabs.guaranteedTuition.table.per_day": "Ανά ημέρα",
  "schedule.guaranteedTuition.tabs.guaranteedTuition.table.total_count": "Αριθμός μαθημάτων",
  "schedule.guaranteedTuition.tabs.guaranteedTuition.table.total_time": "Συνολικός χρόνος",
  "schedule.header": "Πρόγραμμα",
  "schedule.navigation.automate": "Δημιουργία<br>προγράμματος",
  "schedule.navigation.data": "Χρονοδιάγραμμα<br>δεδομένα",
  "schedule.navigation.dutyAssignment": "Καθήκον<br>ανάθεση",
  "schedule.navigation.edit": "Επεξεργασία προγράμματος",
  "schedule.navigation.examine": "Εξετάστε το πρόγραμμα",
  "schedule.navigation.guaranteedTuition": "Εγγυημένα δίδακτρα",
  "schedule.navigation.lessonSettings": "Ρυθμίσεις μαθήματος<br>",
  "schedule.navigation.reports": "Αναφορές/<wbr>Στατιστικά",
  "schedule.navigation.scheduleDataAnalysis": "Ανάλυση δεδομένων",
  "schedule.navigation.scheduleWarnings": "Πρόγραμμα<br>προειδοποιήσεις",
  "schedule.navigation.settings": "Χρονοδιάγραμμα<br>Ρυθμίσεις",
  "schedule.print.header": "Εκτύπωση",
  "schedule.print.page.toolbar.color.descr": "Εκτύπωση χωρίς χρώμα για εξοικονόμηση μελανιού εκτυπωτή.",
  "schedule.print.page.toolbar.color.label": "Χρήση χρωμάτων",
  "schedule.print.page.toolbar.print.descr": "Από το παράθυρο διαλόγου εκτύπωσης μπορείτε επίσης να αποθηκεύσετε το έγγραφο ως αρχείο PDF.",
  "schedule.print.page.toolbar.print.label": "Εκτύπωση",
  "schedule.print.page.toolbar.QR.descr": "Ο σύνδεσμος οδηγεί στη διαδικτυακή προβολή.",
  "schedule.print.page.toolbar.QR.label": "Συμπεριλάβετε σύνδεσμο QR",
  "schema-defects.category.critical.label": "Κρίσιμο",
  "schema-defects.category.minor.label": "Δευτερεύον",
  "schema-defects.category.significant.label": "Σημαντικό",
  "schema-defects.courseClustering": "Ομαδοποίηση μαθημάτων",
  "schema-defects.courseDurationMismatch": "Συνολική διάρκεια εκδήλωσης",
  "schema-defects.doubleBooking": "Διπλή κράτηση",
  "schema-defects.eventDurationOverflow": "Διάρκεια εκδήλωσης",
  "schema-defects.fixed.count.explanation": "Υπάρχουν προειδοποιήσεις για τα χρονοδιαγράμματα αυτής της κατηγορίας που προκύπτουν εξ ολοκλήρου λόγω της διόρθωσης των μαθημάτων.",
  "schema-defects.insufficientBookingMargin": "Περιθώριο κράτησης",
  "schema-defects.insufficientBreakLength": "Μήκος διαλείμματος",
  "schema-defects.missingDependency": "Λείπει αίθουσα",
  "schema-defects.missingGroupsAndTeachers": "Λείπει ο δάσκαλος και η ομάδα",
  "schema-defects.non-fixed.count.explanation": "Υπάρχουν προειδοποιήσεις για τα χρονοδιαγράμματα αυτής της κατηγορίας που προκύπτουν από άλλους λόγους εκτός της διόρθωσης των σχετικών μαθημάτων.\n",
  "schema-defects.overflow": "Σπασμένο πλαίσιο χρονοδιαγράμματος",
  "schema-defects.overlap": "Σύγκρουση",
  "schema-defects.overtime": "Υπερωρίες",
  "schema-defects.unavailableDay": "Απαγορευμένη ημέρα",
  "settings.index.copy": "Αντιγραφή κλειδιού API",
  "settings.index.error": "Δεν ήταν δυνατή η δημιουργία κλειδιού API",
  "settings.integrationsOut.form.title": "Δημιουργία κλειδιού API",
  "settings.integrationsOut.menu": "Εξαγωγή API",
  "settings.integrationsOut.page.descr": "Χρησιμοποιώντας την παρακάτω λειτουργία, μπορείτε να δημιουργήσετε νέα κλειδιά API για να διαθέσετε δεδομένα χρονοδιαγράμματος σε άλλους παρόχους υπηρεσιών. Επικοινωνήστε με τον άλλο πάροχο και ελέγξτε αν μπορούν να εισάγουν δεδομένα χρονοδιαγράμματος μέσω του προτύπου SS 12000 API.",
  "settings.integrationsOut.page.title": "Εξαγωγή δεδομένων μέσω API (SS 12000:2020)",
  "settings.integrationsOut.shared.description": "Περιγραφή",
  "settings.integrationsOut.shared.displayName": "Όνομα",
  "settings.integrationsOut.shared.integrationType": "Πλατφόρμα",
  "settings.integrationsOut.table.copyKey": "Πλήκτρο αντιγραφής",
  "settings.integrationsOut.table.createdAt": "Δημιουργήθηκε",
  "settings.integrationsOut.table.legend": "Κλειδιά ΑΡΙ",
  "settings.integrationsOut.table.usedAt": "Τελευταία δραστηριότητα",
  "shared.components.schema-filter-dropdowns coalesced": "Προσθήκη φίλτρου",
  "shared.components.schema-filter-dropdowns search course event": "Αναζήτηση για μάθημα",
  "shared.dialogs.add-duration-set.label": "Εισάγετε τη διάρκεια του μαθήματος",
  "shared.dialogs.add-duration-set.title": "Νέο σύνολο διάρκειας",
  "shared.dialogs.company.description": "Δημιουργεί και προσθέτει ένα σχολείο στον οργανισμό σας.",
  "shared.dialogs.company.title": "Δημιουργήστε ένα σχολείο",
  "shared.dialogs.confirm-action.title": "Είστε σίγουρος;",
  "shared.dialogs.confirm-remove.confirm": "Καταλαβαίνω",
  "shared.dialogs.confirm-remove.sub-title": "Αυτές οι ενέργειες δεν μπορούν να ανακληθούν",
  "shared.dialogs.confirm-remove.title": "Επιβεβαίωση διαγραφής",
  "shared.dialogs.login.description": "Για να ανανεώσετε τη συνεδρία σας πρέπει να συνδεθείτε ξανά. Αν θέλετε να ακυρώσετε τη συνεδρία σας, απλά κλείστε αυτό το παράθυρο διαλόγου.",
  "shared.dialogs.login.title": "Η συνεδρία έληξε",
  "shared.dialogs.overlappableEventSets.description.forced": "Τα μαθήματα σε κάθε σετ θα είναι κεντραρισμένα το ένα πάνω στο άλλο και έτσι αναγκάζονται να επικαλύπτονται. Οποιοσδήποτε αριθμός μαθημάτων στο σετ επικαλυπτόμενων γεγονότων μπορεί να αναγκαστεί να επικαλύψει το ένα το άλλο. Μερικές φορές, ωστόσο, ορισμένες διαμορφώσεις επικάλυψης θα προκαλέσουν προβλήματα λόγω χρονικών περιορισμών.",
  "shared.dialogs.overlappableEventSets.description.set": "Ο αλγόριθμος θα προσπαθήσει να ομαδοποιήσει τα μαθήματα σε κάθε σύνολο, αλλά δεν είναι εγγυημένη η πλήρης επικάλυψη μεταξύ τους. Για παράδειγμα, τα μαθήματα που ανήκουν στο ίδιο μάθημα δεν επιτρέπεται ποτέ να επικαλύπτονται, εκτός αν εξαναγκαστούν.",
  "shared.dialogs.overlappableEventSets.error.set": "Τουλάχιστον ένα μάθημα στο σύνολο των αλληλοεπικαλυπτόμενων μαθημάτων δεν έχει ορισμένη υποχεωτική αλληλοεπικάλυψη",
  "shared.dialogs.overlappableEventSets.forceOverlap": "Επικάλυψη μαθήματος δύναμης",
  "shared.dialogs.overlappableEventSets.gridView": "Προβολή πλέγματος",
  "shared.dialogs.overlappableEventSets.listView": "Προβολή λίστας",
  "shared.dialogs.overlappableEventSets.new.forced": "Αφήστε ένα μάθημα εδώ για να δημιουργήσετε ένα νέο σετ μαθημάτων με αναγκαστική επικάλυψη",
  "shared.dialogs.overlappableEventSets.new.set": "Αφήστε ένα μάθημα εδώ για να δημιουργήσετε ένα νέο σετ αλληλεπικαλυπτόμενων μαθημάτων",
  "shared.dialogs.overlappableEventSets.noCourses": "Δεν βρέθηκαν τμήματα",
  "shared.dialogs.overlappableEventSets.noEvents": "Δεν βρέθηκαν μαθήματα",
  "shared.dialogs.overlappableEventSets.warning.forced": "Ένα αναγκαστικό σετ αλληλεπικαλυπτόμενων μαθημάτων θα πρέπει να περιέχει τουλάχιστον δύο μαθήματα",
  "shared.dialogs.overlappableEventSets.warning.set": "Ένα σετ αλληλεπικαλυπτόμενων μαθημάτων πρέπει να περιέχει τουλάχιστον δύο μαθήματα",
  "shared.dialogs.sync.collections": "Επιλογή δεδομένων χρονοδιαγράμματος",
  "shared.dialogs.sync.error.0": "Δεν ήταν δυνατή η πιστοποίηση ταυτότητας στην επιλεγμένη πλατφόρμα με τα διαπιστευτήριά σας. Εάν πρόκειται για σφάλμα, επικοινωνήστε με την υποστήριξη.",
  "shared.dialogs.sync.error.1": "Δεν ήταν δυνατή η λήψη δεδομένων από την επιλεγμένη πλατφόρμα. Κάτι μπορεί να έχει ρυθμιστεί λανθασμένα",
  "shared.dialogs.sync.last_sync": "Τελευταίος συγχρονισμός",
  "shared.dialogs.sync.modified": "Τροποποιείται μόνο μετά",
  "shared.dialogs.sync.platform": "Επιλέξτε πλατφόρμα",
  "shared.dialogs.sync.synced_data": "Τροποποιημένα δεδομένα χρονοδιαγράμματος",
  "shared.dialogs.sync.time": "Επιλέξτε περίοδο",
  "shared.dialogs.sync.title": "Συγχρονίστε το πρόγραμμά σας",
  "shared.dialogs.users.description": "Πληκτρολογήστε μια διεύθυνση ηλεκτρονικού ταχυδρομείου και επιλέξτε ένα σχολείο για να δημιουργήσετε μια σύνδεση στο επιλεγμένο σχολείο.",
  "shared.dialogs.users.title": "Δημιουργία χρήστη",
  "shared.form-fields.available-locations.empty-repository": "Δεν υπάρχουν διαθέσιμες αίθουσες",
  "shared.form-fields.available-locations.groups": "Ομάδα αιθουσών",
  "shared.form-fields.available-locations.locations": "Αίθουσες",
  "shared.form-fields.available-locations.select-null": "Χωρίς αίθουσα",
  "shared.form-fields.available-locations.selected-null": "Χωρίς αίθουσα",
  "shared.form-fields.components.schema-filter-dropdowns search course event": "Αναζήτηση μαθήματος",
  "shared.forms.change-password-form.current_password": "Τρέχων κωδικός πρόσβασης",
  "shared.forms.change-password-form.new_password": "Νέος κωδικός πρόσβασης",
  "shared.forms.change-password-form.repeat_password": "Επανάληψη κωδικού πρόσβασης",
  "shared.forms.company.name": "Όνομα",
  "shared.forms.company.organization": "Μητρική οργάνωση",
  "shared.forms.company.theme": "Θέμα",
  "shared.forms.users.company": "Επιλέξτε σχολείο",
  "shared.forms.users.username": "Ηλεκτρονικό ταχυδρομείο",
  "source.set_fail": "Δεν ήταν δυνατή η ενημέρωση της τιμής",
  "source.set_success": "Η ενημέρωση πέτυχε",
  "source.set_timeout": "Η ενημέρωση τερματίστηκε χρονικά",
  "source.validation_fail": "Μη έγκυρη ενημέρωση",
  "table.itemsPerPageLabel": "Γραμμές ανά σελίδα",
  "table.nextPageLabel": "Επόμενη σελίδα",
  "table.previousPageLabel": "Προηγούμενη σελίδα",
  "tables.courses.values.overridden": "Η τιμή δεν κληρονομείται από όλα τα μαθήματα του μαθήματος.",
  "tables.notifications.alerts.error.title": "Αδυναμία ενημέρωσης των προτιμήσεων",
  "tables.notifications.alerts.success.descr": "Εάν είναι ανοιχτές άλλες καρτέλες, πρέπει να επαναφορτωθούν για να πραγματοποιηθούν οι αλλαγές.",
  "tables.notifications.alerts.success.title": "Οι προτιμήσεις ενημερώθηκαν επιτυχώς",
  "tables.notifications.columns.category.descr": "Οι ειδοποιήσεις κάθε κατηγορίας εμφανίζονται μαζί.",
  "tables.notifications.columns.category.name": "Κατηγορία",
  "tables.notifications.columns.category.values.generalError": "Γενικά σφάλματα",
  "tables.notifications.columns.category.values.inputAnalysisError": "Αναλυτής εισόδου: κρίσιμα σφάλματα",
  "tables.notifications.columns.category.values.inputAnalysisNotice": "Αναλυτής εισόδου: ειδοποιήσεις",
  "tables.notifications.columns.category.values.inputAnalysisWarning": "Αναλυτής εισόδου: προειδοποιήσεις",
  "tables.notifications.columns.category.values.other": "Άλλο",
  "tables.notifications.columns.location.descr": "Σε ποιο σημείο της οθόνης θα εμφανίζονται οι ειδοποιήσεις αυτής της κατηγορίας.",
  "tables.notifications.columns.location.name": "Τοποθεσία",
  "tables.notifications.columns.location.values.bottom left": "Κάτω αριστερή γωνία",
  "tables.notifications.columns.location.values.bottom right": "Κάτω δεξιά γωνία",
  "tables.notifications.columns.location.values.top left": "Επάνω αριστερή γωνία",
  "tables.notifications.columns.location.values.top right": "Επάνω δεξιά γωνία",
  "tables.notifications.columns.mode.descr": "Εάν αυτές οι ειδοποιήσεις είναι ενεργοποιημένες, σε σίγαση ή απενεργοποιημένες.",
  "tables.notifications.columns.mode.name": "Λειτουργία",
  "tables.notifications.columns.mode.values.mute": "Σίγαση",
  "tables.notifications.columns.mode.values.off": "Απενεργοποιημένο",
  "tables.notifications.columns.mode.values.on": "Στο",
  "tables.notifications.columns.sound.descr": "Ποιος ήχος θα αναπαράγεται όταν λαμβάνεται μια ειδοποίηση.",
  "tables.notifications.columns.sound.name": "Ήχος",
  "tables.notifications.columns.test.descr": "Δοκιμάστε να στείλετε μια εικονική ειδοποίηση.",
  "tables.notifications.columns.test.name": "Δοκιμή",
  "tables.notifications.columns.test.value": "αποστολή",
  "tables.notifications.columns.volume.descr": "Η ένταση του ήχου σε σχέση με το κύριο επίπεδο έντασης ήχου.",
  "tables.notifications.columns.volume.name": "Έκδοση",
  "validators.email": "Μη έγκυρη διεύθυνση ηλεκτρονικού ταχυδρομείου",
  "validators.equal": "Οι τιμές δεν ταιριάζουν",
  "validators.invalidDate": "Μη έγκυρη ημερομηνία",
  "validators.invalidDiscretization": "Πρέπει να είναι πολλαπλάσιο των 5 λεπτών",
  "validators.invalidLaterDate": "Πρέπει να είναι μεταγενέστερη ημερομηνία",
  "validators.invalidMultiple": "Πρέπει να είναι πολλαπλάσιο του 5",
  "validators.invalidTime": "Άκυρος χρόνος (διαστήματα 5 λεπτών)",
  "validators.matDatepickerMax": "Πρέπει να επιλέξετε προγενέστερη ημερομηνία",
  "validators.matDatepickerMin": "Πρέπει να επιλέξετε μια μεταγενέστερη ημερομηνία",
  "validators.matEndDateInvalid": "Μη έγκυρο εύρος ημερομηνιών",
  "validators.matStartDateInvalid": "Μη έγκυρο εύρος ημερομηνιών",
  "validators.max": "Αυτή η τιμή είναι πολύ υψηλή",
  "validators.min": "Αυτή η τιμή είναι πολύ χαμηλή",
  "validators.minLength": "Πολύ μικρό",
  "validators.minlength": "Πολύ μικρό",
  "validators.noMatch": "Λάθος email ή κωδικός πρόσβασης",
  "validators.outOfBounds": "Η τιμή είναι εκτός ορίων",
  "validators.required": "Το πεδίο αυτό είναι υποχρεωτικό",
  "validators.usernameOccupied": "Το email χρησιμοποιείται",
  "view.administrator.organizations.table.add": "Δημιουργία σχολείου",
  "view.administrator.organizations.table.createdAt": "Δημιουργήθηκε στο",
  "view.administrator.organizations.table.header": "Σχολεία",
  "view.administrator.organizations.table.login": "Μπείτε σε αυτό το σχολείο",
  "view.administrator.organizations.table.name": "Όνομα",
  "view.administrator.page.menu.organizations": "Τα σχολεία μου",
  "view.administrator.users.table.add": "Δημιουργία χρήστη",
  "view.administrator.users.table.administrator": "Διαχειριστής",
  "view.administrator.users.table.company": "Σχολείο/Οργανισμός",
  "view.administrator.users.table.createdAt": "Δημιουργήθηκε στο",
  "view.administrator.users.table.header": "Λογαριασμοί χρηστών",
  "view.administrator.users.table.login": "Σύνδεση σε αυτό το σχολείο",
  "view.administrator.users.table.mail.fail": "Ο σύνδεσμος ενεργοποίησης δεν μπόρεσε να σταλεί στον χρήστη",
  "view.administrator.users.table.mail.success": "Ο σύνδεσμος ενεργοποίησης εστάλη στον χρήστη",
  "view.administrator.users.table.mail.tooltip": "Αποστολή συνδέσμου ενεργοποίησης στον χρήστη",
  "view.administrator.users.table.restricted": "Περιορισμένο",
  "view.administrator.users.table.role": "Ρόλος χρήστη",
  "view.administrator.users.table.unrestricted": "Προγραμματιστής",
  "view.administrator.users.table.username": "Ηλεκτρονικό ταχυδρομείο",
  "view.demo.schedule.components.editor_contact_sub_title": "Για να μάθετε περισσότερα για το προϊόν μας, συμπληρώστε τη διεύθυνση ηλεκτρονικού ταχυδρομείου σας και θα επικοινωνήσουμε μαζί σας για περισσότερες πληροφορίες.",
  "view.demo.schedule.components.editor_contact_title": "Το πρόγραμμα έχει ολοκληρωθεί",
  "view.demo.schedule.components.introduction_contact_submit": "Ενδιαφέρομαι",
  "view.demo.schedule.components.introduction_slide_1": "Καλώς ήρθατε!",
  "view.demo.schedule.components.introduction_slide_2": "Έχουμε προ-συμπληρώσει τα δεδομένα του προγράμματος για ένα μεσαίου μεγέθους σουηδικό σχολείο.",
  "view.demo.schedule.components.introduction_slide_3": "Το μόνο που απομένει είναι να κάνετε κλικ στην επιλογή \"έναρξη αυτόματου προγραμματισμού\", να περιμένετε μερικά λεπτά και έχετε δημιουργήσει το πρόγραμμά σας.",
  "view.demo.schedule.page.exit": "Έξοδος από το demo",
  "view.demo.schedule.page.restart": "Επανεκκίνηση του demo",
  "view.private.integrations.in.connectionTo": "Σύνδεση με",
  "view.private.integrations.in.descr": "Παρακάτω θα βρείτε πιθανές ενσωματώσεις API για την ανταλλαγή πληροφοριών με εξωτερικούς παρόχους υπηρεσιών.",
  "view.private.integrations.in.error.get": "Δεν ήταν δυνατή η ανάκτηση δεδομένων χρονοδιαγράμματος. Παρακαλούμε επικοινωνήστε με την υποστήριξη εάν το πρόβλημα παραμένει.",
  "view.private.integrations.in.error.postOrPatch": "Τα στοιχεία της ενσωμάτωσης δεν μπόρεσαν να αποθηκευτούν. Παρακαλούμε επικοινωνήστε με την υποστήριξη εάν το πρόβλημα παραμένει.",
  "view.private.integrations.in.import": "Εισαγωγή δεδομένων",
  "view.private.integrations.in.menu": "Ενσωματώσεις",
  "view.private.integrations.in.SchoolSoftDialog.tab1.title": "Όνομα και περίοδος",
  "view.private.integrations.in.SchoolSoftDialog.tab2.descr": "Προς το παρόν δεν είναι δυνατή η απευθείας εισαγωγή δωματίων μέσω του API του SchoolSoft. Ωστόσο, έως ότου επιλυθεί αυτό, μπορούν να εισαχθούν με αντιγραφή-επικόλληση των αντίστοιχων δεδομένων σε μορφή διαχωρισμένων με καρτέλες ή κόμματα, τα οποία μπορείτε να βρείτε <a href=\"https://sms.schoolsoft.se/{{webapp}}/jsp/admin/right_admin_import_room.jsp?menu=admin_import_room\" target=\"_blank\">here</a>.",
  "view.private.integrations.in.SchoolSoftDialog.tab2.error": "Αδυναμία ανάλυσης του κειμένου",
  "view.private.integrations.in.SchoolSoftDialog.tab2.label": "Επικολλήστε το κείμενο του πίνακα εδώ",
  "view.private.integrations.in.SchoolSoftDialog.tab2.title": "Εισάγετε αίθουσες",
  "view.private.integrations.in.setupDialog.key": "Κλειδί API",
  "view.private.integrations.in.setupDialog.meta.schoolapp": "schoolapp",
  "view.private.integrations.in.setupDialog.meta.schoolId": "Αναγνωριστικό σχολείου",
  "view.private.integrations.in.setupDialog.meta.webapp": "webapp",
  "view.private.integrations.in.setupDialog.startsWith": "Ξεκινά με {{value}}",
  "view.private.integrations.in.setupDialog.title": "Λεπτομέρειες ενσωμάτωσης",
  "view.private.integrations.in.success": "Το πρόγραμμα έχει εισαχθεί με επιτυχία",
  "view.private.integrations.in.title": "Ενσωματώσεις μέσω API",
  "view.private.integrations.in.tooltip.delete": "Διαγραφή λεπτομερειών ενσωμάτωσης",
  "view.private.integrations.in.tooltip.modify": "Τροποποίηση λεπτομερειών ενσωμάτωσης",
  "view.private.integrations.in.validation.error.401": "Μη έγκυρες λεπτομέρειες ενσωμάτωσης",
  "view.private.integrations.in.validation.error.404": "Μη έγκυρες λεπτομέρειες ενσωμάτωσης",
  "view.private.integrations.in.validation.error.500": "Οι λεπτομέρειες ενσωμάτωσης δεν ήταν δυνατόν να επικυρωθούν",
  "view.private.integrations.in.validation.error.SchoolSoft.key": "Μη έγκυρο κλειδί API",
  "view.private.integrations.in.validation.error.SchoolSoft.schoolapp": "Μη έγκυρη τιμή schoolapp",
  "view.private.integrations.in.validation.error.SchoolSoft.webapp": "Μη έγκυρη τιμή webapp",
  "view.private.integrations.in.validation.error.unknown": "Οι λεπτομέρειες ενσωμάτωσης δεν ήταν δυνατόν να επικυρωθούν",
  "view.private.integrations.in.validation.valid": "Έγκυρες λεπτομέρειες ενσωμάτωσης",
  "view.private.integrations.in.validation.validating": "Επικύρωση λεπτομερειών ενσωμάτωσης",
  "view.private.menu.schema": "Προγράμματα",
  "view.private.menu.settings": "Ρυθμίσεις",
  "view.private.menu.tutorial": "Οδηγίες χρήσης",
  "view.private.menu.users": "Χρήστες",
  "view.private.schema.components.admentum.subtitle": "Εξαγωγή στο Admentum",
  "view.private.schema.components.idunsoft.subtitle": "Εξαγωγή στο IdunSoft",
  "view.private.schema.components.infomentor.subtitle": "Εξαγωγή στο InfoMentor",
  "view.private.schema.components.link.subtitle": "Αντιγράψτε το σύνδεσμο για αυτό το πρόγραμμα",
  "view.private.schema.components.link.success": "Ο σύνδεσμος αντιγράφηκε",
  "view.private.schema.components.link.title": "Πρόγραμμα προβολής στο διαδίκτυο",
  "view.private.schema.components.royalschedule.subtitle": "Εξαγωγή στο Royal Schedule",
  "view.private.schema.components.schoolsoft_displayName.subtitle": "Εξαγωγή στο SchoolSoft χρησιμοποιώντας ονόματα ως αναφορές",
  "view.private.schema.components.schoolsoft.subtitle": "Εξαγωγή στο SchoolSoft",
  "view.private.schema.components.table.actions": "Δράσεις",
  "view.private.schema.components.table.basis": "Δεδομένα προγράμματος",
  "view.private.schema.components.table.clone": "Αντιγραφή προγράμματος",
  "view.private.schema.components.table.createdAt": "Δημιουργήθηκε",
  "view.private.schema.components.table.editedAt": "Τελευταία επεξεργασία",
  "view.private.schema.components.table.header": "Τα προγράμματά μου",
  "view.private.schema.components.table.interval": "Διάρκεια",
  "view.private.schema.components.table.link": "σύνδεσμος προς πρόγραμμα προβολής στο διαδίκτυο",
  "view.private.schema.components.table.name": "Όνομα προγράμματος",
  "view.private.schema.components.table.public": "Δημόσιο",
  "view.private.schema.edit.toolbar.colorDescription": "Περιγραφή χρώματος",
  "view.private.schema.edit.toolbar.selectedPeriods": "Inkluderande perioder",
  "view.private.schema.page.create": "Δημιουργία προγράμματος",
  "view.private.schema.page.download_fail": "Δεν ήταν δυνατή η λήψη του προγράμματος",
  "view.private.schema.page.header": "Προγράμματα",
  "view.private.schema.page.sync": "Συγχρονισμός δεδομένων",
  "view.private.schema.page.upload": "Ανέβασμα αρχείου",
  "view.private.schema.sub-pages.schema-editor.components.event-form.fix-start": "Καθορίστε την ώρα αυτού του μαθήματος",
  "view.private.schema.sub-pages.schema-editor.components.event-form.make-visible": "Εμφάνιση στην προβολή προγράμματος",
  "view.private.schema.sub-pages.schema-editor.components.event-form.park": "Παρκάρετε αυτό το μάθημα",
  "view.private.schema.sub-pages.schema-editor.components.list.filter": "Φίλτρα μαθημάτων",
  "view.private.schema.sub-pages.schema-editor.components.list.filter.not_placed": "Παρκαρισμένα μαθήματα",
  "view.private.schema.sub-pages.schema-editor.components.list.filter.placed": "Τοποθετημένα μαθήματα",
  "view.private.schema.sub-pages.schema-editor.components.progress.info_PENDING": "Το πρόγραμμα έχει μπει στην ουρά. Θα ξεκινήσει σε λίγα λεπτά.",
  "view.private.schema.sub-pages.schema-editor.components.progress.info_STARTED": "Η εργασία έχει ξεκινήσει. Μπορεί να χρειαστούν μερικά λεπτά μέχρι να ολοκληρωθεί.",
  "view.private.schema.sub-pages.schema-editor.page.limited_state": "Η ανάλυση σύγκρουσης είναι απενεργοποιημένη",
  "view.private.schema.sub-pages.schema-editor.page.start_job": "Έναρξη αυτόματου προγραμματισμού",
  "view.private.schema.sub-pages.settings.components.default-values-form.fieldset.break-lengths": "Ελάχιστο μήκος διαλλείματος",
  "view.private.schema.sub-pages.settings.components.default-values-form.fieldset.other": "Άλλο",
  "view.private.schema.sub-pages.settings.components.default-values-form.fieldset.time-frame": "Χρονικό πλαίσιο",
  "view.private.schema.sub-pages.settings.components.default-values-form.fieldset.working-hours": "Ώρες εργασίας",
  "view.private.schema.sub-pages.settings.components.options-form.fieldset.overlappable-lessons-set": "Επικάλυψη μαθήματος",
  "view.private.schema.sub-pages.settings.components.options-form.requireForcedOverlappingEventsSets.description": "Με την ενεργοποίηση αυτής της επιλογής, όλα τα μαθήματα που ανήκουν σε ένα σετ επικαλυπτόμενων μαθημάτων πρέπει να τοποθετηθούν επίσης σε ένα σετ μαθημάτων με αναγκαστική επικάλυψη, δηλαδή σε μια αναγκαστική επικάλυψη μαθημάτων που έχει καθοριστεί από τον χρήστη. Από την άλλη πλευρά, αν απενεργοποιηθεί, αυτό δεν απαιτείται πλέον και τα μαθήματα που δεν έχουν αναγκαστική επικάλυψη ο αλγόριθμος θα προσπαθήσει -από μόνος του- να κάνει επικάλυψη με άλλα γεγονότα στο ίδιο σύνολο επικαλυπτόμενων μαθημάτων.",
  "view.private.schema.sub-pages.settings.components.options-form.requireForcedOverlappingEventsSets.label": "Απαιτεί αναγκαστική επικάλυψη μαθημάτων",
  "view.private.schema.sub-pages.settings.page.tab.default-values": "Προεπιλεγμένες τιμές",
  "view.private.schema.sub-pages.settings.page.tab.options": "Επιλογές",
  "view.private.schema.sub-pages.statistics.duty-assignments.empty": "Δεν υπάρχουν διαθέσιμα δεδομένα",
  "view.private.schema.sub-pages.statistics.duty-assignments.header": "Αναθέσεις καθηκόντων",
  "view.private.schema.sub-pages.statistics.duty-assignments.min_of_min_per_week": "{{current}} του {{total}} min / εβδομάδα",
  "view.private.schema.sub-pages.statistics.duty-assignments.min_per_week": "Ελάχιστα την εβδομάδα",
  "view.private.settings.sync.out": "Ενεργά χρονοδιαγράμματα",
  "view.private.tutorial.page.advanced": "Προχωρημένο",
  "view.private.tutorial.page.basic": "Βασικά στοιχεία",
  "view.private.tutorial.page.description.api": "Συγχρόνισε δεδομένα",
  "view.private.tutorial.page.description.bulk-update": "Μάθετε πώς να επεξεργάζεστε μαζικά",
  "view.private.tutorial.page.description.courses": "Μάθετε πώς να δημιουργείτε μαθήματα και εκδηλώσεις",
  "view.private.tutorial.page.description.create": "Δημιουργία προγράμματος",
  "view.private.tutorial.page.description.excel": "Ανεβάστε ένα αρχείο Excel",
  "view.private.tutorial.page.description.groups": "Μάθετε πώς να δημιουργείτε ομάδες",
  "view.private.tutorial.page.description.in-locations": "Μάθετε πώς να τροποποιείτε τις επιτρεπόμενες αίθουσες",
  "view.private.tutorial.page.description.locations": "Μάθετε πώς να δημιουργείτε αίθουσε;",
  "view.private.tutorial.page.description.locked-times": "Μάθετε πώς να μπλοκάρετε χρονοθυρίδες",
  "view.private.tutorial.page.description.teacher": "Μάθετε πώς να δημιουργείτε δασκάλους",
  "view.private.tutorial.page.description.time-frame": "Διαχείριση του χρονοδιαγράμματος",
  "view.private.tutorial.page.filter.category": "Κατηγορία",
  "view.private.tutorial.page.filter.category.adjustments": "Ρυθμίσεις",
  "view.private.tutorial.page.filter.category.conditions": "Συνθήκες",
  "view.private.tutorial.page.filter.category.data": "Δεδομένα προγράμματος",
  "view.private.tutorial.page.filter.category.edit": "Μη-αυτοματοποιημένος προγραμματισμός",
  "view.private.tutorial.page.filter.category.education": "Εκπαίδευση",
  "view.private.tutorial.page.filter.category.export": "Εξαγωγή δεδομένων προγράμματος",
  "view.private.tutorial.page.filter.category.import": "Εισαγωγή δεδομένων προγράμματος",
  "view.private.tutorial.page.filter.category.inputAnalyzer": "Αναλυτής εισόδου",
  "view.private.tutorial.page.filter.category.other": "Άλλα",
  "view.private.tutorial.page.filter.category.settings": "Ρυθμίσεις",
  "view.private.tutorial.page.filter.category.warnings": "Προειδοποιήσεις",
  "view.private.tutorial.page.filter.level": "Δυσκολία",
  "view.private.tutorial.page.filter.level.advanced": "Προχωρημένος",
  "view.private.tutorial.page.filter.level.beginner": "Αρχάριος",
  "view.private.tutorial.page.filter.level.intermediate": "Ενδιάμεσος",
  "view.private.tutorial.page.header": "Οδηγίες χρήσης",
  "view.private.tutorial.page.type.video": "Οδηγός βίντεο",
  "view.public.activation.approved": "Εγκεκριμένο",
  "view.public.activation.expired": "Ο σύνδεσμος έχει λήξει",
  "view.public.activation.invalid": "Μη έγκυρος σύνδεσμος",
  "view.public.activation.verifying": "Επαλήθευση...",
  "view.public.demo.valid_config": "Διαμόρφωση περιβάλλοντος",
  "view.public.login.fail": "Απέτυχε να συνδεθεί",
  "view.public.login.password": "Κωδικός πρόσβασης",
  "view.public.login.status_email_required": "Απαιτείται έγκυρη διεύθυνση ηλεκτρονικού ταχυδρομείου",
  "view.public.login.status_invalid_credentials": "Μη έγκυρα διαπιστευτήρια",
  "view.public.login.status_invalid_email_format": "Μη έγκυρη μορφή ηλεκτρονικού ταχυδρομείου",
  "view.public.login.status_password_required": "Απαιτείται κωδικός πρόσβασης",
  "view.public.login.status_reset_password_fail": "Αδυναμία αποστολής συνδέσμου επαναφοράς",
  "view.public.login.status_reset_password_success": "Ο σύνδεσμος επαναφοράς αποστέλλεται στο {{email}} εάν υπάρχει τέτοιος χρήστης",
  "view.public.login.username": "Διεύθυνση ηλεκτρονικού ταχυδρομείου",
  "view.public.web-viewer.not_public": "Το πρόγραμμα δεν εμφανίζεται δημόσια",
  "view.void.status": "Επικύρωση διαπιστευτηρίων",
  "common.delete_all": "Διαγραφή όλων",
  "schedule.navigation.daysCount": "Αριθμός ημερών",
  "common.whole": "Ολόκληρο",
  "view.private.schema.sub-pages.statistics.days-count.caption": "Ο πίνακας δείχνει πόσες φορές η κάθε ημέρα εμφανίζεται σε κάθε περίοδο και επίσης τον συνολικό αριθμό ημερών. Η περίοδος \"{{ whole }}\" αναφέρεται στην περίοδο που καθορίζεται από την ημερομηνία έναρξης και λήξης του προγράμματος.",
};