<form [formGroup]="form"
      novalidate
      class="form"
      (keydown)="keydown($event)"
      [ngSwitch]="type">
  <ng-template ngSwitchCase="default">
    <div class="content-default">

    </div>
  </ng-template>

  <ng-template ngSwitchCase="row" class="content-row">
    <div class="content-row">
      <div class="form-field-wrapper">
        <mat-form-field class="form-field" appearance="fill" style="font-size: 12px">
          <input matInput
                type="text"
                formControlName="firstName"
                id="firstName"
                placeholder="{{ 'attributes.person.firstName' | translate }}"
                autocomplete="off">
        </mat-form-field>
      </div>

      <div class="form-field-wrapper">
        <mat-form-field class="form-field" appearance="fill" style="font-size: 12px">
          <input matInput
                type="text"
                formControlName="lastName"
                id="lastName"
                placeholder="{{ 'attributes.person.lastName' | translate }}"
                autocomplete="off">
        </mat-form-field>
      </div>
    </div>
  </ng-template>

</form>
