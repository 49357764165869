import { Component,
         OnInit,
         Input,
         EventEmitter,
         Output,
         OnDestroy                       } from '@angular/core';
import { coerceBooleanProperty           } from '@angular/cdk/coercion';
import _                                   from 'lodash';
import { BehaviorSubject                 } from 'rxjs';

import { Populated as P                  } from '@app/shared/interfaces';
import { Key,
         Value,
         Filter,
         Transform                       } from './filter.interface';

@Component({
  selector: 'app-overlap-group-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit,
                                        OnDestroy {
  @Output() onChange = new EventEmitter<Value | null>();
  public filter      = new BehaviorSubject<Filter>([]);
  public transform:   Transform = {
    teachers: new Map(),
    groups:   new Map()
  };

  constructor() { }

  ngOnInit(): void {
  }

  @Input()
  get groups(): P.group[] {
    return this._groups;
  }
  set groups(_val: P.group[] | null) {
    this._groups = _.sortBy(_val ?? [], ['displayName', 'ids']);
    this._groups.forEach(x => this.transform.groups.set(x.id, x));
  }
  private _groups: P.group[] = [];

  @Input()
  get teachers(): P.teacher[] {
    return this._teachers;
  }
  set teachers(_val: P.teacher[] | null) {
    this._teachers = _.sortBy(_val ?? [], ['displayName', 'ids']);
    this._teachers.forEach(x => this.transform.teachers.set(x.id, x));
  }
  private _teachers: P.teacher[] = [];

  @Input({ transform: coerceBooleanProperty })
  public disableGroups: boolean = false;

  @Input({ transform: coerceBooleanProperty })
  public disableTeachers: boolean = false;

  get value(): Value | null {
    if (! this.filter.value?.length) return null;

    return this.filter.value.reduce((ob: Value, { key, value }) => {
      if (! ob[key]) ob[key] = [];
      (ob[key] as string[]).push(value);
      return ob;
    }, {});
  }

  get active(): number | null {
    return this.filter.value?.length || null;
  }

  public addFilter(_value: string, _key: Key): void {
    if (! _value) return;
    if (this.filter.value.some(({ key, value }) => key == _key && value == _value)) return;

    let { value } = this.filter;
    this.filter.next([...value, { key: _key, value: _value }]);
    this.onChange.emit(this.value);
  }

  public removeFilter(_value: string, _key: Key): void {
    this.filter.next(this.filter.value.filter(({ value, key }) => ! (_key == key && value == _value)));
    this.onChange.emit(this.value);
  }

  ngOnDestroy() {
    this.filter.complete();
  }
}
