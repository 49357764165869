import { Component,
         OnInit,
         Input,
         ElementRef,
         OnDestroy,
         Optional,
         HostBinding,
         Self,
         EventEmitter,
         Output,
         ChangeDetectionStrategy,
         ViewChild                       } from '@angular/core';
import { UntypedFormGroup,
         ControlValueAccessor,
         NgControl                       } from '@angular/forms';
import { MatSelectChange                 } from '@angular/material/select';
import { MatFormFieldControl             } from '@angular/material/form-field';
import { FocusMonitor                    } from '@angular/cdk/a11y';
import { coerceBooleanProperty           } from '@angular/cdk/coercion';
import { coerceNumberProperty            } from '@angular/cdk/coercion';
import { MatSelect                       } from '@angular/material/select';
import { asyncScheduler,
         Subject                         } from 'rxjs';
import _                                   from 'lodash';

@Component({
  selector: 'app-form-field-duration',
  templateUrl: './duration.component.html',
  styleUrls: ['./duration.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: DurationComponent
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DurationComponent implements OnInit,
                                          OnDestroy,
                                          ControlValueAccessor,
                                          MatFormFieldControl<number | undefined> {
  @ViewChild(MatSelect)      trigger:           MatSelect;
  @Output('onChange') emitter: EventEmitter<number> = new EventEmitter<number>();
  public durations:   number[]      = [];
  public form:        UntypedFormGroup;
  static nextId:      number        = 0;
  public stateChanges:Subject<void> = new Subject<void>();
  public focused:     boolean       = false;
  public errorState:  boolean       = false;
  public controlType: string        = 'duration-input';
  public id:          string        = `duration-input-${ DurationComponent.nextId++ }`;
  public describedBy: string        = '';
  public onChange = (_: any) => {};
  public onTouched = () => {};

  @HostBinding('attr.tabindex') __tabindex = 0;

  constructor(private _focusMonitor: FocusMonitor,
              private _elementRef: ElementRef<HTMLElement>,
              @Optional() @Self() public ngControl: NgControl) {

    this._setValues(this.discretization);

    _focusMonitor.monitor(_elementRef, true)
    .subscribe((origin: any) => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
  }

  public change(event: MatSelectChange): void {
    const { value } = event;
    this._value = value;

    this.pristine = _.isEqual(this._value, this._pristineValue);

    this._handleInput();

    this.emitter.emit(event.value);
  }

  private _setValues(_min: number): void {
    this.durations = [...Array(100).keys()].map((x: number) => (_min + (x * this.discretization)));
  }

  @Input()
  get min(): number { return this._min; }
  set min(_min: number | string) {
    const min = coerceNumberProperty(_min, this.discretization);
    this._min = min;
    this._setValues(min);
    this.stateChanges.next();
  }
  private _min: number = this.discretization;

  @Input()
  get discretization(): number { return this._discretization; }
  set discretization(value: number) {
    this._discretization = coerceNumberProperty(value, 5);
    this._setValues(this.min);
    this.stateChanges.next();
  }
  private _discretization: number = 5;

  get empty() {
    return this._value == null;
  }

  get shouldLabelFloat() { return this.focused || !this.empty; }

  get pristine(): boolean {
    return this._pristine;
  }
  set pristine(_val: boolean) {
    const val      = coerceBooleanProperty(_val);
    this._pristine = val;

    if (val) asyncScheduler.schedule(() => this.ngControl.control?.markAsPristine());

    this.stateChanges.next();
  }
  private _pristine:  boolean = true;

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get voidText(): string { return this._voidText; }
  set voidText(value: number | string | undefined) {
    this._voidText = value?.toString() ?? '';
    this.stateChanges.next();
  }
  private _voidText: string = '';

  @Input()
  get prefix(): string { return this._prefix; }
  set prefix(value: string) {
    this._prefix = value;
    this.stateChanges.next();
  }
  private _prefix: string = '';

  @Input()
  get suffix(): string { return this._suffix; }
  set suffix(value: string) {
    this._suffix = value;
    this.stateChanges.next();
  }
  private _suffix: string = '';

  @Input()
  get secondary(): number| null | undefined { return this._secondary; }
  set secondary(value: number| null | undefined) {
    this._secondary = value;
    this.stateChanges.next();
  }
  private _secondary: number| null | undefined;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    //this._disabled ? this.form?.disable() : this.form?.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get nullable(): boolean { return this._nullable; }
  set nullable(value: boolean | string) {
    this._nullable = coerceBooleanProperty(value);
  }
  private _nullable = false;

  @Input()
  get isPreferredDuration(): boolean { return this._isPreferredDuration; }
  set isPreferredDuration(value: boolean | string) {
    this._isPreferredDuration = coerceBooleanProperty(value);
  }
  private _isPreferredDuration = false;

  @Input()
  get preferred(): number { return this._preferred; }
  set preferred(value: number | undefined) {
    if (_.isNil(value)) return;
    this._preferred = value;
  }
  private _preferred: number;

  @Input()
  get variance(): number { return this._variance; }
  set variance(value: number | undefined) {
    if (_.isNil(value)) return;
    this._variance = value;
  }
  private _variance: number;

  @Input()
  get setVoidText(): string { return this._setVoidText; }
  set setVoidText(value: string) {
    this._setVoidText = value;
  }
  private _setVoidText: string;

  @Input()
  get value(): number | null | undefined {
    return this._value;
  }
  set value(_val: number | null | undefined) {
    this._value = this._pristineValue = _val;
    this.stateChanges.next();
  }
  private _value:         number | null | undefined;
  private _pristineValue: number | null | undefined;

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent) {
    if (! this.disabled)
      this.trigger?.open();
  }

  writeValue(val: number): void {
    this.value = val;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  _handleInput(): void {
    this.onChange(this.value);
  }

  static ngAcceptInputType_disabled: boolean | string | null | undefined;
  static ngAcceptInputType_required: boolean | string | null | undefined;
}
