<div class="toolbar">
  <div class="row">
    @if(inherit) {
      <mat-slide-toggle
        class="inherit-toggle"
        labelPosition="before"
        color="accent"
        (change)="inheritToggle($event)"
        [checked]="inheritValue()">
        {{ 'attributes.event.inherit' | translate }}
      </mat-slide-toggle>
    }

    @if(! pristine()) {
      <button mat-stroked-button
              class="action reset"
              color="primary"
              (click)="resetValue()">
        <div>
          <span>{{ 'common.restore' | translate }}</span>
          <mat-icon class="royal-icon">undo</mat-icon>
        </div>
      </button>
    }

    <span class="spacer"></span>

    @if(nullable) {
      <button mat-stroked-button
              class="action reset"
              color="primary"
              (click)="clearValue()">
        <div>
          <span>{{ 'common.reset' | translate }}</span>
        </div>
      </button>
    }
  </div>

  <div class="row search dense-4">
    @if (hasCoalesced()) {
      <mat-button-toggle-group>
        <mat-button-toggle checked
                           [matTooltip]="['common', type] | join:'.' | translate"
                           (click)="setList()"
                           class="flex align-center">
          <mat-icon class="royal-icon material-icons-outlined">{{ type | collectionIcon }}</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle [matTooltip]="['common', coalescedType] | join:'.' | translate"
                            (click)="setList(coalescedType)"
                            class="flex align-center">
          <mat-icon class="royal-icon material-icons-outlined">{{ coalescedType! | collectionIcon }}</mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>
    }

    <app-search class="search" (paste)="paste($event)" (onChange)="onSearch.next($event)"></app-search>
  </div>

  @if((groups | async)?.length) {
    <div class="row search dense-4 flex flex-justify-end no-min-height">
      <!-- <select name="cars" id="cars">
        @for (group of groups | async; track $index) {
          <option [value]="group.id">{{ group | displayName }}</option>
        }
      </select> -->
      <!-- <mat-select class="filter-select"
                  [panelWidth]="null"
                  disableRipple
                  multiple
                  (selectionChange)="onGroupSelect?.next($event.value)"
                  [placeholder]="'common.filter_by_groups' | translate">
        @for (group of groups | async; track $index) {
          <mat-option [value]="group.id">{{ group | displayName }}</mat-option>
        }
      </mat-select> -->
    </div>
  }

  @if(hasMultiple()) {
    <div class="row">
      <mat-checkbox [checked]="checked | async"
                    cypress="toggle-all"
                    [disabled]="! isMultiple()"
                    [indeterminate]="indeterminate | async"
                    (change)="toggleAll($event)">
      </mat-checkbox>

      @if('persons' | environmentCollection) {
        <div class="number-selected">
          @if((primarySelection | async)?.size) {
            <div>
              {{ (primarySelection | async)!.size | totalSelected:type:(coalescedSelection | async)!.size | lowercase | capitalizeFirst }}
            </div>
          } @else {
            {{ type == 'persons' ? ('common.none_plural' | translate) : '0 ' + ('common.' + type | translate | lowercase) }}
          }
        </div>
      }

      <span class="spacer"></span>

      <button mat-icon-button
              type="button"
              [matTooltip]="(override.value ? 'common.all' : 'common.selected') | translate"
              (click)="filterSelected()"
              color="none">
        <mat-icon class="royal-icon material-icons-outlined">
          {{ override.value ? 'rule' : 'checklist_rtl' }}
        </mat-icon>
      </button>
    </div>
  }
</div>

<div class="content position-relative"
     [style.width.px]="((numColumns | async) ?? 0) * 400">
  @if(! (isEmpty | async) && (filtered | async)?.length) {
    @if(isMultiple()) {
      <ng-container [ngTemplateOutlet]="defSelLstTpl"
                    [ngTemplateOutletContext]="{ multiple: true }">
      </ng-container>
    } @else {
      <ng-container [ngTemplateOutlet]="defSelLstTpl"
                    [ngTemplateOutletContext]="{ multiple: false }">
      </ng-container>
    }
  }

  @if((loading | async) || computing()) {
    <app-loading loading whiteOverlay></app-loading>
  }

  @if((isEmpty | async) || ! (filtered | async)?.length) {
    <div class="empty-container">
      <img src="assets/images/svg/empty.svg">
    </div>
  }
</div>

<ng-template #defSelLstTpl let-isMultipleSelect="multiple">
  <cdk-virtual-scroll-viewport [itemSize]="40"
                              class="dark-scrollbar inherit-height">
    <div *cdkVirtualFor="let set of filtered | async; let i = index"
        class="table-row"
        [class.multiple]="isMultipleSelect">
      @for (item of set; track item.id; let j = $index) {
        <div #option
          class="item"
          (mousedown)="dragStart(i, j, selection.value.has(item.id)!)"
          (mouseenter)="dragEnter(i, j)"
          [ngClass]="(availabilityMap | async)?.get(item.id ?? '')"
          [class.animate-left]="!isCoalescedSelection()"
          [class.pseudo-selected]="pseudoSelected().has(i * ((numColumns | async) ?? 0) + j)"
          [class.indicate-availability]="!! (availabilityMap | async)">
          @if(isMultipleSelect) {
            @if(pseudoSelected().has(i * ((numColumns | async) ?? 0) + j) ? pseudoState() : (selection | async)!.has(item.id)) {
              <mat-icon color="accent" class="padding-left-8">check_box</mat-icon>
            } @else {
              <mat-icon class="padding-left-8 royal-icon">check_box_outline_blank</mat-icon>
            }
          } @else {
            @if((selection | async)!.has(item.id)) {
              <mat-icon color="accent" class="padding-left-8">radio_button_checked</mat-icon>
            } @else {
              <mat-icon class="padding-left-8 royal-icon">radio_button_unchecked</mat-icon>
            }
          }
          <ng-container [ngTemplateOutlet]="defOptTpl" [ngTemplateOutletContext]="{ group: item }"></ng-container>
        </div>
      }
      @if (set.length < ((numColumns | async) ?? 0)) {
        @for (_ of ' '.repeat(((numColumns | async) ?? 0) - set.length).split(''); let l = $index; track l) {
          <div class="flex-1 inherit-height" (mouseenter)="dragEnter(i, l + set.length)"></div>
        }
      }
    </div>
  </cdk-virtual-scroll-viewport>
</ng-template>

<ng-template #defOptTpl let-group="group">

  <div class="list-text-wrapper">
    <div class="list-text-displayName">
      {{ group.displayName | displayName }}
      @if(selectionType() == 'persons') {
        <div>{{ group.SSN?.value }}</div>
      }
    </div>

    @if(! (selection | async)!.has(group.id) && isQuasiSelected().get(group.id)) {
      <mat-icon class="help"
                [matTooltip]="'shared.form-fields.groups.selection-warning' | translate">
        warning_value
      </mat-icon>
    }

    @if(selectionType() == 'persons' && group.group) {
      <div class="members"
           [class.selected]="(primarySelection | async)?.has(group.group)">
        {{ group.group | displayName }}
      </div>
    }

    @if(group.members && ('persons' | environmentCollection)) {
      <div class="members"
          [class.inactive]="! flat && !(selection | async)!.has(group.id)"
          [class.warning]="! (selection | async)!.has(group.id) && isQuasiSelected().get(group.id)">
        @if(group.members.length) {
          <div>
            @if(flat) {
              <span>
                {{ group | selectedMembers:value:!!(selection | async)!.has(group.id):coalescedValue }}
              </span>
            }

            {{ [group.members.length, group.excluded] | filter:undefined | join:' - '}}  {{ 'common.persons' | translate }}
          </div>
        } @else {
          {{ 'common.empty' | translate }}
        }
      </div>
    }

  </div>
</ng-template>