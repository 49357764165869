import { Component,
         OnInit,
         Input,
         signal,
         Output,
         EventEmitter,
         OnDestroy                       } from '@angular/core';
import { coerceBooleanProperty           } from '@angular/cdk/coercion';
import { UntypedFormGroup,
         UntypedFormBuilder,
         Validators                      } from '@angular/forms';
import { animationFrameScheduler,
         Subject                         } from 'rxjs';
import $                                   from 'jquery';


import { MAT_CHECKBOX_DEFAULT_OPTIONS    } from 'app/common';
import { LoggerService                   } from 'app/core/logger/logger.service';
import { DivisionSettings,
         Populated as P                  } from 'app/shared/interfaces';
import { EnvironmentService,
         UserPreferencesService          } from 'app/core';
import { FormCore                        } from './form-core';

@Component({
  selector: 'app-event-form',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss'],
  providers: [
    {
      provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
      useValue: { clickAction: 'noop' }
    }
  ]
})
export class EventComponent extends FormCore
                                  implements OnInit,
                                             OnDestroy {
  @Output() onSubmit:                 EventEmitter<void> = new EventEmitter<void>();
  private onDestroy:                  Subject<boolean> = new Subject<boolean>();
  protected form?:                        UntypedFormGroup;
  protected submitted:                   boolean         = false;
  protected loading = signal(true);


  protected _event?: P.event;

  constructor(private _fb:          UntypedFormBuilder,
              private _environment: EnvironmentService,
              private _logger:      LoggerService,
              private _preferences: UserPreferencesService) {
    super(_preferences, _environment);
    this._setForm();
  }

  ngOnInit() {
  }

  public keydown(event: KeyboardEvent): void {
    if (event.key == 'Enter' && this.form?.valid) {
      this.onSubmit.next();
    }
  }

  @Input()
  set focus(_field: string) {
    animationFrameScheduler.schedule(() => {
      const elem = $(`#${ _field }`)[0];
      elem?.focus();
    })
  }

  @Input()
  get value(): P.event {
    const { controls } = this.form!;
    return Object.fromEntries(
             Object.keys(controls)
                   .map((key): [string, typeof controls[0]] => [key, controls[key]])
                   .filter(([, control]) => control.valid && ! control.pristine)
                   .map(([key, control]) => [key, control.value])
           ) as P.event;
  }
  set value(_val: P.event) {
    if (! _val) return;

    // transform from undefined to null (don't know why this is not needed for the course form...)
    (_val as any).period      = _val.period      ?? null;
    (_val as any).lockedTimes = _val.lockedTimes ?? null;
    (_val as any).days        = _val.days        ?? null;

    this.form?.reset();
    if (! _val) return;
    this._event = _val;
    this.form?.patchValue(_val);
    this.loading.set(false);
  }

  get course (): Partial<P.course> | undefined {
    return this._event?.course as Partial<P.course>;
  }

  get pristine(): boolean {
    return !! this.form?.pristine;
  }

  get valid(): boolean {
    return !! this.form?.valid;
  }

  @Input()
  get settings(): DivisionSettings | null {
    return this._settings;
  }
  set settings(_val: DivisionSettings | null) {
    if (! _val) return;
    this._settings = _val;
  }
  private _settings: DivisionSettings | null = null;

  @Input()
  get locationList(): P.location[] {
    return this._locationList;
  }
  set locationList(_val: P.location[] | null) {
    this._locationList = _val ?? [];
  }
  private _locationList: P.location[] = [];

  @Input()
  get periodList(): any[] {
    return this._periodList;
  }
  set periodList(_val: any[] | null) {
    this._periodList = _val ?? [];
  }
  private _periodList: any[] = [];

  @Input()
  get hideCalendars(   ): boolean  { return this._hideCalendars;                        }
  set hideCalendars(_val: boolean | string) { this._hideCalendars = coerceBooleanProperty(_val); }
  private _hideCalendars: boolean = false;

  private _setForm(): void {
    this.form = this._fb.group({
      ids:                [undefined, []],
      displayName:        [undefined, []],
      days:               [undefined, []],
      minBreakLength:     [undefined, []],
      duration:           [undefined, [<any>Validators.required, <any>Validators.min(this.discretization), <any>Validators.max(1000)]],
      preferredDuration:  [undefined, [<any>Validators.required, <any>Validators.min(this.discretization), <any>Validators.max(1000)]],
      durationVariance:   [undefined, [<any>Validators.min(0), <any>Validators.max(1000)]],
      start:              [undefined, []],
      end:                [undefined, []],
      course:             [undefined, []],
      groups:             [undefined, []],
      teachers:           [undefined, []],
      participants:       [undefined, []],
      locations:          [undefined, []],
      lockedTimes:        [undefined, []],
      intervals:          [undefined, []],
      centerOfAttraction: [undefined, []],
      period:             [null, []],
    });
  }

  public reset(): void {
    this.form?.reset();
    this.form?.markAsPristine();
    this.form?.markAsUntouched();
  }

  ngOnDestroy() {
    this.onDestroy.next(true);
    this.onDestroy.complete();
  }
}
