<!-- force rerender on stateChanges.next() otherwise it is not guaranteed to show the latest values due to some lazy rendering-->
{{ stateChanges | async }}

<div class="center-of-attraction-select-label clickable"
     (menuClosed)="closed()"
     (menuOpened)="opened()"
     [matMenuTriggerFor]="menu">
  <app-form-field-center-of-attraction-display-value [value]="internalValue"
                                                     [voidText]="voidText">
  </app-form-field-center-of-attraction-display-value>
</div>

<mat-menu yPosition="below"
          #menu="matMenu"
          class="center-of-attraction-panel no-vertical-padding"
          [overlapTrigger]="true">

  <div class="toolbar" (click)="$event.stopPropagation()">
    <div class="row">
      <button mat-stroked-button
              class="action reset"
              color="primary"
              (click)="resetValue()"
              *ngIf="! pristine && reset && valid">
        <div>
          <span>{{ 'common.restore' | translate }}</span>
          <mat-icon class="royal-icon">undo</mat-icon>
        </div>
      </button>

      <span class="spacer"></span>

      <button mat-icon-button
              class="close right button"
              type="button"
              (click)="trigger?.closeMenu()"
              matTooltip="{{ 'common.close' | translate }}">
        <mat-icon class="royal-icon">close</mat-icon>
      </button>
    </div>
  </div>

  <div (click)="$event.stopPropagation()"
       class="content"
       *ngIf="! isVoid && form"
       [formGroup]="form">

    <div class="type-container">
      <mat-radio-group class="radio-group" formControlName="type">
        <mat-radio-button value="inherits" class="radio-button">
          {{ setVoidText || ('common.no_value' | translate) }}
        </mat-radio-button>
        <mat-radio-button value="value" class="radio-button">
          {{ 'common.chooseOther' | translate }}
        </mat-radio-button>
      </mat-radio-group>
    </div>

    <div class="container" formGroupName="value">
      <mat-form-field appearance="outline">
        <app-form-field-time formControlName="time"></app-form-field-time>
        <mat-error><app-form-field-error [control]="form.controls.value.controls.time"></app-form-field-error></mat-error>
      </mat-form-field>
    </div>
  </div>

  <mat-divider class="divider"
               *ngIf="! disableActions"
               (click)="$event.stopPropagation()"></mat-divider>

  <div (click)="$event.stopPropagation()" class="actions" *ngIf="! disableActions">
    <button mat-stroked-button
            color="primary"
            type="button"
            class="button"
            (click)="trigger?.closeMenu()">
      {{ 'common.cancel' | translate }}
    </button>
    <button mat-flat-button
            color="primary"
            type="button"
            [disabled]="! valid"
            (click)="submit(); trigger?.closeMenu()"
            class="button right">
      {{ 'common.save' | translate }}
    </button>
  </div>

</mat-menu>


