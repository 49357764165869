import { NgModule                        } from '@angular/core';
import { CommonModule                    } from '@angular/common';
import { FormsModule,
         ReactiveFormsModule             } from '@angular/forms';
import { NgxCleaveDirectiveModule         } from 'ngx-cleave-directive';

import { MatChipsModule                  } from '@angular/material/chips';
import { AppCommonModule                 } from 'app/common/common.module';
import { TranslationModule               } from 'app/core/translate/translate.module';
import { PipesModule                     } from 'app/shared/pipes/pipes.module';
import { CreateCoursesComponent          } from './create-courses.component';
import { FormComponent                   } from './components/form/form.component';
import { DurationFormFieldModule         } from '@app/shared/form-fields/duration/duration.module';
import { ExcelComponent                  } from './components/excel/excel.component';
import { SelectedPipe                    } from './create-courses.pipes';
import { PlannedDurationComponent        } from '@app/shared/form-fields/planned-duration/planned-duration.component';

@NgModule({
  declarations: [
    CreateCoursesComponent,
    FormComponent,
    SelectedPipe
  ],
  exports: [
    CreateCoursesComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    AppCommonModule,
    MatChipsModule,
    TranslationModule,
    DurationFormFieldModule,
    NgxCleaveDirectiveModule,
    ExcelComponent,
    PlannedDurationComponent
  ]
})
export class CreateCoursesModule { }
