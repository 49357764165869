import { Component,
         Output,
         EventEmitter,
         OnInit,
         Input,
         OnDestroy                       } from '@angular/core';
import { FormControl,
         FormGroup,
         Validators                      } from '@angular/forms';
import { coerceBooleanProperty           } from '@angular/cdk/coercion';
import { BehaviorSubject,
         combineLatest,
         debounceTime,
         fromEvent,
         map,
         Observable,
         Subject,
         takeUntil                       } from 'rxjs';
import $                                   from 'jquery';

import { Company                         } from '@shared/interfaces';
import { Util                            } from '@app/common';
import { OrganizationType,
         Theme                           } from '@app/core/environment/environment.types';

export type School = {
  Kommunkod:      string;
  PeOrgNr:        string;
  Skolenhetskod:  string;
  Skolenhetsnamn: string;
  Status:         string;
}

type Form = {
  name:             FormControl<string>;
  schoolCode:       FormControl<null | string>;
  organization:     FormControl<null | string>;
  organizationType: FormControl<null | OrganizationType>;
  theme:            FormControl<null | Theme>;
}

@Component({
  selector: 'app-company-form',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit, OnDestroy {
  @Output() onSubmit = new EventEmitter<Partial<Omit<Company.complete, 'id'>>>();

  private readonly onDestroy = new Subject<void>();
  private onSubject = new Subject<boolean>();
  public onFilter   = new BehaviorSubject<Partial<School>>({});
  public filter     = new BehaviorSubject<School[]>([]);

  public readonly ORGANIZATION_TYPES = ['school', 'sports_facility'];
  public readonly THEMES             = ['royal_schedule', 'sdui', 'schoolsoft', 'additio'];

  public submitted: boolean;
  public form = new FormGroup<Form>({
    name:             new FormControl('', { nonNullable: true, validators: [Validators.required] }),
    schoolCode:       new FormControl(null),
    organization:     new FormControl(null),
    organizationType: new FormControl(null, [Validators.required]),
    theme:            new FormControl(null, [Validators.required]),
  });

  public organizations = [];

  constructor () {

    // filter
    combineLatest({
      filter:  this.onFilter,
      schools: this.onSchools
    })
    .pipe(
      takeUntil(this.onDestroy),
      debounceTime(100),
      map(({ filter, schools }) => {
        // loop over each filter key and restrict the schools array
        let result: School[] | undefined;
        Util.functions.objectEntries(filter as Partial<School>)
        .forEach(([key, value]) => {
          // must have a value to filter on
          if ( ! value) return;

          // try initiate
          if ( ! result) result = structuredClone(schools);

          // filter
          const newRes: School[] = [];
          for (const school of result) {
            if (school[key].toLowerCase().includes(value.toLowerCase())) newRes.push(school);
          }
          result = newRes;
        });

        return result ?? [ ];
      })
    )
    .subscribe(this.filter);


    // listen to click outside a custom autocomplete or its parent form field to close the former
    fromEvent(document, 'click')
    .pipe(
      takeUntil(this.onDestroy),
      map((event: MouseEvent) => $(event.target!)),
    )
    .subscribe($target => {
      // find parent that is a form field
      const $formField = $target.closest('mat-form-field');

      // if such a parent does not exist, close custom autocomplete
      if ($formField.length === 0) {
        this.onFilter.next({ });
        return;
      }

      // look inside the parent form field for a custom autocomplete
      const $customAutocomplete = $formField.find('.custom-autocomplete');

      // if no custom autocomplete is found, close all custom autocomplete
      if ($customAutocomplete.length === 0) {
        this.onFilter.next({ });
        return;
      }
    });
  }


  ngOnDestroy(): void {
    this.onFilter.complete();
    this.filter.complete();
    this.onSubject.complete();
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  ngOnInit() {
  }

  public get valid(): boolean { return this.form?.valid ?? false }

  public formChange(): Observable<any> {
    return this.onSubject.asObservable();
  }

  public submit(): void {
    const { name, theme, organization, schoolCode, organizationType } = this.form!.value;

    const data: Partial<Omit<Company.complete, 'id'> > = {
      name,
      ...this.organization && { organization },
      ...schoolCode        && {
        identifiers: [
          {
            value: schoolCode,
            country: 'SE',
            type: 'SchoolCode'
          }
        ],
      },
      environment: {
        theme:            theme            as any,  // null is not allowed
        organizationType: organizationType as any,  // null is not allowed
      }
    };
    console.log(data)


    this.onSubmit.next(data);
  }

  public selectSchool(school: School): void {
    this.form?.patchValue({
      name:       school.Skolenhetsnamn,
      schoolCode: school.Skolenhetskod,
    });

    // turn off filter
    this.onFilter.next({ })
  }

  @Input()
  get theme(): boolean { return this._theme }
  set theme(val: boolean | undefined) {
    this._theme = coerceBooleanProperty(val);
  }
  private _theme: boolean = false;

  @Input()
  get organizationType(): boolean { return this._organizationType }
  set organizationType(val: boolean | string) {
    this._organizationType = coerceBooleanProperty(val);
  }
  private _organizationType: boolean = false;

  @Input()
  get organization(): boolean { return this._organization }
  set organization(val: boolean | undefined) {
    this._organization = coerceBooleanProperty(val);
  }
  private _organization: boolean = false;

  @Input()
  get schoolCode(): boolean { return this._schoolCode }
  set schoolCode(val: boolean | undefined) {
    this._schoolCode = coerceBooleanProperty(val);
  }
  private _schoolCode: boolean = false;

  @Input()
  set schools(val: School[] | undefined | null) {
    this.onSchools.next(val ?? []);
  }
  private onSchools = new Subject<School[]>();
}