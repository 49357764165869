import { NgModule,
         Optional,
         ErrorHandler,
         SkipSelf                        } from '@angular/core';
import { CommonModule                    } from '@angular/common';
import { PlatformModule                  } from '@angular/cdk/platform';
import { HTTP_INTERCEPTORS               } from '@angular/common/http';

import { HttpService                     } from './http/http.service';
import { HttpClientInterceptor           } from './http/http.interceptor';
import { TranslationModule               } from './translate/translate.module';
import { AppErrorHandler                 } from './error/error.handler';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    PlatformModule,
    TranslationModule.forRoot(),
  ],
  providers: [
    HttpService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpClientInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: AppErrorHandler
    }
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() core: CoreModule) {
    if (core) throw new Error('Core run multiple times');
  }
}