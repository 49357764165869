<!-- standard division form -->
<app-division-form displayName start end></app-division-form>

<!-- SchoolSoft -->
<ng-container *ngIf="mapName == 'SchoolSoft'">
  <div class="header">{{ 'shared.dialogs.upload-file.configure.format_specific' | translate }}</div>

  <mat-selection-list class="no-vertical-padding">
    <mat-list-option class="dynamic-height"
      [selected]=" !! secondaryForms[mapName].controls.interval.value"
      (selectedChange)="secondaryForms[mapName].controls.interval.setValue($event ? getWeekRange() : null)"
    >
      <mat-icon matListItemIcon>filter_alt</mat-icon>
      <span matListItemTitle>{{ 'shared.dialogs.upload-file.configure.SchoolSoft.interval.label' | translate }}</span>
      <span>{{ 'shared.dialogs.upload-file.configure.SchoolSoft.interval.descr' | translate:(weekRange | async) }}</span>
    </mat-list-option>
  </mat-selection-list>

</ng-container>

<!-- Skola24 -->
<ng-container *ngIf="mapName == 'Skola24'" [formGroup]="secondaryForms[mapName]">
  <div class="header">{{ 'shared.dialogs.upload-file.configure.format_specific' | translate }}</div>

  <div class="form-field-wrapper">
    <mat-icon class="form-field-icon no-padding-bottom">event</mat-icon>
      <mat-form-field class="form-field" appearance="outline" floatLabel="always" subscriptSizing="dynamic">
      <mat-label>{{ 'shared.dialogs.upload-file.configure.Skola24.week.label' | translate }}</mat-label>
      <mat-select formControlName="week"
                  panelClass="dark-scrollbar"
                  [placeholder]="'shared.dialogs.upload-file.configure.Skola24.week.ignoreAllEvents' | translate">
        <mat-option>{{ 'shared.dialogs.upload-file.configure.Skola24.week.ignoreAllEvents' | translate }}</mat-option>
        <mat-option [value]="w" *ngFor="let w of weeks">{{ w }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <mat-selection-list>
    <mat-list-option class="dynamic-height"
      [selected]="secondaryForms[mapName].controls.ignoreEmptyCourses.value"
      (selectedChange)="secondaryForms[mapName].controls.ignoreEmptyCourses.setValue($event)"
    >
      <mat-icon matListItemIcon>filter_alt</mat-icon>
      <span matListItemTitle>{{ 'shared.dialogs.upload-file.configure.Skola24.ignoreEmptyCourses.label' | translate }}</span>
    </mat-list-option>

    <mat-list-option class="dynamic-height"
      [selected]="secondaryForms[mapName].controls.removeUnreferencedTeachers.value"
      (selectedChange)="secondaryForms[mapName].controls.removeUnreferencedTeachers.setValue($event)"
    >
      <mat-icon matListItemIcon>filter_alt</mat-icon>
      <span matListItemTitle>{{ 'shared.dialogs.upload-file.configure.Skola24.removeUnreferencedTeachers.label' | translate }}</span>
      <span [innerHTML]="'shared.dialogs.upload-file.configure.Skola24.removeUnreferencedTeachers.descr' | translate"></span>
    </mat-list-option>

    <mat-list-option class="dynamic-height"
      [selected]="secondaryForms[mapName].controls.removeUnreferencedGroups.value"
      (selectedChange)="secondaryForms[mapName].controls.removeUnreferencedGroups.setValue($event)"
    >
      <mat-icon matListItemIcon>filter_alt</mat-icon>
      <span matListItemTitle>{{ 'shared.dialogs.upload-file.configure.Skola24.removeUnreferencedGroups.label' | translate }}</span>
      <span [innerHTML]="'shared.dialogs.upload-file.configure.Skola24.removeUnreferencedGroups.descr' | translate"></span>
    </mat-list-option>

    <mat-list-option class="dynamic-height"
      [selected]="secondaryForms[mapName].controls.deduceGroupsFromCourseName.value"
      (selectedChange)="secondaryForms[mapName].controls.deduceGroupsFromCourseName.setValue($event)"
    >
      <mat-icon matListItemIcon>manage_search</mat-icon>
      <span matListItemTitle>{{ 'shared.dialogs.upload-file.configure.Skola24.deduceGroupsFromCourseName.label' | translate }}</span>
      <span [innerHTML]="'shared.dialogs.upload-file.configure.Skola24.deduceGroupsFromCourseName.descr' | translate"></span>
    </mat-list-option>
  </mat-selection-list>

</ng-container>

<!-- Plan Digital -->
<ng-container  *ngIf="mapName == 'Plan Digital'" [formGroup]="secondaryForms[mapName]">
  <div class="header">{{ 'shared.dialogs.upload-file.configure.format_specific' | translate }}</div>

  <div class="form-field-wrapper">
    <mat-icon class="form-field-icon no-padding-bottom">filter_alt</mat-icon>
      <mat-form-field class="form-field" appearance="outline" floatLabel="always">
      <mat-label>{{ 'shared.dialogs.upload-file.configure.PlanDigital.periods.label' | translate }}</mat-label>
      <mat-select formControlName="selectedPeriods"
                  panelClass="dark-scrollbar"
                  [placeholder]="'shared.dialogs.upload-file.configure.PlanDigital.periods.placeholder' | translate"
                  multiple>
        <mat-option *ngFor="let period of periods | async"
                    [value]="period.Namn">
          {{ period.Namn }}
        </mat-option>
      </mat-select>
      <mat-error><app-form-field-error [control]="secondaryForms[mapName].controls.selectedPeriods"></app-form-field-error></mat-error>
    </mat-form-field>
  </div>

  <div class="form-field-wrapper">
    <mat-icon class="form-field-icon">filter_alt</mat-icon>
      <mat-form-field class="form-field" appearance="outline" floatLabel="always">
      <mat-label>{{ 'shared.dialogs.upload-file.configure.PlanDigital.displayName.label' | translate }}</mat-label>
      <mat-select formControlName="displayName"
                  panelClass="dark-scrollbar">
        <mat-option *ngFor="let key of planDigitalNameKeys"
                    [value]="key">
          {{ key }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

</ng-container>
