import { CommonModule                    } from '@angular/common';
import { Component                       } from '@angular/core';
import { MatDialogRef                    } from '@app/common';
import { AppCommonModule                 } from '@app/common/common.module';
import { UserInactivityService           } from '@app/core';
import { TranslationModule               } from '@app/core/translate/translate.module';

@Component({
  standalone: true,
  selector: 'app-user-inactive',
  templateUrl: './user-inactive.component.html',
  styleUrl: './user-inactive.component.scss',
  imports: [
    CommonModule,
    AppCommonModule,
    TranslationModule
  ]
})
export class UserInactiveComponent {

  constructor (
    dialogRef:      MatDialogRef<UserInactiveComponent>,
    userInactivity: UserInactivityService
  ) {
    // prevent the dialog from being closed by clicking outside of it
    dialogRef.disableClose = true;

    // reload and to reset the user inactivity when the dialog is closed
    dialogRef.beforeClosed().subscribe(() => userInactivity.reloadAllTabs());
  }

}
