import { Component,
         OnInit,
         Input                           } from '@angular/core';
import { coerceBooleanProperty           } from '@angular/cdk/coercion';
import { asyncScheduler                  } from 'rxjs';

@Component({
  selector: 'app-submit-pane',
  templateUrl: './submit-pane.component.html',
  styleUrls: ['./submit-pane.component.scss']
})
export class SubmitPaneComponent implements OnInit {
  public state: number = 0;

  constructor() { }

  ngOnInit() {
  }

  private _setState(state: number) {
    this.state = state;
  }

  public setDefaultState() {
    this._setState(0);
  }

  public setLoadingState() {
    this._setState(-1);
  }

  public setSavingState() {
    this._setState(3);
  }

  public setErrorState(): void {
    this._setState(6);
  }
  public setCreationSuccessState(): Promise<boolean> {
    return new Promise((resolve) => {
      this._setState(2);
      asyncScheduler.schedule(() => {
        resolve(true);
      }, 400);
   })
  }

  public setUpdateSuccessState(): Promise<boolean> {
    return new Promise((resolve) => {
      this._setState(7);
      asyncScheduler.schedule(() => {
        resolve(true);
      }, 400);
   })
  }

  public setCreationFail():void {
    this._setState(5);
    asyncScheduler.schedule(() => {
      this._setState(0);
    }, 400);
  }

  public setSavingSuccessState(): Promise<boolean> {
    return new Promise((resolve) => {
      asyncScheduler.schedule(() => {
        this._setState(4);
        asyncScheduler.schedule(() => {
          resolve(true);
        }, 400);
      }, 400);
   })
  }

  public setSavingFail(): Promise<void> {
    return new Promise((resolve) => {
      this._setState(5);
      asyncScheduler.schedule(() => {
        this._setState(0);
        resolve();
      }, 400);
   })
  }


  @Input()
  get hasActions(): boolean { return this._hasActions; }
  set hasActions(value: boolean | string) {
    this._hasActions = coerceBooleanProperty(value);
  }
  private _hasActions = false;
}
