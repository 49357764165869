import { Injectable,
         NgZone                                 } from '@angular/core';
import { BehaviorSubject,
         fromEvent,
         map,
         Observable,
         skip,
         merge,
         filter                                 } from 'rxjs';

import { EditorShortcuts,
         DataShortcuts,
         UserPreferencesService,
         ShortcutCategories                     } from 'app/core';
import { Util                                   } from 'app/common';

type Emit = {
  shortcut: keyof EditorShortcuts | keyof DataShortcuts;
  event:    KeyboardEvent;
  type:     'keydown' | 'keyup';
};

export const modifiers = ['ctrl', 'meta', 'shift', 'alt'] as const;
export type Modifier = typeof modifiers[number];
export const modifierRecord: Record<string, Modifier> = {
  ctrl:    'ctrl',
  control: 'ctrl',
  meta:    'meta',
  shift:   'shift',
  alt:     'alt'
};


export function mapKeyEvent (event: Partial<KeyboardEvent> | undefined): string[] {
  if ( ! event?.key) return [];

  const pressed = new Set<string>();

  // add modifiers
  event.altKey   && pressed.add(modifierRecord.alt);
  event.ctrlKey  && pressed.add(modifierRecord.ctrl);
  event.metaKey  && pressed.add(modifierRecord.meta);
  event.shiftKey && pressed.add(modifierRecord.shift);

  // in case of control, we want to add ctrl instead of control
  // TODO: [RSA-220] undefined is not an object (evaluating 'vt.key.toLowerCase')
  // (called from "const keys = mapKeyEvent(event).join('+');" further down the file)
  // PREV 1. : const key = event.key.toLowerCase();
  // PREV 2. : event seem to be undefined
  let key: string;
  try {
    key = event.key.toLowerCase();
  } catch (e) {
    throw new Error(`event.key.toLowerCase() failed for event: ${JSON.stringify(event as any ? { ...'key' in event && { key: event.key }, } : undefined) }`);
  }
  if (key == 'control') pressed.add(modifierRecord.ctrl);
  else                  pressed.add(key);

  return [...pressed.values()].sort();
}


@Injectable({
  providedIn: 'root'
})
export class KeyboardShortcutsService {

  private onShortcutTriggered = new BehaviorSubject<Emit | null>(null);

  constructor (
    private readonly preferences: UserPreferencesService,
            readonly ngZone:      NgZone
  ) {
    // run outside of angular to prevent change detection
    ngZone.runOutsideAngular(() => {

      merge(
        fromEvent(document, 'keydown').pipe(map(e => ({ type: 'keydown', event: e }) as Omit<Emit, 'shortcut'>)),
        fromEvent(document, 'keyup'  ).pipe(map(e => ({ type: 'keyup'  , event: e }) as Omit<Emit, 'shortcut'>))
      )
      .subscribe(({ type, event }) => {
        // skip unless someone is actually listening
        if ( ! this.onShortcutTriggered.observed) return;

        // abort if a select is in focus and the user is pressing arrow keys
        const ae = document.activeElement;
        const arrows = ['arrowup', 'arrowdown', 'arrowleft', 'arrowright']
        if (ae instanceof HTMLElement && ae.tagName.toLowerCase() == 'mat-select' && arrows.includes(event.key.toLowerCase())) return;

        // map the key event to pressed keys
        const keys = mapKeyEvent(event).join('+');

        // match against shortcuts
        const shortcuts = Util.functions
          .objectKeyVals(this.preferences.keyboardShortcuts)
          .filter(sc => sc.val.sort().join('+') == keys)
          .map(x => x.key);

        // loop over all found shortcuts
        shortcuts.forEach(shortcut => {
          // obtain category from the shortcut by using the fact that shortcut = "category/..."
          const category = shortcut.split('/')[0] as ShortcutCategories

          // do not fire again if the previous triggered one was identical
          // (if the key is held down)
          const prev = this.onShortcutTriggered.value;
          if (prev && prev.type == type && prev.shortcut == shortcut) return;

          // emit the shortcut (inside the ngZone)
          this.ngZone.run(() => {
            this.onShortcutTriggered.next({ shortcut, event, type });
          });
        })
      });

    });
  }

  public watch (): Observable<Emit> {
    return this.onShortcutTriggered.pipe(
      skip(1),
      filter(x => {
        // allow all shortcuts that cannot be matched to a target and thus filtered out
        if ( ! x?.event.target || ! (x.event.target instanceof Element)) return true;
        const target = x.event.target;
        const nodeName = target.nodeName.toLowerCase();

        // ignore textareas and mat-selects
        if (nodeName == 'textarea' || nodeName == 'mat-select') return false;

        // ignore inputs that are text-like
        // (see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#type)
        if (target instanceof HTMLInputElement) {
          const type = target.type.toLowerCase();
          const ignoreTypes = ['text', 'number', 'search', 'url', 'tel', 'password', 'email', 'datetime', 'datetime-local', 'date', 'month', 'week', 'time'];
          if (ignoreTypes.includes(type)) return false;
        }

        // allow all other shortcuts
        return true
      })
    ) as Observable<Emit>;
  }


}