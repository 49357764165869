import { Type                            } from '@angular/core';
import { Subject                         } from 'rxjs';

// Form field components
import { GroupsComponent                 } from './groups/groups.component';
import { BreakLengthComponent            } from './break-length/break-length.component';
import { AvailableDaysComponent          } from './available-days/available-days.component';
import { AvailableLocationsComponent     } from './available-locations/available-locations.component';
import { DurationComponent               } from './duration/duration.component';
import { DynamicLockedTimesComponent     } from './dynamic-locked-times/dynamic-locked-times.component';
import { LockedTimesComponent            } from './locked-times/locked-times.component';
import { DayComponent                    } from './day/day.component';
import { TimeComponent                   } from './time/time.component';
import { FixedStartComponent             } from './fixed-start/fixed-start.component';
import { IntervalsComponent              } from './intervals/intervals.component';
import { CenterOfAttractionComponent     } from './center-of-attraction/center-of-attraction.component';
import { PeriodComponent                 } from './period/period.component';
import { RootIntervalsComponent          } from './root-intervals/root-intervals.component';
import { OptionsComponent                } from './options/options.component';
import { TextComponent                   } from './text/text.component';
import { NumHoursComponent               } from './num-hours/num-hours.component';
import { DurationWithVariationComponent  } from './duration-with-variation/duration-with-variation.component';
import { MinutesComponent                } from './minutes/minutes.component';
import { TagsComponent                   } from './tags/tags.component';

// Display value components
import { DisplayValueComponent as GroupsDisplayValue             } from './groups/components/display-value/display-value.component';
import { DisplayValueComponent as BreakLengthDisplayValue        } from './break-length/components/display-value/display-value.component';
import { DisplayValueComponent as AvailableDaysDisplayValue      } from './available-days/components/display-value/display-value.component';
import { DisplayValueComponent as AvailableLocationsDisplayValue } from './available-locations/components/display-value/display-value.component';
import { DisplayValueComponent as DurationDisplayValue           } from './duration/components/display-value/display-value.component';
import { DisplayValueComponent as DynamicLockedTimesDisplayValue } from './dynamic-locked-times/components/display-value/display-value.component';
import { DisplayValueComponent as LockedTimesDisplayValue        } from './locked-times/components/display-value/display-value.component';
import { DisplayValueComponent as FixedStartDisplayValue         } from './fixed-start/components/display-value/display-value.component';
import { DisplayValueComponent as IntervalsDisplayValue          } from './intervals/components/display-value/display-value.component';
import { DisplayValueComponent as CenterOfAttractionDisplayValue } from './center-of-attraction/components/display-value/display-value.component';
import { DisplayValueComponent as PeriodDisplayValue             } from './period/components/display-value/display-value.component';
import { DisplayValueComponent as RootIntervalsDisplayValue      } from './root-intervals/components/display-value/display-value.component';
import { DisplayValueComponent as TagsDisplayValue               } from './tags/components/display-value/display-value.component';


import { State                        } from './form-fields.types';
import { SourceService, sourceHasPipe } from '@app/core';

export function getComponentRef(
  state: State
): Type<any> {
  switch (state) {
    case 'group':
    case 'group-persons':
    case 'groups':
    case 'groups-persons':
    case 'teacher':
    case 'teachers':
    case 'persons':
    case 'person':
      return GroupsComponent;
    case 'available-days':
      return AvailableDaysComponent;
    case 'available-locations':
      return AvailableLocationsComponent;
    case 'break-lengths':
      return BreakLengthComponent;
    case 'center-of-attraction':
      return CenterOfAttractionComponent;
    case 'color':
      return OptionsComponent;
    case 'days':
      return DayComponent;
    case 'duration':
      return DurationComponent;
    case 'duration-variance':
      return DurationWithVariationComponent;
    case 'dynamic-locked-times':
      return DynamicLockedTimesComponent;
    case 'fixed-start':
      return FixedStartComponent;
    case 'intervals':
      return IntervalsComponent;
    case 'locked-times':
      return LockedTimesComponent;
    case 'minutes':
      return MinutesComponent;
    case 'num-hours':
      return NumHoursComponent;
    case 'period':
      return PeriodComponent;
    case 'root-intervals':
      return RootIntervalsComponent;
    case 'text':
      return TextComponent;
    case 'time':
      return TimeComponent;
    case 'tags':
      return TagsComponent;
    default:
      throw new Error(`Component for state ${state} is not available`);
  }
}

export function getDisplayNameComponent(
  state: State
): Type<any> | null {
  switch (state) {
    case 'group':
    case 'group-persons':
    case 'groups':
    case 'groups-persons':
    case 'teacher':
    case 'teachers':
    case 'persons':
    case 'person':
      return GroupsDisplayValue;
    case 'available-days':
      return AvailableDaysDisplayValue;
    case 'available-locations':
      return AvailableLocationsDisplayValue;
    case 'break-lengths':
      return BreakLengthDisplayValue;
    case 'center-of-attraction':
      return CenterOfAttractionDisplayValue;
    case 'duration':
      return DurationDisplayValue;
    case 'dynamic-locked-times':
      return DynamicLockedTimesDisplayValue;
    case 'fixed-start':
      return FixedStartDisplayValue;
    case 'intervals':
      return IntervalsDisplayValue;
    case 'locked-times':
      return LockedTimesDisplayValue;
    case 'period':
      return PeriodDisplayValue;
    case 'root-intervals':
      return RootIntervalsDisplayValue;
    case 'tags':
      return TagsDisplayValue;
    case 'duration-variance':
    case 'color':
    case 'days':
    case 'text':
    case 'minutes':
    case 'num-hours':
    case 'time':
      return null;
    default:
      throw new Error(`Component for state ${state} is not available`);
  }
}

export function getComponentState(
  state:      State,
  source:     SourceService,
  onDestroy:  Subject<void>,
  did:        string,
  flat:       boolean = false,
  path:       string = ''
) {
  switch (state) {
    case 'groups':
      switch (path) {
        case 'groups':
        default:
          return {
            multiple: true,
            type:     'groups',
            list:     source.getGroups({ did, onDestroy }),
            flat
          }
        case 'parentGroups':
          return {
            multiple: true,
            type:     'groups',
            list:     source.getGroups({ did, onDestroy }, sourceHasPipe({ species: true })),
            flat,
            numCols:  1
          }
      }
    case 'groups-persons':
      return {
        multiple:      true,
        type:          'groups',
        list:          source.getGroups({ did, onDestroy }),
        flat,
        coalescedList: source.getStudents({ did, onDestroy }),
        coalescedType: 'persons'
      }
    case 'group':
      return {
        multiple: false,
        type:     'groups',
        list:     source.getGroups({ did, onDestroy }, sourceHasPipe({ species: true })),
        flat,
        nullable: true,
        numCols:  1
      }
    case 'group-persons':
      return {
        multiple:      false,
        nullable:      false,
        type:          'groups',
        list:          source.getGroups({ did, onDestroy }),
        flat,
        coalescedList: source.getStudents({ did, onDestroy }),
        coalescedType: 'persons',
        constrictCoalesced: true,
        numCols:  1
      }
    case 'teachers':
      return {
        multiple: true,
        type:     'teachers',
        list:     source.getTeachers({ did, onDestroy }),
        flat
      }
    case 'teacher':
      return {
        multiple: false,
        nullable: false,
        type:     'teachers',
        list:     source.getTeachers({ did, onDestroy }),
        flat,
        numCols:  1
      }
    case 'persons':
      return {
        multiple: true,
        type:     'persons',
        list:     source.getStudents({ did, onDestroy }),
        groups:   source.getGroups({ did, onDestroy }),
        flat
      }
    case 'person':
      return {
        multiple: false,
        nullable: false,
        type:     'persons',
        list:     source.getStudents({ did, onDestroy }),
        groups:   source.getGroups({ did, onDestroy }),
        flat
      }
    default:
      return {};
  }
}