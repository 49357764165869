import { Subject                     } from 'rxjs';

/**
 * @description
 * A simple wrapper around the native BroadcastChannel that allows broadcasting to the same tab and uses Observables.
 */
export class Broadcast<Message> {

  private _bc: BroadcastChannel;
  private _onMessage = new Subject<Message>();

  constructor (name: string) {
    this._bc = new BroadcastChannel(name);
    this._bc.onmessage = ({ data }: { data: Message }) => this._onMessage.next(data);
  }

  get onMessage () { return this._onMessage.asObservable(); }

  public postMessage (message: Message, self = true) {
    this._bc.postMessage(message);
    if (self) this._onMessage.next(message);
  }

  public close () {
    this._bc.close();
  }
}