import { NgModule                                          } from '@angular/core';
import { CommonModule                                      } from '@angular/common';
import { NgPipesModule                                     } from 'ngx-pipes';

import { AppCommonModule                                   } from 'app/common/common.module';
import { TranslationModule                                 } from 'app/core/translate/translate.module';
import { FormFieldsModule                                  } from 'app/shared/form-fields/form-fields.module';
import { PipesModule                                       } from 'app/shared/pipes/pipes.module';
import { FormsModule      as AppFormsModule                } from 'app/shared/forms/forms.module';
import { ComponentsModule as AppComponentsModule           } from 'app/shared/components/components.module';

import { ComponentsModule                                  } from '../components/components.module';
import { OrganizationsComponent                            } from './organizations.component';
import { ComponentsModule as OrganizationsComponentsModule } from './components/components.module';


@NgModule({
  declarations: [
    OrganizationsComponent
  ],
  exports: [
    OrganizationsComponent,
    OrganizationsComponentsModule
  ],
  imports: [
    CommonModule,
    NgPipesModule,
    AppCommonModule,
    TranslationModule,
    FormFieldsModule,
    PipesModule,
    AppFormsModule,
    AppComponentsModule,
    ComponentsModule
  ]
})
export class OrganizationsModule { }
