import { AfterViewInit,
         Component,
         ElementRef,
         Input,
         QueryList,
         ViewChild,
         ViewChildren             } from '@angular/core';
import { animate,
         style,
         transition,
         trigger                  } from '@angular/animations';
import { coerceBooleanProperty    } from '@angular/cdk/coercion';
import { asyncScheduler           } from 'rxjs';
import $                            from 'jquery';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  animations: [
    trigger('anim', [
      transition(':leave', [
        style({ opacity: 1}),
        animate('120ms', style({ opacity: 0 }))
      ]),
      transition(':enter', [
        style({ opacity: 0}),
        animate('80ms', style({ opacity: 1 }))
      ])
    ])
  ]
})
export class LoadingComponent implements AfterViewInit {
  @ViewChildren('sw') sw: QueryList<ElementRef>;
  @ViewChildren('ne') ne: QueryList<ElementRef>;
  @ViewChild('svg') svg: ElementRef;

  constructor() { }

  ngAfterViewInit(): void {
    this.setupSVG();
  }

  private setupSVG () {
    // abort if the SVG element does not exist due *ngIf or similar
    if ( ! this.svg) return;

    // abort also if it has already been set up
    const $svg = $(this.svg.nativeElement);
    if ($svg.attr('viewBox')) return;

    // width and height of the SVG
    const W = 31.42, H = 21.28;
    $(this.svg.nativeElement).attr('viewBox', `0 0 ${W} ${H}`);

    // width, height, and corner-radius of the rectangles
    const w = 19.28, h = 14.18, r = 1.2;

    // bottom left corner locations of the south east and north west rectangles
    const sw = { x: W - w, y: 0,     };
    const ne = { x: 0,     y: H - h, };

    // animation pace 2*"ease-in-out"
    const pace = `dur="4s"
                  repeatCount="indefinite"
                  keytimes="0; 0.5; 1"
                  calcMode="spline"
                  keysplines=".42 0 .58 1; .42 0 .58 1"`;

    this.sw.forEach(x => {
      const $x = $(x.nativeElement);
      $x.attr('width', w).attr('height', h).attr('rx', r).attr('ry', r);
      $x.attr('x', sw.x).attr('y', sw.y);

      $x.html(`
        <animate attributeName="x" values="${ sw.x };${ ne.x };${ sw.x }" ${ pace }/>
        <animate attributeName="y" values="${ sw.y };${ ne.y };${ sw.y }" ${ pace }/>
      `);
    });

    this.ne.forEach(x => {
      const $x = $(x.nativeElement);
      $x.attr('width', w).attr('height', h).attr('rx', r).attr('ry', r);
      $x.attr('x', ne.x).attr('y', ne.y);

      $x.html(`
        <animate attributeName="x" values="${ ne.x };${ sw.x };${ ne.x }" ${ pace }/>
        <animate attributeName="y" values="${ ne.y };${ sw.y };${ ne.y }" ${ pace }/>
      `);
    });
  }

  @Input()
  get loading () { return this._loading; }
  set loading (val: boolean | string | null ) {
    this._loading = coerceBooleanProperty(val);

    // need to re-setup the SVG if the loading state changes back from false to true due to *ngIf
    if (this._loading) {
      asyncScheduler.schedule(() => this.setupSVG());
    }
  }
  private _loading: boolean = true;

  @Input()
  position: 'absolute' | 'fixed' | 'sticky';

  @Input()
  set noOverlay (val: boolean | string) {
    this._noOverlay = coerceBooleanProperty(val);
  }
  get noOverlay () { return this._noOverlay; }
  private _noOverlay = false;

  @Input()
  set dark (val: boolean | string) {
    this._dark = coerceBooleanProperty(val);
  }
  get dark () { return this._dark; }
  private _dark = false;

  @Input()
  set whiteOverlay (val: boolean | string) {
    this._whiteOverlay = coerceBooleanProperty(val);
  }
  get whiteOverlay () { return this._whiteOverlay; }
  private _whiteOverlay = false;

  @Input()
  set borderRadius (val: string) {
    this._borderRadius = val;
  }
  get borderRadius () { return this._borderRadius; }
  private _borderRadius = '0';

  @Input()
  set description (val: string | null) {
    this._description = val;
  }
  get description () { return this._description; }
  private _description: string | null;
}
