import { Component,
         EmbeddedViewRef,
         EnvironmentInjector,
         Renderer2,
         Injectable,
         Injector,
         ViewContainerRef,
         createComponent,
         ApplicationRef,
         ComponentFactoryResolver,
         Type,
         ChangeDetectorRef,
         ComponentRef} from '@angular/core';
import { Subject,
         animationFrameScheduler,
         asyncScheduler} from 'rxjs';
import _                                   from 'lodash';
import $                                   from 'jquery';

/**
 * @description Service that transforms a element into a full sized card with content.
 */
@Injectable()
export class TransformMaterialService {
  protected _viewContainerRef: ViewContainerRef;

  private _animationDuration = 400;
  private _resizeObserver:     ResizeObserver;
  private _expanded:           JQuery<HTMLElement> | undefined;
  private _minimizedContainer: JQuery<EventTarget> | undefined;


  constructor(
    private _renderer:          Renderer2,
    private _appRef:            ApplicationRef,
    private _viewRef:           ViewContainerRef
  ) { }

  /**
   * @param component         Component to be injected as content into the container
   * @param data              Data to be passed into the component
   * @param parentContainer   Parent container
   * @param event             MouseEvent that triggered the opening of the component
   * @param options           Options for the component
   * @param options.zIndex    Z-index of the component
   * @description Opens the component inside the parent container.
   */
  public open (
    component:           Type<any>,
    _viewContainerRef:   ViewContainerRef,
    event:               MouseEvent,
    environmentInjector: EnvironmentInjector,
    injector:            Injector,
    options: {
      zIndex?:                 number,
      padding?:                number,
      selector?:               string,
      classList?:              string[],
      animationDuration?:      number,
      delayComponentCreation?: boolean,
      componentParameters?:    any
    } = { }
  ): void {
    /*
      Destroy all previous components.
    */
    this._destroy();

    this._animationDuration = options.animationDuration ?? this._animationDuration;

    this._viewContainerRef = _viewContainerRef;

    /*
      Create a wrapper element for the component.
      This is used to delay component creation until the animation is finished to avoid stagger.
    */

    const wrapperElem = this._renderer.createElement('div');
    wrapperElem.classList.add('mat-elevation-z4') //, 'padding-inline', 'padding-bottom-8';
    wrapperElem.style['zIndex'] = _.isNumber(options.zIndex) ? options.zIndex.toString() : '2';
    wrapperElem.classList.add('expanded-card');
    wrapperElem.classList.add('white-bg');
    if (options.classList) {
      wrapperElem.classList.add(...options.classList);
    }
    wrapperElem.style['overflow'] = 'hidden';

    document.body.appendChild(wrapperElem);

    /*
      Get the parent container and the parent container's dimensions.
    */
    const parent       = $(this._viewContainerRef.element.nativeElement);

    const padding = options.padding ?? 24;

    const windowWidth  = window.innerWidth;
    const windowHeight = window.innerHeight

    const parentWidth  = Math.min(parent.innerWidth()!, windowWidth - 2 * padding);
    const parentHeight = Math.min(parent.innerHeight()!, windowHeight - 2 * padding);
    const parentTop    = Math.max(parent.offset()!.top, padding);
    const parentLeft   = Math.max(parent.offset()!.left, 0);

    const container          = options.selector ? $(event.target!).closest(options.selector) : $(event.target!);

    const top                = Math.max(container.offset()!.top, 0);
    const left               = Math.max(container.offset()!.left, 0);
    const width              = container.outerWidth(true)!;
    const height             = container.outerHeight(true)!;
    const borderRadius       = container.css('border-radius');

    this._expanded           = $(wrapperElem);

    this._minimizedContainer = container;

    this._expanded.css({
      top,
      left,
      width,
      height,
      'border-radius': borderRadius
    });


    let componentRef!: ComponentRef<any>;

    this._expanded.animate({
      top:    parentTop + padding!,
      left:   parentLeft + padding!,
      width:  parentWidth - padding! * 2,
      height: parentHeight - padding! * 2,
      'border-radius': '4px',
    }, this._animationDuration, () => {
      /*
        Create the component and attach it to the view.
      */
      componentRef = createComponent(component, {
        environmentInjector,
        elementInjector: injector,
        hostElement: wrapperElem,
      });
      /*
        Attach the created view such that it will be dirty checked.
      */
      this._appRef.attachView(componentRef.hostView);

      /*
        Assign the component parameters to the component instance.
      */
      Object.assign(componentRef.instance, options.componentParameters ?? { });
      // this._renderer.appendChild(wrapperElem, domElem);

      componentRef.instance.onDestroy?.subscribe(() => componentRef.destroy());
   });

    this._resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const {
          contentRect: {
              width,
              height,
            }
          } = entry;

          this._expanded!.css({
            width:  width - padding! * 2,
            height: height - padding! * 2
          });
        }
    });

    this._resizeObserver.observe(this._viewContainerRef.element.nativeElement);
  }

  ngOnDestroy(): void {
    this._destroy();
  }

  private _getMinimizedContainer() {
    const container          = this._minimizedContainer;
    if (! container) return;

    const top                = container.offset()!.top;
    const left               = container.offset()!.left;
    const width              = container.outerWidth(true)!;
    const height             = container.outerHeight(true)!;
    const borderRadius       = container.css('border-radius');

    return {
      top,
      left,
      height,
      width,
      'border-radius': borderRadius
    };
  }

  public get isOpen(): boolean {
    return !!this._expanded;
  }

  /**
   * @description Closes the component
   */
  public close (): void {
    const minimizedState    = this._getMinimizedContainer();
    /**
     * If the minimized state is not available, simply destroy the component.
     */
    if (! minimizedState) {
      this._destroy();
      return;
    };
    /*
      Fade out all children elements such that the animation become more smooth.
    */
    this._expanded?.children().animate({
      opacity: 0
    }, this._animationDuration / 2);
    /*
      Clear all content of the component such that the animation become more smooth.
    */
    animationFrameScheduler.schedule(() => {
      this._expanded?.empty();
    }, this._animationDuration / 2);

    this._expanded?.animate(
      minimizedState,
      this._animationDuration,
      () => {
        this._expanded?.animate({
          opacity: 0
        }, 300, () => {
          this._destroy();
        });
      }
    );
  }

  private _destroy() {
    this._resizeObserver?.unobserve(this._viewContainerRef.element.nativeElement);
    this._expanded?.remove();
  }
}