import { NgModule                        } from '@angular/core';
import { CommonModule                    } from '@angular/common';
import { ScrollingModule                 } from '@angular/cdk/scrolling';
import { AppCommonModule                 } from 'app/common/common.module';

import { DirectivesModule                } from 'app/shared/directives/directives.module';
import { ToolbarContainerComponent       } from './toolbar-container.component';

@NgModule({
  declarations: [
    ToolbarContainerComponent,
  ],
  exports: [
    ToolbarContainerComponent
  ],
  imports: [
    CommonModule,
    ScrollingModule,
    AppCommonModule,
    DirectivesModule
  ]
})
export class ToolbarContainerModule { }
