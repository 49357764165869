import { Component,
         ElementRef,
         EventEmitter,
         HostBinding,
         HostListener,
         Input,
         OnDestroy,
         Optional,
         Output,
         Self,
         ViewChild                          } from '@angular/core';
import { CommonModule                       } from '@angular/common';
import { ControlValueAccessor,
         FormControl,
         FormsModule,
         NgControl,
         ReactiveFormsModule,
         Validators                         } from '@angular/forms';
import { MatFormFieldControl                } from '@angular/material/form-field';
import { MatMenuTrigger                     } from '@angular/material/menu';
import { FocusMonitor                       } from '@angular/cdk/a11y';
import { coerceBooleanProperty              } from '@angular/cdk/coercion';
import { takeUntilDestroyed                 } from '@angular/core/rxjs-interop';
import { MatInput                           } from '@angular/material/input';
import { BehaviorSubject,
         Subject,
         combineLatest,
         filter,
         fromEvent,
         map,
         startWith,
         takeUntil                          } from 'rxjs';
import { NgPipesModule                      } from 'ngx-pipes';
import { NgxCleaveDirectiveModule           } from 'ngx-cleave-directive';

import { TranslationModule                  } from 'app/core/translate/translate.module';
import { ExtendedValidators                 } from 'app/shared/forms';
import { Util                               } from 'app/common';
import { AppCommonModule                    } from 'app/common/common.module';
import { DisplayValueComponent              } from './components/display-value/display-value.component';

type Unit = Util.Types.PlannedDurationUnit;

@Component({
  standalone: true,
  selector: 'app-form-field-planned-duration',
  templateUrl: './planned-duration.component.html',
  styleUrl: './planned-duration.component.scss',
  imports: [
    CommonModule,
    AppCommonModule,
    NgPipesModule,
    TranslationModule,
    FormsModule,
    NgxCleaveDirectiveModule,
    ReactiveFormsModule,
    DisplayValueComponent,
  ],
  // in order to use the component as a form field control, we need to provide it as a form field control
  providers: [
    { provide: MatFormFieldControl, useExisting: PlannedDurationComponent }
  ],
})
export class PlannedDurationComponent
  implements OnDestroy, ControlValueAccessor, MatFormFieldControl<string | null> {

  @ViewChild(MatMenuTrigger) trigger?: MatMenuTrigger;
  @ViewChild(MatInput      ) input?:   MatInput;

  @Output('onChange') emitter = new EventEmitter<string | null>();

  private onClose     = new Subject<void>();
  static nextId       = 0;
  public stateChanges = new Subject<void>();
  public focused      = false;
  public errorState   = false;
  public controlType  = 'planned-duration-input';
  public id           = `planned-duration-input-${ PlannedDurationComponent.nextId++ }`;
  public describedBy  = '';
  public onChange:  any = () => {};
  public onTouched: any = () => {};



  protected readonly valueCtrl = new FormControl<string>('',    { nonNullable: false, validators: [ Validators.min(0), ExtendedValidators.isMultipleOf(5) ] });
  protected readonly unitCtrl  = new FormControl<Unit  >('hrs', { nonNullable: false, validators: [ Validators.required ] });

  protected primaryDisplayValue:   string | null = null;
  protected secondaryDisplayValue: string | null = null;

  protected readonly cleaveIntegerFormat = {
    numeral:             true,
    delimiter:           '',
    stripLeadingZeroes:  true,
    numeralPositiveOnly: true,
    numeralDecimalScale: 0,
  };

  // to be able to tab to the input
  @HostBinding('attr.tabindex') __tabindex = 0;

  @HostListener('keydown', ['$event'])
  onKeydown(event: KeyboardEvent) {
    // open unless tab (or shift+tab) is pressed
    if (event.key == 'Tab') return;
    this.trigger?.openMenu();
  }

  constructor (
    private _focusMonitor: FocusMonitor,
    private _elementRef:   ElementRef<HTMLElement>,
    @Optional() @Self()
    public ngControl: NgControl
  ) {

    _focusMonitor.monitor(_elementRef, true).subscribe((origin: any) => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    const value$ = this.valueCtrl.valueChanges.pipe(takeUntilDestroyed(), startWith(this.valueCtrl.value));
    const unit$  = this.unitCtrl .valueChanges.pipe(takeUntilDestroyed(), startWith(this.unitCtrl .value));

    // set validators of valueCtrl based on the unit
    unit$.subscribe(x => {
      if (x === 'hrs') this.valueCtrl.setValidators([ ]);
      else             this.valueCtrl.setValidators([ ExtendedValidators.isMultipleOf(5) ]);
      this.valueCtrl.updateValueAndValidity();
    });

    // to use in combination with MatFormFieldControl
    combineLatest({
      value: value$,
      unit:  unit$,
    })
    .pipe(
      filter(() => this.valid()),
      map(({ value, unit }) => {
        if (value == null) return null;

        // strip the value of any non-numeric characters
        // (it seems this triggers before cleave directive has a chance to format the value, so we need to do it manually)
        value = value.replace(/\D/g, '');

        return `${value} ${unit}`;
      })
    )
    .subscribe(x => this.onChange(x));

    // set display value based on value and unit and also compute the derived value
    combineLatest({
      value:    value$,
      unit:     unit$,
      numWeeks: this.numWeeks
    })
    .subscribe(({ value: _value, unit, numWeeks }) => {
      // strip the value of any non-numeric characters
      // (it seems this triggers before cleave directive has a chance to format the value, so we need to do it manually)
      if (_value) _value = _value.replace(/\D/g, '');

      // console.log('value = ', _value, 'unit = ', unit, 'numWeeks = ', numWeeks);
      // console.log(_value, ! _value, _value?.length);

      if ( ! _value || ! this.valid()) {
        this.primaryDisplayValue   = null;
        this.secondaryDisplayValue = null;
        return;
      }

      // convert to integer
      const value = parseInt(_value);

      // set display value
      this.primaryDisplayValue = `${value} ${unit}`;

      // proceed to compute the secondary representation if numWeeks is available
      if (numWeeks == null) {
        this.secondaryDisplayValue = null;
        return;
      }

      // multiplication factor for min/week -> hrs (divide for reverse)
      const hrsOverMinPerWeek = numWeeks / 60;

      // try derive the secondary representation, if unit is hrs the other representation should be min/week
      const sUnit  = unit === 'hrs' ? 'min/week' : 'hrs';
      let   sValue = unit === 'hrs' ? value / hrsOverMinPerWeek : value * hrsOverMinPerWeek;

      // round up to nearest integer if hours, and nearest 5 if min/week
      if (sUnit === 'hrs') sValue = Math.ceil(sValue);
      else                 sValue = Math.ceil(sValue / 5) * 5;

      this.secondaryDisplayValue = `${ isFinite(sValue) ? sValue : '∞' } ${sUnit}`;
    });

  }

  public closed(): void {
    if (this.saveOnClose && this.valid() && this.dirty()) {
      this.markAsPristine();
      this._pristineValue = this.value;
      this.emitter.emit(this.value);
    }

    if (this.saveOnClose && ! this.valid()) {
      this.value = this._pristineValue;
      this.markAsPristine();
    }

    this.onClose.next();
  }

  public opened(): void {
    fromEvent(document, 'keydown')
    .pipe(takeUntil(this.onClose))
    .subscribe((event: KeyboardEvent) => {
      if (event.key == 'Escape' || event.key == 'Enter') this.trigger?.closeMenu()
    });

    // focus on the input element, need to wait for the next tick
    setTimeout(() => this.input?.focus());
  }

  ngOnInit(): void {
  }


  public resetValue(): void {
    this.value = this._pristineValue;
    this.valueCtrl.markAsPristine();
    this.unitCtrl.markAsPristine();
    this.stateChanges.next();
  }

  protected clearValue(): void {
    // make dirty so that the change may propagate on close
    // (this so it is easy to clear the bulk value)
    this.valueCtrl.setValue(null);
    this.valueCtrl.markAsDirty();

    this.unitCtrl.setValue(null);
    this.unitCtrl.markAsDirty();

    this.stateChanges.next();
  }

  protected valid (): boolean {
    // valid state if both are null
    if (this.nullable && ! this.valueCtrl.value && ! this.unitCtrl.value) return true;

    return this.valueCtrl.valid && this.unitCtrl.valid;
  }

  protected dirty (): boolean {
    return this.valueCtrl.dirty || this.unitCtrl.dirty;
  }

  protected pristine (): boolean {
    return this.valueCtrl.pristine && this.unitCtrl.pristine;
  }

  protected markAsPristine (): void {
    this.valueCtrl.markAsPristine();
    this.unitCtrl.markAsPristine();
  }



  get empty() { return ! this.value; }

  get shouldLabelFloat() { return this.focused || ! this.empty; }

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input({ transform: coerceBooleanProperty })
  public nullable: boolean = false;

  @Input({ transform: coerceBooleanProperty })
  public hideToolbar: boolean = false;

  @Input()
  get value(): string | null {
    const value = this.valueCtrl.value;
    if ( ! value) return null;

    const unit = this.unitCtrl.value;
    return `${value} ${unit}`;
  }
  set value(value: string | null | undefined) {
    this._pristineValue = value ?? null;

    // value of the form '${number} ${unit}', extract the number as integer unit as string
    const number = value?.match(/\d+/);
    if (number) this.valueCtrl.setValue(parseInt(number[0]).toString());
    else        this.valueCtrl.setValue(null);

    // extract unit from value
    const unit = value?.match(/(hrs|min\/week)/);
    if      (unit               ) this.unitCtrl.setValue(unit[0] as Unit);
    else if (value === undefined) this.unitCtrl.setValue(null);   // set to null but only if mismatching bulkvalue


    this.stateChanges.next();
  }
  _pristineValue: string | null = null;

  @Input()
  set numberOfWeeksInPeriod (value: number | null) {
    this.numWeeks.next(value);
  }
  protected numWeeks = new BehaviorSubject<number | null>(null);

  @Input()
  get saveOnClose(): boolean { return this._saveOnClose; }
  set saveOnClose(value: boolean | string) {
    this._saveOnClose = coerceBooleanProperty(value);
  }
  private _saveOnClose: boolean = false;

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
    this.onClose.next();
    this.onClose.complete();
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick (event: MouseEvent) {
    // open the menu when MatFormFieldControl is clicked
    this.trigger?.openMenu();
  }

  writeValue(val: string | null): void {
    this.value = val;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  static ngAcceptInputType_disabled: boolean | string | null | undefined;
  static ngAcceptInputType_required: boolean | string | null | undefined;
}