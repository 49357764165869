import { Component,
         AfterViewInit,
         ElementRef,
         ChangeDetectorRef,
         OnDestroy,
         ViewContainerRef} from '@angular/core';
import { EventApi                                           } from '@fullcalendar/core';
import { combineLatest                                      } from 'rxjs';
import { takeUntil,
         distinctUntilChanged,
         filter,
         debounceTime                                       } from 'rxjs/operators';
import _                                                      from 'lodash';
import $                                                      from 'jquery';
import moment                                                 from 'moment';

import { TranslateService,
         LoggerService,
         KeyboardShortcutsService                           } from 'app/core';
import { MatDialog                                          } from 'app/common';
import { DateService                                        } from 'app/shared/services';
import { CalendarInterval,
         Populated as P                                     } from 'app/shared/interfaces';
import { DisplayNamePipe,
         EventColorPipe                                     } from 'app/shared/pipes/common/common.pipe';

import { CalendarCore                                       } from './calendar.core';
import { EventSource,
         isEvent,
         _EventSource                                       } from './types';
import { PopoverService                                     } from '../popover/popover.service';


@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent extends    CalendarCore
                               implements AfterViewInit, OnDestroy {

  constructor (
    protected _translate:       TranslateService,
    protected _displayNamePipe: DisplayNamePipe,
    protected _eventColorPipe:  EventColorPipe,
    protected _dialog:          MatDialog,
    protected _popoverService:  PopoverService,
    protected _changeDetector:  ChangeDetectorRef,
    protected _date:            DateService,
    protected _logger:          LoggerService,
    protected _viewContainerRef:ViewContainerRef,
    private   _shortcuts:       KeyboardShortcutsService,
    // the reference to the dom element of the component
    protected elementRef:       ElementRef,
  ) {
    super(_date);

    // set css variable for cell height
    elementRef.nativeElement.style.setProperty('--cell-height', '3em');

    this._shortcuts.watch()
    .pipe(takeUntil(this.onDestroy))
    .subscribe(x => {
      if (x.shortcut == 'editor/moveEventIndividually') this.moveIndividually.next(x.type == 'keydown');
      if (x.shortcut == 'editor/multipleSelectEvents')  this.multipleSelect  .next(x.type == 'keydown');
    });
  }

  ngAfterViewInit () {
    // subscribe to language changes
    this._translate.onCurrentLanguage
    .pipe(takeUntil(this.onDestroy))
    .subscribe(language => this.calendar?.getApi().setOption('locale', language?.id ?? 'en'));

    // fetch the jquery selector of the calendar
    this.$calendar = $(this.elementRef.nativeElement).find('full-calendar');

    // set correct initial day
    this.calendar.getApi().gotoDate(DateService.firstDay.format('YYYY-MM-DD'));

    // subscribe to background events
    this.backgroundEvents
    .pipe(takeUntil(this.onDestroy))
    .subscribe(events => {
      this.calendar.getApi().getEventSourceById('background')?.remove();
      this.calendar.getApi().addEventSource({ id: 'background', events });
    });

    // subscribe to foreground events
    this.foregroundEvents
    .pipe(takeUntil(this.onDestroy))
    .subscribe(events => {
      // get all unique overlap groups
      this.overlapGroups = _.uniq(events
        .map(e => isEvent(e.extendedProps.source) ? e.extendedProps.source.course?.overlapGroup?.id : undefined)
        .filter(Boolean)
      ).sort();

      // remove all events without a source or those that belong to an outdated source
      // (the later can happens to an event that is dragged when the source is updated)
      this.calendar.getApi().getEvents().forEach(event => {
        if ( ! event.source?.internalEventSource) event.remove();
      });

      // try remove the event that is currently being dragged
      if (this.draggedEvent) events = events.filter(x => x.id !== this.draggedEvent);

      this.calendar.getApi().getEventSourceById('foreground')?.remove();
      this.calendar.getApi().addEventSource({ id: 'foreground', events });
    });

    // subscribe to input changes in the visible range
    // such that the calendar is scaled accordingly
    this._visibleRange
    .pipe(
      takeUntil(this.onDestroy),
      distinctUntilChanged((i1, i2) => i1.start == i2.start && i1.end == i2.end)
    )
    .subscribe(x => this.scaleVertically(x));

    // rerender the calendar
    this.onRerender
    .pipe(
      takeUntil(this.onDestroy),
      debounceTime(0)
    )
    .subscribe(() => this.calendar.getApi()?.render());

    // get information of the current selected events
    combineLatest({
      events:   this.foregroundEvents.pipe(
        distinctUntilChanged((prev, curr) => _.isEqual(prev.sort(), curr.sort()))
      ),
      selected: this.selectedEvents
    })
    .pipe(
      takeUntil(this.onDestroy),
      debounceTime(0)
    )
    .subscribe(({ events, selected }) => {
      // find out if only a single overlapping group is selected
      const overlapGroups = _.uniq(events
        .filter(e => selected.includes(e.id!))
        .map(e => e.extendedProps.source)
        .filter((e): e is P.event => isEvent(e))
        .map(e => e.course?.overlapGroup?.id)
      );
      this.selectedOverlapGroup = overlapGroups.length === 1 ? overlapGroups[0] : undefined;

      // find out if only a single overlap species is selected
      const overlapSpecies = _.uniq(events
        .filter(e => selected.includes(e.id!))
        .map(e => e.extendedProps.source)
        .filter((e): e is P.event => isEvent(e))
        .map(e => e.overlapSpecies?.species?.find(x => x.to.id == e.id)?.id)
      );
      this.selectedOverlapSpecies = overlapSpecies.length === 1 ? overlapSpecies[0] : undefined;

      // schedule rerendering
      this.onRerender.next();
    });

    // setup all DOM events necessary for the time axis hover
    this.onListenToTimeAxisHover
    .pipe(
      takeUntil(this.onDestroy),
      filter(x => !! x)
    )
    .subscribe(() => this.setupTimeAxisHoverEvents());

    // add classes to calendar element to indicate keys pressed
    this.moveIndividually.pipe(takeUntil(this.onDestroy))
    .subscribe(x => this.$calendar.toggleClass('move-individually', x));
  }

  // whenever the schedule is resized
  protected onResize () {
    this.calendar?.getApi().updateSize();
  }

  // override selected events with new ones
  public setSelectedEvents (ids: string[]) {
    // emit only if the selection has changed
    if ( ! _.isEqual(ids.sort(), this.selectedEvents.value.sort()))
      this.selectedEvents.next(ids);
  }

  protected _selectEvent (
    id:   string | null,
    mode: 'set' | 'toggle' | 'multiselect' = 'toggle'
  ) {
    this.selectEvent(id, mode);
    this.eventSelectionChange.next({ change: id, selected: this.selectedEvents.value });
  }

  public selectEvent (
    id:   string | null,
    mode: 'set' | 'toggle' | 'multiselect' = 'toggle'
  ) {
    if (id != null) {
      const selIds = this.selectedEvents.value;
      if (mode === 'set') {
        this.selectedEvents.next([id]);
      } else if (mode === 'toggle') {
        // toggle single
        if (selIds.length == 1 && selIds[0]== id) this.selectedEvents.next([]);
        else                                      this.selectedEvents.next([id]);
      } else {
        // toggle multiple
        if (selIds.some(e => e === id)) this.selectedEvents.next(selIds.filter(e => e!== id));
        else                            this.selectedEvents.next(selIds.concat(id));
      }
    } else {
      this.selectedEvents.next([]);
    }
  }

  protected getEventSource (event: EventApi): EventSource {
    const { extendedProps: { source } } = event;

    // ensure that the source event reference exists
    if ( ! source) {
      console.log(source);
      this._logger.error(new Error('Event does not have an extendedProps.source property'));
    }

    // add extended properties to the source element
    return Object.assign(source, { extendedProps: _.omit(event.extendedProps, 'source') });
  }

  protected updateEventSource (event: EventApi) {
    const source = this.getEventSource(event);

    // ensures that the start/end dates lies within the day range
    // (sometimes the event is dragged outside this range when the calendar is resized by folding in/out the sidebars)
    this.correctEventPosition(event);

    source.start = moment.utc(event.start);
    source.end   = moment.utc(event.end);

    // do not emit "onEventUpdate" as this will make the event quickly jump back if dropped
  }

  public setBusinessHours (val: CalendarInterval[]): void {
    this._intervals = val;

    this.calendarOptions.businessHours
      = val.map(interval => ({
        daysOfWeek: [ interval.start.day() ],
        startTime:  interval.start.format('HH:mm'),
        endTime:    interval.end  .format('HH:mm')
      }));
  }
  private _intervals: CalendarInterval[] = [];


  public clear(): void {
    this.foregroundEvents.next([]);
    this.backgroundEvents.next([]);
  }

  protected rerender(): void {
    // a hax to force a rerendering of the calendar and respect a possibly new size
    this.setBusinessHours(this._intervals);
  }

  public setEditable (isEditable: boolean):void {
    this.calendarOptions.editable = isEditable;
  }

  public gotoDate(date: moment.Moment | string): void {
    this.calendar.getApi()?.gotoDate(moment.utc(date).format('YYYY-MM-DD'));
  }

  // zoom
  public zoomIn    () { this.zoom(-2*60);  }
  public zoomOut   () { this.zoom(+2*60); }
  public zoomReset () { this.scaleVertically(this._visibleRange.value); }

  // adjusts the event position to lie within the specified day range
  protected correctEventPosition (event: EventApi) {
    if (this._disableCorrectEventPosition) return;

    const start = moment.utc(event.start);
    const end   = moment.utc(event.end);
    if (this.startDate.isAfter(start)) {
      const diff = this.startDate.diff(start.clone().startOf('day'), 'days');
      start.add(diff, 'days');
      end  .add(diff, 'days');
    } else if (this.endDate.isBefore(end)) {
      const diff = end.clone().endOf('day').diff(this.endDate, 'days');
      start.subtract(diff, 'days');
      end  .subtract(diff, 'days');
    }
    event.setStart(start.toDate());
    event.setEnd  (end  .toDate());
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }


  private setupTimeAxisHoverEvents () {
    ////
    //// leave calendar
    ////
    // the mouse leaves the calendar body
    this.$calendar.on('mouseleave', '.fc-timegrid-body', () => {
      if (this.prevNum5Mins != undefined) {
        this.prevNum5Mins = undefined;
        this.onTimeAxisHover.emit();
      }
    });

    // the mouse enter the time labels
    this.$calendar.on('mouseenter', '.fc-timegrid-slot.fc-timegrid-slot-label', () => {
      if (this.prevNum5Mins != undefined) {
        this.prevNum5Mins = undefined;
        this.onTimeAxisHover.emit();
      }
    });


    ////
    //// mousemove
    ////
    // the mouse hovers an event
    this.$calendar.on('mousemove', '.fc-timegrid-col.fc-day', ev => {
      let dayElem = $(ev.currentTarget);

      // compute time
      let dy = ev.clientY - (dayElem.offset()!.top - parseInt(dayElem.css("border-top-width")));
      let height = dayElem.outerHeight()!;
      // need to account for inaccuracies
      let ratio = Math.max(0, Math.min(1, dy/height));
      let num5Mins = Math.round(ratio*24*60/5);

      // compute day index
      let classes = dayElem.attr("class")!;
      let days = ['fc-day-mon', 'fc-day-tue', 'fc-day-wed', 'fc-day-thu', 'fc-day-fri', 'fc-day-sat', 'fc-day-sun'];
      let d;
      for (d = 0; d < days.length; d++) {
        if (classes.includes(days[d])) break;
      }

      // try emit
      if (this.prevNum5Mins != d*24*60/5 + num5Mins) {
        this.prevNum5Mins = d*24*60/5 + num5Mins
        let d8 = this._date.fromDiscretizedTime(num5Mins, 5, d);
        this.onTimeAxisHover.emit(d8);
      }
    });

    // if there is no event where the mouse hovers
     this.$calendar.on('mousemove', '.fc-timegrid-slot.fc-timegrid-slot-lane', ev => {
      let timeElem = $(ev.currentTarget);

      // compute time
      let dy = ev.clientY - (timeElem.offset()!.top - parseInt(timeElem.css("border-top-width")));
      let height = timeElem.outerHeight()!;
      // need to account for inaccuracies
      let ratioY = Math.max(0, Math.min(1, dy/height));
      let numHours = parseInt(timeElem.data('time').split(':')[0]);
      let num5Mins = numHours*60/5 + Math.round(ratioY*60/5);


      // compute day index
      let dx = ev.clientX - timeElem.offset()!.left;
      let width = timeElem.outerWidth()!;
      // need to account for inaccuracies
      let ratioX = Math.max(0, Math.min(1, dx/width));
      let d = Math.trunc(ratioX * this.numDays);

      // try emit
      if (this.prevNum5Mins != d*24*60/5 + num5Mins) {
        this.prevNum5Mins = d*24*60/5 + num5Mins
        let d8 = this._date.fromDiscretizedTime(num5Mins, 5, d);
        this.onTimeAxisHover.emit(d8);
      }
    });
  }
  private prevNum5Mins: number | undefined;
}
