import { NgModule                        } from '@angular/core';
import { CommonModule                    } from '@angular/common';

import { TranslationModule               } from 'app/core/translate/translate.module';
import { EmptyComponent                  } from './empty.component';

@NgModule({
  declarations: [
    EmptyComponent,
  ],
  exports: [
    EmptyComponent
  ],
  imports: [
    CommonModule,
    TranslationModule
  ]
})
export class EmptyModule { }