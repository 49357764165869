<h1 matDialogTitle>{{ 'shared.dialogs.add-duration-set.title' | translate }}</h1>

<mat-dialog-content>
  <mat-form-field #formField appearance="outline" floatLabel="always" subscriptSizing="dynamic" >
    <mat-label>{{ 'shared.dialogs.add-duration-set.label' | translate }}</mat-label>
    <mat-chip-grid #chipGrid [formControl]="ctrl">
      <mat-chip-row *ngFor="let x of ctrl.value; let i = index" (removed)="remove(i)">
        {{ x }}
      </mat-chip-row>
      <input
        [cleave]="cleave_number"
        [placeholder]="'common.minutes' | translate | lowercase"
        [matChipInputFor]="chipGrid"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        matChipInputAddOnBlur
        (matChipInputTokenEnd)="add($event)"
      >
    </mat-chip-grid>
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button mat-dialog-close>
    {{ 'common.cancel' | translate }}
  </button>
  <span class="spacer"></span>
  <button mat-button color="primary"
    [disabled]=" ! ctrl.value.length"
    (click)="submit($event)" (keydown.enter)="submit($event)"
  >
    {{ 'common.add' | translate }}
  </button>
</mat-dialog-actions>