import { NgModule                             } from '@angular/core';
import { CommonModule                         } from '@angular/common';
import { RouterModule                         } from '@angular/router';
import { NgPipesModule                        } from 'ngx-pipes';

import { AppCommonModule                      } from 'app/common/common.module';
import { TranslationModule                    } from 'app/core/translate/translate.module';
import { ComponentsModule                     } from 'app/shared/components/components.module';
import { PipesModule                          } from 'app/shared/pipes/pipes.module';

import { GenerateScheduleComponent            } from './generate-schedule.component';

@NgModule({
  declarations: [
    GenerateScheduleComponent,
  ],
  exports: [
    GenerateScheduleComponent
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    TranslationModule,
    ComponentsModule,
    PipesModule,
    RouterModule,
    NgPipesModule
  ]
})
export class GenerateScheduleModule { }
