import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CalendarComponent } from './calendar/calendar.component';

import { CalendarModule } from 'app/shared/calendar/calendar.module';


@NgModule({
  declarations: [
    CalendarComponent,
  ],
  exports: [
    CalendarComponent,
  ],
  imports: [
    CommonModule,
    CalendarModule
  ]
})
export class ComponentsModule { }
