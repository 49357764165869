import { NgModule                         } from '@angular/core';
import { CommonModule                     } from '@angular/common';

import { AppCommonModule                  } from 'app/common/common.module';
import { SvgImageComponent                } from './svg-image.component';
import { PipesModule                      } from '@app/shared/pipes/pipes.module';


@NgModule({
  declarations: [
    SvgImageComponent,
  ],
  exports: [
    SvgImageComponent
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    PipesModule
  ]
})
export class SvgImageModule { }
