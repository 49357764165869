<!-- force rerender on stateChanges.next() otherwise it is not guaranteed to show the latest values due to some lazy rendering-->
{{ stateChanges | async }}

<mat-select class="form-field-duration-select"
            (selectionChange)="change($event)"
            [class.form-field-disabled]="disabled"
            panelClass="form-field-duration-select-panel dark-scrollbar"
            [placeholder]="(prefix ? prefix + ' ' : '') + voidText + (suffix ? ' ' + suffix : '')"
            [class.empty]="value == undefined"
            [value]="value">
  <mat-select-trigger>
    <app-form-field-duration-display-value [value]="value"
                                           (click)="disabled ? $event.stopPropagation(): null"
                                           [voidText]="voidText"
                                           [class.form-field-disabled]="disabled"
                                           [prefix]="prefix"
                                           [suffix]="suffix"
                                           [secondary]="secondary"
                                           [isPreferredDuration]="isPreferredDuration">
    </app-form-field-duration-display-value>
  </mat-select-trigger>
  <mat-option *ngIf="nullable"
              [value]="null"
              class="mat-select-options-sticky mat-elevation-z2">
    {{ setVoidText || ('common.no_value' | translate) }}
  </mat-option>
  <mat-option *ngFor="let duration of durations"
              [class.valid]="duration | inInterval:preferred:variance"
              [class.exact]="duration === preferred"
              [class.preferred]="preferred != null && variance != null"
              [value]="duration">
    {{ duration }}
  </mat-option>

</mat-select>