import { Component,
         ViewChild,
         AfterViewInit,
         Inject,
         DestroyRef,
         inject                          } from '@angular/core';
import { takeUntilDestroyed              } from '@angular/core/rxjs-interop';
import { CdkDragDrop,
         moveItemInArray,
         transferArrayItem               } from '@angular/cdk/drag-drop';
import { BehaviorSubject,
         Observable,
         combineLatest                   } from 'rxjs';
import { map,
         debounceTime                    } from 'rxjs/operators';
import { nanoid                          } from 'nanoid';
import _                                   from 'lodash';

import { LoggerService,
         sourceHasPipe,
         sourceMatchPipe,
         SourceService,
         UserPreferencesService          } from 'app/core';
import { ParallelCoursesComponent,
         SearchComponent                 } from 'app/shared';
import { Coalesced,
         DivisionSettings,
         Populated as P,
         Species                         } from 'app/shared/interfaces';
import { inOutAnimation                  } from 'app/shared/animations';
import { Value as FilterValue            } from '../filter/filter.interface';
import { FilterComponent                 } from '../filter/filter.component';
import { autoLink,
         isAutoLinkable                  } from '../events/utils';


function autoLinkChanges (overlapGroup: P.overlapGroup) {
  const events = (overlapGroup.coalesced ?? []).flatMap(x => x.to.events ?? []);
  const sets = autoLink(events);

  const changes: { id: string; species: Species<string, 'events'>[] } = {
    id:      overlapGroup.id,
    species: sets.flatMap(events => {
      const id = nanoid(8);
      return events.map(x => ({ id, to: x.id, toModel: 'events' }));
    })
  };

  return changes;
}

type OverlapGroup = P.overlapGroup & { isAutoLinkable?: boolean };

const delay = 0;

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.scss'],
  providers: [ SourceService ],
  animations: [ inOutAnimation ]
})
export class CoursesComponent implements AfterViewInit {
  private readonly destroyRef = inject(DestroyRef);

  protected readonly path = 'shared.dialogs.overlappableEventSets';
  protected readonly typeToken: { course?: { to: P.course, toModel: 'courses' } };
  protected readonly did: string;

  protected emptyContainer = [];

  protected onAddHover:    boolean = false;
  protected showEmptyList: boolean = true;

  protected readonly onFilter = new BehaviorSubject<FilterValue | null>(null);

  protected readonly maxNumListItems = new BehaviorSubject<number>(100);
  protected numHiddenCourses?: Observable<number>;

  protected settings:      Observable<DivisionSettings>;
  protected groups:        Observable<P.group[]>;
  protected teachers:      Observable<P.teacher[]>;
  protected courses:       Observable<Coalesced<P.course, 'courses'>[]>;
  protected courseMap$:    Observable<Map<string, P.course> >;
  protected overlapGroups = new BehaviorSubject<OverlapGroup[]>([]);

  protected id: string = nanoid(8);

  @ViewChild('leftSearch',    { static: false }) unusedSearch?: SearchComponent;
  @ViewChild('rightSearch',   { static: false }) usedSearch?:   SearchComponent;
  @ViewChild(FilterComponent, { static: false }) filter?:       FilterComponent;

  constructor (
    protected  preferences: UserPreferencesService,
    private _source: SourceService,
    private _logger: LoggerService,
    @Inject('parent')
    private _parentComponent: ParallelCoursesComponent
  ) {
    this.did = _parentComponent.did;

    this.settings   = this._source.getStrictSettings   ({ did: this.did, onDestroy: this.destroyRef });
    this.groups     = this._source.getPopulatedGroups  ({ did: this.did, onDestroy: this.destroyRef });
    this.teachers   = this._source.getPopulatedTeachers({ did: this.did, onDestroy: this.destroyRef });
    this.courseMap$ = this._source.getPopulatedCourses ({ did: this.did, onDestroy: this.destroyRef }).pipe(
      map(x => new Map(x.map(x => [x.id, x])))
    );

    // enable drag after the data has been updated
    this._source.getPopulatedOverlapGroups({ did: this.did, onDestroy: this.destroyRef })
    .pipe(
      takeUntilDestroyed(),
      debounceTime(delay)
    )
    .subscribe(() => {
      this._dragDisabled.next(false);
    });
  }

  ngAfterViewInit () {

    const searchKeys = ['displayName', 'groups.to.displayName', 'teachers.to.displayName', 'events.preferredDuration', 'period.displayName'];

    const coursesWithoutOverlapGroup: Observable<Coalesced<P.course, 'courses'>[]> =
      combineLatest({
        courses: this._source.getPopulatedCourses({ did: this.did, onDestroy: this.destroyRef },
            sourceHasPipe({ overlapGroup: false }),
            sourceMatchPipe(this.unusedSearch!.onValue, searchKeys)
          ),
        defaultPeriod: this._source.getStrictSettings({ did: this.did, onDestroy: this.destroyRef }).pipe(map(x => x.period))
      })
      .pipe(
        debounceTime(delay),
        map(x => {
          // add default period to courses if missing
          // (this implementation is flawed since it occurs after the search. However, it works since since it modifies the references?)
          x.courses.forEach(y => y.period ??= (x.defaultPeriod as any));

          return x.courses.map(x => ({ to: x, toModel: 'courses' }));
        }),
      );

    this.courses = combineLatest({
      all: coursesWithoutOverlapGroup,
      num: this.maxNumListItems,
    })
    .pipe(
      // show only the "num" first courses if truthy
      map(x => x.num ? _.take(x.all, x.num) : x.all),
    );

    this.numHiddenCourses = combineLatest({
      all:   coursesWithoutOverlapGroup.pipe(map(x => x.length)),
      shown: this.courses              .pipe(map(x => x.length))
    })
    .pipe(map(x => x.all - x.shown));

    combineLatest({
      overlapGroups: this._source.getPopulatedOverlapGroups({ did: this.did, onDestroy: this.destroyRef }),
      courseMap: this._source.getPopulatedCourses({ did: this.did, onDestroy: this.destroyRef }).pipe(
          map(x => new Map(x.map(x => [x.id, x])))
        ),
      filter: this.onFilter,
      search: this.usedSearch!.onValue
    })
    .pipe(
      map(({ overlapGroups, courseMap, filter, search }) => {

        // replace the shallow coalesced.to course object with the deep one
        let out = structuredClone(overlapGroups) as OverlapGroup[];
        out.forEach(x => {
          x.coalesced?.forEach(y => {
            if (y.toModel != 'courses') return;

            const course = courseMap.get(y.to.id);
            if ( ! course) return;

            y.to = course;
          });
        });

        // if the filter is active, keep only the overlap groups that contain a course with at least one of the filter values
        const groupFilter   = filter?.groups;
        const teacherFilter = filter?.teachers;
        if (groupFilter?.length || teacherFilter?.length) {
          out = out.filter(x => {
              return x.coalesced?.some(({ to: course }) => {
                return groupFilter  ?.some(id => course.groups  ?.some(y => y.to.id == id))
                    || teacherFilter?.some(id => course.teachers?.some(y => y.to.id == id));
              });
            });
        }

        // further filter by search
        if (search) {
          const searchTerm = search.toLowerCase();
          out = out.filter(x => {
              return x.coalesced?.some(({ to: course }) => {

                if (course.displayName        ?.toLowerCase().includes(searchTerm)) return true;
                if (course.period?.displayName?.toLowerCase().includes(searchTerm)) return true;
                if (course.groups  ?.some(y => y.to.displayName?.toLowerCase().includes(searchTerm))) return true;
                if (course.teachers?.some(y => y.to.displayName?.toLowerCase().includes(searchTerm))) return true;
                if (course.events  ?.some(y => y.preferredDuration?.toString().includes(searchTerm))) return true;

                return false
              });
            });
        }

        // figure out which overlap groups are auto-linkable
        // (and share with parent component)
        out.forEach(x => {
          const events = (x.coalesced ?? []).flatMap(y => y.to.events ?? []);
          x.isAutoLinkable = isAutoLinkable(events);
        });
        this._parentComponent.setNumAutoLinkable(out.filter(x => x.isAutoLinkable).length);

        return out;
      }),
      debounceTime(delay),
      takeUntilDestroyed(this.destroyRef)
    )
    .subscribe(this.overlapGroups);
  }

  protected drop(event: CdkDragDrop<Coalesced<P.course, 'courses'>[]>) {
    try {
      if (event.container == event.previousContainer) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);

        // need to enable drag here since no data update is expected
        this._dragDisabled.next(false);
      } else {
        transferArrayItem(event.previousContainer.data,
                          event.container.data,
                          event.previousIndex,
                          event.currentIndex);

        // update data
        const index        = event.currentIndex;
        const id           = event.container.data[index].to.id;
        const overlapGroup = event.container.id;
        this._source.set({ collection: 'courses', did: this.did }, { id, overlapGroup });
      }
    } catch(err) {
      this._logger.error(err);
    }
  }

  protected create(event: CdkDragDrop<never[]>) {
    this.drop(event);
    this.id = nanoid(8);
    this.emptyContainer = [];
  }

  protected delete (overlapGroup: P.overlapGroup) {
    // await response before being able to drag again
    this._dragDisabled.next(true);

    this._source.unset({ collection: 'overlapGroups', did: this.did }, overlapGroup.id);
  }

  protected released () {
    // This function is triggered by the cdkDragReleased event when the user has released a drag item, before any animations have started.
    // This event is critical to prevent any crashes since the cdkDropListDropped is triggered after the animation.
    // Using only the latter would mean that the user might have already started another drag action which in turn
    // is what causes the crash in certain situations.
    this._dragDisabled.next(true);
  }

  protected trackBy (index: number, entity: { id: string }): string {
    return entity.id;
  }
  protected trackByCoalesced (index: number, coalesced: { to: { id: string }}): string {
    return coalesced.to.id;
  }

  protected gotoEventsTab (val: P.overlapGroup) {
    this._parentComponent.gotoEventsTab(val);
  }

  protected showRemaining (event: Event) {
    // ensure that the function is not fired twice by the same event (keydown and click)
    event.preventDefault();
    this.maxNumListItems.next(0);
  }

  protected autoLink (overlapGroup: P.overlapGroup) {
    const changes = autoLinkChanges(overlapGroup);
    this._source.set({ collection: 'overlapGroups', did: this.did }, changes);
  }

  public autoLinkAll () {
    const changes = this.overlapGroups.value
      .filter(x => x.isAutoLinkable)
      .map(x => autoLinkChanges(x));
    this._source.set({ collection: 'overlapGroups', did: this.did }, changes);
  }

  ////
  //// IO
  ////
  // disable drag when we expect a data change to prevent dragula from crashing
  get dragDisabled () { return this._dragDisabled as Observable<boolean>; }
  private _dragDisabled = new BehaviorSubject(false);
}