import { Pipe,
         PipeTransform                   } from '@angular/core';

import { Group,
         Teacher                         } from 'app/shared/interfaces';

import { Key,
         Filter                          } from './filter.interface';

@Pipe({
  name: 'collectionIcon'
})
export class CollectionIconPipe implements PipeTransform {
  transform(collection: Key): unknown {
    switch (collection) {
      case 'groups':
      default:
        return 'group';
      case 'teachers':
        return 'school';
    }

  }
}

@Pipe({
  name: 'filterActive'
})
export class FilterActivePipe implements PipeTransform {
  transform <T extends { id: string }> (values: T[], filter: Filter | null, type: Key): T[] {
    if ( ! filter) return values;

    const _filters = filter
      .filter(({ key }) => key == type)
      .map(({ value }) => value);

    return values.filter(value => ! _filters.includes(value.id));
  }
}