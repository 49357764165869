<mat-chip-set>
  <mat-chip-row *ngFor="let meta of (filter | async)" (removed)="removeFilter(meta.value, meta.key)">
    <mat-icon matChipAvatar>{{ meta.key | collectionIcon }}</mat-icon>
    {{ transform[meta.key].get(meta.value) | displayName }}
    <button matChipRemove>
      <mat-icon>cancel</mat-icon>
    </button>
  </mat-chip-row>
</mat-chip-set>

<button mat-icon-button
  [matTooltip]="'common.add_filter' | translate"
  [matMenuTriggerFor]="typeMenu"
>
  <mat-icon >{{ active ? ('filter_' + active) : 'filter_list' }}</mat-icon>
</button>


<mat-menu #typeMenu="matMenu" class="dark-scrollbar">
  <button mat-menu-item
          [matMenuTriggerFor]="groupsMenu"
          [disabled]="! (groups | filterActive:(filter | async):'groups').length"
          *ngIf="! disableGroups && groups.length">
    <mat-icon>people</mat-icon>
    {{ 'common.group' | translate }}
  </button>

  <button mat-menu-item
          [matMenuTriggerFor]="teacherMenu"
          [disabled]="! (teachers | filterActive:(filter | async):'teachers').length"
          *ngIf="! disableTeachers && teachers.length">
    <mat-icon>school</mat-icon>
    {{ 'common.teacher' | translate }}
  </button>
</mat-menu>

<mat-menu #groupsMenu="matMenu" class="dark-scrollbar">
  <button mat-menu-item
          *ngFor="let group of groups | filterActive:(filter | async):'groups'"
          (click)="addFilter(group.id, 'groups')">
    {{ group | displayName }}
  </button>
</mat-menu>

<mat-menu #teacherMenu="matMenu" class="dark-scrollbar">
  <button mat-menu-item
          *ngFor="let teacher of (teachers | filterActive:(filter | async):'teachers')"
          (click)="addFilter(teacher.id, 'teachers')">
    {{ teacher | displayName }}
  </button>
</mat-menu>
