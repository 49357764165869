<section class="instructions">
  {{ 'dialogs.create-courses.components.excel.instructions' | translate }}
</section>

<section class="flex-justify-center">
  <button mat-button (click)="downloadTemplate()" (keydown.enter)="downloadTemplate()">
    {{ 'common.download' | translate }}
  </button>

  <input #fileInput
    hidden
    type="file"
    [accept]="acceptedTypes"
    (change)="fileInputChange.next($event)"
  >
  <button mat-flat-button color="primary" (click)="fileInput.click()">
    @if (file | async; as file) {
      {{ 'common.selected_file' | translate:{ file: file?.name } }}
    } @else {
      {{ 'common.select_file' | translate }}
    }
  </button>
</section>

@if (warnings | async; as warnings) {
  @if (warnings?.length) {
    <section>
      <mat-list>
        @for (x of warnings; track $index) {
          <mat-list-item class="dynamic-height">
            <mat-icon matListItemIcon class="warn-color">error</mat-icon>
            <span>{{ x }}</span>
          </mat-list-item>
        }
      </mat-list>
    </section>
  }
}

@if (displayCourses | async; as courses) {
  @if (courses.length > 0) {
    <section class="dark-scrollbar">
      <table>
        <thead>
          <tr>
            <th [innerHTML]="'attributes.shared.ids'                | translate | capitalizeFirst"></th>
            <th [innerHTML]="'attributes.shared.displayName'        | translate | capitalizeFirst"></th>
            <th [innerHTML]="'attributes.shared.subject'            | translate | capitalizeFirst"></th>
            <th [innerHTML]="'attributes.shared.groups'             | translate | capitalizeFirst"></th>
            <th [innerHTML]="'attributes.shared.teachers'           | translate | capitalizeFirst"></th>
            <th [innerHTML]="'attributes.course.plannedDuration'    | translate | capitalizeFirst"></th>
            <th [innerHTML]="'common.event_durations'               | translate | capitalizeFirst"></th>
            <th [innerHTML]="'attributes.shared.locations'          | translate | capitalizeFirst"></th>
          </tr>
        </thead>
        <tbody>
          @for (course of courses; track $index) {
            <tr>
              <td>{{ course.ids }}</td>
              <td>{{ course.displayName }}</td>
              <td>{{ course.subject }}</td>
              <td>{{ course.groups }}</td>
              <td>{{ course.teachers }}</td>
              <td>{{ course.plannedDuration }}</td>
              <td>{{ course.events }}</td>
              <td>{{ course.locations }}</td>
            </tr>
          }
      </table>
    </section>
  }
}