import { NgModule                            } from '@angular/core';
import { CommonModule                        } from '@angular/common';
import { FormsModule,
         ReactiveFormsModule                 } from '@angular/forms';
import { ScrollingModule                     } from '@angular/cdk/scrolling';

import { AppCommonModule                     } from 'app/common/common.module';

import { TranslationModule                   } from 'app/core/translate/translate.module';

import { ComponentsModule                    } from 'app/shared/components/components.module';

import { ColorModule                         } from './color/color.module';
import { GroupsFormFieldModule               } from './groups/groups.module';
import { BreakLengthFormFieldModule          } from './break-length/break-length.module';
import { AvailableDaysFormFieldModule        } from './available-days/available-days.module';
import { AvailableLocationsFormFieldModule   } from './available-locations/available-locations.module';
import { DurationFormFieldModule             } from './duration/duration.module';
import { DynamicLockedTimesFormFieldModule   } from './dynamic-locked-times/dynamic-locked-times.module';
import { LockedTimesFormFieldModule          } from './locked-times/locked-times.module';
import { DayFormFieldModule                  } from './day/day.module';
import { TimeFormFieldModule                 } from './time/time.module';
import { FixedStartFormFieldModule           } from './fixed-start/fixed-start.module';
import { IntervalsFormFieldModule            } from './intervals/intervals.module';
import { CenterOfAttractionFormFieldModule   } from './center-of-attraction/center-of-attraction.module';
import { PeriodFormFieldModule               } from './period/period.module';
import { RootIntervalsFormFieldModule        } from './root-intervals/root-intervals.module';
import { OptionsFormFieldModule              } from './options/options.module';
import { TextFormFieldModule                 } from './text/text.module';
import { NumHoursFormFieldModule             } from './num-hours/num-hours.module';
import { DurationWithVariationModule         } from './duration-with-variation/duration-with-variation.module';
import { MinutesFormFieldModule              } from './minutes/minutes.module';

import { CourseComponent                     } from './course/course.component';
import { PlannedDurationComponent            } from './planned-duration/planned-duration.component';
import { TagsComponent                       } from './tags/tags.component';

import { FormFieldsDirective                 } from './form-fields.directive';

@NgModule({
  declarations: [
    CourseComponent,
    FormFieldsDirective
  ],
  exports: [
    ColorModule,
    GroupsFormFieldModule,
    BreakLengthFormFieldModule,
    AvailableDaysFormFieldModule,
    AvailableLocationsFormFieldModule,
    DurationFormFieldModule,
    DynamicLockedTimesFormFieldModule,
    LockedTimesFormFieldModule,
    DayFormFieldModule,
    TimeFormFieldModule,
    FixedStartFormFieldModule,
    IntervalsFormFieldModule,
    TextFormFieldModule,
    OptionsFormFieldModule,
    CourseComponent,
    CenterOfAttractionFormFieldModule,
    PeriodFormFieldModule,
    RootIntervalsFormFieldModule,
    NumHoursFormFieldModule,
    DurationWithVariationModule,
    MinutesFormFieldModule,
    FormFieldsDirective,
    PlannedDurationComponent,
    TagsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AppCommonModule,
    ScrollingModule,
    TranslationModule,
    ComponentsModule,
    PlannedDurationComponent,
    TagsComponent
  ]
})
export class FormFieldsModule { }
