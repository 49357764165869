<div class="label"
     [matTooltip]="tooltip"
     [matTooltipDisabled]="inherits"
      matTooltipClass="new-line-tooltip"
     [class.inherits]="inherits"
     [class.multiple-lines]="label | isArray">
  @if (label | typeGuard:isString) {
    {{ label }}
  }
  @if (label | typeGuard:isArray; as array) {
    <div class="per-day-row">
      @for (x of array; track $index){
        <div class="per-day-column">
          <div class="container">
            @if (x.available) {
              <div class="nowrap">{{ 'common.day_' + $index | translate | shorten:3 }}</div>
              <div class="nowrap">{{ x.start }}</div>
              <div class="nowrap">{{ x.end }}</div>
            }
          </div>
        </div>
      }
    </div>
  }
</div>