import { NgModule                        } from '@angular/core';
import { CommonModule                    } from '@angular/common';
import { FormsModule,
         ReactiveFormsModule             } from '@angular/forms';
import { NgxCleaveDirectiveModule        } from 'ngx-cleave-directive';

import { TranslationModule               } from 'app/core/translate/translate.module';
import { AppCommonModule                 } from 'app/common/common.module';
import { ComponentsModule                } from 'app/shared/components/components.module';
import { ComponentsModule as SubModule   } from './components/components.module';
import { BreakLengthComponent            } from './break-length.component';
import { DurationFormFieldModule         } from '../duration/duration.module';
import { MinutesFormFieldModule          } from '../minutes/minutes.module';


@NgModule({
  declarations: [
    BreakLengthComponent
  ],
  exports: [
    BreakLengthComponent,
    SubModule
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    DurationFormFieldModule,
    TranslationModule,
    FormsModule,
    SubModule,
    ComponentsModule,
    ReactiveFormsModule,
    NgxCleaveDirectiveModule,
    MinutesFormFieldModule
  ]
})
export class BreakLengthFormFieldModule { }
