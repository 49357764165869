import _                                from "lodash";

import { commonConstants              } from "@app/constants";
import { CommonService                } from "../common/common.service";
import { InputAnalysisService         } from "./input-analysis.service";
import { Remark                       } from "./types";


const defaultEventColor      = commonConstants.COLORS.EVENT_DEFAULT;
const defaultLockedTimeColor = commonConstants.COLORS.LOCKED_TIME_DEFAULT;

function chipFactory (
  content: string,
  args:   {
    class?: string,
    color?: string,
    bg?:    string,
  } = {}
): string {
  const bgColor   = args.bg    ?? defaultEventColor;
  const textColor = args.color ?? CommonService.contrastService(bgColor);
  return `<span class="chip-outer">
            <span class="chip ${args.class || ''}"
                  style="background: ${bgColor} !important; color: ${textColor} !important">${content}</span>
          </span>`;
}


export function makeReadable (
  this:     InputAnalysisService,
  entities: Remark['entities'],
  { chip = false, capitalize = true }: { chip?: boolean, capitalize?: boolean } = { }
): string {

  // group the entities by their type
  let i = 0;
  const segments = _(entities)
  .groupBy('is')
  .map((entities) => {
    // order according to whether they are require definite form
    const names = _.orderBy(entities.map(entity => this.makeSingleReadable(entity, chip)), 'makeDefinite', 'desc');
    const numDefinite = names.filter(x => x.makeDefinite).length;

    // try add definite form
    let pre = '';
    if (numDefinite == 1) {
      if      (entities[0].is == 'period'    ) pre = this.t('common.the_period');
      else if (entities[0].is == 'location'  ) pre = this.t('common.the_location');
      else if (entities[0].is == 'group'     ) pre = this.t('common.the_group');
      else if (entities[0].is == 'teacher'   ) pre = this.t('common.the_teacher');
      else if (entities[0].is == 'person'    ) pre = this.t('common.the_person');
      else if (entities[0].is == 'course'    ) pre = this.t('common.the_course');
      else if (entities[0].is == 'event'     ) pre = this.t('common.the_event');
      else if (entities[0].is == 'lockedTime') pre = this.t('common.the_lunch');
      else if (entities[0].is == 'holiday'   ) pre = this.t('common.the_holiday');
    } else if (numDefinite > 1) {
      if      (entities[0].is == 'period'    ) pre = this.t('common.the_periods');
      else if (entities[0].is == 'location'  ) pre = this.t('common.the_locations');
      else if (entities[0].is == 'group'     ) pre = this.t('common.the_groups');
      else if (entities[0].is == 'teacher'   ) pre = this.t('common.the_teachers');
      else if (entities[0].is == 'person'    ) pre = this.t('common.the_persons');
      else if (entities[0].is == 'course'    ) pre = this.t('common.the_courses');
      else if (entities[0].is == 'event'     ) pre = this.t('common.the_events');
      else if (entities[0].is == 'lockedTime') pre = this.t('common.the_lunches');
      else if (entities[0].is == 'holiday'   ) pre = this.t('common.the_holidays');
    }

    let out = '';
    if      (names.length >  3) out =  `${ pre.toLowerCase() } ${ names[0].value }, ${ names[1].value } ${ this.t('common.andNOthers', {n: names.length - 2})}`;
    else if (names.length == 3) out =  `${ pre.toLowerCase() } ${ names[0].value }, ${ names[1].value } ${ this.t('common.and1Other')}`;
    else if (names.length == 2) out =  `${ pre.toLowerCase() } ${ names[0].value } ${ this.t('common.and') } ${ names[1].value }`;
    else if (names.length == 1) out =  `${ pre.toLowerCase() } ${ names[0].value }`;

    // trim
    out = out.trim();

    // try capitalize
    if (i == 0 && capitalize) out = out.capitalizeFirst();

    return out;
  })
  .value();

  // join the segments
  if (segments.length == 1) return segments[0];
  else                      return `${segments.splice(0, segments.length - 1).join(', ')} ${this.t('common.and')} ${segments[segments.length - 1]}`;
}

export function makeSingleReadable (
  this:   InputAnalysisService,
  entity: Remark['entities'][0],
  chip:   boolean = false
): { makeDefinite: boolean, value: string } {

  // if ( ! entity) {
  //   this._logger.error(new Error(`(InputAnalysisService::makeReadable) unknown entity ${JSON.stringify(entity)}`));
  //   return 'unknown entity'.capitalizeFirst(capitalize);
  // }

  if (entity.is == 'event') {
    const name  = this._common.deriveName(entity);
    const opt   = { bg: entity.color ?? entity.course?.color };

    if (name.owner == 'event') return { makeDefinite: true,  value:                                                            chip ? chipFactory(name.value, opt) : `<b>${name.value}</b>`  };
    else                       return { makeDefinite: false, value: this.t('common.an_event_belonging_to_the_course') + ' ' + (chip ? chipFactory(name.value, opt) : `<b>${name.value}</b>`) };
  }
  else if (entity.is == 'lockedTime' && entity.type == 'LUNCH') {
    const parent     = entity.coalesced?.[0].to;
    const parentType = entity.coalesced?.[0].toModel.slice(0, -1); // removes the trailing 's':  groups -> group, teachers -> teacher, persons -> person
    const name       = this._common.deriveName(parent);
    const opt        = { bg: defaultLockedTimeColor };

    return { makeDefinite: false, value: this.t(`common.a_lunch_belonging_to_the_${parentType}`) + ' ' + (chip ? chipFactory(name.value, opt) : `<b>${name.value}</b>`) };
  }
  else if (entity.is == 'course') {
    const name = this._common.deriveName(entity).value
    const opt  = { bg: entity.color };

    return { makeDefinite: true, value: chip ? chipFactory(name, opt) : `<b>${name}</b>` };
  }
  else if (entity.is == 'group' || entity.is == 'teacher' || entity.is == 'person') {
    const name = this._common.deriveName(entity).value;

    return { makeDefinite: true, value: chip ? chipFactory(name) : `<b>${name}</b>` };
  }
  else if (entity.is == 'period') {
    const name = this._common.deriveName(entity).value;

    return { makeDefinite: true, value: chip ? chipFactory(name) : `<b>${name}</b>` };
  }
  else if (entity.is == 'holiday') {
    const name = this.getName(entity);

    return { makeDefinite: true, value: chip ? chipFactory(name) : `<b>${name}</b>` };
  }
  else {
    this._logger.error(new Error(`(InputAnalysisService::makeReadable) unknown entity ${JSON.stringify(entity)}`));
    return { makeDefinite: false, value: 'an unknown entity' };
  }
}