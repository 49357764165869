
import $                                         from 'jquery';

import { PushNotificationService               } from './push-notification.service';
import { Categories, Category, Sounds, Button,
         NotificationArgs, Message, Stack      } from './types';


export function push (
  this:     PushNotificationService,
  message:  Message,
  category: Category,
  args:    {
    duration?:   number | false;
    silent?:     boolean;
    title?:      string;
    titleClass?: string;
    icon?:       string;
    iconClass?:  string;
    buttons?:    Button[];
  } = {}
) {
  // fetch stack
  let stack = this._stacks.get(category);

  if ( ! stack) {
    this._logger.error(new Error(`Stack of category "${category}" does not exist. Defaulting to "other" stack.`));
    stack = this._stacks.get('other')!;
  }

  // create default message args
  let messageArgs: NotificationArgs = {
    id:         this.notificationCount++,
    duration:   (args.duration !== undefined ? args.duration : 5000),
    title:      args.title      || false,
    titleClass: args.titleClass || '',
    icon:       args.icon       || false,
    iconClass:  args.iconClass  || '',
    buttons:    (args.buttons?.length ? args.buttons : false),
  };

  // try translate message
  const finalMessage = typeof message == 'object' ? this._translate.instant(message.translate) : message;

  // add to data structure
  stack.notifications.push({ message: finalMessage, args: messageArgs });

  if (stack.args.mode != 'off') {
    // render DOM
    this.createNotifElem(stack, finalMessage, messageArgs);

    // try emit sound
    if (stack.args.mode == 'on' && ! args.silent) {
      let audio = Sounds.find(s => s.name == stack?.args.sound)!.audio;

      audio.volume = stack.args.volume / 100;
      audio.play();
    }
  }

  // emit update
  this._stacks_BH.next(this._stacks);
}





export function notificationFactory (
  this: PushNotificationService,
  msg:  string,
  args: NotificationArgs
): string {

  let header = '';
  if (args.title && args.icon) {
    header = `<div class="title">
                <span class="icon material-icons-outlined ${args.iconClass}">
                  ${args.icon}
                </span>
                <span class="${args.titleClass}">
                  ${args.title}
                </span>
              </div>`;
  } else if (args.title) {
    header = `<div class="title">
                <span class="${args.titleClass}">
                  ${args.title}
                </span>
              </div>`;
  }

  let buttons = '';
  let bottomPusher = '';
  if (args.buttons) {
    buttons = `<div class="buttons-wrapper">
                 ${ /* buttons to be appended later */ ''}
               </div>`;

    bottomPusher = `<!-- margin for inaccurate flex caluclation -->
                    <div class="bottom-pusher"></div>`;
  }

  return `<div class="notification creating ${args.id}">
            <div class="close"></div>
            <div class="content">
              ${header}
              <div class="body">
                <div style="width:100%">
                  ${buttons}
                  ${msg}
                  ${bottomPusher}
                </div>
              </div>
            </div>
          </div>`;
}

export function createNotifElem (
  this:  PushNotificationService,
  stack: Stack,
  msg:   string,
  args:  NotificationArgs
) {
  // fetch container element
  let   containerId = 'notification-container';
  let containerElem = $(`#${containerId}`);

  // fetch corner
  let cornerClass = '.' + (stack.args.location as string).replace(/\s+/g, '.');
  let wrapper     = containerElem.find(`.corner${cornerClass} .wrapper`);

  // fetch stack element
  let stackElem  = wrapper.children(`.stack.${stack.args.category}`);
  if ( ! stackElem.length) {
    stackElem = wrapper.append(`<div class="stack ${stack.args.category}"></div>`)
                       .children(`.stack.${stack.args.category}`);
  }


  // create notification
  stackElem.append(this.notificationFactory(msg, args))
  let notifElem = stackElem.children('.notification.creating').last();

  // try append buttons
  // (need to do this separately in order to bind events)
  if (args.buttons) {
    let buttonsWrapper = notifElem.find('.buttons-wrapper'); //.css("background", "red");
    args.buttons.forEach(b => {
      let buttonHTML = `<button class="${b.class || ''} ${b.close === false ? '' : 'close'}">${b.text}</button>`;
      buttonsWrapper.append(buttonHTML)
                    .children().last()
                    .on('click', b.action);
    });
  }


  // remove "new" class from notification which will trigger a transition
  setTimeout(() => {
    notifElem.removeClass('creating');
  }, 0);

  // try remove removal animation of stack
  stackElem.removeClass('closing')


  ////
  //// try schedule removal of notification
  ////
  if (args.duration !== false) {

    // set initial timer
    let timer = setTimeout(() => {
      notifElem.children('div.close').trigger('click');
    }, args.duration as number);

    // pause timer on mouseover
    notifElem.on("mouseover", () => {
      clearTimeout(timer);
    });

    // restart timer on mouseout
    notifElem.on("mouseout", () => {
      timer = setTimeout(() => {
        notifElem.children('div.close').trigger('click');
      }, args.duration as number);
    });
  }
}





export function closeNotification (
  this: PushNotificationService,
  ev:   JQuery.ClickEvent
) {
  let notifElem = $(ev.target).closest('.notification');
  let stackElem = notifElem.parent('.stack');

  // get notification and stack id
  let notifId = parseInt(notifElem.attr('class')!.match(/(\d+)/)![0]);
  let stackName = Categories.find(cat => stackElem.attr('class')?.includes(cat)) as Category;

  // get stack
  let stack = this._stacks.get(stackName)!;

  // animate removal
  notifElem.addClass('closing');

  // remove also the parent stack if empty
  if (stackElem.children().length == 1) stackElem.addClass('closing');

  // start animating second notification to transition into the first
  let notifAfter = notifElem.next('.notification')
                            .addClass('after-closing-first');

  // Fixes the margin (delayed as to not push list down)
  setTimeout(() => {
    notifAfter.addClass('after-closing-second');
  }, 150);


  // remove element
  setTimeout(() => {
    notifElem.remove();

    // try remove the "after-closing" classes
    setTimeout(() => {
      notifAfter.removeClass('after-closing-first after-closing-second');
    }, 0);

    // remove notification from stack
    stack.notifications = stack.notifications.filter(n => n.args.id != notifId);

    // remove also the parent stack if empty
    if (stackElem.children().length == 0) {

      // // if there will be no more stacks in the corner remove transparent class
      // if (stackElem.siblings().length == 0)
      //   $("#notification-container .corner:hover").removeClass('transparent');

      // remove stack element
      stackElem.remove();
    }

    // mouseout fix for safari which fails to be triggered
    setTimeout(() => {
      if ($("#notification-container .corner:hover").length == 0)
        $("#notification-container .corner").removeClass('transparent');
    }, 0);

  }, 300);
}