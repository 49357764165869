import { of                               } from 'rxjs';
import { TranslateLoader,
         MissingTranslationHandler,
         MissingTranslationHandlerParams,
         TranslateCompiler                } from '@ngx-translate/core';

import { Util                             } from '@app/common';
import { EnvironmentService               } from '../environment/environment.service';
import { getSubstitutionRecord,
         substituteInTranslation          } from './substitute';
import { LanguageConstant                 } from './types';
import { languageRecords                  } from './constants';


function deriveFromIntl (
  lang:         LanguageConstant,
  translations: { [key: string]: string }
) {
  // day names
  for (const style of ['long', 'short', 'narrow'] as const) {
    const dateTime = new Intl.DateTimeFormat(lang, { weekday: style });
    const dayNames = [...Array(7).keys()].map((day) => dateTime.format(new Date(1970, 0, 5 + day)).capitalizeFirst());

    dayNames.forEach((name, i) => translations[`common.day_${style}_${i}`] = name);
    if (style === 'long') dayNames.forEach((name, i) => translations[`common.day_${i}`] = name);
  }

  // // time units (not yet supported outside Safari)
  // if ('DurationFormat' in Intl) {
  //   for (let unit of ['hours', 'minutes', 'seconds'] as const) {
  //     for (let style of ['long', 'short', 'narrow'] as const) {
  //       const format = new (Intl as any).DurationFormat(lang)
  //       console.log(format)
  //     }
  //   }
  // } else {
  //   console.log('DurationFormat not supported')
  // }

}


export function getTranslation (lang: LanguageConstant) {
  const trans = languageRecords[lang];

  // derive some translations from the initialization library
  deriveFromIntl(lang, trans);

  return structuredClone(trans);
}

export class CustomLoader implements TranslateLoader {
  public getTranslation (lang: LanguageConstant) {
    return of(getTranslation(lang));
  }
}

export class CustomTranslateCompiler implements TranslateCompiler {

  constructor (private environment: EnvironmentService) {}

  compile (value: string, lang: string): string {
    // console.log('compile', value, lang)
    return value;
  }
  compileTranslations (
    translation: Record<string, string>,
    lang:        LanguageConstant
  ): Record<string, string> {
    // useful when searching for words to convert into symbols
    if (false) {
      // find all translations containing templates of the form [[...]] and extract the content
      const templates = new Set<string>();
      Util.functions.objectEntries(translation)
      .forEach(([key, value]) => {
        const matches = value.match(/\[\[([^\]]*)\]\]/g);
        if (matches) {
          matches.forEach((match) => {
            // remove the brackets and add to the set
            templates.add(match.slice(2, -2).toLowerCase());
          });
        }
      });
      console.log('templates', templates)

      console.log('--------------------------------------------------')
      Util.functions.objectEntries(translation)
      .forEach(([key, value]) => {
        // replace all occurrences
        // a regex that matches 'XYZ...' but not '[[XYZ...' where '...' is any number of characters
        const regex = /(?<!\[\[)'ämne'[a-zåäö]*\b/gi;
        if (value.match(regex)) {
          console.log(key, value)
        }
      });
      console.log('--------------------------------------------------')
    }

    const substitutionRecord = getSubstitutionRecord(lang, this.environment.organizationType);

    // if the translation is provided it is updated in place
    substituteInTranslation(translation, substitutionRecord);

    return translation;
  }
}

export class CustomMissingTranslationHandler implements MissingTranslationHandler {
  handle (params: MissingTranslationHandlerParams) {
    return `${params.key}`;
  }
}

