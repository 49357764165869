import { proxy,
         ProxyMarked,
         releaseProxy,
         finalizer,
         Remote                          } from 'comlink';

import { SystemCore                      } from '@core/source/core/system-core';
//import { Proxy                           } from './proxy';
import { Subscriber,
         Observable,
         shareReplay,
         finalize                        } from 'rxjs';
import { nanoid                          } from 'nanoid';
import _                                   from 'lodash';
import { SourceData } from '../../source.interface';

/*
                    ______________________
                    |                     |
                    |  _________________  |
                    | |    Coalesced    | |
                    | |      Core       | |     ./core
                    | |_________________| |
                    |          |          |
                    | _________|_________ |
                    | |                 | |
                    | |      Proxy      | |
                    | |_________________| |
                    |     Shared Worker   |
                    |_____________________|
                      |                |
               port 1 |                | port N
     _________________|                |________________
     |   [origin 1]   |                |  [origin N]    |
     |  ___________   |                |  ___________   |
     |  |          |  |                |  |          |  |
     |  |  reverse |  |      ...       |  |  reverse |  |      <- You are here
     |  |   proxy  |  |                |  |   proxy  |  |
     |  |__________|  |                |  |__________|  |
     |  ______|____   |                |  ______|____   |
     |  |          |  |                |  |          |  |
     |  |  source  |  |      ...       |  |  source  |  |
     |  |__________|  |                |  |__________|  |
     |________________|                |________________|


               |                       destroy()
               |                          |
               |                          ^
               |                          |
      worker   |     Observable      Teardown logic
               |         |                |
               |---------v----------------^-----------------------
               |         |                |
      main     |      subscribe()     unsubscribe()
               |         |________________|
               |         |
               |         v
               |         |
               |        docs
*/

type Proxy = any;

export class ReverseProxy {
  public identifier = nanoid();
  private cache = new Map<string, WeakRef<Observable<any>>>();
  private refs = new Map<string, Set<WeakRef<any>>>();
  // private registerFinalizer = new FinalizationRegistry(message => {
  //   console.log(message)
  // });

  constructor(private _core: Remote<Proxy>) {
  }

  public checkRefs() {
    const unReleased: Record<string, any[]> = {};
    this.refs.forEach((value, key) => {
      unReleased[key] = Array.from(value).map(ref => ref.deref()).filter(x => x != null);
    });
    return unReleased;
  }

  public get(...args: Parameters<SystemCore['get']>): ReturnType<SystemCore['get']> {

    const { collection, did, ...meta } = args[0];
    if (_.isEmpty(meta) && this.cache.has(collection)) {
      // console.log('cache hit', collection);
      const ob = this.cache.get(`${did}.${collection}`)!.deref();
      if (! ob) console.log('cache miss due to garbage collector', collection);
      if (ob) return ob;
    }
    // console.log('cache miss', collection, _.isEmpty(meta));
    let teardownFunction: Function | undefined;
    let callback: ProxyMarked | null;
    const ob = new Observable((subscriber: Subscriber<SourceData | SourceData[] | null>) => {

      callback = proxy((val: string | null) => {
        try {
          const docs = val ? JSON.parse(val) : val;
          subscriber.next(docs);
          if (docs)
            this.refs.get(`${did}.${collection}`)?.add(new WeakRef(docs));
        } catch(err) {
          console.log(val)
          console.error(err);
        }
      });

      this._core.get(this.identifier, callback, ...args)
      .then((unsubscribe: Function) => {
        teardownFunction = unsubscribe;
        this.refs.get(`${did}.${collection}`)?.add(new WeakRef(unsubscribe));
      });

    }).pipe(
      finalize(() => {
        teardownFunction?.();
        teardownFunction = undefined;
        (callback as any)?.[releaseProxy];
        (callback as any)?.[finalizer];
        this.cache.delete(`${did}.${collection}`);
        callback = null;
        // setTimeout(() => {
        //   console.log('checking refs', collection);
        //   for (const ref of this.refs.get(collection) ?? []) {
        //     if (ref.deref()) {
        //       this.registerFinalizer.register(ref.deref(), 'Proxy is now cleaned up')
        //       console.log('some refs are still alive', collection, ref.deref());
        //     }
        //   }
        // }, 10000);
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    if (_.isEmpty(meta)) {
      this.refs.set(`${did}.${collection}`, new Set([new WeakRef(ob)]));
      this.cache.set(`${did}.${collection}`, new WeakRef(ob));
    }

    // this.registerFinalizer.register(ob, 'Observable is now cleaned up');

    return ob;
  }

  public set(...args: Parameters<SystemCore['set']>): ReturnType<SystemCore['set']> {
    const [options, update] = args;
    return this._core.set(options, JSON.stringify(update));
  }

  public unset(...args: Parameters<SystemCore['unset']>): ReturnType<SystemCore['unset']> {
    return this._core.unset(...args);
  }

  public complete(...args: Parameters<SystemCore['complete']>): ReturnType<SystemCore['complete']> {
    return this._core.complete(...args);
  }

  public useCommand(...args: any[]): void {
    return this._core.useCommand(...args);
  }

  public groupBy(...args: Parameters<SystemCore['groupBy']>): ReturnType<SystemCore['groupBy']> {
    return proxy(this._core.groupBy(...args));
  }

  public getDebugInfo(...args: Parameters<SystemCore['getDebugInfo']>): ReturnType<SystemCore['getDebugInfo']> {
    return this._core.getDebugInfo(...args);
  }

  public onChange(...args: Parameters<SystemCore['onChange']>): ReturnType<SystemCore['onChange']> {
    return new Observable((subscriber: Subscriber<any>) => {
      let teardownFunction: Function | undefined;
      this._core.onChange(this.identifier, proxy((val: any) => subscriber.next(val)), ...args)
      .then((unsubscribe: Function) => {
        teardownFunction = unsubscribe;
      });

      return () => {
        teardownFunction?.();
      }
    })
  }

  public onCommand(): ReturnType<SystemCore['onCommand']> {
    return new Observable((subscriber: Subscriber<any>) => {
      let teardownFunction: Function | undefined;
      this._core.onCommand(proxy((val: any) => subscriber.next(val)))
      .then((unsubscribe: Function) => {
        teardownFunction = unsubscribe;
      });

      return () => {
        teardownFunction?.();
      }
    })
  }
}