<form [formGroup]="form">

  <section class="hours">
    <input #hours matInput formControlName="hours" type="text" [cleave]="cleave_number" autocomplete="off" [placeholder]="placeholder" (keydown)="hoursKeydown($event)" (blur)="blur('hours')">
    <span matTextSuffix class="form-field-suffix">{{ (form.controls.hours.value == '1' ? 'common.hour' : 'common.hours') | translate }}</span>
  </section>

  <section class="minutes">
    <input #minutes matInput formControlName="minutes" type="text" [cleave]="cleave_number" autocomplete="off" [placeholder]="placeholder" (keydown)="minutesKeydown($event)" (blur)="blur('minutes')">
    <span matTextSuffix class="form-field-suffix">{{ 'common.minutes' | translate }}</span>
  </section>

</form>
