import { Injectable,
         Injector,
         Optional                      } from '@angular/core';
import { Observable,
         Subject,
         BehaviorSubject,
         firstValueFrom                  } from 'rxjs';
import { first                           } from 'rxjs/operators';
import _                                   from 'lodash';

import { StorageService                  } from 'app/core/storage/storage.service';
import { BroadcastService                } from 'app/core/broadcast/broadcast.service';
import { DatabaseService                 } from 'app/core/database/database.service';
import { Collection                      } from 'app/core/database/database.interface';
import { LoggerService                   } from 'app/core/logger/logger.service';
import { MatSnackBar                     } from 'app/common';
import { Core                            } from './thread.core';

@Injectable({
  providedIn: 'root'
})
export class ThreadService extends Core {
  protected readonly onDestroy = new Subject<void>();   // never called, please implement

  protected _master:     SharedWorker;
  protected _collection: Collection              = 'threads';
  protected _onSubject:  Subject<boolean>        = new Subject<boolean>();
  protected _onMaster:   Subject<void>           = new Subject<void>();
  protected _threadId:   BehaviorSubject<number | undefined> = new BehaviorSubject<number | undefined>(undefined);

  public isMaster:       boolean = false;   // must be initialized to false it seems
  public numThreads:     number;

  constructor(protected _storage:   StorageService,
              protected _broadcast: BroadcastService,
              @Optional() protected  _database:  DatabaseService,
              private _injector: Injector,
              protected _logger:    LoggerService,
              protected _snackBar:  MatSnackBar) {
    super();

    if (typeof SharedWorker !== 'undefined') {
      this._master = new SharedWorker(new URL('./thread.worker', import.meta.url), { type: 'module', name: 'Royal Schedule Thread Worker' });
      this._master.port.start();
      this._master.port.onmessage = ({ data }) => {
        if (! _.isObjectLike(data))
          return;

        if ('numThreads' in data && this.numThreads !== data.numThreads) {
          this.numThreads = this._logger.numThreads = data.numThreads;
        }

        if ('command' in data) {
          const { command } = data;

          if ('setMaster' in command && this.isMaster !== command.setMaster) {
            this._logger.debug(`(Thread::Check) This thread is ${ command.setMaster ? 'master' : 'a slave' }`);
            this.isMaster = true;
            this._onMaster.next();
          }

          if ('setThreadId' in command) {
            this._threadId.next(command.setThreadId);
            this._threadId.complete();
            this._storage.setThreadID(command.setThreadId);
          }
        }
      };
      addEventListener('beforeunload', () => {
        this._master.port.postMessage({ command: 'disconnect' });
      });
    } else {
      const injector = Injector.create(
        {
          parent: this._injector,
          providers: [
            {
              provide: DatabaseService,
              deps: [
                LoggerService
              ]
            }
          ]
        }
      );

      this._database = injector.get(DatabaseService);
      // Web workers are not supported in this environment.
      // You should add a fallback so that your program still executes correctly.
      this._onMaster.pipe(first()).subscribe(this._emitPing.bind(this));

      this._receivePing();

      this._database
      .watch(this._collection)
      .subscribe(async (next) => {
        if (this._threadId.value == undefined)
          await firstValueFrom(this._threadId);
      });

      this._listen();

      this._addThread()
      .then(() => {
        this.checkIfMaster();
      }).catch(this._logger.error);
    }
  }

  protected setThreadAsMaster(): void {
    this.isMaster = true;
    this._onMaster.next();
  }

  public watch(): Observable<boolean> {
    return this._onSubject.asObservable();
  }

  protected _addThread(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this._database.put(this._collection, { })
      .then((id: number) => {
        if (! id)
          return this._logger.error(new Error(`(Thread::Service) No thread document created on init`));
        this._threadId.next(id);
        this._threadId.complete();
        this._storage.setThreadID(id);
        resolve();
      }).catch((err: Error) => {
        this._logger.error(err);
        reject(err);
      });
    })
  }

  protected _removeThread(): void {
    if (this._threadId.value != undefined)
      this._database.delete(this._collection, this._threadId.value);
  }

  public getThreadId(): number | undefined {
    return this._threadId.value;
  }

  public destroy() {
    this._broadcast.emit('thread_sync', { remove: this._threadId.value });
    this._removeThread();
  }
}