import { Component,
         Input,
         OnDestroy                        } from '@angular/core';
import { coerceBooleanProperty            } from '@angular/cdk/coercion';
import { Subject, takeUntil               } from 'rxjs';
import _ from 'lodash';

import { AvailableLocation                } from 'app/shared/interfaces';
import { DisplayNamePipe                  } from '@app/shared/pipes/common/common.pipe';
import { TranslateService                 } from '@app/core';

@Component({
  selector: 'app-form-field-available-locations-display-value',
  templateUrl: './display-value.component.html',
  styleUrls: ['./display-value.component.scss']
})
export class DisplayValueComponent implements OnDestroy {

  private onDestroy:     Subject<void> = new Subject<void>();
  private onLabelChange: Subject<void> = new Subject<void>();

  public inherits: boolean;
  public label:    string | undefined;
  public tooltip:  string;

  constructor(private _translate:   TranslateService,
              private _displayName: DisplayNamePipe) {

    this.onLabelChange
    .pipe(takeUntil(this.onDestroy))
    .subscribe(() => this._updateLabel());
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }


  private _updateLabel(): void {

    if (this._selected !== undefined) {
      ////
      //// relevant for the in-location form display value
      ////
      if (this._selected === null) {
        this.label   = undefined;
        this.tooltip = '';
      } else {
        this.label   = this._displayName.transform(this._selected);
        this.tooltip = '';
      }
    } else {
      ////
      //// relevant for the available location form display value
      ////
      if ( (   this.inherit && this._value)
        || ( ! this.inherit && this._value?.length) )
      {
        this.inherits = false;

        if (this._value?.length == 0) {
          this.label   = '-';
          this.tooltip = this._translate.instant('common.none_plural');
        } else {
          const value = Array.isArray(this._value) ? this._value : [this._value];
          const sets = this._toDeepStructure(value)
            .map(set => set
              .map(x => x.locations?.[0] ? this._displayName.transform(x.locations[0]) : null)
              .filter(Boolean)
            )

          this.label   = sets?.map(set => set.join(',\r')).join(' +\r');
          this.tooltip = sets?.map(set => set.join(',\r')).join('\n\n');
        }

      } else {
        this.inherits = true;
        this.label   = this.voidText;
        this.tooltip = '';
      }
    }

  }

  private _toDeepStructure(_val: AvailableLocation[]): AvailableLocation[][] {
    let val = _(_val ?? [])
      .map(x => ({
        groupIndex: x.groupIndex ?? 0,
        val: x
      }))
      .groupBy(x => x.groupIndex)
      .toArray()
      .map(x => x.map(y => y.val).filter(Boolean))
      .value();

    return val;
  }

  @Input()
  set value(value:  AvailableLocation[] | null | undefined) {
    this._value = value;
    this.onLabelChange.next();
  }
  private _value: AvailableLocation[] | null | undefined;

  @Input()
  get voidText(): string { return this._voidText; }
  set voidText(value: string) {
    this._voidText = value;
    this.onLabelChange.next();
  }
  private _voidText: string = '';

  @Input()
  set selected(value:  { id: string } & any) {
    this._selected   = value;
    this.onLabelChange.next();
  }
  private _selected: { id: string } & any;

  @Input()
  get disableTooltip(): boolean { return this._disableTooltip; }
  set disableTooltip(value: boolean) {
    this._disableTooltip = coerceBooleanProperty(value);
  }
  private _disableTooltip: boolean = false;

  @Input()
  get inherit(): boolean { return this._inherit; }
  set inherit(value: boolean | string) {
    this._inherit = coerceBooleanProperty(value);
    this.onLabelChange.next();
  }
  private _inherit: boolean = false;
}
