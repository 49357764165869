import { Subject,
         combineLatest,
         firstValueFrom,
         merge                                  } from 'rxjs';
import { takeUntil,
         filter,
         debounceTime,
         map,
         tap                                    } from 'rxjs/operators';

import { SourceService                          } from '@app/core';
import { environment                            } from '@env/environment';
import { InputAnalysisService                   } from './input-analysis.service';
import { AnalyzedOut,
         Remark,
         VerifiedOut                            } from './types';
import { SharedWorkerWrapper                    } from './Shared-worker/Shared-worker-wrapper';


export async function setupSharedWorker (
  this: InputAnalysisService
): Promise<void> {
  if ( ! this.did) return Promise.reject(new Error('No did provided'));
  if (this.worker) return Promise.reject(new Error('Shared worker already exists'));

  this.worker = new SharedWorkerWrapper(this._logger, this._ngZone);
  if (this.worker.isSupported()) {
    return this.worker.register(
      this.did,
      (x: VerifiedOut) => this.onVerified.next(x),
      (x: AnalyzedOut) => this.onAnalyzed.next(x)
    );
  } else {
    this._logger.error(new Error('Shared workers are not supported in this environment.'));
    return Promise.reject(new Error('Shared workers are not supported in this environment.'));
  }
}

export async function setupSource (
  this: InputAnalysisService,
  did:  string
) {
  const subject = new Subject<void>();

  this.source!.groupBy({ did,
    collections: ['divisions', 'settings', 'periods', 'locations', 'groups', 'teachers', 'persons', 'courses', 'events', 'lockedTimes', 'overlapGroups']
  })
  .then(() => {
    // ensure that the analyzer is still active as it may take several seconds to setup the source
    if ( ! this.active || ! this.source) return;

    this.source.getPopulatedLocations  ({ did: this.did!, onDestroy: this.onDestroy }).pipe(takeUntil(this.onDestroy)).subscribe(this.locations  );
    this.source.getPopulatedGroups     ({ did: this.did!, onDestroy: this.onDestroy }).pipe(takeUntil(this.onDestroy)).subscribe(this.groups     );
    this.source.getPopulatedTeachers   ({ did: this.did!, onDestroy: this.onDestroy }).pipe(takeUntil(this.onDestroy)).subscribe(this.teachers   );
    this.source.getPopulatedPersons    ({ did: this.did!, onDestroy: this.onDestroy }).pipe(takeUntil(this.onDestroy)).subscribe(this.persons    );
    this.source.getPopulatedEvents     ({ did: this.did!, onDestroy: this.onDestroy }).pipe(takeUntil(this.onDestroy)).subscribe(this.events     );
    this.source.getPopulatedLockedTimes({ did: this.did!, onDestroy: this.onDestroy }).pipe(takeUntil(this.onDestroy)).subscribe(this.lockedTimes);

    combineLatest({
      division:      this.source.getStrictDivision        ({ did: this.did!, onDestroy: this.onDestroy }),
      settings:      this.source.getStrictSettings        ({ did: this.did!, onDestroy: this.onDestroy }),
      periods:       this.source.getPopulatedPeriods      ({ did: this.did!, onDestroy: this.onDestroy }),
      locations:     this.source.getPopulatedLocations    ({ did: this.did!, onDestroy: this.onDestroy }),
      groups:        this.source.getPopulatedGroups       ({ did: this.did!, onDestroy: this.onDestroy }),
      teachers:      this.source.getPopulatedTeachers     ({ did: this.did!, onDestroy: this.onDestroy }),
      persons:       this.source.getPopulatedPersons      ({ did: this.did!, onDestroy: this.onDestroy }),
      courses:       this.source.getPopulatedCourses      ({ did: this.did!, onDestroy: this.onDestroy }),
      events:        this.source.getPopulatedEvents       ({ did: this.did!, onDestroy: this.onDestroy }),
      lockedTimes:   this.source.getPopulatedLockedTimes  ({ did: this.did!, onDestroy: this.onDestroy }),
      overlapGroups: this.source.getPopulatedOverlapGroups({ did: this.did!, onDestroy: this.onDestroy })
    })
    .pipe(
      filter(x => ! x.division.running),
      debounceTime(100),   // to prevent running for each update in a cascade of updates
      takeUntil(this.onDestroy)
    )
    .subscribe(x => {
      // increment the version and invalidate
      const version  = ++this.version;
      this.valid     = false;
      this.analyzing = true;

      // initialized
      this.initializing = false;

      // reset
      (this.criticalVerifierErrors        as any) = undefined;
      (this.noncriticalVerifierErrors     as any) = undefined;
      (this.intervalAnalysis              as any) = undefined;
      (this.eventMassDistributionAnalysis as any) = undefined;

      this.onData.next({ version, data: x });

      // analyze the data
      try {
        // (the output will be emitted by the onAnalyzed subject)
        environment.verbose && console.log(`(InputAnalysisService::_onChange) requesting analysis for %c${ x.division.id }`, 'font-weight: bold');
        this.worker?.analyze(version, x);
      } catch(err) {
        // catch errors within the worker thread
        this._logger.error(err);
        return;
      }

    })
    this._logger.verbose(`(InputAnalysisService::activate) input analysis activated for did ${ did }`)
    subject.next();
  })
  .catch((err: Error) => {
    this.deactivate();
    this._logger.error(new Error(`(InputAnalysisService::activate) Could not fetch collection for did ${ did }`));
    subject.error(err);
  });

  // emit as promise
  return firstValueFrom(subject);
}

export function setupDataProcessing (
  this: InputAnalysisService
) {
  let errors:  Remark[] = [];
  let issues:  Remark[] = [];
  let notices: Remark[] = [];

  let remarkIds     = new Set<string>();
  let prevRemarkIds = new Set<string>();

  // subscribe
  let currVersion    = 0;
  let firstCompleted = false;
  merge(
    this.onData    .pipe(                                map(x => ({ stream: 'data'         as const, completed: true,        version: x.version, remarks: this.processData    (x.version, x.data) }))),
    this.onVerified.pipe(filter(x => x.did == this.did), map(x => ({ stream: 'verification' as const, completed: true,        version: x.version, remarks: this.processVerified(x                ) }))),
    this.onAnalyzed.pipe(filter(x => x.did == this.did), map(x => ({ stream: 'analysis'     as const, completed: x.completed, version: x.version, remarks: this.processAnalyzed(x                ) })))
  )
  .pipe(
    takeUntil(this.onDestroy),
    tap(({ version }) => {
      // if the version is not the same as the current version the data is reset as it is outdated
      if (version > currVersion) {
        currVersion = version;
        remarkIds   = new Set<string>();
        errors      = [];
        issues      = [];
        notices     = [];
      }
    }),
    // accept only relevant versions
    filter(x => x.version == this.version)
  )
  .subscribe(({ stream, completed, remarks }) => {

    // emit notifications only after the first completed analysis
    if (firstCompleted) {
      remarks.forEach(x => {
        if (x.id && ! prevRemarkIds.has(x.id)) this.notify(x);
      });
    }

    // handle remarks
    remarks.forEach(remark => {
      if (remark.id) remarkIds.add(remark.id);

      if      (remark.type == 'error' ) errors .push(remark);
      else if (remark.type == 'issue' ) issues .push(remark);
      else if (remark.type == 'notice') notices.push(remark);
    });
    this.errors  = errors .sort(this.sortMapFun.bind(this));
    this.issues  = issues .sort(this.sortMapFun.bind(this));
    this.notices = notices.sort(this.sortMapFun.bind(this));

    // all critical errors stem from the verification
    if (stream == 'verification') this.valid = ! this.errors.length;

    // the analysis is completed when the analysis stream is completed
    if (stream == 'analysis' && completed) {
      this.analyzing = false;
      firstCompleted = true;

      // store as previous remark ids before the next analysis
      prevRemarkIds = remarkIds;
    }
  });
}


export async function activate (
  this:   InputAnalysisService,
  did:    string,
  source: SourceService
): Promise<void> {
  // abort if already active with same did
  if (did == this.did) return Promise.resolve();

  // force prior deactivation if active with different did
  if (this.did && did != this.did) this.deactivate();

  this.active       = true;
  this.did          = did;
  this.source       = source;
  this.initializing = true;

  // setup
  this.setupDataProcessing();
  await this.setupSharedWorker();
  return this.setupSource(did);
}

export function deactivate (
  this: InputAnalysisService
) {
  // temp save
  const did = this.did;

  this.active       = false;
  this.did          = undefined;
  this.source       = null;
  this.initializing = false;
  this.analyzing    = false;
  this.valid        = false;

  this.errors  = [];
  this.issues  = [];
  this.notices = [];

  this.onDestroy.next();

  // unsubscribe to the shared worker
  this.worker?.destroy();
  delete this.worker;

  // log activation
  this._logger.verbose(`(InputAnalysisService::deactivate) did ${ did } deactivated`)
}