import { Component,
         Optional,
         Inject,
         OnInit                          } from '@angular/core';
import { HttpService                     } from 'app/core';
import { apiConstants                    } from 'app/constants';
import { MatDialogRef                    } from 'app/common';

@Component({
  selector: 'app-confirm-action',
  templateUrl: './confirm-action.component.html',
  styleUrls: ['./confirm-action.component.scss']
})
export class ConfirmActionComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ConfirmActionComponent>) { }

  ngOnInit(): void {
  }

  public confirm(): void {
    this.dialogRef.close(true);
  }

}
