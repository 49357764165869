import { Injectable             } from '@angular/core';
import fontColorContrast          from 'font-color-contrast';

import { TranslateService,
         UserPreferencesService } from '@app/core';
import { Populated as P,
         Day                    } from 'app/shared/interfaces';
import _                          from 'lodash';



export type NameableEntity = undefined | null | string
                           | { displayName?: string }
                           | Pick<P.location | P.group | P.teacher | P.course, 'is' | 'displayName' | 'ids'>
                           | Pick<P.event, 'is' | 'displayName' | 'ids'> & { course?: Pick<P.course, 'displayName' | 'ids'> }
                           | Pick<P.lockedTime, 'is' | 'displayName' | 'type' | 'coalesced'>
                           | Pick<Day, 'is' | 'day'>
                           | Pick<P.person, 'is' | 'displayName' | 'firstName' | 'lastName' | 'ids'>
                           | Pick<P.period, 'is' | 'displayName'>
                           | Pick<P.rootInterval, 'is' | 'displayName'>;

type Is = NonNullable<(P.location | P.group | P.teacher | P.person | P.course | P.event | P.lockedTime | P.period | P.rootInterval | Day)['is']>;

function getIs (entity: Exclude<NameableEntity, undefined | null | string>): Is | null {
  return 'is' in entity && entity.is ? entity.is : null;
}

function rgba2rgb (
  RGBA_color:       number[],
  RGB_background:   number[],
  additional_alpha: number = 1
) {
  let alpha = RGBA_color[3] * additional_alpha;

  return [
    (1 - alpha) * RGB_background[0] + alpha * RGBA_color[0],
    (1 - alpha) * RGB_background[1] + alpha * RGBA_color[1],
    (1 - alpha) * RGB_background[2] + alpha * RGBA_color[2]
  ];
}

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor (private _translate:   TranslateService,
               private _preferences: UserPreferencesService) { }

  public static contrastService (_color: string, opacity: number = 1): "#ffffff" | "#000000" {
    let color: string | number[];

    // exit if empty string
    if (_color == '') return '#000000';

    if (/^rgba/.test(_color)) {
      // is rgba
      let rgba = _color.replace(/[^\d,.%]/g, '')
                       .split(',')
                       .map(x => parseFloat(x));

      // assume background color is white
      let rgb = rgba2rgb(rgba, [255, 255, 255], opacity);

      // must be integer for some reason
      color = rgb.map(x => Math.trunc(x));
    }
    else if (/^rgba/.test(_color)) {
      // is rgb
      let rgb = _color.replace(/[^\d,.%]/g, '')
                      .split(',')
                      .map(x => parseFloat(x));

      // assume background color is white
      rgb = rgba2rgb(rgb, [255, 255, 255], opacity);

      // must be integer for some reason
      color = rgb.map(x => Math.trunc(x));
    }
    else if (/^#/.test(_color)) {
      // is hex

      // TODO: take care of opacity also here

      color = _color;
    } else {
      console.warn(`Unknown color: ${_color}`);
      color = _color;
    }

    // bias towards white text
    return fontColorContrast(color as any, 0.8);
  }


  private _deriveName (
    entity: NameableEntity
  ): { value: string, owner: Is | null } {

    if ( ! entity) return { value: this._translate.instant('common.unknown'), owner: null };

    // if entity is a string display this value
    if (_.isString(entity)) return { value: entity, owner: null };

    // whether or not public ids are permissible
    const allowIds = false; // this._preferences.displayPublicId;

    // branch depending on the type of entity
    if ('is' in entity && entity.is == 'event' || 'course' in entity) {
      // is event
      if (entity.displayName)             return { value: entity.displayName,        owner: 'event' };
      if (allowIds && entity.ids)         return { value: entity.ids,                owner: 'event' };
      if (entity.course?.displayName)     return { value: entity.course.displayName, owner: 'course' };
      if (allowIds && entity.course?.ids) return { value: entity.course.ids,         owner: 'course' };
      return { value: this._translate.instant('common.nameless'), owner: 'event' };
    }

    else if ('is' in entity && entity.is == 'person') {//|| 'type' in entity
      // is person
      let name: string;
      if      (entity.displayName)                  name = entity.displayName;
      else if (entity.firstName || entity.lastName) name = `${entity.firstName} ${entity.lastName}`.trim();
      else                                          name = this._translate.instant('common.nameless');
      return { value: name, owner: 'person' };
    }

    else if ('is' in entity && entity.is == 'lockedTime' ) {//|| 'type' in entity
      // is locked time
      let name: string;
      if      (entity.type == 'LUNCH') name = this._translate.instant('common.lunch');
      else if (entity.displayName)     name = entity.displayName;
      else                             name = this._translate.instant('common.locked_time');
      return { value: name, owner: 'lockedTime' };
    }

    else if ('is' in entity && entity.is == 'day') {
      // is day
      return { value: this._translate.instant(`common.day_${entity.day}`), owner: 'day' };
    }

    else if ('displayName' in entity) {
      // is location, group, teacher, or course

      let name: string;
      if      (entity.displayName)                        name = entity.displayName;
      else if (allowIds && 'ids' in entity && entity.ids) name = entity.ids;
      else                                                name = this._translate.instant('common.nameless')
      return { value: name, owner: getIs(entity) };
    }

    return { value: this._translate.instant('common.unknown'), owner: null };
  }


  public deriveName (
    entity: NameableEntity,
    { capitalize = true }: { capitalize?: boolean } = { }
  ): { value: string, owner: Is | null } {

    let { value, owner } = this._deriveName(entity);

    // trim and capitalize
    value = value.trim();
    if (capitalize) value = value.capitalizeFirst();

    return { value, owner };
  }

}
