import * as T           from './translations/index';
import { LANG_SV_SUBS } from './substitutions/sv';


import { AvailableLanguage, LanguageConstant, SubstitutionRecordKey } from './types';

// alphabetical order
const AvailableLanguagesRecord: Record<LanguageConstant, AvailableLanguage> = {
  [T.LANG_CA_NAME]: {
    id:   T.LANG_CA_NAME,
    name: 'Català',
    img:  'assets/images/svg/countries/catalan_countries.svg'
  },
  [T.LANG_DE_NAME]: {
    id:   T.LANG_DE_NAME,
    name: 'Deutsch',
    img:  'assets/images/svg/countries/germany.svg'
  },
  // [LANG_EL_NAME]: {
  //   id:   LANG_EL_NAME,
  //   name: 'Ελληνικά',
  //   img:  'assets/images/svg/countries/greece.svg'
  // },
  [T.LANG_EN_NAME]: {
    id:   T.LANG_EN_NAME,
    name: 'English',
    img:  'assets/images/svg/countries/united_kingdom.svg'
  },
  [T.LANG_ES_NAME]: {
    id:   T.LANG_ES_NAME,
    name: 'Español',
    img:  'assets/images/svg/countries/spain.svg'
  },
  [T.LANG_FR_NAME]: {
    id:   T.LANG_FR_NAME,
    name: 'Français',
    img:  'assets/images/svg/countries/france.svg'
  },
  [T.LANG_SV_NAME]: {
    id:   T.LANG_SV_NAME,
    name: 'Svenska',
    img:  'assets/images/svg/countries/sweden.svg'
  }
};
export const availableLanguages: AvailableLanguage[] = Array.from(Object.values(AvailableLanguagesRecord));
export const languageConstants = availableLanguages.map(x => x.id);

export const languageRecords: Record<LanguageConstant, Record<string, string> > = {
  [T.LANG_CA_NAME]: T.LANG_CA_TRANS,
  [T.LANG_DE_NAME]: T.LANG_DE_TRANS,
  // [LANG_EL_NAME]: LANG_EL_TRANS,
  [T.LANG_EN_NAME]: T.LANG_EN_TRANS,
  [T.LANG_ES_NAME]: T.LANG_ES_TRANS,
  [T.LANG_FR_NAME]: T.LANG_FR_TRANS,
  [T.LANG_SV_NAME]: T.LANG_SV_TRANS
};

export const substitutionRecords: { [key in SubstitutionRecordKey]?: Record<string, string> } = {
  ...LANG_SV_SUBS
};
