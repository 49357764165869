import { NgModule                        } from '@angular/core';
import { CommonModule                    } from '@angular/common';
import { FormsModule,
         ReactiveFormsModule             } from '@angular/forms';

import { TranslationModule               } from 'app/core/translate/translate.module';

import { CalendarModule                  } from './calendar/calendar.module';
import { ComponentsModule                } from './components/components.module';
import { DialogsModule                   } from './dialogs/dialogs.module';
import { FormsModule as MyFormsModule    } from './forms/forms.module'
import { PipesModule                     } from './pipes/pipes.module';
import { RouteGuardService               } from './services/route-guard/route-guard.service';
import { TablesModule                    } from './tables/tables.module';
import { FormFieldsModule                } from './form-fields/form-fields.module';
import { DirectivesModule                } from './directives/directives.module';

@NgModule({
  declarations: [
  ],
  exports: [
    CalendarModule,
    ComponentsModule,
    DialogsModule,
    MyFormsModule,
    PipesModule,
    TablesModule,
    FormFieldsModule,
    DirectivesModule
  ],
  imports: [
    CommonModule,
    FormsModule,
    MyFormsModule,
    ComponentsModule,
    ReactiveFormsModule,
    TranslationModule,
    DialogsModule,
    TablesModule,
    FormFieldsModule,
    DirectivesModule
  ]
})
export class SharedModule {

  /*
    import SharedModule.forRoot() from appModule to provide services
    import SharedModule to only import components from child module
  */

  /*
  import SharedModule.forRoot() from appModule to provide services
  import SharedModule to only import components from child module
*/
static forRoot(): any {
    return {
        ngModule: SharedModule,
        providers: []
    };
}
}
