import { Injectable                      } from '@angular/core';
import { Observable,
         Subject,
         firstValueFrom                  } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RouteGuardService {
  private _onResolve:    Subject<boolean>;
  private _onDeactivate: Subject<void>;
  private _isLocked:     boolean = false;

  constructor() { }

  public lockSelf(): void {
    this._isLocked = true;
  }

  public canDeactivate() {
    return () => this._isLocked ? firstValueFrom(this._onResolve) : true;
  }

  public canActivate(): boolean {
    return true;
  }

  public onDeactivate(): Observable<void> {
    return this._onDeactivate.asObservable();
  }

  public resolve(resolved: boolean): void {
    this._onResolve.next(resolved);
  }
}
