import { PushNotificationService               } from './push-notification.service';
import { CategoryArgs, Category, Sounds,
         Locations, Modes, Stack, defaultCategorySettings, Categories } from './types';



export function setStack (
  this:     PushNotificationService,
  category: Category,
  _args:    Omit<CategoryArgs, 'category'>
): Stack | false {
  // ensure the category exists (the DB could send whatever garbage)
  if ( ! Categories.includes(category)) return false;

  // setup args
  let _default = defaultCategorySettings(category);
  let args: CategoryArgs = {
    category: category,
    location: (Locations.includes(_args.location)      ) ? _args.location : _default.location,
    sound:    (Sounds.some(x => x.name == _args.sound) ) ? _args.sound    : _default.sound,
    mode:     (Modes.includes(_args.mode)              ) ? _args.mode     : _default.mode,
    volume:   (_args.volume != undefined               ) ? _args.volume   : _default.volume,
  }

  if (this._stacks.has(category)) {
    // update arguments if it already exist
    this._stacks.get(category)!.args = args;
  } else {
    // create new stack
    this._stacks.set(category, { notifications: [], args });
  }

  return this._stacks.get(category)!;
}