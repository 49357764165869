import { NgModule                        } from '@angular/core';
import { CommonModule                    } from '@angular/common';
import { TranslationModule               } from 'app/core/translate/translate.module';

import { CollectionIconPipe,
         EnvironmentCollectionPipe       } from '@core/environment/environment.pipe';

import { DurationSincePipe               } from './duration/duration.pipe';
import { JoinPipe                        } from './join/join.pipe';
import { DefaultPipe,
         DisplayNamePipe,
         DisplayIconPipe,
         EventDisplayNamePipe,
         StringComparePipe,
         ConditionalPipe,
         ContrastPipe,
         HasValuePipe,
         DisplayNamesPipe,
         DisplayAvailableLocationsPipe,
         FirstKeyPipe,
         ReadableDurationPipe,
         InIntervalPipe,
         ReadableBreakLengthPipe,
         ReadableLockedTimesPipe,
         EventColorPipe,
         CourseColorPipe,
         ObjectEntriesPipe,
         ObjectValuesPipe,
         SexPipe,
         LogPipe,
         TypeGuardPipe,
         HasKeyPipe,
         AllowGreaterThanPipe,
         NegatePipe,
         StringifyPipe,
         EventDurationsPipe,
         NotPipe                         } from './common/common.pipe';
import { ArrayGreaterThanPipe,
         ArrayLengthPipe,
         ArrayIntervalPipe,
         ArrayLessThanPipe,
         PickPipe,
         FirstPipe,
         IncludesPipe,
         TruncatePipe,
         PickElementsPipe,
         FilterElementPipe,
         FilterPipe,
         LengthPipe                      } from './array/array.pipe';
import { ParagraphsPipe                  } from './paragraphs/paragraphs.pipe';
import { AddDayPipe,
         DayNamesPipe,
         FormatMomentPipe,
         StartOfWeekPipe,
         EndOfWeekPipe,
         MomentPipe,
         LocaleDatePipe,
         DatePipe                        } from './date/date.pipe';
import { CombineErrorsPipe               } from './errors/errors.pipe';
import { TranslatePipe,
         RolePipe,
         OrganizationTypePipe            } from './translate.pipe';
import { CapitalizeFirstPipe,
         TruncateStringPipe              } from './string.pipe';
import { SafePipe                        } from './safe.pipe';

@NgModule({
  declarations: [
    TruncateStringPipe,
    DurationSincePipe,
    JoinPipe,
    ConditionalPipe,
    DefaultPipe,
    DisplayNamePipe,
    DisplayIconPipe,
    EventDisplayNamePipe,
    StringComparePipe,
    ArrayGreaterThanPipe,
    ArrayIntervalPipe,
    ArrayLessThanPipe,
    AddDayPipe,
    ArrayLengthPipe,
    ParagraphsPipe,
    ContrastPipe,
    HasValuePipe,
    PickPipe,
    FirstPipe,
    IncludesPipe,
    DisplayNamesPipe,
    DisplayAvailableLocationsPipe,
    MomentPipe,
    DatePipe,
    LocaleDatePipe,
    CombineErrorsPipe,
    FirstKeyPipe,
    ReadableDurationPipe,
    InIntervalPipe,
    DayNamesPipe,
    ReadableBreakLengthPipe,
    ReadableLockedTimesPipe,
    EventColorPipe,
    CourseColorPipe,
    ObjectEntriesPipe,
    ObjectValuesPipe,
    LogPipe,
    SexPipe,
    TypeGuardPipe,
    HasKeyPipe,
    TranslatePipe,
    StartOfWeekPipe,
    EndOfWeekPipe,
    RolePipe,
    OrganizationTypePipe,
    EnvironmentCollectionPipe,
    CollectionIconPipe,
    CapitalizeFirstPipe,
    FormatMomentPipe,
    TruncatePipe,
    FilterElementPipe,
    PickElementsPipe,
    FilterPipe,
    AllowGreaterThanPipe,
    LengthPipe,
    NotPipe,
    NegatePipe,
    StringifyPipe,
    DatePipe,
    SafePipe,
    EventDurationsPipe
  ],
  exports: [
    TruncateStringPipe,
    DurationSincePipe,
    JoinPipe,
    ConditionalPipe,
    DisplayNamePipe,
    DisplayIconPipe,
    EventDisplayNamePipe,
    StringComparePipe,
    DefaultPipe,
    ArrayGreaterThanPipe,
    ArrayLessThanPipe,
    ArrayIntervalPipe,
    ParagraphsPipe,
    ArrayLengthPipe,
    AddDayPipe,
    ContrastPipe,
    HasValuePipe,
    PickPipe,
    FirstPipe,
    IncludesPipe,
    DisplayNamesPipe,
    DisplayAvailableLocationsPipe,
    MomentPipe,
    DatePipe,
    LocaleDatePipe,
    CombineErrorsPipe,
    FirstKeyPipe,
    ReadableDurationPipe,
    InIntervalPipe,
    DayNamesPipe,
    ReadableBreakLengthPipe,
    ReadableLockedTimesPipe,
    EventColorPipe,
    CourseColorPipe,
    ObjectEntriesPipe,
    ObjectValuesPipe,
    LogPipe,
    SexPipe,
    TypeGuardPipe,
    HasKeyPipe,
    TranslatePipe,
    StartOfWeekPipe,
    EndOfWeekPipe,
    RolePipe,
    OrganizationTypePipe,
    EnvironmentCollectionPipe,
    CollectionIconPipe,
    CapitalizeFirstPipe,
    FormatMomentPipe,
    TruncatePipe,
    FilterElementPipe,
    PickElementsPipe,
    FilterPipe,
    AllowGreaterThanPipe,
    LengthPipe,
    NotPipe,
    NegatePipe,
    StringifyPipe,
    DatePipe,
    SafePipe,
    EventDurationsPipe
  ],
  imports: [
    CommonModule,
    TranslationModule
  ]
})
export class PipesModule { }