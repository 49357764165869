import $                                         from 'jquery';
import { Subject, takeUntil                    } from 'rxjs';

import { Util                                  } from '@app/common';
import { PushNotificationService               } from './push-notification.service';


export function activate (
  this: PushNotificationService
) {
  // cannot be activated twice
  if (this.activated) return;
  this._activated = true;

  // verbose
  this._logger.verbose('Push notification service activated');

  // construct container DOM elements and subscribe to events
  this.setupDOM();

  // listen to preferences for category/stack property changes
  this.onActive = new Subject();
  this._preferences
    .watch('notifications')
    .pipe(takeUntil(this.onActive))
    .subscribe(x => {

      Util.functions.objectEntries(x)
      .forEach(([category, args]) => this.setStack(category, args));

      // emit
      this._stacks_BH.next(this._stacks);
    });
}


export function deactivate (
  this: PushNotificationService
) {
  if ( ! this._activated) return;
  this._activated = false;

  // verbose
  this._logger.verbose('Push notification service deactivated');

  // stop watching preferences changes
  this.onActive.next(true);
  this.onActive.complete();

  // clear all data
  this._stacks.clear();
  this._stacks_BH.next(this._stacks);
}


export function setupDOM (
  this: PushNotificationService
) {
  const containerId = 'notification-container';
  let container = $(`#${containerId}`);
  if ( ! container.length) {
    // insert container element
    container = $('body').append(`<div id="${containerId}" class="dark-scrollbar"></div>`)
                         .children(`#${containerId}`);

    // insert corners
    for (let loc of ['top left', 'top right', 'bottom left', 'bottom right']) {
      container.append(`<div class="corner ${loc} dark-scrollbar">
                          <div class="wrapper">
                          </div>
                        <div>`);
    }

    // subscribe click event of future events
    $(document).on("click", "#notification-container .close", this.closeNotification.bind(this));

    // make non-active corners transparent
    $(document).on("mouseover", "#notification-container .corner .wrapper .stack", ev => {
      $("#notification-container .corner").addClass('transparent');
      $(ev.currentTarget).parent().parent().removeClass('transparent');
    });
    $(document).on("mouseout", "#notification-container .corner .wrapper .stack", () => {
      $("#notification-container .corner").removeClass('transparent');
    });
  }
}