import { DivisionSettings,
         Division,
         Populated as P                   } from "@app/shared/interfaces";
import { WasmObservable                   } from "./wasm-types";
import { ChangeEvent as SourceChangeEvent } from '@app/core/source/core/types';

export namespace operations {
  export type All     = Exclude<keyof SourceChangeEvent, 'referenced'>;   // 'created' | 'updated' | 'removed'
  export type Removed = Extract<All, 'removed'>;

  export const all     = ['created', 'updated', 'removed'] as const satisfies readonly All[];
  export const removed = 'removed' as const satisfies Removed;
};

export type ExtendedDivisionSettings = DivisionSettings & { dt?: number };


export type ScheduleData = {
  division:      Division,
  settings:      ExtendedDivisionSettings;
  rootIntervals: P.rootInterval[];
  periods:       P.period[];
  persons:       P.person[];
  groups:        P.group[];
  locations:     P.location[];
  teachers:      P.teacher[];
  courses:       P.course[];
  events:        P.event[];
  lockedTimes:   P.lockedTime[];
  overlapGroups: P.overlapGroup[];
}

type Patch<T extends object> = {
  [K in Exclude<operations.All, operations.Removed>]?: Partial<T>;
} & {
  [K in operations.Removed]?: string[]
}

export type SchedulePatch = {
  settings?:      Patch<ExtendedDivisionSettings[]>;
  rootIntervals?: Patch<P.rootInterval[]>;
  periods?:       Patch<P.period[]>;
  persons?:       Patch<P.person[]>;
  groups?:        Patch<P.group[]>;
  locations?:     Patch<P.location[]>;
  teachers?:      Patch<P.teacher[]>;
  courses?:       Patch<P.course[]>;
  events?:        Patch<P.event[]>;
  lockedTimes?:   Patch<P.lockedTime[]>;
  overlapGroups?: Patch<P.overlapGroup[]>;
}

export type CustomError = {
  message:   string;
  location?: string;
  wasmFile?: string;
}

export namespace Callbacks {
  export type requestInitialization = (initiate: (data: ScheduleData) => void) => void;
  export type requestProvision      = (patch: (data: SchedulePatch) => void) => void;
  export type initialized           = () => void;
  export type error                 = (error: CustomError | Error | string | any, logError: boolean) => void;
  export type subscribe             = (data: any) => void;
  export type getLog                = (log: any[]) => void;
}


/**
 * Represents the API for interacting with the shared worker and the WASM that it hosts.
 */
export type Api = {
  /**
   * Registers a client's interest in a specific division.
   * This will cause the shared worker to initialize a WASM schedule instance for the division if it has not already been initialized.
   *
   * @param cid - The client id.
   * @param did - The division id.
   * @param onInitialized - The callback function to be called when the WASM schedule instance is initialized.
   * @param onError - The callback function to be called when the WASM schedule instance encounters an error.
   * @param onRequestInitialization - The callback function to be called when initialization of the WASM schedule instance is requested.
   * @param onRequestProvision - The callback function to be called when data provision of the WASM schedule instance is requested.
   */
  register: (
    cid:                     string,
    did:                     string,
    onInitialized:           Callbacks.initialized,
    onError:                 Callbacks.error,
    onRequestInitialization: Callbacks.requestInitialization,
    onRequestProvision:      Callbacks.requestProvision
  ) => void;

  /**
   * Deregisters a client.
   * This will cause the shared worker to destroy all the WASM schedule instances that the client has registered for if no other clients are interested in them.
   *
   * @param cid - The client id.
   */
  deregister: (
    cid: string
  ) => void;

  /**
   * Subscribes a client to the observable of the corresponding WASM schedule instance.
   *
   * @param cid - The client id.
   * @param did - The division id.
   * @param observable - The observable to subscribe to.
   * @param data - Additional data to pass to the observable.
   * @param onNext - The callback function to be called when a new value is emitted by the observable.
   * @returns A key-id pair that can be used to unsubscribe from the observable.
   */
  subscribe: (
    cid:        string,
    did:        string,
    observable: WasmObservable,
    onNext:     Callbacks.subscribe,
  ) => void | [observableKey: string, callbackId: number];

  /**
   * Unsubscribes a client from the observable of the corresponding WASM schedule instance.
   *
   * @param cid - The client id.
   * @param did - The division id.
   * @param observableKey - The key of the observable to unsubscribe from. a combination of the observable name and its data argument.
   * @param callbackId - The id of the callback function to unsubscribe.
   */
  unsubscribe: (
    cid:           string,
    did:           string,
    observableKey: string,
    callbackId:    number
  ) => void;

  /**
   * Retrieves the log for the WASM schedule instance.
   *
   * @param did - The division id.
   * @param onResponse - The callback function to be called with the log response.
   */
  getLog: (
    cid:        string,
    did:        string,
    onResponse: Callbacks.getLog
  ) => void;
}