import { ChangeDetectionStrategy,
         Component,
         Inject,
         ViewChild                       } from '@angular/core';
import { FormControl,
         FormGroup,
         Validators                      } from '@angular/forms';
import { MAT_DIALOG_DATA,
         MatDialogRef                    } from '@angular/material/dialog';
import { Router                          } from '@angular/router';
import { BehaviorSubject,
         Observable,
         Subject,
         defer,
         distinctUntilChanged,
         map,
         of,
         retry,
         startWith,
         switchMap,
         takeUntil,
         throwError                      } from 'rxjs';
import moment                              from 'moment';
import _                                   from 'lodash';

import { Util                            } from '@app/common';

import { apiConstants                    } from '@app/constants';

import { DivisionComponent               } from 'app/shared/forms/division/division.component';

import { HttpService,
         EnvironmentService,
         TranslateService                } from '@app/core';

import { Types                           } from '@app/shared/interfaces';

import { FormData,
         SyncedResponse,
         SyncedData                      } from './sync.types';

import { ValidatorComponent              } from './components/validator/validator.component';

@Component({
  templateUrl: './sync.component.html',
  styleUrls: ['./sync.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SyncComponent {
  @ViewChild(DivisionComponent) divisionForm?:       DivisionComponent;
  @ViewChild('validator')       validator:          ValidatorComponent;
  protected showAdvanced  = new BehaviorSubject<boolean>(false);
  protected onDestroy     = new Subject<void>();
  protected submitted     = new BehaviorSubject<boolean>(false);
  protected syncedData    = new BehaviorSubject<SyncedData | null>(null);
  protected error         = new BehaviorSubject<number | null>(null);
  protected errorMessages = new BehaviorSubject<string | null>(null);
  protected integrations  = this._environment.getIntegrations().filter((i) => ['SchoolSoft', 'IST', 'Edlevo', 'Sdui', 'Gotit'].includes(i.value));
  protected collections   = this._environment.collections.filter((x): x is 'groups' | 'courses' | 'teachers' | 'persons' | 'locations' | 'events' => ['groups', 'courses', 'teachers', 'persons', 'locations', 'events'].includes(x));
  protected form = new FormGroup({
    partner:     new FormControl<FormData['partner']>     (this.integrations.length == 1 ? this.integrations[0].value : undefined, { nonNullable: true, validators: [Validators.required] }),
    collections: new FormControl<FormData['collections']> (this.collections, { nonNullable: true, validators: [Validators.required] }),
    interval:    new FormGroup({
      useDefault: new FormControl<FormData['interval']['useDefault']>(true),
      start:      new FormControl<FormData['interval']['start']>({ value: undefined, disabled: true }, { nonNullable: true }),
      end:        new FormControl<FormData['interval']['end']>({ value: undefined, disabled: true }, { nonNullable: true })
    }),
    modifiedAfter: new FormControl<FormData['modifiedAfter']>({ value: undefined, disabled: true }, { nonNullable: true }),
    modifiedType: new FormControl<FormData['modifiedType']>(undefined),
  });
  protected showValidator = this.form.get('partner')?.valueChanges.pipe(
    startWith(this.form.get('partner')?.value),
    map(x => x && this._environment.getIntegration(x)?.implicitAction == 'push'),
    distinctUntilChanged(),
    takeUntil(this.onDestroy)
  );

  constructor(private _environment: EnvironmentService,
              private _dialogRef:   MatDialogRef<SyncComponent>,
              private _router:      Router,
              private _http:        HttpService,
              protected translate:  TranslateService,
              @Inject(MAT_DIALOG_DATA) protected data: { division?: Types.divisions, integrationType: Types.integrations['integrationType'] }) {
    this.submitted.pipe(takeUntil(this.onDestroy))
    .subscribe((submitted) => {
      submitted ? this.form.disable() : this._setEnabledForm();
    });

    if (data.division?.start && data.division?.end) {
      this.form.get('interval')?.setValue({ start: moment(data.division.start).toDate(), end: moment(data.division.end).toDate(), useDefault: false });
      this.form.get('modifiedAfter')?.setValue(moment(data.division.start).toDate());
    }
        // this.syncedData.next([]);

    if (data.division?.syncedAt) {
      this.form.get('modifiedType')?.setValue('lastSync');
    }

    /*
      If this is a whitelabel environment, set partner to said whitelabel
    */
    if (this._environment.isWhiteLabel) {
      data.integrationType = this._environment.whiteLabelItn;
      // this.form.get('partner')?.setValue(this._environment.whiteLabel as FormData['partner']);
    }
    /*
      If the integration type is set, set the partner to the integration type
    */
    if (data.integrationType)
      this.form.get('partner')?.setValue(data.integrationType);

    this.form.get('interval')?.get('useDefault')?.setValue(true);

    this.form.get('modifiedType')
    ?.valueChanges.pipe(takeUntil(this.onDestroy))
    .subscribe((value) => {
      this._setModifiedForm(value == 'custom');
    });

    this.form.get('interval')?.get('useDefault')
    ?.valueChanges.pipe(takeUntil(this.onDestroy))
    .subscribe((value) => {
      this._setIntervalForm(!!value);
     });
  }

  private _setEnabledForm() {
    this.form.enable();
    this._setIntervalForm(!!this.form.value.interval?.useDefault);
    this._setModifiedForm(this.form.value.modifiedType == 'custom');
  }

  private _setIntervalForm(value: boolean) {
    ! value ? this.form.get('interval')?.get('start')?.enable() : this.form.get('interval')?.get('start')?.disable();
    ! value ? this.form.get('interval')?.get('end')?.enable() : this.form.get('interval')?.get('end')?.disable();
    ! value ? this.form.get('interval')?.get('start')?.addValidators([Validators.required]) : this.form.get('interval')?.get('start')?.removeValidators([Validators.required]);
    ! value ? this.form.get('interval')?.get('end')?.addValidators([Validators.required]) : this.form.get('interval')?.get('end')?.removeValidators([Validators.required]);
  }

  private _setModifiedForm(value: boolean) {
    value ? this.form.get('modifiedAfter')?.enable() : this.form.get('modifiedAfter')?.disable();
    value ? this.form.get('modifiedAfter')?.addValidators([Validators.required]) : this.form.get('modifiedAfter')?.removeValidators([Validators.required]);
  }

  public openSchedule() {
    this._router.navigate(['schema', this.data.division?.id, 'data']);
    this._dialogRef.close();
  }

  public submit() {
    if (this.form.invalid)
      return;
    this.error.next(null);
    const { partner, collections } = this.form.value;

    if (! partner || ! collections)
      return;
    const query = _.omitBy({
      collections,
      modifiedAfter: this._getModifiedAfter(),
      ...this._getInterval()
    }, _.isNil);

    this.syncedData.next(null);

    this.submitted.next(true);
    const action = this._environment.getIntegration(partner)?.implicitAction == 'pull' ? 'get' : 'post';
    let ob: Observable<SyncedResponse>;
    if (this.data.division?.id)
      ob = this._http[action](`${ apiConstants.CONNECTIONS }/${ partner }/${ this.data.division.id }`, query);
    else
      ob = this._http.post(`${ apiConstants.CONNECTIONS }/${ partner }`, { division: this.divisionForm?.form.value }, query);

    ob.subscribe({
      next: (res) => {
        defer(() => {
          return this._http.get(`operations/${ res.id }`)
          .pipe(
            switchMap((x: Types.operations) => {
              return (x.status == 'STARTED' || x.status == 'PENDING') ? throwError(() => new Error('Operation still running')) : of(x)
            })
          )
        })
        .pipe(
          takeUntil(this.onDestroy),
          // retry for an hour with 3 second delay
          retry({ count: 1200, delay: 3000 })
        )
        .subscribe({
          next: (res) => {
            this.submitted.next(false);
            if (res.status == 'FAILED') {
              this.submitted.next(false);
              this.error.next(res.error?.code ?? -1);
              if (_.isString(res.error?.data?.meta?.errors?.[0])) {
                this.errorMessages.next(res.error!.data.meta.errors);
              }
              return;
            }
            this.syncedData.next({
              action: this._environment.getIntegration(partner)?.implicitAction,
              data: res.modified ?? []
            });
            if (! this.data.division && res.belongsTo)
              this.data.division = { id: res.belongsTo.id, ...this.divisionForm?.form.value };
          },
          error: (error: Error) => {
            this.submitted.next(false);
            this.error.next(-1);
            if (_.isString(error.message)) {
              this.errorMessages.next(error.message);
            }
          }
        });
      },
      error: (res) => {
        this.submitted.next(false);
        if (Util.TypeGuards.isHttpErrorResponse(res)) {
          const { error } = res;
          this.error.next(error?.code ?? -1);
          if (_.isString(error?.data?.meta?.errors?.[0])) {
            this.errorMessages.next(error.data.meta.errors);
          }
        }
      }
    })
  }

  private _getModifiedAfter() {
    switch (this.form.value.modifiedType) {
      case 'custom':
        return moment(this.form.value.modifiedAfter).toISOString();
      case 'lastSync':
        return moment(this.data.division?.syncedAt).toISOString();
      default:
      case null:
        return null;
    }
  }

  private _getInterval() {
    const { interval } = this.form.value;
    if (interval && interval.useDefault === false) {
      return {
        start: moment(interval.start).toISOString(),
        end:   moment(interval.end).toISOString()
      };
    }
    return {};
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }
}
