import { Component,
         OnDestroy,
         Inject,
         OnInit                          } from '@angular/core';
import { UntypedFormControl,
         UntypedFormBuilder,
         Validators                      } from '@angular/forms';
import { Subject                         } from 'rxjs';
import { takeUntil                       } from 'rxjs/operators';

import { HttpService                     } from 'app/core/http/http.service';
import { TranslateService                } from 'app/core/translate/translate.service';
import { StorageService                  } from 'app/core/storage/storage.service';

import { apiConstants,
         storageConstants                } from 'app/constants';

import { MatDialogRef,
         MAT_DIALOG_DATA                 } from 'app/common';

type AuthData = {
  language:     string,
  role:         string,
  token:        any
  user:         any,
  preferences?: any
}
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit,
                                       OnDestroy {
  private onSubject: Subject<boolean> = new Subject<boolean>();
  private onDestroy: Subject<boolean> = new Subject<boolean>();
  private noMatch:   boolean          = false;
  public form:       any;
  public submitted:  boolean;
  public hide:       boolean          = true;

  constructor(private _http:            HttpService,
              private _dialogRef:       MatDialogRef<LoginComponent>,
              private _storage:         StorageService,
              @Inject(MAT_DIALOG_DATA)
              public data:              { username: string } | undefined,
              private _fb:              UntypedFormBuilder) {
    this.form = this._fb.group({
      username: [{ value: data?.username, disabled: true }, [<any>Validators.required, this.noMatchValidator.bind(this), Validators.email]],
      password: ['', [<any>Validators.required, <any>Validators.minLength(5)]]
    });
  }

  ngOnInit(): void {
    /* SO THAT THE LOGIN DIALOG DOES NOT APPEAR ON THE LOGIN SCREEN? */ // this._storage.watchStorage(storageConstants.ACCESS_TOKEN)
    /* SO THAT THE LOGIN DIALOG DOES NOT APPEAR ON THE LOGIN SCREEN? */ // .pipe(takeUntil(this.onDestroy))
    /* SO THAT THE LOGIN DIALOG DOES NOT APPEAR ON THE LOGIN SCREEN? */ // .subscribe(({ value }) => {
    /* SO THAT THE LOGIN DIALOG DOES NOT APPEAR ON THE LOGIN SCREEN? */ //   this._dialogRef.close({ renewed: !! value });
    /* SO THAT THE LOGIN DIALOG DOES NOT APPEAR ON THE LOGIN SCREEN? */ // });

    this.form.controls.password.valueChanges
    .pipe(takeUntil(this.onDestroy))
    .subscribe(() => {
      if (this.noMatch) {
        this.noMatch = false;
        this.form.controls.username.updateValueAndValidity();
      }
    })
  }

  ngOnDestroy() {
    this.onDestroy.next(true);
    this.onDestroy.complete();
  }

  private noMatchValidator(control: UntypedFormControl): { [key: string]: boolean } {
    if (this.noMatch) {
      return { noMatch: true };
    }
    return {};
  }

  public login(model: any, isValid: boolean): void {
    if (! isValid)
      return;
    this.onSubject.next(true);
    this.submitted = true;
    this.noMatch = false;

    for(let key in this.form.controls)
      this.form.controls[key].disable();

    this._http.post(`${ apiConstants.AUTH }`, { username: this.data?.username, password: model.password }, undefined, true)
    .subscribe({
     next: (res: AuthData) => {
        this._dialogRef.close({ credentials: res, renewed: true });
      },
      error: (err: Error) => {
        for (let key in this.form.controls)
          this.form.controls[key].enable();
        this.submitted = false;
        this.onSubject.next(false);
        (<UntypedFormControl>this.form.controls['password'])
        .setValue(undefined, { onlySelf: true });

        (<UntypedFormControl>this.form.controls['password']).markAsUntouched();
        (<UntypedFormControl>this.form.controls['password']).markAsPristine();
        (<UntypedFormControl>this.form.controls['password']).updateValueAndValidity();
        this.noMatch = true;
        (<UntypedFormControl>this.form.controls['username']).updateValueAndValidity();
      }
    });
  }
}
